/*
*
* =================================================================================================================
* FUNKCIE
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import './items.css';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { pdfjs } from 'react-pdf';
import { Chip } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

/*
*
* =================================================================================================================
* FUNKCIE
* =================================================================================================================
*
*/

export const Debug = (data, color, typ) => {
    /*
        USAGE:
        ---------------------------------------
        Debug('text');
        Debug('text', 'gray', 'info');
        Debug('text', 'yellow', 'debug');
        Debug('text', 'red', 'warn');

    */

    if (global.testing == true) {
        if (color == undefined && typ == undefined) {
            console.log(data);
        } else {
            if (typ == 'warn') {
                console.warn('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else if (typ == 'info') {
                console.info('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else if (typ == 'debug') {
                console.debug('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else {
                console.log('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            }
        }
    }
}


export function GetImageLink(path) {
    return path.substring(0, 4) == 'http' ? path : global.web + "/" + path;
}

export const ResetDashboard = () => {
    SaveValue('dashboard_date', 0); // VYNUTENIE NACITANIA DASHBOARDU
}

export const CopyData = (source, dest, exclude) => {
    // SKOPIROVANIE VSYTKYCH KEYS Z OBJEKTU - source DO OBJEKTU - dest
    // exclude - array[id, ...] - ktory keys nebudú skopírované

    var keys = Object.keys(source);
    keys.forEach(key => {
        if (!exclude.includes(key)) {
            dest[key] = source[key];
        }
    });
}

export const DataToBinary = (data1, data2, data3, data4, data5, data6, data7, data8) => {
    // VYTVORI DECIMAL CISLO Z UDAJOV    
    var result = 0;

    let d1 = data1 == true ? 1 : 0;
    let d2 = data2 == true ? 1 : 0;
    let d3 = data3 == true ? 1 : 0;
    let d4 = data4 == true ? 1 : 0;
    let d5 = data5 == true ? 1 : 0;
    let d6 = data6 == true ? 1 : 0;
    let d7 = data7 == true ? 1 : 0;
    let d8 = data8 == true ? 1 : 0;

    result = d1 << 1 | d2;
    result = result << 1 | d3;
    result = result << 1 | d4;
    result = result << 1 | d5;
    result = result << 1 | d6;
    result = result << 1 | d7;
    result = result << 1 | d8;

    return result;
}

export const BinaryToData = (value) => {
    // Z BINARY CISLA VYTVORI ITEMS

    let d1 = (value & 0B10000000) == 0B10000000 ? true : false;
    let d2 = (value & 0B01000000) == 0B01000000 ? true : false;
    let d3 = (value & 0B00100000) == 0B00100000 ? true : false;
    let d4 = (value & 0B00010000) == 0B00010000 ? true : false;
    let d5 = (value & 0B00001000) == 0B00001000 ? true : false;
    let d6 = (value & 0B00000100) == 0B00000100 ? true : false;
    let d7 = (value & 0B00000010) == 0B00000010 ? true : false;
    let d8 = (value & 0B00000001) == 0B00000001 ? true : false;

    let data = {
        d1: d1,
        d2: d2,
        d3: d3,
        d4: d4,
        d5: d5,
        d6: d6,
        d7: d7,
        d8: d8,
    };

    return data;
}

export const PermissionToBinary = (array) => {
    // VYTVORI DECIMAL CISLO Z PERMISSION -> [1,3]
    var result = 0;

    let d1 = array.includes(1) == true ? 1 : 0;
    let d2 = array.includes(2) == true ? 1 : 0;
    let d3 = array.includes(3) == true ? 1 : 0;
    let d4 = array.includes(4) == true ? 1 : 0;
    let d5 = array.includes(5) == true ? 1 : 0;
    let d6 = array.includes(6) == true ? 1 : 0;
    let d7 = array.includes(7) == true ? 1 : 0;
    let d8 = array.includes(8) == true ? 1 : 0;

    result = d1 << 1 | d2;
    result = result << 1 | d3;
    result = result << 1 | d4;
    result = result << 1 | d5;
    result = result << 1 | d6;
    result = result << 1 | d7;
    result = result << 1 | d8;

    return result;
}

export const BinaryToPermission = (value) => {
    // Z BINARY CISLA VYTVORI PERMISION ARRAY - potrebný pri nastavovaní povolení

    let d1 = (value & 0B10000000) == 0B10000000 ? true : false;
    let d2 = (value & 0B01000000) == 0B01000000 ? true : false;
    let d3 = (value & 0B00100000) == 0B00100000 ? true : false;
    let d4 = (value & 0B00010000) == 0B00010000 ? true : false;
    let d5 = (value & 0B00001000) == 0B00001000 ? true : false;
    let d6 = (value & 0B00000100) == 0B00000100 ? true : false;
    let d7 = (value & 0B00000010) == 0B00000010 ? true : false;
    let d8 = (value & 0B00000001) == 0B00000001 ? true : false;

    let data = [];
    if (d1) {
        data.push(1);
    }
    if (d2) {
        data.push(2);
    }
    if (d3) {
        data.push(3);
    }
    if (d4) {
        data.push(4);
    }
    if (d5) {
        data.push(5);
    }
    if (d6) {
        data.push(6);
    }
    if (d7) {
        data.push(7);
    }
    if (d8) {
        data.push(8);
    }

    return data;
}

export const CheckPermission = (superAdmin, permision) => {
    // KONTROLA POVOLENIA
    /*
                USAGE: 
                -----------------------------------------------------------------------------------------------
                CheckPermission(props.user.super_admin, props.permissions['db_column']).write == true

                PARAMS:
                -----------------------------------------------------------------------------------------------
                db_column   -> názov stĺpca z DB - permissions

                RESULT:
                -----------------------------------------------------------------------------------------------
                editing     -> true ak jedna z edit/write/delete je povolená, inak false

    */

    var result = { read: true, write: false, edit: false, delete: false, export: false, enabled: false };
    if (superAdmin == true) {
        result = { read: true, write: true, edit: true, delete: true, export: true, enabled: true, editing: true };
    } else {
        if (permision != undefined) {
            let item = BinaryToData(permision);
            result.read = item.d1;
            result.write = item.d2;
            result.edit = item.d3;
            result.delete = item.d4;
            result.export = item.d5;
            result.enabled = item.d8;
            result.editing = item.d2 == true || item.d3 == true || item.d4 == true || item.d8 == true ? true : false;
        }
    }
    return result;
}

export function CoeficientToPercentage(coeficient, precission_) {
    var result = 100;
    var sub_string = '+';
    result = Math.abs(1 - coeficient);
    if (coeficient < 1) {
        sub_string = '-';
    }
    if (coeficient == 1) {
        sub_string = '';
    }

    var precission = 0;
    if (precission_ != undefined) {
        precission = precission_;
    }
    return sub_string + String((result * 100).toFixed(precission));
}


export function IsEmpty(data) {
    // kontrola ci je retazec prázdny
    if (data.trim() == '') {
        return true;
    } else {
        return false;
    }
}

export function GetVersion(data) {
    // vráti aktualnu verziu aplikácie
    var tmp = parseInt(data);
    var number = parseInt(tmp / 1000);
    var decimal = parseInt(tmp % 1000);

    return number.toString().padStart(1, '0') + '.' + decimal.toString().padStart(3, '0');
}

export function GetTopPosition(height, dialogHeight) {
    // posun okna hore, ak je zapnuta klavesnica
    var offset = 0;

    if (height < 900) {
        offset = 0 - (height - dialogHeight) + global.topmenu_height * 2;
    }

    return offset;
}

export function GetLeftPosition(width) {
    // posun okna do lava
    var offset = 0;

    if (width > global.max_screen) {
        offset = global.menu_width;
    }

    if (width < 1600) {
        offset = 0;
    }


    return offset;
}

export function notNumber(data) {
    // nie je císlo ?
    var number = data.trim().replaceAll(' ', '');
    number = number.replaceAll(',', '.');

    if (number != '') {
        if (isNaN(number)) {
            return true
        } else {
            return false;
        }
    } else {
        // prázdny retazec
        return true;
    }
}

export function notTime(time) {
    // nie je čas?
    var result = false;

    var patt = new RegExp("^(0?[1-9]|1[0-9]|2[0-3]):[0-5][0-9]$");
    var result = patt.test(time);

    return !result;
}

export function IsMobil(mobil) {
    // je medzinárodné číslo ?
    var result = false;
    if (mobil.length > 0) {
        if (mobil.substring(0, 1) == '+') {
            result = true;
        }
    } else {
        result = true;
    }

    return result;
}

export function nonEmail(mail) {
    // kontrola správnosti e-mailovej adresy - ak je chyba vráti -> true
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (false)
    }
    return (true);
};

export function paswordCheck(password) {
    var result = 0;
    let length = password.length;

    if (length > 1) {
        let poorRegExp = /[a-z]/;
        let weakRegExp = /(?=.*?[0-9])/;
        let strongRegExp = /(?=.*?[#?!@$%^&*+-])/;
        let sequenceRegExp = /^(?!.*([0-9])\1)[0-9]+$/;

        result += password.match(poorRegExp) == null ? 0 : 1;
        result += password.match(weakRegExp) == null ? 0 : 1;
        result += password.match(strongRegExp) == null ? 0 : 1;
        result = password.match(sequenceRegExp) == null ? result : 1;

    }
    return (length < 7 ? 1 : result);
};

export function strShuffle(s) {
    // tvorba náhodného kluca 
    var arr = s.split('');

    arr.sort(function () {
        return 0.5 - Math.random();
    });
    s = arr.join('');
    return s;
}


export function strShuffleCode(key, pass) {
    // tvorba kluca na základe hesla
    // key - kodovaci retazec
    // pass - heslo k zakodovaniu

    var n = 0;
    var sume = 0;
    while (n < pass.length) {
        sume += parseInt(pass.charCodeAt(n));
        n++;
    }
    sume = sume % 10;
    var offset = (sume * 4) % key.length;
    var code = key.substring(offset, key.length) + key.substring(0, offset);
    var arr = code.split('');

    n = 0;
    arr.sort(function () {
        let rand = pass.charCodeAt(n) % 10;
        n++;
        if (n == pass.length) {
            rand = sume;
            n = 0;
        }
        return rand - 5;
    });

    var str = arr.join('');
    return str;
}

export function GetTid(text) {
    var txt = '';
    if (text != null && text != undefined) {

        txt = text.trim();
        txt = text.toLowerCase();
        txt = txt.replaceAll('    ', '-');
        txt = txt.replaceAll('   ', '-');
        txt = txt.replaceAll('  ', '-');
        txt = txt.replaceAll(' ', '-');
        txt = txt.replaceAll('+', '');
        txt = txt.replaceAll('/', '');
        txt = txt.replaceAll('@', '');
        txt = txt.replaceAll('#', '');
        txt = txt.replaceAll('$', '');
        txt = txt.replaceAll('*', '');
        txt = txt.replaceAll('=', '');
        txt = txt.replaceAll(',', '');
        txt = txt.replaceAll('.', '');
        txt = txt.replaceAll('_', '-');

        txt = txt.replaceAll('ľ', 'l');
        txt = txt.replaceAll('š', 's');
        txt = txt.replaceAll('č', 'c');
        txt = txt.replaceAll('ť', 't');
        txt = txt.replaceAll('ž', 'z');
        txt = txt.replaceAll('ý', 'y');
        txt = txt.replaceAll('á', 'a');
        txt = txt.replaceAll('í', 'i');
        txt = txt.replaceAll('é', 'e');
        txt = txt.replaceAll('ú', 'u');
        txt = txt.replaceAll('ä', 'a');
        txt = txt.replaceAll('ô', 'o');
        txt = txt.replaceAll('ň', 'n');
        txt = txt.replaceAll('ř', 'r');
        txt = txt.replaceAll('ó', 'o');
        txt = txt.replaceAll('ď', 'd');
    }

    return txt;
}

export function GetDefaultDesktop(lang, typ) {
    var result = {};

    if (typ == global.account_salon) {
        // KOZMETICKY SALON

        let desktop = {
            calendar: true,
            todo: true,
            birthday: true,
            eshop: true,
            reserve: true
        };

        let shortcuts = [
            { id: 0, tid: 'stat_day', label: lang.sc_stats_day, value: false },
            { id: 1, tid: 'product_selling', label: lang.sc_selling, value: false },
            { id: 2, tid: 'product_booking', label: lang.sc_products_booking, value: false },
            { id: 3, tid: 'product_settings', label: lang.sc_products_settings, value: false },
            { id: 4, tid: 'bonus_booking', label: lang.sc_bonus_booking, value: false },
            { id: 5, tid: 'service_info', label: lang.sc_services_info, value: false },
            { id: 6, tid: 'product_info', label: lang.sc_products_info, value: false },
            { id: 7, tid: 'info_newsletter', label: lang.sc_info_newsletter, value: false },
            { id: 8, tid: 'info_cennik', label: lang.sc_info_cennik, value: false },
            { id: 9, tid: 'info_action', label: lang.sc_info_action, value: false },
            { id: 10, tid: 'web_settings', label: lang.sc_page_settings, value: false },
            { id: 11, tid: 'web_preview', label: lang.sc_page_web, value: false },
            { id: 12, tid: 'last_client', label: lang.sc_client_last, value: false },
        ]

        result = { desktop: desktop, shortcuts: shortcuts };
    } else if (typ == global.account_medical) {
        let desktop = {
            todo: true,
        };

        let shortcuts = [
            { id: 2, tid: 'product_booking', label: lang.sc_products_booking, value: false },
            { id: 4, tid: 'bonus_booking', label: lang.sc_bonus_booking, value: false },
            { id: 5, tid: 'service_info', label: lang.sc_services_info, value: false },
            { id: 6, tid: 'product_info', label: lang.sc_products_info, value: false },
            { id: 7, tid: 'info_newsletter', label: lang.sc_info_newsletter, value: false },
            { id: 8, tid: 'info_cennik', label: lang.sc_info_cennik, value: false },
            { id: 9, tid: 'info_action', label: lang.sc_info_action, value: false },
        ]

        result = { desktop: desktop, shortcuts: shortcuts };
    } else {
        // AGENT

        let desktop = {
            products: true,
            bonus: true,
            reklamacie: true,
            todo: true
        };

        let shortcuts = [
            { id: 0, tid: 'booking_products', label: lang.sc_salon_booking_products, value: true },
            { id: 1, tid: 'booking_bonus', label: lang.sc_salon_booking_bonus, value: true },
            { id: 2, tid: 'reklamacie', label: lang.reklamacie, value: true },
            { id: 3, tid: 'service_info', label: lang.sc_services_info, value: false },
            { id: 4, tid: 'product_info', label: lang.sc_products_info, value: false },
            { id: 5, tid: 'info_newsletter', label: lang.sc_info_newsletter, value: false },
            { id: 6, tid: 'info_cennik', label: lang.sc_info_cennik, value: false },
            { id: 7, tid: 'info_action', label: lang.sc_info_action, value: false },
            { id: 8, tid: 'last_salon', label: lang.sc_salon_last, value: true },
        ]

        result = { desktop: desktop, shortcuts: shortcuts };
    }

    return result;
}

export function GetMoney(id, currencies) {
    // ZISKANIE MENY Z DB CURENCY PODLA INDEXU
    //
    // USAGE: GetMoney(1, props.system.currencies)
    //
    var result = '';
    var tmp = currencies.find(x => x.id == id);
    if (tmp != undefined) {
        result = tmp.currency;
    }

    return (result);
};

export function GetPrice(price) {
    if (price != undefined) {
        var txt = price.toString().replace('.', ',');
    } else {
        txt = '';
    }
    return txt;
}


export function ConvertToPrice(price, precision) {
    var txt = price.toString().replace(',', '.');
    txt = txt.trim();
    if (txt == '') {
        txt = '0';
    }
    txt = parseFloat(txt).toFixed(precision);

    return txt;
}


export function ConvertToPrice2(price, precision) {
    return parseFloat(price).toFixed(precision);
}


export function UniqueID() {
    let s4 = () => { return Math.floor((1 + Math.random() * 0x10000)).toString(16).substring(1); }
    return (Date.now() + '-' + s4() + s4() + s4() + '-' + s4() + s4() + s4() + '-' + s4() + s4() + s4());
}


export const Init = () => {

}


// ********************************************************
// LANGUAGE
// ********************************************************

export const SaveLanguage = (lang) => {
    localStorage.setItem('vision-crm-app-language', lang);
}

export const LoadLanguage = () => {
    var lang = localStorage.getItem('vision-crm-app-language');
    if (lang != null) {
        var result = sk;

        if (lang == 1) {
            result = sk;
        }
        if (lang == 2) {
            result = cz;
        }
        if (lang == 3) {
            result = cz;
        }
        return result;
    } else {
        return sk;
    }
}


export const GetLanguage = (lang) => {
    var result = sk;
    if (lang == 1) {
        result = sk;
    }
    if (lang == 2) {
        result = sk;
    }
    if (lang == 3) {
        result = sk;
    }
    return result;
}


export function RoundValue(value, precision) {
    // ZAOKRUHLENIE CISLA
    var result = 0;
    if (precision == 2) {
        result = Math.round(parseFloat(value) * 100) / 100;
    }
    if (precision == 3) {
        result = Math.round(parseFloat(value) * 1000) / 1000;
    }
    return result;
};



export function FormatMoney(price_, precision) {
    // FORMATOVANIE CISLA: 1 234,56
    var price = parseFloat(price_);
    let value = price.toLocaleString("sk-SK", { minimumFractionDigits: precision, maximumFractionDigits: precision });

    return (value);
};

export function FormatMoneyDPH(price_, dph_, precision) {
    // FORMATOVANIE CISLA: 1 234,56
    var price_without_dph = parseFloat(price_);
    var price = price_without_dph * ((parseFloat(dph_) / 100) + 1);
    let value = price.toLocaleString("sk-SK", { minimumFractionDigits: precision, maximumFractionDigits: precision });

    return (value);
};

export function FormatPieces(quantity_, precision) {
    // FORMATOVANIE CISLA: 1 234,56
    var quantity = parseInt(quantity_);
    let value = quantity.toLocaleString("sk-SK", { minimumFractionDigits: 0, maximumFractionDigits: 0 });

    return (value);
};


export const ResetLogin = () => {
    var data = { token: '', expire: 0 };
    SaveLogin(data);
}


export const LoadLogin = () => {
    var data = localStorage.getItem('vision-crm-app-login');
    if (data != null) {
        return JSON.parse(data);
    } else {
        return { token: '', date: 0 };
    }
}


export const SaveLogin = (data) => {
    localStorage.setItem('vision-crm-app-login', JSON.stringify(data));
}


export const RemoveValue = (id) => {
    localStorage.removeItem('vision-crm-' + String(id));
}

export const SaveValue = (id, value) => {
    localStorage.setItem('vision-crm-' + String(id), value);
}


export const LoadValue = (id, defaultValue) => {
    var value = localStorage.getItem('vision-crm-' + String(id));
    if (value != null) {
        return value;
    } else {
        return defaultValue;
    }
}

export const SaveString = (id, value) => {
    localStorage.setItem('vision-crm-' + String(id), value);
}


export const LoadString = (id, defaultValue) => {
    var value = localStorage.getItem('vision-crm-' + String(id));
    if (value != null) {
        return value;
    } else {
        return defaultValue;
    }
}

export const SaveBool = (id, value) => {
    localStorage.setItem('vision-crm-' + String(id), value == false ? '0' : '1');
}


export const LoadBool = (id, defaultValue) => {
    var value = localStorage.getItem('vision-crm-' + String(id));
    if (value != null) {
        return value == '0' ? false : true;
    } else {
        return defaultValue;
    }
}


export const SaveArray = (id, arr) => {
    localStorage.setItem('vision-crm-arr-' + String(id), JSON.stringify(arr));
}


export const LoadArray = (id, defaultValue) => {
    var value = localStorage.getItem('vision-crm-arr-' + String(id));
    if (value != null) {
        return JSON.parse(value);
    } else {
        return defaultValue;
    }
}

export const GetPages = (count, itemsMax) => {
    // PAGIMATION - výpočet počtu strán
    return Math.ceil(count / itemsMax);
}

export const GetGBFromBytes = (value) => {
    // výpočet GB z Bytes
    let bytes = parseInt(value);
    return String(parseFloat(bytes / (1024 * 1024 * 1024)).toFixed(2)).replace('.', ',');
}

export const GetMBFromBytes = (value) => {
    // výpočet GB z Bytes
    let bytes = parseInt(value);
    return String(parseFloat(bytes / (1024 * 1024)).toFixed(2)).replace('.', ',');
}

export const GetPriceDiscount = (ranges_, default_price, quantity_) => {
    var result = default_price;
    if (ranges_ != false) {
        ranges_.forEach(range => {
            if (quantity_ >= range.from && quantity_ <= range.to) {
                result = range.price;
            }
        });
    }

    return result;
}

export const StockCovered = (stock_quantity, stock_reserved, quantity) => {
    // Vráti stav - vykryté, čiastočne vykryté, nevykryté
    var result = global.order_covered.covered;
    var stock_quantity = parseInt(stock_quantity) - parseInt(stock_reserved);
    var order_quantity = parseInt(quantity);
    if (stock_quantity - order_quantity < 0) {
        if (stock_quantity - order_quantity <= 0 - order_quantity) {
            result = global.order_covered.not_covered;
        } else {
            result = global.order_covered.partly;
        }
    } else {
        result = global.order_covered.covered;
    }

    return result;
}

export const StockCoveredChip = (props) => {
    // Vráti stav - CHIP s nápisom
    var status = StockCovered(props.stock_quantity, props.stock_reserved, props.quantity);

    return (
        status == global.order_covered.covered ? null :
            <Chip variant='outlined' size='small' style={{ marginTop: props.marginTop == undefined ? 0 : props.marginTop, color: global.order_covered_color[status], borderColor: global.order_covered_color[status] }} label={props.lang.order_covers[status]}></Chip>
    );
}

export const GetMonthsArray = (props) => {
    // VRATI ARRAY S NAZVAMI MESIACOV
    var status = StockCovered(props.stock_quantity, props.stock_reserved, props.quantity);

    return (
        status == global.order_covered.covered ? null :
            <Chip variant='outlined' size='small' style={{ marginTop: props.marginTop == undefined ? 0 : props.marginTop, color: global.order_covered_color[status], borderColor: global.order_covered_color[status] }} label={props.lang.order_covers[status]}></Chip>
    );
}

export const GetModuleName = (id, lang) => {
    var result = '';
    switch (parseInt(id)) {
        case 0:
            result = lang.organization;
            break;
        case 1:
            result = lang.users;
            break;
        case 2:
            result = lang.app_language;
            break;
        case 3:
            result = lang.currency;
            break;
        case 4:
            result = lang.settings;
            break;
        case 5:
            result = lang.conds;
            break;
        case 6:
            result = lang.permissions;
            break;
        case 7:
            result = lang.user_groups;
            break;
        case 8:
            result = lang.manufacturers;
            break;
        case 9:
            result = lang.exported_data;
            break;
        case 10:
            result = lang.exported_data;
            break;
        case 11:
            result = lang.products_categories;
            break;
        case 12:
            result = lang.products;
            break;
        case 13:
            result = lang.products_groups;
            break;
        case 14:
            result = lang.price_list;
            break;
        case 15:
            result = lang.price_list_groups;
            break;
        case 16:
            result = lang.stocks;
            break;
        case 17:
            result = lang.stock_items;
            break;
        case 18:
            result = lang.stock_adjustment;
            break;
        case 19:
            result = lang.stock_transport;
            break;
        case 20:
            result = lang.customers;
            break;
        case 21:
            result = lang.customers_categories;
            break;
        case 22:
            result = lang.customers_person;
            break;
        case 23:
            result = lang.orders;
            break;
        case 24:
            result = lang.customers_groups;
            break;
        case 25:
            result = lang.flags;
            break;
        case 26:
            result = lang.address;
            break;
        case 27:
            result = lang.stock_cards;
            break;
        case 28:
            result = lang.files_folders;
            break;
        case 29:
            result = lang.file;
            break;
        case 30:
            result = lang.customer_notes;
            break;
        case 31:
            result = lang.order_types;
            break;
        case 32:
            result = lang.distributors;
            break;
        case 33:
            result = lang.stock_removals;
            break;
        case 34:
            result = lang.approvals;
            break;
        case 35:
            result = lang.contacts;
            break;
        case 36:
            result = lang.stock_orders;
            break;
        case 37:
            result = lang.stock_orders_receive;
            break;
        case 38:
            result = lang.reklamacie;
            break;
        case 39:
            result = lang.presentations;
            break;
        case 40:
            result = lang.quizes;
            break;
        case 41:
            result = lang.quiz_questions;
            break;
        case 42:
            result = lang.routes;
            break;
        case 43:
            result = lang.route_items;
            break;
        case 44:
            result = lang.presentation_items;
            break;
        case 45:
            result = lang.calendar;
            break;
        case 46:
            result = lang.codebook;
            break;
        case 47:
            result = lang.pages;
            break;
        case 48:
            result = lang.page_items;
            break;
        case 49:
            result = lang.gallery;
            break;
        case 50:
            result = lang.gallery_items;
            break;
        case 51:
            result = lang.services;
            break;
        case 52:
            result = lang.services_categories;
            break;
    }

    return result;
}

export const OpenAIChat = (history, question, answer, lang, system) => {
    // TVORI HISTORIU CHATU S OPENAI

    var result = [];

    if (history.length == 0) {
        // ----------------------------------------------------------------------
        // ZACIATOK CHATU
        // ----------------------------------------------------------------------
        if (system == true) {
            var data = { role: "system", content: lang.openai_chat_start };
            result.push(data);
        } else {
            var data = { role: "system", content: lang.openai_function_start };
            result.push(data);

        }
        data = { role: "user", content: question };
        result.push(data);

    } else {
        // ----------------------------------------------------------------------
        // UZ JE HISTORIA
        // ----------------------------------------------------------------------

        history.forEach(data => {
            result.push(data);
        });
        if (question.trim() != '') {
            let data = { role: "user", content: question };
            result.push(data);
        }

        if (answer.trim() != '') {
            let data = { role: "assistant", content: answer };
            result.push(data);
        }
    }

    return result;
}

export const GetFunctionID = (function_name) => {
    // VRATI ID Z NAZVU FUNKCIE Z OPENAI

    var result = 0;

    switch (function_name) {
        case 'get_customers':
            result = 300;
            break;
        case 'get_products':
            result = 100;
            break;
        case 'get_warehouse':
            result = 122;
            break;
        case 'stock_transport':
            result = 121;
            break;
        case 'stock_adjust':
            result = 122;
            break;
    }

    return result;
}

export function deg2rad(degrees) {
    var pi = Math.PI;
    return degrees * (pi / 180);
}

export function GetDistance(startLat, startLng, endLat, endLng, route) {
    // VYPOCET VZDIALENOSTI MEDZI GPS BODOM START A END (km)

    let constant = route == true ? 1.18 : 1.00;
    let base_lat = startLat;
    let base_lng = startLng;
    let earth_radius_km = 6371.009;

    let loca_lat = endLat;
    let loca_lng = endLng;

    // Vypocet
    let lat1 = deg2rad(loca_lat);
    let lon1 = deg2rad(loca_lng);
    let lat2 = deg2rad(base_lat);
    let lon2 = deg2rad(base_lng);

    let delta_lat = lat2 - lat1;
    let delta_lng = lon2 - lon1;

    let hav_lat = (Math.sin(delta_lat / 2)) ** 2;
    let hav_lng = (Math.sin(delta_lng / 2)) ** 2;

    let distance = 2 * Math.asin(Math.sqrt(hav_lat + Math.cos(lat1) * Math.cos(lat2) * hav_lng));
    let actual_distance = (earth_radius_km * distance * constant).toFixed(1);

    return (actual_distance);
}

// KONTROLA, CI JE DANY PLUGIN AKTIVNY
export function CheckPlugin(system, plugin_id) {
    var result = false;

    if (system.plugins != false) {
        var tmp = system.plugins.find(x => x.plugin_id == plugin_id);
        if (tmp != undefined) {
            result = tmp.enabled == false ? false : true;
        }
    }
    return result;
}

// Kontrola ci je medzera #minutes minút medzi dátumom 1 a dátumom 2
export function GetMinutesBetweenDates(date1_, date2_, minutes) {
    var date1 = new Date(date1_);
    var date2 = new Date(date2_);
    const time1 = date1.getTime();
    const time2 = date2.getTime();

    const differenceInMillis = Math.abs(time1 - time2);
    const differenceInMinutes = differenceInMillis / (1000 * 60);

    return differenceInMinutes > minutes;
}

export function GetCalendarGroups(lang) {
    const calendarGroups = [
        { id: 1, name: lang.calendar_groups[1], short: lang.calendar_groups[1][0] },
        { id: 2, name: lang.calendar_groups[2], short: lang.calendar_groups[2][0] },
        { id: 3, name: lang.calendar_groups[3], short: lang.calendar_groups[3][0] },
        { id: 0, name: lang.calendar_groups[0], short: '' },
    ];

    return calendarGroups;
}