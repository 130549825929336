/*
*
* =================================================================================================================
* PROFIL UŽÍVATELA

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <MyProfile organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Avatar, FormError, FormLabel, Icon, FormText, FormYesNo, Line, Loading, ShowError, ShowOK, FormSpace, FormNote, FormSelect, DialogYesNo, DialogEnterPin, DialogInfo, FormButton, DialogPasswordChange } from './items';
import { styles } from './styles';
import { Button, IconButton, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faExclamationTriangle, faInfoCircle, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetTodayYear, Today } from './functions_date';
import { Debug, IsEmpty } from './functions';
import { Photos } from './photos';
import { Help } from './Help';

export const MyProfile = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let permissions = global.permissions;
    let color = global.themes[props.theme];

    // IMAGES
    const user_default = require('./react/app/user.png');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [storeData, setStoreData] = useState(false);

    const [id, setID] = useState(props.user.id);

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [alias, setAlias] = useState('');
    const [phone, setPhone] = useState('');
    const [mobil, setMobil] = useState('');
    const [email, setEmail] = useState('');
    const [birthdate, setBirthdate] = useState(Today());
    const [street, setStreet] = useState('');
    const [town, setTown] = useState('');
    const [psc, setPSC] = useState('');
    const [state, setState] = useState('');
    const [roleID, setRoleID] = useState(0);
    const [permissionsID, setPermissionsID] = useState(0);
    const [themeID, setThemeID] = useState(0);
    const [avatar, setAvatar] = useState('');
    const [languageID, setLanguageID] = useState(0);
    const [smsVerify, setSmsVerify] = useState(false);
    const [mobilVerified, setMobilVerified] = useState(false);

    const [languages, setLanguages] = useState([]);
    const [pinCode, setPinCode] = useState('');
    const [code, setCode] = useState('');

    // DIALOGS
    const [showVerify, setShowVerify] = useState(false);
    const [showEnterPin, setShowEnterPin] = useState(false);
    const [showPinError, setShowPinError] = useState(false);
    const [showSelectPhoto, setSelectPhoto] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const column1 = 200;
    const column2 = 350;
    const offset = 20;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get();
            db_get_data();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ------------------------------------
                    id: props.user.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                let item = json.user;
                setStoreData(item);
                setAvatar(item.avatar);

                if (item != false) {
                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setLanguages(json.languages);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    organization_id: props.organization.id,
                    user_id: props.user.id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
                setEditing(false);
                if (data.sms_verify != undefined) {
                    setSmsVerify(data.sms_verify);
                }
                if (data.mobil_verified != undefined) {
                    setMobilVerified(data.mobil_verified);
                }

                if (data.sms_verify == true && data.mobil_verified == false) {
                    VerifySmsVerify();
                }
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_send_pin = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'send_sms_pin', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    organization_id: props.organization.id,
                    user_id: props.user.id,
                    mobil: mobil,
                    language_id: languageID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.pin != '') {
                    setPinCode(json.pin);
                    setShowEnterPin(true);
                } else {
                    // nepodarilo sa odoslat PIN - nie je kredit, ...
                    setPinCode('');
                    Cancel2FA();
                    setShowPinError(true);
                }
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setName(item.name);
        setSurname(item.surname);
        setAlias(item.alias);
        setPhone(item.phone);
        setMobil(item.mobil);
        setEmail(item.email);
        setBirthdate(item.birthdate);
        setStreet(item.street);
        setTown(item.town);
        setPSC(item.psc);
        setState(item.state);
        setThemeID(item.theme_id);
        setAvatar(item.avatar);
        setLanguageID(item.language_id);
        setSmsVerify(item.sms_verify == false ? false : true);
        setMobilVerified(item.mobil_verified == false ? false : true);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.name = name;
        storeData.surname = surname;
        storeData.alias = alias;
        storeData.email = email;
        storeData.phone = phone;
        storeData.mobil = mobil;
        storeData.birthdate = birthdate;
        storeData.street = street;
        storeData.town = town;
        storeData.psc = psc;
        storeData.state = state;
        storeData.theme_id = themeID;
        storeData.avatar = avatar;
        storeData.language_id = languageID;
        storeData.sms_verify = smsVerify;
        storeData.mobil_verified = mobilVerified;
    }

    const GetData = () => {
        // získanie údajov do reťazca
        let data = {
            id: id,
            language_id: languageID,
            name: name.trim(),
            surname: surname.trim(),
            alias: alias.trim(),
            email: email.trim(),
            phone: phone.trim(),
            mobil: mobil.trim(),
            birthdate: birthdate == Today() ? null : birthdate,
            street: street,
            town: town,
            psc: psc,
            state: state,
            theme_id: themeID,
            avatar: avatar,
            sms_verify: mobil.trim() == '' ? false : smsVerify,
            mobil_verified: mobil != storeData.mobil ? false : mobil.trim() == '' ? false : mobilVerified
        }

        if (props.system.sms_notify == false) {
            data.sms_verify = false;
            data.mobil_verified = false;
        }

        return data;
    }

    const Save = () => {
        setError('');

        // údaje sú OK - uložiť
        let data = GetData();

        StoreEditing();
        db_update(data);

    }

    const FormLabelResult = (value) => {
        if (value == 0) {
            setEditing(true);
        }
    }

    const VerifySmsVerify = () => {
        // OVERENIE MOBILU
        var timer = setTimeout(() => {
            setShowVerify(true);
        }, 1500);
    }

    const Cancel2FA = () => {
        let data = {
            id: id,
            sms_verify: false,
        }
        setSmsVerify(false);
        db_update(data);
    }

    const VerifyResult = (typ) => {
        setShowVerify(false);

        if (typ == false) {
            // Zrusenie 2FA
            Cancel2FA();

        } else {
            // OVERIT MOBIL
            db_send_pin();
        }
    }

    const PinResult = (typ, pin) => {
        setShowEnterPin(false);

        if (typ == true) {
            // KOD OK
            let data = {
                id: id,
                mobil_verified: true,
            }
            setMobilVerified(true);
            db_update(data);
        } else {
            // PO TROCH POKUSOCH - NESPRAVNY KOD - VYPNUTE OKNO

            // Zrusenie 2FA
            let data = {
                id: id,
                sms_verify: false,
            }
            setSmsVerify(false);
            db_update(data);
        }
    }

    const AvatarPress = (value) => {
        if (value == true) {
            setSelectPhoto(true);
        } else {
            setAvatar('');
        }
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);

            let data = {
                id: id,
                avatar: path + file
            }
            db_update(data);
        }
    }

    const ChangePassword = (value) => {
        if (value == true) {
            setShowChangePassword(true);
        }
    }

    const PasswordResult = (typ, value) => {
        setShowChangePassword(false);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faUser} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Help link={lang.help_settings_my_profile} lang={props.lang} theme={props.theme} />
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, paddingBottom: global.list_padding, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                    {storeData != false ?
                        <Paper elevation={global.elevation_form} style={{ ...styles.BlockCenter, width: column1 + column2 + (2 * offset), marginTop: 20, paddingTop: offset, paddingBottom: offset * 2, backgroundColor: color.white, borderRadius: props.radius }}>
                            <Avatar editable={editing} image={avatar} lang={props.lang} theme={props.theme} func={AvatarPress.bind()} />
                            <FormSpace height={30} />

                            <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.user_profile_info} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />

                            {/* INFORMACIE */}
                            <FormText require value={name} editing={editing} title={lang.name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                            <FormText require value={surname} editing={editing} title={lang.surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSurname(txt)} />
                            <FormText value={alias} editing={editing} title={lang.alias} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setAlias(txt)} />
                            <FormSpace />
                            <FormText require value={email} editing={editing} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />
                            <FormText phone value={mobil} editing={editing} title={lang.mobil} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setMobil(txt)} />
                            {editing == false && mobilVerified == true ?
                                <FormNote title={lang.user_profile_mobil_verified} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                : null}
                            {editing == true ?
                                <FormNote title={lang.mobil_international} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                : null}
                            <FormText value={phone} editing={editing} title={lang.phone} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPhone(txt)} />
                            <FormSpace />
                            <FormText date value={birthdate} editing={editing} title={lang.birtdate} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setBirthdate(txt)} />

                            {/* ADRESA */}
                            <FormLabel editing={editing} title={lang.address} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                            <FormText value={street} editing={editing} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setStreet(txt)} />
                            <FormText value={town} editing={editing} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setTown(txt)} />
                            <FormText value={psc} editing={editing} title={lang.psc} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPSC(txt)} />
                            <FormText value={state} editing={editing} title={lang.state} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setState(txt)} />

                            {/* JAZYK APLIKACIE */}
                            <FormLabel editing={editing} title={lang.app_language} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                            <FormSelect require value={languageID} items={languages} field={'state'} editing={editing} title={lang.app_language} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setLanguageID(id)} />

                            {props.system.sms_enabled == true ? props.system.sms_notify == true ?
                                <div>
                                    {/* DVOJFAKTOROVE OVERENIE */}
                                    <FormLabel editing={editing} title={lang.user_profile_login} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                    <Line theme={props.theme} />
                                    <FormYesNo value={smsVerify} editing={editing} title={lang.user_profile_2fa} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSmsVerify(txt)} />
                                    {editing == true ?
                                        <FormNote title={lang.user_profile_2fa_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                        : null}
                                </div>
                                : null : null}

                            {/* ZMENA PRIHLASOVACIE HESLA */}
                            <FormLabel editing={editing} title={lang.password_login} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                            <FormButton value={'********'} editing={editing} title={lang.password} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={ChangePassword.bind(this)} />
                            <FormNote title={lang.password_change_login} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />


                            {/* SAVE - CANCEL */}
                            {editing == true ?
                                <div style={{ ...styles.Block, paddingTop: 10 }}>
                                    <FormError theme={props.theme} error={error} small />

                                    <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </Paper>
                        : null}

                    {props.remote == true && props.remoteMobile == true ?
                        <FormSpace height={200} />

                        : null}

                </div>
            </div>

            {showSelectPhoto == true ?
                <Photos autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                : null}

            {showChangePassword == true ?
                <DialogPasswordChange radius={props.radius} title={'nazov'} sub_title={'podnázov'} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} token={props.token} func={PasswordResult.bind(this)} />
                : null}

            {showPinError == true ?
                <DialogInfo title={lang.error} text={lang.sms_error_text1} sub_text={lang.sms_error_text2} button={lang.close} icon={faExclamationTriangle} icon_color={color.red} theme={props.theme} radius={props.radius} func={() => setShowPinError(false)} />
                : null}

            {showEnterPin == true ?
                <DialogEnterPin title={lang.sms_code} label={lang.sms_code_sended} pin={code} verify_pin={pinCode} button={lang.continue} lang={lang} theme={props.theme} radius={props.radius} func={PinResult.bind(this)} />
                : null}

            {showVerify == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.user_profile_2fa} text={lang.user_profile_mobil_text} sub_text={lang.user_profile_mobil_number.replace('@mobil', mobil)} warning_text={''} icon={faExclamation} yes={lang.user_profile_mobil_number_send} buttonWidth={200} no={lang.user_profile_mobil_number_cancel} yes_color={color.dark_red} no_color={color.button_dark} func={VerifyResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


