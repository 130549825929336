/*
*
* =================================================================================================================
* OBJEDNAVKY
* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { DialogTabs, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, ShowError, ShowOK, TextInput, Logs, Avatar, Line, MenuButton, SelectInput, DialogExport, SearchButton, PlusMinus, DeleteButton, FormSelect, DialogEnter, FormNote, ButtonChangeStyle, ContactCompany, ProductSearch, ShowInfo, DialogInfo, TextLine } from './items';
import { styles } from './styles';
import { Backdrop, Box, Button, Checkbox, Chip, Hidden, IconButton, ListItemIcon, ListItemText, MenuItem, Pagination, Paper, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowPointer, faArrowUp, faBan, faEye, faInfo, faInfoCircle, faLayerGroup, faPaperPlane, faPercent, faPercentage, faPlus, faPlusCircle, faQuestion, faRotate, faRotateLeft, faSave, faSearch, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, CoeficientToPercentage, ConvertToPrice, ConvertToPrice2, Debug, FormatMoney, FormatPieces, GetLeftPosition, GetMoney, GetPages, GetPriceDiscount, IsEmpty, LoadArray, LoadBool, LoadValue, RoundValue, SaveArray, SaveBool, SaveValue, StockCoveredChip } from './functions';
import { GetDatum2, GetDatumTime2 } from './functions_date.js';
import {
    DataGrid, skSK,
    GridColumnMenu,
} from '@mui/x-data-grid';
import { ProductSelect } from './product_select.js';
import { CustomerSelect } from './customers.js';
import { Notes } from './notes.js';
import { UsersSelect } from './users_select.js';
import { Reklamacia } from './reklamacie.js';
import { ServiceSelect } from './services.js';

export const Orders = (props) => {

    let module_id = global.model.orders;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'orders';
    let currency = props.locale.currency;
    let max_width = props.width > 1100 ? 1100 : props.width;
    let orders_limited = props.permissions.orders_limited == true && props.user.super_admin == false ? true : false;

    let defaultFilter = {
        ordering: global.order.up,
        order_column: 'created',
        customer_id: props.customerID == undefined ? 0 : props.customerID,
        country_id: props.locale.id,
        agent_id: orders_limited == true ? props.user.id : undefined,
        status: props.customerID == undefined || props.customerID == 0 ? 1 : 0
    }

    const [precisions, setPrecisions] = useState(props.system.precisions == 0 ? 2 : 3);
    const [maxWidth, setMaxWidth] = useState(max_width);

    var columns = [
        {
            field: 'number',
            headerName: lang.order_number,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 160,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: false,
            hide: false,
        },
        {
            field: 'company',
            headerName: lang.company_name,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 250,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: true,
            hide: false,
        },
        {
            field: 'surname',
            headerName: lang.surname,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 180,
            editable: false,
            sortable: false,
            sorting: true,
            hide: false,
        },
        {
            field: 'name',
            headerName: lang.name,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            //renderCell: (params) => (params.row.parent_id == 0 ? params.row.name : params.row.parent_name + ' - ' + params.row.name),
            type: 'string',
            align: 'left',
            width: 180,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'products',
            headerName: lang.order_products,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'number',
            renderCell: (params) => parseInt(params.row.products),
            align: 'center',
            width: 140,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
        {
            field: 'cost',
            headerName: lang.order_sum_cost,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'number',
            renderCell: (params) => ConvertToPrice2(params.row.cost, precisions),
            align: 'right',
            width: 160,
            editable: false,
            sortable: false,
            sorting: false,
            hide: true,
            hideable: true,
        },
        {
            field: 'price',
            headerName: lang.order_sum_price,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'number',
            renderCell: (params) => ConvertToPrice2(params.row.price, precisions),
            align: 'right',
            width: 160,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
        {
            field: 'status',
            headerName: lang.status,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            //type: 'number',
            renderCell: (params) => lang.order_status[params.row.status],
            align: 'center',
            width: 140,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
        {
            field: 'distributor',
            headerName: lang.distributor,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            //type: 'number',
            //renderCell: (params) => lang.order_status[params.row.status],
            align: 'left',
            width: 200,
            editable: false,
            sortable: false,
            sorting: false,
            hide: props.system.distributors == true ? false : true,
            hideable: true,
        },
        {
            field: 'note',
            headerName: lang.note,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            //renderCell: (params) => (params.row.parent_id == 0 ? params.row.name : params.row.parent_name + ' - ' + params.row.name),
            type: 'string',
            align: 'left',
            width: 300,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
    ];

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    var itemsExportMenu =
        [
            { id: 1, checked: false, enabled: true, label: lang.export_selected },
            { id: 2, checked: false, enabled: true, label: lang.export_all },
            { id: 30, divider: true },
            { id: 5, checked: LoadBool('orders-mode', true), enabled: true, label: lang.preview_mode },
            { id: 31, divider: true },
            { id: 3, checked: false, enabled: true, icon: faRotateLeft, label: lang.refresh }

        ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    const orderStates = [
        { id: 0, name: lang.orders_all },
        { id: 1, name: lang.orders_ready },
        { id: 2, name: lang.orders_finished },
    ]
    const [orderStateID, setOrderStateID] = useState(props.customerID == undefined || props.customerID == 0 ? 1 : 0);

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);

    const [displayStyle, setDisplayStyle] = useState(LoadValue('orders-style', 0));
    const [previewMode, setPreviewMode] = useState(LoadBool('orders-mode', true));
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(LoadArray('orders-table', {}));

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.name, default: true },
        { id: 2, enabled: true, checked: false, column: 'town', label: lang.town },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showOrderNew, setShowOrderNew] = useState(false);
    const [showCustomers, setShowCustomers] = useState(false);
    const [customerID, setCustomerID] = useState(0);
    const [customer, setCustomer] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [showReklamacia, setShowReklamacia] = useState(false);
    const [userID, setUserID] = useState(0);
    const [user, setUser] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 96;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const widthOffset = 20;
    const chipHeight = 50;
    const previewHeight = 112;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);


    const db_get = async (page, filter, maxItems) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'orders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.orders,
                    filename: lang.orders,
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const GetFilter = () => {
        var filter = defaultFilter;

        if (search != '') {
            filter.search = search;
        }

        if (orderStateID > 0) {
            filter.status = orderStateID;
        }

        if (customerID > 0) {
            filter.customer_id = customerID;
        }
        if (userID > 0) {
            filter.agent_id = userID;
            filter.limited = true;
        }
        return filter;
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            filter.search = txt;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowOrderNew(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == 2) {
            var timer = setTimeout(() => {
                setShowReklamacia(true);
            }, 250);
        }
        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const MenuResult = (id, index, item) => {
        // EXPORT MENU       
        if (index == 5) {
            // PREVIEW MODE
            var tmp = item;
            tmp.checked = previewMode == true ? false : true;
            setPreviewMode(!previewMode);
            SaveBool('orders-mode', !previewMode);
        }
        if (index == 3) {
            // RELOAD
            db_get(page, filter, maxItems);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // DATUM
                column = 'created';
                break;

            case 2: // MENO PRIEZVISKO
                column = 'surname_name';
                break;

            case 4: // STATUS
                column = 'status';
                break;

        }

        var filter_ = GetFilter();
        filter_.ordering = order;
        filter_.order_column = column;

        db_get(0, filter_, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SortUp = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.up;
        filter.order_column = data.colDef.field;
        db_get(0, filter, maxItems);
    }

    const SortDown = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.down;
        filter.order_column = data.colDef.field;
        db_get(0, filter, maxItems);
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const ChangeStyle = (value) => {
        setItems(false);
        setItemsFiltered(false);
        setItemSelected(false);
        setDisplayStyle(value);

        if (value == 0) {
            setMaxWidth(max_width);
        } else {
            setMaxWidth(props.width);
        }
        SaveValue('orders-style', value);
        db_get(0, filter, maxItems);
    }

    function MyMenuItem(props) {
        const { menuHandler, menuLabel, menuIcon } = props;
        return (
            <MenuItem onClick={menuHandler}>
                <ListItemIcon>
                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={menuIcon} />
                </ListItemIcon>
                <ListItemText>{menuLabel}</ListItemText>
            </MenuItem>
        );
    }

    function CustomColumnMenu(props) {
        // VLASTNÁ COLUMN MENU
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuSortItem: null,
                    columnMenuFilterItem: null,
                    columnMenuUserItem1: props.colDef.sorting == true ? MyMenuItem : null,
                    columnMenuUserItem2: props.colDef.sorting == true ? MyMenuItem : null,
                }
                }
                slotProps={{
                    columnMenuUserItem1: {
                        displayOrder: 1,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowUp,
                        menuHandler: () => SortUp(props),
                    },
                    columnMenuUserItem2: {
                        displayOrder: 0,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowDown,
                        menuHandler: () => SortDown(props),
                    },

                }}
            />
        );
    }

    const ChangeColumns = (data) => {
        // ZOBRAZIT / SKRYT STLPCE -> uloženie do cookies
        setColumnVisibilityModel(data);
        SaveArray('orders-table', data);
    }

    const OrderNewResult = (typ, redraw_, order) => {
        setShowOrderNew(false);
        if (redraw_ == true) {
            db_get(page, filter, maxItems);

            setItemSelected(order);
            setShowDialog(true);
        }
    }

    const ChangeState = (id) => {
        setOrderStateID(id);
        var filter = GetFilter();
        if (id > 0) {
            filter.status = id;
        } else {
            delete (filter.status);
        }

        db_get(0, filter, maxItems);
    }

    const CustomersResult = (typ, items, selected) => {
        setShowCustomers(false);

        if (typ == true && items != false) {
            setCustomerID(items[0]);
            setCustomer(selected[0]);

            var filter = GetFilter();
            filter.customer_id = items[0];

            db_get(0, filter, maxItems);
        }
    }

    const UserResult = (typ, selected) => {
        setShowUsers(false);

        if (typ == true && selected != false) {
            setUserID(selected.id);
            setUser(selected);

            var filter = GetFilter();
            filter.agent_id = selected.id;
            filter.limited = true;

            db_get(0, filter, maxItems);
        }
    }

    const UserOnly = () => {
        setUserID(props.user.id);
        setUser(props.user);

        var filter = GetFilter();
        filter.agent_id = props.user.id;
        filter.limited = true;

        db_get(0, filter, maxItems);
    }

    const CustomerReset = () => {
        setCustomerID(0);

        var filter = GetFilter();
        delete (filter.customer_id);
        setFilter(filter);

        db_get(0, filter, maxItems);
    }

    const UserReset = () => {
        setUserID(0);

        var filter = GetFilter();
        delete (filter.agent_id);
        delete (filter.limited);
        setFilter(filter);

        db_get(0, filter, maxItems);
    }

    return (
        <div id={'id_start'} style={{ ...styles.BlockCenter, height: props.height }}>
            <div style={{ ...styles.BlockRow, width: maxWidth, height: global.widow_bar_height }}>
                <div style={{ ...styles.Block, width: maxWidth - 50 - 50 - 60, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    {/*
                    <p style={{ ...styles.TextDialogLabel, marginLeft: 10 }}>{props.title}</p>
                    */}
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.widow_bar_height }}>
                    <ButtonChangeStyle value={displayStyle} theme={props.theme} func={(id) => ChangeStyle(id)} />
                </div>

                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    {global.appstore == false ?
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                        : null}
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div id='id_orders_start'></div>

                    <div style={{ ...styles.Block, marginTop: 10 }}>
                        <div style={{ ...styles.Block, width: maxWidth - widthOffset }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: 240, height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <TextInput search theme={props.theme} width={200} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ ...styles.Block, width: 230, height: menuHeight }}>
                                    <SelectInput redraw theme={props.theme} enabled={true} lang={lang} value={orderStateID} items={orderStates} width={200} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => ChangeState(id)} />
                                </div>
                                {props.customerID == 0 || props.customerID == undefined ?
                                    <div style={{ ...styles.Block, width: 170, height: menuHeight }}>
                                        <Button onClick={() => setShowCustomers(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                            <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                            {lang.customer}
                                        </Button>
                                    </div>
                                    : null}

                                <div style={{ ...styles.Block, width: 170, height: menuHeight }}>
                                    {props.customerID == 0 || props.customerID == undefined ?
                                        <Button onClick={() => setShowUsers(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                            <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                            {lang.customer_agent}
                                        </Button>
                                        : null}
                                </div>

                                <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                    {props.customerID == 0 || props.customerID == undefined ?
                                        <IconButton onClick={() => UserOnly(true)} style={{ ...styles.ButtonUser }}>
                                            <FontAwesomeIcon style={{ height: 14 }} icon={faUser} />
                                        </IconButton>
                                        : null}
                                </div>

                                <div style={{ ...styles.BlockRight, width: props.customerID == 0 || props.customerID == undefined ? '25%' : '35%', height: menuHeight }}>
                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.order_new}
                                        </Button>
                                        : null}
                                </div>
                            </div>
                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {customerID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{customer != false ? customer.company != '' ? customer.company : customer.name + ' ' + customer.surname : ''}</p>} onDelete={() => CustomerReset()}></Chip>
                                        : null}
                                    {userID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.customers_of_rep}: {user.name + ' ' + user.surname}</p>} onDelete={() => UserReset()}></Chip>
                                        : null}
                                </div>
                            </div>
                            {displayStyle == 0 ?
                                <div style={{ ...styles.Block, marginTop: 20 }}>

                                    {/* ****************************************************************************************************************
                                        TABLE HEADER
                                    **************************************************************************************************************** */}

                                    <div style={{ ...styles.Block, marginBottom: 10, border: color.border }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: 100, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.date}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.order_number}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: props.system.distributors == true ? maxWidth - 50 - 100 - 150 - 150 - 150 - 150 - widthOffset : maxWidth - 50 - 100 - 150 - 150 - 150 - widthOffset, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.customer}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    {/*
                                                <MenuButton id={3} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                */}
                                                </div>
                                            </div>
                                            {props.system.distributors == true ?
                                                <div style={{ ...styles.Block, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.distributor}</p>
                                                </div>
                                                : null}
                                            <div style={{ ...styles.BlockRow, width: 140, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.order_sum_price_}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    {/*
                                                    <MenuButton id={4} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                    */}
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: 160, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.status}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <MenuButton id={4} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ****************************************************************************************************************
                                        ZOZNAM OBJEDNAVOK
                                    **************************************************************************************************************** */}

                                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block,
                                                backgroundColor: item.enabled == false ? color.disabled : item.status == global.orders.create_error || item.status == global.orders.sending_error ? color.lighteen_red : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.status < global.orders.finished ? '4px solid ' + global.order_colors[item.status] : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '99%' }}>
                                                <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                    {/* SELECT */}
                                                    <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                                </div>

                                                {/* DATA */}
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny }}>{GetDatum2(item.created)}</p>
                                                </div>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny }}>{item.number}</p>
                                                </div>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: props.system.distributors == true ? maxWidth - 50 - 100 - 150 - 150 - 150 - 150 - widthOffset : maxWidth - 50 - 100 - 150 - 150 - 150 - widthOffset, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <ContactCompany company={item.company} name={item.name} surname={item.surname} theme={props.theme} />                                                    {item.agent != '' ?
                                                        <Chip variant='outlined' size='small' style={{ marginTop: 5 }} label={item.agent} />
                                                        : null}
                                                </div>
                                                {props.system.distributors == true ?
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny }}>{item.distributor}</p>
                                                    </div>
                                                    : null}
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRight, width: 140, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny }}><b>{FormatMoney(ConvertToPrice(item.price, precisions), precisions)} {item.currency}</b></p>
                                                    {props.system.show_prices_dph == true ?
                                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{lang.with_dph}: {FormatMoney(ConvertToPrice(item.price_dph, precisions), precisions)} {item.currency}</p>
                                                        : null}
                                                    {parseFloat(item.discount_coeficient) == 1 ?
                                                        null
                                                        :
                                                        <p style={{ ...styles.TextTiny, marginTop: 8 }}>{lang.discount} {CoeficientToPercentage(item.discount_coeficient)}%</p>
                                                    }
                                                </div>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 160, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {/* STATUS */}
                                                    {item.products == 0 && item.status != global.orders.canceled ?
                                                        <Chip size='small' label={lang.order_empty} style={{ backgroundColor: global.order_colors[6], color: color.white, fontSize: global.font_tiny }}></Chip>
                                                        :
                                                        <Chip label={lang.order_status[item.status]} variant='outlined' size='small'
                                                            sx={{
                                                                height: 'auto',
                                                                '& .MuiChip-label': {
                                                                    display: 'block',
                                                                    whiteSpace: 'normal',
                                                                },
                                                            }}
                                                            style={{ maxWidth: 120, padding: 2, borderColor: global.order_colors[item.status], color: global.order_colors[item.status], fontSize: global.font_tiny }}></Chip>
                                                    }
                                                    {parseInt(item.reklamacie) > 0 ?
                                                        <Chip label={lang.reklamacia} variant='outlined' size='small' style={{ marginTop: 10, borderColor: color.error, color: color.error, fontSize: global.font_tiny }}></Chip>
                                                        : null}
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <div style={{ ...styles.Block, paddingTop: 20 }}>
                                            <EmptyList row small lang={lang} />
                                        </div>
                                    }

                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                    **************************************************************************************************************** */}

                                    <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            {itemsFiltered != false ?
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                        <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                        <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}


                            {displayStyle == 1 ?
                                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - chipHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <Box sx={{
                                        height: previewMode == true ? props.height - global.widow_bar_height - menuHeight - chipHeight - 84 - previewHeight : props.height - global.widow_bar_height - menuHeight - chipHeight - 84,
                                        width: 1,
                                        /*
                                        '& .super-app-theme--header': {
                                            backgroundColor: color.light_gray,
                                        },
                                        */
                                        '& .super-app-theme--normal': {
                                            backgroundColor: color.white,
                                            color: '#000000',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.table_row),
                                            },
                                        },
                                        '& .super-app-theme--red': {
                                            backgroundColor: color.lighteen_blue,
                                            color: color.dark_red,
                                            fontWeight: 'normal',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.gray),
                                            },
                                        },
                                        '& .super-app-theme--blue': {
                                            backgroundColor: color.white,
                                            color: color.blue,
                                            fontWeight: 'normal',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.gray),
                                            },
                                        },
                                    }}>
                                        <DataGrid

                                            sx={{
                                                "& .MuiDataGrid-columnHeaderTitle": {
                                                    whiteSpace: "normal",
                                                    lineHeight: "normal"
                                                },
                                                "& .MuiDataGrid-columnHeader": {
                                                    height: "unset !important"
                                                },
                                                "& .MuiDataGrid-columnHeaders": {
                                                    maxHeight: "168px !important"
                                                },
                                                fontSize: global.font_table, border: global.theme_table_border_width, borderColor: color.dark_gray
                                            }}

                                            rows={itemsFiltered == false ? [] : itemsFiltered}
                                            columns={columns.filter(x => x.hide == false)}
                                            slots={{ columnMenu: CustomColumnMenu }}
                                            rowHeight={previewMode == true ? 30 : undefined}

                                            checkboxSelection
                                            disableRowSelectionOnClick
                                            onRowSelectionModelChange={(newRowSelectionModel) => { setItemsSelected(newRowSelectionModel) }}
                                            onRowClick={(params) => { setItemSelected(params.row) }}
                                            onRowDoubleClick={(params) => { SelectItem(params.row) }}
                                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                            getRowClassName={(params) => (params.row.enabled == 1 ? `super-app-theme--normal` : `super-app-theme--red`)}
                                            hideFooterRowCount={true}
                                            hideFooterSelectedRowCount={true}
                                            hideFooterPagination
                                            hideFooter
                                            columnVisibilityModel={columnVisibilityModel}
                                            onColumnVisibilityModelChange={(newModel) =>
                                                ChangeColumns(newModel)
                                            }
                                        />
                                    </Box>

                                    {previewMode == true && itemSelected != false ?
                                        <div style={{ ...styles.Block, height: previewHeight, marginTop: 5 }}>
                                            <div style={{ ...styles.BlockRow, width: '100%' }}>
                                                <div style={{ ...styles.BlockLeft, width: previewHeight, height: previewHeight, justifyContent: 'center' }}>
                                                    <Avatar picture image={itemSelected.image} size={previewHeight - 10} theme={props.theme} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '40%', height: previewHeight, justifyContent: 'center' }}>
                                                    {itemSelected.company != '' ?
                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{itemSelected.company}</p>
                                                        : null}
                                                    <p style={{ ...styles.TextTiny, fontWeight: itemSelected.company != '' ? '400' : '600' }}>{itemSelected.surname} {itemSelected.name}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 8 }}>{lang.order_sum_price}: {FormatMoney(ConvertToPrice(itemSelected.price, precisions), precisions)}</p>
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        {itemSelected.category != '' ?
                                                            <Chip
                                                                style={{ marginTop: 2, marginBottom: 2 }}
                                                                size={'small'}
                                                                label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_gray }}>{lang.order_status[itemSelected.status]}</p>} variant="outlined"
                                                            />
                                                            : null}
                                                        {itemSelected.distributor != '' && props.system.distributors == true ?
                                                            <Chip
                                                                style={{ marginTop: 2, marginBottom: 2, marginLeft: 10 }}
                                                                size={'small'}
                                                                label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_gray }}>{lang.distributor}: {itemSelected.distributor}</p>} variant="outlined"
                                                            />
                                                            : null}
                                                    </div>

                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '40%', height: previewHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    {itemSelected.phone != '' ?
                                                        <p style={{ ...styles.TextXSmall, marginLeft: 10 }}>{lang.phone}: {itemSelected.phone}</p>
                                                        : null}
                                                    {itemSelected.mobil != '' ?
                                                        <p style={{ ...styles.TextXSmall, marginLeft: 10 }}>{lang.mobil}: {itemSelected.mobil}</p>
                                                        : null}
                                                    {itemSelected.email != '' ?
                                                        <p style={{ ...styles.TextXSmall, marginLeft: 10 }}>{lang.email}: {itemSelected.email}</p>
                                                        : null}
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                        **************************************************************************************************************** */}
                                    <div style={{ ...styles.BlockRow, paddingTop: 10, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            {itemsFiltered != false ?
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                        <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                        <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>

                                </div>
                                : null}


                        </div>
                    </div>
                </div>
            </div>

            {showReklamacia == true ?
                <Reklamacia remote={props.remote} remoteMobile={props.remoteMobile} module_id={global.model.reklamacie} item={false} order={itemSelected} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={() => setShowReklamacia(false)} />
                : null}

            {showUsers == true ?
                <UsersSelect remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.stock_transport_approve_choose} token={props.token} func={UserResult.bind()} />
                : null}

            {showCustomers == true ?
                <CustomerSelect remote={props.remote} remoteMobile={props.remoteMobile} single showVariations={true} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomersResult.bind()} />
                : null}

            {showOrderNew == true ?
                <OrderNew remote={props.remote} remoteMobile={props.remoteMobile} customerID={props.customerID == undefined ? 0 : props.customerID} item={itemSelected} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} settings={props.settings} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={OrderNewResult.bind()} />
                : null}

            {showExport == true ?
                <DialogExport remote={props.remote} remoteMobile={props.remoteMobile} rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <Order remote={props.remote} remoteMobile={props.remoteMobile} module_id={module_id} item={itemSelected} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </div >
    );
}



/*
*
* =================================================================================================================
* UPRAVA ZAZNAMU
* =================================================================================================================
*
*/
export const Order = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let stock_default = props.locale.stock_default;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = { enabled: true, all_records: true };

    let status_menu = [
        { id: global.orders.created, label: lang.order_status[global.orders.created] },
        { id: global.orders.in_proccess, label: lang.order_status[global.orders.in_proccess] },
        //{ id: global.orders.finished, label: lang.order_status[global.orders.finished] }
    ];


    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [redraw, setRedraw] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [editing, setEditing] = useState(props.item == false ? true : false);
    const [editEnabled, setEditEnabled] = useState(props.item == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [order, setOrder] = useState(false);
    const [orderEdit, setOrderEdit] = useState(false);
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [excluded, setExcluded] = useState([]);
    const [servicesExcluded, setServicesExcluded] = useState([]);
    const [status, setStatus] = useState(1);
    const [sum, setSum] = useState(0);
    const [sumDPH, setSumDPH] = useState(0);
    const [cost, setCost] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [maxItems, setMaxItems] = useState(global.items_max);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);

    // OBJEDNAVKA
    const [currency, setCurrency] = useState('');
    const [stocks, setStocks] = useState(false);
    const [stock, setStock] = useState('');
    const [stockID, setStockID] = useState(stock_default);
    const [stockFromID, setStockFromID] = useState(0);
    const [discount, setDiscount] = useState(1);
    const [number, setNumber] = useState('');
    const [priceListID, setPriceListID] = useState(0);
    const [distributorPriceListID, setDistributorPriceListID] = useState(0);
    const [priceListName, setPriceListName] = useState('');
    const [priceLists, setPriceLists] = useState(false);
    const [orderTypes, setOrderTypes] = useState(false);
    const [orderTypeIDS, setOrderTypesIDS] = useState(false);
    const [orderTypeDefault, setOrderTypeDefault] = useState(false);
    const [distributors, setDistributors] = useState(false);
    const [distributor, setDistributor] = useState('');
    const [distributorID, setDistributorID] = useState(-1);
    const [type, setType] = useState(props.system.distributors == false ? 0 : true);

    // ZAKAZNIK
    const [enabled, setEnabled] = useState(true);
    const [customerID, setCustomerID] = useState(0);
    const [company, setCompany] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [street, setStreet] = useState('');
    const [town, setTown] = useState('');
    const [address, setAddress] = useState(false);
    const [psc, setPSC] = useState('');
    const [state, setState] = useState('');
    const [phone, setPhone] = useState('');
    const [mobil, setMobil] = useState('');
    const [email, setEmail] = useState('');
    const [ico, setICO] = useState('');
    const [dic, setDIC] = useState('');
    const [icDPH, setICDPH] = useState('');
    const [dph, setDPH] = useState(false);
    const [note, setNote] = useState('');
    const [avatar, setAvatar] = useState('');
    const [shop, setShop] = useState(false);
    const [countryID, setCountryID] = useState(props.locale.id);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DOPLNIT PRODUKT / SLUZBU
    const [selectProducts, setSelectProducts] = useState(false);
    const [selectServices, setSelectServices] = useState(false);

    // VYMAZANIE OBJEDNAVKY - ZRUSENIE
    const [showDelete, setShowDelete] = useState(false);

    // ZADANIE ZLAVY NA CELU OBJEDNAVKU
    const [showDiscount, setShowDiscount] = useState(false);

    // PRODUKT - DETAILS
    const [showProduct, setShowProduct] = useState(false);

    // UKONCENIE - ODOSLANIE OBJEDNAVKY
    const [showFinalize, setShowFinalize] = useState(false);

    // REKLAMACIA - CHYBA
    const [showReklamaciaError, setShowReklamaciaError] = useState(false);

    // ZMENA ZAKAZNIKA
    const [showCustomers, setShowCustomers] = useState(false);

    // PDF EXPORT ERROR
    const [showPdfError, setShowPdfError] = useState(false);

    // ZOBRAZIT CHYBU PO ODOSIELANI
    const [sendError, setSendError] = useState(false);
    const [showSendError, setShowSendError] = useState(false);

    // ZOBRAZENIE STAVU OBJEDNAVKY - PROTOKOL
    const [showProtocol, setShowProtocol] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1100 ? 1100 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = props.item == false ? 0 : 40;
    const buttonHeight = 80;
    const errorHeight = 40;
    const column1 = 150;
    const column2 = 400;
    const rowProductHeight = 70;
    const rowHeight = 92;
    const tableHeight = 40;
    const sumHeight = 80;


    let itemsExportMenu_ = [
        { id: 1, checked: false, enabled: false, label: lang.order_export },
        { id: 2, checked: false, enabled: true, label: lang.order_export_pdf },
        { id: 5, checked: false, enabled: true, label: lang.reklamacia_create },
        { id: 50, divider: true, label: lang.order_discounts },
        { id: 51, checked: false, enabled: true, icon: faPercent, label: lang.order_discount },
        { id: 52, checked: false, enabled: true, icon: faBan, label: lang.order_discount_cancel },
        { id: 53, divider: true },
        { id: 54, checked: false, enabled: true, icon: faXmark, label: lang.order_delete },
    ];

    const [itemsExportMenu, setItemsExportMenu] = useState(itemsExportMenu_);

    const tabs = [
        { id: 0, enabled: true, label: lang.order },
        { id: 3, enabled: true, label: lang.order_settings },
        { id: 1, enabled: true, label: lang.customer },
        { id: 4, enabled: true, label: lang.notes },
        { id: 2, enabled: true, label: lang.history },
    ];

    let { func } = props;
    var running = false;
    var lineChange = false;
    var lineType = 0;

    useEffect(() => {
        if (running == false) {
            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();

                var filter = defaultFilter;
                filter.stock_id = props.item.stock_id;
                db_get_products(0, filter, true);
            }

            db_data();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'order', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false && json.customer != false) {
                    let item = json.item;
                    setOrder(item);
                    setCurrency(item.currency);
                    setStockFromID(item.stock_id);
                    setStatus(item.status);
                    setDiscount(item.discount_coeficient);
                    setNumber(item.number);
                    setPriceListID(item.price_list_id);
                    setDistributorPriceListID(item.distributor_price_list_id);
                    setDistributorID(item.distributor_id);
                    setStockID(item.stock_id);
                    setSum(item.price);
                    setSumDPH(item.price_dph);
                    setDiscount(item.discount_coeficient);

                    if (props.system.distributors == true) {
                        if (item.distributor_id > 0) {
                            setType(true);
                        } else {
                            setType(false);
                        }
                    }

                    AssignData(json.customer);
                    setShop(json.shop);
                    if (json.address != false) {
                        let address_ = json.address;
                        setAddress(address_);
                        setStreet(address_.street);
                        setTown(address_.town);
                        setPSC(address_.psc);
                        setState(address_.state);
                    }
                    if (item.status > global.orders.cancel_request) {
                        var tmp = itemsExportMenu_.filter(x => x.id < 50);
                        setItemsExportMenu(tmp);
                        setOrderEdit(false);
                        setEditEnabled(false);
                    } else {
                        setOrderEdit(CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false)
                        setEditEnabled(true);
                    }
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_data = async () => {
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                var tmp = json.stocks;
                tmp = tmp.filter(x => x.type == 0);
                if (tmp.length == 0) {
                    tmp = false;
                }
                setStocks(tmp);

                /*
                if (json.stocks.length == 1) {
                    setStockID(json.stocks[0].id);
                } else {
                    setStockID(stock_default);
                }
                */
                setPriceLists(json.price_lists);
                setDistributors(json.distributors);
                var types = json.order_types;
                setOrderTypes(types);

                if (props.system.order_types == true) {
                    if (types != false) {
                        var arr = [];
                        types.forEach(element => {
                            arr[element.id] = element;
                        });

                        setOrderTypesIDS(arr);
                    }
                }

                if (props.item.default_order_type > 0) {
                    var tmp = types.find(x => x.id == props.item.default_order_type);
                    if (tmp != undefined) {
                        setOrderTypeDefault(tmp);
                    }
                }
            }
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_products = async (page, filter_, scroll) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'order_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    order_id: props.item.id,
                    start: page * maxItems,
                    length: maxItems,
                    filter: filter_
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    var tmp = json.items;

                    if (tmp != false) {
                        tmp.sort((a, b) => {
                            if (a.name < b.name) {
                                return 1;
                            } else {
                                return -1;
                            }
                        })

                        tmp.sort((a, b) => {
                            if (parseInt(a.order_type_id) > parseInt(b.order_type_id)) {
                                return 1;
                            } else {
                                return -1;
                            }
                        })
                    }

                    setItems(tmp);
                    var types = false;
                    if (props.system.order_types == true) {
                        types = json.order_types;
                        setOrderTypesIDS(types);
                    }
                    if (json.status < global.orders.finished) {
                        let sume = GetSum(json.items, json.discount_coeficient, types);
                        setSum(sume.sum);
                        setCost(sume.cost);
                        setSumDPH(sume.sum_dph);
                        setDiscount(json.discount_coeficient);
                        setExcluded(sume.products_id);
                        setServicesExcluded(sume.services_id);
                    }

                    setPage(page);
                    setPages(GetPages(json.count, maxItems));
                    RedrawPage(tmp, page, search, scroll);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'order_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    order_id: props.item.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setLogs(json.logs);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'order_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            setBusy(false);

            if (json.ok > 0) {
                if (data.status != undefined) {
                    setStatus(data.status);

                    if (data.status > global.orders.cancel_request) {
                        var tmp = itemsExportMenu_.filter(x => x.id < 50);
                        setItemsExportMenu(tmp);
                        setOrderEdit(false);
                        setEditEnabled(false);
                    }
                }
                if (data.stock_id != undefined) {
                    setStockFromID(data.stock_id);
                }
                setRedrawRequired(true);
                setShowOK(true);

                if (data.customer_id != undefined) {
                    db_get();
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_product_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'order_product_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setRedraw(!redraw);
                setRedrawRequired(true);

                if (data.enabled == false) {
                    db_get_products(page, GetFilter(), false);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_product_add = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'order_product_add', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setRedraw(!redraw);
                setRedrawRequired(true);

                db_get_products(page, GetFilter(), false);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_change_price_list = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'order_change_price_list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    order_id: props.item.id,
                    price_list_id: id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setCurrency(json.money);
                setRedraw(!redraw);
                setRedrawRequired(true);

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export_pdf = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'order_export_pdf', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    order_id: props.item.id
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                setShowOK(true)
            } else {
                setShowError(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const GetFilter = () => {
        var filter_ = defaultFilter;
        filter_.stock_id = stockID;

        return filter_;
    }


    const Close = () => {
        func(redrawRequired);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setCustomerID(item.id);
        setCompany(item.company);
        setName(item.name);
        setSurname(item.surname);
        setMobil(item.mobil);
        setPhone(item.phone);
        setEmail(item.email);
        setICO(item.ico);
        setDIC(item.dic);
        setICDPH(item.ic_dph);
        setDPH(item.dph == false ? false : true);
        setNote(item.note);
        setAvatar(item.avatar);
        setCountryID(item.country_id);
    }

    const MenuResult = (id, index, item) => {
        switch (index) {
            case 0:
                Debug('Click - menu 1')
                break;

            case 1:
                Debug('Click - menu 2')
                break;

            case 2:
                // EXPORT PDF
                if (items != false) {
                    db_export_pdf();
                } else {
                    setShowPdfError(true);
                }
                break;

            case 5:
                // VYTVORENIE REKLAMACIE
                if (status == global.orders.finished || status == global.orders.canceled) {
                    func(2, false);
                } else {
                    setShowReklamaciaError(true);
                }
                break;

            case 54:
                if (status < global.orders.finished) {
                    setShowDelete(true);
                }
                break;

            case 51:
                setShowDiscount(true);
                break;

            case 52:
                DiscountResult(true, 1);
                break;
        }
    }

    const GetSum = (itemList, discount_, orderTypeIDS_) => {
        var sum = 0;            // Celková suma bez DPH
        var sum_dph = 0;        // Celková suma s DPH
        var sume_dph = [];      // SUMY PRE JEDNOTLIVE DPH SADZBY
        var products_count = 0; // POCET PRODUKTOV V OBJEDNAVKE
        var cost = 0;           // Náklady bez DPH
        var sum_products = 0;   // Celková suma len za produkty bez DPH
        var products_id = [];   // Excluded - produkty
        var services_id = [];   // Excluded - sluzby

        if (itemList != false) {
            // ----------------------------------------------------------------------
            // VYPOCET CENY PRODUKTOV
            // ----------------------------------------------------------------------
            var items_ = itemList.filter(x => parseInt(x.product_id) > 0);
            items_.forEach(item => {
                // pocet produktov v objednávke + excluded ids
                products_count += 1;
                products_id.push(parseInt(item.id));

                // PREDAJNA CENA BEZ DPH
                var sume = 0;
                let item_dph = parseInt(item.dph) < 0 ? props.system.dph : item.dph;
                if (sume_dph[parseInt(item_dph)] == undefined) {
                    sume_dph[parseInt(item_dph)] = 0;
                }

                if (parseFloat(item.quantity) > 0) {
                    sume = RoundValue(parseFloat(item.price_final) * parseFloat(item.quantity) * parseFloat(item.discount_coeficient), 3);
                }
                if (orderTypeIDS_ != false) {
                    if (orderTypeIDS_[item.order_type_id] != undefined) {
                        if (orderTypeIDS_[item.order_type_id].invoice != false) {
                            sum += sume;
                            sume_dph[parseInt(item_dph)] += sume;
                            if (parseInt(item.product_id) > 0) {
                                sum_products += sume;
                            }
                        }
                    } else {
                        sum += sume;
                        sume_dph[parseInt(item_dph)] += sume;
                        if (parseInt(item.product_id) > 0) {
                            sum_products += sume;
                        }
                    }
                } else {
                    sum += sume;
                    sume_dph[parseInt(item_dph)] += sume;
                    if (parseInt(item.product_id) > 0) {
                        sum_products += sume;
                    }
                }

                // NAKLADY
                var sume_cost = 0;
                if (parseFloat(item.quantity) > 0) {
                    sume_cost = parseFloat(item.cost) * parseFloat(item.quantity);
                }
                cost += sume_cost;
            });

            // ----------------------------------------------------------------------
            // VYPOCET CENY SLUZIEB
            // ----------------------------------------------------------------------
            var items_ = itemList.filter(x => parseInt(x.product_id) == 0);
            items_.forEach(item => {
                item.free = false;
                // excluded ids
                services_id.push(parseInt(item.item_id));

                // PREDAJNA CENA BEZ DPH
                var sume = 0;
                let item_dph = parseInt(item.dph) < 0 ? props.system.dph : item.dph;
                if (sume_dph[parseInt(item_dph)] == undefined) {
                    sume_dph[parseInt(item_dph)] = 0;
                }

                if (parseFloat(item.quantity) > 0) {
                    sume = RoundValue(parseFloat(item.price_final) * parseFloat(item.quantity) * parseFloat(item.discount_coeficient), 3);
                }

                // Je sluzba od urcitej sumy zadarmo?
                var quantity_discount = item.quantity_discount;
                if (item.quantity_discount != false) {
                    quantity_discount = JSON.parse(quantity_discount);
                    if (quantity_discount.price_free_enabled == true) {
                        item.price_final = quantity_discount.price;
                        if (sum_products > parseFloat(quantity_discount.price_free)) {
                            sume = 0;
                            item.free = true;
                        }
                    }
                }

                sum += sume;
                sume_dph[parseInt(item_dph)] += sume;

                // NAKLADY
                var sume_cost = 0;
                if (parseFloat(item.quantity) > 0) {
                    sume_cost = parseFloat(item.cost) * parseFloat(item.quantity);
                }
                cost += sume_cost;

            });

            // Vypocet sumy s DPH
            sume_dph.forEach((element, index) => {
                let dph = (parseFloat(index) / 100) + 1;
                sum_dph += (element * parseFloat(discount_)) * dph;
            });

        }

        sum = RoundValue(sum * parseFloat(discount_), 3);
        cost = RoundValue(cost, 3);

        return {
            sum: sum,
            cost: cost,
            products_id: products_id,
            services_id: services_id,
            sum_dph: RoundValue(sum_dph, 3),
            products_count: products_count
        };
    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 2) {
            db_get_logs();
        }
        if (id == 0) {
            db_get_products(page, GetFilter(), true);
        }
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id-products-start');
            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 150);
    }

    const SliceItems = (items_, page_) => {
        var data = false;
        if (items_ != false) {
            data = items_.slice(maxItems * page_, (maxItems * page_) + maxItems)
        }
        return data;
    }

    const RedrawPage = (items_, page_, search_, scroll) => {
        let data = items_;

        if (data != false) {
            if (search_.trim() != '') {
                data = data.filter(x => x.name.toLowerCase().includes(search_.toLowerCase()) || x.parent_name.toLowerCase().includes(search_.toLowerCase()));
            }
            setPages(GetPages(data.length, maxItems));

        } else {
            setPages(0);
        }

        setItemsFiltered(SliceItems(data, page_));

        if (scroll == true) {
            GoToStart();
        }
    }

    const ChangePage = (value) => {
        setPage(value - 1);
        RedrawPage(items, value - 1, search, true);
        //db_get(value - 1, filter);
    }

    const SelectItem = (item) => {
        if (CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true) {
            setItemSelected(item);
            setShowProduct(true);
        }
    }

    const DeleteItem = (item) => {
        var tmp = items.filter(x => parseInt(x.id) != parseInt(item.id));
        let data = {
            id: item.card_id,
            quantity: 0,
            quantity_offset: 0 - item.quantity,
            order_id: props.item.id,
            product_id: item.id,
            stock_uid: item.stock_uid,
            enabled: false
        }

        let sume = GetSum(tmp, discount, orderTypeIDS);
        setSum(sume.sum);
        setSumDPH(sume.sum_dph);
        setCost(sume.cost);

        data.order_price = sume.sum;
        data.order_price_dph = sume.sum_dph;
        data.order_cost = sume.cost;

        db_product_update(data);
    }

    const PlusMinusResult = (quantity, item_) => {

        var item = items.find(x => x.id == item_.id);
        if (item != undefined) {

            if (item.card_id > 0) {
                var price_final = parseFloat(item.price);
                if (item.quantity_discount != false && parseInt(item.product_id) > 0) {
                    price_final = GetPriceDiscount(item.quantity_discount, item.price_default, parseInt(quantity));
                }

                let data = {
                    id: item.card_id,
                    quantity: quantity,
                    quantity_offset: parseInt(quantity) - parseInt(item.quantity),
                    price_final: price_final,
                    order_id: props.item.id,
                    product_id: item.id,
                    stock_uid: item.stock_uid
                }

                item.quantity = quantity;
                item.price_final = price_final;

                let sume = GetSum(items, discount, orderTypeIDS);
                setSum(sume.sum);
                setSumDPH(sume.sum_dph);
                setCost(sume.cost);

                data.order_price = sume.sum;
                data.order_price_dph = sume.sum_dph;
                data.order_cost = sume.cost;

                db_product_update(data);
            }
        }
    }

    const StatusChange = (typ, status) => {
        if (typ == 0) {
            // BUTTON CLICK - uprava
            setOrderEdit(true);
        }
        if (typ == 1) {
            // MENU CLICK - zmena statusu
            let data = {
                id: props.item.id,
                status: status
            }

            let sume = GetSum(items, discount, orderTypeIDS);
            data.price = RoundValue(sume.sum, precisions);
            data.cost = RoundValue(sume.cost, precisions);

            db_update(data);
        }

    }

    const ProductResult = (typ, ids, selected, order_type) => {

        if (typ == true && ids != false) {
            let item = selected[0];
            //let dph = (item.dph / 100) + 1;
            let dph = parseInt(item.dph) < 0 ? (props.system.dph / 100) + 1 : (item.dph / 100) + 1;

            var sume = GetSum(items, discount, orderTypeIDS);

            let data = {
                id: 0,
                enabled: true,
                organization_id: props.organization.id,
                user_id: props.user.id,
                customer_id: customerID,
                product_id: item.id,
                stock_uid: item.stock_uid,
                order_id: props.item.id,
                name: item.name,
                parent_name: item.parent_name,
                image: item.image,
                unit: item.unit,
                quantity: 1,
                quantity_original: 0,
                cost: item.cost_final,
                price: Math.round(item.price_final * 100) / 100,
                price_final: Math.round(item.price_final * 100) / 100,
                dph: parseInt(item.dph) < 0 ? props.system.dph : item.dph,
                quantity_discount: item.quantity_discount == false ? false : JSON.stringify(item.quantity_discount),
                order_type_id: order_type,
                products: items != false ? sume.products_count + 1 : 1,
            }

            db_product_add(data);
        }

        setSelectProducts(false);
    }

    const ServiceResult = (typ, item) => {

        if (typ == true && item != false) {
            let dph = parseInt(item.dph) < 0 ? (props.system.dph / 100) + 1 : (item.dph / 100) + 1;

            var sume = GetSum(items, discount, orderTypeIDS);

            let data = {
                id: 0,
                enabled: true,
                organization_id: props.organization.id,
                user_id: props.user.id,
                customer_id: customerID,
                product_id: 0,
                stock_uid: 0,
                item_id: item.id,
                order_id: props.item.id,
                name: item.name,
                parent_name: '',
                image: item.image,
                unit: '',
                quantity: 1,
                quantity_original: 0,
                cost: item.cost,
                price: RoundValue(item.price, 2),
                price_final: RoundValue(item.price, 2),
                dph: parseInt(item.dph) < 0 ? props.system.dph : item.dph,
                quantity_discount: JSON.stringify({ price_free_enabled: item.price_free_enabled == true ? 1 : 0, price_free: item.price_free, price: item.price }),
                order_type_id: 0,
                products: items != false ? sume.products_count : 0,
            }
            db_product_add(data);
        }

        setSelectServices(false);
    }

    const DeleteOrder = () => {
        let data = {
            id: props.item.id,
            status: global.orders.canceled
        }

        db_update(data);

    }

    const Sumarize = () => {
        setDistributor('');
        setPriceListName('');
        setStock('');
        setError('');

        if ((type == true && distributorID > 0) || type == false && stockID > 0) {
            if (distributors != false) {
                var tmp = distributors.find(x => x.id == distributorID);
                if (tmp != undefined) {
                    setDistributor(tmp.name);
                }
            }
            if (priceLists != false) {
                var tmp = priceLists.find(x => x.id == priceListID);
                if (tmp != undefined) {
                    setPriceListName(tmp.name);
                }
            }
            if (stocks != false) {
                var tmp = stocks.find(x => x.id == stockID);
                if (tmp != undefined) {
                    setStock(tmp.name);
                }
            }
        } else {
            if (type == true) {
                setError(lang.order_expedite_distributor_error);
            } else {
                setError(lang.order_expedite_stock_error);
            }
        }
        setShowFinalize(true);
    }

    const Expedite = (value) => {
        setShowFinalize(false);

        if (value == true) {
            let sume = GetSum(items, discount, orderTypeIDS);

            let data = {
                id: props.item.id,
                products: items != false ? sume.products_count : 0,
                status: global.orders.finished
            }

            data.price = RoundValue(sume.sum, precisions);
            data.price_dph = RoundValue(sume.sum_dph, precisions);
            data.cost = RoundValue(sume.cost, precisions);
            data.stock_id = stockID;

            db_update(data);
        }
    }


    const ChangePriceList = (id) => {
        setPriceListID(id);
        db_change_price_list(id);
    }

    const DeleteResult = (value) => {
        setShowDelete(false);

        if (value == true) {
            let data = {
                id: props.item.id,
                status: global.orders.canceled
            }

            db_update(data);
        }
    }

    const DiscountResult = (typ, value) => {
        setShowDiscount(false);

        if (typ == true) {
            if (value >= 0) {
                setDiscount(value);
                let sume = GetSum(items, value, orderTypeIDS);
                setSum(sume.sum);
                setSumDPH(sume.sum_dph);

                let data = {
                    id: props.item.id,
                    discount_coeficient: value,
                    price: RoundValue(sume.sum, precisions),
                    price_dph: RoundValue(sume.sum_dph, precisions),
                }

                db_update(data);
            }
        }

    }

    const SaveChanges = () => {
        let data = {
            id: props.item.id,
            number: number
        };
        order.number = number;

        db_update(data);

    }

    const GetOrderType = (id) => {
        var result = '';
        if (orderTypes != false) {
            var tmp = orderTypes.find(x => x.id == id);
            if (tmp != undefined) {
                result = tmp.name;
            }
        }

        return (result);
    }

    const ProductDetailsResult = (typ, quantity, discount_, order_type_, order_type_label) => {
        setShowProduct(false);

        if (typ == true) {
            if (itemSelected.product_id > 0) {
                var item = items.find(x => x.id == itemSelected.id);
                if (item != undefined) {

                    if (item.card_id > 0) {
                        var price_final = parseFloat(item.price);
                        if (item.quantity_discount != false) {
                            price_final = GetPriceDiscount(item.quantity_discount, item.price_default, parseInt(quantity));
                        }

                        let data = {
                            id: item.card_id,
                            discount_coeficient: discount_,
                            quantity: quantity,
                            quantity_offset: parseInt(quantity) - parseInt(item.quantity),
                            price_final: price_final,
                            order_id: props.item.id,
                            product_id: item.id,
                            stock_uid: item.stock_uid,
                            order_type_id: order_type_
                        }

                        item.quantity = quantity;
                        item.price_final = price_final;
                        item.discount_coeficient = discount_;
                        item.order_type_id = order_type_;
                        item.order_type = order_type_label;


                        let sume = GetSum(items, discount, orderTypeIDS);
                        setSum(sume.sum);
                        setSumDPH(sume.sum_dph);
                        setCost(sume.cost);

                        var tmp = itemsFiltered;

                        if (tmp != false) {
                            tmp.sort((a, b) => {
                                if (a.name < b.name) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            })

                            tmp.sort((a, b) => {
                                if (parseInt(a.order_type_id) > parseInt(b.order_type_id)) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            })
                        }
                        setItemsFiltered(tmp);

                        data.order_price = sume.sum;
                        data.order_cost = sume.cost;
                        data.order_price_dph = sume.sum_dph;

                        db_product_update(data);
                    }
                }
            } else {
                // SLUZBA
                let item = itemSelected;
                var price_final = parseFloat(item.price);

                let data = {
                    id: item.card_id,
                    discount_coeficient: discount_,
                    quantity: quantity,
                    price_final: price_final,
                    order_id: props.item.id,
                    product_id: 0,
                    stock_uid: 0,
                }

                item.price_final = price_final;
                item.discount_coeficient = discount_;

                let sume = GetSum(items, discount, orderTypeIDS);
                setSum(sume.sum);
                setSumDPH(sume.sum_dph);
                setCost(sume.cost);

                data.order_price = sume.sum;
                data.order_cost = sume.cost;
                data.order_price_dph = sume.sum_dph;

                db_product_update(data);
            }
        }
    }


    const ChangeType = (id) => {
        // ZMENA TYPU OBJEDNAVKY
        if (id == false) {
            // expedicia zo skladu
            setDistributorID(-1);
            setDistributorPriceListID(0);
            setStockID(stock_default);

            let data = {
                id: props.item.id,
                distributor_id: 0,
                distributor_price_list_id: 0,
                stock_id: stock_default,
                stock_from: stockFromID
            };

            db_update(data);
        }

        setType(id);
    }


    const ChangeStock = (id) => {
        setStockID(id);
        let data = {
            id: props.item.id,
            stock_id: id,
            stock_from: stockFromID
        };

        var filter_ = GetFilter();
        filter_.stock_id = id;

        db_update(data);
    }


    const ChangeDistributor = (id) => {
        if (distributors != false) {
            var tmp = distributors.find(x => x.id == id);
            if (tmp != undefined) {
                setStockID(tmp.stock_id);
                setDistributorPriceListID(tmp.price_list_id);
                setDistributorID(id);

                let data = {
                    id: props.item.id,
                    distributor_id: id,
                    distributor_price_list_id: tmp.price_list_id,
                    stock_id: tmp.stock_id,
                    stock_from: stockFromID
                };

                db_update(data); // db_change_distributor
            }
        }
    }


    const CheckQuantity = () => {
        var result = 0;

        if (items != false) {
            items.forEach(item => {
                result += parseInt(item.quantity);
            });
        }

        return result;
    }

    const NotesResult = () => {

    }

    const QuickResult = (item) => {
        ProductResult(true, [item.id], [item], orderTypeDefault.id);
    }

    const CustomersResult = (typ, customer_id, customer, address) => {
        setShowCustomers(false);

        if (typ == true) {
            var address_id = address == false ? 0 : address.id;

            let data = {
                id: props.item.id,
                customer_id: customer_id,
                shop_id: address_id
            };

            db_update(data);

        }
    }

    const LogsResult = (typ, item) => {
        if (typ == true) {
            if (item.error_id > 0) {
                setSendError(item.error_id);
                setShowSendError(true);
            }
        }
    }

    const SendErrorResult = (redraw_) => {
        setShowSendError(false);
        if (redraw_ == true) {
            setTabIndex(0);
            setRedrawRequired(true);
            db_get();

        }
    }

    const ProtocolResult = () => {
        setShowProtocol(false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 800 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120 - 50, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.order}: <span style={{ ...styles.TextXSmall, fontWeight: '400' }}>{order != false ? order.number : ''}</span></p>
                    </div>
                    <div style={{ ...styles.Block, width: 50, height: global.widow_bar_height }}>
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    {props.item != false ?
                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs value={tabIndex} items={tabs} width={dialogWidth / 5} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        /*
                        *
                        * =================================================================================================================
                        * OBJEDNANE PRODUKTY
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.BlockCenter, height: status < global.orders.finished ? dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight : dialogHeight - global.dialog_bar_height - tabHeight - 5, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div id={'id-products-start'} style={{ ...styles.Block, width: '98%' }}>
                                <div style={{ ...styles.BlockRow, height: sumHeight, width: dialogWidth - 30 }}>
                                    <div style={{ ...styles.BlockLeft, width: dialogWidth - 320 - 20 - 200, height: sumHeight }}>
                                        <p style={{ ...styles.TextXSmall }}>{lang.order_status_}: <b>{lang.order_status[status]}</b></p>
                                        <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.date}: {GetDatumTime2(order.created)}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 200, height: sumHeight }}>
                                        {(order.export_type == 2 || order.export_type == 3) && parseInt(order.code) > 0 ?
                                            <Button onClick={() => setShowProtocol(true)} style={{ ...styles.ButtonOutlined }}>{lang.protocol}</Button>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 320, height: sumHeight }}>
                                        <div style={{ ...styles.BlockRight, height: sumHeight - 10, backgroundColor: color.white, borderRadius: 10, border: '1px solid #000000' }}>
                                            <p style={{ ...styles.TextSmall, marginRight: 8 }}><b>{lang.order_sum_price_}</b> <span style={{ ...styles.TextXTiny }}>({lang.withou_dph}):</span> <b>{FormatMoney(sum, precisions)} {currency}</b></p>
                                            {props.system.show_prices_dph == true ?
                                                <p style={{ ...styles.TextTiny, marginRight: 8 }}><span style={{ ...styles.TextXTiny }}>({lang.with_dph}):</span> {FormatMoney(sumDPH, precisions)} {currency}</p>
                                                : null}
                                            {discount != 1 ?
                                                <p style={{ ...styles.TextTiny, marginRight: 8 }}>{lang.price_list_discount}: {CoeficientToPercentage(discount, 2)} %</p>
                                                : null}
                                        </div>
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockRow, width: dialogWidth - 30, border: color.border, marginTop: 10, marginBottom: 10 }}>
                                    {/* DATA */}
                                    <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableHeight, justifyContent: 'center' }}>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: dialogWidth - 30 - rowHeight - 200 - 130 - 130 - 180, height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 5 }}>{lang.product_name}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 200, height: tableHeight, borderLeft: color.border }}>
                                        {status == global.orders.created || status == global.orders.in_proccess ?
                                            <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 5 }}>{lang.product_quantity_stock_}</p>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 130, height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 5 }}>{lang.price_} {lang.withou_dph}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 130, height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 5 }}>{lang.sume} {lang.withou_dph}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 180, height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 5 }}>{lang.stock_quantity}</p>
                                    </div>
                                </div>

                                {order.note != '' ?
                                    <Paper
                                        elevation={1}
                                        style={{
                                            ...styles.Block,
                                            backgroundColor: color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowProductHeight,
                                            marginTop: 5, marginBottom: 5,
                                            paddingTop: 10, paddingBottom: 10,
                                        }}
                                    >
                                        <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                            <p style={{ ...styles.TextTiny, fontWeight: '600', textAlign: 'left' }}>{lang.note}:</p>
                                            <p style={{ ...styles.TextXSmall, textAlign: 'left', marginTop: 5 }}>{order.note}</p>
                                        </div>
                                    </Paper>
                                    : null}

                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <div key={index} style={{ ...styles.Block }}>
                                        <div style={{ display: 'none' }} >
                                            {lineType != item.order_type_id ? lineChange = true : lineChange = false}
                                            {lineType = item.order_type_id}
                                        </div>

                                        {lineChange == true && props.system.order_types == true ?
                                            <div style={{ ...styles.Block, height: 40, backgroundColor: color.lighter, borderRadius: 25, marginTop: 20 }}>
                                                <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{GetOrderType(item.order_type_id)}</p>
                                            </div>
                                            : null}

                                        <Paper
                                            elevation={item.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block,
                                                backgroundColor: item.quantity == 0 ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowProductHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>

                                                <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <Avatar picture image={item.image} size={rowHeight - 8} theme={props.theme} />
                                                </div>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, overflow: 'hidden', width: dialogWidth - 40 - rowHeight - 200 - 130 - 130 - 180, minHeight: rowHeight }}>
                                                    <div style={{ ...styles.BlockLeft, justifyContent: 'center', minHeight: rowHeight, marginLeft: 10 }}>
                                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.code}</p>
                                                        {item.parent_name != '' ?
                                                            <div style={{ ...styles.BlockLeft }}>
                                                                <p style={{ ...styles.TextXSmall, fontWeight: '600', whiteSpace: 'nowrap' }}>{item.parent_name}</p>
                                                                <p style={{ ...styles.TextTiny, whiteSpace: 'nowrap' }}>{item.name}</p>
                                                            </div>
                                                            :
                                                            <div style={{ ...styles.BlockLeft }}>
                                                                <p style={{ ...styles.TextXSmall, fontWeight: '600', whiteSpace: 'nowrap' }}>{item.name}</p>
                                                            </div>
                                                        }
                                                        {item.order_type_id > 0 ?
                                                            <Chip size='small' label={item.order_type} variant="outlined" style={{ marginTop: 3 }} ></Chip>
                                                            : null}
                                                    </div>
                                                </div>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 200, minHeight: rowHeight }}>
                                                    {parseInt(item.product_id) > 0 ? status == global.orders.created || status == global.orders.in_proccess ?
                                                        <div style={{ ...styles.Block }}>
                                                            <p style={{ ...styles.TextTiny, color: item.stock_quantity < 0 ? color.red : color.black, fontWeight: item.stock_quantity < 0 ? '600' : '400' }}>{FormatPieces(item.stock_quantity)} {item.unit}</p>
                                                            {item.quantity > 0 ?
                                                                <StockCoveredChip marginTop={5} stock_quantity={item.stock_quantity} stock_reserved={0} quantity={item.quantity} lang={props.lang} />
                                                                : null}
                                                        </div>
                                                        : null : null}
                                                </div>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRight, width: 130, minHeight: rowHeight }}>
                                                    <p style={{ ...styles.TextTiny, marginRight: 5 }}>{item.free == true ? FormatMoney(0, precisions) : FormatMoney(parseFloat(item.price_final), precisions)} {currency}</p>
                                                    {item.discount_coeficient != 1 ?
                                                        <div style={{ ...styles.BlockRight }}>
                                                            <p style={{ ...styles.TextXTiny, marginTop: 10, marginRight: 5 }}>{lang.price_list_discount}: {CoeficientToPercentage(item.discount_coeficient, 2)} %</p>
                                                            <p style={{ ...styles.TextXTiny, marginRight: 5 }}>{FormatMoney(parseFloat(item.price_final) * parseFloat(item.discount_coeficient), precisions)} {currency}</p>
                                                        </div>
                                                        : null}
                                                </div>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRight, width: 130, minHeight: rowHeight }}>
                                                    <p style={{ ...styles.TextTiny, marginRight: 5 }}>{item.free == true ? FormatMoney(0, precisions) : FormatMoney(parseFloat(item.price_final) * parseInt(item.quantity) * parseFloat(item.discount_coeficient), precisions)} {currency}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 180, minHeight: rowHeight }}>
                                                    {orderEdit == true ?
                                                        <PlusMinus min={0} value={item.quantity} item={item} theme={props.theme} lang={props.lang} func={PlusMinusResult.bind(this)} />
                                                        :
                                                        <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{item.quantity}</p>
                                                    }

                                                    {item.product_id > 0 ? parseInt(item.quantity) != parseInt(item.quantity_original) ?
                                                        <Chip size="small" style={{ marginTop: 5 }} label={lang.order_quantity_original + ' ' + item.quantity_original + ' ' + item.unit}></Chip>
                                                        : null : null}
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 60, minHeight: rowHeight }}>
                                                    {orderEdit == true ?
                                                        <DeleteButton ask theme={props.theme} lang={props.lang} func={() => DeleteItem(item)} />
                                                        : null}
                                                </div>
                                            </div>
                                        </Paper>
                                        <div style={{ display: 'none' }} >
                                            {lineChange = false}
                                        </div>
                                    </div>
                                )) : null
                                }

                                {orderEdit == true ?
                                    props.system.order_types == false || orderTypeDefault != false ?
                                        <ProductSearch price_list={priceListID} stock_id={stockID} excluded={excluded} marginTop={25} theme={props.theme} lang={lang} radius={props.radius} locale={props.locale} organization={props.organization} token={props.token} user={props.user} func={(item) => QuickResult(item)} />
                                        : null
                                    : null}

                                {/* PAGINATION */}
                                {pages > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                    </div>
                                    : null}


                                <div style={{ ...styles.Block, minHeight: global.list_padding }} />
                            </div>
                        </div>
                        : null}

                    {tabIndex == 3 ?
                        /*
                        *
                        * =================================================================================================================
                        * NASTAVENIA OBJEDNAVKY
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div>
                                <FormSpace />
                                <FormLabel editing={false} icon={faInfoCircle} title={lang.order} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                <FormSpace />
                                <FormText require redraw value={number} editing={editEnabled} title={lang.order_number_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNumber(txt)} />
                                <FormSpace />

                                {props.system.distributors == true ?
                                    <div>
                                        <FormYesNo value={type} editing={editEnabled} title={lang.distribute_type} column1={column1} column2={column2} theme={props.theme} no={lang.distribute_type_stock} yes={lang.distribute_type_distributor} lang={lang} radius={props.radius} func={(id) => ChangeType(id)} />
                                        <FormSpace />
                                    </div>
                                    : null}
                                {type == true ?
                                    <FormSelect require value={distributorID} items={distributors} field={'name'} editing={editEnabled} title={lang.distributor} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeDistributor(id)} />
                                    : null}

                                {type == false ?
                                    <div>
                                        <FormSelect require value={stockID} items={stocks} field={'name'} editing={editEnabled} title={lang.stock} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeStock(id)} />
                                    </div>
                                    : null}

                                <FormSelect require value={priceListID} items={priceLists} field={'name'} editing={editEnabled} title={lang.order_customer_price_list} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangePriceList(id)} />
                                <FormNote title={lang.order_price_list_note} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                                <FormSpace />
                                {editEnabled == true ?
                                    <FormSelect require value={status} items={status_menu} field={'label'} editing={editEnabled} title={lang.order_status_} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => StatusChange(1, id)} />
                                    : null}
                                {props.remote == true && props.remoteMobile == true ?
                                    <FormSpace height={500} />
                                    : null}

                            </div>
                        </div>
                        : null}

                    {tabIndex == 1 ?
                        /*
                        *
                        * =================================================================================================================
                        * ZAKAZNIK - INFO
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - 5, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div>
                                <FormSpace />
                                <div style={{ ...styles.Block }}>
                                    <Avatar editable={false} picture image={avatar} size={75} theme={props.theme} func={null} />
                                    <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.default_image}</p>
                                </div>
                                <Line marginTop={10} theme={props.theme} />
                                < FormSpace />
                                <FormLabel editing={false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                <FormText require redraw value={company} editing={false} title={lang.company_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                <FormText require redraw value={name} editing={false} title={lang.name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                <FormText require redraw value={surname} editing={false} title={lang.surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                <FormText redraw value={ico} editing={editing} title={lang.ico} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setICO(txt)} />
                                <FormText redraw value={dic} editing={editing} title={lang.dic} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDIC(txt)} />
                                <FormYesNo value={dph} editing={editing} title={lang.dph_pay} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setDPH(value)} />

                                <FormSpace />
                                <FormLabel editing={false} editButton={false} title={lang.customer_firm_address} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                <FormText redraw value={street} editing={false} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setStreet(txt)} />
                                <FormText redraw value={town} editing={false} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setTown(txt)} />
                                <FormText redraw value={psc} editing={false} title={lang.psc} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPSC(txt)} />
                                <FormText redraw value={state} editing={false} title={lang.state} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setState(txt)} />


                                {shop != false ?
                                    <div>
                                        <FormSpace />
                                        <FormLabel editing={false} title={lang.customer_shop_address} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} />
                                        <FormText redraw value={shop.name} editing={false} title={lang.customer_shop_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                        <FormText redraw value={shop.street} editing={false} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                        <FormText redraw value={shop.town} editing={false} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                    </div>
                                    : null}

                                <FormSpace />
                                <FormLabel editing={false} editButton={false} title={lang.contact_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                <FormText redraw value={mobil} editing={false} title={lang.mobil} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setMobil(txt)} />
                                <FormText redraw value={phone} editing={false} title={lang.phone} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPhone(txt)} />
                                <FormText redraw value={email} editing={false} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />

                                <FormSpace />
                                <FormText redraw value={note} editing={false} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                                <FormSpace />

                                {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                    status < global.orders.cancel_request ?
                                        <Button onClick={() => setShowCustomers(true)} style={{ ...styles.ButtonOutlined, width: 200, marginTop: 20 }}>
                                            <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faSearch} />
                                            {lang.customer_change}
                                        </Button>
                                        : null : null}

                                <FormSpace height={150} />

                            </div>
                        </div>
                        : null}


                    {tabIndex == 4 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Notes module_id={global.model.orders} item_id={props.item.id} height={dialogHeight - global.dialog_bar_height - tabHeight - errorHeight} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={NotesResult.bind(this)} />
                        </div>
                        : null}

                    {tabIndex == 2 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs logs={logs} typ={0} lang={props.lang} locale={props.locale} theme={props.theme} func={LogsResult.bind(this)} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        status < global.orders.finished ?
                            <div style={{ ...styles.Block, height: buttonHeight, borderTop: color.border }}>
                                <div style={{ ...styles.BlockRowRaw, height: buttonHeight }}>
                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                        status < global.orders.cancel_request ?
                                            <div style={{ ...styles.Block, width: 200, height: buttonHeight }}>
                                                <Button onClick={() => setSelectProducts(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 190 }}>
                                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPlusCircle} />
                                                    {lang.order_add_product}
                                                </Button>
                                            </div>
                                            : null : null}

                                    {props.system.eshop_enabled == true ? CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                        status < global.orders.cancel_request ?
                                            <div style={{ ...styles.Block, width: 200, height: buttonHeight }}>
                                                <Button onClick={() => setSelectServices(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add_2, width: 190 }}>
                                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPlusCircle} />
                                                    {lang.order_add_service}
                                                </Button>
                                            </div>
                                            : null : null : null}


                                    {status == global.orders.cancel_request ?
                                        <div style={{ ...styles.Block, width: 250, height: buttonHeight }}>
                                            <DeleteButton ask button bold label={lang.order_cancel_accept} icon={faXmark} width={200} position={'top'} backgroundColor={color.button_delete} text={lang.order_cancel_accept_} text_note={lang.order_cancel_accept_text} tooltip={lang.delete} theme={props.theme} lang={props.lang} backColor={color.white} func={() => DeleteOrder()} />
                                        </div>
                                        : null}

                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                        status < global.orders.cancel_request ?
                                            CheckQuantity() > 0 ?
                                                <div style={{ ...styles.BlockRight, width: 250, height: buttonHeight }}>
                                                    <Button onClick={() => Sumarize()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_finalize, width: 200 }}>
                                                        <FontAwesomeIcon style={{ height: 12, marginRight: 10 }} icon={faPaperPlane} />
                                                        {lang.order_expedite}
                                                    </Button>
                                                </div>
                                                : null
                                            : null
                                        : null}

                                </div>
                            </div>
                            : null : null}

                    {tabIndex == 3 ?
                        <div style={{ ...styles.Block, height: buttonHeight, borderTop: color.border }}>
                            {order.number != number ?
                                <Button onClick={() => SaveChanges()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 180 }}>
                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faSave} />
                                    {lang.save_changes}
                                </Button>
                                : null}
                        </div>
                        : null}
                </div >

                {showProtocol == true ?
                    <OrderProtocol remote={props.remote} remoteMobile={props.remoteMobile} order_id={props.item.id} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProtocolResult.bind(this)} />
                    : null}

                {showSendError == true ?
                    <OrderError remote={props.remote} remoteMobile={props.remoteMobile} error_id={sendError} order_id={props.item.id} status={status} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={SendErrorResult.bind(this)} />
                    : null}

                {showReklamaciaError == true ?
                    <DialogInfo title={lang.reklamacia} text={lang.reklamacia_order_error} sub_text={lang.reklamacia_order_error_note} button={lang.close} icon={faInfoCircle} icon_color={color.red} theme={props.theme} radius={props.radius} func={() => setShowReklamaciaError(false)} />
                    : null}

                {showPdfError == true ?
                    <ShowInfo text={lang.order_pdf_error} sub_text={''} lang={lang} theme={props.theme} func={() => setShowPdfError(false)} />
                    : null}

                {showCustomers == true ?
                    <CustomerSelect remote={props.remote} remoteMobile={props.remoteMobile} shop_select shop_required single showVariations={true} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomersResult.bind()} />
                    : null}

                {showFinalize == true ?
                    <OrderSumar remote={props.remote} remoteMobile={props.remoteMobile} error={error} type={type} distributor={distributor} stock={stock} price_list={priceListName} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={precisions} isSmall={props.isSmall} func={Expedite.bind(this)} />
                    : null}

                {showProduct == true ?
                    <OrderProduct remote={props.remote} remoteMobile={props.remoteMobile} item={itemSelected} priceListID={priceListID} orderTypes={orderTypes} editable={status < global.orders.cancel_request} status={status} currency={currency} country_id={countryID} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={precisions} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductDetailsResult.bind(this)} />
                    : null}

                {selectProducts == true ?
                    <ProductSelect remote={props.remote} remoteMobile={props.remoteMobile} hide_multiple excluded={excluded} orderTypes={props.system.order_types == true ? true : false} orderTypeDefault={orderTypeDefault} price_list={priceListID} price only_enabled stocks stock_id={stockID} only_sku={true} country_id={countryID} showVariations={true} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={precisions} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductResult.bind()} />
                    : null}

                {selectServices == true ?
                    <ServiceSelect typ={global.services.service} remote={props.remote} remoteMobile={props.remoteMobile} excluded={servicesExcluded} country_id={countryID} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={precisions} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ServiceResult.bind()} />
                    : null}

                {showDiscount == true ?
                    <DialogEnter remote={props.remote} remoteMobile={props.remoteMobile} percentage title={lang.order_discount_} label={lang.order_discount_percentage} value={discount == 1 ? '' : CoeficientToPercentage(discount, 2) + '%'} icon={faPercentage} button={props.lang.save} theme={props.theme} radius={props.radius} lang={props.lang} func={DiscountResult.bind(this)} />
                    : null}

                {showDelete == true ?
                    <DialogYesNo remote={props.remote} remoteMobile={props.remoteMobile} theme={props.theme} radius={props.radius} title={lang.order_delete_label} text={lang.order_delete_text} sub_text={lang.order_delete_note} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={DeleteResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}




export const OrderSelect = (props) => {

    let module_id = global.model.orders;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'orders';
    let currency = props.locale.currency;
    let orders_limited = props.permissions.orders_limited == true && props.user.super_admin == false ? true : false;

    let defaultFilter = {
        ordering: global.order.up,
        order_column: 'created',
        customer_id: props.customerID == undefined ? 0 : props.customerID,
        country_id: props.locale.id,
        agent_id: orders_limited == true ? props.user.id : undefined,
        status: 2
    }

    const [precisions, setPrecisions] = useState(props.system.precisions == 0 ? 2 : 3);

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);


    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);


    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.name, default: true },
        { id: 2, enabled: true, checked: false, column: 'town', label: lang.town },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);


    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showOrderNew, setShowOrderNew] = useState(false);
    const [showCustomers, setShowCustomers] = useState(false);
    const [customerID, setCustomerID] = useState(0);
    const [customer, setCustomer] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [userID, setUserID] = useState(0);
    const [user, setUser] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const infoHeight = props.info == undefined ? 0 : 50;
    const menuHeight = 50;
    const rowHeight = 96;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const widthOffset = 20;
    const maxWidth = dialogWidth - 20;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);


    const db_get = async (page, filter, maxItems) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'orders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const GetFilter = () => {
        var filter = defaultFilter;

        if (search != '') {
            filter.search = search;
        }
        /*
        if (orderStateID > 0) {
            filter.status = orderStateID;
        }
        */
        if (customerID > 0) {
            filter.customer_id = customerID;
        }
        if (userID > 0) {
            filter.agent_id = userID;
            filter.limited = true;
        }
        return filter;
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            filter.search = txt;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowOrderNew(true);
    }

    const SelectItem = (item) => {
        func(true, item);
    }

    const PreviewItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);

    }
    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // DATUM
                column = 'created';
                break;

            case 2: // MENO PRIEZVISKO
                column = 'number';
                break;

            case 4: // STATUS
                column = 'status';
                break;

        }

        var filter_ = GetFilter();
        filter_.ordering = order;
        filter_.order_column = column;

        db_get(0, filter_, maxItems);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const OrderNewResult = (typ, redraw_, order) => {
        setShowOrderNew(false);
        if (redraw_ == true) {
            db_get(page, filter, maxItems);
            setItemSelected(order);
            setShowDialog(true);
        }
    }

    const CustomersResult = (typ, items, selected) => {
        setShowCustomers(false);

        if (typ == true && items != false) {
            setCustomerID(items[0]);
            setCustomer(selected[0]);

            var filter = GetFilter();
            filter.customer_id = items[0];

            db_get(0, filter, maxItems);
        }
    }

    const UserResult = (typ, selected) => {
        setShowUsers(false);

        if (typ == true && selected != false) {
            setUserID(selected.id);
            setUser(selected);

            var filter = GetFilter();
            filter.agent_id = selected.id;
            filter.limited = true;

            db_get(0, filter, maxItems);
        }
    }

    const UserOnly = () => {
        setUserID(props.user.id);
        setUser(props.user);

        var filter = GetFilter();
        filter.agent_id = props.user.id;
        filter.limited = true;

        db_get(0, filter, maxItems);
    }

    const CustomerReset = () => {
        setCustomerID(0);

        var filter = GetFilter();
        delete (filter.customer_id);
        setFilter(filter);

        db_get(0, filter, maxItems);
    }

    const UserReset = () => {
        setUserID(0);

        var filter = GetFilter();
        delete (filter.agent_id);
        delete (filter.limited);
        setFilter(filter);

        db_get(0, filter, maxItems);
    }

    const Close = () => {
        func(false);
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div id={'id_start'} style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomRightRadius: global.radius, borderBottomLeftRadius: global.radius }}>
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, marginTop: 10 }}>
                                <div style={{ ...styles.BlockLeft, width: maxWidth - widthOffset }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.BlockLeft, width: 240, height: menuHeight }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <TextInput search theme={props.theme} width={200} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                                <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                                    <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                                </div>
                                            </div>
                                        </div>
                                        {props.customerID == 0 || props.customerID == undefined ?
                                            <div style={{ ...styles.Block, width: 170, height: menuHeight }}>
                                                <Button onClick={() => setShowCustomers(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                                    {lang.customer}
                                                </Button>
                                            </div>
                                            : null}
                                        {props.customerID == undefined ?
                                            <div style={{ ...styles.Block, width: 170, height: menuHeight }}>
                                                <Button onClick={() => setShowUsers(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                                    {lang.customer_agent}
                                                </Button>
                                            </div>
                                            : null}
                                        {props.customerID == undefined ?
                                            <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                                <IconButton onClick={() => UserOnly(true)} style={{ ...styles.ButtonUser }}>
                                                    <FontAwesomeIcon style={{ height: 14 }} icon={faUser} />
                                                </IconButton>
                                            </div>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            {customerID > 0 ?
                                                <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{customer != false ? customer.company != '' ? customer.company : customer.name + ' ' + customer.surname : ''}</p>} onDelete={() => CustomerReset()}></Chip>
                                                : null}
                                            {userID > 0 ?
                                                <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.customers_of_rep}: {user.name + ' ' + user.surname}</p>} onDelete={() => UserReset()}></Chip>
                                                : null}
                                        </div>
                                    </div>

                                    {props.info != undefined ?
                                        <div style={{ ...styles.BlockCenter, height: infoHeight }}>
                                            <div style={{ ...styles.BlockRowRaw, backgroundColor: color.user_highlight, borderRadius: (infoHeight - 16) / 2 }}>
                                                <div style={{ ...styles.BlockLeft, width: undefined, height: infoHeight - 16 }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 5, marginLeft: 20 }} icon={faInfoCircle} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: undefined, height: infoHeight - 16 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', marginRight: 20 }}>{props.info}</p>
                                                </div>
                                            </div>
                                        </div>
                                        : null}

                                    <div style={{ ...styles.Block, marginTop: 20 }}>

                                        {/* ****************************************************************************************************************
                                                TABLE HEADER
                                        **************************************************************************************************************** */}

                                        <div style={{ ...styles.Block, marginBottom: 10, border: color.border }}>
                                            <div style={{ ...styles.BlockRow }}>
                                                <div style={{ ...styles.BlockRow, width: 100, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.date}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.order_number}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: props.system.distributors == true ? maxWidth - 50 - 100 - 150 - 150 - 150 - 150 - widthOffset : maxWidth - 50 - 100 - 150 - 150 - 150 - widthOffset, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.customer}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        {/*
                                                <MenuButton id={3} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                */}
                                                    </div>
                                                </div>
                                                {props.system.distributors == true ?
                                                    <div style={{ ...styles.Block, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.distributor}</p>
                                                    </div>
                                                    : null}
                                                <div style={{ ...styles.BlockRow, width: 140, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.order_sum_price_}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        {/*
                                                    <MenuButton id={4} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                    */}
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: 160, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.status}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <MenuButton id={4} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>

                                                </div>
                                            </div>
                                        </div>

                                        {/* ****************************************************************************************************************
                                            ZOZNAM OBJEDNAVOK
                                        **************************************************************************************************************** */}

                                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.status < global.orders.finished ? '4px solid ' + global.order_colors[item.status] : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '99%' }}>

                                                    {/* DATA */}
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny }}>{GetDatum2(item.created)}</p>
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny }}>{item.number}</p>
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: props.system.distributors == true ? maxWidth - 50 - 100 - 150 - 150 - 150 - 150 - widthOffset : maxWidth - 50 - 100 - 150 - 150 - 150 - widthOffset, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <ContactCompany company={item.company} name={item.name} surname={item.surname} theme={props.theme} />                                                    {item.agent != '' ?
                                                            <Chip variant='outlined' size='small' style={{ marginTop: 5 }} label={item.agent} />
                                                            : null}
                                                    </div>
                                                    {props.system.distributors == true ?
                                                        <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                            <p style={{ ...styles.TextTiny }}>{item.distributor}</p>
                                                        </div>
                                                        : null}
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRight, width: 140, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny }}>{FormatMoney(ConvertToPrice(item.price, precisions), precisions)} {item.currency}</p>
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 160, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        {/* STATUS */}
                                                        {item.products == 0 && item.status != global.orders.canceled ?
                                                            <Chip size='small' label={lang.order_empty} style={{ backgroundColor: global.order_colors[6], color: color.white, fontSize: global.font_tiny }}></Chip>
                                                            :
                                                            <Chip label={lang.order_status[item.status]} variant='outlined' size='small'
                                                                sx={{
                                                                    height: 'auto',
                                                                    '& .MuiChip-label': {
                                                                        display: 'block',
                                                                        whiteSpace: 'normal',
                                                                    },
                                                                }}
                                                                style={{ maxWidth: 120, padding: 2, borderColor: global.order_colors[item.status], color: global.order_colors[item.status], fontSize: global.font_tiny }}></Chip>
                                                        }
                                                        {parseInt(item.reklamacie) > 0 ?
                                                            <Chip label={lang.reklamacia} variant='outlined' size='small' style={{ marginTop: 10, borderColor: color.error, color: color.error, fontSize: global.font_tiny }}></Chip>
                                                            : null}
                                                    </div>

                                                    <div style={{ ...styles.Block, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                        {/* NAHLAD */}
                                                        <IconButton onClick={() => PreviewItem(item)} style={{ ...styles.ButtonIcon }}>
                                                            <FontAwesomeIcon style={{ width: 12, color: color.dark_gray }} icon={faEye} />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </Paper>
                                        )) :
                                            <div style={{ ...styles.Block, paddingTop: 20 }}>
                                                <EmptyList row small lang={lang} />
                                            </div>
                                        }

                                        {/* ****************************************************************************************************************
                                                PAGINATION
                                        **************************************************************************************************************** */}

                                        <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                            <div style={{ ...styles.Block, width: 200 }}></div>
                                            <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                                {pages > 1 ?
                                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                {itemsFiltered != false ?
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                            <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                        </div>
                                                        <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                            <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    {showUsers == true ?
                        <UsersSelect remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.stock_transport_approve_choose} token={props.token} func={UserResult.bind()} />
                        : null}

                    {showCustomers == true ?
                        <CustomerSelect remote={props.remote} remoteMobile={props.remoteMobile} single showVariations={true} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomersResult.bind()} />
                        : null}

                    {showOrderNew == true ?
                        <OrderNew remote={props.remote} remoteMobile={props.remoteMobile} customerID={props.customerID == undefined ? 0 : props.customerID} item={itemSelected} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} settings={props.settings} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={OrderNewResult.bind()} />
                        : null}

                    {showDialog == true ?
                        <Order remote={props.remote} remoteMobile={props.remoteMobile} module_id={module_id} item={itemSelected} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                        : null}

                    {showOK == true ?
                        <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                        : null}

                    {showError == true ?
                        <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                        : null}

                    {isBusy == true ?
                        <Loading offset={props.offset}></Loading>
                        : null}

                </div >

            </Paper >
        </Backdrop >
    );
}


export const OrderNew = (props) => {
    /*
            VYTVORENIE NOVEHO PREDAJA

            USAGE:
            <OrderNew module_id={module_id} item={itemSelected} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />

            const DialogInfoResult = (value) => {
                console.log(value)
            }
    */
    let stock_default = props.locale.stock_default;
    let currency = props.locale.currency;

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);

    // DATA
    const [stocks, setStocks] = useState(false);
    const [stockID, setStockID] = useState(-1);
    const [customer, setCustomer] = useState(false);
    const [customerID, setCustomerID] = useState(props.customerID);
    const [shop, setShop] = useState(false);
    const [shopID, setShopID] = useState(-1);
    const [shops, setShops] = useState(false); // ZOZNAM PREVADZOK
    const [priceListID, setPriceListID] = useState(-1);
    const [distributorPriceListID, setDistributorPriceListID] = useState(-1);
    const [priceLists, setPriceLists] = useState(false);
    const [priceList, setPriceList] = useState(false);
    const [address, setAddress] = useState(false);
    const [distributors, setDistributors] = useState(false);
    const [distributorID, setDistributorID] = useState(-1);
    const [type, setType] = useState(props.system.distributors == false ? 0 : true);
    const [orderTypes, setOrderTypes] = useState(false);
    const [orderTypeID, setOrderTypeID] = useState(0);


    // DIALOGS
    const [showCustomers, setShowCustomers] = useState(false);
    const [showSelectAddress, setShowSelectAddress] = useState(false);

    // SCREEN DIMS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 600;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    let column1 = 150;
    let column2 = dialogWidth - column1 - 40;
    let customerHeight = 300;

    let { func } = props;
    let color = global.themes[props.theme];
    let lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {

            if (props.customerID > 0) {
                db_get(props.customerID, 0);
            }

            db_data();

            running = true;
        }

        return () => {
        };

    }, []);


    const db_get = async (customer_id, shop_id) => {
        try {
            const response = await fetch(
                global.db_url + 'customer', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,

                    id: customer_id,
                    shop_id: shop_id
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setCustomer(json.item);
                setCustomerID(json.item.id);
                setAddress(json.address1);
                setShopID(shop_id);
                setShop(json.shop);
                setShops(json.shops);
                setPriceListID(json.item.price_list_id);
                setPriceList(json.price_list);
            }
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_data = async () => {
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.stocks != false) {
                    var tmp = json.stocks;
                    tmp = tmp.filter(x => x.type == 0);
                    if (tmp.length == 0) {
                        tmp = false;
                    }

                    setStocks(tmp);

                    if (json.stocks.length == 1) {
                        setStockID(json.stocks[0].id);
                    } else {
                        setStockID(stock_default);
                    }

                    setPriceLists(json.price_lists);
                    setDistributors(json.distributors);
                    if (json.order_types != false) {
                        var types = [{ id: 0, name: lang.order_type_mixed }];
                        json.order_types.forEach(item => {
                            types.push(item);
                            if (item.default_type == true) {
                                setOrderTypeID(item.id);
                            }
                        });
                        setOrderTypes(types);
                    }
                }

            }
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'order_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                func(true, true, json.data);
            } else {
                setShowError(false);
            }
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false, false);
    }

    const CustomersResult = (typ, customer_id, customer, address) => {
        setShowCustomers(false);

        if (typ == true) {
            var address_id = address == false ? 0 : address.id;
            setCustomer(customer_id);
            setShopID(address_id);
            db_get(customer_id, address_id);
        }
    }

    const Save = () => {
        var err = 0;
        setError('');

        var currency_ = currency;
        if (priceList != false) {
            currency_ = GetMoney(priceList.currency_id, props.system.currencies);
        }

        if (props.customerID > 0 && shops != false) {
            if (shopID < 1) {
                err++;
            }
        }
        if (stockID > 0 && priceListID > 0 && err == 0) {

            let data = {
                id: 0,
                enabled: true,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                distributor_id: distributorID < 0 ? 0 : distributorID,
                stock_id: stockID,
                customer_id: customerID,
                shop_id: shopID,
                user_id: props.user.id,
                price_list_id: priceListID,
                distributor_price_list_id: distributorPriceListID < 0 ? 0 : distributorPriceListID,
                currency_id: priceList == false ? customer.country_id : priceList.currency_id,
                currency: priceList == false ? currency : currency_,
                number: '',
                cost: 0,
                price: 0,
                products: 0,
                status: global.orders.created,
                note: '',
                default_order_type: props.system.order_types == true ? orderTypeID : 0,
            };

            db_update(data);
        } else {
            setError(lang.required_red);
        }
    }


    const ChangeStock = (id) => {
        setStockID(id);
        setError('');
    }

    const ChangeDistributor = (id) => {
        if (distributors != false) {
            var tmp = distributors.find(x => x.id == id);
            if (tmp != undefined) {
                setStockID(tmp.stock_id);
                setDistributorPriceListID(tmp.price_list_id);
                setDistributorID(id);
            }
        }
    }

    const ChangeType = (id) => {
        setStockID(-1);

        if (customer != false) {
            setPriceListID(customer.price_list_id);
        }
        setDistributorID(-1);
        setDistributorPriceListID(-1);
        setType(id);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faInfo} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.order_new}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockCenter }}>
                        <div style={{ ...styles.BlockCenter, backgroundColor: color.back }}>
                            <FormSpace />
                            <FormLabel editing={false} icon={faInfoCircle} title={lang.customer} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} />
                            {props.system.distributors == true ?
                                <div>
                                    <FormYesNo value={type} editing={true} title={lang.distribute_type} column1={column1} column2={column2} theme={props.theme} no={lang.distribute_type_stock} yes={lang.distribute_type_distributor} lang={lang} radius={props.radius} func={(id) => ChangeType(id)} />
                                    <FormSpace />
                                </div>
                                : null}
                            {type == true ?
                                <FormSelect require value={distributorID} items={distributors} field={'name'} editing={true} title={lang.distributor} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeDistributor(id)} />
                                : null}

                            {props.customerID > 0 && shops != false ?
                                <FormSelect require value={shopID == 0 ? -1 : shopID} items={shops} field={'name'} editing={true} title={lang.customer_shop} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setShopID(id)} />
                                : null}

                            {type == false ?
                                <div>
                                    <FormSelect require value={stockID} items={stocks} field={'name'} editing={true} title={lang.stock} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeStock(id)} />
                                    {/*
                                    <FormSelect require value={priceListID} items={priceLists} field={'name'} editing={true} title={lang.price_list} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setPriceListID(id)} />
                                    */}
                                </div>
                                : null}
                            <FormSpace />

                            {customer != false ?
                                <div>
                                    <FormText redraw value={customer.company} editing={false} title={lang.company_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormText redraw value={customer.name} editing={false} title={lang.name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormText redraw value={customer.surname} editing={false} title={lang.surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                </div>
                                : null}

                            {address != false ?
                                <div>
                                    <FormText redraw value={address.street + ' ' + address.town} editing={false} title={lang.address} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                </div>
                                : null}

                            {shop != false && props.customerID == 0 ?
                                <div>
                                    <FormSpace />
                                    <FormLabel editing={false} title={lang.customer_shop} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormText redraw value={shop.name} editing={false} title={lang.customer_shop_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormText redraw value={shop.street} editing={false} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormText redraw value={shop.town} editing={false} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                </div>
                                : null}

                            <FormSpace />
                            {props.system.order_types == true ?
                                <>
                                    <FormSelect require value={orderTypeID} items={orderTypes} field={'name'} editing={true} title={lang.order_type_default} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setOrderTypeID(id)} />
                                    {orderTypeID == 0 ?
                                        <FormNote title={lang.order_type_mixed_note} column1={column1} column2={column2} marginTop={5} theme={props.theme} lang={lang} radius={props.radius} />
                                        : null}
                                </>
                                : null}
                            <FormSpace />

                        </div>
                        {props.customerID == 0 ?
                            <div style={{ ...styles.Block, marginTop: 0 }}>
                                <Button onClick={() => setShowCustomers(true)} style={{ ...styles.ButtonOutlined, width: 200, marginTop: 20 }}>
                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faSearch} />
                                    {customer == false ? lang.customer_select : lang.customer_change}
                                </Button>
                            </div>
                            : null}
                    </div>

                    {props.remote == true && props.remoteMobile == true ?
                        <FormSpace height={500} />
                        : null}

                </div>
                <div style={{ ...styles.Block, height: 40 }}>
                    <FormError margin={0} error={error} small theme={props.theme} />
                </div>
                <div style={{ ...styles.Block, height: 60 }}>
                    {(type == false && customerID > 0 && priceListID > 0 && stockID > 0) || (type == true && distributorID > 0 && customerID > 0) ?
                        <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.order_create}</Button>
                        : null}
                </div>
            </Paper>

            {showCustomers == true ?
                <CustomerSelect shop_select shop_required single showVariations={true} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomersResult.bind()} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}


export const OrdersWindow = (props) => {

    let module_id = global.model.vendors;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'products_vendors';
    let { func } = props;

    useEffect(() => {


    }, [props.locale]);

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => func(true)} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }} >
                <Orders remote={props.remote} remoteMobile={props.remoteMobile} customerID={0} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={props.lang.orders} screenWidth={props.screenWidth} width={props.width} height={props.height - global.widow_bar_height} token={props.token} />
            </div>

        </Paper >
    );
}


export const OrderProduct = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let precisions = 2;
    let dph = parseFloat(props.system.dph);
    let service = props.item.product_id == 0 ? true : false;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [product, setProduct] = useState(false);
    const [price, setPrice] = useState(0);
    const [currency, setCurrency] = useState('');
    const [ranges, setRanges] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const [discount, setDiscount] = useState(1);
    const [orderType, setOrderType] = useState(props.item.order_type_id);
    const [orderTypeLabel, setOrderTypeLabel] = useState('');

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 800 ? 800 : height;
    const tableWidth = 110;

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        //parent_id: 0,
        enabled: true,
        //country_id: 1,
        array_id: [props.item.id]
    }

    const titleHeight = 160;
    const column1 = 220;
    const column2 = dialogWidth - column1 - 80;

    let { func } = props;

    useEffect(() => {

        setRanges(props.item.quantity_discount);
        setQuantity(props.item.quantity);
        setOrderType(props.item.order_type_id);
        var tmp = props.orderTypes.find(x => x.id == props.item.order_type_id);
        if (tmp != undefined) {
            setOrderTypeLabel(tmp.name);
        }

        if (props.item.product_id > 0) {
            var price_ = GetPriceDiscount(props.item.quantity_discount, props.item.price_final, parseInt(props.item.quantity))
            setPrice(price_);
        }
        setProduct(props.item);
        setCurrency(props.currency);
        setDiscount(props.item.discount_coeficient);


    }, []);

    const Close = () => {
        func(false);
    }


    const PlusMinusResult = (quantity, item) => {
        setQuantity(quantity);
    }

    const Save = () => {
        func(true, quantity, discount, orderType, orderTypeLabel);
    }

    const ChangeOrderType = (id) => {
        setOrderType(id);
        var tmp = props.orderTypes.find(x => x.id == id);
        if (tmp != undefined) {
            setOrderTypeLabel(tmp.name);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color_light }}>
                    <div style={{ ...styles.Block, width: titleHeight + 16, height: titleHeight }}>
                        <Avatar picture image={product.image} size={titleHeight - 16} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - titleHeight + 16 - 60 - 150, height: titleHeight, alignItems: 'flex-start' }}>
                        {product != false ? product.parent_id > 0 ?
                            <div style={{ ...styles.BlockLeft }}>
                                <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{product.parent_name}</p>
                                <p style={{ ...styles.TextXSmall }}>{product.name}</p>
                                <p style={{ ...styles.TextSmall, marginTop: 10 }}>{lang.price}: <span style={{ fontWeight: '600', color: color.dark_blue }}>{FormatMoney(price, precisions)} {currency}</span><span style={{ ...styles.TextTiny }}>{parseInt(product.quantity_package) > 1 ? ' / ' + lang.package : ''}</span></p>
                                {product.quantity_discount != false ?
                                    <p style={{ ...styles.TextTiny, marginTop: 2, color: color.dark_gray }}>{lang.price_list_range}</p>
                                    : null}
                            </div>
                            :
                            <div style={{ ...styles.BlockLeft }}>
                                <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{product.name}</p>
                                <p style={{ ...styles.TextSmall, marginTop: 10 }}>{lang.price}: <span style={{ fontWeight: '600', color: color.dark_blue }}>{FormatMoney(price, precisions)} {currency}</span><span style={{ ...styles.TextTiny }}>{parseInt(product.quantity_package) > 1 ? ' / ' + lang.package : ''}</span></p>
                                {product.quantity_discount != false ?
                                    <p style={{ ...styles.TextTiny, marginTop: 2, color: color.dark_gray }}>{lang.price_list_range}</p>
                                    : null}
                            </div>
                            : null}

                        {product != false ?
                            <div style={{ ...styles.BlockRowRaw, marginTop: 8 }}>
                                {product.product_flags != undefined ? product.product_flags != false ?
                                    product.product_flags.map((element, index) => (
                                        <div key={index} style={{ ...styles.Block, width: undefined, backgroundColor: global.flag_colors[element.color], borderRadius: 20, paddingTop: 2, paddingBottom: 2, marginRight: 6 }}>
                                            <p style={{ ...styles.TextTiny, color: color.white, marginLeft: 7, marginRight: 7, textAlign: 'center' }}>{element.name}</p>
                                        </div>
                                    ))
                                    : null : null}
                            </div>
                            : null}

                    </div>
                    <div style={{ ...styles.Block, width: 150, justifyContent: 'flex-start', paddingTop: 15 }}>
                        {props.item.product_id > 0 ? props.status < global.orders.finished && product.category != '' ?
                            <Chip label={product.category} style={{ backgroundColor: '#FFFFFF70' }} size='small' variant="outlined" />
                            : null : null}
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {product != false ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - 150, width: '96%', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {product.code != '' ?
                                <FormText value={product.code} editing={false} title={service == false ? lang.product_code : lang.service_code} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                : null}

                            <FormText value={FormatMoney(price, precisions) + ' ' + currency} editing={false} title={lang.price_withou_dph + (parseInt(product.quantity_package) > 1 ? '/' + lang.package : '')} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                            {parseInt(product.quantity_package) > 1 ?
                                <div>
                                    <FormText value={FormatMoney(price / parseInt(product.quantity_package), precisions) + ' ' + currency} editing={false} title={lang.price_ + ' ' + lang.withou_dph} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                </div>
                                : null}

                            {service == false && props.status < global.orders.finished ?
                                <div>
                                    {product.category != '' && product.category != null ?
                                        <FormText value={product.category} editing={false} title={lang.product_category} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                        : null}
                                    {product.manufacture != '' && product.manufacture != null ?
                                        <FormText value={product.manufacture} editing={false} title={lang.manufacturer} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                        : null}

                                    <FormText value={product.quantity_package} editing={false} title={lang.product_quantity_package} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={null} />

                                    {props.system.product_book_1 == true ?
                                        <FormSelect require value={product.book_1} items={false} field={'name'} codebook={global.codebook.product_book_1} editing={false} title={props.system.product_book_label_1} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={null} />
                                        : null}
                                    {props.system.product_book_2 == true ?
                                        <FormSelect require value={product.book_2} items={false} field={'name'} codebook={global.codebook.product_book_2} editing={false} title={props.system.product_book_label_1} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={null} />
                                        : null}
                                    {props.system.product_book_3 == true ?
                                        <FormSelect require value={product.book_3} items={false} field={'name'} codebook={global.codebook.product_book_3} editing={false} title={props.system.product_book_label_1} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={null} />
                                        : null}
                                </div>
                                : null}

                            <FormSpace />
                            <FormText percentage value={CoeficientToPercentage(props.item.discount_coeficient, 2) + '%'} editing={props.editable} title={lang.order_discount_percentage} column1={column1} column2={column2} width={200} theme={props.theme} lang={props.lang} radius={props.radius} func={(txt) => setDiscount(txt)} />
                            <FormNote title={lang.order_discount_item} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                            {service == false && props.system.order_types == true ?
                                <FormSelect require value={orderType} items={props.orderTypes} field={'name'} editing={props.editable} title={lang.order_type} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => ChangeOrderType(id)} />
                                : null}

                            {/*
                            <FormText value={FormatPieces(product.stock_quantity) + ' ' + product.unit} editing={false} title={lang.product_quantity_stock_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                            */}
                            {service == false && product.quantity_discount != false ?
                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    <div style={{ ...styles.BlockLeft, width: 3 * tableWidth }}>
                                        <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.discount_quantity}:</p>
                                        {product.quantity_discount.map((item, index) => (
                                            <div key={index} style={{ ...styles.BlockRowRaw, borderBottom: color.border }}>
                                                <div style={{ ...styles.BlockLeft, width: tableWidth }}>
                                                    <p style={{ ...styles.TextTiny }}>{lang.discount_from}: {item.from} {product.unit}</p>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: tableWidth }}>
                                                    {item.to > 990000 ?
                                                        <p style={{ ...styles.TextTiny }}>{lang.discount_more}</p>
                                                        :
                                                        <p style={{ ...styles.TextTiny }}>{lang.discount_to}: {item.to} {product.unit}</p>
                                                    }
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: tableWidth }}>
                                                    <p style={{ ...styles.TextTiny }}>{FormatMoney(item.price, precisions)} {currency}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                : null}
                        </div>
                        : null}

                    {product != false ?
                        <div style={{ ...styles.Block, height: 150, backgroundColor: color.back, borderBottomLeftRadius: global.radius, borderBottomRightRadius: global.radius }}>
                            {service == false ?
                                <>
                                    <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.order_quantity_}</p>
                                    {props.editable == true ?
                                        <PlusMinus min={0} value={product.quantity} theme={props.theme} lang={props.lang} func={PlusMinusResult.bind(this)} />
                                        :
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{product.quantity}</p>
                                    }
                                </>
                                : null}
                            {props.editable == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 160, marginTop: 30 }}>
                                    {lang.save_changes}
                                </Button>
                                :
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 160, marginTop: 30 }}>
                                    {lang.close}
                                </Button>

                            }
                        </div>
                        : null}
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


export const OrderSumar = (props) => {


    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 650;
    const dialogHeight = 300;

    let lang = props.lang;
    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);


    const Close = () => {
        func(false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faInfo} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.order_expedite_check}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.order_expedite_check_label}</p>
                    <p style={{ ...styles.TextXSmall, marginTop: 5 }}>{lang.distribute_type}: <b>{props.type == true ? lang.distribute_type_distributor : lang.distribute_type_stock}</b></p>
                    {props.error == '' ?
                        <div style={{ ...styles.Block }}>
                            {props.type == true ?
                                <div style={{ ...styles.Block, marginTop: 25 }}>
                                    <p style={{ ...styles.TextSmall }}>{lang.distributor}: <b>{props.distributor}</b></p>
                                </div>
                                :
                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    <p style={{ ...styles.TextXSmall }}>{lang.order_expedite_stock}: {props.stock}</p>
                                    <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{lang.order_expedite_price_list}: {props.price_list}</p>
                                </div>
                            }
                        </div>
                        :
                        <div style={{ ...styles.Block }}>
                            <p style={{ ...styles.TextSmall, color: color.error, marginTop: 25 }}>{lang.error}!</p>
                            <p style={{ ...styles.TextSmall, color: color.error, marginTop: 2, fontWeight: '600' }}>{props.error}</p>
                        </div>
                    }
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        {props.error == '' ?
                            <Button onClick={() => func(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 40 }}>{lang.order_expedite_button}</Button>
                            : null}
                        <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.cancel}</Button>
                    </div>
                </div>
            </Paper>
        </Backdrop >
    );
}


export const OrderError = (props) => {

    const [isBusy, setBusy] = useState(false);
    const [item, setItem] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 700 ? 700 : width;
    const dialogHeight = height >= 400 ? 400 : 400;


    let lang = props.lang;
    let { func } = props;
    let color = global.themes[props.theme];
    var running = false;

    useEffect(() => {
        if (running == false) {
            db_get(props.error_id)

            running = true;
        }

        return () => {
        };

    }, []);


    const db_get = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'order_error', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItem(json.item);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'order_update_raw', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                func(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(false);
    }

    const Save = () => {
        let data = {
            id: props.order_id,
            status: props.status == global.orders.create_error ? global.orders.ready_to_create : global.orders.ready_to_send
        }

        db_update(data);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faInfo} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.order_send_error}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 100, width: '96%', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {item != false ?
                            <>
                                <FormSpace />
                                <p style={{ ...styles.TextXSmall, textAlign: 'center', fontWeight: '600' }}>{lang.error} #{item.code}:</p>
                                <p style={{ ...styles.TextXSmall, textAlign: 'center' }}>{lang.order_pharminfo_codes[item.code]}</p>

                                <p style={{ ...styles.TextXSmall, textAlign: 'center', fontWeight: '600', marginTop: 20 }}>{lang.error_details}:</p>
                                <p style={{ ...styles.TextXSmall, textAlign: 'center' }}>{item.description}</p>
                                <p style={{ ...styles.TextXSmall, textAlign: 'center', marginTop: 5 }}>{item.details}</p>
                            </>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        {props.status == global.orders.create_error || props.status == global.orders.sending_error ?
                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 40 }}>{lang.order_resend}</Button>
                            : null}
                        <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>
        </Backdrop >
    );
}



export const OrderProtocol = (props) => {

    const [isBusy, setBusy] = useState(false);
    const [items, setItems] = useState(false);
    const [status, setStatus] = useState(-1);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 900 ? 900 : width;
    const dialogHeight = height >= 800 ? 800 : height;


    let lang = props.lang;
    let { func } = props;
    let color = global.themes[props.theme];
    var running = false;
    const rowHeight = 70;
    const buttonHeight = 80;
    const tableHeight = 40;

    useEffect(() => {
        if (running == false) {
            db_get()

            running = true;
        }

        return () => {
        };

    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'order_details', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    order_id: props.order_id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItems(json.item.items);
                setStatus(parseInt(json.item.status));
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faInfo} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.order_protocol}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, width: '98%' }}>
                        <div style={{ ...styles.Block, height: 70 }}>
                            {status > -1 ?
                                <>
                                    <p style={{ ...styles.TextTiny }}>{lang.order_status_}</p>
                                    <p style={{ ...styles.TextXSmall, textAlign: 'center', fontWeight: '600' }}>{lang.order_pharminfo_codes[status]}</p>
                                </>
                                : null}
                        </div>

                        <div style={{ ...styles.BlockRow, width: dialogWidth - 30, border: color.border, marginTop: 10, marginBottom: 10 }}>
                            {/* DATA */}
                            <div style={{ ...styles.BlockLeft, width: dialogWidth - 40 - 150 - 150, height: tableHeight }}>
                                <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product_name}</p>
                            </div>
                            <div style={{ ...styles.Block, width: 150, height: tableHeight, borderLeft: color.border }}>
                                <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 5 }}>{lang.order_protocol_amount}</p>
                            </div>
                            <div style={{ ...styles.Block, width: 150, height: tableHeight, borderLeft: color.border }}>
                                <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 5 }}>{lang.order_protocol_confirmed}</p>
                            </div>
                        </div>

                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - tableHeight - 10 - 70 - 5, width: dialogWidth - 20, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {items != false ?
                                items.map((item, index) => (
                                    <Paper
                                        key={index}
                                        elevation={1}
                                        style={{
                                            ...styles.Block,
                                            width: dialogWidth - 28,
                                            backgroundColor: color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowHeight,
                                            marginTop: 5, marginBottom: 5,
                                        }}
                                    >
                                        <div style={{ ...styles.BlockRow, width: dialogWidth - 60 }}>
                                            <div style={{ ...styles.BlockLeft, width: dialogWidth - 60 - 150 - 150, height: rowHeight }}>
                                                <TextLine text={item.name} fontSize={global.font_tiny} fontWeight={'600'} color={color.black} />
                                                <TextLine text={lang.order_pharminfo_item_codes[item.status]} fontSize={global.font_tiny} color={color.dark_gray} />
                                            </div>
                                            <div style={{ ...styles.Block, width: 150, height: rowHeight }}>
                                                <p style={{ ...styles.TextXSmall, textAlign: 'left' }}>{item.amount}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 150, height: rowHeight }}>
                                                <p style={{ ...styles.TextXSmall, textAlign: 'left' }}>{item.confirmedAmount}</p>
                                            </div>
                                        </div>
                                    </Paper>
                                ))
                                : null}
                        </div>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                </div>
            </Paper>

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}

