import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Loading } from './items';
import { styles } from './styles';
import './items.css';
import { ResetLogin } from './functions';

export const AppLogout = (props) => {

    const navigate = useNavigate();
    const [isBusy, setBusy] = useState(false);

    useEffect(() => {
        ResetLogin();
        var timer = setTimeout(() => {
            navigate('/');
        }, 1000);

        return () => {
        };

    }, []);


    return (
        <div style={{ ...styles.Block }}>
            <Loading center />
        </div>
    );
}