/*
*
* =================================================================================================================
* ZAKAZNICI

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { DialogTabs, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, ShowError, ShowOK, TextInput, Logs, Avatar, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormSelect, FormNote, FormNodes, FormAutoComplete, ShowInfo, ContactCompany, ButtonChangeStyle, ContactInfo, TextLine, NameSurname, DialogInfo } from './items';
import { styles } from './styles';
import { Backdrop, Box, Button, Checkbox, Chip, IconButton, ListItemIcon, ListItemText, MenuItem, Pagination, Paper, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faAt, faBars, faCheck, faFile, faFilter, faFolderTree, faFrown, faInfoCircle, faLayerGroup, faLocation, faLocationDot, faPlus, faPlusCircle, faQuestion, faRotateRight, faTableCells, faUser, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, ConvertToPrice, ConvertToPrice2, Debug, GetLeftPosition, GetPages, IsEmpty, LoadArray, LoadBool, LoadValue, SaveArray, SaveBool, SaveValue } from './functions';
import { Photos } from './photos';
import { CustomersPerson } from './customers_person.js';
import { CustomersCategorySelect } from './customers_categories.js';
import { UsersSelect } from './users_select.js';
import { CustomersAddress } from './customers_address.js';
import { CustomersGroup, CustomersGroupSelect } from './customers_groups.js';
import {
    DataGrid, skSK,
    GridColumnMenu,
} from '@mui/x-data-grid';
import { Help } from './Help.js';
import { Customer } from './customer.js';
import { DistributorsCodeChecker } from './distributors_export.js';
import { BatchUpdate } from './batch_update.js';


export const Customers = (props) => {

    let module_id = global.model.customers;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'customers';
    let limited = props.permissions.customers_limited == true && props.user.super_admin == false ? true : false;

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        country_id: props.locale.id,
        enabled: true,
        agent_id: limited == true ? props.user.id : undefined,
    }

    const [precisions, setPrecisions] = useState(props.system.precisions == 0 ? 2 : 3);

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrderingName = [
        { id: 1, enabled: true, label: lang.company_name, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.company_name, icon: faArrowDown },
        { id: 3, enabled: true, label: lang.surname, icon: faArrowUp },
        { id: 4, enabled: true, label: lang.surname, icon: faArrowDown }
    ];
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // SEARCH MENU
    const [filterMenu, setFilterMenu] = useState([
        { id: 1, checked: true, enabled: true, label: lang.products_filter_enabled },
        { id: 2, checked: false, enabled: true, label: lang.products_filter_unabled },
        { id: 3, checked: false, enabled: true, label: lang.filter_not_portal },
        { id: 4, checked: false, enabled: props.system.eshop_enabled == true ? true : false, label: lang.customers_b2b_show },
        { id: 5, checked: false, enabled: props.system.eshop_enabled == true ? true : false, label: lang.customers_b2c_show },
    ]);

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, checked: false, enabled: true, label: lang.export_selected },
        { id: 2, checked: false, enabled: true, label: lang.export_all },
        { id: 104, divider: true },
        { id: 6, checked: false, enabled: true, label: lang.dist_code_checker },
        { id: 30, divider: true },
        { id: 5, checked: LoadBool('customers-mode', true), enabled: true, label: lang.preview_mode },
        { id: 32, divider: true },
        { id: 3, checked: false, enabled: true, icon: faPlus, label: lang.customers_list_group_new },
        { id: 31, divider: true },
        { id: 7, checked: false, enabled: CheckPermission(props.user.super_admin, props.permissions[permission_column]).editing == true ? true : false, label: lang.batch_update },
        { id: 33, divider: true },
        { id: 4, checked: false, enabled: true, icon: faRotateRight, label: lang.refresh }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.customer_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    var columns = [
        {
            field: 'enabled',
            headerName: lang.enabled_,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'boolean',
            align: 'center',
            width: 60,
            renderCell: (params) => (params.value == false ? <FontAwesomeIcon style={{ width: 14, height: 14, color: color.dark_red }} icon={faXmarkCircle} /> : <FontAwesomeIcon style={{ width: 14, height: 14, color: color.black }} icon={faCheck} />),
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
        {
            field: 'company',
            headerName: lang.company_name,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 300,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: false,
            hide: false,
        },
        {
            field: 'surname',
            headerName: lang.surname,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 200,
            editable: false,
            sortable: false,
            sorting: true,
            hide: false,
        },
        {
            field: 'name',
            headerName: lang.name,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            //renderCell: (params) => (params.row.parent_id == 0 ? params.row.name : params.row.parent_name + ' - ' + params.row.name),
            type: 'string',
            align: 'left',
            width: 200,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'category',
            headerName: lang.customer_category,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 200,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: false,
            hide: false,
        },
        {
            field: 'phone',
            headerName: lang.phone,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 150,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'mobil',
            headerName: lang.mobil,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 150,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'email',
            headerName: lang.email,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 300,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            align: 'left',
        },
        {
            field: 'credit',
            headerName: lang.credit,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'number',
            renderCell: (params) => ConvertToPrice2(params.row.credit, precisions),
            align: 'right',
            width: 120,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
        {
            field: 'portal_enabled',
            headerName: lang.product_portal,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'boolean',
            align: 'center',
            width: 80,
            renderCell: (params) => (params.value == false ? <FontAwesomeIcon style={{ width: 14, height: 14, color: color.dark_red }} icon={faXmarkCircle} /> : <FontAwesomeIcon style={{ width: 14, height: 14, color: color.black }} icon={faCheck} />),
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
    ];

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(LoadArray('customers-table', {}));
    const [previewMode, setPreviewMode] = useState(LoadBool('customers-mode', true));

    // STYL ZOBRAZOVANIE - RIADKOVE / TABULKA
    const [displayStyle, setDisplayStyle] = useState(LoadValue('customers-style', 0));

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);

    // FILTERS
    const [showEnabled, setShowEnabled] = useState(true);
    const [showUnabled, setShowUnabled] = useState(false);
    const [showNotPortal, setShowNotPortal] = useState(false);
    const [showB2B, setShowB2B] = useState(false);
    const [showB2C, setShowB2C] = useState(false);
    const [category, setCategory] = useState(false);
    const [agent, setAgent] = useState(false);
    const [groupID, setGroupID] = useState(false);
    const [group, setGroup] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'company', label: lang.company_name, default: true },
        { id: 2, enabled: true, checked: true, column: 'surname', label: lang.surname },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showExportError, setShowExportError] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [showGroups, setShowGroups] = useState(false);
    const [showAgents, setShowAgents] = useState(false);
    const [showGroupError, setShowGroupError] = useState(false);
    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [showMyGroups, setShowMyGroups] = useState(false);
    const [showBatch, setShowBatch] = useState(false);

    // KONTROLA OBJEDNAVKOVYCH KODOV
    const [showDistributorCodes, setShowDistributorCodes] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 94;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const chipHeight = 50;
    const maxWidth = 1100;
    const previewHeight = 100;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            setSearch('');

            var filter = defaultFilter;

            if (props.params != false) {
                var columns = [];
                var search_arr = [];
                var search_label = '';

                if (props.params.my_customers == true) {
                    filter.agent_id = props.user.id;
                    setAgent(props.user);
                }

                if (props.params.user_id != undefined) {
                    filter.agent_id = props.params.user_id;
                    setAgent(props.params.user);
                }
                if (props.params.customer_id != undefined) {
                    setItemSelected(props.params.customer);
                    setShowDialog(true);
                }

                if (props.params.enabled == true) {
                    filter.enabled = true;
                    setShowEnabled(true);
                }

                if (props.params.customer_category_id != undefined) {
                    filter.category_id = props.params.customer_category_id;
                    setCategory({ label: props.params.customer_category });
                }

                if (props.params.customer_group_id != undefined) {
                    filter.group_id = props.params.customer_group_id;
                    setGroupID(props.params.customer_group_id);
                    setGroup(props.params.customer_group);
                }

                if (props.params.enabled == false) {
                    filter.disabled = true;
                    delete (filter.enabled);
                    filter.filters = true;
                    setShowUnabled(true);
                }

                if (props.params.name_surname != undefined) {
                    search_label = props.params.name_surname;
                    columns.push('name');
                    columns.push('surname');
                    var search_text = props.params.name_surname.split(' ');
                    if (search_text != undefined) {
                        search_text.forEach(element => {
                            search_arr.push(element);
                        });
                    }
                }

                if (props.params.company != undefined) {
                    search_label = search_label + ' ' + props.params.company;
                    columns.push('company');

                    var search_text = props.params.company.split(' ');
                    if (search_text != undefined) {
                        search_text.forEach(element => {
                            search_arr.push(element);
                        });
                    }
                }

                if (props.params.email != undefined) {
                    search_label = search_label + ' ' + props.params.email;
                    columns.push('email');

                    var search_text = props.params.email.split(' ');
                    if (search_text != undefined) {
                        search_text.forEach(element => {
                            search_arr.push(element);
                        });
                    }
                }

                if (search_arr.length > 0) {
                    filter.search = search_arr;
                    filter.search_columns = columns;
                }

                setSearch(search_label);

            }
            db_get(0, filter, maxItems);

            running = true;
        }

    }, [props.locale, props.params]);

    const db_get = async (page, filter, maxItems) => {
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customers', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_customers_add = async (id, data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customers_group_items_add', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    group_id: id,
                    customers: data
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.customers,
                    filename: lang.customers, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    function GetFilter() {
        var filter = defaultFilter;
        delete (filter.enabled);

        if (search.trim() != '') {
            filter.search = search;

            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default == true);
                columns.push(tmp.column);
            }

            var search_text = search.split(' ');
            if (search_text != undefined) {
                filter.search = search_text;
                filter.search_columns = columns;
            }
        }
        if (category != false) {
            filter.category_id = category.id;
        }
        if (agent != false) {
            filter.agent_id = agent.id;
        }
        if (showEnabled == true) {
            filter.enabled = true;
        } else {
            filter.filters = true;
        }
        if (showUnabled == true) {
            filter.disabled = true;
            filter.filters = true;
        }
        if (groupID > 0) {
            filter.group_id = groupID;
        }
        if (showNotPortal == true) {
            filter.portal = false;
            filter.filters = true;
        }
        if (showB2B == true) {
            filter.b2b = true;
            filter.b2c = false;
        }
        if (showB2C == true) {
            filter.b2b = false;
            filter.b2c = true;
        }
        return filter;
    }

    const FilterMenuResult = (id, index, item) => {
        var filter = GetFilter();
        setItemSelected(false);

        if (index == 1) {
            // LEN ENABLED
            let value = !showEnabled;
            item.checked = value;
            filterMenu[1].checked = false;
            delete (filter.disabled);
            setShowEnabled(value);
            setShowUnabled(false);
            if (value == true) {
                filter.enabled = true;
            } else {
                delete (filter.enabled);
                filter.filters = true;
            }
        }
        if (index == 2) {
            // LEN UNABLED
            let value = !showUnabled;
            item.checked = value;
            filterMenu[0].checked = false;
            delete (filter.enabled);
            setShowUnabled(value);
            setShowEnabled(false);
            if (value == true) {
                filter.disabled = true;
                filter.filters = true;
            } else {
                delete (filter.disabled);
            }
        }

        if (index == 3) {
            // LEN BEZ PRISTUPU NA PORTAL
            let value = !showNotPortal;
            item.checked = value;
            setShowNotPortal(value);
            if (value == true) {
                filter.portal = false;
                filter.filters = true;
            } else {
                delete (filter.portal);
            }
        }

        if (index == 4) {
            // LEN B2B ZAKAZNIKOV
            let value = !showB2B;
            item.checked = value;
            filterMenu[4].checked = false;
            setShowB2B(value);
            setShowB2C(false);
            if (value == true) {
                filter.b2b = true;
                delete (filter.b2c);
            } else {
                delete (filter.b2b);
                delete (filter.b2c);
            }
        }

        if (index == 5) {
            // LEN B2C ZAKAZNIKOV
            let value = !showB2C;
            item.checked = value;
            filterMenu[3].checked = false;
            setShowB2B(false);
            setShowB2C(value);
            if (value == true) {
                filter.b2c = true;
                delete (filter.b2b);
            } else {
                delete (filter.b2b);
                delete (filter.b2c);
            }
        }

        db_get(0, filter, maxItems);
    }

    const FiltersDelete = () => {
        var filter = GetFilter();

        delete (filter.filters);
        delete (filter.disabled);
        delete (filter.portal);
        filter.enabled = true;

        setShowEnabled(true);
        setShowUnabled(false);
        setShowNotPortal(false);

        filterMenu[0].checked = true;
        filterMenu[1].checked = false;
        filterMenu[2].checked = false;

        db_get(0, filter, maxItems);
    }

    const B2BDelete = () => {
        var filter = GetFilter();

        delete (filter.b2b);
        delete (filter.b2c);

        setShowB2B(false);
        setShowB2C(false);

        filterMenu[3].checked = false;
        filterMenu[4].checked = false;

        db_get(0, filter, maxItems);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const ChangeEnabled = (item, value) => {
        // ENABLED POLOZKY ZMENENY

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: item.id,
            action: value == true ? global.logs.enabled : global.logs.disabled
        };

        let data = {
            id: item.id,
            enabled: value,
            logs: log
        }
        db_update(data);
    }

    const MenuResult = (id, index, item) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1 || index == 2) {
            if (index == 1) {
                let tmp = items.find(x => x.selected === true);
                if (tmp == undefined) {
                    enable = false;
                }
            }

            if (enable == true) {
                setMenuIndex(index);
                setShowExport(true);
            } else {
                setShowExportError(true);
            }
        }
        if (index == 3) {
            // NOVA SKUPINA
            CreateGroup();
        }

        if (index == 4) {
            // REFRESH PAGE
            db_get(page, filter, maxItems);
        }

        if (index == 5) {
            // PREVIEW MODE
            var tmp = item;
            tmp.checked = previewMode == true ? false : true;
            setPreviewMode(!previewMode);
            SaveBool('customers-mode', !previewMode);
        }

        if (index == 6) {
            // Kontrola objednávkových kodov zákazníkov
            setShowDistributorCodes(true);
        }

        if (index == 7) {
            // BATCH UPDATE
            setShowBatch(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // NAZOV FIRMY (index: 1,2) PRIEZVISKO (index: 3,4)
                column = index == 1 || index == 2 ? 'company' : 'surname';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;
        }

        var filter = {
            ordering: order,
            order_column: column
        };
        if (search != '') {
            filter.search = search;
        }
        db_get(0, filter, maxItems);
    }
    /*
    const CreateGroup = () => {
        var tmp = itemsFiltered.filter(x => x.selected == true);

        if (tmp.length > 0) {
            setShowCreateGroup(true);
        } else {
            setShowGroupError(true);
        }
    }

    const CreateGroupResult = (typ, id) => {
        setShowCreateGroup(false);
        Debug(typ);
        Debug(id);
        if (typ == true) {
            var tmp = itemsFiltered.filter(x => x.selected == true);
            var array = [];
            tmp.forEach(item => {
                array.push(item.id);
            });

            db_customers_add(id, array);
        }
    }
    */
    const CreateGroup = () => {
        if (displayStyle == 0) {
            var tmp = itemsFiltered.filter(x => x.selected == true);

            if (tmp.length > 0) {
                setShowCreateGroup(true);
            } else {
                setShowGroupError(true);
            }
        } else {
            if (itemsSelected.length > 0) {
                setShowCreateGroup(true);
            } else {
                setShowGroupError(true);
            }
        }
    }

    const CreateGroupResult = (typ, id) => {
        setShowCreateGroup(false);
        if (typ == true) {
            if (displayStyle == 0) {
                var tmp = itemsFiltered.filter(x => x.selected == true);
                var array = [];
                tmp.forEach(item => {
                    array.push(item.id);
                });
                db_customers_add(id, array);
            } else {
                db_customers_add(id, itemsSelected);
            }
        }
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const CategoriesResult = (typ, selected) => {
        setShowCategories(false);

        if (typ == true) {
            setCategory(selected);

            var filter = GetFilter();
            filter.category_id = selected.id;
            db_get(0, filter, maxItems);
        }
    }

    const CategoryDelete = () => {
        var filter = GetFilter();
        delete filter.category_id;

        setCategory(false);
        setFilter(filter);
        db_get(0, filter, maxItems);
    }

    const MyGroupResult = (typ, item) => {
        setShowMyGroups(false);

        if (typ == true) {
            setGroup(item.label);
            setGroupID(item.id);

            var filter = GetFilter();
            filter.group_id = item.id;

            db_get(0, filter, maxItems);
        }
    }


    const MyGroupDelete = () => {
        var filter = GetFilter();
        delete filter.group_id;

        setGroupID(false);
        setFilter(filter);
        db_get(0, filter, maxItems);
    }

    const AgentsResult = (typ, selected) => {
        setShowAgents(false);

        if (typ == true) {
            setAgent(selected);
            var filter = GetFilter();
            filter.agent_id = selected.id;
            db_get(0, filter, maxItems);
        }
    }

    const UserOnly = () => {
        setAgent(props.user);
        var filter = GetFilter();
        filter.agent_id = props.user.id;
        db_get(0, filter, maxItems);
    }

    const AgentDelete = () => {
        var filter = GetFilter();
        delete filter.agent_id;

        setAgent(false);
        setFilter(filter);
        db_get(0, filter, maxItems);
    }


    const ChangeStyle = (value) => {
        setItems(false);
        setItemsFiltered(false);
        setItemSelected(false);
        setDisplayStyle(value);

        if (value == 0) {
            filter.parent_id = 0;
        } else {
            delete (filter.parent_id);
        }

        SaveValue('customers-style', value);
        db_get(0, filter, maxItems);
    }

    const SortUp = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.up;
        filter.order_column = data.colDef.field;
        db_get(0, filter, maxItems);
    }

    const SortDown = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.down;
        filter.order_column = data.colDef.field;
        db_get(0, filter, maxItems);
    }

    function MyMenuItem(props) {
        const { menuHandler, menuLabel, menuIcon } = props;
        return (
            <MenuItem onClick={menuHandler}>
                <ListItemIcon>
                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={menuIcon} />
                </ListItemIcon>
                <ListItemText>{menuLabel}</ListItemText>
            </MenuItem>
        );
    }

    function CustomColumnMenu(props) {
        // VLASTNÁ COLUMN MENU
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuSortItem: null,
                    columnMenuFilterItem: null,
                    columnMenuUserItem1: props.colDef.sorting == true ? MyMenuItem : null,
                    columnMenuUserItem2: props.colDef.sorting == true ? MyMenuItem : null,
                }}
                slotProps={{
                    columnMenuUserItem1: {
                        displayOrder: 1,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowUp,
                        menuHandler: () => SortUp(props),
                    },
                    columnMenuUserItem2: {
                        displayOrder: 0,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowDown,
                        menuHandler: () => SortDown(props),
                    },
                }}
            />
        );
    }

    const ChangeColumns = (data) => {
        // ZOBRAZIT / SKRYT STLPCE -> uloženie do cookies
        setColumnVisibilityModel(data);
        SaveArray('customers-table', data);
    }

    const DistributorCodeCheckerResult = () => {
        setShowDistributorCodes(false);
    }

    const BatchResult = (redraw_) => {
        setShowBatch(false);
        if (redraw_ == true) {
            db_get(page, filter, maxItems);
        }
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 270, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.widow_bar_height }}>
                    <ButtonChangeStyle value={displayStyle} theme={props.theme} func={(id) => ChangeStyle(id)} />
                </div>
                <div style={{ ...styles.Block, width: 50, height: global.widow_bar_height, marginRight: 20 }}>
                    <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                </div>
                <div style={{ ...styles.Block, width: 50, height: global.widow_bar_height, marginRight: 20 }}>
                    <Help link={lang.help_main} lang={props.lang} theme={props.theme} />
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.Block, maxWidth: displayStyle == 0 ? maxWidth : '98%', width: '100%' }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '25%', height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3, marginRight: 5 }}>
                                            <MenuButton id={0} icon={faFilter} backgroundColor={'#00000000'} color={color.dark_gray} label={''} menuItems={filterMenu} theme={props.theme} func={FilterMenuResult.bind()} />
                                        </div>
                                        <TextInput search redraw theme={props.theme} width={'100%'} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '45%', height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw, height: menuHeight }}>
                                        <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                            <Button onClick={() => setShowCategories(true)} style={{ ...styles.ButtonOutlined, width: 130, fontSize: global.font_tiny }}>
                                                <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faFolderTree} />
                                                {lang.customer_categories}
                                            </Button>
                                        </div>
                                        <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                            <Button onClick={() => setShowMyGroups(true)} style={{ ...styles.ButtonOutlined, width: 130, fontSize: global.font_tiny }}>
                                                <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faLayerGroup} />
                                                {lang.customers_groups_}
                                            </Button>
                                        </div>
                                        {limited == false ?
                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                <Button onClick={() => setShowAgents(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faUser} />
                                                    {lang.customer_agent}
                                                </Button>
                                            </div>
                                            : null}
                                        {limited == false ?
                                            <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                                <IconButton onClick={() => UserOnly(true)} style={{ ...styles.ButtonUser }}>
                                                    <FontAwesomeIcon style={{ height: 14 }} icon={faUser} />
                                                </IconButton>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '30%', height: menuHeight }}>
                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.customer_new}
                                        </Button>
                                        : null}
                                </div>
                            </div>

                            <div style={{ ...styles.BlockRow, height: chipHeight }}>
                                {filter != false ? filter.filters == true ?
                                    <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.cancel_filters}</p>} onDelete={() => FiltersDelete()}></Chip>
                                    : null : null}

                                {filter != false ? filter.b2b != undefined || filter.b2c != undefined ?
                                    <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{filter.b2b == true ? lang.customers_b2b : lang.customers_b2c}</p>} onDelete={() => B2BDelete()}></Chip>
                                    : null : null}

                                {filter != false ? filter.category_id != undefined ?
                                    <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{category.label}</p>} onDelete={() => CategoryDelete()}></Chip>
                                    : null : null}

                                {filter != false ? filter.group_id != undefined ?
                                    <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{group}</p>} onDelete={() => MyGroupDelete()}></Chip>
                                    : null : null}

                                {limited == false ? filter != false ? filter.agent_id != undefined ?
                                    <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{agent.surname} {agent.name}</p>} onDelete={() => AgentDelete()}></Chip>
                                    : null : null : null}
                            </div>

                            {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}
                            {displayStyle == 0 ?
                                <div style={{ ...styles.Block, width: '99%', border: color.border }}>
                                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                                        <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center' }}>
                                            {/* SELECT */}
                                            <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: '75%' }}>
                                            {/* DATA */}
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '40%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.customer}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <MenuButton id={1} sortMenu={true} menuItems={itemsOrderingName} theme={props.theme} func={MenuSortResult.bind()} />
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '40%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.address}</p>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: 150, height: tableLabelHeight, borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.customer_category}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: 100, borderLeft: color.border }}>
                                            {/* ENABLED */}
                                            <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.enabled}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {displayStyle == 0 ?
                                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - tableLabelHeight - chipHeight - 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <div id={'id_start'} style={{ ...styles.BlockCenter }}></div>

                                    {/* ****************************************************************************************************************
                                        ZOZNAM ZAKAZNIKOV
                                        **************************************************************************************************************** */}

                                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block,
                                                width: '99%',
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                    {/* SELECT */}
                                                    <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                                </div>

                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '75%' }}>
                                                    {/* DATA */}
                                                    <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <Avatar picture image={item.avatar} size={rowHeight - 20} theme={props.theme} />
                                                    </div>

                                                    <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <ContactCompany company={item.company} name={item.name} surname={item.surname} theme={props.theme} />
                                                        {item.agent != '' ?
                                                            <Chip variant='outlined' size='small' style={{ marginTop: 5 }} label={item.agent} />
                                                            : null}
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        {item.address != false}{
                                                            <div style={{ ...styles.BlockLeft }}>
                                                                <p style={{ ...styles.TextTiny }}>{item.address.street}</p>
                                                                <p style={{ ...styles.TextTiny }}>{item.address.psc} {item.address.town}</p>
                                                                <p style={{ ...styles.TextTiny }}>{item.address.state}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {item.category != '' ?
                                                        <Chip
                                                            style={{ marginTop: 2, marginBottom: 2 }}
                                                            sx={{
                                                                borderColor: color.dark_gray,
                                                                paddingTop: '2px',
                                                                paddingBottom: '2px',
                                                                height: 'auto',
                                                                '& .MuiChip-label': {
                                                                    display: 'block',
                                                                    whiteSpace: 'normal',
                                                                },
                                                            }}
                                                            label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_gray }}>{item.category}</p>} variant="outlined"
                                                        />
                                                        : null}
                                                    {props.system.eshop_enabled == true ?
                                                        item.b2b == true ?
                                                            <Chip
                                                                style={{ marginTop: 2, marginBottom: 2 }}
                                                                sx={{
                                                                    borderColor: color.dark_gray,
                                                                    paddingTop: '2px',
                                                                    paddingBottom: '2px',
                                                                    height: 'auto',
                                                                    '& .MuiChip-label': {
                                                                        display: 'block',
                                                                        whiteSpace: 'normal',
                                                                    },
                                                                }}
                                                                label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_gray }}>{lang.customer_b2b}</p>} variant="outlined"
                                                            />
                                                            : null : null}
                                                </div>
                                                <div style={{ ...styles.Block, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {/* ENABLED */}
                                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                        <SwitchEnable enabled={item.enabled == false ? false : true} theme={props.theme} func={(value) => ChangeEnabled(item, value)} />
                                                        : null}
                                                    {item.enabled == false ?
                                                        <div style={{ ...styles.Block, height: 20 }}>
                                                            <p style={{ ...styles.TextXXTiny, color: color.dark_gray }}>{lang.disabled}</p>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }

                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                        **************************************************************************************************************** */}

                                    <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {displayStyle == 1 ?
                                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - chipHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <Box sx={{
                                        height: previewMode == true ? props.height - global.widow_bar_height - menuHeight - chipHeight - 84 - previewHeight : props.height - global.widow_bar_height - menuHeight - chipHeight - 84,
                                        width: 1,
                                        /*
                                        '& .super-app-theme--header': {
                                            backgroundColor: color.light_gray,
                                        },
                                        */
                                        '& .super-app-theme--normal': {
                                            backgroundColor: color.white,
                                            color: '#000000',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.table_row),
                                            },
                                        },
                                        '& .super-app-theme--red': {
                                            backgroundColor: color.lighteen_blue,
                                            color: color.dark_red,
                                            fontWeight: 'normal',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.gray),
                                            },
                                        },
                                        '& .super-app-theme--blue': {
                                            backgroundColor: color.white,
                                            color: color.blue,
                                            fontWeight: 'normal',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.gray),
                                            },
                                        },
                                    }}>
                                        <DataGrid

                                            sx={{
                                                "& .MuiDataGrid-columnHeaderTitle": {
                                                    whiteSpace: "normal",
                                                    lineHeight: "normal"
                                                },
                                                "& .MuiDataGrid-columnHeader": {
                                                    height: "unset !important"
                                                },
                                                "& .MuiDataGrid-columnHeaders": {
                                                    maxHeight: "168px !important"
                                                },
                                                fontSize: global.font_table, border: global.theme_table_border_width, borderColor: color.dark_gray
                                            }}

                                            rows={itemsFiltered == false ? [] : itemsFiltered}
                                            columns={columns.filter(x => x.hide == false)}
                                            slots={{ columnMenu: CustomColumnMenu }}
                                            rowHeight={previewMode == true ? 30 : undefined}

                                            checkboxSelection
                                            disableRowSelectionOnClick
                                            onRowSelectionModelChange={(newRowSelectionModel) => { setItemsSelected(newRowSelectionModel) }}
                                            onRowClick={(params) => { setItemSelected(params.row) }}
                                            onRowDoubleClick={(params) => { SelectItem(params.row) }}
                                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                            getRowClassName={(params) => (params.row.enabled == 1 ? `super-app-theme--normal` : `super-app-theme--red`)}
                                            hideFooterRowCount={true}
                                            hideFooterSelectedRowCount={true}
                                            hideFooterPagination
                                            hideFooter
                                            columnVisibilityModel={columnVisibilityModel}
                                            onColumnVisibilityModelChange={(newModel) =>
                                                ChangeColumns(newModel)
                                            }
                                        />
                                    </Box>

                                    {previewMode == true && itemSelected != false ?
                                        <div style={{ ...styles.Block, height: previewHeight, marginTop: 5 }}>
                                            <div style={{ ...styles.BlockRow, width: '100%' }}>
                                                <div style={{ ...styles.BlockLeft, width: previewHeight, height: previewHeight, justifyContent: 'center' }}>
                                                    <Avatar picture image={itemSelected.image} size={previewHeight - 10} theme={props.theme} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '40%', height: previewHeight, justifyContent: 'center' }}>
                                                    {itemSelected.company != '' ?
                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{itemSelected.company}</p>
                                                        : null}
                                                    <p style={{ ...styles.TextXSmall, fontWeight: itemSelected.company != '' ? '400' : '600' }}>{itemSelected.surname} {itemSelected.name}</p>
                                                    <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{itemSelected.code}</p>
                                                    {itemSelected.category != '' ?
                                                        <Chip
                                                            style={{ marginTop: 2, marginBottom: 2 }}
                                                            sx={{
                                                                borderColor: color.dark_gray,
                                                                paddingTop: '2px',
                                                                paddingBottom: '2px',
                                                                height: 'auto',
                                                                '& .MuiChip-label': {
                                                                    display: 'block',
                                                                    whiteSpace: 'normal',
                                                                },
                                                            }}
                                                            label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_gray }}>{itemSelected.category}</p>} variant="outlined"
                                                        />
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '40%', height: previewHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, width: '90%', marginLeft: 5 }}>
                                                        <ContactInfo mobil={itemSelected.mobil} phone={itemSelected.phone} email={itemSelected.email} theme={props.theme} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                        **************************************************************************************************************** */}
                                    <div style={{ ...styles.BlockRow, paddingTop: 10, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                : null}

                        </div>
                    </div>
                </div>
            </div>

            {showBatch == true ?
                <BatchUpdate model_id={global.model.customers} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={BatchResult.bind()} />
                : null}

            {showDistributorCodes == true ?
                <DistributorsCodeChecker customer remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={DistributorCodeCheckerResult.bind(this)} />
                : null}

            {showCreateGroup == true ?
                <CustomersGroup remote={props.remote} remoteMobile={props.remoteMobile} index={0} item={false} module_id={global.model.customers_groups} organization={props.organization} locale={props.locale} settings={props.settings} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CreateGroupResult.bind()} />
                : null}

            {showExport == true ?
                <DialogExport remote={props.remote} remoteMobile={props.remoteMobile} rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <Customer remote={props.remote} remoteMobile={props.remoteMobile} module_id={module_id} item={itemSelected} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showCategories == true ?
                <CustomersCategorySelect remote={props.remote} remoteMobile={props.remoteMobile} module_id={module_id} item={itemSelected} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CategoriesResult.bind()} />
                : null}

            {showMyGroups == true ?
                <CustomersGroupSelect remote={props.remote} remoteMobile={props.remoteMobile} country_id={props.locale.id} organization={props.organization} settings={props.settings} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={MyGroupResult.bind()} />
                : null}

            {showAgents == true ?
                <UsersSelect lremote={props.remote} remoteMobile={props.remoteMobile} imited={limited == true ? true : undefined} groups organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.user_select} token={props.token} func={AgentsResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showGroupError == true ?
                <ShowInfo text={lang.customers_list_group_error} sub_text={''} lang={lang} theme={props.theme} func={() => setShowGroupError(false)} />
                : null}

            {showExportError == true ?
                <ShowInfo text={lang.export_none_error} sub_text={lang.export_none_error_} lang={lang} theme={props.theme} func={() => setShowExportError(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}



/*
*
* =================================================================================================================
    VYBER ZAKAZNIKA

    single          -> vyber len jedneho
    shop_select     -> výber prevádzky
    shop_required   -> vyzaduje sa prevadzka
* =================================================================================================================
*
*/

export const CustomerSelect = (props) => {

    let module_id = global.model.customers;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'customers';
    let limited = props.permissions.customers_limited == true && props.user.super_admin == false ? true : false;

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        country_id: props.locale.id,
        enabled: true,
        agent_id: limited == true ? props.user.id : undefined,
        excluded: props.excluded == undefined || props.excluded == false ? undefined : props.excluded
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrderingName = [
        { id: 1, enabled: true, label: lang.company_name, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.company_name, icon: faArrowDown },
        { id: 3, enabled: true, label: lang.surname, icon: faArrowUp },
        { id: 4, enabled: true, label: lang.surname, icon: faArrowDown }
    ];
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // SEARCH MENU
    const [filterMenu, setFilterMenu] = useState([
        { id: 1, checked: true, enabled: true, label: lang.products_filter_enabled },
        { id: 2, checked: false, enabled: true, label: lang.products_filter_unabled },
    ]);

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.customer_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [addresses, setAddresses] = useState(false);

    // FILTERS
    const [showEnabled, setShowEnabled] = useState(false);
    const [showUnabled, setShowUnabled] = useState(false);
    const [category, setCategory] = useState(false);
    const [agent, setAgent] = useState(false);
    const [groupID, setGroupID] = useState(0);
    const [group, setGroup] = useState(0);

    // VYBER EMAILOVEJ ADRESY
    const [customerID, setCustomerID] = useState(0);
    const [customer, setCustomer] = useState(false);
    const [showSelectEmail, setShowSelectEmail] = useState(false);
    const [showSelectAddress, setShowSelectAddress] = useState(false);
    const [showNoneAddress, setShowNoneAddress] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'company', label: lang.company_name, default: true },
        { id: 2, enabled: true, checked: true, column: 'surname', label: lang.surname },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [showAgents, setShowAgents] = useState(false);
    const [showMyGroups, setShowMyGroups] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1100 ? 1100 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;


    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 94;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const buttonHeight = 80;
    const chipHeight = 50;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);

    const db_get = async (page, filter, maxItems) => {
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customers', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.customers,
                    filename: lang.customers, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_address = async (id, item) => {
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_addresses', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    customer_id: id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.items != false) {
                    setAddresses(json.items);
                    setShowSelectAddress(true);
                } else {
                    if (props.shop_required == true) {
                        setShowNoneAddress(true);
                    } else {
                        func(true, item.id, item, false);
                    }
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    function GetFilter() {
        var filter = defaultFilter;

        if (search.trim() != '') {
            filter.search = search;

            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default == true);
                columns.push(tmp.column);
            }

            var search_text = search.split(' ');
            if (search_text != undefined) {
                filter.search = search_text;
                filter.search_columns = columns;
            }
        }
        if (category != false) {
            filter.category_id = category.id;
        }
        if (agent != false) {
            filter.agent_id = agent.id;
        }
        if (showEnabled == true) {
            filter.enabled = true;
        }
        if (showUnabled == true) {
            filter.disabled = true;
        }

        if (groupID > 0) {
            filter.group_id = groupID;
        }
        return filter;
    }

    const FilterMenuResult = (id, index, item) => {
        var filter = GetFilter();
        setItemSelected(false);

        filterMenu.forEach(element => {
            element.checked = false;
        });

        delete (filter.enabled);
        delete (filter.disabled);

        setShowEnabled(false);
        setShowUnabled(false);

        if (index == 1) {
            // LEN ENABLED
            let value = !showEnabled;
            item.checked = value;
            setShowEnabled(value);
            if (value == true) {
                filter.enabled = true;
            }
        }
        if (index == 2) {
            // LEN UNABLED
            let value = !showUnabled;
            item.checked = value;
            setShowUnabled(value);
            if (value == true) {
                filter.disabled = true;
            }
        }

        db_get(0, filter, maxItems);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_customers_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }

            Debug('element','orange');
            Debug(element);
            
        }, global.goto_start_delay);
    }

    const ChangeEnabled = (item, value) => {
        // ENABLED POLOZKY ZMENENY

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: item.id,
            action: value == true ? global.logs.enabled : global.logs.disabled
        };

        let data = {
            id: item.id,
            enabled: value,
            logs: log
        }
        db_update(data);
    }

    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // NAZOV FIRMY (index: 1,2) PRIEZVISKO (index: 3,4)
                column = index == 1 || index == 2 ? 'company' : 'surname';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;
        }

        var filter = {
            ordering: order,
            order_column: column
        };
        if (search != '') {
            filter.search = search;
        }
        db_get(0, filter, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const CategoriesResult = (typ, selected) => {
        setShowCategories(false);

        if (typ == true) {
            setCategory(selected);

            var filter = GetFilter();
            filter.category_id = selected.id;
            db_get(0, filter, maxItems);
        }
    }

    const CategoryDelete = () => {
        var filter = GetFilter();
        delete filter.category_id;

        setCategory(false);
        db_get(0, filter, maxItems);
    }

    const MyGroupResult = (typ, item) => {
        setShowMyGroups(false);

        if (typ == true) {
            setGroup(item.label);
            setGroupID(item.id);

            var filter = GetFilter();
            filter.group_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const AgentsResult = (typ, selected) => {
        setShowAgents(false);

        if (typ == true) {
            setAgent(selected);
            var filter = GetFilter();
            filter.agent_id = selected.id;
            db_get(0, filter, maxItems);
        }
    }

    const UserOnly = () => {
        setAgent(props.user);
        var filter = GetFilter();
        filter.agent_id = props.user.id;
        db_get(0, filter, maxItems);
    }

    const AgentDelete = () => {
        var filter = GetFilter();
        delete filter.agent_id;

        setAgent(false);
        db_get(0, filter, maxItems);
    }

    const GroupDelete = () => {
        var filter = GetFilter();
        delete filter.group_id;

        setGroupID(0);
        db_get(0, filter, maxItems);
    }

    const SelectItem = (item) => {
        // VYBER JEDNEJ POLOZKY

        if (props.email == true) {
            setCustomerID(item.id);
            setCustomer(item);
            setShowSelectEmail(true);
        } else {
            if (props.shop_select == true) {
                setCustomerID(item.id);
                setCustomer(item);
                db_address(item.id, item);
            } else {
                func(true, [item.id], [item]);
            }
        }
    }

    const EmailResult = (typ, email) => {
        setShowSelectEmail(false);

        if (typ == true) {
            func(true, customerID, customer, email);
        }
    }

    const SelectClose = () => {
        // MULTI SELECT
        var selected_id = [];
        var selected_items = [];
        items.forEach(item => {
            if (item.selected == true) {
                selected_id.push(item.id);
                selected_items.push(item);
            }
        });
        if (selected_id.length == 0) {
            selected_id = false;
            selected_items = false;
        }

        func(true, selected_id, selected_items);
    }

    const AddressResult = (typ, item) => {
        setShowSelectAddress(false);

        if (typ == true) {
            func(true, customerID, customer, item);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2100 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.customer_select_}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - buttonHeight }}>
                        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                            <div style={{ ...styles.Block, width: '96%' }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '30%', height: menuHeight }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.Block, width: 30, paddingTop: 3, marginRight: 5 }}>
                                                <MenuButton id={0} icon={faFilter} backgroundColor={'#00000000'} color={color.dark_gray} label={''} menuItems={filterMenu} theme={props.theme} func={FilterMenuResult.bind()} />
                                            </div>
                                            <TextInput search theme={props.theme} width={'100%'} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                            <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                                <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '40%', height: menuHeight }}>
                                        <div style={{ ...styles.BlockRowRaw, height: menuHeight }}>
                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 20 }}>
                                                <Button onClick={() => setShowCategories(true)} style={{ ...styles.ButtonOutlined, width: 120, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faFolderTree} />
                                                    {lang.customer_categories}
                                                </Button>
                                            </div>
                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                <Button onClick={() => setShowMyGroups(true)} style={{ ...styles.ButtonOutlined, width: 130, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faLayerGroup} />
                                                    {lang.customers_groups_}
                                                </Button>
                                            </div>
                                            {limited == false ?
                                                <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                    <Button onClick={() => setShowAgents(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                                        <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faUser} />
                                                        {lang.customer_agent}
                                                    </Button>
                                                </div>
                                                : null}
                                            <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                                <IconButton onClick={() => UserOnly(true)} style={{ ...styles.ButtonUser }}>
                                                    <FontAwesomeIcon style={{ height: 14 }} icon={faUser} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '30%', height: menuHeight }}>
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockRow, height: chipHeight }}>
                                    {category != false ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{category.label}</p>} onDelete={() => CategoryDelete()}></Chip>
                                        : null}

                                    {agent != false ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{agent.surname} {agent.name}</p>} onDelete={() => AgentDelete()}></Chip>
                                        : null}

                                    {groupID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{group}</p>} onDelete={() => GroupDelete()}></Chip>
                                        : null}
                                </div>

                                <div style={{ ...styles.Block }}>

                                    {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}

                                    <div style={{ ...styles.Block, border: color.border }}>
                                        <div style={{ ...styles.BlockRow, width: '99%' }}>
                                            <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center' }}>
                                                {/* SELECT */}
                                                <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    {props.single == undefined ?
                                                        <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                                        : null}
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '85%' }}>
                                                {/* DATA */}
                                                <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: '40%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.customer}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <MenuButton id={1} sortMenu={true} menuItems={itemsOrderingName} theme={props.theme} func={MenuSortResult.bind()} />
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '40%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.address}</p>
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: 150, height: tableLabelHeight, borderLeft: color.border }}>
                                                {/* ENABLED */}
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.customer_category}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - menuHeight - tableLabelHeight - chipHeight - buttonHeight - 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                        <div id={'id_customers_start'} style={{ ...styles.Block }}></div>

                                        {/* ****************************************************************************************************************
                                        ZOZNAM ZAKAZNIKOV
                                        **************************************************************************************************************** */}

                                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: item.enabled == false ? color.disabled : item.can_buy == false ? color.lighteen_red : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                    <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                        {/* SELECT */}
                                                        {props.single == undefined ?
                                                            <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                                            : null}
                                                    </div>

                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '85%' }}>
                                                        {/* DATA */}
                                                        <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                            <Avatar picture image={item.avatar} size={rowHeight - 20} theme={props.theme} />
                                                        </div>

                                                        <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                            <ContactCompany company={item.company} name={item.name} surname={item.surname} theme={props.theme} />
                                                            {item.agent != '' ?
                                                                <Chip variant='outlined' size='small' style={{ marginTop: 5 }} label={item.agent} />
                                                                : null}
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                            {item.address != false}{
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <p style={{ ...styles.TextTiny }}>{item.address.street}</p>
                                                                    <p style={{ ...styles.TextTiny }}>{item.address.psc} {item.address.town}</p>
                                                                    <p style={{ ...styles.TextTiny }}>{item.address.state}</p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        {item.can_buy == false ?
                                                            <Chip
                                                                variant='filled'
                                                                style={{ backgroundColor: color.error }}
                                                                label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.white }}>{lang.customer_can_not_buy}</p>}
                                                            />
                                                            : null}
                                                        {item.category != '' ?
                                                            <Chip
                                                                style={{ marginTop: 2, marginBottom: 2 }}
                                                                sx={{
                                                                    borderColor: color.dark_gray,
                                                                    paddingTop: '2px',
                                                                    paddingBottom: '2px',
                                                                    height: 'auto',
                                                                    '& .MuiChip-label': {
                                                                        display: 'block',
                                                                        whiteSpace: 'normal',
                                                                    },
                                                                }}
                                                                label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_gray }}>{item.category}</p>} variant="outlined"
                                                            />
                                                            : null}
                                                    </div>
                                                </div>
                                            </Paper>
                                        )) :
                                            <EmptyList row small lang={lang} />
                                        }

                                        {/* ****************************************************************************************************************
                                            PAGINATION
                                        **************************************************************************************************************** */}

                                        <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                            <div style={{ ...styles.Block, width: 200 }}></div>
                                            <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                                {pages > 1 ?
                                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                        <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                        <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => SelectClose()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.ok}</Button>
                    </div>
                </div>

                {showNoneAddress == true ?
                    <DialogInfo title={lang.customer_address_none} text={lang.customer_address_none_text} sub_text={lang.customer_address_none_note} button={lang.close} icon={faFrown} icon_color={color.red} theme={props.theme} radius={props.radius} func={() => setShowNoneAddress(false)} />
                    : null}

                {showSelectAddress == true ?
                    <CustomerSelectAdddress items={addresses} module_id={module_id} item={itemSelected} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={AddressResult.bind()} />
                    : null}

                {showSelectEmail == true ?
                    <CustomerSelectEmail customerID={customerID} module_id={module_id} item={itemSelected} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={EmailResult.bind()} />
                    : null}

                {showCategories == true ?
                    <CustomersCategorySelect module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CategoriesResult.bind()} />
                    : null}

                {showMyGroups == true ?
                    <CustomersGroupSelect country_id={props.locale.id} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={MyGroupResult.bind()} />
                    : null}

                {showAgents == true ?
                    <UsersSelect groups organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.user_select} token={props.token} func={AgentsResult.bind()} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}


            </Paper >
        </Backdrop>
    );
}




export const CustomerSelectEmail = (props) => {
    /*

            Výber emailovej adresy daného zákazníka

    */
    let lang = props.lang;
    let module_id = global.model.contacts;

    const [isBusy, setBusy] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [email, setEmail] = useState('');

    const [enabled, setEnabled] = useState(false);
    const [customer, setCustomer] = useState(0);
    const [address1, setAddress1] = useState(false);
    const [address2, setAddress2] = useState(false);
    const [persons, setPersons] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width > 600 ? 600 : width;
    const dialogHeight = height > 750 ? 750 : height;

    let { func } = props;
    const color = global.themes[props.theme];

    var running = false;
    useEffect(() => {

        if (running == false) {

            db_get();

            running = true;
        }

        return () => {
        };

    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_info', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.customerID,
                })
            })

            const json = await response.json();
            Debug('CUSTOMER INFO');
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    setCustomer(json.item);
                    setAddress1(json.address1);
                    setAddress2(json.address2);
                    setPersons(json.persons);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(false, false);
    }

    const Proccess = () => {
        func(true, email);
    }

    const SelectEmail = (email) => {
        if (email.trim() != '') {
            if (props.one_click == true) {
                func(true, email);
            } else {
                setEmail(email);
            }
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2500 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius, backgroundColor: color.back }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faAt} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.email_address}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 80, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, height: 70, backgroundColor: color.user_highlight }}>
                        {email == '' ?
                            <p style={{ ...styles.TextXSmall }}>{lang.email_selected_none}</p>
                            :
                            <>
                                <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.email_selected}</p>
                                <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{email}</p>
                            </>
                        }
                    </div>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 80 - 70, width: '100%', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.Block, width: '98%' }}>
                            {customer.email != '' ?
                                <Paper onClick={() => SelectEmail(customer.email)} elevation={global.elevation} style={{ ...styles.Block, minHeight: 50, width: '98%', paddingTop: 10, paddingBottom: 10, marginTop: 10, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextXSmall, fontWeight: '600', color: color.darker_gray }}>{lang.email_customer}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 8, color: color.darker_gray }}>{customer.email}</p>
                                </Paper>
                                : null}

                            {address1 != false ?
                                address1.email != '' ?
                                    <Paper onClick={() => SelectEmail(address1.email)} elevation={global.elevation} style={{ ...styles.Block, minHeight: 50, width: '98%', paddingTop: 10, paddingBottom: 10, marginTop: 10, cursor: 'pointer' }}>
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600', color: color.darker_gray }}>{lang.email_facture}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 8, color: color.darker_gray }}>{address1.email}</p>
                                    </Paper>
                                    : null : null}

                            {address2 != false ?
                                <>
                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600', color: color.darker_gray }}>{lang.customer_shipping_address}</p>
                                    </div>
                                    {address2.map((item, index) => (
                                        <Paper key={index} onClick={() => SelectEmail(item.email)} elevation={global.elevation} style={{ ...styles.Block, minHeight: 70, width: '98%', paddingTop: 10, paddingBottom: 10, marginTop: 10, cursor: 'pointer' }}>
                                            <p style={{ ...styles.TextTiny, color: color.darker_gray }}>{item.street}</p>
                                            <p style={{ ...styles.TextTiny, color: color.darker_gray }}>{item.town}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 8, color: color.darker_gray }}>{item.email == '' ? lang.email_empty : item.email}</p>
                                        </Paper>
                                    ))}
                                </>
                                : null}

                            {persons != false ?
                                <>
                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600', color: color.darker_gray }}>{lang.customer_contact_person}</p>
                                    </div>
                                    {persons.map((item, index) => (
                                        <Paper key={index} onClick={() => SelectEmail(item.email)} elevation={global.elevation} style={{ ...styles.Block, minHeight: 50, width: '98%', paddingTop: 10, paddingBottom: 10, marginTop: 10, cursor: 'pointer' }}>
                                            <NameSurname name={item.name} surname={item.surname} fontSize={global.font_tiny} color={color.darker_gray} settings={props.settings} theme={props.theme} />
                                            <p style={{ ...styles.TextTiny, marginTop: 8, color: color.darker_gray }}>{item.email == '' ? lang.email_empty : item.email}</p>
                                        </Paper>
                                    ))}
                                </>
                                : null}
                        </div>


                        <div style={{ minHeight: global.list_padding }}></div>
                    </div>

                </div>
                <div style={{ ...styles.Block, height: 80 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        {email != '' ?
                            <div style={{ ...styles.Block, width: 200, height: 80 }}>
                                <Button onClick={() => Proccess()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 160 }}>{lang.ok}</Button>
                            </div>
                            : null}
                        <div style={{ ...styles.Block, width: 200, height: 80 }}>
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 160 }}>{lang.close}</Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {
                showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null
            }

        </Backdrop >
    );
}



export const CustomerSelectAdddress = (props) => {
    /*

            Výber adresy daného zákazníka

    */
    let lang = props.lang;
    let module_id = global.model.contacts;

    const [isBusy, setBusy] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width > 600 ? 600 : width;
    const dialogHeight = height > 750 ? 750 : height;

    const rowHeight = 80;

    let { func } = props;
    const color = global.themes[props.theme];

    var running = false;
    useEffect(() => {

        if (running == false) {

            running = true;
        }

        return () => {
        };

    }, []);



    const Close = () => {
        func(false, false);
    }

    const SelectItem = (item) => {
        func(true, item);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2500 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius, backgroundColor: color.back }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLocation} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.route_address_select}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 80, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 80 - 70, width: '100%', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.Block, width: '98%' }}>
                            {props.items != false ? props.items.map((item, index) => (
                                <Paper key={index} style={{ ...styles.BlockRow, width: '98%', height: rowHeight, marginTop: 5, marginBottom: 5, borderRadius: global.radius }}>
                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: 50, height: rowHeight }}>
                                        <FontAwesomeIcon style={{ height: 26, color: color.light_gray, marginLeft: 15 }} icon={faLocationDot} />
                                    </div>
                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '90%', height: rowHeight }}>
                                        {item.name != '' ?
                                            <TextLine text={item.name} fontWeight='600' fontSize={global.font_xsmall} color={color.black} />
                                            : null}
                                        <TextLine text={item.street} fontSize={global.font_xsmall} color={color.black} />
                                        <TextLine text={item.town} fontSize={global.font_xsmall} color={color.black} />
                                    </div>
                                </Paper>
                            )) : null}
                        </div>


                        <div style={{ minHeight: global.list_padding }}></div>
                    </div>

                </div>
                <div style={{ ...styles.Block, height: 80 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 160 }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>

        </Backdrop >
    );
}

