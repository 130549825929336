import React, { useEffect, useState } from 'react';

import './App.css';
import './globals.js';
import './themes.js';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { styles } from './styles';
import './items.css';

// IMAGES
import img_background from './react/app/background_intro.jpg';
import img_background_small from './react/app/background_intro_small.jpg';
import { Backdrop, Button, IconButton, InputAdornment, Paper, TextField, useMediaQuery } from '@mui/material';
import { faAngleRight, faEye, faAngleLeft, faCheckCircle, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Debug, GetLanguage, LoadLanguage, LoadLogin, SaveLanguage, SaveLogin } from './functions';
import { DialogEnterPin, DialogEnterText, DialogInfo, Loading, ShowError } from './items';

export const Start = () => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    // APLIKACIA
    const [appVersion, setAppVersion] = useState('');
    const [language, setLanguage] = useState(1);
    const [token, setToken] = useState('');
    const [logged, setLogged] = useState(true);

    // VZHLAD APKY
    const [offset, setOffset] = useState(16);
    const [radius, setRadius] = useState(16);
    const [showRegister, setShowRegister] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');
    const isMedium = useMediaQuery('(max-width:' + global.device_medium + 'px)');


    const logo = require('./react/app/logo.png');
    const flag_sk = require('./react/app/flag_sk.png');
    const flag_cz = require('./react/app/flag_cz.png');
    const flag_en = require('./react/app/flag_en.png');

    const logo_size = isSmall == true ? 300 : 400;
    const dialogWidth = isSmall == true ? 300 : 600;
    const flag_size = 40;

    const buttonHeight = 56;
    const buttonWeight = 180;
    const buttonRadius = 10;
    const imageSize = 42;

    var lang = GetLanguage(language);
    var color = global.themes[0];

    useEffect(() => {

        if (width < global.device_small) {
            // MOBILE
            setRadius(0);
            setOffset(0);
        } else {
            // DESKTOP
            setRadius(global.radius);
            setOffset(global.offset);

            // RELOAD PAGE - AK SA ZMENI VELKOST OKNA               
            function handleResize() {
                setWidth(window.innerWidth);
                setHeight(window.innerHeight);
            }
            window.addEventListener('resize', handleResize);
        }

        SaveLanguage(0);
        lang = LoadLanguage();

        var login = LoadLogin();
        if (login.token != '') {

            navigate('/application');
        } else {

        }

    }, [])

    const ChangeLanguage = (value) => {
        setLanguage(value);
        SaveLanguage(value);
    }

    const Login = () => {
        setShowLogin(true);
    }

    const DialogLoginResult = (user, token) => {
        setShowLogin(false);
        if (user != false) {
            if (token != '') {
                SaveLogin({ token: token, expire: 0 });
                navigate('/application');
            }
        }
    }

    return (
        <div style={{ ...styles.Block, height: height, alignItems: 'flex-start', justifyContent: 'flex-start', fontFamily: 'roboto', msOverflowStyle: 'none', scrollbarWidth: 'none', backgroundColor: color.black, backgroundSize: 'cover' }} className='container'>
            <div style={{ ...styles.Block, height: height }}>
                <img src={logo} style={{ width: '100%', height: '100%', maxWidth: logo_size, maxHeight: logo_size / 3, objectFit: 'contain' }}></img>
                <div style={{ ...styles.Block, width: 280, height: flag_size + 20, backgroundColor: '#FFFFFF20', borderRadius: (flag_size + 20) / 2, marginTop: 20, border: '0px solid #303030' }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <div onClick={() => ChangeLanguage(1)} style={{ ...styles.Block, width: flag_size + 20, cursor: 'pointer' }}>
                            <img src={flag_sk} style={{ width: '100%', height: '100%', maxWidth: flag_size, maxHeight: flag_size, objectFit: 'contain', opacity: language == 1 ? 1.0 : 0.2 }}></img>
                        </div>
                        <div onClick={() => ChangeLanguage(2)} style={{ ...styles.Block, width: flag_size + 20, cursor: 'pointer' }}>
                            <img src={flag_cz} style={{ width: '100%', height: '100%', maxWidth: flag_size, maxHeight: flag_size, objectFit: 'contain', opacity: language == 2 ? 1.0 : 0.2 }}></img>
                        </div>
                        <div onClick={() => ChangeLanguage(3)} style={{ ...styles.Block, width: flag_size + 20, cursor: 'pointer' }}>
                            <img src={flag_en} style={{ width: '100%', height: '100%', maxWidth: flag_size, maxHeight: flag_size, objectFit: 'contain', opacity: language == 3 ? 1.0 : 0.2 }}></img>
                        </div>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: dialogWidth, marginTop: 100 }}>
                    <Button onClick={() => Login()} style={{ ...styles.ButtonThemed, backgroundColor: color.dark, width: 200, height: 46, borderRadius: 23 }}>
                        <p style={{ ...styles.TextSmall, letterSpacing: 1.5, fontWeight: '600', color: color.white }}>{lang.login}</p>
                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginLeft: 10 }} icon={faAngleRight} />
                    </Button>
                    {/*
                    <Button onClick={() => Register()} style={{ ...styles.ButtonLink, color: color.white, marginTop: 30 }}>{lang.register}</Button>
                    */}
                </div>

            </div>

            {
                showLogin == true ?
                    <DialogLogin theme={0} lang={lang} radius={16} language={language} func={DialogLoginResult.bind(this)} />
                    : null
            }
        </div >
    );
}



export const DialogLogin = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogLogin lang={props.lang} radius={props.radius} func={DialogLoginResult.bind(this)} />

            const DialogLoginResult = (user) => {
                console.log(user)
            }

    */
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [username, setUsername] = useState(global.testing == true ? 'info@vgvstudio.sk' : '');
    const [password, setPassword] = useState(global.testing == true ? '33333333' : '');
    const [showPassword, setShowPassword] = useState(false);
    const [password2, setPassword2] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [codeSystem, setCodeSystem] = useState('');
    const [userID, setUserID] = useState(0);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);

    const [user, setUser] = useState(false);
    const [token, setToken] = useState(false);
    const [pinCode, setPinCode] = useState('');
    const [showEnterPin, setShowEnterPin] = useState(false);


    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const logo = require('./react/app/logo_vision.png');
    const logo_size = 150;

    const dialogWidth = 350;
    const dialogHeight = 480;

    let { func } = props;
    let color = global.themes[0];
    let lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);


    const db_login = async () => {
        // Prihlásenie sa

        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'login', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: username,
                        password: password,
                        language: props.language
                    })
                })

                const json = await response.json();
                Debug(json);
                if (json.ok > 0) {
                    if (json.login == true) {
                        // VSETKO OK
                        if (json.pin != '') {
                            setUser(json.user);
                            setToken(json.token);
                            setPinCode(json.pin);
                            setShowEnterPin(true);
                        } else {
                            Press(json.user, json.token);
                        }
                    } else {
                        // ZLE PRIHLASOVACIE UDAJE
                        setUser(false);
                        setToken(false);
                        setError(props.lang.login_error);
                    }
                } else {
                    setShowError(true);
                }
                setBusy(false);

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    }

    const db_recover = async () => {
        // obnova hesla - kontrola e-mailovej adresy
        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'login_email', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        language: props.language,
                    })
                })

                const json = await response.json();
                Debug(json);
                if (json.ok > 0) {
                    if (json.checked == true) {
                        setCodeSystem(json.code);
                        setUserID(json.user_id);
                        setIndex(2);
                    } else {
                        setError(props.lang.lost_login_error);
                    }

                } else {
                    setShowError(true);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const db_recover_update = async (data) => {
        // zmena hesla
        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'login_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        data: data
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    // predvyplnit prihlasovací dialog s novym heslom
                    setUsername(email);
                    setIndex(4);
                } else {
                    setShowError(true);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }


    const Press = (user, token) => {
        func(user, token);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }


    const Login = () => {

        setError('');
        if (username.trim() != '' && password.trim() != '') {
            db_login();
        }
    }

    const Home = () => {
        setError('');
        setUsername('');
        setPassword('');
        setIndex(0);
    }


    const Forgot = () => {
        setError('');
        setEmail('');
        setPassword('');
        setPassword2('');
        setCode('');
        setIndex(1);
    }

    const ForgotProcess = () => {
        // KONTROLA EMAILOVEJ ADRESY
        setError('');
        if (email.trim != '') {
            db_recover();
        }
    }

    const ForgotCheckCode = () => {
        // KONTROLA ZADANEHO KODU
        setError('');
        if (codeSystem == code.trim()) {
            setIndex(3);
        } else {
            setError(props.lang.login_pin_code_error);
        }
    }

    const ChangePassword = () => {
        // ULOZENIE NOVEHO HESLA
        setError('');
        var p1 = password.trim();
        var p2 = password2.trim();

        if (p1.length < 8) {
            setError(props.lang.password_error_len);
        } else if (p1 != p2) {
            setError(props.lang.passwords_not_equal);
        } else {
            var data = {
                id: userID,
                password: p1
            }
            db_recover_update(data);
        }
    }

    const PinResult = (typ, pin) => {
        setShowEnterPin(false);
        if (typ == true) {
            if (pinCode == pin) {
                setBusy(true);
                var timer = setTimeout(() => {
                    Press(user, token);
                }, 750);
            }
        } else {
            setUsername('');
            setPassword('');
        }
    }

    return (

        <Backdrop open={true} style={{ backgroundColor: '#000000FF' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, position: 'relative', height: dialogHeight, background: color.dialog_background, borderRadius: props.radius }}>

                <Paper elevation={6} style={{ ...styles.Block, position: 'relative', left: dialogWidth / 2 - ((logo_size + 0) / 2), width: logo_size, top: -(logo_size) / 2, height: logo_size, backgroundColor: color.white, borderRadius: (logo_size + 40) / 2 }}>
                    <img src={logo} style={{ width: '100%', height: '100%', maxWidth: logo_size, maxHeight: logo_size, objectFit: 'contain', marginTop: 0 }}></img>
                </Paper>

                {index == 0 ?
                    // PRIHLASENIE
                    <div style={{ ...styles.BlockCenter, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <p style={{ ...styles.TextXLarge, letterSpacing: 2, fontWeight: '500', marginTop: 20 }}>{props.lang.login.toUpperCase()}</p>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 30 }}>
                            <p style={{ ...styles.TextInput }}>{props.lang.loginname}</p>
                            <TextField
                                value={username}
                                onInput={e => setUsername(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: color.white, borderRadius: 20 },
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <p style={{ ...styles.TextInput }}>{props.lang.password}</p>
                            <TextField
                                value={password}
                                onInput={e => setPassword(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} style={{ width: 20, height: 20 }}>
                                            <FontAwesomeIcon style={{ height: 14, color: showPassword == true ? color.dark_gray : color.light_gray }} icon={faEye} />
                                        </IconButton>
                                    </InputAdornment>,
                                    style: { backgroundColor: color.white, borderRadius: 20 },
                                }}
                                variant="outlined"
                                type={showPassword == true ? 'text' : 'password'}
                            />
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <Button onClick={() => Forgot()} style={{ ...styles.ButtonLink, width: 200 }}>{props.lang.lost_password_button}</Button>
                        </div>
                        <div style={{ ...styles.Block, height: 40 }}>
                            <p style={{ ...styles.TextSmall, color: color.dark_red }}>{error}</p>
                        </div>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <Button onClick={() => Login()} style={{ ...styles.ButtonThemed, backgroundColor: color.dark }}>{props.lang.login_button}</Button>
                        </div>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 20 }}>
                            <Button onClick={() => Press(false, '')} style={{ ...styles.ButtonLink }}>{props.lang.close}</Button>
                        </div>

                    </div>
                    : null}

                {index == 1 ?
                    // ZABUDNUTE HESLO
                    <div style={{ ...styles.BlockCenter, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <p style={{ ...styles.TextXLarge, letterSpacing: 2, fontWeight: '500', marginTop: 20 }}>{props.lang.lost_password.toUpperCase()}</p>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 80 }}>
                            <p style={{ ...styles.TextInput }}>{props.lang.login_email}</p>
                            <TextField
                                value={email}
                                onInput={e => setEmail(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: color.white, borderRadius: 20 },
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div style={{ ...styles.Block, height: 60 }}>
                            <p style={{ ...styles.TextSmall, color: color.dark_red }}>{error}</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, width: 50 }}>
                                    <IconButton onClick={() => Home()} style={{ width: 36, height: 36, backgroundColor: color.white }}>
                                        <FontAwesomeIcon style={{ width: 10, color: color.dark_blue }} icon={faAngleLeft} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => ForgotProcess()} style={{ ...styles.ButtonThemed, backgroundColor: color.dark }}>{props.lang.continue}</Button>
                                </div>
                                <div style={{ ...styles.Block, width: 50 }}></div>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 2 ?
                    // VLOZENIE PIN KODU
                    <div style={{ ...styles.BlockCenter, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <p style={{ ...styles.TextXLarge, letterSpacing: 2, fontWeight: '500', marginTop: 20 }}>{props.lang.lost_password.toUpperCase()}</p>
                        <div style={{ ...styles.Block, width: '95%', height: 40 }}>
                            <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 10 }}>{props.lang.login_email_send}</p>
                        </div>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 40 }}>
                            <p style={{ ...styles.TextInput }}>{props.lang.login_pin_code}</p>
                            <TextField
                                value={code}
                                onInput={e => setCode(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: color.white, borderRadius: 20 },
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div style={{ ...styles.Block, height: 60 }}>
                            <p style={{ ...styles.TextSmall, color: color.dark_red }}>{error}</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, width: 50 }}>
                                    <IconButton onClick={() => Home()} style={{ width: 36, height: 36, backgroundColor: color.white }}>
                                        <FontAwesomeIcon style={{ width: 10, color: color.dark_blue }} icon={faAngleLeft} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => ForgotCheckCode()} style={{ ...styles.ButtonThemed, backgroundColor: color.dark }}>{props.lang.continue}</Button>
                                </div>
                                <div style={{ ...styles.Block, width: 50 }}></div>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 3 ?
                    // ZADANIE NOVEHO HESLA
                    <div style={{ ...styles.BlockCenter, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <p style={{ ...styles.TextXLarge, letterSpacing: 2, fontWeight: '500', marginTop: 20 }}>{props.lang.lost_password.toUpperCase()}</p>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 40 }}>
                            <p style={{ ...styles.TextInput }}>{props.lang.password_new}</p>
                            <TextField
                                value={password}
                                onInput={e => setPassword(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: color.white, borderRadius: 20 },
                                }}
                                variant="outlined"
                                type='password'
                            />
                        </div>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 20 }}>
                            <p style={{ ...styles.TextInput }}>{props.lang.password_new_again}</p>
                            <TextField
                                value={password2}
                                onInput={e => setPassword2(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: color.white, borderRadius: 20 },
                                }}
                                variant="outlined"
                                type='password'
                            />
                        </div>

                        <div style={{ ...styles.Block, height: 60 }}>
                            <p style={{ ...styles.TextSmall, color: color.dark_red }}>{error}</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, width: 50 }}>
                                    <IconButton onClick={() => Home()} style={{ width: 36, height: 36, backgroundColor: color.white }}>
                                        <FontAwesomeIcon style={{ width: 10, color: color.dark_blue }} icon={faAngleLeft} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => ChangePassword()} style={{ ...styles.ButtonThemed, backgroundColor: color.dark }}>{props.lang.save}</Button>
                                </div>
                                <div style={{ ...styles.Block, width: 50 }}></div>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 4 ?
                    // HESLO BOLO ZMENENE
                    <div style={{ ...styles.Block, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <FontAwesomeIcon style={{ height: 30, color: color.darker_green }} icon={faCheckCircle} />
                        <p style={{ ...styles.TextNormal, marginTop: 20 }}>{props.lang.password_ok}</p>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 50 }}>
                            <Button onClick={() => setIndex(0)} style={{ ...styles.ButtonThemed, backgroundColor: color.dark }}>{props.lang.continue}</Button>
                        </div>
                    </div>
                    : null}

            </Paper>


            {showEnterPin == true ?
                <DialogEnterPin center title={lang.sms_code} label={lang.sms_code_sended} pin={''} verify_pin={pinCode} button={lang.continue} lang={lang} theme={props.theme} radius={props.radius} func={PinResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading theme={props.theme} center={true}></Loading>
                : null}

            {showError == true ?
                <ShowError theme={props.theme} center={true} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}


        </Backdrop>
    );
}



