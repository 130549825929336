/*
*
* =================================================================================================================
* PRODUKTY

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={props.locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    TYP:    1 - štandart
            2 - variant
            3 - balík

* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';

import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormAutoComplete, FormNote, AutoCompleteInput, FormRadio, FormChips, ShowInfo, DeleteButton, FormFlags, FormNodes, FormSelect, Note, FormNodeChips, FormSelectChips, ButtonChangeStyle, FormInfo, TextLine, StockStatus, PlusMinus, FormSwitch, FormParams, FormCodes } from './items';
import { styles } from './styles';
import { Backdrop, Box, Button, Checkbox, Chip, IconButton, ListItemIcon, ListItemText, MenuItem, Pagination, Paper, Skeleton, Stack, TextField, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowPointer, faArrowUp, faBars, faCheck, faCheckCircle, faCircle, faCircleRight, faCopy, faDollar, faDownload, faFileExport, faFilter, faFlag, faFolderTree, faInfo, faInfoCircle, faLayerGroup, faList, faLocation, faLock, faMagnifyingGlass, faPallet, faPen, faPlus, faPlusCircle, faQuestion, faRefresh, faRotateRight, faTableCells, faUpload, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, ConvertToPrice, ConvertToPrice2, Debug, FormatMoney, FormatPieces, GetLeftPosition, GetPages, GetPrice, IsEmpty, LoadBool, LoadValue, SaveBool, SaveValue, LoadArray, SaveArray, UniqueID, FormatMoneyDPH } from './functions';
import { Photos } from './photos';
import { Today } from './functions_date';
import { ProductCategorySelect } from './product_categories';
import { ManufacturerSelect } from './manufacturers';
import { VendorSelect } from './vendors';
import { BatchUpdate } from './batch_update';
import { ProductsGroup, ProductsGroupSelect } from './product_groups';
import { ProductSelect } from './product_select.js';
import { ConditionDesigner } from './condition_designer.js';
import { Conditions } from './conditions.js';
import { StockHistory } from './stock_history.js';
import { ProductFlagsSelect } from './product_flags.js';
import {
    DataGrid, skSK,
    GridColumnMenu,
} from '@mui/x-data-grid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DialogColors, WebPageDesigner } from './webpage_designer.js';
import { Gallery } from './gallery.js';
import { DistributorsCodeChecker, DistributorsCodes } from './distributors_export.js';
import { Product, ProductParams } from './product.js';

export const Products = (props) => {

    let module_id = global.model.products;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'products';
    let currency = props.locale.currency;
    let codes = props.system.product_codes;
    let show_cost = props.system.show_cost_prices == false ? false : props.user.super_admin == true ? true : props.permissions.show_cost == false ? false : true;
    let show_prices_dph = props.system.show_prices_dph == false ? false : true;

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        country_id: props.locale.id,
        parent_id: 0,
        enabled: true
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowDown },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowUp }
    ];

    const itemsOrdering2 = [
        { id: 1, enabled: true, label: lang.product_name, icon: faArrowDown },
        { id: 2, enabled: true, label: lang.product_name, icon: faArrowUp },
        { id: 100, divider: true },
        { id: 3, enabled: true, label: lang.document_number, icon: faArrowDown },
        { id: 4, enabled: true, label: lang.document_number, icon: faArrowUp },
        { id: 101, divider: true },
        { id: 5, enabled: true, label: lang.product_code, icon: faArrowDown },
        { id: 6, enabled: true, label: lang.product_code, icon: faArrowUp },
    ];

    // EXPORT UDAJOV
    const exportColumns = [
        { id: 1, column: 'name', label: lang.product_name, typ: 'text', align: 'left' },
        { id: 2, column: 'code', label: lang.product_code, typ: 'text', align: 'left' },
        { id: 3, column: 'quantity_stock', label: lang.product_quantity_stock_, typ: 'integer', align: 'left' },
        { id: 10, column: 'cost', label: lang.product_cost, typ: 'currency', align: 'right' },
        { id: 11, column: 'price', label: lang.product_price, typ: 'currency', align: 'rigth' },
        { id: 12, column: 'price_retail', label: lang.product_price_retail + ' - ' + lang.temporary, typ: 'currency', align: 'rigth' },
        { id: 13, column: 'cost_temp', label: lang.product_cost + ' - ' + lang.temporary, typ: 'currency', align: 'rigth' },
        { id: 14, column: 'price_temp', label: lang.product_price + ' - ' + lang.temporary, typ: 'currency', align: 'rigth' },
        { id: 15, column: 'price_retail_temp', label: lang.product_price_retail + ' - ' + lang.temporary, typ: 'currency', align: 'rigth' },
        { id: 16, column: 'dph', label: lang.product_dph, typ: 'currency', align: 'rigth' },
        { id: 17, column: 'description_short', label: lang.product_description_short, typ: 'text', align: 'left' },
        { id: 18, column: 'date_start', label: lang.product_date_start, typ: 'text', align: 'center' },
        { id: 19, column: 'date_end', label: lang.product_date_end, typ: 'text', align: 'center' },
        { id: 25, column: 'quantity_package', label: lang.product_quantity_package, typ: 'text', align: 'center' },
        { id: 26, column: 'unit', label: lang.product_unit, typ: 'text', align: 'center' },
        { id: 30, column: 'note', label: lang.note, typ: 'text', align: 'left' },
        { id: 98, column: 'variations', label: lang.product_variations_, typ: 'boolean', align: 'center' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean', align: 'center' },
    ];

    // SEARCH MENU
    const [filterMenu, setFilterMenu] = useState([
        { id: 1, checked: true, enabled: true, label: lang.products_filter_enabled },
        { id: 2, checked: false, enabled: true, label: lang.products_filter_unabled },
        { id: 3, checked: false, enabled: true, label: lang.products_filter_not_started },
        { id: 4, checked: false, enabled: true, label: lang.products_filter_ended },
        { id: 5, checked: false, enabled: props.system.conditions_enabled, label: lang.products_filter_condition },
        { id: 6, checked: false, enabled: true, label: lang.products_filter_not_published },
        { id: 7, checked: false, enabled: true, label: lang.products_filter_no_category },
    ]);

    // EXPORT
    const [itemsExportMenu, setItemsExportMenu] = useState(
        [
            { id: 1, checked: false, enabled: CheckPermission(props.user.super_admin, props.permissions[permission_column]).export == true ? true : false, label: lang.export_selected },
            { id: 2, checked: false, enabled: CheckPermission(props.user.super_admin, props.permissions[permission_column]).export == true ? true : false, label: lang.export_all },
            { id: 104, divider: true },
            { id: 12, checked: false, enabled: true, label: lang.dist_code_checker },
            { id: 101, divider: true },
            { id: 3, checked: true, enabled: true, label: lang.show_decimal },
            { id: 4, checked: false, enabled: true, label: lang.show_manufacture },
            { id: 6, checked: true, enabled: true, label: lang.show_variations },
            { id: 9, checked: false, enabled: true, label: lang.product_show_dph },
            { id: 10, checked: true, enabled: true, label: lang.product_show_typ },
            { id: 8, checked: LoadBool('products-mode', true), enabled: true, label: lang.preview_mode },
            { id: 102, divider: true },
            { id: 7, checked: false, enabled: CheckPermission(props.user.super_admin, props.permissions['products_groups']).write == true ? true : false, icon: faPlus, label: lang.product_group_new },
            { id: 103, divider: true },
            { id: 5, checked: false, enabled: CheckPermission(props.user.super_admin, props.permissions[permission_column]).editing == true ? true : false, label: lang.batch_update },
            { id: 11, checked: false, enabled: true, label: lang.refresh, icon: faRotateRight },
        ]
    );


    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.product_name, default: true },
        { id: 2, enabled: true, checked: true, column: 'code', label: lang.product_code },
        { id: 4, enabled: codes[3].enabled == true ? true : false, checked: false, column: 'code_vendor', label: lang.product_code_vendor_ },
        { id: 5, enabled: codes[4].enabled == true ? true : false, checked: false, column: 'code_ean', label: lang.product_code_ean },
        { id: 3, enabled: true, checked: true, column: 'tags', label: lang.product_tags }
    ]);

    // TABLE
    var columns = [
        {
            field: 'enabled',
            headerName: lang.enabled_,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'boolean',
            align: 'center',
            width: 60,
            renderCell: (params) => (params.value == false ? <FontAwesomeIcon style={{ width: 14, height: 14, color: color.dark_red }} icon={faXmarkCircle} /> : <FontAwesomeIcon style={{ width: 14, height: 14, color: color.black }} icon={faCheck} />),
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
        {
            field: 'code',
            headerName: lang.product_code_,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 120,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: false,
            hide: false,
        },
        {
            field: 'name',
            headerName: lang.product_name,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            renderCell: (params) => (params.row.parent_id == 0 ? params.row.name : params.row.parent_name + ' - ' + params.row.name),
            type: 'string',
            align: 'left',
            width: 300,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'category',
            headerName: lang.product_category,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 150,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            align: 'left',
        },
        {
            field: 'dph',
            headerName: lang.product_dph + ' (%)',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            renderCell: (params) => ConvertToPrice2(params.row.dph == -1 ? props.system.dph : params.row.dph, 0),
            type: 'number',
            width: 100,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            align: 'center',
        },
        {
            field: 'cost_final',
            headerName: lang.product_cost + ' (' + currency + ')',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            renderCell: (params) => ConvertToPrice2(params.row.cost_final, precisions),
            type: 'number',
            width: 150,
            editable: false,
            sortable: false,
            sorting: true,
            hide: show_cost == true ? false : true,
            align: 'right',
        },
        {
            field: 'price_final',
            headerName: lang.product_price + ' (' + currency + ')',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            renderCell: (params) => ConvertToPrice2(params.row.price_final, precisions),
            type: 'number',
            width: 150,
            editable: false,
            sortable: false,
            sorting: true,
            hide: false,
            align: 'right',
            print: true,
        },
        {
            field: 'price_retail',
            headerName: lang.product_price_retail + ' (' + currency + ')',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            renderCell: (params) => ConvertToPrice2(params.row.price_retail, precisions),
            type: 'number',
            width: 150,
            editable: false,
            sortable: false,
            sorting: true,
            hide: false,
            align: 'right',
        },
        {
            field: 'flag',
            headerName: lang.flags,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 200,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            align: 'center',
        },
        {
            field: 'ean',
            headerName: lang.product_code_ean,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            align: 'center',
            width: 200,
            editable: false,
            sortable: false,
            sorting: false,
            hide: props.system.product_codes != false ? props.system.product_codes[4].enabled == true ? false : true : true,
            hideable: true,
        },
        {
            field: 'vendors',
            headerName: lang.product_code_vendor_,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            align: 'center',
            width: 200,
            editable: false,
            sortable: false,
            sorting: false,
            hide: props.system.product_codes != false ? props.system.product_codes[3].enabled == true ? false : true : true,
            hideable: true,
        },
        {
            field: 'code_1',
            headerName: props.system.product_codes != false ? props.system.product_codes[0].label : '',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 120,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: true,
            hide: props.system.product_codes != false ? props.system.product_codes[0].enabled == true ? false : true : true,
        },
        {
            field: 'code_2',
            headerName: props.system.product_codes != false ? props.system.product_codes[1].label : '',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 120,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: true,
            hide: props.system.product_codes != false ? props.system.product_codes[1].enabled == true ? false : true : true,
        },
        {
            field: 'code_3',
            headerName: props.system.product_codes != false ? props.system.product_codes[2].label : '',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 120,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: true,
            hide: props.system.product_codes != false ? props.system.product_codes[2].enabled == true ? false : true : true,
        },
        {
            field: 'portal',
            headerName: lang.product_portal,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'boolean',
            align: 'center',
            width: 80,
            renderCell: (params) => (params.value == false ? <FontAwesomeIcon style={{ width: 14, height: 14, color: color.dark_red }} icon={faXmarkCircle} /> : <FontAwesomeIcon style={{ width: 14, height: 14, color: color.black }} icon={faCheck} />),
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
    ];

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(LoadArray('products-table', {}));

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showGroupError, setShowGroupError] = useState(false);
    const [showExportError, setShowExportError] = useState(false);

    // TABS
    const [precisions, setPrecisions] = useState(props.system.precisions == 0 ? 2 : 3);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(global.items_max);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [categoryID, setCategoryID] = useState(0);
    const [category, setCategory] = useState(0);
    const [manufactureID, setManufactureID] = useState(0);
    const [manufacture, setManufacture] = useState(0);
    const [flagID, setFlagID] = useState(0);
    const [flag, setFlag] = useState(0);
    const [vendorID, setVendorID] = useState(0);
    const [vendor, setVendor] = useState(0);
    const [groupID, setGroupID] = useState(0);
    const [group, setGroup] = useState(0);
    const [previewMode, setPreviewMode] = useState(LoadBool('products-mode', true));
    const [flags, setFlags] = useState(false);

    // STYL ZOBRAZOVANIE - RIADKOVE / TABULKA
    const [displayStyle, setDisplayStyle] = useState(LoadValue('products-style', 0));

    // FILTROVANIE
    const [showEnabled, setShowEnabled] = useState(true);
    const [showUnabled, setShowUnabled] = useState(false);
    const [showNotStarted, setShowNotStarted] = useState(false);
    const [showEnded, setShowEnded] = useState(false);
    const [showCond, setShowCond] = useState(false);
    const [showNotPublished, setShowNotPublished] = useState(false);
    const [showNoCategory, setShowNoCategory] = useState(false);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);
    const [showManufacture, setShowManufacture] = useState(false);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showCategorySelect, setShowCategorySelect] = useState(false);
    const [showManufactureSelect, setShowManufactureSelect] = useState(false);
    const [showFlagsSelect, setShowFlagsSelect] = useState(false);
    const [showVendorSelect, setShowVendorSelect] = useState(false);
    const [showGroup, setShowGroup] = useState(false);
    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [showBatch, setShowBatch] = useState(false);
    const [showVariations, setShowVariations] = useState(true);
    const [showDPH, setShowDPH] = useState(false);
    const [showTyp, setShowTyp] = useState(true);
    const [showPage, setShowPage] = useState(false);
    const [pageID, setPageID] = useState(0);

    // KONTROLA OBJEDNAVKOVYCH KODOV
    const [showDistributorCodes, setShowDistributorCodes] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 100;
    const rowVariantHeight = 56;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const maxWidth = 1100;
    const filterButtons = 110;
    const flagSize = 10;
    const chipHeight = 50;
    const previewHeight = 100;

    let { func } = props;
    var running = false;

    useEffect(() => {

        if (running == false) {
            setItemSelected(false);
            setSearch('');
            setShowCond(false);
            setShowUnabled(false);
            setShowNotStarted(false);
            setShowEnded(false);
            setShowNotPublished(false);
            setShowNoCategory(false);
            setCategoryID(0);
            setGroupID(0);
            setManufactureID(0);
            setVendorID(0);
            setFlagID(0);

            // UDAJE Z DB
            var filter = defaultFilter;

            if (props.params != false) {
                var columns = [];
                var search_arr = [];
                var search_txt = '';

                if (props.params.no_category == true) {
                    filter.no_category = true;
                    setShowNoCategory(true);
                }
                if (props.params.product_category_id != undefined) {
                    filter.category_id = props.params.product_category_id;
                    setCategoryID(props.params.product_category_id);
                    setCategory(props.params.product_category);
                }
                if (props.params.product_flag_id != undefined) {
                    filter.flag_id = props.params.product_flag_id;
                    setFlagID(props.params.product_flag_id);
                    setFlag(props.params.product_flag);
                }
                if (props.params.product_group_id != undefined) {
                    filter.group_id = props.params.product_group_id;
                    setGroupID(props.params.product_group_id);
                    setGroup(props.params.product_group);
                }
                if (props.params.enabled == true) {
                    filter.enabled = true;
                    setShowEnabled(true);
                }

                if (props.params.enabled == false) {
                    filter.disabled = true;
                    delete (filter.enabled);
                    setShowUnabled(true);
                }

                if (props.params.product_name != undefined) {
                    columns.push('name');
                    search_txt = props.params.product_name;

                    var search_text = props.params.product_name.split(' ');
                    if (search_text != undefined) {
                        search_text.forEach(element => {
                            search_arr.push(element);
                        });
                    }
                }

                if (props.params.product_code != undefined) {
                    columns.push('code');
                    search_txt = search_txt + ' ' + props.params.product_code;

                    var search_text = props.params.product_code.split(' ');
                    if (search_text != undefined) {
                        search_text.forEach(element => {
                            search_arr.push(element);
                        });
                    }
                }
                if (search_arr.length > 0) {
                    filter.search = search_arr;
                    filter.search_columns = columns;
                    setSearch(search_txt);
                }

            }
            db_get(0, filter, maxItems);
            db_data();

            running = true;
        }

    }, [props.locale, props.params]);

    const db_get = async (page, filter, maxItems) => {
        Debug('FILTER -----');
        Debug(filter);

        setBusy(true);
        if (displayStyle == 1) {
            setItemsFiltered([]);
        }
        try {
            const response = await fetch(
                global.db_url + 'products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug('PRODUCTS');
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart(itemSelected == false ? 0 : itemSelected.id);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_data = async () => {
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                })
            })

            const json = await response.json();
            Debug('PRODUCT-DATA');
            Debug(json);
            if (json.ok > 0) {
                setFlags(json.flags);
                //setCategories(json.categories);
                //setManufactures(json.manufactures);
                //setVendors(json.vendors);
                //setStocks(json.stocks);
            }

        } catch (error) {
            console.log(error);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_enable', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                db_get(page, filter, maxItems);

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note, columns) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.products,
                    filename: lang.products, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: columns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_products_add = async (group_id, data) => {
        // PRIDANIE PRODUKTOV DO SKUPINY
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products_group_items_add', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    group_id: group_id,
                    products: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);
        setItemSelected(false);

        var filter = GetFilter();

        if (txt.trim() != '') {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            if (search_text != undefined) {
                filter.search = search_text;
                filter.search_columns = columns;
            }
            delete (filter.parent_id);
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = (id) => {
        setTimeout(() => {
            var element = document.getElementById('id_start');

            if (id > 0) {
                element = document.getElementById('product-id-' + id);
            }

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth', block: "nearest" }); // start, center, end, nearest
            }
        }, global.goto_start_delay);
    }

    const ResetFilters = () => {
        setSearch('');
        setCategoryID(0);
        setCategory('');
        setManufactureID(0);
        setManufacture('');
        setVendorID(0);
        setVendor('');
        setGroupID(0);
        setGroup('');
    }


    function GetFilter() {
        var filter = {
            ordering: ordering,
            order_column: orderColumn,
            country_id: props.locale.id
        };
        if (search.trim() != '') {
            filter.search = search;

            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default == true);
                columns.push(tmp.column);
            }

            var search_text = search.split(' ');
            if (search_text != undefined) {
                filter.search = search_text;
                filter.search_columns = columns;
            }
        }
        if (vendorID > 0) {
            filter.vendor_id = vendorID
        }
        if (manufactureID > 0) {
            filter.manufacture_id = manufactureID
        }
        if (flagID > 0) {
            filter.flag_id = flagID;
        }
        if (categoryID > 0) {
            filter.category_id = categoryID;
        }
        if (groupID > 0) {
            filter.group_id = groupID;
        }
        if (showEnabled == true) {
            filter.enabled = true;
        }
        if (showUnabled == true) {
            filter.disabled = true;
        }
        if (showNotStarted == true) {
            filter.not_started = true;
        }
        if (showEnded == true) {
            filter.ended = true;
        }
        if (showCond == true) {
            filter.condition = true;
        }
        if (displayStyle == 0) {
            filter.parent_id = 0;
        }
        if (showNotPublished == true) {
            filter.not_published = true;
        }
        if (showNoCategory == true) {
            filter.no_category = true;
        }
        return filter;
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        let id = itemSelected == false ? 0 : itemSelected.id;
        setItemSelected(item);
        Debug('previewMode');
        Debug(previewMode);
        if ((previewMode == false && displayStyle == 0) || displayStyle == 1) {
            setShowDialog(true);
        } else {
            if (id == item.id) {
                setItemSelected(false);
            }
            GoToStart(item.id);
        }
    }

    const EditProduct = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw, id) => {
        setShowDialog(false);

        if (redraw == true) {
            var filter_ = filter;
            if (id > 0) {
                filter_ = { product_id: id };
            }
            db_get(page, filter_, maxItems);

        } else {
            if (previewMode == false) {
                setItemSelected(false);
            }
        }
    }


    const ChangeEnabled = (item, value) => {
        // ENABLED POLOZKY ZMENENY

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            product_id: item.id,
            parent_id: item.parent_id,
            action: value == true ? global.logs.enabled : global.logs.disabled,
            cost: ConvertToPrice(item.cost, precisions),
            price: ConvertToPrice(item.price, precisions),
            enabled: value
        };

        let data = {
            id: item.id,
            enabled: value,
            logs: log
        }
        db_update(data);
    }

    const MenuResult = (id, index, item) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1 || index == 2) {
            if (displayStyle == 0) {
                if (index == 1) {
                    let tmp = items.find(x => x.selected === true);
                    if (tmp == undefined) {
                        enable = false;
                    }
                }

                if (enable == true) {
                    setMenuIndex(index);
                    setShowExport(true);
                } else {
                    setShowExportError(true);
                }
            } else {
                if (itemsSelected.length > 0) {
                    setMenuIndex(index);
                    setShowExport(true);
                } else {
                    setShowExportError(true);
                }
            }
        }

        if (index == 3) {
            // nezobrazovat / zobrazovat desatine miesta
            var tmp = item;
            if (tmp != undefined) {
                tmp.checked = precisions == 0 ? true : false;
            }
            let p = props.system.precisions == 0 ? 2 : 3;
            setPrecisions(precisions == 0 ? p : 0);
        }

        if (index == 4) {
            // nezobrazovat / zobrazovat výrobcu
            var tmp = item;
            if (tmp != undefined) {
                tmp.checked = showManufacture == true ? false : true;
            }
            setShowManufacture(!showManufacture);
        }
        if (index == 5) {
            // BATCH UPDATE
            setShowBatch(true);
        }

        if (index == 11) {
            // REFRESH PAGE
            db_get(page, filter, maxItems);
        }

        if (index == 6) {
            // nezobrazovat / zobrazovat variacie
            var tmp = item;
            if (tmp != undefined) {
                tmp.checked = showVariations == true ? false : true;
            }
            setShowVariations(!showVariations);
        }

        if (index == 7) {
            // NOVA SKUPINA
            CreateGroup();
        }

        if (index == 8) {
            // PREVIEW MODE
            var tmp = item;
            tmp.checked = previewMode == true ? false : true;
            setPreviewMode(!previewMode);
            SaveBool('products-mode', !previewMode);
        }

        if (index == 9) {
            // nezobrazovat / zobrazovat DPH
            var tmp = item;
            if (tmp != undefined) {
                tmp.checked = showDPH == false ? true : false;
            }
            setShowDPH(showDPH == false ? true : false);
        }

        if (index == 10) {
            // nezobrazovat / zobrazovat TYP produktu
            var tmp = item;
            if (tmp != undefined) {
                tmp.checked = showTyp == false ? true : false;
            }
            setShowTyp(showTyp == false ? true : false);
        }

        if (index == 12) {
            // Kontrola objednávkových kodov produktov
            setShowDistributorCodes(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.down : global.order.up;
        var column = 'name';

        switch (id) {

            case 2: // NAKUPNA CENA
                column = 'cost_final';
                break;

            case 3: // PREDAJNA CEMA
                column = 'price_final';
                break;
        }

        var filter = GetFilter();
        filter.ordering = order;
        filter.order_column = column;
        db_get(0, filter, maxItems);
    }

    const MenuSortResult2 = (id, index) => {
        var order = global.order.down;
        var column = 'name';

        switch (index) {

            case 1: // NAZOV
                column = 'name';
                order = global.order.down
                break;

            case 2: // SKLADOM
                column = 'name';
                order = global.order.up
                break;

            case 3: // ID
                column = 'id';
                order = global.order.down
                break;

            case 4: // ID
                column = 'id';
                order = global.order.up
                break;

            case 5: // KOD PRODUKTU
                column = 'code';
                order = global.order.down
                break;

            case 6: // KOD PRODUKTU
                column = 'code';
                order = global.order.up
                break;
        }

        var filter = GetFilter();
        filter.ordering = order;
        filter.order_column = column;
        db_get(0, filter, maxItems);
    }


    const MenuSortResult3 = (id, index) => {
        var order = global.order.down;
        var column = 'name';

        switch (index) {

            case 1: // CASOVO OBMEDZENE
                column = 'date_end';
                order = global.order.down
                break;

            case 2: // CASOVO OBMEDZENE
                column = 'date_end';
                order = global.order.up
                break;

        }

        var filter = GetFilter();
        filter.ordering = order;
        filter.order_column = column;
        db_get(0, filter, maxItems);
    }


    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note, columns) => {
        setShowExport(false);
        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            var eColumns = columns == false ? exportColumns : columns;
            switch (menuIndex) {
                case 1:
                    if (displayStyle == 0) {
                        items.forEach(item => {
                            if (item.selected == true) {
                                if (item.variations == true) {
                                    if (item.variants != false) {
                                        item.variants.forEach(variant => {
                                            itemRows.push(variant.id);
                                        });
                                    }
                                } else {
                                    itemRows.push(item.id);
                                }
                            }
                        });
                        if (itemRows.length > 0) {
                            db_export(itemRows, note, eColumns);
                        }
                    } else {
                        if (itemsSelected.length > 0) {
                            db_export(itemsSelected, note, eColumns);
                        }
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note, eColumns);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const NewRecordReset = () => {
        ResetFilters();
        db_get(0, defaultFilter, maxItems);
    }

    const CategoryReset = () => {
        setCategoryID(0);
        setCategory('');

        var filter = GetFilter();
        delete (filter.category_id);
        db_get(0, filter, maxItems);
    }

    const CategoryResult = (typ, item) => {
        setShowCategorySelect(false);

        if (typ == true) {
            setCategory(item.label);
            setCategoryID(item.id);

            var filter = GetFilter();
            filter.category_id = item.id;
            db_get(0, filter, maxItems);
        }
    }


    const ManufactureResult = (typ, item) => {
        setShowManufactureSelect(false);

        if (typ == true) {
            setManufacture(item.name);
            setManufactureID(item.id);

            var filter = GetFilter();
            filter.manufacture_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const ResetManufacture = () => {
        setManufactureID(0);
        setManufacture('');

        var filter = GetFilter();
        delete (filter.manufacture_id);
        db_get(0, filter, maxItems);
    }

    const FlagResult = (typ, item) => {
        setShowFlagsSelect(false);

        if (typ == true) {
            setFlag(item.name);
            setFlagID(item.id);

            var filter = GetFilter();
            filter.flag_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const ResetFlag = () => {
        setFlagID(0);
        setFlag('');

        var filter = GetFilter();
        delete (filter.flag_id);
        db_get(0, filter, maxItems);
    }

    const VendorResult = (typ, item) => {
        setShowVendorSelect(false);

        if (typ == true) {
            setVendor(item.name);
            setVendorID(item.id);

            var filter = GetFilter();
            filter.vendor_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const ResetVendor = () => {
        setVendorID(0);
        setVendor('');

        var filter = GetFilter();
        delete (filter.vendor_id);
        db_get(0, filter, maxItems);
    }

    const GroupResult = (typ, item) => {
        setShowGroup(false);

        if (typ == true) {
            setGroup(item.label);
            setGroupID(item.id);

            var filter = GetFilter();
            filter.group_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const ResetGroup = () => {
        setGroupID(0);
        setGroup('');

        var filter = GetFilter();
        delete (filter.group_id);
        db_get(0, filter, maxItems);
    }

    const BatchResult = (redraw_) => {
        setShowBatch(false);
        if (redraw_ == true) {
            db_get(page, filter, maxItems);
        }
    }

    const CreateGroup = () => {
        if (displayStyle == 0) {
            var tmp = itemsFiltered.filter(x => x.selected == true);

            if (tmp.length > 0) {
                setShowCreateGroup(true);
            } else {
                setShowGroupError(true);
            }
        } else {
            if (itemsSelected.length > 0) {
                setShowCreateGroup(true);
            } else {
                setShowGroupError(true);
            }
        }
    }

    const CreateGroupResult = (typ, id) => {
        setShowCreateGroup(false);
        if (typ == true) {
            if (displayStyle == 0) {
                var tmp = itemsFiltered.filter(x => x.selected == true);
                var array = [];
                tmp.forEach(item => {
                    array.push(item.id);
                });
                db_products_add(id, array);
            } else {
                db_products_add(id, itemsSelected);
            }
        }
    }

    const ResetMenuFilters = () => {
        var filter = GetFilter();
        setItemSelected(false);

        filterMenu.forEach(element => {
            element.checked = false;
        });

        delete (filter.disabled);
        delete (filter.not_started);
        delete (filter.ended);
        delete (filter.condition);
        delete (filter.not_published);
        delete (filter.no_category);

        filterMenu[0].checked = true;
        filter.enabled = true;

        setShowEnabled(true);
        setShowUnabled(false);
        setShowNotStarted(false);
        setShowEnded(false);
        setShowCond(false);
        setShowNotPublished(false);
        setShowNoCategory(false);

        db_get(0, filter, maxItems);
    }

    const FilterMenuResult = (id, index, item) => {
        var filter = GetFilter();
        setItemSelected(false);

        filterMenu.forEach(element => {
            element.checked = false;
        });

        delete (filter.enabled);
        delete (filter.disabled);
        delete (filter.not_started);
        delete (filter.ended);
        delete (filter.condition);
        delete (filter.no_category);

        setShowEnabled(false);
        setShowUnabled(false);
        setShowNotStarted(false);
        setShowEnded(false);
        setShowCond(false);
        setShowNoCategory(false);


        if (index == 1) {
            // LEN ENABLED
            let value = !showEnabled;
            item.checked = value;
            setShowEnabled(value);
            if (value == true) {
                filter.enabled = true;
            }
        }
        if (index == 2) {
            // LEN UNABLED
            let value = !showUnabled;
            item.checked = value;
            setShowUnabled(value);
            if (value == true) {
                filter.disabled = true;
            }
        }
        if (index == 3) {
            // NAPLANOVANE SPUSTENIE
            let value = !showNotStarted;
            item.checked = value;
            setShowNotStarted(value);
            if (value == true) {
                filter.not_started = value;
            }
        }
        if (index == 4) {
            // UKONCENE
            let value = !showEnded
            item.checked = value;
            setShowEnded(value);
            if (value == true) {
                filter.ended = value;
            }
        }
        if (index == 5) {
            // S PODMIENKOU
            let value = !showCond
            item.checked = value;
            setShowCond(value);
            if (value == true) {
                filter.condition = value;
            }
        }
        if (index == 6) {
            // NEPUBLIKOVANE
            let value = !showNotPublished
            item.checked = value;
            setShowNotPublished(value);
            if (value == true) {
                filter.not_published = value;
            }
        }
        if (index == 7) {
            // BEZ KATEGORIE
            let value = !showNoCategory
            item.checked = value;
            setShowNoCategory(value);
            if (value == true) {
                filter.no_category = value;
            }
        }
        db_get(0, filter, maxItems);
    }

    const getStockQuantity = (stock_id, item) => {
        var quantity = 0;
        if (item.stocks_quantity != false) {
            let tmp = item.stocks_quantity.find(x => x.id == stock_id);
            if (tmp != undefined) {
                quantity = tmp.quantity;
            }
        }

        return FormatMoney(quantity, 0);
    }

    const getFlagEnabled = (id) => {
        var result = false;
        if (flags != false) {
            let tmp = flags.find(x => x.id == id);

            if (tmp != undefined) {
                result = tmp.enabled;
            }
        }

        return result;
    }

    const getFlagColor = (id) => {
        var result = 0;
        if (flags != false) {
            let tmp = flags.find(x => x.id == id);

            if (tmp != undefined) {
                result = tmp.color;
            }
        }

        return global.flag_colors[result];
    }

    const getFlagName = (id) => {
        var result = '';
        if (flags != false) {
            let tmp = flags.find(x => x.id == id);

            if (tmp != undefined) {
                result = tmp.name;
            }
        }

        return result;
    }

    const ChangeStyle = (value) => {
        setItems(false);
        setItemsFiltered(false);
        setItemSelected(false);
        setDisplayStyle(value);

        if (value == 0) {
            filter.parent_id = 0;
        } else {
            delete (filter.parent_id);
        }

        SaveValue('products-style', value);
        db_get(0, filter, maxItems);
    }

    const SortUp = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.up;
        filter.order_column = data.colDef.field;
        db_get(0, filter, maxItems);
    }

    const SortDown = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.down;
        filter.order_column = data.colDef.field;
        db_get(0, filter, maxItems);
    }

    function MyMenuItem(props) {
        const { menuHandler, menuLabel, menuIcon } = props;
        return (
            <MenuItem onClick={menuHandler}>
                <ListItemIcon>
                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={menuIcon} />
                </ListItemIcon>
                <ListItemText>{menuLabel}</ListItemText>
            </MenuItem>
        );
    }


    function CustomColumnMenu(props) {
        // VLASTNÁ COLUMN MENU
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuSortItem: null,
                    columnMenuFilterItem: null,
                    columnMenuUserItem1: props.colDef.sorting == true ? MyMenuItem : null,
                    columnMenuUserItem2: props.colDef.sorting == true ? MyMenuItem : null,
                }}
                slotProps={{
                    columnMenuUserItem1: {
                        displayOrder: 1,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowUp,
                        menuHandler: () => SortUp(props),
                    },
                    columnMenuUserItem2: {
                        displayOrder: 0,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowDown,
                        menuHandler: () => SortDown(props),
                    },
                }}
            />
        );
    }

    const ShowProductPage = (item) => {
        setPageID(item.page_id);
        setShowPage(true);
    }

    const ChangeColumns = (data) => {
        // ZOBRAZIT / SKRYT STLPCE -> uloženie do cookies
        setColumnVisibilityModel(data);
        SaveArray('products-table', data);
    }

    const DistributorCodeCheckerResult = () => {
        setShowDistributorCodes(false);
    }
    /*
    *
    * =================================================================================================================
    * PRODUCT - RODIČ
    * =================================================================================================================
    *
    */

    const ProductRow = (rowProps) => {
        let item = rowProps.item;
        return (
            <div id={'product-id-' + item.id} key={item.id} style={{ ...styles.Block }}>
                <Paper
                    key={item.id}
                    elevation={item.id == overID ? 4 : 1}
                    style={{
                        ...styles.Block,
                        backgroundColor: item.enabled == false ? color.disabled : item.grouped == true ? color.grouped : item.variations == true ? color.variation : color.white,
                        borderRadius: global.items_radius,
                        minHeight: rowHeight,
                        cursor: 'pointer',
                        marginTop: 5, marginBottom: 5,
                        paddingTop: 2, paddingBottom: 2,
                        borderLeft: item.enabled == false ? color.disabled_border : item.variations == true ? color.border_variation : item.grouped == true ? color.border_grouped : '',
                    }}
                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                >
                    <div style={{ ...styles.BlockRow, width: '100%' }}>
                        <div style={{ ...styles.BlockLeft, width: 40, height: rowHeight, justifyContent: 'center' }}>
                            {/* SELECT */}
                            <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                        </div>

                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '95%' }}>
                            {/* DATA */}
                            <div style={{ ...styles.BlockLeft, width: rowHeight - 25, minHeight: rowHeight, justifyContent: 'center' }}>
                                <Avatar picture image={item.image} size={rowHeight - 40} theme={props.theme} />
                            </div>

                            <div style={{ ...styles.BlockLeft, width: show_cost == true ? '43%' : '61%', minHeight: rowHeight, justifyContent: 'center' }}>
                                {flags != false ?
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        {item.flags != false ?
                                            item.flags.map((element, index) => (
                                                getFlagEnabled(element) == true ?
                                                    <div key={index} style={{ ...styles.BlockCenter, width: flagSize, height: flagSize }}>
                                                        <FontAwesomeIcon style={{ height: flagSize - 2, color: getFlagColor(element) }} icon={faCircle} />
                                                    </div>
                                                    : null
                                            ))
                                            : null}

                                    </div>
                                    : null}

                                {item.parent_id > 0 ?
                                    <p style={{ ...styles.TextXTiny }}>{item.parent_name}</p>
                                    : null}
                                <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name}</p>
                                <Line marginTop={4} theme={props.theme} />
                                <div style={{ ...styles.BlockRowRaw, marginTop: 4 }}>
                                    {item.variations == false || item.parent_id > 0 ?
                                        <div style={{ ...styles.Block, width: undefined, height: 24 }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.code}</p>
                                        </div>
                                        : null}
                                    {item.category != '' && item.category != undefined ?
                                        <div style={{ ...styles.Block, width: undefined, height: 24, marginLeft: 10 }}>
                                            <Chip variant='outlined' size='small' style={{ borderColor: color.dark_gray, height: 18 }} label={<p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.category}</p>}></Chip>
                                        </div>
                                        : null}
                                    {item.manufacture != '' && item.manufacture != undefined && showManufacture == true ?
                                        <div style={{ ...styles.Block, width: undefined, height: 24 }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{item.manufacture}</p>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            {show_cost == true ?
                                <div style={{ ...styles.BlockRight, width: '18%', minHeight: rowHeight, justifyContent: 'center' }}>
                                    {item.variations == false ?
                                        <p style={{ ...styles.TextTiny }}>{FormatMoney(item.cost_final, precisions)} {currency}</p>
                                        : null}
                                </div>
                                : null}
                            <div style={{ ...styles.BlockRight, width: '18%', minHeight: rowHeight, justifyContent: 'center' }}>
                                {item.variations == false ?
                                    <>
                                        <p style={{ ...styles.TextTiny, fontWeight: show_prices_dph == true ? '600' : '400' }}>{FormatMoney(item.price_final, precisions)} {currency}</p>
                                        {show_prices_dph == true ?
                                            <>
                                                <p style={{ ...styles.TextXXTiny, marginTop: 7, color: color.dark_gray }}>{lang.price_with_dph}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{FormatMoneyDPH(item.price_final, item.dph == -1 ? props.locale.dph : item.dph, precisions)} {currency}</p>
                                            </>
                                            : null}
                                    </>
                                    : null}
                            </div>
                            <div style={{ ...styles.BlockRight, width: '13%', minHeight: rowHeight, justifyContent: 'center' }}>
                                <div style={{ ...styles.Block, width: '85%' }}>
                                    {item.date_end != null ? item.date_end != global.date_endless ?
                                        <Chip
                                            style={{ marginTop: 2, marginBottom: 2 }}
                                            sx={{
                                                borderColor: color.dark_red,
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                height: 'auto',
                                                '& .MuiChip-label': {
                                                    display: 'block',
                                                    whiteSpace: 'normal',
                                                },
                                            }}
                                            label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_red }}>{lang.product_ranged}</p>} variant="outlined"
                                        />
                                        : null : null}
                                    {item.variations == true && showTyp == true ?
                                        <Chip
                                            style={{ marginTop: 2, marginBottom: 2 }}
                                            sx={{
                                                borderColor: color.blue,
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                height: 'auto',
                                                '& .MuiChip-label': {
                                                    display: 'block',
                                                    whiteSpace: 'normal',
                                                },
                                            }}
                                            label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.blue, }}>{lang.product_type_variant}</p>} variant="outlined"
                                        />
                                        : null}
                                    {item.grouped == true && showTyp == true ?
                                        <Chip
                                            style={{ marginTop: 2, marginBottom: 2 }}
                                            sx={{
                                                borderColor: color.violet,
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                height: 'auto',
                                                '& .MuiChip-label': {
                                                    display: 'block',
                                                    whiteSpace: 'normal',
                                                },
                                            }}
                                            label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.violet, }}>{lang.product_type_group}</p>} variant="outlined"
                                        />
                                        : null}
                                    {item.parent_id > 0 ?
                                        <Chip
                                            style={{ marginTop: 2, marginBottom: 2 }}
                                            sx={{
                                                borderColor: color.blue,
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                height: 'auto',
                                                '& .MuiChip-label': {
                                                    display: 'block',
                                                    whiteSpace: 'normal',
                                                },
                                            }}
                                            label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.blue, }}>{lang.product_variation}</p>} variant="outlined"
                                        />
                                        : null}
                                    {item.condition_id > 0 ?
                                        <Chip
                                            style={{ marginTop: 2, marginBottom: 2 }}
                                            sx={{
                                                borderColor: color.dark_red,
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                height: 'auto',
                                                '& .MuiChip-label': {
                                                    display: 'block',
                                                    whiteSpace: 'normal',
                                                },
                                            }}
                                            label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_red }}>{lang.cond}</p>} variant="outlined"
                                        />
                                        : null}
                                    {showDPH == true ?
                                        <Chip
                                            style={{ marginTop: 2, marginBottom: 2 }}
                                            sx={{
                                                borderColor: color.dark_gray,
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                height: 'auto',
                                                '& .MuiChip-label': {
                                                    display: 'block',
                                                    whiteSpace: 'normal',
                                                },
                                            }}
                                            label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_gray }}>{lang.product_dph.toUpperCase()}: {item.dph == -1 ? props.locale.dph : item.dph}%</p>} variant="outlined"
                                        />
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: 75, minHeight: rowHeight, justifyContent: 'center' }}>
                            {/* ENABLED */}
                            {/*
                            {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                (item.parent_id == 0 && item.disabled == false) || (item.parent_id > 0 && item.parent_enabled == true) ?
                                    <SwitchEnable enabled={item.enabled == false ? false : true} theme={props.theme} func={(value) => ChangeEnabled(item, value)} />
                                    : null : null}
                            */}
                            {item.portal == true ?
                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.yes}</p>
                                :
                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.no}</p>
                            }
                            {/*item.enabled == false && item.disabled == false ?
                                <div style={{ ...styles.Block, height: 20 }}>
                                    <p style={{ ...styles.TextXXXTiny, color: color.dark_gray }}>{lang.disabled}</p>
                                </div>
                        : null*/}
                            {/*item.disabled == true ?
                                <div style={{ ...styles.Block, height: 20 }}>
                                    <p style={{ ...styles.TextXXXTiny, color: color.dark_gray, textAlign: 'center' }}>{lang.product_range_disabled}</p>
                                </div>
                    : null*/}
                        </div>
                    </div>
                    {itemSelected != false ? previewMode == true && itemSelected.id == item.id ?
                        <SubView item={item} />
                        : null : null}
                </Paper>
                <div style={{ ...styles.BlockRight, marginBottom: item.variants != false && showVariations == true ? 30 : 0 }}>
                    {showVariations == true ? item.variants != false ? item.variants.map((variant) => (
                        <VariantRow key={variant.id} item={variant} show_variant={false} />
                    )) : null : null}
                </div>
            </div>
        );
    }


    /*
    *
    * =================================================================================================================
    * VARIANT
    * =================================================================================================================
    *
    */

    const VariantRow = (rowProps) => {
        let variant = rowProps.item;
        return (
            <div id={'product-id-' + variant.id} style={{ ...styles.BlockRight }}>
                <Paper
                    key={variant.id}
                    elevation={variant.id == overID ? 4 : 1}
                    style={{
                        ...styles.Block,
                        width: '97%',
                        backgroundColor: variant.enabled == false ? color.disabled : color.variation,
                        borderRadius: global.items_radius,
                        minHeight: rowVariantHeight,
                        cursor: 'pointer',
                        marginTop: 5, marginBottom: 5,
                        paddingTop: 2, paddingBottom: 2,
                        borderLeft: variant.enabled == false ? color.disabled_border : '',
                    }}
                    onMouseOverCapture={() => setOverID(variant.id)} onMouseLeave={() => setOverID(-1)}
                >
                    <div onClick={() => SelectItem(variant)} style={{ ...styles.BlockRow, width: '98%' }}>
                        <div style={{ ...styles.BlockLeft, width: rowHeight - 25, height: rowVariantHeight, justifyContent: 'center' }}>
                            <Avatar picture image={variant.image} size={rowVariantHeight - 5} theme={props.theme} />
                        </div>
                        <div style={{ ...styles.BlockLeft, width: show_cost == true ? '43%' : '61%', height: rowVariantHeight }}>
                            {flags != false ?
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {variant.flags != false ?
                                        variant.flags.map((element, index) => (
                                            getFlagEnabled(element) == true ?
                                                <div key={index} style={{ ...styles.BlockCenter, width: flagSize, height: flagSize }}>
                                                    <FontAwesomeIcon style={{ height: flagSize - 2, color: getFlagColor(element) }} icon={faCircle} />
                                                </div>
                                                : null
                                        ))
                                        : null}

                                </div>
                                : null}
                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{variant.name}</p>
                            <p style={{ ...styles.TextXTiny }}>{variant.code}</p>
                        </div>
                        {show_cost == true ?
                            <div style={{ ...styles.BlockRight, width: '18%', height: rowVariantHeight }}>
                                <p style={{ ...styles.TextXSmall }}>{FormatMoney(variant.cost_final, precisions)} {currency}</p>
                            </div>
                            : null}
                        <div style={{ ...styles.BlockRight, width: '18%', height: rowVariantHeight }}>
                            <p style={{ ...styles.TextXSmall, fontWeight: show_prices_dph == true ? '600' : '400' }}>{FormatMoney(variant.price_final, precisions)} {currency}</p>
                            {show_prices_dph == true ?
                                <>
                                    <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{FormatMoneyDPH(variant.price_final, variant.dph == -1 ? props.locale.dph : variant.dph, precisions)} {currency} {lang.with_dph}</p>
                                </>
                                : null}
                        </div>
                        <div style={{ ...styles.Block, width: '12%', height: rowVariantHeight }}>
                            {variant.parent_id > 0 && rowProps.show_variant == true ?
                                <Chip
                                    style={{ marginTop: 2, marginBottom: 2 }}
                                    sx={{
                                        borderColor: color.blue,
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                        height: 'auto',
                                        '& .MuiChip-label': {
                                            display: 'block',
                                            whiteSpace: 'normal',
                                        },
                                    }}
                                    label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.blue, }}>{lang.product_variation}</p>} variant="outlined"
                                />
                                : null}
                        </div>
                        <div style={{ ...styles.Block, width: 75, height: rowVariantHeight }}>
                            {variant.portal == true ?
                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.yes}</p>
                                :
                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.no}</p>
                            }
                        </div>
                    </div>

                    {itemSelected != false ? previewMode == true && itemSelected.id == variant.id ?
                        <SubView item={variant} />
                        : null : null}

                </Paper>
            </div>
        )
    }



    /*
    *
    * =================================================================================================================
    * PRODUKT - náhľad
    * =================================================================================================================
    *
    */
    const SubView = (subProps) => {
        let item = subProps.item;

        return (
            <div style={{ ...styles.Block, marginTop: 5, paddingTop: 10, paddingBottom: 15, borderTop: color.border }}>
                {item.variations == false ?
                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                        <div style={{ ...styles.BlockLeft, width: '99%' }}>

                            {/*props.system.stocks == true ?
                                <div style={{ ...styles.BlockLeft }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.stocks_status}</p>
                                    <div style={{ ...styles.BlockRow, flexWrap: 'wrap', paddingTop: 5, paddingBottom: 10 }}>
                                        {stocks != false ? stocks.map((stock, index) => (
                                            <div key={stock.id} style={{ ...styles.Block, width: stockCellWidth, height: stockCellHeight, border: color.border, marginRight: 5, marginBottom: 5 }}>
                                                <div style={{ ...styles.Block, height: (stockCellHeight / 2) - 1, overflow: 'hidden' }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 2 }}>{stock.code}</p>
                                                </div>
                                                <Line theme={props.theme} />
                                                <div style={{ ...styles.Block, height: stockCellHeight / 2 }}>
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{getStockQuantity(stock.id, item)} {item.unit}</p>
                                                </div>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                                        : null*/}

                            {flags != false ?
                                <div style={{ ...styles.BlockLeft }}>
                                    {item.flags != false ?
                                        item.flags.map((element, index) => (
                                            getFlagEnabled(element) == true ?
                                                <div key={index} style={{ ...styles.BlockRowRaw }}>
                                                    <FontAwesomeIcon style={{ height: flagSize - 2, color: getFlagColor(element), marginRight: 8, marginTop: 4 }} icon={faCircle} />
                                                    <p style={{ ...styles.TextTiny }}>{getFlagName(element)}</p>
                                                </div>
                                                : null
                                        ))
                                        : null}

                                </div>
                                : null}

                            {codes[0].enabled == true && item != false && item.code_1 != '' ?
                                // EXTERNAL CODE 1
                                <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10, paddingBottom: 10 }}>
                                    <p style={{ ...styles.TextTiny, marginTop: 9, marginRight: 5 }}>{props.system.product_codes[0].label}: {item.code_1}</p>
                                </div>
                                : null}

                            {codes[1].enabled == true && item != false && item.code_2 != '' ?
                                // EXTERNAL CODE 2
                                <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10, paddingBottom: 10 }}>
                                    <p style={{ ...styles.TextTiny, marginTop: 9, marginRight: 5 }}>{props.system.product_codes[1].label}: {item.code_2}</p>
                                </div>
                                : null}

                            {codes[2].enabled == true && item != false && item.code_3 != '' ?
                                // EXTERNAL CODE 3
                                <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10, paddingBottom: 10 }}>
                                    <p style={{ ...styles.TextTiny, marginTop: 9, marginRight: 5 }}>{props.system.product_codes[2].label}: {item.code_3}</p>
                                </div>
                                : null}


                            <div style={{ ...styles.BlockLeft, marginTop: 10, paddingBottom: 10 }}>
                                <p style={{ ...styles.TextXSmall }}>{lang.product_quantity_package}: {item.quantity_package}</p>
                            </div>

                            {item.grouped == true ?
                                <div style={{ ...styles.BlockLeft, paddingTop: 10, paddingBottom: 10 }}>
                                    <p style={{ ...styles.TextXSmall, fontWeight: '600', marginBottom: 2 }}>{lang.product_sub_products_label}:</p>
                                    {item.grouped_products != false ?
                                        item.grouped_products.map((element, index) => (
                                            <div key={element.id} style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextXSmall }}>{parseInt(index + 1)}. {element.code} {element.parent_name} <b>{element.name}</b></p>
                                            </div>
                                        ))
                                        : null}
                                </div>
                                : null}

                            {item.note != null ? item.note.trim() != '' ?
                                // POZNAMKA
                                <div style={{ ...styles.BlockLeft, marginTop: 10, paddingBottom: 10 }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.note}:</p>
                                    <p style={{ ...styles.TextXSmall }}>{item.note}</p>
                                </div>
                                : null : null}

                            {item.description_short.trim() != '' ?
                                // KRATKY POPIS
                                <div style={{ ...styles.BlockLeft, marginTop: 10, paddingBottom: 10 }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.product_description_short}:</p>
                                    <p style={{ ...styles.TextXSmall }}>{item.description_short}</p>
                                </div>
                                : null}

                            {props.system.eshop_enabled == true && item.page_id > 0 ?
                                <div style={{ ...styles.Block, marginTop: 10 }}>
                                    <Button onClick={() => ShowProductPage(item)} style={{ ...styles.ButtonOutlined, width: 220 }}>{lang.web_page_product}</Button>
                                </div>
                                : null}

                        </div>
                        <div style={{ ...styles.BlockRight, width: 50 }}>
                            <IconButton onClick={() => EditProduct(item)} style={{ ...styles.ButtonIcon }}>
                                <FontAwesomeIcon style={{ width: 12 }} icon={faPen} />
                            </IconButton>
                        </div>
                    </div>
                    :
                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                        <div style={{ ...styles.BlockLeft, width: '99%' }}>
                        </div>
                        <div style={{ ...styles.BlockRight, width: 50 }}>
                            <IconButton onClick={() => EditProduct(item)} style={{ ...styles.ButtonIcon }}>
                                <FontAwesomeIcon style={{ width: 12 }} icon={faPen} />
                            </IconButton>
                        </div>
                    </div>
                }
            </div>
        );
    }


    /*
    *
    * =================================================================================================================
    * MAIN WINDOW
    * =================================================================================================================
    *
    */

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 270, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.widow_bar_height }}>
                    <ButtonChangeStyle value={displayStyle} theme={props.theme} func={(id) => ChangeStyle(id)} />
                </div>

                <div style={{ ...styles.Block, width: 50, height: global.widow_bar_height, marginRight: 20 }}>
                    <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.Block, maxWidth: displayStyle == 0 ? maxWidth : '98%', width: '100%' }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            {filter.product_id == undefined ?
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '25%', height: menuHeight }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.Block, width: 30, paddingTop: 3, marginRight: 5 }}>
                                                <MenuButton id={0} icon={faFilter} backgroundColor={'#00000000'} color={color.dark_gray} label={lang.user_new} menuItems={filterMenu} theme={props.theme} func={FilterMenuResult.bind()} />
                                            </div>
                                            <div style={{ ...styles.Block, width: '65%' }}>
                                                <TextInput search redraw theme={props.theme} width={'100%'} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                                <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '55%', height: menuHeight }}>
                                        <div style={{ ...styles.BlockRowRaw, height: menuHeight }}>
                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight }}>
                                                <Button onClick={() => setShowCategorySelect(true)} style={{ ...styles.ButtonOutlined, width: filterButtons, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faFolderTree} />
                                                    {lang.product_category}
                                                </Button>
                                            </div>
                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                <Button onClick={() => setShowGroup(true)} style={{ ...styles.ButtonOutlined, width: filterButtons, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faLayerGroup} />
                                                    {lang.product_group}
                                                </Button>
                                            </div>
                                            {props.system.search_manufacturers == true ?
                                                <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                    <Button onClick={() => setShowManufactureSelect(true)} style={{ ...styles.ButtonOutlined, width: filterButtons, fontSize: global.font_tiny }}>
                                                        <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                                        {lang.manufacturer}
                                                    </Button>
                                                </div>
                                                : null}
                                            {props.system.search_vendors == true ?
                                                <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                    <Button onClick={() => setShowVendorSelect(true)} style={{ ...styles.ButtonOutlined, width: filterButtons, fontSize: global.font_tiny }}>
                                                        <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                                        {lang.vendor}
                                                    </Button>
                                                </div>
                                                : null}

                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                <IconButton onClick={() => setShowFlagsSelect(true)} style={{ ...styles.ButtonOutlined, width: 36, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 14 }} icon={faFlag} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '20%', height: menuHeight }}>
                                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                {lang.product_new}
                                            </Button>
                                            : null}
                                    </div>
                                </div>
                                :
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '25%', height: menuHeight }}>
                                        {filter.product_id > 0 ?
                                            <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.new_record}</p>} onDelete={() => NewRecordReset()}></Chip>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '55%', height: menuHeight }}>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '20%', height: menuHeight }}>
                                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                {lang.product_new}
                                            </Button>
                                            : null}
                                    </div>
                                </div>
                            }

                            <div style={{ ...styles.BlockLeft, height: chipHeight, justifyContent: 'flex-start' }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {showCond == true || showEnabled == false || showUnabled == true || showNotStarted == true || showEnded == true > 0 || showNotPublished == true || showNoCategory == true ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.filters}</p>} onDelete={() => ResetMenuFilters()}></Chip>
                                        : null}
                                    {categoryID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{category}</p>} onDelete={() => CategoryReset(0)}></Chip>
                                        : null}
                                    {groupID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{group}</p>} onDelete={() => ResetGroup(0)}></Chip>
                                        : null}
                                    {manufactureID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{manufacture}</p>} onDelete={() => ResetManufacture(0)}></Chip>
                                        : null}
                                    {vendorID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{vendor}</p>} onDelete={() => ResetVendor(0)}></Chip>
                                        : null}
                                    {flagID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{flag}</p>} onDelete={() => ResetFlag(0)}></Chip>
                                        : null}
                                </div>
                            </div>

                            <div style={{ ...styles.Block }}>

                                {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}
                                {displayStyle == 0 ?
                                    <div style={{ ...styles.Block, width: '99%', border: color.border, height: tableLabelHeight }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.Block, width: 40, height: tableLabelHeight, justifyContent: 'center' }}>
                                                {/* SELECT */}
                                                <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '95%' }}>
                                                {/* DATA */}
                                                <div style={{ ...styles.BlockLeft, width: rowHeight - 25, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: show_cost == true ? '43%' : '61%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering2} theme={props.theme} func={MenuSortResult2.bind()} />
                                                    </div>
                                                </div>
                                                {show_cost == true ?
                                                    <div style={{ ...styles.BlockRow, width: '18%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                        <div style={{ ...styles.BlockLeft, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>
                                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product_cost}</p>
                                                        </div>
                                                        <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                            <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                        </div>
                                                    </div>
                                                    : null}
                                                <div style={{ ...styles.BlockRow, width: '18%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product_price}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <MenuButton id={3} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: '13%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>

                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        {/*
                                                    <MenuButton id={4} sortMenu={true} menuItems={itemsOrdering3} theme={props.theme} func={MenuSortResult3.bind()} />
                                                    */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ ...styles.Block, width: 75, borderLeft: color.border }}>
                                                {/* ENABLED */}
                                                <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.product_portal}</p>
                                                </div>
                                                {/*
                                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <MenuButton id={4} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                </div>
                                               */}
                                            </div>
                                        </div>
                                    </div>
                                    : null}

                                {/* ****************************************************************************************************************
                                        PRODUKTY - ZOZNAM
                                **************************************************************************************************************** */}
                                {displayStyle == 0 ?
                                    <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - chipHeight - tableLabelHeight - 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div id={'id_start'} style={{ ...styles.Block }}></div>
                                        <div style={{ ...styles.BlockCenter, width: '99%' }}>
                                            {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                                <ProductRow key={item.id} item={item} />
                                            )) :
                                                <EmptyList row small lang={lang} />
                                            }

                                            {/* ****************************************************************************************************************
                                            PAGINATION
                                            **************************************************************************************************************** */}
                                            {itemsFiltered != false ?
                                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                                        {pages > 1 ?
                                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                            : null}
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 200 }}>
                                                        <div style={{ ...styles.BlockRowRaw }}>
                                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                            </div>
                                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                    : null}

                                {displayStyle == 1 ?
                                    <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - chipHeight - 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <Box sx={{
                                            height: previewMode == true ? props.height - global.widow_bar_height - menuHeight - chipHeight - 84 - previewHeight : props.height - global.widow_bar_height - menuHeight - chipHeight - 84,
                                            width: 1,
                                            /*
                                            '& .super-app-theme--header': {
                                                backgroundColor: color.light_gray,
                                            },
                                            */
                                            '& .super-app-theme--normal': {
                                                backgroundColor: color.white,
                                                color: '#000000',
                                                '&:hover': {
                                                    bgcolor: (theme) => (color.table_row),
                                                },
                                            },
                                            '& .super-app-theme--red': {
                                                backgroundColor: color.lighteen_blue,
                                                color: color.dark_red,
                                                fontWeight: 'normal',
                                                '&:hover': {
                                                    bgcolor: (theme) => (color.gray),
                                                },
                                            },
                                            '& .super-app-theme--blue': {
                                                backgroundColor: color.white,
                                                color: color.blue,
                                                fontWeight: 'normal',
                                                '&:hover': {
                                                    bgcolor: (theme) => (color.gray),
                                                },
                                            },
                                        }}>
                                            <DataGrid

                                                sx={{
                                                    "& .MuiDataGrid-columnHeaderTitle": {
                                                        whiteSpace: "normal",
                                                        lineHeight: "normal"
                                                    },
                                                    "& .MuiDataGrid-columnHeader": {
                                                        height: "unset !important"
                                                    },
                                                    "& .MuiDataGrid-columnHeaders": {
                                                        maxHeight: "168px !important"
                                                    },
                                                    fontSize: global.font_table, border: global.theme_table_border_width, borderColor: color.dark_gray
                                                }}

                                                rows={itemsFiltered == false ? [] : itemsFiltered}
                                                columns={columns.filter(x => x.hide == false)}
                                                slots={{ columnMenu: CustomColumnMenu }}
                                                rowHeight={previewMode == true ? 30 : undefined}

                                                checkboxSelection
                                                disableRowSelectionOnClick
                                                onRowSelectionModelChange={(newRowSelectionModel) => { setItemsSelected(newRowSelectionModel) }}
                                                onRowClick={(params) => { setItemSelected(params.row) }}
                                                onRowDoubleClick={(params) => { SelectItem(params.row) }}
                                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                                getRowClassName={(params) => (params.row.enabled == 1 ? `super-app-theme--normal` : `super-app-theme--red`)}
                                                hideFooterRowCount={true}
                                                hideFooterSelectedRowCount={true}
                                                hideFooterPagination
                                                hideFooter
                                                columnVisibilityModel={columnVisibilityModel}
                                                onColumnVisibilityModelChange={(newModel) =>
                                                    ChangeColumns(newModel)
                                                }
                                            />
                                        </Box>

                                        {previewMode == true && itemSelected != false ?
                                            <div style={{ ...styles.Block, height: previewHeight, marginTop: 5 }}>
                                                <div style={{ ...styles.BlockRow, width: '100%' }}>
                                                    <div style={{ ...styles.BlockLeft, width: previewHeight, height: previewHeight, justifyContent: 'center' }}>
                                                        <Avatar picture image={itemSelected.image} size={previewHeight - 10} theme={props.theme} />
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '50%', height: previewHeight, justifyContent: 'center' }}>
                                                        {itemSelected.parent_id > 0 ?
                                                            <p style={{ ...styles.TextXTiny }}>{itemSelected.parent_name}</p>
                                                            : null}
                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{itemSelected.name}</p>
                                                        <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{itemSelected.code}</p>
                                                        <div style={{ ...styles.BlockCenter, maxHeight: 40, overflow: 'hidden', alignItems: 'flex-start' }}>
                                                            <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{itemSelected.description_short}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '30%', height: previewHeight, justifyContent: 'center' }}>
                                                    </div>
                                                </div>

                                            </div>
                                            : null}
                                        {/* ****************************************************************************************************************
                                        PAGINATION
                                        **************************************************************************************************************** */}
                                        <div style={{ ...styles.BlockRow, paddingTop: 10, borderTop: '1px solid ' + global.theme_light_gray }}>
                                            <div style={{ ...styles.Block, width: 200 }}></div>
                                            <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                                {pages > 1 ?
                                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                        <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                        <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    : null}

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showDistributorCodes == true ?
                <DistributorsCodeChecker product remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={DistributorCodeCheckerResult.bind(this)} />
                : null}

            {showPage == true ?
                <WebPageDesigner preview item={{ id: pageID }} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowPage(false)} />
                : null}

            {showCreateGroup == true ?
                <ProductsGroup index={0} country_id={props.locale.id} module_id={global.model.products_groups} item={false} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CreateGroupResult.bind()} />
                : null}

            {showBatch == true ?
                <BatchUpdate model_id={global.model.products} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={BatchResult.bind()} />
                : null}

            {showCategorySelect == true ?
                <ProductCategorySelect module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CategoryResult.bind()} />
                : null}

            {showManufactureSelect == true ?
                <ManufacturerSelect module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ManufactureResult.bind()} />
                : null}

            {showFlagsSelect == true ?
                <ProductFlagsSelect module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={FlagResult.bind()} />
                : null}

            {showVendorSelect == true ?
                <VendorSelect module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={VendorResult.bind()} />
                : null}

            {showGroup == true ?
                <ProductsGroupSelect organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={GroupResult.bind()} />
                : null}

            {showExport == true ?
                <DialogExport columns={exportColumns} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <Product module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showExportError == true ?
                <ShowInfo text={lang.export_none_error} sub_text={lang.export_none_error_} lang={lang} theme={props.theme} func={() => setShowExportError(false)} />
                : null}

            {showGroupError == true ?
                <ShowInfo text={lang.product_group_create_error} sub_text={''} lang={lang} theme={props.theme} func={() => setShowGroupError(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}





/*
*
* =================================================================================================================
* VARIACIA PRODUKTU
* =================================================================================================================
*
*/
export const ProductVariation = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let currency = props.locale.currency;
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let coeficientEnabled = props.system.price_coeficient_enabled == false ? false : true;
    let coeficient = props.system.price_coeficient;
    let product_code_searching = props.system.pharminfo_enabled == true || props.system.pharmos_enabled == true ? true : false;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);
    const [needSave, setNeedSave] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});
    const [flagItems, setFlagItems] = useState(false);

    const [enabled, setEnabled] = useState(props.productEnabled == true ? true : false);
    const [image, setImage] = useState('');
    const [name, setName] = useState('');
    const [priceCoeficientEnabled, setPriceCoeficientEnabled] = useState(coeficientEnabled == true ? true : false);
    const [priceTempCoeficientEnabled, setPriceTempCoeficientEnabled] = useState(coeficientEnabled == true ? true : false);
    const [copyPrice, setCopyPrice] = useState(true);
    const [copyPriceTemp, setCopyPriceTemp] = useState(true);
    const [cost, setCost] = useState('');
    const [costTemp, setCostTemp] = useState('');
    const [price, setPrice] = useState('');
    const [priceTemp, setPriceTemp] = useState('');
    const [priceRetail, setPriceRetail] = useState('');
    const [priceRetailTemp, setPriceRetailTemp] = useState('');
    const [code, setCode] = useState(props.item == false ? props.code != '' ? props.code + '-' + (parseInt(props.count) + 1) : '' : '');
    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const [codeVendor, setCodeVendor] = useState(false);
    const [quantityStock, setQuantityStock] = useState('0');
    const [priceTempEnabled, setPriceTempEnabled] = useState(false);
    const [priceStart, setPriceStart] = useState(Today());
    const [priceEnd, setPriceEnd] = useState(Today());
    const [stocks, setStocks] = useState(props.stocks);
    const [stocksQuantity, setStocksQuantity] = useState(props.stocks);
    const [flags, setFlags] = useState([]);
    const [portal, setPortal] = useState(true);
    const [book1, setBook1] = useState(0);
    const [book2, setBook2] = useState(0);
    const [book3, setBook3] = useState(0);

    // PARAMETRE PRODUKTU
    const [paramSelected, setParamSelected] = useState(false);
    const [params, setParams] = useState(false);
    const [showParam, setShowParam] = useState(false);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);

    // NACITANIE KODU - ADC / SUKL
    const [codeID, setCodeID] = useState(0);
    const [showDistributorCodes, setShowDistributorCodes] = useState(false);

    // VYBER FARBY
    const [showColors, setShowColors] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 900 ? 900 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = props.item == false ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = 600 - 40 - column1;
    const stockCellWidth = 150;
    const stockCellHeight = 90;
    const cardHeight = 70;

    const tabs = [
        { id: 0, enabled: true, label: lang.setting },
        { id: 2, enabled: true, label: lang.stock_card },
        { id: 1, enabled: true, label: lang.history },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }

            db_data();
            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id,
                    prices_final: false,
                    stock_status: true,
                    condition_exe: false
                })
            })


            const json = await response.json();
            Debug('Product', 'red');
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    AssignData(item);
                    setParams(item.params == undefined ? false : item.params);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setFlagItems(json.flags);
            }

            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    product_id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_check = async (data) => {
        Debug(code);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.count == 0) {
                    db_update(data);
                } else {
                    setError(lang.product_code_error);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_params = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_params', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    product_id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setParams(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 0) {
            setEditing(false);
            setNeedSave(false);
            db_get(false);
        }

        if (id == 1) {
            setEditing(false);
            db_get_logs();
        }

    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setPortal(item.portal == false ? false : true);
        setImage(item.image);
        setName(item.name);
        setPriceCoeficientEnabled(item.price_coeficient_enabled == false ? false : true);
        setPriceTempCoeficientEnabled(item.price_temp_coeficient_enabled == false ? false : true);
        setCopyPrice(item.variation_copy_price == false ? false : true);
        setCopyPriceTemp(item.variation_copy_price_temp == false ? false : true);
        setCost(item.cost);
        setCostTemp(item.cost_temp);
        setPrice(item.price);
        setPriceTemp(item.price_temp);
        setPriceRetail(item.price_retail);
        setPriceRetailTemp(item.price_retail_temp);
        setCode(item.code);
        setCode1(item.code_1);
        setCode2(item.code_2);
        setCode3(item.code_3);
        setCode4(item.code_4);
        setCodeVendor(item.code_vendor);
        setQuantityStock(item.quantity_stock);
        setPriceTempEnabled(item.price_temp_enabled == false ? false : true);
        setPriceStart(item.price_start == null ? Today() : item.price_start);
        setPriceEnd(item.price_end == null ? Today() : item.price_end);
        setStocksQuantity(item.stocks_quantity);
        setFlags(item.flags == false ? [] : item.flags);
        setBook1(item.book_1);
        setBook2(item.book_2);
        setBook3(item.book_3);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
        setNeedSave(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.enabled = enabled;
        storeData.portal = portal;
        storeData.image = image;
        storeData.name = name;
        storeData.price_coeficient_enabled = priceCoeficientEnabled;
        storeData.price_temp_coeficient_enabled = priceTempCoeficientEnabled;
        storeData.variation_copy_price = copyPrice;
        storeData.variation_copy_price_temp = copyPriceTemp;
        storeData.cost = cost;
        storeData.cost_temp = costTemp;
        storeData.price = price;
        storeData.price_temp = priceTemp;
        storeData.price_temp_enabled = priceTempEnabled;
        storeData.price_retail = priceRetail;
        storeData.price_retail_temp = priceRetailTemp;
        storeData.code = code;
        storeData.code_1 = code1;
        storeData.code_2 = code2;
        storeData.code_3 = code3;
        storeData.code_4 = code4;
        storeData.code_vendor = codeVendor;
        storeData.quantity_stock = quantityStock;
        storeData.priceStart = priceStart;
        storeData.priceEnd = priceEnd;
        storeData.flags = flags;
        storeData.book_1 = book1;
        storeData.book_2 = book2;
        storeData.book_3 = book3;
    }

    const Save = () => {
        setError('');
        var error = 0;
        var error_text = lang.required_red;

        error += IsEmpty(code) == true ? 1 : 0;
        error += IsEmpty(name) == true ? 1 : 0;
        if (copyPrice == false) {
            error += IsEmpty(cost) == true ? 1 : 0;
        }
        if (copyPriceTemp == false && priceTempEnabled == true) {
            error += IsEmpty(costTemp) == true ? 1 : 0;
        }

        if (priceTempEnabled == true) {
            // Kontrola dátumov
            let date1 = new Date(priceStart).getTime();
            let date2 = new Date(priceEnd).getTime();
            if (date1 > date2) {
                error++;
                error_text = lang.product_price_start_error;
            }
        }


        if (error == 0) {
            var action = global.logs.variation_created;
            if (props.item != false) {
                action = global.logs.variation_modified;
                if (enabled != storeData.enabled) {
                    action = enabled == true ? global.logs.variation_enabled : global.logs.variation_disabled;
                }
            }

            if (props.item != false) {
                if (storeData.cost != cost) {
                    action = global.logs.variation_price_cost_changed;
                }
                if (storeData.price != price) {
                    action = global.logs.variation_price_sell_changed;
                }
                if (storeData.cost != cost && storeData.price != price) {
                    action = global.logs.variation_prices_changed;
                }
            }

            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                product_id: props.item == false ? 0 : props.item.id,
                parent_id: props.productID,
                action: action,
                cost: ConvertToPrice(cost, precisions),
                price: ConvertToPrice(price, precisions),
                enabled: enabled
            };

            let data = {
                id: props.item == false ? 0 : props.item.id,
                organization_id: props.organization.id,
                enabled: enabled,
                portal: portal,
                variations: false,
                parent_id: props.productID,
                name: name.trim(),
                image: image,
                price_coeficient_enabled: priceCoeficientEnabled,
                price_temp_coeficient_enabled: priceTempCoeficientEnabled,
                variation_copy_price: copyPrice,
                variation_copy_price_temp: false,
                cost: ConvertToPrice(cost, precisions),
                cost_temp: ConvertToPrice(costTemp, precisions),
                price: ConvertToPrice(price, precisions),
                price_temp: ConvertToPrice(priceTemp, precisions),
                price_temp_enabled: copyPrice == true ? false : priceTempEnabled,
                price_retail: ConvertToPrice(priceRetail, precisions),
                price_retail_temp: ConvertToPrice(priceRetailTemp, precisions),
                code: code,
                code_1: code1,
                code_2: code2,
                code_3: code3,
                code_4: code4,
                code_vendor: codeVendor.trim(),
                quantity_stock: quantityStock,
                date_start: global.date_start,
                date_end: global.date_endless,
                price_start: priceStart,
                price_end: priceEnd,
                flags: flags == false ? false : JSON.stringify(flags),
                country_id: props.countryID,
                book_1: book1,
                book_2: book2,
                book_3: book3,

                logs: log,
                stocks: stocks
            };

            if (props.item == false) {
                // NOVY ZAZNAM - GENEROVANIE UID
                data.stock_uid = UniqueID();
            }

            StoreEditing();
            db_check(data);

        } else {
            setError(error_text);
        }

    }

    const ShowOKPress = () => {
        func(true);
    }

    const AvatarPress = (value) => {
        setNeedSave(true);
        if (value == 1) {
            setSelectPhoto(true);
        }
        if (value == 0) {
            setImage('');
        } if (value == 2) {
            setShowColors(true);
        }
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setImage(path + file);
        }
    }

    const ColorsResult = (typ, color) => {
        setShowColors(false);
        if (typ == true) {
            Debug(color);
            setImage(color);
        }
    }


    const StockHistoryResult = () => { }

    const ChangeCode = (id, value, button) => {
        if (button == true) {
            setCodeID(id);
            setShowDistributorCodes(true);
        } else {
            setNeedSave(true);
            if (id == 1) {
                setCode1(value);
            }
            if (id == 2) {
                setCode2(value);
            }
            if (id == 3) {
                setCode3(value);
            }
            if (id == 4) {
                setCode4(value);
            }
        }
    }

    const DistributorCodesResult = (typ, item) => {
        setShowDistributorCodes(false);

        if (typ == true) {
            if (codeID == 1) {
                setCode1(item.adc);
            }
            if (codeID == 2) {
                setCode2(item.adc);
            }
            if (codeID == 3) {
                setCode3(item.adc);
            }
            if (codeID == 4) {
                setCode4(item.adc);
            }
        }
    }

    const ParamResult = (typ, redraw_) => {
        setShowParam(false);

        if (typ == true) {
            db_params();
        }
    }

    const EditParam = (item) => {
        if (editing == true) {
            setParamSelected(item);
            setShowParam(true);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.product_variation_new : lang.product_variation}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: '#F4F4F4', borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    {props.item != false ?
                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs center disabled={needSave == true ? true : false} value={tabIndex} items={tabs} width={dialogWidth / 3} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, minHeight: dialogHeight - global.dialog_bar_height - buttonHeight - errorHeight - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                            {storeData != false ?
                                <div style={{ ...styles.Block }}>
                                    <FormSpace />
                                    <div style={{ ...styles.Block }}>
                                        <Avatar color editable={editing} picture image={image} size={120} theme={props.theme} func={AvatarPress.bind()} />
                                        <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.product_variation_image}</p>
                                    </div>
                                    <FormSpace />
                                    {/* INFORMACIE O PRODUKTE */}
                                    <FormSpace />
                                    <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} icon={faInfoCircle} title={lang.product_variation_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                    {props.productEnabled == true ?
                                        <FormYesNo value={enabled} editing={editing} title={lang.product_variation_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setEnabled(value); setNeedSave(true); }} />
                                        : null}
                                    <FormText require redraw value={code} editing={editing} title={lang.product_variation_code} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setCode(txt); setNeedSave(true); }} />
                                    <FormText require redraw value={name} editing={editing} title={lang.product_variation_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setName(txt); setNeedSave(true); }} />
                                    <FormFlags value={flags} items={flagItems} field={'name'} editing={editing} title={lang.flags} width={200} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setFlags(id); setNeedSave(true); }} />

                                    {props.system.product_book_1 == true ?
                                        <FormSelect require value={book1} items={false} field={'name'} codebook={global.codebook.product_book_1} editing={editing} title={props.system.product_book_label_1} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => { setBook1(id); setNeedSave(true); }} />
                                        : null}
                                    {props.system.product_book_2 == true ?
                                        <FormSelect require value={book2} items={false} field={'name'} codebook={global.codebook.product_book_2} editing={editing} title={props.system.product_book_label_1} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => { setBook2(id); setNeedSave(true); }} />
                                        : null}
                                    {props.system.product_book_3 == true ?
                                        <FormSelect require value={book3} items={false} field={'name'} codebook={global.codebook.product_book_3} editing={editing} title={props.system.product_book_label_1} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => { setBook3(id); setNeedSave(true); }} />
                                        : null}

                                    <FormYesNo value={portal} editing={editing} title={lang.product_portal_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setPortal(value); setNeedSave(true); }} />
                                    <FormNote title={lang.product_portal_enabled_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />

                                    {/* CENA */}
                                    <FormSpace />
                                    <FormLabel editing={false} title={lang.product_variation_price} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                    <FormYesNo value={copyPrice} editing={editing} title={lang.product_variation_copy_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setCopyPrice(value); setNeedSave(true); }} />
                                    {copyPrice == false ?
                                        <div>
                                            <FormText require money currency={currency} precisions={precisions} redraw value={cost} editing={editing} title={lang.product_cost} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setCost(txt); setNeedSave(true); }} />
                                            {coeficientEnabled == true ?
                                                <FormYesNo value={priceCoeficientEnabled} editing={editing} title={lang.system_price_coeficient_enable} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setPriceCoeficientEnabled(value); setNeedSave(true); }} />
                                                : null}
                                            {priceCoeficientEnabled == false || coeficientEnabled == false ?
                                                <FormText money currency={currency} precisions={precisions} redraw value={price} editing={editing} title={lang.product_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPrice(txt); setNeedSave(true); }} />
                                                :
                                                <FormNote title={lang.product_price_coeficient} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                            }
                                            <FormText money currency={currency} precisions={precisions} redraw value={priceRetail} editing={editing} title={lang.product_price_retail} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPriceRetail(txt); setNeedSave(true); }} />
                                        </div>
                                        : null}
                                    <FormSpace />

                                    {copyPrice == false ?
                                        <div style={{ ...styles.Block }}>
                                            {/* DOCASNA CENA */}
                                            <FormSpace />
                                            <FormLabel editing={false} title={lang.product_variation_ranged} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                            <div>
                                                <FormYesNo value={priceTempEnabled} editing={editing} title={lang.product_price_ranged_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setPriceTempEnabled(value); setNeedSave(true); }} />
                                                <FormSpace />
                                                {priceTempEnabled == true ?
                                                    <div>
                                                        <FormText redraw date value={priceStart} editing={editing} title={lang.product_price_start} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPriceStart(txt); setNeedSave(true); }} />
                                                        <FormText redraw date value={priceEnd} editing={editing} title={lang.product_price_end} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPriceEnd(txt); setNeedSave(true); }} />
                                                        <FormSpace />
                                                        <FormText money require currency={currency} precisions={precisions} redraw value={costTemp} editing={editing} title={lang.product_cost} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setCostTemp(txt); setNeedSave(true); }} />
                                                        {coeficientEnabled == true ?
                                                            <FormYesNo value={priceTempCoeficientEnabled} editing={editing} title={lang.system_price_coeficient_enable} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setPriceTempCoeficientEnabled(value); setNeedSave(true); }} />
                                                            : null}
                                                        {priceTempCoeficientEnabled == false || coeficientEnabled == false ?
                                                            <FormText money currency={currency} precisions={precisions} redraw value={priceTemp} editing={editing} title={lang.product_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPriceTemp(txt); setNeedSave(true); }} />
                                                            :
                                                            <FormNote title={lang.product_price_coeficient} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                        }
                                                        <FormText money currency={currency} precisions={precisions} redraw value={priceRetailTemp} editing={editing} title={lang.product_price_retail} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPriceRetailTemp(txt); setNeedSave(true); }} />
                                                        <FormSpace />
                                                    </div>
                                                    : null}
                                            </div>
                                            <FormSpace />
                                        </div>
                                        : null}

                                    {/* DODATOCNE KODY PRODUKTU */}
                                    <FormSpace />
                                    <FormLabel editing={editing} title={lang.product_code_external_} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                    {props.system.product_codes[3].enabled == true ?
                                        // KOD DODAVATELA
                                        <FormText redraw value={codeVendor} editing={editing} title={lang.product_code_vendor_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => { setCodeVendor(txt); setNeedSave(true); }} />
                                        : null}
                                    {props.system.product_codes[4].enabled == true ?
                                        // EAN KODY
                                        <FormCodes redraw product_id={props.item.id} typ={0} editing={editing} title={lang.product_code_ean} column1={column1} column2={column2} theme={props.theme} radius={props.radius} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} token={props.token} />
                                        : null}

                                    {props.system.product_codes[0].enabled == true ?
                                        <FormText redraw value={code1} editing={editing} button={product_code_searching == true && props.system.product_code_search_1 == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.product_codes[0].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(1, txt, press)} />
                                        : null}
                                    {props.system.product_codes[1].enabled == true ?
                                        <FormText redraw value={code2} editing={editing} button={product_code_searching == true && props.system.product_code_search_2 == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.product_codes[1].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(2, txt, press)} />
                                        : null}
                                    {props.system.product_codes[2].enabled == true ?
                                        <FormText redraw value={code3} editing={editing} button={product_code_searching == true && props.system.product_code_search_3 == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.product_codes[2].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(3, txt, press)} />
                                        : null}
                                    {props.system.product_codes[5].enabled == true ?
                                        <FormText redraw value={code4} editing={editing} button={product_code_searching == true && props.system.product_code_search_4 == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.product_codes[5].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(4, txt, press)} />
                                        : null}
                                    <FormSpace />

                                    {props.system.eshop_enabled == true && props.item != false ?
                                        // PARAMETRE A SPECIFIKACIA PRODUKTU
                                        <>
                                            <FormSpace />
                                            <FormLabel editButton={editing} buttonIcon={faPlus} editing={false} title={lang.product_params_label} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => { setParamSelected(false); setShowParam(true); }} />

                                            {showParam == true ?
                                                <ProductParams product_id={props.item.id} param={paramSelected} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={ParamResult.bind(this)} />
                                                : null}

                                            {params != false ? params.map((item, index) => (
                                                <div onClick={() => EditParam(item)} style={{ ...styles.BlockRowRaw, cursor: 'pointer' }}>
                                                    <div style={{ ...styles.BlockRight, width: column1, marginRight: 10, height: 30 }}>
                                                        <p style={{ ...styles.TextTiny }}>{item.name}:</p>
                                                    </div>
                                                    {item.color != undefined ?
                                                        item.color != false ?
                                                            <div style={{ ...styles.BlockRowRaw, width: column2 }}>
                                                                <div style={{ ...styles.BlockLeft, width: 32, height: 30 }}>
                                                                    <div style={{ ...styles.BlockLeft, width: 24, height: 24, borderRadius: 12, backgroundColor: item.color }}>
                                                                    </div>
                                                                </div>
                                                                <div style={{ ...styles.BlockLeft, width: column2 - 32, height: 30 }}>
                                                                    <p style={{ ...styles.TextTiny }}>{item.text} {item.unit}</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div style={{ ...styles.BlockLeft, width: column2, height: 30 }}>
                                                                <p style={{ ...styles.TextTiny }}>{item.text} {item.unit}</p>
                                                            </div>

                                                        :
                                                        <div style={{ ...styles.BlockLeft, width: column2, height: 30 }}>
                                                            <p style={{ ...styles.TextTiny }}>{item.text} {item.unit}</p>
                                                        </div>
                                                    }
                                                </div>
                                            )) : false}
                                            <FormSpace />
                                        </>
                                        : null}

                                    <FormSpace />
                                    {/* SKLADOVE INFORMACIE */}
                                    {props.item == false ?
                                        <div style={{ ...styles.Block, width: '98%', backgroundColor: color.white, borderRadius: radius }}>
                                            <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                                {/* SKLADOVE INFORMACIE */}
                                                <FormSpace />
                                                <p style={{ ...styles.TextLarge, color: color.black, fontWeight: '600' }}>{lang.product_stock_information}:</p>
                                                <FormSpace />
                                                <div style={{ ...styles.BlockCenter, flexDirection: 'row', flexWrap: 'wrap' }}>
                                                    {stocks != false ? stocks.map((item, index) => (
                                                        <div key={item.id} style={{ ...styles.Block, width: stockCellWidth, border: color.border, paddingTop: 5, paddingBottom: 5, margin: 5 }}>
                                                            <div style={{ ...styles.Block, height: 30 }}>
                                                                <p style={{ ...styles.TextTiny }}>{item.code}</p>
                                                            </div>
                                                            <div style={{ ...styles.Block, height: stockCellHeight - 30 }}>
                                                                <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{lang.stock_quantity}</p>
                                                                <TextInput numeric center precision={0} redraw theme={props.theme} enabled={true} lang={lang} value={item.quantity} label={''} width='90%' func={(txt) => { item.quantity = txt; setNeedSave(true); }} />
                                                            </div>
                                                        </div>
                                                    )) : null}
                                                </div>
                                                <FormSpace />
                                            </div>
                                        </div>
                                        : null}

                                    <FormSpace />
                                    {editing == false ?
                                        <FormLabel editing={false} title={lang.product_stock_information} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                        : null}
                                    {editing == false ?
                                        <div style={{ ...styles.BlockLeft, width: column1 + column2 }}>
                                            <div style={{ ...styles.BlockCenter, flexDirection: 'row', flexWrap: 'wrap' }}>
                                                {stocksQuantity != false ? stocksQuantity.map((item, index) => (
                                                    <div key={item.id} style={{ ...styles.Block, width: stockCellWidth, border: color.border, paddingTop: 5, paddingBottom: 5, margin: 5 }}>
                                                        <div style={{ ...styles.Block, width: '90%', height: stockCellHeight / 2 }}>
                                                            <p style={{ ...styles.TextTiny }}>{item.code}</p>
                                                        </div>
                                                        <div style={{ ...styles.Block, height: stockCellHeight / 2, borderTop: color.border }}>
                                                            <div style={{ ...styles.Block, height: stockCellHeight / 2 }}>
                                                                <p style={{ ...styles.TextTiny, marginTop: 2, fontWeight: '600' }}>{item.quantity} {item.unit}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : null}
                                            </div>
                                        </div>
                                        : null}

                                    <FormSpace height={global.list_padding} />

                                </div>
                                : null}
                        </div>
                        : null}

                    {tabIndex == 2 ?
                        // SKLADOVA KARTA
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, width: '98%' }}>
                                <StockHistory showNew showSelect stock_uid={props.item.stock_uid} width={dialogWidth} height={dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight} organization={props.organization} system={props.system} locale={props.locale} user={props.user} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={StockHistoryResult.bind(this)} />
                            </div>
                        </div>
                        : null}


                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs logs={logs} lang={props.lang} theme={props.theme} locale={props.locale} currency={currency} precisions={precisions} />
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {tabIndex == 0 && editing == true && needSave == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            {editing == true && props.item != false ?
                                <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                :
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                            }
                        </div>
                    </div>
                </div >

                {showDistributorCodes == true ?
                    <DistributorsCodes product remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={DistributorCodesResult.bind(this)} />
                    : null}

                {showColors == true ?
                    <DialogColors remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={ColorsResult.bind(this)} />
                    : null}

                {selectPhoto == true ?
                    <Photos autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} locale={props.locale} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}



/*
*
* =================================================================================================================
* INFORMACIE O PRODUKTE
* =================================================================================================================
*
*/
export const ProductInfo = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'stock_adjustment';
    let currency = props.locale.currency;
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let coeficientEnabled = props.system.price_coeficient_enabled == false ? false : true;
    let coeficient = props.system.price_coeficient;
    let product_id = props.product_id;

    let productTyp = [
        { id: 1, label: lang.product_type_standart },
        { id: 2, label: lang.product_type_variant },
        { id: 3, label: lang.product_type_group },
        { id: 4, label: lang.product_variation }
    ];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(true);
    const [editing, setEditing] = useState(props.item == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [storeData, setStoreData] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [categories, setCategories] = useState(false);
    const [manufactures, setManufactures] = useState(false);
    const [vendors, setVendors] = useState(false);
    const [flagItems, setFlagItems] = useState(false);
    const [orderTypesList, setOrderTypesList] = useState(false);

    const [product, setProduct] = useState(true);
    const [enabled, setEnabled] = useState(true);
    const [number, setNumber] = useState('');
    const [image, setImage] = useState('');
    const [name, setName] = useState('');
    const [parentName, setParentName] = useState('');
    const [variations, setVariations] = useState(false);
    const [priceCoeficientEnabled, setPriceCoeficientEnabled] = useState(coeficientEnabled == true ? true : false);
    const [priceTempCoeficientEnabled, setPriceTempCoeficientEnabled] = useState(coeficientEnabled == true ? true : false);
    const [cost, setCost] = useState('');
    const [costTemp, setCostTemp] = useState('');
    const [price, setPrice] = useState('');
    const [priceTemp, setPriceTemp] = useState('');
    const [priceRetail, setPriceRetail] = useState('');
    const [priceRetailTemp, setPriceRetailTemp] = useState('');
    const [dph, setDph] = useState('');
    const [code, setCode] = useState('');
    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const [codeVendor, setCodeVendor] = useState(false);
    const [descriptionShort, setDescriptionShort] = useState('');
    const [descriptionLong, setDescriptionLong] = useState('');
    const [details, setDetails] = useState('');
    const [tags, setTags] = useState('');
    const [quantityStock, setQuantityStock] = useState('0');
    const [quantityPackage, setQuantityPackage] = useState('1');
    const [unit, setUnit] = useState(lang.piece);
    const [guarantee, setGuarantee] = useState('24');
    const [manufactureID, setManufactureID] = useState(0);
    const [vendorID, setVendorID] = useState(0);
    const [categoryID, setCategoryID] = useState(0);
    const [orderTypes, setOrderTypes] = useState(false);
    const [dateStart, setDateStart] = useState(Today());
    const [dateEnd, setDateEnd] = useState(Today());
    const [dateEndless, setDateEndless] = useState(true);
    const [priceTempEnabled, setPriceTempEnabled] = useState(false);
    const [priceStart, setPriceStart] = useState(Today());
    const [priceEnd, setPriceEnd] = useState(Today());
    const [countryID, setCountryID] = useState(0);
    const [conditionID, setConditionID] = useState(0);
    const [note, setNote] = useState('');
    const [typ, setTyp] = useState(1);
    const [stocks, setStocks] = useState(false);
    const [flags, setFlags] = useState([]);
    const [book1, setBook1] = useState(0);
    const [book2, setBook2] = useState(0);
    const [book3, setBook3] = useState(0);
    const [portal, setPortal] = useState(false);
    const [pageID, setPageID] = useState(0);
    const [B2B, setB2B] = useState(false);
    const [B2C, setB2C] = useState(false);

    // STRANKA
    const [showPage, setShowPage] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.dialogWidth == undefined ? width >= global.dialog_max_width ? global.dialog_max_width : width : props.dialogWidth;
    const dialogHeight = props.dialogHeight == undefined ? height >= global.dialog_max_height ? global.dialog_max_height : height : props.dialogHeight

    // CONSTANTS
    const tabHeight = props.item == false ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = (dialogWidth / 2) - 40 - column1;
    const stockCellWidth = 140;
    const stockCellHeight = 76;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            db_get(true);
            db_data();

            running = true;
        }

    }, []);

    const db_get = async () => {
        Debug('props.product_id', 'red');
        Debug(props.product_id);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    start: 0,
                    length: 1,
                    filter: { product_id: props.product_id, stock: true, country_id: props.locale.id, }
                    /*
                    id: props.product_id,
                    prices_final: final,
                    stock_status: true,
                    condition_exe: true
                    */
                })
            })

            const json = await response.json();
            Debug('------ PRODUCTS -----');
            Debug(json);
            if (json.ok > 0) {

                if (json.items != false) {
                    Debug(json.items[0]);

                    let item = json.items[0];
                    setStoreData(item);
                    AssignData(item);

                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_data = async () => {
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();
            Debug('PRODUCT-DATA');
            Debug(json);
            if (json.ok > 0) {
                setCategories(json.categories);
                setManufactures(json.manufactures);
                setVendors(json.vendors);
                setFlagItems(json.flags);
                setOrderTypesList(json.order_types);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const Close = () => {
        func(false);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setProduct(item);
        setEnabled(item.enabled == false ? false : true);
        setTyp(item.variations == true ? 2 : item.grouped == true ? 3 : item.parent_id > 0 ? 4 : 1);
        setPortal(item.portal == false ? false : true);
        setNumber(item.number);
        setImage(item.image);
        setName(item.name);
        setParentName(item.variants == false ? '' : item.parent_name);
        setVariations(item.variations == false ? false : true);
        setPriceCoeficientEnabled(item.price_coeficient_enabled == false ? false : true);
        setPriceTempCoeficientEnabled(item.price_temp_coeficient_enabled == false ? false : true);
        setCost(item.cost);
        setCostTemp(item.cost_temp);
        setPrice(item.price);
        setPriceTemp(item.price_temp);
        setPriceRetail(item.price_retail);
        setPriceRetailTemp(item.price_retail_temp);
        setDph(item.dph == -1 ? props.locale.dph : item.dph);
        setCode(item.code);
        setCode1(item.code_1);
        setCode2(item.code_2);
        setCode3(item.code_3);
        setCode4(item.code_4);
        setCodeVendor(item.code_vendor);
        setDescriptionShort(item.description_short);
        setDescriptionLong(item.description_long);
        setDetails(item.details);
        setTags(item.tags);
        setQuantityStock(item.quantity_stock);
        setQuantityPackage(item.quantity_package);
        setUnit(item.unit);
        setGuarantee(item.guarantee);
        setManufactureID(item.manufacture_id);
        setVendorID(item.vendor_id);
        setCategoryID(item.products_category_id);
        setOrderTypes(item.order_types);
        setDateStart(item.date_start == null ? Today() : item.date_start);
        setDateEnd(item.date_end == global.date_endless ? Today() : item.date_end);
        setDateEndless(item.date_end == global.date_endless ? true : false);
        setPriceTempEnabled(item.price_temp_enabled == false ? false : true);
        setPriceStart(item.price_start == null ? Today() : item.price_start);
        setPriceEnd(item.price_end == null ? Today() : item.price_end);
        setCountryID(item.country_id);
        setConditionID(item.condition_id);
        setNote(item.note);
        setStocks(item.stocks_quantity);
        setBook1(item.book_1);
        setBook2(item.book_2);
        setBook3(item.book_3);
        setFlags(item.flags == false ? [] : item.flags);
        setPageID(item.page_id);
        setB2B(item.b2b == false ? false : true);
        setB2C(item.b2c == false ? false : true);
    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const RenderData = () => {
        return (

            <div style={{ ...styles.BlockCenter, height: dialogHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                {isBusy == false && storeData != false ?
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, backgroundColor: color.white }}>
                            <FormSpace />
                            <Avatar editable={editing} picture image={image} size={200} theme={props.theme} func={null} />
                            <FormSpace />
                            <Line theme={props.theme} />
                        </div>

                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.Block, width: '50%' }}>
                                {/* INFORMACIE O PRODUKTE */}
                                < FormSpace />
                                <FormLabel paddingBottom={0} editing={false} icon={faInfoCircle} title={lang.product_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                <FormYesNo marginTop={0} value={enabled} editing={false} title={lang.product_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                <FormRadio marginTop={0} value={typ} items={productTyp} editing={false} title={lang.product_typ} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={null} />

                                {props.system.eshop_enabled == true && props.system.products_b2b_b2c == true ?
                                    <FormSwitch value1={B2B} value2={B2C} title={lang.products_b2b_b2c} title_1={lang.customer_b2b_} title_2={lang.customer_b2c_} editing={editing} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value1, value2) => { setB2B(value1); setB2C(value2); }} />
                                    : null}

                                <FormText marginTop={0} redraw value={code} editing={false} title={lang.product_code} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCode(txt)} />
                                {parentName != '' ?
                                    <FormText marginTop={0} redraw value={parentName} editing={false} title={lang.product_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setParentName(txt)} />
                                    : null}
                                <FormText marginTop={0} redraw value={storeData.name} editing={false} title={storeData.parent_id == 0 ? lang.product_name : lang.product_variation_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                <FormAutoComplete marginTop={0} value={categoryID} items={categories} field={'label'} editing={false} title={lang.product_category} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCategoryID(id)} />
                                {props.system.order_types == true ?
                                    <FormSelectChips value={orderTypes} items={orderTypes} field={'name'} editing={false} title={lang.order_types} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setOrderTypes(id)} />
                                    : null}
                                <FormAutoComplete marginTop={0} value={manufactureID} items={manufactures} field={'name'} editing={false} title={lang.manufacturer} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setManufactureID(id)} />
                                <FormAutoComplete marginTop={0} value={vendorID} items={vendors} field={'name'} editing={false} title={lang.vendor} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setVendorID(id)} />
                                {typ != 2 ?
                                    <FormFlags value={flags} items={flagItems} field={'name'} editing={editing} title={lang.flags} width={200} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setFlags(id)} />
                                    : null}
                                <FormText require redraw value={details} editing={false} title={lang.product_details} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDetails(txt)} />
                                {props.system.product_book_1 == true ?
                                    <FormSelect require value={book1} items={false} field={'name'} codebook={global.codebook.product_book_1} editing={false} title={props.system.product_book_label_1} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setBook1(id)} />
                                    : null}
                                {props.system.product_book_2 == true ?
                                    <FormSelect require value={book2} items={false} field={'name'} codebook={global.codebook.product_book_2} editing={false} title={props.system.product_book_label_1} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setBook2(id)} />
                                    : null}
                                {props.system.product_book_3 == true ?
                                    <FormSelect require value={book3} items={false} field={'name'} codebook={global.codebook.product_book_3} editing={false} title={props.system.product_book_label_1} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setBook3(id)} />
                                    : null}
                                <FormYesNo value={portal} editing={false} title={lang.product_portal_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setPortal(value)} />


                                {/* CENY PRODUKTU */}
                                <FormSpace />
                                <FormLabel paddingBottom={0} editing={false} title={lang.product_prices} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                <FormText marginTop={0} money currency={currency} precisions={precisions} redraw value={cost} editing={false} title={lang.product_cost} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCost(txt)} />
                                <FormText marginTop={0} money currency={currency} precisions={precisions} redraw value={price} editing={false} title={lang.product_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPrice(txt)} />
                                <FormText marginTop={0} money currency={currency} precisions={precisions} redraw value={priceRetail} editing={false} title={lang.product_price_retail} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPriceRetail(txt)} />
                                <FormText marginTop={0} number precisions={precisions} redraw value={dph} editing={false} title={lang.product_dph + ' %'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDph(txt)} />

                                {/* DATUM PREDAJA */}
                                <FormSpace />
                                <FormLabel marginTop={0} paddingBottom={0} editing={false} title={lang.product_date_start_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                <FormText marginTop={0} redraw date value={dateStart} editing={editing} title={lang.product_date_start} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDateStart(txt)} />
                                {dateEndless == false ?
                                    <div>
                                        <FormText redraw date value={dateEnd} editing={editing} title={lang.product_date_end} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDateEnd(txt)} />
                                    </div>
                                    : null}

                                {/* POPIS PRODUKTU */}
                                <FormSpace />
                                <FormLabel paddingBottom={0} editing={false} title={lang.product_description_information} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                <FormText marginTop={0} value={descriptionShort} editing={false} title={lang.product_description_short} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDescriptionShort(txt)} />
                                <FormText marginTop={0} value={note} editing={false} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />

                                {/* DODATOCNE KODY PRODUKTU */}
                                {/*typ != 2 ?
                                    <div style={{ ...styles.Block }}>
                                        <FormSpace />
                                        <FormLabel editing={editing} title={lang.product_code_external_} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />

                                        {props.system.product_codes[3].enabled == true ?
                                            // KOD DODAVATELA
                                            <FormText redraw value={codeVendor} editing={editing} title={lang.product_code_vendor_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => { setCodeVendor(txt) }} />
                                            : null}
                                        {props.system.product_codes[4].enabled == true ?
                                            // EAN KODY
                                            <FormCodes redraw product_id={props.product_id} typ={0} editing={editing} title={lang.product_code_ean} column1={column1} column2={column2} theme={props.theme} radius={props.radius} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} token={props.token} />
                                            : null}
                                        {props.system.product_codes[0].enabled == true ?
                                            <FormText redraw value={code1} editing={editing} title={props.system.product_codes[0].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCode1(txt)} />
                                            : null}
                                        {props.system.product_codes[1].enabled == true ?
                                            <FormText redraw value={code2} editing={editing} title={props.system.product_codes[1].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCode2(txt)} />
                                            : null}
                                        {props.system.product_codes[2].enabled == true ?
                                            <FormText redraw value={code3} editing={editing} title={props.system.product_codes[2].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCode3(txt)} />
                                            : null}
                                        <FormSpace />

                                        {props.system.eshop_enabled == true && pageID > 0 ?
                                            <div style={{ ...styles.Block, marginTop: 10 }}>
                                                <Button onClick={() => setShowPage(true)} style={{ ...styles.ButtonOutlined, width: 220 }}>{pageID == 0 ? lang.web_page_product_new : lang.web_page_product}</Button>
                                            </div>
                                            : null}

                                    </div>
                                    : null*/}
                            </div>
                            <div style={{ ...styles.Block, width: '50%' }}>
                                {/* SKLADOVE INFORMACIE */}
                                <FormSpace />
                                <FormLabel paddingBottom={0} editing={false} title={lang.product_stock_information} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                <FormText marginTop={0} redraw value={storeData.parent_id > 0 ? storeData.unit : unit} editing={false} title={lang.product_unit} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setUnit(txt)} />
                                <FormText marginTop={0} redraw value={storeData.parent_id > 0 ? storeData.quantity_package : quantityPackage} editing={false} title={lang.product_quantity_package} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setQuantityPackage(txt)} />
                                {/*
                                <FormText marginTop={0} redraw value={quantityStock} editing={false} title={lang.product_quantity_stock} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setQuantityStock(txt)} />
                                */}
                                <FormSpace />

                                {typ != 2 ?
                                    <div style={{ ...styles.BlockCenter, flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {stocks != false ? stocks.map((item, index) => (
                                            <StockStatus key={item.id} stock={item} product={product} theme={props.theme} />
                                        )) : null}
                                    </div>
                                    : null}

                            </div>
                        </div>
                        <FormSpace height={global.empty_padding} />
                    </div>
                    : null}

                {showPage == true ?
                    <WebPageDesigner preview item={{ id: pageID }} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowPage(false)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </div >

        );
    }

    return (
        props.dialog == true ?
            <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
                <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius, backgroundColor: color.back }}>
                    <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                        <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                            <Icon theme={props.theme} icon={faInfo} />
                        </div>
                        <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.product}</p>
                        </div>
                        <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                            <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                                <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 80, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        <RenderData />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                    </div>
                </Paper>
            </Backdrop>
            :
            <RenderData />
    );
}


export const ProductDuplicate = (props) => {
    /*
 
            VYTVORENIE KOPIE PRODUKTU
 
    */
    let lang = props.lang;

    const [isBusy, setBusy] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');

    const [enabled, setEnabled] = useState(false);
    const [countries, setCountries] = useState(props.organization.currencies);
    const [countryID, setCountryID] = useState(0);
    const [stockCard, setStockCard] = useState(false);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [product, setProduct] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 600;
    const dialogHeight = 636;

    let column1 = props.column1 == undefined ? 150 : props.column1;
    let column2 = props.column2 == undefined ? dialogWidth - column1 - 40 : props.column2;

    let { func } = props;
    const inputRef = useRef(null);
    const color = global.themes[props.theme];

    var running = false;
    useEffect(() => {

        if (running == false) {

            db_get();

            running = true;
        }

        return () => {
        };

    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.product_id,
                    prices_final: false,
                    stock_status: true,
                    condition_exe: false
                })
            })

            const json = await response.json();
            Debug('PRODUCT');
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;

                    setProduct(item);

                    setEnabled(item.enabled == false ? false : true);
                    setCountryID(item.country_id);
                    setName(item.name);
                    setCode(item.code);

                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_duplicate', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    original_id: props.product_id, // POVODNY PRODUKT
                    stock_create: stockCard,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(false, false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
        func(true);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Proccess = () => {

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            product_id: 0,
            parent_id: 0,
            action: global.logs.created,
            cost: product.cost,
            price: product.price,
            enabled: enabled
        };

        var data = product;

        data.id = 0;
        data.enabled = enabled;
        data.country_id = countryID;
        data.name = name;
        data.code = code;
        data.logs = log;

        data.code_ean = product.code_ean == false ? false : JSON.stringify(product.code_ean);
        data.code_vendor = product.code_vendor == false ? false : JSON.stringify(product.code_vendor);
        data.flags = product.flags == false ? false : JSON.stringify(product.flags);
        data.order_types = product.order_types == false ? false : JSON.stringify(product.order_types);
        delete (data.stocks_quantity);

        Debug(data);
        db_update(data);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faCopy} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.product_copy}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 140, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {countryID > 0 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 140, width: '98%', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <FormSpace />
                            <FormLabel editing={true} icon={faInfoCircle} title={lang.product_copy_new} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />

                            <FormYesNo value={enabled} editing={true} title={lang.product_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                            <FormNote title={lang.product_copy_enabled} marginBottom={8} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                            <FormText redraw value={name} editing={true} title={lang.product_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                            <FormText redraw value={code} editing={true} title={lang.product_code} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCode(txt)} />
                            <FormSpace />

                            <FormSelect require value={countryID} items={countries} field={'state'} editing={true} title={lang.app_country} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCountryID(id)} />
                            <FormNote title={lang.product_copy_country} marginBottom={8} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                            <FormYesNo value={stockCard} editing={true} title={lang.stock_card} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} yes={lang.product_copy_stock_card_1} no={lang.product_copy_stock_card_0} func={(value) => setStockCard(value)} />
                            <FormNote title={stockCard == true ? lang.product_copy_stock_card_new : lang.product_copy_stock_card_share} marginBottom={8} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                            <FormSpace />
                            <FormInfo title={lang.product_copy_new_note} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                        </div>
                        : null}
                </div>
                <div style={{ ...styles.Block, height: 140 }}>
                    <div style={{ ...styles.Block, height: 50 }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: 90 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button onClick={() => Proccess()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 180 }}>{lang.product_copy_}</Button>
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 180, marginLeft: 20 }}>{props.lang.close}</Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}


            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}
