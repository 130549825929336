/*
*
* =================================================================================================================
* PRESUN NA INY SKLAD

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useRef, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormSelect, DeleteButton, ProductSearch } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, IconButton, Pagination, Paper, Skeleton, Stack, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowPointer, faArrowRight, faArrowUp, faCheck, faInfoCircle, faLayerGroup, faPen, faPlus, faPlusCircle, faQuestion, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, FormatPieces, GetLeftPosition, GetPages, IsEmpty, StockCovered, StockCoveredChip } from './functions';
import { Photos } from './photos';
import { ProductSelect } from './product_select.js';
import { GetDatum1, GetDatum2, GetDatumTime, GetDatumTime2, GetTodayYear } from './functions_date.js';
import { ProductInfo } from './products.js';
import { UsersSelect } from './users_select.js';
import { ApprovalAsk } from './approvals.js';

export const ProductTransports = (props) => {

    let module_id = global.model.stock_transport;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'stock_transport';
    let limited = props.permissions.stocks_limited == true && props.user.super_admin == false ? true : false;

    let defaultFilter = {
        year: GetTodayYear(),
        agent_id: limited == true ? props.user.id : undefined,
    }

    const stockStates = [
        { id: 0, name: lang.stocks_transport_states[0] },
        { id: 1, name: lang.stocks_transport_states[1] },
        { id: 2, name: lang.stocks_transport_states[2] },
        { id: 3, name: lang.stocks_transport_states[3] },
    ]
    const [stockStateID, setStockStateID] = useState(0);

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.stock_transport_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [params, setParams] = useState(props.params);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // UZIVATELIA
    const [showUsers, setShowUsers] = useState(false);
    const [userID, setUserID] = useState(0);
    const [user, setUser] = useState(false);

    // FILTROVANIE
    const [stockFromID, setStockFromID] = useState(0);
    const [stockFrom, setStockFrom] = useState(false);
    const [stockToID, setStockToID] = useState(0);
    const [stockTo, setStockTo] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [year, setYear] = useState(GetTodayYear());

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.number, default: true },
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 92;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const menuWidth = global.sub_menu_width;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            var filter = defaultFilter;

            if (props.params != false) {

                if (props.params.add_new == true) {
                    AddNew();
                } else {
                    if (props.params.stock_from_id != undefined) {
                        filter.stock_from_id = props.params.stock_from_id;
                        filter.filter = true;
                        setStockFromID(props.params.stock_from_id);
                        setStockFrom(props.params.stock_from);
                    }
                    if (props.params.stock_to_id != undefined) {
                        filter.stock_to_id = props.params.stock_to_id;
                        filter.filter = true;
                        setStockToID(props.params.stock_to_id);
                        setStockTo(props.params.stock_to);
                    }
                }
            }

            db_get(0, filter, maxItems);

            running = true;
        }

    }, [props.params]);

    const db_get = async (page, filter, maxItems) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_transports', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })
            Debug(filter);
            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {

                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_transport_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.stock_transports,
                    filename: lang.stock_transports, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GetFilter = () => {
        var filter = defaultFilter;
        filter.year = year;
        //filter.ordering = ordering;
        //filter.order_column = orderColumn;

        if (stockStateID == 1) {
            filter.status = global.stock_trasports.in_proccess;
        }

        if (stockStateID == 2) {
            filter.status = global.stock_trasports.finished;
        }

        if (stockStateID == 3) {
            filter.status = global.stock_trasports.canceled;
        }

        if (userID > 0 && limited == false) {
            filter.agent_id = userID;
        }

        if (stockFromID > 0) {
            filter.stock_from_id = stockFromID;
        }

        if (stockToID > 0) {
            filter.stock_to_id = stockToID;
        }


        if (search.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            filter.search = search;
            filter.search_columns = columns;
        }

        return filter;
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            filter.search = txt;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            var filter = GetFilter();
            delete (filter.search);
            delete (filter.search_columns);
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw) => {
        setParams(false);
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const SelectYearResult = (year_, month) => {
        setYear(year_);
        var filter_ = filter
        filter_.year = year_;
        db_get(0, filter_, maxItems);
    }

    const ChangeState = (id) => {
        setStockStateID(id);

        if (id == 0) {
            // VSETKY ZAZNAMY
            var filter = GetFilter();
            delete (filter.status);
            db_get(0, filter, maxItems);
        }
        if (id == 1) {
            // V PRIPRAVE
            var filter = GetFilter();
            filter.status = global.stock_trasports.in_proccess;

            db_get(0, filter, maxItems);
        }
        if (id == 2) {
            // UKONCENE
            var filter = GetFilter();
            filter.status = global.stock_trasports.finished;

            db_get(0, filter, maxItems);
        }
        if (id == 3) {
            // ZRUSENE
            var filter = GetFilter();
            filter.status = global.stock_trasports.canceled;

            db_get(0, filter, maxItems);
        }

    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }


    const UserResult = (typ, selected) => {
        setShowUsers(false);

        if (typ == true && selected != false) {
            setUserID(selected.id);
            setUser(selected);

            var filter = GetFilter();
            filter.agent_id = selected.id;

            db_get(0, filter, maxItems);
        }
    }

    const UserOnly = () => {
        setUser(props.user);
        setUserID(props.user.id);
        var filter = GetFilter();
        filter.agent_id = props.user.id;
        db_get(0, filter, maxItems);
    }

    const UserReset = () => {
        setUserID(0);

        var filter = GetFilter();
        if (limited == false) {
            delete (filter.agent_id);
        }

        db_get(0, filter, maxItems);
    }

    const StockFromReset = () => {

        var filter = GetFilter();
        delete (filter.stock_from_id);

        setStockFromID(0);

        db_get(0, filter, maxItems);
    }

    const StockToReset = () => {

        var filter = GetFilter();
        delete (filter.stock_to_id);

        setStockToID(0);

        db_get(0, filter, maxItems);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div id={'id_start'} ></div>
                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%', marginTop: 10 }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.BlockRow }}>
                                {/*
                                    <div style={{ ...styles.BlockLeft, width: '40%', height: menuHeight }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <TextInput search theme={props.theme} width={300} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                            <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                                <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                    */}
                                <div style={{ ...styles.BlockLeft, width: 220, height: menuHeight }}>
                                    <SelectInput redraw theme={props.theme} enabled={true} lang={lang} value={stockStateID} items={stockStates} width={200} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => ChangeState(id)} />
                                </div>
                                {limited == false ?
                                    <div style={{ ...styles.Block, width: 170, height: menuHeight }}>
                                        <Button onClick={() => setShowUsers(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                            <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                            {lang.user}
                                        </Button>
                                    </div>
                                    : null}
                                {limited == false ?
                                    <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                        <IconButton onClick={() => UserOnly(true)} style={{ ...styles.ButtonUser }}>
                                            <FontAwesomeIcon style={{ height: 14 }} icon={faUser} />
                                        </IconButton>
                                    </div>
                                    : null}
                                <div style={{ ...styles.BlockRight, width: limited == true ? '55%' : '40%', height: menuHeight }}>
                                    <SelectYear min={2020} value={year} theme={props.theme} func={SelectYearResult.bind(this)} />
                                </div>
                                <div style={{ ...styles.BlockRight, width: 200, height: menuHeight }}>
                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.stock_transport_new}
                                        </Button>
                                        : null}
                                </div>
                            </div>

                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {stockFromID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.stock_transport_from}: {stockFrom}</p>} onDelete={() => StockFromReset()}></Chip>
                                        : null}
                                    {stockToID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.stock_transport_to}: {stockTo}</p>} onDelete={() => StockToReset()}></Chip> : null}
                                    {userID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{user.name + ' ' + user.surname}</p>} onDelete={() => UserReset()}></Chip>
                                        : null}
                                </div>
                            </div>

                            <div style={{ ...styles.Block, marginTop: 20 }}>

                                {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, marginBottom: 10, border: color.border }}>
                                    {/* DATA */}
                                    <div style={{ ...styles.BlockLeft, width: '15%', height: tableLabelHeight, justifyContent: 'center' }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.number}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '37%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.stock_transport_reason}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: '10%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.count}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: '18%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.status}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '20%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.date}</p>
                                    </div>
                                </div>

                                {/* ****************************************************************************************************************
                                        TABLE DATA
                                **************************************************************************************************************** */}

                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowHeight,
                                            cursor: 'pointer',
                                            marginTop: 5, marginBottom: 5,
                                            paddingTop: 2, paddingBottom: 2,
                                            borderLeft: item.enabled == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow }}>
                                            {/* DATA */}
                                            <div style={{ ...styles.BlockLeft, width: '15%', height: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{item.number}</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '37%', height: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.reason}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 2 }}>{item.stock_from} <span><FontAwesomeIcon style={{ height: 12, color: color.black, marginLeft: 10, marginRight: 10 }} icon={faArrowRight} /></span> {item.stock_to}</p>
                                                <Chip variant='outlined' size='small' style={{ marginTop: 5 }} label={item.user} />
                                            </div>
                                            <div style={{ ...styles.Block, width: '10%', height: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny }}>{item.count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: '18%', height: rowHeight, justifyContent: 'center' }}>
                                                {item.approval_status > -1 && item.status == global.stock_trasports.in_proccess ?
                                                    <Chip label={lang.approval_status[item.approval_status]} size='small' variant='outlined' style={{ color: global.approvals_colors[item.approval_status], borderColor: global.approvals_colors[item.approval_status], fontSize: global.font_tiny }} />
                                                    :
                                                    <Chip label={lang.stock_transport_status[item.status]} size='small' variant='outlined' style={{ color: global.stock_trasport_colors[item.status], borderColor: global.stock_trasport_colors[item.status], fontSize: global.font_tiny }} />
                                                }
                                            </div>
                                            <div style={{ ...styles.Block, width: '20%', height: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny }}>{GetDatumTime2(item.updated)}</p>
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <EmptyList row small lang={lang} />
                                }

                                {/* ****************************************************************************************************************
                                        PAGINATION
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 200 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showUsers == true ?
                <UsersSelect organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.user_select} token={props.token} func={UserResult.bind()} />
                : null}

            {showExport == true ?
                <DialogExport rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <ProductTransport params={params} module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} settings={props.settings} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}



/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const ProductTransport = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = props.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);
    const [overID, setOverID] = useState(0);
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(props.item == false ? 0 : 1);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [loaded, setLoaded] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [stockFromID, setStockFromID] = useState(props.params.stock_from_id == undefined ? -1 : props.params.stock_from_id);
    const [stockToID, setStockToID] = useState(props.params.stock_to_id == undefined ? -1 : props.params.stock_to_id);
    const [reasonID, setReasonID] = useState(-1);
    const [note, setNote] = useState('');
    const [status, setStatus] = useState(0);
    const [approvalStatus, setApprovalStatus] = useState(-1);
    const [approved, setApproved] = useState(props.permissions.stocks_approve == true || props.user.super_admin == true ? true : 0); // Presun schválený 
    const [approvedUserID, setApprovedUserID] = useState(props.permissions.stocks_approve == true || props.user.super_admin == true ? props.user.id : 0); // Kto schválil presuny
    const [approveUser, setApproveUser] = useState(false);

    // UPRAVA MNOZSTVA
    const [quantity, setQuantity] = useState('');
    const [itemSelected, setItemSelected] = useState(false);

    // SKLADY
    const [stocks, setStocks] = useState(false);
    const [stocksFiltered, setStocksFiltered] = useState(false);

    // PRODUKTY
    const [products, setProducts] = useState(false);
    const [excluded, setExcluded] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(50);
    const [product, setProduct] = useState(false);
    const [editEnabled, setEditEnabled] = useState(true);

    const [selectProducts, setSelectProducts] = useState(false);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showTransportAsk, setShowTransportAsk] = useState(false);
    const [showAskApprove, setShowAskApprove] = useState(false);
    const [showApproveYesNo, setShowApproveYesNo] = useState(false);


    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = itemID == false ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 150;
    const column2 = 400;
    const rowHeight = 96;
    const tableHeight = 40;
    const titleHeight = 80;
    const paginationHeight = 80;

    const [tabs, setTabs] = useState([
        { id: 0, enabled: true, label: lang.setting },
        { id: 1, enabled: props.item == false ? false : true, label: lang.products },
        { id: 2, enabled: true, label: lang.history },
    ]);

    let itemsExportMenu = [
        { id: 1, enabled: true, label: lang.order_export_pdf },
    ];

    let { func } = props;
    var running = false;
    const inputRef = useRef(null);

    useEffect(() => {
        if (running == false) {
            if (itemID != 0) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
                db_products(page, false, maxItems, true);
            }

            db_get_data();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_transport', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    AssignData(item);

                    setApprovalStatus(json.item.approval_status);
                    setEditEnabled(item.status == global.stock_trasports.in_proccess && (json.item.approval_status == -1 || (props.user.approve_enabled == true && json.item.approval_status < global.approvals.approved)) ? true : false);
                    setLoaded(true);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_transport_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setStocks(json.stocks);
                setStocksFiltered(json.stocks);

                if (json.stocks != false && permissions.stocks_limited == true && props.user.super_admin == false) {
                    Debug('permissions');
                    Debug(permissions);
                    // LEN SKLADY ORGANIZACIE
                    var tmp = json.stocks.filter(x => x.type == 0)
                    if (tmp.length > 0) {
                        setStocks(tmp);
                    }


                    if (props.user.stock_default > 0) {
                        // LEN SKLAD UZIVATELA - ak je nastavený
                        var tmp = json.stocks.filter(x => x.id == props.user.stock_default)
                        if (tmp.length > 0) {
                            setStocksFiltered(tmp);
                            if (tmp.length == 1) {
                                setStockToID(tmp[0].id);
                            }
                        }
                    }
                }

                if (itemID == 0) {
                    setNumber(json.number);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_transport_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                setEditing(false);
                if (itemID == 0) {
                    setItemID(json.ok);
                    tabs[1].enabled = true;
                    setNumber(json.number);
                    setTabIndex(1);
                }
                if (data.status != undefined) {
                    setStatus(data.status);
                }
                setRedrawRequired(true);

                if (data.enabled == false) {
                    func(true);
                }
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_products = async (page, filter, maxItems, scroll) => {
        Debug(itemID);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_transport_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    transport_id: itemID,
                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug('Products', 'green');
            Debug(json);
            if (json.ok > 0) {

                var tmp = json.items;
                setProducts(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setExcluded(json.items_id);

                if (scroll == true) {
                    GoToStart();
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_product_add = async (products_) => {
        Debug(itemID);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_transport_product_add', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    transport_id: itemID,
                    stock_form_id: stockFromID,
                    products: products_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_products(page, false, maxItems, false);
                setRedrawRequired(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_product_delete = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_transport_product_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    transport_id: itemID,
                    stock_from_id: stockFromID,
                    id: id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_products(page, false, maxItems, false);
                setRedrawRequired(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_product_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_transport_product_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    stock_from_id: stockFromID,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_products(page, false, maxItems, false);
                setRedrawRequired(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_exec = async () => {

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: itemID,
            action: global.logs.products_transfered
        };

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_transport_exec', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    transport_id: itemID,
                    log: log
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setStatus(global.stock_trasports.finished);
                setEditEnabled(false);
                setRedrawRequired(true);
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const ChangePage = (value) => {
        db_products(value - 1, false, maxItems, true);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setApprovedUserID(item.approved_user_id);
        setNumber(item.number);
        setName(item.name);
        setStatus(item.status);
        setReasonID(item.reason_id);
        setStockFromID(item.stock_from_id);
        setStockToID(item.stock_to_id);
        setNote(item.note);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.number = number;
        storeData.name = name;
        storeData.status = status;
        storeData.reason_id = reasonID;
        storeData.stock_from_id = stockFromID;
        storeData.note = note;
    }

    const Save = () => {
        setError('');
        var error = false;
        var errorText = lang.required_red;

        error += IsEmpty(number) == true ? 1 : 0;
        if (stockFromID == -1) {
            error++;
            errorText = lang.stock_transport_from_error;
        }
        if (stockToID == -1) {
            error++;
            errorText = lang.stock_transport_to_error;
        }
        if (stockToID > -1 && stockToID > -1 && stockFromID == stockToID) {
            error++;
            errorText = lang.stock_transport_from_to_error;
        }


        if (reasonID == -1) {
            error++;
        }

        if (error == 0) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: itemID,
                action: itemID == 0 ? global.logs.created : global.logs.modified
            };

            let data = {
                id: itemID,
                organization_id: props.organization.id,
                user_id: props.user.id,
                approve_id: 0,
                number: number.trim(),
                stock_from_id: stockFromID,
                stock_to_id: stockToID,
                reason_id: reasonID,
                note: note,
                logs: log
            };

            StoreEditing();
            Debug(data);
            db_update(data);

        } else {
            setError(errorText);
        }

    }

    const db_export_pdf = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_transport_export_pdf', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------                    
                    transport_id: itemID
                })
            })

            const json = await response.json();
            Debug('Export PDF');
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setRedrawRequired(true);
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const ShowOKPress = () => {
        setShowOK(false);
    }

    const MenuResult = (id, index, item) => {
        switch (index) {
            case 1:
                db_export_pdf();
                break;

            case 2:
                break;
        }
    }

    const ChangeTab = (id) => {
        setTabIndex(id);
        if (id == 1) {
            db_products(0, false, maxItems, true);
        }
        if (id == 2) {
            db_get_logs();
        }
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {

        }
    }

    const ChangeReason = (id) => {
        Debug(id);
        if (id > 0) {
            setReasonID(id);
        } else {

        }
    }

    const ProductResult = (typ, ids, items) => {
        setSelectProducts(false);

        if (typ == true && ids != false) {
            var tmp = [];
            items.forEach(item => {
                tmp.push({ uid: item.stock_uid, product_id: item.id });
            });
            db_product_add(tmp);
        }
    }

    const DeleteProduct = (item) => {
        db_product_delete(item.id);
    }

    const ChangeQuantity = (value) => {
        Debug(value);
        setQuantity(value);
    }

    const EditQuantity = (item) => {
        setQuantity(parseInt(item.quantity));
        setItemSelected(item);
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);
    }

    const SaveQuantity = () => {
        let data = {
            id: itemSelected.id,
            quantity: quantity
        }

        db_product_update(data);

        setItemSelected(false);
    }

    const ChangeEditing = () => {
        if (status == 0 || status == 1) {
            setEditing(true);
        }
    }

    const productDetails = (item) => {
    }

    const runTransport = (typ) => {
        setShowTransportAsk(false);

        if (typ == true) {
            db_exec();
        }
    }

    const ApproveResult = (typ, value) => {
        setShowAskApprove(false);

        if (typ == true) {
            setRedrawRequired(true);
            setApprovalStatus(value);
            if (value == global.approvals.waiting_approve) {
                setEditEnabled(false);
            }
        }

    }

    const ApproveYesNoResult = (value) => {
        setShowApproveYesNo(false);

        if (value == true) {
            let data = {
                id: itemID,
                approved_user_id: approveUser.id,
                send_approve: true,
                status: global.stock_trasports.waiting_approve,
            }

            Debug(data);
            db_update(data);
        }
    }

    const DeleteTransport = () => {
        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: itemID,
            action: global.logs.canceled
        };

        let data = {
            id: itemID,
            status: global.stock_trasports.canceled,
            enabled: false,
            logs: log
        }

        db_update(data);
    }

    const SelectProducts = () => {
        setSelectProducts(true);
    }

    const QuickResult = (item) => {
        ProductResult(true, [item.id], [item]);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{itemID == 0 ? lang.stock_transport_new : lang.stock_transport}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 50, height: global.widow_bar_height }}>
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.Block, height: tabHeight }}>
                        {itemID > 0 && editing == false ?
                            <DialogTabs center value={tabIndex} items={tabs} width={dialogWidth / 2} theme={props.theme} func={(id) => ChangeTab(id)} />
                            : null}
                    </div>


                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block }}>
                                {storeData != false ?
                                    <div>
                                        <FormSpace />
                                        <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true && editEnabled == true ? true : false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => ChangeEditing(true)} />
                                        <FormText require redraw value={number} editing={editing} title={lang.number} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNumber(txt)} />
                                        <div style={{ ...styles.Block }}>
                                            <FormSpace />
                                            <FormSelect require value={stockFromID} items={stocks} field={'name'} editing={products.length > 0 ? false : editing} title={lang.stock_transport_from} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setStockFromID(id)} />
                                            <FormSelect require value={stockToID} items={stocksFiltered} field={'name'} editing={products.length > 0 ? false : editing} title={lang.stock_transport_to} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setStockToID(id)} />
                                            <FormSpace />
                                        </div>
                                        <FormSelect require value={reasonID} items={false} field={'name'} codebook={global.codebook.reasons_transport} editing={editing} title={lang.stock_transport_reason} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => ChangeReason(id)} />
                                        <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                                    </div>
                                    :
                                    <Stack style={{ ...styles.Block }} spacing={1}>
                                        <FormSpace />
                                        <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    </Stack>}
                            </div>

                        </div>
                        : null}

                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight }} >
                            <div style={{ ...styles.Block, width: '96%' }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '30%', height: titleHeight }}>
                                        <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.stock_transport_products}</p>
                                    </div>

                                    <div style={{ ...styles.Block, width: '40%', height: titleHeight }}>

                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{approvalStatus == -1 ? lang.stock_transport_status[status] : status == global.stock_trasports.in_proccess ? lang.approval_status[approvalStatus] : lang.stock_transport_status[status]}</p>
                                    </div>

                                    <div style={{ ...styles.BlockRight, width: '30%', height: titleHeight }}>
                                        {editing == false && editEnabled == true ?
                                            CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true && (status == global.stock_trasports.in_proccess || status == global.stock_trasports.waiting_approve && (props.user.id == approvedUserID || props.user.super_admin == true)) ?
                                                <Button onClick={() => SelectProducts()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, color: color.black, width: 180 }}>
                                                    <FontAwesomeIcon style={{ height: 14, marginRight: 10 }} icon={faPlus} />
                                                    {lang.stock_adjustment_products_add}
                                                </Button>
                                                : null : null}
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockRow, border: color.border, height: tableHeight }}>
                                    <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableHeight, justifyContent: 'center' }}>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: status == global.stock_trasports.in_proccess && editEnabled == true ? '55%' : '70%', height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product}</p>
                                    </div>
                                    {status == global.stock_trasports.in_proccess && editEnabled == true ?
                                        <div style={{ ...styles.BlockLeft, width: '15%', height: tableHeight, borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product_quantity_stock_}</p>
                                        </div>
                                        : null}
                                    <div style={{ ...styles.BlockLeft, width: '20%', height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.stock_transport_quantity_}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: 70, height: tableHeight, borderLeft: color.border }}>

                                    </div>
                                </div>

                                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - titleHeight - tableHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <div id={'id-start'} style={{ ...styles.BlockCenter, paddingBottom: global.list_padding }}>

                                        {products != false ? products.map((item, index) => (

                                            <Paper
                                                key={item.id}
                                                id={'id-product-' + item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: item.enabled == false ? color.disabled : item.grouped == true ? color.grouped : item.variations == true ? color.variation : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : item.variations == true ? color.border_variation : item.grouped == true ? color.border_grouped : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                    <div onClick={() => productDetails(item)} style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <Avatar picture image={item.image} size={rowHeight - 10} theme={props.theme} />
                                                    </div>
                                                    <div onClick={() => productDetails(item)} style={{ ...styles.BlockLeft, width: status == global.stock_trasports.in_proccess && editEnabled == true ? '55%' : '70%', minHeight: rowHeight }}>
                                                        {item.parent_name != '' ?
                                                            <p style={{ ...styles.TextXSmall, fontWeight: '600', marginBottom: 2 }}>{item.parent_name}</p>
                                                            : null}
                                                        <p style={{ ...item.parent_name != '' ? styles.TextTiny : styles.TextXSmall, fontWeight: item.parent_name != '' ? '400' : '600' }}>{item.name}</p>
                                                        <p style={{ ...styles.TextTiny }}>{item.code}</p>
                                                    </div>
                                                    {status == global.stock_trasports.in_proccess && editEnabled == true ?
                                                        <div style={{ ...styles.Block, width: '15%', minHeight: rowHeight }}>
                                                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{FormatPieces(parseInt(item.stock_quantity) - parseInt(item.stock_reserved))}</p>
                                                            {parseInt(item.stock_reserved) > 0 ?
                                                                <div style={{ ...styles.Block }}>
                                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.product_quantity_stock_}: {FormatPieces(item.stock_quantity)}</p>
                                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.product_quantity_reserved_}: {FormatPieces(item.stock_reserved)}</p>
                                                                </div>
                                                                : null}
                                                            <StockCoveredChip stock_quantity={item.stock_quantity} stock_reserved={item.stock_reserved} quantity={item.quantity} lang={props.lang} />
                                                        </div>
                                                        : null}
                                                    <div style={{ ...styles.BlockRight, width: '20%', minHeight: rowHeight }}>
                                                        {itemSelected != false && itemSelected.id == item.id ?
                                                            <div style={{ ...styles.BlockRowRaw }}>
                                                                <div style={{ ...styles.BlockLeft, width: 100, minHeight: rowHeight }}>
                                                                    <TextField
                                                                        inputRef={inputRef}
                                                                        value={itemSelected == false ? FormatPieces(quantity) : quantity}
                                                                        onInput={(e) => ChangeQuantity(e.target.value)}
                                                                        size="small"
                                                                        autoCapitalize="off"
                                                                        autoComplete="off"
                                                                        autoCorrect="off"
                                                                        style={{ width: '100%' }}
                                                                        onKeyDown={(event) => {
                                                                            if (event.key === 'Enter') {
                                                                                SaveQuantity(false);
                                                                            }
                                                                            if (event.key === 'Escape') {
                                                                                setItemSelected(false);
                                                                            }
                                                                        }}
                                                                        InputProps={{
                                                                            style: { backgroundColor: global.theme_white },
                                                                        }}
                                                                        onFocus={event => {
                                                                            event.target.select();
                                                                        }}
                                                                        variant="outlined"
                                                                    />
                                                                </div>
                                                                <div style={{ ...styles.Block, width: 40, minHeight: rowHeight }}>
                                                                    <IconButton onClick={() => SaveQuantity(false)} style={{ ...styles.ButtonIcon }}>
                                                                        <FontAwesomeIcon style={{ width: 14, color: color.dark_green }} icon={faCheck} />
                                                                    </IconButton>
                                                                </div>
                                                                <div style={{ ...styles.Block, width: 40, minHeight: rowHeight }}>
                                                                    <IconButton onClick={() => setItemSelected(false)} style={{ ...styles.ButtonIcon }}>
                                                                        <FontAwesomeIcon style={{ width: 14, color: color.dark_red }} icon={faXmark} />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div style={{ ...styles.BlockRowRaw, height: rowHeight, alignItems: 'center' }}>
                                                                <p style={{ ...styles.TextTiny }}>{FormatPieces(item.quantity)} {item.unit}</p>
                                                                {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true && editEnabled == true ?
                                                                    <IconButton onClick={() => EditQuantity(item)} style={{ ...styles.ButtonIcon, marginLeft: 20 }}>
                                                                        <FontAwesomeIcon style={{ width: 10 }} icon={faPen} />
                                                                    </IconButton>
                                                                    : null}
                                                            </div>
                                                        }

                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: 70, minHeight: rowHeight }}>
                                                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true && editEnabled == true ?
                                                            <DeleteButton ask theme={props.theme} lang={props.lang} func={() => DeleteProduct(item)} />
                                                            : null}
                                                    </div>
                                                </div>
                                            </Paper>

                                        )) :
                                            <div style={{ ...styles.Block, paddingTop: 50 }}>
                                                <EmptyList row small lang={lang} />
                                            </div>
                                        }

                                        {editEnabled == true ?
                                            <ProductSearch excluded={excluded} marginTop={25} theme={props.theme} lang={lang} radius={props.radius} locale={props.locale} organization={props.organization} func={(item) => QuickResult(item)} />
                                            : null}

                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                        : null}

                    {tabIndex == 2 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        {enabled == true ?
                            <div style={{ ...styles.BlockRowRaw }}>
                                {tabIndex == 0 && editing == true ?
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                        <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                    </div>
                                    : null}
                                {tabIndex == 1 ?
                                    <div style={{ ...styles.Block }}>

                                        {products != false ?
                                            <div style={{ ...styles.BlockRowRaw }}>

                                                {(approved == true && status == global.stock_trasports.in_proccess && approvalStatus == -1) || status == global.stock_trasports.in_proccess && approvalStatus == global.approvals.approved ?
                                                    // SCHALENA - PRESUNUT
                                                    CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                        <Button onClick={() => setShowTransportAsk(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 200, marginRight: 20 }}>{lang.stock_transport_run}</Button>
                                                        : null
                                                    : null}

                                                {(approved == false && approvalStatus == -1 && props.permissions.stocks_approve == false) ?
                                                    // POZIADAT O SCHVALENIE
                                                    <Button onClick={() => setShowAskApprove(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 200, marginRight: 20 }}>{lang.stock_transport_ask_approve}</Button>
                                                    : null
                                                }

                                                {editing == false ?
                                                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 200 }}>{lang.close}</Button>
                                                    : null}

                                            </div>
                                            :
                                            // ZRUSIT PRESUN
                                            loaded == true ?
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <DeleteButton ask button bold label={lang.stock_transport_cancel} icon={faTrash} position={'top'} backgroundColor={color.button_delete} text={lang.stock_transport_cancel_ask} tooltip={lang.delete} theme={props.theme} lang={props.lang} width={200} ok={lang.yes} cancel={lang.no} func={() => DeleteTransport()} />
                                                </div>
                                                : null
                                        }

                                    </div>
                                    : null}
                            </div>
                            : null}
                    </div>
                </div >
            </Paper >

            {showApproveYesNo == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.stock_transport_approve_choose_label} text={lang.stock_transport_approve_choose_text} sub_text={approveUser.name + ' ' + approveUser.surname} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={ApproveYesNoResult.bind(this)} />
                : null}

            {showAskApprove == true ?
                <ApprovalAsk module_id={props.module_id} item_id={itemID} module_name={lang.stock_transport} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.stock_transport_approve_choose} token={props.token} func={ApproveResult.bind()} />
                : null}

            {showTransportAsk == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.stock_transport_run} text={lang.stock_transport_ask_text} sub_text={lang.stock_transport_ask_sub_text} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={runTransport.bind(this)} />
                : null}

            {selectProducts == true ?
                <ProductSelect excluded={excluded} only_enabled country_id={props.locale.id} stock={stockFromID} only_sku={true} showVariations={true} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductResult.bind()} />
                : null}

            {selectPhoto == true ?
                <Photos autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop>
    );
}
