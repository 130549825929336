/*
*
* =================================================================================================================
* DODAVATELIA

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormNote, FormSelect, DeleteButton, FormNodes, UpDown, ButtonNew, ButtonChangeStyle } from './items';
import { styles } from './styles';
import { Backdrop, Box, Button, Checkbox, Chip, IconButton, ListItemIcon, ListItemText, MenuItem, Pagination, Paper, Skeleton, Stack, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowPointer, faArrowUp, faBars, faCheck, faCheckCircle, faDollar, faFolderTree, faInfoCircle, faLayerGroup, faList, faPlus, faPlusCircle, faQuestion, faTableCells, faTrash, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, CoeficientToPercentage, ConvertToPrice, ConvertToPrice2, Debug, FormatMoney, FormatMoneyDPH, GetLeftPosition, GetPages, IsEmpty, LoadArray, LoadBool, LoadValue, SaveArray, SaveBool, SaveValue } from './functions';
import { Photos } from './photos';
import { Today } from './functions_date';
import { ProductCategorySelect } from './product_categories';
import { ManufacturerSelect } from './manufacturers';
import { VendorSelect } from './vendors';
import { ProductsGroupSelect } from './product_groups';
import { ProductSelect } from './product_select';
import { Conditions } from './conditions.js';
import { ConditionDesigner } from './condition_designer.js';
import {
    DataGrid, skSK,
    GridColumnMenu,
} from '@mui/x-data-grid';

export const PriceLists = (props) => {

    let module_id = global.model.price_list;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'price_lists';

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        country_id: props.locale.id
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.price_list_name, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.price_list_name, default: true },
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 86;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const menuWidth = global.sub_menu_width;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);

    const db_get = async (page, filter, maxItems) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_lists', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart(itemSelected == false ? 0 : itemSelected.id);
                setItemSelected(false);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.price_list,
                    filename: lang.price_list, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = {
            ordering: ordering,
            order_column: orderColumn,
            country_id: props.locale.id
        };

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = (id) => {
        setTimeout(() => {
            var element = document.getElementById('id_start');

            if (id > 0) {
                element = document.getElementById('price-list-' + id);
            }

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw, id) => {
        setShowDialog(false);

        if (redraw == true) {
            var filter_ = filter;
            /*
            if (id > 0) {
                filter_ = { id: id };
            }
                */
            db_get(page, filter_, maxItems);

        } else {
            setItemSelected(false);
        }
    }

    const ChangeEnabled = (item, value) => {
        // ENABLED POLOZKY ZMENENY

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: item.id,
            action: value == true ? global.logs.enabled : global.logs.disabled
        };

        let data = {
            id: item.id,
            enabled: value,
            logs: log
        }
        db_update(data);
    }

    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // NAZOV
                column = 'name';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;
        }

        var filter = {
            ordering: order,
            order_column: column
        };
        if (search != '') {
            filter.search = search;
        }
        db_get(0, filter, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const ResetNewRecord = () => {
        db_get(0, defaultFilter, maxItems);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    {global.appstore == false ?
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                        : null}
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div id={'id_start'} style={{ ...styles.Block, maxWidth: global.max_width, width: '100%', marginTop: 10 }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            {filter.id == undefined ?
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <TextInput search theme={props.theme} width={300} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                            <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                                <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                {lang.price_list_new}
                                            </Button>
                                            : null}
                                    </div>
                                </div>
                                :
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                        {filter.id > 0 ?
                                            <Chip size='medium' style={{ backgroundColor: color.dark_red }} label={<p style={{ ...styles.TextTiny, color: color.white }}>{lang.new_record}</p>} onDelete={() => ResetNewRecord(-1)}></Chip>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                {lang.price_list_new}
                                            </Button>
                                            : null}
                                    </div>
                                </div>
                            }

                            <div style={{ ...styles.Block, marginTop: 10 }}>

                                {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.Block, marginBottom: 10, border: color.border }}>
                                    <div style={{ ...styles.BlockRow, width: '99%' }}>
                                        <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center' }}>
                                            {/* SELECT */}
                                            <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: '90%' }}>
                                            {/* DATA */}
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '65%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.price_list_name}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                </div>
                                            </div>
                                            <div style={{ ...styles.Block, width: 80, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.currency_}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: '27%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.price_list_discount_add}</p>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: 100, borderLeft: color.border }}>
                                            {/* ENABLED */}
                                            <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.enabled}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - 125, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    {/* ****************************************************************************************************************
                                        ZOZNAM CENNIKOV
                                    **************************************************************************************************************** */}

                                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            id={'price-list-' + item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block,
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                    {/* SELECT */}
                                                    <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                                </div>

                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '85%' }}>
                                                    {/* DATA */}
                                                    <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <Avatar picture image={item.avatar} size={rowHeight - 20} theme={props.theme} />
                                                    </div>

                                                    <div style={{ ...styles.BlockLeft, width: '65%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name}</p>
                                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{item.note}</p>
                                                    </div>

                                                    <div style={{ ...styles.Block, minHeight: rowHeight, width: 80 }}>
                                                        <Chip
                                                            style={{ marginTop: 2, marginBottom: 2 }}
                                                            sx={{
                                                                borderColor: color.dark_gray,
                                                                paddingTop: '2px',
                                                                paddingBottom: '2px',
                                                                height: 'auto',
                                                                '& .MuiChip-label': {
                                                                    display: 'block',
                                                                    whiteSpace: 'normal',
                                                                },
                                                            }}
                                                            label={<p style={{ ...styles.TextTiny, textAlign: 'center', color: color.dark_gray }}>{item.currency}</p>} variant="outlined"
                                                        />
                                                    </div>
                                                    <div style={{ ...styles.Block, width: '27%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        {item.all_products == true ?
                                                            <p style={{ ...styles.TextTiny }}>{CoeficientToPercentage(item.coeficient)}%</p>
                                                            :
                                                            <p style={{ ...styles.TextTiny }}>{lang.price_list_groups}</p>
                                                        }
                                                        {item.condition_id > 0 ?
                                                            <Chip
                                                                style={{ marginTop: 2, marginBottom: 2 }}
                                                                sx={{
                                                                    borderColor: color.dark_red,
                                                                    paddingTop: '2px',
                                                                    paddingBottom: '2px',
                                                                    height: 'auto',
                                                                    '& .MuiChip-label': {
                                                                        display: 'block',
                                                                        whiteSpace: 'normal',
                                                                    },
                                                                }}
                                                                label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_red }}>{lang.cond}</p>} variant="outlined"
                                                            />
                                                            : null}
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.Block, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {/* ENABLED */}
                                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                        <SwitchEnable enabled={item.enabled == false ? false : true} theme={props.theme} func={(value) => ChangeEnabled(item, value)} />
                                                        : null}
                                                    {item.enabled == false ?
                                                        <div style={{ ...styles.Block, height: 20 }}>
                                                            <p style={{ ...styles.TextXXTiny, color: color.dark_gray }}>{lang.disabled}</p>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }

                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                    **************************************************************************************************************** */}

                                    <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showExport == true ?
                <DialogExport rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <PriceList module_id={module_id} item={itemSelected} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}



/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const PriceList = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let currency = props.locale.currency;
    let show_cost = props.system.show_cost_prices == false ? false : props.user.super_admin == true ? true : props.permissions.show_cost == false ? false : true;

    const sourceItems = [{ id: 0, label: lang.price_list_source_1 }, { id: 1, label: lang.price_list_source_2 }];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [requireRedraw, setRequireRedraw] = useState(false);
    const [redraw, setRedraw] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(props.item == false ? 0 : 2);

    // DATA
    const [storeData, setStoreData] = useState(false);

    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');
    const [allProducts, setAllProducts] = useState(true);
    const [source, setSource] = useState(1);
    const [coeficient, setCoeficient] = useState('1');
    const [dateStart, setDateStart] = useState(Today());
    const [dateEnd, setDateEnd] = useState(Today());
    const [dateEndless, setDateEndless] = useState(true);
    const [note, setNote] = useState('');
    const [conditionID, setConditionID] = useState(0);
    const [conditionName, setConditionName] = useState('');
    const [countries, setCountries] = useState(props.organization.currencies);
    const [countryID, setCountryID] = useState(props.locale.id);
    const [currencyID, setCurrencyID] = useState(props.locale.id);
    const [currencyMoney, setCurrencyMoney] = useState(currency);
    const [currencyCoeficient, setCurrencyCoeficient] = useState(1);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // PODMIENKY
    const [showCondition, setShowCondition] = useState(false);
    const [showConditions, setShowConditions] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = props.item == false ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = 400;

    const [tabs, setTabs] = useState([
        { id: 0, enabled: true, label: lang.setting },
        { id: 1, enabled: true, label: lang.price_list_groups },
        { id: 2, enabled: true, label: lang.products },
        { id: 3, enabled: true, label: lang.history },
    ]);

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            } else {
                setStoreData({});
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    if (item.all_products == true) {
                        tabs[1].enabled = false;
                    }

                    AssignData(item);

                    let tmp = props.system.currencies.find(x => x.id == item.currency_id);
                    if (tmp != undefined) {
                        setCurrencyMoney(tmp.currency);
                    }
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (props.item == false) {
                    func(true, json.item_id);
                } else {
                    if (data.all_products == true) {
                        tabs[1].enabled = false;
                    } else {
                        tabs[1].enabled = true;
                    }
                    setRequireRedraw(true);
                    setShowOK(true);
                }
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(requireRedraw, 0);
    }


    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setCountryID(item.country_id);
        setName(item.name);
        setAllProducts(item.all_products);
        setSource(item.source);
        setCurrencyID(item.currency_id);
        setCurrencyCoeficient(item.currency_coeficient);
        setCoeficient(item.coeficient);
        setDateStart(item.date_start == null ? Today() : item.date_start);
        setDateEnd(item.date_end == global.date_endless ? Today() : item.date_end);
        setNote(item.note);
        setConditionID(item.condition_id);
        setConditionName(item.condition_name);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.enabled = enabled;
        storeData.country_id = countryID;
        storeData.name = name;
        storeData.allProducts = allProducts;
        storeData.source = source;
        storeData.currency_id = currencyID;
        storeData.currency_coeficient = currencyCoeficient;
        storeData.coeficient = coeficient;
        storeData.date_start = dateStart;
        storeData.date_end = dateEndless == true ? global.date_endless : dateEnd;
        storeData.note = note;
        storeData.condition_id = conditionID;
    }

    const Save = () => {
        setError('');
        var error = false;
        error += IsEmpty(name) == true ? 1 : 0;
        if (allProducts == true) {
            error += IsEmpty(coeficient) == true ? 1 : 0;
        }

        if (error == 0) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: props.item == false ? 0 : props.item.id,
                action: props.item == false ? global.logs.created : storeData.enabled != enabled ? enabled == true ? global.logs.enabled : global.logs.disabled : global.logs.modified
            };

            let data = {
                id: props.item == false ? 0 : props.item.id,
                organization_id: props.organization.id,
                country_id: countryID,
                enabled: enabled,
                name: name.trim(),
                all_products: allProducts,
                source: source,
                currency_id: currencyID,
                currency_coeficient: currencyID != props.locale.id ? currencyCoeficient : 1,
                coeficient: coeficient,
                date_start: dateStart,
                date_end: dateEndless == true ? global.date_endless : dateEnd,
                note: note,
                condition_id: allProducts == true ? conditionID : 0,
                logs: log
            };

            StoreEditing();

            Debug(data);
            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ShowOKPress = () => {
        setEditing(false);
        setShowOK(false);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);
        if (editing == true) {
            CancelEditing(false);
        }

        if (id == 2) {

        }
        if (id == 3) {
            db_get_logs();
        }
    }

    const ProductsResult = () => {

    }

    const Condition = () => {
        setShowCondition(true);
    }

    const ConditionResult = (typ, id, item) => {
        setShowCondition(false);
        if (typ == true) {
            setConditionName(item.name);
            setConditionID(id);
        }
    }

    const ConditionsResult = (typ, item) => {
        setShowConditions(false);
        if (typ == true) {
            setConditionName(item.name);
            setConditionID(item.id);
        }
        if (typ == false && item == true) {
            // REDRAW
            db_get(true);
        }
    }

    const ChangeCurrencyID = (id) => {
        let tmp = props.system.currencies.find(x => x.id == id);
        if (tmp != undefined) {
            setCurrencyMoney(tmp.currency);
        }
        setCurrencyID(id);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 800 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.price_list_new : props.item.name}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {props.item != false ?
                        <div style={{ ...styles.Block, height: tabHeight }}>
                            {storeData != false ?
                                <DialogTabs center value={tabIndex} items={tabs} width={dialogWidth / 4} theme={props.theme} func={(id) => ChangeTab(id)} />
                                : null}
                        </div>
                        : null}
                    <div style={{ ...styles.BlockCenter, height: tabIndex == 0 ? dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight : dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {tabIndex == 0 ?
                            <div style={{ ...styles.BlockCenter }}>
                                {storeData != false ?
                                    <div style={{ ...styles.Block, marginTop: 20, maxWidth: column1 + column2 + 20, backgroundColor: color.white, paddingBottom: global.list_padding, borderRadius: global.radius }}>
                                        < FormSpace />
                                        <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                        <FormYesNo value={enabled} editing={editing} title={lang.price_list_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                        {/*
                                        <FormSelect require value={countryID} items={countries} field={'state'} editing={props.item == false ? editing : false} title={lang.app_country} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCountryID(id)} />
                                        */}
                                        {props.system.pricelist_multi_language == true ?
                                            <>
                                                <FormSelect help_id={12} require value={currencyID} items={props.system.currencies} field={'currency'} editing={editing} title={lang.currency} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeCurrencyID(id)} />
                                                {currencyID != props.locale.id ?
                                                    <div>
                                                        <FormText require numeric redraw value={currencyCoeficient} editing={editing} title={lang.price_list_currency_coeficient} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCurrencyCoeficient(txt)} />
                                                        <FormNote title={lang.price_list_currency_coeficient_note + ': 1,00 ' + currency + ' = ' + ConvertToPrice(currencyCoeficient, 2) + ' ' + currencyMoney} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                    </div>
                                                    : null}
                                            </>
                                            : null}
                                        <FormSpace />
                                        <FormText require redraw value={name} editing={editing} title={lang.price_list_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                        <FormYesNo value={allProducts} editing={editing} title={lang.price_list_typ} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} yes={lang.price_list_typ_all} no={lang.price_list_typ_select} func={(value) => setAllProducts(value)} />
                                        <FormNote title={lang.price_list_all_products_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                        <FormSelect value={source} items={sourceItems} field={'label'} editing={editing} title={lang.price_list_source} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setSource(id)} />
                                        <FormNote title={lang.price_list_source_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                        {allProducts == true ?
                                            <div>
                                                <FormText require percentage redraw value={coeficient} editing={editing} title={lang.price_list_coeficient} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCoeficient(txt)} />
                                                <FormNote title={lang.system_price_coeficient_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                            </div>
                                            : null}

                                        < FormSpace />
                                        {/*
                                        <FormLabel editing={false} title={lang.price_list_life} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                        <FormYesNo value={dateEndless} editing={editing} title={lang.price_list_ranged} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setDateEndless(value)} />
                                        <FormText redraw date value={dateStart} editing={editing} title={lang.price_list_date_from} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDateStart(txt)} />
                                        <FormNote title={lang.date_include} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                        {dateEndless == false ?
                                            <div>
                                                <FormText redraw date value={dateEnd} editing={editing} title={lang.price_list_date_to} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDateEnd(txt)} />
                                                <FormNote title={lang.date_include} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                            </div>
                                            : null}
                                        <FormSpace />
                                        */}

                                        <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />

                                        {props.item != false ?
                                            <FormText require redraw value={props.item.id} editing={false} title={'ID'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => Debug(txt)} />
                                            : null}

                                        <FormSpace />

                                        {props.system.conditions_enabled == true ? allProducts == true ? editing == true ?
                                            <div style={{ ...styles.Block, width: '98%', marginTop: 30, backgroundColor: color.white, borderRadius: global.radius }}>
                                                <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        {conditionID > 0 ?
                                                            <div style={{ ...styles.BlockLeft, width: undefined, height: 40, marginRight: 10 }}>
                                                                <FontAwesomeIcon style={{ height: 16, color: color.darker_green }} icon={faCheckCircle} />
                                                            </div>
                                                            : null}
                                                        <div style={{ ...styles.BlockLeft, width: undefined, height: 40 }}>
                                                            <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{conditionID == 0 ? lang.cond : lang.cond_active}</p>
                                                        </div>
                                                        {conditionID > 0 ?
                                                            <div style={{ ...styles.BlockLeft, width: undefined, height: 40, marginLeft: 10 }}>
                                                                <DeleteButton ask text={lang.cond_delete_ask} theme={props.theme} lang={props.lang} func={() => setConditionID(0)} />
                                                            </div>
                                                            : null}
                                                    </div>

                                                    {conditionID == 0 ?
                                                        <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.cond_note}</p>
                                                        : null}
                                                    <div style={{ ...styles.BlockRow }}>
                                                        <div style={{ ...styles.BlockLeft, width: '50%' }}>

                                                        </div>
                                                        <div style={{ ...styles.BlockRight, width: '50%' }}>

                                                        </div>
                                                    </div>

                                                    {conditionID > 0 && conditionName != '' ?
                                                        <div style={{ ...styles.BlockLeft }}>
                                                            <p style={{ ...styles.TextXSmall, textAlign: 'center', marginLeft: 26 }}>{lang.cond_name}: <b>{conditionName}</b></p>
                                                        </div>
                                                        : null}

                                                    <FormSpace />
                                                    <div style={{ ...styles.Block }}>
                                                        <div style={{ ...styles.BlockRowRaw }}>
                                                            <div style={{ ...styles.Block, width: undefined, height: 40 }}>
                                                                <Button onClick={() => Condition()} style={{ ...styles.ButtonThemed, color: color.black, width: 180, backgroundColor: color.button_action }}>{conditionID == 0 ? lang.cond_new : lang.cond_update}</Button>
                                                            </div>
                                                            <div style={{ ...styles.Block, width: undefined, height: 40, marginLeft: 10 }}>
                                                                <IconButton onClick={() => setShowConditions(true)} style={{ ...styles.ButtonIcon, width: 36, height: 36, borderRadius: 18 }}>
                                                                    <FontAwesomeIcon style={{ height: 16 }} icon={faList} />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormSpace />
                                                </div>
                                            </div>
                                            :
                                            <div style={{ ...styles.Block, width: '98%', marginTop: 30, backgroundColor: color.white, borderRadius: global.radius }}>
                                                <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                                    <FormSpace />
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        {conditionID > 0 ?
                                                            <div style={{ ...styles.BlockLeft, width: undefined, height: 40, marginRight: 10 }}>
                                                                <FontAwesomeIcon style={{ height: 16, color: color.darker_green }} icon={faCheckCircle} />
                                                            </div>
                                                            : null}
                                                        <div style={{ ...styles.BlockLeft, width: undefined, height: 40 }}>
                                                            <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{conditionID == 0 ? lang.cond : lang.cond_active}</p>
                                                            {conditionID > 0 && conditionName != '' ?
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <p style={{ ...styles.TextXSmall, textAlign: 'center' }}>{lang.cond_name}: <b>{conditionName}</b></p>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <FormSpace />
                                                </div>
                                            </div>
                                            : null
                                            : null
                                        }

                                    </div>
                                    :
                                    <Stack style={{ ...styles.Block }} spacing={1}>
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    </Stack>}
                            </div>
                            : null}

                        {tabIndex == 1 ?
                            // CENOVE SKUPINY
                            <div style={{ ...styles.Block, width: '98%', marginTop: 10 }}>
                                <PriceListGroups country_id={countryID} priceListID={props.item.id} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductsResult.bind()} />
                            </div>
                            : null}

                        {tabIndex == 2 ?
                            // ZOZNAM PRODUKTOV
                            <div style={{ ...styles.Block, width: '98%' }}>
                                <PriceListProducts country_id={countryID} allProducts={allProducts} priceListID={props.item.id} height={dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductsResult.bind()} />
                            </div>
                            : null}

                        {tabIndex == 3 ?
                            // LOGS
                            <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                            </div>
                            : null}

                    </div>

                    {tabIndex == 0 ?
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {tabIndex == 0 && editing == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            {editing == true && props.item != false ?
                                <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                :
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                            }
                        </div>
                    </div>

                </div>

                {showCondition == true ?
                    <ConditionDesigner item_id={conditionID} module_id={global.model.price_list} organization={props.organization} locale={props.locale} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ConditionResult.bind(this)} />
                    : null}

                {showConditions == true ?
                    <Conditions item_id={conditionID} module_id={global.model.price_list} organization={props.organization} locale={props.locale} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ConditionsResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}



/*
*
* =================================================================================================================
* PRODUKTY V CENNIKU
* =================================================================================================================
*
*/
export const PriceListProducts = (props) => {

    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let currencySource = props.locale.currency;
    let show_cost = props.system.show_cost_prices == false ? false : props.user.super_admin == true ? true : props.permissions.show_cost == false ? false : true;
    let show_prices_dph = props.system.show_prices_dph == false ? false : true;

    const sourceItems = [{ id: 0, label: lang.price_list_source_1 }, { id: 1, label: lang.price_list_source_2 }];
    const [currency, setCurrency] = useState('');
    const [currencyOriginal, setCurrencyOriginal] = useState(props.locale.currency);

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // TABLE
    var columns = [
        {
            field: 'enabled',
            headerName: lang.enabled_,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'boolean',
            align: 'center',
            width: 60,
            renderCell: (params) => (params.value == false ? <FontAwesomeIcon style={{ width: 14, height: 14, color: color.dark_red }} icon={faXmarkCircle} /> : <FontAwesomeIcon style={{ width: 14, height: 14, color: color.black }} icon={faCheck} />),
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
        {
            field: 'code',
            headerName: lang.product_code_,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 120,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: false,
            hide: false,
        },
        {
            field: 'name',
            headerName: lang.product_name,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            renderCell: (params) => (params.row.parent_id == 0 ? params.row.name : params.row.parent_name + ' - ' + params.row.name),
            type: 'string',
            align: 'left',
            width: 300,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'category',
            headerName: lang.product_category,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 150,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            align: 'left',
        },
        {
            field: 'cost_final',
            headerName: lang.product_cost + ' (' + currencyOriginal + ')',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            renderCell: (params) => ConvertToPrice2(params.row.cost_final, precisions),
            type: 'number',
            width: 150,
            editable: false,
            sortable: false,
            sorting: true,
            hide: show_cost == true ? false : true,
            align: 'right',
        },
        {
            field: 'price_default',
            headerName: lang.price_list_price_default + ' (' + currencyOriginal + ')',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            renderCell: (params) => ConvertToPrice2(params.row.price_default, precisions),
            type: 'number',
            width: 150,
            editable: false,
            sortable: false,
            sorting: true,
            hide: false,
            align: 'right',
            print: true,
        },
        {
            field: 'price_retail',
            headerName: lang.product_price_retail + ' (' + currencyOriginal + ')',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            renderCell: (params) => ConvertToPrice2(params.row.price_retail, precisions),
            type: 'number',
            width: 150,
            editable: false,
            sortable: false,
            sorting: true,
            hide: false,
            align: 'right',
        },
        {
            field: 'price_final',
            headerName: lang.product_price + ' (' + currency + ')',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            renderCell: (params) => ConvertToPrice2(params.row.price_final, precisions),
            type: 'number',
            width: 150,
            editable: false,
            sortable: false,
            sorting: true,
            hide: false,
            align: 'right',
            print: true,
        },
        {
            field: 'flag',
            headerName: lang.flags,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 200,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            align: 'center',
        },
        {
            field: 'ean',
            headerName: lang.product_code_ean,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            align: 'center',
            width: 200,
            editable: false,
            sortable: false,
            sorting: false,
            hide: props.system.product_codes != false ? props.system.product_codes[4].enabled == true ? false : true : true,
            hideable: true,
        },
        {
            field: 'vendors',
            headerName: lang.product_code_vendor_,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            align: 'center',
            width: 200,
            editable: false,
            sortable: false,
            sorting: false,
            hide: props.system.product_codes != false ? props.system.product_codes[3].enabled == true ? false : true : true,
            hideable: true,
        },
        {
            field: 'code_1',
            headerName: props.system.product_codes != false ? props.system.product_codes[0].label : '',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 120,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: true,
            hide: props.system.product_codes != false ? props.system.product_codes[0].enabled == true ? false : true : true,
        },
        {
            field: 'code_2',
            headerName: props.system.product_codes != false ? props.system.product_codes[1].label : '',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 120,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: true,
            hide: props.system.product_codes != false ? props.system.product_codes[1].enabled == true ? false : true : true,
        },
        {
            field: 'code_3',
            headerName: props.system.product_codes != false ? props.system.product_codes[2].label : '',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 120,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: true,
            hide: props.system.product_codes != false ? props.system.product_codes[2].enabled == true ? false : true : true,
        },
        {
            field: 'portal',
            headerName: lang.product_portal,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'boolean',
            align: 'center',
            width: 80,
            renderCell: (params) => (params.value == false ? <FontAwesomeIcon style={{ width: 14, height: 14, color: color.dark_red }} icon={faXmarkCircle} /> : <FontAwesomeIcon style={{ width: 14, height: 14, color: color.black }} icon={faCheck} />),
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
    ];

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(LoadArray('price-list-table', {}));


    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [requireRedraw, setRequireRedraw] = useState(false);
    const [redraw, setRedraw] = useState(false);


    // ZOZNAM PRODUKTOV
    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        country_id: props.country_id,
        parent_id: 0,
        //only_changes: true,
        //product_id: 15
        enabled: true
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowDown },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowUp }
    ];

    const itemsOrdering2 = [
        { id: 1, enabled: true, label: lang.product_name, icon: faArrowDown },
        { id: 2, enabled: true, label: lang.product_name, icon: faArrowUp },
        { id: 100, divider: true },
        { id: 3, enabled: true, label: lang.document_number, icon: faArrowDown },
        { id: 4, enabled: true, label: lang.document_number, icon: faArrowUp },
        { id: 101, divider: true },
        { id: 5, enabled: true, label: lang.product_code, icon: faArrowDown },
        { id: 6, enabled: true, label: lang.product_code, icon: faArrowUp },
    ];

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.product_name, default: true },
        { id: 2, enabled: true, checked: true, column: 'code', label: lang.product_code },
        { id: 3, enabled: true, checked: true, column: 'tags', label: lang.product_tags }
    ]);

    // PRODUCTS - MENU
    const [menuItems, setMenuItems] = useState(
        [
            { id: 1, checked: false, enabled: CheckPermission(props.user.super_admin, props.permissions[permission_column]).export == true ? true : false, label: lang.export_selected },
            { id: 2, checked: false, enabled: CheckPermission(props.user.super_admin, props.permissions[permission_column]).export == true ? true : false, label: lang.export_all },
            { id: 101, divider: true },
            { id: 3, checked: true, enabled: true, label: lang.show_variations },
            { id: 4, checked: false, enabled: true, label: lang.price_list_show_changes },
            { id: 102, divider: true },
            { id: 8, checked: LoadBool('price-list-mode', true), enabled: true, label: lang.preview_mode },
        ]
    );

    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [search, setSearch] = useState('');
    const [showVariations, setShowVariations] = useState(true);

    const [categoryID, setCategoryID] = useState(0);
    const [category, setCategory] = useState(0);
    const [manufactureID, setManufactureID] = useState(0);
    const [manufacture, setManufacture] = useState(0);
    const [vendorID, setVendorID] = useState(0);
    const [vendor, setVendor] = useState(0);
    const [groupID, setGroupID] = useState(0);
    const [group, setGroup] = useState(0);
    const [priceGroups, setPriceGroups] = useState(false);
    const [priceGroupID, setPriceGroupID] = useState(-1);
    const [flags, setFlags] = useState(false);

    // DIALOGS
    const [showExport, setShowExport] = useState(false);
    const [showCategorySelect, setShowCategorySelect] = useState(false);
    const [showManufactureSelect, setShowManufactureSelect] = useState(false);
    const [showVendorSelect, setShowVendorSelect] = useState(false);
    const [showGroup, setShowGroup] = useState(false);
    const [showProduct, setShowProduct] = useState(false);

    // ZOBRAZENIE LEN MANUALNYCH ZMIEN
    const [showChanges, setShowChanges] = useState(false);

    // STYL ZOBRAZOVANIE - RIADKOVE / TABULKA
    const [displayStyle, setDisplayStyle] = useState(LoadValue('price-list-style', 0));
    const [previewMode, setPreviewMode] = useState(LoadBool('price-list-mode', true));

    const menuHeight = 50;
    const rowHeight = 86;
    const rowVariantHeight = 58;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const chipHeight = 50;
    const previewHeight = 100;


    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            db_groups();
            db_products(0, filter, maxItems);

            running = true;
        }

    }, []);

    const db_products = async (page, filter, maxItems) => {
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.priceListID,
                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug('PRODUCTS', 'red');
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);

                let curr = json.currency;
                if (curr != false) {
                    setCurrency(curr.currency);
                }
                GoToStart(itemSelected == false ? 0 : itemSelected.id);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_groups = async () => {
        try {
            const response = await fetch(
                global.db_url + 'price_list_groups', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.priceListID,
                    enabled: true
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setPriceGroups(json.items);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const db_product_delete = async (product_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list_product_delete2', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    price_list_id: props.priceListID,
                    product_id: product_id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_products(page, filter, maxItems);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const GoToStart = (id) => {
        setItemSelected(false);
        setTimeout(() => {
            var element = document.getElementById('id_start_dialog');
            if (id > 0) {
                element = document.getElementById('product-id-' + id);
            }

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    function GetFilter() {
        var filter = {
            ordering: ordering,
            order_column: orderColumn,
            enabled: true,
            parent_id: 0,
            country_id: props.country_id,
        };

        if (search.trim() != '') {
            filter.search = search;

            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default == true);
                columns.push(tmp.column);
            }

            var search_text = search.split(' ');
            if (search_text != undefined) {
                filter.search = search_text;
                filter.search_columns = columns;
                //filter.variations = false;

            }

            delete (filter.variations);
            delete (filter.parent_id);
        }


        if (vendorID > 0) {
            filter.vendor_id = vendorID
        }
        if (manufactureID > 0) {
            filter.manufacture_id = manufactureID
        }
        if (categoryID > 0) {
            filter.category_id = categoryID;
        }
        if (groupID > 0) {
            filter.group_id = groupID;
        }
        if (priceGroupID > -1) {
            filter.price_group_id = priceGroupID;
        }
        if (showChanges == true) {
            filter.only_changes = true;
            delete (filter.parent_id);
        }
        return filter;
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.down : global.order.up;
        var column = 'name';

        switch (id) {

            case 2: // NAKUPNA CENA
                column = 'cost_final';
                break;

            case 3: // PREDAJNA CEMA
                column = 'price_final';
                break;
        }

        var filter = GetFilter();
        filter.ordering = order;
        filter.order_column = column;
        db_products(0, filter, maxItems);
    }

    const MenuSortResult2 = (id, index) => {
        var order = global.order.down;
        var column = 'name';

        switch (index) {

            case 1: // NAZOV
                column = 'name';
                order = global.order.down
                break;

            case 2: // SKLADOM
                column = 'name';
                order = global.order.up
                break;

            case 3: // ID
                column = 'id';
                order = global.order.down
                break;

            case 4: // ID
                column = 'id';
                order = global.order.up
                break;

            case 5: // KOD PRODUKTU
                column = 'code';
                order = global.order.down
                break;

            case 6: // KOD PRODUKTU
                column = 'code';
                order = global.order.up
                break;
        }

        var filter = GetFilter();
        filter.ordering = order;
        filter.order_column = column;
        db_products(0, filter, maxItems);
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_products(value - 1, filter, maxItems);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_products(0, filter, item);
    }

    const Search = (txt) => {
        setSearch(txt);


        var filter = GetFilter();
        if (txt.length > 2) {
            if (txt.trim() != '') {
                // zoznam stlpcov v ktorých sa bude vyhladávať
                var columns = [];
                searchColumns.forEach(item => {
                    if (item.checked == true) {
                        columns.push(item.column);
                    }
                });
                if (columns.length == 0) {
                    // ak nieje zadaný žiaden stlpec - default
                    var tmp = searchColumns.find(x => x.default === true);
                    columns.push(tmp.column);
                }

                var search_text = txt.split(' ');
                if (search_text != undefined) {
                    filter.search = search_text;
                    filter.search_columns = columns;
                }
                delete (filter.parent_id);
                //filter.variations = false;
                db_products(0, filter, maxItems);
            }
        }
        if (txt.length == 0) {
            delete (filter.search);
            delete (filter.search_columns);
            delete (filter.variations);
            db_products(0, filter, maxItems);
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const MenuResult = (id, index, item) => {
        // EXPORT MENU
        if (index == 3) {
            // zobrazovať variacie
            let value = !item.checked;
            item.checked = value;
            setShowVariations(value);
        }

        if (index == 4) {
            // zobrazovať len manualne zmeny ceny
            let value = !item.checked;
            item.checked = value;
            setShowChanges(value);

            var filter = GetFilter();
            if (value == true) {
                delete (filter.parent_id);
                delete (filter.vendor_id);
                delete (filter.manufacture_id);
                delete (filter.category_id);
                delete (filter.group_id);
                delete (filter.price_group_id);

                filter.only_changes = true;
                db_products(0, filter, maxItems);
            } else {
                OnlyChangesReset();
            }
        }

        if (index == 8) {
            // PREVIEW MODE
            var tmp = item;
            tmp.checked = previewMode == true ? false : true;
            setPreviewMode(!previewMode);
            SaveBool('price-list-mode', !previewMode);
        }
    }

    const OnlyChangesReset = () => {
        let tmp = menuItems.find(x => x.id == 4);
        if (tmp != undefined) {
            tmp.checked = false;
        }
        var filter = GetFilter();
        filter.parent_id = 0;
        delete (filter.only_changes);
        delete (filter.search);
        setSearch('');
        setShowChanges(false);
        db_products(0, filter, maxItems);
    }

    const SelectItem = (item) => {
        if (CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true) {
            if (item.variations == false) {
                setItemSelected(item);
                setShowProduct(true);
            }
        }
    }

    const SelectItemVariant = (item, variant) => {
        if (CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true) {
            //if (variant.variation_copy_price == false) {
            setItemSelected(variant);
            setShowProduct(true);
            //}
        }
    }


    const ProductResult = (redraw) => {
        setShowProduct(false);

        if (redraw) {
            db_products(page, filter, maxItems);
        } else {
            setItemSelected(false);
        }
    }

    const DeleteItem = (item) => {
        db_product_delete(item.id);
    }

    const CategoryReset = (id) => {
        setCategoryID(0);
        setCategory('');

        var filter = GetFilter();
        delete (filter.category_id);
        db_products(0, filter, maxItems);
    }

    const CategoryResult = (typ, item) => {
        setShowCategorySelect(false);

        if (typ == true) {
            setCategory(item.label);
            setCategoryID(item.id);

            var filter = GetFilter();
            filter.category_id = item.id;
            db_products(0, filter, maxItems);
        }
    }


    const ManufactureResult = (typ, item) => {
        setShowManufactureSelect(false);

        if (typ == true) {
            setManufacture(item.name);
            setManufactureID(item.id);

            var filter = GetFilter();
            filter.manufacture_id = item.id;

            db_products(0, filter, maxItems);
        }
    }

    const ResetManufacture = () => {
        setManufactureID(0);
        setManufacture('');

        var filter = GetFilter();
        delete (filter.manufacture_id);
        db_products(0, filter, maxItems);
    }

    const VendorResult = (typ, item) => {
        setShowVendorSelect(false);

        if (typ == true) {
            setVendor(item.name);
            setVendorID(item.id);

            var filter = GetFilter();
            filter.vendor_id = item.id;

            db_products(0, filter, maxItems);
        }
    }

    const ResetVendor = () => {
        setVendorID(0);
        setVendor('');

        var filter = GetFilter();
        delete (filter.vendor_id);
        db_products(0, filter, maxItems);
    }

    const GroupResult = (typ, item) => {
        setShowGroup(false);

        if (typ == true) {
            setGroup(item.label);
            setGroupID(item.id);

            var filter = GetFilter();
            filter.group_id = item.id;

            db_products(0, filter, maxItems);
        }
    }

    const ResetGroup = () => {
        setGroupID(0);
        setGroup('');

        var filter = GetFilter();
        delete (filter.group_id);
        db_products(0, filter, maxItems);
    }

    const ChangePriceGroup = (id) => {
        setPriceGroupID(id);
        var filter = GetFilter();
        filter.price_group_id = id;

        db_products(0, filter, maxItems);
    }

    const ResetPriceGroup = (id) => {
        setPriceGroupID(-1);

        var filter = GetFilter();
        delete (filter.price_group_id);
        db_products(0, filter, maxItems);
    }


    const ChangeStyle = (value) => {
        setItems(false);
        setItemsFiltered(false);
        setItemSelected(false);
        setDisplayStyle(value);

        if (value == 0) {
            filter.parent_id = 0;
        } else {
            delete (filter.parent_id);
        }

        SaveValue('price-list-style', value);
        db_products(0, filter, maxItems);
    }

    const SortUp = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.up;
        filter.order_column = data.colDef.field;
        db_products(0, filter, maxItems);
    }

    const SortDown = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.down;
        filter.order_column = data.colDef.field;
        db_products(0, filter, maxItems);
    }

    function MyMenuItem(props) {
        const { menuHandler, menuLabel, menuIcon } = props;
        return (
            <MenuItem onClick={menuHandler}>
                <ListItemIcon>
                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={menuIcon} />
                </ListItemIcon>
                <ListItemText>{menuLabel}</ListItemText>
            </MenuItem>
        );
    }

    function CustomColumnMenu(props) {
        // VLASTNÁ COLUMN MENU
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuSortItem: null,
                    columnMenuFilterItem: null,
                    columnMenuUserItem1: props.colDef.sorting == true ? MyMenuItem : null,
                    columnMenuUserItem2: props.colDef.sorting == true ? MyMenuItem : null,
                }}
                slotProps={{
                    columnMenuUserItem1: {
                        displayOrder: 1,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowUp,
                        menuHandler: () => SortUp(props),
                    },
                    columnMenuUserItem2: {
                        displayOrder: 0,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowDown,
                        menuHandler: () => SortDown(props),
                    },
                }}
            />
        );
    }

    const ChangeColumns = (data) => {
        // ZOBRAZIT / SKRYT STLPCE -> uloženie do cookies
        setColumnVisibilityModel(data);
        SaveArray('price-list-table', data);
    }


    return (

        <div style={{ ...styles.Block, width: '98%' }}>

            <div style={{ ...styles.BlockLeft }}>
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.Block, width: '20%', height: menuHeight }}>
                        <TextInput redraw search theme={props.theme} width={'100%'} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                    </div>
                    <div style={{ ...styles.Block, width: 30, height: menuHeight }}>
                        <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                    </div>
                    {props.allProducts == false && showChanges == false ?
                        <div style={{ ...styles.Block, width: '20%', height: menuHeight, marginLeft: 20 }}>
                            {priceGroups.length > 1 ?
                                <SelectInput default={lang.price_list_group} theme={props.theme} enabled={true} lang={lang} value={priceGroupID} items={priceGroups} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => ChangePriceGroup(id)} />
                                : null}
                        </div>
                        : null}
                    {showChanges == true ?
                        <div style={{ ...styles.BlockLeft, width: '70%', height: menuHeight }}>
                            <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.price_list_show_changes}</p>} onDelete={() => OnlyChangesReset()}></Chip>
                        </div>
                        :
                        <div style={{ ...styles.Block, width: props.allProducts == true ? '70%' : '50%', height: menuHeight }}>
                            <div style={{ ...styles.BlockRowRaw, height: menuHeight }}>
                                <div style={{ ...styles.Block, width: undefined, height: menuHeight }}>
                                    <Button onClick={() => setShowCategorySelect(true)} style={{ ...styles.ButtonOutlined, width: 120, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faFolderTree} />
                                        {lang.product_category}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                    <Button onClick={() => setShowGroup(true)} style={{ ...styles.ButtonOutlined, width: 120, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faLayerGroup} />
                                        {lang.product_group}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                    <Button onClick={() => setShowManufactureSelect(true)} style={{ ...styles.ButtonOutlined, width: 120, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                        {lang.manufacturer}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                    <Button onClick={() => setShowVendorSelect(true)} style={{ ...styles.ButtonOutlined, width: 120, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                        {lang.vendor}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockRowRaw, width: 150 }}>
                        <div style={{ ...styles.Block, width: 100, height: menuHeight }}>
                            <ButtonChangeStyle value={displayStyle} theme={props.theme} func={(id) => ChangeStyle(id)} />
                        </div>

                        <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                            <MenuButton id={0} menuItems={menuItems} theme={props.theme} func={MenuResult.bind()} />
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ ...styles.BlockLeft, height: chipHeight, justifyContent: 'flex-start' }}>
                {showChanges == true ? null :
                    <div style={{ ...styles.BlockRowRaw }}>
                        {priceGroupID > -1 ?
                            <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.price_list_group}</p>} onDelete={() => ResetPriceGroup(-1)}></Chip>
                            : null}
                        {categoryID > 0 ?
                            <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{category}</p>} onDelete={() => CategoryReset(0)}></Chip>
                            : null}
                        {groupID > 0 ?
                            <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{group}</p>} onDelete={() => ResetGroup(0)}></Chip>
                            : null}
                        {manufactureID > 0 ?
                            <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{manufacture}</p>} onDelete={() => ResetManufacture(0)}></Chip>
                            : null}
                        {vendorID > 0 ?
                            <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{vendor}</p>} onDelete={() => ResetVendor(0)}></Chip>
                            : null}
                    </div>
                }
            </div>

            {displayStyle == 0 ?
                <div style={{ ...styles.Block, border: color.border }}>
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ ...styles.Block, width: 40, height: tableLabelHeight, justifyContent: 'center' }}>
                            {/* SELECT */}
                            <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                            </div>
                        </div>
                        <div style={{ ...styles.BlockRow, width: '95%' }}>
                            {/* DATA */}
                            <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                            </div>
                            <div style={{ ...styles.BlockRow, width: show_cost == true ? '47%' : '62%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                <div style={{ ...styles.BlockLeft, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product}</p>
                                </div>
                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                    <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering2} theme={props.theme} func={MenuSortResult2.bind()} />
                                </div>
                            </div>
                            {show_cost == true ?
                                <div style={{ ...styles.BlockRow, width: '15%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                    <div style={{ ...styles.BlockRight, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product_cost}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                        <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                    </div>
                                </div>
                                : null}
                            <div style={{ ...styles.BlockRow, width: '15%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                <div style={{ ...styles.BlockRight, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.price_list_price_default}</p>
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, width: '15%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                <div style={{ ...styles.BlockRight, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product_price}</p>
                                </div>
                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                    <MenuButton id={3} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                </div>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: 80, height: tableLabelHeight, borderLeft: color.border }}>
                        </div>
                    </div>
                </div>
                : null}

            {/* ****************************************************************************************************************
                ZOZNAM PRODUKTOV
            **************************************************************************************************************** */}
            {displayStyle == 0 ?
                <div style={{ ...styles.BlockCenter, height: props.height - menuHeight - chipHeight - tableLabelHeight - 5, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                    <div id={'id_start_dialog'} style={{ ...styles.Block }}></div>

                    <div style={{ ...styles.BlockCenter, width: '99%' }}>
                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                            <div key={item.id} id={'product-id-' + item.id} style={{ ...styles.Block }}>
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : item.grouped == true ? color.grouped : item.variations == true ? color.variation : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : item.variations == true ? color.border_variation : item.grouped == true ? color.border_grouped : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '100%' }}>
                                        <div style={{ ...styles.BlockLeft, width: 40, height: rowHeight, justifyContent: 'center' }}>
                                            {/* SELECT */}
                                            <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                        </div>

                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '95%' }}>
                                            {/* DATA */}
                                            <div style={{ ...styles.Block, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                <Avatar picture image={item.image} size={rowHeight - 25} theme={props.theme} />
                                            </div>

                                            <div style={{ ...styles.BlockLeft, width: show_cost == true ? '47%' : '62%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                {item.flag != '' ?
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.flag}</p>
                                                    </div>
                                                    : null}
                                                {item.parent_name != '' ?
                                                    <p style={{ ...styles.TextTiny }}>{item.parent_name}</p>
                                                    : null}

                                                <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{global.testing == true ? item.id + '. ' : ''}{item.name}</p>
                                                <Line marginTop={4} theme={props.theme} />
                                                <div style={{ ...styles.BlockRowRaw, marginTop: 4 }}>
                                                    <div style={{ ...styles.Block, width: undefined, height: 24 }}>
                                                        {item.variations == false ?
                                                            <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.code}</p>
                                                            : null}
                                                    </div>
                                                    <div style={{ ...styles.Block, width: undefined, height: 24, marginLeft: 10 }}>
                                                        {item.category != '' && item.category != undefined ?
                                                            <Chip variant='outlined' size='small' style={{ borderColor: color.dark_gray, height: 18 }} label={<p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.category}</p>}></Chip>
                                                            : null}
                                                    </div>
                                                    <div style={{ ...styles.Block, width: undefined, height: 24 }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{item.manufacture}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {show_cost == true ?
                                                <div style={{ ...styles.BlockRight, width: '15%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {item.variations == false ?
                                                        <p style={{ ...styles.TextTiny }}>{FormatMoney(item.cost, precisions)} {currencySource}</p>
                                                        : null}
                                                </div>
                                                : null}
                                            <div style={{ ...styles.BlockRight, width: '15%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                {item.variations == false ?
                                                    <>
                                                        <p style={{ ...styles.TextTiny }}>{FormatMoney(item.price_default, precisions)} {currencySource}</p>

                                                    </>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: '15%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                {item.variations == false ?
                                                    <>
                                                        <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{FormatMoney(item.price_final, precisions)} {currency}</p>
                                                        {show_prices_dph == true ?
                                                            <>
                                                                <p style={{ ...styles.TextXXTiny, marginTop: 5, color: color.dark_gray }}>{lang.price_with_dph}</p>
                                                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{FormatMoneyDPH(item.price_final, item.dph == -1 ? props.locale.dph : item.dph, precisions)} {currency}</p>
                                                            </>
                                                            : null}
                                                    </>
                                                    : null}
                                                {item.product_changes == true ?
                                                    <div style={{ ...styles.BlockRight, marginTop: 5 }}>
                                                        <Chip variant='outlined' size='small' style={{ borderColor: color.dark_gray, height: 18 }} label={<p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{lang.price_list_price_product_changes}</p>}></Chip>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                        <div style={{ ...styles.Block, width: 80, minHeight: rowHeight, justifyContent: 'center' }}>
                                            {props.allProducts == false ?
                                                item.deletable == true ?
                                                    <DeleteButton ask text={lang.product_delete_from_list} theme={props.theme} lang={props.lang} func={() => DeleteItem(item)} />
                                                    : null : null}
                                        </div>
                                    </div>
                                </Paper>
                                <div style={{ ...styles.BlockRight, marginBottom: item.variants != false && showVariations != false ? 30 : 0 }}>
                                    {showVariations == true ? item.variants != false ? item.variants.map((variant) => (
                                        <Paper
                                            key={variant.id}
                                            elevation={variant.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block,
                                                width: '97%',
                                                backgroundColor: variant.enabled == false ? color.disabled : color.variation,
                                                borderRadius: global.items_radius,
                                                minHeight: rowVariantHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: variant.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(variant.id)} onMouseLeave={() => setOverID(-1)}
                                            onClick={() => SelectItemVariant(item, variant)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                <div style={{ ...styles.Block, width: rowHeight, height: rowVariantHeight, justifyContent: 'center' }}>
                                                    <Avatar picture image={variant.image} size={rowVariantHeight - 5} theme={props.theme} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: show_cost == true ? '49%' : '64%', height: rowVariantHeight }}>
                                                    <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{global.testing == true ? variant.id + '. ' : ''}{variant.name} { }</p>
                                                    <p style={{ ...styles.TextXTiny }}>{variant.code}</p>
                                                </div>
                                                {show_cost == true ?
                                                    <div style={{ ...styles.BlockRight, width: '15%', height: rowVariantHeight }}>
                                                        <p style={{ ...styles.TextXSmall, color: variant.variation_copy_price == true ? color.dark_gray : color.black }}>{FormatMoney(variant.cost_final, precisions)} {currencySource}</p>
                                                    </div>
                                                    : null}
                                                <div style={{ ...styles.BlockRight, width: '15%', height: rowVariantHeight }}>
                                                    <p style={{ ...styles.TextXSmall, color: variant.variation_copy_price == true ? color.dark_gray : color.black }}>{FormatMoney(variant.price_default, precisions)} {currencySource}</p>
                                                    {/*
                                                            <p style={{ ...styles.TextTiny, color: color.medium_gray }}>{variant.coeficient}</p>
                                                            <p style={{ ...styles.TextTiny, color: color.medium_gray }}>{variant.coeficient_price}</p>
                                                        */}
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: '15%', height: rowVariantHeight }}>
                                                    <p style={{ ...styles.TextXSmall, color: variant.variation_copy_price == true ? color.dark_gray : color.black, fontWeight: '600' }}>{FormatMoney(variant.price_final, precisions)} {currency}</p>
                                                    {show_prices_dph == true ?
                                                        <>
                                                            <p style={{ ...styles.TextXXTiny, marginTop: 0 }}>{FormatMoneyDPH(item.price_final, item.dph == -1 ? props.locale.dph : item.dph, precisions)} {currency} {lang.with_dph}</p>
                                                        </>
                                                        : null}
                                                    {variant.product_changes == true ?
                                                        <div style={{ ...styles.BlockRight, marginTop: 5 }}>
                                                            <Chip variant='outlined' size='small' style={{ borderColor: color.dark_gray, height: 16 }} label={<p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{lang.price_list_price_product_changes}</p>}></Chip>
                                                        </div>
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.Block, width: 70, height: rowVariantHeight }}>
                                                </div>
                                            </div>
                                        </Paper>
                                    )) : null : null}
                                </div>
                            </div>
                        )) :
                            <EmptyList row small lang={lang} />
                        }

                        <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                            <div style={{ ...styles.Block, width: 200 }}></div>
                            <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                {pages > 1 ?
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                    : null}
                            </div>
                            <div style={{ ...styles.Block, width: 200 }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                        <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                        <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            {displayStyle == 1 ?
                <div style={{ ...styles.BlockCenter, height: props.height - menuHeight - chipHeight - 5, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockCenter, height: props.height - menuHeight - chipHeight - 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <Box sx={{
                            height: previewMode == true ? props.height - menuHeight - chipHeight - 84 - previewHeight : props.height - menuHeight - chipHeight - 84,
                            width: 1,
                            /*
                            '& .super-app-theme--header': {
                                backgroundColor: color.light_gray,
                            },
                            */
                            '& .super-app-theme--normal': {
                                backgroundColor: color.white,
                                color: '#000000',
                                '&:hover': {
                                    bgcolor: (theme) => (color.table_row),
                                },
                            },
                            '& .super-app-theme--red': {
                                backgroundColor: color.lighteen_blue,
                                color: color.dark_red,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (color.gray),
                                },
                            },
                            '& .super-app-theme--blue': {
                                backgroundColor: color.white,
                                color: color.blue,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (color.gray),
                                },
                            },
                        }}>
                            <DataGrid

                                sx={{
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        whiteSpace: "normal",
                                        lineHeight: "normal"
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        height: "unset !important"
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        maxHeight: "168px !important"
                                    },
                                    fontSize: global.font_table, border: global.theme_table_border_width, borderColor: color.dark_gray
                                }}

                                rows={itemsFiltered == false ? [] : itemsFiltered}
                                columns={columns.filter(x => x.hide == false)}
                                slots={{ columnMenu: CustomColumnMenu }}
                                rowHeight={previewMode == true ? 30 : undefined}

                                checkboxSelection
                                disableRowSelectionOnClick
                                onRowSelectionModelChange={(newRowSelectionModel) => { setItemsSelected(newRowSelectionModel) }}
                                onRowClick={(params) => { setItemSelected(params.row) }}
                                onRowDoubleClick={(params) => { SelectItem(params.row) }}
                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                getRowClassName={(params) => (params.row.enabled == 1 ? `super-app-theme--normal` : `super-app-theme--red`)}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                                hideFooterPagination
                                hideFooter
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel) =>
                                    ChangeColumns(newModel)
                                }
                            />
                        </Box>

                        {previewMode == true && itemSelected != false ?
                            <div style={{ ...styles.Block, height: previewHeight, marginTop: 5 }}>
                                <div style={{ ...styles.BlockRow, width: '100%' }}>
                                    <div style={{ ...styles.BlockLeft, width: previewHeight, height: previewHeight, justifyContent: 'center' }}>
                                        <Avatar picture image={itemSelected.image} size={previewHeight - 10} theme={props.theme} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: previewHeight, justifyContent: 'center' }}>
                                        {itemSelected.parent_id > 0 ?
                                            <p style={{ ...styles.TextXTiny }}>{itemSelected.parent_name}</p>
                                            : null}
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{itemSelected.name}</p>
                                        <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{itemSelected.code}</p>
                                        <div style={{ ...styles.BlockCenter, maxHeight: 40, overflow: 'hidden', alignItems: 'flex-start' }}>
                                            <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{itemSelected.description_short}</p>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '30%', height: previewHeight, justifyContent: 'center' }}>
                                    </div>
                                </div>

                            </div>
                            : null}
                        {/* ****************************************************************************************************************
                                        PAGINATION
                                        **************************************************************************************************************** */}
                        <div style={{ ...styles.BlockRow, paddingTop: 10, borderTop: '1px solid ' + global.theme_light_gray }}>
                            <div style={{ ...styles.Block, width: 200 }}></div>
                            <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                {pages > 1 ?
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                    : null}
                            </div>
                            <div style={{ ...styles.Block, width: 200 }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                        <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                        <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                : null}



            {
                showCategorySelect == true ?
                    <ProductCategorySelect item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CategoryResult.bind()} />
                    : null
            }

            {
                showManufactureSelect == true ?
                    <ManufacturerSelect item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ManufactureResult.bind()} />
                    : null
            }

            {
                showVendorSelect == true ?
                    <VendorSelect item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={VendorResult.bind()} />
                    : null
            }

            {
                showGroup == true ?
                    <ProductsGroupSelect organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={GroupResult.bind()} />
                    : null
            }

            {
                showProduct == true ?
                    <PriceListProduct item={itemSelected} priceListID={props.priceListID} organization={props.organization} settings={props.settings} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductResult.bind()} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null
            }
        </div >
    );
}


/*
*
* =================================================================================================================
* CENOVE SKUPINY
* =================================================================================================================
*
*/
export const PriceListGroups = (props) => {

    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let currency = props.locale.currency;

    // SYSTEM
    const [isBusy, setBusy] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redraw, setRedraw] = useState(false);


    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [overID, setOverID] = useState(-1);
    const [excluded, setExcluded] = useState([]);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [selectProducts, setSelectProducts] = useState(false);

    const menuHeight = 50;
    const rowHeight = 50;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            db_get();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list_groups', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.priceListID,
                })
            })

            const json = await response.json();
            Debug('GROUPS');
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setExcluded(json.product_ids == false ? [] : json.product_ids);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_products_add = async (products) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list_group_add_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    price_list_id: props.priceListID,
                    group_id: itemSelected.id,
                    products: products
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                db_get();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_reorder = async (data1, data2) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list_group_reorder', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data1: data1,
                    data2: data2
                })
            })

            const json = await response.json();
            Debug(json);

            if (json.ok > 0) {
                db_get();
            }

            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get();
        }
    }

    const AddProducts = (item) => {
        setItemSelected(item);
        setSelectProducts(true);
    }

    const ProductResult = (typ, items) => {
        setSelectProducts(false);
        if (typ == true && items != false) {
            db_products_add(items);
        }
    }

    const UpDownResult = (button, index, item) => {
        if (items != false) {
            let data1 = {
                id: items[index].id,
                priority: items[index + button].priority,
            };

            let data2 = {
                id: items[index + button].id,
                priority: items[index].priority,
            };

            db_reorder(data1, data2);
        }
    }

    return (

        <div id={'id_start_dialog'} style={{ ...styles.Block, maxWidth: global.max_width, marginTop: 10 }}>

            <div style={{ ...styles.BlockRow }}>
                <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                </div>
                <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                            {lang.price_list_group_new}
                        </Button>
                        : null}
                </div>
            </div>

            <div style={{ ...styles.BlockRow, border: color.border }}>
                <div style={{ ...styles.BlockRow, width: '80%' }}>
                    <div style={{ ...styles.BlockLeft, width: '60%', justifyContent: 'center', height: 36 }}>
                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 15 }}>{lang.price_list_group_name}</p>
                    </div>
                    <div style={{ ...styles.Block, width: '20%', justifyContent: 'center', borderLeft: color.border, height: 36 }}>
                        <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.price_list_group}</p>
                    </div>
                    <div style={{ ...styles.Block, width: '20%', justifyContent: 'center', borderLeft: color.border, height: 36 }}>
                        <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.price_list_discount_add}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 85, justifyContent: 'center', borderLeft: color.border, height: 36 }}>
                </div>
                <div style={{ ...styles.Block, width: 100, justifyContent: 'center', borderLeft: color.border, height: 36 }}>
                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.priority}</p>
                </div>
            </div>

            <div style={{ ...styles.Block, marginTop: 20 }}>
                {items != false ? items.map((item, index) => (
                    <Paper
                        key={item.id}
                        elevation={item.id == overID ? 4 : 1}
                        style={{
                            ...styles.Block,
                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                            borderRadius: global.items_radius,
                            minHeight: rowHeight,
                            cursor: 'pointer',
                            marginTop: 5, marginBottom: 5,
                            paddingTop: 2, paddingBottom: 2,
                            borderLeft: item.enabled == false ? color.disabled_border : '',
                        }}
                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                    >
                        <div style={{ ...styles.BlockRow, width: '96%' }}>
                            <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '85%' }}>
                                <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name == '' ? lang.price_list_group : item.name}</p>
                                </div>
                                <div style={{ ...styles.Block, width: '20%', minHeight: rowHeight, justifyContent: 'center' }}>
                                    {item.condition_id > 0 ?
                                        <Chip
                                            style={{ marginTop: 2, marginBottom: 2 }}
                                            sx={{
                                                borderColor: color.dark_red,
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                height: 'auto',
                                                '& .MuiChip-label': {
                                                    display: 'block',
                                                    whiteSpace: 'normal',
                                                },
                                            }}
                                            label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_red }}>{lang.cond}</p>} variant="outlined"
                                        />
                                        : null}
                                </div>
                                <div style={{ ...styles.Block, width: '20%', minHeight: rowHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextXSmall }}>{item.category_id == 0 ? lang.price_list_products_selected : item.subcategories == true ? lang.product_subcategories : lang.product_category}</p>
                                </div>
                                <div style={{ ...styles.Block, width: '20%', minHeight: rowHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextXSmall }}>{CoeficientToPercentage(item.coeficient)}%</p>
                                </div>
                            </div>
                            <div style={{ ...styles.Block, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                {item.category_id == 0 ?
                                    <Tooltip title={lang.product_group_products_add}>
                                        <IconButton onClick={() => AddProducts(item)} style={{ ...styles.ButtonIcon }}>
                                            <FontAwesomeIcon style={{ width: 10 }} icon={faPlus} />
                                        </IconButton>
                                    </Tooltip>
                                    : null}
                            </div>
                            <div style={{ ...styles.BlockRight, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                <UpDown item={item} index={index} max={items.length - 1} width={70} color={color.white} border={color.border} theme={props.theme} lang={props.lang} func={UpDownResult.bind(this)} />
                            </div>
                        </div>
                    </Paper>
                )) :
                    <EmptyList row small lang={lang} />
                }
            </div>

            {selectProducts == true ?
                <ProductSelect country_id={props.country_id} excluded={excluded} price={true} showVariations={false} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductResult.bind()} />
                : null}

            {showDialog == true ?
                <PriceListGroup item={itemSelected} priceListID={props.priceListID} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}
        </div>
    );
}


/*
*
* =================================================================================================================
* SKUPINA - ZMENA ZLAVY
* =================================================================================================================
*
*/
export const PriceListGroup = (props) => {

    let module_id = props.module_id;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let currency = props.locale.currency;
    let precisions = props.system.precisions == 0 ? 2 : 3;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [requireRedraw, setRequireRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');
    const [coeficient, setCoeficient] = useState('1');
    const [ranges, setranges] = useState(false);
    const [conditionID, setConditionID] = useState(0);
    const [conditionName, setConditionName] = useState('');
    const [categories, setCategories] = useState(false);
    const [categoryID, setCategoryID] = useState(0);
    const [subcategories, setSubcategories] = useState(true);
    const [tree, setTree] = useState(false);

    // PODMIENKY
    const [showCondition, setShowCondition] = useState(false);
    const [showConditions, setShowConditions] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 700 ? 700 : height;

    // CONSTANTS
    const tabHeight = props.item == false ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = 350;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            db_data();
            db_get();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list_group', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id,
                    price_list_id: props.priceListID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;

                    setStoreData(item);
                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list_group_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setRequireRedraw(true);
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setCategories(json.categories);
            }

            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(requireRedraw);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB    
        setEnabled(item.enabled == false ? false : true);
        setName(item.name);
        setCoeficient(item.coeficient < 0 ? '1' : item.coeficient);
        setranges(item.ranges == false ? false : true);
        setCategoryID(item.category_id);
        setSubcategories(item.subcategories);
        setConditionID(item.condition_id);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.enabled = enabled;
        storeData.name = name;
        storeData.coeficient = coeficient;
        storeData.ranges = ranges;
        storeData.category_id = categoryID;
        storeData.subcategories = subcategories;
        storeData.condition_id = conditionID;
    }

    const Save = () => {
        setError('');
        var error = false;

        error += IsEmpty(name) == true ? 1 : 0;
        error += IsEmpty(coeficient) == true ? 1 : 0;

        if (error == 0) {

            let data = {
                id: props.item == false ? 0 : props.item.id,
                organization_id: props.organization.id,
                price_list_id: props.priceListID,
                enabled: enabled,
                name: name,
                coeficient: coeficient,
                ranges: ranges,
                category_id: categoryID,
                subcategories: subcategories,
                condition_id: conditionID
            };

            if (props.item == false && categoryID > 0) {
                if (tree == true) {
                    data.tree = true;
                }
            }

            StoreEditing();
            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ShowOKPress = () => {
        func(true);
    }

    const Condition = () => {
        setShowCondition(true);
    }

    const ConditionResult = (typ, id, item) => {
        setShowCondition(false);
        if (typ == true) {
            setConditionName(item.name);
            setConditionID(id);
        }
    }

    const ConditionsResult = (typ, item) => {
        setShowConditions(false);
        if (typ == true) {
            setConditionName(item.name);
            setConditionID(item.id);
        }
        if (typ == false && item == true) {
            // REDRAW
            //db_get(true);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.price_list_group_new : lang.price_list_group}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {storeData != false ?
                                <div>
                                    < FormSpace />
                                    <FormLabel editing={false} icon={faInfoCircle} title={lang.price_list_price_product_change} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                    <FormYesNo value={enabled} editing={true} title={lang.price_list_group_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                    <FormText require redraw value={name} editing={true} title={lang.price_list_group_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                    <FormText require percentage redraw value={coeficient} editing={true} title={lang.price_list_coeficient} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCoeficient(txt)} />
                                    <FormNote title={lang.price_list_group_coeficient_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormNodes deletable value={categoryID} items={categories} editing={true} title={lang.price_list_products_in_category} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCategoryID(id)} />
                                    <FormSpace />

                                    {categoryID > 0 && tree == false ?
                                        <div style={{ ...styles.BlockLeft }}>
                                            <FormYesNo value={subcategories} editing={true} title={lang.price_list_groups_subcategories} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setSubcategories(value)} />
                                            <FormNote title={lang.price_list_groups_subcategories_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                        </div>
                                        : null}

                                    {/* ZATIAL VYPNUTE - PLNE FUNKCNE - STACI SPUSTIT
                                    <FormSpace />
                                    <Line theme={props.theme} />
                                    {props.item == false && categoryID > 0 ?
                                        <div style={{ ...styles.BlockLeft }}>
                                            <FormYesNo help_id={15} value={tree} editing={true} title={lang.price_list_groups_from_categories} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setTree(value)} />
                                            <FormNote title={lang.price_list_groups_from_categories_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                        </div>
                                        : null}
                                    <FormSpace />
                                    */}

                                    {props.item != false ?
                                        <div style={{ ...styles.Block, width: '98%', marginTop: 30, backgroundColor: color.white, borderRadius: global.radius }}>
                                            <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                                <FormSpace />
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    {conditionID > 0 ?
                                                        <div style={{ ...styles.BlockLeft, width: undefined, height: 40, marginRight: 10 }}>
                                                            <FontAwesomeIcon style={{ height: 16, color: color.darker_green }} icon={faCheckCircle} />
                                                        </div>
                                                        : null}
                                                    <div style={{ ...styles.BlockLeft, width: undefined, height: 40 }}>
                                                        <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{conditionID == 0 ? lang.cond : lang.cond_active}</p>
                                                    </div>
                                                    {conditionID > 0 ?
                                                        <div style={{ ...styles.BlockLeft, width: undefined, height: 40, marginLeft: 10 }}>
                                                            <DeleteButton ask text={lang.cond_delete_ask} theme={props.theme} lang={props.lang} func={() => setConditionID(0)} />
                                                        </div>
                                                        : null}
                                                </div>

                                                {conditionID == 0 ?
                                                    <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.cond_note}</p>
                                                    : null}
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div style={{ ...styles.BlockLeft, width: '50%' }}>

                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: '50%' }}>

                                                    </div>
                                                </div>

                                                {conditionID > 0 && conditionName != '' ?
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        <p style={{ ...styles.TextXSmall, textAlign: 'center', marginLeft: 26 }}>{lang.cond_name}: <b>{conditionName}</b></p>
                                                    </div>
                                                    : null}

                                                <FormSpace />
                                                <div style={{ ...styles.Block }}>
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        <div style={{ ...styles.Block, width: undefined, height: 40 }}>
                                                            <Button onClick={() => Condition()} style={{ ...styles.ButtonThemed, color: color.black, width: 180, backgroundColor: color.button_action }}>{conditionID == 0 ? lang.cond_new : lang.cond_update}</Button>
                                                        </div>
                                                        <div style={{ ...styles.Block, width: undefined, height: 40, marginLeft: 10 }}>
                                                            <IconButton onClick={() => setShowConditions(true)} style={{ ...styles.ButtonIcon, width: 36, height: 36, borderRadius: 18 }}>
                                                                <FontAwesomeIcon style={{ height: 16 }} icon={faList} />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </div>
                                                <FormSpace />
                                            </div>
                                        </div>
                                        : null}

                                </div>
                                :
                                <Stack style={{ ...styles.Block }} spacing={1}>
                                    <FormSpace />
                                    <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                </Stack>}
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                        </div>
                    </div>
                </div >


                {showCondition == true ?
                    <ConditionDesigner item_id={conditionID} module_id={global.model.price_list} organization={props.organization} locale={props.locale} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ConditionResult.bind(this)} />
                    : null}

                {showConditions == true ?
                    <Conditions item_id={conditionID} module_id={global.model.price_list} organization={props.organization} locale={props.locale} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ConditionsResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}



/*
*
* =================================================================================================================
* PRODUKT - ZMENA ZLAVY
* =================================================================================================================
*
*/
export const PriceListProduct = (props) => {

    let module_id = props.module_id;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let currencySource = props.locale.currency;
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let show_cost = props.system.show_cost_prices == false ? false : props.user.super_admin == true ? true : props.permissions.show_cost == false ? false : true;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [requireRedraw, setRequireRedraw] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});
    const [priceList, setPriceList] = useState(false);
    const [productCost, setProductCost] = useState('');
    const [productPriceDefault, setProductPriceDefault] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [changePrice, setChangePrice] = useState(false);
    const [quantityDiscounts, setQuantityDiscounts] = useState(false);
    const [discounts, setDiscounts] = useState([]);

    const [enabled, setEnabled] = useState(true);
    const [variant, setVariant] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [name, setName] = useState('');
    const [itemID, setItemID] = useState(0);
    const [groupID, setGroupID] = useState(0);
    const [coeficient, setCoeficient] = useState('1');
    const [price, setPrice] = useState('0');
    const [priceEnter, setPriceEnter] = useState(true);
    const [currency, setCurrency] = useState('');

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 800 ? 800 : height;

    // CONSTANTS
    const tabHeight = props.item == false ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = 350;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            db_get();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list_product', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    product_id: props.item.id,
                    price_list_id: props.priceListID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;

                    setStoreData(item);
                    AssignData(item);
                }
                let product = json.product;
                setAvatar(product.image);
                setName(product.name);
                setProductCost(product.cost_final);
                setProductPrice(product.price_final);
                setProductPriceDefault(product.price_default);
                setGroupID(product.price_list_group_id);
                setVariant(product.parent_id > 0 ? true : false);
                if (json.currency != false) {
                    setCurrency(json.currency.currency);
                }
                setPriceList(json.price_list);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list_product_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setRequireRedraw(true);
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_delete = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list_product_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setRequireRedraw(true);
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(requireRedraw);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB    
        setItemID(item.id);
        setPrice(item.price < 0 ? '0' : item.price);
        setCoeficient(item.coeficient < 0 ? '1' : item.coeficient);
        setPriceEnter(item.coeficient < 0 ? true : false);
        setChangePrice(item.coeficient != -1 || item.price != -1 ? true : false);
        setQuantityDiscounts(item.quantity_discount == false ? false : true);
        setDiscounts(item.quantity_discount == false ? [] : JSON.parse(item.quantity_discount));
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.price = price;
        storeData.coeficient = coeficient;
    }

    const Save = () => {
        setError('');
        var error = false;
        if (priceEnter == true) {
            error += IsEmpty(price) == true ? 1 : 0;
        } else {
            error += IsEmpty(coeficient) == true ? 1 : 0;
        }

        if (quantityDiscounts == true && discounts.length > 0) {
            // KONTROLA MNOZSTEVNYCH ZLIAV

            discounts.forEach(item => {
                Debug(item);
                if (String(item.coef).trim() == '' && String(item.price).trim() == '') {
                    error++;
                }
                if (String(item.from).trim() == '' || String(item.to).trim() == '') {
                    error++;
                }
            });
        }

        if (error == 0) {
            var changed = false;
            if (changePrice == true || (quantityDiscounts == true && discounts.length > 0)) {
                changed = true;
            }

            let data = {
                id: itemID,
                organization_id: props.organization.id,
                enabled: true,
                price_list_id: props.priceListID,
                //price_group_id: groupID,
                product_id: props.item.id,
                variant: variant,
                changed: changed,
                coeficient: changePrice == false ? -1 : priceEnter == true ? -1 : coeficient,
                price: changePrice == false ? -1 : priceEnter == true ? price : -1,
                quantity_discount: quantityDiscounts == true ? discounts.length == 0 ? false : JSON.stringify(discounts) : false
            };

            StoreEditing();
            db_update(data);
            Debug(data);

        } else {
            setError(lang.required_red);
        }

    }

    /*
    const ResetPrices = () => {
        let data = {
            id: itemID,
            organization_id: props.organization.id,
            enabled: true,
            price_list_id: props.priceListID,
            price_group_id: groupID,
            product_id: props.item.id,
            variant: variant,
            changed: false,
            coeficient: - 1,
            price: -1
        };

        db_update(data);
    }
    */

    const ShowOKPress = () => {
        func(true);
    }

    const ChangePrices = (value) => {
        setChangePrice(value);

        if (value == true) {
            setQuantityDiscounts(false);
        }
    }

    const ChangeQuantityDiscounts = (value) => {
        setQuantityDiscounts(value);
        if (value == true) {
            setChangePrice(false);
        }
    }

    const DiscountAdd = () => {
        var tmp = discounts;
        var last = 0;
        if (tmp.length > 0) {
            last = parseInt(tmp[tmp.length - 1].to) + 1;
        }
        let data = {
            from: last,
            to: 999999,
            coef: '',
            price: ''
        }
        tmp.push(data);

        setDiscounts(tmp);
        setRedraw(!redraw);
    }

    const Delete = (id) => {
        var tmp = discounts;
        var array = [];
        tmp.forEach((item, index) => {
            if (index != id) {
                array.push(item);
            }

        });
        setDiscounts(array);
        setRedraw(!redraw);
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.price_list_price_product_change}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {storeData != false ?
                                <div style={{ paddingBottom: global.empty_padding }}>
                                    <FormSpace />
                                    <div style={{ ...styles.Block }}>
                                        <Avatar editable={false} picture image={avatar} size={75} theme={props.theme} func={null} />
                                        <p style={{ ...styles.TextXSmall, marginTop: 7, marginBottom: 10, fontWeight: '600', color: color.black }}>{name}</p>
                                        <div style={{ ...styles.BlockRow, border: color.border, paddingTop: 2, paddingBottom: 2 }}>
                                            {show_cost == true ?
                                                <div style={{ ...styles.Block, width: '33%', height: 40 }}>
                                                    <p style={{ ...styles.TextTiny, color: color.black, marginBottom: 2, color: color.dark_gray }}>{lang.product_cost}</p>
                                                    <p style={{ ...styles.TextXSmall, color: color.black }}>{FormatMoney(productCost, precisions)} {currencySource}</p>
                                                </div>
                                                : null}
                                            <div style={{ ...styles.Block, width: show_cost == true ? '33%' : '50%', borderLeft: color.border, height: 40 }}>
                                                <p style={{ ...styles.TextTiny, color: color.black, marginBottom: 2, color: color.dark_gray }}>{lang.price_list_price_default}</p>
                                                <p style={{ ...styles.TextXSmall, color: color.black }}>{FormatMoney(productPriceDefault, precisions)} {currencySource}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: show_cost == true ? '33%' : '50%', borderLeft: color.border, height: 40 }}>
                                                <p style={{ ...styles.TextTiny, color: color.black, marginBottom: 2, color: color.dark_gray }}>{lang.product_price}</p>
                                                <p style={{ ...styles.TextXSmall, color: color.black }}>{FormatMoney(productPrice, precisions)} {currency}</p>
                                            </div>
                                        </div>
                                    </div>

                                    < FormSpace />
                                    <FormLabel editing={false} title={lang.price_list_price_product_change} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                    <FormYesNo value={changePrice} editing={true} title={lang.price_list_change_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => ChangePrices(value)} />
                                    {changePrice == true ?
                                        <div style={{ ...styles.Block }}>
                                            <FormYesNo value={priceEnter} editing={true} title={lang.price_list_price_product} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} yes={lang.price_list_manual_price} no={lang.price_list_coefficient} func={(value) => setPriceEnter(value)} />
                                            <FormNote title={lang.price_list_change_price_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                            {priceEnter == true ?
                                                <FormText require money currency={currency} precisions={precisions} redraw value={price} editing={true} title={lang.product_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPrice(txt)} />
                                                :
                                                <FormText require percentage redraw value={coeficient} editing={true} title={lang.price_list_coeficient} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCoeficient(txt)} />
                                            }
                                            < FormSpace />
                                        </div>
                                        :
                                        < FormSpace />
                                    }
                                    <FormLabel editing={false} title={lang.price_list_quantity_discounts} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                    <FormYesNo value={quantityDiscounts} editing={true} title={lang.price_list_quantity_discounts_use} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => ChangeQuantityDiscounts(value)} />
                                    <FormNote editing={false} title={lang.price_list_quantity_discounts_use_note} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    <div style={{ ...styles.Block }}>
                                        {quantityDiscounts == true ? discounts.length > 0 ? discounts.map((item, index) => (
                                            <div key={index} style={{ ...styles.BlockRowRaw, marginBottom: 10 }}>
                                                <div style={{ ...styles.BlockLeft, width: 135 }}>
                                                    <TextInput numeric require redraw enabled={true} lang={lang} value={item.from} label={lang.price_list_quantity_discounts_from} func={(txt) => item.from = txt} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: 135, marginLeft: 5 }}>
                                                    <TextInput numeric require redraw enabled={true} lang={lang} value={item.to} label={lang.price_list_quantity_discounts_to} func={(txt) => item.to = txt} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: 90, marginLeft: 20 }}>
                                                    <TextInput numeric require={item.coef == '' && item.price == '' ? true : false} redraw enabled={true} lang={lang} value={item.coef} label={lang.price_list_coefficient} func={(txt) => item.coef = txt} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: 120, marginLeft: 5 }}>
                                                    <TextInput numeric redraw enabled={true} lang={lang} value={item.price} label={lang.product_price} func={(txt) => item.price = txt} />
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 40, marginLeft: 0 }}>
                                                    <IconButton onClick={() => Delete(index)} style={{ ...styles.ButtonIcon, marginTop: 22 }}>
                                                        <FontAwesomeIcon style={{ width: 10 }} icon={faTrash} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )) : null : null}
                                    </div>

                                    {quantityDiscounts == true ?
                                        <div style={{ ...styles.Block }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginBottom: 2 }}>{lang.price_list_quantity_discounts_coef_price}</p>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginBottom: 10 }}>{lang.price_list_quantity_discounts_to_end}</p>
                                            <ButtonNew icon={faPlusCircle} label={lang.price_list_quantity_discount_add} theme={props.theme} func={DiscountAdd.bind()} />
                                        </div>
                                        : null}


                                </div>
                                :
                                <Stack style={{ ...styles.Block }} spacing={1}>
                                    <FormSpace />
                                    <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                </Stack>}
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {/*
                            itemID > 0 && priceList.all_products == true ?
                                <Button onClick={() => Delete()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_delete, marginRight: 20 }}>{lang.reset}</Button>
                                :
                                <Button onClick={() => ResetPrices()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_delete, marginRight: 20 }}>{lang.reset}</Button>
                            */}
                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                        </div>
                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}




export const PriceListSelect = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = {
        enabled: true,
        ordering: global.order.down,
        order_column: 'name'
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DATA
    const [value, setValue] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 700 ? 700 : height;

    const menuHeight = 60;
    const rowHeight = 66;
    const viewWidth = '96%';

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter);

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_lists', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems));

                setFilter(filter_);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        if (txt.length > 2) {
            var filter = defaultFilter;

            if (txt.length > 1) {

                var search_text = txt.split(' ');
                filter.search = search_text;
                filter.search_columns = ['name'];
                db_get(0, filter);
            }
        }
        if (txt == '') {
            db_get(0, filter);
        }

    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        func(true, item)
    }

    const NodeSelectResult = (id) => {
        var tmp = items.find(x => x.id == id);
        if (tmp != undefined) {
            func(true, tmp);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.vendors}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start'} style={{ ...styles.BlockLeft, width: viewWidth }}>

                            {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    onClick={() => SelectItem(item)}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                                        <div style={{ ...styles.BlockLeft, width: rowHeight + 6, height: rowHeight }}>
                                            <Avatar picture image={item.avatar} size={rowHeight - 6} theme={props.theme} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '87%', minHeight: rowHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name}</p>
                                            <div style={{ ...styles.BlockLeft, maxHeight: 20, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.street} {item.town}{item.town != '' && item.state != '' ? ', ' : ' '}{item.state}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div >

                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


