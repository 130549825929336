/*
*
* =================================================================================================================
* PRODUKTY

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { EmptyList, Icon, Loading, ShowError, ShowOK, TextInput, Avatar, Line, MenuButton, SelectInput, DialogExport, SearchButton, ShowInfo, DialogEnterText, ButtonChangeStyle, StockStatus, DialogEnterNumber } from './items';
import { styles } from './styles';
import { Backdrop, Box, Button, Checkbox, Chip, IconButton, LinearProgress, ListItemIcon, ListItemText, MenuItem, Pagination, Paper, Skeleton, Stack, TextField, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowPointer, faArrowUp, faBars, faBoxesStacked, faCheck, faFilter, faFolderTree, faLayerGroup, faPen, faPlus, faQuestion, faTableCells, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, FormatPieces, GetLeftPosition, GetPages, LoadArray, LoadValue, SaveArray, SaveBool, SaveValue, notNumber } from './functions';
import { ProductCategorySelect } from './product_categories';
import { ManufacturerSelect } from './manufacturers';
import { VendorSelect } from './vendors';
import { BatchUpdate } from './batch_update';
import { ProductsGroup, ProductsGroupSelect } from './product_groups';
import { ProductAdjust } from './product_adjustment.js';
import { StockHistory } from './stock_history.js';
import {
    DataGrid, skSK,
    GridColumnMenu,
} from '@mui/x-data-grid';
import { StockSelect } from './stocks.js';

export const Warehouse = (props) => {

    let module_id = global.model.products;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'stock_adjustment';
    let currency = props.locale.currency;
    let codes = props.system.product_codes;
    let stock_default = 0;

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        stock: stock_default,
        parent_id: 0,
        enabled: true,
        country_id: props.locale.id,
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowDown },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowUp }
    ];

    const itemsOrdering2 = [
        { id: 1, enabled: true, label: lang.product_name, icon: faArrowDown },
        { id: 2, enabled: true, label: lang.product_name, icon: faArrowUp },
        { id: 100, divider: true },
        { id: 3, enabled: true, label: lang.document_number, icon: faArrowDown },
        { id: 4, enabled: true, label: lang.document_number, icon: faArrowUp },
        { id: 101, divider: true },
        { id: 5, enabled: true, label: lang.product_code, icon: faArrowDown },
        { id: 6, enabled: true, label: lang.product_code, icon: faArrowUp },
    ];

    // EXPORT UDAJOV
    const exportColumns = [
        { id: 1, column: 'name', label: lang.product_name, typ: 'text', align: 'left' },
        { id: 2, column: 'code', label: lang.product_code, typ: 'text', align: 'left' },
        { id: 3, column: 'quantity_stock', label: lang.product_quantity_stock_, typ: 'integer', align: 'left' },
        { id: 10, column: 'cost', label: lang.product_cost, typ: 'currency', align: 'right' },
        { id: 11, column: 'price', label: lang.product_price, typ: 'currency', align: 'rigth' },
        { id: 12, column: 'price_retail', label: lang.product_price_retail + ' - ' + lang.temporary, typ: 'currency', align: 'rigth' },
        { id: 13, column: 'cost_temp', label: lang.product_cost + ' - ' + lang.temporary, typ: 'currency', align: 'rigth' },
        { id: 14, column: 'price_temp', label: lang.product_price + ' - ' + lang.temporary, typ: 'currency', align: 'rigth' },
        { id: 15, column: 'price_retail_temp', label: lang.product_price_retail + ' - ' + lang.temporary, typ: 'currency', align: 'rigth' },
        { id: 16, column: 'dph', label: lang.product_dph, typ: 'currency', align: 'rigth' },
        { id: 17, column: 'description_short', label: lang.product_description_short, typ: 'text', align: 'left' },
        { id: 18, column: 'date_start', label: lang.product_dph, typ: 'text', align: 'center' },
        { id: 19, column: 'date_end', label: lang.product_date_end, typ: 'text', align: 'center' },
        { id: 25, column: 'quantity_package', label: lang.product_quantity_package, typ: 'text', align: 'center' },
        { id: 26, column: 'unit', label: lang.product_unit, typ: 'text', align: 'center' },
        { id: 30, column: 'note', label: lang.note, typ: 'text', align: 'left' },
        { id: 98, column: 'variations', label: lang.product_variations_, typ: 'boolean', align: 'center' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean', align: 'center' },
    ];

    // SEARCH MENU
    const [filterMenu, setFilterMenu] = useState([
        { id: 1, enabled: true, label: lang.stock_zero_status },
        { id: 2, enabled: true, label: lang.stock_smaller_status },
        { id: 4, enabled: true, label: lang.stock_bigger_status },
        { id: 3, enabled: true, label: lang.products_filter_unabled },
        { id: 5, enabled: true, label: lang.stock_guarded },
        { id: 6, enabled: true, label: lang.stock_not_guarded },
    ]);

    // TYPY SKLADOV
    let stock_types = [
        { id: 0, name: lang.warehouses_all },
        { id: 1, name: lang.warehouse_types_[0] },
        { id: 2, name: lang.warehouse_types_[1] },
        { id: 3, name: lang.warehouse_types_[2] },
    ];

    var columns = [
        {
            field: 'enabled',
            headerName: lang.enabled_,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'boolean',
            align: 'center',
            width: 60,
            renderCell: (params) => (params.value == false ? <FontAwesomeIcon style={{ width: 14, height: 14, color: color.dark_red }} icon={faXmarkCircle} /> : <FontAwesomeIcon style={{ width: 14, height: 14, color: color.black }} icon={faCheck} />),
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
        {
            field: 'code',
            headerName: lang.product_code_,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 120,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: false,
            hide: false,
        },
        {
            field: 'name',
            headerName: lang.product_name,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            renderCell: (params) => (params.row.parent_id == 0 ? params.row.name : params.row.parent_name + ' - ' + params.row.name),
            type: 'string',
            align: 'left',
            width: 300,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'category',
            headerName: lang.product_category,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 150,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            align: 'left',
        },
        {
            field: 'stock_quantity',
            headerName: lang.product_quantity_stock_,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            renderCell: (params) => FormatPieces(params.row.stock_quantity),
            type: 'number',
            width: 130,
            editable: false,
            sortable: false,
            sorting: true,
            hide: false,
            align: 'center',
        },
        {
            field: 'flag',
            headerName: lang.flags,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 200,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            align: 'center',
        },
        {
            field: 'ean',
            headerName: lang.product_code_ean,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            align: 'center',
            width: 200,
            editable: false,
            sortable: false,
            sorting: false,
            hide: props.system.product_codes != false ? props.system.product_codes[4].enabled == true ? false : true : true,
            hideable: true,
        },
        {
            field: 'vendors',
            headerName: lang.product_code_vendor_,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            align: 'center',
            width: 200,
            editable: false,
            sortable: false,
            sorting: false,
            hide: props.system.product_codes != false ? props.system.product_codes[3].enabled == true ? false : true : true,
            hideable: true,
        },
        {
            field: 'code_1',
            headerName: props.system.product_codes != false ? props.system.product_codes[0].label : '',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 120,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: true,
            hide: props.system.product_codes != false ? props.system.product_codes[0].enabled == true ? false : true : true,
        },
        {
            field: 'code_2',
            headerName: props.system.product_codes != false ? props.system.product_codes[1].label : '',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 120,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: true,
            hide: props.system.product_codes != false ? props.system.product_codes[1].enabled == true ? false : true : true,
        },
        {
            field: 'code_3',
            headerName: props.system.product_codes != false ? props.system.product_codes[2].label : '',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 120,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: true,
            hide: props.system.product_codes != false ? props.system.product_codes[2].enabled == true ? false : true : true,
        },
        {
            field: 'portal',
            headerName: lang.product_portal,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'boolean',
            align: 'center',
            width: 80,
            renderCell: (params) => (params.value == false ? <FontAwesomeIcon style={{ width: 14, height: 14, color: color.dark_red }} icon={faXmarkCircle} /> : <FontAwesomeIcon style={{ width: 14, height: 14, color: color.black }} icon={faCheck} />),
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showGroupError, setShowGroupError] = useState(false);
    const [showExportError, setShowExportError] = useState(false);

    // EXPORT
    const [itemsExportMenu, setItemsExportMenu] = useState(
        [
            { id: 1, checked: false, enabled: CheckPermission(props.user.super_admin, props.permissions[permission_column]).export == true ? true : false, label: lang.export_selected },
            { id: 2, checked: false, enabled: CheckPermission(props.user.super_admin, props.permissions[permission_column]).export == true ? true : false, label: lang.export_all },
            { id: 101, divider: true },
            { id: 6, checked: true, enabled: true, label: lang.show_variations },
            { id: 102, divider: true },
            { id: 7, checked: false, enabled: CheckPermission(props.user.super_admin, props.permissions['products_groups']).write == true ? true : false, icon: faPlus, label: lang.product_group_new },
        ]
    );

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [categoryID, setCategoryID] = useState(0);
    const [category, setCategory] = useState(0);
    const [manufactureID, setManufactureID] = useState(0);
    const [manufacture, setManufacture] = useState(0);
    const [vendorID, setVendorID] = useState(0);
    const [vendor, setVendor] = useState(0);
    const [groupID, setGroupID] = useState(0);
    const [group, setGroup] = useState(0);
    const [previewMode, setPreviewMode] = useState(true);

    // SKLADY
    const [stocks, setStocks] = useState(false);
    const [stockStatus, setStockStatus] = useState(false);
    const [stockID, setStockID] = useState(0);
    const [stock, setStock] = useState('');
    const [stockType, setStockType] = useState(0);
    const [stockSelected, setStockSelected] = useState(0);

    // FILTROVANIE
    const [showEnabled, setShowEnabled] = useState(true);
    const [showDisabled, setShowDisabled] = useState(false);
    const [showZero, setShowZero] = useState(false);
    const [showSmaller, setShowSmaller] = useState(0);
    const [showSmallerValue, setShowSmallerValue] = useState(10);
    const [showSmallerQuantity, setShowSmallerQuantity] = useState(false);
    const [showBigger, setShowBigger] = useState(0);
    const [showBiggerValue, setShowBiggerValue] = useState(10);
    const [showBiggerQuantity, setShowBiggerQuantity] = useState(false);
    const [showGuarded, setShowGuarded] = useState(false);
    const [showNotGuarded, setShowNotGuarded] = useState(false);
    const [showRange, setShowRange] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.product_name, default: true },
        { id: 2, enabled: true, checked: true, column: 'code', label: lang.product_code },
        { id: 4, enabled: codes[3].enabled == true ? true : false, checked: false, column: 'code_vendor', label: lang.product_code_vendor_ },
        { id: 5, enabled: codes[4].enabled == true ? true : false, checked: false, column: 'code_ean', label: lang.product_code_ean },
        { id: 3, enabled: true, checked: true, column: 'tags', label: lang.product_tags }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showCategorySelect, setShowCategorySelect] = useState(false);
    const [showManufactureSelect, setShowManufactureSelect] = useState(false);
    const [showVendorSelect, setShowVendorSelect] = useState(false);
    const [showGroup, setShowGroup] = useState(false);
    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [showBatch, setShowBatch] = useState(false);
    const [showVariations, setShowVariations] = useState(true);
    const [showStockCard, setShowStockCard] = useState(false);
    const [showSelectStock, setShowSelectStock] = useState(false);

    // UPRAVA SKLADU - ADJUSTMENT
    const [showAdjust, setShowAdjust] = useState(false);

    // STYL ZOBRAZOVANIE - RIADKOVE / TABULKA
    const [displayStyle, setDisplayStyle] = useState(LoadValue('warehouse-style', 0));
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(LoadArray('warehouse-table', {}));

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 100;
    const rowVariantHeight = 56;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const maxWidth = 1100;
    const stockCellWidth = 150;
    const stockCellHeight = 70;
    const chipHeight = 50;
    const previewHeight = 100;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            // DEFAULT STOCK = props.locale.stock_default
            Debug(props.params);
            setShowEnabled(false);
            setShowDisabled(false);
            setShowZero(false);
            setShowSmaller(false);
            setShowSmallerQuantity(false);
            setShowSmallerValue(0);
            setShowBigger(0);
            setShowBiggerValue(0);
            setShowBiggerQuantity(false);
            setShowGuarded(false);
            setShowNotGuarded(false);
            setShowRange(false);

            setItemSelected(false);
            setStockType(0);
            setStockSelected(0);
            // UDAJE Z DB
            var filter = defaultFilter;
            if (props.params != false) {

                var columns = [];
                var search_arr = [];

                if (props.params.stock_id != undefined) {
                    filter.stock_id = props.params.stock_id;
                    setStockID(props.params.stock_id);
                    setStock(props.params.stock);
                }

                if (props.params.range_from != undefined && props.params.range_to != undefined) {
                    filter.stock_range_from = props.params.range_from;
                    filter.stock_range_to = props.params.range_to;
                    setShowRange({ from: props.params.range_from, to: props.params.range_to });
                }
                if (props.params.stock_zero == true) {
                    filter.stock_zero = true;
                    setShowZero(true);
                }
                if (props.params.stock_bigger != undefined) {
                    filter.stock_bigger = parseInt(props.params.stock_bigger);
                    setShowBiggerValue(parseInt(filter.stock_bigger));
                    setShowBigger(true);
                }
                if (props.params.stock_smaller != undefined) {
                    filter.stock_smaller = parseInt(props.params.stock_smaller);
                    setShowSmallerValue(parseInt(filter.stock_smaller));
                    setShowSmaller(true);
                }
                if (props.params.product_category_id != undefined) {
                    filter.category_id = props.params.product_category_id;
                    setCategoryID(props.params.product_category_id);
                    setCategory(props.params.product_category);
                }
                if (props.params.product_group_id != undefined) {
                    filter.group_id = props.params.product_group_id;
                    setGroupID(props.params.product_group_id);
                    setGroup(props.params.product_group);
                }

                if (props.params.product_name != undefined) {
                    columns.push('name');

                    var search_text = props.params.product_name.split(' ');
                    if (search_text != undefined) {
                        search_text.forEach(element => {
                            search_arr.push(element);
                        });
                    }
                }

                if (props.params.product_code != undefined) {
                    columns.push('code');

                    var search_text = props.params.product_code.split(' ');
                    if (search_text != undefined) {
                        search_text.forEach(element => {
                            search_arr.push(element);
                        });
                    }
                }
                if (search_arr.length > 0) {
                    filter.search = search_arr;
                    filter.search_columns = columns;
                }
            }

            db_get(0, filter, maxItems);
            db_data();

            running = true;
        }

    }, [props.locale, props.params]);


    const db_get = async (page_, filter, maxItems) => {
        Debug('filter ----------');
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'warehouse_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------

                    start: page_ * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page_);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);

                if (page_ != page) {
                    GoToStart(0);
                } else {
                    GoToStart(itemSelected == false ? 0 : itemSelected.id);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_data = async () => {
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                //setCategories(json.categories);
                //setManufactures(json.manufactures);
                //setVendors(json.vendors);
                var tmp = json.stocks;
                if (tmp.length > 1) {
                    tmp.push({
                        id: 0,
                        enabled: true,
                        name: lang.warehouse_all,
                        code: lang.warehouse_all,
                    });
                }
                setStocks(json.stocks);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const db_stock_status = async (product_uid) => {
        setStockStatus(false);
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'product_stock_status', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    product_uid: product_uid,
                    stock_type: stockType
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setStockStatus(json.stocks);
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note, columns) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.products,
                    filename: lang.products, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: columns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_products_add = async (group_id, data) => {
        // PRIDANIE PRODUKTOV DO SKUPINY
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products_group_items_add', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    group_id: group_id,
                    products: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);
        setItemSelected(false);

        var filter = GetFilter();

        if (txt.trim() != '') {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            if (search_text != undefined) {
                filter.search = search_text;
                filter.search_columns = columns;
            }
            delete (filter.parent_id);
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = (id) => {
        setTimeout(() => {
            var element = document.getElementById('id_start');

            if (id > 0) {
                element = document.getElementById('product-id-' + id);
                if (element != null) {
                    element.scrollIntoView({ behavior: 'smooth', block: "nearest" }); // start, center, end, nearest
                }
            } else {
                if (element != null) {
                    element.scrollIntoView({ behavior: 'smooth' }); // start, center, end, nearest
                }
            }

        }, global.goto_start_delay);
    }

    const ResetFilters = () => {
        setSearch('');
        setCategoryID(0);
        setCategory('');
        setManufactureID(0);
        setManufacture('');
        setVendorID(0);
        setVendor('');
        setGroupID(0);
        setGroup('');
    }


    function GetFilter() {
        var filter = {
            ordering: ordering,
            order_column: orderColumn,
            enabled: true,
            country_id: props.locale.id,
        };
        filter.stock = stockType;
        filter.parent_id = 0;

        filter = defaultFilter;

        if (search.trim() != '') {
            delete (filter.parent_id);
            filter.search = search;

            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default == true);
                columns.push(tmp.column);
            }

            var search_text = search.split(' ');
            if (search_text != undefined) {
                filter.search = search_text;
                filter.search_columns = columns;
            }
        }
        if (showRange != false) {
            filter.stock_range_from = showRange.from;
            filter.stock_range_to = showRange.to;
        }
        if (vendorID > 0) {
            filter.vendor_id = vendorID
        }
        if (manufactureID > 0) {
            filter.manufacture_id = manufactureID
        }
        if (categoryID > 0) {
            filter.category_id = categoryID;
        }
        if (groupID > 0) {
            filter.group_id = groupID;
        }
        if (showZero == true) {
            filter.stock_zero = true;
        }
        if (showSmaller == true) {
            filter.stock_smaller = showSmallerValue;
        }
        if (showBigger == true) {
            filter.stock_bigger = showBiggerValue;
        }
        if (showEnabled == true) {
            filter.enabled = true;
        }
        if (showGuarded == true) {
            delete (filter.parent_id);
            filter.guarded = true;
        }
        if (showNotGuarded == true) {
            delete (filter.parent_id);
            filter.not_guarded = true;
        }
        if (showDisabled == true) {
            filter.disabled = true;
            delete (filter.enabled);
        }
        if (stockID > 0) {
            filter.stock_id = stockID;
            delete (filter.stock);
        }
        return filter;
    }

    const SelectItem = (item) => {
        db_stock_status(item.stock_uid);

        let id = itemSelected == false ? 0 : itemSelected.id;
        setItemSelected(item);
        if (id == item.id) {
            setItemSelected(false);
        }
        GoToStart(item.id);
    }


    const MenuResult = (id, index, item) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1 || index == 2) {
            if (index == 1) {
                let tmp = items.find(x => x.selected === true);
                if (tmp == undefined) {
                    enable = false;
                }
            }

            if (enable == true) {
                setMenuIndex(index);
                setShowExport(true);
            } else {
                setShowExportError(true);
            }
        }

        if (index == 6) {
            // nezobrazovat / zobrazovat variacie
            var tmp = item;
            if (tmp != undefined) {
                tmp.checked = showVariations == true ? false : true;
            }
            setShowVariations(!showVariations);
        }

        if (index == 7) {
            // NOVA SKUPINA
            CreateGroup();
        }

        if (index == 8) {
            // PREVIEW MODE
            var tmp = item;
            tmp.checked = previewMode == true ? false : true;
            setPreviewMode(!previewMode);
            SaveBool('products-stock-mode', !previewMode);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.down : global.order.up;
        var column = 'name';

        switch (id) {

            case 2: // SKLADOM
                column = 'stock_quantity';
                break;

        }

        var filter = GetFilter();
        filter.ordering = order;
        filter.order_column = column;
        db_get(0, filter, maxItems);
    }

    const MenuSortResult2 = (id, index) => {
        var order = global.order.down;
        var column = 'name';

        switch (index) {

            case 1: // NAZOV
                column = 'name';
                order = global.order.down
                break;

            case 2: // SKLADOM
                column = 'name';
                order = global.order.up
                break;

            case 3: // ID
                column = 'id';
                order = global.order.down
                break;

            case 4: // ID
                column = 'id';
                order = global.order.up
                break;

            case 5: // KOD PRODUKTU
                column = 'code';
                order = global.order.down
                break;

            case 6: // KOD PRODUKTU
                column = 'code';
                order = global.order.up
                break;
        }

        var filter = GetFilter();
        filter.ordering = order;
        filter.order_column = column;
        db_get(0, filter, maxItems);
    }


    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note, columns) => {
        setShowExport(false);
        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            var eColumns = columns == false ? exportColumns : columns;
            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            if (item.variations == true) {
                                if (item.variants != false) {
                                    item.variants.forEach(variant => {
                                        itemRows.push(variant.id);
                                    });
                                }
                            } else {
                                itemRows.push(item.id);
                            }
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note, eColumns);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note, eColumns);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const NewRecordReset = () => {
        ResetFilters();
        db_get(0, defaultFilter, maxItems);
    }

    const FilterReset = () => {

        setShowZero(false);
        setShowSmaller(false);
        setShowBigger(false);

        var filter = GetFilter();
        delete (filter.stock_zero);
        delete (filter.stock_smaller);
        delete (filter.stock_bigger);
        db_get(0, filter, maxItems);
    }

    const FilterGuardedReset = () => {

        setShowGuarded(false);
        setShowNotGuarded(false);

        var filter = GetFilter();
        delete (filter.guarded);
        delete (filter.not_guarded);
        db_get(0, filter, maxItems);
    }

    const DisabledReset = () => {

        setShowDisabled(false);

        var filter = GetFilter();
        delete (filter.disabled);
        filter.enabled = true;
        db_get(0, filter, maxItems);
    }

    const RangeReset = () => {
        setShowRange(false);

        var filter = GetFilter();
        delete (filter.stock_range_from);
        delete (filter.stock_range_to);

        db_get(0, filter, maxItems);

    }

    const CategoryReset = () => {
        setCategoryID(0);
        setCategory('');

        var filter = GetFilter();
        delete (filter.category_id);
        db_get(0, filter, maxItems);
    }

    const CategoryResult = (typ, item) => {
        setShowCategorySelect(false);

        if (typ == true) {
            setCategory(item.label);
            setCategoryID(item.id);

            var filter = GetFilter();
            filter.category_id = item.id;
            db_get(0, filter, maxItems);
        }
    }


    const ManufactureResult = (typ, item) => {
        setShowManufactureSelect(false);

        if (typ == true) {
            setManufacture(item.name);
            setManufactureID(item.id);

            var filter = GetFilter();
            filter.manufacture_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const ResetManufacture = () => {
        setManufactureID(0);
        setManufacture('');

        var filter = GetFilter();
        delete (filter.manufacture_id);
        db_get(0, filter, maxItems);
    }

    const VendorResult = (typ, item) => {
        setShowVendorSelect(false);

        if (typ == true) {
            setVendor(item.name);
            setVendorID(item.id);

            var filter = GetFilter();
            filter.vendor_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const StockResult = (typ, item) => {
        setShowSelectStock(false);

        if (typ == true) {
            setStock(item.name);
            setStockID(item.id);

            var filter = GetFilter();
            filter.stock_id = item.id;
            delete (filter.stock);

            db_get(0, filter, maxItems);
        }
    }

    const ResetVendor = () => {
        setVendorID(0);
        setVendor('');

        var filter = GetFilter();
        delete (filter.vendor_id);
        db_get(0, filter, maxItems);
    }

    const ResetStock = () => {
        setStockID(0);
        setStock('');

        var filter = GetFilter();
        delete (filter.stock_id);
        db_get(0, filter, maxItems);
    }

    const GroupResult = (typ, item) => {
        setShowGroup(false);

        if (typ == true) {
            setGroup(item.label);
            setGroupID(item.id);

            var filter = GetFilter();
            filter.group_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const ResetGroup = () => {
        setGroupID(0);
        setGroup('');

        var filter = GetFilter();
        delete (filter.group_id);
        db_get(0, filter, maxItems);
    }

    const BatchResult = (redraw_) => {
        setShowBatch(false);
        if (redraw_ == true) {
            db_get(page, filter, maxItems);
        }
    }

    const CreateGroup = () => {
        var tmp = itemsFiltered.filter(x => x.selected == true);

        if (tmp.length > 0) {
            setShowCreateGroup(true);
        } else {
            setShowGroupError(true);
        }
    }

    const CreateGroupResult = (typ, id) => {
        setShowCreateGroup(false);
        if (typ == true) {
            var tmp = itemsFiltered.filter(x => x.selected == true);
            var array = [];
            tmp.forEach(item => {
                array.push(item.id);
            });
            db_products_add(id, array);
        }
    }

    const FilterMenuResult = (id, index, item) => {
        var filter = GetFilter();

        filter.parent_id = 0;
        filter.enabled = true;

        setItemSelected(false);


        if (index == 1) {
            // LEN NULOVE STAVY

            delete (filter.stock_zero);
            delete (filter.stock_smaller);
            delete (filter.stock_bigger);

            setShowZero(true);
            setShowSmaller(false);
            setShowBigger(false);
            filter.stock_zero = true;
        }

        if (index == 2) {
            // LEN STAVY MENSIE AKO

            delete (filter.stock_zero);
            delete (filter.stock_bigger);

            setShowZero(false);
            setShowBigger(false);
            setShowSmallerQuantity(true);
        }

        if (index == 4) {
            // LEN STAVY VACSIE AKO

            delete (filter.stock_zero);
            delete (filter.stock_smaller);

            setShowZero(false);
            setShowSmaller(false);
            setShowBiggerQuantity(true);
        }

        if (index == 3) {
            // NEAKTIVNE
            delete (filter.disabled);

            setShowDisabled(true);
            delete (filter.enabled);
            filter.disabled = true;
        }

        if (index == 5) {
            // STRAZENE
            delete (filter.not_guarded);
            delete (filter.parent_id);

            setShowGuarded(true);
            setShowNotGuarded(false);
            filter.guarded = true;
        }

        if (index == 6) {
            // NESTRAZENE
            delete (filter.guarded);
            delete (filter.parent_id);

            setShowGuarded(false);
            setShowNotGuarded(true);
            filter.not_guarded = true;
        }

        db_get(0, filter, maxItems);
    }


    const ChangeStock = (id) => {
        setStockType(id);
        setItemSelected(0);
        var filter = GetFilter();
        filter.stock = id;
        db_get(0, filter, maxItems);
    }


    const SmallerResult = (typ, value) => {
        setShowSmallerQuantity(false);
        if (typ == true) {
            setShowSmaller(true);
            setShowSmallerValue(parseInt(value));

            let filter = GetFilter();
            filter.stock_smaller = parseInt(value);

            db_get(0, filter, maxItems);
        }

    }

    const BiggerResult = (typ, value) => {
        setShowBiggerQuantity(false);
        if (typ == true) {
            setShowBigger(true);
            setShowBiggerValue(parseInt(value));

            let filter = GetFilter();
            filter.stock_bigger = parseInt(value);

            db_get(0, filter, maxItems);
        }
    }


    const EditStock = (stock, item) => {
        setStockSelected(stock);
        setItemSelected(item);

        Debug(stock);
        Debug(item);
        setShowAdjust(true);
    }

    const AdjustResult = (redraw_) => {
        setShowAdjust(false);
        if (redraw_ == true) {
            db_stock_status(itemSelected.stock_uid);
            db_get(page, filter, maxItems);
        }
    }

    const StockCard = (item) => {
        setItemSelected(item);
        setShowStockCard(true);
    }

    const StockCardClose = (redraw) => {
        setShowStockCard(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }


    const ChangeStyle = (value) => {
        setItems(false);
        setItemsFiltered(false);
        setItemSelected(false);
        setDisplayStyle(value);

        if (value == 0) {
            filter.parent_id = 0;
        } else {
            delete (filter.parent_id);
        }

        SaveValue('warehouse-style', value);
        db_get(0, filter, maxItems);
    }

    const SortUp = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.up;
        filter.order_column = data.colDef.field;
        db_get(0, filter, maxItems);
    }

    const SortDown = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.down;
        filter.order_column = data.colDef.field;
        db_get(0, filter, maxItems);
    }

    function MyMenuItem(props) {
        const { menuHandler, menuLabel, menuIcon } = props;
        return (
            <MenuItem onClick={menuHandler}>
                <ListItemIcon>
                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={menuIcon} />
                </ListItemIcon>
                <ListItemText>{menuLabel}</ListItemText>
            </MenuItem>
        );
    }

    function CustomColumnMenu(props) {
        // VLASTNÁ COLUMN MENU
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuSortItem: null,
                    columnMenuFilterItem: null,
                    columnMenuUserItem1: props.colDef.sorting == true ? MyMenuItem : null,
                    columnMenuUserItem2: props.colDef.sorting == true ? MyMenuItem : null,
                }}
                slotProps={{
                    columnMenuUserItem1: {
                        displayOrder: 1,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowUp,
                        menuHandler: () => SortUp(props),
                    },
                    columnMenuUserItem2: {
                        displayOrder: 0,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowDown,
                        menuHandler: () => SortDown(props),
                    },
                }}
            />
        );
    }

    const ChangeColumns = (data) => {
        // ZOBRAZIT / SKRYT STLPCE -> uloženie do cookies
        setColumnVisibilityModel(data);
        SaveArray('warehouse-table', data);
    }

    /*
    *
    * =================================================================================================================
    * PRODUCT - RODIČ
    * =================================================================================================================
    *
    */

    const ProductRow = (rowProps) => {
        let item = rowProps.item;
        return (
            <div id={'product-id-' + item.id} key={item.id} style={{ ...styles.Block }}>
                <Paper
                    key={item.id}
                    elevation={item.id == overID ? 4 : 1}
                    style={{
                        ...styles.Block,
                        backgroundColor: item.enabled == false ? color.disabled : item.grouped == true ? color.grouped : item.variations == true ? color.variation : color.white,
                        borderRadius: global.items_radius,
                        minHeight: rowHeight,
                        cursor: 'pointer',
                        marginTop: 5, marginBottom: 5,
                        paddingTop: 2, paddingBottom: 2,
                        borderLeft: item.enabled == false ? color.disabled_border : item.variations == true ? color.border_variation : item.grouped == true ? color.border_grouped : '',
                    }}
                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                >
                    <div style={{ ...styles.BlockRow, width: '100%' }}>
                        <div style={{ ...styles.BlockLeft, width: 40, height: rowHeight, justifyContent: 'center' }}>
                            {/* SELECT */}
                            <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                        </div>

                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '95%' }}>
                            {/* DATA */}
                            <div style={{ ...styles.BlockLeft, width: rowHeight - 25, minHeight: rowHeight, justifyContent: 'center' }}>
                                <Avatar picture image={item.image} size={rowHeight - 40} theme={props.theme} />
                            </div>

                            <div style={{ ...styles.BlockLeft, width: '70%', minHeight: rowHeight, justifyContent: 'center' }}>
                                {item.parent_name != '' ?
                                    <p style={{ ...styles.TextXTiny }}>{item.parent_name}</p>
                                    : null}
                                <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name}</p>
                                <Line marginTop={4} theme={props.theme} />
                                <div style={{ ...styles.BlockRowRaw, marginTop: 4 }}>
                                    {item.variations == false || item.parent_id > 0 ?
                                        <div style={{ ...styles.Block, width: undefined, height: 24 }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.code}</p>
                                        </div>
                                        : null}
                                    {item.category != '' && item.category != undefined ?
                                        <div style={{ ...styles.Block, width: undefined, height: 24, marginLeft: 10 }}>
                                            <Chip variant='outlined' size='small' style={{ borderColor: color.dark_gray, height: 18 }} label={<p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.category}</p>}></Chip>
                                        </div>
                                        : null}
                                    {item.manufacture != '' ?
                                        <div style={{ ...styles.Block, width: undefined, height: 24 }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{item.manufacture}</p>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            <div style={{ ...styles.Block, width: 140, minHeight: rowHeight, justifyContent: 'center' }}>
                                {item.stockable == true && item.automat == true ?
                                    <Chip variant='outlined' size='small' label={item.quantity_min + '/' + item.quantity_max}></Chip>
                                    : null}
                            </div>
                            <div style={{ ...styles.Block, width: 120, minHeight: rowHeight, justifyContent: 'center' }}>
                                {item.variations == false ?
                                    <p style={{ ...styles.TextTiny }}>{FormatPieces(item.quantity_reserved)} {item.unit}</p>
                                    : null}
                            </div>
                            <div style={{ ...styles.Block, width: 120, minHeight: rowHeight, justifyContent: 'center' }}>
                                {item.variations == false ?
                                    <p style={{ ...styles.TextTiny }}>{FormatPieces(item.stock_quantity)} {item.unit}</p>
                                    : null}
                            </div>
                        </div>
                    </div>
                    {itemSelected != false ? previewMode == true && itemSelected.id == item.id && itemSelected.variations == false ?
                        <div style={{ ...styles.Block, paddingBottom: 5 }}>
                            <SubView item={item} />
                        </div>
                        : null : null}
                </Paper>
                <div style={{ ...styles.BlockRight, marginBottom: item.variants != false && showVariations == true ? 30 : 0 }}>
                    {showVariations == true ? item.variants != false ? item.variants.map((variant) => (
                        <VariantRow key={variant.id} item={variant} show_variant={false} />
                    )) : null : null}
                </div>
            </div>
        );
    }


    /*
    *
    * =================================================================================================================
    * SKLAD - náhľad
    * =================================================================================================================
    *
    */
    const SubView = (subProps) => {
        let item = subProps.item;

        return (
            <div style={{ ...styles.Block, paddingTop: 5, borderTop: color.border }}>
                <div style={{ ...styles.BlockRow, width: '92%' }}>

                    <div style={{ ...styles.BlockLeft, width: '96%' }}>
                        {subProps.table == undefined ?
                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.stocks_status}</p>
                            : null}
                        {isLoading == true ?
                            <div style={{ ...styles.Block, paddingTop: 10 }}>
                                <Box sx={{ width: '90%' }}>
                                    <LinearProgress />
                                </Box>
                            </div>
                            : null}

                        <div style={{ ...styles.BlockRow, flexWrap: 'wrap', paddingTop: 5, paddingBottom: 5 }}>
                            {item.stock_uid > 0 ? stockStatus != false ? stockStatus.map((stock, index) => (
                                <StockStatus key={stock.id} stock={stock} product={item} allowed={CheckPermission(props.user.super_admin, props.permissions[permission_column]).editing == true ? true : false} theme={props.theme} func={EditStock.bind(this)} />
                            )) : null :
                                <p style={{ ...styles.TextXSmall }}>{lang.stock_card_none}</p>
                            }
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRight, width: 200 }}>
                        {CheckPermission(props.user.super_admin, props.permissions['stock_cards']).read == true ?
                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                <Button onClick={() => StockCard(subProps.item)} style={{ ...styles.ButtonOutlined, color: color.black }}>{lang.stock_card}</Button>
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        );
    }

    /*
    *
    * =================================================================================================================
    * VARIANT
    * =================================================================================================================
    *
    */

    const VariantRow = (rowProps) => {
        let variant = rowProps.item;
        return (
            <div id={'product-id-' + variant.id} style={{ ...styles.BlockRight }}>
                <Paper
                    key={variant.id}
                    elevation={variant.id == overID ? 4 : 1}
                    style={{
                        ...styles.Block,
                        width: '97%',
                        backgroundColor: variant.enabled == false ? color.disabled : color.variation,
                        borderRadius: global.items_radius,
                        minHeight: rowVariantHeight,
                        cursor: 'pointer',
                        marginTop: 5, marginBottom: 5,
                        paddingTop: 2, paddingBottom: 2,
                        borderLeft: variant.enabled == false ? color.disabled_border : '',
                    }}
                    onMouseOverCapture={() => setOverID(variant.id)} onMouseLeave={() => setOverID(-1)}
                >
                    <div onClick={() => SelectItem(variant)} style={{ ...styles.BlockRow, width: '98%' }}>
                        <div style={{ ...styles.BlockLeft, width: rowHeight - 25, height: rowVariantHeight, justifyContent: 'center' }}>
                            <Avatar picture image={variant.image} size={rowVariantHeight - 5} theme={props.theme} />
                        </div>
                        <div style={{ ...styles.BlockLeft, width: '80%', height: rowVariantHeight }}>
                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{variant.name} { }</p>
                            <p style={{ ...styles.TextXTiny }}>{variant.code}</p>
                        </div>
                        <div style={{ ...styles.Block, width: 140, minHeight: rowHeight, justifyContent: 'center' }}>
                            {variant.stockable == true && variant.automat == true ?
                                <Chip variant='outlined' size='small' label={variant.quantity_min + '/' + variant.quantity_max}></Chip>
                                : null}
                        </div>
                        <div style={{ ...styles.Block, width: 120, minHeight: rowVariantHeight }}>
                            <p style={{ ...styles.TextTiny }}>{FormatPieces(variant.quantity_reserved)} {variant.unit}</p>
                        </div>
                        <div style={{ ...styles.Block, width: 120, height: rowVariantHeight }}>
                            <p style={{ ...styles.TextXSmall }}>{FormatPieces(variant.stock_quantity)} {variant.unit}</p>
                        </div>
                    </div>

                    {itemSelected != false ? previewMode == true && itemSelected.id == variant.id ?
                        <SubView item={variant} />
                        : null : null}

                </Paper>
            </div>
        )
    }

    /*
    *
    * =================================================================================================================
    * MAIN WINDOW
    * =================================================================================================================
    *
    */

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 270, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.widow_bar_height }}>
                    <ButtonChangeStyle value={displayStyle} theme={props.theme} func={(id) => ChangeStyle(id)} />
                </div>

                <div style={{ ...styles.Block, width: 50, height: global.widow_bar_height, marginRight: 20 }}>
                    {global.appstore == false ?
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                        : null}
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.Block, maxWidth: displayStyle == 0 ? maxWidth : '98%', width: '100%', marginTop: 10 }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            {filter.id == undefined ?
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '30%', height: menuHeight }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.Block, width: 30, paddingTop: 3, marginRight: 5 }}>
                                                <MenuButton id={0} icon={faFilter} backgroundColor={'#00000000'} color={color.dark_gray} label={lang.user_new} menuItems={filterMenu} theme={props.theme} func={FilterMenuResult.bind()} />
                                            </div>
                                            <div style={{ ...styles.Block, width: '65%' }}>
                                                <TextInput search theme={props.theme} width={'100%'} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                                <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                        <div style={{ ...styles.BlockRowRaw, height: menuHeight }}>
                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight }}>
                                                <Button onClick={() => setShowCategorySelect(true)} style={{ ...styles.ButtonOutlined, width: 120, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faFolderTree} />
                                                    {lang.product_category}
                                                </Button>
                                            </div>
                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                <Button onClick={() => setShowGroup(true)} style={{ ...styles.ButtonOutlined, width: 120, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faLayerGroup} />
                                                    {lang.product_group}
                                                </Button>
                                            </div>
                                            {props.system.search_manufacturers == true ?
                                                <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                    <Button onClick={() => setShowManufactureSelect(true)} style={{ ...styles.ButtonOutlined, width: 120, fontSize: global.font_tiny }}>
                                                        <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                                        {lang.manufacturer}
                                                    </Button>
                                                </div>
                                                : null}
                                            {/*
                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                <Button onClick={() => setShowVendorSelect(true)} style={{ ...styles.ButtonOutlined, width: 120, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                                    {lang.vendor}
                                                </Button>
                                            </div>
                                            */}
                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                <Button onClick={() => setShowSelectStock(true)} style={{ ...styles.ButtonOutlined, width: 120, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faBoxesStacked} />
                                                    {lang.stock}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '20%', height: menuHeight }}>
                                        {stocks != false && stockID == 0 ? stocks.length > 1 ?
                                            <SelectInput redraw theme={props.theme} enabled={true} lang={lang} value={stockType} items={stock_types} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => ChangeStock(id)} />
                                            : null : null}
                                    </div>
                                </div>
                                :
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '25%', height: menuHeight }}>
                                        {filter.id > 0 ?
                                            <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.new_record}</p>} onDelete={() => NewRecordReset()}></Chip>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '55%', height: menuHeight }}>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '20%', height: menuHeight }}>

                                    </div>
                                </div>
                            }

                            <div style={{ ...styles.BlockLeft, height: chipHeight, justifyContent: 'flex-start' }}>
                                <div style={{ ...true.BlockRowRaw }}>
                                    {showDisabled == true ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.products_filter_unabled}</p>} onDelete={() => DisabledReset(0)}></Chip>
                                        : null}
                                    {showZero == true || showSmaller == true || showBigger == true ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.cancel_filters}: {lang.status.toLowerCase()}{showZero == true ? '=' : showSmaller == true ? '<' : '>'}{showZero == true ? '0' : showSmaller == true ? showSmallerValue : showBiggerValue}</p>} onDelete={() => FilterReset()}></Chip>
                                        : null}
                                    {showRange != false ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.stock_range} {showRange.from}-{showRange.to}</p>} onDelete={() => RangeReset()}></Chip>
                                        : null}
                                    {showGuarded == true ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.stock_guarded_}</p>} onDelete={() => FilterGuardedReset()}></Chip>
                                        : null}
                                    {showNotGuarded == true ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.stock_not_guarded_}</p>} onDelete={() => FilterGuardedReset()}></Chip>
                                        : null}
                                    {categoryID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{category}</p>} onDelete={() => CategoryReset(0)}></Chip>
                                        : null}
                                    {groupID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{group}</p>} onDelete={() => ResetGroup(0)}></Chip>
                                        : null}
                                    {manufactureID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{manufacture}</p>} onDelete={() => ResetManufacture(0)}></Chip>
                                        : null}
                                    {vendorID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{vendor}</p>} onDelete={() => ResetVendor(0)}></Chip>
                                        : null}
                                    {stockID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.stock}: {stock}</p>} onDelete={() => ResetStock(0)}></Chip>
                                        : null}
                                </div>
                            </div>

                            {displayStyle == 0 ?
                                <div style={{ ...styles.Block }}>

                                    {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}

                                    <div style={{ ...styles.Block, marginBottom: 10, border: color.border }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.Block, width: 40, height: tableLabelHeight, justifyContent: 'center' }}>
                                                {/* SELECT */}
                                                <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '95%' }}>
                                                {/* DATA */}
                                                <div style={{ ...styles.BlockLeft, width: rowHeight - 25, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: '70%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering2} theme={props.theme} func={MenuSortResult2.bind()} />
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.Block, width: 140, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.watch_dog}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 120, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product_quantity_reserved_}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: 120, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product_quantity_stock_}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ****************************************************************************************************************
                                        PRODUKTY DATA
                                **************************************************************************************************************** */}

                                    <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - chipHeight - tableLabelHeight - 40, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div id={'id_start'} style={{ ...styles.Block }}></div>

                                        <div style={{ ...styles.BlockCenter, width: '99%' }}>
                                            {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                                <ProductRow key={item.id} item={item} />
                                            )) :
                                                <EmptyList row small lang={lang} />
                                            }

                                            {/* ****************************************************************************************************************
                                        PAGINATION
                                    **************************************************************************************************************** */}

                                            <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                                <div style={{ ...styles.Block, width: 200 }}></div>
                                                <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                                    {pages > 1 ?
                                                        <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.Block, width: 200 }}>
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                            <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                        </div>
                                                        <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                            <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                : null}
                            {displayStyle == 1 ?
                                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - chipHeight - 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <Box sx={{
                                        height: previewMode == true ? props.height - global.widow_bar_height - menuHeight - chipHeight - 84 - previewHeight : props.height - global.widow_bar_height - menuHeight - chipHeight - 84,
                                        width: 1,
                                        /*
                                        '& .super-app-theme--header': {
                                            backgroundColor: color.light_gray,
                                        },
                                        */
                                        '& .super-app-theme--normal': {
                                            backgroundColor: color.white,
                                            color: '#000000',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.table_row),
                                            },
                                        },
                                        '& .super-app-theme--red': {
                                            backgroundColor: color.lighteen_blue,
                                            color: color.dark_red,
                                            fontWeight: 'normal',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.gray),
                                            },
                                        },
                                        '& .super-app-theme--blue': {
                                            backgroundColor: color.white,
                                            color: color.blue,
                                            fontWeight: 'normal',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.gray),
                                            },
                                        },
                                    }}>
                                        <DataGrid

                                            sx={{
                                                "& .MuiDataGrid-columnHeaderTitle": {
                                                    whiteSpace: "normal",
                                                    lineHeight: "normal"
                                                },
                                                "& .MuiDataGrid-columnHeader": {
                                                    height: "unset !important"
                                                },
                                                "& .MuiDataGrid-columnHeaders": {
                                                    maxHeight: "168px !important"
                                                },
                                                fontSize: global.font_table, border: global.theme_table_border_width, borderColor: color.dark_gray
                                            }}

                                            rows={itemsFiltered == false ? [] : itemsFiltered}
                                            columns={columns.filter(x => x.hide == false)}
                                            slots={{ columnMenu: CustomColumnMenu }}
                                            rowHeight={previewMode == true ? 30 : undefined}

                                            checkboxSelection
                                            disableRowSelectionOnClick
                                            onRowSelectionModelChange={(newRowSelectionModel) => { setItemsSelected(newRowSelectionModel) }}
                                            onRowClick={(params) => { SelectItem(params.row) }}
                                            //onRowDoubleClick={(params) => { SelectItem(params.row) }}
                                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                            getRowClassName={(params) => (params.row.enabled == 1 ? `super-app-theme--normal` : `super-app-theme--red`)}
                                            hideFooterRowCount={true}
                                            hideFooterSelectedRowCount={true}
                                            hideFooterPagination
                                            hideFooter
                                            columnVisibilityModel={columnVisibilityModel}
                                            onColumnVisibilityModelChange={(newModel) =>
                                                ChangeColumns(newModel)
                                            }
                                        />
                                    </Box>

                                    {previewMode == true && itemSelected != false ?
                                        <div style={{ ...styles.Block, height: previewHeight, marginTop: 5 }}>
                                            <div style={{ ...styles.BlockRow, width: '100%' }}>
                                                <div style={{ ...styles.BlockLeft, width: previewHeight, height: previewHeight, justifyContent: 'center' }}>
                                                    <Avatar picture image={itemSelected.image} size={previewHeight - 10} theme={props.theme} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '90%', height: previewHeight, justifyContent: 'flex-start', overflowY: 'scroll' }}>
                                                    <SubView item={itemSelected} table />
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                        **************************************************************************************************************** */}
                                    <div style={{ ...styles.BlockRow, paddingTop: 10, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                : null}

                        </div>
                    </div>
                </div>
            </div>

            {showStockCard == true ?
                <WarehouseStockCard item={itemSelected} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={StockCardClose.bind()} />
                : null}

            {showAdjust == true ?
                <ProductAdjust stock={stockSelected} product={itemSelected} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={AdjustResult.bind()} />
                : null}

            {showCreateGroup == true ?
                <ProductsGroup index={0} module_id={global.model.products_groups} item={false} organization={props.organization} locale={props.locale} s system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CreateGroupResult.bind()} />
                : null}

            {showBatch == true ?
                <BatchUpdate model_id={global.model.products} organization={props.organization} locale={props.locale} suser={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={BatchResult.bind()} />
                : null}

            {showCategorySelect == true ?
                <ProductCategorySelect module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} s user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CategoryResult.bind()} />
                : null}

            {showManufactureSelect == true ?
                <ManufacturerSelect module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} s user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ManufactureResult.bind()} />
                : null}

            {showVendorSelect == true ?
                <VendorSelect module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} s user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={VendorResult.bind()} />
                : null}

            {showSelectStock == true ?
                <StockSelect module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} s user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={StockResult.bind()} />
                : null}

            {showGroup == true ?
                <ProductsGroupSelect organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={GroupResult.bind()} />
                : null}

            {showExport == true ?
                <DialogExport columns={exportColumns} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showExportError == true ?
                <ShowInfo text={lang.export_none_error} sub_text={lang.export_none_error_} lang={lang} theme={props.theme} func={() => setShowExportError(false)} />
                : null}

            {showGroupError == true ?
                <ShowInfo text={lang.product_group_create_error} sub_text={''} lang={lang} theme={props.theme} func={() => setShowGroupError(false)} />
                : null}

            {showSmallerQuantity == true ?
                <DialogEnterNumber radius={props.radius} title={lang.stock_smaller_status_label} value={0} min={0} icon={faQuestion} button={lang.ok} lang={props.lang} theme={props.theme} func={SmallerResult.bind(this)} />
                : null}

            {showBiggerQuantity == true ?
                <DialogEnterNumber radius={props.radius} title={lang.stock_bigger_status_label} value={0} min={0} icon={faQuestion} button={lang.ok} lang={props.lang} theme={props.theme} func={BiggerResult.bind(this)} />
                : null}


            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


export const WarehouseStockCard = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [value, setValue] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    const buttonHeight = 70;
    const errorHeight = 0;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            running = true;
        }

    }, []);


    const Close = () => {
        func(redrawRequired);
    }

    const StockHistoryResult = (redraw) => {
        if (redraw == true) {
            setRedrawRequired(true);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 800 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item.name}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        <StockHistory showNew showSelect stock_uid={props.item.stock_uid} width={dialogWidth} height={dialogHeight - global.dialog_bar_height - buttonHeight} organization={props.organization} locale={props.locale} system={props.system} user={props.user} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={StockHistoryResult.bind(this)} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, marginLeft: 10 }}>{lang.close}</Button>
                        </div>
                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}