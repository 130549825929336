import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import { Loading, ShowError, ShowOK, Icon } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faFolderOpen, faImage, faImages, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@mui/material';

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Debug, GetLeftPosition } from './functions';
import { FileSelect } from './files';

export const Photos = (props) => {
    /*
    USAGE: 
            UPLOAD fotografie + cropping

            <Photos autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />


            PARAMS:
            --------------------------------------------------------------------------------------------------------------------
            autoSelect  => spustí ihneď výber fotografie
            full        => ak je TRUE - nastaví sa orez na plnú veľkosť fotografie
            max_size    => fotografia po zmensení (rozmer najdlhsej strany)
            width       => požadovaná šírka fotografie
            height      => požadovaná výška fotografie
            typ         => typy, ktore oznacuju cestu, do ktorého adresara budú fotografie nahraté na servery
                            0 - avatar
                            1 - images
                            2 - products
                            3 - services
                            4 - galéria fotografii na karte zákazníka
                            5 - email
            full        => fotografia / pdf bude skopírované bez zmeny


            RESULT:
            --------------------------------------------------------------------------------------------------------------------
            const PhotoResult = (typ, path, file) => {
                if(typ==true){

                }
            }

    */
    let color = global.themes[props.theme];

    const logo = require('./react/app/no_image.jpg');

    const [isBusy, setBusy] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [filename, setFilename] = useState('');

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [remoteFile, setRemoteFile] = useState(true);

    const [crop, setCrop] = useState();
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    // ZOBRAZIT KNIZNICU
    const [showLibrary, setShowLibrary] = useState(false);

    let { func } = props;
    var lang = props.lang;

    const imageMul = isSmall ? 1.0 : 1.5; // nasobenie width fotografie (ovplyvňuje veľkosť okna)
    const imageSize = isSmall ? 300 : 420;
    const dialogWidth = (imageSize * imageMul) + 50;

    const inputRef = useRef(null);

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            if (props.autoSelect == true) {
                //inputRef.current.click();
            }
        }

    }, []);

    const image_upload = async () => {

        setBusy(true);
        var coef = 0;
        if (height >= width) {
            coef = height / imageSize;
        } else {
            coef = width / (imageSize * imageMul)
        }

        const data = new FormData();
        data.append('typ', props.typ);

        data.append('token', props.token);
        data.append('organization_id', props.organization.id);
        data.append('user_id', props.user.id);

        data.append('crop_x', crop.x);
        data.append('crop_y', crop.y);
        data.append('crop_w', crop.width);
        data.append('crop_h', crop.height);
        data.append('width', width);
        data.append('height', height);
        data.append('coef', coef);
        data.append('max_size', props.max_size);
        data.append('full', props.full == true ? 1 : 0);
        data.append('filename', filename);
        data.append("name", "image");
        data.append("file_attachment", selectedImage);
        data.append("file_url", selectedImage);
        data.append("remote_file", remoteFile == false ? 0 : 1);

        try {
            const response = await fetch(
                global.db_url + 'upload_image', {
                method: 'POST',
                body: data
            }
            );
            const json = await response.json();
            setBusy(false);
            Debug('UPLOAD IMAGE');
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                var timer = setTimeout(() => {
                    Press(true, json.path, json.filename);
                }, 1500);
            } else {
                setShowError(true);
            }
            return;

        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    };

    const Press = (typ, path, filename) => {
        func(typ, path, filename);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeCrop = (c) => {
        setCrop(c);

    }

    function PhotoSelect(e) {
        // FOTOGRAFIE - VYBER SUBOROV A ODOSLANIE NA SERVER           
        var file = e.target.files[0];
        var filename = file.name.toLowerCase();

        // ROZMERY FOTOGRAFIE
        var _URL = window.URL || window.webkitURL;
        var img_link = URL.createObjectURL(file);

        const img = new Image();
        var w = 0;
        var h = 0;
        img.onload = function () {
            GetSize(this.width, this.height);
            _URL.revokeObjectURL(img_link);
        };
        img.src = img_link;

        setRemoteFile(false);
        setSelectedImage(file);
        setFilename(filename);
    }

    function PhotoDelete() {
        setSelectedImage(null);
        setRemoteFile(false);
        if (props.full == true) {
            setCrop({
                unit: '%',
                x: 0,
                y: 0,
                width: 100,
                height: 100
            });
        } else {
            setCrop({
                unit: 'px',
                x: 25,
                y: 25,
                width: 50,
                height: 50
            });
        }
    }

    const GetSize = (w, h) => {
        setWidth(w);
        setHeight(h);

        // VYPOCET NAJKRATSEJ DLZKY A NASTAVENIE CROPPINGU
        var min_size = h;
        if (h > w) {
            min_size = w;
        }

        if (h >= w) {
            var coef = h / imageSize;
        } else {
            var coef = w / (imageSize * imageMul);
        }
        min_size = (min_size / coef) - 0;  // ZMENENE Z 20 NA 0

        if (props.full == true) {
            setCrop({
                unit: 'px',
                x: 0,
                y: 0,
                width: w / coef,
                height: h / coef
            });
        } else {
            if (props.width == props.height) {
                setCrop({
                    unit: 'px',
                    x: 0, // ZMENENE Z 10 NA 0
                    y: 0, // ZMENENE Z 10 NA 0
                    width: min_size,
                    height: min_size
                });
            } else {
                setCrop({
                    unit: 'px',
                    x: 0,
                    y: 0,
                    width: h > w ? (w / coef) : (h / coef),
                    height: h > w ? (w / coef) * (props.height / props.width) : (h / coef) * (props.height / props.width)
                });

            }
        }
    }

    const PhotoSave = () => {
        image_upload();
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ImageDims = (url) => {
        var _URL = window.URL || window.webkitURL;
        var img_link = global.web + '/' + url;

        const img = new Image();
        img.src = img_link;

        img.onload = function () {
            GetSize(this.width, this.height);
            _URL.revokeObjectURL(img_link);
        };
    }

    const SelectResult = (value, link) => {
        setShowLibrary(false);
        if (value == true) {
            setSelectedImage(link);
            ImageDims(link);
            setRemoteFile(true);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(screenWidth), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 9999 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, background: color.theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faImage} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, height: 40 }}>
                        {props.width == undefined ?
                            <p style={{ ...styles.TextSmall }}>{lang.photo_select_title}</p>
                            :
                            <p style={{ ...styles.TextSmall }}>{lang.photo_select_title} <span style={{ ...styles.TextTiny }}>{props.full == true ? '' : '(' + lang.photo_min + ': ' + props.width + 'x' + props.height + ' px)'}</span></p>
                        }

                    </div>
                    <div style={{ ...styles.Block, height: imageSize + 50 }}>
                        {selectedImage != null && selectedImage != undefined ?
                            props.full == true ?
                                <img
                                    src={remoteFile == true ? global.web + '/' + selectedImage : URL.createObjectURL(selectedImage)}
                                    style={{ maxWidth: '100%', width: height < width ? imageSize * imageMul : undefined, height: height >= width ? imageSize : undefined, objectFit: 'contain' }}
                                    alt="Thumb"
                                />
                                :
                                <ReactCrop crop={crop} onChange={c => ChangeCrop(c)} aspect={props.width / props.height} >
                                    <img
                                        src={remoteFile == true ? global.web + '/' + selectedImage : URL.createObjectURL(selectedImage)}
                                        style={{ maxWidth: '100%', width: height < width ? imageSize * imageMul : undefined, height: height >= width ? imageSize : undefined, objectFit: 'contain' }}
                                        alt="Thumb"
                                    />
                                </ReactCrop>
                            : <div style={{ ...styles.Block, height: imageSize, width: imageSize, border: '1px solid ' + color.light_gray }}>
                                <img src={logo} style={{ width: '100%', height: '100%', maxWidth: 200, maxHeight: 200, marginTop: 10, objectFit: 'contain' }}></img>
                            </div>}
                    </div>
                    <div style={{ ...styles.Block, maxWidth: 500, marginTop: 20, height: isSmall ? 110 : 80 }}>
                        {selectedImage != null && selectedImage != undefined ?
                            // ULOZIT / VYMAZAŤ
                            <div style={{ ...isSmall == true ? styles.Block : styles.BlockRowRaw }}>

                                <Button onClick={() => PhotoSave()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_dark, width: 180 }}>
                                    {lang.photo_save}
                                </Button>
                                <div style={{ marginTop: isSmall ? 10 : 0, marginLeft: isSmall ? 0 : 20 }}>
                                    <Button onClick={() => PhotoDelete()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, color: color.black, width: 180 }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faXmarkCircle} />
                                        {lang.photo_delete}
                                    </Button>
                                </div>
                            </div>
                            :
                            // VYBRAT FOTOGRAFIU
                            <div style={{ ...isSmall == true ? styles.Block : styles.BlockRowRaw }}>
                                {props.organization.cloud_enabled == true ?
                                    <Button onClick={() => setShowLibrary(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_dark, width: 210, marginRight: 20 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 10 }} icon={faFolderOpen} />
                                        <p style={{ ...styles.TextTiny, color: color.white }}>{lang.file_library_select}</p>
                                    </Button>
                                    : null}
                                <Button variant="contained" component="label" style={{ ...styles.ButtonThemed, backgroundColor: color.button_dark, width: 210 }}>
                                    <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 10 }} icon={faImage} />
                                    <p style={{ ...styles.TextTiny, color: color.white }}>{lang.file_photo_select}</p>
                                    <input ref={inputRef} onInput={(e) => PhotoSelect(e)} onChange={(e) => PhotoSelect(e)} hidden accept={props.full == true ? "image/jpeg,image/png,application/pdf" : "image/jpeg,image/png"} type="file" />
                                </Button>
                            </div>
                        }
                    </div>
                </div>

                {showLibrary == true ?
                    <FileSelect selecting images init_type={1} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.file_library_select_title} token={props.token} func={SelectResult.bind()} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.upload_image_error} sub_text={lang.upload_image_error_note} func={ShowErrorPress.bind(this)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset == undefined ? 0 : props.offset}></Loading>
                    : null}

            </Paper>
        </Backdrop>
    );
}


