/*
*
* =================================================================================================================
* ZAZAZNICKA KARTA

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Customer module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { DialogTabs, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, ShowError, ShowOK, TextInput, Logs, Avatar, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormSelect, FormNote, FormNodes, FormAutoComplete, ShowInfo, SubMenuButton, WebURLPreview, DeleteButton, TextXSmall, TextLine } from './items';
import { styles } from './styles';
import { Backdrop, Box, Button, Checkbox, Chip, IconButton, ListItemIcon, ListItemText, MenuItem, Pagination, Paper, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowPointer, faArrowUp, faAt, faBars, faCheck, faFile, faFilter, faFolderTree, faInfoCircle, faLayerGroup, faMagnifyingGlass, faPlus, faPlusCircle, faQuestion, faRotateRight, faTableCells, faUser, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, ConvertToPrice, ConvertToPrice2, Debug, GetLeftPosition, GetPages, IsEmpty, LoadArray, LoadBool, LoadValue, SaveArray, SaveBool, SaveValue } from './functions';
import { Photos } from './photos';
import { CustomersPerson } from './customers_person.js';
import { CustomersCategorySelect } from './customers_categories.js';
import { UsersSelect } from './users_select.js';
import { CustomersAddress } from './customers_address.js';
import { CustomersGroup, CustomersGroupSelect } from './customers_groups.js';
import {
    DataGrid, skSK,
    GridColumnMenu,
} from '@mui/x-data-grid';
import { Help } from './Help.js';
import { EmailEditor, EmailPreview } from './Editor.js';
import { GetDatumTime2 } from './functions_date.js';
import { Orders } from './orders.js';
import { Notes } from './notes.js';
import { StockRemovals } from './stock_removals.js';
import { CustomerSelect } from './customers.js';
import { Reklamacie } from './reklamacie.js';
import { Presentations } from './presentations.js';
import { QuizeResults, Quizes } from './quiz.js';


/*
*
* =================================================================================================================
* ZAKAZNICKA KARTA
* =================================================================================================================
*
*/
export const Customer = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let country_id = props.locale.id;
    let customer_code_searching = props.system.pharminfo_enabled == true || props.system.pharmos_enabled == true ? true : false;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [redrawEmails, setRedrawEmails] = useState(false);
    const [overID, setOverID] = useState(-1);

    // TABS
    const [tabIndex, setTabIndex] = useState(props.item == false ? 0 : 0);

    // DATA
    const [storeData, setStoreData] = useState({});
    const [storeAddressData1, setStoreAddressData1] = useState(false);
    const [storeAddressData2, setStoreAddressData2] = useState(false);

    const [customerID, setCustomerID] = useState(props.item == false ? 0 : props.item.id);
    const [countryID, setCountryID] = useState(props.locale.id);
    const [countries, setCountries] = useState(props.organization.currencies);
    const [b2b, setB2B] = useState(true);
    const [avatar, setAvatar] = useState('');
    const [agentID, setAgentID] = useState(0);
    const [agents, setAgents] = useState(false);
    const [categoryID, setCategoryID] = useState(0);
    const [erpCode, setErpCode] = useState('');
    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const [enabled, setEnabled] = useState(true);
    const [activated, setActivated] = useState(false);
    const [canBuy, setCanBuy] = useState(true);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [mobil, setMobil] = useState('');
    const [email, setEmail] = useState('');
    const [ico, setICO] = useState('');
    const [dic, setDIC] = useState('');
    const [icDPH, setICDPH] = useState('');
    const [dph, setDPH] = useState(false);
    const [bank, setBank] = useState('');
    const [iban, setIban] = useState('');
    const [swift, setSwift] = useState('');
    const [priceListID, setPriceListID] = useState(-1);
    const [credit, setCredit] = useState('');
    const [portalEnabled, setPortalEnabled] = useState(false);
    const [sendInvitation, setSendInvitation] = useState(false);
    const [askSendInvitation, setAskSendInvitation] = useState(false);
    const [note, setNote] = useState('');

    const [personSelected, setPersonSelected] = useState(false);
    const [addressSelected, setAddressSelected] = useState(false);
    const [categories, setCategories] = useState('');
    const [priceLists, setPriceLists] = useState(false);

    // FIREMNA ADRESA
    const [firmStreet, setFirmStreet] = useState('');
    const [firmTown, setFirmTown] = useState('');
    const [firmPSC, setFirmPSC] = useState('');
    const [firmState, setFirmState] = useState('');
    const [firmMobil, setFirmMobil] = useState('');
    const [firmEmail, setFirmEmail] = useState('');
    const [firmPhone, setFirmPhone] = useState('');
    const [firmNote, setFirmNote] = useState('');

    // NACITANIE KODU - ADC / SUKL
    const [codeID, setCodeID] = useState(0);
    const [showDistributorCodes, setShowDistributorCodes] = useState(false);

    // KONTAKTNE OSOBY
    const [persons, setPersons] = useState(false);

    // DODACIE ADRESY
    const [addresses, setAdresses] = useState(false);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // EMAILS
    const [showEmailEditor, setShowEmailEditor] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showPerson, setShowPerson] = useState(false);
    const [showAddress, setShowAddress] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1240 ? 1240 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS    
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 180;
    const column2 = 600 - column1;
    const rowHeight = 90;
    const tableLabelHeight = 40;
    const menuWidth = global.sub_menu_width;
    const barHeight = 110;

    /*
    const tabs = [
        { id: 5, enabled: true, label: lang.customer_overview },
        { id: 0, enabled: true, label: lang.customer_basic_info },
        { id: 1, enabled: true, label: lang.customer_firm_address },
        { id: 4, enabled: true, label: lang.customer_shipping_address },
        { id: 2, enabled: true, label: lang.customer_contact_person },
        { id: 3, enabled: true, label: lang.history },
    ];
    */

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            db_get_data(countryID);

            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: customerID
                })
            })

            const json = await response.json();
            Debug('CUSTOMER', 'yellow');
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);
                    AssignData(item);

                    if (json.address1 != false) {
                        setStoreAddressData1(json.address1);
                    }

                    if (json.address2 != false) {
                        setStoreAddressData2(json.address2);
                    }

                    AssignDataAddress(json.address1, json.address2);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_persons = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_persons', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    customer_id: customerID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setPersons(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_addresses = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_addresses', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    customer_id: customerID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setAdresses(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_address_default = async (id) => {
        //setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_address_default', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    customer_id: customerID,
                    id: id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_data = async (country_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    country_id: country_id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setCategories(json.categories);
                setAgents(json.agents);
                setPriceLists(json.price_list);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: customerID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                setEditing(false);
                if (customerID == 0) {
                    setCustomerID(json.item_id);
                    GoToStart();
                }
                setRedrawRequired(true)
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_address_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_address_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                setEditing(false);
                if (customerID == 0) {
                    setCustomerID(json.item_id);
                }
                setRedrawRequired(true)
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_send = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_send_invitation', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    customer_id: customerID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true)
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_check = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    email: email,
                    id: data.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.count == 0) {
                    db_update(data);
                } else {
                    setError(lang.customer_email_exists);
                    setBusy(false);
                }
            } else {
                setShowError(true);
                setBusy(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(redrawRequired);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setCountryID(item.country_id);
        setB2B(item.b2b == false ? false : true);
        setAvatar(item.avatar);
        setAgentID(item.agent_id);
        setCategoryID(item.category_id);
        //setErpCode(item.erp_code);
        setCode1(item.code_1);
        setCode2(item.code_2);
        setCode3(item.code_3);
        setCode4(item.code_4);
        setEnabled(item.enabled == false ? false : true);
        setActivated(item.activated == false ? false : true);
        setName(item.name);
        setSurname(item.surname);
        setCompany(item.company);
        setMobil(item.mobil);
        setPhone(item.phone);
        setEmail(item.email);
        setICO(item.ico);
        setDIC(item.dic);
        setICDPH(item.ic_dph);
        setDPH(item.dph == false ? false : true);
        setBank(item.bank);
        setIban(item.iban);
        setSwift(item.swift);
        setPriceListID(item.price_list_id);
        setCredit(item.credit);
        setPortalEnabled(item.portal_enabled == false ? false : true)
        setCanBuy(item.can_buy == false ? false : true)
        setNote(item.note);

    }

    const AssignDataAddress = (address1, address2) => {
        // priradenie údajov pri načítaní z DB
        if (address1 != false) {
            setFirmStreet(address1.street);
            setFirmTown(address1.town);
            setFirmPSC(address1.psc);
            setFirmState(address1.state);
            setFirmMobil(address1.mobil);
            setFirmPhone(address1.phone);
            setFirmEmail(address1.email);
        }

    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        if (tabIndex == 0) {
            AssignData(storeData);
        }

        if (tabIndex == 1) {
            AssignDataAddress(storeAddressData1, storeAddressData2);
        }
        setError('');
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.id = customerID;
        storeData.country_id = countryID;
        storeData.b2b = b2b;
        storeData.avatar = avatar;
        storeData.agent_id = agentID;
        storeData.category_id = categoryID;
        //storeData.erp_code = erpCode;
        //storeData.code_1 = code1;
        //storeData.code_2 = code2;
        //storeData.code_3 = code3;
        //storeData.code_4 = code4;
        storeData.enabled = enabled;
        storeData.name = name;
        storeData.surname = surname;
        storeData.company = company;
        storeData.phone = phone;
        storeData.mobil = mobil;
        storeData.email = email;
        storeData.ico = ico;
        storeData.div = dic;
        storeData.id_dph = icDPH;
        storeData.dph = dph;
        storeData.bank = bank;
        storeData.iban = iban;
        storeData.swift = swift;
        storeData.price_list_id = priceListID;
        storeData.credit = credit;
        storeData.portal_enabled = portalEnabled;
        storeData.can_buy = canBuy;
        storeData.note = note;

    }

    const StoreAddressEditing = () => {
        // Uloziť upravené údaje do pameti
        let data = {
            street: firmStreet,
            town: firmTown,
            psc: firmPSC,
            state: firmState,
            mobil: firmMobil,
            phone: firmPhone,
            email: firmEmail,
        }

        setStoreAddressData1(data);
    }

    const Save = () => {
        setError('');
        var error = false;

        if (tabIndex == 0) {
            // ZAKAZNIK
            if (IsEmpty(company) == true && IsEmpty(surname) == true) {
                error += 1;
            }
            if (priceListID == -1) {
                error += 1;
            }

            if (error == 0) {

                var action = global.logs.modified;
                var text = '';
                if (customerID == 0) {
                    action = global.logs.created;
                }
                if (storeData.enabled != enabled) {
                    if (enabled == true) {
                        action = global.logs.enabled;
                    } else {
                        action = global.logs.disabled;
                    }
                }
                if (storeData.company != company) {
                    action = global.logs.company_name;
                    text = storeData.company + ' -> ' + company;
                }

                let log = {
                    organization_id: props.organization.id,
                    user_id: props.user.id,
                    module_id: module_id,
                    item_id: customerID,
                    action: action,
                    text: text
                };

                let data = {
                    id: customerID,
                    organization_id: props.organization.id,
                    country_id: countryID,
                    user_id: props.user.id,
                    agent_id: agentID,
                    category_id: categoryID,
                    b2b: b2b,
                    //erp_code: erpCode,
                    //code_1: code1,
                    //code_2: code2,
                    //code_3: code3,
                    //code_4: code4,
                    enabled: enabled,
                    typ: 0,
                    language_id: props.organization.language_id,
                    name: name.trim(),
                    surname: surname.trim(),
                    company: company.trim(),
                    mobil: mobil,
                    phone: phone,
                    email: email,
                    ico: ico,
                    dic: dic,
                    ic_dph: icDPH,
                    dph: dph,
                    bank: bank,
                    iban: iban,
                    swift: swift,
                    price_list_id: priceListID,
                    credit: ConvertToPrice(credit, precisions),
                    portal_enabled: portalEnabled,
                    send_invitation: sendInvitation,
                    can_buy: canBuy,
                    avatar: avatar,
                    note: note,
                    logs: log
                };

                StoreEditing();

                db_check(data);

            } else {
                setError(lang.required_red);
            }
        }

        if (tabIndex == 1) {
            error += IsEmpty(firmStreet) == true ? 1 : 0;
            error += IsEmpty(firmTown) == true ? 1 : 0;
            error += IsEmpty(firmPSC) == true ? 1 : 0;

            if (error == 0) {
                // ADRESY
                let log = {
                    organization_id: props.organization.id,
                    user_id: props.user.id,
                    module_id: module_id,
                    item_id: customerID,
                    action: customerID == 0 ? global.logs.created : storeData.enabled != enabled ? enabled == true ? global.logs.enabled : global.logs.disabled : global.logs.modified
                };

                let data = {
                    organization_id: props.organization.id,
                    enabled: true,
                    typ: 0,
                    customer_id: customerID,
                    street: firmStreet,
                    town: firmTown,
                    psc: firmPSC,
                    state: firmState,
                    phone: firmPhone,
                    mobil: firmMobil,
                    email: firmEmail,
                    note: firmNote,
                    logs: log
                }

                StoreAddressEditing();
                db_address_update(data);
            } else {
                setError(lang.required_red);
            }
        }

    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 2) {
            if (logs == false) {
                db_addresses();
            }
        }

        if (id == 3) {
            if (logs == false) {
                db_persons();
            }
        }

        if (id == 99) {
            if (logs == false) {
                db_get_logs();
            }
        }
    }


    const AvatarPress = (value) => {
        if (value == true) {
            setSelectPhoto(true);
        } else {
            setAvatar('');
        }
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);
        }
    }

    const SendInvitationButton = (value) => {
        setAskSendInvitation(false);

        if (value == true && customerID > 0) {
            db_send();
        }
    }

    const PersonAdd = () => {
        setPersonSelected(false);
        setShowPerson(true);
    }

    const PersonEdit = (item) => {
        setPersonSelected(item);
        setShowPerson(true);
    }


    const PersonResult = (redraw_) => {
        setShowPerson(false);

        if (redraw_ == true) {
            db_persons();
        }
    }

    const AddressAdd = () => {
        setAddressSelected(false);
        setShowAddress(true);
    }

    const AddressEdit = (item) => {
        setAddressSelected(item);
        setShowAddress(true);
    }

    const AddressResult = (redraw_) => {
        setShowAddress(false);

        if (redraw_ == true) {
            db_addresses();
        }
    }

    const ChangeDefault = (item) => {
        addresses.forEach(element => {
            element.defaulted = false;
            if (element.id == item.id) {
                element.defaulted = true;
            }
        });

        setRedraw(!redraw);
        db_address_default(item.id);
    }

    const ChangeCountryID = (id) => {
        setCountryID(id);
        setPriceListID(-1);
        db_get_data(id);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_customer_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }


    const SendEmail = (typ) => {
        setShowEmailEditor(false);

        if (typ == true) {
            setRedrawEmails(!redrawEmails);
        }
    }

    const EmailsResult = () => {

    }

    const NotesResult = () => {

    }

    const OrdersResult = () => {

    }

    const ReklamacieResult = () => {

    }

    const StockRemovalsResult = () => {

    }

    const PresentationsResult = () => {

    }

    const ChangeCode = (id, value, button) => {
        if (button == true) {
            setCodeID(id);
            setShowDistributorCodes(true);
        } else {
            if (id == 1) {
                setCode1(value);
            }
            if (id == 2) {
                setCode2(value);
            }
            if (id == 3) {
                setCode3(value);
            }
            if (id == 4) {
                setCode4(value);
            }
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 200 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: barHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    {/*
                    <div style={{ ...styles.Block, width: 60, height: barHeight }}>
                        <Icon theme={props.theme} icon={faUser} />
                    </div>
                    */}
                    <div style={{ ...styles.Block, width: barHeight + 20, height: barHeight }}>
                        <Avatar editable={false} picture image={avatar} size={barHeight - 20} theme={props.theme} func={AvatarPress.bind()} />
                    </div>
                    <div style={{ ...styles.BlockCenter, width: dialogWidth - 140 - 200 - barHeight, height: barHeight }}>
                        {props.item == false ?
                            <div style={{ ...styles.BlockLeft, height: barHeight, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextDialogLabel }}>{lang.customer_new}</p>
                            </div>
                            :
                            storeData != false ?
                                <div style={{ ...styles.BlockLeft, height: barHeight, justifyContent: 'center' }}>
                                    {storeData.company != false ?
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{storeData.company}</p>
                                        : null}
                                    <p style={{ ...styles.TextXSmall }}>{storeData.name + ' ' + storeData.surname}</p>
                                    <FormSpace height={10}></FormSpace>
                                    {storeData.mobil != '' ?
                                        <p style={{ ...styles.TextXSmall }}>{lang.mobil + ': ' + storeData.mobil}</p>
                                        : null}
                                    {storeData.email != '' ?
                                        <p style={{ ...styles.TextXSmall }}>{lang.email + ': ' + storeData.email}</p>
                                        : null}
                                </div>
                                : null
                        }
                    </div>
                    <div style={{ ...styles.Block, width: 200, height: barHeight }}>
                        {customerID > 0 ? storeData != false ? storeData.email != '' ?
                            <Button onClick={() => setShowEmailEditor(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action_in_bar }}>
                                <FontAwesomeIcon style={{ height: 16, color: color.white }} icon={faAt} />
                                <p style={{ ...styles.TextTiny, color: color.white, marginLeft: 10 }}>{lang.customer_email_new}</p>
                            </Button>
                            : null : null : null}
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: barHeight }}>
                        <Help link={lang.help_main} lang={props.lang} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: barHeight }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - barHeight, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockCenter, height: dialogHeight - barHeight }}>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.BlockCenter, backgroundColor: '#e0e0e0', width: menuWidth, height: dialogHeight - barHeight, borderBottomLeftRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <SubMenuButton id={0} selected_id={tabIndex} width={menuWidth} label={lang.customer_basic_info} theme={props.theme} func={ChangeTab.bind(this)} />
                                {customerID > 0 ?
                                    <div>
                                        <SubMenuButton id={4} selected_id={tabIndex} width={menuWidth} label={lang.customer_notes} theme={props.theme} func={ChangeTab.bind(this)} />
                                        <FormSpace />
                                        <SubMenuButton id={6} selected_id={tabIndex} width={menuWidth} label={lang.orders} theme={props.theme} func={ChangeTab.bind(this)} />
                                        <SubMenuButton id={8} selected_id={tabIndex} width={menuWidth} label={lang.reklamacie} theme={props.theme} func={ChangeTab.bind(this)} />
                                        {global.appstore == false ?
                                            <SubMenuButton id={7} selected_id={tabIndex} width={menuWidth} label={lang.stock_removal} theme={props.theme} func={ChangeTab.bind(this)} />
                                            : null}
                                        <FormSpace />
                                        {global.appstore == false ?
                                            <SubMenuButton id={5} selected_id={tabIndex} width={menuWidth} label={lang.customer_emails} theme={props.theme} func={ChangeTab.bind(this)} />
                                            : null}
                                        {global.appstore == false ?
                                            <SubMenuButton id={9} selected_id={tabIndex} width={menuWidth} label={lang.presentations} theme={props.theme} func={ChangeTab.bind(this)} />
                                            : null}
                                        {global.appstore == false ?
                                            <SubMenuButton id={10} selected_id={tabIndex} width={menuWidth} label={lang.quizes} theme={props.theme} func={ChangeTab.bind(this)} />
                                            : null}
                                        <FormSpace />
                                        <SubMenuButton id={1} selected_id={tabIndex} width={menuWidth} label={lang.customer_firm_address} theme={props.theme} func={ChangeTab.bind(this)} />
                                        <SubMenuButton id={2} selected_id={tabIndex} width={menuWidth} label={lang.customer_shop_address_} theme={props.theme} func={ChangeTab.bind(this)} />
                                        <SubMenuButton id={3} selected_id={tabIndex} width={menuWidth} label={lang.customer_contact_person} theme={props.theme} func={ChangeTab.bind(this)} />
                                        <FormSpace />
                                        <SubMenuButton id={99} selected_id={tabIndex} width={menuWidth} label={lang.history} theme={props.theme} func={ChangeTab.bind(this)} />
                                    </div>
                                    : null}
                            </div>
                            <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, borderBottom: color.border, height: dialogHeight - global.dialog_bar_height - 1, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                {tabIndex == 0 ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * ZÁKLADNÉ ÚDAJE
                                    * =================================================================================================================
                                    *
                                    */
                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - barHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        {storeData != false ?
                                            <div style={{ ...styles.BlockCenter, paddingBottom: global.list_padding }}>
                                                <div id={'id_customer_start'} style={{ ...styles.Block }}></div>
                                                <div style={{ ...styles.Block, backgroundColor: color.white }}>
                                                    <FormSpace />
                                                    <Avatar editable={editing} picture image={avatar} size={75} theme={props.theme} func={AvatarPress.bind()} />
                                                    <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.default_image}</p>
                                                    <FormSpace />
                                                </div>
                                                <FormSpace />
                                                <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                                <FormYesNo value={enabled} editing={editing} title={lang.customer_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                                {props.system.eshop_enabled == true ?
                                                    <FormYesNo value={b2b} editing={editing} title={lang.customer_b2b} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setB2B(value)} />
                                                    : null}
                                                <FormYesNo value={canBuy} editing={editing} title={lang.customer_can_buy} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setCanBuy(value)} />

                                                <FormSpace />
                                                <FormSelect require value={countryID} items={countries} field={'state'} editing={editing} title={lang.app_country} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeCountryID(id)} />
                                                <FormNodes value={categoryID} items={categories} editing={editing} title={lang.customer_category} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCategoryID(id)} />
                                                <FormSpace />
                                                <FormText require={surname == '' ? true : false} redraw value={company} editing={editing} title={lang.company_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCompany(txt)} />
                                                <FormText redraw value={name} editing={editing} title={lang.name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                                <FormText require={company == '' ? true : false} redraw value={surname} editing={editing} title={lang.surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSurname(txt)} />

                                                <FormSpace />
                                                <FormLabel editing={editing} editButton={false} title={lang.firm_data} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                                <FormText redraw value={ico} editing={editing} title={lang.ico} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setICO(txt)} />
                                                <FormText redraw value={dic} editing={editing} title={lang.dic} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDIC(txt)} />
                                                <FormYesNo value={dph} editing={editing} title={lang.dph_pay} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setDPH(value)} />
                                                {dph == true ?
                                                    <FormText redraw value={icDPH} editing={editing} title={lang.ic_dph} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setICDPH(txt)} />
                                                    : null}

                                                <FormSpace />
                                                <FormText redraw value={bank} editing={editing} title={lang.bank} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setBank(txt)} />
                                                <FormText redraw value={iban} editing={editing} title={lang.iban} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setIban(txt)} />
                                                <FormText redraw value={swift} editing={editing} title={lang.swift} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSwift(txt)} />

                                                <FormSpace />
                                                <FormLabel editing={editing} editButton={false} title={lang.contact_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                                <FormText redraw value={mobil} editing={editing} title={lang.mobil} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setMobil(txt)} />
                                                <FormText redraw value={phone} editing={editing} title={lang.phone} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPhone(txt)} />
                                                <FormText redraw require value={email} editing={editing} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />
                                                {editing == true ?
                                                    <FormNote title={lang.customer_email_note} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                                    : null}

                                                <FormSpace />
                                                <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                                                <FormSpace />

                                                <FormSpace />
                                                <FormLabel editing={editing} editButton={false} title={lang.settings} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />

                                                {email.trim() != '' ?
                                                    <div style={{ ...styles.Block }}>
                                                        <FormYesNo value={portalEnabled} editing={editing} title={lang.customer_portal} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setPortalEnabled(value)} />
                                                        {editing == true ?
                                                            <FormNote title={lang.customer_portal_note} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                                            : null}
                                                    </div>
                                                    : null}
                                                {portalEnabled == true && customerID == 0 ?
                                                    <div style={{ ...styles.Block }}>
                                                        <FormYesNo value={sendInvitation} editing={editing} title={lang.customer_port_send_invitation} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setSendInvitation(value)} />
                                                        <FormNote title={lang.customer_port_send_invitation_note} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                                    </div>
                                                    : null}

                                                {/* KODY */}
                                                <FormSpace />
                                                {/*
                                                <FormText redraw value={erpCode} editing={editing} title={lang.erp_code} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setErpCode(txt)} />
                                                 */}
                                                {/* 
                                                {props.system.customers_codes[0].enabled == true ?
                                                    <FormText redraw value={code1} editing={editing} button={customer_code_searching == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.customers_codes[0].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(1, txt, press)} />
                                                    : null}
                                                {props.system.customers_codes[1].enabled == true ?
                                                    <FormText redraw value={code2} editing={editing} button={customer_code_searching == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.customers_codes[1].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(2, txt, press)} />
                                                    : null}
                                                {props.system.customers_codes[2].enabled == true ?
                                                    <FormText redraw value={code3} editing={editing} button={customer_code_searching == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.customers_codes[2].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(3, txt, press)} />
                                                    : null}
                                                {props.system.customers_codes[3].enabled == true ?
                                                    <FormText redraw value={code4} editing={editing} button={customer_code_searching == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.customers_codes[3].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(4, txt, press)} />
                                                    : null}
                                                */}
                                                <FormSpace />
                                                <FormText redraw value={credit} numeric={true} editing={editing} title={lang.credit} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCredit(txt)} />
                                                <FormAutoComplete require help_id={2} no_default value={priceListID} items={priceLists} field={'name'} editing={editing} title={lang.price_list} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setPriceListID(id)} />
                                                <FormAutoComplete value={agentID} items={agents} field={'name'} editing={editing} title={lang.customer_agent} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setAgentID(id)} />
                                                <FormSpace />

                                                {props.remote == true && props.remoteMobile == true ?
                                                    <FormSpace height={300} />
                                                    : null}

                                            </div>
                                            :
                                            <Stack style={{ ...styles.Block }} spacing={1}>
                                                <FormSpace />
                                                <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                                <FormSpace />
                                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                                <FormSpace />
                                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                                <FormSpace />
                                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                                <FormSpace />
                                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                            </Stack>}
                                    </div>
                                    : null}


                                {tabIndex == 1 ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * FAKTURACNA ADRESA
                                    * =================================================================================================================
                                    *
                                    */
                                    <div style={{ ...styles.Block, justifyContent: 'flex-start', height: dialogHeight - barHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        {storeData != false ?
                                            <div style={{ ...styles.BlockCenter, paddingBottom: global.list_padding }}>

                                                <FormSpace />
                                                <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} title={lang.customer_firm_address} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                                <FormText require redraw value={firmStreet} editing={editing} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setFirmStreet(txt)} />
                                                <FormText require redraw value={firmTown} editing={editing} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setFirmTown(txt)} />
                                                <FormText require redraw value={firmPSC} editing={editing} title={lang.psc} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setFirmPSC(txt)} />
                                                <FormText redraw value={firmState} editing={editing} title={lang.state} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setFirmState(txt)} />
                                                <FormSpace />
                                                <FormText redraw value={firmMobil} editing={editing} title={lang.mobil} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setFirmMobil(txt)} />
                                                <FormText redraw value={firmPhone} editing={editing} title={lang.phone} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setFirmPhone(txt)} />
                                                <FormText redraw value={firmEmail} editing={editing} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setFirmEmail(txt)} />

                                                {props.remote == true && props.remoteMobile == true ?
                                                    <FormSpace height={500} />
                                                    : null}

                                            </div>
                                            : null}
                                    </div>
                                    : null}


                                {tabIndex == 2 ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * DODACIE ADRESY / PREVADZKY
                                    * =================================================================================================================
                                    *
                                    */
                                    <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - barHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div style={{ ...styles.BlockCenter, paddingBottom: global.list_padding }}>
                                            <div style={{ ...styles.BlockRow, width: '90%' }}>
                                                <div style={{ ...styles.BlockLeft, width: '50%', height: 60 }}>
                                                    <p style={{ ...styles.TextTiny }}>{lang.customer_shop_address_list}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: '50%', height: 60 }}>
                                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                        <Button onClick={() => AddressAdd()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                            {lang.customer_add_contact}
                                                        </Button>
                                                        : null}
                                                </div>
                                            </div>

                                            <div style={{ ...styles.Block, width: '90%', border: color.border }}>
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div style={{ ...styles.BlockLeft, width: '42%', height: tableLabelHeight, borderRight: color.border }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.address}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '42%', height: tableLabelHeight, borderRight: color.border }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.contact_data}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 60, minHeight: tableLabelHeight, borderRight: color.border }}>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 140, minHeight: tableLabelHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.customer_address_default}</p>
                                                    </div>
                                                </div>

                                            </div>

                                            <div style={{ ...styles.BlockCenter, width: '90%' }}>
                                                {addresses != false ? addresses.map((item, index) => (
                                                    <Paper
                                                        key={item.id}
                                                        elevation={item.id == overID ? 4 : 1}
                                                        style={{
                                                            ...styles.Block,
                                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                            borderRadius: global.items_radius,
                                                            minHeight: rowHeight,
                                                            cursor: 'pointer',
                                                            marginTop: 5, marginBottom: 5,
                                                            paddingTop: 2, paddingBottom: 2,
                                                            borderLeft: item.enabled == false ? color.disabled_border : '',
                                                        }}
                                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                    >
                                                        <div style={{ ...styles.BlockRow, width: '96%' }}>
                                                            <div onClick={() => AddressEdit(item)} style={{ ...styles.BlockLeft, width: '42%', height: rowHeight }}>
                                                                <p style={{ ...styles.TextXSmall }}><b>{item.name}</b></p>
                                                                <p style={{ ...styles.TextXSmall }}>{item.street}</p>
                                                                <p style={{ ...styles.TextXSmall }}>{item.psc} {item.town}</p>
                                                                <p style={{ ...styles.TextXSmall }}>{item.state}</p>
                                                            </div>
                                                            <div onClick={() => AddressEdit(item)} style={{ ...styles.BlockLeft, width: '42%', height: rowHeight }}>
                                                                <p style={{ ...styles.TextTiny }}>{lang.mobil}: {item.mobil}</p>
                                                                <p style={{ ...styles.TextTiny }}>{lang.phone}: {item.phone}</p>
                                                                <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.email}: {item.email}</p>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: 60, minHeight: rowHeight, justifyContent: 'center' }}>
                                                                {item.lng != 0 ?
                                                                    <Chip label={'GPS'} variant='outlined' size='small'></Chip>
                                                                    : null}
                                                            </div>
                                                            <div style={{ ...styles.Block, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                                {/* ENABLED */}
                                                                {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                                    item.enabled == true ?
                                                                        <SwitchEnable enabled={item.defaulted == false ? false : true} theme={props.theme} func={(value) => ChangeDefault(item, value)} />
                                                                        : null : null}
                                                            </div>
                                                        </div>
                                                    </Paper>
                                                )) : null}
                                            </div>
                                        </div>

                                    </div>
                                    : null}


                                {tabIndex == 3 ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * KONTAKTNE OSOBY
                                    * =================================================================================================================
                                    *
                                    */
                                    <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - barHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div style={{ ...styles.BlockRow, width: '90%' }}>
                                            <div style={{ ...styles.BlockLeft, width: '50%', height: 60 }}></div>
                                            <div style={{ ...styles.BlockRight, width: '50%', height: 60 }}>
                                                {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                    <Button onClick={() => PersonAdd()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                        {lang.customer_add_contact}
                                                    </Button>
                                                    : null}
                                            </div>
                                        </div>

                                        <div style={{ ...styles.BlockCenter, width: '90%' }}>
                                            {persons != false ? persons.map((item, index) => (
                                                <Paper
                                                    key={item.id}
                                                    elevation={item.id == overID ? 4 : 1}
                                                    onClick={() => PersonEdit(item)}
                                                    style={{
                                                        ...styles.Block,
                                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                        borderRadius: global.items_radius,
                                                        minHeight: rowHeight,
                                                        cursor: 'pointer',
                                                        marginTop: 5, marginBottom: 5,
                                                        paddingTop: 2, paddingBottom: 2,
                                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                                    }}
                                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                >
                                                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                                                        <div style={{ ...styles.BlockLeft, width: '50%', height: rowHeight }}>
                                                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name} {item.surname}</p>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '50%', height: rowHeight }}>
                                                            <p style={{ ...styles.TextTiny }}>{lang.mobil}: {item.mobil}</p>
                                                            <p style={{ ...styles.TextTiny }}>{lang.phone}: {item.phone}</p>
                                                            <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.email}: {item.email}</p>
                                                        </div>
                                                    </div>
                                                </Paper>
                                            )) : null}
                                        </div>
                                    </div>
                                    : null}


                                {tabIndex == 4 ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * POZNAMKY
                                    * =================================================================================================================
                                    *
                                    */
                                    <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - barHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        {/*
                                        <CustomerNotes customerID={customerID} height={dialogHeight - barHeight - buttonHeight} title={lang.email_new} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={NotesResult.bind(this)} />
                                        */}
                                        <Notes module_id={global.model.customers} item_id={customerID} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={NotesResult.bind(this)} />
                                    </div>
                                    : null}


                                {tabIndex == 5 ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * EMAILOVA KOMUNIKACIA
                                    * =================================================================================================================
                                    *
                                    */
                                    <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - barHeight - buttonHeight, overflow: 'hidden' }}>
                                        <CustomerEmails remote={props.remote} remoteMobile={props.remoteMobile} reload={redrawEmails} customerID={customerID} height={dialogHeight - barHeight - buttonHeight} delete={false} title={lang.email_new} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={EmailsResult.bind(this)} />
                                    </div>
                                    : null}


                                {tabIndex == 6 ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * OBJEDNAVKY PRODUKTOV
                                    * =================================================================================================================
                                    *
                                    */
                                    <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - barHeight - buttonHeight, overflow: 'hidden' }}>
                                        <Orders remote={props.remote} remoteMobile={props.remoteMobile} customerID={customerID} width={dialogWidth - menuWidth} height={dialogHeight - barHeight - buttonHeight} title={lang.orders} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={OrdersResult.bind(this)} />
                                    </div>
                                    : null}

                                {tabIndex == 8 ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * REKLAMACIE OBJEDNAVOK
                                    * =================================================================================================================
                                    *
                                    */
                                    <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - barHeight - buttonHeight, overflow: 'hidden' }}>
                                        <Reklamacie remote={props.remote} remoteMobile={props.remoteMobile} embedded customerID={customerID} width={dialogWidth - menuWidth} height={dialogHeight - barHeight - buttonHeight} title={lang.orders} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={ReklamacieResult.bind(this)} />
                                    </div>
                                    : null}


                                {tabIndex == 7 ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * VYSKLADNENIE ZASOB
                                    * =================================================================================================================
                                    *
                                    */
                                    <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - barHeight - buttonHeight, overflow: 'hidden' }}>
                                        <StockRemovals remote={props.remote} remoteMobile={props.remoteMobile} customerID={customerID} width={dialogWidth - menuWidth} height={dialogHeight - barHeight - buttonHeight} title={lang.orders} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={StockRemovalsResult.bind(this)} />
                                    </div>
                                    : null}

                                {tabIndex == 9 ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * PREZENTACIE
                                    * =================================================================================================================
                                    *
                                    */
                                    <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - barHeight - buttonHeight, overflow: 'hidden' }}>
                                        <Presentations remote={props.remote} remoteMobile={props.remoteMobile} embedded customer={storeData} width={dialogWidth - menuWidth} height={dialogHeight - barHeight - buttonHeight} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.presentations} token={props.token} func={PresentationsResult.bind(this)} />
                                    </div>
                                    : null}

                                {tabIndex == 10 ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * KONTAKTNE SPRAVY
                                    * =================================================================================================================
                                    *
                                    */
                                    <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - barHeight - buttonHeight, overflow: 'hidden' }}>
                                        <QuizeResults remote={props.remote} remoteMobile={props.remoteMobile} embedded customer={storeData} width={dialogWidth - menuWidth} height={dialogHeight - barHeight - buttonHeight} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.presentations} token={props.token} func={PresentationsResult.bind(this)} />
                                    </div>
                                    : null}

                                {tabIndex == 99 ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * HISTORIA - LOGS
                                    * =================================================================================================================
                                    *
                                    */
                                    <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - barHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                                    </div>
                                    : null}

                                {tabIndex != 99 && tabIndex != 5 && tabIndex != 6 && tabIndex != 7 && tabIndex != 8 && tabIndex != 4 && tabIndex != 9 && tabIndex != 10 ?
                                    <div style={{ ...styles.Block, height: errorHeight }}>
                                        <FormError small margin={0} error={error} theme={props.theme} />
                                    </div>
                                    : null}

                                <Paper elevation={3} style={{ ...styles.Block, height: buttonHeight - 1, borderTop: '1px solid #E0E0E0' }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        {editing == false && portalEnabled == true && tabIndex == 0 && activated == false ?
                                            <Button onClick={() => setAskSendInvitation(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 180, marginRight: 20 }}>{lang.customer_port_send_invitation}</Button>
                                            : null}
                                        {(tabIndex == 0 || tabIndex == 1 || tabIndex == 2) && editing == true ?
                                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 180, marginRight: 20 }}>{lang.save}</Button>
                                            : null}
                                        {editing == true && props.item != false ?
                                            <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 180 }}>{lang.editing_finish}</Button>
                                            :
                                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 180 }}>{lang.close}</Button>
                                        }
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>

                {showEmailEditor == true ?
                    <EmailEditor remote={props.remote} remoteMobile={props.remoteMobile} email={email} customerID={customerID} delete={false} attachment_delete={false} title={lang.email_new} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={SendEmail.bind(this)} />
                    : null}

                {showAddress == true ?
                    <CustomersAddress remote={props.remote} remoteMobile={props.remoteMobile} item={addressSelected} address_fakt={storeAddressData1} customerID={customerID} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={AddressResult.bind(this)} />
                    : null}

                {showPerson == true ?
                    <CustomersPerson remote={props.remote} remoteMobile={props.remoteMobile} item={personSelected} customerID={customerID} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={PersonResult.bind(this)} />
                    : null}

                {askSendInvitation == true ?
                    <DialogYesNo remote={props.remote} remoteMobile={props.remoteMobile} theme={props.theme} radius={props.radius} title={lang.customer_portal} text={lang.customer_ask_send_invitation_text} sub_text={lang.customer_ask_send_invitation_subtext + email} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={SendInvitationButton.bind(this)} />
                    : null}

                {selectPhoto == true ?
                    <Photos remote={props.remote} remoteMobile={props.remoteMobile} autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} locale={props.locale} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}



export const CustomerEmails = (props) => {
    /*

            USAGE:
            <CustomerEmails lang={props.lang} theme={props.theme} radius={props.radius} func={Result.bind(this)} />


    */

    let permission_column = 'customers';
    let limited = props.permissions.customers_limited == true && props.user.super_admin == false ? true : false;
    let lang = props.lang;

    var defaultFilter = {
        customer_id: props.customerID,
        agent_id: limited == true ? props.user.id : undefined,
    }

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.name, default: true },
        { id: 2, enabled: true, checked: false, column: 'town', label: lang.town },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    let radius = props.radius;
    let color = global.themes[props.theme];

    const [isBusy, setBusy] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(global.items_max);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);

    // VYBER ZAKAZNIKA
    const [showCustomers, setShowCustomers] = useState(false);
    const [customerID, setCustomerID] = useState(0);
    const [customer, setCustomer] = useState(false);

    // VYBER UZIVATELA
    const [showUsers, setShowUsers] = useState(false);
    const [userID, setUserID] = useState(0);
    const [user, setUser] = useState(false);

    // CONSTANTS
    const rowHeight = 68;
    const paginationHeight = 50;
    const menuHeight = 50;

    let { func } = props;
    var running = false;
    useEffect(() => {
        if (running == false) {

            var filter = defaultFilter;

            if (props.params != undefined) {
                if (props.params != false) {
                    if (props.add_new == undefined || props.add_new == false) {
                        if (props.params.email != undefined) {
                            filter.search = props.params.email;
                            setSearch(props.params.email);
                        }
                        if (props.params.my_emails == true) {
                            filter.agent_id = props.user.id;
                            filter.limited = true;
                            setUserID(props.user.id);
                            setUser(props.user);
                        }
                        if (props.params.user_id != undefined) {
                            filter.agent_id = props.params.user_id;
                            filter.limited = true;
                            setUserID(props.params.user_id);
                            setUser(props.params.user);
                        }
                    }
                }
            }

            db_get(0, filter, maxItems);

            running = true;
        }
        return () => {
        };

    }, [props.reload, props.params]);


    const db_get = async (page, filter_, max_items) => {
        setBusy(true);
        Debug('filter', 'red');
        Debug(filter_);
        try {
            const response = await fetch(
                global.db_url + 'customer_emails', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    start: page * max_items,
                    length: max_items,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, max_items)); // Max pocet na stranku - Select

                setFilter(filter);
                //setOrdering(filter.ordering);
                //setOrderColumn(filter.order_column);
                GoToStart();
            } else {
                setShowError(true);
                setBusy(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_emails_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter, maxItems);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }


    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const GetFilter = () => {
        var filter = defaultFilter;

        if (search != '') {
            filter.search = search;
        }

        if (customerID > 0) {
            filter.customer_id = customerID;
        }
        if (userID > 0) {
            filter.agent_id = userID;
            filter.limited = true;
        }
        return filter;
    }


    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            filter.search = txt;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            db_get(0, filter, maxItems);
        }
    }

    const CustomersResult = (typ, items, selected) => {
        setShowCustomers(false);

        if (typ == true && items != false) {
            setCustomerID(items[0]);
            setCustomer(selected[0]);

            var filter = GetFilter();
            filter.customer_id = items[0];

            db_get(0, filter, maxItems);
        }
    }

    const UserResult = (typ, selected) => {
        setShowUsers(false);

        if (typ == true && selected != false) {
            setUserID(selected.id);
            setUser(selected);

            var filter = GetFilter();
            filter.agent_id = selected.id;
            filter.limited = true;

            db_get(0, filter, maxItems);
        }
    }

    const UserOnly = () => {
        setUserID(props.user.id);
        setUser(props.user);

        var filter = GetFilter();
        filter.agent_id = props.user.id;
        filter.limited = true;

        db_get(0, filter, maxItems);
    }

    const CustomerReset = () => {
        setCustomerID(0);

        var filter = GetFilter();
        delete (filter.customer_id);
        setFilter(filter);

        db_get(0, filter, maxItems);
    }

    const UserReset = () => {
        setUserID(0);

        var filter = GetFilter();
        delete (filter.agent_id);
        delete (filter.limited);
        setFilter(filter);

        db_get(0, filter, maxItems);
    }

    return (
        <div style={{ ...styles.Block, width: '98%', height: props.height }}>

            <div style={{ ...styles.BlockRow, width: '98%', height: 60 }}>
                <div style={{ ...styles.Block, width: '70%', height: 60, alignItems: 'flex-start' }}>
                    {props.hide_title == undefined ?
                        <p style={{ ...styles.TextDialogLabel, marginLeft: 10 }}>{lang.customer_emails}</p>
                        :
                        <>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.BlockLeft, width: 220, height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <TextInput search theme={props.theme} width={200} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        {/*
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                        */}
                                    </div>
                                </div>
                                <div style={{ ...styles.Block, width: 170, height: menuHeight }}>
                                    <Button onClick={() => setShowCustomers(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                        {lang.customer}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, width: 170, height: menuHeight }}>
                                    <Button onClick={() => setShowUsers(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                        {lang.customer_agent}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                    <IconButton onClick={() => UserOnly(true)} style={{ ...styles.ButtonUser }}>
                                        <FontAwesomeIcon style={{ height: 14 }} icon={faUser} />
                                    </IconButton>
                                </div>
                            </div>

                        </>
                    }
                </div>
                <div style={{ ...styles.BlockRight, width: '30%', height: 60 }}>
                    {props.new_button == true ?
                        <Button onClick={() => func(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action_in_bar }}>
                            <FontAwesomeIcon style={{ height: 16, color: color.white }} icon={faAt} />
                            <p style={{ ...styles.TextTiny, color: color.white, marginLeft: 10 }}>{lang.customer_email_new}</p>
                        </Button>
                        : null}
                </div>
            </div>

            <div style={{ ...styles.Block, justifyContent: 'flex-start', height: props.height - 60, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                <div id={'id_emails_start'} style={{ ...styles.BlockCenter }}></div>

                <div style={{ ...styles.BlockLeft, width: '98%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        {customerID > 0 ?
                            <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{customer != false ? customer.company != '' ? customer.company : customer.name + ' ' + customer.surname : ''}</p>} onDelete={() => CustomerReset()}></Chip>
                            : null}
                        {userID > 0 ?
                            <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{user.name + ' ' + user.surname}</p>} onDelete={() => UserReset()}></Chip>
                            : null}
                    </div>
                </div>

                {/* ****************************************************************************************************************
                    ZOZNAM EMAILOV
                **************************************************************************************************************** */}

                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                    <Paper
                        key={item.id}
                        elevation={item.id == overID ? 4 : 1}
                        style={{
                            ...styles.Block,
                            width: '98%',
                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                            borderRadius: global.items_radius,
                            minHeight: rowHeight,
                            cursor: 'pointer',
                            marginTop: 5, marginBottom: 5,
                            paddingTop: 2, paddingBottom: 2,
                            borderLeft: item.enabled == false ? color.disabled_border : '',
                        }}
                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                        onClick={() => SelectItem(item)}
                    >
                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                            <div style={{ ...styles.BlockLeft, maxWidth: '35%', minHeight: rowHeight }}>
                                <TextLine text={item.subject} fontSize={global.font_xsmall} fontWeight={'600'} marginLeft={5} color={color.black} />
                                <Chip style={{ marginTop: 5 }} label={item.agent} variant="outlined" size='small'></Chip>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight }}>
                                <TextLine text={item.customer} fontSize={global.font_tiny} color={color.black} />
                                <TextLine text={item.address_to} fontSize={global.font_tiny} color={color.black} marginTop={5} />
                            </div>
                            <div style={{ ...styles.Block, width: 150, minHeight: rowHeight }}>
                                {item.sended == true ?
                                    <Chip label={lang.email_sended_} style={{ color: color.ok, borderColor: color.ok }} variant="outlined"></Chip>
                                    :
                                    item.sended == false && item.remaind == 0 ?
                                        <Chip label={lang.email_sended_not} style={{ color: color.error, borderColor: color.error }} variant="outlined"></Chip>
                                        :
                                        <Chip label={lang.email_delayled} style={{ color: color.dark_orange, borderColor: color.dark_orange }} variant="outlined"></Chip>
                                }
                            </div>
                            <div style={{ ...styles.Block, width: 150, minHeight: rowHeight, borderLeft: color.border }}>
                                <p style={{ ...styles.TextTiny, marginBottom: 2, color: color.dark_gray }}>{GetDatumTime2(item.updated)}</p>
                            </div>

                        </div>
                    </Paper>
                )) :
                    <div style={{ ...styles.Block, paddingTop: 40 }}>
                        <EmptyList row small lang={lang} />
                    </div>
                }

                {/* ****************************************************************************************************************
                PAGINATION
                **************************************************************************************************************** */}

                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                    <div style={{ ...styles.Block, width: 200 }}></div>
                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                        {pages > 1 ?
                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                            : null}
                    </div>
                    {itemsFiltered != false ?
                        <div style={{ ...styles.Block, width: 200 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                    <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                </div>
                                <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>

            {showUsers == true ?
                <UsersSelect remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.user_select} token={props.token} func={UserResult.bind()} />
                : null}

            {showCustomers == true ?
                <CustomerSelect remote={props.remote} remoteMobile={props.remoteMobile} single showVariations={true} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.customer_select_} token={props.token} func={CustomersResult.bind()} />
                : null}

            {showDialog == true ?
                <EmailPreview remote={props.remote} remoteMobile={props.remoteMobile} item={itemSelected} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={() => setShowDialog(false)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </div >
    );
}


export const EmailsWindow = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'products_vendors';
    let { func } = props;

    // ZAKAZNIK
    const [customerID, setCustomerID] = useState(0);
    const [email, setEmail] = useState('');
    const [emailCC, setEmailCC] = useState('');
    const [subject, setSubject] = useState('');
    const [reload, setReload] = useState(true);
    const [params, setParams] = useState(props.params);

    //DIALOGOVE OKNA
    const [showDialog, setShowDialog] = useState(false);

    var running = false;

    useEffect(() => {
        if (running == false) {

            setParams(props.params);

            if (props.params != undefined) {
                if (props.params != false) {

                    if (props.params.add_new == true) {
                        if (props.params.email != undefined) {
                            setEmail(props.params.email);
                        }
                        if (props.params.email_cc != undefined) {
                            setEmailCC(props.params.email_cc);
                        }
                        if (props.params.subject != undefined) {
                            setSubject(props.params.subject);
                        }

                        setShowDialog(true);
                    }
                }
            }

            running = true;
        }
    }, [props.locale, props.params]);

    const EmailsResult = () => {
        setShowDialog(true);
    }

    const SendEmail = (typ) => {
        setParams(false);
        setEmail('');
        setSubject('');

        setShowDialog(false);

        if (typ == true) {
            setReload(!reload);
        }
    }


    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => func(true)} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                <div style={{ ...styles.Block, maxWidth: global.max_width, backgroundColor: color.back }}>
                    <CustomerEmails remote={props.remote} remoteMobile={props.remoteMobile} params={props.params} hide_title new_button={true} reload={reload} customerID={0} height={props.height - global.widow_bar_height} delete={false} title={lang.email_new} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={EmailsResult.bind(this)} />
                </div>
            </div>

            {showDialog == true ?
                <EmailEditor remote={props.remote} remoteMobile={props.remoteMobile} params={params} email={email} subject={subject} email_cc={emailCC} customerID={customerID} delete={false} attachment_delete={false} title={lang.email_new} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={SendEmail.bind(this)} />
                : null}

        </Paper >
    );
}

/*
*
* =================================================================================================================
* POZNAMKY
* =================================================================================================================
*
*/

export const CustomerNotes = (props) => {

    var defaultFilter = {
        customer_id: props.customerID
    }
    let permission_column = 'customers';

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    const [isBusy, setBusy] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redraw, setRedraw] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(global.items_max);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [noteNew, setNoteNew] = useState('');
    const [note, setNote] = useState('');


    // CONSTANTS
    const rowHeight = 48;
    const paginationHeight = 50;

    let { func } = props;
    var running = false;
    useEffect(() => {
        if (running == false) {
            db_get(0, defaultFilter, maxItems, true);

            running = true;
        }
        return () => {
        };

    }, [props.reload]);


    const db_get = async (page, filter_, max_items, goto_start) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_notes', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    start: page * max_items,
                    length: max_items,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, max_items)); // Max pocet na stranku - Select

                setFilter(filter);
                if (goto_start == true) {
                    GoToStart();
                }
            } else {
                setShowError(true);
                setBusy(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.customer_notes,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);
            if (json.ok > 0) {
                db_get(data.id == 0 ? 0 : page, filter, maxItems, data.id == 0 ? true : false);
            } else {
                setShowError(true);
                setBusy(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_customers_notes_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const SelectItem = (item) => {
        setNote(item.note);
        setItemSelected(item);
        setItemEditing(true);
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter, maxItems, true);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const Save = (newItem) => {
        var err = false;
        if (newItem == true) {
            if (noteNew.trim() == '') {
                err = true;
            }
        } else {
            if (note.trim() == '') {
                err = true;
            }
        }

        if (err == false) {

            let data = {
                id: newItem ? 0 : itemSelected.id,
                organization_id: props.organization.id,
                user_id: props.user.id,
                enabled: true,
                customer_id: props.customerID,
                note: newItem ? noteNew : note
            };

            if (newItem == true) {
                setNoteNew('');
            }
            setItemEditing(false);
            db_update(data);
        }

    }

    const DeleteItem = (item) => {
        let data = {
            id: item.id,
            enabled: false,
        };
        db_update(data);
    }

    return (
        <div style={{ ...styles.BlockCenter, width: '96%' }}>
            <div id={'id_customers_notes_start'} style={{ ...styles.BlockCenter }}></div>

            <div style={{ ...styles.BlockRow, height: global.widow_bar_height }}>
                <div style={{ ...styles.Block, width: '95%', height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{lang.customer_notes}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    {/*
                <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                */}
                </div>
            </div>


            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                {/* NOVA POZNAMKA */}
                <FormSpace />
                <TextInput redraw placeholder={lang.customer_note_placeholder} theme={props.theme} enabled={true} rows={3} lang={lang} value={noteNew} label={lang.customers_note_new} func={(txt) => setNoteNew(txt)} />
                {noteNew != '' ?
                    <div style={{ ...styles.BlockRight, paddingTop: 10, paddingBottom: 20 }}>
                        <Button onClick={() => Save(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action }}>{lang.save}</Button>
                    </div>
                    : null}

                {/* ****************************************************************************************************************
                    ZOZNAM EMAILOV
                **************************************************************************************************************** */}
                <div style={{ ...styles.BlockLeft, marginTop: 20, paddingBottom: 5 }}>
                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.customers_notes}:</p>
                </div>
                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                    <Paper
                        key={item.id}
                        elevation={item.id == overID ? 4 : 1}
                        style={{
                            ...styles.Block,
                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                            borderRadius: global.items_radius,
                            //minHeight: rowHeight,
                            cursor: 'pointer',
                            marginTop: 5, marginBottom: 5,
                            paddingTop: 2, paddingBottom: 2,
                            borderLeft: item.enabled == false ? color.disabled_border : '',
                        }}
                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                    >
                        <div style={{ ...styles.Block, width: '98%', paddingTop: 10, paddingBottom: 10 }}>
                            {itemEditing == true && item.id == itemSelected.id ?
                                <div style={{ ...styles.BlockRow, minHeight: rowHeight }}>
                                    <div style={{ ...styles.Block, width: '95%', minHeight: rowHeight }}>
                                        <TextInput theme={props.theme} enabled={true} rows={3} lang={lang} value={note} label={lang.customers_note_edit} func={(txt) => setNote(txt)} />
                                        <FormSpace></FormSpace>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <Button onClick={() => Save(false)} style={{ ...styles.ButtonOutlined, marginRight: 20 }}>{lang.save}</Button>
                                            <Button onClick={() => setItemEditing(false)} style={{ ...styles.ButtonOutlined }}>{lang.cancel}</Button>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.Block, width: 50 }}>
                                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).delete == true ?
                                            <DeleteButton ask text={lang.customers_note_delete} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteItem(item)} />
                                            : null}
                                    </div>
                                </div>
                                :
                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, minHeight: rowHeight }}>
                                    <p style={{ ...styles.TextXTiny, marginBottom: 2, color: color.medium_gray }}>{GetDatumTime2(item.updated)}</p>
                                    <p style={{ ...styles.TextXSmall }}>{item.note}</p>
                                </div>
                            }
                        </div>
                    </Paper>
                )) :
                    <div style={{ ...styles.Block, paddingTop: 40 }}>
                        <EmptyList row small lang={lang} />
                    </div>
                }

                {/* ****************************************************************************************************************
                PAGINATION
                **************************************************************************************************************** */}

                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                    <div style={{ ...styles.Block, width: 200 }}></div>
                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                        {pages > 1 ?
                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                            : null}
                    </div>
                    {itemsFiltered != false ?
                        <div style={{ ...styles.Block, width: 200 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                    <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                </div>
                                <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </div >
    );
}

