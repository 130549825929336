/*
*
* =================================================================================================================
* DODAVATELIA

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormNote, FormSelect, FormNodes } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, IconButton, Pagination, Paper, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faCog, faCogs, faInfoCircle, faLayerGroup, faMinusCircle, faPlusCircle, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, FormatMoney, GetLeftPosition, GetPages, IsEmpty } from './functions';
import { Photos } from './photos';
import { Service } from './services.js';

export const TradePayments = (props) => {

    let module_id = global.model.order_types;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'code_book';
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let currency = props.locale.currency;

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        typ: 1
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.vendor_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    const tabs = [
        { id: 0, enabled: true, label: lang.trade },
        { id: 1, enabled: true, label: lang.payments },
        { id: 2, enabled: false, label: lang.payment_gate },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);

    const [codeBookID, setCodeBookID] = useState(global.codebook.reasons);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.label, default: true },
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 46;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const menuWidth = global.sub_menu_width;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            setTabIndex(0);
            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);


    const db_get = async (page, filter, maxItems) => {
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'services', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'trade_payment_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GetFilter = () => {
        var filter_ = defaultFilter;

        return filter_;
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            filter.name = txt;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            filter.name = undefined;
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        if (CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true) {
            setItemSelected(item);
            setShowDialog(true);
        }
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(0, filter, maxItems);
        }
    }

    const ChangeEnabled = (item, value) => {
        // ENABLED POLOZKY ZMENENY
        /*
        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: item.id,
            action: value == true ? global.logs.enabled : global.logs.disabled
        };
        */

        let data = {
            id: item.id,
            enabled: value,
            //logs: log
        }
        db_update(data);
    }

    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // NAZOV
                column = 'name';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;
        }

        var filter = {
            ordering: order,
            order_column: column
        };
        if (search != '') {
            filter.search = search;
        }
        db_get(0, filter, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const ChangeTab = (id) => {
        setTabIndex(id);
        var filter_ = GetFilter();

        if (id == 0) {
            filter_.typ = global.services.trade;
        }
        if (id == 1) {
            filter_.typ = global.services.payment;
        }
        if (id == 2) {
            filter_.typ = global.services.payment_gate;
        }

        db_get(0, filter_, maxItems);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }} >
                    <div id={'id_start'} style={{ ...styles.Block, maxWidth: global.max_width, width: '100%', marginTop: 10 }}>

                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs center value={tabIndex} items={tabs} width={220} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>

                        <div style={{ ...styles.Block, width: '96%', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: 220, height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <TextInput search theme={props.theme} width={200} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ ...styles.Block, width: '56%', height: menuHeight }}>
                                    {tabIndex == 0 ?
                                        <p style={{ ...styles.TextXSmall, textAlign: 'center', fontWeight: '600' }}>{lang.trade_text}</p>
                                        : null}
                                    {tabIndex == 1 ?
                                        <p style={{ ...styles.TextXSmall, textAlign: 'center', fontWeight: '600' }}>{lang.payments_text}</p>
                                        : null}
                                </div>
                                <div style={{ ...styles.BlockRight, width: 220, height: menuHeight }}>
                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.new_record}
                                        </Button>
                                        : null}
                                </div>
                            </div>

                            <div style={{ ...styles.Block, marginTop: 20 }}>

                                {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.Block, width: '98%', marginBottom: 10, border: color.border }}>
                                    <div style={{ ...styles.BlockRow, width: '99%' }}>
                                        <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center' }}>
                                            {/* SELECT */}
                                            <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: '60%' }}>
                                            {/* DATA */}
                                            <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.label}</p>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.Block, width: 150, borderLeft: color.border, height: tableLabelHeight }}>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: 150, borderLeft: color.border }}>
                                            {/* ENABLED */}
                                            <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.price}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: 100, borderLeft: color.border }}>
                                            {/* ENABLED */}
                                            <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.enabled}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* ****************************************************************************************************************
                                        DATA
                                **************************************************************************************************************** */}
                                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - tabHeight - tableLabelHeight - 10 - 40 - 60, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block, width: '98%',
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                    {/* SELECT */}
                                                    <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                                </div>

                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '58%', minHeight: rowHeight }}>
                                                    <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{item.name}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {item.price_free_enabled == true ?
                                                        <Chip variant='outlined' size='small' style={{ borderColor: color.dark_gray, height: 18 }} label={<p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{tabIndex == 0 ? lang.service_trade_free : lang.service_payment_free}</p>}></Chip>
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny }}>{FormatMoney(item.price, precisions)} {currency}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {/* ENABLED */}
                                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                        <SwitchEnable enabled={item.enabled == false ? false : true} theme={props.theme} func={(value) => ChangeEnabled(item, value)} />
                                                        : null}
                                                    {item.enabled == false ?
                                                        <div style={{ ...styles.Block, height: 20 }}>
                                                            <p style={{ ...styles.TextXXTiny, color: color.dark_gray }}>{lang.disabled}</p>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }

                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                    **************************************************************************************************************** */}

                                    <div style={{ ...styles.BlockRow, height: 80, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, height: 80, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, height: 80, width: 200 }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showDialog == true ?
                <Service typ={tabIndex == 0 ? 1 : 2} trade remote={props.remote} remoteMobile={props.remoteMobile} module_id={module_id} item={itemSelected} organization={props.organization} settings={props.settings} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}



/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const CodeBook = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');


    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 680 ? 680 : width;
    const dialogHeight = height >= 300 ? 300 : height;

    // CONSTANTS
    const tabHeight = props.item == false ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = dialogWidth - column1 - 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                AssignData(props.item);
            }

            running = true;
        }

    }, []);

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'codebook_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setName(item.name);
    }

    const Save = () => {
        setError('');
        var error = false;
        error += IsEmpty(name) == true ? 1 : 0;

        if (error == 0) {
            let data = {
                id: props.item == false ? 0 : props.item.id,
                organization_id: props.organization.id,
                enabled: enabled,
                book_id: props.codebook,
                name: name,
                priority: 100
            };

            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ShowOKPress = () => {
        func(true);
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000, top: props.remote == true && props.remoteMobile == true ? -400 : 0 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.new_record : lang.update_record}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div>
                            <FormSpace />
                            <FormYesNo value={enabled} editing={true} title={lang.order_type_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                            <FormText require redraw value={name} editing={true} title={lang.order_type_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                            <FormSpace />

                        </div>
                    </div>

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                        </div>
                    </div>
                </div >


                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}

