/*
*
* =================================================================================================================

    DIALOG - POPIS

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { EmptyList, FormError, Icon, Loading, SelectInput, ShowError, ShowOK, TextInput, TextLine, Avatar, ContactCompany } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, Pagination, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faLayerGroup, faMagnifyingGlass, faPlay, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetLeftPosition, GetPages } from './functions';
import { Product } from './product';
import { Customer } from './customer';

export const DistributorsCodes = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let codeTyp = props.distributor == true ? 0 : props.product == true ? 1 : 2;
    let endPoint = props.locale.id == 1 ? 'pharminfo_search' : props.locale.id == 2 ? 'pharmos_search' : '';
    let defaultFilter = { enabled: true };

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsMax, setItemsMax] = useState(20);
    const [itemSelected, setItemSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DATA
    const [value, setValue] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 800 ? 800 : height;

    const menuHeight = 100;
    const rowHeight = 80;
    const viewWidth = '96%';

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            // db_get(0, defaultFilter);

            running = true;
        }

    }, []);

    const db_get = async (page) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + endPoint, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    page: parseInt(page) + 1,
                    length: itemsMax,
                    search: search,
                    typ: codeTyp
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.curl_result != false) {
                    if (json.curl_result.code == 200) {
                        var tmp = json.curl_result.items;
                        setItems(tmp);

                        setPage(page);
                        setPages(GetPages(json.curl_result.total, itemsMax));

                        GoToStart();
                    }
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(false, false);
    }


    const ChangePage = (value) => {
        db_get(value - 1);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_code_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        func(true, item);
    }

    const Search = () => {
        db_get(0);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{codeTyp == 0 ? lang.dist_code_distributor : codeTyp == 1 ? lang.dist_code_product : lang.dist_code_customer}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                            <TextInput theme={props.theme} enabled={true} lang={lang} value={search} label={codeTyp == 0 ? lang.dist_code_distributor_search : codeTyp == 1 ? lang.dist_code_product_search : lang.dist_code_customer_search} placeholder={''} func={(txt) => setSearch(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '48%', height: menuHeight, marginLeft: 20 }}>
                            <Button onClick={() => Search()} style={{ ...styles.ButtonOutlined, marginTop: 14 }}>
                                <FontAwesomeIcon style={{ height: 16, color: color.white.theme_black, marginRight: 8 }} icon={faMagnifyingGlass} />
                                {lang.search}
                            </Button>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_code_start'} style={{ ...styles.Block, width: viewWidth }}>
                            {items != false ? items.map((item, index) => (
                                <Paper
                                    key={index}
                                    elevation={index == overID ? 4 : 1}
                                    onClick={() => SelectItem(item)}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: codeTyp == 2 && item.isReplaced == true ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: codeTyp == 2 && item.isReplaced == true ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(index)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                                        <div style={{ ...styles.BlockLeft, width: '80%', minHeight: rowHeight, justifyContent: 'center' }}>
                                            <TextLine text={item.name} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                            {codeTyp == 0 || codeTyp == 2 ?
                                                <>
                                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{item.address}</p>
                                                    <p style={{ ...styles.TextTiny }}>{item.city} {item.country != '' ? ', ' + item.county : ''}</p>
                                                </>
                                                : null}
                                            {codeTyp == 1 ?
                                                <>
                                                    <TextLine text={item.manufacturer} fontSize={global.font_tiny} marginTop={5} color={color.black} />
                                                </>
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            {codeTyp == 0 ?
                                                <p style={{ ...styles.TextTiny }}>{item.vatNo}</p>
                                                : null}
                                            {codeTyp == 1 ?
                                                <p style={{ ...styles.TextTiny }}>{item.code}</p>
                                                : null}
                                            {codeTyp == 2 && item.isReplaced == true ?
                                                <p style={{ ...styles.TextTiny }}>{lang.disabled}</p>
                                                : null}
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div >

                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}



export const DistributorsCodeChecker = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let codeTyp = props.product == true ? 0 : 1;
    let defaultFilter = { enabled: true };

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // SKLADY
    const [stocks, setStocks] = useState(false);
    const [stockID, setStockID] = useState(-1);
    const [stock, setStock] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [itemsMax, setItemsMax] = useState(20);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // PRODUKTY
    const [showProduct, setShowProduct] = useState(false);

    // ZAKAZNICI
    const [showCustomer, setShowCustomer] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 800 ? 800 : height;

    const menuHeight = 160;
    const rowHeight = 80;
    const viewWidth = '96%';

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'distribution_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    typ: codeTyp
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setStocks(json.stocks);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_items = async (page) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'distribution_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    typ: codeTyp,
                    start: page * itemsMax,
                    length: itemsMax,
                    stock_id: stockID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                setPage(page);
                setPages(GetPages(json.count, itemsMax));

                setStock(json.stock);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false, false);
    }


    const ChangePage = (value) => {
        db_items(value - 1);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_code_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        if (codeTyp == 0) {
            setShowProduct(true);
        } else {
            setShowCustomer(true);
        }
    }

    const Search = () => {
        db_items(0);
    }


    const ProductResult = () => {
        setShowProduct(false);
    }

    const CustomerResult = () => {
        setShowCustomer(false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faCheckDouble} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.dist_code_checker_label}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>



                    <div style={{ ...styles.BlockCenter, height: menuHeight }}>
                        <p style={{ ...styles.TextTiny, marginTop: 10 }}><b>{lang.dist_checker_stock_select_note}</b></p>
                        <div style={{ ...styles.BlockRow, width: viewWidth, marginTop: 16 }}>
                            <div style={{ ...styles.BlockLeft, width: '50%', height: 60 }}>
                                <p style={{ ...styles.TextTiny, marginLeft: 2, marginBottom: 2 }}>{lang.dist_checker_stock_select}</p>
                                <SelectInput default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={stockID} items={stocks} field={'name'} radius={global.radius} func={(txt) => setStockID(txt)} />
                            </div>
                            <div style={{ ...styles.BlockRight, width: '48%', height: 60, marginLeft: 20 }}>
                                {stockID > 0 ?
                                    <Button onClick={() => Search()} style={{ ...styles.ButtonOutlined, marginTop: 14 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.white.theme_black, marginRight: 8 }} icon={faPlay} />
                                        {lang.dist_check}
                                    </Button>
                                    : null}
                            </div>
                        </div>
                        <div style={{ ...styles.Block, height: 40 }}>
                            {stock != false ?
                                <div style={{ ...styles.Block }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.stock_export_typ}: <b>{stock.export_typ == 0 ? lang.stock_export_stock_none : lang.stock_export_types[stock.export_typ]}</b></p>
                                </div>
                                : null}
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_code_start'} style={{ ...styles.Block, width: viewWidth }}>

                            {items != false ?
                                <div style={{ ...styles.Block, height: 50 }}>
                                    <p style={{ ...styles.TextTiny }}>{codeTyp == 0 ? lang.dist_products_code_missing : lang.dist_customers_code_missing}</p>
                                </div>
                                : null}

                            {items != false ? items.map((item, index) => (
                                <Paper
                                    key={index}
                                    elevation={index == overID ? 4 : 1}
                                    onClick={() => SelectItem(item)}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: codeTyp == 2 && item.isReplaced == true ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: codeTyp == 2 && item.isReplaced == true ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(index)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                                        <div style={{ ...styles.BlockLeft, width: rowHeight - 25, minHeight: rowHeight, justifyContent: 'center' }}>
                                            <Avatar picture image={codeTyp == 0 ? item.image : item.avatar} size={rowHeight - 40} theme={props.theme} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '80%', minHeight: rowHeight, justifyContent: 'center' }}>
                                            {codeTyp == 0 ?
                                                <>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginBottom: 2 }}>{item.code}</p>
                                                    <TextLine text={item.name} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                </>
                                                : null}
                                            <ContactCompany company={item.company} name={item.name} surname={item.surname} theme={props.theme} />
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div >

                    </div>
                </div >


                {showCustomer == true ?
                    <Customer remote={props.remote} remoteMobile={props.remoteMobile} module_id={global.model.customers} item={itemSelected} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={'customers'} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomerResult.bind()} />
                    : null}

                {showProduct == true ?
                    <Product module_id={global.model.products} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={'products'} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductResult.bind()} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}