/*
*
* =================================================================================================================
* DODAVATELIA

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, TextLine, DeleteButton, AutoCompleteInput, ContactCompany, FormSelect, PlusMinus, FormNote, DialogChooseTermin, DialogQRCode } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, FormControlLabel, IconButton, Pagination, Paper, Radio, RadioGroup, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faPlus, faInfoCircle, faLayerGroup, faLocationDot, faPlusCircle, faQuestion, faXmark, faAngleLeft, faArrowAltCircleLeft, faArrowAltCircleRight, faPlayCircle, faAngleRight, faAngleUp, faAngleDoubleDown, faAngleDown, faQuestionCircle, faComment, faCheck, faPen, faCommentAlt, faCircle, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, GetLeftPosition, GetPages, IsEmpty, UniqueID } from './functions';
import { Photos } from './photos';
import { BarChart, PieChart } from '@mui/x-charts';
import { GetDatumTime, GetDatumTime2, Today, TodayDateTime } from './functions_date.js';
import { CustomerSelect } from './customers.js';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { QRCodeSVG } from 'qrcode.react';

export const Quizes = (props) => {

    let module_id = global.model.quizes;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'quizes';

    let defaultFilter = {
        ordering: global.order.up,
        order_column: 'created',
        country_id: props.locale.id
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.vendor_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.vendor_name, default: true },
        { id: 2, enabled: true, checked: false, column: 'town', label: lang.town },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showPresentation, setShowPresentation] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const playHeight = 40;
    const menuHeight = 50;
    const rowHeight = 80;
    const paginationHeight = 50;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);


    const db_get = async (page, filter, maxItems) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quizes', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.vendors,
                    filename: lang.vendors, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = defaultFilter;

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        if (CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true) {
            setItemSelected(item);
            setShowDialog(true);
        }
    }

    const PlayItem = (item) => {
        setItemSelected(item);
        setShowPresentation(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const PresentationResult = (typ) => {
        setShowPresentation(false);

        if (redraw == true) {
        }
    }

    const ChangeEnabled = (item, value) => {
        // ENABLED POLOZKY ZMENENY

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: item.id,
            action: value == true ? global.logs.enabled : global.logs.disabled
        };

        let data = {
            id: item.id,
            enabled: value,
            logs: log
        }
        db_update(data);
    }

    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // NAZOV
                column = 'name';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;
        }

        var filter = {
            ordering: order,
            order_column: column
        };
        if (search != '') {
            filter.search = search;
        }
        db_get(0, filter, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }


    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.embedded == true ? 0 : props.radius }}>
            {props.embedded == true ? null :
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} />
                    </div>
                    <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
            }
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.embedded == true ? props.height : props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.embedded == true ? 0 : props.radius, borderBottomRightRadius: props.embedded == true ? 0 : props.radius }}>
                <div style={{ ...styles.BlockCenter, maxWidth: props.embedded == true ? '98%' : '100%', height: props.embedded == true ? props.height : props.height - global.widow_bar_height }}>
                    <div id={'id_start'} style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                        <div style={{ ...styles.Block, height: 60 }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <TextInput search theme={props.theme} width={300} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        {/*
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                        */}
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                    {props.customer == undefined && CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 190 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.quiz_new_}
                                        </Button>
                                        : null}
                                </div>
                            </div>
                        </div>

                        <div style={{ ...styles.BlockCenter, height: props.embedded == true ? props.height : props.height - global.widow_bar_height - 60 - 6, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.BlockCenter, marginTop: 20 }}>

                                {/* ****************************************************************************************************************
                                        TABLE DATA
                                **************************************************************************************************************** */}

                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            width: '98%',
                                            minHeight: rowHeight,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            cursor: 'pointer',
                                            margin: 6,
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                                            {/* DATA */}
                                            <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: rowHeight, minHeight: rowHeight, opacity: item.enabled == true ? 1 : 0.30 }}>
                                                <Avatar picture image={item.image} size={rowHeight - 8} theme={props.theme} />
                                            </div>

                                            <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '70%', minHeight: rowHeight, marginLeft: 10 }}>
                                                <TextLine text={item.name} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                {item.text != '' ?
                                                    <TextLine text={item.text} marginTop={2} fontSize={global.font_xsmall} color={color.black} />
                                                    : null}
                                            </div>
                                            <div onClick={() => PlayItem(item)} style={{ ...styles.Block, width: 200, minHeight: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.middle_gray }}>{lang.quiz_fill}</p>
                                            </div>
                                        </div>

                                    </Paper>
                                )) :
                                    <EmptyList row small lang={lang} />
                                }

                                {/* ****************************************************************************************************************
                                        PAGINATION
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 200 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {showExport == true ?
                <DialogExport remote={props.remote} remoteMobile={props.remoteMobile} rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <Quiz remote={props.remote} remoteMobile={props.remoteMobile} customer={props.customer == undefined ? undefined : props.customer} module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showPresentation == true ?
                <QuizPlay remote={props.remote} remoteMobile={props.remoteMobile} save={true} customer={props.customer == undefined ? undefined : props.customer} module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={PresentationResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


/*
*
* =================================================================================================================
* VYSLEDNE SPRAVY - ZOZNAM
* =================================================================================================================
*
*/
export const QuizeResults = (props) => {

    let module_id = global.model.quizes;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'quizes';

    let defaultFilter = {
        ordering: global.order.up,
        order_column: 'created',
        country_id: props.locale.id,
        customer_id: props.customer.id
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.vendor_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);

    // VYPLNENIE NOVEJE SPRAVY
    const [quiz, setQuiz] = useState(false);
    const [showNewQuiz, setShowNewQuiz] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.vendor_name, default: true },
        { id: 2, enabled: true, checked: false, column: 'town', label: lang.town },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [showPresentation, setShowPresentation] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const playHeight = 40;
    const menuHeight = 50;
    const rowHeight = 80;
    const paginationHeight = 50;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);


    const db_get = async (page_, filter_, max_items) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quiz_results', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    quiz_id: 0,
                    start: page_ * max_items,
                    length: max_items,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, max_items)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.vendors,
                    filename: lang.vendors, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = defaultFilter;

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowSelect(true);
    }

    const PlayItem = (item) => {
        setItemSelected(item);
        setShowPresentation(true);
    }

    const QuizResult = (typ) => {
        setShowPresentation(false);

        if (redraw == true) {
        }
    }

    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const SelectResult = (typ, item) => {
        setShowSelect(false);

        if (typ == true) {
            setQuiz(item);
            setTimeout(() => {
                setShowNewQuiz(true);
            }, 200);
        }
    }

    const QuizNewResult = (typ) => {
        setShowNewQuiz(false)

        if (typ == true) {
            db_get(0, filter, maxItems);
        }
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.embedded == true ? 0 : props.radius }}>
            {props.embedded == true ? null :
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} />
                    </div>
                    <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
            }
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.embedded == true ? props.height : props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.embedded == true ? 0 : props.radius, borderBottomRightRadius: props.embedded == true ? 0 : props.radius }}>
                <div style={{ ...styles.BlockCenter, maxWidth: props.embedded == true ? '98%' : '100%', height: props.embedded == true ? props.height : props.height - global.widow_bar_height }}>
                    <div id={'id_start'} style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                        <div style={{ ...styles.Block, height: 60 }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <TextInput search theme={props.theme} width={300} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        {/*
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                        */}
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                    <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 190 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                        {lang.quiz_create}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div style={{ ...styles.BlockCenter, height: props.embedded == true ? props.height : props.height - global.widow_bar_height - 60 - 6, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.BlockCenter, marginTop: 20 }}>

                                {/* ****************************************************************************************************************
                                        TABLE DATA
                                **************************************************************************************************************** */}

                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            width: '98%',
                                            minHeight: rowHeight,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            cursor: 'pointer',
                                            margin: 6,
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                                            {/* DATA */}
                                            <div onClick={() => PlayItem(item)} style={{ ...styles.Block, width: rowHeight, minHeight: rowHeight, opacity: item.enabled == true ? 1 : 0.30 }}>
                                                <Avatar picture image={item.image} size={rowHeight - 8} theme={props.theme} />
                                            </div>

                                            <div onClick={() => PlayItem(item)} style={{ ...styles.BlockLeft, width: '70%', minHeight: rowHeight, marginLeft: 10 }}>
                                                <TextLine text={item.name} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                {item.text != '' ?
                                                    <TextLine text={item.text} marginTop={2} fontSize={global.font_xsmall} color={color.black} />
                                                    : null}
                                            </div>
                                            <div onClick={() => PlayItem(item)} style={{ ...styles.Block, width: 200, minHeight: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{GetDatumTime2(item.created)}</p>
                                            </div>
                                        </div>

                                    </Paper>
                                )) :
                                    <EmptyList row small lang={lang} />
                                }

                                {/* ****************************************************************************************************************
                                        PAGINATION
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 200 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {showPresentation == true ?
                <QuizPlay save={props.user.id == itemSelected.user_id || CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} customer={props.customer == undefined ? undefined : props.customer} result={itemSelected} module_id={module_id} item={{ id: itemSelected.quiz_id }} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={QuizResult.bind()} />
                : null}

            {showNewQuiz == true ?
                <QuizPlay save={true} customer={props.customer == undefined ? undefined : props.customer} stats={false} module_id={module_id} item={quiz} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={QuizNewResult.bind()} />
                : null}

            {showSelect == true ?
                <QuizSelect module_id={module_id} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={SelectResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}




/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const Quiz = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = {
        ordering: global.order.up,
        order_column: 'created',
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [editing, setEditing] = useState(props.item == false ? true : false);
    const [overID, setOverID] = useState(-1);
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [itemLast, setItemLast] = useState(0);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showPreview, setShowPreview] = useState(false);


    // VYSLEDNE SPRAVY
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [resultSelected, setResultSelected] = useState(false);
    const [resultStats, setResultStats] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [enabled, setEnabled] = useState(true);
    const [uid, setUID] = useState('');
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [note, setNote] = useState('');
    const [avatar, setAvatar] = useState('');
    const [questions, setQuestions] = useState(false);
    const [questionSelected, setQuestionSelected] = useState(false);
    const [results, setResults] = useState(false);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showQuestion, setShowQuestion] = useState(false);
    const [showChooseDate, setShowChooseDate] = useState(false);
    const [showQRCode, setShowQRCode] = useState(false);

    // ŠTATISTIKA
    const [statQuestions, setStatQuestions] = useState(false);
    const [statQuestionID, setStatQuestionID] = useState(0);
    const [statQuestion, setStatQuestion] = useState(false);
    const [statAnswers, setStatAnswers] = useState(false);
    const [statData, setStatData] = useState(false);
    const [statDataFiltered, setStatDataFiltered] = useState(false);
    const [statCount, setStatCount] = useState(0);
    const [statDate, setStatDate] = useState(false);
    const [statDateText, setStatDateText] = useState('');

    // UZIVATELIA
    const [users, setUsers] = useState(false);
    const [userID, setUserID] = useState(0);

    // CLIPBOARD
    const [quizLink, setQuizLink] = useState('');
    const [showCopied, setShowCopied] = useState(false);

    let itemsExportMenu = [
        { id: 1, enabled: true, label: lang.order_export_pdf },
    ];

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1100 ? 1100 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    // CONSTANTS
    const tabHeight = 40;
    const buttonHeight = 70;
    const questionButton = 70;
    const errorHeight = 40;
    const column1 = 160;
    const column2 = 600 - column1 - 40;
    const rowSize = 60;
    const resultHeight = 80;
    const paginationHeight = 50;

    const tabs = [
        { id: 0, enabled: true, label: lang.setting },
        { id: 2, enabled: true, label: lang.quiz_questions_answers },
        { id: 4, enabled: true, label: lang.quiz_results },
        { id: 3, enabled: true, label: lang.presentation_stats },
        { id: 1, enabled: true, label: lang.history },
    ];

    const ordering_menu = [
        { id: 0, name: lang.presentation_ordering_0 },
        { id: 1, name: lang.presentation_ordering_1 },
        { id: 2, name: lang.presentation_ordering_2 },
    ];


    const tableHeight = 40;
    const inputRef = useRef(null);

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            Debug('Customer');
            Debug(props.customer);
            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quiz', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID,
                    result_id: 0
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);
                    setUsers(json.users);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_questions = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quiz_questions', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    quiz_id: itemID,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setQuestions(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_stats = async (date) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quiz_stats', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    quiz_id: itemID,
                    date: date
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setStatQuestions(json.questions);
                setStatAnswers(json.answers);
                setStatData(json.stats);
                setStatDataFiltered(false);
                setStatCount(json.count);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (data.id == 0) {
                    setItemID(json.item_id);
                    storeData.id = json.item_id;
                }
                setEditing(false);
                setRedrawRequired(true);
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_delete = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quiz_question_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_questions();
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_results = async (page_, filter_, max_items) => {

        try {
            const response = await fetch(
                global.db_url + 'quiz_results', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    quiz_id: itemID,
                    start: page_ * max_items,
                    length: max_items,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setResults(tmp);

                setPage(page_);
                setPages(GetPages(json.count, max_items)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }

        } catch (error) {
            console.log(error);

        }
    }

    const db_swap = async (item1, item2) => {

        try {
            const response = await fetch(
                global.db_url + 'db_swap', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.quiz_questions,
                    item1: item1,
                    item2: item2,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_questions();
            }

        } catch (error) {
            console.log(error);

        }
    }

    const db_reorder = async (items) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reorder_items', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.quiz_questions,
                    items: items
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export_pdf = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quiz_export_pdf', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------                    
                    quiz_id: itemID,
                    questions: statQuestions,
                    count: statCount,
                    date: statDate
                })
            })

            const json = await response.json();
            Debug('Export PDF');
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setRedrawRequired(true);
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_result_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const Close = () => {
        func(redrawRequired);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setUID(item.uid);
        setName(item.name);
        setText(item.text);
        setNote(item.note);
        setAvatar(item.image);
        setQuizLink(GetQuizLink(item.uid));
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.id = itemID;
        storeData.user_id = props.user.id;
        storeData.enabled = enabled;
        storeData.name = name;
        storeData.text = text;
        storeData.note = note;
        storeData.image = avatar;
    }

    const Save = () => {
        setError('');
        var error = false;
        error += IsEmpty(name) == true ? 1 : 0;

        if (error == 0) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: props.item == false ? 0 : itemID,
                action: itemID == 0 ? global.logs.created : storeData.enabled != enabled ? enabled == true ? global.logs.enabled : global.logs.disabled : global.logs.modified
            };

            var data = {
                id: itemID,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                enabled: enabled,
                name: name.trim(),
                text: text,
                note: note,
                image: avatar,
                logs: log
            };

            if (itemID == 0) {
                var uid_ = 'LNK-' + props.organization.id + UniqueID();
                data.uid = uid_;
                setUID(uid_);
                setQuizLink(GetQuizLink(uid_));
            }

            Debug(data);
            StoreEditing();

            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const MenuResult = (id, index, item) => {
        switch (index) {
            case 1:
                db_export_pdf();
                break;
        }
    }

    const DeleteQuestion = (item) => {
        db_delete(item.id);
    }


    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 2) {
            db_questions();
        }
        if (id == 3) {
            setStatQuestionID(0);

            db_stats(statDate);
        }
        if (id == 4) {
            db_results(page, filter, maxItems);
        }
        if (id == 1) {
            if (logs == false) {
                db_get_logs();
            }
        }
    }


    const AvatarPress = () => {
        setSelectPhoto(true);
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);
        }
    }


    const Swap = (typ, index) => {

        setItemLast(questions[index].id);

        var index1 = index;
        var index2 = typ == -1 ? index - 1 : index + 1;

        var item1 = {
            id: questions[index1].id,
            priority: questions[index2].priority
        }

        var item2 = {
            id: questions[index2].id,
            priority: questions[index1].priority
        }

        Debug(item1);
        Debug(item2);

        db_swap(item1, item2);
    }

    const SelectQuestion = (item) => {
        setQuestionSelected(item);
        setShowQuestion(true);

    }

    const NewQuestion = () => {
        setQuestionSelected(false);
        setShowQuestion(true);
    }

    const QuestionResult = (typ) => {
        setShowQuestion(false);
        if (typ == true) {
            db_questions();
        }
    }

    const ShowPreview = () => {
        setResultSelected(false);
        setResultStats(false);
        setShowPreview(true);
    }

    const SelectResult = (item) => {
        setResultSelected(item);
        setResultStats(false);
        setShowPreview(true);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_results(0, filter, item);
    }

    const ChangePage = (value) => {
        db_results(value - 1, filter, maxItems);
    }

    const GetFilter = () => {
        var filter = defaultFilter;

        if (userID > 0) {
            filter.agent_id = userID;
        }

        return filter;
    }

    const ChangeUser = (id) => {
        setUserID(id);
        var filter = GetFilter();
        filter.agent_id = id;

        db_results(0, filter, maxItems);
    }

    const ChangeStat = (id) => {
        setStatQuestionID(id);

        if (statData != false) {
            var question = statQuestions.find(x => x.id == id);
            if (question != undefined) {
                if (question.typ != 4) {
                    setStatQuestion(question);

                    var tmp = statData[id];

                    if (tmp != undefined && tmp != false) {
                        var array = [];
                        var keys = Object.keys(tmp);

                        keys.forEach(key => {
                            var name = key;
                            if (question.typ == 1) {
                                name = key == 0 ? question.text_no : question.text_yes;
                            }
                            if (question.typ == 3) {
                                Debug(key);
                                name = statAnswers[id][key];
                            }
                            let data = {
                                id: key,
                                value: tmp[key],
                                label: name
                            }
                            array.push(data);
                        });
                        setStatDataFiltered(array);
                    } else {
                        setStatDataFiltered(false);
                    }
                } else {
                    setStatDataFiltered(false);
                }
            }
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (result.destination) {
            const tmp = reorder(questions, result.source.index, result.destination.index);
            var n = 1;
            var items_ = [];
            tmp.forEach(item => {
                item.priority = n;

                let data = {
                    id: item.id,
                    priority: n
                }
                items_.push(data);
                n++
            });

            setQuestions(tmp);
            db_reorder(items_);
        }

    }

    const LinkCopy = () => {
        navigator.clipboard.writeText(quizLink);
        setShowCopied(true);
        var timer = setTimeout(() => {
            setShowCopied(false);
        }, 2000);
    }

    function GetQuizLink(uid) {
        return global.quiz_url + props.locale.id + '/' + props.organization.uid + '/' + uid;
    }

    const TerminResult = (typ, date, text) => {
        Debug(text);
        setShowChooseDate(false);
        if (typ == true) {
            setStatDate(date);
            setStatDateText(text);
            db_stats(date);
        }
    }

    const DeleteFilter = () => {
        setStatDate(false);
        setStatDateText('');
        db_stats(false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 170, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.quiz_new : lang.quiz}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 50, height: global.widow_bar_height }}>
                        {tabIndex == 3 ?
                            <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.Block, height: tabHeight }}>
                        {itemID > 0 ?
                            <DialogTabs disabled={editing == true ? true : false} value={tabIndex} items={tabs} width={dialogWidth / 5} theme={props.theme} func={(id) => ChangeTab(id)} />
                            : null}
                    </div>

                    {tabIndex == 0 ?
                        // *******************************************************************************************
                        // NASTAVENIA
                        // *******************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {storeData != false ?
                                <div>
                                    <FormSpace />
                                    <div style={{ ...styles.Block }}>
                                        <Avatar editable={editing} picture image={avatar} size={75} theme={props.theme} func={AvatarPress.bind()} />
                                        <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.quiz_image}</p>
                                    </div>
                                    <Line marginTop={10} theme={props.theme} />
                                    < FormSpace />
                                    <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                    <FormYesNo value={enabled} editing={editing} title={lang.quiz_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                    <FormText require redraw value={name} editing={editing} title={lang.quiz_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                    <FormText redraw value={text} rows={3} editing={editing} title={lang.quiz_text} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setText(txt)} />
                                    <FormSpace />
                                    <FormText rows={3} redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />

                                    {editing == false ?
                                        <div onClick={() => LinkCopy()} style={{ ...styles.Block, cursor: 'pointer', marginTop: 40 }}>
                                            <p style={{ ...styles.TextXSmall, marginBottom: 1 }}><b>{lang.quiz_link}:</b></p>
                                            <p style={{ ...styles.TextXTiny, marginBottom: 10, color: color.dark_gray }}>({lang.click_to_copy})</p>
                                            {/*
                                            <p style={{ ...styles.TextTiny, textAlign: 'center', color: color.dark_gray }}>{quizLink}</p>
                                            */}
                                            <div style={{ ...styles.Block, height: 24 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'center' }}>{showCopied == true ? lang.quiz_link_copied : ''}</p>
                                            </div>
                                        </div>
                                        : null}

                                    {itemID > 0 ?
                                        <QRCodeSVG onClick={() => setShowQRCode(true)} value={quizLink} size={192} />
                                        : null}
                                        
                                    <FormSpace height={50} />
                                    {
                                        props.remote == true && props.remoteMobile == true ?
                                            <FormSpace height={500} />
                                            : null
                                    }
                                </div>
                                :
                                <Stack style={{ ...styles.Block }} spacing={1}>
                                    <FormSpace />
                                    <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                </Stack>}
                        </div>
                        : null}

                    {tabIndex == 2 ?
                        // *******************************************************************************************
                        // OTAZKY
                        // *******************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none' }}>
                            <div style={{ ...styles.Block, height: questionButton }}>
                                <div style={{ ...styles.BlockRow, width: '96%' }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: questionButton }}>

                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '50%', height: questionButton }}>
                                        <Button onClick={() => NewQuestion()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 190 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.quiz_question_add}
                                        </Button>
                                    </div>
                                </div>

                            </div>
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - questionButton, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                {questions != false ?
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={{ ...styles.Block }}
                                                >
                                                    {questions.map((item, index) => (
                                                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Paper
                                                                        key={item.id}
                                                                        elevation={item.id == overID ? 4 : 1}
                                                                        style={{
                                                                            ...styles.Block,
                                                                            width: dialogWidth - 40,
                                                                            minHeight: rowSize,
                                                                            backgroundColor: item.id == itemLast ? color.lighteen_red : color.white,
                                                                            borderRadius: global.items_radius,
                                                                            cursor: 'pointer',
                                                                            marginTop: 6,
                                                                        }}
                                                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                                        onClick={() => SelectQuestion(item)}
                                                                    >
                                                                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                                            <div style={{ ...styles.Block, minHeight: rowSize, width: 50 }}>
                                                                                <p style={{ ...styles.TextTiny }}>{index + 1}.</p>
                                                                            </div>
                                                                            <div style={{ ...styles.BlockLeft, minHeight: rowSize }}>
                                                                                <p style={{ ...styles.TextTiny }}>{item.name}</p>
                                                                            </div>
                                                                            <div style={{ ...styles.Block, width: 80, height: rowSize, borderLeft: color.border }}>
                                                                                <DeleteButton ask text={lang.quiz_question_delete} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteQuestion(item)} />
                                                                            </div>
                                                                        </div>
                                                                    </Paper>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext >
                                    :
                                    <div style={{ ...styles.Block, height: 100 }}>
                                        <EmptyList row small lang={lang} />
                                    </div>
                                }

                            </div>
                        </div>
                        : null}

                    {tabIndex == 4 ?
                        // *******************************************************************************************
                        // VYSLEDKY - VYPLNENE SPRAVY
                        // *******************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight }}>
                            <div style={{ ...styles.BlockCenter, width: '96%' }}>

                                <div style={{ ...styles.BlockRow, width: dialogWidth - 40, borderBottom: color.border }}>
                                    <div style={{ ...styles.BlockRow, width: '100%', height: 80 }}>
                                        {props.customer != undefined ?
                                            <div style={{ ...styles.BlockLeft, width: '50%', height: 80 }}>
                                                <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.customer}:</p>
                                                <ContactCompany company={props.customer.company} name={props.customer.name} surname={props.customer.surname} theme={props.theme} />
                                            </div>
                                            : null}
                                        <div style={{ ...styles.BlockLeft, width: '50%', height: 80 }}>
                                            <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.user_select}</p>
                                            <AutoCompleteInput value={userID} items={users} field={'name'} width={250} default={lang.all_records} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => ChangeUser(txt)} />
                                        </div>
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - 80, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <div id={'id_result_start'} style={{ ...styles.Block }}></div>
                                    <FormSpace />

                                    {results != false ? results.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block,
                                                minHeight: resultHeight,
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                cursor: 'pointer',
                                                margin: 6,
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                {/* DATA */}
                                                <div onClick={() => SelectResult(item)} style={{ ...styles.BlockLeft, width: '75%', minHeight: resultHeight, justifyContent: 'center' }}>
                                                    {item.customer_id == 0 ?
                                                        <p style={{ ...styles.TextXSmall }}>{lang.customer_anonyme}</p>
                                                        :
                                                        <>
                                                            {item.customer_company != '' ?
                                                                <TextLine text={item.customer_company} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                                : null}
                                                            {item.customer_name != '' ?
                                                                <TextLine text={item.customer_name} fontSize={global.font_xsmall} fontWeight={'400'} color={color.black} />
                                                                : null}
                                                        </>
                                                    }
                                                </div>
                                                <div onClick={() => SelectResult(item)} style={{ ...styles.Block, width: 250, minHeight: resultHeight }}>
                                                    {item.customer_id > 0 ?
                                                        <Chip variant='outlined' size='small' label={item.agent} />
                                                        : null}
                                                </div>
                                                <div onClick={() => SelectResult(item)} style={{ ...styles.Block, width: 200, minHeight: resultHeight, borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny }}>{GetDatumTime2(item.created)}</p>
                                                </div>
                                            </div>

                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }

                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                    **************************************************************************************************************** */}

                                    <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}

                    {tabIndex == 3 ?
                        // *******************************************************************************************
                        // ŠTATISTIKA
                        // *******************************************************************************************
                        <div style={{ ...styles.Block, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight }}>
                            <div style={{ ...styles.BlockRow, width: dialogWidth - 40, height: 100, borderBottom: color.border }}>
                                <div style={{ ...styles.BlockRow, width: '100%', height: 100 }}>
                                    <div style={{ ...styles.Block, width: 50, height: 100 }}>
                                        <IconButton onClick={() => setShowChooseDate(true)} style={{ ...styles.ButtonUser, marginTop: 18 }}>
                                            <FontAwesomeIcon style={{ height: 14 }} icon={faCalendarDays} />
                                        </IconButton>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '40%', height: 100 }}>
                                        <p style={{ ...styles.TextXSmall, marginTop: 18 }}>{lang.quiz_results_count}: <b>{statCount}</b></p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '58%', height: 100 }}>
                                        <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.quiz_question_select}</p>
                                        <SelectInput all={lang.quiz_answers_all} default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={statQuestionID} items={statQuestions} field={'name'} label={lang.mobil} radius={global.radius} func={(txt) => ChangeStat(txt)} />
                                    </div>
                                    <div style={{ ...styles.Block, width: 50, height: 100 }}>
                                        <IconButton onClick={() => ChangeStat(0)} style={{ ...styles.ButtonIcon, marginTop: 20 }}>
                                            <FontAwesomeIcon style={{ height: 12 }} icon={faXmark} />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                            <div style={{ ...styles.BlockCenter, marginTop: 20, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - 120, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                {statDate != false ?
                                    <div style={{ ...styles.BlockLeft }}>
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.termin_periode}: {statDateText}</p>} onDelete={() => DeleteFilter()}></Chip>
                                    </div>
                                    : null}

                                {statQuestionID > 0 ?
                                    <div style={{ ...styles.Block }}>

                                        <div style={{ ...styles.BlockRow, width: dialogWidth - 40 }}>
                                            <div style={{ ...styles.BlockLeft, width: 50 }}>
                                                <IconButton onClick={() => ChangeStat(0)} style={{ ...styles.ButtonIcon }}>
                                                    <FontAwesomeIcon style={{ height: 12 }} icon={faAngleLeft} />
                                                </IconButton>
                                            </div>
                                            {statDataFiltered != false ?
                                                <div style={{ ...styles.Block, width: '98%' }}>
                                                    <PieChart
                                                        series={[
                                                            {
                                                                data: statDataFiltered,
                                                            },
                                                        ]}
                                                        width={dialogWidth - 40 - 100}
                                                        height={dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - 200}
                                                    />
                                                </div>
                                                : <div style={{ ...styles.Block, width: '98%' }}></div>}
                                            <div style={{ ...styles.BlockLeft, width: 50 }}></div>
                                        </div>

                                    </div>
                                    : statQuestionID == 0 ?
                                        <div style={{ ...styles.BlockCenter }}>
                                            {statQuestions != false ? statQuestions.map((item, index) => (
                                                <div
                                                    key={item.id}
                                                    style={{
                                                        ...styles.Block,
                                                        width: '98%',
                                                        minHeight: rowSize,
                                                        cursor: 'pointer',
                                                        marginTop: 0,
                                                        borderBottom: '1px solid ' + color.light_gray
                                                    }}
                                                    onClick={() => ChangeStat(item.id)}
                                                >
                                                    <div style={{ ...styles.BlockRow }}>
                                                        <div style={{ ...styles.Block, minHeight: rowSize, width: 50 }}>
                                                            <p style={{ ...styles.TextXSmall }}>{index + 1}.</p>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, minHeight: rowSize }}>
                                                            <p style={{ ...styles.TextXSmall }}><b>{item.name}</b></p>
                                                        </div>
                                                    </div>

                                                    <div style={{ ...styles.Block, paddingBottom: 10 }}>
                                                        {item.answers != false ?
                                                            item.answers.map((answer, i) => (
                                                                <div key={i} style={{ ...styles.BlockRow }}>
                                                                    <div style={{ ...styles.Block, width: 50 }}>
                                                                    </div>
                                                                    <div style={{ ...styles.BlockLeft, width: 12, marginBottom: 5 }}>
                                                                        <FontAwesomeIcon style={{ height: 6, color: color.light_gray, marginTop: 5 }} icon={faCircle} />
                                                                    </div>
                                                                    <div style={{ ...styles.BlockLeft, marginBottom: 5 }}>
                                                                        {item.typ == 4 ?
                                                                            <p style={{ ...styles.TextXSmall }}>{answer.value}</p>
                                                                            :
                                                                            <p style={{ ...styles.TextXSmall }}>{answer.name}: {answer.value}x</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))
                                                            : null}
                                                    </div>
                                                </div>

                                            )) :
                                                <div style={{ ...styles.Block, height: 100 }}>
                                                    <EmptyList row small lang={lang} />
                                                </div>
                                            }
                                            <FormSpace height={100} />
                                        </div>
                                        : null}
                            </div>
                        </div>
                        : null}

                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {tabIndex == 0 && editing == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            {editing == true ?
                                itemID > 0 ?
                                    <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                    : null
                                :
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, width: 180, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                                    {itemID > 0 && editing == false ?
                                        <Button onClick={() => ShowPreview()} style={{ ...styles.ButtonThemed, width: 180, backgroundColor: color.button_ok, marginLeft: 20 }}>{lang.quiz_preview}</Button>
                                        : null}
                                </div>

                            }
                        </div>
                    </div>
                </div >

                {showQRCode == true ?
                    <DialogQRCode title={lang.quiz_link} value={quizLink} lang={props.lang} theme={props.theme} radius={props.radius} func={() => setShowQRCode(false)} />
                    : null}

                {showChooseDate == true ?
                    <DialogChooseTermin date lang={lang} theme={props.theme} radius={props.radius} func={TerminResult.bind(this)} />
                    : null}

                {showQuestion == true ?
                    <QuizQuestion remote={props.remote} remoteMobile={props.remoteMobile} question={questionSelected} quiz={storeData} module_id={module_id} organization={props.organization} settings={props.settings} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={QuestionResult.bind(this)} />
                    : null}

                {showPreview == true ?
                    <QuizPlay remote={props.remote} remoteMobile={props.remoteMobile} module_id={module_id} result={resultSelected} item={storeData} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={() => setShowPreview(false)} />
                    : null}

                {selectPhoto == true ?
                    <Photos remote={props.remote} remoteMobile={props.remoteMobile} autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}


/*
*
* =================================================================================================================
* SPUSTENIE QVÍZU
*
*   PARAMS:
* -----------------------------------------------------------------------------------------------------------------
*   customer        -> zakazník
*   save            -> ukladanie umoznené
* =================================================================================================================
*
*/
export const QuizPlay = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/user.png');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // OTAZKY
    const [quiz, setQuiz] = useState(false);
    const [questions, setQuestions] = useState(false);
    const [customer, setCustomer] = useState(false);
    const [agent, setAgent] = useState(false);

    const [customerID, setCustomerID] = useState(props.customer != undefined ? props.customer.id : 0);
    const [showCustomers, setShowCustomers] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 700 ? 700 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const buttonHeight = 70;
    const errorHeight = 40;
    const rowOffset = 20;
    const radioOffset = 10;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            Debug('props.customer', 'yellow');
            Debug(props.customer);
            if (props.item != false) {
                db_get();
            }

            running = true;
        }

    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quiz', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id,
                    result_id: props.result != undefined && props.result != false ? props.result.id : 0
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setQuiz(json.item);
                setCustomer(json.customer);
                setAgent(json.agent);

                var items = json.questions;

                // DEFAULT PREDVYPLNENE HODNOTY
                if (json.results == false) {
                    items.forEach(item => {
                        if (item.typ == 1) {
                            item.result = -1;
                        }
                        if (item.typ == 2) {
                            item.result = 1;
                        }
                        if (item.typ == 3) {
                            item.result = -1;
                        }
                        if (item.typ == 4) {
                            item.result = '';
                        }
                        if (item.typ == 5) {
                            item.result = Today();
                        }
                        if (item.typ == 6) {
                            item.result = '09:00:00';
                        }
                    });
                } else {
                    items.forEach(item => {
                        let result = json.results.find(x => x.question_id == item.id);
                        if (result != undefined) {
                            if (item.typ == 1) {
                                item.result = parseInt(result.value);
                            }
                            if (item.typ == 2) {
                                item.result = parseInt(result.value);
                            }
                            if (item.typ == 3) {
                                Debug('json.result');
                                Debug(result);
                                if (item.multiselect == true) {
                                    if (item.answers != false) {
                                        item.answers.forEach(answer => {
                                            let tmp = result.value.find(x => x.id == answer.id);
                                            if (tmp != undefined) {
                                                answer.result = parseInt(tmp.value);
                                            } else {
                                                answer.result = 0;
                                            }
                                        });
                                    }
                                } else {
                                    item.result = parseInt(result.value);
                                }
                            }
                            if (item.typ == 4) {
                                item.result = result.value;
                            }
                            if (item.typ == 5) {
                                item.result = result.value;
                            }
                            if (item.typ == 6) {
                                item.result = result.value;
                            }
                        }
                    });
                }
                Debug(items);
                setQuestions(items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_result = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quiz_result_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);
            if (json.ok > 0) {
                func(true);
            } else {

            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const Save = () => {
        var result = [];
        var err = false;
        setError('');

        if (customerID == 0) {
            setError(lang.quiz_customer_error);
        } else {
            questions.forEach(question => {
                if (question.typ == 3 && question.multiselect == true) {
                    var array = [];
                    question.answers.forEach(answer => {
                        array.push({ id: answer.id, value: answer.result == undefined ? 0 : answer.result, typ: question.typ, multi: question.multiselect });
                    });
                    question.result = array;
                }
                let data = {
                    question_id: question.id,
                    value: question.result,
                    typ: question.typ,
                    multi: question.multiselect
                }

                question.error = false;
                if (question.result == undefined || question.result == -1) {
                    question.error = true;
                    err = true;
                }

                result.push(data);
            });

            if (err == true) {
                setError(lang.quiz_answer_required_);
            } else {
                let data = {
                    id: props.result == undefined || props.result == false ? 0 : props.result.id,
                    organization_id: props.organization.id,
                    quiz_id: props.item.id,
                    user_id: props.user.id,
                    customer_id: customerID,
                    results: JSON.stringify(result),
                    score: 0
                }

                Debug(data);
                db_result(data);
            }
        }

    }

    const PlusMinusResult = (value, item) => {
        var tmp = questions.find(x => x.id == item.id);
        if (tmp != undefined) {
            tmp.result = value;
        }
    }

    const CustomersResult = (typ, items, selected) => {
        setShowCustomers(false);

        if (typ == true && items != false) {
            setCustomerID(items[0]);
            setCustomer(selected[0]);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 800 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.quiz}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20, backgroundColor: color.light_gray }}>
                            {quiz != false ?
                                <>
                                    {quiz.image != '' ?
                                        <div style={{ ...styles.Block, marginBottom: 20 }}>
                                            <Avatar editable={false} picture image={quiz.image} size={120} theme={props.theme} func={null} />
                                        </div>
                                        :
                                        <div style={{ ...styles.Block, marginBottom: 20 }}>
                                            <img src={no_image} style={{ width: '100%', height: '100%', maxWidth: 100, maxHeight: 100, objectFit: 'contain' }}></img>
                                        </div>

                                    }
                                    <p style={{ ...styles.TextSmall, color: color.black, textAlign: 'center' }}><b>{quiz.name}</b></p>
                                    {quiz.text != '' ?
                                        <div style={{ ...styles.Block, width: '96%' }}>
                                            <p style={{ ...styles.TextXSmall, color: color.black, textAlign: 'center', marginTop: 10 }}>{quiz.text}</p>
                                        </div>
                                        : null}

                                    {customer != false ?
                                        <div style={{ ...styles.Block, width: '96%', marginTop: 10, borderTop: '1px solid #C7C7C7', paddingTop: 10 }}>
                                            <ContactCompany center company={customer.company} name={customer.name} surname={customer.surname} theme={props.theme} />
                                        </div>
                                        : null}

                                    {agent != false ?
                                        <div style={{ ...styles.Block, width: '96%', marginTop: 10 }}>
                                            <Chip label={agent.name + ' ' + agent.surname} variant='outlined' />
                                        </div>
                                        : null}

                                    {props.customer == undefined && props.save == true ?
                                        <div style={{ ...styles.Block, width: '96%', marginTop: 20 }}>
                                            <Button onClick={() => setShowCustomers(true)} style={{ ...styles.ButtonOutlined }}>{lang.customer_select_}</Button>
                                        </div>
                                        : null}
                                </>
                                : null}
                        </div>

                        <div style={{ ...styles.Block, width: '96%' }}>
                            {questions != false ? questions.map((item, index) => (
                                <div key={item.id} style={{ ...styles.BlockRow, marginTop: rowOffset, marginBottom: rowOffset, paddingBottom: rowOffset, borderBottom: color.border }}>
                                    <div style={{ ...styles.BlockLeft, width: 30 }}>
                                        <p style={{ ...styles.TextXSmall, color: color.black }}>{index + 1}.</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft }}>
                                        <p style={{ ...styles.TextXSmall }}><b>{item.name}</b></p>
                                        {item.error == true ?
                                            <Chip label={lang.quiz_answer_required} variant='outlined' size={'small'} style={{ borderColor: color.error, color: color.error }}></Chip>
                                            : null}
                                        {/*
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginTop: 10 }}>{lang.quiz_answer_user}</p>
                                        */}
                                        <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                            {item.typ == 1 ?
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <RadioGroup
                                                        value={item.result}
                                                        onChange={event => { item.result = (event.target.value); setRedraw(!redraw) }}
                                                        style={{ flexDirection: 'column' }}
                                                    >
                                                        <FormControlLabel value={1} control={<Radio style={{ padding: 0, paddingLeft: 10, paddingRight: 2 }} />} label={<p style={{ ...styles.TextTiny }}>{item.text_yes}</p>} />
                                                        <FormControlLabel value={0} style={{ marginTop: radioOffset }} control={<Radio style={{ padding: 0, paddingLeft: 10, paddingRight: 2 }} />} label={<p style={{ ...styles.TextTiny }}>{item.text_no}</p>} />
                                                    </RadioGroup>
                                                </div>
                                                : null}

                                            {item.typ == 2 ?
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <PlusMinus value={item.result} item={item} theme={props.theme} lang={props.lang} func={PlusMinusResult.bind(this)} />
                                                </div>
                                                : null}

                                            {item.typ == 3 && item.answers != false ?
                                                item.multiselect == true ?
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        {item.answers.map((answer, i) => (
                                                            <FormControlLabel key={i} label={<p style={{ ...styles.TextXSmall }}>{answer.name}</p>}
                                                                control={<Checkbox checked={answer.result == 1 ? true : false} size={'small'} onChange={event => { answer.result = (event.target.checked == true ? 1 : 0); setRedraw(!redraw); Debug(event.target.checked) }} />}
                                                            />
                                                        ))}
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        <RadioGroup
                                                            value={item.result}
                                                            onChange={event => { item.result = (event.target.value); setRedraw(!redraw) }}
                                                            style={{ flexDirection: 'column' }}
                                                        >
                                                            {item.answers.map((answer, i) => (
                                                                <FormControlLabel key={i} style={{ marginTop: radioOffset }} value={answer.id} control={<Radio style={{ padding: 0, paddingLeft: 10, paddingRight: 2 }} />} label={<p style={{ ...styles.TextXSmall }}>{answer.name}</p>} />
                                                            ))}
                                                        </RadioGroup>
                                                    </div>
                                                : null}

                                            {item.typ == 4 ?
                                                <div style={{ ...styles.Block }}>
                                                    <TextInput redraw theme={props.theme} enabled={true} value={item.result} lang={lang} label={''} func={(txt) => item.result = txt} />
                                                </div>
                                                : null}

                                            {item.typ == 5 ?
                                                <div style={{ ...styles.BlockLeft, width: 180 }}>
                                                    <TextInput date redraw theme={props.theme} enabled={true} lang={lang} value={item.result} label={''} width={180} func={(txt) => item.result = txt} />
                                                </div>
                                                : null}

                                            {item.typ == 6 ?
                                                <div style={{ ...styles.BlockLeft, width: 180 }}>
                                                    <TextInput time redraw theme={props.theme} enabled={true} lang={lang} value={item.result} label={''} width={180} func={(txt) => item.result = txt} />
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            )) : null}

                            <FormSpace height={global.list_padding} />
                        </div>

                    </div>
                    <Paper elevation={3} style={{ ...styles.Block, height: errorHeight + buttonHeight, backgroundColor: color.gray, borderTop: color.border, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {props.save == true && questions != false ?
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                    : null}
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                            </div>
                        </div>
                    </Paper>
                </div >
            </Paper >

            {showCustomers == true ?
                <CustomerSelect single showVariations={true} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomersResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => func(true)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop>
    );
}



export const QuizSelect = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = {
        enabled: true,
        ordering: global.order.down,
        order_column: 'name'
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DATA
    const [value, setValue] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 700 ? 700 : height;

    const menuHeight = 60;
    const rowHeight = 66;
    const viewWidth = '96%';

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter);

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quizes', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems));

                setFilter(filter_);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = defaultFilter;

        if (txt.length > 1) {

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = ['name'];
            db_get(0, filter);
        }
        if (txt == '') {
            db_get(0, filter);
        }

    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        func(true, item)
    }

    const NodeSelectResult = (id) => {
        var tmp = items.find(x => x.id == id);
        if (tmp != undefined) {
            func(true, tmp);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.quiz_select}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start'} style={{ ...styles.BlockLeft, width: viewWidth }}>

                            {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    onClick={() => SelectItem(item)}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                                        <div style={{ ...styles.BlockLeft, width: rowHeight + 6, height: rowHeight }}>
                                            <Avatar picture image={item.avatar} size={rowHeight - 6} theme={props.theme} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '87%', minHeight: rowHeight, justifyContent: 'center' }}>
                                            <TextLine text={item.name} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                            {item.text != '' ?
                                                <TextLine text={item.text} marginTop={2} fontSize={global.font_xsmall} color={color.black} />
                                                : null}
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div >

                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


export const QuizQuestion = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // QUIZ QUESTIONS TYPES
    const types = [
        { id: 1, name: lang.question_typ_1 },
        { id: 2, name: lang.question_typ_2 },
        { id: 3, name: lang.question_typ_3 },
        { id: 4, name: lang.question_typ_4 },
        { id: 5, name: lang.question_typ_5 },
        { id: 6, name: lang.question_typ_6 },
    ]

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [editing, setEditing] = useState(true);
    const [editID, setEditID] = useState(0);
    const [enabled, setEnabled] = useState(true);
    const [typ, setTyp] = useState(-1);
    const [question, setQuestion] = useState('');
    const [questionYes, setQuestionYes] = useState(lang.quiz_answer_yes_);
    const [questionNo, setQuestionNo] = useState(lang.quiz_answer_no_);
    const [questionScore, setQuestionScore] = useState(0);
    const [answers, setAnswers] = useState(false);
    const [answerText, setAnswerText] = useState('');
    const [answerChange, setAnswerChange] = useState('');
    const [multiSelect, setMultiSelect] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 700 ? 700 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const column1 = 160;
    const column2 = dialogWidth - column1 - 40;
    const answerHeight = 50;

    const buttonHeight = 70;
    const errorHeight = 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.quiz.id > 0) {
                // UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quiz_question', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    question_id: props.question.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setEnabled(json.question.enabled == false ? false : true);
                setTyp(json.question.typ);
                setMultiSelect(json.question.multiselect == false ? false : true);
                setQuestion(json.question.name);
                setQuestionYes(json.question.text_yes);
                setQuestionNo(json.question.text_no);

                if (json.answers != false) {
                    setAnswers(json.answers);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (question_data, answers_data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quiz_question_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    question_data: question_data,
                    answers_data: answers_data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_delete = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quiz_answer_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const Save = () => {
        var err = 0;
        setError('');
        if (question.trim() != '') {

            if (typ == 3 && answers == false) {
                err++;
            }

            if (err == 0) {
                var answers_data = [];
                let question_data = {
                    id: props.question == false ? 0 : props.question.id,
                    organization_id: props.organization.id,
                    quiz_id: props.quiz.id,
                    typ: typ,
                    multiselect: multiSelect,
                    name: question,
                    text: '',
                    score: 0,
                    text_yes: questionYes,
                    text_no: questionNo,
                }

                if (typ == 3) {
                    if (answers != false) {
                        answers.forEach(item => {
                            let data = {
                                id: item.new == true ? 0 : item.id,
                                organization_id: props.organization.id,
                                quiz_id: props.quiz.id,
                                question_id: props.question == false ? 0 : props.question.id,
                                name: item.name,
                                score: 0
                            };

                            answers_data.push(data);
                        });
                    }
                }

                Debug(question_data);
                Debug(answers_data);

                db_update(question_data, answers_data);
            } else {
                setError(lang.quiz_answers_error);
            }
        } else {
            setError(lang.required_red);
        }
    }

    const AddAnswer = () => {
        if (answerText.trim() != '') {
            var id = 1;
            var tmp = [];
            if (answers != false) {
                answers.forEach(item => {
                    if (parseInt(item.id) >= id) {
                        id = parseInt(item.id) + 1;
                    }
                });

                tmp = answers;
            }

            let data = { id: id, name: answerText, new: true };
            tmp.push(data);

            setAnswers(tmp);
            setAnswerText('');
        }
    }

    const DeleteAnswer = (item) => {
        var tmp = answers.filter(x => x.id != item.id);

        if (tmp.length == 0) {
            tmp = false;
        }
        setAnswers(tmp);

        if (item.new != true) {
            db_delete(item.id);
        }
        setRedraw(!redraw);
    }

    const EditAnswer = (item) => {
        setAnswerChange(item.name);
        setEditID(item.id);
    }

    const SaveAnswer = () => {
        if (answerChange.trim() != '') {
            var tmp = answers.find(x => x.id == editID);
            if (tmp != undefined) {
                tmp.name = answerChange;
            }

            setEditID(0);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.question == false ? lang.quiz_question_add : lang.quiz_question}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <FormSpace />
                        <FormLabel editing={editing} icon={faQuestionCircle} title={lang.quiz_question} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                        <FormYesNo value={enabled} editing={editing} title={lang.quiz_question_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                        <FormSelect require value={typ} items={types} field={'name'} editing={editing} title={lang.question_typ} width={200} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setTyp(id)} />
                        <FormText require rows={2} redraw value={question} editing={editing} title={lang.quiz_question} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setQuestion(txt)} />
                        {typ == 3 ?
                            <FormYesNo value={multiSelect} editing={editing} title={lang.quiz_answer_multiselect} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setMultiSelect(value)} />
                            : null}
                        {typ == 3 && multiSelect == true ?
                            <FormNote title={lang.quiz_answer_multiselect_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                            : null}

                        <FormSpace />
                        <Line color={color.light_gray} theme={props.theme} />
                        <FormSpace height={10} />
                        <FormLabel hide_border editing={editing} icon={faComment} title={lang.quiz_answers} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                        {typ == 1 ?
                            // **************************************************************************************************************
                            // ÁNO / NIE
                            // **************************************************************************************************************
                            <>
                                <FormText require redraw value={questionYes} editing={editing} title={lang.quiz_answer_yes} column1={column1} column2={column2} width={200} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setQuestionYes(txt)} />
                                <FormText require redraw value={questionNo} editing={editing} title={lang.quiz_answer_no} column1={column1} column2={column2} width={200} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setQuestionNo(txt)} />
                            </>
                            : null}

                        {typ == 2 ?
                            // **************************************************************************************************************
                            // ČISLO
                            // **************************************************************************************************************
                            <>
                                <FormSpace />
                                <p style={{ ...styles.TextTiny }}>{lang.question_typ_2_}</p>
                            </>
                            : null}

                        {typ == 3 ?
                            // **************************************************************************************************************
                            // ODPOVEDE
                            // **************************************************************************************************************
                            <div style={{ ...styles.Block }}>

                                <div style={{ ...styles.Block, width: '96%', marginTop: 16, marginBottom: 2 }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.quiz_answers_list}</p>
                                </div>

                                {answers != false ? answers.map((item, index) => (
                                    <div key={item.id} style={{ ...styles.Block, marginTop: 5, width: '96%', backgroundColor: color.white, minHeight: 40, borderRadius: 5 }}>
                                        {editID == item.id ?
                                            <div style={{ ...styles.BlockRow, width: '96%' }}>
                                                <div style={{ ...styles.BlockLeft, minHeight: answerHeight, width: '90%' }}>
                                                    <FormText redraw marginTop={0} value={answerChange} editing={true} title={lang.quiz_answer_text_change} column1={column1 - 10} column2={column2 - 100} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setAnswerChange(txt)} />
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 50, minHeight: answerHeight }}>
                                                    <IconButton onClick={() => SaveAnswer(item)} style={{ ...styles.ButtonIcon }}>
                                                        <FontAwesomeIcon style={{ width: 14, color: color.darker_green }} icon={faCheck} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 50, minHeight: answerHeight }}>
                                                    <IconButton onClick={() => setEditID(0)} style={{ ...styles.ButtonIcon }}>
                                                        <FontAwesomeIcon style={{ width: 10, color: color.dark_red }} icon={faXmark} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ ...styles.BlockRow, width: '96%' }}>
                                                <div style={{ ...styles.BlockLeft, width: '88%', minHeight: answerHeight }}>
                                                    <p style={{ ...styles.TextXSmall }}>{index + 1}. {item.name}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 50, minHeight: answerHeight }}>
                                                    <IconButton onClick={() => EditAnswer(item)} style={{ ...styles.ButtonIcon }}>
                                                        <FontAwesomeIcon style={{ width: 10 }} icon={faPen} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 50, minHeight: answerHeight }}>
                                                    <DeleteButton ask text={lang.quiz_answer_delete} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteAnswer(item)} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )) :
                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        <EmptyList row small lang={lang} />
                                    </div>
                                }

                                <div style={{ ...styles.Block, height: 110, backgroundColor: color.light_gray, marginTop: 20 }}>
                                    <FormText enabled={editID == 0 ? true : false} redraw value={answerText} editing={true} title={lang.quiz_answer_text_new} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setAnswerText(txt)} />
                                    <div style={{ ...styles.BlockRight, width: column1 + column2 + 10, marginTop: 5 }}>
                                        <div style={{ ...styles.BlockRight, height: 40 }}>
                                            {editID == 0 ?
                                                <Button onClick={() => AddAnswer()} style={{ ...styles.ButtonOutlined }}>
                                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPlusCircle} />
                                                    {lang.quiz_answer_add_}
                                                </Button>
                                                : null}
                                        </div>
                                    </div>
                                </div>

                                <FormSpace height={global.list_padding} />

                            </div>
                            : null}

                        {typ == 4 ?
                            // **************************************************************************************************************
                            // TEXTOVA ODPOVED
                            // **************************************************************************************************************
                            <>
                                <FormSpace />
                                <p style={{ ...styles.TextTiny }}>{lang.question_typ_4_}</p>
                            </>
                            : null}

                        {typ == 5 ?
                            // **************************************************************************************************************
                            // DÁTUM
                            // **************************************************************************************************************
                            <>
                                <FormSpace />
                                <p style={{ ...styles.TextTiny }}>{lang.question_typ_5_}</p>
                            </>
                            : null}

                        {typ == 6 ?
                            // **************************************************************************************************************
                            // ČAS
                            // **************************************************************************************************************
                            <>
                                <FormSpace />
                                <p style={{ ...styles.TextTiny }}>{lang.question_typ_6_}</p>
                            </>
                            : null}

                        {props.remote == true && props.remoteMobile == true ?
                            <FormSpace height={500} />
                            : null}

                    </div>
                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button disabled={editID == 0 ? false : true} onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: editID > 0 ? color.medium_gray : color.button_ok, marginRight: 10 }}>{lang.save}</Button>
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, marginLeft: 10 }}>{lang.cancel}</Button>
                        </div>
                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => func(true)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}