/*
*
* =================================================================================================================
* SK - LANGUAGE
* =================================================================================================================
*
*/

import { faCog, faRotate, faHome, faKeyboard, faLayerGroup, faP, faPerson, faShoppingBasket, faShoppingCart, faT, faUser, faUsers, faDollarSign, faClock, faClockRotateLeft, faCalendarDays, faM, faS } from "@fortawesome/free-solid-svg-icons";

export const sk = {
    id: 1,

    // **********************************************************************    
    // APLIKACIA
    // **********************************************************************

    title: 'Vizzie CRM',
    title_welcome: 'Vitajte v systéme Vizzie CRM',
    region: 'Krajina',
    language: 'SK',
    state: 'Štát',

    // **********************************************************************    
    // STRUKTURA MENU
    // **********************************************************************

    menu: [
        {
            id: 0,
            enabled: true,
            name: 'Domov',
            label: 'Domov',
            icon: faHome,
            color: '#FFFFFF',
        },

        /*
        *
        * =================================================================================================================
        * AGENDA
        * =================================================================================================================
        *
        */

        {
            id: 60,
            enabled: true,
            permission_column: 'agenda',
            name: 'Agenda',
            label: 'Agenda',
            admin_only: false,
            icon: faCalendarDays,
            color: '#FFDDAA',
            children: [
                {
                    id: 600,
                    enabled: true,
                    permission_column: 'calendar',
                    admin_only: false,
                    reload: true,
                    name: 'Kalendár',
                    label: 'Kalendár',
                },
                {
                    id: 601,
                    enabled: true,
                    permission_column: 'plans',
                    admin_only: false,
                    reload: true,
                    name: 'Plánovanie',
                    label: 'Plánovanie',
                },
                {
                    id: 650,
                    // ===== LINE =====
                    divider: true,
                    enabled: global.appstore == true ? false : true,
                    name: 'Nastavenie'
                },
                {
                    id: 610,
                    enabled: global.appstore == true ? false : true,
                    permission_column: 'routes',
                    admin_only: false,
                    reload: true,
                    name: 'Moje trasy',
                    label: 'Moje trasy',
                },
                {
                    id: 611,
                    enabled: global.appstore == true ? false : true,
                    permission_column: 'quizes',
                    admin_only: false,
                    reload: true,
                    name: 'Kontaktné správy',
                    label: 'Kontaktné správy',
                },
                {
                    id: 612,
                    enabled: global.appstore == true ? false : true,
                    permission_column: 'presentations',
                    admin_only: false,
                    reload: true,
                    name: 'Prezentácie',
                    label: 'Prezentácie',
                },
            ]
        },

        /*
        *
        * =================================================================================================================
        * OBCHOD
        * =================================================================================================================
        *
        */

        {
            id: 40,
            enabled: true,
            permission_column: 'business',
            name: 'Obchod',
            label: 'Obchod',
            admin_only: false,
            icon: faDollarSign,
            color: '#FFA000',
            children: [
                {
                    id: 400,
                    enabled: true,
                    permission_column: 'orders',
                    admin_only: false,
                    reload: true,
                    name: 'Objednávky produktov',
                    label: 'Prijaté objednávky produktov',
                },
                {
                    id: 401,
                    enabled: true,
                    permission_column: 'reklamacie',
                    admin_only: false,
                    reload: true,
                    name: 'Reklamácie objednávok',
                    label: 'Reklamácie objednávok',
                },
            ]
        },


        /*
        *
        * =================================================================================================================
        *   ZAKAZNICI
        * =================================================================================================================
        * 
        */

        {
            id: 30,
            enabled: true,
            permission_column: 'customers',
            name: 'Zákazníci',
            label: 'Zákazníci',
            admin_only: false,
            icon: faUser,
            color: '#70FF70',
            children: [
                {
                    id: 300,
                    enabled: true,
                    ai: true,
                    permission_column: 'customers',
                    admin_only: false,
                    reload: true,
                    name: 'Zoznam zákazníkov',
                    label: 'Zoznam zákazníkov',
                },
                {
                    id: 305,
                    enabled: true,
                    ai: true,
                    permission_column: 'contacts',
                    admin_only: false,
                    reload: true,
                    name: 'Kontakty',
                    label: 'Zoznam kontaktných osôb',
                },
                {
                    id: 306,
                    enabled: global.appstore == true ? false : true,
                    ai: true,
                    permission_column: 'emails',
                    admin_only: false,
                    reload: true,
                    name: 'E-mailová komunikácia',
                    label: 'E-mailová komunikácia',
                },
                {
                    id: 304,
                    enabled: true,
                    ai: true,
                    permission_column: 'documents',
                    admin_only: false,
                    name: 'Dokumenty',
                    label: 'Zoznam súborov a dokumentov',
                },
                {
                    id: 302,
                    // ===== LINE =====
                    divider: true,
                    enabled: true,
                    name: 'Členenie zákazníkov'
                },
                {
                    id: 303,
                    enabled: true,
                    permission_column: 'customers_groups',
                    admin_only: false,
                    name: ' Moje skupiny',
                    label: 'Moje skupiny',
                },
                {
                    id: 301,
                    enabled: true,
                    permission_column: 'customers_categories',
                    admin_only: false,
                    name: 'Kategórie zákazníkov',
                    label: 'Kategórie zákazníkov',
                },
            ]
        },


        /*
        *
        * =================================================================================================================
        * MARKETING
        * =================================================================================================================
        *
        */

        {
            id: 70,
            enabled: global.appstore == true ? false : true,
            permission_column: 'newsletter',
            name: 'Marketing',
            label: 'Marketing',
            admin_only: false,
            model: 'marketing_enabled',
            icon: faM,
            color: '#70AAFF',
            children: [
                {
                    id: 71,
                    // ===== LINE =====
                    divider: true,
                    enabled: true,
                    permission_column: 'newsletter',
                    admin_only: false,
                    name: 'Newsletter'
                },
                {
                    id: 700,
                    enabled: true,
                    permission_column: 'newsletter',
                    admin_only: false,
                    reload: true,
                    name: 'Tvorba newsletterov',
                    label: 'Tvorba newsletterov',
                },
                {
                    id: 703,
                    enabled: true,
                    permission_column: 'newsletter',
                    admin_only: false,
                    reload: true,
                    name: 'Odberatelia newslettera',
                    label: 'Odberatelia newslettera',
                },
                {
                    id: 705,
                    enabled: true,
                    permission_column: 'newsletter',
                    admin_only: false,
                    reload: true,
                    name: 'E-mailová kampaň',
                    label: 'E-mailová kampaň',
                },
                {
                    id: 710,
                    // ===== LINE =====
                    divider: true,
                    enabled: true,
                    permission_column: 'blog_internal',
                    admin_only: false,
                    name: 'Interné nástroje'
                },
                {
                    id: 715,
                    enabled: true,
                    permission_column: 'blog_internal',
                    admin_only: false,
                    reload: true,
                    name: 'Príspevky a oznamy',
                    label: 'Príspevky a oznamy pre užívateľov',
                },
            ]
        },

        /*
        *
        * =================================================================================================================
        * ESHOP
        * =================================================================================================================
        *
        */

        {
            id: 80,
            enabled: global.testing_menu,
            permission_column: 'eshop',
            name: 'E-shop',
            label: 'E-shop',
            model: 'eshop_enabled',
            admin_only: false,
            icon: faShoppingCart,
            color: '#FFAA70',
            children: [
                {
                    id: 805,
                    enabled: true,
                    permission_column: 'blog_public',
                    admin_only: false,
                    reload: true,
                    name: 'Blog',
                    label: 'Blog',
                },
                {
                    id: 800,
                    enabled: true,
                    permission_column: 'eshop',
                    admin_only: false,
                    reload: true,
                    name: 'Bannery',
                    label: 'Bannery',
                },
                {
                    id: 860,
                    // ===== LINE =====
                    divider: true,
                    enabled: true,
                    permission_column: 'eshop',
                    admin_only: false,
                    name: 'Stránky e-shopu'
                },
                {
                    id: 820,
                    enabled: true,
                    permission_column: 'eshop',
                    admin_only: false,
                    reload: true,
                    name: 'Úvodná stránka',
                    label: 'Úvodná stránka',
                },
                {
                    id: 825,
                    enabled: true,
                    permission_column: 'eshop',
                    admin_only: false,
                    reload: true,
                    name: 'Kontaktná stránka',
                    label: 'Kontaktná stránka',
                },
                {
                    id: 827,
                    enabled: true,
                    permission_column: 'eshop',
                    admin_only: false,
                    reload: true,
                    name: 'Stránka produktu',
                    label: 'Stránka produktu',
                },
                {
                    id: 830,
                    enabled: true,
                    permission_column: 'eshop',
                    admin_only: false,
                    reload: true,
                    name: 'O nás',
                    label: 'O nás',
                },
                {
                    id: 835,
                    enabled: true,
                    permission_column: 'eshop',
                    admin_only: false,
                    reload: true,
                    name: 'Náš tým',
                    label: 'Náš tým',
                },
                {
                    id: 840,
                    // ===== LINE =====
                    divider: true,
                    enabled: true,
                    permission_column: 'eshop',
                    admin_only: false,
                    name: 'Nastavenia'
                },
                {
                    id: 810,
                    enabled: true,
                    permission_column: 'eshop',
                    admin_only: false,
                    reload: true,
                    name: 'Hlavička stránky',
                    label: 'Hlavička stránky',
                },
                {
                    id: 815,
                    enabled: true,
                    permission_column: 'eshop',
                    admin_only: false,
                    reload: true,
                    name: 'Päta stránky',
                    label: 'Päta stránky',
                },
                {
                    id: 850,
                    enabled: true,
                    permission_column: 'eshop',
                    admin_only: false,
                    reload: true,
                    name: 'Nastavenia stránky',
                    label: 'Nastavenia stránky',
                },
            ]
        },

        /*
        *
        * =================================================================================================================
        * PROCESY
        * =================================================================================================================
        *
        */

        {
            id: 50,
            enabled: global.appstore == true ? false : true,
            permission_column: 'approvals',
            name: 'Procesy',
            label: 'Procesy',
            admin_only: false,
            icon: faClockRotateLeft,
            color: '#FF70FF',
            children: [
                {
                    id: 500,
                    enabled: true,
                    permission_column: 'approvals',
                    admin_only: false,
                    reload: true,
                    name: 'Požiadavky o schválenie',
                    label: 'Požiadavky o schválenie',
                },
                {
                    id: 505,
                    enabled: true,
                    permission_column: 'automate',
                    admin_only: false,
                    reload: true,
                    model: 'automation_enabled',
                    name: 'Procesy automatizácie',
                    label: 'Procesy automatizácie',
                },
            ]
        },

        /*
        *
        * =================================================================================================================
        *   PRODUKTY
        * =================================================================================================================
        * 
        */

        {
            id: 10,
            enabled: true,
            permission_column: 'products',
            name: 'Produkty',
            label: 'Správa produktov',
            admin_only: false,
            icon: faP,
            color: '#FF7070',
            children: [
                {
                    id: 100,
                    enabled: true,
                    ai: true,
                    permission_column: 'products',
                    admin_only: false,
                    name: 'Zoznam produktov',
                    label: 'Zoznam produktov',
                },
                {
                    id: 115,
                    enabled: true,
                    permission_column: 'price_lists',
                    admin_only: false,
                    name: 'Cenníky produktov',
                    label: 'Cenníky produktov',
                },
                {
                    id: 101,
                    // ===== LINE =====
                    divider: true,
                    enabled: true,
                    permission_column: 'products_categories',
                    admin_only: false,
                    name: 'Členenie produktov'
                },
                {
                    id: 103,
                    enabled: true,
                    permission_column: 'products_groups',
                    admin_only: false,
                    name: 'Moje skupiny',
                    label: 'Moje skupiny produktov',
                },
                {
                    id: 102,
                    enabled: true,
                    permission_column: 'products_categories',
                    admin_only: false,
                    name: 'Produktové kategórie',
                    label: 'Produktové kategórie',
                },
                {
                    id: 116,
                    enabled: true,
                    permission_column: 'products_flags',
                    admin_only: false,
                    name: 'Značky',
                    label: 'Zoznam značiek produktov',
                },
                {
                    id: 110,
                    // ===== LINE =====
                    divider: true,
                    permission_column: 'products_manufactures',
                    admin_only: false,
                    enabled: true,
                    name: 'Nastavenia'
                },
                {
                    id: 111,
                    enabled: true,
                    permission_column: 'products_manufactures',
                    admin_only: false,
                    name: 'Výrobcovia produktov',
                    label: 'Zoznam výrobcov produktov',
                },
                {
                    id: 112,
                    enabled: true,
                    permission_column: 'products_vendors',
                    admin_only: false,
                    name: 'Dodávatelia produktov',
                    label: 'Zoznam dodávateľov produktov',
                },
            ]
        },

        /*
        *
        * =================================================================================================================
        *   SLUZBY
        * =================================================================================================================
        * 
        */

        {
            id: 90,
            enabled: true,
            permission_column: 'services',
            model: 'eshop_enabled',
            name: 'Služby',
            label: 'Služby',
            admin_only: false,
            icon: faS,
            color: '#A0A0A0',
            children: [
                {
                    id: 900,
                    enabled: true,
                    ai: true,
                    permission_column: 'services',
                    admin_only: false,
                    name: 'Zoznam služieb',
                    label: 'Zoznam služieb',
                },
                {
                    id: 980,
                    // ===== LINE =====
                    divider: true,
                    permission_column: 'services_categories',
                    admin_only: false,
                    enabled: true,
                    name: 'Nastavenia'
                },
                {
                    id: 901,
                    enabled: true,
                    ai: true,
                    permission_column: 'services_categories',
                    admin_only: false,
                    name: 'Kategórie služieb',
                    label: 'Kategórie služieb',
                },
            ]
        },

        /*
        *
        * =================================================================================================================
        *   SKLADY
        * =================================================================================================================
        * 
        */
        {
            id: 20,
            enabled: true,
            permission_column: 'stock',
            name: 'Sklad',
            label: 'Skladové zásoby',
            admin_only: false,
            icon: faLayerGroup,
            color: '#77AAFF',
            children: [
                {
                    id: 122,
                    enabled: true,
                    ai: true,
                    permission_column: 'stock',
                    admin_only: false,
                    name: 'Skladové zásoby',
                    label: 'Skladové zásoby produktov',
                },
                {
                    id: 224,
                    // ===== LINE =====
                    divider: true,
                    enabled: true,
                    permission_column: 'stock_cards',
                    admin_only: false,
                    name: 'Úprava skladových zásob'
                },
                {
                    id: 120,
                    enabled: true,
                    permission_column: 'stock_adjustment',
                    admin_only: false,
                    name: 'Úprava zásob',
                    label: 'Úprava skladových zásob produktov',
                },
                {
                    id: 121,
                    enabled: global.appstore == true ? false : true,
                    ai: true,
                    permission_column: 'stock_transfer',
                    admin_only: false,
                    name: 'Presun zásob',
                    label: 'Presun skladových zásob produktov na sklad',
                },
                {
                    id: 126,
                    enabled: global.appstore == true ? false : true,
                    permission_column: 'stock_removals',
                    admin_only: false,
                    name: 'Vyskladnenie zásob',
                    label: 'Vyskladnenie zásob zákazníkom',
                },
                {
                    id: 223,
                    // ===== LINE =====
                    divider: true,
                    enabled: global.appstore == true ? false : true,
                    permission_column: 'stock_orders',
                    admin_only: false,
                    name: 'Naskladnenie tovaru'
                },
                {
                    id: 127,
                    enabled: global.appstore == true ? false : true,
                    permission_column: 'stock_orders',
                    admin_only: false,
                    name: 'Objednávky tovaru',
                    label: 'Objednávky tovaru na sklad',
                },
                {
                    id: 128,
                    enabled: global.appstore == true ? false : true,
                    permission_column: 'stock_orders_received',
                    admin_only: false,
                    name: 'Príjem tovaru',
                    label: 'Príjem objednaného tovaru na sklad',
                },
                {
                    id: 222,
                    // ===== LINE =====
                    divider: true,
                    enabled: true,
                    permission_column: 'stock_cards',
                    admin_only: false,
                    name: 'Nastavenia'
                },
                {
                    id: 125,
                    enabled: true,
                    permission_column: 'stocks',
                    admin_only: false,
                    name: 'Sklady',
                    label: 'Zoznam skladov produktov',
                },
                {
                    id: 123,
                    enabled: true,
                    permission_column: 'stock_cards',
                    admin_only: false,
                    name: 'Skladové karty',
                    label: 'Zoznam skladových kariet',
                },
                {
                    id: 124,
                    enabled: true,
                    permission_column: 'distributors',
                    admin_only: false,
                    distributors: true,
                    name: 'Distribútori',
                    label: 'Zoznam distribútorov objednávok',
                },
            ]
        },
        /*
        *
        * =================================================================================================================
        *   NASTAVENIE
        * =================================================================================================================
        *
        */
        {
            id: 9000,
            enabled: true,
            admin_only: false,
            name: 'Nastavenie',
            label: 'Nastavenie aplikácie',
            icon: faCog,
            color: '#FFFF70',
            children: [
                {
                    id: 9006,
                    // ===== LINE =====
                    divider: true,
                    enabled: true,
                    reload: true,
                    name: 'Užívateľ'
                },
                {
                    id: 9005,
                    enabled: true,
                    reload: true,
                    admin_only: false,
                    name: 'Môj profil',
                    label: 'Môj profil',
                },
                {
                    id: 9007,
                    // ===== LINE =====
                    divider: true,
                    enabled: true,
                    name: 'Systém CRM'
                },
                {
                    id: 9000,
                    enabled: true,
                    reload: true,
                    permission_column: 'organization',
                    admin_only: true,
                    name: 'Organizácia',
                    label: 'Nastavenia organizácie',
                },
                {
                    id: 9001,
                    enabled: true,
                    reload: true,
                    permission_column: 'users',
                    admin_only: false,
                    name: 'Užívatelia',
                    label: 'Užívatelia aplikácie',
                },
                {
                    id: 9002,
                    enabled: true,
                    reload: true,
                    permission_column: 'customization',
                    admin_only: false,
                    name: 'Aplikácia',
                    label: 'Nastavenie aplikácie',
                },
                {
                    id: 9011,
                    enabled: true,
                    permission_column: 'order_types',
                    admin_only: false,
                    order_types: true,
                    name: 'Typy objednávok',
                    label: 'Typy objednávok',
                },
                {
                    id: 9003,
                    enabled: true,
                    reload: true,
                    permission_column: 'sms_notify',
                    admin_only: false,
                    model: 'sms_enabled',
                    sms: true,
                    name: 'Notifikácie SMS',
                    label: 'Prehľad SMS notifikácii',
                },
                {
                    id: 9020,
                    enabled: true,
                    reload: true,
                    permission_column: 'conditions',
                    admin_only: false,
                    model: 'conditions_enabled',
                    name: 'Podmienky',
                    label: 'Podmienky',
                },
                {
                    id: 9021,
                    enabled: true,
                    reload: true,
                    permission_column: 'code_book',
                    admin_only: false,
                    name: 'Číselníky',
                    label: 'Číselníky',
                },
                {
                    id: 9022,
                    enabled: true,
                    reload: true,
                    permission_column: 'eshop',
                    model: 'eshop_enabled',
                    admin_only: false,
                    name: 'Doprava a platba',
                    label: 'Doprava a platba',
                },
                {
                    id: 9004,
                    enabled: global.appstore == true ? false : true,
                    reload: true,
                    permission_column: 'system_logs',
                    admin_only: true,
                    name: 'Systémové logy',
                    label: 'Systémové logy',
                },
                {
                    id: 9008,
                    // ===== LINE =====
                    divider: true,
                    enabled: global.appstore == true ? false : true,
                    admin_only: true,
                    name: 'Rozšírenia'
                },
                {
                    id: 9030,
                    enabled: global.appstore == true ? false : true,
                    reload: true,
                    permission_column: 'plugins',
                    admin_only: true,
                    name: 'Pluginy',
                    label: 'Pluginy',
                },
            ]
        },
        /*
        *
        * =================================================================================================================
        *   TEST
        * =================================================================================================================
        *
        */

        {
            id: 9999,
            enabled: global.testing_menu,
            admin_only: true,
            name: 'Test',
            label: 'Testovanie aplikácie',
            icon: faT,
            color: '#FF70FF',
            children: [
                {
                    id: 9997,
                    enabled: true,
                    name: 'Dialog 1',
                    label: 'Dialogové okno 1'
                },
                {
                    id: 9994,
                    enabled: true,
                    name: 'Dialog 2',
                    label: 'Dialogové okno 2'
                },
                {
                    id: 9998,
                    enabled: true,
                    name: 'Okno',
                    label: 'Okno - design'
                },
                {
                    id: 9999,
                    enabled: true,
                    name: 'Tvorba podmienok',
                    label: 'Tvorba podmienok'
                },
                {
                    id: 9995,
                    enabled: true,
                    name: 'Forms',
                    label: 'Forms'
                },
                {
                    id: 9996,
                    enabled: true,
                    name: 'Test AI',
                    label: 'Test AI'
                },
                {
                    id: 9993,
                    enabled: true,
                    name: 'Galéria',
                    label: 'Galéria fotografií'
                },
            ]
        },
    ],

    // **********************************************************************    
    // PRIHLASENIE DO APLIKACIE
    // **********************************************************************

    login: 'Prihlásenie',
    login_email: 'E-mailová adresa',
    loginname: 'Prihlasovacie meno (e-mail)',
    loginname_error: 'Prihlasovanie meno musí byť e-mailová adresa',
    login_data: 'Prihlasovacie údaje',
    email_error: 'Nesprávny formát e-mailovej adresy',
    username: 'Prihlasovacie meno',
    password: 'Heslo',
    password_login: 'Prihlasovanie heslo',
    password_old: 'Pôvodné heslo',
    password_old_err: 'Pôvodné heslo je nesprávne',
    password_: 'Heslo (minimálna dĺžka 8 znakov)',
    password_new: 'Nové heslo (minimálne 8 znakov)',
    password_new_account: 'Vytvorte si nové heslo (minimálne 8 znakov)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadané heslá sa nezhodujú',
    password_ok: 'Heslo bolo úspešne zmenené',
    password_error: 'Pri zmene hesla nastala chyba. Skúste požiadavku opakovať.',
    password_error_len: 'Minimálna dĺžka hesla musí byť 8 znakov',
    password_change: 'Zmeniť heslo',
    password_change_: 'Zmena hesla',
    password_strong: 'Sila hesla',
    password_change_login: 'Změna přihlašovací hesla do aplikace',
    password_change_login: 'Zmena prihlasovacie hesla do aplikácie',
    passwords: ['Nepostačujúce', 'Slabé', 'Priemerné', 'Bezpečné'],
    login_button: 'Prihlásiť sa',
    lost_password_button: 'Zabudli ste heslo?',
    login_error: 'Nesprávne prihlasovacie údaje!',
    lost_password: 'Zabudnuté heslo',
    lost_login_error: 'Zadaná e-mailová adresa sa nenachádza v systéme',
    login_pin_code_error: 'Nesprávny overovací kód',
    login_pin_code: 'Zadajte kód z e-mailu',
    login_email_send: 'Na Vašu e-mailovú adresu sme zaslali overovací kód. Skontrolujte aj spamový adresár.',
    password_changed: 'Zmena hesla prebehla úspešne',
    logout_title: 'Odhlásiť sa',
    logout_ask: 'Chcete sa odhlásiť z aplikácie?',
    email_exists: 'Zadaná e-mailová adresa už je registrovaná v CRM. Zadajte inú adresu.',
    link_error: 'Error 404 - Neplatný link',
    module: 'Modul',
    modules: 'Moduly',
    system: 'Systém',
    appearance: 'Vzhľad',
    preview_mode: 'Náhľadový mód',
    number: 'Číslo',
    no_name: 'Bez názvu',

    register: 'Registrácia',
    register_: 'Registrovať',
    register_new: 'Nová registrácia',

    // **********************************************************************    
    // SYSTEM
    // **********************************************************************

    db_error: 'Pri získavaní informácii z DB nastala chyba!',
    db_error_text: 'Skúste požiadavku opakovať.',
    enabled: 'Aktívny',
    enabled_: 'Akt.',
    disabled: 'Neaktívny',
    close: 'Zavrieť',
    ok: 'OK',
    back: 'Späť',
    continue: 'Pokračovať',
    choose: 'Výber',
    choose_: 'Vybrať',
    save: 'Uložiť',
    save_changes: 'Uložiť zmeny',
    cancel: 'Zrušiť',
    required: 'Povinný údaj',
    yes: 'Áno',
    no: 'Nie',
    node_root: 'CEO',
    node_parent: 'Vlastník',
    node_name: 'Pozícia',
    node_name_: 'Názov pozície',
    node_share: 'Zdieľať údaje',
    node_share_: 'Zdieľať údaje s kolegami',
    node_share_text: 'Údaje budú zdelané s kolegami v rámci jednej pozície',
    node_editing: 'Úprava uživateľských pozícií',
    node_edit: 'Úprava pozície',
    calculator: 'Kalkulačka',
    textfield_calc_1: 'Toto textové pole pracuje aj ako kalkulačka',
    textfield_calc_2: 'napr. (100 + 50) * 2.1',
    required_red: 'Textové polia označené červenou farbou sú povinné',
    search: 'Hľadať',
    search_filter: 'Hľadať podľa',
    order_items: 'Zoradiť položky',
    empty_list: 'Žiadne položky',
    created: 'Vytvorené',
    updated: 'Upravené',
    updated_by: 'Upravil',
    records: 'Záznamy',
    edit: 'Upraviť',
    app_language: 'Jazyk aplikácie',
    app_country: 'CRM krajina',
    app_countries: 'CRM krajiny',
    app_countries_label: 'Krajina',
    app_country_change: 'Možnosť zmeniť krajinu',
    app_country_change_note: 'Užívateľ bude mať prístup ku všetkým zaregistrovaným krajinám v CRM',
    info: 'Informácie',
    info_: 'Informácia',
    delete: 'Vymazať',
    credit: 'Kredit',
    credits: 'Kredity',
    credits_: 'Kreditov',
    credit_price: 'Cena kreditu',
    buy: 'Kúpiť',
    sms_code: 'SMS kód',
    sms_code_error: 'Nesprávny kód',
    sms_code_last_try: 'Posledný pokus',
    sms_code_sended: 'Zadajte overovací SMS kód, ktorý sme vám odoslali na váš mobil.',
    ip_address: 'IP adresa',
    date: 'Dátum',
    order: 'Objednávka',
    orders: 'Objednávky',
    application: 'Aplikácia',
    error: 'Chyba',
    sms_error_text1: 'Nepodarilo sa odoslať SMS správu',
    sms_error_text2: 'Skúste požiadavku opakovať, alebo sa poraďte s administrátorom.',
    setting: 'Nastavenie',
    settings: 'Nastavenia',
    history: 'História',
    logs_history: 'História vytváraných zmien',
    logs_status: [
        'vytvorené',
        'upravené',
        'vymazané',
        'stav zmenený na aktívne',
        'stav zmenený na neaktívne',
        'variácia vytvorená',
        'variácia upravená',
        'stav variácie zmenený na aktívna',
        'stav variácie zmenený na neaktívna',
        'zmena cien',
        'zmena nákupnej ceny',
        'zmena predajnej ceny',
        'zmena cien variácie',
        'zmena nákupnej ceny variácie',
        'zmena predajnej ceny variácie',
        'presunuté',
        'zrušené',
        'presun schválený',
        'presun zamietnutý',
        'zrušenie skladu',
        'ukončené',
        'vytvorené a ukončené',
        'vyskladnenie vykonané',
        'požiadavka na schválenie',
        'požiadavka schválená',
        'požiadavka zamietnutá',
        'vytvorené z kontaktu',
        'zmena názvu spoločnosti',
    ],
    warning: 'Upozornenie',

    default_image: 'Profilový obrázok',
    app_error: 'Pri vstupe do aplikácie nastala chyba. Skontrolujte internetové pripojenie a reštartujte aplikáciu!',
    internet_error: 'Zdá sa, že nie ste pripojený k internetu.',
    internet_error_text: 'Skontrolujte internetové pripojenie a reštartujte aplikáciu!',
    export: 'Exportovať',
    export_pdf: 'Exportovať do PDF',
    export_xls: 'Exportovať do XLS',
    export_all: 'Exportovať všetko',
    export_selected: 'Exportovať označené',
    export_note: 'Poznámka k exportu údajov',
    export_deleting: 'Platnosť súborov na stiahnutie: @days dní',
    export_files: 'Export súborov',
    export_columns: 'Vyberte stĺpce, ktoré sa budú exportovať',
    sorting: 'Triedenie',
    ordering: 'Zoraďovanie',
    items_count: 'Počet položiek',
    exported_data: 'Exportované údaje',
    exported_data_: 'Export údajov',
    stock_items: 'Skladové položky',
    customers_person: 'Zákazník',
    editing_finish: 'Ukončiť úpravy',
    finish_and_close: 'Ukončiť a uzavrieť',
    watch_dog: 'Stráženie',
    create: 'Vytvoriť',
    created_by: 'Vytvoril',
    comment: 'Komentár',
    erp_code: 'Kód v ERP',
    colors_own: 'Vlastná farba',
    colors_none: 'Bez farby',
    image_choose: 'Vybrať obrázok',
    import: 'Importovať',
    import_: 'Import',
    protocol: 'Protokol',
    authorize: 'Autorizácia',
    authorize_: 'Autorizovať',
    click_to_copy: 'Kliknutím skopírovať',

    // **********************************************************************    
    // SÚBORY
    // **********************************************************************

    file: 'Súbor',
    files: 'Súbory',
    files_and_docs: 'Súbory a dokumenty',
    files_downloads: 'Súbory na stiahnutie',
    files_library: 'Knižnica súborov',
    files_folders: 'Adresáre',
    files_folder: 'Adresár',
    files_folder_name: 'Názov adresára',
    files_folder_enabled: 'Adresár aktívny',
    files_folder_edit: 'Upraviť adresár',
    files_folder_new: 'Vytvoriť nový adresár',
    files_folder_new_: 'Nový adresár',
    files_all: 'Všetky nahraté súbory',
    files_all_: 'Všetky súbory',
    files_images: 'Obrázky',
    files_documents: 'Dokumenty',
    file_upload: 'Nahrať súbor',
    files_upload: 'Nahrať súbory',
    files_upload_title: 'Nahrať súbory na server',
    file_list: 'Zoznam súborov',
    file_delete: 'Vymazať',
    file_filename: 'Názov súboru',
    file_size: 'Veľkosť súboru',
    file_size_: 'Veľkosť',
    file_link: 'Cesta k súboru',
    file_downloaded: 'Počet stiahnutí',
    file_viewed: 'Počet zobrazení',
    files_upload_folder: 'Nahrať súbory do adresára',
    files_upload_start: 'Spustiť nahrávanie súborov',
    files_select: 'Pridať súbory',
    files_copy: 'Kopírovať cestu',
    files_copied: 'Cesta k súboru bola skopírovaná do clipboardu.',
    file_preview: 'Náhľad súboru',
    file_delete_ask: 'Vymazať súbor z knižnice?',
    file_cloud: 'Úložisko',
    file_cloud_space: 'Využitie úložiska',
    file_cloud_free_space: 'Voľné miesto',
    file_cloud_full: 'Úložisko súborov je plné',
    file_cloud_full_note: 'Ak chcete nahrať nové súbory, uvoľnite miesto v úložisku!',
    file_cloud_not_enoght_space: 'Nedostatok miesta v úložisku',
    files_exported: 'Exportované súbory',
    file_size_error: 'Prekročená max. veľkosť súboru na nahratie',
    file_size_error_note: 'Maximálna povolená veľkosť jedného súboru je @size MB',
    file_folder_delete: 'Vymazať adresár?',
    file_folder_delete_note: 'Obsah adresára nebude vymazaný. Súbory sa nevymažú!',
    file_library: 'Knižnica',
    file_library_select: 'Vybrať z knižnice',
    file_library_select_title: 'Výber súboru',
    file_photo_select: 'Nahrať fotografiu',
    files_shared: 'Zdieľané',
    files_shared_note: 'Zdieľanie súboru s užívateľmi CRM',
    files_sharing: 'Zdieľanie',
    files_sharing_label: 'Zdieľanie súboru v CRM',
    files_public: 'Verejné',
    files_public_note: 'K súboru bude mať prístup každý užívateľ CRM v rámci spoločnosti',
    files_share_permissions: 'Nemáte práva k úprave dokumentu',
    file_exported: 'Exportovaný súbor',
    file_copy_to_folder: 'Kopírovať súbor',
    file_copy_to_folder_note: 'Kopírovať exportovaný súbor do adresára',
    file_copy: 'Kopírovať',
    file_email_send_label: 'Odoslať súbor e-mailom',
    file_email_send: 'Odoslať súbor e-mailom',

    download: 'Stiahnúť súbor',
    downloaded: 'Stiahnutý súbor',
    close_all: 'Zavrieť všetko',
    open_all: 'Otvoriť všetko',
    open: 'Otvoriť',
    hide_decimal: 'Nezobrazovať desatinné miesta',
    show_decimal: 'Zobrazovať desatinné miesta',
    hide_manufacture: 'Nezobrazovať výrobcu',
    show_manufacture: 'Zobrazovať výrobcu',
    date_include: 'Dátum vrátane zvoleného dňa',
    piece: 'ks',
    tags: 'Kľučové slová',
    tags_text: 'Jednotlivé slová oddeľujte medzerou',
    undefined: 'Nedefinovaný',
    batch_update: 'Dávková úprava',
    show_variations: 'Zobrazovať variácie',
    hide_variations: 'Nezobrazovať variácie',
    temporary: 'Dočasná',
    temporary_: 'Dočasný',
    export_none_error: 'Nie sú označené žiadne položky',
    export_none_error_: 'Označte položky, ktoré budú exportované',
    change: 'Zmena',
    changes: 'Zmeny',
    reset: 'Reset',
    text_percentage: 'Percentá / Koeficient',
    text_percentage_text_1: 'Vklad koeficientu',
    text_percentage_text_2: 'Koeficient napr. 1.10 znamená +10%, 0.95 znamená -5%',
    text_percentage_text_3: 'Vklad percent - musí byť použitý znak % na konci',
    text_percentage_text_4: 'Percená napr. +10%, -5%',
    new_record: 'Nový záznam',
    update_record: 'Úprava záznamu',
    automatic: 'Automatické',
    manual: 'Manuálne',
    code_name: 'Názov kódu',
    bytes_left: 'Zostáva @byte Bytes na vloženie údajov',
    bytes_left_out: 'Na vloženie údajov nie je dostatok miesta',
    insert_press_enter: 'Po zadaní stlačne "Enter"',
    delete_item_ask: 'Vymazať položku?',
    cancel_filters: 'Zrušiť filtre',
    filters: 'Filtrovanie záznamov',
    status: 'Stav',
    count: 'Počet',
    label: 'Názov',
    system_match: 'Podľa systémového nastavenia',
    priority: 'Poradie',
    priority_change: 'Zmena poradia',
    priority_items_change: 'Zmena poradia položiek',
    priority_up: 'Presunúť vyššie',
    priority_down: 'Presunúť nižšie',
    refresh: 'Znovu načítať',
    show_detail_settings: 'Zobraziť detailné nastavenie',
    hide_detail_settings: 'Skryť detailné nastavenie',
    make_copy: 'Vytvoriť kópiu',
    copy: 'Kópia',

    all_records: 'Všetky záznamy',
    enter_value: 'Vklad hodnoty',
    only_on_stock: 'Zobraziť iba skladom',

    approve: 'Schváliť',
    deny: 'Zamietnúť',
    statistics: 'Štatistiky',
    statistic: 'Štatistika',
    upload_image_error: 'Chyba pri uploade obrázku!',
    upload_image_error_note: 'Nepodporovaný formát, alebo typ obrázku!',

    // **********************************************************************    
    // CALENDAR
    // **********************************************************************

    year: 'Rok',
    month: 'Mesiac',
    day: 'Deň',

    // **********************************************************************    
    // CONDITIONS - PODMIENKY
    // **********************************************************************

    cond: 'Podmienka',
    conds: 'Podmienky',
    cond_designer: 'Tvorba podmienok',
    cond_next: 'Ďalšia podmienka',
    cond_exe: 'Spustenie podmienky',
    cond_true: 'Splnená podmienka',
    cond_false: 'Nesplnená podmienka',
    cond_yes: 'Áno',
    cond_no: 'Nie',
    cond_result: 'Výsledok',
    cond_result_condition: 'Výsledok podmienky',
    cond_pattern: 'Vzor vyhodnocovania',
    cond_undefined: 'Nedefinovaný',
    cond_and: 'A',
    cond_or: 'Alebo',
    cond_include: 'Obsahuje',
    cond_starts: 'Začína',
    cond_ends: 'Končí',
    cond_name: 'Názov podmienky',

    cond_read: 'Pri načítaní údajov',
    cond_write: 'Pri zápise údajov',
    cond_choose: 'Výber',
    cond_error: 'Nesprávne vyplnené údaje',
    cond_result_error: 'Hodnoty výslednej premennej nie sú definované',
    cond_condition_error: 'Podmienka nesmie byť prázdna',

    cond_product_quantity: 'Počet kusov',
    cond_product_label: 'Názov produktu',
    cond_product_enabled: 'Produkt aktívny',
    cond_date: 'Dátum',
    cond_enabled: 'Aktívny',
    cond_price: 'Cena',
    cond_time: 'Čas',
    cond_email: 'E-mail',
    cond_stock: 'Skladom',

    cond_new: 'Nová podmienka',
    cond_update: 'Úprava podmienky',
    cond_note: 'Vytvorenie podmieneného zobrazovania produktu',
    cond_active: 'Podmienka - aktívna',
    cond_delete_ask: 'Vymazať podmienku?',
    cond_copy_value: '"..." pôvodná hodnota',
    cond_used: 'Využitie',
    cond_used_items: 'Využitie podmienky',

    // **********************************************************************    
    // NASTAVENIE ORGANIZACIE / APLIKACIE
    // **********************************************************************

    organization: 'Organizácia',
    organization_settings: 'Nastavenie organizácie',
    organization_name: 'Názov',
    organization_name_: 'Názov spoločnosti',
    organization_info: 'Informácie o organizácii',
    organization_verified: 'Registrácia v CRM bola úspešne overená',
    organization_to_crm: 'Prejsť do CRM',
    organization_logo: 'Logo organizácie',

    organization_cancel_account: 'Zrušenie účtu v aplikácii',
    organization_cancel_account_label: 'Zrušenie účtu v aplikácii Vision CRM',
    organization_cancel_account_yes: 'Odoslať požiadavku',
    organization_cancel_account_text: 'Požiadať o zrušenie účtu',
    organization_account_cancel_option_1: 'Dočasné pozastavenie',
    organization_account_cancel_option_1_note: 'Účet bude dočasne vypnutý a v budúcnosti bude opäť možné účet používať.',
    organization_account_cancel_option_2: 'Zrušenie účtu',
    organization_account_cancel_option_2_note: 'Účet bude zrušený a prístupové údaje vymazané do 24 hod.',
    organization_account_cancel_option_3: 'Zrušenie účtu a vymazanie údajov',
    organization_account_cancel_option_3_note: 'Účet bude zrušený, osobné údaje správcu účtu a karty zákazníkov budú vymazané do 10 dní.',
    organization_account_cancel_send: 'Odoslať požiadavku?',
    organization_account_cancel_send_text: 'Požiadavka bude odoslaná správcovi systému',
    organization_account_cancel_sended: 'Požiadavka úspešne odoslaná',
    organization_account_cancel_sended_text: 'Váš účet bude zrušený podľa zadanej požiadavky do 24 hod.',
    organization_account_cancel_sended_text_logout: 'Z aplikácie budete odhlásený!',


    app_settings: 'Nastavenie aplikácie',
    app_customize: 'Prispôsobenie',
    application: 'Aplikácia',
    application_customize: 'Prispôsobenie aplikácie',
    customize_name: 'Zobrazovanie mena',
    customize_name_1: 'Meno Priezvisko',
    customize_name_2: 'Priezvisko Meno',

    system_settings: 'Nastavenie systému CRM',
    system_precision: 'Počet desatiných miest',
    system_precision_note: 'Počet desatiných miest zobrazovaných v cenách',
    system_precision_1: '2 desatiné miesta',
    system_precision_2: '3 desatiné miesta',
    system_price_creator: 'Tvorba predajných cien',
    system_price_coeficient: 'Koeficient výpočtu ceny',
    system_price_coeficient_enable: 'Prepočet cez koeficient',
    system_price_coeficient_note: 'Predajná cena sa vypočíta (nákupná cena * koeficient)',
    system_price_coeficient_category_note: 'Výpočet predajnej ceny cez koeficient pre celú kategóriu',

    modules_settings: 'Nastavenie jednotlivých modulov',
    modules_numbering: 'Číslovanie záznamov',
    modules_numbering_default: 'Prednastavené číslovanie',
    modules_sms_pattern: 'SMS nákupy kreditu',
    modules_product_pattern: 'Číslovanie produktov',
    modules_product_auto: 'Automatické číslovanie nových produktov',

    additional_codes: 'Dodatočné kódy',
    additional_codebooks: 'Dodatočné číselníky',

    products_additional_codes: 'Dodatočné kódy produktov',
    products_additional_codebooks: 'Dodatočné číselníky produktov',

    product_searchings: 'Triedenie produktov',
    manufacturers_search: 'Podľa výrobcov',
    vendors_search: 'Podľa dodávateľov',

    show_cost_prices: 'Zobrazovať nákupné ceny',
    show_prices_dph: 'Zobrazovať ceny s dph',

    // **********************************************************************    
    // USER / ORGANIZATION / COMPANY
    // **********************************************************************

    user: 'Užívateľ',
    users: 'Užívatelia',
    user_enabled: 'Aktívny',
    users_list: 'Zoznam užívateľov',
    users_groups: 'Skupiny',
    users_group: 'Skupina',
    users_group_new: 'Nová skupina',
    users_groups_: 'Skupiny užívateľov',
    users_roles: 'Pozície',
    users_roles_: 'Uživateľské pozície',
    users_roles_text: 'Na tejto stránke môžete nadefinovať ako budú zdieľané údaje medzi jednotlivými užívateľmi v rámci jednej organizácie.',
    users_role: 'Pozícia',
    users_role_missing: 'Chýba pozícia',
    use_roles: 'Používať užívateľské pozície',
    user_new: 'Nový užívateľ',
    user_info: 'Informácie o užívateľovi',
    user_register: 'Registrácia nového užívateľa',
    user_groups: 'Užívatelské skupiny',
    user_groups_text: 'Skupiny dovoľujú radenie jednotlivých užívateľov do skupín.',
    user_group_enabled: 'Aktívna skupina',
    user_group_label: 'Názov skupiny',
    user_group_info: 'Informácie',
    user_group_edit: 'Úprava skupiny',
    users_group_list: 'Zoznam užívateľov v skupine',
    users_groups_all: 'Všetky skupiny',
    super_admin: 'Super admin',
    user_invite: 'Odoslať pozvánku',
    users_add: 'Pridať užívateľov',
    user_select: 'Výber užívateľa',
    company_name: 'Názov firmy',

    user_register_stock: 'Vytvoriť sklad',
    user_register_stock_note: 'Užívateľovi bude vytvorený a pridelený sklad',

    basic_info: 'Základné informácie',
    contact_info: 'Kontaktné údaje',
    name: 'Meno',
    surname: 'Priezvisko',
    name_surname: 'Meno a priezvisko',
    alias: 'Alias',
    address: 'Adresa',
    street: 'Ulica',
    psc: 'PSČ',
    town: 'Mesto',
    state: 'Štát',
    birtdate: 'Dátum narodenia',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ DPH',
    bank: 'Názov banky',
    iban: 'IBAN',
    swift: 'SWIFT',
    currency: 'Platobná mena',
    currency_: 'Mena',
    phone: 'telefón',
    mobil: 'mobil',
    mobil_international: 'Číslo musí byť v medzinárodnom tvare (+421...)',
    email: 'e-mail',
    emails: 'e-maily',
    email_address: 'e-mailová adresa',
    url: 'Webová adresa',
    note: 'Poznámka',
    firm_data: 'Firemné údaje',
    contact_data: 'Kontaktné údaje',
    dph_pay: 'Platca dph',

    user_profile: 'Môj profil',
    user_profile_info: 'Základné informácie',
    user_profile_login: 'Prihlasovanie do aplikácie',
    user_profile_2fa: 'Dvojfaktorové overenie',
    user_profile_2fa_note: 'Dvojfaktorové overenie pri prihlasovaní cez SMS správu',
    user_profile_mobil_verify: 'Overenie mobilného čísla',
    user_profile_mobil_text: 'Pre spustenie dvojfaktorového overovania cez SMS je potrebné overenie mobilného čísla.',
    user_profile_mobil_number: 'Na vaše mobilné číslo @mobil bude odoslaný verifikačný kód',
    user_profile_mobil_number_send: 'Odoslať kód',
    user_profile_mobil_number_cancel: 'Vypnúť overovanie',
    user_profile_mobil_verified: 'Overené číslo',

    // **********************************************************************    
    // FOTOGRAFIE
    // **********************************************************************

    photos: 'Fotografie',
    photo: 'Fotografia',
    photo_select_title: 'Výber a úprava fotografie',
    photo_select: 'Vybrať fotografiu',
    photo_change: 'Zmeniť fotografiu',
    photo_delete: 'Vymazať',
    photo_delete_text: 'Chcete vymazať fotografiu?',
    photo_save: 'Vložiť',
    photo_min: 'Minimálna veľkosť',

    // **********************************************************************    
    // PERMISSIONS - povolenia
    // **********************************************************************

    permission: 'Povolenie',
    permissions: 'Povolenia',
    profil: 'Uživateľký Profil',
    profiles: 'Uživateľské Profily',
    profiles_text: 'Profil je súbor povolení pre jednotlivé moduly v aplikácii. Jednotlivým užívateľom možete priradiť rôzne profily.',
    profil_new: 'Nový profil',
    profil_edit: 'Úprava profilu',
    profil_name: 'Názov profilu',
    profil_enabled: 'Aktívny profil',

    permission_none: 'Žiadne',
    permission_view: 'Prehliadať',
    permission_create: 'Vytvárať',
    permission_edit: 'Upravovať',
    permission_delete: 'Vymazávať',
    permission_export: 'Exportovať',

    permissions_modules: 'Povolenia pre jednotlivé moduly',
    permissions_credit_buy: 'Kúpa SMS kreditu',
    permissions_stocks_approve: 'Vykonávať presuny',
    permissions_stocks_approve_0: 'Potrebný súhlas',
    permissions_stocks_approve_1: 'Bez súhlasu',
    permissions_stocks_approve_note_0: 'K vykonávaniu presunov je potrebný súhlas nadriadeného',
    permissions_stocks_approve_note_1: 'Vykonávanie presunov bez potrebného súhlasu nadriadeného',

    permissions_customers: 'Zákazníci',
    permissions_customers_groups: 'Skupiny zákazníkov',
    permissions_customers_limited: 'Obmedzený prístup',
    permissions_customers_limited_note_0: 'Užívateľ má prístup ku všetkým zákazníkom',
    permissions_customers_limited_note_2: 'Užívateľ má prístup ku všetkým zákazníkom na základe pozície',
    permissions_customers_limited_note_1: 'Užívateľ má prístup len k prideleným zákazníkom',

    permissions_contacts: 'Kontakty',
    permissions_contacts_limited: 'Obmedzený prístup',
    permissions_contacts_limited_note_0: 'Užívateľ má prístup ku všetkým kontaktom',
    permissions_contacts_limited_note_2: 'Užívateľ má prístup ku všetkým kontaktom na základe pozície',
    permissions_contacts_limited_note_1: 'Užívateľ má prístup len k svojím kontaktom',

    permissions_orders_limited: 'Obmedzený prístup',
    permissions_orders_limited_note_0: 'Užívateľ má prístup ku všetkým objednávkam',
    permissions_orders_limited_note_2: 'Užívateľ má prístup ku všetkým objednávkam na základe pozície',
    permissions_orders_limited_note_1: 'Užívateľ má prístup len k objednávkam svojich zákazníkov',


    permissions_make_copy: 'Vytvorenie kópie profilu',
    permissions_make_copy_ask: 'Chcete vytvoriť kópiu daného profilu?',
    permissions_make_copy_text: 'Profil bude označený názvom "kópia"',


    // **********************************************************************    
    // PRODUKTY
    // **********************************************************************

    products: 'Produkty',
    product: 'Produkt',
    products_categories: 'Produktové kategórie',
    products_category_labels: 'Preklady názvu kategórie pre zákaznícky portál',
    products_groups: 'Produktové skupiny',
    products_manufactures: 'Výrobcovia produktov',
    products_vendors: 'Dodávatelia produktov',
    products_list: 'Zoznam produktov',

    product_info: 'Informácie o produkte',
    product_select: 'Výber produktu',
    product_name: 'Názov produktu',
    product_code: 'Kód produktu (SKU)',
    product_code_: 'Kód',
    product_code_external: 'Dodatočný kód',
    product_code_external_: 'Doplnkové kódy produktu',
    product_code_error: 'Produkt s daným kódom sa už nachádza v databáze',
    product_code_ean: 'Kódy EAN',
    product_code_vendor: 'Kódy dodávateľov',
    product_code_vendor_: 'Kód dodávateľa',
    product_tags: 'Kľúčové slová',
    product_new: 'Nový produkt',
    product_enabled: 'Produkt aktívny',
    product_image: 'Obrázok produktu',
    product_typ: 'Typ produktu',
    product_typ_normal: 'Produkt',
    product_typ_variant: 'Variačný produkt',
    product_variations: 'Variácie produktu',
    product_variations_enabled: 'Produkt obsahuje variácie',
    product_variations_text: 'Pred vytvorením variácií je potrebné vytvoriť a uložiť hlavný produkt',
    product_date_start_: 'Začiatok predaja produktu',
    product_date_start: 'Začiatok predaja',
    product_date_end: 'Koniec predaja',
    product_date_endless: 'Neobmedzený predaj',
    product_prices: 'Cena produktu',
    product_cost: 'Nákupná cena',
    product_price: 'Predajná cena',
    product_price_coeficient: 'Predajná cena bude vypočítana cez koeficient',
    product_price_retail: 'Doporučená cena',
    product_quantity_stock_: 'Skladom',
    product_ranged: 'Časovo obmedzené',
    product_range_disabled: 'Dočasne vypnutý',
    product_price_ranged: 'Dočasná cena produktu',
    product_price_ranged_: 'Dočasná cena',
    product_price_start: 'Začiatok zmeny ceny',
    product_price_end: 'Koniec zmeny ceny',
    product_dph: 'Dph',
    product_dph_note: 'Ak nie je vyplnené, bude použitá systémová DPH',
    product_show_dph: 'Zobrazovať DPH',
    product_show_typ: 'Zobrazovať typ',
    product_stock_information: 'Skladové informácie',
    product_description_information: 'Popis produktu',
    product_description_short: 'Krátky popis',
    product_quantity_stock: 'Počet kusov skladom',
    product_quantity_package: 'Počet kusov v balení',
    product_quantity_reserved: 'Počet kusov v rezervácii',
    product_quantity_reserved_: 'Rezervácie',
    product_reserved: 'Rezervácie',
    product_reserved_: 'Rezerv.',
    product_details: 'Popis balenia',
    product_details_note: 'Stručný popis balenia produktu (váha, objem,...)',
    product_unit: 'Jednotka',
    product_settings: 'Nastavenia produktu',
    product_guarantee: 'Dĺžka záruky (mesiacov)',
    product_guarantee_: 'Dĺžka záruky',
    product_date_start_error: 'Dátum začiatku predaja musí byť nižší ako dátum konca predaja!',
    product_price_start_error: 'Dátum začiatku dočasnej ceny musí byť nižší ako dátum konca dočasnej ceny!',
    product_variations_name: 'Názov variácii',
    product_variation: 'Variácia',
    product_variations_: 'Variácie',
    product_variation_new: 'Nová variácia',

    product_variation_add_product: 'Doplniť produkt',
    product_variation_add_product_label: 'Vloženie produktu do variácie',
    product_variation_add_product_ask: 'Chcete vložiť existujúci produkt do variačného produkt?',
    product_variation_add_product_note: 'Zvolený produkt sa stane súčasťou variačného produktu.',
    product_variation_eject: 'Osamostatniť produkt',
    product_variation_eject_ask: 'Chcete vytvoriť z variácie samostatný produkt?',
    product_variation_eject_note: 'Z produktu sa stane štandartný typ, nastavenia budú zachované.',

    product_variation_enabled: 'Variácia aktívna',
    product_variation_info: 'Informácie o variácii',
    product_variation_code: 'Kód variácie',
    product_variation_name: 'Názov variácie',
    product_variation_price: 'Cena variácie',
    product_variation_ranged: 'Dočasná cena variácie',
    product_variation_image: 'Obrázok variácie',
    product_variation_copy_price: 'Zhodná s produktom',
    product_sub_products_add: 'Pridať produkt',
    product_sub_products_label: 'Produkty v balíčku',
    product_delete_from_list: 'Vymazať produkt zo zoznamu?',

    product_type_standart: 'Štandartný',
    product_type_variant: 'Variačný',
    product_type_group: 'Balíček',

    products_filter_enabled: 'Zobraziť len aktívne',
    products_filter_unabled: 'Zobraziť neaktívne',
    products_filter_not_started: 'Zobraziť naplánované',
    products_filter_ended: 'Zobraziť ukončené',
    products_filter_condition: 'Zobraziť len s podmienkou',
    products_filter_not_published: 'Zobraziť nepublikované',
    products_filter_published: 'Zobraziť publikované',
    filter_not_portal: 'Zobraziť bez prístupu na portál',
    products_filter_no_category: 'Zobraziť bez priradenej kategórie',

    product_group: 'Skupina',
    product_groups: 'Skupiny',
    product_groups_: 'Skupiny produktov',
    product_group_: 'Skupina produktov',
    product_group_new: 'Nová skupina',
    product_group_edit: 'Úprava skupiny',
    product_group_name: 'Názov skupiny',
    product_group_enabled: 'Skupina aktívna',
    product_group_number: 'Počet produktov',
    product_group_products_add: 'Pridať produkty',
    product_group_create_error: 'Označte produkty, ktoré chcete pridať do skupiny',
    product_group_delete: 'Odstrániť skupinu',
    product_group_delete_ask: 'Chcete odstrániť skupinu produktov?',
    product_group_delete_ask_note: 'Vymaže sa len skupina, produkty budú zachované.',

    product_categories: 'Kategórie',
    product_category: 'Kategória',
    product_subcategories: 'Podkategórie',
    product_category_new: 'Nová kategória',
    product_category_enabled: 'Kategória aktívna',
    product_category_name: 'Názov kategórie',
    product_category_parent: 'Vlastník kategórie',
    product_category_update: 'Úprava kategórie',
    product_category_main: 'Hlavná kategória',
    product_subcategory_add: 'Pridať podkategóriu',
    product_portal_enabled: 'Publikovaný',
    product_portal: 'Portál',
    product_portal_enabled_note: 'Zobrazovanie produktu na e-shope a zákazníckom portáli',

    product_copy_new: 'Nastavenia nového produktu',
    product_copy_new_note: 'Po vytvorení kópie bude produkt registrovaný ako nový produkt.',
    product_copy: 'Duplikovať produkt',
    product_copy_info: 'Tvorba kópie produktu je možná až po ukončení úprav produktu.',
    product_copy_: 'Vytvoriť produkt',
    product_copy_note: 'Vytvoriť novú kópiu produktu',
    product_copy_stock_card_share: 'Skladová karta nového produktu bude zdielať kartu pôvodného produktu',
    product_copy_stock_card_new: 'Produktu bude vytvorená nová skladová karta',
    product_copy_stock_card_1: 'Vytvoriť novú',
    product_copy_stock_card_0: 'Ponechať pôvodnú',
    product_copy_enabled: 'Stav nového produktu po vytvorení kópie',
    product_copy_country: 'CRM krajina v ktorej bude nový produkt registrovaný',
    product_select_variant: 'Vyberte produkt, ktorý bude vložený ako variácia k danému produktu',

    product_variant_types: ['Obrázok', 'Text'],
    product_variant_typ: 'Zobrazovanie variácií',

    product_show_cost: 'Zobrazovať nákupnú cenu pri produktoch',

    product_book: 'Dodatočný číselník',
    product_book_name: 'Názov číselníku',

    product_quick_search: 'Rýchle vyhľadanie a vloženie produktu',
    product_quick_search_: 'Zadajte názov, alebo kód produktu',
    products_costs: 'Nákupné ceny',
    product_packet_quantity: 'ks v balíčku',
    products_settings_b2b_b2c: 'Delenie na B2B a B2C',
    products_settings_b2b_b2c_note: 'Možnosť delenia produktov pre B2B a B2C zákazníkov',
    products_b2b_b2c: 'Produkt určený pre',

    vendors: 'Dodávatelia',
    vendor: 'Dodávateľ',
    vendor_enabled: 'Dodávateľ aktívny',
    vendor_name: 'Meno dodávateľa',
    vendor_new: 'Nový dodávateľ',
    vendor_update: 'Úprava dodávateľa',

    manufacturers: 'Výrobcovia',
    manufacturer: 'Výrobca',
    manufacturer_enabled: 'Výrobca aktívny',
    manufacturer_name: 'Meno výrobcu',
    manufacturer_new: 'Nový výrobca',
    manufacturer_update: 'Úprava výrobcu',

    price_list: 'Cenník produktov',
    price_list_: 'Cenník',
    price_lists: 'Cenníky produktov',
    price_lists_: 'Cenníky',
    price_list_new: 'Nový cenník',
    price_list_name: 'Názov cenníka',
    price_list_enabled: 'Cenník aktívny',
    price_list_ranged: 'Neobmedzená',
    price_list_date_from: 'Platnosť od',
    price_list_date_to: 'Platnosť do',
    price_list_life: 'Platnosť cenníka',
    price_list_all_products: 'Všetky produkty',
    price_list_all_products_note: 'Zahrnúť do cenníka všetky produkty, alebo výber produktov',
    price_list_products_selected: 'Výber produktov',
    price_list_source: 'Počítať z ceny',
    price_list_source_note: 'Výsledne ceny budú počítané ...',
    price_list_source_1: 'z nákupnej ceny produktov',
    price_list_source_2: 'z predajnej ceny produktov',
    price_list_coeficient: 'Koeficient výpočtu ceny',
    price_list_ranges: 'Množstevné zľavy',
    price_list_groups: 'Cenové skupiny',
    price_list_group_new: 'Nová skupina',
    price_list_group: 'Cenová skupina',
    price_list_group_enabled: 'Skupina aktívna',
    price_list_group_name: 'Názov skupiny',
    price_list_group_coeficient_note: 'Výpočet ceny pre všetky produkty v danej skupine',
    price_list_product_delete: 'Vymazať zo skupiny',
    price_list_price_default: 'Pôvodná cena',
    price_list_price_product: 'Cena produktu',
    price_list_price_product_change: 'Zmena ceny produktu',
    price_list_price_product_changes: 'Zmena ceny',
    price_list_price_enter: 'Zadať pevnú cenu',
    price_list_discount: 'Zľava',
    price_list_add: 'Prirážka',
    price_list_discount_add: 'Zľava / Prirážka',
    price_list_products_in_category: 'Produkty v kategórii',
    price_list_groups_from_categories: 'Vytvoriť skupiny',
    price_list_groups_from_categories_note: 'Vytvoriť samostané skupiny pre každú podkategóriu?',
    price_list_groups_subcategories: 'Zahrnúť podkategórie',
    price_list_groups_subcategories_note: 'Do kategórie budú zahrnuté aj produkty podkategórií',
    price_list_show_changes: 'Zobrazovať len zmeny',
    price_list_change_price: 'Aplikovať zmenu ceny',
    price_list_change_price_note: 'Zadať pevnú cenu, alebo koeficient prepočtu',
    price_list_manual_price: 'Pevná cena',
    price_list_coefficient: 'Koeficient',
    price_list_typ: 'Typ cenníka',
    price_list_typ_all: 'Všetky produkty',
    price_list_typ_select: 'Výber produktov',

    price_list_quantity_discount: 'Množstevná zľava',
    price_list_quantity_discounts: 'Množstevné zľavy',
    price_list_quantity_discounts_use: 'Používať množstevné zľavy',
    price_list_quantity_discounts_use_note: 'Zľava bude vypočítaná na základe množstva objednaných kusov produktu',
    price_list_quantity_discounts_coefficient: 'Zľava',
    price_list_quantity_discounts_from: 'od kusov (vrátane)',
    price_list_quantity_discounts_to: 'do (vrátane)',
    price_list_quantity_discounts_coef_price: 'Zadávajte len koeficient, alebo cenu. Nepoužité musí byť prázdne!',
    price_list_quantity_discounts_to_end: 'pre (a viac) vložte najvyššie možnú hodnotu - napr. 999999',
    price_list_quantity_discount_add: 'Pridať zľavu',

    price_list_currency_coeficient: 'Prepočet na peňažnú menu',
    price_list_currency_coeficient_note: 'Prepočet',

    price_flatten: 'Zjednotenie predajnej ceny',
    price_flatten_: 'Výpočet predajnej ceny z koeficientu',
    price_flatten_note: 'Predajná cena bude vypočítaná (nákupná cena * koeficient) a prepočet cez koeficient bude vypnutý',

    // **********************************************************************    
    // SYSTEM LOGS
    // **********************************************************************

    system_logs: 'Systémové logy',
    logs_logins: 'Prihlasovanie',
    logs_cost_changed: 'Zmena nákupnej ceny z @cost_from @cur na @cost_to @cur',
    logs_price_changed: 'Zmena predajnej ceny z @cost_from @cur na @cost_to @cur',
    logs_product_time: 'Dátum',
    logs_product_value: 'Nákupná a predajná cena',
    logs_product_cost_label: 'Nákupná cena',
    logs_product_sell_label: 'Predajná cena',
    logs_product_label: 'Vývoj cien produktu v čase',

    // **********************************************************************    
    // SMS - NOTIFIKACIE
    // **********************************************************************    

    sms_notifies: 'SMS notifikácie',
    sms_notify: 'SMS notifikácia',
    sms_notify_text: 'SMS notifikácia vám umoží posielať informačné SMS správy vašim zákazníkom a užívateľom CRM',
    sms_notify_enable: 'Povoliť sms notifikáciu pre CRM',
    sms_notify_settings: 'Nastavenie notifikácií',
    sms_notify_sender: 'Odosielateľ SMS',
    sms_notify_sender_note: 'max.11 znakový text s názvom odosielateľa SMS bez diakritiky',
    sms_notify_unicode: 'Používať diakritiku',
    sms_notify_unicode_note: 'Používať diakritiku v texte notifikácie',
    sms_notify_label: 'Prehľad SMS notifikácii',
    sms_notify_message: 'Text správy',
    sms_notify_credit_buy: 'Dobiť kredit',
    sms_notify_credit_buy_label: 'Dobiť SMS kredit',
    sms_notify_credit_status: 'Aktuálny stav kreditu',
    sms_notify_credit_stat: 'Prehľad využívania kreditu',
    sms_notify_credit_buyed: 'Zakúpený kredit',
    sms_notify_credit_used: 'Použitý kredit',
    sms_notify_credit_receiver: 'Príjemca',
    sms_notify_credit_sender: 'Odosielateľ',
    sms_notify_credit_list: 'Zoznam SMS správ',
    sms_notify_credit_details: 'Informácie o odoslanej správe',
    sms_notify_credit_stat: 'Využitie kreditu',
    sms_notify_credit_stat_label: 'Využitie kreditu v jednotlivých mesiacoch',
    sms_credit_watchdog: 'Upozornenie',
    sms_credit_watchdog_note: 'Upozorniť, ak kredit klesne pod stanovenú výšku',
    sms_credit_watchdog_email: 'Upozornenie e-mail',
    sms_credit_watchdog_email_note: 'e-mail, kde bude poslané upozornenie',
    sms_credit_buy_note: 'Výška zostavajúceho kreditu bude navýšená do 24 hod. po kúpe',
    sms_credit_buy_success_text: 'Kredit bol úspešne objednaný.',
    sms_credit_purchase_list: 'Zoznam objednávok SMS kreditov',
    sms_credit_purchase_detail: 'Objednávka SMS kreditu',
    sms_credit_purchase_credit: 'Počet kreditov',
    sms_credit_purchase_price: 'Suma za kredity',
    sms_credit_purchase_state: 'Stav objednávky',
    sms_credit_purchase_cancel: 'Zrušiť objednávku',
    sms_credit_purchase_date: 'Dátum objednávky',
    sms_credit_purchase_status: ['Objednaná', 'Prebieha spracovanie', 'Dokončená', 'Zrušená'],

    // **********************************************************************    
    // TVORBA CISLA DOKUMENTOV
    // **********************************************************************  
    document_number_title: 'Tvorba čísla dokumentov',
    document_number_format: 'Formát čísla',
    document_number_prefix: 'Predpona',
    document_number_char: 'Znak',
    document_number_char_none: 'žiaden',
    document_number_value: 'Hodnota',
    document_number_value_none: '---',
    document_number: 'Poradové číslo',
    document_number_decimal: 'Počet číslic v poradovom čísle',
    document_number_error: 'Chyba - nie je zvolené poradové číslo!',


    // **********************************************************************    
    // SKLADY
    // ********************************************************************** 

    stock: 'Sklad',
    stocks: 'Sklady',
    stockable: 'Skladová položka',
    stockable_note: 'Je produkt udržiavaný na sklade?',
    stocks_use: 'Používanie skladov',
    stocks_use_note: 'Vedenie skladového stavu produktov vo viacero skladoch',
    stock_one: 'Jeden sklad',
    stock_multi: 'Viac skladov',
    stock_name: 'Názov',
    stock_name_: 'Názov skladu',
    stock_code: 'Kód skladu',
    stock_new: 'Nový sklad',
    stock_enabled: 'Sklad aktívny',
    stock_default: 'Hlavný sklad',
    stock_default_note: 'Označiť sklad ako hlavný sklad produktov',
    stocks_status: 'Skladové stavy',
    stocks_disabled: 'Používanie viacero skladov je vypnuté!',
    stocks_disabled_note: 'Po zmene nastavení už nebude možné používanie skladov vypnúť.',
    stocks_enabled: 'Začať používať',
    stocks_enabled_ask: 'Chcete začať používať viac skladov ako jeden?',
    stock_starting_quantity: 'Počiatočný stav skladu',

    stock_adjustment_name: 'Názov',
    stock_adjustments: 'Úpravy zásob',
    stock_adjustment: 'Úprava zásob',
    stock_adjustment_: 'Úprava skladových zásob',
    stock_adjustment_new: 'Nová úprava',
    stock_adjustment_new_: 'Nová úprava skladových zásob',
    stock_adjustment_enabled: 'Úprava aktívna',
    stock_adjustment_reason: 'Dôvod zmeny',
    stock_adjustment_reason_add: 'Úprava zoznamu',
    stock_adjustment_products: 'Zoznam produktov',
    stock_adjustment_products_add: 'Pridať produkt',

    stock_adjustment_status: ['V príprave', 'Ukončené'],
    stock_adjustment_finish: 'Ukončiť a uzavrieť',
    stock_adjustment_finish_: 'Ukončenie úpravy zásob',
    stock_adjustment_finish_ask: 'Chcete ukončiť a uzavrieť úpravu zásob?',
    stock_adjustment_finish_text: 'Úprava bude označená ako ukončená a už nebude možné vykonávať zmeny.',

    stock_original_quantity: 'Pôvodný stav',
    stock_original_quantity_: 'Pôvodný počet kusov',
    stock_adjustment_quantity: 'Upravený stav',
    stock_quantity: 'Počet kusov',
    stock_quantity_adjusted: 'Upravený počet kusov',
    stock_zero_status: 'Zobraziť nulové stavy',
    stock_zero_status_: 'Nulové stavy',
    stock_smaller_status: 'Zobraziť stav menší ako',
    stock_bigger_status: 'Zobraziť stav väčší ako',
    stock_smaller_status_label: 'Zobraziť stav skladu menší ako',
    stock_bigger_status_label: 'Zobraziť stav skladu väčší ako',
    stock_filter: 'Zobraziť',
    stock_filters: 'Stavy skladov',
    stock_guarded: 'Zobraziť strážené skladové stavy',
    stock_guarded_: 'Strážené skladové stavy',
    stock_not_guarded: 'Zobraziť nestrážené skladové stavy',
    stock_not_guarded_: 'Nestrážené skladové stavy',
    stock_product_history: 'Pohyby skladových stavov produktu',
    stock_history_type: ['Počiatočný stav skladu', 'Úprava skladových zásob', 'Presun skladových zásob', 'Predaj produktu', 'Vyskladnenie produktu', 'Príjem produktu', 'Reklamácia - vrátenie produktu'],

    stock_transports: 'Presuny skladových zásob',
    stock_transport: 'Presun skladových zásob',
    stock_transports_: 'Presun zásob',
    stock_transport_name: 'Názov',
    stock_transport_new: 'Nový presun',
    stock_transport_reason: 'Dôvod presunu',
    stock_transport_products: 'Zoznam produktov',
    stock_transport_products_add: 'Pridať produkt',
    stock_transport_quantity: 'Počet kusov',
    stock_transport_quantity_: 'Počet presúvaných kusov',
    stock_transport_from: 'Zo skladu',
    stock_transport_to: 'Na sklad',
    stock_transport_from_to_error: 'Sklady nesmú byť rovnaké!',
    stock_transport_from_error: 'Nie je zvolený sklad, z ktorého sa budú produkty presúvať!',
    stock_transport_to_error: 'Nie je zvolený sklad, do ktorého sa budú produkty presúvať!',
    stock_transport_run: 'Uskutočniť presun',
    stock_transport_ask_approve: 'Požiadať o schválenie',
    stock_transport_status: ['V príprave', 'Presunuté', 'Zrušené'],
    stock_transport_ask_text: 'Uskutočniť presun produktov?',
    stock_transport_ask_sub_text: 'Po presune už nebude možné meniť stav presúvaných produktov.',
    stock_shared: 'Zdieľaný',
    stock_shared_note: 'Sklad je viditeľný vo všetkých krajinách CRM',
    stock_choose: 'Výber skladu',

    stock_card: 'Skladová karta',
    stock_cards: 'Skladové karty',
    stock_card_info: 'Informácie o aktuálnych skladových zásobách',
    stock_card_note: 'Skladová karta uchováva všetky transakcie produktu urobené v skladoch',
    stock_card_new: 'Vytvoriť novú',
    stock_card_new_: 'Nová karta',
    stock_card_select: 'Vybrať kartu',
    stock_card_select_note: 'Presunúť produkt na existujúcu kartu',
    stock_card_add_product: 'Pridať produkt na kartu',
    stock_card_none: 'Nie je priradená skladová karta',
    stock_card_new_label: 'Nová skladová karta',
    stock_card_new_ask: 'Chcete založiť novú skladovú kartu a prideliť produktu?',
    stock_card_new_note: 'Aktuálne skladové stavy produktu v skladoch budú vynulované.',
    stock_card_name: 'Názov karty',
    stock_card_transactions: 'Pohyby na skladovej karte',
    stock_card_transactions_: 'Pohyby',
    stock_card_history: 'Štatistika',
    stock_card_history_: 'Vývoj skladových zásob v čase',
    stock_card_products_: 'Produkty na karte',
    stock_card_products: 'Zoznam produktov na skladovej karte',
    stock_card_change_label: 'Zmena skladovej karty',
    stock_card_change_ask: 'Chcete presunúť produkt na skladovú kartu vybratého produktu?',
    stock_card_change_ask_note: 'Produkt bude presunutý na vybratú skladovú kartu!',
    stock_card_change_ask2: 'Chcete pridať produkt na danú skladovú kartu?',
    stock_card_change_ask_note2: 'Pôvodná skladová karta produktu bude nahradená.',
    stock_card_change_none: 'Zvolený produkt nemá pridelenú skladovú kartu!',
    stock_card_product_add: 'Priradiť kartu produktu',
    stock_card_product_move: 'Odobrať produkt z karty a presunúť na novú',
    stock_card_product_move_label: 'Výber skladovej karty, na ktorú bude produkt presunutý',

    stock_card_automat: 'Automatická objednávka',
    stock_card_automat_label: 'Automatická objednávka na sklad',
    stock_card_automat_note: 'Generovanie automatickej objednávky produktu na sklad',
    stock_card_quantity_min: 'Minimálne množstvo',
    stock_card_quantity_min_note: 'Skladový limit - minimálne množstvo produktu skladom, pri ktorom sa generuje objednávka',
    stock_card_quantity_max: 'Odporúčané množstvo',
    stock_card_quantity_max_note: 'Odporúčané množstvo produktu skladom',

    stock_transport_not_approved_label: 'Uskutočnenie presunu',
    stock_transport_not_approved: 'K vykonaniu presunu je potrebný súhlas. Vyberte užívateľa, ktorý daný presun schváli',
    stock_transport_approved: 'Schválený',
    stock_transport_approve_: 'Schváliť',
    stock_allow_approve: 'Schvalovanie presunov',
    stock_allow_approve_note_0: 'Užívateľ nemá právo schvalovať presuny na skladoch',
    stock_allow_approve_note_1: 'Užívateľ schvaľuje presuny na skladoch užívateľom bez povolenia',
    stock_transport_approve_choose: 'Požiadať užívateľa o schválenie presunu',
    stock_transport_approve_choose_label: 'Odoslať požiadavku?',
    stock_transport_approve_choose_text: 'Požiadavka bude odoslaná na schválenie:',
    stock_transport_approve_waiting: 'Čaká na schválenie',
    stocks_limited: 'Obmedzený prístup',
    stocks_limited_0: 'Užívateľ má prístup ku všetkým záznamom',
    stocks_limited_2: 'Užívateľ má prístup ku všetkým záznamom na základe pozícií',
    stocks_limited_1: 'Užívateľ má prístup len k svojim záznamom',
    stocks_transport_states: ['Všetky záznamy', 'V príprave', 'Presunuté', 'Zrušené'],

    stock_user: 'Sklad užívateľa',
    stock_user_none: 'Žiaden sklad',
    stock_transport_cancel: 'Zrušiť presun',
    stock_transport_cancel_ask: 'Chcete zrušiť a vymazať daný presun?',
    stock_cancel: 'Zrušiť sklad',
    stock_cancel_: 'Zrušenie skladu',
    stock_cancel_text: 'Vyberte sklad, na ktorý budú presunuté skladové stavy po zrušení',
    stock_cancel_sub_text: 'Sklad bude označený ako neaktívny. V budúcnosti ho bude možné opäť použiť.',
    stock_cancel_note: 'Po zrušení skladu, zásoby nebudú presunuté automaticky. Bude vytvorený nový záznam v presune zásob.',

    stock_removal: 'Vyskladnenie zásob',
    stock_removals: 'Vyskladnenie zásob zákazníkom',
    stock_removals_all: 'Všetky záznamy',
    stock_removals_: 'Vyskladnenie zásob zákazníkovi',
    stock_removals_new: 'Nové vyskladnenie',
    stock_removals_status: ['V príprave', 'Ukončené'],
    stock_removals_finish: 'Vyskladniť a uzavrieť',
    stock_removals_add: 'Doplniť produkt',
    stock_removals_products: 'Počet produktov',
    stock_removals_status_: 'Stav vyskladnenia',
    stock_removals_stock_note: 'Stav, z ktorého budú zásoby vyskladnené',
    stock_removals_customer_note: 'Zákazník, ktorý vyskladnené zásoby prevzal',
    stock_removals_exec: 'Chcete uzavrieť a vyskladniť zásoby zákazníkovi?',
    stock_removals_exec_note: 'Vyskladnenie bude ukončené a už nebude možné vykonávať zmeny.',
    stock_removal_empty: 'Žiadne položky',


    stock_of_rep: 'Sklad reprezentanta',
    stock_of_user: 'Sklad užívateľa',
    stock_of_rep_choose: 'Výber skladu reprezentanta',

    stock_show_transactions: 'Zobrazovať pohyby',
    stock_show_transactions_note: 'Zobrazovať pohyby na skladovej karte',
    stock_code_note: 'Kód, alebo skrátený názov skladu (napr. 100)',
    stock_export_label: 'Export objednávok na sklad',
    stock_export: 'Export objednávok',
    stock_export_typ: 'Spôsob odosielania',
    stock_export_types: ['Neodosielať', 'E-mailom', 'PharmInfo', 'Pharmos'],
    stock_export_stock_none: 'Objednávky sa na daný sklad automaticky neodosielajú',
    stock_export_email: 'E-mailová adresa',
    stock_export_email_note: 'E-mailová adresa, na ktorú budú automaticky odosielané objednávky',
    stock_export_email_note_: 'Voliteľné, ak je e-mailová adresa zadaná, budú objednávky odosielané aj prostredníctvom e-mailu',
    stock_export_product_code: 'Kód produktu',
    stock_export_product_code_note: 'Kód produktu použitý pri odosielaní objednávky',
    stock_export_product_codes: ['Interný ERP kód produktu', 'SKU produktu', ''],
    stock_export_customer_code: 'Kód zákazníka',
    stock_export_customer_code_note: 'Kód zákazníka použitý pri odosielaní objednávky',
    stock_export_customer_codes: ['Interný ERP kód zákazníka'],
    stock_export_prices: 'Ceny produktov',
    stock_export_prices_note: 'Do objednávky budú vložené ceny produktov pre zákazníka',

    stock_code_searchings: 'Vyhľadanie kódu',
    stock_code_searchings_note: 'Vyhľadanie kódu prostredníctvom PharmInfo / Pharmos',

    warehouse: 'Skladové zásoby',
    warehouse_all: 'Sklady organizácie',
    warehouse_products: 'Sklad produktov',
    warehouse_default: 'Predvolený sklad',
    warehouse_types: ['Sklad organizácie', 'Sklad užívateľa', 'Sklad distribútora'],
    warehouse_types_: ['Sklady organizácie', 'Sklady užívateľov', 'Sklady distribútorov'],
    warehouse_type: 'Typ skladu',
    warehouses_all: 'Všetky typy skladov',

    codebook: 'Číselník',
    codebooks: 'Číselníky',
    codebook_add: 'Nová položka',
    codebook_item_exists: 'Položka sa už nachádza v zozname!',
    codebook_adjust_label: 'Dôvody úprav skladových zásob',
    codebook_trans_label: 'Dôvody presunov skladových zásob',
    code_book_placeholder: 'Zadajte názov a stlačte "Enter"',

    codebook_user_defined: 'Číselníky užívateľa',
    codebook_user_defined_: 'Číselník užívateľa',
    codebook_user_defined_values: 'Vlastné číselníky',
    codebook_user_defined_label: 'Užívateľom definované číselníky',
    codebook_name: 'Názov',
    codebook_value_new: 'Nová hodnota',
    codebook_value_edit: 'Úprava hodnoty',
    codebook_new: 'Nový číselník',
    codebook_edit: 'Úprava číselníka',
    codebooks_system: 'Číselníky',
    codebook_color: 'Farba',

    // **********************************************************************    
    // ZAKAZNICI
    // ********************************************************************** 

    customer: 'Zákazník',
    customers: 'Zákazníci',
    customer_new: 'Nový zákazník',
    customer_name: 'Meno a priezvisko',
    customer_enabled: 'Zákazník aktívny',
    customer_category: 'Kategória',
    customer_category_: 'Kategória zákazníkov',
    customer_contact_person: 'Kontaktná osoba',
    customer_portal: 'Zákaznícky portál',
    customer_portal_note: 'Povoliť prístup do zákazníckeho portálu',
    customer_can_buy: 'Môže nakupovať',
    customer_code: 'Kód',
    customer_email_note: 'E-mailová adresa potrebná k aktivácii portálu',
    customer_agent: 'Reprezentant',
    customer_agent_: 'rep.',
    customer_email_exists: 'Zadaná e-mailová adresa už je registrovaná v inom zákazníckom účte! Zvoľte inú.',
    customer_firm_address: 'Fakturačná adresa',
    customer_shipping_address: 'Dodacia adresa',
    customer_shipping_address_new: 'Nová dodacia adresa',
    customer_port_send_invitation: 'Odoslať pozvánku',
    customer_port_send_invitation_note: 'Odoslať zákazníkovi pozvánku na vstup do portálu',
    customer_ask_send_invitation_text: 'Odoslať zákazníkovi pozvánku na vstup do portálu ?',
    customer_ask_send_invitation_subtext: 'Pozvánka bude odoslaná na e-mailovú adresu: ',
    customer_add_contact: 'Pridať',
    customer_contact_person: 'Kontaktná osoba',
    customer_contact_person_new: 'Nová kontaktná osoba',
    customer_contact_person_active: 'Osoba aktívna',
    customer_categories: 'Kategórie',
    customer_add: 'Pridať zákazníka',
    customer_address_active: 'Adresa aktívna',
    customer_address_list: 'Zoznam dodacích adries zákazníka',
    customer_address_equal: 'Rovnaká ako fakturačná',
    customer_basic_info: 'Údaje',
    customer_overview: 'Prehľad',
    customer_notes: 'Poznámky',
    customer_emails: 'E-mailová komunikácia',
    customer_email_new: 'Nový e-mail',
    customer_address_default: 'Prednastavená',
    customers_list_group_new: 'Nová skupina',
    customers_list_group_error: 'Označte zákazníkov, ktorých chcete pridať do skupiny',
    customer_change: 'Zmena zákazníka',

    customers_groups: 'Skupiny zákazníkov',
    customers_groups_: 'Skupiny',
    customers_group: 'Skupina zákazníkov',
    customers_group_new: 'Nová skupina zákazníkov',
    customers_group_count: 'Počet zákazníkov',
    customers_group_name: 'Názov skupiny',
    customers_group_delete: 'Odstrániť skupinu',
    customers_group_delete_ask: 'Chcete odstrániť skupinu zákazníkov?',
    customers_group_delete_ask_note: 'Vymaže sa len skupina, záznamy zákazníkov budú zachované.',


    customers_portal: 'Zákaznícky portál',
    customers_portal_stock: 'Skladové stavy',
    customers_portal_stock_note: 'Zobrazovanie skladových stavov',

    customers_note: 'Poznámka',
    customers_notes: 'Poznámky',
    customers_note_new: 'Nová poznámka',
    customers_note_edit: 'Úprava poznámky',
    customers_note_delete: 'Vymazať poznámku',
    customer_note_placeholder: 'Napísať novú poznámku ...',
    customer_select: 'Vybrať zákazníka',
    customer_select_: 'Výber zákazníka',
    customer_select_email: 'Výber e-mailovej adresy',

    customers_of_rep: 'Zákazníci reprezentanta',
    customers_categories: 'Kategórie zákazníkov',
    customer_can_not_buy: 'Zákaz nákupov',
    customer_b2b: 'B2B zákazník',
    customer_b2b_: 'B2B zákazníka',
    customers_b2b: 'B2B zákazníci',
    customer_b2c: 'B2C zákazník',
    customer_b2c_: 'B2C zákazníka',
    customers_b2c: 'B2C zákazníci',
    customers_b2b_show: 'Zobraziť B2B zákazníkov',
    customers_b2c_show: 'Zobraziť B2C zákazníkov',
    customer_anonyme: 'Anonymný zákazník',

    customer_address_none: 'Chýba adresa dodania',
    customer_address_none_text: 'Vyžaduje sa adresa dodania. Zákazník nemá definovanú adresu dodania!',
    customer_address_none_note: 'Adresu dodania zadáte v karte zákazníka',

    customers_list: 'Zoznam zákazníkov',
    customers_quantity: 'Počet zákazníkov',

    // **********************************************************************    
    // ORDERS
    // ********************************************************************** 

    orders: 'Objednávky',
    orders_products: 'Objednávky produktov',
    orders_all: 'Všetky objednávky',
    orders_ready: 'Nespracované',
    orders_finished: 'Ukončené',
    order: 'Objednávka',
    order_new: 'Nová objednávka',
    order_stock_error: 'Nie je vybratý sklad!',
    order_create: 'Vytvoriť',
    order_enabled: 'Objednávka aktívna',
    order_number: 'Číslo obj.',
    order_number_: 'Číslo objednávky',
    order_products: 'Počet produktov',
    order_sum_cost: 'Náklady  (bez DPH)',
    order_sum_price: 'Celkom (bez DPH)',
    order_sum_price_: 'Celkom',
    order_edit: 'Upraviť',
    order_export: 'Exportovať objednávku',
    order_export_pdf: 'Vytvoriť PDF',
    order_status_: 'Stav objednávky',
    order_add_product: 'Doplniť produkt',
    order_status_change: 'Zmeniť stav objednávky',
    order_edit_status_change: 'Upraviť a zmeniť stav objednávky',
    order_quantity_original: 'Pôvodný počet',
    order_end_editing: 'Ukončiť úpravy',
    order_cancel: 'Zrušiť objednávku',
    order_cancel_accept: 'Potvrdiť zrušenie',
    order_cancel_accept_: 'Zrušiť objednávku?',
    order_expedite_label: 'Ukončiť a expedovať objednávku',
    order_expedite_label_: 'Ukončiť a expedovať objednávku?',
    order_expedite_button: 'Expedovať',
    order_expedite_note: 'Objednávka bude označená ako ukončená.',
    order_expedite_check: 'Kontrola objednávky',
    order_expedite_check_label: 'Kontrola objednávky pred expedovaním',
    order_expedite: 'Ukončiť a expedovať',
    order_expedite_stock: 'Expedovaná zo skladu',
    order_expedite_price_list: 'Priradený cenník',
    order_expedite_distributor_error: 'Nie je zvolený distribútor',
    order_expedite_stock_error: 'Nie je zvolený sklad',
    order_editing: 'Úprava objednávky',
    order_expedite_: 'Expedovať',
    order_delete: 'Zrušiť objednávku',
    order_delete_label: 'Zrušenie objednávky',
    order_delete_text: 'Chcete zrušiť objednávku?',
    order_delete_note: 'Zrušenú objednávku nebude možné obnoviť!',
    order_cancel_accept_text: 'Objednávka bude zrušená. Rezervované produkty budú uvoľnené.',
    order_discount: 'Pridať zľavu na objednávku',
    order_discount_cancel: 'Zrušiť zľavu',
    order_discount_: 'Zľava na celú objednávku',
    order_discounts: 'Zľavy',
    order_settings: 'Nastavenia',
    order_price_list_note: 'Zmenou cenníka sa načítajú nové predajné ceny produktov',
    order_discount_percentage: 'Zľavový koeficient',
    order_discount_item: 'Zľava na položku (napr. -10%)',
    order_quantity: 'Počet kusov',
    order_quantity_: 'Objednaný počet kusov',
    order_types: 'Typy objednávok',
    order_types_select: 'Výber typu objednávky',
    order_type: 'Typ objednávky',
    order_type_new: 'Nový typ',
    order_type_mixed: 'Zmiešaný',
    order_type_mixed_note: 'Pred každým vkladaním produktov bude potrebné zvoliť typ',
    order_customer_price_list: 'Cenník pre zákazníka',
    order_type_enabled: 'Aktívny typ',
    order_type_name: 'Názov typu',
    order_type_default: 'Predvolený typ',
    order_type_default_: 'Predvolený typ objednávky',
    order_types_label: 'Typy objednávok',
    order_types_note: 'Delenie objednávok podľa typov a kritérií',
    order_type_stock: 'Skladové stavy',
    order_type_products_all: 'Zahrnúť produkty',
    order_type_products_all_0: 'Len označené',
    order_type_products_all_1: 'Všetky produkty',
    order_type_products_all_2: 'V kategórii',
    order_type_products_all_: 'Ktoré produkty budú zahrnuté do daného typu',
    order_type_selected: 'Výber produktov',
    order_type_all: 'Všetky produkty',
    order_type_typ: 'Typ položky',
    order_type_typ_note: 'Položka bude odpočítaná / pripočítaná k aktuálnemu stavu',
    order_type_negative: 'Odpočítana',
    order_type_positive: 'Pripočítaná',
    order_type_stock_note_0: 'Položka v objednávke bude odpočítaná zo skladu?',
    order_type_stock_note_1: 'Položka v objednávke bude pripočítaná na sklad?',
    order_type_invoice: 'Faktúrovať položku',
    order_type_invoice_note_0: 'Položka v objednávke bude faktúrovaná ako kladná?',
    order_type_invoice_note_1: 'Položka v objednávke bude faktúrovaná ako záporná?',
    order_type_credit: 'Uplatnené z kreditu',
    order_type_credit_note_0: 'Položka v objednávke bude odpočítaná z kreditu zákazníka?',
    order_type_credit_note_1: 'Položka v objednávke bude pripočítaná ku kreditu zákazníka?',
    order_type_settings: 'Nastavenia',
    order_covers: ['Vykryté', 'Čiastočne vykryté', 'Nevykryté'],
    order_empty: 'Prázdna',
    order_status: ['Vytvorená', 'V príprave', 'Žiadosť o zrušenie', 'Pripravená na odoslanie', 'Chyba pri odosielaní', 'Chyba pri odosielaní', '', '', 'Odosiela sa', 'Ukončená', 'Zrušená'],
    order_pdf_error: 'Objednávka neobsahuje žiadne produkty',

    distributors: 'Distribútori',
    distributors_use: 'Využívanie distribútorov',
    distributors_use_note: 'Využívanie distribútorov k odosielaniu objednávok',
    distributor: 'Distribútor',
    distributor_new: 'Nový distribútor',
    distributor_name: 'Názov distribútora',
    distributor_enabled: 'Aktívny distribútor',
    distribute_type: 'Spôsob distribúcie',
    distribute_type_stock: 'Zo skladu',
    distribute_type_distributor: 'Cez distribútora',
    distribute_code: 'ID kód distribútora',
    distribute_code_note: 'Identifikačný kód distribútora odosielaný spolu s objednávkou',

    with_dph: 's DPH',
    withou_dph: 'bez DPH',
    price_with_dph: 'Cena s DPH',
    price_withou_dph: 'Cena bez DPH',
    price_sum: 'Cena spolu',
    price_from: 'Cena od',
    sume: 'Celkom',
    price_item: 'Cena/ks',
    price: 'Cena',
    price_: 'Jedn.cena',

    discount: 'Zľava',
    discount_quantity: 'Množstevná zľava pri kúpe produktu',
    discount_from: 'Od',
    discount_to: 'do',
    discount_more: 'a viac',
    package: 'Balenie',

    // **********************************************************************    
    // ZNACKY PRODUKTOV
    // ********************************************************************** 

    flags: 'Značky',
    flag: 'Značka',
    flag_new: 'Nová značka',
    flag_enabled: 'Značka aktívna',
    flag_name: 'Názov značky',
    flag_update: 'Úprava značky',
    flag_color: 'Farba',
    flag_portal: 'Publikovať na portáli',

    // **********************************************************************    
    // POMOCNÍK
    // ********************************************************************** 
    help: 'Pomoc',
    helper: 'Pomocník',

    // **********************************************************************    
    // EMAILS
    // ********************************************************************** 

    email_send: 'Odoslať email',
    email_message: 'E-mailová správa',
    email_header: 'Predmet',
    email_header_: 'Predmet správy',
    email_body: 'Text správy',
    email_new: 'Nová e-mailová správa',
    email_sended: 'Správa bola úspešne odoslaná',
    email_sended_: 'Odoslané',
    email_sended_not: 'Neodoslané',
    email_error_header: 'Predmet správy musí byť vyplnený',
    email_attachment: 'Príloha',
    email_attachment_none: 'Žiadne prílohy',
    email_attachments: 'Prílohy',
    email_attachments_: 'Zoznam príloh',
    email_attachment_add: 'Vložiť prílohu',
    email_attachment_delete: 'Vymazať',
    email_attachment_size_error: 'Presiahli ste maximálnu dovolenú veľkosť prílohy',
    email_attachment_size_error_note: 'Maximálna veľkosť prílohy je 5 MB',
    email_attachment_max_size: 'Maximálna veľkosť príloh spolu je 5 MB',

    // **********************************************************************    
    // NÁVODY
    // ********************************************************************** 

    notes: 'Poznámky',
    note_add: 'Vložiť',

    // **********************************************************************    
    // NÁVODY
    // ********************************************************************** 

    batch_add: 'Pridať produkty',
    batch_add_customers: 'Pridať zákazníkov',
    batch_selection_type: 'Zdroj produktov',
    batch_selection_type_all: 'Všetky produkty',
    batch_selection_type_all_customers: 'Všetci zákazníci',
    batch_selection_type_all_: 'Všetky registrované produkty',
    batch_selection_type_category: 'Produkty v kategórii',
    batch_selection_type_category_customers: 'Zákazníci v kategórii',
    batch_selection_type_selected: 'Výber produktov',
    batch_selection_type_selected_customers: 'Výber zákazníkov',
    batch_category_select: 'Vybrať kategóriu',
    batch_sub_categories: 'Zahrnúť podkategórie',
    batch_sub_categories_note: 'Do výberu budú zahrnuté aj všetky podkategórie zvolenej kategórie',
    batch_active: 'Filtrovanie záznamov',
    batch_active_1: 'Len aktívne',
    batch_active_2: 'Všetky',
    batch_update_exec: 'Aktualizovať údaje',
    batch_value: 'Výber parametru na aktualizovanie',
    batch_value_select: 'Vybrať',
    batch_updated_ok: 'Údaje boli úspešne aktualizované',
    batch_updated_error: 'Pri aktualizácii údajov nastala chyba, skúste požiadavku opakovať',

    // **********************************************************************    
    // GRAF
    // ********************************************************************** 

    graph_label_months: 'Obdobie - rok',
    graph_label_pieces: 'Počet kusov',
    graph_label_stock_average: 'Priemerná mesačná hodnota',
    graph_label_stock_max: 'Maximálna mesačná hodnota',

    // **********************************************************************    
    // NOVA VERZIA APLIKACIE
    // ********************************************************************** 
    version_new: 'K dispozícii je nová verzia aplikácie',
    version_mobile_new: 'K dispozícii je nová verzia aplikácie pre iPad',
    version_update: 'Aktualizovať',

    // **********************************************************************    
    // NÁVODY
    // ********************************************************************** 
    help_main: 'https://support.datadream.sk/guide/crm/sk',
    help_settings_my_profile: 'https://support.datadream.sk/guide/crm/sk/moj-profil/1',
    help_settings_organization: 'https://support.datadream.sk/guide/crm/sk/organizacia/1',

    stock_all: 'Všetky sklady',

    // **********************************************************************    
    // SCHAĽOVACIE PROCESY
    // **********************************************************************    

    approval: 'Schválenie',
    approvals: 'Schválenia',
    approval_ask: 'Požiadavka o schválenie',
    approvals_ask: 'Požiadavky o schválenie',
    approvals_enabled: 'Schvaľovanie procesov',
    approvals_enabled_note_0: 'Užívateľ nemá právo schvalovať procesy',
    approvals_enabled_note_1: 'Užívateľ má právo schvalovať procesy iných užívateľov',
    approval_asking_user: 'Žiadateľ',
    approval_asking_user_: 'Žiadateľ o schválenie',
    approval_asking_module: 'Modul',
    approval_asked_user: 'Schvaľovateľ',
    approval_asked_user_text: 'Požiadať úžívateľa o schválenie',
    approval_asked_user_select: 'Výber úžívateľa',
    approval_asked_user_change: 'Zmeniť úžívateľa',
    approval_send: 'Odoslať požiadavku',
    approval_approve_text: 'Schváliť požiadavku?',
    approval_approve_note: 'Požiadavka bude označená ako schválená.',
    approval_deny_text: 'Zamietnuť požiadavku?',
    approval_deny_note: 'Požiadavka bude označená ako zamietnutá.',
    approval_status: ['Čaká na schválenie', 'Schválené', 'Zamietnuté'],
    approval_status_: 'Stav požiadavky',
    approval_preview: 'Zobraziť',

    contacts: 'Kontakty',
    contact: 'Kontakt',
    contact_new: 'Nový kontakt',
    contact_enabled: 'Kontakt aktívny',
    contact_customer: 'Doplnenie údajov',
    contact_to_customer: 'Zmeniť kontakt na zákazníka',
    contact_to_customer_note: 'Kontakt bude doplnený o informácie a zmenený na zákazníka',
    contact_to_customer_enabled: 'Stav po vytvorení nového zákazníka',
    contact_to_customer_note_: 'Po vytvorení zákazníka bude kontakt presunutý do modulu "Zákazníci"',
    contact_to_customer_create: 'Vytvoriť zákazníka',
    contact_to_customer_price_list: 'Pridelený cenník produktov',
    contact_to_customer_rep: 'Reprezentant pridelený zákazníkovi',

    email_company: 'e-mailová adresa spoločnosti',
    email_customer: 'e-mailová adresa zákazníka',
    email_facture: 'fakturačná e-mailová adresa',
    email_selected: 'Zvolená e-mailová adresa',
    email_selected_none: 'Vyberte e-mailovú adresu zákazníka',
    email_empty: 'E-mailová adresa nie je zadaná',
    email_delayled: 'Naplánované',
    email_cc: 'Odoslať kópiu na emailové adresy (oddeľujte čiarkou ",")',
    email_cc_: 'Kópia e-mailu',
    email_address_label: 'e-mailová adresa',

    stock_order: 'Objednávka tovaru',
    stock_orders: 'Objednávky tovaru',
    stock_order_new: 'Nová objednávka',
    stock_order_new_label: 'Nová objednávka tovaru na sklad',
    stock_order_auto: 'Sprievodca',
    stock_order_auto_label: 'Sprievodca objednávkou',
    stock_order_auto_run: 'Spustiť',
    stock_order_select: 'Výber objednávky',
    stock_order_finish: 'Ukončiť a odoslať',
    stock_order_finish_label: 'Ukončenie objednávky',
    stock_order_finish_ask: 'Chcete ukončiť objednávku tovaru?',
    stock_order_finish_ask_note: 'Objednávka bude označená ako ukončená a už nebude možné vykonávať zmeny.',
    stock_order_auto_text: 'Doplnenie produktov do objednávky',
    stock_order_auto_note: 'Sprievodca vyhodnotí všetky aktívne produkty, ktoré majú nastavené parametre automatickej objednávky a doplní do objednávky',
    stock_order_status: ['', 'V príprave', 'Ukončené'],

    stock_orders_received: 'Prijaté objednávky',
    stock_orders_received_: 'Príjem tovaru',
    stock_order_receive: 'Prijať objednávku',
    stock_order_received: 'Prijatá objednávka',
    stock_ordered_quantity: 'Objednaný počet',
    stock_reserved_quantity: 'Rezervovaný počet',
    stock_received_quantity: 'Prijatý počet',
    stock_delivered_quantity: 'Dodaný počet',
    stock_actual_quantity: 'Počet skladom',
    stock_received_finish: 'Prijať na sklad',
    stock_order_received_ask: 'Chcete prijať tovar na sklad?',
    stock_order_received_ask_note: 'Tovar bude pripísany na sklad v zadaných množstvách.',
    stock_order_receive_status: ['', 'V príprave', 'Ukončené'],
    stock_watch_dog: 'Stráženie stavu',
    stock_range: 'Skladom od',

    reklamacie: 'Reklamácie',
    reklamacia: 'Reklamácia',
    reklamacia_new: 'Nová reklamácia',
    reklamacia_create: 'Vytvoriť reklamáciu',
    reklamacia_new_label: 'Nová reklamácia objednávky',
    reklamacie_orders: 'Reklamácie objednávok',
    reklamacia_reason: 'Dôvod reklamácie',
    reklamacia_reasons: 'Dôvody reklamácie',
    reklamacia_reasons_label: 'Dôvody vytvorenia reklamácie',
    reklamacia_deny: 'Dôvod zamietnutia',
    reklamacia_denies: 'Dôvody zamietnutia',
    reklamacia_denies_label: 'Dôvody zamietnutia reklamácie',
    reklamacia_order_select: 'Vytvorenie reklamácie z objednávky',
    reklamacia_order_info: 'Výber objednávky pre vytvorenie novej reklamácie',
    reklamacia_dodaci_list: 'Číslo dodacieho listu',
    reklamacia_faktura: 'Číslo faktúry',
    reklamacia_faktura_date: 'Dátum faktúry',
    reklamacia_comment: 'Komentár k reklamácii',
    reklamacia_data: 'Informácie o objednávke',
    reklamacia_images: 'Fotografie',
    reklamacia_image_preview: 'Náhľad fotografie',
    reklamacia_image_delete: 'Vymazať fotografiu',
    reklamacia_images_label: 'Fotografie k reklamácii',
    reklamacia_image_add: 'Pridať fotografiu',
    reklamacia_products_label: 'Reklamované produkty',
    reklamacia_products_quantity: 'Reklamované kusy',
    reklamacia_products_quantity_: 'Počet reklamovaných produktov',
    reklamacia_products_returned_quantity_: 'Počet vrátených produktov',
    reklamacia_products_select: 'Výber produktov',
    reklamacia_products_select_text: 'Výber reklamovaných produktov z objednávky',
    reklamacia_products_add: 'Vložiť',
    reklamacia_products_ordered: 'Objednaný počet',
    reklamacia_products_ordered: 'Objednaný počet',
    reklamacia_approval_label: 'Schvaľovanie reklamácie',
    reklamacia_approval: 'Schvaľovanie reklamácie',
    reklamacia_close: 'Ukončenie reklamácie',
    reklamacia_close_label: 'Chcete ukončiť reklamáciu objednávky?',
    reklamacia_close_text: 'Reklamácia bude označená ako ukončená a už nebude možné vykonávať zmeny.',
    reklamacia_approval_status: 'Stav schvaľovania',
    reklamacia_approvals_status: ['Posudzovanie', 'Schválené', 'Zamietnuté'],
    reklamacia_type: 'Typ reklamácie',
    reklamacia_types: ['Vrátenie produktov', 'Reklamácia produktov'],
    reklamacia_product_return: 'Vrátené na sklad',
    reklamacia_product_send: 'Výdaj zo skladu',
    reklamacia_product_none: 'Bez zmeny',
    reklamacia_type_0: 'Vrátenie produktov a pripísanie do skladových zásob',
    reklamacia_type_1: 'Reklamácia produktov (výmena, nedodané produkty, ...)',
    reklamacia_stock: 'Skladové zásoby',
    reklamacia_products_returned: 'Vrátené produkty',
    reklamacia_stock_label: 'Ovplyvnenie skladových zásob',
    reklamacia_stock_note_0: 'Skladové zásoby nebudú ovplyvnené',
    reklamacia_stock_note_1: 'Položka bude pripočítaná do skladových zásob',
    reklamacia_stock_note_2: 'Položka bude zo skladových zásob odpočítaná',
    reklamacia_order_filter: 'Hľadanie reklamácii podľa objednávky',
    reklamacia_order_error: 'Nesprávny stav objednávky',
    reklamacia_order_error_note: 'Stav objednávky na vytvorenie reklamácie musí byť "Ukončená", alebo "Zrušená"',
    reklamacia_export_image: 'Zahrnúť obrázky do PDF',
    reklamacia_export_image_note: 'Budú do exportu do PDF zahrnuté aj obrázky?',

    email_choose_label: 'Vloženie e-mailovej adresy',
    email_choose_text: 'Výber typu e-mailovej adresy:',

    customer_shop: 'Prevádzka',
    customer_shop_name: 'Názov prevádzky',
    customer_shop_address_: 'Prevádzky',
    customer_shop_address: 'Prevádzka / dodacia adresa',
    customer_new_shop_address: 'Nová prevádzka, dodacia adresa',
    customer_shop_address_list: 'Zoznam prevádzok a dodacích adries',
    gps_coordinates: 'GPS súradnice prevádzky',
    gps_coordinates_from_address: 'GPS súradnice',
    gps_coordinates_address: 'Nájdená adresa',
    gps_coordinates_search: 'Hľadanie súradníc podľa zadanej adresy (Ulica, Mesto, Štát)',
    gps_coordinates_search_: 'Ulica, Mesto, Štát',
    gps_lat: 'Zemepisná šírka',
    gps_lng: 'Zemepisná dĺžka',
    gps_not_found: 'GPS súradnice na základe zadanej adresy nie sú k dispozícii',
    gps_show_map: 'Zobraziť na mape',
    gps_map: 'Mapa',
    gps_missing: 'Chýbajúce GPS',
    gps_save: 'Uložiť zadané súradnice ako predvolené',
    gps_load: 'Vložiť predvolené súradnice',

    openai_chat_start: 'Si užitočný asistent. Nepoužívaj v odpovediach vysvetlenia.',
    openai_function_start: "Don't make assumptions about what values to plug into functions. Ask for clarification if a user request is ambiguous",
    openai_label: 'AI asistent',
    openai_ask: 'Odoslať požiadavku AI',
    openai_request: 'Požiadavka',
    openai_answer: 'Odpoveď na požiadavku:',
    openai_answer_default: 'Čo môžem pre Vás urobiť?',
    openai_close_email: 'Použiť odpoveď',
    openai_last_request: 'Posledná požiadavka',

    openai_email_assistent: 'Použiť asistenta',
    openai_email_request: 'Vytvor emailovú správu pre zákazníka, nevkladaj predmet,',
    openai_email_company: 'použi názov spoločnosti "@company"',
    openai_email_email: 'použi môj email "@email"',
    openai_email_no_email_mobil: 'nepoužívaj môj email ani mobil',
    openai_email_mobil: 'použi môj mobil "@mobil"',
    openai_email_name: 'použi moje meno "@name"',
    openai_email_subject: 'Predmetom správy je',
    openai_error: 'Žial, na zadanú požiadavku som nenašiel odpoveď.',
    openai_request_question: 'Vaša požiadavka, alebo otázka ...',
    openai_assistent: 'asistent',
    openai_assistent_ask: 'Čo môžem pre Vás urobiť?',

    agenda: 'Agenda',

    presentations: 'Prezentácie',
    presentation: 'Prezentácia',
    presentation_name: 'Názov prezentácie',
    presentation_new: 'Nová prezentácia',
    presentation_enabled: 'Prezentácia aktívna',
    presentation_image: 'Obrázok prezentácie',
    presentation_slides: 'Snímky prezentácie',
    presentation_slide: 'Snímok prezentácie',
    presentation_slide_: 'Snímok',
    presentation_slide_add: 'Vložiť snímok',
    presentation_slide_delete: 'Vymazať snímok?',
    presentation_run: 'Spustiť',
    presentation_run_: 'Spustiť prezentáciu',
    presentation_stop: 'Ukončenie prezentácie',
    presentation_stats: 'Štatistika',
    presentation_stat_label: 'Dĺžka prezerania prezentácie (v sekundách)',
    presentation_time: 'Čas v sekundách',
    presentation_stop_ask: 'Chcete ukončiť prezentáciu?',
    presentation_ordering: 'Zoraďovanie snímkov',
    presentation_ordering_0: 'Podľa dátumu pridania',
    presentation_ordering_1: 'Abecedne',
    presentation_ordering_2: 'Vlastné',
    presentation_effect: 'Prechod snímkov',
    presentation_effect_0: 'Bez efektu',
    presentation_effect_1: 'Mix',
    presentation_effect_2: 'Zväčšenie',
    presentation_preview: 'Náhľad prezentácie',
    presentation_stat_count: 'Celkový počet prezentácii',

    quizes: 'Kontaktné správy',
    quiz: 'Kontaktná správa',
    quiz_select: 'Výber kontaktnej správy',
    quiz_enabled: 'Správa aktívna',
    quiz_new: 'Nová kontaktná správa',
    quiz_new_: 'Nová správa',
    quiz_question_delete: 'Vymazať otázku?',
    quiz_questions_answers: 'Otázky a odpovede',
    quiz_name: 'Názov',
    quiz_preview: 'Náhľad správy',
    quiz_image: 'Obrázok kontaktnej správy',
    quiz_text: 'Krátky popis',
    quiz_question_add: 'Nová otázka',
    quiz_question: 'Otázka',
    quiz_question_select: 'Výber otázky',
    quiz_question_enabled: 'Otázka aktívna',
    quiz_answer: 'Odpoveď',
    quiz_answers: 'Odpovede',
    quiz_answers_all: 'Všetky odpovede',
    quiz_answer_edit: 'Úprava odpovede',
    quiz_answer_yes: 'Text pre "Áno"',
    quiz_answer_yes_: 'Áno',
    quiz_answer_no: 'Text pre "Nie"',
    quiz_answer_no_: 'Nie',
    quiz_answer_add: 'Pridať odpoveď',
    quiz_answer_add_: 'Pridať odpoveď',
    quiz_answer_text: 'Text odpovede',
    quiz_answer_text_new: 'Nová odpoveď',
    quiz_answer_text_change: 'Úprava odpovede',
    quiz_answers_list: 'Zoznam odpovedí',
    quiz_answer_delete: 'Vymazať odpoveď?',
    quiz_answer_multiselect: 'Viac odpovedí',
    quiz_answer_multiselect_note: 'K danej otázke môže byť označených viac odpovedí',
    quiz_answer_user: 'Vaša odpoveď',
    quiz_answer_required: 'Chýba odpoveď',
    quiz_answer_required_: 'Chýbajúce odpovede',
    quiz_fill: 'Vyplniť správu',
    quiz_results: 'Výsledky',
    quiz_customer_error: 'Nie je vybratý zákazník',
    quiz_results_count: 'Celkový počet výsledných správ',
    quiz_create: 'Vyplniť správu',
    quiz_link: 'Link na anketu pre zákazníka',
    quiz_link_copied: 'Cesta k ankete bola skopírovaná do clipboardu.',
    quiz_answers_error: 'Chýbaju odpovede k otázke',

    question_typ: 'Typ otázky',
    question_typ_1: 'Áno / Nie',
    question_typ_2: 'Číslo',
    question_typ_2_: 'Odpoveďou je číselná hodnota',
    question_typ_3: 'Zoznam odpovedí',
    question_typ_4: 'Textová odpoveď',
    question_typ_4_: 'Odpoveďou je písaný text',
    question_typ_5: 'Dátum',
    question_typ_5_: 'Odpoveďou je zadaný dátum',
    question_typ_6: 'Čas',
    question_typ_6_: 'Odpoveďou je zadaný čas',

    routes: 'Moje trasy',
    route: 'Trasa',
    route_name: 'Názov trasy',
    route_new: 'Nová trasa',
    route_show: 'Zobraziť trasu',
    route_enabled: 'Trasa aktívna',
    route_start: 'GPS súradnice začiatku trasy',
    route_start_lat: 'Zemepisná šírka (LAT)',
    route_start_lng: 'Zemepisná dĺžka (LNG)',
    route_length: 'Dĺžka trasy v km',
    route_length_: 'Dĺžka trasy',
    route_length_km: 'km',
    route_length_hours: 'hod.',
    route_length_note: 'Približná dĺžka celej trasy v km',
    route_length_time_note: 'Dĺžka trasy a čas potrebný na cestu nezahŕňajú návrat',
    route_time_total: 'Čas spolu s návštevami',
    route_customer_add: 'Pridať zákazníka',
    route_customer_delete: 'Vymazať zákazníka?',
    route_customers: 'Poradie zákazníkov',
    route_address_select: 'Výber prevádzky',
    route_visit_time: 'Dĺžka návštevy',
    route_visit_time_minutes: 'minút',
    route_visit_settings: 'Nastavenia návštevy',
    route_customer_visit: 'Návšteva zákazníka',
    route_visit_error: 'Dĺžka návštevy nemôže byť nulová!',
    route_preview: 'Náhľad trasy',
    route_duplicate: 'Duplikovať trasu',
    route_duplicate_ask: 'Duplikovať trasu?',
    route_duplicate_text: 'Duplikovaním sa vytvorí kópia trasy.',

    months_list: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
    months_list_: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
    all_months: 'Celý rok',
    days: ['Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota', 'Nedeľa'],
    days_short: ['Ned', 'Pon', 'Uto', 'Str', 'Štv', 'Pia', 'Sob'],
    days_short_label: ['Pon', 'Uto', 'Str', 'Štv', 'Pia', 'Sob', 'Ned'],
    sviatky: ['01-01', '01-06', '05-01', '05-08', '07-05', '08-29', '09-01', '09-15', '11-01', '11-17', '12-24', '12-25', '12-26'],
    today: 'Dnes',
    week: 'týždeň',
    calendar: 'Kalendár',
    calendar_types: ['mesiac', 'týždeň', 'deň', 'zoznam'],
    calendars_planned: 'Naplánované udalosti',
    calendars_planned_none: 'Žiadne udalosti',
    calendar_group: 'Typ kalendára',
    calendar_groups: ['Zobraziť spolu', 'Pracovný', 'Súkromný', 'Iný'],
    calendar_termin_new: 'Nová udalosť',
    calendar_termin: 'Naplánovaná udalosť',
    calendar_date: 'Dátum',
    calendar_time: 'Čas',
    calendar_users: 'Pozvaní užívatelia',
    calendar_task_date_time: 'Dátum, čas a dĺžka trvania úlohy',
    calendar_time_from_to: 'Čas (od - do)',
    calendar_time_start: 'Začiatok',
    calendar_time_end: 'Koniec',
    calendar_time_length: 'Dĺžka trvania',
    calendar_time_length_other: 'Vlastná hodnota',
    calendar_termin_name: 'Názov udalosti',
    calendar_termin_minutes: 'minút',
    calendar_termin_priority: 'Priorita',
    calendar_termin_priorities: ['normálna', 'vysoká'],
    calendar_termin_all_day: 'Celý deň',
    calendar_hours_error: 'Nespávne zadaný čas konca udalosti',
    calendar_type: ['Udalosť', 'Úloha', 'Trasa', 'Dovolenka'],
    calendar_type_: 'Typ',
    calendar_route_name: 'Názov trasy',
    calendar_route: 'Trasa',
    calendar_route_none: 'Výber trasy',
    calendar_route_customers: 'Počet zákazníkov',
    calendar_customer: 'Zákazník',
    calendar_task_text: 'Zadanie úlohy',
    calendar_customer_select: 'Výber zákazníka',
    calendar_termin_customer: 'Udalosť viazaná na zákazníka',
    calendar_termin_customers: 'Udalosť viazaná na zákazníkov',
    calendar_termin_deadline: 'Deadline - dátum a čas ukončenia úlohy',
    calendar_termin_deadline_date: 'Dátum',
    calendar_termin_deadline_time: 'Čas',
    calendar_termin_deadline_enabled: 'Určiť deadline',
    calendar_termin_task_finished: 'Úloha je ukončená',
    calendar_termin_delete: 'Vymazať',
    calendar_termin_delete_label: 'Vymazať termín z kalendára?',
    calendar_termin_delete_text: 'Termín bude natrvalo vymazaný z kalendára.',
    calendar_deadline_date_error: 'Nesprávny deadline dátum',
    calendar_deadline_time_error: 'Nesprávny deadline čas',
    calendar_types_use: 'Viac kalendárov',
    calendar_types_use_note: 'Využívanie viac typov kalendára: pracovný, súkromný, ...',
    calendar_tooltips: 'Zobrazovať náhľady',
    calendar_holiday: 'Dovolenka',
    calendar_holiday_select: 'Uznačte dovolenkové dni',
    calendar_termins_none: 'Žiadne udalosti',
    calendar_termin_task_name: 'Názov úlohy',
    calendar_remind: 'Pripomienka',
    calendar_remind_types: ['V aplikácii', 'E-mailom', 'Notifikáciou', 'SMS'],
    termin_lengths: [
        { id: 15, name: '15 minút' },
        { id: 30, name: '30 minút' },
        { id: 60, name: '1 hodina' },
        { id: 120, name: '2 hodiny' },
        { id: 180, name: '3 hodiny' },
        { id: 240, name: '4 hodiny' },
        { id: 300, name: '5 hodin' },
        { id: 480, name: '8 hodin' },
    ],
    termin_periodes: ['dnes', 'včera', 'tento mesiac', 'tento rok', 'všetky záznamy', 'konkrétny deň'],
    termin_periode: 'Filtrovanie',


    plans: 'Plánovanie',
    plan: 'Plán',
    plan_new: 'Nový plán',
    plan_name: 'Názov plánu',
    plan_periods: ['dnes', 'zajtra', 'tento týždeň', '7 dní', 'tento mesiac', 'tri mesiace', 'celý rok', 'všetko', 'všetky záznamy'],
    plan_hide_expired: 'Len záznamy od dnešného dňa',
    plan_choose_termin: 'Výber obdobia',
    plan_choose_termin_: 'Obdobie',
    plan_choose_typ: 'Filtrovanie podľa typu plánu',
    plan_choose_typ_: 'Výber typu',
    plan_typ: 'Typ plánu',
    plan_types: 'Typy plánovania',
    plan_typ_error: 'Nie je zvolený typ plánu!',
    plan_finished: 'Ukončené',

    // NOTIFIKACIE
    notify: 'Notifikácie',
    notify_list: 'Zoznam notifikácii za posledný mesiac',


    // DASHBOARD
    dashboard: 'Pracovná plocha',
    dashboard_blog: 'Aktuality a oznamy',
    dashboard_calendar: 'Kalendár a naplánované termíny na dnes',
    dashboard_Label: 'Úprava zobrazovania pracovnej plochy',
    dashboard_Label_text: 'Možnosť zapnutia, alebo vypnutia zobrazovania jednotlivých prvkov na pracovnej ploche.',
    dashboard_today_plans: 'Dnes naplánované úlohy',
    dashboard_today_plans_: 'Dnešné úlohy',
    dashboard_today_no_plans: 'Žiadne naplánované udalosti',
    dashboard_blog_all: 'Všetky príspevky',
    dashboard_orders: 'Objednávky',
    dashboard_orders_note: 'Nevybavené objednávky',
    dashboard_reklamacie: 'Reklamácie',
    dashboard_reklamacie_note: 'Nevybavené reklamácie',
    dashboard_orders_count: 'Počet nevybavených objednávok',
    dashboard_reklamacie_count: 'Počet nevybavených reklamácií',
    dashboard_plans_count: 'Počet dnes naplánovaných úloh',
    dashboard_today_customers: 'Zoznam zákazníkov dnes naplánovaných',
    dashboard_approvals: 'Požiadavky na schválenie',

    // PLUGINS
    plugins: 'Pluginy',
    plugin: 'Plugin',
    plugin_enabled: 'Plugin aktívny',
    plugin_sap: 'SAP',
    plugin_sap_naos: 'SAP Naos',
    plugin_sap_text: 'Prepojenie medzi SAP NAOS a CRM',
    plugin_sap_pricelist: 'Predvolený cenník',
    plugin_sap_pricelist_note: 'ID cenníka, ktorý bude predvolený pre nových zákazníkov',
    plugin_sap_esthederm: 'ID kategórie Esthederm',
    plugin_sap_esthederm_note: 'ID z katagórie zákazníkov Esthederm',
    plugin_sap_bioderma: 'ID kategórie Bioderma',
    plugin_sap_bioderma_note: 'ID z katagórie zákazníkov Bioderma',
    plugin_eshop: 'Eshop',
    plugin_eshop_text: 'Predaj produktov prostredníctvom e-shopu',
    plugin_ai_assistant: 'Asistent AI',
    plugin_ai_assistant_text: 'Využitie umelej inteligencie v CRM',
    plugin_sms: 'SMS brána',
    plugin_sms_text: 'Využívanie sms notifikácii v CRM',
    plugin_conditions: 'Podmienky',
    plugin_conditions_text: 'Podmienečné zobrazovanie produktov a výpočet cien',
    plugin_newsletter: 'Marketingové nástroje',
    plugin_newsletter_text: 'Tvorba neslettera a e-mailové kampane',
    plugin_pharminfo: 'Pharminfo SK',
    plugin_pharminfo_text: 'Odosielanie objednávok distribútorom cez systém PharmInfo',
    plugin_pharmos: 'Pharmos CZ',
    plugin_pharmos_text: 'Odosielanie objednávok distribútorom cez systém Pharmos',
    plugin_automation: 'Automatizácia',
    plugin_automation_text: 'Využívanie procesov automatizácie',
    plugin_pharminfo_site: 'Nastavenia prístupu',
    plugin_pharminfo_orders: 'Objednávky produktov',
    plugin_pharminfo_orders_watchdog: 'Stráženie objednávok',
    plugin_pharminfo_orders_watchdog_time: 'Dĺžka stráženia (hod.)',
    plugin_pharminfo_orders_watchdog_note: 'Dĺžka stráženia musí byť v rozsahu 24 - 336 hodín',
    plugin_pharminfo_url: 'URL adresa',
    plugin_pharminfo_url_note: 'Adresa pharminfo: https://www.pharminfo.sk',
    plugin_pharminfo_endpoint: 'Endpoint URL',
    plugin_pharminfo_endpoint_note: 'Adresa endpointu bez URL adresy: /eo/api.svc/',
    plugin_pharminfo_access_key: 'Access key',
    plugin_pharminfo_private_key: 'Private key',
    plugin_pharminfo_logins: 'Autorizácia',
    plugin_pharminfo_test: 'Test pripojenia',
    plugin_sal: 'Esthederm Salón',
    plugin_sal_text: 'Prepojenie medzi CRM a aplikáciou Esthederm Salón',

    // DOPRAVA A PLATBA
    trade_payments: 'Doprava a platba',
    trade: 'Doprava',
    trade_text: 'Spôsoby dopravy objednávky z e-shopu',
    payments: 'Platba',
    payments_text: 'Spôsoby platby',
    payment_gate: 'Platobná brána',

    // eshop
    eshop: 'E-shop',
    eshop_codebook_published: 'Publikovaný na e-shope',
    eshop_codebook_published_note: 'Číselník bude dostupný pri vyhľadávaní produktu na e-shope',

    // WEBPAGE DESIGNER
    web_page_designer: 'Designer stránok',
    web_page_designer_: 'Designer',
    web_page_items: 'Stránka',
    web_page_item_new: 'Pridať prvok',
    web_page_item: 'Pridať stránky',
    web_page_item_select: 'Pridať prvok na stránku',
    web_page_preview: 'Náhľad stránky',
    web_page_background: 'Pozadie',
    web_page_background_typ: 'Typ pozadia',
    web_page_background_solid: 'Jednofarebné',
    web_page_background_gradient: 'Prechod',
    web_page_color: 'Farba',
    web_page_color_select: 'Výber farby',
    web_page_padding: 'Okraje bloku',
    web_page_padding_top: 'Vrchný',
    web_page_padding_bottom: 'Spodný',
    web_page_border_radius: 'Zaoblenie',
    web_page_border_radius_value: 'Zaoblenie',
    web_page_align: 'Zarovnať blok',
    web_page_image_position: 'Umiestnenie',
    web_page_image_position_left: 'Umiestnenie vľavo',
    web_page_image_position_right: 'Umiestnenie napravo',
    web_page_max_width: 'Max. šírka',
    web_page_max_width_zero: 'max',
    web_page_pallette: 'Farby',
    web_page_pallette_saved: 'Uložené farby',
    web_page_pallette_save: 'Uložiť farbu',
    web_page_pallette_save_: 'Uložiť farbu do zoznamu',
    web_page_pallette_category: 'Kategória',
    web_page_pallette_category_my: 'Moje farby',
    web_page_pallette_delete: 'Vymazať farbu zo zoznamu',

    web_page_item_enabled: 'Blok aktívny',
    web_page_item_preview: 'Náhľad',
    web_page_item_preview_: 'Náhľad prvku stránky',
    web_page_item_font: 'Veľkosť písma',
    web_page_item_color: 'Farba',
    web_page_item_title: 'Nadpis',
    web_page_item_thickness: 'Hrúbka čiary',
    web_page_item_spacer_height: 'Výška medzery',
    web_page_item_title_note: 'Nadpis s voliteľnou velkosťou písma',
    web_page_item_text: 'Text',
    web_page_item_text_short: 'Krátky text',
    web_page_item_text_note: 'Textový odstavec - neformátovaný',
    web_page_item_label_max: 'maximálne 100 znakov',
    web_page_item_text_max: 'maximálne 1000 znakov',
    web_page_item_text_short_max: 'maximálne 50 znakov',
    web_page_item_html: 'Html text',
    web_page_item_html_note: 'Text vo formáte HTML',
    web_page_item_image: 'Obrázok',
    web_page_item_image_note: 'Obrázok s voliteľným textom',
    web_page_item_banner: 'Banner',
    web_page_item_banner_note: 'Obrázkový banner po celej šírke stránky',
    web_page_item_line: 'Oddeľujúca čiara',
    web_page_item_line_note: 'Čiara oddeľujúca jednotlivé prvky',
    web_page_item_space: 'Medzera',
    web_page_item_space_note: 'Medzera oddeľujúca jednotlivé prvky',
    web_page_item_text_image: 'Text a obrázok',
    web_page_item_text_image_note: 'Text s možnosťou výberu umiestnenia obrázku',
    web_page_item_images: 'Stĺpce',
    web_page_item_images_note: 'Obrázky a text v stľpcoch',
    web_page_item_link: 'Odkaz',
    web_page_item_link_note: 'Prechod na adresu URL',
    web_page_item_button: 'Odkaz - adresa URL',
    web_page_item_button_text: 'Text odkazu',
    web_page_item_tip_color_1: 'Farba pozadia',
    web_page_item_tip_color_2: 'Okrajová farba',
    web_page_item_tip_width: 'Veľkosť okraja',
    web_page_item_button_color_1: 'Farba pozadia',
    web_page_item_button_color_2: 'Farba textu',
    web_page_item_button_typ: 'Typ',
    web_page_item_image_size: 'Veľkosť',
    web_page_item_product: 'Produkt',
    web_page_item_product_note: 'Informácie o produkte',
    web_page_tips: 'Tip / Upozornenie',
    web_page_tips_note: 'Tip alebo upozornenie s nadpisom a krátkym textom',
    web_page_gallery: 'Galéria',
    web_page_gallery_size: 'Veľkosť obrázkov v galérii',
    web_page_gallery_note: 'Galéria fotografíí a obrázkov',
    web_page_enabled: 'Zobraziť len aktívne položky',
    web_page_unabled: 'Neaktívne',
    web_page_settings: 'Nastavenie vzhľadu stránky',
    web_page_paddings: 'Okraje stránky',
    web_page_enabled_: 'Stránka aktívna',
    web_page_unabled_: 'Stránka neaktívna',
    web_page_create: 'Vytvorenie obsahu',
    web_page_create_question: 'Chcete vytvoriť nový grafický obsah?',
    web_page_create_question_note: 'Obsah môže obsahovať graficky upravený text, obrázky a odkazy',
    web_page_category: 'Stránka kategórie',
    web_page_category_new: 'Vytvoriť stránku kategórie',
    web_page_category_title: 'Informačná HTML stránka kategórie',
    web_page_product_new: 'Vytvoriť stránku produktu',
    web_page_product: 'Stránka produktu',
    web_page_stats: 'Štatistika',
    web_page_stats_text: 'Štatistika počtu kliknutí na jednotlivé odkazy stránky',
    web_page_stat_clicks: 'Počet kliknutí',
    web_page_stat_link: 'Názov odkazu',


    // BLOG
    blog: 'Príspevok',
    blogs: 'Príspevky',
    blog_internal: 'Príspevky v organizácii',
    blog_public: 'Blog',
    blogs_internal: 'Príspevky v rámci organizácie',
    blogs_public: 'Príspevky pre zákazníkov',
    blog_new: 'Nový príspevok',
    blog_not_published: 'Nepublikované',
    blog_published: 'Publikované',
    blog_published_: 'Publikovaný príspevok',
    blog_head: 'Hlavička a obsah príspevku',
    blog_content_text: 'Obsahová stránka príspevku',
    blog_content: 'Obsah príspevku',
    blog_content_new: 'Vytvoriť obsah príspevku',
    blog_label: 'Titulok príspevku',
    blog_description: 'Krátky úvodný text',
    blog_image: 'Obrázok príspevku',
    blog_image_error: 'Chýba obrázok príspevku',
    blog_author: 'Autor príspevku',
    blog_date: 'Dátum publikovania',
    blog_preview: 'Náhľad príspevku',
    blog_delete: 'Vymazať príspevok',
    blog_delete_ask: 'Vymazať príspevok?',
    blog_delete_ask_text: 'Vymazaný príspevok bude odstránaný zo zoznamu príspevkov a nebude sa dať obnoviť',
    blog_delete_info: 'Vymazanie aktuálneho príspevku a jeho obsahu',
    blog_settings: 'Nastavenia príspevkov',
    blog_category: 'Kategória',
    blog_all_countries: 'Viditeľnosť príspevkov',
    blog_all_countries_yes: 'Spoločné',
    blog_all_countries_no: 'Delené podľa krajín',
    blog_all_countries_note: 'Publikované príspevky budú spoločné pre všetky krajiny, alebo delené podľa CRM krajín',

    // MARKETING
    marketing: 'Marketing',
    marketing_blog: 'Marketing',

    newsletter: 'Newsletter',
    newsletter_editor: 'Tvorba newsletterov',
    newsletter_new: 'Nový newsletter',
    newsletter_content: 'Obsah',
    newsletter_title: 'Titulok',
    newsletter_delete: 'Vymazať newsletter',
    newsletter_name: 'Titulok newslettera',
    newsletter_label_color: 'Farba titulku',
    newsletter_delete_info: 'Vymazanie aktuálneho newslettera a jeho obsahu',
    newsletter_delete_ask_text: 'Vymazaný newsletter bude odstránaný zo zoznamu a nebude sa dať obnoviť',
    newsletter_background_color: 'Farba pozadia',
    newsletter_border_radius: 'Zaoblenie pozadia',
    newsletter_text_footer: 'Doplňujúci text na konci stránky',
    newsletter_text: 'Text',
    newsletter_text_align: 'Zarovnanie textu',
    newsletter_text_color: 'Farba textu',
    newsletter_content: 'Obsah newslettera',
    newsletter_content_new: 'Vytvoriť obsah newslettera',
    newsletter_preview: 'Náhľad',
    newsletter_preview_: 'Náhľad newslettera',
    newsletter_text_approval_: 'Text súhlasu',
    newsletter_text_approval: 'Tento newsletter bol odoslaný na základe marketingových súhlasov.',
    newsletter_logo: 'Logo spoločnosti',
    newsletter_logo_enabled: 'Zobrazovať',
    newsletter_logo_hide: 'Nezobrazovať',
    newsletter_link: 'Link na newsletter',
    newsletter_link_copied: 'Cesta k newslettru bola skopírovaná do clipboardu.',

    newsletter_emails_categories: 'Kategórie odberateľov newslettera',
    newsletter_emails_category: 'Kategória odberateľov',
    newsletter_emails_category_import_note: 'E-mailové adresy budú zaradené do zvolenej kategórie',
    newsletter_emails_category_all: 'Všetky kategórie',
    newsletter_emails: 'Registrované emailové adresy odberateľov newslettera',
    newsletter_emails_: 'Odberatelia newslettera',
    newsletter_email: 'Odberateľ',
    newsletter_email_error: 'Zadaná e-mailová adresa už je registrovaná!',
    newsletter_email_enabled: 'Aktívny odberateľ',
    newsletter_email_new: 'Nový odberateľ',
    newsletter_email_customers_import: 'Importovať zo zoznamu zákazníkov',
    newsletter_email_customers_import_text: 'Importovanie e-mailových adries zákazníkov do zoznamu odberateľov newslettera',
    newsletter_email_customers_import_text_: 'Importované budú len adresy, ktoré sa v danej kategórii odberateľov nenachádzajú',
    newsletter_email_import_enabled: 'Len aktívnych zákazníkov',
    newsletter_email_import: 'Import',
    newsletter_email_imported: 'Výsledok imporovania',
    newsletter_email_imported_sum: 'Celkový počet nájdených zákazníkov',
    newsletter_email_imported_count: 'Z toho počet importovaných adries',

    newsletter_campaign: 'E-mailová kampaň',
    newsletter_campaign_: 'Kampaň',
    newsletter_campaign_plugin_email: 'E-mailová adresa',
    newsletter_campaign_plugin_email_note: 'E-mailová adresa z ktorej bude newsletter odosielaný',
    newsletter_campaign_plugin_password: 'Heslo k adrese',
    newsletter_campaign_plugin_max_send: 'Max. počet odoslaní',
    newsletter_campaign_plugin_max_send_note: 'Počet e-mailov odoslaných počas jedného volania webcron - max. množstvo je 100x',
    newsletter_campaigns: 'E-mailové kampane',
    newsletter_campaign_new: 'Nová kampaň',
    newsletter_campaign_name: 'Názov kampane',
    newsletter_campaign_name_note: 'Názov nie je odosielaný zákazníkovi, slúži len na identifikáciu kampane pre užívateľa',
    newsletter_statuses: ['Vytvorená', 'Spustená', 'Pozastavená', 'Ukončená', 'Zastavená'],
    newsletter_statuses_all: 'Všetky kampane',
    newsletter_status: 'Stav kampane',
    newsletter_select: 'Výber newslettera',
    newsletter_select_: 'Výbrať newsletter',
    newsletter_change: 'Zmeniť newsletter',
    newsletter_campaign_start: 'Spustiť kampaň',
    newsletter_campaign_start_ask: 'Kampaň bude vytvorená a odosielanie e-mailov bude spustené',
    newsletter_campaign_start_ask_note: 'Pozor, po spustení kampane už nebude možné meniť zoznam e-mailových adries!',
    newsletter_campaign_start_first: 'Vytvoriť a spustiť kampaň',
    newsletter_campaign_started: 'Kampaň je spustená a prebieha odosielanie e-mailov',
    newsletter_campaign_stop: 'Zastaviť kampaň',
    newsletter_campaign_stop_: 'Zastaviť kampaň a odosielanie e-mailov',
    newsletter_campaign_stoped: 'Kampaň je zastavená',
    newsletter_campaign_finished: 'Kampaň je ukončená',
    newsletter_campaign_not_created: 'Kampaň nie je vytvorená',
    newsletter_campaign_page: 'Obsah newslettera',
    newsletter_campaign_newsletter: 'Newsletter kampane',
    newsletter_campaign_stats: 'Štatistika',
    newsletter_campaign_stats_items: 'Štatistika počtu kliknutí na jednotlivé odkazy newslettera',
    newsletter_replyto: 'Adresa odpovede',
    newsletter_replyto_note: 'E-mailová adresa na ktorú môže zákazník odpovedať na newsletter',
    newsletter_campaign_category_note: 'Newsletter bude odoslaný na e-mailové adresy v danej kategórií',
    newsletter_campaign_stat: 'Priebeh odosielania newslettera',
    newsletter_campaign_stat_count: 'Celkový počet e-mailových adries',
    newsletter_campaign_stat_count_sended: 'Počet úspešne odoslaných',
    newsletter_campaign_stat_count_to_send: 'Zostáva odoslať',
    newsletter_campaign_stat_count_error: 'Neodoslaných',
    newsletter_campaign_subject: 'Predmet emailovej správy',
    newsletter_campaign_subject_note: 'Text predmetu e-mailovej správy odoslanej zákazníkovi',
    newsletter_campaign_limits: 'Limity odosielania emailov: 300 za hodinu / 3000 za jeden deň',
    newsletter_customers_category: 'Z kategórie zákazníkov',
    newsletter_to_email_category: 'Do kategórie odberateľov',
    newsletter_permissions: 'Tvorba newsletterov',

    // GALERIA FOTOGRAFII
    gallery: 'Galéria',
    gallery_add: 'Nová fotografia',
    gallery_create: 'Vytvorenie galérie',
    gallery_create_ask: 'Chcete vytvoriť novú galériu fotografií?',
    gallery_create_askproduct: 'Galéria fotografií bude priradená k produktu',
    gallery_photo_delete: 'Vymazať fotografiu?',
    gallery_display: 'Štýl zobrazovania  fotografií',
    gallery_no_exist: 'Galéria fotografií nie je vytvorená',
    gallery_create: 'Vytvoriť galériu',

    // VYHLADAVANIE KODOV - DISTRIBUTOR / PRODUKT / ZAKAZNIK
    dist_code_distributor: 'Hľadanie ID kódu distribútora',
    dist_code_product: 'Hľadanie ID kódu produktu',
    dist_code_customer: 'Hľadanie ID kódu zákazníka',
    dist_code_distributor_search: 'Názov spoločnosti, alebo IČO',
    dist_code_product_search: 'Hľadaný reťazec (názov, kód, ean, výrobca)',
    dist_code_customer_search: 'Názov lekárne',
    dist_auto_sending: 'Nastavenie automatického odosielania objednávok',
    dist_code_checker: 'Kontrola objednávkových kódov',
    dist_code_checker_label: 'Kontrola objednávkových ID kódov',
    dist_checker_stock_select: 'Distribučný sklad',
    dist_checker_stock_select_note: 'Zvoľte distribučný sklad, na základe ktorého prebehne kontrola',
    dist_check: 'Spustiť',
    dist_products_code_missing: 'Zoznam produktov, ktoré nemajú zadaný kód potrebný k vytvoreniu objednávky',
    dist_customers_code_missing: 'Zoznam zákazníkov, ktorí nemajú zadaný kód potrebný k vytvoreniu objednávky v adrese dodania',

    // PARAMETRE PRODUKTU
    parameter_typ: 'Typ premennej',
    parameter_types: ['Číselná hodnota (celé číslo)', 'Číselná hodnota (desatiné číslo)', 'Textová hodnota', 'Hodnota z číselníku'],
    parameter_add: 'Pridať parameter',
    parameter_delete: 'Vymazať parameter',
    parameter_delete_text: 'Parameter produktu bude vymazaný',
    product_params: 'Parametre produktu',
    product_params_label: 'Parametre a špecifikácia produktu',
    product_param_new: 'Nový parameter',
    product_param_update: 'Úprava hodnoty',
    product_param_unit: 'Jednotka',
    product_param_value: 'Hodnota',
    product_my_groups: 'Moje skupiny produktov',

    // SERVICES - SLUŽBY
    services: 'Služby',
    service: 'Služba',
    service_name: 'Názov služby',
    service_name_: 'Názov',
    service_new: 'Nová služba',
    service_new_trade: 'Nová doprava',
    service_new_payment: 'Nová platobná metóda',
    service_enabled: 'Služba aktívna',
    services_categories: 'Kategórie služieb',
    service_category: 'Kategória',
    service_cost: 'Nákupná cena',
    service_code: 'Kód služby',
    service_text: 'Krátky popis služby',
    service_select: 'Výber služby',
    service_trade_for: 'Doprava určená pre',
    service_payment_for: 'Platba určená pre',
    service_payments: 'Platobné metódy',
    service_payments_note: 'Platobné metódy, ktoré budú k dispozícii pre daný spôsob dopravy',
    service_need_address: 'Vyžaduje sa adresa',
    service_need_address_note: 'Od zákazníka bude vyžadované zadanie adresy doručenia',
    service_trade_free: 'Doprava zadarmo',
    service_payment_free: 'Platba zadarmo',
    service_trade_free_price: 'Od ceny nákupu',
    service_trade_free_price_note: 'Doprava bude zadarmo ak je výška nákupu minimálne ... bez DPH',
    service_payment_free_price_note: 'Platobná metóda bude zadarmo ak je výška nákupu minimálne ...',

    order_add_service: 'Doplniť službu',
    product_code_checking: 'Kontrola kódu',
    product_code_checking_note: 'Kontrola kódu pri zakladaní produktov, či už kód existuje',
    pricelist_multi_language: 'Multijazyčné cenníky',
    pricelist_multi_language_note: 'Vytváranie cenníkov vo viacero platobných menách v jednej CRM krajine',

    order_send_error: 'Chyba pri odosielaní objednávky',
    order_pharminfo_codes: {
        0: 'Nová objednávka. Stav pred odoslaním.',
        100: 'Pripravená na odoslanie.',
        200: 'Odosielaná',
        201: 'Nesprávny parameter.',
        202: 'Objednávka z daným číslo objednávky už existuje.',
        203: 'Nesprávne formátovaná požiadavka pre vloženie novej objednávky.',
        300: 'Potvrdená. Všetky položky boli potvrdené',
        301: 'Potvrdená. Niektoré položky boli potvrdené.',
        400: 'Zrušená',
        401: 'Požiadavka nebola autentifikovaná.',
        404: 'Požadovaný objekt neexistuje.',
        405: 'Nesprávne adresovanie API metódy.',
        500: 'Chyba pri odosielaní.',
        600: 'Chyba pri spracovaní dodávateľom.',
        601: 'Chyba pri spracovaní dodávateľom. Neznámy zákazník.',
        602: 'Chyba pri spracovaní dodávateľom. Blokovaný zákazník.',
        603: 'Chyba pri spracovaní dodávateľom. Čas určený na spracovanie vypršal.',
        604: 'Chyba pri spracovaní dodávateľom. Žiadna z položiek nebola potvrdená.',
        605: 'Chyba pri spracovaní dodávateľom. Zákazník prekročil úverový limit.',
        606: 'Chyba pri spracovaní dodávateľom. Objednávka nespĺňa dodávateľom stanovené minimálne požiadavky na množstvo alebo celkovú cenu.',
    },
    order_pharminfo_item_codes: {
        0: 'Stav položky ešte nebol vyhodnotený',
        100: 'Pripravená na odoslanie',
        101: 'Položka potvrdená čiastočne, množstvo je menšie ako objednané',
        102: 'Položka nebola potvrdená, množstvo je 0',
        200: 'Pri objednávaní položky nastala nešpecifikovaná chyba',
        201: 'Nenájdená položka',
        202: 'Nepovolený predaj nešpecifikovaný',
        203: 'Nepovolený predaj alkoholu',
        204: 'Nepovolený predaj liekov',
        205: 'Nepovolený predaj opiátov',
        206: 'Nepovolený predaj pre lekáreň',
        207: 'Nepovolený predaj prekurzorov',
        208: 'Neznámy alebo nepovolený rabat',
        300: 'Položka nebola v odpovedi nájdená',
    },
    order_resend: 'Odoslať znovu',
    order_protocol: 'Protokol o objednávke',
    order_protocol_amount: 'Objednaný počet',
    order_protocol_confirmed: 'Potvrdený počet',
    order_protocol: 'Protokol o objednávke',

    error_details: 'Popis chyby',

    export_delete_days: 'Vymazanie súborov',
    export_delete_days_note: 'Počet dní, po ktorých budú exportované súbory vymazané',
    export_delete_days_: 'Počet dní',

    reklamacia_email_note: 'Odosielanie reklamácií',
    reklamacia_email_sk: 'Emailová adresa SK',
    reklamacia_email_cz: 'Emailová adresa CZ',
    reklamacia_email_en: 'Emailová adresa EN',
}
