/*
*
* =================================================================================================================
* TRASY

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormNote, DialogGPS, ContactCompany, DeleteButton, TextLine } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, IconButton, Pagination, Paper, Skeleton, Stack, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowDownShortWide, faArrowUp, faArrowUpShortWide, faBook, faCar, faCircle, faCog, faCopy, faDownload, faDownLong, faEarth, faEllipsisVertical, faFolderOpen, faHome, faHomeAlt, faInfo, faInfoCircle, faLayerGroup, faLocationDot, faMap, faPerson, faPlusCircle, faQuestion, faRoute, faSave, faSearch, faSort, faUpload, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, GetDistance, GetLeftPosition, GetPages, IsEmpty, LoadValue, SaveValue, deg2rad } from './functions';
import { Photos } from './photos';
import { GoogleMap } from './geolocation.js';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CustomerSelect } from './customers.js';
import dist from 'react-image-crop';
import { FunctionsSharp } from '@mui/icons-material';
import { Customer } from './customer.js';
import { GetHoursFromMinutes } from './functions_date.js';

export const MyRoutes = (props) => {

    let module_id = global.model.routes;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'routes';

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        user_id: props.user.id
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.vendor_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.vendor_name, default: true },
        { id: 2, enabled: true, checked: false, column: 'town', label: lang.town },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 80;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const menuWidth = global.sub_menu_width;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale.id]);


    const db_get = async (page, filter, maxItems) => {
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_routes', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.vendors,
                    filename: lang.vendors, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = {
            ordering: ordering,
            order_column: orderColumn
        };

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const ChangeEnabled = (item, value) => {

        let data = {
            id: item.id,
            enabled: value,
        }
        db_update(data);
    }

    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // NAZOV
                column = 'name';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;
        }

        var filter = {
            ordering: order,
            order_column: column
        };
        if (search != '') {
            filter.search = search;
        }
        db_get(0, filter, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }


    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>

                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%', marginTop: 10 }}>
                        <div style={{ ...styles.Block }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <TextInput search theme={props.theme} width={300} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                    <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                        {lang.route_new}
                                    </Button>
                                </div>
                            </div>

                            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - 30, marginTop: 0, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                <div id={'id_start'} style={{ ...styles.Block, marginBottom: 20 }}></div>

                                {/* ****************************************************************************************************************
                                        TABLE DATA
                                **************************************************************************************************************** */}

                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            width: '98%',
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowHeight,
                                            cursor: 'pointer',
                                            marginTop: 5, marginBottom: 5,
                                            paddingTop: 2, paddingBottom: 2,
                                            borderLeft: item.enabled == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '96%' }}>
                                            <div style={{ ...styles.BlockLeft, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                                <FontAwesomeIcon style={{ height: 20, color: color.light_gray }} icon={faCar} />
                                            </div>
                                            <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '86%', height: rowHeight }}>
                                                <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name}</p>
                                                {item.note != '' ?
                                                    <TextLine text={item.note} fontSize={global.font_xsmall} color={color.black} />
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                {/* ENABLED */}
                                                <SwitchEnable enabled={item.enabled == false ? false : true} theme={props.theme} func={(value) => ChangeEnabled(item, value)} />
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <EmptyList row small lang={lang} />
                                }

                                {/* ****************************************************************************************************************
                                        PAGINATION
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 200 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showExport == true ?
                <DialogExport remote={props.remote} remoteMobile={props.remoteMobile} rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <MyRoute remote={props.remote} remoteMobile={props.remoteMobile} module_id={module_id} item={itemSelected} organization={props.organization} settings={props.settings} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}



/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const MyRoute = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [storeData, setStoreData] = useState({});
    const [showGPS, setShowGPS] = useState(false);

    // CUSTOMERS
    const [items, setItems] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [itemsExcluded, setItemsExcluded] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);

    // VALUES
    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');
    const [routeLength, setRouteLength] = useState(0);
    const [startLat, setStartLat] = useState(String(LoadValue('gps-home-lat', '0')));
    const [startLng, setStartLng] = useState(String(LoadValue('gps-home-lng', '0')));
    const [defaultLat, setDefaultLat] = useState(String(LoadValue('gps-home-lat', '')));
    const [defaultLng, setDefaultLng] = useState(String(LoadValue('gps-home-lng', '')));
    const [time, setTime] = useState(0);
    const [note, setNote] = useState('');
    const [gpsChanged, setGpsChanged] = useState(props.item == false ? true : false);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DIALOGS
    const [showMap, setShowMap] = useState(false);
    const [showTraceMap, setShowTraceMap] = useState(false);
    const [points, setPoints] = useState([]);
    const [showCustomers, setShowCustomers] = useState(false);
    const [showCustomer, setShowCustomer] = useState(false);
    const [showRouteSettings, setShowRouteSettings] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = dialogWidth - column1 - 40;
    const rowHeight = 86;
    const tagColor = color.light_gray;
    const kmSize = 46;

    const tabs = [
        { id: 0, enabled: true, label: lang.setting },
        { id: 1, enabled: true, label: lang.customers },
        { id: 2, enabled: true, label: lang.history },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_route', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_route_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------                    
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (data.id == 0) {
                    setItemID(json.item_id);
                }

                setEditing(false);
                setRedrawRequired(true);
                setShowOK(true);

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_items = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_route_items', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    route_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                //tmp = CalculateDistances(tmp);
                setItems(tmp);
                setRouteLength(json.distance_total);
                setTime(json.time_total);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_item_update = async (data) => {
        Debug(data);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_route_items_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    route_id: itemID,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                db_items();
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_duplicate = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_route_duplicate', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    route_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                setRedrawRequired(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_reorder = async (items) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_route_items_reorder', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    route_id: itemID,
                    items: items
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_items();
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setName(item.name);
        setRouteLength(item.route_length);
        setStartLat(item.start_lat);
        setStartLng(item.start_lng);
        setTime(item.time);
        setNote(item.note);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setGpsChanged(false);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.enabled = enabled;
        storeData.name = name;
        storeData.route_length = routeLength;
        storeData.start_lat = startLat;
        storeData.start_lng = startLng;
        storeData.time = time;
        storeData.note = note;
    }

    const Save = () => {
        setError('');
        var error = false;
        error += IsEmpty(name) == true ? 1 : 0;

        if (error == 0) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: props.item == false ? 0 : itemID,
                action: props.item == false ? global.logs.created : storeData.enabled != enabled ? enabled == true ? global.logs.enabled : global.logs.disabled : global.logs.modified
            };

            let data = {
                id: itemID == false ? 0 : itemID,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                user_id: props.user.id,
                enabled: enabled,
                name: name.trim(),
                route_length: routeLength,
                start_lat: startLat,
                start_lng: startLng,
                time: time,
                note: note,
                gps_changed: gpsChanged,
                logs: log
            };

            StoreEditing();
            Debug('Save data');
            Debug(data);
            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 1) {
            db_items();
        }
        if (id == 2) {
            if (logs == false) {
                db_get_logs();
            }
        }
    }

    const GetGPS = (typ, address, lat, lng) => {
        setShowGPS(false);

        if (typ == true) {
            setGpsChanged(true);
            setStartLat(lat);
            setStartLng(lng);
        }
    }

    const AddNew = () => {
        if (items != false) {
            var ids = [];
            items.forEach(item => {
                ids.push(item.customer_id);
            });
            setItemsExcluded(ids);
        } else {
            setItemsExcluded(false);
        }

        setShowCustomers(true);
    }

    const CustomersResult = (typ, customer_id, customer, address) => {
        setShowCustomers(false);

        if (typ == true && customer != false) {
            let data = {
                id: 0,
                enabled: true,
                organization_id: props.organization.id,
                route_id: itemID,
                customer_id: customer.id,
                shop_id: address != false ? address.id : 0,
                name: '',
                lat: address != false ? address.lat : 0,
                lng: address != false ? address.lng : 0,
                distance: 0,
                distance_total: 0,
                time: 0,
                visit_time: 30,
                priority: 0,
                note: ''
            };

            Debug(data);
            db_item_update(data);
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (result.destination) {
            var tmp = reorder(items, result.source.index, result.destination.index);
            var n = 1;
            var items_ = [];
            tmp.forEach(item => {
                item.priority = n;

                let data = {
                    id: item.id,
                    priority: n
                }
                items_.push(data);
                n++
            });

            //tmp = CalculateDistances(tmp);

            setItems(tmp);
            db_reorder(items_);
        }

    }

    const DeleteItem = (item) => {
        let data = {
            id: parseInt(item.id),
            enabled: false,
            gps_changed: true
        };

        db_item_update(data);
    }

    const ShowTrace = () => {
        var array = [];

        if (parseFloat(startLat) > 0 && parseFloat(startLng) > 0) {
            let data = {
                lat: startLat,
                lng: startLng,
                priority: 0
            };
            array.push(data);
        }

        var n = 1;
        items.forEach(item => {
            if (item.lat > 0 && item.lng > 0) {

                let distance = GetDistance(startLat, startLng, item.lat, item.lng);
                Debug(distance);

                let data = {
                    lat: item.lat,
                    lng: item.lng,
                    priority: n,
                    distance: distance * 1.15
                };
                array.push(data);
                n++;
            }
        });

        if (array.length > 0) {
            setPoints(array);
            setShowTraceMap(true);
        }
    }

    const SortCustomers = () => {
        if (items != false) {
            var tmp = items;
            tmp.sort((a, b) => {
                if (parseInt(a.distance_total) > parseInt(b.distance_total)) {
                    return 1;
                } else {
                    return -1;
                }
            })

            var n = 1;
            var items_ = [];
            tmp.forEach(item => {
                item.priority = n;

                let data = {
                    id: item.id,
                    priority: n
                }
                items_.push(data);
                n++
            });

            setItems(tmp);
            Debug(tmp);
            db_reorder(items_);
        }
    }

    const ChangeEdit = (value) => {
        setGpsChanged(false);
        setEditing(value);
    }

    const CustomerShow = (item) => {
        setItemSelected(item);
        setShowCustomer(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowRouteSettings(true);
    }

    const RouteResult = (typ, data) => {
        setShowRouteSettings(false);

        if (typ == true) {
            db_item_update(data);
        }
    }

    const SaveGPS = () => {
        SaveValue('gps-home-lat', String(startLat));
        SaveValue('gps-home-lng', String(startLng));
        setDefaultLat(startLat);
        setDefaultLng(startLng);

        setShowOK(true);
    }

    const LoadGPS = () => {
        Debug(LoadValue('gps-home-lat', ''));
        setStartLat(defaultLat);
        setStartLng(defaultLng);
    }


    const Duplicate = () => {
        db_duplicate();
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.route_new : lang.route}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.Block, height: tabHeight }}>
                        <DialogTabs disabled={editing == true ? true : false} value={tabIndex} items={tabs} width={dialogWidth / 3} theme={props.theme} func={(id) => ChangeTab(id)} />
                    </div>

                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {storeData != false ?
                                <div>
                                    <FormSpace />
                                    <FormLabel editing={editing} editButton={true} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => ChangeEdit(true)} />
                                    <FormYesNo value={enabled} editing={editing} title={lang.route_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                    <FormText require redraw value={name} editing={editing} title={lang.route_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                    <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />

                                    <FormSpace height={36} />
                                    <div style={{ ...styles.BlockRowRaw, width: column1 + column2 + 10 }}>
                                        <FormLabel editing={false} editButton={editing} buttonIcon={faSearch} icon={faEarth} title={lang.route_start} column1={column1} column2={column2 - 32 - 42} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setShowGPS(true)} />
                                        <div style={{ ...styles.BlockRight, width: 42 }}>
                                            {editing == true ? startLat != '' && startLng != '' ?
                                                <Tooltip title={lang.gps_save}>
                                                    <IconButton onClick={() => SaveGPS()} style={{ ...styles.ButtonIcon }}>
                                                        <FontAwesomeIcon style={{ width: 12 }} icon={faSave} />
                                                    </IconButton>
                                                </Tooltip>
                                                : null : null}
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 42 }}>
                                            {editing == true ? defaultLat != '' && defaultLng != '' ?
                                                <Tooltip title={lang.gps_load}>
                                                    <IconButton onClick={() => LoadGPS()} style={{ ...styles.ButtonIcon }}>
                                                        <FontAwesomeIcon style={{ width: 12 }} icon={faFolderOpen} />
                                                    </IconButton>
                                                </Tooltip>
                                                : null : null}
                                        </div>
                                    </div>
                                    <FormText redraw numeric width={150} value={startLat} editing={editing} title={lang.route_start_lat} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setStartLat(txt); setGpsChanged(true) }} />
                                    <FormText redraw numeric width={150} value={startLng} editing={editing} title={lang.route_start_lng} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setStartLng(txt); setGpsChanged(true) }} />
                                    <FormSpace />
                                    <FormText redraw value={parseInt(routeLength / 1000).toFixed(1) + ' ' + lang.route_length_km} editing={false} title={lang.route_length_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => Debug('')} />
                                    <FormText redraw value={GetHoursFromMinutes(parseInt(time) / 60) + ' ' + lang.route_length_hours} editing={false} title={lang.route_time_total} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => Debug('')} />
                                    <FormNote title={lang.route_length_time_note} icon={faInfoCircle} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                                    {parseFloat(startLat) > 0 && parseFloat(startLng) > 0 ?
                                        <Button onClick={() => setShowMap(true)} style={{ ...styles.ButtonOutlined, marginTop: 10 }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.gps_show_map}</p>
                                        </Button>
                                        : null}

                                    <FormSpace />
                                    {itemID > 0 ?
                                        <DeleteButton ask button bold label={lang.route_duplicate} icon={faCopy} position={'top'} backgroundColor={color.button_action} text={lang.route_duplicate_ask} text_note={lang.route_duplicate_text} tooltip={lang.route_duplicate} theme={props.theme} lang={props.lang} func={() => Duplicate()} />
                                        : null}

                                    {props.remote == true && props.remoteMobile == true ?
                                        <FormSpace height={500} />
                                        : null}

                                </div>
                                :
                                <Stack style={{ ...styles.Block }} spacing={1}>
                                    <FormSpace />
                                    <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                </Stack>}
                        </div>
                        : null}

                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight }} >
                            <div style={{ ...styles.BlockRow, width: dialogWidth - 20, height: 60 }}>
                                <div style={{ ...styles.BlockRow, width: '33%', height: 60 }}>
                                    {items != false && parseFloat(startLat) > 0 && parseFloat(startLng) > 0 ?
                                        <div style={{ ...styles.BlockLeft, width: 40, height: 60 }}>
                                            <IconButton onClick={() => SortCustomers()} style={{ ...styles.ButtonIcon }}>
                                                <FontAwesomeIcon style={{ height: 14 }} icon={faArrowDownShortWide} />
                                            </IconButton>
                                        </div>
                                        : null}
                                    <div style={{ ...styles.BlockLeft, height: 60 }}>
                                        <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.route_customers}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.Block, width: '33%', height: 60 }}>
                                    {items != false ?
                                        <Button onClick={() => ShowTrace()} style={{ ...styles.ButtonOutlined }}>
                                            {lang.gps_show_map}
                                        </Button>
                                        : null}
                                </div>
                                <div style={{ ...styles.BlockRight, width: '33%', height: 60 }}>
                                    <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 190 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                        {lang.route_customer_add}
                                    </Button>
                                </div>
                            </div>
                            <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - 60, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                {items != false ?
                                    <div style={{ ...styles.BlockLeft }}>
                                        <FontAwesomeIcon style={{ height: 28, color: '#C0C0C0', marginLeft: 20 }} icon={faHome} />
                                    </div>
                                    : null}

                                {items != false ?
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={{ ...styles.Block }}
                                                >
                                                    {items.map((item, index) => (
                                                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    {index < items.length ?
                                                                        <div style={{ ...styles.BlockLeft }}>
                                                                            <div style={{ ...styles.BlockRow }}>
                                                                                <div style={{ ...styles.BlockLeft, width: 40, height: kmSize }}>
                                                                                    <FontAwesomeIcon style={{ height: 28, color: tagColor, marginLeft: 16, marginTop: 1 }} icon={faDownLong} />
                                                                                </div>
                                                                                <div style={{ ...styles.BlockLeft, width: '90%', height: kmSize }}>
                                                                                    {item.distance != undefined ? item.distance > 0 ?
                                                                                        <p style={{ ...styles.TextTiny, color: color.middle_gray }}>~ {(parseFloat(item.distance) / 1000).toFixed(1)} km ({parseInt(parseInt(item.time) / 60)} min.)</p>
                                                                                        :
                                                                                        <p style={{ ...styles.TextTiny, color: color.middle_gray }}>?</p>
                                                                                        : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null}
                                                                    <Paper
                                                                        elevation={overID == item.id ? 4 : 1}
                                                                        style={{
                                                                            ...styles.Block,
                                                                            width: dialogWidth - 20,
                                                                            minHeight: rowHeight,
                                                                            backgroundColor: color.white,
                                                                            marginTop: 3, marginBottom: 3,
                                                                            borderRadius: global.radius
                                                                        }}
                                                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                                    >
                                                                        <div style={{ ...styles.BlockRow }}>
                                                                            <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, position: 'relative', width: 50, minHeight: rowHeight, cursor: 'pointer' }}>
                                                                                <FontAwesomeIcon style={{ height: 26, color: tagColor, marginLeft: 15 }} icon={faLocationDot} />
                                                                                <FontAwesomeIcon style={{ height: 18, color: tagColor, marginLeft: 22, position: 'absolute', top: rowHeight / 2 + 16 }} icon={faEllipsisVertical} />
                                                                            </div>
                                                                            <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 40, minHeight: rowHeight, cursor: 'pointer' }}>
                                                                                <p style={{ ...styles.TextXSmall }}>{index + 1}.</p>
                                                                            </div>
                                                                            <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '45%', minHeight: rowHeight, cursor: 'pointer' }}>
                                                                                <ContactCompany company={item.company} name={item.customer_name} surname={item.customer_surname} theme={props.theme} />
                                                                                {item.shop_street != '' || item.shop_town != '' ?
                                                                                    <TextLine text={item.shop_street + (item.shop_street != '' ? ', ' : '') + item.shop_town} fontSize={global.font_xsmall} color={color.black} />
                                                                                    : null}
                                                                            </div>
                                                                            <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 150, minHeight: rowHeight }}>
                                                                                {item.lat == 0 && item.lng == 0 ?
                                                                                    <Chip variant='outlined' size='small' style={{ borderColor: color.error }} label={<p style={{ ...styles.TextTiny, color: color.error }}>{lang.gps_missing}</p>} />
                                                                                    : null}
                                                                            </div>
                                                                            <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 150, minHeight: rowHeight }}>
                                                                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.route_visit_time}:</p>
                                                                                <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{item.visit_time} {lang.route_visit_time_minutes}</p>
                                                                            </div>
                                                                            <div style={{ ...styles.Block, width: 70, minHeight: rowHeight }}>
                                                                                <IconButton onClick={() => CustomerShow(item)} style={{ ...styles.ButtonIcon }}>
                                                                                    <FontAwesomeIcon style={{ width: 12, color: color.dark_gray }} icon={faUser} />
                                                                                </IconButton>

                                                                            </div>
                                                                            <div style={{ ...styles.Block, width: 70, minHeight: rowHeight, borderLeft: color.border }}>
                                                                                <DeleteButton ask text={lang.route_customer_delete} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteItem(item)} />
                                                                            </div>
                                                                        </div>
                                                                    </Paper>

                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext >
                                    :
                                    <div style={{ ...styles.Block, height: 100 }}>
                                        <EmptyList row small lang={lang} />
                                    </div>
                                }
                                <FormSpace height={global.empty_padding} />
                            </div>

                        </div>
                        : null}

                    {tabIndex == 2 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        : null}
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {tabIndex == 0 && editing == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            {editing == true && props.item != false ?
                                <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                :
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                            }
                        </div>
                    </div>
                </div >

                {showCustomer == true ?
                    <Customer module_id={module_id} item={{ id: itemSelected.customer_id }} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={() => setShowCustomer(false)} />
                    : null}

                {showRouteSettings == true ?
                    <RouteSettings module_id={module_id} item={itemSelected} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={RouteResult.bind()} />
                    : null}

                {showCustomers == true ?
                    <CustomerSelect shop_select single /*excluded={itemsExcluded}*/ showVariations={true} organization={props.organization} settings={props.settings} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomersResult.bind()} />
                    : null}

                {showMap == true ?
                    <GoogleMap gps={[{ lat: startLat, lng: startLng }]} lang={props.lang} theme={props.theme} radius={props.radius} func={() => setShowMap(false)} />
                    : null}

                {showTraceMap == true ?
                    <GoogleMap gps={points} zoom={9} lang={props.lang} theme={props.theme} radius={props.radius} func={() => setShowTraceMap(false)} />
                    : null}

                {showGPS == true ?
                    <DialogGPS organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={GetGPS.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}




export const RouteSelect = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = {
        enabled: true,
        ordering: global.order.down,
        order_column: 'name',
        user_id: props.user.id
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DATA
    const [routeItems, setRouteItems] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 700 ? 700 : height;

    const menuHeight = 60;
    const rowHeight = 66;
    const viewWidth = '96%';

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter);

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_routes', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems));

                setFilter(filter_);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_items = async (item) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_route_items', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    route_id: item.id
                })
            })

            const json = await response.json();
            Debug('my_route_items');
            Debug(json);
            if (json.ok > 0) {
                func(true, item, json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = defaultFilter;

        if (txt.length > 1) {

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = ['name'];
            db_get(0, filter);
        }
        if (txt == '') {
            db_get(0, filter);
        }

    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        db_items(item);
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faRoute} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.routes}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start'} style={{ ...styles.BlockLeft, width: viewWidth }}>

                            {items != false ? items.map((item, index) => (
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    onClick={() => SelectItem(item)}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockLeft, width: '92%' }}>
                                        <TextLine text={item.name} fontSize={global.font_tiny} fontWeight={'600'} color={color.black} />
                                        <TextLine text={item.note} fontSize={global.font_tiny} fontWeight={'400'} color={color.black} />
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div >

                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


export const RouteSettings = (props) => {
    /*

            Nastavenia zákazníka v zozname

    */
    let lang = props.lang;

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const [visitTime, setVisitTime] = useState(props.item.visit_time);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width > 600 ? 600 : width;
    const dialogHeight = height > 250 ? 250 : height;

    const column1 = 200;
    const column2 = dialogWidth - column1 - 40;

    const errorHeight = 50;
    const buttonHeight = 80;

    let { func } = props;
    const color = global.themes[props.theme];

    var running = false;
    useEffect(() => {

        if (running == false) {

            running = true;
        }

        return () => {
        };

    }, []);



    const Close = () => {
        func(false, false);
    }

    const Save = () => {
        var err = 0;
        setError('');

        err = IsEmpty(visitTime) == true ? 1 : 0;

        if (err > 0) {
            setError(lang.required_red);
        } else {
            if (parseInt(visitTime) > 0) {
                var data = {
                    id: props.item.id,
                    visit_time: visitTime
                }

                func(true, data);
            } else {
                setError(lang.route_visit_error);
            }
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2500 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius, backgroundColor: color.back }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faCog} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.route_visit_settings}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight, width: '100%', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.Block, width: '98%' }}>
                            <FormSpace />
                            <FormText redraw numeric value={visitTime} editing={true} title={lang.route_visit_time + ' (' + lang.route_visit_time_minutes + ')'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setVisitTime(txt)} />
                        </div>
                    </div>

                </div>
                <div style={{ ...styles.Block, height: errorHeight }}>
                    <FormError small margin={0} error={error} theme={props.theme} />
                </div>
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 160, marginRight: 20 }}>{lang.save}</Button>
                        <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 160 }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>

        </Backdrop >
    );
}


/*
*
* =================================================================================================================
* NAHLAD TRASY Z KALENDARA
* =================================================================================================================
*
*/
export const MyRoutePreview = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [itemID, setItemID] = useState(0);
    const [storeData, setStoreData] = useState({});
    const [showGPS, setShowGPS] = useState(false);

    // CUSTOMERS
    const [items, setItems] = useState(props.routeItems);
    const [overID, setOverID] = useState(-1);
    const [itemsExcluded, setItemsExcluded] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);

    // VALUES
    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');
    const [routeLength, setRouteLength] = useState(0);
    const [startLat, setStartLat] = useState(0);
    const [startLng, setStartLng] = useState(0);
    const [time, setTime] = useState(0);
    const [note, setNote] = useState('');
    const [gpsChanged, setGpsChanged] = useState(props.item == false ? true : false);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DIALOGS
    const [showMap, setShowMap] = useState(false);
    const [showTraceMap, setShowTraceMap] = useState(false);
    const [points, setPoints] = useState([]);
    const [showCustomers, setShowCustomers] = useState(false);
    const [showCustomer, setShowCustomer] = useState(false);
    const [showRouteSettings, setShowRouteSettings] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = 0;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = dialogWidth - column1 - 40;
    const rowHeight = 86;
    const tagColor = color.light_gray;
    const kmSize = 46;

    const tabs = [
        { id: 0, enabled: true, label: lang.setting },
        { id: 1, enabled: true, label: lang.customers },
        { id: 2, enabled: true, label: lang.history },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_route', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_route_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------                    
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (data.id == 0) {
                    setItemID(json.item_id);
                }

                setEditing(false);
                setRedrawRequired(true);
                setShowOK(true);

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_items = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_route_items', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    route_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                //tmp = CalculateDistances(tmp);
                setItems(tmp);
                setRouteLength(json.distance_total);
                setTime(json.time_total);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_item_update = async (data) => {
        Debug(data);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_route_items_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    route_id: itemID,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                db_items();
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_reorder = async (items) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'my_route_items_reorder', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    route_id: itemID,
                    items: items
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_items();
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setName(item.name);
        setRouteLength(item.route_length);
        setStartLat(item.start_lat);
        setStartLng(item.start_lng);
        setTime(item.time);
        setNote(item.note);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setGpsChanged(false);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.enabled = enabled;
        storeData.name = name;
        storeData.route_length = routeLength;
        storeData.start_lat = startLat;
        storeData.start_lng = startLng;
        storeData.time = time;
        storeData.note = note;
    }

    const Save = () => {
        setError('');
        var error = false;
        error += IsEmpty(name) == true ? 1 : 0;

        if (error == 0) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: props.item == false ? 0 : itemID,
                action: props.item == false ? global.logs.created : storeData.enabled != enabled ? enabled == true ? global.logs.enabled : global.logs.disabled : global.logs.modified
            };

            let data = {
                id: itemID == false ? 0 : itemID,
                organization_id: props.organization.id,
                enabled: enabled,
                name: name.trim(),
                route_length: routeLength,
                start_lat: startLat,
                start_lng: startLng,
                time: time,
                note: note,
                gps_changed: gpsChanged,
                logs: log
            };

            StoreEditing();
            Debug('Save data');
            Debug(data);
            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 1) {
            db_items();
        }
        if (id == 2) {
            if (logs == false) {
                db_get_logs();
            }
        }
    }

    const GetGPS = (typ, address, lat, lng) => {
        setShowGPS(false);

        if (typ == true) {
            setGpsChanged(true);
            setStartLat(lat);
            setStartLng(lng);
        }
    }

    const AddNew = () => {
        if (items != false) {
            var ids = [];
            items.forEach(item => {
                ids.push(item.customer_id);
            });
            setItemsExcluded(ids);
        } else {
            setItemsExcluded(false);
        }

        setShowCustomers(true);
    }

    const CustomersResult = (typ, customer_id, customer, address) => {
        setShowCustomers(false);

        if (typ == true && customer != false) {
            let data = {
                id: 0,
                enabled: true,
                organization_id: props.organization.id,
                route_id: itemID,
                customer_id: customer.id,
                shop_id: address != false ? address.id : 0,
                name: '',
                lat: address != false ? address.lat : 0,
                lng: address != false ? address.lng : 0,
                distance: 0,
                distance_total: 0,
                time: 0,
                visit_time: 30,
                priority: 0,
                note: ''
            };

            Debug(data);
            db_item_update(data);
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (result.destination) {
            var tmp = reorder(items, result.source.index, result.destination.index);
            var n = 1;
            var items_ = [];
            tmp.forEach(item => {
                item.priority = n;

                let data = {
                    id: item.id,
                    priority: n
                }
                items_.push(data);
                n++
            });

            //tmp = CalculateDistances(tmp);

            setItems(tmp);
            db_reorder(items_);
        }

    }

    const DeleteItem = (item) => {
        let data = {
            id: parseInt(item.id),
            enabled: false,
            gps_changed: true
        };

        db_item_update(data);
    }

    const ShowTrace = () => {
        var array = [];

        if (parseFloat(startLat) > 0 && parseFloat(startLng) > 0) {
            let data = {
                lat: startLat,
                lng: startLng,
                priority: 0
            };
            array.push(data);
        }

        var n = 1;
        items.forEach(item => {
            if (item.lat > 0 && item.lng > 0) {

                let distance = GetDistance(startLat, startLng, item.lat, item.lng);
                Debug(distance);

                let data = {
                    lat: item.lat,
                    lng: item.lng,
                    priority: n,
                    distance: distance * 1.15
                };
                array.push(data);
                n++;
            }
        });

        if (array.length > 0) {
            setPoints(array);
            setShowTraceMap(true);
        }
    }

    const SortCustomers = () => {
        if (items != false) {
            var tmp = items;
            tmp.sort((a, b) => {
                if (parseInt(a.distance_total) > parseInt(b.distance_total)) {
                    return 1;
                } else {
                    return -1;
                }
            })

            var n = 1;
            var items_ = [];
            tmp.forEach(item => {
                item.priority = n;

                let data = {
                    id: item.id,
                    priority: n
                }
                items_.push(data);
                n++
            });

            setItems(tmp);
            Debug(tmp);
            db_reorder(items_);
        }
    }

    const ChangeEdit = (value) => {
        setGpsChanged(false);
        setEditing(value);
    }

    const CustomerShow = (item) => {
        setItemSelected(item);
        setShowCustomer(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowRouteSettings(true);
    }

    const RouteResult = (typ, data) => {
        setShowRouteSettings(false);

        if (typ == true) {
            db_item_update(data);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.route_new : lang.route}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight }} >
                        <div style={{ ...styles.Block, width: dialogWidth - 20, height: 60 }}>
                            {items != false ?
                                <Button onClick={() => ShowTrace()} style={{ ...styles.ButtonOutlined }}>
                                    {lang.gps_show_map}
                                </Button>
                                : null}
                        </div>
                        <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - 60, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                            {items != false ?
                                <div style={{ ...styles.BlockLeft }}>
                                    <FontAwesomeIcon style={{ height: 28, color: '#C0C0C0', marginLeft: 20 }} icon={faHome} />
                                </div>
                                : null}

                            {items != false ? items.map((item, index) => (
                                <div key={index} style={{ ...styles.Block }}>
                                    {index < items.length ?
                                        <div style={{ ...styles.BlockLeft, marginLeft: 18 }}>
                                            <div style={{ ...styles.BlockRow }}>
                                                <div style={{ ...styles.BlockLeft, width: 40, height: kmSize }}>
                                                    <FontAwesomeIcon style={{ height: 28, color: tagColor, marginLeft: 16, marginTop: 1 }} icon={faDownLong} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '90%', height: kmSize }}>
                                                    {item.distance != undefined ? item.distance > 0 ?
                                                        <p style={{ ...styles.TextTiny, color: color.middle_gray }}>~ {(parseFloat(item.distance) / 1000).toFixed(1)} km ({parseInt(parseInt(item.time) / 60)} min.)</p>
                                                        :
                                                        <p style={{ ...styles.TextTiny, color: color.middle_gray }}>?</p>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    <Paper
                                        elevation={overID == item.id ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            width: dialogWidth - 20,
                                            minHeight: rowHeight,
                                            backgroundColor: color.white,
                                            marginTop: 3, marginBottom: 3,
                                            borderRadius: global.radius
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.BlockLeft, position: 'relative', width: 50, minHeight: rowHeight }}>
                                                <FontAwesomeIcon style={{ height: 26, color: tagColor, marginLeft: 15 }} icon={faLocationDot} />
                                                <FontAwesomeIcon style={{ height: 18, color: tagColor, marginLeft: 22, position: 'absolute', top: rowHeight / 2 + 16 }} icon={faEllipsisVertical} />
                                            </div>
                                            <div style={{ ...styles.Block, width: 40, minHeight: rowHeight }}>
                                                <p style={{ ...styles.TextXSmall }}>{index + 1}.</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '50%', minHeight: rowHeight }}>
                                                <ContactCompany company={item.company} name={item.customer_name} surname={item.customer_surname} theme={props.theme} />
                                                {item.shop_street != '' || item.shop_town != '' ?
                                                    <TextLine text={item.shop_street + (item.shop_street != '' ? ', ' : '') + item.shop_town} fontSize={global.font_xsmall} color={color.black} />
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 150, minHeight: rowHeight }}>
                                                {item.lat == 0 && item.lng == 0 ?
                                                    <Chip variant='outlined' size='small' style={{ borderColor: color.error }} label={<p style={{ ...styles.TextTiny, color: color.error }}>{lang.gps_missing}</p>} />
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 150, minHeight: rowHeight }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.route_visit_time}:</p>
                                                <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{item.visit_time} {lang.route_visit_time_minutes}</p>
                                            </div>
                                        </div>
                                    </Paper>
                                </div>
                            ))
                                :
                                <div style={{ ...styles.Block, height: 100 }}>
                                    <EmptyList row small lang={lang} />
                                </div>
                            }
                            <FormSpace height={global.empty_padding} />
                        </div>

                    </div>

                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                    </div>
                </div >

                {
                    showCustomer == true ?
                        <Customer module_id={module_id} item={{ id: itemSelected.customer_id }} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={() => setShowCustomer(false)} />
                        : null
                }

                {
                    showRouteSettings == true ?
                        <RouteSettings module_id={module_id} item={itemSelected} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={RouteResult.bind()} />
                        : null
                }

                {
                    showCustomers == true ?
                        <CustomerSelect shop_select single /*excluded={itemsExcluded}*/ showVariations={true} organization={props.organization} settings={props.settings} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomersResult.bind()} />
                        : null
                }

                {
                    showMap == true ?
                        <GoogleMap gps={[{ lat: startLat, lng: startLng }]} lang={props.lang} theme={props.theme} radius={props.radius} func={() => setShowMap(false)} />
                        : null
                }

                {
                    showTraceMap == true ?
                        <GoogleMap gps={points} zoom={9} lang={props.lang} theme={props.theme} radius={props.radius} func={() => setShowTraceMap(false)} />
                        : null
                }

                {
                    showGPS == true ?
                        <DialogGPS organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={GetGPS.bind(this)} />
                        : null
                }

                {
                    showOK == true ?
                        <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                        : null
                }

                {
                    showError == true ?
                        <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                        : null
                }

                {
                    isBusy == true ?
                        <Loading offset={props.offset}></Loading>
                        : null
                }

            </Paper >
        </Backdrop >
    );
}