/*
*
* =================================================================================================================
* KONTAKTY - ta istá DB ako zákazníci

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { DialogTabs, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, ShowError, ShowOK, TextInput, Logs, Avatar, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormSelect, FormNote, FormNodes, FormAutoComplete, ShowInfo, SubMenuButton, ButtonChangeStyle, ContactInfo, FormInfo, ContactCompany } from './items';
import { styles } from './styles';
import { Backdrop, Box, Button, Checkbox, Chip, IconButton, ListItemIcon, ListItemText, MenuItem, Pagination, Paper, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faAt, faBars, faCheck, faCopy, faFile, faFilter, faFolderTree, faInfoCircle, faLayerGroup, faPaperPlane, faPlus, faPlusCircle, faQuestion, faRotateRight, faTableCells, faUser, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, ConvertToPrice, ConvertToPrice2, Debug, GetLeftPosition, GetPages, IsEmpty, LoadArray, LoadBool, LoadValue, SaveArray, SaveBool, SaveValue } from './functions';
import { Photos } from './photos';
import { CustomersPerson } from './customers_person.js';
import { CustomersCategorySelect } from './customers_categories.js';
import { UsersSelect } from './users_select.js';
import { CustomersAddress } from './customers_address.js';
import { CustomersGroup, CustomersGroupSelect } from './customers_groups.js';
import {
    DataGrid, skSK,
    GridColumnMenu,
} from '@mui/x-data-grid';
import { Help } from './Help.js';
import { CustomerEmails } from './customer.js';
import { EmailEditor } from './Editor.js';
import { Notes } from './notes.js';


export const Contacts = (props) => {

    let module_id = global.model.contacts;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'contacts';
    let limited = props.permissions.customers_limited == true && props.user.super_admin == false ? true : false;

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        country_id: props.locale.id,
        enabled: true,
        agent_id: limited == true ? props.user.id : undefined,
    }

    const [precisions, setPrecisions] = useState(props.system.precisions == 0 ? 2 : 3);

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrderingName = [
        { id: 1, enabled: true, label: lang.company_name, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.company_name, icon: faArrowDown },
        { id: 3, enabled: true, label: lang.surname, icon: faArrowUp },
        { id: 4, enabled: true, label: lang.surname, icon: faArrowDown }
    ];
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // SEARCH MENU
    const [filterMenu, setFilterMenu] = useState([
        { id: 1, checked: true, enabled: true, label: lang.products_filter_enabled },
        { id: 2, checked: false, enabled: true, label: lang.products_filter_unabled },
    ]);

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, checked: false, enabled: true, label: lang.export_selected },
        { id: 2, checked: false, enabled: true, label: lang.export_all },
        { id: 30, divider: true },
        { id: 5, checked: LoadBool('contacts-mode', true), enabled: true, label: lang.preview_mode },
        { id: 32, divider: true },
        { id: 4, checked: false, enabled: true, icon: faRotateRight, label: lang.refresh }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.customer_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    var columns = [
        {
            field: 'enabled',
            headerName: lang.enabled_,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'boolean',
            align: 'center',
            width: 60,
            renderCell: (params) => (params.value == false ? <FontAwesomeIcon style={{ width: 14, height: 14, color: color.dark_red }} icon={faXmarkCircle} /> : <FontAwesomeIcon style={{ width: 14, height: 14, color: color.black }} icon={faCheck} />),
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
        {
            field: 'company',
            headerName: lang.company_name,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 300,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: false,
            hide: false,
        },
        {
            field: 'surname',
            headerName: lang.surname,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 200,
            editable: false,
            sortable: false,
            sorting: true,
            hide: false,
        },
        {
            field: 'name',
            headerName: lang.name,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            //renderCell: (params) => (params.row.parent_id == 0 ? params.row.name : params.row.parent_name + ' - ' + params.row.name),
            type: 'string',
            align: 'left',
            width: 200,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'phone',
            headerName: lang.phone,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 150,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'mobil',
            headerName: lang.mobil,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 150,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'email',
            headerName: lang.email,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 300,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            align: 'left',
        },

    ];

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(LoadArray('customers-table', {}));
    const [previewMode, setPreviewMode] = useState(LoadBool('contacts-mode', true));

    // STYL ZOBRAZOVANIE - RIADKOVE / TABULKA
    const [displayStyle, setDisplayStyle] = useState(LoadValue('customers-style', 0));

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [params, setParams] = useState(props.params);

    // FILTERS
    const [showEnabled, setShowEnabled] = useState(true);
    const [showUnabled, setShowUnabled] = useState(false);
    const [showNotPortal, setShowNotPortal] = useState(false);
    const [category, setCategory] = useState(false);
    const [agent, setAgent] = useState(false);
    const [groupID, setGroupID] = useState(false);
    const [group, setGroup] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'company', label: lang.company_name, default: true },
        { id: 2, enabled: true, checked: true, column: 'surname', label: lang.surname },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showExportError, setShowExportError] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [showGroups, setShowGroups] = useState(false);
    const [showAgents, setShowAgents] = useState(false);
    const [showGroupError, setShowGroupError] = useState(false);
    const [showMyGroups, setShowMyGroups] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 94;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const chipHeight = 50;
    const maxWidth = 1100;
    const previewHeight = 100;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            var filter = defaultFilter;

            if (props.params != false) {
                var columns = [];
                var search_arr = [];
                var search_label = '';

                // VYHLADAVANIE
                if (props.params.add_new != true) {

                    if (props.params.my_contacts == true) {
                        filter.agent_id = props.user.id;
                        setAgent(props.user);
                    }

                    if (props.params.user_id != undefined) {
                        filter.agent_id = props.params.user_id;
                        setAgent(props.params.user);
                    }

                    if (props.params.name_surname != undefined) {
                        search_label = props.params.name_surname;
                        columns.push('name');

                        var search_text = props.params.name_surname.split(' ');
                        if (search_text != undefined) {
                            search_text.forEach(element => {
                                search_arr.push(element);
                            });
                        }
                    }

                    if (props.params.company != undefined) {
                        search_label = search_label + ' ' + props.params.company;
                        columns.push('company');

                        var search_text = props.params.company.split(' ');
                        if (search_text != undefined) {
                            search_text.forEach(element => {
                                search_arr.push(element);
                            });
                        }

                        setSearch(props.params.company);
                    }
                    if (search_arr.length > 0) {
                        filter.search = search_arr;
                        filter.search_columns = columns;
                    }

                    setSearch(search_label);
                } else {
                    if (props.params.add_new == true) {
                        AddNew(true);
                    }
                }

            }

            // UDAJE Z DB
            db_get(0, filter, maxItems);

            running = true;
        }

    }, [props.locale, props.params]);

    const db_get = async (page, filter, maxItems) => {
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'contacts', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }



    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.customers,
                    filename: lang.customers, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    function GetFilter() {
        var filter = defaultFilter;
        delete (filter.enabled);

        if (search.trim() != '') {
            filter.search = search;

            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default == true);
                columns.push(tmp.column);
            }

            var search_text = search.split(' ');
            if (search_text != undefined) {
                filter.search = search_text;
                filter.search_columns = columns;
            }
        }
        if (agent != false) {
            filter.agent_id = agent.id;
        }
        if (showEnabled == true) {
            filter.enabled = true;
        }
        if (showUnabled == true) {
            filter.disabled = true;
            filter.filters = true;
        }
        return filter;
    }

    const FilterMenuResult = (id, index, item) => {
        var filter = GetFilter();
        setItemSelected(false);

        if (index == 1) {
            // LEN ENABLED
            let value = !showEnabled;
            item.checked = value;
            filterMenu[1].checked = false;
            delete (filter.disabled);
            setShowEnabled(value);
            setShowUnabled(false);
            if (value == true) {
                filter.enabled = true;
                filter.filters = true;
            } else {
                delete (filter.enabled);
            }
        }
        if (index == 2) {
            // LEN UNABLED
            let value = !showUnabled;
            item.checked = value;
            filterMenu[0].checked = false;
            delete (filter.enabled);
            setShowUnabled(value);
            setShowEnabled(false);
            if (value == true) {
                filter.disabled = true;
                filter.filters = true;
            } else {
                delete (filter.disabled);
            }
        }

        db_get(0, filter, maxItems);
    }


    const FiltersDelete = () => {
        var filter = GetFilter();
        delete (filter.filters);
        delete (filter.disabled);
        filter.enabled = true;

        setShowUnabled(false);
        setShowEnabled(true);

        filterMenu[0].checked = true;
        filterMenu[1].checked = false;

        setFilter(filter);

        db_get(0, filter, maxItems);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = (use_params) => {
        if (use_params == true) {
            setParams(props.params);
        } else {
            setParams(false);
        }
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const ChangeEnabled = (item, value) => {
        // ENABLED POLOZKY ZMENENY

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: global.model.customers,
            item_id: item.id,
            action: value == true ? global.logs.enabled : global.logs.disabled
        };

        let data = {
            id: item.id,
            enabled: value,
            logs: log
        }
        db_update(data);
    }

    const MenuResult = (id, index, item) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1 || index == 2) {
            if (index == 1) {
                let tmp = items.find(x => x.selected === true);
                if (tmp == undefined) {
                    enable = false;
                }
            }

            if (enable == true) {
                setMenuIndex(index);
                setShowExport(true);
            } else {
                setShowExportError(true);
            }
        }

        if (index == 4) {
            // REFRESH PAGE
            db_get(page, filter, maxItems);
        }

        if (index == 5) {
            // PREVIEW MODE
            var tmp = item;
            tmp.checked = previewMode == true ? false : true;
            setPreviewMode(!previewMode);
            SaveBool('contacts-mode', !previewMode);
        }

    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // NAZOV FIRMY (index: 1,2) PRIEZVISKO (index: 3,4)
                column = index == 1 || index == 2 ? 'company' : 'surname';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;
        }

        var filter = {
            ordering: order,
            order_column: column
        };
        if (search != '') {
            filter.search = search;
        }
        db_get(0, filter, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const AgentsResult = (typ, selected) => {
        setShowAgents(false);

        if (typ == true) {
            setAgent(selected);
            var filter = GetFilter();
            filter.agent_id = selected.id;
            db_get(0, filter, maxItems);
        }
    }

    const UserOnly = () => {
        setAgent(props.user);
        var filter = GetFilter();
        filter.agent_id = props.user.id;
        db_get(0, filter, maxItems);
    }

    const AgentDelete = () => {
        var filter = GetFilter();
        delete filter.agent_id;

        setAgent(false);
        setFilter(filter);

        db_get(0, filter, maxItems);
    }


    const ChangeStyle = (value) => {
        setItems(false);
        setItemsFiltered(false);
        setItemSelected(false);
        setDisplayStyle(value);

        if (value == 0) {
            filter.parent_id = 0;
        } else {
            delete (filter.parent_id);
        }

        SaveValue('customers-style', value);
        db_get(0, filter, maxItems);
    }

    const SortUp = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.up;
        filter.order_column = data.colDef.field;
        db_get(0, filter, maxItems);
    }

    const SortDown = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.down;
        filter.order_column = data.colDef.field;
        db_get(0, filter, maxItems);
    }

    function MyMenuItem(props) {
        const { menuHandler, menuLabel, menuIcon } = props;
        return (
            <MenuItem onClick={menuHandler}>
                <ListItemIcon>
                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={menuIcon} />
                </ListItemIcon>
                <ListItemText>{menuLabel}</ListItemText>
            </MenuItem>
        );
    }

    function CustomColumnMenu(props) {
        // VLASTNÁ COLUMN MENU
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuSortItem: null,
                    columnMenuFilterItem: null,
                    columnMenuUserItem1: props.colDef.sorting == true ? MyMenuItem : null,
                    columnMenuUserItem2: props.colDef.sorting == true ? MyMenuItem : null,
                }}
                slotProps={{
                    columnMenuUserItem1: {
                        displayOrder: 1,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowUp,
                        menuHandler: () => SortUp(props),
                    },
                    columnMenuUserItem2: {
                        displayOrder: 0,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowDown,
                        menuHandler: () => SortDown(props),
                    },
                }}
            />
        );
    }

    const ChangeColumns = (data) => {
        // ZOBRAZIT / SKRYT STLPCE -> uloženie do cookies
        setColumnVisibilityModel(data);
        SaveArray('customers-table', data);
    }


    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 270, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.widow_bar_height }}>
                    <ButtonChangeStyle value={displayStyle} theme={props.theme} func={(id) => ChangeStyle(id)} />
                </div>
                <div style={{ ...styles.Block, width: 50, height: global.widow_bar_height, marginRight: 20 }}>
                    {global.appstore == false ?
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                        : null}
                </div>
                <div style={{ ...styles.Block, width: 50, height: global.widow_bar_height, marginRight: 20 }}>
                    <Help link={lang.help_main} lang={props.lang} theme={props.theme} />
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.Block, maxWidth: displayStyle == 0 ? maxWidth : '98%', width: '100%' }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '25%', height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3, marginRight: 5 }}>
                                            <MenuButton id={0} icon={faFilter} backgroundColor={'#00000000'} color={color.dark_gray} label={''} menuItems={filterMenu} theme={props.theme} func={FilterMenuResult.bind()} />
                                        </div>
                                        <TextInput search redraw theme={props.theme} width={'100%'} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '45%', height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw, height: menuHeight }}>
                                        {limited == false ?
                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                <Button onClick={() => setShowAgents(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faUser} />
                                                    {lang.customer_agent}
                                                </Button>
                                            </div>
                                            : null}
                                        {limited == false ?
                                            <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                                <IconButton onClick={() => UserOnly(true)} style={{ ...styles.ButtonUser }}>
                                                    <FontAwesomeIcon style={{ height: 14 }} icon={faUser} />
                                                </IconButton>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '30%', height: menuHeight }}>
                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                        <Button onClick={() => AddNew(false)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.contact_new}
                                        </Button>
                                        : null}
                                </div>
                            </div>

                            <div style={{ ...styles.BlockRow, height: chipHeight }}>
                                {filter != false ? filter.filters == true ?
                                    <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.cancel_filters}</p>} onDelete={() => FiltersDelete()}></Chip>
                                    : null : null}
                                {limited == false ? filter != false ? filter.agent_id != undefined ?
                                    <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{agent.surname} {agent.name}</p>} onDelete={() => AgentDelete()}></Chip>
                                    : null : null : null}
                            </div>

                            {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}
                            {displayStyle == 0 ?
                                <div style={{ ...styles.Block, width: '99%', border: color.border }}>
                                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                                        <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center' }}>
                                            {/* SELECT */}
                                            <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: '85%' }}>
                                            {/* DATA */}
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '50%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.label}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <MenuButton id={1} sortMenu={true} menuItems={itemsOrderingName} theme={props.theme} func={MenuSortResult.bind()} />
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '45%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.contact_info}</p>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: 100, borderLeft: color.border }}>
                                            {/* ENABLED */}
                                            <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.enabled}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {displayStyle == 0 ?
                                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - tableLabelHeight - chipHeight - 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <div id={'id_start'} style={{ ...styles.BlockCenter }}></div>

                                    {/* ****************************************************************************************************************
                                        ZOZNAM KONTAKTOV
                                        **************************************************************************************************************** */}

                                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block,
                                                width: '99%',
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                    {/* SELECT */}
                                                    <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                                </div>

                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '85%' }}>
                                                    {/* DATA */}
                                                    <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <Avatar picture image={item.avatar} size={rowHeight - 20} theme={props.theme} />
                                                    </div>

                                                    <div style={{ ...styles.BlockLeft, width: '50%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <ContactCompany company={item.company} name={item.name} surname={item.surname} theme={props.theme} />
                                                        {item.agent != '' ?
                                                            <Chip variant='outlined' size='small' style={{ marginTop: 5 }} label={item.agent} />
                                                            : null}
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '45%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <ContactInfo mobil={item.mobil} phone={item.phone} email={item.email} theme={props.theme} />
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.Block, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {/* ENABLED */}
                                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                        <SwitchEnable enabled={item.enabled == false ? false : true} theme={props.theme} func={(value) => ChangeEnabled(item, value)} />
                                                        : null}
                                                    {item.enabled == false ?
                                                        <div style={{ ...styles.Block, height: 20 }}>
                                                            <p style={{ ...styles.TextXXTiny, color: color.dark_gray }}>{lang.disabled}</p>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }

                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                        **************************************************************************************************************** */}

                                    <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {displayStyle == 1 ?
                                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - chipHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <Box sx={{
                                        height: previewMode == true ? props.height - global.widow_bar_height - menuHeight - chipHeight - 84 - previewHeight : props.height - global.widow_bar_height - menuHeight - chipHeight - 84,
                                        width: 1,
                                        /*
                                        '& .super-app-theme--header': {
                                            backgroundColor: color.light_gray,
                                        },
                                        */
                                        '& .super-app-theme--normal': {
                                            backgroundColor: color.white,
                                            color: '#000000',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.table_row),
                                            },
                                        },
                                        '& .super-app-theme--red': {
                                            backgroundColor: color.lighteen_blue,
                                            color: color.dark_red,
                                            fontWeight: 'normal',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.gray),
                                            },
                                        },
                                        '& .super-app-theme--blue': {
                                            backgroundColor: color.white,
                                            color: color.blue,
                                            fontWeight: 'normal',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.gray),
                                            },
                                        },
                                    }}>
                                        <DataGrid

                                            sx={{
                                                "& .MuiDataGrid-columnHeaderTitle": {
                                                    whiteSpace: "normal",
                                                    lineHeight: "normal"
                                                },
                                                "& .MuiDataGrid-columnHeader": {
                                                    height: "unset !important"
                                                },
                                                "& .MuiDataGrid-columnHeaders": {
                                                    maxHeight: "168px !important"
                                                },
                                                fontSize: global.font_table, border: global.theme_table_border_width, borderColor: color.dark_gray
                                            }}

                                            rows={itemsFiltered == false ? [] : itemsFiltered}
                                            columns={columns.filter(x => x.hide == false)}
                                            slots={{ columnMenu: CustomColumnMenu }}
                                            rowHeight={previewMode == true ? 30 : undefined}

                                            checkboxSelection
                                            disableRowSelectionOnClick
                                            onRowSelectionModelChange={(newRowSelectionModel) => { setItemsSelected(newRowSelectionModel) }}
                                            onRowClick={(params) => { setItemSelected(params.row) }}
                                            onRowDoubleClick={(params) => { SelectItem(params.row) }}
                                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                            getRowClassName={(params) => (params.row.enabled == 1 ? `super-app-theme--normal` : `super-app-theme--red`)}
                                            hideFooterRowCount={true}
                                            hideFooterSelectedRowCount={true}
                                            hideFooterPagination
                                            hideFooter
                                            columnVisibilityModel={columnVisibilityModel}
                                            onColumnVisibilityModelChange={(newModel) =>
                                                ChangeColumns(newModel)
                                            }
                                        />
                                    </Box>

                                    {previewMode == true && itemSelected != false ?
                                        <div style={{ ...styles.Block, height: previewHeight, marginTop: 5 }}>
                                            <div style={{ ...styles.BlockRow, width: '100%' }}>
                                                <div style={{ ...styles.BlockLeft, width: previewHeight, height: previewHeight, justifyContent: 'center' }}>
                                                    <Avatar picture image={itemSelected.image} size={previewHeight - 10} theme={props.theme} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '40%', height: previewHeight, justifyContent: 'center' }}>
                                                    {itemSelected.company != '' ?
                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{itemSelected.company}</p>
                                                        : null}
                                                    <p style={{ ...styles.TextXSmall, fontWeight: itemSelected.company != '' ? '400' : '600' }}>{itemSelected.surname} {itemSelected.name}</p>
                                                    <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{itemSelected.code}</p>
                                                    {itemSelected.agent != '' ?
                                                        <Chip
                                                            style={{ marginTop: 2, marginBottom: 2 }}
                                                            sx={{
                                                                borderColor: color.dark_gray,
                                                                paddingTop: '2px',
                                                                paddingBottom: '2px',
                                                                height: 'auto',
                                                                '& .MuiChip-label': {
                                                                    display: 'block',
                                                                    whiteSpace: 'normal',
                                                                },
                                                            }}
                                                            label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_gray }}>{itemSelected.agent}</p>} variant="outlined"
                                                        />
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '40%', height: previewHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, width: '90%', marginLeft: 5 }}>
                                                        <ContactInfo mobil={itemSelected.mobil} phone={itemSelected.phone} email={itemSelected.email} theme={props.theme} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                        **************************************************************************************************************** */}
                                    <div style={{ ...styles.BlockRow, paddingTop: 10, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                : null}

                        </div>
                    </div>
                </div>
            </div>

            {
                showExport == true ?
                    <DialogExport remote={props.remote} remoteMobile={props.remoteMobile} rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                    : null
            }

            {
                showDialog == true ?
                    <Contact remote={props.remote} remoteMobile={props.remoteMobile} params={params} module_id={module_id} item={itemSelected} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                    : null
            }

            {
                showAgents == true ?
                    <UsersSelect remote={props.remote} remoteMobile={props.remoteMobile} limited={limited == true ? true : undefined} groups organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.user_select} token={props.token} func={AgentsResult.bind()} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null
            }

            {
                showGroupError == true ?
                    <ShowInfo text={lang.customers_list_group_error} sub_text={''} lang={lang} theme={props.theme} func={() => setShowGroupError(false)} />
                    : null
            }

            {
                showExportError == true ?
                    <ShowInfo text={lang.export_none_error} sub_text={lang.export_none_error_} lang={lang} theme={props.theme} func={() => setShowExportError(false)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null
            }

        </Paper >
    );
}




/*
*
* =================================================================================================================
* KONTAKT - DETAILS
* =================================================================================================================
*
*/
export const Contact = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let country_id = props.locale.id;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [redrawEmails, setRedrawEmails] = useState(false);
    const [overID, setOverID] = useState(-1);

    // TABS
    const [tabIndex, setTabIndex] = useState(props.item == false ? 0 : 0);

    // DATA
    const [storeData, setStoreData] = useState({});
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);

    const [countryID, setCountryID] = useState(props.params.country_id == undefined ? props.locale.id : props.params.country_id);
    const [countries, setCountries] = useState(props.organization.currencies);
    const [avatar, setAvatar] = useState('');
    const [agentID, setAgentID] = useState(0);
    const [agents, setAgents] = useState(false);
    const [categoryID, setCategoryID] = useState(0);
    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [enabled, setEnabled] = useState(true);
    const [activated, setActivated] = useState(false);
    const [canBuy, setCanBuy] = useState(true);
    const [name, setName] = useState(props.params.name == undefined ? '' : props.params.name);
    const [surname, setSurname] = useState(props.params.surname == undefined ? '' : props.params.surname);
    const [company, setCompany] = useState(props.params.company == undefined ? '' : props.params.company);
    const [phone, setPhone] = useState('');
    const [mobil, setMobil] = useState(props.params.mobil == undefined ? '' : props.params.mobil);
    const [email, setEmail] = useState(props.params.email == undefined ? '' : props.params.email);
    const [ico, setICO] = useState('');
    const [dic, setDIC] = useState('');
    const [icDPH, setICDPH] = useState('');
    const [dph, setDPH] = useState(false);
    const [bank, setBank] = useState('');
    const [iban, setIban] = useState('');
    const [swift, setSwift] = useState('');
    const [priceListID, setPriceListID] = useState(-1);
    const [credit, setCredit] = useState('');
    const [note, setNote] = useState(props.params.note == undefined ? '' : props.params.note);

    const [priceLists, setPriceLists] = useState(false);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // EMAILS
    const [showEmailEditor, setShowEmailEditor] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showChange, setChange] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 900 ? 900 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS    
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 180;
    const column2 = 600 - column1;
    const rowHeight = 90;
    const tableLabelHeight = 40;
    const menuWidth = global.sub_menu_width;
    const barHeight = global.dialog_bar_height;

    const tabs = [
        { id: 0, enabled: true, label: lang.customer_basic_info },
        { id: 1, enabled: true, label: lang.customer_emails },
        { id: 2, enabled: true, label: lang.notes },
        { id: 99, enabled: true, label: lang.history },
    ];

    const tabHeight = 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            db_get_data(countryID);

            if (itemID > 0) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'contact', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);
                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_data = async (country_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    country_id: country_id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setAgents(json.agents);
                setPriceLists(json.price_list);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: global.model.customers,
                    item_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                setEditing(false);
                if (itemID == 0) {
                    setItemID(json.item_id);
                    GoToStart();
                }
                setRedrawRequired(true)
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_check = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    email: email,
                    id: data.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.count == 0) {
                    db_update(data);
                } else {
                    setError(lang.customer_email_exists);
                    setBusy(false);
                }
            } else {
                setShowError(true);
                setBusy(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(redrawRequired);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setCountryID(item.country_id);
        setAvatar(item.avatar);
        setAgentID(item.agent_id);
        setCategoryID(item.category_id);
        setCode1(item.code_1);
        setCode2(item.code_2);
        setCode3(item.code_3);
        setEnabled(item.enabled == false ? false : true);
        setActivated(item.activated == false ? false : true);
        setName(item.name);
        setSurname(item.surname);
        setCompany(item.company);
        setMobil(item.mobil);
        setPhone(item.phone);
        setEmail(item.email);
        setICO(item.ico);
        setDIC(item.dic);
        setICDPH(item.ic_dph);
        setDPH(item.dph == false ? false : true);
        setBank(item.bank);
        setIban(item.iban);
        setSwift(item.swift);
        setPriceListID(item.price_list_id);
        setNote(item.note);

    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setError('');
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.country_id = countryID;
        storeData.avatar = avatar;
        storeData.agent_id = agentID;
        storeData.category_id = categoryID;
        storeData.code_1 = code1;
        storeData.code_2 = code2;
        storeData.code_3 = code3;
        storeData.enabled = enabled;
        storeData.name = name;
        storeData.surname = surname;
        storeData.company = company;
        storeData.phone = phone;
        storeData.mobil = mobil;
        storeData.email = email;
        storeData.ico = ico;
        storeData.div = dic;
        storeData.id_dph = icDPH;
        storeData.dph = dph;
        storeData.bank = bank;
        storeData.iban = iban;
        storeData.swift = swift;
        storeData.price_list_id = priceListID;
        storeData.note = note;

    }

    const Save = () => {
        setError('');
        var error = false;

        if (IsEmpty(company) == true && IsEmpty(surname) == true) {
            error += 1;
        }

        if (error == 0) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: global.model.customers,
                item_id: itemID,
                action: itemID == 0 ? global.logs.created : storeData.enabled != enabled ? enabled == true ? global.logs.enabled : global.logs.disabled : global.logs.modified
            };

            let data = {
                id: itemID,
                organization_id: props.organization.id,
                country_id: countryID,
                user_id: props.user.id,
                enabled: enabled,
                typ: 1,
                language_id: props.organization.language_id,
                name: name.trim(),
                surname: surname.trim(),
                company: company.trim(),
                mobil: mobil,
                phone: phone,
                email: email,
                ico: ico,
                dic: dic,
                ic_dph: icDPH,
                dph: dph,
                //bank: bank,
                //iban: iban,
                //swift: swift,
                //price_list_id: priceListID,
                avatar: avatar,
                note: note,
                logs: log
            };

            StoreEditing();

            db_check(data);

        } else {
            setError(lang.required_red);
        }


    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 2) {
            if (logs == false) {
            }
        }

        if (id == 3) {
            if (logs == false) {
            }
        }

        if (id == 99) {
            if (logs == false) {
                db_get_logs();
            }
        }
    }


    const AvatarPress = (value) => {
        if (value == true) {
            setSelectPhoto(true);
        } else {
            setAvatar('');
        }
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);
        }
    }

    const ChangeCountryID = (id) => {
        setCountryID(id);
        setPriceListID(-1);
        db_get_data(id);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_customer_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }


    const SendEmail = (typ) => {
        setShowEmailEditor(false);

        if (typ == true) {
            setRedrawEmails(!redrawEmails);
        }
    }

    const EmailsResult = () => {
        setShowEmailEditor(true);
    }

    const NotesResult = () => {

    }

    const ChangeResult = (typ) => {
        setChange(false);
        if (typ == true) {
            func(true);
        }

    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 100 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: barHeight }}>
                        <Icon theme={props.theme} icon={faUser} />
                    </div>
                    <div style={{ ...styles.BlockCenter, width: dialogWidth - 120 - 60, height: barHeight }}>
                        {itemID == 0 ?
                            <div style={{ ...styles.BlockLeft, height: barHeight, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextDialogLabel }}>{lang.contact_new}</p>
                            </div>
                            :
                            storeData != false ?
                                <div style={{ ...styles.BlockLeft, height: barHeight, justifyContent: 'center' }}>
                                    {storeData.company != '' ?
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{storeData.company}</p>
                                        :
                                        <p style={{ ...styles.TextXSmall }}>{storeData.name + ' ' + storeData.surname}</p>
                                    }
                                </div>
                                : null
                        }
                    </div>

                    <div style={{ ...styles.Block, width: 60, height: barHeight }}>
                        <Help link={lang.help_main} lang={props.lang} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: barHeight }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - barHeight, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    {itemID > 0 ?
                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs value={tabIndex} items={tabs} width={dialogWidth / 4} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>
                        : null}

                    <div style={{ ...styles.BlockCenter, height: itemID == 0 ? dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight : dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        {tabIndex == 0 ?
                            /*
                            *
                            * =================================================================================================================
                            * ZÁKLADNÉ ÚDAJE
                            * =================================================================================================================
                            *
                            */
                            <div style={{ ...styles.BlockCenter }}>
                                {storeData != false ?
                                    <div style={{ ...styles.BlockCenter, paddingBottom: global.list_padding }}>
                                        <div id={'id_customer_start'} style={{ ...styles.Block }}></div>
                                        <div style={{ ...styles.Block, backgroundColor: color.white }}>
                                            <FormSpace />
                                            <Avatar editable={editing} picture image={avatar} size={75} theme={props.theme} func={AvatarPress.bind()} />
                                            <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.default_image}</p>
                                            <FormSpace />
                                        </div>
                                        <FormSpace />
                                        <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                        <FormYesNo value={enabled} editing={editing} title={lang.contact_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                        <FormSelect require value={countryID} items={countries} field={'state'} editing={editing} title={lang.app_country} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeCountryID(id)} />
                                        <FormSpace />
                                        <FormText require={surname == '' ? true : false} redraw value={company} editing={editing} title={lang.company_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCompany(txt)} />
                                        <FormText redraw value={name} editing={editing} title={lang.name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                        <FormText require={company == '' ? true : false} redraw value={surname} editing={editing} title={lang.surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSurname(txt)} />

                                        <FormSpace />
                                        <FormLabel editing={editing} editButton={false} title={lang.firm_data} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                        <FormText redraw value={ico} editing={editing} title={lang.ico} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setICO(txt)} />
                                        <FormText redraw value={dic} editing={editing} title={lang.dic} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDIC(txt)} />
                                        <FormYesNo value={dph} editing={editing} title={lang.dph_pay} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setDPH(value)} />
                                        {dph == true ?
                                            <FormText redraw value={icDPH} editing={editing} title={lang.ic_dph} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setICDPH(txt)} />
                                            : null}

                                        {/*
                                        <FormSpace />
                                        <FormText redraw value={bank} editing={editing} title={lang.bank} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setBank(txt)} />
                                        <FormText redraw value={iban} editing={editing} title={lang.iban} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setIban(txt)} />
                                        <FormText redraw value={swift} editing={editing} title={lang.swift} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSwift(txt)} />
                                        */}

                                        <FormSpace />
                                        <FormLabel editing={editing} editButton={false} title={lang.contact_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                        <FormText redraw value={mobil} editing={editing} title={lang.mobil} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setMobil(txt)} />
                                        <FormText redraw value={phone} editing={editing} title={lang.phone} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPhone(txt)} />
                                        <FormText redraw value={email} editing={editing} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />

                                        <FormSpace />
                                        <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                                        <FormSpace />

                                        {editing == false ?
                                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                                <Button onClick={() => setChange(true)} style={{ ...styles.ButtonLink }}>{lang.contact_to_customer}</Button>
                                                <FormSpace />
                                                <FormInfo title={lang.contact_to_customer_note} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                            </div>
                                            : null}
                                        <FormSpace height={global.list_padding} />
                                        {/*
                                        <FormLabel editing={editing} editButton={false} title={lang.settings} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                        <FormText redraw value={credit} numeric={true} editing={editing} title={lang.credit} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCredit(txt)} />
                                        <FormAutoComplete require help_id={2} no_default value={priceListID} items={priceLists} field={'name'} editing={editing} title={lang.price_list} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setPriceListID(id)} />
                                        <FormAutoComplete value={agentID} items={agents} field={'name'} editing={editing} title={lang.customer_agent} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setAgentID(id)} />
                                        <FormSpace />
                                        */}

                                        {props.remote == true && props.remoteMobile == true ?
                                            <FormSpace height={200} />
                                            : null}

                                    </div>
                                    :
                                    <Stack style={{ ...styles.Block }} spacing={1}>
                                        <FormSpace />
                                        <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    </Stack>}
                            </div>
                            : null}


                        {tabIndex == 1 ?
                            /*
                            *
                            * =================================================================================================================
                            * EMAILOVA KOMUNIKACIA
                            * =================================================================================================================
                            *
                            */
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, overflow: 'hidden' }}>
                                <CustomerEmails remote={props.remote} remoteMobile={props.remoteMobile} new_button={itemID > 0 ? storeData != false ? storeData.email != '' ? true : false : false : false} reload={redrawEmails} customerID={itemID} height={dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight} delete={false} title={lang.email_new} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={EmailsResult.bind(this)} />
                            </div>
                            : null}

                        {tabIndex == 2 ?
                            /*
                            *
                            * =================================================================================================================
                            * POZNAMKY
                            * =================================================================================================================
                            *
                            */
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, overflow: 'hidden' }}>
                                <Notes module_id={global.model.customers} item_id={itemID} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={NotesResult.bind(this)} />
                            </div>
                            : null}


                        {tabIndex == 99 ?
                            /*
                            *
                            * =================================================================================================================
                            * HISTORIA - LOGS
                            * =================================================================================================================
                            *
                            */
                            <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, overflow: 'hidden' }}>
                                <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                            </div>
                            : null}
                    </div>

                    {tabIndex == 0 ?
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        : null}

                    <Paper elevation={5} style={{ ...styles.Block, height: buttonHeight - 1, borderTop: '1px solid #D0D0D0' }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {editing == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 180, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            {editing == true && itemID > 0 ?
                                <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 180 }}>{lang.editing_finish}</Button>
                                :
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 180 }}>{lang.close}</Button>
                            }
                        </div>
                    </Paper>

                </div>

                {showChange == true ?
                    <ContactChange remote={props.remote} remoteMobile={props.remoteMobile} contact_id={itemID} organization={props.organization} settings={props.settings} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ChangeResult.bind(this)} />
                    : null}

                {showEmailEditor == true ?
                    <EmailEditor remote={props.remote} remoteMobile={props.remoteMobile} email={email} customerID={itemID} delete={false} attachment_delete={false} title={lang.email_new} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={SendEmail.bind(this)} />
                    : null}

                {selectPhoto == true ?
                    <Photos remote={props.remote} remoteMobile={props.remoteMobile} autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} locale={props.locale} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}



/*
*
* =================================================================================================================
    VYBER ZAKAZNIKA

    single      -> vyber len jedneho


* =================================================================================================================
*
*/

export const CustomerSelect = (props) => {

    let module_id = global.model.customers;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'customers';
    let limited = props.permissions.customers_limited == true && props.user.super_admin == false ? true : false;

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        country_id: props.locale.id,
        enabled: true,
        agent_id: limited == true ? props.user.id : undefined,
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrderingName = [
        { id: 1, enabled: true, label: lang.company_name, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.company_name, icon: faArrowDown },
        { id: 3, enabled: true, label: lang.surname, icon: faArrowUp },
        { id: 4, enabled: true, label: lang.surname, icon: faArrowDown }
    ];
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // SEARCH MENU
    const [filterMenu, setFilterMenu] = useState([
        { id: 1, checked: true, enabled: true, label: lang.products_filter_enabled },
        { id: 2, checked: false, enabled: true, label: lang.products_filter_unabled },
    ]);

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.customer_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);

    // FILTERS
    const [showEnabled, setShowEnabled] = useState(false);
    const [showUnabled, setShowUnabled] = useState(false);
    const [category, setCategory] = useState(false);
    const [agent, setAgent] = useState(false);
    const [groupID, setGroupID] = useState(0);
    const [group, setGroup] = useState(0);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'company', label: lang.company_name, default: true },
        { id: 2, enabled: true, checked: true, column: 'surname', label: lang.surname },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [showAgents, setShowAgents] = useState(false);
    const [showMyGroups, setShowMyGroups] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1100 ? 1100 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;


    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 94;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const buttonHeight = 80;
    const chipHeight = 50;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);

    const db_get = async (page, filter, maxItems) => {
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customers', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.customers,
                    filename: lang.customers, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    function GetFilter() {
        var filter = defaultFilter;

        if (search.trim() != '') {
            filter.search = search;

            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default == true);
                columns.push(tmp.column);
            }

            var search_text = search.split(' ');
            if (search_text != undefined) {
                filter.search = search_text;
                filter.search_columns = columns;
            }
        }
        if (category != false) {
            filter.category_id = category.id;
        }
        if (agent != false) {
            filter.agent_id = agent.id;
        }
        if (showEnabled == true) {
            filter.enabled = true;
        }
        if (showUnabled == true) {
            filter.disabled = true;
        }

        if (groupID > 0) {
            filter.group_id = groupID;
        }
        return filter;
    }

    const FilterMenuResult = (id, index, item) => {
        var filter = GetFilter();
        setItemSelected(false);

        filterMenu.forEach(element => {
            element.checked = false;
        });

        delete (filter.enabled);
        delete (filter.disabled);

        setShowEnabled(false);
        setShowUnabled(false);

        if (index == 1) {
            // LEN ENABLED
            let value = !showEnabled;
            item.checked = value;
            setShowEnabled(value);
            if (value == true) {
                filter.enabled = true;
            }
        }
        if (index == 2) {
            // LEN UNABLED
            let value = !showUnabled;
            item.checked = value;
            setShowUnabled(value);
            if (value == true) {
                filter.disabled = true;
            }
        }

        db_get(0, filter, maxItems);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // NAZOV FIRMY (index: 1,2) PRIEZVISKO (index: 3,4)
                column = index == 1 || index == 2 ? 'company' : 'surname';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;
        }

        var filter = {
            ordering: order,
            order_column: column
        };
        if (search != '') {
            filter.search = search;
        }
        db_get(0, filter, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const CategoriesResult = (typ, selected) => {
        setShowCategories(false);

        if (typ == true) {
            setCategory(selected);

            var filter = GetFilter();
            filter.category_id = selected.id;
            db_get(0, filter, maxItems);
        }
    }

    const CategoryDelete = () => {
        var filter = GetFilter();
        delete filter.category_id;

        setCategory(false);
        setFilter(filter);

        db_get(0, filter, maxItems);
    }

    const MyGroupResult = (typ, item) => {
        setShowMyGroups(false);

        if (typ == true) {
            setGroup(item.label);
            setGroupID(item.id);

            var filter = GetFilter();
            filter.group_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const AgentsResult = (typ, selected) => {
        setShowAgents(false);

        if (typ == true) {
            setAgent(selected);
            var filter = GetFilter();
            filter.agent_id = selected.id;
            db_get(0, filter, maxItems);
        }
    }

    const UserOnly = () => {
        setAgent(props.user);
        var filter = GetFilter();
        filter.agent_id = props.user.id;
        db_get(0, filter, maxItems);
    }

    const AgentDelete = () => {
        var filter = GetFilter();
        delete filter.agent_id;

        setAgent(false);
        db_get(0, filter, maxItems);
    }


    const SelectItem = (item) => {
        // VYBER JEDNEJ POLOZKY
        func(true, [item.id], [item]);
    }

    const SelectClose = () => {
        // MULTI SELECT
        var selected_id = [];
        var selected_items = [];
        items.forEach(item => {
            if (item.selected == true) {
                selected_id.push(item.id);
                selected_items.push(item);
            }
        });
        if (selected_id.length == 0) {
            selected_id = false;
            selected_items = false;
        }

        func(true, selected_id, selected_items);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2100 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.customers}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - buttonHeight }}>
                        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                            <div style={{ ...styles.Block, width: '96%' }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '30%', height: menuHeight }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.Block, width: 30, paddingTop: 3, marginRight: 5 }}>
                                                <MenuButton id={0} icon={faFilter} backgroundColor={'#00000000'} color={color.dark_gray} label={''} menuItems={filterMenu} theme={props.theme} func={FilterMenuResult.bind()} />
                                            </div>
                                            <TextInput search theme={props.theme} width={'100%'} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                            <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                                <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '40%', height: menuHeight }}>
                                        <div style={{ ...styles.BlockRowRaw, height: menuHeight }}>
                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 20 }}>
                                                <Button onClick={() => setShowCategories(true)} style={{ ...styles.ButtonOutlined, width: 120, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faFolderTree} />
                                                    {lang.customer_categories}
                                                </Button>
                                            </div>
                                            <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                <Button onClick={() => setShowMyGroups(true)} style={{ ...styles.ButtonOutlined, width: 130, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faLayerGroup} />
                                                    {lang.customers_groups_}
                                                </Button>
                                            </div>
                                            {limited == false ?
                                                <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                                    <Button onClick={() => setShowAgents(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                                        <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faUser} />
                                                        {lang.customer_agent}
                                                    </Button>
                                                </div>
                                                : null}
                                            <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                                <IconButton onClick={() => UserOnly(true)} style={{ ...styles.ButtonUser }}>
                                                    <FontAwesomeIcon style={{ height: 14 }} icon={faUser} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '30%', height: menuHeight }}>
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockRow, height: chipHeight }}>
                                    {filter != false ? filter.category_id != undefined ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{category.label}</p>} onDelete={() => CategoryDelete()}></Chip>
                                        : null : null}

                                    {limited == false ? filter != false ? filter.agent_id != undefined ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{agent.surname} {agent.name}</p>} onDelete={() => AgentDelete()}></Chip>
                                        : null : null : null}
                                </div>

                                <div style={{ ...styles.Block }}>

                                    {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}

                                    <div style={{ ...styles.Block, border: color.border }}>
                                        <div style={{ ...styles.BlockRow, width: '99%' }}>
                                            <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center' }}>
                                                {/* SELECT */}
                                                <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    {props.single == undefined ?
                                                        <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                                        : null}
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '85%' }}>
                                                {/* DATA */}
                                                <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: '40%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.customer}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                        <MenuButton id={1} sortMenu={true} menuItems={itemsOrderingName} theme={props.theme} func={MenuSortResult.bind()} />
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '40%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.address}</p>
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: 150, height: tableLabelHeight, borderLeft: color.border }}>
                                                {/* ENABLED */}
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.customer_category}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - menuHeight - tableLabelHeight - chipHeight - buttonHeight - 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                        <div id={'id_start'} style={{ ...styles.Block }}></div>

                                        {/* ****************************************************************************************************************
                                        ZOZNAM ZAKAZNIKOV
                                    **************************************************************************************************************** */}

                                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                    <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                        {/* SELECT */}
                                                        {props.single == undefined ?
                                                            <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                                            : null}
                                                    </div>

                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '85%' }}>
                                                        {/* DATA */}
                                                        <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                            <Avatar picture image={item.avatar} size={rowHeight - 20} theme={props.theme} />
                                                        </div>

                                                        <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                            <ContactCompany company={item.company} name={item.name} surname={item.surname} theme={props.theme} />
                                                            {item.agent != '' ?
                                                                <Chip variant='outlined' size='small' style={{ marginTop: 5 }} label={item.agent} />
                                                                : null}
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                            {item.address != false}{
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <p style={{ ...styles.TextTiny }}>{item.address.street}</p>
                                                                    <p style={{ ...styles.TextTiny }}>{item.address.psc} {item.address.town}</p>
                                                                    <p style={{ ...styles.TextTiny }}>{item.address.state}</p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        {item.category != '' ?
                                                            <Chip
                                                                style={{ marginTop: 2, marginBottom: 2 }}
                                                                sx={{
                                                                    borderColor: color.dark_gray,
                                                                    paddingTop: '2px',
                                                                    paddingBottom: '2px',
                                                                    height: 'auto',
                                                                    '& .MuiChip-label': {
                                                                        display: 'block',
                                                                        whiteSpace: 'normal',
                                                                    },
                                                                }}
                                                                label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_gray }}>{item.category}</p>} variant="outlined"
                                                            />
                                                            : null}
                                                    </div>
                                                </div>
                                            </Paper>
                                        )) :
                                            <EmptyList row small lang={lang} />
                                        }

                                        {/* ****************************************************************************************************************
                                            PAGINATION
                                        **************************************************************************************************************** */}

                                        <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                            <div style={{ ...styles.Block, width: 200 }}></div>
                                            <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                                {pages > 1 ?
                                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                        <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                        <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => SelectClose()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.ok}</Button>
                    </div>
                </div>

                {showCategories == true ?
                    <CustomersCategorySelect module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CategoriesResult.bind()} />
                    : null}

                {showMyGroups == true ?
                    <CustomersGroupSelect country_id={props.locale.id} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={MyGroupResult.bind()} />
                    : null}

                {showAgents == true ?
                    <UsersSelect groups organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.user_select} token={props.token} func={AgentsResult.bind()} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}


            </Paper >
        </Backdrop>
    );
}


export const ContactChange = (props) => {
    /*

            ZMENA KONTAKTU NA ZAKAZNIKA + DOPLENIE INFORMACII

    */
    let lang = props.lang;
    let module_id = global.model.contacts;

    const [isBusy, setBusy] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');

    const [enabled, setEnabled] = useState(false);
    const [contact, setContact] = useState(0);

    const [agents, setAgents] = useState(false);
    const [agentID, setAgentID] = useState(-1);
    const [priceLists, setPriceLists] = useState(false);
    const [priceListID, setPriceListID] = useState(-1);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 600;
    const dialogHeight = 636;

    let column1 = props.column1 == undefined ? 200 : props.column1;
    let column2 = props.column2 == undefined ? dialogWidth - column1 - 40 : props.column2;

    let { func } = props;
    const color = global.themes[props.theme];

    var running = false;
    useEffect(() => {

        if (running == false) {

            db_get();

            running = true;
        }

        return () => {
        };

    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'contact', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.contact_id,
                })
            })

            const json = await response.json();
            Debug('CONTACT');
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;

                    setContact(item);
                    setEnabled(item.enabled == false ? false : true);

                    db_get_data(item.country_id);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_data = async (country_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    country_id: country_id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setAgents(json.agents);
                setPriceLists(json.price_list);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(false, false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
        func(true);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Proccess = () => {

        var err = 0;

        if (priceListID <= 0) {
            err++;
        }

        if (err == 0) {
            setError('');

            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: global.model.customers,
                item_id: props.contact_id,
                action: global.logs.created_from_contact
            };

            let data = {
                id: props.contact_id,
                typ: 0,
                enabled: enabled,
                price_list_id: priceListID,
                agent_id: agentID > 0 ? agentID : 0,
                logs: log
            }

            Debug(data);
            db_update(data);

        } else {
            setError(lang.required_red);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faPaperPlane} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.customer_new}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 140, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 140, width: '98%', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <FormSpace />
                        <FormLabel editing={true} icon={faInfoCircle} title={lang.contact_customer} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />

                        <FormYesNo value={enabled} editing={true} title={lang.customer_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                        <FormNote title={lang.contact_to_customer_enabled} marginBottom={8} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                        <FormAutoComplete require help_id={2} no_default value={priceListID} items={priceLists} field={'name'} editing={true} title={lang.price_list} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setPriceListID(id)} />
                        <FormNote title={lang.contact_to_customer_price_list} marginBottom={8} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                        <FormAutoComplete value={agentID} items={agents} field={'name'} editing={true} title={lang.customer_agent} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setAgentID(id)} />
                        <FormNote title={lang.contact_to_customer_rep} marginBottom={8} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <FormInfo title={lang.contact_to_customer_note_} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                        </div>

                    </div>

                </div>
                <div style={{ ...styles.Block, height: 140 }}>
                    <div style={{ ...styles.Block, height: 50 }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: 90 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button onClick={() => Proccess()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 180 }}>{lang.contact_to_customer_create}</Button>
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 180, marginLeft: 20 }}>{props.lang.close}</Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}


            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}
