/*
*
* =================================================================================================================

    VYBER PRODUKTOV

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <ProductSelect country_id={country_id} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    showVariations      -> TRUE - zobrazí na výber aj variácie
    hide_grouped        -> nezobrazí balíčky
    hide_multiple       -> skryt check box na výber produktov - možnosť vybrať len jeden produkt
    only_enabled        -> iba enabled
    only_sku            -> len produkty a variacie s SKU (mimo variacneho balíčka)
    countrySelect       -> výber krajiny
    stock               -> ID SKLADU -  počet kusov na danom sklade
    changeCountry       -> zmena krajiny a produktov
    excluded            -> Array[100, 234,...] - produkty, ktoré nebudú v zozname
    orderTypes          -> triedenie podla typov objednávok 
    orderTypeDefault    -> Predvolený typ objednávky
    filters             -> zobrazenie filtrov
    info                -> textova informácia k vyberu produktu

    [0] VYBER PRODUKTU
    -----------------------------------------------
    nesiem byt definovaný "stock" ani "price_list"
    select_only         -> len výber produktu bez cien a skladu

    [1] VYBER PRODUKTU ZO SKLADOV
    -----------------------------------------------
    stock               -> ID skladu
    stocks              -> true (zapne zobrazovanie skladových stavov)

    [2] CENNIK PRODUKTOV
    -----------------------------------------------
    price_list          -> ID cenníka

    TYPE:
        0 - produkty
        1 - sklady
        2 - cenníky


* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { Avatar, EmptyList, MenuButton, Icon, Line, Loading, SearchButton, SelectInput, ShowError, ShowOK, TextInput, CountrySelect, DialogEnterText, DialogEnterNumber } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, FormControlLabel, IconButton, MenuItem, Pagination, Paper, Radio, RadioGroup, Select, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowPointer, faArrowUp, faFilter, faFlag, faFolderTree, faInfoCircle, faLayerGroup, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, FormatMoney, FormatMoneyDPH, FormatPieces, GetLeftPosition, GetPages, notNumber } from './functions';
import { ProductCategorySelect } from './product_categories';
import { ManufacturerSelect } from './manufacturers';
import { VendorSelect } from './vendors';
import { ProductsGroupSelect } from './product_groups';
import { ProductFlagsSelect } from './product_flags';
import { OrderTypeSelect } from './order_types';


export const ProductSelect = (props) => {

    let module_id = global.model.products;
    let url = props.price_list == undefined ? props.stock == undefined ? global.db_url + 'products' : global.db_url + 'warehouse_products' : global.db_url + 'price_list_products'

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'products';
    let currency = props.locale.currency;
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let show_prices_dph = props.system.show_prices_dph == false ? false : true;

    let type = props.price_list == undefined ? props.stock == undefined ? 0 : 1 : 2;

    let defaultFilter = props.price_list == undefined ?
        props.stock == undefined ?
            // -------------------------------------------------------------------------------------------------------------------------------------
            // PRODUKTY
            // -------------------------------------------------------------------------------------------------------------------------------------
            {
                a: 'products',
                ordering: global.order.down,
                order_column: 'name',
                parent_id: 0,
                country_id: props.country_id,
                hide_grouped: props.hide_grouped == true ? true : false,
                excluded: props.excluded != undefined ? props.excluded.length == 0 ? undefined : props.excluded : undefined,
            }
            :
            // -------------------------------------------------------------------------------------------------------------------------------------
            // SKLAD
            // -------------------------------------------------------------------------------------------------------------------------------------
            {
                a: 'Warehouse',
                enabled: props.only_enabled == true ? true : undefined,
                ordering: global.order.down,
                order_column: 'name',
                parent_id: 0,
                country_id: props.country_id,
                hide_grouped: props.hide_grouped == true ? true : false,
                excluded: props.excluded != undefined ? props.excluded.length == 0 ? undefined : props.excluded : undefined,
                stock_id: props.stock,
            }
        :
        // -------------------------------------------------------------------------------------------------------------------------------------
        // CENNIK
        // -------------------------------------------------------------------------------------------------------------------------------------
        {
            a: 'Pricelist',
            ordering: global.order.down,
            order_column: 'name',
            country_id: props.country_id,
            parent_id: 0,
            enabled: true,
            stocks: true,
            stock_id: props.stock_id == undefined ? 0 : props.stock_id,
            excluded: props.excluded != undefined ? props.excluded.length == 0 ? undefined : props.excluded : undefined,
        };

    // SEARCH MENU
    const [filterMenu, setFilterMenu] = useState(type == 0 ?
        [
            { id: 1, enabled: true, label: lang.products_filter_enabled },
            { id: 2, enabled: true, label: lang.products_filter_unabled },
            { id: 3, enabled: true, label: lang.products_filter_published },
            { id: 4, enabled: true, label: lang.products_filter_not_published },
        ]
        :
        [
            //{ id: 1, enabled: true, label: lang.products_filter_enabled },
            //{ id: 2, enabled: true, label: lang.products_filter_unabled },
            { id: 11, enabled: true, label: lang.stock_zero_status },
            { id: 12, enabled: true, label: lang.stock_smaller_status },
            { id: 14, enabled: true, label: lang.stock_bigger_status },
            { id: 15, enabled: true, label: lang.only_on_stock },
        ]
    );

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [maxItems, setMaxItems] = useState(20);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [searchArray, setSearchArray] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [categoryID, setCategoryID] = useState(0);
    const [category, setCategory] = useState(0);
    const [manufactureID, setManufactureID] = useState(0);
    const [manufacture, setManufacture] = useState(0);
    const [flagID, setFlagID] = useState(0);
    const [flag, setFlag] = useState(0);
    const [vendorID, setVendorID] = useState(0);
    const [vendor, setVendor] = useState(0);
    const [groupID, setGroupID] = useState(0);
    const [group, setGroup] = useState(0);
    const [flags, setFlags] = useState(false);
    const [countryID, setCountryID] = useState(props.country_id);

    // TYPY OBJEDNAVKY
    const [orderType, setOrderType] = useState('');
    const [orderTypeID, setOrderTypeID] = useState(0);
    const [orderTypeProductsAll, setOrderTypeProductsAll] = useState(0);
    const [orderTypeCategoryID, setOrderTypeCategoryID] = useState(0);

    const [filters, setFilters] = useState(false);

    const [filterEnabled, setFilterEnabled] = useState(false);
    const [filterDisabled, setFilterDisabled] = useState(false);
    const [filterPublished, setFilterPublished] = useState(false);
    const [filterNotPublished, setFilterNotPublished] = useState(false);

    const [filterZero, setFilterZero] = useState(false);
    const [filterSmaller, setFilterSmaller] = useState(-1);
    const [enterSmallerValue, setEnterSmallerValue] = useState(10);
    const [filterBigger, setFilterBigger] = useState(-1);
    const [enterBiggerValue, setEnterBiggerValue] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.product_name, default: true },
        { id: 2, enabled: true, checked: true, column: 'code', label: lang.product_code },
        { id: 3, enabled: true, checked: true, column: 'tags', label: lang.product_tags }
    ]);

    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowDown },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowUp }
    ];
    const itemsOrdering2 = [
        { id: 1, enabled: true, label: lang.product_name, icon: faArrowDown },
        { id: 2, enabled: true, label: lang.product_name, icon: faArrowUp },
        { id: 100, divider: true },
        { id: 3, enabled: true, label: lang.document_number, icon: faArrowDown },
        { id: 4, enabled: true, label: lang.document_number, icon: faArrowUp },
        { id: 101, divider: true },
        { id: 5, enabled: true, label: lang.product_code, icon: faArrowDown },
        { id: 6, enabled: true, label: lang.product_code, icon: faArrowUp },
    ];

    // DATA
    const [value, setValue] = useState(false);

    // DIALOGS
    const [showCategorySelect, setShowCategorySelect] = useState(false);
    const [showManufactureSelect, setShowManufactureSelect] = useState(false);
    const [showVendorSelect, setShowVendorSelect] = useState(false);
    const [showGroup, setShowGroup] = useState(false);
    const [showFlagsSelect, setShowFlagsSelect] = useState(false);
    const [showVariations, setShowVariations] = useState(props.showVariations == true ? true : false);
    const [showOrderTypes, setShowOrderTypes] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    const menuHeight = 60;
    const rowHeight = 80;
    const filterHeight = 50;
    const infoHeight = props.info == undefined ? 0 : 50;
    const viewWidth = '96%';
    const bottomHeight = 80;
    const paginationHeight = 50;
    const tableLabelHeight = 40;
    const rowVariantHeight = 56;
    const filterButtons = 110;

    let { func } = props;
    var running = false;
    const inputRef = useRef(null);

    useEffect(() => {
        if (running == false) {
            Debug('defaultFilter', 'blue');
            Debug(defaultFilter);
            if (props.orderTypes == true) {
                if (props.orderTypeDefault == false) {
                    setShowOrderTypes(true);
                } else {
                    OrderTypeResult(true, props.orderTypeDefault);
                }
            } else {
                // UDAJE Z DB
                var filter = defaultFilter;

                /*
                if (props.stock != undefined) {
                    filter.stock = props.stock;
                }
                */
                if (props.only_enabled == true) {
                    filter.enabled = true;
                }
                if (props.orderTypes == true) {
                    filter.order_type = orderTypeID;
                }

                db_get(0, filter, maxItems);
                db_data();
            }

            // FOCUS DO SEARCH TEXT
            setTimeout(() => {
                inputRef.current.focus();
            }, 300);

            running = true;
        }

    }, []);

    const db_get = async (page, filter, maxItems) => {
        Debug('filter', 'red');
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.price_list == undefined ? 0 : props.price_list,
                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_data = async () => {
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                })
            })

            const json = await response.json();
            Debug('PRODUCT-DATA');
            Debug(json);
            if (json.ok > 0) {
                setFlags(json.flags);
            }

        } catch (error) {
            console.log(error);
        }
    }


    const Close = () => {
        func(false, false);
    }

    function GetFilter() {

        var filter = defaultFilter;

        if (props.countrySelect == true) {
            filter.country_id = countryID;
        }
        if (props.orderTypes == true) {
            if (orderTypeProductsAll == 0 || orderTypeProductsAll == 2) {
                filter.order_type = orderTypeID;
            }
            filter.order_products_all = orderTypeProductsAll;
            filter.order_category_id = orderTypeCategoryID;
        }
        if (props.excluded != undefined) {
            filter.excluded = props.excluded.length == 0 ? undefined : props.excluded
        }

        if (props.stocks == true) {
            filter.stocks = true;
        }

        if (search.trim() != '') {
            filter.search = search;

            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default == true);
                columns.push(tmp.column);
            }

            var search_text = search.split(' ');
            if (search_text != undefined) {
                filter.search = search_text;
                filter.search_columns = columns;
            }
        }
        if (vendorID > 0) {
            filter.vendor_id = vendorID
        }
        if (manufactureID > 0) {
            filter.manufacture_id = manufactureID
        }
        if (flagID > 0) {
            filter.flag_id = flagID;
        }
        if (categoryID > 0) {
            filter.category_id = categoryID;
        }
        if (groupID > 0) {
            filter.group_id = groupID;
        }

        if (props.stock != undefined) {
            filter.stock_id = props.stock;
        }
        if (filterEnabled == true) {
            filter.enabled = true;
        }
        if (filterDisabled == true) {
            filter.disabled = true;
        }
        if (filterNotPublished == true) {
            filter.not_published = true;
        }
        if (filterPublished == true) {
            filter.published = true;
        }
        if (filterZero == true) {
            filter.stock_zero = true;
        }
        if (filterSmaller > -1) {
            filter.stock_smaller = filterSmaller;
        }
        if (filterBigger > -1) {
            filter.stock_bigger = filterBigger;
        }

        filter.parent_id = 0;

        return filter;
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.trim() != '') {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            if (search_text != undefined) {
                filter.search = search_text;
                filter.search_columns = columns;
            }

            if (props.showVariations == true) {
                delete (filter.parent_id);
            } else {
                filter.parent_id = 0;
            }

            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            db_get(0, filter, maxItems);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.down : global.order.up;
        var column = 'name';
        Debug(id);
        switch (id) {

            case 1: // NAZOV
                column = 'name';
                break;

            case 2: // SKLADOM
                column = 'stock_quantity';
                break;

            case 3: // SKLADOM
                column = 'price_final';
                break;
        }

        var filter = GetFilter();
        filter.ordering = order;
        filter.order_column = column;

        db_get(0, filter, maxItems);
    }

    const MenuSortResult2 = (id, index) => {
        var order = global.order.down;
        var column = 'name';

        switch (index) {

            case 1: // NAZOV
                column = 'name';
                order = global.order.down
                break;

            case 2: // SKLADOM
                column = 'name';
                order = global.order.up
                break;

            case 3: // ID
                column = 'id';
                order = global.order.down
                break;

            case 4: // ID
                column = 'id';
                order = global.order.up
                break;

            case 5: // KOD PRODUKTU
                column = 'code';
                order = global.order.down
                break;

            case 6: // KOD PRODUKTU
                column = 'code';
                order = global.order.up
                break;
        }

        var filter = GetFilter();
        filter.ordering = order;
        filter.order_column = column;
        db_get(0, filter, maxItems);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start_dialog_1');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const CategoryResult = (typ, item) => {
        setShowCategorySelect(false);

        if (typ == true) {
            setCategory(item.label);
            setCategoryID(item.id);

            var filter = GetFilter();
            filter.category_id = item.id;
            db_get(0, filter, maxItems);
        }
    }

    const OrderTypeResult = (typ, item) => {
        setShowOrderTypes(false);
        Debug(item);
        if (typ == 1) {
            item.products_all = parseInt(item.products_all);
            setOrderType(item.name);
            setOrderTypeID(item.id);
            setOrderTypeProductsAll(item.products_all);
            setOrderTypeCategoryID(item.category_id);

            var filter = GetFilter();
            if (item.products_all == 0 || item.products_all == 2) {
                filter.order_type = item.id;
                filter.order_products_all = item.products_all;
                filter.order_category_id = item.category_id;
            } else {
                delete (filter.order_type);
                delete (filter.order_products_all);
                delete (filter.order_category_id);
            }
            db_get(0, filter, maxItems);
        }
    }

    const CategoryReset = (id) => {
        setCategoryID(0);
        setCategory('');

        var filter = GetFilter();
        delete (filter.category_id);
        db_get(0, filter, maxItems);
    }

    const ManufactureResult = (typ, item) => {
        setShowManufactureSelect(false);

        if (typ == true) {
            setManufacture(item.name);
            setManufactureID(item.id);

            var filter = GetFilter();
            filter.manufacture_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const ResetManufacture = () => {
        setManufactureID(0);
        setManufacture('');

        var filter = GetFilter();
        delete (filter.manufacture_id);
        db_get(0, filter, maxItems);
    }

    const FlagResult = (typ, item) => {
        setShowFlagsSelect(false);

        if (typ == true) {
            setFlag(item.name);
            setFlagID(item.id);

            var filter = GetFilter();
            filter.flag_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const ResetFlag = () => {
        setFlagID(0);
        setFlag('');

        var filter = GetFilter();
        delete (filter.flag_id);
        db_get(0, filter, maxItems);
    }

    const VendorResult = (typ, item) => {
        setShowVendorSelect(false);

        if (typ == true) {
            setVendor(item.name);
            setVendorID(item.id);

            var filter = GetFilter();
            filter.vendor_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const ResetVendor = () => {
        setVendorID(0);
        setVendor('');

        var filter = GetFilter();
        delete (filter.vendor_id);
        db_get(0, filter, maxItems);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const SelectItem = (item) => {
        // VYBER JEDNEJ POLOZKY
        if ((props.only_sku == true && item.variations == false) || props.only_sku == undefined) {
            func(true, [item.id], [item], orderTypeID);
        }
    }

    const SelectClose = () => {
        // MULTI SELECT
        var selected_id = [];
        var selected_items = [];
        items.forEach(item => {
            if (item.selected) {
                if (item.variations == true && props.showVariations == true) {
                    if (item.variants != false) {
                        item.variants.forEach(variant => {
                            selected_id.push(variant.id);
                            selected_items.push(variant);
                        });
                    }
                } else {
                    selected_id.push(item.id);
                    selected_items.push(item);
                }
            }
        });
        if (selected_id.length == 0) {
            selected_id = false;
            selected_items = false;
        }

        func(true, selected_id, selected_items, orderTypeID);
    }

    const GroupResult = (typ, item) => {
        setShowGroup(false);

        if (typ == true) {
            setGroup(item.label);
            setGroupID(item.id);

            var filter = GetFilter();
            filter.group_id = item.id;

            db_get(0, filter, maxItems);
        }
    }

    const ResetGroup = () => {
        setGroupID(0);
        setGroup('');

        var filter = GetFilter();
        delete (filter.group_id);
        db_get(0, filter, maxItems);
    }

    const ChangeCountry = (item) => {
        setCountryID(item.id);
        var filter = GetFilter();
        filter.country_id = item.id;
        setCountryID(item.id);
        db_get(0, filter, maxItems);
    }

    const FilterMenuResult = (id, index, item) => {

        var filter = GetFilter();
        setItemSelected(false);

        if (index == 1) {
            // ENABLED
            setFilterEnabled(true);
            setFilterDisabled(false);

            filter.enabled = true;
            delete (filter.disabled);

            db_get(0, filter, maxItems);
        }
        if (index == 2) {
            // DISABLED
            setFilterEnabled(false);
            setFilterDisabled(true);

            delete (filter.enabled);
            filter.disabled = true;

            db_get(0, filter, maxItems);
        }
        if (index == 3) {
            // PUBLIKOVANE
            setFilterPublished(true);
            setFilterNotPublished(false);

            filter.published = true;
            delete (filter.not_published);

            db_get(0, filter, maxItems);
        }
        if (index == 4) {
            // NEPUBLIKOVANE
            setFilterPublished(false);
            setFilterNotPublished(true);

            delete (filter.published);
            filter.not_published = true

            db_get(0, filter, maxItems);
        }

        if (index == 11) {
            // LEN NULOVE STAVY

            delete (filter.stock_zero);
            delete (filter.stock_smaller);
            delete (filter.stock_bigger);

            setFilterZero(true);
            setFilterSmaller(-1);
            setFilterBigger(-1);
            filter.stock_zero = true;

            db_get(0, filter, maxItems);
        }

        if (index == 12) {
            // LEN STAVY MENSIE AKO

            delete (filter.stock_zero);
            delete (filter.stock_bigger);

            setFilterZero(false);
            setFilterBigger(-1);
            setEnterSmallerValue(true);
        }

        if (index == 14) {
            // LEN STAVY VACSIE AKO

            delete (filter.stock_zero);
            delete (filter.stock_smaller);

            setFilterZero(false);
            setFilterSmaller(-1);
            setEnterBiggerValue(true);
        }

        if (index == 15) {
            // IBA SKLADOM - vacsie ako 0

            delete (filter.stock_zero);
            delete (filter.stock_smaller);
            filter.stock_bigger = 0;

            setFilterZero(false);
            setFilterSmaller(-1);
            setFilterBigger(0);

            db_get(0, filter, maxItems);
        }

    }

    const FiltersReset = (id) => {
        var filter = GetFilter();

        if (id == 1) {
            // ENABLED
            setFilterEnabled(false);
            delete (filter.enabled);
        }
        if (id == 2) {
            // DISABLED
            setFilterDisabled(false);
            delete (filter.disabled);
        }
        if (id == 3) {
            // PUBLIKOVANE
            setFilterPublished(false);
            delete (filter.published);
        }
        if (id == 4) {
            // NEPUBLIKOVANE
            setFilterNotPublished(false);
            delete (filter.not_published);
        }

        if (id == 11) {
            // LEN NULOVE STAVY
            delete (filter.stock_zero);
            setFilterZero(false);
        }

        if (id == 12) {
            // LEN STAVY MENSIE AKO
            delete (filter.stock_smaller);
            setFilterSmaller(-1);
        }

        if (id == 14) {
            // LEN STAVY VACSIE AKO
            delete (filter.stock_bigger);
            setFilterBigger(-1);
        }

        db_get(0, filter, maxItems);
    }


    const SmallerResult = (typ, value) => {

        setEnterSmallerValue(false);
        if (typ == true) {
            setFilterSmaller(parseInt(value > -1 ? value : 0));

            let filter = GetFilter();
            filter.stock_smaller = parseInt(value);
            delete (filter.stock_bigger);
            db_get(0, filter, maxItems);
        }
    }

    const BiggerResult = (typ, value) => {
        setEnterBiggerValue(false);
        if (typ == true) {
            setFilterBigger(parseInt(value > -1 ? value : 0));

            let filter = GetFilter();
            filter.stock_bigger = parseInt(value);
            delete (filter.stock_smaller);

            db_get(0, filter, maxItems);
        }
    }


    const ProductRow = (rowProps) => {
        let item = rowProps.item;
        return (
            <div key={item.id} style={{ ...styles.Block }}>
                <Paper
                    key={item.id}
                    elevation={item.id == overID ? 4 : 1}
                    style={{
                        ...styles.Block,
                        backgroundColor: item.enabled == false ? color.disabled : item.grouped == true ? color.grouped : item.variations == true ? color.variation : color.white,
                        borderRadius: global.items_radius,
                        minHeight: rowHeight,
                        cursor: 'pointer',
                        marginTop: 5, marginBottom: 5,
                        paddingTop: 2, paddingBottom: 2,
                        borderLeft: item.enabled == false ? color.disabled_border : item.variations == true ? color.border_variation : item.grouped == true ? color.border_grouped : '',
                    }}
                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                >
                    <div style={{ ...styles.BlockRow, width: '100%' }}>
                        <div style={{ ...styles.Block, width: 40, minHeight: rowHeight }}>
                            {/* SELECT */}
                            {props.hide_multiple != true ?
                                <Checkbox style={{ padding: 0, margin: 0 }} checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                : null}
                        </div>

                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '96%' }}>
                            {/* DATA */}
                            <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight }}>
                                <Avatar picture image={item.image} size={rowHeight - 20} theme={props.theme} />
                            </div>

                            <div style={{ ...styles.BlockLeft, width: '65%', minHeight: rowHeight, justifyContent: 'center' }}>
                                <div style={{ ...styles.BlockLeft, height: 28, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    {item.parent_id > 0 ?
                                        <p style={{ ...styles.TextXTiny }}>{item.parent_name}</p>
                                        : null}
                                    <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name}</p>
                                </div>
                                <Line marginTop={4} theme={props.theme} />
                                <div style={{ ...styles.BlockRowRaw, marginTop: 4 }}>
                                    <div style={{ ...styles.Block, width: undefined, height: 24 }}>
                                        {item.variations == false || item.parent_id > 0 ?
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.code}</p>
                                            : null}
                                    </div>
                                    {item.category != '' && item.category != undefined ?
                                        <div style={{ ...styles.Block, width: undefined, height: 24, marginLeft: 10 }}>
                                            <Chip variant='outlined' size='small' style={{ borderColor: color.dark_gray, height: 18 }} label={<p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.category}</p>}></Chip>
                                        </div>
                                        : null}
                                    <div style={{ ...styles.Block, width: undefined, height: 24 }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{item.manufacture}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ ...props.stock != undefined ? styles.Block : styles.BlockRight, width: '20%', minHeight: rowHeight, justifyContent: 'center' }}>
                                {item.variations == false ?
                                    props.price == true ?
                                        <>
                                            <p style={{ ...styles.TextTiny }}>{FormatMoney(item.price_final, precisions)} {currency}</p>
                                            {show_prices_dph == true ?
                                                <>
                                                    <p style={{ ...styles.TextXXTiny, marginTop: 5, color: color.dark_gray }}>{lang.price_with_dph}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 0 }}>{FormatMoneyDPH(item.price_final, item.dph == -1 ? props.locale.dph : item.dph, precisions)} {currency}</p>
                                                </>
                                                : null}
                                        </>
                                        : null
                                    : null}
                                {item.variations == false ?
                                    props.stock != undefined ?
                                        <p style={{ ...styles.TextTiny }}>{FormatPieces(item.stock_quantity)}</p>
                                        : null
                                    : null}
                            </div>
                            <div style={{ ...styles.Block, width: '15%', minHeight: rowHeight, justifyContent: 'center' }}>
                                {props.stocks == true ?
                                    item.variations == false ?
                                        <p style={{ ...styles.TextTiny }}>{FormatPieces(item.stock_quantity)} {item.unit}</p>
                                        : null :
                                    <div style={{ ...styles.Block, width: '85%' }}>
                                        {item.date_end != global.date_endless ?
                                            <Chip
                                                style={{ marginTop: 2, marginBottom: 2 }}
                                                sx={{
                                                    borderColor: color.dark_red,
                                                    paddingTop: '2px',
                                                    paddingBottom: '2px',
                                                    height: 'auto',
                                                    '& .MuiChip-label': {
                                                        display: 'block',
                                                        whiteSpace: 'normal',
                                                    },
                                                }}
                                                label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.dark_red, }}>{lang.product_ranged}</p>} variant="outlined"
                                            />
                                            : null}
                                        {item.price_temporary == true ?
                                            <Chip
                                                style={{ marginTop: 2, marginBottom: 2 }}
                                                sx={{
                                                    borderColor: color.darkest_green,
                                                    paddingTop: '2px',
                                                    paddingBottom: '2px',
                                                    height: 'auto',
                                                    '& .MuiChip-label': {
                                                        display: 'block',
                                                        whiteSpace: 'normal',
                                                    },
                                                }}
                                                label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.darkest_green, }}>{lang.product_price_ranged_}</p>} variant="outlined"
                                            />
                                            : null}
                                        {item.variations == true ?
                                            <Chip
                                                style={{ marginTop: 2, marginBottom: 2 }}
                                                sx={{
                                                    borderColor: color.blue,
                                                    paddingTop: '2px',
                                                    paddingBottom: '2px',
                                                    height: 'auto',
                                                    '& .MuiChip-label': {
                                                        display: 'block',
                                                        whiteSpace: 'normal',
                                                    },
                                                }}
                                                label={<p style={{ ...styles.TextXXTiny, textAlign: 'center', color: color.blue, }}>{lang.product_variations_}</p>} variant="outlined"
                                            />
                                            : null}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Paper>

                <div style={{ ...styles.BlockRight, marginBottom: item.variants != false && showVariations == true ? 30 : 0 }}>
                    {showVariations == true ? item.variants != false ? item.variants.map((variant) => (
                        <VariantRow key={variant.id} item={variant} show_variant={false} />
                    )) : null : null}
                </div>
            </div>
        );
    }

    const VariantRow = (rowProps) => {
        let variant = rowProps.item;
        return (
            <div style={{ ...styles.BlockRight }}>
                <Paper
                    key={variant.id}
                    elevation={variant.id == overID ? 4 : 1}
                    style={{
                        ...styles.Block,
                        width: '97%',
                        backgroundColor: variant.enabled == false ? color.disabled : color.variation,
                        borderRadius: global.items_radius,
                        minHeight: rowVariantHeight,
                        cursor: 'pointer',
                        marginTop: 5, marginBottom: 5,
                        paddingTop: 2, paddingBottom: 2,
                        borderLeft: variant.enabled == false ? color.disabled_border : '',
                    }}
                    onMouseOverCapture={() => setOverID(variant.id)} onMouseLeave={() => setOverID(-1)}
                    onClick={() => SelectItem(variant)}
                >
                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                        <div style={{ ...styles.BlockLeft, width: rowHeight, height: rowVariantHeight, justifyContent: 'center' }}>
                            <Avatar picture image={variant.image} size={rowVariantHeight - 5} theme={props.theme} />
                        </div>
                        <div style={{ ...styles.BlockLeft, width: '65%', height: rowVariantHeight }}>
                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{variant.name} { }</p>
                            <p style={{ ...styles.TextXTiny }}>{variant.code}</p>
                        </div>
                        <div style={{ ...props.stock != undefined ? styles.Block : styles.BlockRight, width: '20%', height: rowVariantHeight }}>
                            {props.stock != undefined ?
                                <p style={{ ...styles.TextXSmall }}>{FormatPieces(variant.stock_quantity)}</p>
                                : null}
                            {props.price == true ?
                                <>
                                    <p style={{ ...styles.TextXSmall }}>{FormatMoney(variant.price_final, precisions)} {currency}</p>
                                    {show_prices_dph == true ?
                                        <>
                                            <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{FormatMoneyDPH(variant.price_final, variant.dph == -1 ? props.locale.dph : variant.dph, precisions)} {currency} {lang.with_dph}</p>
                                        </>
                                        : null}
                                </>
                                : null}
                        </div>
                        <div style={{ ...styles.Block, width: '15%', height: rowVariantHeight }}>
                            {props.stocks == true ?
                                <p style={{ ...styles.TextTiny }}>{FormatPieces(variant.stock_quantity)} {variant.unit}</p>
                                : null}
                        </div>
                    </div>
                </Paper>
            </div>
        )
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.product_select}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth, height: menuHeight }}>
                        <div style={{ ...styles.BlockLeft, width: '25%', height: menuHeight }}>
                            <div style={{ ...styles.BlockRow }}>
                                {props.filters == true ?
                                    <div style={{ ...styles.Block, width: 30, paddingTop: 3, marginRight: 5 }}>
                                        <MenuButton id={0} icon={faFilter} backgroundColor={'#00000000'} color={color.dark_gray} label={lang.user_new} menuItems={filterMenu} theme={props.theme} func={FilterMenuResult.bind()} />
                                    </div>
                                    : null}
                                <div style={{ ...styles.Block, width: '75%' }}>
                                    <TextInput inputRef={inputRef} search theme={props.theme} width={'100%'} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                </div>
                                <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                    <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                </div>
                            </div>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: '75%', height: menuHeight }}>
                            <div style={{ ...styles.BlockRowRaw, height: menuHeight }}>
                                {props.orderTypes == true ?
                                    <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginRight: 10 }}>
                                        <Button onClick={() => setShowOrderTypes(true)} style={{ ...styles.ButtonOutlined, width: filterButtons + 66, fontSize: global.font_tiny }}>
                                            <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faLayerGroup} />
                                            {lang.order_types}
                                        </Button>
                                    </div>
                                    : null}
                                <div style={{ ...styles.Block, width: undefined, height: menuHeight }}>
                                    <Button onClick={() => setShowCategorySelect(true)} style={{ ...styles.ButtonOutlined, width: filterButtons, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faFolderTree} />
                                        {lang.product_category}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                    <Button onClick={() => setShowGroup(true)} style={{ ...styles.ButtonOutlined, width: filterButtons, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faLayerGroup} />
                                        {lang.product_group}
                                    </Button>
                                </div>
                                {props.system.search_manufacturers == true ?
                                    <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                        <Button onClick={() => setShowManufactureSelect(true)} style={{ ...styles.ButtonOutlined, width: filterButtons, fontSize: global.font_tiny }}>
                                            <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                            {lang.manufacturer}
                                        </Button>
                                    </div>
                                    : null}
                                {props.system.search_vendors == true ?
                                    <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                        <Button onClick={() => setShowVendorSelect(true)} style={{ ...styles.ButtonOutlined, width: filterButtons, fontSize: global.font_tiny }}>
                                            <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                            {lang.vendor}
                                        </Button>
                                    </div>
                                    : null}
                                <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                    <IconButton onClick={() => setShowFlagsSelect(true)} style={{ ...styles.ButtonOutlined, width: 36, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 14 }} icon={faFlag} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: undefined, height: menuHeight, marginLeft: 10 }}>
                                    {props.organization.currencies.length > 1 && props.countrySelect == true ?
                                        <CountrySelect tiny radius width={70} countries={props.organization.currencies} background={color.white} value={props.locale} theme={props.theme} func={(item) => ChangeCountry(item)} />
                                        : null}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockLeft, width: viewWidth, height: filterHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {filterEnabled == true ?
                                <Chip size='medium' style={{ backgroundColor: color.xxgray, marginRight: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.products_filter_enabled}</p>} onDelete={() => FiltersReset(1)}></Chip>
                                : null}
                            {filterDisabled == true ?
                                <Chip size='medium' style={{ backgroundColor: color.xxgray, marginRight: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.products_filter_unabled}</p>} onDelete={() => FiltersReset(2)}></Chip>
                                : null}
                            {filterPublished == true ?
                                <Chip size='medium' style={{ backgroundColor: color.xxgray, marginRight: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.products_filter_published}</p>} onDelete={() => FiltersReset(3)}></Chip>
                                : null}
                            {filterNotPublished == true ?
                                <Chip size='medium' style={{ backgroundColor: color.xxgray, marginRight: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.products_filter_not_published}</p>} onDelete={() => FiltersReset(4)}></Chip>
                                : null}
                            {filterZero == true ?
                                <Chip size='medium' style={{ backgroundColor: color.xxgray, marginRight: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.stock_zero_status}</p>} onDelete={() => FiltersReset(11)}></Chip>
                                : null}
                            {filterSmaller > -1 ?
                                <Chip size='medium' style={{ backgroundColor: color.xxgray, marginRight: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.stock_smaller_status} {filterSmaller}</p>} onDelete={() => FiltersReset(12)}></Chip>
                                : null}
                            {filterBigger > -1 ?
                                <Chip size='medium' style={{ backgroundColor: color.xxgray, marginRight: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.stock_bigger_status} {filterBigger}</p>} onDelete={() => FiltersReset(14)}></Chip>
                                : null}

                            {orderTypeID > 0 ?
                                <Chip size='medium' variant="outlined" style={{ marginRight: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray, fontWeight: '600' }}>{orderType}</p>} ></Chip>
                                : null}
                            {categoryID > 0 ?
                                <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{category}</p>} onDelete={() => CategoryReset(0)}></Chip>
                                : null}
                            {groupID > 0 ?
                                <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{group}</p>} onDelete={() => ResetGroup(0)}></Chip>
                                : null}
                            {manufactureID > 0 ?
                                <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{manufacture}</p>} onDelete={() => ResetManufacture(0)}></Chip>
                                : null}
                            {vendorID > 0 ?
                                <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{vendor}</p>} onDelete={() => ResetVendor(0)}></Chip>
                                : null}
                            {flagID > 0 ?
                                <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{flag}</p>} onDelete={() => ResetFlag(0)}></Chip>
                                : null}

                        </div>
                    </div>

                    {props.info != undefined ?
                        <div style={{ ...styles.BlockCenter, width: viewWidth, height: infoHeight }}>
                            <div style={{ ...styles.BlockRowRaw, backgroundColor: color.user_highlight, borderRadius: (infoHeight - 16) / 2 }}>
                                <div style={{ ...styles.BlockLeft, width: undefined, height: infoHeight - 16 }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 5, marginLeft: 20 }} icon={faInfoCircle} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: undefined, height: infoHeight - 16 }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600', marginRight: 20 }}>{props.info}</p>
                                </div>
                            </div>
                        </div>
                        : null}

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight - filterHeight - infoHeight - bottomHeight, backgroundColor: color.back, paddingTop: 10, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start_dialog_1'} style={{ ...styles.Block, width: viewWidth }}>

                            {props.select_only == undefined ?
                                <div style={{ ...styles.BlockRow, width: '100%', border: color.border }}>
                                    <div style={{ ...styles.Block, width: 40, height: tableLabelHeight }}>
                                        {props.hide_multiple != true ?
                                            <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                            : null}
                                    </div>

                                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                                        <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableLabelHeight }}>
                                        </div>

                                        <div style={{ ...styles.BlockRow, width: '65%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            <div style={{ ...styles.BlockLeft, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering2} theme={props.theme} func={MenuSortResult2.bind()} />
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: '20%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            <div style={{ ...styles.BlockLeft, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{props.stock != undefined ? lang.product_quantity_stock_ : props.price == true ? lang.product_price : ''}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                {props.stock != undefined ?
                                                    <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                    : null}
                                                {props.price == true ?
                                                    <MenuButton id={3} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                    : null}

                                            </div>
                                        </div>
                                        <div style={{ ...styles.Block, width: '15%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{props.stocks == true ? lang.product_quantity_stock_ : ''}</p>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                <ProductRow key={item.id} item={item} />
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* PAGINATION */}
                            <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                <div style={{ ...styles.Block, width: 200 }}></div>
                                <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                    {pages > 1 ?
                                        <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                        : null}
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                            <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                            <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div >

                    </div>
                    <div style={{ ...styles.Block, height: bottomHeight, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        <Button onClick={() => SelectClose()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.ok}</Button>
                    </div>
                </div >

                {enterSmallerValue == true ?
                    <DialogEnterNumber value={10} min={0} title={lang.stock_smaller_status_label} icon={faQuestion} button={lang.ok} theme={props.theme} radius={props.radius} lang={props.lang} func={SmallerResult.bind(this)} />
                    : null}

                {enterBiggerValue == true ?
                    <DialogEnterNumber value={0} min={0} title={lang.stock_bigger_status_label} icon={faQuestion} button={lang.ok} theme={props.theme} radius={props.radius} lang={props.lang} func={BiggerResult.bind(this)} />
                    : null}

                {showOrderTypes == true ?
                    <OrderTypeSelect hideClose module_id={module_id} item={itemSelected} organization={props.organization} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={OrderTypeResult.bind()} />
                    : null}

                {showCategorySelect == true ?
                    <ProductCategorySelect module_id={module_id} item={itemSelected} organization={props.organization} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CategoryResult.bind()} />
                    : null}

                {showManufactureSelect == true ?
                    <ManufacturerSelect module_id={module_id} item={itemSelected} organization={props.organization} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ManufactureResult.bind()} />
                    : null}

                {showFlagsSelect == true ?
                    <ProductFlagsSelect module_id={module_id} item={itemSelected} organization={props.organization} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={FlagResult.bind()} />
                    : null}

                {showVendorSelect == true ?
                    <VendorSelect module_id={module_id} item={itemSelected} organization={props.organization} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={VendorResult.bind()} />
                    : null}

                {showGroup == true ?
                    <ProductsGroupSelect organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={GroupResult.bind()} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}


