/*
*
* =================================================================================================================
* USERS - zoznam užívateľov aplikácie pre danú organizáciu

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { ButtonNew, Colors, DialogDates, DialogImagePreview, DialogInfo, DialogLabel, DialogLogin, DialogTabs, DialogVideo, DialogYesNo, EmployeeSelect, EmptyList, FormError, FormLabel, FormNote, FormPermissions, FormSpace, FormText, FormYesNo, Icon, Line, Loading, Menu, MenuTitle, MenuTop, SelectInput, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, MenuItem, Pagination, Paper, Tab, Tabs, rgbToHex, setRef, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAsterisk, faCheckCircle, faFolderTree, faInfoCircle, faKey, faLayerGroup, faList, faPen, faPlug, faPlus, faPlusCircle, faQuestion, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetTodayYear } from './functions_date';
import { BinaryToPermission, Debug, GetPages, PermissionToBinary } from './functions';
import { GetNodeChilds, TreeNodes } from './nodes';
import { UsersSettings } from './users_settings';
import { red } from '@mui/material/colors';
import { UsersGroups } from './users_groups';
import { SettingsPermissions } from './settings_permissions';

export const Users = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [roleEditing, setRoleEditing] = useState(false);

    // DIALOGS
    const [showRole, setShowRole] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 100;

    const tabs = [
        { id: 0, enabled: true, label: lang.users_list },
        { id: 1, enabled: true, label: lang.users_groups },
        { id: 2, enabled: props.user.super_admin == false ? false : true, label: lang.users_roles },
        { id: 3, enabled: props.user.super_admin == false ? false : true, label: lang.permissions }
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            //db_get(0, false);

            running = true;
        }

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }


    const Close = () => {
        Press(0, 0);
    }


    const ChangeTab = (id) => {
        setTabIndex(id);
    }


    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faUsers} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.Block, height: tabHeight }}>
                        <DialogTabs value={tabIndex} items={tabs} width={220} theme={props.theme} func={(id) => ChangeTab(id)} />
                    </div>
                    {tabIndex == 0 ?
                        // *********************************************************************************************************
                        // USERS
                        // *********************************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <UsersSettings remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} system={props.system} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} />
                        </div>
                        : null}

                    {tabIndex == 1 ?
                        // *********************************************************************************************************
                        // SKUPINY
                        // *********************************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <UsersGroups remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} system={props.system} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} />
                        </div>
                        : null}

                    {tabIndex == 2 ?
                        // *********************************************************************************************************
                        // POZICIE
                        // *********************************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Roles remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} system={props.system} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} />
                        </div>
                        : null}

                    {tabIndex == 3 ?
                        // *********************************************************************************************************
                        // POVOLENIA
                        // *********************************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <SettingsPermissions remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} system={props.system} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} />
                        </div>
                        : null}
                </div>
            </div>

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}





export const Roles = (props) => {
    /*
            // Pozície

            USAGE:
            <Roles theme={props.theme} lang={props.lang} radius={props.radius} />

            PARAMS:
    */

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [settingsID, setSettingsID] = useState(0);
    const [roles, setRoles] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [itemEditing, setItemEditing] = useState(false);
    const [useRoles, setUseRoles] = useState(false);

    // DIALOGS
    const [showRole, setShowRole] = useState(false);

    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 50;

    let { func } = props;

    useEffect(() => {

        db_settings();

        return () => {
        };

    }, []);

    const db_settings = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.settings != false) {
                    setSettingsID(json.settings.id);
                    setRoles(JSON.parse(json.settings.roles));
                    setUseRoles(json.settings.use_roles == false ? false : true);
                } else {
                    setSettingsID(0);
                    setRoles([{ id: 1, enabled: true, label: lang.node_root, parent_id: 0, note: '', shared: false }]);
                    setUseRoles(true);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_settings_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const NodesResult = (typ, nodes, tree) => {
        if (typ == 1) {
            // ULOZIT
            let data = {
                id: settingsID,
                organization_id: props.organization.id,
                enabled: true,
                roles: JSON.stringify(nodes),
                roles_tree: JSON.stringify(tree),
            }
            setEditMode(false);
            setRoles(nodes);
            db_settings_update(data);
        }
        if (typ == 2) {
            // OZNACENY NODE
            if (nodes.id > 1) {
                setItemEditing(nodes);
                setShowRole(true);
            }
        }
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }


    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const RoleResult = (typ, items) => {
        setShowRole(false);

        if (typ == true) {
            var tmp = roles.find(x => x.id == itemEditing.id);
            if (tmp != undefined) {
                tmp.label = items.label;
                tmp.note = items.note;
                tmp.shared = items.shared;

            }
            let data = {
                id: settingsID,
                organization_id: props.organization.id,
                enabled: true,
                roles: JSON.stringify(roles),
            }
            setRedraw(!redraw);

            db_settings_update(data);
        }
    }
    const ChangeUseRoles = (value) => {
        setUseRoles(value);

        let data = {
            id: settingsID,
            use_roles: value
        }
        props.system.use_roles = value;
        db_settings_update(data);

    }


    return (
        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
            <div style={{ ...styles.Block, width: '96%' }}>

                <DialogLabel label={lang.users_roles_} sub_label={lang.users_roles_text} theme={props.theme} />

                <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray, marginTop: 10 }}>
                    <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                        {editMode == true ?
                            <p style={{ ...styles.TextTiny }}>{lang.node_editing}</p>
                            :
                            <FormYesNo value={useRoles} editing={true} title={lang.use_roles} column1={undefined} column2={200} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => ChangeUseRoles(txt)} />
                        }
                    </div>
                    <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                        {editMode == false ?
                            <ButtonNew label={lang.edit} icon={faPen} theme={props.theme} func={() => setEditMode(!editMode)} />
                            :
                            <Button onClick={() => setEditMode(false)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 180 }}>
                                <FontAwesomeIcon style={{ width: 10, marginRight: 10 }} icon={faXmark} />
                                {lang.editing_finish}
                            </Button>
                        }
                    </div>
                </div>

                {useRoles ?
                    <TreeNodes nodes={roles} title={lang.users_roles} editMode={editMode} showDisabled={true} allowSelectRoot={true} allowDelete={true} deleteMode={true} organization={props.organization} user={props.user} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={NodesResult.bind(this)} />
                    : null}

            </div>

            {showRole ?
                <DialogRole itemEditing={itemEditing} organization={props.organization} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={RoleResult.bind()} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                : null}

        </div>
    );
}


export const DialogRole = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    const column1 = 150;
    const column2 = 450;
    const offset = 20;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(true);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [label, setLabel] = useState(props.itemEditing.label);
    const [note, setNote] = useState(props.itemEditing.note);
    const [shared, setShared] = useState(props.itemEditing.shared == false ? false : true);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= column1 + column2 + (4 * offset) ? column1 + column2 + (4 * offset) : width;
    const dialogHeight = height >= 400 ? 400 : height;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            //db_get();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_get', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.organizations,
                    item_id: props.organization.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    model_id: global.model.organizations,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Save = () => {
        let data = {
            label: label,
            note: note,
            shared: shared
        }
        Press(1, data);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faFolderTree} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.node_edit}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        <FormText value={label} editing={editing} title={lang.node_name_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setLabel(txt)} />
                        <FormText value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                        <FormSpace />
                        <FormYesNo value={shared} editing={editing} title={lang.node_share} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setShared(txt)} />
                        <FormNote title={lang.node_share_text} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} />

                        <div style={{ ...styles.Block, paddingTop: 10 }}>
                            <FormError theme={props.theme} error={error} small />

                            <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.cancel}</Button>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


