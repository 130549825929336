import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { DialogAgreements, DialogInfo, DialogLogin, DialogPasswordChange, DialogWarningYesNo, DialogYesNo, FormError, Loading, Menu, MenuTitle, MenuTop, ShowError, ShowOK, ShowTokenError, TextInput } from './items';
import { styles } from './styles';
import { Button, Checkbox, Fade, FormControlLabel, IconButton, MenuItem, Paper, Radio, RadioGroup, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faCog, faExclamationTriangle, faEye, faInfoCircle, faList, faPen, faPlus, faQuestion, faSave, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import './items.css';
import { PinCode } from './pin_code';
import { GetMinutesFromHours } from './functions_date';
import { ConvertToPrice, GetLanguage, GetVersion, IsEmpty, ResetLogin, nonEmail, notNumber, notTime } from './functions';


export const Settings = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [loading, setLoading] = useState(true);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [index, setIndex] = useState(-1);
    const [showLogout, setShowLogout] = useState(false);
    const [overID, setOverID] = useState(-1);

    const [error, setError] = useState(false);
    const [edit, setEdit] = useState(false);

    let { func } = props;
    var lang = props.lang;

    const menu_width = 200;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();
        }
    }, [])


    const db_get = async () => {
        try {
            const response = await fetch(
                global.db_url + 'user', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.user.id,
                    token: props.token
                })
            })

            const json = await response.json();

            setLoading(false);
            if (json.ok > 0) {
            } else {
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    const Press = () => {
        func();
    }

    const Close = () => {
        Press();
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Logout = (value) => {
        setShowLogout(false);
        if (value == true) {
            ResetLogin();
            navigate('/logout');
        }
    }

    const Save = (index) => {
       
    }

    const ChangeIndex = (index) => {
        setIndex(index);
       
        GoToStart();
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faCog} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '85%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 180, height: global.dialog_bar_height }}>
                    <p style={{ ...styles.TextTiny }}>{lang.version} {GetVersion(props.version)}</p>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                <div>
                    {props.user != false ? props.user.typ == global.account_salon ?
                        <div style={{ ...styles.BlockCenter, width: menu_width, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettings, backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_basic}</Button>
                            <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettings, backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_company}</Button>
                            <Button onClick={() => ChangeIndex(2)} style={{ ...styles.ButtonSettings, backgroundColor: index == 2 ? global.theme_back : '#00000000', color: index == 2 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.page_eshop}</Button>
                            <Button onClick={() => ChangeIndex(3)} style={{ ...styles.ButtonSettings, backgroundColor: index == 3 ? global.theme_back : '#00000000', color: index == 3 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_calendar}</Button>
                            <Button onClick={() => ChangeIndex(6)} style={{ ...styles.ButtonSettings, backgroundColor: index == 6 ? global.theme_back : '#00000000', color: index == 6 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.bonus_system}</Button>
                            <Button onClick={() => ChangeIndex(5)} style={{ ...styles.ButtonSettings, backgroundColor: index == 5 ? global.theme_back : '#00000000', color: index == 5 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.employees}</Button>
                            <Button onClick={() => ChangeIndex(7)} style={{ ...styles.ButtonSettings, backgroundColor: index == 7 ? global.theme_back : '#00000000', color: index == 7 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.agent}</Button>
                            <Button onClick={() => ChangeIndex(4)} style={{ ...styles.ButtonSettings, backgroundColor: index == 4 ? global.theme_back : '#00000000', color: index == 4 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_system}</Button>
                            <Button onClick={() => ChangeIndex(8)} style={{ ...styles.ButtonSettings, backgroundColor: index == 8 ? global.theme_back : '#00000000', color: index == 8 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.account_cancel}</Button>
                            <Button onClick={() => ChangeIndex(9)} style={{ ...styles.ButtonSettings, backgroundColor: index == 9 ? global.theme_back : '#00000000', color: index == 9 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.gdpr_}</Button>
                            <Button onClick={() => ChangeIndex(99)} style={{ ...styles.ButtonSettings, backgroundColor: index == 99 ? global.theme_back : '#00000000', color: index == 99 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_logout}</Button>
                        </div>
                        : null : null}

                    {props.user != false ? props.user.typ == global.account_medical ?
                        <div style={{ ...styles.BlockCenter, width: menu_width, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettings, backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_basic}</Button>
                            <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettings, backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_company}</Button>
                            <Button onClick={() => ChangeIndex(7)} style={{ ...styles.ButtonSettings, backgroundColor: index == 7 ? global.theme_back : '#00000000', color: index == 7 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.agent}</Button>
                            <Button onClick={() => ChangeIndex(8)} style={{ ...styles.ButtonSettings, backgroundColor: index == 8 ? global.theme_back : '#00000000', color: index == 8 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.account_cancel}</Button>
                            <Button onClick={() => ChangeIndex(9)} style={{ ...styles.ButtonSettings, backgroundColor: index == 9 ? global.theme_back : '#00000000', color: index == 9 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.gdpr_}</Button>
                            <Button onClick={() => ChangeIndex(99)} style={{ ...styles.ButtonSettings, backgroundColor: index == 99 ? global.theme_back : '#00000000', color: index == 99 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_logout}</Button>
                        </div>
                        : null : null}

                    {props.user != false ? props.user.typ == global.account_agent ?
                        <div style={{ ...styles.BlockCenter, width: menu_width, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <Button onClick={() => ChangeIndex(20)} style={{ ...styles.ButtonSettings, backgroundColor: index == 20 ? global.theme_back : '#00000000', color: index == 20 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_basic}</Button>
                            <Button onClick={() => ChangeIndex(99)} style={{ ...styles.ButtonSettings, backgroundColor: index == 99 ? global.theme_back : '#00000000', color: index == 99 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_logout}</Button>
                        </div>
                        : null : null}
                </div>

                <div style={{ ...styles.BlockCenter, width: props.width - menu_width, height: props.height - global.dialog_bar_height, borderBottomRightRadius: props.radius, backgroundColor: global.theme_back, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockCenter, width: props.isSmall ? '94%' : '100%' }}>
                        <div id={'id_start'}></div>
                        {index == 0 ?
                            // *********************************************************************************************************
                            // ZAKLADNE UDAJE
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, paddingTop: 10, paddingBottom: props.isSmall ? global.keyboard_padding_small : global.keyboard_padding }}>
                            </div>
                            : null}

                        {index == 99 ?
                            // *********************************************************************************************************
                            // ODHLASIŤ SA
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, paddingTop: 10 }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.logout_title}</p>
                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    <Button onClick={() => setShowLogout(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: 40 }}>{lang.logout}</Button>
                                </div>
                            </div>
                            : null}

                    </div>
                </div>
            </div >

        </Paper >
    );
}


