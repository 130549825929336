/*
*
* =================================================================================================================
* KATEGORIE

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { Colors, DialogImagePreview, DialogInfo, DialogLogin, DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormSelect, FormAutoComplete, FormNote } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Fade, IconButton, Pagination, Paper, Skeleton, Stack, Tooltip, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faArrowDown, faArrowUp, faCheckCircle, faCircle, faClose, faDollar, faFilter, faHome, faInfoCircle, faLayerGroup, faList, faMinus, faPlus, faPlusCircle, faQuestion, faSliders, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetDatumTime2, GetTodayYear } from './functions_date';
import { CheckPermission, ConvertToPrice, CopyData, Debug, GetLeftPosition, GetPages, IsEmpty } from './functions';
import { Photos } from './photos';
import { GetNodeChilds } from './nodes';
import { red } from '@mui/material/colors';
import { NodeSelect } from './nodes_select';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { WebPageDesigner } from './webpage_designer.js';

export const ProductCategories = (props) => {

    let module_id = global.model.products_categories;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'products_categories';
    let marginOffset = 30;
    let border = '1px solid ' + color.middle_gray;
    let coeficientEnabled = props.system.price_coeficient_enabled == false ? false : true;
    let coeficient = props.system.price_coeficient;
    let precisions = props.system.precisions == 0 ? 2 : 3;


    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'label'
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        //{ id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'label', label: lang.vendor_name, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [parentID, setParentID] = useState(0);

    // Zmena poradia
    const [changingOrder, setChangingOrder] = useState(false);
    const [orderItems, setOrderItems] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'label', label: lang.product_category_name, default: true },
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 58;
    const rowWidth = 450;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(defaultFilter);

            running = true;
        }

    }, []);


    const db_get = async (filter) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products_categories', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    filter: filter
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    tmp.forEach(item => {
                        item.hidden = true;
                    })
                }

                setItems(tmp);

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.vendors,
                    filename: lang.product_categories, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_reorder = async (items) => {

        try {
            const response = await fetch(
                global.db_url + 'reorder_items', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.products_categories,
                    items: items
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }

        } catch (error) {
            console.log(error);

        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = defaultFilter;

        filter.ordering = ordering;
        filter.order_column = orderColumn;

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = columns;
            db_get(filter);
        }
        if (txt == '') {
            db_get(filter);
        }

    }

    const AddNew = (parent_id) => {
        setParentID(parent_id);
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (typ, item) => {
        setShowDialog(false);

        if (typ == true) {
            if (itemSelected == false) {
                item.hidden = false;
                items.push(item);
            } else {
                CopyData(item, itemSelected, ['id']);
            }
            setRedraw(!redraw);
        }
    }

    const ChangeEnabled = (item, value) => {
        // ENABLED POLOZKY ZMENENY

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: item.id,
            action: value == true ? global.logs.enabled : global.logs.disabled
        };

        let data = {
            id: item.id,
            enabled: value,
            logs: log
        }
        db_update(data);
    }

    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }


    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    function HideItem(id) {
        var tmp = items.find(x => x.id == id);
        tmp.hidden = !tmp.hidden;
        setRedraw(!redraw);
    }

    const HideAll = () => {
        items.forEach(item => {
            item.hidden = true;
        });

        setRedraw(!redraw);
    }

    const ChangeOrder = (items) => {
        Debug(items);
        setOrderItems(items);
        setChangingOrder(true);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (result.destination) {
            const tmp = reorder(orderItems, result.source.index, result.destination.index);
            var n = 1;
            var items_ = [];
            tmp.forEach(item => {
                item.priority = n;

                let data = {
                    id: item.id,
                    priority: n
                }
                items_.push(data);
                n++
            });

            setOrderItems(tmp);
            db_reorder(items_);
        }
    }

    /*
    *
    * =================================================================================================================
    * ROW - RENDER
    * =================================================================================================================
    *
    */

    const ItemRow = (data) => {

        let items = data.items != false ? data.items.filter(x => x.parent_id == data.id).sort((a, b) => {
            if (parseInt(a.priority) > parseInt(b.priority)) {
                return 1;
            } else {
                return -1;
            }
        }) : false;

        useEffect(() => {
            return () => {
            };

        }, []);

        function IsEmpty(id) {
            let item = data.items.filter(x => x.parent_id === id);
            return item.length == 0 ? true : false;
        }

        return (
            items != false ?
                <>
                    <div style={{ ...styles.Block, borderLeft: border, width: rowWidth, marginLeft: items[0].parent_id == 0 ? marginOffset / 2 : marginOffset + 25, height: 48, justifyContent: 'flex-end' }}>
                        <Button onClick={() => ChangeOrder(items)} style={{ ...styles.ButtonOutlinedLight, width: 180, height: 26, marginLeft: items[0].parent_id == 0 ? marginOffset : marginOffset + 25 }}>
                            <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faAngleDown} />
                            {lang.priority_change}
                        </Button>
                    </div>
                    {items.map((item, index) => (
                        <div key={item.id} style={{ ...styles.BlockLeft, borderLeft: index < items.length - 1 ? border : '1px solid #00000000', marginLeft: item.parent_id == 0 ? marginOffset / 2 : marginOffset + 25 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, width: 0 }}>
                                    <div style={{ ...styles.Block, width: 1, height: (rowHeight + 10) / 2, borderLeft: border }}></div>
                                    <div style={{ ...styles.Block, width: 1, height: (rowHeight + 10) / 2, borderLeft: index < items.length - 1 ? border : '1px solid #00000000' }}></div>
                                </div>

                                <div style={{ ...styles.Block, width: marginOffset, height: rowHeight + 10 }}>
                                    <div style={{ ...styles.Block, width: marginOffset, height: 1, borderTop: border }}></div>
                                </div>
                                <div style={{ ...styles.Block, height: rowHeight + 10 }}>
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            width: rowWidth,
                                            backgroundColor: item.enabled == false || data.disabled == true ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            height: rowHeight,
                                            cursor: 'pointer',
                                            borderLeft: item.enabled == false || data.disabled == true ? color.disabled_border : '',
                                        }}
                                    //onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                                            <div style={{ ...styles.Block, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                                {IsEmpty(item.id) == false ?
                                                    <IconButton onClick={() => HideItem(item.id)} style={{ ...styles.ButtonIconThemed }}>
                                                        <FontAwesomeIcon style={{ width: 10, color: color.black }} icon={item.hidden == true ? faAngleRight : faAngleDown} />
                                                    </IconButton>
                                                    : null}
                                            </div>
                                            <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '91%' }}>
                                                {/* DATA */}
                                                <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <Avatar picture image={item.avatar} size={rowHeight - 14} theme={props.theme} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '90%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.label}</p>
                                                    {item.price_coeficient_enabled == true ?
                                                        <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{lang.system_price_coeficient}: {ConvertToPrice(item.price_coeficient, precisions)}</p>
                                                        : null}
                                                </div>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, minHeight: rowHeight, justifyContent: 'center' }}>
                                                {item.price_coeficient_enabled == true ?
                                                    <div style={{ ...styles.Block, width: 20, height: 20, backgroundColor: color.light_red, borderRadius: 10 }}>
                                                        <FontAwesomeIcon style={{ height: 14, color: color.white }} icon={faDollar} />
                                                    </div>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                                {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                    <Tooltip title={lang.product_subcategory_add}>
                                                        <IconButton onClick={() => AddNew(item.id)} style={{ ...styles.ButtonIcon }}>
                                                            <FontAwesomeIcon style={{ width: 10 }} icon={faPlus} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                                {/* ENABLED */}
                                                {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                    <SwitchEnable enabled={item.enabled == false ? false : true} theme={props.theme} func={(value) => ChangeEnabled(item, value)} />
                                                    : null}
                                            </div>
                                        </div>
                                    </Paper >
                                </div>
                            </div>
                            {index == items.length - 1 && IsEmpty(item.id) == true ?
                                <FormSpace />
                                : null}
                            {IsEmpty(item.id) == false && item.hidden == false ?
                                <ItemRow items={data.items} id={item.id} disabled={item.enabled == false || data.disabled == true ? true : false}></ItemRow>
                                : null}

                        </div>
                    ))
                    }
                </>
                : null


        )
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    {global.appstore == false ?
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                        : null}
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll', overflowX: 'hidden' }} className='container'>
                    <div id={'id_start'} style={{ ...styles.BlockCenter, maxWidth: global.max_width, width: '100%', marginTop: 10 }}>
                        <div style={{ ...styles.BlockCenter, width: '96%' }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <TextInput search theme={props.theme} width={300} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                        <Button onClick={() => AddNew(0)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.product_category_new}
                                        </Button>
                                        : null}
                                </div>
                            </div>

                            <div style={{ ...styles.BlockLeft, marginTop: 10, paddingTop: 10, paddingBottom: global.list_padding, borderTop: color.border }}>

                                {/* ****************************************************************************************************************
                                        ITEMS
                                **************************************************************************************************************** */}
                                {changingOrder == true ?
                                    <div style={{ ...styles.BlockLeft }}>
                                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.priority_items_change}</p>
                                        </div>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="droppable">
                                                {(provided) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        style={{ ...styles.BlockLeft }}
                                                    >
                                                        {orderItems != false ?
                                                            orderItems.map((item, index) => (
                                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <Paper
                                                                                key={item.id}
                                                                                elevation={item.id == overID ? 4 : 1}
                                                                                style={{
                                                                                    ...styles.Block,
                                                                                    width: props.width > global.max_width ? global.max_width - 40 : props.width - 40,
                                                                                    backgroundColor: color.white,
                                                                                    borderRadius: global.items_radius,
                                                                                    height: rowHeight,
                                                                                    cursor: 'pointer',
                                                                                    marginTop: 5, marginBottom: 5
                                                                                }}
                                                                            //onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                                            >
                                                                                <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                                                    {/* DATA */}
                                                                                    <div style={{ ...styles.Block, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                                                                        <p style={{ ...styles.TextTiny }}>{index + 1}.</p>
                                                                                    </div>
                                                                                    <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                                                        <Avatar picture image={item.avatar} size={rowHeight - 14} theme={props.theme} />
                                                                                    </div>
                                                                                    <div style={{ ...styles.BlockLeft, width: '85%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.label}</p>
                                                                                        {item.price_coeficient_enabled == true ?
                                                                                            <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{lang.system_price_coeficient}: {ConvertToPrice(item.price_coeficient, precisions)}</p>
                                                                                            : null}
                                                                                    </div>
                                                                                </div>
                                                                            </Paper>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )) : null}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext >

                                        <div style={{ ...styles.Block }}>
                                            <Button onClick={() => setChangingOrder(false)} style={{ ...styles.ButtonOutlined, marginTop: 40 }}>
                                                {lang.editing_finish}
                                            </Button>
                                        </div>

                                    </div>
                                    :
                                    search.trim() == '' ?
                                        <div style={{ ...styles.BlockLeft }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.Block, width: marginOffset, height: marginOffset, backgroundColor: color.middle_gray, borderRadius: marginOffset / 2 }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.white }} icon={faHome} />
                                                </div>
                                                <div style={{ ...styles.Block, width: marginOffset / 2, height: marginOffset }}>
                                                    <div style={{ ...styles.Block, width: marginOffset / 2, height: 1, borderTop: border }}></div>
                                                </div>
                                                <Tooltip title={lang.close_all}>
                                                    <IconButton onClick={() => HideAll()} style={{ ...styles.ButtonIcon, height: marginOffset, width: marginOffset }}>
                                                        <FontAwesomeIcon style={{ width: 12, color: color.dark }} icon={faMinus} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>

                                            <ItemRow items={items} id={0} disabled={false} func={(items) => ChangeOrder(items)}></ItemRow>
                                        </div>
                                        :
                                        items != false ? items.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    width: rowWidth,
                                                    backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    height: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '95%' }}>
                                                        {/* DATA */}
                                                        <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                            <Avatar picture image={item.avatar} size={rowHeight - 14} theme={props.theme} />
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '90%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.label}</p>
                                                            {item.price_coeficient_enabled == true ?
                                                                <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{lang.system_price_coeficient}: {ConvertToPrice(item.price_coeficient, precisions)}</p>
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        {item.price_coeficient_enabled == true ?
                                                            <div style={{ ...styles.Block, width: 20, height: 20, backgroundColor: color.light_red, borderRadius: 10 }}>
                                                                <FontAwesomeIcon style={{ height: 14, color: color.white }} icon={faDollar} />
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                            <Tooltip title={lang.product_subcategory_add}>
                                                                <IconButton onClick={() => AddNew(item.id)} style={{ ...styles.ButtonIcon }}>
                                                                    <FontAwesomeIcon style={{ width: 10 }} icon={faPlus} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            : null}
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        {/* ENABLED */}
                                                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                            <SwitchEnable enabled={item.enabled == false ? false : true} theme={props.theme} func={(value) => ChangeEnabled(item, value)} />
                                                            : null}
                                                    </div>
                                                </div>
                                            </Paper >
                                        )) :
                                            <EmptyList row small lang={lang} />
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {
                showExport == true ?
                    <DialogExport remote={props.remote} remoteMobile={props.remoteMobile} rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                    : null
            }

            {
                showDialog == true ?
                    <ProductCategory remote={props.remote} remoteMobile={props.remoteMobile} module_id={module_id} item={itemSelected} parentID={parentID} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null
            }

        </Paper >
    );
}



/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const ProductCategory = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let currency = props.locale.currency;
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let coeficientEnabled = props.system.price_coeficient_enabled == false ? false : true;
    let coeficient = props.system.price_coeficient;
    let category_id = props.item == false ? 0 : props.item.id;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [enabled, setEnabled] = useState(true);
    const [parentID, setParentID] = useState(props.parentID);
    const [label, setLabel] = useState('');
    const [portalLabels, setPortalLabels] = useState(false);
    const [note, setNote] = useState('');
    const [avatar, setAvatar] = useState('');
    const [priority, setPriority] = useState(0);
    const [priceCoeficientEnabled, setPriceCoeficientEnabled] = useState(false);
    const [priceCoeficient, setPriceCoeficient] = useState('1');
    const [categories, setCategories] = useState(false);
    const [pageID, setPageID] = useState(0);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showPage, setShowPage] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    // CONSTANTS
    const tabHeight = props.item == false ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 210;
    const column2 = 340;

    const tabs = [
        { id: 0, enabled: true, label: lang.setting },
        { id: 2, enabled: props.system.eshop_enabled == true ? true : false, label: lang.eshop },
        { id: 1, enabled: true, label: lang.history },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            } else {
                initLabels();
            }

            db_get_data();
            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products_category', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products_category_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item == false ? 0 : props.item.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    var categories = json.categories;

                    if (props.items != false) {
                        let data = (GetNodeChilds(categories, props.item.id, false));
                        categories.forEach(item => {
                            if (data.includes(item.id)) {
                                item.enabled = false;
                            } else {
                                item.enabled = true;
                            }
                        });
                        categories = categories.filter(x => x.enabled == true);
                    }

                    setCategories(categories);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: props.item.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (data.id == 0) {
                    storeData.id = json.item_id;
                }
                if (data.page_id != undefined) {
                    setPageID(data.page_id);
                } else {
                    func(true, storeData);
                }
                //setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false, false);
    }

    const initLabels = () => {
        var array = [];
        props.organization.currencies.forEach(item => {
            let data = {
                lang: item.state,
                label: ''
            };
            array.push(data);
        });
        setPortalLabels(array);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setParentID(item.parent_id);
        setLabel(item.label);
        if (item.portal_labels == false) {
            initLabels();
        } else {
            setPortalLabels(JSON.parse(item.portal_labels));
        }
        setNote(item.note);
        setPriceCoeficientEnabled(item.price_coeficient_enabled == false ? false : true);
        setPriceCoeficient(item.price_coeficient);
        setAvatar(item.avatar);
        setPriority(item.priority);
        setPageID(item.page_id);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.id = props.item == false ? 0 : props.item.id;
        storeData.enabled = enabled;
        storeData.parent_id = parentID;
        storeData.label = label;
        storeData.portal_labels = portalLabels;
        storeData.note = note;
        storeData.price_coeficient_enabled = priceCoeficientEnabled;
        storeData.priceCoeficient = priceCoeficient;
        storeData.avatar = avatar;
        storeData.priority = priority;
    }

    const Save = () => {
        setError('');
        var error = false;
        error += IsEmpty(label) == true ? 1 : 0;


        if (error == 0) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: props.item == false ? 0 : props.item.id,
                action: props.item == false ? global.logs.created : storeData.enabled != enabled ? enabled == true ? global.logs.enabled : global.logs.disabled : global.logs.modified
            };

            let data = {
                id: props.item == false ? 0 : props.item.id,
                organization_id: props.organization.id,
                enabled: enabled,
                label: label.trim(),
                portal_labels: portalLabels == false ? false : JSON.stringify(portalLabels),
                parent_id: parentID,
                note: note,
                avatar: avatar,
                price_coeficient_enabled: priceCoeficientEnabled,
                price_coeficient: ConvertToPrice(priceCoeficient, precisions),
                //priority: priority,
                logs: log
            };

            if (props.item == false) {
                // TVORBA CISLA PORADIA
            }

            StoreEditing();
            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ShowOKPress = (value) => {
        if (value == true) {
            //func(true, storeData);
        }
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 1) {
            if (logs == false) {
                db_get_logs();
            }
        }
    }


    const AvatarPress = () => {
        setSelectPhoto(true);
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);
        }
    }

    const PageResult = (redraw_, page_id) => {
        if (page_id > 0) {
            let data = {
                id: category_id,
                page_id: page_id
            }
            setPageID(page_id);
            Debug(data);
            db_update(data);

        } else {
            setShowPage(false);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.product_category_new : props.item.label}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    {props.item != false ?
                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs value={tabIndex} items={tabs} width={dialogWidth / (tabs.length)} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {storeData != false ?
                                <div>
                                    <FormSpace />
                                    <div style={{ ...styles.Block }}>
                                        <Avatar editable={editing} picture image={avatar} size={75} theme={props.theme} func={AvatarPress.bind()} />
                                        <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.default_image}</p>
                                    </div>
                                    <Line marginTop={10} theme={props.theme} />
                                    <FormSpace />
                                    <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                    <FormYesNo value={enabled} editing={editing} title={lang.product_category_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                    <FormText require redraw value={label} editing={editing} title={lang.product_category_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setLabel(txt)} />

                                    <FormSpace />
                                    <FormLabel editing={editing} paddingBottom={0} title={lang.customer_portal} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                    <FormNote title={lang.products_category_labels} column1={0} column2={column1 + column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />

                                    {portalLabels != false ? portalLabels.map((item, index) => (
                                        <FormText key={index} require redraw value={item.label} editing={editing} title={lang.product_category_name + ' (' + item.lang + ')'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => item.label = txt} />
                                    )) : null}

                                    <FormSpace />
                                    <FormAutoComplete require value={parentID} items={categories} field={'label'} editing={editing} title={lang.product_category_parent} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setParentID(txt)} />
                                    <FormSpace />
                                    <FormYesNo help_id={14} value={priceCoeficientEnabled} editing={editing} title={lang.system_price_coeficient_enable} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setPriceCoeficientEnabled(value)} />
                                    <FormNote title={lang.system_price_coeficient_category_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    {priceCoeficientEnabled == true ?
                                        <div>
                                            <FormText numeric redraw value={priceCoeficient} editing={editing} title={lang.system_price_coeficient} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPriceCoeficient(txt)} />
                                            <FormNote title={lang.system_price_coeficient_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                        </div>
                                        : null}

                                    <FormSpace />
                                    {/*
                                    <FormText numeric redraw value={priority} editing={editing} title={lang.priority} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPriority(txt)} />
                                    */}
                                    <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                                    <FormSpace />

                                    {props.remote == true && props.remoteMobile == true ?
                                        <FormSpace height={500} />
                                        : null}

                                </div>
                                :
                                <Stack style={{ ...styles.Block }} spacing={1}>
                                    <FormSpace />
                                    <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                </Stack>}
                        </div>
                        : null}

                    {tabIndex == 2 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {category_id > 0 ?
                                <div style={{ ...styles.Block, marginTop: 30 }}>
                                    <p style={{ ...styles.TextXSmall }}><b>{lang.web_page_category_title}</b></p>
                                    <Button onClick={() => setShowPage(true)} style={{ ...styles.ButtonOutlined, width: 220, marginTop: 16 }}>{pageID == 0 ? lang.web_page_category_new : lang.web_page_category}</Button>
                                </div>
                                : null}
                        </div>
                        : null}

                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {tabIndex == 0 && editing == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            {editing == true && props.item != false ?
                                <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                :
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                            }
                        </div>
                    </div>
                </div >

                {showPage == true ?
                    <WebPageDesigner item={pageID == 0 ? false : { id: pageID }} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={PageResult.bind(this)} />
                    : null}

                {selectPhoto == true ?
                    <Photos autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => ShowOKPress(true)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


export const ProductCategorySelect = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = {
        enabled: true,
        ordering: global.order.down,
        order_column: 'priority'
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DATA
    const [value, setValue] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 700 ? 700 : height;

    const menuHeight = 60;
    const rowHeight = 40;
    const viewWidth = '96%';

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter);

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products_categories', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter_);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = defaultFilter;

        if (txt.length > 1) {

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = ['label'];
            db_get(0, filter);
        }
        if (txt == '') {
            db_get(0, filter);
        }

    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        func(true, item)
    }

    const NodeSelectResult = (id) => {
        var tmp = items.find(x => x.id == id);
        if (tmp != undefined) {
            func(true, tmp);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.product_category}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start'} style={{ ...styles.BlockLeft, width: viewWidth }}>

                            {items != false && search == '' ?
                                <NodeSelect nodes={items} theme={props.theme} func={NodeSelectResult.bind(this)} />
                                : null}

                            {search != '' ? itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    onClick={() => SelectItem(item)}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                                        <div style={{ ...styles.BlockLeft, width: '100%', minHeight: rowHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXSmall }}>{item.label}</p>
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                                : null
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div >

                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


