// ###################################################################################
//
// CONDITIONS + STATEMENTS
//
// ###################################################################################

global.datatypes = [
    { id: 0, label: 'Decimal' },
    { id: 1, label: 'Bigint' },
    { id: 2, label: 'String' },
    { id: 3, label: 'Boolean' },
    { id: 4, label: 'Date' },
    { id: 5, label: 'Time' },
    { id: 6, label: 'Datetime' },
    { id: 7, label: 'Array' },
    { id: 8, label: 'Object' },
    { id: 9, label: 'Condition' },
    { id: 10, label: 'Statement' },
]



// ###################################################################################
// exe_id - funkcia volaná operátorom - ako má vyhodnotiť podmienku (variable1 operator[exe_id] variable2)
global.operators = [
    { id: 0, datatype: 0, exe_id: 0, label: '==' },
    { id: 1, datatype: 0, exe_id: 1, label: '>=' },
    { id: 2, datatype: 0, exe_id: 2, label: '<=' },
    { id: 3, datatype: 0, exe_id: 3, label: '>' },
    { id: 4, datatype: 0, exe_id: 4, label: '<' },
    { id: 5, datatype: 0, exe_id: 5, label: '!=' },
    { id: 6, datatype: 9, exe_id: 6, label: 'A', lang: 'cond_and' },
    { id: 7, datatype: 9, exe_id: 7, label: 'Alebo', lang: 'cond_or' },
    { id: 8, datatype: 1, exe_id: 0, label: '==' },
    { id: 9, datatype: 1, exe_id: 1, label: '>=' },
    { id: 10, datatype: 1, exe_id: 2, label: '<=' },
    { id: 11, datatype: 1, exe_id: 3, label: '>' },
    { id: 12, datatype: 1, exe_id: 4, label: '<' },
    { id: 13, datatype: 1, exe_id: 5, label: '!=' },
    { id: 14, datatype: 2, exe_id: 8, label: 'Obsahuje', lang: 'cond_include' },
    { id: 15, datatype: 2, exe_id: 9, label: 'Začína', lang: 'cond_starts' },
    { id: 16, datatype: 2, exe_id: 10, label: 'Končí', lang: 'cond_ends' },
    { id: 17, datatype: 2, exe_id: 0, label: '==' },
    { id: 18, datatype: 2, exe_id: 5, label: '!=' },
    { id: 19, datatype: 3, exe_id: 0, label: '==' },
    { id: 20, datatype: 4, exe_id: 0, label: '==' },
    { id: 21, datatype: 4, exe_id: 1, label: '>=' },
    { id: 22, datatype: 4, exe_id: 2, label: '<=' },
    { id: 23, datatype: 4, exe_id: 3, label: '>' },
    { id: 24, datatype: 4, exe_id: 4, label: '<' },
    { id: 25, datatype: 4, exe_id: 5, label: '!=' },
    { id: 26, datatype: 5, exe_id: 0, label: '==' },
    { id: 27, datatype: 5, exe_id: 5, label: '!=' },
    { id: 28, datatype: 5, exe_id: 1, label: '>=' },
    { id: 29, datatype: 5, exe_id: 2, label: '<=' },
    { id: 30, datatype: 5, exe_id: 3, label: '>' },
    { id: 31, datatype: 5, exe_id: 4, label: '<' },

]



// ###################################################################################
// VARIABLE 1 - premenné pre jednotlivé moduly
/* --------------------------------------------------------------------------------
    lang        -> názov z language
    table_id    -> DB model podla ID
    item_id     -> riadok v DB podla ID
    column         -> názov stĺpca v DB pre daný riadok
-------------------------------------------------------------------------------- */
global.variables = [
    { id: 0, lang: 'cond_product_label', table_id: 0, item_id: 0, column: 'name', datatype: 2 },
    { id: 1, lang: 'cond_date', table_id: 0, item_id: 0, column: 'today_date', datatype: 4 },
    { id: 2, lang: 'cond_enabled', table_id: 0, item_id: 0, column: 'enabled', datatype: 3 },
    { id: 3, lang: 'cond_price', table_id: 0, item_id: 0, column: 'price', datatype: 0 },
    { id: 4, lang: 'cond_time', table_id: 0, item_id: 0, column: 'today_time', datatype: 5 },
    { id: 5, lang: 'cond_stock', table_id: 0, item_id: 0, column: 'quantity_stock', datatype: 1 },
]



// ###################################################################################
// VARIABLE RESULT - výsledok po vyhodnotení podmienky
/* --------------------------------------------------------------------------------
    value_true      -> hodnota premennej po splnení podmienky - TRUE
    value_false     -> hodnota premennej po nesplnení podmienky - FALSE
-------------------------------------------------------------------------------- */
global.variable_results = [
    { id: 0, lang: 'product_price', table_id: 0, item_id: 0, column: 'price_final', datatype: 0, value_true: '', value_false: '' },
    { id: 1, lang: 'cond_product_label', table_id: 0, item_id: 0, column: 'name', datatype: 2, value_true: '', value_false: '' },
    { id: 2, lang: 'cond_product_enabled', table_id: 0, item_id: 0, column: 'enabled', datatype: 3, value_true: '', value_false: '' },
    { id: 3, lang: 'price_list_coeficient', table_id: 0, item_id: 0, column: 'coeficient', datatype: 0, value_true: '', value_false: '' },
]



// ###################################################################################
// ZOZNAM PREMENNYCH - VARIABLE 1 podla modulov ID
global.variable1 = [
    { id: 0, variables: [] }, //
    { id: 1, variables: [] }, //
    { id: 2, variables: [] }, //
    { id: 3, variables: [] }, //
    { id: 4, variables: [] }, //
    { id: 5, variables: [] }, //
    { id: 6, variables: [] }, //
    { id: 7, variables: [] }, //
    { id: 8, variables: [] }, //
    { id: 9, variables: [] }, //
    { id: 10, variables: [] }, //
    { id: 11, variables: [] }, //
    { id: 12, variables: [0, 1, 2, 3, 4, 5] }, // PRODUKTY
    { id: 13, variables: [] }, //
    { id: 14, variables: [1, 4] }, // CENNIKY
]



// ###################################################################################
// ZOZNAM PREMENNYCH - RESULT(výsledná premenná pre podmienku) podla modulov ID
global.result = [
    { id: 0, variables: [] }, //
    { id: 1, variables: [] }, //
    { id: 2, variables: [] }, //
    { id: 3, variables: [] }, //
    { id: 4, variables: [] }, //
    { id: 5, variables: [] }, //
    { id: 6, variables: [] }, //
    { id: 7, variables: [] }, //
    { id: 8, variables: [] }, //
    { id: 9, variables: [] }, //
    { id: 10, variables: [] }, //
    { id: 11, variables: [] }, //
    { id: 12, variables: [0, 1, 2] }, // PRODUKTY
    { id: 13, variables: [] }, //
    { id: 14, variables: [3] }, // CENNIKY

]

