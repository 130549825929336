import React, { useEffect, useState } from 'react';

import './App.css';
import { EmptyList, FormSpace, Icon, TextLine, Avatar, ContactCompany } from './items';
import { styles } from './styles';
import { IconButton, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faCalendar, faCalendarDay, faCheckCircle, faFile, faHourglass, faTasks, faTriangleExclamation, faUser } from '@fortawesome/free-solid-svg-icons';
import './items.css';
import { Debug, GetImageLink, GetMinutesBetweenDates, LoadValue, SaveValue } from './functions';
import { PieChart } from '@mui/x-charts';
import { GetDatum2, GetHours, TodayDateTime } from './functions_date';
import { BlogPreview } from './blog';

export const Desktop = (props) => {
    let color = global.themes[props.theme];
    const offset = 16;
    const refresh_interval = 2; // v minutách -  Refreshing minutes - ako casto v minútach sa znovu načíta Dashboard

    const [width, setWidth] = useState(props.width - (props.offset * 2));

    // NASTAVENIE DASHBOARDU
    const [dashboard, setDashboard] = useState(false);

    // HODNOTY
    const [blogs, setBlogs] = useState(false);
    const [termins, setTermins] = useState(false);
    const [orders, setOrders] = useState(0);
    const [reklamacie, setReklamacie] = useState(0);
    const [customers, setCustomers] = useState(false);
    const [approvals, setApprovals] = useState(0);

    let { func } = props;
    var lang = props.lang;
    const buttonHeight = 32;
    var running = false;

    useEffect(() => {
        if (running == false && props.bodyID == 0) {

            setWidth(props.width - (props.offset * 2));

            var last_date = LoadValue('dashboard_date', false);
            var actual_date = TodayDateTime();

            Debug('DASHBOARD', 'blue');
            Debug('last_date: ' + last_date);
            Debug('actual_date: ' + actual_date);

            if (last_date == false || GetMinutesBetweenDates(last_date, actual_date, refresh_interval)) {
                db_get();
            }

            GoToStart();
        }

        return () => {
        }

    }, [props.employee_id, props.user, props.width, props.country, props.bodyID, props.locale.id])

    const db_get = async () => {
        Debug('Dashboard reading from DB', 'red');
        SaveValue('dashboard_date', TodayDateTime());
        try {
            const response = await fetch(
                global.db_url + 'dashboard_info', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    organization_id: props.organization.id,
                    token: props.token,
                    user_id: props.user.id,
                    country_id: props.locale.id,
                    items: global.dashboard
                })
            })

            const json = await response.json();
            Debug('DASHOBOARD - JSON', 'cyan');
            Debug(json);
            if (json.ok > 0) {
                setDashboard(json.dashboard);
                setBlogs(json.blogs);
                setTermins(json.termins);
                setOrders(json.orders);
                setReklamacie(json.reklamacie);
                setCustomers(json.customer_cards);
                setApprovals(json.approvals);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_desktop_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const InfoResult = (id, value) => {
        func(id, value);
    }

    function CheckEnabled(id) {
        var tmp = dashboard.find(x => x.id == id);
        if (tmp == undefined) {
            return false;
        } else {
            return tmp.enabled == false ? false : true;
        }
    }

    return (
        <div style={{ ...styles.Block, position: 'relative', height: props.height, width: props.width }}>
            {dashboard != false ?
                <div style={{ ...styles.BlockCenter, height: props.height, width: props.width, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div id='id_desktop_start' style={{ ...styles.Block }}></div>

                    <div style={{ ...styles.Block, flexDirection: props.isSmall ? 'column' : 'row', flexWrap: 'wrap', width: props.width, marginTop: offset / 2 }}>
                        {CheckEnabled(3) ?
                            <DesktopBoxInfo id={1} color={color.dashboard_blue} icon={faUser} title={lang.dashboard_orders} value={orders} note={lang.dashboard_orders_note} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} token={props.token} func={() => func(1, 400)} />
                            : null}
                        {CheckEnabled(4) ?
                            <DesktopBoxInfo id={2} color={color.dashboard_red} icon={faFile} title={lang.dashboard_reklamacie} value={reklamacie} note={lang.dashboard_reklamacie_note} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} token={props.token} func={() => func(1, 401)} />
                            : null}
                        {CheckEnabled(5) ?
                            <DesktopBoxInfo id={3} color={color.dashboard_green} icon={faTasks} title={lang.dashboard_today_plans_} value={termins == false ? 0 : termins.length} note={lang.info_customer_note} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} token={props.token} func={() => func(1, 601)} />
                            : null}
                        {CheckEnabled(7) && props.user.approve_enabled == true ?
                            <DesktopBoxInfo id={4} color={color.dashboard_orange} icon={faCheckCircle} title={lang.approvals} value={approvals} note={lang.approvals_ask} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} token={props.token} func={() => func(1, 500)} />
                            : null}
                    </div>

                    {blogs != false && CheckEnabled(1) ?
                        <div style={{ ...styles.Block, width: width, marginTop: offset }}>
                            <DesktopBlogs blogs={blogs} width={width} theme={props.theme} organization={props.organization} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} token={props.token} func={InfoResult.bind(this)} />
                        </div>
                        : null}

                    <div style={{ ...props.isLarge ? styles.BlockLeft : styles.Block, flexDirection: 'row', flexWrap: 'wrap', justifyContent: props.isLarge ? 'center' : 'center', width: width, marginTop: offset }}>
                        {CheckEnabled(2) ?
                            <div style={{ ...styles.Block, width: props.isLarge ? width / 2 - props.offset : '100%', margin: props.offset / 2 }}>
                                <DesktopTodayPlans items={termins} half theme={props.theme} organization={props.organization} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - (props.offset * 2)} token={props.token} func={InfoResult.bind(this)} />
                            </div>
                            : null}
                        {CheckEnabled(6) ?
                            <div style={{ ...styles.Block, width: props.isLarge ? width / 2 - props.offset : '100%', margin: props.offset / 2 }}>
                                <DesktopTodayCustomers items={customers} half theme={props.theme} organization={props.organization} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - (props.offset * 2)} token={props.token} func={InfoResult.bind(this)} />
                            </div>
                            : null}
                        {CheckEnabled(99) ?
                            <div style={{ ...styles.Block, width: props.isLarge ? width / 2 - props.offset : '100%', margin: props.offset / 2 }}>
                                <DesktopBoxHalf half theme={props.theme} organization={props.organization} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - (props.offset * 2)} token={props.token} />
                            </div>
                            : null}
                    </div>

                    {/*
                <div style={{ ...styles.Block, width: width, marginTop: props.offset }}>
                    <DesktopBoxLarge simple width={width} theme={props.theme} organization={props.organization} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} token={props.token} />
                </div>

                <div style={{ ...styles.BlockRowRaw, width: width, marginTop: props.offset }}>
                    <div style={{ ...styles.BlockRight, width: '50%', marginRight: props.offset }}>
                        <DesktopBoxHalf half theme={props.theme} organization={props.organization} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - (props.offset * 2)} token={props.token} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                        <DesktopBoxHalf half theme={props.theme} organization={props.organization} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - (props.offset * 2)} token={props.token} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: width, marginTop: props.offset }}>
                    <DesktopBoxHalf half theme={props.theme} organization={props.organization} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - (props.offset * 2)} token={props.token} />
                </div>

                <div style={{ ...styles.Block, flexDirection: props.isSmall ? 'column' : 'row', flexWrap: 'wrap', width: props.width }}>
                    <DesktopBoxInfo id={1} color={color.dashboard_blue} icon={faUser} title={lang.info_customer} value={10} note={lang.info_customer_note} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - props.offset * 2} token={props.token} func={InfoResult.bind(this)} />
                </div>

                <div style={{ ...styles.Block, flexDirection: props.isSmall ? 'column' : 'row', flexWrap: 'wrap', width: props.width }}>
                    <DesktopBoxPie title={lang.info_customer_stars} pie_value_1={10} pie_value_2={3} pie_text_1={lang.info_customer_inactive} pie_text_2={lang.info_customer_active} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - props.offset * 2} token={props.token} func={InfoResult.bind(this)} />
                </div>
            */}

                    {global.testing == true ?
                        <DesktopTesting theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - props.offset * 2} token={props.token} func={InfoResult.bind(this)} />
                        : null}
                    <FormSpace />
                </div >
                : null}
            <div style={{ ...styles.BlockLeft, height: buttonHeight, width: buttonHeight, position: 'absolute', bottom: 0, left: props.offset }}>
                <IconButton onClick={() => db_get()} style={{ ...styles.ButtonIcon }}>
                    <FontAwesomeIcon style={{ width: 10 }} icon={faArrowsRotate} />
                </IconButton>
            </div>
        </div>
    );
}

export const DesktopTodayPlans = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const height = 200;

    let { func } = props;
    var lang = props.lang;
    var running = false;
    let labelHeight = 40;
    let rowHeight = 54;

    useEffect(() => {
        if (running == false) {

            running = true;
        }
    }, [props.width])

    return (
        <div style={{ ...styles.BlockCenter, borderRadius: radius, backgroundColor: color.desktop_window }} >
            <div style={{ ...styles.BlockRow, height: global.dashboard_menu_height - 1, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, backgroundColor: color.lighteen, borderBottom: color.border }}>
                <div onClick={() => func(4, 0)} style={{ ...styles.Block, width: 60, height: global.dashboard_menu_height }}>
                    <Icon theme={props.theme} icon={faCalendarDay} />
                </div>
                <div style={{ ...styles.Block, width: '90%', height: global.dashboard_menu_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{lang.dashboard_today_plans}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.dashboard_menu_height }}>
                    <IconButton onClick={() => func(4, 0)} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 16 }} icon={faCalendar} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.BlockCenter, paddingTop: 10, paddingBottom: 10 }}>
                {/* BODY */}

                {props.items != false ?
                    props.items.map((item, index) => (
                        <div key={item.id}
                            onClick={() => func(2, item)}
                            style={{
                                ...styles.Block,
                                minHeight: rowHeight,
                                height: rowHeight,
                                width: '98%',
                                backgroundColor: item.priority == 1 ? item.task_finished == true ? color.disabled : color.lighteen_red : item.task_finished == true ? color.disabled : color.white,
                                cursor: 'pointer',
                                marginTop: 3,
                                marginBottom: 3,
                                borderRadius: props.radius
                            }}>
                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                <div style={{ ...styles.BlockLeft, width: 86, height: rowHeight }}>
                                    <p style={{ ...styles.TextTiny }}>{GetDatum2(item.date)}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: 56, height: rowHeight }}>
                                    <p style={{ ...styles.TextXSmall }}><b>{GetHours(item.time_start)}</b></p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '82%', height: rowHeight }}>
                                    <TextLine text={item.name} fontSize={global.font_xsmall} color={color.black} />
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                        <EmptyList row small lang={lang} text={lang.dashboard_today_no_plans} />
                    </div>
                }
            </div>
        </div>
    );
}

export const DesktopTodayCustomers = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const height = 200;

    let { func } = props;
    var lang = props.lang;
    var running = false;
    let labelHeight = 40;
    let rowHeight = 54;

    useEffect(() => {
        if (running == false) {

            running = true;
        }
    }, [props.width])

    return (
        <div style={{ ...styles.BlockCenter, borderRadius: radius, backgroundColor: color.desktop_window }} >
            <div style={{ ...styles.BlockRow, height: global.dashboard_menu_height - 1, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, backgroundColor: color.lighteen, borderBottom: color.border }}>
                <div style={{ ...styles.Block, width: 60, height: global.dashboard_menu_height }}>
                    <Icon theme={props.theme} icon={faUser} />
                </div>
                <div style={{ ...styles.Block, width: '95%', height: global.dashboard_menu_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{lang.dashboard_today_customers}</p>
                </div>
            </div>

            <div style={{ ...styles.BlockCenter, paddingTop: 10, paddingBottom: 10 }}>
                {/* BODY */}

                {props.items != false ?
                    props.items.map((item, index) => (
                        <div key={item.id}
                            onClick={() => func(3, item.id)}
                            style={{
                                ...styles.Block,
                                minHeight: rowHeight,
                                height: rowHeight,
                                width: '98%',
                                backgroundColor: item.priority == 1 ? item.task_finished == true ? color.disabled : color.lighteen_red : item.task_finished == true ? color.disabled : color.white,
                                cursor: 'pointer',
                                marginTop: 3,
                                marginBottom: 3,
                                borderRadius: props.radius
                            }}>
                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                    <Avatar picture image={item.avatar} size={rowHeight - 4} theme={props.theme} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '82%', height: rowHeight, marginLeft: 10 }}>
                                    <ContactCompany company={item.company} name={item.name} surname={item.surname} theme={props.theme} />
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                        <EmptyList row small lang={lang} text={lang.dashboard_today_no_plans} />
                    </div>
                }
            </div>
        </div>
    );
}

/*
*
* =================================================================================================================
* BLOG - interné príspevky
* =================================================================================================================
*
*/

export const DesktopBlogs = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const [width, setWidth] = useState(props.width);
    const [items, setItems] = useState(props.blogs);
    const [itemSelected, setItemSelected] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    const height = 240;

    let { func } = props;
    var lang = props.lang;
    var running = false;
    const boxWidth = 150;
    const boxHeight = height - 40;

    useEffect(() => {
        if (running == false) {

            setWidth(props.width);
            setItems(props.blogs);

            running = true;
        }
    }, [props.width, props.blogs])

    const Preview = (item) => {
        setItemSelected(item);
        setShowPreview(true);
    }

    const ShowMore = () => {
        func(1, 715);
    }


    return (
        <div style={{ ...styles.Block }}>
            <div style={{ ...styles.Block, height: 28, borderBottom: '1px solid #B0B0B0' }}>
                <p style={{ ...styles.TextDialogLabel }}>{lang.dashboard_blog}</p>
            </div>
            <div style={{ ...styles.BlockRow, alignItems: 'center', justifyContent: items != false ? items.length < 6 ? 'center' : 'flex-start' : 'center', width: props.border == true ? props.width - 2 : props.width, height: height - 28, backgroundColor: '#FFFFFF00', borderRadius: radius, border: props.border == true ? '1px solid ' + color.middle_gray : undefined, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowX: 'scroll' }} className='container'>
                {/* BODY */}
                {items != false ? items.map((item, index) => (
                    <Paper key={item.id} elevation={3} onClick={() => Preview(item)} style={{ ...styles.Block, backgroundColor: color.white, minWidth: boxWidth, maxWidth: boxWidth, height: boxHeight, marginLeft: 0, marginRight: 8, cursor: 'pointer' }}>
                        <img src={GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: boxWidth, maxHeight: boxWidth, objectFit: 'contain', borderRadius: radius }}></img>
                        <div style={{ ...styles.Block, width: boxWidth - 4, height: boxHeight - boxWidth }}>
                            <TextLine text={item.title} fontSize={global.font_tiny} color={color.black} />
                        </div>
                    </Paper>
                )) : null}

                {items != false ? items.length > 5 ?
                    <div onClick={() => ShowMore()} style={{ ...styles.Block, backgroundColor: color.lighteen, minWidth: boxWidth, maxWidth: boxWidth, height: 40, marginLeft: 0, marginRight: 8, cursor: 'pointer', borderRadius: props.radius }}>
                        <p style={{ ...styles.TextTiny }}>{lang.dashboard_blog_all} ...</p>
                    </div>
                    : null : null}

                {showPreview == true ?
                    <BlogPreview blogID={itemSelected.id} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowPreview(false)} />
                    : null}
            </div>
        </div>
    );
}


// ************************************************************************************************************************************************************************************
// TEMPLATES
// ************************************************************************************************************************************************************************************

export const DesktopBoxHalf = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const height = 200;

    let { func } = props;
    var lang = props.lang;
    var running = false;
    const labelHeight = 40;

    useEffect(() => {
        if (running == false) {

            running = true;
        }
    }, [props.width])

    return (
        <div style={{ ...styles.BlockCenter, height: height, borderRadius: radius, backgroundColor: color.desktop_window }} >
            <div style={{ ...styles.BlockRow, height: global.dashboard_menu_height - 1, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, backgroundColor: color.white, borderBottom: color.border }}>
                <div style={{ ...styles.Block, width: 60, height: global.dashboard_menu_height }}>
                    <Icon theme={props.theme} icon={faCalendarDay} />
                </div>
                <div style={{ ...styles.Block, width: '95%', height: global.dashboard_menu_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{'Title'}</p>
                </div>
            </div>

            <div style={{ ...styles.Block, width: '96%', height: labelHeight }}>

            </div>

            <div style={{ ...styles.BlockCenter, height: height - global.dashboard_menu_height - labelHeight - 2, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                {/* BODY */}
            </div>
        </div>
    );
}


export const DesktopBoxLarge = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const [width, setWidth] = useState(props.width);

    const height = 200;

    let { func } = props;
    var lang = props.lang;
    var running = false;
    const labelHeight = 40;

    useEffect(() => {
        if (running == false) {
            Debug(props.width);
            setWidth(props.width);

            running = true;
        }
    }, [props.width])


    return (
        <Paper elevation={3} style={{ ...styles.BlockCenter, height: height, borderRadius: radius }} >
            <div style={{ ...styles.BlockRow, height: global.dashboard_menu_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color_light }}>
                <div style={{ ...styles.Block, width: 60, height: global.dashboard_menu_height }}>
                    <Icon theme={props.theme} icon={faUser} />
                </div>
                <div style={{ ...styles.Block, width: '95%', height: global.dashboard_menu_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{lang.subscriptions}</p>
                </div>
            </div>
            <div style={{ ...styles.BlockCenter, height: height - global.dashboard_menu_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                {/* BODY */}

            </div>
        </Paper>
    );
}


export const DesktopSimpleBoxLarge = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const [width, setWidth] = useState(props.width);

    const height = 200;

    let { func } = props;
    var lang = props.lang;
    var running = false;
    const labelHeight = 40;

    useEffect(() => {
        if (running == false) {
            Debug(props.width);
            setWidth(props.width);

            running = true;
        }
    }, [props.width])


    return (
        <div style={{ ...styles.BlockCenter, width: props.border == true ? props.width - 2 : props.width, height: height, backgroundColor: '#FFFFFF50', borderRadius: radius, border: props.border == true ? '1px solid ' + color.middle_gray : undefined }} >
            {/* BODY */}

        </div>
    );
}


export const DesktopBoxInfo = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const width = 220;
    const height = 80;

    let { func } = props;
    var lang = props.lang;
    var running = false;

    useEffect(() => {
        if (running == false) {

            running = true;
        }
    }, [props.width, props.value])


    return (
        <div elevation={3} onClick={() => func(true)} style={{ ...styles.Block, margin: props.offset / 4, width: width, height: height, borderRadius: radius, backgroundColor: props.color, cursor: 'pointer' }} >
            <div style={{ ...styles.BlockRow }}>
                <div style={{ ...styles.Block, width: 40, height: height }}>
                    <FontAwesomeIcon style={{ height: 24, color: '#00000040' }} icon={props.icon} />
                </div>
                <div style={{ ...styles.Block, width: width - 40, height: height }}>
                    <div style={{ ...styles.Block, height: 30 }}>
                        <p style={{ ...styles.TextTiny, color: color.black }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: width - 30 - 20, height: height - 40, backgroundColor: '#FFFFFF70', borderRadius: 10 }}>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.Block, width: '90%' }}>
                                <p style={{ ...styles.TextXLarge, color: color.black, fontWeight: '600' }}>{props.value}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: 30 }}>
                        <p style={{ ...styles.TextXTiny, color: '#00000070' }}>{props.note}</p>
                    </div>
                </div>
            </div>

        </div>
    );
}


export const DesktopBoxPie = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const [width, setWidth] = useState(240 * 2 + props.offset);
    const height = 220;

    let { func } = props;
    var lang = props.lang;
    var running = false;

    useEffect(() => {
        if (running == false) {

            running = true;
        }
    }, [props.width, props.pie_value_1, props.pie_value_2])


    return (
        <Paper elevation={3} style={{ ...styles.Block, marginLeft: props.offset, marginTop: props.offset, width: width, height: height, borderRadius: radius, backgroundColor: color.dashboard }} >
            <div style={{ ...styles.Block, height: 40, borderBottom: color.border }}>
                <p style={{ ...styles.TextXSmall }}>{props.title}</p>
            </div>
            <div style={{ ...styles.Block, width: undefined, height: height - 40 }}>
                <PieChart
                    series={[
                        {
                            data: [
                                { id: 0, value: props.pie_value_1, label: props.pie_text_1 },
                                { id: 1, value: props.pie_value_2, label: props.pie_text_2 },
                            ],
                        },
                    ]}
                    slotProps={{
                        legend: {
                            padding: 0,
                            //hidden: true
                        },
                    }}
                    margin={{
                        left: -180,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    }}
                    width={360}
                    height={160}
                />
            </div>
        </Paper>
    );
}

export const DesktopTesting = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const [width, setWidth] = useState(320);
    const height = 120;

    let { func } = props;
    var lang = props.lang;
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
        }
    }, [props.width])



    return (
        <div style={{ ...styles.BlockCenter, marginLeft: props.offset, marginTop: props.offset, width: width, height: height, borderRadius: radius, borderRadius: 20 }} >
            <div style={{ ...styles.Block, width: width, height: height }}>
                <FontAwesomeIcon style={{ height: 30, color: color.dark_red }} icon={faTriangleExclamation} />
                <p style={{ ...styles.TextXLarge, color: color.dark_red, marginTop: 10 }}><b>Testovacia verzia</b></p>
                <p style={{ ...styles.TextXSmall, color: color.dark_red, marginTop: 0 }}>Verzia na testovanie vývojárom aplikácie</p>
            </div>
        </div>
    );
}