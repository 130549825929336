import React, { useEffect, useState } from 'react';

import './App.css';
import { FormatMoney } from './functions';
import { styles } from './styles';
import { Paper, Popover } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { SelectYear } from './items';


/*
*
* =================================================================================================================
*   VYKRESLENIE GRAFU
* =================================================================================================================
*
*/
export const Graph = (props) => {
    /*

            USAGE:
            --------------------------------------------------------------------------------------------------------------
            lang        = language
            mini        = mensia verzia bez nadpisov
            elevation   = velkost tieňa
            radius      = border radius
            width       = šírka tabulky
            height      = výška tabulky
            tableback   = farba pozadia tabulky
            topLine     = zobrazovanie ciary nad grafom
            title       = nadpis
            sub_title   = podnadpis
            title_align = left - title align to left / undefined - align to center
            legend      = FALSE -> nezobrazuje sa, legenda={názov 1}, legenda2={nazov2}
            typ         = typ grafu 0,1 -> vertikálny graf, 2 -> horizontálny porovnávaci graf
            lines       = zobrazovat riadky (nepovinne) (pri scrollovani je potrebne vypnut)
            barWidth    = šírka stlpca (nepovinne)
            highColor   = farba stlpca po kliknutí (nepovinne)
            overColor   = farba okraja stlpca ak je nad nim pointer (nepovinne)
            showMax     = oznacit najvyssiu hodnotu (nepovinne)
            units       = Jednotky za textom value
            marginTop   = marginTop style
            selectable  = ci sa dá oznacit stlpec, alebo nie
            popover     = zobrazovanie hodnoty po stlačení stlpca (true / false)
            tableName, tableName2 = názov datasetu, zobrazuje sa po kliknutí na graf
            show_value  = zobrazenie hodnoty nad stlpcom
            year        = Zobrazí výber roku štatistiky (true / false)
            data        = údaje: value / value2 (ak je definované value2 - graf je dvojitý)

                        data - príklad:
                        {id:0, label: '01/2022', value: 123, backColor: color.dark, backColor2: color.dark_red, textColor: color.black, icon: faBook, barText:'01'}
            --------------------------------------------------------------------------------------------------------------

            <Graph
                lang = {props.lang}
                elevation={0}
                radius = {0}
                title={'nazov'}
                sub_title={''}
                title_align={'left'}
                width={800}
                height={400}
                typ={0}
                data={data}
                barWidth={50}
                highColor={color.red}
                overColor={color.blue}
                showMax={true}
                lines={true}
                units={'%'}
                marginTop={10}
                selectable={true}
                show_value={true}
                theme={props.theme} 
                func={GraphResult.bind(this)}
            />

            EXAMPLES:

            Vertikálny graf:
            -----------------
            const data = [
                { id: 0, label: '01/2022', value: 31, value2: 10 },
                { id: 1, label: '02/2022', value: 100, value2: 50 },                       
                { id: 2, label: '03/2022', value: 59, value2: 5 },                       
            ];
            <Graph elevation={3} title={'nazov'} sub_title={'podnadpis'} width={900} height={400} data={data} barWidth={50} showMax={true} lines={true} typ={0} units={'%'} selectable={true} func={GraphResult.bind(this)} />


            Porovnavaci graf:
            -----------------
            const data = [
                { id: 0, label: '01/2022', value: 31 },
                { id: 1, label: '02/2022', value: 100 },                       
            ];
            <Graph elevation={0} title={'nazov'} sub_title={'podnadpis'} width={900} height={50} data={data} barWidth={50} showMax={true} lines={true} typ={2} units={'%'} func={GraphResult.bind(this)} />

            const GraphResult = (id, value, value2) => {
                if (id == -2) {
                    // ZMENA ROKA
                    console.log(value, value2);
                }
                if (id == -1) {
                    // STLPEC - deselect
                }
                if (id >= 0) {
                    // OZNACENY STLPEC
                }
            }

    */
    let color = global.themes[props.theme];

    const [isReady, setReady] = useState(false);
    const [items, setItems] = useState([]);
    const [barWidth, setBarWidth] = useState(50);
    const [barID, setBarID] = useState(-1);
    const [barIndex, setBarIndex] = useState(-1);
    const [barOverID, setBarOverID] = useState(-1);
    const [highColor, setHighColor] = useState(props.highColor == undefined ? 'linear-gradient(to bottom,#C3170B 0%,#F77268 50%)' : props.highColor);
    const [overColor, setOverColor] = useState(props.overColor == undefined ? '#777777' : props.overColor);
    const [maxValueID, setMaxValueId] = useState(0);
    const [maxValueID2, setMaxValueId2] = useState(0);
    const [lines, setLines] = useState([]);
    const [typ, setTyp] = useState(props.typ);
    const [showPop, setShowPop] = useState(false);
    const [selectedItem, setSelectedItem] = useState([]);
    const [statsYear, setStatsYear] = useState(new Date().getFullYear());

    // *********************************************************************************************************
    // DEFINOVANIE FARIEB
    // *********************************************************************************************************
    const tableBackground = typ == 2 ? '#00000000' : props.tableback == undefined ? color.white : props.tableback;    // Pozadie pod tabulkou
    const tableBorderColorTop = color.gray;    // Farba borders - horny pod textom
    const tableBorderColorBottom = typ == 1 ? color.dark_gray : color.medium_gray;    // Farba borders - spodny na label textom
    const titleBackground = props.tableback == undefined ? color.white : props.tableback;    // Pozadie pod hlavným nadpisom
    const titleColor = color.black;         // farba hlavného nadpisu
    const subtitleColor = color.dark_gray;  // farba podnadpisu
    const labelBackground = props.tableback == undefined ? color.white : props.tableback;    // Pozadie textu pod stlpcami
    const labelColor = color.dark_gray;     // farba textu pod stlpcami
    const valueDefaultColor = typ == 1 ? color.white : typ == 2 ? color.white : color.black;  // farba textu nad stlpcami - ak nieje definovana ina
    const heardColor = typ == 1 || typ == 2 ? '#FFFF00' : color.red;           // farba srdiecka - maximalna hodnota
    const barDefaultColor = typ == 1 ? '#0C346B' : typ == 2 ? 'linear-gradient(to left,#0C0C50 0%,#205CCA 50%)' : 'linear-gradient(to bottom,#0C0C50 0%,#205CCA 50%)'; // predefinovana farba stlpcov
    const barDefaultColor2 = typ == 1 ? '#0C346B' : typ == 2 ? 'linear-gradient(to right,#0C0C50 0%,#205CCA 50%)' : 'linear-gradient(to bottom,#055505 0%,#10AA10 50%)'; // predefinovana farba stlpcov

    // *********************************************************************************************************
    // ROZMERY
    // *********************************************************************************************************
    const barRadius = 10;       // Radius stlpcov
    const linesOffset = 25;     // Vzdialenost horizontalnych ciar
    const tableRadius = 10;     // Radius celej tabulky
    const titleHeight = props.mini == true ? 0 : 100;     // Výška hlavného titulku aj s podtitukom    
    const valueHeight = props.typ == 1 ? 30 : 20;     // Výška popisu nad stlpcom
    const labelHeight = 30;     // Výška hodnoty pod stlpcom
    const compareTitle = 200;     // Sirka pre porovnavaci text v strede pre typ 2

    // *********************************************************************************************************
    // Výpočet max. výšky stlpca
    // *********************************************************************************************************
    const barHeight = props.height - titleHeight - labelHeight - valueHeight;

    const [anchor, setAnchor] = useState(null);

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        setBarID(-1);
        setBarIndex(-1);

        if (props.data != null && props.data != false) {
            var data = props.data;

            // Hladanie najvyssej hodnoty
            var max = 0;
            var max1 = 0;
            var max2 = 0;
            var max_id = -1;
            var max_id2 = -1;
            data.forEach(item => {
                if (parseFloat(item.value) > max) {
                    max = parseFloat(item.value);
                }
                if (parseFloat(item.value) > max1) {
                    max1 = parseFloat(item.value);
                    max_id = item.id;
                }

                if (item.value2 != undefined) {
                    if (parseFloat(item.value2) > max) {
                        max = parseFloat(item.value2);
                    }
                    if (parseFloat(item.value2) > max2) {
                        max2 = parseFloat(item.value2);
                        max_id2 = item.id;
                    }
                }
            });
            setMaxValueId(max_id);
            setMaxValueId2(max_id2);
            if (props.barWidth == undefined) {
                setBarWidth(props.width / data.length);
            } else {
                setBarWidth(props.barWidth);
            }

            if (props.typ == 0 || props.typ == 1) {
                // *********************************************************************************************************
                // VERTIKALNY GRAF
                // *********************************************************************************************************
                // ------------------------------------------------------------------------------------
                // sirka stlpca
                // ------------------------------------------------------------------------------------

                // ------------------------------------------------------------------------------------
                // prepocet výšky stlpcov
                // ------------------------------------------------------------------------------------
                var divider = max / (barHeight);
                data.forEach(item => {
                    let value = parseInt(item.value / divider);
                    if (value < 5) {
                        item.height = 5;
                    } else {
                        item.height = value - 1;
                    }
                    if (item.value2 != undefined) {
                        let value = parseInt(item.value2 / divider);
                        if (value < 5) {
                            item.height2 = 5;
                        } else {
                            item.height2 = value - 1;
                        }
                    }
                });
                setItems(data);

                // vypocet riadkov
                var n = 0;
                var arr = [];
                while (n < barHeight) {
                    var tmp = { height: linesOffset }
                    arr.push(tmp);
                    n += linesOffset;
                }
                setLines(arr);

            }

            if (props.typ == 2) {
                // *********************************************************************************************************
                // HORIZONTALNY POROVNAVACI GRAF
                // *********************************************************************************************************
                var wide = (props.width - compareTitle) / 2 - 20;
                var divider = max / wide;

                data.forEach(item => {
                    let value = parseInt(item.value / divider);
                    if (value < 5) {
                        item.width = 5;
                    } else {
                        item.width = value;
                    }
                });
                setItems(data);

            }

            if (props.typ != undefined) {
                setTyp(props.typ);
            }

            setReady(true);
        }

        return () => {
        };

    }, [props.data]);

    const Press = (id, value, value2) => {
        func(id, value, value2);
    }

    const Select = (event, item, value) => {
        // kliknutie na stlpec
        if (props.selectable == true) {
            setAnchor(event.currentTarget);
            if (barID == item.id && value == barIndex) {
                setBarID(-1);
                setBarIndex(-1);
                Press(-1, value, item);
            } else {
                setBarID(item.id);
                setBarIndex(value);
                setSelectedItem(item);
                if (props.popover == true) {
                    setShowPop(true);
                }
                Press(item.id, value, item);
            }
        }
    }

    const handleClosePop = () => {
        setShowPop(false);
        setBarID(-1);
        setBarIndex(-1);
    }


    const SelectYearResult = (year, month) => {
        Press(-2, year, month)
    }

    return (
        <Paper elevation={props.elevation != undefined ? props.elevation : 0} style={{ ...styles.BlockCenter, width: props.width, position: 'relative', height: typ == 2 ? barWidth + 20 : props.height, backgroundColor: tableBackground, borderRadius: tableRadius, marginTop: props.marginTop == undefined ? 0 : props.marginTop, borderRadius: props.radius != undefined ? props.radius : 0 }}>
            {typ == 0 || typ == 1 ?
                <div style={{ ...styles.Block }}>
                    {props.mini != true ?
                        <div style={{ ...styles.BlockLeft, height: titleHeight, borderBottom: props.topLine == undefined || props.topLine == true ? '1px solid ' + color.light_gray : '0px', background: titleBackground, borderTopLeftRadius: tableRadius, borderTopRightRadius: tableRadius }}>
                            {/* TITULOK + POD-TITULOK */}
                            <div style={{ ...styles.BlockRow }}>
                                {props.isSmall ? null :
                                    <div style={{ ...styles.Block, width: props.title_align == 'left' ? 10 : 150 }}></div>
                                }
                                <div style={{ ...styles.Block, width: '80%', alignItems: props.isSmall ? 'flex-start' : props.title_align == 'left' ? 'flex-start' : 'center' }}>
                                    <p style={{ ...props.isSmall ? styles.TextSmall : styles.TextLarge, color: color.dialog_label, marginTop: 5, textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{props.title}</p>
                                    <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextTiny, color: subtitleColor, marginTop: 0, marginLeft: props.isSmall ? 10 : 0, textAlign: 'left' }}>{props.sub_title}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: 150, marginRight: 5, marginTop: 5 }}>
                                    {props.year == true ?
                                        <SelectYear min={2020} value={statsYear} theme={props.theme} func={SelectYearResult.bind(this)} />
                                        : null}
                                </div>
                            </div>
                            {/* legenda */}
                            {props.legend == false ? null :
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockRowRaw, marginTop: 5, marginLeft: 10 }}>
                                        <div style={{ ...styles.Block, width: 20, height: 20, borderRadius: 10, background: barDefaultColor }}></div>
                                        <p style={{ ...styles.TextTiny, color: subtitleColor, marginTop: 2, marginLeft: 10 }}>{props.legenda}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRowRaw, marginTop: 5, marginLeft: 50 }}>
                                        <div style={{ ...styles.Block, width: 20, height: 20, borderRadius: 10, background: barDefaultColor2 }}></div>
                                        <p style={{ ...styles.TextTiny, color: subtitleColor, marginTop: 2, marginLeft: 10 }}>{props.legenda2}</p>
                                    </div>
                                </div>
                            }
                        </div>
                        : null}

                    {props.lines == true ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-end', position: 'absolute', top: titleHeight, height: props.height - titleHeight }}>
                            {lines.map((item, index) => (
                                <div key={index} style={{ ...styles.Block, height: item.height, borderBottom: '1px dotted ' + color.light_gray }}></div>
                            ))}
                            <div style={{ ...styles.Block, height: labelHeight }}></div>
                        </div>
                        : null}

                    <div style={{ ...styles.Block, position: props.lines == true ? 'absolute' : undefined, top: titleHeight, height: props.height - titleHeight, alignItems: 'flex-end' }}>
                        <div style={{ ...styles.BlockRow, height: props.height - titleHeight }}>
                            {props.data != null && props.data && isReady == true ?

                                items.map((item, index) => (
                                    typ == 0 ?
                                        <div key={item.id} style={{ ...styles.Block, width: props.width / props.data.length, height: barHeight + labelHeight + valueHeight, justifyContent: 'flex-end', borderLeft: props.lines == true ? '1px dotted ' + color.light_gray : '', backgroundColor: index % 2 == 0 ? '#F0F0F050' : undefined, borderBottomLeftRadius: index == 0 ? tableRadius : 0 }}>
                                            <div style={{ ...styles.BlockRowRaw, width: barWidth, alignItems: 'flex-end' }}>
                                                {/* 1.hodnota */}
                                                <div style={{ ...styles.BlockLeft, width: item.value2 != undefined ? '50%' : '100%' }}>
                                                    {/* HODNOTA NA STLPCI */}
                                                    {props.show_value && item.value > 0 == true ?
                                                        <div onClick={(event) => Select(event, item, 0)} style={{ ...styles.Block, height: valueHeight, cursor: 'pointer' }}>
                                                            <p style={{ ...styles.TextXXTiny, color: item.textColor == undefined ? valueDefaultColor : item.textColor }}>
                                                                {/* NAJVYSSIA HODNOTA - OZNACENA HVIEZDICKOU  */}
                                                                {maxValueID == item.id && props.showMax == true ?
                                                                    <FontAwesomeIcon style={{ width: 10, color: heardColor, marginRight: 2 }} icon={faStar} />
                                                                    : null}
                                                                {props.units == '€' || props.units == 'Kč' ? FormatMoney(item.value, 0) : item.value} <span style={{ marginLeft: 2 }}>{props.units != undefined ? props.units : ''}</span>
                                                            </p>
                                                        </div>
                                                        : null}
                                                    {/* STLPEC */}
                                                    {item.value > 0 ?
                                                        <div onClick={(event) => Select(event, item, 0)} style={{
                                                            ...styles.BlockCenter, maxWidth: barWidth, height: item.height, background: barID == item.id && barIndex == 0 ? highColor : item.backColor == undefined ? barDefaultColor : item.backColor, borderTopLeftRadius: barRadius, borderTopRightRadius: barRadius, cursor: 'pointer',
                                                            borderLeft: barOverID == item.id ? '1px solid ' + overColor : '1px solid ' + tableBackground,
                                                            borderRight: barOverID == item.id ? '1px solid ' + overColor : '1px solid ' + tableBackground
                                                        }}
                                                            onMouseEnter={() => setBarOverID(item.id)}
                                                            onMouseLeave={() => setBarOverID(-1)}>
                                                        </div>
                                                        : null}
                                                </div>

                                                {/* 2.hodnota */}
                                                {item.value2 != undefined ?
                                                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                                        {/* HODNOTA NA STLPCI */}
                                                        {props.show_value == true && item.value2 > 0 ?
                                                            <div onClick={(event) => Select(event, item, 1)} style={{ ...styles.Block, height: valueHeight, cursor: 'pointer' }}>
                                                                <p style={{ ...styles.TextXXTiny, color: item.textColor == undefined ? valueDefaultColor : item.textColor }}>
                                                                    {/* NAJVYSSIA HODNOTA - OZNACENA HVIEZDICKOU  */}
                                                                    {maxValueID2 == item.id && props.showMax == true ?
                                                                        <FontAwesomeIcon style={{ width: 10, color: heardColor, marginRight: 2 }} icon={faStar} />
                                                                        : null}
                                                                    {item.value2}<span style={{ marginLeft: 2 }}>{props.units != undefined ? props.units : ''}</span>
                                                                </p>
                                                            </div>
                                                            : null}
                                                        {/* STLPEC */}
                                                        {item.value2 > 0 ?
                                                            <div onClick={(event) => Select(event, item, 1)} style={{
                                                                ...styles.BlockCenter, maxWidth: barWidth, height: item.height2, background: barID == item.id && barIndex == 1 ? highColor : item.backColor2 == undefined ? barDefaultColor2 : item.backColor2, borderTopLeftRadius: barRadius, borderTopRightRadius: barRadius, cursor: 'pointer',
                                                                borderLeft: barOverID == item.id ? '1px solid ' + overColor : '1px solid ' + tableBackground,
                                                                borderRight: barOverID == item.id ? '1px solid ' + overColor : '1px solid ' + tableBackground
                                                            }}
                                                                onMouseEnter={() => setBarOverID(item.id)}
                                                                onMouseLeave={() => setBarOverID(-1)}>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    : null}
                                            </div>
                                            {/* Popis pod stlpcom */}
                                            <div style={{ ...styles.Block, height: labelHeight, borderTop: '1px solid ' + tableBorderColorBottom, background: labelBackground, borderBottomLeftRadius: item.id == 0 ? tableRadius : 0, borderBottomRightRadius: item.id == items.length - 1 ? tableRadius : 0 }}>
                                                <p style={{ ...props.isSmall == true ? styles.TextXXXTiny : styles.TextXXTiny, color: barID == item.id ? titleColor : labelColor, textAlign: 'center' }}>{item.label}</p>
                                            </div>
                                        </div>
                                        :
                                        typ == 1 ?
                                            <div key={item.id} style={{ ...styles.Block, width: props.width / props.data.length, height: barHeight + labelHeight + valueHeight, justifyContent: 'flex-end' }}>
                                                {/* HODNOTA NA STLPCI */}
                                                <div onClick={(event) => Select(event, item, 0)} style={{ ...styles.Block, width: barWidth, height: valueHeight, cursor: 'pointer', background: item.backColor == undefined ? barDefaultColor : item.backColor, borderTopLeftRadius: barRadius, borderTopRightRadius: barRadius }}>
                                                    <p style={{ ...styles.TextTiny, color: item.textColor == undefined ? valueDefaultColor : item.textColor }}>
                                                        {/* NAJVYSSIA HODNOTA - OZNACENA HVIEZDICKOU  */}
                                                        {maxValueID == item.id && props.showMax == true ?
                                                            <FontAwesomeIcon style={{ width: 10, color: heardColor, marginRight: 2 }} icon={faStar} />
                                                            : null}
                                                        {item.value}<span style={{ marginLeft: 2 }}>{props.units != undefined ? props.units : ''}</span>
                                                    </p>
                                                </div>
                                                {/* STLPEC */}
                                                {item.value > 0 ?
                                                    <div onClick={(event) => Select(event, item, 0)} style={{
                                                        ...styles.Block, maxWidth: barWidth, height: item.height, background: '#205CCA', cursor: 'pointer',
                                                        borderLeft: barOverID == item.id ? '1px solid ' + overColor : '1px solid ' + tableBackground,
                                                        borderRight: barOverID == item.id ? '1px solid ' + overColor : '1px solid ' + tableBackground
                                                    }}
                                                        onMouseEnter={() => setBarOverID(item.id)}
                                                        onMouseLeave={() => setBarOverID(-1)}>
                                                        {item.icon != undefined && item.height > barWidth / 2 ?
                                                            <FontAwesomeIcon style={{ height: barWidth / 2, color: '#FFFFFF', opacity: 0.5 }} icon={item.icon} />
                                                            :
                                                            null}
                                                        {item.barText != undefined && item.height > barWidth / 2 ?
                                                            <p style={{ ...styles.TextTiny, color: '#FFFFFF' }}>{item.barText}</p>
                                                            :
                                                            null}
                                                    </div>
                                                    : null}
                                                {/* Popis pod stlpcom */}
                                                <div style={{ ...styles.Block, height: labelHeight, borderTop: '1px solid ' + tableBorderColorBottom, background: labelBackground, borderBottomLeftRadius: item.id == 0 ? tableRadius : 0, borderBottomRightRadius: item.id == items.length - 1 ? tableRadius : 0 }}>
                                                    <p style={{ ...props.isSmall == true ? styles.TextXXXTiny : styles.TextXXTiny, color: barID == item.id ? titleColor : labelColor, textAlign: 'center' }}>{item.label}</p>
                                                </div>
                                            </div>
                                            : null
                                ))
                                : null}
                        </div>
                    </div>
                </div>
                : null}

            {typ == 2 ?
                props.data != null && props.data && isReady == true ?
                    <div style={{ ...styles.Block, marginTop: 5 }}>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.Block, justifyContent: 'flex-end', flexDirection: 'row', width: (props.width - compareTitle) / 2, height: barWidth + 10 }}>
                                {items[0].width <= 100 ?
                                    <div style={{ ...styles.Block, alignItems: 'flex-end', height: barWidth, marginRight: 5 }}>
                                        <p style={{ ...styles.TextSmall, color: items[0].width < 100 ? '#000000' : items[0].textColor == undefined ? valueDefaultColor : items[0].textColor }}>
                                            {items[0].value}
                                            <span style={{ marginLeft: 2 }}>{props.units != undefined ? props.units : ''}</span>
                                        </p>
                                    </div>
                                    : null}
                                <div onClick={(event) => Select(event, items[0], 0)} style={{ ...styles.Block, alignItems: 'flex-end', height: barWidth, width: items[0].width, background: barID == items[0].id ? highColor : items[0].backColor == undefined ? barDefaultColor : items[0].backColor, borderTopLeftRadius: barWidth / 2, borderBottomLeftRadius: barWidth / 2, cursor: 'pointer' }}>
                                    {items[0].width > 100 ?
                                        <div style={{ ...styles.BlockRowRaw, marginRight: 10 }}>
                                            {/* NAJVYSSIA HODNOTA - OZNACENA HVIEZDICKOU  */}
                                            {maxValueID == items[0].id && props.showMax == true && items[0].value != items[1].value ?
                                                <FontAwesomeIcon style={{ width: 15, color: heardColor, marginRight: 5 }} icon={faStar} />
                                                : null}
                                            <p style={{ ...styles.TextSmall, color: items[0].width < 45 ? '#000000' : items[0].textColor == undefined ? valueDefaultColor : items[0].textColor }}>
                                                {items[0].value}
                                                <span style={{ marginLeft: 2 }}>{props.units != undefined ? props.units : ''}</span>
                                            </p>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            <Paper elevation={6} style={{ ...styles.Block, height: barWidth + 10, width: compareTitle, backgroundColor: color.white, borderRadius: 5 }}>
                                <p style={{ ...styles.TextTiny, color: titleColor, fontWeight: '500' }}>{props.title}</p>
                                {props.sub_title != '' && props.sub_title != undefined ?
                                    <p style={{ ...styles.TextXXTiny, marginTop: 2, color: color.dark_gray }}>{props.sub_title}</p>
                                    : null}
                            </Paper>
                            <div style={{ ...styles.Block, justifyContent: 'flex-start', flexDirection: 'row', width: (props.width - compareTitle) / 2, height: barWidth + 10 }}>
                                <div onClick={(event) => Select(event, items[1], 0)} style={{ ...styles.Block, alignItems: 'flex-start', height: barWidth, width: items[1].width, background: barID == items[1].id ? highColor : items[1].backColor == undefined ? barDefaultColor2 : items[1].backColor, borderTopRightRadius: barWidth / 2, borderBottomRightRadius: barWidth / 2, cursor: 'pointer' }}>
                                    {items[1].width > 100 ?
                                        <div style={{ ...styles.BlockRowRaw, marginLeft: 10 }}>
                                            {/* NAJVYSSIA HODNOTA - OZNACENA HVIEZDICKOU  */}
                                            {maxValueID == items[1].id && props.showMax == true && (items[0].value != items[1].value) ?
                                                <FontAwesomeIcon style={{ width: 15, color: heardColor, marginRight: 5 }} icon={faStar} />
                                                : null}
                                            <p style={{ ...styles.TextSmall, color: items[1].width < 100 ? '#000000' : items[1].textColor == undefined ? valueDefaultColor : items[1].textColor }}>
                                                {items[1].value}
                                                <span style={{ marginLeft: 2 }}>{props.units != undefined ? props.units : ''}</span>
                                            </p>
                                        </div>
                                        : null}
                                </div>
                                {items[1].width <= 100 ?
                                    <div style={{ ...styles.Block, alignItems: 'flex-start', height: barWidth, marginLeft: 5 }}>
                                        <p style={{ ...styles.TextSmall, color: items[1].width < 100 ? '#000000' : items[1].textColor == undefined ? valueDefaultColor : items[1].textColor }}>
                                            {items[1].value}
                                            <span style={{ marginLeft: 2 }}>{props.units != undefined ? props.units : ''}</span>
                                        </p>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                    : null
                : null}

            <Popover
                id={0}
                open={showPop}
                anchorEl={anchor}
                onClose={handleClosePop}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ ...styles.Block, width: 150, height: 50, fontFamily: 'roboto' }}>
                    <p style={{ ...styles.TextTiny }}>{barIndex == 0 ? props.tableName != undefined ? props.tableName : '' : props.tableName2 != undefined ? props.tableName2 : ''}</p>
                    <p style={{ ...styles.TextXXTiny, marginTop: 8 }}>{barIndex == 0 ? selectedItem.value : selectedItem.value2}</p>
                </div>
            </Popover>
        </Paper >
    );
}
