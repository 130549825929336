/*
*
* =================================================================================================================
* NASTAVENIE ORGANIZACIE

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <SettingsCustomize organization={organization} user={user} settings={props.settings} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { DialogDates, FormError, FormLabel, FormNote, FormSelect, FormSpace, FormText, FormYesNo, Line, Loading, ShowError, ShowOK } from './items';
import { styles } from './styles';
import { Button, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { GetTodayYear } from './functions_date';
import { Debug, IsEmpty } from './functions';

export const SettingsCustomize = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let permissions = global.permissions;
    let color = global.themes[props.theme];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [storeData, setStoreData] = useState(false);
    const [nameFormat, setNameFormat] = useState(0);

    // ZOZNAM platobných možností
    const [currencies, setCurrencies] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // DEFAULT ITEMS
    const nameItems = [{ id: 0, label: lang.customize_name_1 }, { id: 1, label: lang.customize_name_2 }];

    const column1 = 180;
    const column2 = 370;
    const offset = 20;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings_user', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var item = json.settings;
                if (item == false) {
                    item = global.settings_user_default;
                }
                setStoreData(item);
                AssignData(item);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings_user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setNameFormat(item.name_format);

    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.name_format = nameFormat;
    }

    const GetData = () => {
        // získanie údajov do reťazca
        let data = {
            id: storeData.id,
            organization_id: props.organization.id,
            user_id: props.user.id,
            name_format: nameFormat,
        }

        return data;
    }

    const Save = () => {
        setError('');

        // údaje sú OK - uložiť
        let data = GetData();
        Debug(data);

        StoreEditing();
        setEditing(false);

        db_update(data);

    }

    const FormLabelResult = (value) => {
        if (value == 0) {
            setEditing(true);
        }
    }

    return (
        <div style={{ ...styles.BlockCenter, backgroundColor: color.back, paddingTop: offset, paddingBottom: offset }}>

            {storeData != false ?
                <Paper elevation={global.elevation_form} style={{ ...styles.BlockCenter, width: column1 + column2 + (2 * offset), paddingTop: offset, paddingBottom: offset * 2, backgroundColor: color.white, borderRadius: global.radius }}>

                    <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.application_customize} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                    <FormSelect value={nameFormat} items={nameItems} field={'label'} editing={editing} title={lang.customize_name} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setNameFormat(id)} />

                    {/* SAVE - CANCEL */}
                    {editing == true ?
                        <div style={{ ...styles.Block, paddingTop: 10 }}>
                            <FormError theme={props.theme} error={error} small />

                            <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                </div>
                            </div>
                        </div>
                        : null}

                </Paper>
                : null}

            {props.remote == true && props.remoteMobile == true ?
                <FormSpace height={500} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}
        </div>

    );
}


