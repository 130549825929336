/*
*
* =================================================================================================================
* SELECT NODES
* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import clsx from 'clsx';
import { styles } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { TreeItem, TreeView, useTreeItem } from '@mui/x-tree-view';
import { Typography } from '@mui/material';
import { Debug } from './functions';

export const NodeSelect = (props) => {
    /*
            VYBER KATEGORIE PODLA NODES


            USAGE:
            <NodeSelect nodes={nodes} theme={props.theme} func={NodeSelectResult.bind(this)} />


            PARAMS:
            ----------------------------------------------------------------------------------------------------
            nodes       -> [{ id: 1, label: 'ROOT', parent_id: 0 }, { id: 2, label: 'CHILD - 1', parent_id: 1 }]
            parent_id   -> 0-root


            RESULT:
            ----------------------------------------------------------------------------------------------------
            Selected ID

    */

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // DEFINOVANIE FARIEB
    let selectedColor = color.lighteen_blue;
    let iconPlusColor = color.blue;
    let iconMinusColor = color.dark_gray;

    const [selectedID, setSelectedID] = useState(0);

    let { func } = props;

    useEffect(() => {
        Debug('NODES');
        Debug(props.nodes);
        return () => {
        };

    }, []);

    const NodeClick = (id) => {
        setSelectedID(id);
        func(id);
    }

    const CustomContent = React.forwardRef(function CustomContent(props, ref) {
        const {
            classes,
            className,
            label,
            nodeId,
            icon: iconProp,
            expansionIcon,
            displayIcon,
        } = props;

        const {
            disabled,
            expanded,
            selected,
            focused,
            handleExpansion,
            handleSelection,
            preventSelection,
        } = useTreeItem(nodeId);

        const icon = iconProp || expansionIcon || displayIcon;

        const handleMouseDown = (event) => {
            preventSelection(event);
        };

        const handleExpansionClick = (event) => {
            handleExpansion(event);
        };

        const handleSelectionClick = (event) => {
            handleSelection(event);
        };

        return (
            <div
                className={clsx(className, classes.root, {
                    [classes.expanded]: expanded,
                    [classes.selected]: selected,
                    [classes.focused]: focused,
                    [classes.disabled]: disabled,
                })}
                onMouseDown={handleMouseDown}
                ref={ref}
                style={{ borderRadius: 10, backgroundColor: nodeId == selectedID ? selectedColor : '#00000000' }}
            >
                <div onClick={handleExpansionClick} className={classes.iconContainer}>
                    {icon}
                </div>
                <Typography
                    onClick={handleSelectionClick}
                    component="div"
                    className={classes.label}
                    style={{ ...styles.BlockLeft, marginLeft: 2 }}
                >
                    <div onClick={() => NodeClick(nodeId)} style={{ ...styles.BlockLeft }}>
                        <p style={{ ...styles.TextSmall, fontWeight: nodeId == selectedID ? '600' : 400 }}>{label}</p>
                    </div>
                </Typography>
            </div>
        );
    });

    const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
        return <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />;
    });

    const Node = (props) => {

        let nodes = props.nodes != false ? props.nodes.filter(x => x.parent_id == props.id) : false;

        useEffect(() => {
            return () => {
            };

        }, []);

        function IsEmpty(id) {
            let item = props.nodes.filter(x => x.parent_id == id);
            return item.length == 0 ? true : false;
        }

        return (
            nodes != false ?
                nodes.map((item, index) => (
                    <CustomTreeItem key={item.id} nodeId={String(item.id)} label={item.label}>
                        {IsEmpty(item.id) == false ?
                            <Node nodes={props.nodes} id={item.id}></Node>
                            : null}
                    </CustomTreeItem>
                ))
                : null
        );
    }

    return (
        <TreeView
            defaultCollapseIcon={<FontAwesomeIcon style={{ height: 18, color: iconMinusColor }} icon={faMinusCircle} />}
            defaultExpandIcon={<FontAwesomeIcon style={{ height: 18, color: iconPlusColor }} icon={faPlusCircle} />}
        >
            {props.nodes != false ?
                <Node nodes={props.nodes} id={0}></Node>
                : null}
        </TreeView>
    );
}

