/*

    DATABASE: hp037900

*/

import { faCalendarWeek, faCheck, faCheckSquare, faCodeFork, faCommentSms, faHourglass, faLaptop, faLink, faMessage, faMicrochip, faNewspaper, faPaperPlane, faPlane, faRoute, faS, faShoppingCart, faT, faXmark } from "@fortawesome/free-solid-svg-icons";
import page_icon_1 from './react/app/page_icon_1.png';
import page_icon_2 from './react/app/page_icon_2.png';
import page_icon_3 from './react/app/page_icon_3.png';
import page_icon_4 from './react/app/page_icon_4.png';
import page_icon_5 from './react/app/page_icon_5.png';
import page_icon_6 from './react/app/page_icon_6.png';
import page_icon_7 from './react/app/page_icon_7.png';
import page_icon_8 from './react/app/page_icon_8.png';
import page_icon_9 from './react/app/page_icon_9.png';
import page_icon_10 from './react/app/page_icon_10.png';
import page_icon_11 from './react/app/page_icon_11.png';
import page_icon_12 from './react/app/page_icon_12.png';
import page_icon_13 from './react/app/page_icon_13.png';

// APSTORE VERZIA
global.appstore = false;

// TESTING -> vývoj aplikácie
global.testing = process.env.REACT_APP_VISION_CRM_TEST == 0 ? false : true;

// MENU počas testovania
global.testing_menu = process.env.REACT_APP_VISION_CRM_TEST == 0 ? false : process.env.REACT_APP_VISION_CRM_TEST_MENU == 0 ? false : true;

// SUBODOMENA
global.subdomain = global.testing == true ? '' : process.env.REACT_APP_VISION_CRM_SUB;

// DECODE KEY
global.code_key = '%E<gw=$0]1+W|4!Bt#;@:>.^}Q{K(*elq/G,po39zR&[)-Tr6iy_u8ADM7SJH5NPvIObhZCUFn2YVXjLksmxfdac';

// PRAVIDELNA KONTROLA - seconds
global.check_period = 1000 * 60 * 15; // 15 minút

// RELEASE VERZIA PRE PETKA
global.release = false;

global.max_screen = 1400;
global.max_width = 1050;
global.app_name = 'Vision CRM';

// KEYBOARD PADDING -> ak je zobrazená klávesnica -> padding-bottom
global.keyboard_padding = 500;
global.keyboard_padding_small = 300;
global.empty_padding = 100;
global.list_padding = 150;

// EMAIL - PRILOHA
global.attachment_max = 5 * 1024 * 1024; // maximálny velkosť súborov v bytoch

// DATABAZA
global.web = global.testing == true ? 'https://crm.datadream-test.sk' : global.subdomain == '' ? 'https://demo.vizzie.app' : 'https://' + global.subdomain + '.vizzie.app';
//global.web = 'https://demo.vizzie.app';
global.db_url = global.web + '/app_route/';

// QUIZ - ANKTETA
global.quiz_url = 'https://vizzie.app/quiz/';

// Images
global.web_presentations = global.web + '/';

// AUDIO - VOICES
global.web_voices = global.web + '/public/voices/';

global.web_pdf = global.web + '';
global.web_excel = global.web + '';

// LINK NA UPDATE iPAD APLIKACIE
global.ipad_update_link = 'https://vizzie.app';

// LINK na exportované PDFs
global.pdf_exported = global.web + '';

// WINDOW RADIUS / OFFSET
global.radius = 8;
global.row_radius = 4;
global.offset = 6;

// NAZVY LOKALNE ULOZENYCH SUBOROV
global.filename_settings = '';
global.filename_token = 'apptoken.tmp';

global.items_max = 20;
global.elevation_over = 4;
global.elevation_form = 2;
global.elevation = 1;
global.max_items_on_page = [
    { id: 10, label: '10' },
    { id: 20, label: '20' },
    { id: 35, label: '35' },
    { id: 50, label: '50' },
    { id: 100, label: '100' }
];
global.goto_start_delay = 200;

global.date_start = '2023-01-01';
global.date_endless = '3000-01-01';

// PADDING -> Small screnn
global.smallOffset = 8;

// Cas potrebný na zatvorenie dialogového okna
global.dialog_close_delay = 150

// LISTING - jednotlivé položky - RADIUS
global.items_radius = 8;

// **********************************************************************
// VELKOST DIALOGOVEHO OKNA
// **********************************************************************
global.dialog_max_width = 1200;
global.dialog_max_height = 900;

// **********************************************************************
// TYPY UČTOV
// **********************************************************************
global.account_disabled = 0;

// ******************************************************************************************
// MENU
// ******************************************************************************************
global.menu_width = 260;
global.menu_info_height = 70;
global.statusbar_height = 40;
global.topmenu_height = 42;
global.dashboard_menu_height = 42;
global.status_height = 30;
global.menutitle_height = 150;
global.menu_elevation = 5;
global.theme_menu_selected = '#015FBD';
global.widow_bar_height = 40; // WINDOW TOP BAR
global.dialog_bar_height = 40;

// SUB MENU
global.sub_menu_width = 200; // Sub menu v oknách na lavej strane

// ******************************************************************************************
// PISMO
// ******************************************************************************************
global.font_xxtiny = 12;
global.font_xtiny = 13;
global.font_tiny = 14;
global.font_xsmall = 15;
global.font_small = 16;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_table = 14;


// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 750; //650
global.device_medium = 1200;
global.screen_max = 950;
global.tablet_width = 1200;

// DATAGRID - TABLE
global.theme_table_border_width = 1;

// **********************************************************************
// COMPANY - UDAJE O SPOLOCNOSTI
// **********************************************************************
global.company = {
    name: 'Datadream s.r.o.',
    street: 'Viničná 52/16',
    psc: '951 93',
    town: 'Machulince',
    state: 'Slovensko',
    mobil1: '0905 - 668 294',
    mobil2: '0944 - 411 144',
    call: '0905 - 668 294',
    email: 'info@datadream.sk',
    ico: '54084253',
    dic: '2121566524',
    district: 'Zlaté Moravce',
};

global.app_name = 'Vision CRM';
global.app_name1 = 'Vision';
global.app_name2 = 'CRM';


// **********************************************************************
// ID JEDNOTLIVYCH MODELOV
// **********************************************************************
global.model = {
    organizations: 0,
    users: 1,
    language: 2,
    currency: 3,
    settings: 4,
    conditions: 5,
    permissions: 6,
    users_groups: 7,
    manufacturers: 8,
    vendors: 9,
    exported_data: 10,
    products_categories: 11,
    products: 12,
    products_groups: 13,
    price_list: 14,
    price_list_groups: 15,
    stocks: 16,
    stock_items: 17,
    stock_adjustment: 18,
    stock_transport: 19,
    customers: 20,
    customers_categories: 21,
    customers_person: 22,
    orders: 23,
    customers_groups: 24,
    product_flags: 25,
    customers_address: 26,
    stock_cards: 27,
    file_folders: 28,
    file: 29,
    customer_notes: 30,
    order_types: 31,
    distributors: 32,
    stock_removals: 33,
    approvals: 34,
    contacts: 35,
    stock_orders: 36,
    stock_orders_receive: 37,
    reklamacie: 38,
    presentations: 39,
    quizes: 40,
    quiz_questions: 41,
    routes: 42,
    route_items: 43,
    presentation_items: 44,
    calendar: 45,
    codebook: 46,
    pages: 47,
    page_items: 48,
    gallery: 49,
    gallery_items: 50,
    services: 51,
    services_categories: 52,
}


// **********************************************************************
// MODULE LOGS
// **********************************************************************
global.logs = {
    created: 0,
    modified: 1,
    deleted: 2,
    enabled: 3,
    disabled: 4,
    variation_created: 5,
    variation_modified: 6,
    variation_enabled: 7,
    variation_disabled: 8,
    prices_changed: 9,
    price_cost_changed: 10,
    price_sell_changed: 11,
    variation_prices_changed: 12,
    variation_price_cost_changed: 13,
    variation_price_sell_changed: 14,
    products_transfered: 15,
    canceled: 16,
    transport_approved: 17,
    transport_deny: 18,
    stock_cancel: 19,
    finished: 20,
    created_finished: 21,
    stock_removal_finished: 22,
    approval_ask: 23,
    approval_approved: 24,
    approval_deny: 25,
    created_from_contact: 26,
    company_name: 27,
}

// **********************************************************************
// ORDERING ITEMS - zoradovanie SORT_DOWN, SORT_UP
// **********************************************************************
global.order = {
    up: 'desc',
    down: 'asc'
}

// **********************************************************************
// EXPORT TYPES - typy exportov
// **********************************************************************
global.export_type = {
    pdf: 0,
    xls: 1,
    csv: 2
}

global.export_type_name = [
    'pdf',
    'xls',
    'csv'
]

// **********************************************************************
// IMAGE TYPES
// **********************************************************************
global.image = {
    avatars: 0,
    images: 1,
    products: 2,
    services: 3,
    gallery: 4,
    email: 5,
    others: 6,
    news: 7,
    reklamacia: 8,
    gallery: 9
}


// **********************************************************************
// SYSTEM / USER SETTINGS - DEFAULT VALUES
// **********************************************************************
global.settings_default = {
    id: 0,
    dph: 20,
    use_roles: false,
    roles: false,
    foles_tree: false,
    precision: 0,
    sms_notify: false,
    sms_sender: '',
    sms_unicode: false,
    sms_credit: 0.00,
    product_auto_code: false,
    product_codes: [
        { id: 0, enabled: false, label: '' },
        { id: 1, enabled: false, label: '' },
        { id: 2, enabled: false, label: '' },
        { id: 3, enabled: false, label: 'Vendor' },
        { id: 4, enabled: false, label: 'Ean' },
    ],
    stocks: false,
    stock_default: 0,
    customers_codes: [
        { id: 0, enabled: false, label: '' },
        { id: 1, enabled: false, label: '' },
        { id: 2, enabled: false, label: '' },
    ],
};

global.settings_user_default = {
    id: 0,
    name_format: 0,
};

global.settings_numbers = {
    default_pattern: JSON.stringify({ char1: 0, char2: 1, char3: 0, decimal: 6, pattern: '@year-@number6', prefix: '', value1: 1, value2: 3, value3: 0, example: "2023-000001" }),
};

// STAV OBJEDNAVKY KREDITOV - SMS STATUS
global.sms_credit_status = [faShoppingCart, faHourglass, faCheck, faXmark];

// TRANSAKCIE - upravy skladových zásob
global.transactions = {
    initial: 0,         // prvotný stav skladu pri zakladaní karty produktu
    adjustment: 1,      // úprava skladu
    transport: 2,       // presun skladu
    order: 3,           // objednávky
    stock_removal: 4,   // vyskladnenie zasob
}

// CISELNIKY - ID
global.codebook = {
    reasons: 1,
    reasons_transport: 2,
    product_book_1: 3,
    product_book_2: 4,
    product_book_3: 5,
    reklamacia_reason: 6,
    reklamacia_deny: 7,
    plans: 8,
    blogs: 9,
    newsletter_emails: 10,
    palette: 11,
    product_parameters: 12,
    user_defined: 13
}

// TYPY SKLADOV
global.warehouse_type = {
    company: 0,
    user: 1,
    distributor: 2,
}

// STATUS OBJEDNAVOK
global.orders = {
    created: 0,
    in_proccess: 1,
    cancel_request: 2,
    ready_to_create: 3,
    create_error: 4,
    sending_error: 5,
    ready_to_send: 8,
    finished: 9,
    canceled: 10,
}

// FARBY STAVOV OBJEDNAVOK
global.order_colors = [
    '#E07700', // created           0
    '#00A000', // in_proccess       1    
    '#CA0000', // cancel_request    2
    '#007700', // ready to create   3
    '#D00000', // create error      4
    '#D00000', // sending error     5
    '#CA0000', // error             6
    '', //                          7
    '#007700', // ready to send     8
    '#404040', // finished          9
    '#808080', // canceled          10
]


// FARBA ZNACIEK PRODUKTOV
global.flag_colors = [
    '#000000',
    '#DD0000',
    '#00DD00',
    '#0000DD',
    '#DDDD00',
    '#0088DD',
    '#DD00DD'
];

// VSETKY PODPOROVANE TYPY SUBOROV NA PREVIEW
global.supported_types = [
    'jpg',
    'jpeg',
    'bmp',
    'tif',
    'png',
    'pdf'
];

// PODPOROVANE TYPY OBRAZKOV
global.supported_images = [
    'jpg',
    'jpeg',
    'bmp',
    'tif',
    'png',
];

// PODPOROVANE TYPY OBRAZKOV PRE PRODUKTY
global.supported_products_images = [
    'jpg',
    'jpeg',
    'bmp',
    'png',
];

// VYKRYTIE OBJEDNAVKY - STATUS
global.order_covered = {
    covered: 0,     // vykryté
    partly: 1,      // čiastočne vykryté
    not_covered: 2  // nevykryté
}

// VYKRYTIE OBJEDNAVKY - COLOR
global.order_covered_color = [
    '#00A000',  // vykryté
    '#E07700',  // čiastočne vykryté
    '#D00000',  // nevykryté
]

// PRESUNY produktov - stavy
global.stock_trasports = {
    in_proccess: 0,     // v príprave
    finished: 1,        // presunuté
    canceled: 2         // ZRUSENE
}

// PRESUNY produktov - farby
global.stock_trasport_colors = [
    '#E07700',  // v príprave
    '#404040',  // presunuté
    '#707070',  // zrušene
]

// PRESUNY produktov - stavy
global.stock_adjustment = {
    in_proccess: 0,     // v príprave
    finished: 1,        // ukončené
}

// PRESUNY produktov - farby
global.stock_adjustment_colors = [
    '#E07700',  // v príprave
    '#404040',  // presunuté
]

// VYSKLADNENIE produktov - stavy
global.stock_removals = {
    created: 0,     // v príprave
    finished: 1,        // ukončené
}

// VYSKLADNENIE produktov - farby
global.stock_removals_colors = [
    '#E07700',  // v príprave
    '#404040',  // presunuté
]

// SCHVALOVACIE PROCESY
global.approvals = {
    waiting_approve: 0, // čaká na schválenie
    approved: 1,        // schválene
    deny: 2,            // zamietnuté
}

// PRESUNY produktov - farby
global.approvals_colors = [
    '#0070FF',  // čaká na schválenie
    '#007700',  // schválene
    '#FF0000',  // zamietnuté
]

global.stock_color = [
    '#E0E0E0',
    '#E0F0FF',
    '#F0E0FF'
];

global.stock_color_dark = [
    '#A0A0A0',
    '#A0D0DD',
    '#D0A0DD'
];

// OBJEDNAVKY NA SKLAD - FARBY
global.stock_order_colors = [
    '#E07700',  // v príprave
    '#404040',  // ukončené
]

// OBJEDNAVKY NA SKLAD - CHIP
global.stock_order_chip = [
    '#000000',  // ---
    '#E07700',  // v príprave
    '#404040',  // ukončené
]

// OBJEDNAVKY NA SKLAD - stavy
global.stock_orders = {
    created: 1,     // v príprave
    finished: 2,        // ukončené
}

// PRIJATE OBJEDNAVKY NA SKLAD - stavy
global.stock_orders_received = {
    created: 1,     // v príprave
    finished: 2,        // ukončené
}

// REKLAMACIE - stavy
global.reklamacie = {
    created: 1,         // vytvorená - v príprave
    finished: 2,        // ukončené
}

// TYPY REKLAMACII
global.reklamacia_type = {
    return: 0,       // vrátenie produktov
    change: 1,        // reklamovanie produktov

}

// STAV SCHVALOVANIA
global.reklamacie_approval = [
    '#0707070',  // v procese schvalovania
    '#00A000',  // schválené
    '#A00000',  // zamietnuté
]

// KALENDAR TYPY
global.plans = {
    event: 0, // UDALOST
    task: 1, // ULOHA
    route: 2, // CESTA
    holiday: 3, // DOVOLENKA
};

// KALENDAR - FARBY TERMINOV
global.terminColors = [
    { id: 0, typ: 0, light: '#E7E7E7', dark: '#A0A0A0', text: '#404040', lines: '#D0D0D0' },
    { id: 1, typ: 0, light: '#FFF0E0', dark: '#FFA070', text: '#700000', lines: '#FFD0C0' },
    { id: 2, typ: 0, light: '#F0E7FF', dark: '#D090DF', text: '#703070', lines: '#F0D0FF' },
    { id: 3, typ: 0, light: '#E0FFE0', dark: '#70FF70', text: '#007000', lines: '#C0FFC0' },
    { id: 4, typ: 0, light: '#A0E0FF', dark: '#20A0F0', text: '#204070', lines: '#A0C0FF' },
    { id: 5, typ: 0, light: '#FFFFE0', dark: '#FFFF70', text: '#707000', lines: '#FFFFC0' },
    { id: 6, typ: 1, light: '#FFE0E0', dark: '#FF5050', text: '#700000', lines: '#FFC0C0' }, // ULOHA
    { id: 7, typ: 2, light: '#E0E7FF80', dark: '#7090FF', text: '#003070', lines: '#C0D0FF' }, // TRASA
    { id: 8, typ: 3, light: '#bbbbbb', dark: '#707070', text: '#000000', lines: '#A0A0A0' }, // DOVOLENKA
    { id: 9, typ: 1, light: '#AA2222', dark: '#707070', text: '#FFFFFF', lines: '#A0A0A0' }, // ULOHA - PRIORITA 2
    { id: 10, typ: 1, light: '#A0A0A0', dark: '#707070', text: '#FFFFFF', lines: '#A0A0A0' }, // ULOHA - FINISHED
];
global.caledar_type_icons = [faCalendarWeek, faCheckSquare, faRoute, faPlane];

// DOPRAVA A PLATBA ESHOP
global.eshop = {
    trade: 0,           // DOPRAVA
    payment: 1,         // PLATBA
    payment_gate: 2,    // PLATOBNA BRANA
};

// *********************************************************************************************************
// PLUGINS
// nastavenie pluginov musí byť vložené do Main.js -> db_token_check
// *********************************************************************************************************
global.plugins = [
    {
        // NAOS - SAP
        id: 1, priority: 10, enabled: false, item_id: 0, icon: faLink, typ: 0, name: 'plugin_sap_naos', text: 'plugin_sap_text',
        button: '', button_text: '',
        // NASTAVENIA - hodnoty
        settings: [
            { id: 0, label: 'customers', name: 'plugin_sap_pricelist', type: 'integer', value: 0, note: 'plugin_sap_pricelist_note' },
            { id: 1, label: '', name: 'plugin_sap_esthederm', type: 'integer', value: 0, note: '' },
            { id: 2, label: '', name: 'plugin_sap_bioderma', type: 'integer', value: 0, note: '' },
        ]
    },
    {
        // ESHOP
        id: 2, priority: 20, enabled: false, item_id: 0, icon: faShoppingCart, typ: 0, name: 'plugin_eshop', text: 'plugin_eshop_text',
        button: '', button_text: '',
        settings: false
    },
    {
        // AI ASSISTANT
        id: 3, priority: 30, enabled: false, item_id: 0, icon: faMicrochip, typ: 0, name: 'plugin_ai_assistant', text: 'plugin_ai_assistant_text',
        button: '', button_text: '',
        settings: false
    },
    {
        // SMS
        id: 4, priority: 40, enabled: false, item_id: 0, icon: faCommentSms, typ: 0, name: 'plugin_sms', text: 'plugin_sms_text',
        button: '', button_text: '',
        settings: false
    },
    {
        // PODMIENKY
        id: 5, priority: 50, enabled: false, item_id: 0, icon: faCodeFork, typ: 0, name: 'plugin_conditions', text: 'plugin_conditions_text',
        button: '', button_text: '',
        settings: false
    },
    {
        // MARKETING
        id: 6, priority: 60, enabled: false, item_id: 0, icon: faNewspaper, typ: 0, name: 'plugin_newsletter', text: 'plugin_newsletter_text',
        button: '', button_text: '',
        // NASTAVENIA - hodnoty
        settings: [
            { id: 0, label: 'newsletter_campaign', name: 'newsletter_campaign_plugin_email', type: '', value: '', note: 'newsletter_campaign_plugin_email_note' },
            { id: 1, label: '', name: 'newsletter_campaign_plugin_password', type: 'password', value: '', note: '' },
            { id: 2, label: '', name: 'newsletter_campaign_plugin_max_send', type: 'integer', value: '10', note: 'newsletter_campaign_plugin_max_send_note' },
        ]
    },
    {
        // PHARMINFO
        id: 7, priority: 70, enabled: false, item_id: 0, icon: faPaperPlane, typ: 0, name: 'plugin_pharminfo', text: 'plugin_pharminfo_text',
        button: 'pharminfo_test', button_text: 'plugin_pharminfo_test',
        // NASTAVENIA - hodnoty
        settings: [
            { id: 0, label: 'plugin_pharminfo_site', name: 'plugin_pharminfo_url', type: '', value: 'https://www.pharminfo.sk', note: 'plugin_pharminfo_url_note' },
            { id: 1, label: '', name: 'plugin_pharminfo_endpoint', type: '', value: '', note: 'plugin_pharminfo_endpoint_note' },
            { id: 2, label: 'plugin_pharminfo_logins', name: 'plugin_pharminfo_access_key', type: 'password', value: '', note: '' },
            { id: 3, label: '', name: 'plugin_pharminfo_private_key', type: 'password', value: '', note: '' },
            { id: 4, label: 'plugin_pharminfo_orders', name: 'plugin_pharminfo_orders_watchdog', type: 'yesno', value: '', note: '' },
            { id: 5, label: '', name: 'plugin_pharminfo_orders_watchdog_time', type: 'integer', value: '', note: 'plugin_pharminfo_orders_watchdog_note' },
        ]
    },
    {
        // PHARMOS
        id: 8, priority: 80, enabled: false, item_id: 0, icon: faPaperPlane, typ: 0, name: 'plugin_pharmos', text: 'plugin_pharmos_text',
        button: 'test', button_text: '',
        // NASTAVENIA - hodnoty
        settings: false
    },
    {
        // AUTOMATIZACIA
        id: 9, priority: 90, enabled: false, item_id: 0, icon: faLaptop, typ: 0, name: 'plugin_automation', text: 'plugin_automation_text',
        button: '', button_text: '',
        // NASTAVENIA - hodnoty
        settings: false
    },
    {
        // SAL
        id: 10, priority: 100, enabled: false, item_id: 0, icon: faS, typ: 0, name: 'plugin_sal', text: 'plugin_sal_text',
        button: '', button_text: '',
        // NASTAVENIA - hodnoty
        settings: false
    },
];

// *********************************************************************************************************
// DASHBOARD
// enabled -> je prvok zobrazený v dashboard
// *********************************************************************************************************
global.dashboard = [

    { id: 3, name: 'dashboard_orders_count', enabled: true },       // POCET OBJEDNAVOK
    { id: 4, name: 'dashboard_reklamacie_count', enabled: true },   // POCET OBJEDNAVOK
    { id: 5, name: 'dashboard_plans_count', enabled: true },        // POCET ULOH
    { id: 7, name: 'dashboard_approvals', enabled: true },        // SCHVALOVANIE POZIADAVIEK

    { id: 1, name: 'dashboard_blog', enabled: true },               // AKTUALITY
    { id: 2, name: 'dashboard_calendar', enabled: true },           // KALENDAR - TERMINY
    { id: 6, name: 'dashboard_today_customers', enabled: true },    // POCET ULOH
];

/*
*
* =================================================================================================================
* PAGES
* =================================================================================================================
*
*/

global.page_items = [
    { id: 1, enabled: true, email: true, name: 'web_page_item_title', image: page_icon_9, note: 'web_page_item_title_note', label: true },
    { id: 2, enabled: true, email: true, name: 'web_page_item_text', image: page_icon_1, note: 'web_page_item_text_note', text: true },
    { id: 3, enabled: true, email: true, name: 'web_page_item_html', image: page_icon_8, note: 'web_page_item_html_note', html: true },
    { id: 4, enabled: true, email: true, name: 'web_page_item_image', image: page_icon_4, note: 'web_page_item_image_note', img: true, text_short: true },
    { id: 5, enabled: true, email: false, name: 'web_page_item_banner', image: page_icon_5, note: 'web_page_item_banner_note', banner: true },
    { id: 6, enabled: true, email: false, name: 'web_page_item_text_image', image: page_icon_2, note: 'web_page_item_text_image_note', img: true, label: true, text: true, button: true },
    /*
    { id: 7, enabled: true, eshop: true,name: 'web_page_item_images', image: page_icon_3, note: 'web_page_item_images_note' },
    */
    { id: 8, enabled: true, email: true, name: 'web_page_item_line', image: page_icon_6, note: 'web_page_item_line_note', line: true },
    { id: 9, enabled: true, email: true, name: 'web_page_item_space', image: page_icon_7, note: 'web_page_item_space_note', spacer: true },
    { id: 10, enabled: true, email: true, name: 'web_page_item_link', image: page_icon_10, note: 'web_page_item_link_note', button: true },
    { id: 11, enabled: true, email: true, name: 'web_page_item_product', image: page_icon_11, note: 'web_page_item_product_note', product_img: true, product: true, label: true, text: true, button: true },
    { id: 12, enabled: true, email: false, name: 'web_page_tips', image: page_icon_12, note: 'web_page_tips_note', info: true, label: true, text: true },
    { id: 13, enabled: true, email: false, name: 'web_page_gallery', image: page_icon_13, note: 'web_page_gallery_note', gallery: true, label: true, text: true },
]

/*
*
* =================================================================================================================
* PAGE - MARGINS
* =================================================================================================================
*
*/

global.page_paddings = [
    { id: 0, enabled: true },
    { id: 5, enabled: true },
    { id: 10, enabled: true },
    { id: 20, enabled: true },
    { id: 50, enabled: true },
    { id: 100, enabled: true },
    { id: 150, enabled: true },
]

/*
*
* =================================================================================================================
* PAGE - BORDER
* =================================================================================================================
*
*/

global.page_borders = [
    { id: 0, enabled: true },
    { id: 1, enabled: true },
    { id: 2, enabled: true },
    { id: 3, enabled: true },
    { id: 5, enabled: true },
    { id: 10, enabled: true },
    { id: 16, enabled: true },
    { id: 30, enabled: true },
]

/*
*
* =================================================================================================================
* PAGE - MARGINS
* =================================================================================================================
*
*/

global.page_spacer = [
    { id: 25, enabled: true },
    { id: 30, enabled: true },
    { id: 50, enabled: true },
    { id: 100, enabled: true },
    { id: 150, enabled: true },
    { id: 250, enabled: true },
]

/*
*
* =================================================================================================================
* PAGE - TEXT SIZE
* =================================================================================================================
*
*/

global.page_font_size = [
    { id: 8, enabled: true },
    { id: 12, enabled: true },
    { id: 14, enabled: true },
    { id: 16, enabled: true },
    { id: 20, enabled: true },
    { id: 28, enabled: true },
    { id: 36, enabled: true },
    { id: 48, enabled: true },
    { id: 64, enabled: true },
]

/*
*
* =================================================================================================================
* PAGE - LINE SIZE
* =================================================================================================================
*
*/

global.line_size = [
    { id: 1, enabled: true },
    { id: 2, enabled: true },
    { id: 3, enabled: true },
    { id: 5, enabled: true },
    { id: 10, enabled: true },
]

/*
*
* =================================================================================================================
* PAGE - LINE SIZE
* =================================================================================================================
*
*/

global.image_size = [
    { id: 50, enabled: true },
    { id: 100, enabled: true },
    { id: 150, enabled: true },
    { id: 200, enabled: true },
    { id: 250, enabled: true },
    { id: 300, enabled: true },
    { id: 400, enabled: true },
    { id: 500, enabled: true },
]

/*
*
* =================================================================================================================
* PAGE - RADIUS
* =================================================================================================================
*
*/

global.page_radius = [
    { id: 0, enabled: true },
    { id: 5, enabled: true },
    { id: 10, enabled: true },
    { id: 15, enabled: true },
    { id: 20, enabled: true },
    { id: 30, enabled: true },
    { id: 50, enabled: true },
]

/*
*
* =================================================================================================================
* MAXIMALNA SIRKA PRVKU
* =================================================================================================================
*
*/

global.page_max_width = [
    { id: 0, enabled: true },
    { id: 200, enabled: true },
    { id: 300, enabled: true },
    { id: 400, enabled: true },
    { id: 500, enabled: true },
    { id: 700, enabled: true },
    { id: 800, enabled: true },
    { id: 900, enabled: true },
    { id: 1000, enabled: true },
    { id: 1200, enabled: true },
]


/*
*
* =================================================================================================================
* COLORS - výber farby z palety farieb
* =================================================================================================================
*
*/

global.colors = [
    { id: 1, color: '#000000' },
    { id: 2, color: '#121212' },
    { id: 3, color: '#222222' },
    { id: 4, color: '#323232' },
    { id: 5, color: '#424242' },
    { id: 6, color: '#525252' },
    { id: 7, color: '#626262' },
    { id: 8, color: '#727272' },
    { id: 9, color: '#828282' },
    { id: 10, color: '#B0B0B0' },
    { id: 11, color: '#D0D0D0' },
    { id: 12, color: '#FFFFFF' },

    { id: 13, color: '#941100' },
    { id: 14, color: '#945200' },
    { id: 15, color: '#929000' },
    { id: 16, color: '#4F8F00' },
    { id: 17, color: '#008F00' },
    { id: 18, color: '#009051' },
    { id: 19, color: '#009193' },
    { id: 20, color: '#005493' },
    { id: 21, color: '#011993' },
    { id: 22, color: '#531B93' },
    { id: 23, color: '#942193' },
    { id: 24, color: '#941751' },

    { id: 25, color: '#FF2600' },
    { id: 26, color: '#FF9300' },
    { id: 27, color: '#FFFB00' },
    { id: 28, color: '#8EFA00' },
    { id: 29, color: '#00F900' },
    { id: 30, color: '#00FA92' },
    { id: 31, color: '#00EFEF' },
    { id: 32, color: '#0096FF' },
    { id: 33, color: '#0433FF' },
    { id: 34, color: '#9437FF' },
    { id: 35, color: '#FF40FF' },
    { id: 36, color: '#FF2F92' },

    { id: 37, color: '#FF7E79' },
    { id: 38, color: '#FFD479' },
    { id: 39, color: '#FFFC9A' },
    { id: 40, color: '#D4FB79' },
    { id: 41, color: '#73FA79' },
    { id: 42, color: '#73FCD6' },
    { id: 43, color: '#73FDFF' },
    { id: 44, color: '#76D6FF' },
    { id: 45, color: '#7A81FF' },
    { id: 46, color: '#D783FF' },
    { id: 47, color: '#FF85FF' },
    { id: 48, color: '#FF8AD8' },

    { id: 49, color: '#FFCECC' },
    { id: 50, color: '#FFEECC' },
    { id: 51, color: '#FFFDCC' },
    { id: 52, color: '#EFFECD' },
    { id: 53, color: '#CEFDD0' },
    { id: 54, color: '#CDFEF0' },
    { id: 55, color: '#CCFFFF' },
    { id: 56, color: '#CCF0FF' },
    { id: 57, color: '#CCCFFF' },
    { id: 58, color: '#EECCFF' },
    { id: 59, color: '#FFCCFF' },
    { id: 60, color: '#FFCCEE' },
]


// SPOSOBY EXPORT OBJEDNAVOK
global.export_id = {
    email: 0,
    pharminfo: 1,
    pharmos: 2,
};

// TYPY SLUZIEB
global.services = {
    service: 0,
    trade: 1,
    payment: 2,
    payment_gate: 3,
};

