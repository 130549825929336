/*
*
* =================================================================================================================

    DASHBOARD - nastavenie

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { DeleteButton, DialogInfo, DialogTabs, EmptyList, FormError, FormNote, FormPermissions, FormSelect, FormSpace, FormText, FormYesNo, Icon, FormLabel, Loading, MenuButton, ShowError, ShowInfo, ShowOK, SubMenuButton, TextInput, WebURLPreview, DialogEmail, Line, TextLine, DialogLabel } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, CircularProgress, FormControlLabel, IconButton, MenuItem, Pagination, Paper, Radio, RadioGroup, Select, Tooltip, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faDownload, faFile, faFileAlt, faFileImage, faFloppyDisk, faFolder, faFolderOpen, faInfoCircle, faLayerGroup, faPen, faPlus, faPlusCircle, faTableCells, faTrash, faUpload, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetGBFromBytes, GetLeftPosition, GetMBFromBytes, GetPages, IsEmpty, LoadValue, ResetDashboard, SaveValue, UniqueID } from './functions';
import { GetDatumTime2, Today } from './functions_date';


export const Dashboard = (props) => {

    var lang = props.lang;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = props.permission_column;

    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [needRedraw, setNeedRedraw] = useState(false);

    // PRACOVNA PLOCHA
    const [items, setItems] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 1000;
    const dialogHeight = props.isSmall ? height : 650;
    const rowOffset = 20;
    const rowHeight = 46;
    const buttonHeight = 80;
    const cellWidth = 225;

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();
        }

        return () => {
        };

    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'dashboard', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ---------------------------------------------
                    items: global.dashboard
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);

            if (json.ok > 0) {
                setItems(json.items);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {

        try {
            const response = await fetch(
                global.db_url + 'dashboard_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ---------------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            Debug(json);
            if (json.ok > 0) {
                ResetDashboard(); // VYNUTENIE NACITANIA DASHBOARDU
                setNeedRedraw(true);
            }

        } catch (error) {
            console.log(error);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(needRedraw);
    }

    const ChangeSettings = (item) => {
        Debug(item);
        var value = !item.enabled;
        item.enabled = value;
        let data = {
            id: item.item_id,
            organization_id: props.organization.id,
            user_id: props.user.id,
            dashboard_id: item.id,
            enabled: value
        };
        setRedraw(!redraw);
        db_update(data);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 800 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faTableCells} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.dashboard}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: 100, width: '90%', borderBottom: color.border }}>
                        <DialogLabel label={lang.dashboard_Label} sub_label={lang.dashboard_Label_text} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.BlockCenter, width: '92%', height: dialogHeight - global.dialog_bar_height - buttonHeight - 101, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {items != false ? items.map((item, index) => (
                            (item.id == 1 && props.system.marketing_enabled == true) || (item.id != 1) ?
                                (item.id == 7 && props.user.approve_enabled == true) || item.id != 7 ?
                                    <Paper elevation={1} key={item.id} style={{ ...styles.Block, width: '98%', minHeight: rowHeight, marginTop: 10, backgroundColor: color.white }}>
                                        <div style={{ ...styles.BlockLeft, width: '96%' }}>
                                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang[item.name]}</p>}
                                                control={<Checkbox checked={item.enabled == false ? false : true} size={'small'} onChange={() => ChangeSettings(item)} />}
                                            />
                                        </div>
                                    </Paper>
                                    : null : null
                        )) : null}
                        <FormSpace height={global.list_padding} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>

            {
                isBusy == true ?
                    <Loading theme={props.theme}></Loading>
                    : null
            }

        </Backdrop >
    );
}
