/*
*
* =================================================================================================================

    REGISTRACIA NOVEHO UZIVATELA

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <UserRegister organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={RegisterResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { Avatar, ButtonNew, DialogDates, DialogEnterPin, EmptyList, FormError, FormLabel, FormNote, FormSelect, FormSpace, FormText, FormYesNo, Icon, Line, Loading, SelectInput, ShowError, ShowOK, SuperAdmin, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, FormControlLabel, IconButton, MenuItem, Pagination, Paper, Radio, RadioGroup, Select, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faLayerGroup, faPerson, faPlusCircle, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, GetPages, IsEmpty, IsMobil } from './functions';
import { Today } from './functions_date';

export const UsersSettings = (props) => {
    /*
            // ZOZNAM POUŽÍVATEĽOV - USERS LIST

            USAGE:
            <UsersSettings theme={props.theme} lang={props.lang} radius={props.radius} />

            PARAMS:
    */
    // IMAGES
    const user_default = require('./react/app/user.png');

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let useRoles = props.system.use_roles == false ? false : true;

    let defaultFilter = {
        unlimited: true
    }

    // SYSTEM
    const [isBusy, setBusy] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [roles, setRoles] = useState(false);
    const [roleID, setRoleID] = useState(0);

    // DIALOGS
    const [showUserRegister, setShowUserRegister] = useState(false);

    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 70;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            db_get(0, defaultFilter);
            if (props.system.roles != false) {
                var arr_ = JSON.parse(props.system.roles);
                var arr = [{ id: 0, label: lang.stock_removals_all }];
                arr_.forEach(element => {
                    arr.push(element);
                });
                setRoles(arr)
            }
            running = true;
        }
        return () => {
        };

    }, []);

    const db_get = async (page, filter) => {
        setBusy(true);
        Debug(filter);
        try {
            const response = await fetch(
                global.db_url + 'users', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -----------------------------
                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -----------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Search = (txt) => {
        setSearch(txt);
        var filter = GetFilter();

        if (txt.length > 1) {
            filter.search = txt;
            db_get(0, filter);
        }
        if (txt == '') {
            delete (filter.search);
            db_get(0, filter);
        }
    }

    const SearchReset = () => {
        setSearch('');
        db_get(0, false);
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_users_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const AddNewResult = () => {
        setItemEditing(false);
        setShowUserRegister(true);
    }

    const UserResult = (typ, redraw) => {
        setShowUserRegister(false);
        if (typ == true && redraw == true) {
            db_get(page, filter);
        }

    }

    const SelectItem = (item) => {
        setItemEditing(item);
        setShowUserRegister(true);
    }

    const ChangeRole = (id) => {
        setRoleID(id);
        var filter_ = GetFilter();
        if (id > 0) {
            filter_.role = id;
        } else {
            delete (filter_.role);
        }
        db_get(page, filter_);
    }

    const GetFilter = () => {
        var filter = defaultFilter;
        if (search != '') {
            filter.search = search;
        }
        if (roleID > 0) {
            filter.role = roleID;
        }

        return filter;
    }

    return (
        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
            <div id='id_users_start' style={{ ...styles.Block }}></div>

            <div style={{ ...styles.Block, width: '96%', paddingBottom: global.list_padding }}>

                <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray, marginTop: 25 }}>
                    <div style={{ ...styles.BlockLeft, width: '33%', height: menuHeight }}>
                        <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                    </div>
                    <div style={{ ...styles.BlockRight, width: '34%', height: menuHeight }}>
                        {roles != false && useRoles == true ?
                            <SelectInput redraw theme={props.theme} enabled={true} lang={lang} value={roleID} items={roles} width={250} field={'label'} label={lang.mobil} radius={global.radius} func={(id) => ChangeRole(id)} />
                            : null}
                    </div>
                    <div style={{ ...styles.BlockRight, width: '33%', height: menuHeight }}>
                        {CheckPermission(props.user.super_admin, props.permissions['users']).write == true ?
                            <ButtonNew icon={faPlusCircle} label={lang.user_new} theme={props.theme} func={AddNewResult.bind()} />
                            : null}
                    </div>
                </div>

                <div style={{ ...styles.Block, marginTop: 20 }}>

                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                        <Paper
                            key={item.id}
                            elevation={item.id == overID ? global.elevation_over : global.elevation}
                            onClick={() => SelectItem(item)}
                            style={{
                                ...styles.Block,
                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                borderRadius: global.items_radius,
                                minHeight: rowHeight,
                                cursor: 'pointer',
                                marginTop: 5, marginBottom: 5,
                                paddingTop: 2, paddingBottom: 2,
                                borderLeft: item.enabled == false ? color.disabled_border : '',
                            }}
                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                        >
                            <div style={{ ...styles.BlockRow, width: '100%' }}>
                                <div style={{ ...styles.Block, width: rowHeight + 10, minHeight: rowHeight, justifyContent: 'center' }}>
                                    <Avatar image={item.avatar} size={rowHeight - 10} theme={props.theme} />
                                </div>

                                <div style={{ ...styles.BlockLeft, width: '30%', minHeight: rowHeight, justifyContent: 'center' }}>
                                    {props.settings.name_format == 0 ?
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name} {item.surname}</p>
                                        :
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.surname} {item.name}</p>
                                    }
                                    {useRoles == true ?
                                        item.role_id == -1 ?
                                            <p style={{ ...styles.TextTiny, color: color.error, fontWeight: '600' }}>{lang.users_role_missing}</p>
                                            :
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.role}</p>
                                        : null}
                                </div>

                                <div style={{ ...styles.BlockLeft, width: '30%', minHeight: rowHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextXSmall }}>{item.email}</p>
                                </div>

                                <div style={{ ...styles.BlockLeft, width: '20%', minHeight: rowHeight, justifyContent: 'center' }}>
                                    <Avatar image={item.country} size={30} theme={props.theme} />
                                </div>


                                <div style={{ ...styles.Block, width: 120, minHeight: rowHeight, justifyContent: 'center' }}>
                                    {item.super_admin == false ? null :
                                        <SuperAdmin theme={props.theme} lang={props.lang} />
                                    }
                                </div>
                            </div>
                        </Paper>
                    )) :
                        <div style={{ ...styles.Block, paddingTop: global.empty_padding }}>
                            <EmptyList row small lang={lang} />
                        </div>
                    }

                    {/* PAGINATION */}
                    {pages > 1 ?
                        <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                        </div>
                        : null}
                </div>

            </div>

            {showUserRegister ?
                <UserRegister remote={props.remote} remoteMobile={props.remoteMobile} itemEditing={itemEditing} organization={props.organization} settings={props.settings} system={props.system} locale={props.locale} user={props.user} permissions={props.permissions} lang={lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={UserResult.bind()} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </div>
    );
}

export const UserRegister = (props) => {

    let color = global.themes[props.theme];
    let permissions = global.permissions;
    var lang = props.lang;
    let radius = props.radius;
    const column1 = 200;
    const column2 = 450;
    const offset = 20;

    // IMAGES
    //const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.itemEditing == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState('');

    // DATA
    const [data, setData] = useState(false);

    const [id, setID] = useState(props.itemEditing == false ? 0 : props.itemEditing.id);
    const [enabled, setEnabled] = useState(true);
    const [createStock, setCreateStock] = useState(false);
    const [activated, setActivated] = useState(false);
    const [superAdmin, setSuperAdmin] = useState(false);

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [alias, setAlias] = useState('');
    const [phone, setPhone] = useState('');
    const [mobil, setMobil] = useState('');
    const [email, setEmail] = useState('');
    const [birthdate, setBirthdate] = useState(Today());
    const [street, setStreet] = useState('');
    const [town, setTown] = useState('');
    const [psc, setPSC] = useState('');
    const [state, setState] = useState('');
    const [roleID, setRoleID] = useState(0);
    const [permissionsID, setPermissionsID] = useState(0);
    const [themeID, setThemeID] = useState(0);
    const [avatar, setAvatar] = useState('');
    const [languageID, setLanguageID] = useState(0);
    const [approveEnabled, setApproveEnabled] = useState(false);

    const [permissionsItems, setPermissions] = useState([]);
    const [rolesItems, setRoles] = useState([]);
    const [useRoles, setUseRoles] = useState(props.system.use_roles == false ? false : true);

    const [languages, setLanguages] = useState([]);

    const [stocks, setStocks] = useState(false);
    const [stockID, setStockID] = useState(0);

    const [calendarTypes, setCalendarTypes] = useState(false);


    const [countries, setCountries] = useState(props.organization.currencies == false ? [] : props.organization.currencies);
    const [countryID, setCountryID] = useState(props.locale.id);
    const [countryChange, setCountryChange] = useState(true);


    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= column1 + column2 + (4 * offset) ? column1 + column2 + (4 * offset) : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;
    const buttonHeight = 120;

    let { func } = props;
    var running = false;

    useEffect(() => {
        Debug('props.locale');
        Debug(props.locale.id);
        if (running == false) {

            if (props.itemEditing == false) {
                // NOVY ZAZNAM - DEFAULT VALUES
                let data = {
                    enabled: true,
                    super_admin: false,
                    name: '',
                    surname: '',
                    alias: '',
                    phone: '',
                    mobil: '',
                    email: '',
                    birthdate: null,
                    street: '',
                    town: '',
                    psc: '',
                    state: '',
                    role_id: -1,
                    permissions_id: -1,
                    theme_id: 0,
                    avatar: '',
                    language_id: props.organization.language_id,
                    country_id: props.locale.id,
                    country_change: true,
                    stock_default: props.locale.stock_default,
                    approve_enabled: false,
                    calendar_types: false,
                }

                AssignData(data);
                setData(data);
            } else {
                // UPRAVA ZAZNAMU
                db_get();
            }
            db_get_data(countryID);

            running = true;
        }

    }, []);

    const db_get_data = async (country_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: country_id
                    // ---------------------------------------
                })
            })

            const json = await response.json();
            Debug('USER DATA');
            Debug(json);
            if (json.ok > 0) {
                setRoles(json.roles);
                setPermissions(json.permissions);
                setLanguages(json.languages);
                var tmp = json.stocks;
                tmp.push({ id: 0, name: lang.stock_user_none });
                setStocks(json.stocks);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ---------------------------------------
                    id: props.itemEditing.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setData(json.user);
                AssignData(json.user);
                setActivated(json.user.activated == false ? false : true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -----------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_check = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'users_check_email', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ------------------------------------
                    id: id,
                    email: email,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.count == 0) {
                    let data = GetData();
                    StoreEditing();
                    setEditing(false);
                    Debug(data);
                    db_update(data);
                } else {
                    setError(lang.email_exists);
                    setBusy(false);
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_invite = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user_invite', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ------------------------------------
                    id: id
                })
            })

            const json = await response.json();
            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }



    const ShowErrorPress = () => {
        setShowError(false);
    }

    const ShowOKPress = () => {
        setShowOK(false);
        Press(true, true);
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(false, false);
    }

    const FormLabelResult = (value) => {
        if (value == 0) {
            if (CheckPermission(props.user.super_admin, props.permissions['users']).edit == true) {
                setEditing(true);
            }
        }
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        if (props.itemEditing == false) {
            Close();
        } else {
            AssignData(data);
            setEditing(false);
        }
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB - Zmena Byte To Array
        setEnabled(item.enabled == false ? false : true);
        setSuperAdmin(item.super_admin == false ? false : true);
        setName(item.name);
        setSurname(item.surname);
        setAlias(item.alias);
        setPhone(item.phone);
        setMobil(item.mobil);
        setEmail(item.email);
        setBirthdate(item.birthdate);
        setStreet(item.street);
        setTown(item.town);
        setPSC(item.psc);
        setState(item.state);
        setRoleID(item.role_id);
        setPermissionsID(item.permissions_id);
        setThemeID(item.theme_id);
        setAvatar(item.avatar);
        setLanguageID(item.language_id);
        setStockID(item.stock_default);
        setCountryID(item.country_id);
        setCountryChange(item.country_change == false ? false : true);
        setApproveEnabled(item.approve_enabled == false ? false : true);
        setCalendarTypes(item.calendar_types == false ? false : true);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti - Zmena Array To Byte        
        data.enabled = enabled;
        data.super_admin = superAdmin;
        data.name = name;
        data.surname = surname;
        data.alias = alias;
        data.email = email;
        data.phone = phone;
        data.mobil = mobil;
        data.birthdate = birthdate;
        data.street = street;
        data.town = town;
        data.psc = psc;
        data.state = state;
        data.role_id = roleID;
        data.permissions_id = permissionsID;
        data.theme_id = themeID;
        data.avatar = avatar;
        data.language_id = languageID;
        data.stock_default = stockID;
        data.country_id = countryID;
        data.country_change = countryChange;
        data.approve_enabled = approveEnabled;
        data.calendar_types = calendarTypes;
    }

    const GetData = () => {
        // získanie údajov do reťazca
        let data = {
            id: id,
            organization_id: props.organization.id,
            enabled: enabled == false ? false : true,
            language_id: languageID,
            super_admin: superAdmin == false ? false : true,
            name: name,
            surname: surname,
            alias: alias,
            email: email,
            phone: phone,
            mobil: mobil,
            birthdate: birthdate == Today() ? null : birthdate,
            street: street,
            town: town,
            psc: psc,
            state: state,
            role_id: roleID,
            permissions_id: permissionsID,
            theme_id: themeID,
            avatar: avatar,
            stock_default: stockID,
            country_id: countryID,
            country_change: countryChange,
            approve_enabled: approveEnabled,
            create_stock: createStock,
            calendar_types: calendarTypes,
        }

        return data;
    }

    const Save = () => {
        setError('');

        var err = 0;
        var error = false;
        err += IsEmpty(name) ? 1 : 0;
        err += IsEmpty(surname) ? 1 : 0;
        err += IsEmpty(email) ? 1 : 0;
        err += IsMobil(mobil) ? 0 : 1;

        if (countryID == 0) {
            err++;
        }

        if (permissionsID == -1) {
            err++;
        }
        if (useRoles == true) {
            if (roleID == -1) {
                err++;
            }
        }

        if (err == 0) {
            // Vsetko OK
            db_check();
        } else {
            // pri kontrole sa našla chyba
            setError(lang.required_red);
        }
    }

    const Invite = () => {
        // ODOSLAŤ POZVÁNKU
        if (id > 0) {
            db_invite();
        }
    }

    const ChangeCountry = (id) => {
        setCountryID(id);
        setStockID(-1);
        /*
        let tmp = countries.find(x => x.id == id);
        if (tmp != undefined) {
            setStockID(tmp.stock_default);
        }
        */
        db_get_data(id);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faUser} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.itemEditing == false ? lang.user_new : lang.user}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {data != false ?
                            props.itemEditing == false ?
                                <div style={{ ...styles.BlockCenter, width: column1 + column2 + (2 * offset), paddingTop: offset, paddingBottom: offset * 2, backgroundColor: color.back }}>
                                    <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.user_info} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                    <FormSpace />

                                    <FormSelect require value={countryID} items={countries} field={'state'} editing={editing} title={lang.app_country} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeCountry(id)} />
                                    {countries.length > 1 ?
                                        <div>
                                            <FormYesNo value={countryChange} editing={editing} title={lang.app_country_change} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCountryChange(txt)} />
                                            <FormNote title={lang.app_country_change_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                        </div>
                                        : null}
                                    <FormSpace />

                                    <FormText require value={name} editing={editing} title={lang.name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                    <FormText require value={surname} editing={editing} title={lang.surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSurname(txt)} />
                                    <FormText require value={email} editing={editing} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />
                                    <FormSpace />

                                    {useRoles == true ?
                                        <FormSelect require value={roleID} items={rolesItems} field={'label'} editing={editing} title={lang.users_role} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setRoleID(id)} />
                                        : null}

                                    <FormSelect require value={permissionsID} items={permissionsItems} field={'label'} editing={editing} title={lang.permissions} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setPermissionsID(id)} />

                                    <FormYesNo value={createStock} editing={editing} title={lang.user_register_stock} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCreateStock(txt)} />
                                    <FormNote title={lang.user_register_stock_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                </div>
                                :
                                <div style={{ ...styles.BlockCenter, width: column1 + column2 + (2 * offset), paddingTop: offset, paddingBottom: offset * 2, backgroundColor: color.back }}>
                                    <FormLabel editing={editing} icon={faInfoCircle} editButton={CheckPermission(props.user.super_admin, props.permissions['users']).edit == true ? true : false} title={lang.user_info} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                    <FormYesNo value={enabled} editing={editing} title={lang.user_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEnabled(txt)} />
                                    <FormYesNo value={superAdmin} editing={editing} title={lang.super_admin} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSuperAdmin(txt)} />
                                    <FormSelect require value={countryID} items={countries} field={'state'} editing={editing} title={lang.app_country} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeCountry(id)} />

                                    {countries.length > 1 ?
                                        <div>
                                            <FormYesNo value={countryChange} editing={editing} title={lang.app_country_change} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCountryChange(txt)} />
                                            <FormNote title={lang.app_country_change_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                        </div>
                                        : null}
                                    <FormSpace />
                                    <FormText require value={name} editing={editing} title={lang.name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                    <FormText require value={surname} editing={editing} title={lang.surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSurname(txt)} />
                                    <FormText value={alias} editing={editing} title={lang.alias} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setAlias(txt)} />
                                    <FormSpace />
                                    <FormText require value={email} editing={editing} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />
                                    <FormText phone value={mobil} editing={editing} title={lang.mobil} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setMobil(txt)} />
                                    {editing == true ?
                                        <FormNote title={lang.mobil_international} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                        : null}
                                    <FormText value={phone} editing={editing} title={lang.phone} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPhone(txt)} />
                                    <FormSpace />
                                    <FormText date value={birthdate} editing={editing} title={lang.birtdate} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setBirthdate(txt)} />

                                    {/* ADRESA */}
                                    <FormLabel editing={editing} title={lang.address} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />

                                    <FormText value={street} editing={editing} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setStreet(txt)} />
                                    <FormText value={town} editing={editing} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setTown(txt)} />
                                    <FormText value={psc} editing={editing} title={lang.psc} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPSC(txt)} />
                                    <FormText value={state} editing={editing} title={lang.state} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setState(txt)} />

                                    {/* POZICIA */}
                                    {useRoles == true ?
                                        <>
                                            <FormLabel editing={editing} title={lang.users_role} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                            <FormSelect require value={roleID} items={rolesItems} field={'label'} editing={editing} title={lang.users_role} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setRoleID(id)} />
                                        </>
                                        : null}
                                    {/* UZIVATELSKY SKLAD */}
                                    {stocks != false ?
                                        <div style={{ ...styles.Block }}>
                                            <FormLabel editing={editing} title={lang.warehouse_products} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                            <FormSelect require value={stockID} items={stocks} field={'name'} editing={editing} title={lang.stock_user} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setStockID(id)} />
                                        </div>
                                        : null}

                                    {/* POVOLENIA */}
                                    <FormLabel editing={editing} title={lang.permissions} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                    <FormSelect require value={permissionsID} items={permissionsItems} field={'label'} editing={editing} title={lang.permissions} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setPermissionsID(id)} />

                                    {/* NASTAVENIA - JAZYK APLIKACIE */}
                                    <FormLabel editing={editing} title={lang.settings} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                    <FormYesNo help_id={19} value={approveEnabled} editing={editing} title={lang.approvals_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setApproveEnabled(txt)} />
                                    {approveEnabled == false ?
                                        <FormNote title={lang.approvals_enabled_note_0} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                        :
                                        <FormNote title={lang.approvals_enabled_note_1} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                    }
                                    <FormYesNo value={calendarTypes} editing={editing} title={lang.calendar_types_use} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCalendarTypes(id)} />
                                    <FormNote title={lang.calendar_types_use_note} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormSelect require value={languageID} items={languages} field={'state'} editing={editing} title={lang.app_language} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setLanguageID(id)} />

                                    <FormSpace />
                                    {data != false && props.itemEditing != false && editing == false ?
                                        <div style={{ ...styles.BlockLeft, width: column1 + column2 + (2 * offset), paddingTop: 30, paddingBottom: 0 }}>
                                            <DialogDates created={data.created} updated={data.updated} updated_by={data.user} lang={props.lang} theme={props.theme} />
                                        </div>
                                        : null}


                                    {props.remote == true && props.remoteMobile == true ?
                                        <FormSpace height={500} />
                                        : null}

                                </div>
                            : null}
                    </div >

                    <div style={{ ...styles.Block, height: buttonHeight }}>

                        {/* SAVE - CANCEL */}
                        {editing == true ?
                            <div style={{ ...styles.Block }}>
                                <div style={{ ...styles.Block, height: 40 }}>
                                    <FormError theme={props.theme} error={error} small />
                                </div>

                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.Block, width: 200, height: buttonHeight - 40 }}>
                                        <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                                    </div>
                                    <div style={{ ...styles.Block, width: 200, height: buttonHeight - 40 }}>
                                        <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                    </div>
                                </div>
                            </div>
                            : null}

                        {/* ODOSLAŤ POZVANKU */}
                        {editing == false && props.itemEditing != false && activated == false ?
                            <div style={{ ...styles.Block, height: buttonHeight }}>
                                <div style={{ ...styles.Block, height: 40 }}>
                                    <FormError theme={props.theme} error={error} small />
                                </div>
                                <div style={{ ...styles.Block, height: buttonHeight - 40 }}>
                                    <Button onClick={() => Invite()} style={{ ...styles.ButtonThemed, color: color.black, backgroundColor: color.button_action }}>{lang.user_invite}</Button>
                                </div>
                            </div>
                            : null}

                    </div>

                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


