/*
*
* =================================================================================================================
* WINDOW

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';

import clsx from 'clsx';
import { ButtonNew, Icon, Loading, MenuButton, TextInput, } from './items';
import { styles } from './styles';
import { Button, Checkbox, FormControlLabel, IconButton, Paper, Typography, rgbToHex, selectClasses, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faLayerGroup, faMinusCircle, faMinusSquare, faPlus, faPlusCircle, faPlusSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { BinaryToPermission, Debug, FormatMoney, GetFunctionID, OpenAIChat, PermissionToBinary } from './functions';
import { Photos } from './photos';
// DragAndDrop
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { setItem } from 'localforage';
import { GoogleMap } from './geolocation';
import { DialogOpenAI } from './dialog_openai';

export const Test = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let nodes = [{ id: 1, label: 'ROOT', parent_id: 0 }, { id: 2, label: 'CHILD - 1', parent_id: 1 }, { id: 3, label: 'CHILD - 2', parent_id: 1 }, { id: 4, label: 'PARENT - 2', parent_id: 0 }, { id: 5, label: 'CHILD - 3', parent_id: 2 }]

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    // VALUES
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [answerFunc, setAnswerFunc] = useState(0);
    const [answerParams, setAnswerParams] = useState(false);
    const [answerKeys, setAnswerKeys] = useState(false);
    const [history, setHistory] = useState([]);
    const [execute, setExecute] = useState(false);
    const [tokens, setTokens] = useState(false);
    const [tokensRequest, setTokensRequest] = useState(false);
    const [message, setMessage] = useState('');
    const [showDialog, setShowDialog] = useState(false);

    const maxWidth = 800;

    const costSended = 0.5; // Cena v $ za odchádzajúce
    const costReceived = 1.5; // Cena v $ za odchádzajúce

    let { func } = props;
    var running = false;
    const inputRef = useRef(null);

    useEffect(() => {
        if (running == false) {
            running = true;

            setTimeout(() => {
                inputRef.current.focus();
            }, 300);
        }

    }, []);

    const db_get = async (data, tutorial) => {
        if (tutorial == true) {
            Debug('Hľadanie v návode');
        } else {
            Debug('Údaje v aplikácii');
        }
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'openai_function', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    history: data,
                    tutorial: tutorial
                })
            })

            const json = await response.json();
            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                var error = false;

                if (json.answer == '' || json.function_id == 0) {
                    error = true;
                }

                setAnswer(json.answer == '' ? 'Chyba' : JSON.stringify(json.answer));
                setAnswerFunc(json.answer == '' ? 0 : json.function_id);
                setTokensRequest(json.tokens);
                setTokens(json.ai_response == undefined ? 0 : json.ai_response.api.usage != undefined ? json.ai_response.api.usage : false);
                setMessage(json.ai_response == undefined ? 0 : json.ai_response.api.error == undefined ? json.ai_response.api.choices[0].message.content : json.ai_response.api.error.message);

                let keys = json.answer == '' ? false : Object.keys(json.arguments);
                let params = json.answer == '' ? false : json.arguments;
                setAnswerKeys(keys);
                setAnswerParams(params);
                setRedraw(!redraw);

                Debug('params');
                Debug(params);

                if (error == false) {
                    if (execute == true) {
                        func(true, json.function_id, params)
                    }
                }

            }

        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    }

    const Close = () => {
        func(false);
    }

    const AskAI = (txt) => {
        var tutorial = txt.includes('?') ? true : false;

        var result = OpenAIChat([], txt.trim(), '', lang, false); // OTAZKA
        setHistory(result);
        setQuestion('');

        db_get(result, tutorial);
    }

    const ResetOpenAI = () => {
        setHistory([]);
        setAnswer('');
        setQuestion('');
        setAnswerFunc(false);
        setAnswerKeys(false);
        setAnswerParams(false);

        setTimeout(() => {
            inputRef.current.focus();
        }, 300);
    }

    const ButtonResult = (index) => {
        switch (index) {
            case 0:
                Debug('Click - menu 1')
                break;

            case 1:
                Debug('Click - menu 2')
                break;

        }
    }

    const TextColumns = (props) => {
        return (
            <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 0 : props.marginTop }}>
                <div style={{ ...styles.BlockLeft, width: 100 }}>
                    <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{props.text1}</p>
                </div>
                <div style={{ ...styles.BlockLeft, width: 100 }}>
                    <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{props.text2}</p>
                </div>
            </div>
        );
    }
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <MenuButton icon={faPlusCircle} label={lang.user_new} menuItems={[{ id: 0, label: lang.export_pdf }, { id: 1, label: lang.export_xls }]} theme={props.theme} func={ButtonResult.bind()} />
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - 100 }}>
                        <div style={{ ...styles.BlockRight, width: maxWidth, marginBottom: 5, marginTop: 10 }}>
                            <IconButton onClick={() => ResetOpenAI()} style={{ ...styles.ButtonIcon }}>
                                <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                            </IconButton>
                        </div>
                        <Paper style={{ ...styles.Block, width: maxWidth, height: 120, justifyContent: 'flex-start', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                <p style={{ ...styles.TextXSmall, marginTop: 2, fontWeight: '600', textAlign: 'left' }}>{lang.openai_answer}</p>
                                <p style={{ ...styles.TextXSmall, marginTop: 5, textAlign: 'left' }}>{answer}</p>
                            </div>
                        </Paper>
                        <div style={{ ...styles.BlockLeft, width: maxWidth, height: 160 }}>
                            <div style={{ ...styles.BlockRow }}>
                                {tokensRequest != false ?
                                    <div style={{ ...styles.BlockLeft, width: '33%' }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{'Request type'}:</p>
                                        <TextColumns text1={'Tokens total:'} text2={tokensRequest.total_tokens} marginTop={5} />
                                        <TextColumns text1={'sended:'} text2={tokensRequest.prompt_tokens} />
                                        <TextColumns text1={'received:'} text2={tokensRequest.completion_tokens} />
                                        <TextColumns text1={'cena:'} text2={((tokensRequest.prompt_tokens * ((1 / 1000000) * costSended)) + (tokensRequest.completion_tokens * ((1 / 1000000) * costReceived))).toFixed(6) + ' $'} />
                                    </div>
                                    : null}
                                {tokens != false ?
                                    <div style={{ ...styles.BlockLeft, width: '33%' }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{'Request final'}:</p>
                                        <TextColumns text1={'Tokens total:'} text2={tokens.total_tokens} marginTop={5} />
                                        <TextColumns text1={'sended:'} text2={tokens.prompt_tokens} />
                                        <TextColumns text1={'received:'} text2={tokens.completion_tokens} />
                                        <TextColumns text1={'cena:'} text2={((tokens.prompt_tokens * ((1 / 1000000) * costSended)) + (tokens.completion_tokens * ((1 / 1000000) * costReceived))).toFixed(6) + ' $'} />
                                    </div>
                                    : null}
                                {tokensRequest != false && tokens != false ?
                                    <div style={{ ...styles.BlockLeft, width: '33%', border: color.border, padding: 5 }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{'Sumar'}:</p>
                                        <TextColumns text1={'Tokens total:'} text2={tokens.total_tokens + tokensRequest.total_tokens} marginTop={5} />
                                        <TextColumns text1={'sended:'} text2={tokens.prompt_tokens + tokensRequest.prompt_tokens} />
                                        <TextColumns text1={'received:'} text2={tokens.completion_tokens + tokensRequest.completion_tokens} />
                                        <TextColumns text1={'cena:'} text2={(((tokens.prompt_tokens + tokensRequest.prompt_tokens) * ((1 / 1000000) * costSended)) + ((tokens.completion_tokens + tokensRequest.completion_tokens) * ((1 / 1000000) * costReceived))).toFixed(6) + ' $'} />
                                    </div>
                                    : null}
                            </div>

                            <p style={{ ...styles.TextXSmall, marginTop: 5, textAlign: 'left' }}>{'Message'}: {message == null ? '' : message}</p>

                        </div>
                        <Paper style={{ ...styles.Block, width: maxWidth, height: 250, justifyContent: 'flex-start', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {answerFunc != false ?
                                <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                    <p style={{ ...styles.TextXSmall, marginTop: 2, fontWeight: '600', textAlign: 'left' }}>Funkcia: {answerFunc}</p>
                                    <p style={{ ...styles.TextXSmall, marginTop: 2, fontWeight: '600', textAlign: 'left' }}>ID: {GetFunctionID(answerFunc)}</p>
                                    {answerKeys != false && answerParams != false ? answerKeys.map((item, index) => (
                                        <p key={index} style={{ ...styles.TextXSmall, marginTop: 5, textAlign: 'left' }}>{item}: {JSON.stringify(answerParams[item])}</p>
                                    )) : null}
                                </div>
                                : null}
                        </Paper>
                        {/*
                        <FormControlLabel style={{ marginTop: 10 }} label={<p style={{ ...styles.TextSmall }}>Vykonanie príkazu po odoslaní požiadavky</p>}
                            control={<Checkbox checked={execute} size={'small'} onChange={() => setExecute(!execute)} />}
                        />
                        */}
                        <div style={{ ...styles.Block, width: maxWidth, marginTop: 10 }}>
                            <TextInput inputRef={inputRef} redraw enter lang={props.lang} value={question} label={lang.openai_request} func={(txt) => { AskAI(txt) }} />
                        </div>
                    </div >

                    <div style={{ ...styles.Block, height: 50 }}>
                        {answerFunc > 0 ?
                            <Button draggable onClick={() => func(true, answerFunc, answerParams, question)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_dark }}>Vykonať príkaz</Button>
                            : null}
                    </div>
                </div >
            </div >

            {showDialog == true ?
                <DialogOpenAI organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={'open_ai'} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={() => setShowDialog(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}


        </Paper >
    );
}
