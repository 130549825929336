/*
*
* =================================================================================================================

    AKTIVACIA NOVEHO UZIVATELA - zadanie hesla

* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { ButtonNew, DialogDates, EmptyList, FormError, FormLabel, FormSelect, FormSpace, FormText, FormYesNo, Icon, Line, Loading, ShowError, ShowOK, SuperAdmin, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, FormControlLabel, IconButton, MenuItem, Paper, Radio, RadioGroup, Select, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown, faInfoCircle, faLayerGroup, faPerson, faPlusCircle, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, GetLanguage, GetPages, IsEmpty, SaveLogin, paswordCheck } from './functions';
import { Today } from './functions_date';
import { sk } from './language_sk';
import { useNavigate, useParams } from 'react-router-dom';


export const LoginRemote = (props) => {

    const navigate = useNavigate();
    const logo = require('./react/app/logo_vision.png');

    let radius = global.radius;
    let color = global.themes[0];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [language, setLanguage] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // PARAMS
    const [user, setUser] = useState(false);
    const [linkError, setLinkError] = useState(false);
    const [password, setPassword] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let { func } = props;
    let params = useParams();
    var running = false;
    var lang = GetLanguage(language);

    const inputRef = useRef(null);

    useEffect(() => {
        if (running == false) {
            Debug('Remote Login');
            db_get();
            running = true;
        }

        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'login_remote', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: params.token
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.login == true) {
                    SaveLogin({ token: json.token, expire: 0 });
                    navigate('/application/remote/' + params.version);
                } else {
                    navigate('/logout');
                }
            } else {
                navigate('/logout');
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            navigate('/logout');
            setBusy(false);
        }
    }


    return (
        <div style={{ ...styles.Block, height: height, fontFamily: 'roboto', msOverflowStyle: 'none', scrollbarWidth: 'none', backgroundColor: color.desktop_background, backgroundSize: 'cover' }} className='container'>

            {isBusy == true ?
                <Loading center></Loading>
                : null}

        </div>
    );
}
