/*
*
* =================================================================================================================

    Blog

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    public  -> verejný blog

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { Avatar, DialogTabs, EmptyList, FormSpace, FormText, Icon, Loading, FormLabel, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, FormYesNo, FormError, TextLine, DeleteButton, DialogLabel, FormSelect, FormColors, FormAlign, Line } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, colors, FormControlLabel, IconButton, Pagination, Paper, rgbToHex, Toolbar, Tooltip, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa1, fa2, faAlignCenter, faAlignLeft, faAlignRight, faArrowLeft, faArrowRight, faBold, faCheck, faCheckCircle, faCircle, faCog, faEye, faFileLines, faImage, faInfoCircle, faItalic, faLayerGroup, faList, faMessage, faNewspaper, faPen, faPlusCircle, faQuestion, faT, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetDatum2, GetTodayYear, Today } from './functions_date';
import { CheckPermission, Debug, GetImageLink, GetLeftPosition, GetPages, IsEmpty } from './functions';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DialogColors, PageItemRender, WebPageDesigner } from './webpage_designer.js';
import DOMPurify from 'dompurify';

export const Newsletters = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'newsletter';
    let publicBlog = props.public == true ? true : false;
    let publicInternal = props.internal == true ? true : false;

    let defaultFilter = {
        enabled: true,
        public: publicBlog,
        internal: publicInternal,
        published: true,
        country_id: props.locale.id
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST    
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // VALUES
    const [value, setValue] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 100;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            setTabIndex(0);
            db_get(0, defaultFilter);

            running = true;
        }

    }, [props.locale.id]);


    const db_get = async (page, filter) => {
        Debug('FILTER');
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'newsletters', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GetFilter = () => {
        var filter_ = defaultFilter;

        if (tabIndex == 0) {
            filter_.published = true;
        } else {
            filter_.published = false;
        }

        return filter_;
    }
    const Search = (txt) => {
        setSearch(txt);
        var filter_ = GetFilter();

        if (txt.length > 1) {
            filter_.search = txt;
            db_get(0, filter_);
        }
        if (txt == '') {
            delete (filter_.search);
            db_get(0, filter_);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);
        if (id == 2) {

        } else {
            var filter_ = GetFilter();

            if (id == 0) {
                filter_.published = true;
            } else {
                filter_.published = false;
            }

            db_get(0, filter_);
        }

    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }


    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const BlogResult = (redraw_, published) => {
        setShowDialog(false);

        if (redraw_ == true) {
            filter.published = published;
            setTabIndex(published == true ? 0 : 1);
            db_get(page, filter);
        }
    }

    const Preview = (item) => {
        setItemSelected(item);
        setShowPreview(true);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faNewspaper} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                            <div style={{ ...styles.Block, width: '96%' }}>
                                <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                        <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add }}>
                                                <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                {lang.newsletter_new}
                                            </Button>
                                            : null}
                                    </div>
                                </div>

                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block,
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '72%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, marginBottom: 8 }}>{GetDatum2(item.date)}</p>
                                                    <TextLine text={item.name} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                    <TextLine text={item.note} fontSize={global.font_tiny} color={color.black} />
                                                </div>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {/*
                                                    <Chip variant='outlined' size='small' label={item.published == false ? lang.blog_not_published : lang.blog_published} style={{ color: item.published == false ? color.dark_red : color.dark_green, borderColor: item.published == false ? color.dark_red : color.dark_green }} />
                                                    */}
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 100, minHeight: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <Chip onClick={() => Preview(item)} variant='filled' size='medium' label={lang.web_page_item_preview} style={{ backgroundColor: color.light_gray, borderLeft: color.border }} />
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }

                                    {/* PAGINATION */}
                                    {pages > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                        </div>
                                        : null}

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {showPreview == true ?
                <NewsletterPreview newsletterID={itemSelected.id} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowPreview(false)} />
                : null}

            {showDialog == true ?
                <Newsletter public={publicBlog} internal={publicInternal} item={itemSelected} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} permissions={props.permissions} func={BlogResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


export const Newsletter = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'blog_public';

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');
    const background_1 = require('./react/app/page_background_1.png');
    const background_2 = require('./react/app/page_background_2.png');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [redrawRequired, setRedrawRequired] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // CLIPBOARD
    const [showCopied, setShowCopied] = useState(false);

    // DATA LIST
    const [newsletterLink, setNewsletterLink] = useState('');
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [pageID, setPageID] = useState(0);
    const [enabled, setEnabled] = useState(true);
    const [uid, setUID] = useState('');
    const [name, setName] = useState('');
    const [labelColor, setLabelColor] = useState('#000000');
    const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
    const [borderRadius, setBorderRadius] = useState(0);
    const [text, setText] = useState('');
    const [textColor, setTextColor] = useState('#404040');
    const [textAlign, setTextAlign] = useState(1);
    const [attachment, setAttachment] = useState('');
    const [attachmentName, setAttachmentName] = useState('');
    const [note, setNote] = useState('');
    const [textApproval, setTextApproval] = useState(lang.newsletter_text_approval);
    const [logoEnabled, setLogoEnabled] = useState(true);

    const [storeData, setStoreData] = useState(false);

    // DIALOGS
    const [showColors, setShowColors] = useState(false);
    const [colorID, setColorID] = useState(0);
    const [showPage, setShowPage] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    // CONSTANTS
    const buttonHeight = 80;
    const errorHeight = 40;
    const column1 = 180;
    const column2 = dialogWidth - column1 - 40;
    const tabHeight = 40;

    const tabs = [
        { id: 0, enabled: true, label: lang.newsletter_content },
        { id: 1, enabled: true, label: lang.settings },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                db_get(props.item.id);
            }

            running = true;
        }

    }, []);


    const db_get = async (newsletter_id, enabled) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'newsletter', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    newsletter_id: newsletter_id,
                    enabled: enabled
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var item = json.item;

                if (item != false) {
                    setStoreData(item);

                    setEnabled(item.enabled == false ? false : true);
                    setUID(item.uid);
                    setNewsletterLink(json.link);
                    setPageID(item.page_id);
                    setName(item.name);
                    setLabelColor(item.label_color);
                    setBackgroundColor(item.background_color);
                    setBorderRadius(item.border_radius);
                    setText(item.text);
                    setTextColor(item.text_color);
                    setTextAlign(item.text_align);
                    setAttachment(item.attachment);
                    setAttachmentName(item.attachment_name);
                    setNote(item.note);
                    setTextApproval(item.text_approval);
                    setLogoEnabled(item.logo_enabled == false ? false : true);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'newsletter_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (itemID == 0) {
                    data.id = json.ok;
                    setItemID(json.ok);
                    setNewsletterLink(json.link);
                }
                setStoreData(data);
                setRedrawRequired(true);
                if (data.enabled == false) {
                    func(true);
                } else {
                    setShowOK(true);
                }

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const ColorsResult = (typ, color) => {
        setShowColors(false);
        if (typ == true) {
        }
    }


    const Save = () => {
        setError('');

        var err = 0;
        var error_text = lang.required_red;
        err += IsEmpty(name) ? 1 : 0;

        if (err == 0) {
            let data = {
                id: itemID,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                user_id: props.user.id,
                enabled: enabled,
                page_id: pageID,
                name: name,
                label_color: labelColor,
                background_color: backgroundColor,
                border_radius: borderRadius,
                text: text,
                text_color: textColor,
                text_align: textAlign,
                attachment: attachment,
                attachment_name: attachmentName,
                note: note,
                text_approval: textApproval,
                logo_enabled: logoEnabled,
            }

            Debug(data);
            db_update(data);
        } else {
            setError(error_text);
        }
    }


    const DeleteBlog = () => {
        let data = {
            id: itemID,
            enabled: false
        }
        Debug(data);
        db_update(data);
    }

    const PageResult = (redraw_, page_id) => {
        if (page_id > 0) {
            let data = {
                id: itemID,
                page_id: page_id
            }
            setPageID(page_id);
            Debug(data);
            db_update(data);

        } else {
            setShowPage(false);
        }
    }

    const Preview = (item) => {
        setShowPreview(true);
    }


    const ChangeTab = (id) => {
        setTabIndex(id);
    }


    const LinkCopy = () => {
        navigator.clipboard.writeText(newsletterLink);
        setShowCopied(true);
        var timer = setTimeout(() => {
            setShowCopied(false);
        }, 2000);
    }

    const CheckChanges = () => {

        var change = 0;
        Debug('storeData');
        Debug(storeData.text_color);
        Debug(textColor);
        if (storeData == false) {
            change = false
        }
        else {
            change += storeData.enabled != enabled ? 1 : 0;
            change += storeData.name != name ? 1 : 0;
            change += storeData.label_color != labelColor ? 1 : 0;
            change += storeData.background_color != backgroundColor ? 1 : 0;
            change += storeData.border_radius != borderRadius ? 1 : 0;
            change += storeData.text != text ? 1 : 0;
            change += storeData.text_color != textColor ? 1 : 0;
            change += storeData.text_align != textAlign ? 1 : 0;
            change += storeData.attachment != attachment ? 1 : 0;
            change += storeData.attachment_name != attachmentName ? 1 : 0;
            change += storeData.note != note ? 1 : 0;
            change += storeData.logo_enabled != logoEnabled ? 1 : 0;
            change += storeData.text_approval != textApproval ? 1 : 0;
        }

        return change > 0 ? true : false;
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faMessage} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{itemID == 0 ? lang.newsletter_new : lang.newsletter}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height }}>

                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs center value={tabIndex} items={tabs} width={230} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>

                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {tabIndex == 0 ?
                                <div style={{ ...styles.Block }}>

                                    <FormSpace />
                                    <FormLabel editing={false} icon={faInfoCircle} title={lang.newsletter_title} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={(id) => Debug(id)} />
                                    <FormText require redraw value={name} editing={true} title={lang.newsletter_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                    <FormColors palette value={labelColor} editing={true} title={lang.newsletter_label_color} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} func={(id) => setLabelColor(id)} />

                                    <FormSpace />
                                    <Line color={'#D0D0D0'} marginBottom={8} theme={props.theme} />
                                    <FormLabel editing={false} icon={faFileLines} title={lang.newsletter_text_footer} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={(id) => Debug(id)} />
                                    <FormAlign value={textAlign} editing={true} title={lang.newsletter_text_align} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setTextAlign(value)} />
                                    <FormColors palette value={textColor} editing={true} title={lang.newsletter_text_color} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} func={(id) => setTextColor(id)} />
                                    <FormText redraw rows={3} value={text} editing={true} title={lang.newsletter_text} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setText(txt)} />

                                    <Line color={'#D0D0D0'} marginTop={20} theme={props.theme} />
                                    <FormSpace height={10} />

                                    {itemID == 0 ? null :
                                        <div style={{ ...styles.Block, width: '92%', marginTop: 18 }}>
                                            <FontAwesomeIcon style={{ height: 36, color: color.xxgray, marginBottom: 8 }} icon={faFileLines} />
                                            <p style={{ ...styles.TextTiny, marginBottom: 8, color: color.dark_gray }}><b>{lang.blog_content_text}</b></p>
                                            <Button onClick={() => setShowPage(true)} style={{ ...styles.ButtonOutlined, width: pageID == 0 ? 220 : 180, fontSize: global.font_tiny }}>
                                                {pageID == 0 ?
                                                    <FontAwesomeIcon style={{ height: 16, color: color.dark_gray, marginRight: 6 }} icon={faPlusCircle} />
                                                    : null}
                                                {pageID == 0 ? lang.newsletter_content_new : lang.newsletter_content}
                                            </Button>
                                        </div>
                                    }

                                </div>
                                : null}

                            {tabIndex == 1 ?
                                <div style={{ ...styles.Block }}>

                                    <FormSpace />
                                    <FormLabel editing={false} icon={faCog} title={lang.settings} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={(id) => Debug(id)} />
                                    <FormColors palette value={backgroundColor} editing={true} title={lang.newsletter_background_color} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} func={(id) => setBackgroundColor(id)} />
                                    <FormSelect value={borderRadius} items={global.page_borders} field={'id'} editing={true} title={lang.newsletter_border_radius} width={100} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setBorderRadius(id)} />
                                    <FormSpace />

                                    <FormYesNo value={logoEnabled} editing={true} title={lang.newsletter_logo} yes={lang.newsletter_logo_enabled} no={lang.newsletter_logo_hide} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setLogoEnabled(value)} />
                                    <FormText redraw rows={3} value={textApproval} editing={true} title={lang.newsletter_text_approval_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setTextApproval(txt)} />

                                    <FormSpace />
                                    <FormText redraw value={note} editing={true} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />

                                    <div style={{ ...styles.Block, marginTop: 30 }}>
                                        {itemID > 0 && newsletterLink != '' ?
                                            <div onClick={() => LinkCopy()} style={{ ...styles.Block, cursor: 'pointer' }}>
                                                <p style={{ ...styles.TextTiny, marginBottom: 2 }}><b>{lang.newsletter_link}:</b></p>
                                                <p style={{ ...styles.TextTiny, textAlign: 'center', color: color.dark_gray }}>{newsletterLink}</p>
                                                <div style={{ ...styles.Block, height: 25 }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'center' }}>{showCopied == true ? lang.newsletter_link_copied : ''}</p>
                                                </div>
                                            </div>
                                            : null}
                                    </div>


                                    <div style={{ ...styles.Block, marginTop: 50 }}>
                                        {itemID > 0 ?
                                            <div style={{ ...styles.Block }}>
                                                <p style={{ ...styles.TextTiny, marginBottom: 10 }}>{lang.newsletter_delete_info}</p>
                                                <DeleteButton button ask bold width={220} backgroundColor={color.medium_gray} icon_color={color.white} label={lang.newsletter_delete} ok={lang.delete} icon={faTrash} position={'top'} text={lang.newsletter_delete + '?'} text_note={lang.newsletter_delete_ask_text} tooltip={lang.newsletter_delete} theme={props.theme} lang={props.lang} func={() => DeleteBlog()} />
                                            </div>
                                            : null}
                                    </div>

                                </div>
                                : null}

                        </div>

                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {itemID != 0 && CheckChanges() == false && parseInt(pageID) > 0 ?
                                    <Button onClick={() => Preview()} style={{ ...styles.ButtonThemed, backgroundColor: color.middle_gray, marginRight: 20, width: 145 }}>{lang.web_page_item_preview}</Button>
                                    : null}
                                {itemID == 0 || CheckChanges() == true ?
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20, width: 145 }}>{itemID == 0 ? lang.create : lang.save}</Button>
                                    : null}
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 145 }}>{lang.close}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper >

            {showPreview == true ?
                <NewsletterPreview newsletterID={itemID} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowPreview(false)} />
                : null}

            {showPage == true ?
                <WebPageDesigner email stats item={pageID == 0 ? false : { id: pageID }} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={PageResult.bind(this)} />
                : null}

            {showColors == true ?
                <DialogColors remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={ColorsResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}


export const NewsletterPreview = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [html, setHTML] = useState('');

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    const imageSize = 200;
    const blogWidth = dialogWidth > 800 ? 800 : dialogWidth - 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                // UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'newsletter_preview', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    newsletter_id: props.newsletterID,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setHTML(json.html);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faEye} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.newsletter_preview}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockCenter }}>
                        <div style={{ ...styles.TextXSmall, width: dialogWidth }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />
                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


export const NewsletterSelect = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let publicBlog = props.public == true ? true : false;
    let publicInternal = props.internal == true ? true : false;

    let defaultFilter = {
        enabled: true,
        public: publicBlog,
        internal: publicInternal,
        published: true,
        country_id: props.locale.id
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST    
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // VALUES
    const [value, setValue] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;


    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 100;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            setTabIndex(0);
            db_get(0, defaultFilter);

            running = true;
        }

    }, [props.locale.id]);


    const db_get = async (page, filter) => {
        Debug('FILTER');
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'newsletters', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false, false);
    }

    const GetFilter = () => {
        var filter_ = defaultFilter;

        if (tabIndex == 0) {
            filter_.published = true;
        } else {
            filter_.published = false;
        }

        return filter_;
    }
    const Search = (txt) => {
        setSearch(txt);
        var filter_ = GetFilter();

        if (txt.length > 1) {
            filter_.search = txt;
            db_get(0, filter_);
        }
        if (txt == '') {
            delete (filter_.search);
            db_get(0, filter_);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        func(true, item);
    }

    const Preview = (item) => {
        setItemSelected(item);
        setShowPreview(true);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faNewspaper} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.newsletter_select}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height }}>
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                                <div style={{ ...styles.Block, width: '96%' }}>
                                    <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray }}>
                                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '72%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, marginBottom: 8 }}>{GetDatum2(item.date)}</p>
                                                        <TextLine text={item.name} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                        <TextLine text={item.note} fontSize={global.font_tiny} color={color.black} />
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        {/*
                                                    <Chip variant='outlined' size='small' label={item.published == false ? lang.blog_not_published : lang.blog_published} style={{ color: item.published == false ? color.dark_red : color.dark_green, borderColor: item.published == false ? color.dark_red : color.dark_green }} />
                                                    */}
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: 100, minHeight: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                        <Chip onClick={() => Preview(item)} variant='filled' size='medium' label={lang.web_page_item_preview} style={{ backgroundColor: color.light_gray, borderLeft: color.border }} />
                                                    </div>
                                                </div>
                                            </Paper>
                                        )) :
                                            <EmptyList row small lang={lang} />
                                        }

                                        {/* PAGINATION */}
                                        {pages > 1 ?
                                            <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            </div>
                                            : null}

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Paper >
            {showPreview == true ?
                <NewsletterPreview newsletterID={itemSelected.id} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowPreview(false)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop>
    );
}
