/*
*
* =================================================================================================================
* SKLADOVA KARTA
* HISTORIA SKLADOVYCH POHYBOV K PRODUKTU

    showNew     -> vytváranie novej karty
    showSelect  -> výber existujúcej karty    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { Avatar, DialogImagePreview, DialogInfo, DialogLogin, DialogTabs, DialogVideo, DialogYesNo, EmployeeSelect, EmptyList, FormText, FormLabel, Icon, Line, Loading, Menu, MenuTitle, MenuTop, SelectInput, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, FormNote, FormSpace, ShowInfo, QuickHelp, SubMenuButton, FormYesNo, FormError } from './items';
import { styles } from './styles';
import { Button, Chip, IconButton, Pagination, Paper, Tooltip, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faInfoCircle, faLayerGroup, faList, faPlusCircle, faQuestion, faWandMagicSparkles, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetDatum2, GetDatumTime, GetDatumTime2, GetTodayYear } from './functions_date';
import { CheckPermission, Debug, FormatPieces, GetPages, IsEmpty } from './functions';
import { ProductSelect } from './product_select.js';
import { BarChart } from '@mui/x-charts';

export const StockHistory = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'conditions';

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [stockUID, setStockUID] = useState(props.stock_uid);
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [year, setYear] = useState(GetTodayYear());
    const [graphDataAverage, setGraphDataAverage] = useState(false);
    const [graphDataMax, setGraphDataMax] = useState(false);

    // SKLADOVA KARTA
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [stockable, setStockable] = useState(true);
    const [automat, setAutomat] = useState(false);
    const [quantityMin, setQuantityMin] = useState(0);
    const [quantityMax, setQuantityMax] = useState(0);

    // SKLADY
    const [stocks, setStocks] = useState(false);
    const [stocksInfo, setStocksInfo] = useState(false);
    const [stockID, setStockID] = useState(-1);
    const [productTemp, setProductTemp] = useState(0);
    const [moveProduct, setMoveProduct] = useState(false);
    const [moveProductItem, setMoveProductItem] = useState(false);

    // PRODUCT - STOCK CARD
    const [stockCard, setStockCard] = useState(false);
    const [cardProducts, setCardProducts] = useState(false);
    const [showAskStockCardChange, setAskShowStockCardChange] = useState(false);
    const [showAskStockCardChange2, setAskShowStockCardChange2] = useState(false);

    // TABS
    const tabs = [
        { id: 4, enabled: true, label: lang.info },
        { id: 0, enabled: true, label: lang.stock_card_products_ },
        { id: 1, enabled: true, label: lang.stock_card_transactions_ },
        { id: 2, enabled: true, label: lang.stock_card_history },
        { id: 3, enabled: true, label: lang.settings },
    ];

    const [tabIndex, setTabIndex] = useState(4);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [selectProduct, setSelectProduct] = useState(false);
    const [itemsID, setItemsID] = useState([]);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const column1 = 220;
    const column2 = 600 - column1;

    // CONSTANTS
    const rowHeight = 68;
    const rowInfoHeight = 42;
    const rowProductHeight = 50;
    const tableLabelHeight = 40;
    const cardHeight = 50;
    const tabHeight = 40;
    const dialogWidth = props.width;

    var type = -1;
    var type_changed = false;

    let graphWidth = props.width > 800 ? 800 : props.width;
    let graphHeight = 360;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            db_data();

            if (props.stock_uid != false) {
                let filter = { year: year, stock: -1 };
                db_get(0, filter);
                db_info();
            } else {
                setEditing(true);
                setTabIndex(3);
            }

            running = true;
        }

    }, []);


    const db_get = async (page, filter) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_stock', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    stock_uid: stockUID,
                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);
                setStockCard(json.card);
                setNumber(json.card.number);
                setName(json.card.name);
                setStockable(json.card.stockable == false ? false : true);
                setAutomat(json.card.automat == false ? false : true);
                setQuantityMin(json.card.quantity_min);
                setQuantityMax(json.card.quantity_max);
                setCardProducts(json.card_products);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_stats = async (page, filter) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_stock_stats', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    stock_uid: stockUID,
                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setGraphDataAverage(json.data_average);
                setGraphDataMax(json.data_max);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_info = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_stock_info', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    stock_uid: stockUID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setStocksInfo(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_data = async () => {
        Debug(props.locale.id);
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();
            Debug('product_data');
            Debug(json);
            if (json.ok > 0) {
                //setCategories(json.categories);
                //setManufactures(json.manufactures);
                //setVendors(json.vendors);
                Debug('STOCKS');
                Debug(json.stocks);
                setStocks(json.stocks);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const db_stock_card_change = async () => {
        try {
            const response = await fetch(
                global.db_url + 'product_stock_card_change', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ----------------------------------------
                    product_id: productTemp,
                    stock_uid: stockCard.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                func(true);
                db_get(0, filter);
                setShowOK(true);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const db_stock_card_move = async () => {
        try {
            const response = await fetch(
                global.db_url + 'product_stock_card_change', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ----------------------------------------
                    product_id: moveProductItem.id,
                    stock_uid: productTemp
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                func(true);
                db_get(0, filter);
                setShowOK(true);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const db_stock_card_update = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'stock_card_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ----------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (stockUID == false) {
                    setStockUID(json.ok);
                }
                func(true);
                setEditing(false);
                setShowOK(true);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_stock_card_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const SelectYearResult = (year_, month) => {
        setYear(year_);
        let filter = { year: year_, stock: stockID };
        if (tabIndex == 1) {
            db_get(0, filter);
        }
        if (tabIndex == 2) {
            db_stats(0, filter);
        }
    }

    const ChangeStock = (id) => {
        setStockID(id);
        var filter = { year: year, stock: id };
        if (tabIndex == 1) {
            db_get(0, filter);
        }
        if (tabIndex == 2) {
            db_stats(0, filter);
        }
    }

    const CancelEditing = () => {
        if (stockUID != false) {
            setError('');
            setName(stockCard.name);
            setEditing(false);
        }
    }

    const SaveData = () => {
        var err = 0;
        setError('');

        var error = false;
        err += IsEmpty(name) == true ? 1 : 0;

        if (err == 0) {
            let data = {
                id: stockUID == false ? 0 : stockCard.id,
                organization_id: props.organization.id,
                name: name,
                stockable: stockable,
                automat: automat,
                quantity_min: quantityMin,
                quantity_max: quantityMax
            }
            db_stock_card_update(data);
        } else {
            setError(lang.required_red);
        }
    }

    const SelectProduct = () => {
        var array = [];
        if (cardProducts != false) {
            cardProducts.forEach(element => {
                array.push(element.id);
            });
        }

        setItemsID(array);
        setMoveProduct(false);
        setSelectProduct(true);
    }

    const MoveProduct = (item) => {
        var array = [];
        if (cardProducts != false) {
            cardProducts.forEach(element => {
                array.push(element.id);
            });
        }

        setItemsID(array);
        setMoveProductItem(item);
        setMoveProduct(true);
        setSelectProduct(true);
    }

    const ProductResult = (typ, id, item) => {
        setSelectProduct(false);
        if (typ == true && item != false) {
            if (item[0] != undefined) {
                if (moveProduct == false) {
                    // PRIDANIE NOVEHO PRODUKTZ NA KARTU
                    setProductTemp(item[0].id);
                    setAskShowStockCardChange2(true);
                } else {
                    // VYMAZANIE Z KARTY A PRESUN NA NOVU KARTU
                    setProductTemp(item[0].stock_uid);
                    setAskShowStockCardChange(true);
                }
            }
        }
    }


    const StockCardAdd = (value) => {
        // PRIDANIE PRODUKTU NA KARTU
        setAskShowStockCardChange2(false);

        if (value == true) {
            db_stock_card_change();
        }
    }

    const StockCardMove = (value) => {
        // PRESUN PRODUKTU NA NOVU KARTU
        setAskShowStockCardChange(false);

        if (value == true) {
            db_stock_card_move();
        }
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 1) {
            // TRANSAKCIE
            if (stockID > 0) {
                var filter = { year: year, stock: stockID };
                db_get(0, filter);
            }
        }
        if (id == 2) {
            // ŠTATISTIKA
            if (stockID > 0) {
                var filter = { year: year, stock: stockID };
                db_stats(0, filter);
            }
        }
        if (id == 4) {
            // AKTUALNE STAVY - INFO
            db_info();
        }
    }

    return (
        <div style={{ ...styles.BlockCenter, height: props.height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

            <div id='id_stock_card_start' style={{ ...styles.Block }}></div>

            <div style={{ ...styles.BlockCenter, maxWidth: global.max_width, width: '100%' }}>
                <div style={{ ...styles.Block, height: cardHeight, backgroundColor: color.light_gray, marginTop: 10, borderRadius: global.radius }}>
                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                        <div style={{ ...styles.BlockLeft, width: '60%', height: cardHeight }}>
                            <FormLabel editing={editing} paddingBottom={0} icon={faInfoCircle} title={stockCard == false ? lang.stock_card : lang.stock_card + ': ' + stockCard.number} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '40%', height: cardHeight }}>
                            {global.testing_menu == true ?
                                <p style={{ ...styles.TextTiny }}>{stockCard.id}</p>
                                : null}
                        </div>
                    </div>
                </div>
                {stockUID != false ?
                    <div style={{ ...styles.Block, height: tabHeight, marginTop: 20 }}>
                        <DialogTabs center value={tabIndex} items={tabs} width={200} theme={props.theme} func={(id) => ChangeTab(id)} />
                    </div>
                    : null}

                {tabIndex == 0 ?
                    // PRODUKTY NA KARTE
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block }}>
                            {cardProducts != false ?
                                <div style={{ ...styles.BlockLeft, marginTop: 20 }}>
                                    <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.stock_card_products}</p>
                                </div>
                                : null}
                            <div style={{ ...styles.Block, marginBottom: 20 }}>
                                {cardProducts != false ? cardProducts.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowProductHeight,
                                            //cursor: 'pointer',
                                            marginTop: 5, marginBottom: 5,
                                            paddingTop: 2, paddingBottom: 2,
                                            borderLeft: item.enabled == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '96%' }}>
                                            <div style={{ ...styles.BlockLeft, width: rowProductHeight, minHeight: rowProductHeight, justifyContent: 'center' }}>
                                                <Avatar picture image={item.image} size={rowProductHeight - 4} theme={props.theme} />
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '70%', minHeight: rowProductHeight }}>
                                                <p style={{ ...styles.TextXSmall, marginLeft: 10 }}>{item.name}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 80, minHeight: rowProductHeight }}>
                                                <Chip variant='outlined' size='small' style={{ borderColor: color.dark_gray }} label={<p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.country}</p>}></Chip>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: '20%', minHeight: rowProductHeight }}>
                                                <p style={{ ...styles.TextTiny }}>{item.code}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 50, minHeight: rowProductHeight }}>
                                                <Tooltip title={lang.stock_card_product_move} >
                                                    <IconButton onClick={() => MoveProduct(item)} style={{ ...styles.ButtonIcon }}>
                                                        <FontAwesomeIcon style={{ height: 14 }} icon={faXmark} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <div style={{ ...styles.Block, marginTop: 20, marginBottom: 20 }}>
                                        <EmptyList row small lang={lang} />
                                    </div>
                                }
                            </div>
                        </div>


                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                            <Button onClick={() => SelectProduct()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 240 }}>{lang.stock_card_add_product}</Button>
                            : null}
                    </div>
                    : null}

                {tabIndex == 4 ?
                    // INFORMACIE O STAVOCH
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.stock_card_info}</p>
                        </div>

                        <div style={{ ...styles.BlockRow, border: color.border, marginTop: 20 }}>
                            <div style={{ ...styles.BlockLeft, width: '55%', minHeight: tableLabelHeight, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.stock_name_}</p>
                            </div>
                            <div style={{ ...styles.Block, width: '15%', minHeight: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.stock_actual_quantity}</p>
                            </div>
                            <div style={{ ...styles.Block, width: '15%', minHeight: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.stock_reserved_quantity}</p>
                            </div>
                            <div style={{ ...styles.Block, width: '15%', minHeight: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.stock_ordered_quantity}</p>
                            </div>
                        </div>

                        {stocksInfo != false ? stocksInfo.map((item, index) => (
                            <div key={item.id} style={{ ...styles.Block }} >
                                <div style={{ display: 'none' }} >
                                    {type != item.type ? type_changed = true : type_changed = false}
                                    {type = item.type}
                                </div>

                                {type_changed == true ?
                                    <div style={{ ...styles.Block, height: 40, backgroundColor: global.stock_color[item.type], borderRadius: 25, marginTop: 20 }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.warehouse_types_[item.type]}</p>
                                    </div>
                                    : null}

                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    onClick={() => SelectItem(item)}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowInfoHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: '3px solid ' + global.stock_color_dark[item.type],
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                                        <div style={{ ...styles.BlockLeft, width: '55%', minHeight: rowInfoHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXSmall }}>{item.name}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '15%', minHeight: rowInfoHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXSmall, marginRight: 10 }}>{item.quantity}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '15%', minHeight: rowInfoHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXSmall, marginRight: 10 }}>{item.reserved}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '15%', minHeight: rowInfoHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXSmall, marginRight: 10 }}>{item.ordered}</p>
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        )) :
                            <div style={{ ...styles.Block, paddingTop: 10, marginTop: 10 }}>
                                <EmptyList row small lang={lang} />
                            </div>
                        }

                        <FormSpace height={50} />
                    </div>
                    : null}

                {tabIndex == 1 ?
                    // TRANSAKCIE - POHYBY
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.BlockLeft, width: '98%', marginTop: 20 }}>
                            <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.stock_card_transactions}</p>
                        </div>

                        <div style={{ ...styles.BlockRow, width: '98%', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRow, width: '50%', height: 50 }}>
                                <div style={{ ...styles.BlockLeft, width: 100, height: 50 }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.stock}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, height: 50 }}>
                                    <SelectInput default={lang.stock_choose} theme={props.theme} enabled={true} lang={lang} value={stockID} items={stocks == false ? false : stocks.filter(x => x.show_transactions == true)} field={'code'} label={lang.mobil} radius={global.radius} func={(id) => ChangeStock(id)} />
                                </div>
                            </div>

                            <div style={{ ...styles.BlockRight, width: '50%', height: 50 }}>
                                {stockID > -1 ?
                                    <SelectYear min={2020} value={year} theme={props.theme} func={SelectYearResult.bind(this)} />
                                    : null}
                            </div>
                        </div>

                        {stockID > -1 ?
                            <div style={{ ...styles.BlockRow, width: '98%', border: color.border, marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: '15%', minHeight: tableLabelHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.date}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '40%', minHeight: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.label}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '15%', minHeight: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.user}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '15%', minHeight: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.stock_quantity}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '15%', minHeight: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product_quantity_stock_}</p>
                                </div>
                            </div>
                            : null}
                        {stockID > -1 ?
                            <div style={{ ...styles.Block, width: '98%', marginTop: 0 }}>
                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        onClick={() => SelectItem(item)}
                                        style={{
                                            ...styles.Block,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowHeight,
                                            cursor: 'pointer',
                                            marginTop: 5, marginBottom: 5,
                                            paddingTop: 2, paddingBottom: 2,
                                            borderLeft: item.enabled == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '96%' }}>
                                            <div style={{ ...styles.BlockLeft, width: '15%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny }}>{GetDatumTime2(item.updated)}</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.stock_history_type[item.transaction_typ]}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 2 }}>{item.reason}</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '15%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, marginTop: 2 }}>{item.name}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: '15%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextXSmall }}>{item.quantity > 0 ? '+' : ''}{FormatPieces(item.quantity)}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: '15%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextXSmall }}>{FormatPieces(parseInt(item.status) + parseInt(item.quantity))}</p>
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <div style={{ ...styles.Block, paddingTop: 10, marginTop: 10 }}>
                                        <EmptyList row small lang={lang} />
                                    </div>
                                }

                                {/* PAGINATION */}
                                {pages > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                    </div>
                                    : null}
                            </div>
                            : null}
                    </div>
                    : null}

                {tabIndex == 2 ?
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.BlockLeft, width: '98%', marginTop: 20 }}>
                            <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.stock_card_history_}</p>
                        </div>

                        <div style={{ ...styles.BlockRow, width: '98%', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRow, width: '30%', height: 50 }}>
                                <div style={{ ...styles.BlockLeft, width: 100, height: 50 }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.stock}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, height: 50 }}>
                                    <SelectInput default={lang.stock_choose} theme={props.theme} enabled={true} lang={lang} value={stockID} items={stocks} field={'code'} label={lang.mobil} radius={global.radius} func={(id) => ChangeStock(id)} />
                                </div>
                            </div>

                            <div style={{ ...styles.BlockRight, width: '70%', height: 50 }}>
                                {stockID > -1 ?
                                    <SelectYear min={2020} value={year} theme={props.theme} func={SelectYearResult.bind(this)} />
                                    : null}
                            </div>
                        </div>

                        <div style={{ ...styles.Block }}>
                            {graphDataMax != false ?
                                <BarChart
                                    sx={{ rx: 15 }}
                                    xAxis={[
                                        {
                                            id: 'months',
                                            data: lang.months_list_,
                                            scaleType: 'band',
                                            label: '( ' + lang.graph_label_months + ' ' + year + ' )',
                                            labelStyle: { fill: color.medium_gray, fontSize: 14 }
                                        },
                                    ]}
                                    yAxis={[
                                        {
                                            id: 'pieces',
                                            label: '( ' + lang.graph_label_pieces + ' )',
                                            labelStyle: { fill: color.medium_gray, fontSize: 14 }
                                        }
                                    ]}

                                    series={[
                                        {
                                            data: graphDataAverage,
                                            label: lang.graph_label_stock_average,
                                            labelStyle: { fill: color.medium_gray, fontSize: 14 },
                                            color: color.graph_bars_1
                                        },
                                        {
                                            data: graphDataMax,
                                            label: lang.graph_label_stock_max,
                                            labelStyle: { fill: color.medium_gray, fontSize: 14 },
                                            color: color.graph_bars_2
                                        },

                                    ]}
                                    slotProps={{
                                        legend: {
                                            labelStyle: {
                                                fontSize: 14,
                                                fill: color.dark_gray,
                                            },
                                        },
                                        bar: {
                                            rx: 5,
                                            ry: 5
                                        },
                                    }}

                                    onItemClick={(event, d) => Debug(d)}

                                    width={graphWidth}
                                    height={graphHeight}
                                />
                                : null}

                        </div>

                    </div>
                    : null}

                {tabIndex == 3 ?
                    <div style={{ ...styles.Block }}>
                        <FormSpace />
                        <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                        {stockUID != false ?
                            <FormText redraw value={number} editing={false} title={lang.number} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNumber(txt)} />
                            : null}
                        <FormText require redraw value={name} editing={editing} title={lang.stock_card_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                        <FormYesNo value={stockable} editing={editing} title={lang.stockable} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setStockable(value)} />
                        <FormNote title={lang.stockable_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                        <FormSpace />

                        {stockable == true ?
                            <>
                                <FormLabel editing={editing} icon={faWandMagicSparkles} title={lang.stock_card_automat_label} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                <FormYesNo value={automat} editing={editing} title={lang.stock_card_automat} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setAutomat(value)} />
                                <FormNote title={lang.stock_card_automat_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                {automat == true ?
                                    <>
                                        <FormText require redraw numeric value={quantityMin} editing={editing} title={lang.stock_card_quantity_min} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setQuantityMin(txt)} />
                                        <FormNote title={lang.stock_card_quantity_min_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                        <FormText require redraw numeric value={quantityMax} editing={editing} title={lang.stock_card_quantity_max} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setQuantityMax(txt)} />
                                        <FormNote title={lang.stock_card_quantity_max_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                                    </>
                                    : null}
                            </>
                            : null}
                        <FormError theme={props.theme} error={error} small />

                        {editing == true ?
                            <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                <Button onClick={() => SaveData()} style={{ ...stockUID == false ? styles.ButtonThemed : styles.ButtonOutlined, backgroundColor: stockUID == false ? color.button_ok : undefined }}>{lang.save}</Button>
                                {stockUID != false ?
                                    <Button onClick={() => CancelEditing()} style={{ ...stockUID == false ? styles.ButtonThemed : styles.ButtonOutlined, backgroundColor: stockUID == false ? color.button_cancel : undefined, marginLeft: 20 }}>{lang.editing_finish}</Button>
                                    : null}
                            </div>
                            : null}

                    </div>

                    : null}


            </div>

            {
                selectProduct == true ?
                    <ProductSelect info={moveProduct == true ? lang.stock_card_product_move_label : undefined} excluded={itemsID} countrySelect country_id={props.locale.id} only_enabled hide_multiple showVariations={false} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.stock_card_product_move_label} token={props.token} func={ProductResult.bind()} />
                    : null
            }

            {
                showAskStockCardChange == true ?
                    // PRESUN PRODUKTU NA INU KARTU
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.stock_card_change_label} text={lang.stock_card_change_ask} sub_text={lang.stock_card_change_ask_note} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={StockCardMove.bind(this)} />
                    : null
            }

            {
                showAskStockCardChange2 == true ?
                    // VLOZENIE PRODUKTU DO KARTY
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.stock_card_change_label} text={lang.stock_card_change_ask2} sub_text={lang.stock_card_change_ask_note2} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={StockCardAdd.bind(this)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null
            }

        </div >
    );
}


