/*
*
* =================================================================================================================
* NASTAVENIE ORGANIZACIE

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Organization organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Avatar, Colors, DeleteButton, DialogImagePreview, DialogInfo, DialogLogin, DialogVideo, DialogYesNo, EmployeeSelect, FormError, FormLabel, FormSelect, FormSelection, FormSpace, FormText, FormYesNo, Icon, Line, Loading, Menu, MenuTitle, MenuTop, SelectInput, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, FormControlLabel, IconButton, Paper, Radio, RadioGroup, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faInfo, faInfoCircle, faLayerGroup, faList, faPaperPlane, faPen, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetTodayYear } from './functions_date';
import { Debug, GetLeftPosition, IsEmpty, ResetLogin } from './functions';
import { Photos } from './photos';
import { Help } from './Help';

export const SettingsOrganization = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let permissions = global.permissions;
    let color = global.themes[props.theme];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [organization, setOrganization] = useState(false);
    const [logo, setLogo] = useState('');
    const [name, setName] = useState('');
    const [alias, setAlias] = useState('');
    const [street, setStreet] = useState('');
    const [town, setTown] = useState('');
    const [psc, setPsc] = useState('');
    const [state, setState] = useState('');
    const [ICO, setICO] = useState('');
    const [DIC, setDIC] = useState('');
    const [IcDPH, setIcDPH] = useState('');
    const [dph, setDPH] = useState(false);
    const [bank, setBank] = useState('');
    const [iban, setIBAN] = useState('');
    const [swift, setSwift] = useState('');
    const [phone, setPhone] = useState('');
    const [mobil, setMobil] = useState('');
    const [email, setEmail] = useState('');
    const [web, setWeb] = useState('');
    const [currencyID, setCurrencyID] = useState([]);
    const [note, setNote] = useState('');

    // ZOZNAM platobných možností
    const [currencies, setCurrencies] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showCancelAccount, setShowAccountCancel] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const column1 = 150;
    const column2 = 400;
    const offset = 20;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get();

            running = true;
        }

    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'organization', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id
                })
            })

            const json = await response.json();

            Debug(json);
            if (json.ok > 0) {
                let item = json.organization;
                setOrganization(item);
                setCurrencies(json.currency);

                if (item != false) {
                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    model_id: global.model.organizations,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(false, false);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setLogo(item.logo);
        setName(item.name);
        setAlias(item.alias);
        setStreet(item.street);
        setTown(item.town);
        setPsc(item.psc);
        setState(item.state);
        setICO(item.ico);
        setDIC(item.dic);
        setIcDPH(item.ic_dph);
        setDPH(item.dph == false ? 0 : 1);
        setBank(item.bank);
        setIBAN(item.iban);
        setSwift(item.swift);
        setPhone(item.phone);
        setMobil(item.mobil);
        setEmail(item.email);
        setWeb(item.web);
        setCurrencyID(item.currency_id == false ? [] : item.currency_id);
        setNote(item.note);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(organization);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        organization.logo = logo;
        organization.name = name;
        organization.alias = alias;
        organization.street = street;
        organization.town = town;
        organization.psc = psc;
        organization.state = state;
        organization.ico = ICO;
        organization.dic = DIC;
        organization.ic_dph = IcDPH;
        organization.dph = dph;
        organization.bank = bank;
        organization.swift = swift;
        organization.phone = phone;
        organization.mobil = mobil;
        organization.email = email;
        organization.web = web;
        organization.currency_id = currencyID;
        organization.note = note;
    }

    const GetData = () => {
        // získanie údajov do reťazca
        let data = {
            id: props.organization.id,
            logo: logo,
            name: name,
            alias: alias,
            street: street,
            town: town,
            psc: psc,
            state: state,
            ico: ICO,
            dic: DIC,
            ic_dph: IcDPH,
            dph: dph == 0 ? false : true,
            bank: bank,
            swift: swift,
            phone: phone,
            mobil: mobil,
            email: email,
            web: web,
            currency_id: currencyID == false ? false : JSON.stringify(currencyID),
            note: note,
        }

        return data;
    }

    const Save = () => {
        var err = 0;
        setError('');

        // KONTROLA VLOZENYCH UDAJOV
        err += IsEmpty(name) == true ? 1 : 0;
        err += IsEmpty(email) == true ? 1 : 0;
        err += IsEmpty(mobil) == true ? 1 : 0;
        if (currencyID.length == 0) {
            err++;
        }

        if (err == 0) {
            // údaje sú OK - uložiť
            let data = GetData();
            Debug(data);

            StoreEditing();
            setEditing(false);

            db_update(data);

        } else {
            // pri kontrole sa našla chyba
            setError(lang.required_red);
        }
    }

    const FormLabelResult = (value) => {
        if (value == 0) {
            setEditing(true);
        }
    }

    const AvatarPress = (value) => {
        if (value == true) {
            setSelectPhoto(true);
        } else {
            setLogo('');
        }
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setLogo(path + file);
        }
    }

    const AccountCancel = (logout) => {
        setShowAccountCancel(false);

        if (logout == true) {

        }
    }


    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Help link={lang.help_settings_organization} lang={props.lang} theme={props.theme} />
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, paddingTop: 20, paddingBottom: 100, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockCenter, width: '96%' }}>

                        {organization != false ?
                            <Paper elevation={3} style={{ ...styles.BlockCenter, width: column1 + column2 + (2 * offset), paddingTop: offset, paddingBottom: offset * 2, backgroundColor: color.back, borderRadius: global.radius }}>

                                <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.organization_info} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                <Avatar editable={editing} picture image={logo} size={100} theme={props.theme} func={AvatarPress.bind()} />
                                <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.organization_logo}</p>
                                <FormSpace />

                                {/* INFORMACIE */}
                                <FormText require value={name} editing={editing} title={lang.organization_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                <FormText value={alias} editing={editing} title={lang.alias} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setAlias(txt)} />

                                {/* ADRESA */}
                                <FormLabel editing={editing} title={lang.address} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                <FormText value={street} editing={editing} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setStreet(txt)} />
                                <FormText value={town} editing={editing} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setTown(txt)} />
                                <FormText value={state} editing={editing} title={lang.state} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setState(txt)} />

                                {/* FIREMNE UDAJE */}
                                <FormLabel editing={editing} title={lang.firm_data} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                <FormText value={ICO} editing={editing} title={lang.ico} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setICO(txt)} />
                                <FormText value={DIC} editing={editing} title={lang.dic} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDIC(txt)} />
                                <FormYesNo value={dph} editing={editing} title={lang.dph_pay} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDPH(txt)} />

                                {dph == 1 ?
                                    <FormText value={IcDPH} editing={editing} title={lang.ic_dph} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setIcDPH(txt)} />
                                    : null}

                                <FormText value={bank} editing={editing} title={lang.bank} column1={column1} column2={column2} marginTop={20} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setBank(txt)} />
                                <FormText value={iban} editing={editing} title={lang.iban} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setIBAN(txt)} />
                                <FormText value={swift} editing={editing} title={lang.swift} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSwift(txt)} />

                                {/* KONTAKTNE UDAJE */}
                                <FormLabel editing={editing} title={lang.contact_data} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                <FormText value={phone} editing={editing} title={lang.phone} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPhone(txt)} />
                                <FormText require value={mobil} editing={editing} title={lang.mobil} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setMobil(txt)} />
                                <FormText require value={email} editing={editing} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />

                                {/* PLATOBNA MENA */}
                                <FormLabel editing={editing} title={lang.app_countries_label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                {/*
                                <FormSelect multiple value={currencyID} items={currencies} field={'currency'} editing={editing} title={lang.currency} width={200} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCurrencyID(id)} />
                                */}
                                <FormSelection help_id={11} require value={currencyID} items={currencies} field={'label'} editing={editing} title={lang.app_countries} column1={column1} column2={column2} width={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCurrencyID(id)} />
                                {/* SAVE - CANCEL */}
                                {editing == true ?
                                    <div style={{ ...styles.Block, paddingTop: 10 }}>
                                        <FormError theme={props.theme} error={error} small />

                                        <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                                            </div>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ ...styles.Block, marginTop: 50 }}>
                                        <Button onClick={() => setShowAccountCancel(true)} style={{ ...styles.ButtonOutlined, width: 240 }}>{lang.organization_cancel_account}</Button>
                                    </div>
                                }

                            </Paper>
                            : null}

                        {props.remote == true && props.remoteMobile == true ?
                            <FormSpace height={300} />
                            : null}

                    </div>
                </div>
            </div>

            {showCancelAccount == true ?
                <OrganizationAccountCancel organization={props.organization} locale={props.locale} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={AccountCancel.bind(this)} />
                : null}

            {selectPhoto == true ?
                <Photos autoSelect typ={global.image.others} max_size={600} full title={lang.photo_select} organization={props.organization} locale={props.locale} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


export const OrganizationAccountCancel = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogYesNo theme={props.theme} radius={props.radius} title={'Otázka'} text={'Text otázky'} sub_text={''} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={DialogYesNoResult.bind(this)} />

            ------------------------------------------------------------
            center      -> v strede
            hideClose   -> nebude sa zobrazovat zatvorenie okna
            warning_text    -> upozornenie - cervene
            buttonWidth     -> šírka buttonov
            ------------------------------------------------------------
            
            const DialogYesNoResult = (value) => {
                console.log(value)
            }
            */

    let lang = props.lang;

    const [isBusy, setBusy] = useState(false);
    const [showError, setShowError] = useState(false);

    const [index, setIndex] = useState(0);
    const [value, setValue] = useState(0);
    const [logout, setLogout] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = width > 800 ? 800 : width;
    const dialogHeight = 400;

    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);

    const db_send = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'send_cancel_account', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // ---------------------------------------------
                    typ: value
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setIndex(1);
                setLogout(true);
                ResetLogin();
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = (value) => {
        func(value);
    }

    const SendRequest = () => {
        db_send();
    }

    return (
        <Backdrop open={true} style={{ zIndex: 500, paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.organization_cancel_account_label}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close(false)} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {index == 0 ?
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <p style={{ ...styles.TextNormal, textAlign: 'center' }}>{lang.organization_cancel_account_text}</p>
                            <RadioGroup
                                value={value}
                                onChange={event => setValue(event.target.value)}
                                style={{ flexDirection: 'column', marginTop: 30 }}
                            >
                                <FormControlLabel value={1} control={<Radio style={{ padding: 0, paddingLeft: 12, paddingRight: 2 }} />} label={<p style={{ ...styles.TextTiny, margin: 0 }}>{lang.organization_account_cancel_option_1}</p>} />
                                <p style={{ ...styles.TextTiny, marginLeft: 28, color: color.dark_gray }}>{lang.organization_account_cancel_option_1_note}</p>
                                <FormSpace />
                                <FormControlLabel value={2} control={<Radio style={{ padding: 0, paddingLeft: 12, paddingRight: 2 }} />} label={<p style={{ ...styles.TextTiny }}>{lang.organization_account_cancel_option_2}</p>} />
                                <p style={{ ...styles.TextTiny, marginLeft: 28, color: color.dark_gray }}>{lang.organization_account_cancel_option_2_note}</p>
                                <FormSpace />
                                <FormControlLabel value={3} control={<Radio style={{ padding: 0, paddingLeft: 12, paddingRight: 2 }} />} label={<p style={{ ...styles.TextTiny }}>{lang.organization_account_cancel_option_3}</p>} />
                                <p style={{ ...styles.TextTiny, marginLeft: 28, color: color.dark_gray }}>{lang.organization_account_cancel_option_3_note}</p>
                            </RadioGroup>
                        </div>
                        : null}
                    {index == 1 ?
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <p style={{ ...styles.TextNormal, textAlign: 'center' }}><b>{lang.organization_account_cancel_sended}</b></p>
                            <p style={{ ...styles.TextXSmall, textAlign: 'center', marginTop: 10 }}>{lang.organization_account_cancel_sended_text}</p>
                            <p style={{ ...styles.TextXSmall, textAlign: 'center', marginTop: 2 }}>{lang.organization_account_cancel_sended_text_logout}</p>
                        </div>
                        : null}
                </div>
                <div style={{ ...styles.BlockRow, height: 100 }}>
                    {index == 0 ?
                        value > 0 ?
                            <div style={{ ...styles.Block, width: '50%', height: 100 }}>
                                <DeleteButton ask button bold width={220} label={lang.organization_cancel_account_yes} icon={faPaperPlane} position={'top'} backgroundColor={color.button_delete} text={lang.organization_account_cancel_send} text_note={lang.organization_account_cancel_send_text} tooltip={lang.organization_account_cancel_send} theme={props.theme} lang={props.lang} func={() => SendRequest()} />
                            </div>
                            : null
                        : null}
                    <div style={{ ...styles.Block, width: index == 0 && value > 0 ? '50%' : '100%', height: 100 }}>
                        <Button onClick={() => Close(logout)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                    </div>

                </div>
            </Paper>

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}
