/*
*
* =================================================================================================================
* NASTAVENIE ORGANIZACIE

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <SettingsCustomize organization={organization} user={user} settings={props.settings} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { DialogDates, DialogLabel, FormError, FormLabel, FormNote, FormSelect, FormText, FormYesNo, Line, Loading, ShowError, ShowOK, SwitchEnable } from './items';
import { styles } from './styles';
import { Button, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCogs, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { GetTodayYear } from './functions_date';
import { Debug, IsEmpty } from './functions';

export const SettingsSMSNotify = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let permissions = global.permissions;
    let color = global.themes[props.theme];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [storeData, setStoreData] = useState(false);
    const [smsNotify, setSmsNotify] = useState(false);
    const [smsSender, setSmsSender] = useState('');
    const [smsUnicode, setSmsUnicode] = useState(false);
    const [smsWatchdog, setSmsWatchdog] = useState(10);
    const [smsWatchdogEmail, setSmsWatchdogEmail] = useState('');

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // DEFAULT ITEMS
    const watchdogItems = [{ id: 10, label: '10 ' + lang.credits_.toLowerCase() }, { id: 50, label: '50 ' + lang.credits_.toLowerCase() }, { id: 100, label: '100 ' + lang.credits_.toLowerCase() }];

    const column1 = 180;
    const column2 = 370;
    const offset = 20;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var item = json.settings;
                if (item == false) {
                    item = global.settings_default;
                }
                setStoreData(item);
                AssignData(item);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setSmsNotify(item.sms_notify == false ? false : true);
        setSmsSender(item.sms_sender);
        setSmsUnicode(item.sms_unicode == false ? false : true);
        setSmsWatchdog(item.sms_watchdog);
        setSmsWatchdogEmail(item.sms_watchdog_email);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.sms_notify = smsNotify;
        storeData.sms_sender = smsSender;
        storeData.sms_unicode = smsUnicode;
        storeData.sms_watchdog = smsWatchdog;
        storeData.sms_watchdog_email = smsWatchdogEmail;
    }

    const GetData = () => {
        // získanie údajov do reťazca
        let data = {
            id: storeData.id,
            organization_id: props.organization.id,
            user_id: props.user.id,
            sms_notify: smsNotify,
            sms_sender: smsSender,
            sms_unicode: smsUnicode,
            sms_watchdog: smsWatchdog,
            sms_watchdog_email: smsWatchdogEmail
        }

        return data;
    }

    const Save = () => {
        setError('');

        var error = 0;
        if (smsNotify == true) {
            error += IsEmpty(smsSender) == true ? 1 : 0;
            error += IsEmpty(smsWatchdogEmail) == true ? 1 : 0;
        }

        if (error == 0) {
            // údaje sú OK - uložiť
            let data = GetData();
            Debug(data);

            StoreEditing();
            setEditing(false);

            db_update(data);
        } else {
            setError(lang.required_red);
        }

    }

    const FormLabelResult = (value) => {
        if (value == 0) {
            setEditing(true);
        }
    }

    const ChangeNotify = (value) => {
        setSmsNotify(value);

        if (value == true) {
            setEditing(true);
        }
    }


    return (
        <div style={{ ...styles.BlockCenter, backgroundColor: color.back, paddingTop: offset, paddingBottom: offset }}>

            {storeData != false ?
                <div style={{ ...styles.Block }}>
                    <div style={{ ...styles.BlockLeft }}>
                        <DialogLabel label={lang.sms_notifies} sub_label={lang.sms_notify_text} theme={props.theme} />
                        <SwitchEnable checkbox title={lang.sms_notify_enable} enabled={smsNotify} theme={props.theme} func={(value) => ChangeNotify(value)} />
                    </div>

                    {smsNotify == true ?
                        <Paper elevation={global.elevation_form} style={{ ...styles.BlockCenter, width: column1 + column2 + (2 * offset), paddingTop: offset, paddingBottom: offset * 2, backgroundColor: color.white, borderRadius: global.radius, marginTop: 30 }}>
                            <FormLabel editing={editing} editButton={true} icon={faCog} title={lang.sms_notify_settings} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                            <Line theme={props.theme} />
                            <FormText require value={smsSender} editing={editing} title={lang.sms_notify_sender} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSmsSender(txt)} />
                            <FormNote title={lang.sms_notify_sender_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                            <FormYesNo value={smsUnicode} editing={editing} title={lang.sms_notify_unicode} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setSmsUnicode(value)} />
                            <FormNote title={lang.sms_notify_unicode_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                            <FormSelect value={smsWatchdog} items={watchdogItems} field={'label'} editing={editing} title={lang.sms_credit_watchdog} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setSmsWatchdog(id)} />
                            <FormNote title={lang.sms_credit_watchdog_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                            <FormText require value={smsWatchdogEmail} editing={editing} title={lang.sms_credit_watchdog_email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSmsWatchdogEmail(txt)} />
                            <FormNote title={lang.sms_credit_watchdog_email_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                            {/* SAVE - CANCEL */}
                            {editing == true ?
                                <div style={{ ...styles.Block, paddingTop: 10 }}>
                                    <FormError theme={props.theme} error={error} small />

                                    <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                        </div>
                                    </div>
                                </div>
                                : null}

                        </Paper>
                        : null}

                    {smsNotify == false ?
                        <div style={{ ...styles.Block, paddingTop: 20 }}>
                            <div style={{ ...styles.Block, paddingBottom: 20 }}>
                                <FormError theme={props.theme} error={error} small />
                            </div>

                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_dark }}>{lang.save}</Button>
                        </div>
                        : null}

                </div>
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}
        </div>

    );
}


