/*
*
* =================================================================================================================
* USERS - zoznam užívateľov aplikácie pre danú organizáciu

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { ButtonNew, Colors, DialogDates, DialogImagePreview, DialogInfo, DialogLabel, DialogLogin, DialogTabs, DialogVideo, DialogYesNo, EmployeeSelect, EmptyList, FormError, FormLabel, FormNote, FormPermissions, FormSpace, FormText, FormYesNo, Icon, Line, Loading, Menu, MenuTitle, MenuTop, SelectInput, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, MenuItem, Pagination, Paper, Tab, Tabs, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCheckCircle, faInfoCircle, faKey, faLayerGroup, faList, faPlug, faPlus, faPlusCircle, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetDatum2, GetDatumTime2, GetTodayYear } from './functions_date';
import { BinaryToPermission, CheckPermission, Debug, GetLeftPosition, GetPages, IsEmpty, PermissionToBinary } from './functions';
import { GetNodeChilds, TreeNodes } from './nodes';
import { CurtainsTwoTone } from '@mui/icons-material';


export const SettingsPermissions = (props) => {
    /*
            // ZOZNAM POUŽÍVATEĽOV - USERS LIST

            USAGE:
            <UsersPermissions theme={props.theme} lang={props.lang} radius={props.radius} />

            PARAMS:
    */

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DIALOGS
    const [showUserRegister, setShowUserRegister] = useState(false);

    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 50;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            db_get(0, false);

            running = true;
        }
        return () => {
        };

    }, []);

    const db_get = async (page, filter) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'permissions', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -----------------------------
                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Search = (txt) => {
        setSearch(txt);
        if (txt.length > 1) {
            let filter = { search: txt };
            db_get(0, filter);
        }
        if (txt == '') {
            db_get(0, false);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const AddNewResult = () => {
        setItemEditing(false);
        setShowUserRegister(true);
    }

    const SelectItem = (item) => {
        setItemEditing(item);
        setShowUserRegister(true);
    }

    const UserResult = (typ, redraw) => {
        setShowUserRegister(false);
        if (typ == true && redraw == true) {
            db_get(page, filter);
        }
    }

    return (
        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
            <div style={{ ...styles.Block, width: '96%' }}>

                <DialogLabel label={lang.profiles} sub_label={lang.profiles_text} theme={props.theme} />

                <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray, marginTop: 10 }}>
                    <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                        <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                    </div>
                    <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                        <ButtonNew label={lang.profil_new} theme={props.theme} func={AddNewResult.bind()} />
                    </div>
                </div>

                <div style={{ ...styles.Block, marginTop: 20 }}>
                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                        <Paper
                            key={item.id}
                            elevation={item.id == overID ? global.elevation_over : global.elevation}
                            onClick={() => SelectItem(item)}
                            style={{
                                ...styles.Block,
                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                borderRadius: global.items_radius,
                                minHeight: rowHeight,
                                cursor: 'pointer',
                                marginTop: 5, marginBottom: 5,
                                paddingTop: 2, paddingBottom: 2,
                                borderLeft: item.enabled == false ? color.disabled_border : '',
                            }}
                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                        >
                            <div style={{ ...styles.BlockRow, width: '96%' }}>
                                <div style={{ ...styles.BlockLeft, width: '30%', minHeight: rowHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.label}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '65%', minHeight: rowHeight, justifyContent: 'center', borderRight: '1px solid ' + color.light_gray }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.note}</p>
                                </div>
                                <div style={{ ...styles.Block, width: '15%', minHeight: rowHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextXXTiny }}>{lang.updated}</p>
                                    <p style={{ ...styles.TextXTiny, marginTop: 5 }}>{GetDatum2(item.updated)}</p>
                                </div>
                            </div>
                        </Paper>
                    )) :
                        <div style={{ ...styles.Block, paddingTop: global.empty_padding }}>
                            <EmptyList row small lang={lang} />
                        </div>
                    }

                    {/* PAGINATION */}
                    {pages > 1 ?
                        <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                        </div>
                        : null}

                </div>

            </div>

            {showUserRegister ?
                <UserPermission remote={props.remote} remoteMobile={props.remoteMobile} itemEditing={itemEditing} organization={props.organization} settings={props.settings} system={props.system} user={props.user} lang={lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={UserResult.bind()} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </div>
    );
}



/*
*
* =================================================================================================================
* PERMISSION - NEW PROFILE / UPDATE PROFILE
* =================================================================================================================
*
*/
export const UserPermission = (props) => {

    let color = global.themes[props.theme];
    let permissions = global.permissions;
    var lang = props.lang;
    let radius = props.radius;
    const column1 = 200;
    const column2 = 450;
    const offset = 20;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.itemEditing == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState('');

    // DATA
    const [items, setItems] = useState(false);

    const [id, setID] = useState(props.itemEditing == false ? 0 : props.itemEditing.id);
    const [enabled, setEnabled] = useState(true);
    const [label, setLabel] = useState('');
    const [note, setNote] = useState('');

    // MODULY - PRODUKTY
    const [products, setProducts] = useState([]);
    const [productsCategories, setProductsCategories] = useState([]);
    const [productsGroups, setProductsGroups] = useState([]);
    const [productsManufactures, setProductsManufactures] = useState([]);
    const [productsVendors, setProductsVendors] = useState([]);
    const [showCost, setShowCost] = useState(false);
    const [smsNotify, setSMSNotify] = useState([]);
    const [creditBuy, setCreditBuy] = useState(false);
    const [priceLists, setPriceLists] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [stockAdjustment, setStockAdjustment] = useState([]);
    const [stockTransport, setStockTransport] = useState([]);
    const [stocksApprove, setStocksApprove] = useState(false);
    const [stockOrders, setStockOrders] = useState([]);
    const [stockOrdersReceived, setStockOrdersReceived] = useState([]);
    const [stocksLimited, setStocksLimited] = useState(false);
    const [codeBook, setCodeBook] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [customersCategories, setCustomersCategories] = useState([]);
    const [customersLimited, setCustomersLimited] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [contactsLimited, setContactsLimited] = useState(false);
    const [stockCards, setStockCards] = useState([]);
    const [stockRemovals, setStockRemovals] = useState([]);
    const [orders, setOrders] = useState([]);
    const [orderTypes, setOrderTypes] = useState([]);
    const [ordersLimited, setOrdersLimited] = useState(false);
    const [distributors, setDistributors] = useState([]);
    const [approvals, setApprovals] = useState([]);
    const [reklamacie, setReklamacie] = useState([]);
    const [ai, setAI] = useState(false);
    const [presentations, setPresentations] = useState([]);
    const [quizes, setQuizes] = useState([]);
    const [blogInternal, setBlogInternal] = useState([]);
    const [blogPublic, setBlogPublic] = useState([]);
    const [services, setServices] = useState([]);
    const [newsletter, setNewsletter] = useState([]);

    // MODULY - UZIVATELIA
    const [users, setUsers] = useState([]);
    const [usersGroups, setUsersGroups] = useState([]);

    // DIALOGS
    const [showMakeCopy, setShowMakeCopy] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= column1 + column2 + (4 * offset) ? column1 + column2 + (4 * offset) : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    let states1 = [{ id: 1, label: lang.permission_view }, { id: 2, label: lang.permission_create }, { id: 3, label: lang.permission_edit }, { id: 4, label: lang.permission_delete }]
    let states2 = [{ id: 1, label: lang.permission_view }, { id: 2, label: lang.permission_create }, { id: 3, label: lang.permission_edit }, { id: 4, label: lang.permission_delete }, { id: 5, label: lang.permission_export }]

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.itemEditing == false) {
                // NOVY ZAZNAM - DEFAULT VALUES
                let data = {
                    enabled: true,
                    label: '',
                    note: '',
                    products: 0B10000000,
                    products_categories: 0B10000000,
                    products_groups: 0B11110000,
                    products_manufactures: 0B10000000,
                    products_vendors: 0B10000000,
                    users: 0B11100000,
                    users_groups: 0B11110000,
                    sms_notify: 0B10000000,
                    credit_buy: false,
                    price_lists: 0B10000000,
                    stocks: 0B10000000,
                    stock_adjustment: 0B10000000,
                    stock_transport: 0B10000000,
                    stock_removals: 0B10000000,
                    stock_orders: 0B10000000,
                    stock_orders_received: 0B10000000,
                    code_book: 0B10000000,
                    conditions: 0B10000000,
                    customers: 0B10000000,
                    customers_categories: 0B10000000,
                    contacts: 0B10000000,
                    stock_cards: 0B10000000,
                    orders: 0B10000000,
                    order_types: 0B10000000,
                    distributors: 0B10000000,
                    approvals: 0B10000000,
                    reklamacie: 0B10000000,
                    presentations: 0B10000000,
                    quizes: 0B10000000,
                    blog_internal: 0B10000000,
                    blog_public: 0B10000000,
                    services: 0B10000000,
                    newsletter: 0B10000000,
                    ai: false,
                    show_cost: false,
                    stocks_approve: false,
                }

                AssignData(data);
                setItems(data);
            } else {
                // UPRAVA ZAZNAMU
                db_get();
            }


            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'permission', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ---------------------------------------
                    permission_id: props.itemEditing.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setItems(json.item);
                AssignData(json.item);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_copy = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'permission_copy', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ---------------------------------------
                    permission_id: props.itemEditing.id,
                    copy: lang.copy.toLowerCase()
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                func(true, true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    model_id: global.model.permissions,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const ShowErrorPress = () => {
        setShowError(false);
    }

    const ShowOKPress = () => {
        setShowOK(false);
        Press(true, true);
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(false, false);
    }

    const FormLabelResult = (value) => {
        if (value == 0) {
            setEditing(true);
        }
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(items);
        setEditing(false);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB - Zmena Byte To Array
        setEnabled(item.enabled == false ? false : true);
        setLabel(item.label);
        setNote(item.note);
        setProducts(BinaryToPermission(item.products));
        setProductsCategories(BinaryToPermission(item.products_categories));
        setProductsGroups(BinaryToPermission(item.products_groups));
        setProductsManufactures(BinaryToPermission(item.products_manufactures));
        setProductsVendors(BinaryToPermission(item.products_vendors));
        setShowCost(item.show_cost == false ? false : true);
        setUsers(BinaryToPermission(item.users));
        setUsersGroups(BinaryToPermission(item.users_groups));
        setSMSNotify(BinaryToPermission(item.sms_notify));
        setCreditBuy(item.credit_buy == false ? false : true);
        setPriceLists(BinaryToPermission(item.price_lists));
        setStocks(BinaryToPermission(item.stocks));
        setStockAdjustment(BinaryToPermission(item.stock_adjustment));
        setStockTransport(BinaryToPermission(item.stock_transport));
        setStocksApprove(item.stocks_approve == false ? false : true);
        setStocksLimited(item.stocks_limited == false ? false : true);
        setStockRemovals(BinaryToPermission(item.stock_removals));
        setStockOrders(BinaryToPermission(item.stock_orders));
        setStockOrdersReceived(BinaryToPermission(item.stock_orders_received));
        setCodeBook(BinaryToPermission(item.code_book));
        setConditions(BinaryToPermission(item.conditions));
        setCustomers(BinaryToPermission(item.customers));
        setCustomersCategories(BinaryToPermission(item.customers_categories));
        setCustomersLimited(item.customers_limited == false ? false : true);
        setContacts(BinaryToPermission(item.contacts));
        setContactsLimited(item.contacts_limited == false ? false : true);
        setStockCards(BinaryToPermission(item.stock_cards));
        setOrders(BinaryToPermission(item.orders));
        setOrderTypes(BinaryToPermission(item.order_types));
        setOrdersLimited(item.orders_limited == false ? false : true);
        setDistributors(BinaryToPermission(item.distributors));
        setApprovals(BinaryToPermission(item.approvals));
        setReklamacie(BinaryToPermission(item.reklamacie));
        setAI(item.ai == false ? false : true);
        setPresentations(BinaryToPermission(item.presentations));
        setQuizes(BinaryToPermission(item.quizes));
        setBlogInternal(BinaryToPermission(item.blog_internal));
        setBlogPublic(BinaryToPermission(item.blog_public));
        setServices(BinaryToPermission(item.services));
        setNewsletter(BinaryToPermission(item.newsletter));
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti - Zmena Array To Byte        
        items.enabled = enabled;
        items.label = label;
        items.note = note;
        items.products = PermissionToBinary(products);
        items.products_categories = PermissionToBinary(productsCategories);
        items.products_groups = PermissionToBinary(productsGroups);
        items.products_manufactures = PermissionToBinary(productsManufactures);
        items.products_vendors = PermissionToBinary(productsVendors);
        items.show_cost = showCost;
        items.users = PermissionToBinary(users);
        items.users_groups = PermissionToBinary(usersGroups);
        items.sms_notify = PermissionToBinary(smsNotify);
        items.credit_buy = creditBuy;
        items.price_lists = PermissionToBinary(priceLists);
        items.stocks = PermissionToBinary(stocks);
        items.stocks_adjustment = PermissionToBinary(stockAdjustment);
        items.stocks_transport = PermissionToBinary(stockTransport);
        items.stocks_approve = stocksApprove;
        items.stocks_limited = stocksLimited;
        items.stock_removals = PermissionToBinary(stockRemovals);
        items.stock_orders = PermissionToBinary(stockOrders);
        items.stock_orders_received = PermissionToBinary(stockOrdersReceived);
        items.code_book = PermissionToBinary(codeBook);
        items.conditions = PermissionToBinary(conditions);
        items.customers = PermissionToBinary(customers);
        items.customers_categories = PermissionToBinary(customersCategories);
        items.customers_limited = customersLimited;
        items.contacts = PermissionToBinary(contacts);
        items.contacts_limited = contactsLimited;
        items.stock_cards = PermissionToBinary(stockCards);
        items.orders = PermissionToBinary(orders);
        items.order_types = PermissionToBinary(orderTypes);
        items.orders_limited = ordersLimited;
        items.distributors = PermissionToBinary(distributors);
        items.approvals = PermissionToBinary(approvals);
        items.reklamacie = PermissionToBinary(reklamacie);
        items.ai = ai;
        items.presentations = PermissionToBinary(presentations);
        items.quizes = PermissionToBinary(quizes);
        items.blog_internal = PermissionToBinary(blogInternal);
        items.blog_public = PermissionToBinary(blogPublic);
        items.services = PermissionToBinary(services);
        items.newsletter = PermissionToBinary(newsletter);
    }

    const GetData = () => {
        // získanie údajov do reťazca
        let data = {
            id: id,
            organization_id: props.organization.id,
            user_id: props.user.id,
            enabled: enabled,
            label: label,
            note: note,
            products: PermissionToBinary(products),
            products_categories: PermissionToBinary(productsCategories),
            products_groups: PermissionToBinary(productsGroups),
            products_manufactures: PermissionToBinary(productsManufactures),
            products_vendors: PermissionToBinary(productsVendors),
            show_cost: showCost,
            users: PermissionToBinary(users),
            users_groups: PermissionToBinary(usersGroups),
            sms_notify: PermissionToBinary(smsNotify),
            credit_buy: creditBuy,
            price_lists: PermissionToBinary(priceLists),
            stocks: PermissionToBinary(stocks),
            stock_adjustment: PermissionToBinary(stockAdjustment),
            stock_transport: PermissionToBinary(stockTransport),
            stocks_approve: stocksApprove,
            stocks_limited: stocksLimited,
            stock_removals: PermissionToBinary(stockRemovals),
            stock_orders: PermissionToBinary(stockOrders),
            stock_orders_received: PermissionToBinary(stockOrdersReceived),
            code_book: PermissionToBinary(codeBook),
            conditions: PermissionToBinary(conditions),
            customers: PermissionToBinary(customers),
            customers_categories: PermissionToBinary(customersCategories),
            customers_limited: customersLimited,
            contacts: PermissionToBinary(contacts),
            contacts_limited: contactsLimited,
            stock_cards: PermissionToBinary(stockCards),
            orders: PermissionToBinary(orders),
            order_types: PermissionToBinary(orderTypes),
            orders_limited: ordersLimited,
            distributors: PermissionToBinary(distributors),
            approvals: PermissionToBinary(approvals),
            reklamacie: PermissionToBinary(reklamacie),
            ai: ai,
            presentations: PermissionToBinary(presentations),
            quizes: PermissionToBinary(quizes),
            blog_internal: PermissionToBinary(blogInternal),
            blog_public: PermissionToBinary(blogPublic),
            services: PermissionToBinary(services),
            newsletter: PermissionToBinary(newsletter),
        }

        return data;
    }

    const Save = () => {

        var err = 0;

        err = IsEmpty(label) ? 1 : 0;

        if (err == 0) {
            let data = GetData();

            StoreEditing();
            setEditing(false);

            db_update(data);
        } else {
            setError(lang.required_red);
        }
    }


    const MakeCopy = (value) => {
        setShowMakeCopy(false);

        if (value == true) {
            db_copy();
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faKey} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.itemEditing == false ? lang.profil_new : lang.profil_edit}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        {items != false ?
                            <div style={{ ...styles.BlockCenter, width: column1 + column2 + (2 * offset), paddingTop: offset, paddingBottom: offset * 2, backgroundColor: color.white }}>
                                <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.permissions_modules} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                <FormYesNo value={enabled} editing={editing} title={lang.profil_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEnabled(txt)} />
                                <FormText require value={label} editing={editing} title={lang.profil_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setLabel(txt)} />
                                <FormText value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />

                                {/* NASTAVENIA - APLIKACIA */}
                                <FormLabel editing={editing} title={lang.application} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />

                                <FormPermissions value={smsNotify} items={states1} field={'label'} editing={editing} title={lang.sms_notifies} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setSMSNotify(id)} />
                                <FormYesNo value={creditBuy} editing={editing} title={lang.permissions_credit_buy} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCreditBuy(txt)} />
                                <FormPermissions value={codeBook} items={states1} field={'label'} editing={editing} title={lang.codebooks} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCodeBook(id)} />
                                <FormPermissions value={conditions} items={states1} field={'label'} editing={editing} title={lang.conds} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setConditions(id)} />
                                <FormPermissions value={approvals} items={states1} field={'label'} editing={editing} title={lang.approvals_ask} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setApprovals(id)} />
                                {props.system.ai_enabled == true ?
                                    <FormYesNo value={ai} editing={editing} title={lang.openai_label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setAI(txt)} />
                                    : null}

                                {/* PRODUKTY */}
                                <FormLabel editing={editing} title={lang.products} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                <FormPermissions value={products} items={states2} field={'label'} editing={editing} title={lang.products} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setProducts(id)} />
                                <FormYesNo value={showCost} editing={editing} title={lang.products_costs} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setShowCost(id)} />
                                <FormNote title={lang.product_show_cost} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                {products.includes(1) == true ?
                                    <div>
                                        <FormPermissions value={productsCategories} items={states2} field={'label'} editing={editing} title={lang.products_categories} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setProductsCategories(id)} />
                                        <FormPermissions value={productsGroups} items={states2} field={'label'} editing={editing} title={lang.products_groups} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setProductsGroups(id)} />
                                        <FormPermissions value={productsManufactures} items={states2} field={'label'} editing={editing} title={lang.products_manufactures} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setProductsManufactures(id)} />
                                        <FormPermissions value={productsVendors} items={states2} field={'label'} editing={editing} title={lang.products_vendors} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setProductsVendors(id)} />
                                        <FormPermissions value={priceLists} items={states2} field={'label'} editing={editing} title={lang.price_lists} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setPriceLists(id)} />
                                    </div>
                                    : null}

                                {/* SKLADY */}
                                <FormLabel editing={editing} title={lang.stocks} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                <FormPermissions value={stocks} items={states2} field={'label'} editing={editing} title={lang.stocks} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setStocks(id)} />
                                <FormPermissions value={stockAdjustment} items={states2} field={'label'} editing={editing} title={lang.stock_adjustment_} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setStockAdjustment(id)} />
                                <FormPermissions value={stockTransport} items={states2} field={'label'} editing={editing} title={lang.stock_transport} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setStockTransport(id)} />
                                <FormPermissions value={stockCards} items={states2} field={'label'} editing={editing} title={lang.stock_cards} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setStockCards(id)} />
                                <FormPermissions value={stockRemovals} items={states2} field={'label'} editing={editing} title={lang.stock_removal} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setStockRemovals(id)} />
                                <FormPermissions value={stockOrders} items={states2} field={'label'} editing={editing} title={lang.stock_orders} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setStockOrders(id)} />
                                <FormPermissions value={stockOrdersReceived} items={states2} field={'label'} editing={editing} title={lang.stock_orders_received_} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setStockOrdersReceived(id)} />
                                <FormYesNo value={stocksLimited} editing={editing} title={lang.stocks_limited} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setStocksLimited(id)} />
                                {stocksLimited == true ?
                                    <FormNote title={lang.stocks_limited_1} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    :
                                    <FormNote title={props.system.use_roles == true ? lang.stocks_limited_2 : lang.stocks_limited_0} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                }
                                <FormYesNo help_id={18} value={stocksApprove} editing={editing} title={lang.permissions_stocks_approve} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} yes={lang.permissions_stocks_approve_1} no={lang.permissions_stocks_approve_0} func={(id) => setStocksApprove(id)} />
                                {stocksApprove == true ?
                                    <FormNote title={lang.permissions_stocks_approve_note_1} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    :
                                    <FormNote title={lang.permissions_stocks_approve_note_0} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                }

                                {/* NASTAVENIA - UZIVATELIA */}
                                <FormLabel editing={editing} title={lang.users} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                <FormPermissions value={users} items={states1} field={'label'} editing={editing} title={lang.users} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setUsers(id)} />
                                <FormPermissions value={usersGroups} items={states1} field={'label'} editing={editing} title={lang.users_groups_} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setUsersGroups(id)} />

                                {/* NASTAVENIA - ZAKAZNICI */}
                                <FormLabel editing={editing} title={lang.customers} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                <FormPermissions value={customers} items={states1} field={'label'} editing={editing} title={lang.permissions_customers} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCustomers(id)} />
                                <FormPermissions value={customersCategories} items={states1} field={'label'} editing={editing} title={lang.permissions_customers_groups} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCustomersCategories(id)} />
                                <FormYesNo value={customersLimited} editing={editing} title={lang.permissions_customers_limited} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCustomersLimited(id)} />
                                {customersLimited == true ?
                                    <FormNote title={lang.permissions_customers_limited_note_1} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    :
                                    <FormNote title={props.system.use_roles == true ? lang.permissions_customers_limited_note_2 : lang.permissions_customers_limited_note_0} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                }

                                {/* NASTAVENIA - KONTAKTY */}
                                <FormLabel editing={editing} title={lang.contacts} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                <FormPermissions value={contacts} items={states1} field={'label'} editing={editing} title={lang.permissions_contacts} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setContacts(id)} />
                                <FormYesNo value={contactsLimited} editing={editing} title={lang.permissions_contacts_limited} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setContactsLimited(id)} />
                                {contactsLimited == true ?
                                    <FormNote title={lang.permissions_contacts_limited_note_1} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    :
                                    <FormNote title={props.system.use_roles == true ? lang.permissions_contacts_limited_note_2 : lang.permissions_contacts_limited_note_0} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                }

                                {/* OBJEDNAVKY */}
                                <FormLabel editing={editing} title={lang.orders} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                <FormPermissions value={orders} items={states1} field={'label'} editing={editing} title={lang.orders_products} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setOrders(id)} />
                                <FormPermissions value={orderTypes} items={states1} field={'label'} editing={editing} title={lang.order_types} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setOrderTypes(id)} />
                                <FormPermissions value={distributors} items={states1} field={'label'} editing={editing} title={lang.distributors} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setDistributors(id)} />
                                <FormYesNo value={ordersLimited} editing={editing} title={lang.permissions_orders_limited} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setOrdersLimited(id)} />
                                {ordersLimited == true ?
                                    <FormNote title={lang.permissions_orders_limited_note_1} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    :
                                    <FormNote title={props.system.use_roles == true ? lang.permissions_orders_limited_note_2 : lang.permissions_orders_limited_note_0} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                }
                                <FormPermissions value={reklamacie} items={states1} field={'label'} editing={editing} title={lang.reklamacie_orders} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setReklamacie(id)} />

                                {/* AGENDA */}
                                <FormLabel editing={editing} title={lang.agenda} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                <FormPermissions value={presentations} items={states1} field={'label'} editing={editing} title={lang.presentations} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setPresentations(id)} />
                                <FormPermissions value={quizes} items={states1} field={'label'} editing={editing} title={lang.quizes} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setQuizes(id)} />

                                {/* MARKETING */}
                                {props.system.marketing_enabled == true ?
                                    <>
                                        <FormLabel editing={editing} title={lang.marketing} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                        <FormPermissions value={blogInternal} items={states1} field={'label'} editing={editing} title={lang.blog_internal} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setBlogInternal(id)} />
                                        <FormPermissions value={newsletter} items={states1} field={'label'} editing={editing} title={lang.newsletter_permissions} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setNewsletter(id)} />
                                    </>
                                    : null}

                                {/* ESHOP */}
                                {props.system.eshop_enabled == true ?
                                    <>
                                        <FormLabel editing={editing} title={lang.eshop} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                        <FormPermissions value={blogPublic} items={states1} field={'label'} editing={editing} title={lang.blog_public} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setBlogPublic(id)} />

                                        {/* SLUZBY */}
                                        <FormLabel editing={editing} title={lang.services} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                                        <FormPermissions value={services} items={states2} field={'label'} editing={editing} title={lang.services} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setServices(id)} />
                                    </>
                                    : null}

                                {/* SAVE - CANCEL */}
                                {editing == true ?
                                    <div style={{ ...styles.Block, paddingTop: 10 }}>
                                        <FormError theme={props.theme} error={error} small />

                                        <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                                            </div>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ ...styles.Block, marginTop: 50 }}>
                                        {props.user.super_admin == true ?
                                            <Button onClick={() => setShowMakeCopy(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.make_copy}</Button>
                                            : null}
                                    </div>
                                }
                            </div>
                            : null}

                        {items != false && props.itemEditing != false && editing == false ?
                            <div style={{ ...styles.BlockLeft, width: column1 + column2 + (2 * offset), paddingTop: 30 }}>
                                <DialogDates created={items.created} updated={items.updated} updated_by={items.user} lang={props.lang} theme={props.theme} />
                            </div>
                            : null}

                        {props.remote == true && props.remoteMobile == true ?
                            <FormSpace height={500} />
                            : null}

                    </div >
                </div >

                {showMakeCopy == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.permissions_make_copy} text={lang.permissions_make_copy_ask} sub_text={lang.permissions_make_copy_text} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={MakeCopy.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}

