import React, { useEffect, useState } from 'react';

import './globals.js';
import './themes.js';
import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Main } from './Main';
import { NotFound } from './notfound';
import { styles } from './styles';
import { Start } from './Start';
import { Helmet } from 'react-helmet';
import { AppLogout } from './logout';
import { Register } from './register';
import { Init } from './functions';
import { UserActivate } from './user_activate.js';
import { OrganizationActivate } from './organization_activate.js';
import { LoginRemote } from './login_remote.js';

function App() {
    //const [width, setWidth] = useState(window.innerWidth);
    //const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {

        // RELOAD PAGE - AK SA ZMENI VELKOST OKNA               
        /*
        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);
        */
        Init();
    }, [])


    return (
        <div className="App" style={{ ...styles.Block, backgroundColor: global.theme_black, fontFamily: 'roboto', WebkitUserSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', userSelect: 'none' }}>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
            />
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            </Helmet>
            <Routes>
                <Route path="/" element={<Start />} />
                <Route path="/code/:token/:version" element={<LoginRemote />} />
                <Route path="/application" element={<Main />} />
                <Route path="/application/:remote/:version" element={<Main />} />
                <Route path="/logout" element={<AppLogout />} />
                <Route path="/register" element={<Register />} />
                <Route path="/account/:uid" element={<UserActivate />} />
                <Route path="/organization/:uid" element={<OrganizationActivate />} />

                <Route path="error-404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
}

export default App;
