/*
*
* =================================================================================================================
* OBJEDNAVKY TOVARU NA SKLAD

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useRef, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormSelect, DeleteButton, FormProduct, ProductSearch, TextLine, GetReserve, WebURLPreview, FormNote, ContactCompany, GetNameSurname } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, IconButton, Pagination, Paper, Skeleton, Stack, TextField, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowPointer, faArrowUp, faCheck, faInfoCircle, faLayerGroup, faPen, faPlus, faPlusCircle, faQuestion, faShoppingCart, faTrash, faUser, faWandMagicSparkles, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, FormatMoney, FormatPieces, GetLeftPosition, GetMoney, GetPages, IsEmpty } from './functions';
import { Photos } from './photos';
import { ProductSelect } from './product_select.js';
import { GetDatum1, GetDatum2, GetDatumTime, GetDatumTime2, GetTodayYear, Today } from './functions_date.js';
import { ProductInfo } from './products.js';
import { Notes } from './notes.js';
import { WarehouseStockCard } from './warehouse.js';
import { OrderSelect } from './orders.js';
import { AddShoppingCart } from '@mui/icons-material';
import { CustomerSelect } from './customers.js';

export const Reklamacie = (props) => {

    let module_id = global.model.reklamacie;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'reklamacie';

    let defaultFilter = {
        ordering: global.order.up,
        order_column: 'created',
        year: GetTodayYear(),
        country_id: props.locale.id,
        status: props.customerID == undefined || props.customerID == 0 ? 1 : 0,
        customer_id: props.customerID == undefined ? 0 : props.customerID,
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    const stockStates = [
        { id: 0, name: lang.all_records },
        { id: 1, name: lang.stock_order_status[1] },
        { id: 2, name: lang.stock_order_status[2] },
    ]
    const [stockStateID, setStockStateID] = useState(props.customerID == undefined || props.customerID == 0 ? 1 : 0);

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.stock_adjustment_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [year, setYear] = useState(GetTodayYear());
    const [order, setOrder] = useState(false);

    // FILTER
    const [orderNumber, setOrderNumber] = useState('');
    const [orderID, setOrderID] = useState(0);
    const [userID, setUserID] = useState(0);
    const [user, setUser] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.number, default: true },
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showOrders, setShowOrders] = useState(false);
    const [showOrderFilter, setShowOrderFilter] = useState(false);
    const [showCustomers, setShowCustomers] = useState(false);
    const [customer, setCustomer] = useState(false);
    const [customerID, setCustomerID] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 86;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const menuWidth = global.sub_menu_width;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);

    const db_get = async (page, filter, maxItems) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacie', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.stock_orders,
                    filename: lang.stock_orders, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            filter.search = txt;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            filter.search = '';
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth', block: "start" });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setOrder(false);
        setShowOrders(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }


    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // NAZOV
                column = 'name';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;
        }

        var filter = {
            ordering: order,
            order_column: column
        };
        if (search != '') {
            filter.search = search;
        }
        db_get(0, filter, maxItems);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const SelectYearResult = (year_, month) => {
        setYear(year_);
        var filter_ = GetFilter();
        filter_.year = year_;
        db_get(0, filter_, maxItems);
    }

    const GetFilter = () => {
        var filter = defaultFilter;
        //filter.year = year;
        //filter.ordering = ordering;
        //filter.order_column = orderColumn;

        filter.status = stockStateID;

        if (orderID > 0) {
            filter.order_id = orderID;
            //delete (filter.year);
        }

        if (customerID > 0) {
            filter.customer_id = customerID;
        }

        if (search.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            filter.search = search;
            filter.search_columns = columns;
        }

        return filter;
    }

    const ChangeState = (id) => {
        setStockStateID(id);

        var filter = GetFilter();
        filter.status = id;
        db_get(0, filter, maxItems);
    }

    const OrdersResult = (typ, item) => {
        setShowOrders(false);

        if (typ == true) {
            setOrder(item);
            setItemSelected(false);

            var timer = setTimeout(() => {
                setShowDialog(true);
            }, 250);

        }
    }

    const OrderFilterResult = (typ, item) => {
        setShowOrderFilter(false);

        if (typ == true) {
            var filter = GetFilter();
            filter.order_id = item.id;
            //delete (filter.year);

            setOrderID(item.id);
            setOrderNumber(item.number);

            db_get(0, filter, maxItems);
        }
    }

    const OrderReset = () => {
        var filter = GetFilter();
        delete (filter.order_id);
        filter.year = year;
        setOrderID(0);

        db_get(0, filter, maxItems);
    }

    const CustomersResult = (typ, items, selected) => {
        setShowCustomers(false);

        if (typ == true && items != false) {
            setCustomerID(items[0]);
            setCustomer(selected[0]);

            var filter = GetFilter();
            filter.customer_id = items[0];

            db_get(0, filter, maxItems);
        }
    }

    const CustomerReset = () => {
        var filter = GetFilter();
        delete (filter.customer_id);
        //filter.year = year;
        setCustomerID(0);

        db_get(0, filter, maxItems);
    }

    const UserOnly = () => {
        setUserID(props.user.id);
        setUser(props.user);

        var filter = GetFilter();
        filter.agent_id = props.user.id;
        filter.limited = true;

        db_get(0, filter, maxItems);
    }

    const UserReset = () => {
        setUserID(0);

        var filter = GetFilter();
        delete (filter.agent_id);
        delete (filter.limited);
        setFilter(filter);

        db_get(0, filter, maxItems);
    }

    return (

        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.embedded == true ? 0 : props.radius }}>
            {props.embedded == true ? null :
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} />
                    </div>
                    <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        {global.appstore == false ?
                            <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
            }
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.embedded == true ? props.height : props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.embedded == true ? 0 : props.radius, borderBottomRightRadius: props.embedded == true ? 0 : props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.embedded == true ? props.height : props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div id={'id_start'} ></div>
                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%', marginTop: 10 }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '45%', height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.BlockLeft, width: undefined, height: menuHeight }}>
                                            <TextInput search theme={props.theme} width={200} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        </div>
                                        <div style={{ ...styles.Block, width: 30, height: menuHeight }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                        <div style={{ ...styles.Block, width: 200, height: menuHeight }}>
                                            <SelectInput redraw theme={props.theme} enabled={true} lang={lang} value={stockStateID} items={stockStates} width={180} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => ChangeState(id)} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ ...styles.Block, width: 170, height: menuHeight }}>
                                    <Button onClick={() => setShowOrderFilter(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                        {lang.orders}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, width: 170, height: menuHeight }}>
                                    {props.embedded == true ? null :
                                        <Button onClick={() => setShowCustomers(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                            <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                            {lang.customer}
                                        </Button>
                                    }
                                </div>
                                <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                    {props.embedded == true ? null :
                                        <IconButton onClick={() => UserOnly(true)} style={{ ...styles.ButtonUser }}>
                                            <FontAwesomeIcon style={{ height: 14 }} icon={faUser} />
                                        </IconButton>
                                    }
                                </div>
                                {/*
                                <div style={{ ...styles.BlockRight, width: 200, height: menuHeight }}>
                                    {orderID == 0 ?
                                        <SelectYear min={2020} value={year} theme={props.theme} func={SelectYearResult.bind(this)} />
                                        : null}
                                </div>
                                */}
                                <div style={{ ...styles.BlockRight, width: 220, height: menuHeight }}>
                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 200 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.reklamacia_new}
                                        </Button>
                                        : null}
                                </div>
                                {props.embedded == true ?
                                    <div style={{ ...styles.BlockRight, width: 60, height: menuHeight, alignItems: 'flex-end' }}>
                                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                                    </div>
                                    : null}
                            </div>

                            <div style={{ ...styles.Block, marginTop: 20 }}>

                                <div style={{ ...styles.BlockLeft, marginBottom: 10 }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        {orderID > 0 ?
                                            <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.order}: {orderNumber}</p>} onDelete={() => OrderReset()}></Chip>
                                            : null}
                                        {customerID > 0 ?
                                            <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.customer}: {GetNameSurname(customer.company, customer.name, customer.surname, props.settings.name_format)}</p>} onDelete={() => CustomerReset()}></Chip>
                                            : null}
                                        {userID > 0 ?
                                            <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.customers_of_rep}: {user.name + ' ' + user.surname}</p>} onDelete={() => UserReset()}></Chip>
                                            : null}
                                    </div>
                                </div>

                                {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, marginBottom: 10, border: color.border }}>
                                    {/* DATA */}
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: tableLabelHeight, justifyContent: 'center' }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.number}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 200, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.order}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.reklamacia_approval_status}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 200, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.date}</p>
                                    </div>
                                </div>

                                {/* ****************************************************************************************************************
                                        TABLE DATA
                                **************************************************************************************************************** */}

                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowHeight,
                                            cursor: 'pointer',
                                            marginTop: 5, marginBottom: 5,
                                            paddingTop: 2, paddingBottom: 2,
                                            borderLeft: item.enabled == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow }}>
                                            {/* DATA */}
                                            <div style={{ ...styles.BlockLeft, width: 10, height: rowHeight, justifyContent: 'center' }}>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '50%', height: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, marginBottom: 10 }}>{item.number}</p>
                                                <ContactCompany company={item.company} name={item.name} surname={item.surname} theme={props.theme} />
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: 200, height: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.order}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 150, height: rowHeight, justifyContent: 'center' }}>
                                                {item.status == 1 ?
                                                    <Chip label={lang.stock_order_status[item.status]} size='small' variant='outlined' style={{ color: global.stock_order_chip[item.status], borderColor: global.stock_order_chip[item.status], fontSize: global.font_tiny }} />
                                                    :
                                                    <>
                                                        {item.status < global.reklamacie.finished ?
                                                            <Chip label={lang.stock_order_status[item.status]} size='small' variant='outlined' style={{ marginBottom: 10, color: global.stock_order_chip[item.status], borderColor: global.stock_order_chip[item.status], fontSize: global.font_tiny }} />
                                                            : null}
                                                        <Chip label={lang.reklamacia_approvals_status[item.approval_status]} size='small' variant='outlined' style={{ color: global.reklamacie_approval[item.approval_status], borderColor: global.reklamacie_approval[item.approval_status], fontSize: global.font_tiny }} />
                                                    </>
                                                }
                                            </div>
                                            <div style={{ ...styles.Block, width: 200, height: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny }}>{GetDatumTime2(item.updated)}</p>
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <EmptyList row small lang={lang} />
                                }

                                {/* ****************************************************************************************************************
                                        PAGINATION
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 200 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showCustomers == true ?
                <CustomerSelect remote={props.remote} remoteMobile={props.remoteMobile} single showVariations={true} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomersResult.bind()} />
                : null}

            {showExport == true ?
                <DialogExport remote={props.remote} remoteMobile={props.remoteMobile} rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <Reklamacia remote={props.remote} remoteMobile={props.remoteMobile} module_id={module_id} item={itemSelected} order={order} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showOrderFilter == true ?
                <OrderSelect remote={props.remote} remoteMobile={props.remoteMobile} customerID={props.embedded == true ? props.customerID : customerID > 0 ? customerID : undefined} title={lang.reklamacia_order_filter} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={OrderFilterResult.bind()} />
                : null}

            {showOrders == true ?
                <OrderSelect remote={props.remote} remoteMobile={props.remoteMobile} customerID={props.embedded == true ? props.customerID : undefined} info={lang.reklamacia_order_info} title={lang.reklamacia_order_select} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={OrdersResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}



/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const Reklamacia = (props) => {

    const image_default = require('./react/app/default_image.png');

    let module_id = global.model.reklamacie;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let currency = props.locale.currency;
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let show_cost = props.system.show_cost_prices == false ? false : props.user.super_admin == true ? true : props.permissions.show_cost == false ? false : true;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);
    const [overID, setOverID] = useState(0);
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [number, setNumber] = useState('');
    const [status, setStatus] = useState(global.reklamacie.created);
    const [reklamaciaTyp, setReklamaciaTyp] = useState(0);
    const [reasonID, setReasonID] = useState(-1);
    const [dodaciList, setDodaciList] = useState('');
    const [fakturaCislo, setFakturaCislo] = useState('');
    const [fakturaDate, setFakturaDate] = useState(Today());
    const [message, setMessage] = useState('');
    const [reasonDenyID, setReasonDenyID] = useState(-1);
    const [approvalStatus, setApprovalStatus] = useState(0);
    const [comment, setComment] = useState('');
    const [orderID, setOrderID] = useState(props.item == false ? props.order.id : 0);
    const [order, setOrder] = useState(false);
    const [customer, setCustomer] = useState(false);

    // UPRAVA MNOZSTVA
    const [quantity, setQuantity] = useState('');
    const [itemSelected, setItemSelected] = useState(false);

    // PRODUKTY
    const [products, setProducts] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(50);
    const [product, setProduct] = useState(false);
    const [showFinish, setShowFinish] = useState(false);

    const [selectProducts, setSelectProducts] = useState(false);
    const [excluded, setExcluded] = useState([]);

    // IMAGES
    const [images, setImages] = useState(false);
    const [imageSelected, setImageSelected] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showProductDetails, setShowProductDetails] = useState(false);
    const [productSelected, setProductSelected] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = itemID == false ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = 400;
    const rowHeight = 80;
    const tableHeight = 40;
    const titleHeight = 80;
    const paginationHeight = 80;
    const imageSize = 200;

    const [tabs, setTabs] = useState([
        { id: 0, enabled: true, label: lang.setting },
        { id: 1, enabled: true, label: lang.products },
        { id: 4, enabled: true, label: lang.reklamacia_images },
        { id: 3, enabled: true, label: lang.notes },
        { id: 2, enabled: true, label: lang.history },
    ]);

    let itemsExportMenu = [
        { id: 1, enabled: true, label: lang.order_export_pdf },
    ];

    let { func } = props;
    var running = false;
    const inputRef = useRef(null);

    useEffect(() => {
        if (running == false) {
            db_get_data();

            if (itemID != 0) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);
                    AssignData(item);
                    setOrder(json.order);
                    setCustomer(json.customer);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setNumber(json.number);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: itemID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setEditing(false);
                if (itemID == 0) {
                    setItemID(json.ok);
                }
                setRedrawRequired(true);

                if (data.status == global.reklamacie.finished) {
                    setStatus(data.status);
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_products = async (page, filter, maxItems, scroll) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    reklamacia_id: itemID,
                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                var tmp = json.items;
                setProducts(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setExcluded(json.items_id);

                if (scroll == true) {
                    GoToStart();
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_product_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_product_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    reklamacia_id: itemID,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                db_products(page, false, maxItems, false);
            } else {
                setShowError(true);
            }
            setRedrawRequired(true);
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_images = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_images', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    reklamacia_id: itemID,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                var tmp = json.items;
                setImages(tmp);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_image_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_image_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    reklamacia_id: itemID,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                db_images();
            } else {
                setShowError(true);
            }
            setRedrawRequired(true);
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_export_pdf', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    reklamacia_id: itemID
                })
            })

            const json = await response.json();
            Debug('Export PDF');
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setRedrawRequired(true);
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const MenuResult = (id, index, item) => {
        switch (index) {
            case 1:
                db_export();
                break;

            case 2:
                break;
        }
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB

        setNumber(item.number);
        setReklamaciaTyp(item.typ);
        setStatus(item.status);
        setApprovalStatus(item.approval_status);
        setReasonID(item.reason_id == 0 ? -1 : item.reason_id);
        setDodaciList(item.dodaci_list);
        setFakturaCislo(item.faktura_cislo);
        setFakturaDate(item.faktura_date);
        setMessage(item.message);
        setReasonDenyID(item.reason_deny_id == 0 ? -1 : item.reason_deny_id);
        setComment(item.comment);
        setOrderID(item.order_id);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.number = number;
        storeData.status = status;
        storeData.typ = reklamaciaTyp;
        storeData.reason_id = reasonID;
        storeData.dodaci_list = dodaciList;
        storeData.faktura_cislo = fakturaCislo;
        storeData.faktura_date = fakturaDate;
        storeData.message = message;
        storeData.reason_deny_id = reasonDenyID;
        storeData.comment = comment;
    }

    const Save = () => {
        setError('');
        var error = false;
        error += IsEmpty(number) == true ? 1 : 0;

        if (reasonID < 1) {
            error++;
        }

        if (error == 0) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: itemID,
                action: itemID == 0 ? global.logs.created : global.logs.modified
            };

            var data = {
                id: itemID,
                enabled: true,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                user_id: props.user.id,
                number: number.trim(),
                status: status,
                approval_status: approvalStatus,
                typ: reklamaciaTyp,
                reason_id: reasonID,
                dodaci_list: dodaciList,
                faktura_cislo: fakturaCislo,
                faktura_date: fakturaDate,
                message: itemID == 0 ? '' : message,
                reason_deny_id: approvalStatus == 1 ? reasonDenyID : 0,
                comment: comment,
                logs: log
            };

            if (props.item == false) {
                data.order_id = props.order.id;
                data.customer_id = props.order.customer_id;
            }

            StoreEditing();
            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);
        if (id == 1) {
            db_products(0, false, maxItems, true);
        }
        if (id == 2) {
            db_get_logs();
        }
        if (id == 4) {
            db_images();
        }
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            let data = {
                id: 0,
                enabled: true,
                organization_id: props.organization.id,
                reklamacia_id: itemID,
                image: path + file,
            }

            db_image_update(data);
        }
    }

    const ProductResult = (typ, items) => {
        setSelectProducts(false);

        if (typ == true) {
            var array = [];
            items.forEach(item => {

                let data = {
                    id: 0,
                    enabled: true,
                    organization_id: props.organization.id,
                    reklamacia_id: itemID,
                    product_id: item.product_id,
                    stock_uid: item.stock_uid,
                    order_item_id: item.id,
                    quantity: item.quantity,
                    typ: reklamaciaTyp == 0 ? 1 : 0
                }
                array.push(data);
            });

            db_product_update(array);
        }
    }

    const DeleteProduct = (item) => {
        let data = {
            id: item.id,
            enabled: false,
            quantity: 0
        }
        db_product_update([data]);
    }

    const ChangeQuantity = (value) => {
        setQuantity(value);
    }

    const EditQuantity = (item) => {
        setQuantity(parseInt(item.quantity));
        setItemSelected(item);
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);
    }

    const SaveQuantity = () => {
        let data = {
            id: itemSelected.id,
            quantity: quantity,
        }
        db_product_update([data]);

        setItemSelected(false);
    }

    const ChangeEditing = () => {
        setEditing(true);
    }

    const productDetails = (item) => {
        if (status == 1) {
            if (reklamaciaTyp > 0) {
                setProductSelected(item);
                setShowProductDetails(true);
            }
        }
    }

    const ProductDetailsResult = (typ, result) => {
        setShowProductDetails(false);

        if (typ == true) {
            let data = {
                id: productSelected.id,
                typ: result
            }
            db_product_update([data]);
        }
    }

    const FinishResult = (value) => {
        setShowFinish(false);

        if (value == true) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: itemID,
                action: global.logs.finished
            };

            let data = {
                id: itemID,
                status: global.reklamacie.finished,
                logs: log
            };

            db_update(data);
        }
    }

    const NotesResult = () => { }

    const SelectProducts = () => {
        if (products != false) {
            var arr = [0];
            products.forEach(product => {
                arr.push(product.product_id);
            });

            setExcluded(arr);
        } else {
            setExcluded([0]);
        }
        setSelectProducts(true);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_products(page, false, item, true);
    }


    const PreviewImage = (item) => {
        var image = item.image.substring(0, 4).toLowerCase() == 'http' ? item.image : global.web + '/' + item.image;
        setImageSelected(image);
        setShowPreview(true);
    }

    const DeleteImage = (item) => {
        let data = {
            id: item.id,
            enabled: false,
        }

        db_image_update(data);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 750 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120 - 50, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{itemID == 0 ? lang.reklamacia_new_label : lang.reklamacia}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 50, height: global.widow_bar_height }}>
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.Block, height: tabHeight }}>
                        {itemID > 0 && editing == false ?
                            <DialogTabs center value={tabIndex} items={tabs} width={dialogWidth / 5} theme={props.theme} func={(id) => ChangeTab(id)} />
                            : null}
                    </div>


                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block }}>
                                {storeData != false ?
                                    <div>
                                        <FormSpace />
                                        <FormLabel editing={editing} editButton={status == 1 ? CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false : false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => ChangeEditing(true)} />
                                        <FormText require redraw value={number} editing={editing} title={lang.number} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNumber(txt)} />

                                        <FormSpace />
                                        <FormYesNo value={reklamaciaTyp} editing={editing} title={lang.reklamacia_type} column1={column1} column2={column2} theme={props.theme} yes={lang.reklamacia_types[1]} no={lang.reklamacia_types[0]} lang={lang} radius={props.radius} func={(id) => setReklamaciaTyp(id)} />
                                        <FormNote title={reklamaciaTyp == 0 ? lang.reklamacia_type_0 : lang.reklamacia_type_1} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                                        <FormSelect require value={reasonID} items={false} field={'name'} codebook={global.codebook.reklamacia_reason} editing={editing} title={lang.reklamacia_reason} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setReasonID(id)} />

                                        <FormSpace />
                                        <FormLabel editing={editing} title={lang.reklamacia_data} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                        {order != false && editing == false ?
                                            <>
                                                <FormText require redraw value={order.number} editing={false} title={lang.order_number_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                                <FormText require redraw value={customer.company} editing={false} title={lang.customer} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                                <FormText require redraw value={customer.name + ' ' + customer.surname} editing={false} title={lang.name_surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                            </>
                                            : null}
                                        <FormText redraw value={dodaciList} editing={editing} title={lang.reklamacia_dodaci_list} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDodaciList(txt)} />
                                        <FormText redraw value={fakturaCislo} editing={editing} title={lang.reklamacia_faktura} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setFakturaCislo(txt)} />
                                        <FormText redraw date value={fakturaDate} editing={editing} title={lang.reklamacia_faktura_date} column1={column1} column2={200} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setFakturaDate(txt)} />

                                        <FormSpace />
                                        <FormLabel editing={editing} title={lang.reklamacia_approval_label} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                        <FormYesNo value={approvalStatus} editing={editing} title={lang.reklamacia_approval_status} column1={column1} column2={column2} theme={props.theme} no={lang.reklamacia_approvals_status[0]} yes={lang.reklamacia_approvals_status[1]} default={lang.reklamacia_approvals_status[2]} lang={lang} radius={props.radius} func={(id) => setApprovalStatus(id)} />
                                        {approvalStatus == 2 ?
                                            <FormSelect require value={reasonDenyID} items={false} field={'name'} codebook={global.codebook.reklamacia_deny} editing={editing} title={lang.reklamacia_deny} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setReasonDenyID(id)} />
                                            : null}
                                        <FormSpace />
                                        <FormText redraw value={comment} rows={5} editing={editing} title={lang.reklamacia_comment} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setComment(txt)} />

                                        {props.remote == true && props.remoteMobile == true ?
                                            <FormSpace height={500} />
                                            : null}

                                    </div>
                                    :
                                    <Stack style={{ ...styles.Block }} spacing={1}>
                                        <FormSpace />
                                        <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    </Stack>}
                            </div>

                        </div>
                        : null}

                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight }} >
                            <div style={{ ...styles.Block, width: '96%' }}>
                                <div style={{ ...styles.BlockRow, width: '99%' }}>
                                    <div style={{ ...styles.BlockLeft, width: '90%', height: titleHeight }}>
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{reklamaciaTyp == 0 ? lang.reklamacia_products_returned : lang.reklamacia_products_label}</p>
                                        <p style={{ ...styles.TextTiny }}>{reklamaciaTyp == 0 ? lang.reklamacia_products_returned_quantity_ : lang.reklamacia_products_quantity_}: {products == false ? 0 : products.length}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: 200, height: titleHeight }}>
                                        {editing == false ?
                                            status == 1 ?
                                                CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                                    <Button onClick={() => SelectProducts()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, color: color.black, width: 200 }}>
                                                        <FontAwesomeIcon style={{ height: 14, marginRight: 10 }} icon={faPlus} />
                                                        {lang.stock_adjustment_products_add}
                                                    </Button>
                                                    : null : null : null}
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockRow, width: '99%', border: color.border, height: tableHeight }}>
                                    <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableHeight, justifyContent: 'center' }}>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '45%', height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 200, height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.stock}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 150, height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.reklamacia_products_ordered}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 200, height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.reklamacia_products_quantity}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 60, height: tableHeight, borderLeft: color.border }}>
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - titleHeight - tableHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <div id={'id-start'} style={{ ...styles.BlockCenter, paddingBottom: global.list_padding }}>
                                        {products != false ? products.map((item, index) => (

                                            <Paper
                                                key={item.id}
                                                id={'id-product-' + item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    width: '99%',
                                                    backgroundColor: item.enabled == false ? color.disabled : item.grouped == true ? color.grouped : item.variations == true ? color.variation : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : item.variations == true ? color.border_variation : item.grouped == true ? color.border_grouped : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                    <div onClick={() => productDetails(item)} style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <Avatar picture image={item.image} size={rowHeight - 10} theme={props.theme} />
                                                    </div>
                                                    <div onClick={() => productDetails(item)} style={{ ...styles.BlockLeft, width: '45%', minHeight: rowHeight }}>
                                                        {item.parent_name != '' ?
                                                            <TextLine text={item.parent_name} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                            : null}
                                                        <TextLine text={item.name} fontSize={item.parent_name != '' ? global.font_tiny : global.font_xsmall} fontWeight={item.parent_name != '' ? '400' : '600'} color={color.black} />
                                                        <p style={{ ...styles.TextTiny }}>{item.code}</p>
                                                    </div>
                                                    <div onClick={() => productDetails(item)} style={{ ...styles.Block, width: 200, minHeight: rowHeight }}>
                                                        {reklamaciaTyp == 0 ?
                                                            <Chip size='medium' variant='outlined' style={{}} label={lang.reklamacia_product_return}></Chip>
                                                            :
                                                            item.typ > 0 ?
                                                                <Chip size='medium' variant='outlined' style={{}} label={item.typ == 1 ? lang.reklamacia_product_return : lang.reklamacia_product_send}></Chip>
                                                                : null
                                                        }
                                                    </div>
                                                    <div onClick={() => productDetails(item)} style={{ ...styles.Block, width: 150, minHeight: rowHeight }}>
                                                        <p style={{ ...styles.TextTiny }}>{item.quantity_max} {item.unit}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: 200, minHeight: rowHeight }}>
                                                        {itemSelected != false && itemSelected.id == item.id ?
                                                            <div style={{ ...styles.BlockRowRaw }}>
                                                                <div style={{ ...styles.BlockLeft, width: 100, minHeight: rowHeight }}>
                                                                    <TextField
                                                                        inputRef={inputRef}
                                                                        value={itemSelected == false ? FormatPieces(quantity) : quantity}
                                                                        onInput={(e) => ChangeQuantity(e.target.value)}
                                                                        size="small"
                                                                        autoCapitalize="off"
                                                                        autoComplete="off"
                                                                        autoCorrect="off"
                                                                        style={{ width: '100%' }}
                                                                        onKeyDown={(event) => {
                                                                            if (event.key === 'Enter') {
                                                                                SaveQuantity(false);
                                                                            }
                                                                            if (event.key === 'Escape') {
                                                                                setItemSelected(false);
                                                                            }
                                                                        }}
                                                                        InputProps={{
                                                                            style: { backgroundColor: global.theme_white },
                                                                        }}
                                                                        onFocus={event => {
                                                                            event.target.select();
                                                                        }}
                                                                        variant="outlined"
                                                                    />
                                                                </div>
                                                                <div style={{ ...styles.Block, width: 40, minHeight: rowHeight }}>
                                                                    <IconButton onClick={() => SaveQuantity(false)} style={{ ...styles.ButtonIcon }}>
                                                                        <FontAwesomeIcon style={{ width: 14, color: color.dark_green }} icon={faCheck} />
                                                                    </IconButton>
                                                                </div>
                                                                <div style={{ ...styles.Block, width: 40, minHeight: rowHeight }}>
                                                                    <IconButton onClick={() => setItemSelected(false)} style={{ ...styles.ButtonIcon }}>
                                                                        <FontAwesomeIcon style={{ width: 14, color: color.dark_red }} icon={faXmark} />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div style={{ ...styles.BlockRowRaw, height: rowHeight, alignItems: 'center' }}>
                                                                <p style={{ ...styles.TextXSmall }}>{FormatPieces(item.quantity)} {item.unit}</p>
                                                                {status == 1 ? CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                                    <IconButton onClick={() => EditQuantity(item)} style={{ ...styles.ButtonIcon, marginLeft: 20 }}>
                                                                        <FontAwesomeIcon style={{ width: 10 }} icon={faPen} />
                                                                    </IconButton>
                                                                    : null : null}
                                                            </div>
                                                        }

                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: 60, minHeight: rowHeight }}>
                                                        {status == 1 ? CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                            <DeleteButton ask theme={props.theme} lang={props.lang} func={() => DeleteProduct(item)} />
                                                            : null : null}
                                                    </div>
                                                </div>
                                            </Paper>

                                        )) :
                                            <div style={{ ...styles.Block, paddingTop: 25 }}>
                                                <EmptyList row small lang={lang} />
                                            </div>
                                        }
                                        {/*
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight, paddingBottom: global.empty_padding }}>
                                            <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                                <div style={{ ...styles.Block, width: 200 }}></div>
                                                <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                                    {pages > 1 ?
                                                        <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.Block, width: 200 }}>
                                                    {products != false ?
                                                        <div style={{ ...styles.BlockRowRaw }}>
                                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                            </div>
                                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                        */}
                                    </div>
                                </div>


                            </div>
                        </div>
                        : null}

                    {tabIndex == 4 ?
                        <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - buttonHeight }} >
                            <div style={{ ...styles.Block, width: '96%' }}>
                                <div style={{ ...styles.BlockRow, width: '99%' }}>
                                    <div style={{ ...styles.BlockLeft, width: '90%', height: titleHeight }}>
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.reklamacia_images_label}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: 200, height: titleHeight }}>
                                        {editing == false ?
                                            status == 1 ?
                                                CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                                    <Button onClick={() => setSelectPhoto(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, color: color.black, width: 200 }}>
                                                        <FontAwesomeIcon style={{ height: 14, marginRight: 10 }} icon={faPlus} />
                                                        {lang.reklamacia_image_add}
                                                    </Button>
                                                    : null : null : null}
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - titleHeight - tableHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <div id={'id-start'} style={{ ...styles.BlockCenter, paddingBottom: global.list_padding, flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {images != false ? images.map((item, index) => (

                                            <Paper
                                                key={item.id}
                                                id={'id-product-' + item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    width: imageSize + 10,
                                                    height: imageSize + 10,
                                                    margin: 5,
                                                    backgroundColor: item.enabled == false ? color.disabled : item.grouped == true ? color.grouped : item.variations == true ? color.variation : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : item.variations == true ? color.border_variation : item.grouped == true ? color.border_grouped : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                onClick={() => PreviewImage(item)}
                                            >
                                                <div style={{ ...styles.Block, position: 'relative' }}>
                                                    <img src={item.image == undefined || item.image == false || item.image == '' ? image_default : item.image.substring(0, 4).toLowerCase() == 'http' ? item.image : global.web + '/' + item.image} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                                </div>
                                                {overID == item.id && status == 1 ?
                                                    <div style={{ position: 'absolute' }}>
                                                        <DeleteButton tooltip={lang.reklamacia_image_delete} ask theme={props.theme} lang={props.lang} left={(imageSize / 2) - 15} top={0 - (imageSize / 2) + 15} func={() => DeleteImage(item)} />
                                                    </div>
                                                    : null}
                                            </Paper>

                                        )) :
                                            <div style={{ ...styles.Block, paddingTop: 25 }}>
                                                <EmptyList row small lang={lang} />
                                            </div>
                                        }

                                    </div>
                                </div>


                            </div>
                        </div>
                        : null}

                    {tabIndex == 3 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Notes module_id={global.model.reklamacie} item_id={itemID} height={dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={NotesResult.bind(this)} />
                        </div>
                        : null}


                    {tabIndex == 2 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                            <div style={{ ...styles.BlockRowRaw }}>
                                {editing == false && status == 1 && approvalStatus > 0 && products != false ?
                                    <Button onClick={() => setShowFinish(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_finalize, marginRight: 20 }}>{lang.finish_and_close}</Button>
                                    : null}
                                {tabIndex == 0 && editing == true ?
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{itemID == false ? lang.create : lang.save}</Button>
                                    : null}
                                {editing == true && itemID != 0 ?
                                    <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                    :
                                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                                }
                            </div>
                            :
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                        }
                    </div>
                </div >

            </Paper >

            {showProductDetails == true ?
                <ReklamáciaProduct product={productSelected} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductDetailsResult.bind()} />
                : null}

            {showPreview == true ?
                <WebURLPreview url={imageSelected} theme={props.theme} title={lang.reklamacia_image_preview} lang={props.lang} func={() => setShowPreview(false)} />
                : null}

            {showFinish == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.reklamacia_close} text={lang.reklamacia_close_label} sub_text={lang.reklamacia_close_text} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={FinishResult.bind(this)} />
                : null}

            {selectProducts == true ?
                <ReklamaciaSelectProduct excluded={excluded} order_id={orderID} organization={props.organization} settings={props.settings} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductResult.bind()} />
                : null}

            {selectPhoto == true ?
                <Photos autoSelect full typ={global.image.reklamacia} max_size={1200} title={lang.photo_select} organization={props.organization} locale={props.locale} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop>
    );
}

export const ReklamaciaSelectProduct = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [overID, setOverID] = useState(-1);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [products, setProducts] = useState(false);
    const [selected, setSelected] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 800 ? 800 : height;

    const buttonHeight = 70;
    const rowHeight = 80;
    const tableHeight = 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            db_get(0, { excluded: props.excluded });

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_order_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    order_id: props.order_id,
                    filter: filter_
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setProducts(json.products);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false, false);
    }

    const ChangeSelected = (item) => {
        if (item.selected == true) {
            item.selected = false;
        } else {
            item.selected = true;
        }
        setRedraw(!redraw);
    }

    const SelectItem = (item) => {
        func(true, [item]);
    }

    const ChangeAll = () => {
        let selected_ = !selected;
        products.forEach(product => {
            product.selected = selected_;
        });

        setSelected(selected_);

    }

    const Process = () => {

        var array = [];
        products.forEach(product => {
            if (product.selected == true) {
                array.push(product);
            }
        });

        func(true, array);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.reklamacia_products_select}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.Block, height: 50 }}>
                        <p style={{ ...styles.TextXSmall }}>{lang.reklamacia_products_select_text}</p>
                    </div>
                    <div style={{ ...styles.BlockRow, width: '98%', border: color.border, height: tableHeight }}>
                        <div style={{ ...styles.Block, width: 70, height: tableHeight, justifyContent: 'center' }}>
                            <Checkbox style={{ padding: 0, margin: 0 }} checked={selected} size={'small'} onChange={() => ChangeAll()} />
                        </div>
                        <div style={{ ...styles.BlockLeft, width: '80%', height: tableHeight, borderLeft: color.border }}>
                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product}</p>
                        </div>
                        <div style={{ ...styles.Block, width: 180, height: tableHeight, borderLeft: color.border }}>
                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.order_quantity}</p>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - 100, marginTop: 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {products != false ? products.map((item, index) => (
                            <Paper
                                key={item.id}
                                elevation={item.id == overID ? 4 : 1}
                                style={{
                                    ...styles.Block,
                                    backgroundColor: item.quantity == 0 ? color.disabled : color.white,
                                    borderRadius: global.items_radius,
                                    minHeight: rowHeight,
                                    cursor: 'pointer',
                                    marginTop: 5, marginBottom: 5,
                                    paddingTop: 2, paddingBottom: 2,
                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                    width: '98%',
                                }}
                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                            >
                                <div style={{ ...styles.BlockRow, width: '98%' }}>
                                    <div style={{ ...styles.Block, width: 70, minHeight: rowHeight, justifyContent: 'center' }}>
                                        <Checkbox style={{ padding: 0, margin: 0, marginRight: 8 }} checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                    </div>
                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                        <Avatar picture image={item.image} size={rowHeight - 8} theme={props.theme} />
                                    </div>
                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, overflow: 'hidden', width: '80%', minHeight: rowHeight }}>
                                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', minHeight: rowHeight, marginLeft: 10 }}>
                                            <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.code}</p>
                                            {item.parent_name != '' ?
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextXSmall, fontWeight: '600', whiteSpace: 'nowrap' }}>{item.parent_name}</p>
                                                    <p style={{ ...styles.TextTiny, whiteSpace: 'nowrap' }}>{item.name}</p>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextXSmall, fontWeight: '600', whiteSpace: 'nowrap' }}>{item.name}</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRight, width: 180, minHeight: rowHeight }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: '600', marginRight: 10 }}>{item.quantity} {item.unit}</p>
                                    </div>
                                </div>
                            </Paper>
                        )) : null}
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {products != false ?
                                <Button onClick={() => Process()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginLeft: 10 }}>{lang.reklamacia_products_add}</Button>
                                : null}
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, marginLeft: 10 }}>{lang.cancel}</Button>
                        </div>
                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}

export const ReklamáciaProduct = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let precisions = 2;
    let dph = parseFloat(props.system.dph);

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [typ, setTyp] = useState(props.product.typ);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 680 ? 680 : width;
    const dialogHeight = height >= 400 ? 400 : height;

    const column1 = 150;
    const column2 = dialogWidth - column1 - 40;
    const buttonHeight = 80;

    let { func } = props;

    useEffect(() => {

    }, []);

    const Close = () => {
        func(false);
    }

    const Process = () => {
        func(true, typ);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.settings}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, background: color.white }}>
                    <FormSpace />
                    <FormLabel editing={true} title={lang.reklamacia_stock_label} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                    <FormSpace />
                    <FormYesNo value={typ} editing={true} title={lang.reklamacia_stock} column1={column1} column2={column2} theme={props.theme} no={lang.reklamacia_product_none} yes={lang.reklamacia_product_return} default={lang.reklamacia_product_send} lang={lang} radius={props.radius} func={(id) => setTyp(id)} />
                    <FormNote title={typ == 0 ? lang.reklamacia_stock_note_0 : typ == 1 ? lang.reklamacia_stock_note_1 : lang.reklamacia_stock_note_2} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                </div>
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <Button onClick={() => Process()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                        <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                    </div>

                </div>
                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}
