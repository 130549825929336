/*
*
* =================================================================================================================
* KALENDAR

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './items.css';
import './styles.css';

import { ContactCompany, FormButton, FormButtons, FormColors, FormError, FormSelect, FormSpace, FormText, Icon, Line, Loading, MenuButton, SelectInput, FormLabel, TextLine, FormYesNo, DeleteButton, FormAutoComplete, FormChips, FormList, FormNote, } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, FormControlLabel, IconButton, Paper, Tooltip, Typography, rgbToHex, selectClasses, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAngleUp, faAnglesUp, faArrowsDownToLine, faCalendarDay, faCalendarWeek, faCheckSquare, faChevronLeft, faChevronRight, faCircle, faClock, faCogs, faEllipsis, faExclamationCircle, faInfoCircle, faLayerGroup, faMagnifyingGlass, faMinus, faMinusCircle, faMinusSquare, faPen, faPlane, faPlus, faPlusCircle, faPlusSquare, faRoute, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, FormatMoney, GetCalendarGroups, GetFunctionID, GetLeftPosition, IsEmpty, LoadValue, OpenAIChat, PermissionToBinary, SaveValue } from './functions';
import { GetDate3, GetDateDay, GetDateMonth, GetDateYear, GetDatum2, GetDatum3, GetDatum4, GetDayOfWeek, GetHours, GetHoursFromMinutes, GetMinutesFromHours, GetTodayDatum, GetTodayMonth, GetTodayYear, GetTodayYearMonth, Today, getNextDay, getPreviousDay, getWeekDates } from './functions_date';
import { CustomerSelect } from './customers';
import { MyRoutePreview, RouteSelect } from './routes';
import { UsersSelect } from './users_select';


/*
*
* =================================================================================================================
    KALENDAR

    TYPY TERMINOV:
            0 - Udalosť
            1 - Úloha
            2 - Trasa
            3 - Dovolenka    


* =================================================================================================================
*
*/

export const Calendar = (props) => {


    var lang = props.lang;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = props.permission_column;


    const types = [
        { id: 0, name: lang.calendar_types[0] },
        { id: 1, name: lang.calendar_types[1] },
        { id: 2, name: lang.calendar_types[2] },
        { id: 3, name: lang.calendar_types[3] },
    ];

    const terminColors = global.terminColors;
    /*
        const [termins, setTermins] = useState([
            { id: 1, group_id: 1, date: '2024-06-18', time_start: 420, length: 80, name: 'Objednávka tovaru na sklad', color: 0, note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed cras ornare arcu dui vivamus arcu. In hac habitasse platea dictumst quisque sagittis purus.', typ: 0 },
            { id: 2, group_id: 1, date: '2024-06-17', time_start: 540, length: 150, name: 'Test 2', color: 0, note: '', typ: 1 },
            { id: 3, group_id: 1, date: '2024-06-19', time_start: 800, length: 150, name: 'Test 3', color: 2, note: '', typ: 0 },
            { id: 4, group_id: 1, date: '2024-05-29', time_start: 900, length: 120, name: 'Test 4 ', color: 3, note: '', typ: 0 },
            { id: 5, group_id: 1, date: '2024-06-01', time_start: 520, length: 120, name: 'Test 5 ', color: 3, note: '', typ: 0 },
            { id: 6, group_id: 2, date: '2024-05-27', time_start: 520, length: 120, name: 'Test 6 ', color: 1, note: '', typ: 0 },
            { id: 7, group_id: 3, date: '2024-05-27', time_start: 480, length: 120, name: 'Test 7 ', color: 2, note: '', typ: 0 },
        ]);
    */
    const [termins, setTermins] = useState([]);

    /*
    const calendarGroups = [
        { id: 1, name: lang.calendar_groups[1], short: lang.calendar_groups[1][0] },
        { id: 2, name: lang.calendar_groups[2], short: lang.calendar_groups[2][0] },
        { id: 3, name: lang.calendar_groups[3], short: lang.calendar_groups[3][0] },
        { id: 0, name: lang.calendar_groups[0], short: '' },
    ];
    */
    const calendarGroups = GetCalendarGroups(lang);

    // OZNACENIE ZACIATKU A KONCA PRACOVNEHO DNA
    const startWorkingTime = '08:00';
    const endWorkingTime = '18:00';

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    const [isBusy, setBusy] = useState(false);
    const [customUser, setCustomUser] = useState(props.user);
    const [customUserID, setCustomUserID] = useState(props.user.id);

    // KALENDAR
    const [calendarTyp, setCalendarTyp] = useState(1);
    const [groupID, setGroupID] = useState(1);

    // TERMINY - UDALOSTI    
    const [terminDragID, setTerminDragID] = useState(0);
    const [dragTyp, setDragTyp] = useState(0);
    const [dragOverID, setDragOverID] = useState(0);
    const [dragOverDay, setDragOverDay] = useState('');
    const [dragTerminOverID, setDragTerminOverID] = useState(0);

    // UPRAVA ROUTE
    const [itemSelected, setItemSelected] = useState(false);
    const [showRoute, setShowRoute] = useState(false);


    const hours = Array.from({ length: 24 }, (_, index) => '@hour:00'.replace('@hour', String(index).padStart(2, '0')));
    const minutes = Array.from({ length: 2 }, (_, index) => String(index * 30));
    const [days, setDays] = useState(getWeekDates(Today()));
    const [today, setToday] = useState(Today());
    const [months, setMonths] = useState(getDaysInWeeks(GetTodayYear(), GetTodayMonth()));
    const [actualWeekDay, setActualWeekDay] = useState(Today());
    const [actualMonth, setActualMonth] = useState(GetTodayMonth());

    // NOVY TERMIN
    const [terminSelected, setTerminSelected] = useState({ date: Today(), hour: 8, minute: 0, new: true, group_id: 1 });
    const [showTermin, setShowTermin] = useState(false);
    const [showTerminPreview, setShowTerminPreview] = useState(false);

    // KALENDAR - VELKOSTI
    const calendarSmallWidth = 280;
    const calendarMaxWidth = props.width - 40;
    const calendarWidth = props.isLarge ? props.width > calendarMaxWidth - calendarSmallWidth ? calendarMaxWidth - calendarSmallWidth : props.width - calendarSmallWidth : props.width - 40;
    const hoursWidth = 60;
    const cellWidth = ((calendarWidth - hoursWidth) / 7) - 2 < 80 ? 80 : ((calendarWidth - hoursWidth) / 7) - 2;
    const cellWidthFull = (calendarWidth - hoursWidth) - 2 < 80 ? 80 : (calendarWidth - hoursWidth) - 2;
    const topOffset = 6;
    const cellSize = 60;
    const draggingOpacity = 0.75;
    const radius = 6;
    const zoom = 0.75;
    const headerHeight = 70;
    const daysHeight = 50;
    const monthLabelSize = 30;
    const monthChipSize = 26;
    const rowHeight = 26;
    const listingHeight = 50;

    const [terminWidth, setTerminWidth] = useState(cellWidth);

    // ENABLE / DISABLE tooltip
    const [tooltipEnabled, setTooltipEnabled] = useState(LoadValue('calendar-tooltips', 0) == 0 ? false : true);

    // COLORS
    const borderColor = '#D0D0D0';
    const borderLightColor = '#E0E0E0';
    const todayColor = color.today_highlight;
    const naviButtons = color.calendar_navi_buttons;

    let { func } = props;
    var running = false;
    const inputRef = useRef(null);
    const offset = useRef({ y: 0 });
    const clickTimeoutRef = useRef(null);

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get(today, customUserID);
        }

    }, []);


    const db_get = async (datum, custom_user_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'calendar', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    custom_user_id: custom_user_id, // kalendár užívateľa
                    date: datum
                })
            })

            const json = await response.json();
            Debug('CALENDAR', 'red');
            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                if (json.items != false) {
                    setTermins(json.items);
                } else {
                    setTermins([]);
                }
            }

        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    }

    // DRAG AND DROP - ulozenie zmeny
    const db_update = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'calendar_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {

            }

        } catch (error) {
            console.log(error);
        }
    }

    const Close = () => {
        func(false);
    }

    const handleDragStart = (e, termin) => {
        e.dataTransfer.setData('text/plain', termin.id);
        e.dataTransfer.effectAllowed = 'move';
        setTerminDragID(termin.id);
        setTimeout(function () {
            e.target.style.visibility = "hidden";
        }, 1);
    }

    const handleDragEnd = (e) => {
        setTimeout(function () {
            e.target.style.visibility = "";
        }, 1);
    }

    const handleDrop = (e, index, day) => {
        var termin = termins.find(x => x.id == terminDragID);
        if (termin != undefined) {
            termin.time_start = termin.typ == 3 ? 0 : index;
            termin.date = day;
            termin.time_end = parseInt(termin.time_start) + parseInt(termin.length);
        }

        var tmp = termins;
        tmp.sort((a, b) => {
            if (parseInt(a.time_start) > parseInt(b.time_start)) {
                return 1;
            } else {
                return -1;
            }
        })

        setTermins(tmp);
        setTerminDragID(0);
        setDragOverDay('');
        setDragTerminOverID(0);

        let data = {
            id: termin.id,
            date: termin.date,
            time_start: termin.time_start,
            time_end: parseInt(termin.time_start) + parseInt(termin.length)
        };

        db_update([data]);
    };

    const checkOverlapping = (termin, date) => {
        if (groupID == 0) {
            return termins.filter(x => x.id != termin.id && x.date == date && parseInt(termin.time_start) < parseInt(x.time_start + x.length) && parseInt(termin.time_start + termin.length) > parseInt(x.time_start));
        } else {
            return termins.filter(x => x.id != termin.id && x.group_id == groupID && x.date == date && parseInt(termin.time_start) < parseInt(x.time_start + x.length) && parseInt(termin.time_start + termin.length) > parseInt(x.time_start));
        }
    };

    function getWeekDates(date_) {
        var date = date_.toString().replaceAll('-', '/');
        const startDate = new Date(date);
        const dayOfWeek = (startDate.getDay() + 6) % 7;

        const weekDates = [];

        for (let i = 0; i < 7; i++) {
            const dateCopy = new Date(startDate);
            dateCopy.setDate(startDate.getDate() - dayOfWeek + i);
            weekDates.push(GetDate3(dateCopy));
        }

        return weekDates;
    }

    function getFirstDayOfNextWeek(date_) {
        var date = date_.toString().replaceAll('-', '/');
        const currentDate = new Date(date);
        const dayOfWeek = (currentDate.getDay() + 6) % 7;

        const daysToAdd = 7 - dayOfWeek;

        const nextWeekStartDate = new Date(currentDate);
        nextWeekStartDate.setDate(currentDate.getDate() + daysToAdd);

        return GetDate3(nextWeekStartDate);
    }

    function getFirstDayOfPreviousWeek(date_) {
        var date = date_.toString().replaceAll('-', '/');
        const currentDate = new Date(date);
        const dayOfWeek = (currentDate.getDay() + 6) % 7;

        const daysToSubtract = dayOfWeek + 7;

        const previousWeekStartDate = new Date(currentDate);
        previousWeekStartDate.setDate(currentDate.getDate() - daysToSubtract);

        return GetDate3(previousWeekStartDate);
    }

    function getWeekNumber(date_) {
        var date = date_.toString().replaceAll('-', '/');
        const currentDate = new Date(date);
        // Set the time to noon to avoid issues with DST changes
        currentDate.setHours(12, 0, 0, 0);

        // Get Thursday of the current week to ensure the current week belongs to the right year
        const thursday = new Date(currentDate);
        thursday.setDate(currentDate.getDate() + 3 - (currentDate.getDay() + 6) % 7);

        // Get the first Thursday of the year
        const firstThursday = new Date(thursday.getFullYear(), 0, 4);

        // Calculate the week number
        const weekNumber = Math.ceil(((thursday - firstThursday) / 86400000 + 1) / 7);

        return weekNumber;
    }

    function getNextDay(date_) {
        var date = date_.toString().replaceAll('-', '/');
        const currentDate = new Date(date);
        const nextDayDate = new Date(currentDate);
        nextDayDate.setDate(currentDate.getDate() + 1);
        return GetDate3(nextDayDate);
    }

    function getPreviousDay(date_) {
        var date = date_.toString().replaceAll('-', '/');
        const currentDate = new Date(date);
        const previousDayDate = new Date(currentDate);
        previousDayDate.setDate(currentDate.getDate() - 1);
        return GetDate3(previousDayDate);
    }

    function getLastDayOfMonth(year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();
    }

    function getMonth(date) {
        return date.getMonth();
    }

    function getDaysInWeeks(year, month_) {
        let month = month_ - 1;
        let weeks = [];
        let firstDayOfMonth = new Date(year, month, 1).getDay();
        let lastDateOfMonth = new Date(year, month + 1, 0).getDate();

        // Adjust first day of the month for Monday start (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        firstDayOfMonth = (firstDayOfMonth === 0) ? 6 : firstDayOfMonth - 1;

        let week = [];
        // Fill the first week with placeholder Date objects for days before the 1st of the month
        for (let i = 0; i < firstDayOfMonth; i++) {
            let date = new Date(year, month, 1 - firstDayOfMonth + i);
            week.push({ date: GetDate3(date), day: GetDateDay(date), month: GetDateMonth(date), enabled: false });

        }

        // Loop through each day of the month
        for (let day = 1; day <= lastDateOfMonth; day++) {
            let date = new Date(year, month, day);
            week.push({ date: GetDate3(date), day: day, month: month + 1, enabled: true });
            if (week.length === 7) {
                weeks.push(week);
                week = [];
            }
        }

        // If there are remaining days after the loop, fill the rest of the week with placeholder Date objects
        if (week.length > 0) {
            let lastDayOfWeek = new Date(year, month, lastDateOfMonth).getDay();
            lastDayOfWeek = (lastDayOfWeek === 0) ? 6 : lastDayOfWeek - 1;
            for (let i = lastDayOfWeek + 1; i < 7; i++) {
                let date = new Date(year, month, lastDateOfMonth + i - lastDayOfWeek);
                week.push({ date: GetDate3(date), day: GetDateDay(date), month: GetDateMonth(date), enabled: false });
            }
            weeks.push(week);
        }

        return weeks;
    }

    function getPreviousMonth(date_) {
        let date = new Date(date_);
        let year = date.getFullYear();
        let month = date.getMonth();

        if (month === 0) {
            year -= 1;
            month = 11;
        } else {
            month -= 1;
        }

        return { date: new Date(year, month, 1), year: year, month: month };
    }

    function getNextMonth(date_) {
        let date = new Date(date_);
        let year = date.getFullYear();
        let month = date.getMonth();

        if (month === 11) {
            year += 1;
            month = 0;
        } else {
            month += 1;
        }

        return { date: new Date(year, month, 1), year: year, month: month };
    }

    const GotoNext = () => {
        var newDate = '';

        if (calendarTyp == 0) {
            // MESIAC            
            let date = getNextMonth(actualWeekDay);
            let days = getDaysInWeeks(date.year, date.month + 1);
            setMonths(days);
            setActualWeekDay(date.date);
            newDate = GetDate3(date.date);
        }
        if (calendarTyp == 1) {
            // TYZDEN
            let date = getFirstDayOfNextWeek(actualWeekDay);
            var days = getWeekDates(date);
            setDays(days);
            setActualWeekDay(date);
            newDate = GetDate3(days[0]);
        }
        if (calendarTyp == 2 || calendarTyp == 3) {
            // DEN
            let date = getNextDay(actualWeekDay);
            setDays([date]);
            setActualWeekDay(date);
            newDate = GetDate3(date);
        }

        // RELOAD DATA FROM DB ?        
        if (newDate != '') {
            let month = new Date(newDate).getMonth();

            if (actualMonth != month) {
                // len v prípade, ak došlo k zmene aktuálneho mesiaca
                db_get(newDate, customUserID);
                setActualMonth(month);
            }
        }
    }

    const GotoPrev = () => {
        var newDate = '';

        if (calendarTyp == 0) {
            // MESIAC            
            let date = getPreviousMonth(actualWeekDay);
            let days = getDaysInWeeks(date.year, date.month + 1);
            setMonths(days);
            setActualWeekDay(date.date);
            newDate = GetDate3(date.date);
        }
        if (calendarTyp == 1) {
            // TYZDEN
            var date = getFirstDayOfPreviousWeek(actualWeekDay);
            var days = getWeekDates(date);
            setDays(days);
            setActualWeekDay(date);
            newDate = GetDate3(days[0]);
        }
        if (calendarTyp == 2 || calendarTyp == 3) {
            // DEN
            var date = getPreviousDay(actualWeekDay);
            setDays([date]);
            setActualWeekDay(date);
            newDate = GetDate3(date);
        }

        // RELOAD DATA FROM DB ?            
        if (newDate != '') {
            let month = new Date(newDate).getMonth();

            if (actualMonth != month) {
                // len v prípade, ak došlo k zmene aktuálneho mesiaca
                db_get(newDate, customUserID);
                setActualMonth(month);
            }
        }
    }

    const GotoToday = () => {
        var newDate = '';

        if (calendarTyp == 0) {
            // MESIAC            
            let date = Today();
            let days = getDaysInWeeks(GetTodayYear(), GetTodayMonth());
            setMonths(days);
            setActualWeekDay(date);
            newDate = GetDate3(date);
        }
        if (calendarTyp == 1) {
            var date = Today();
            var days = getWeekDates(date);
            setDays(days);
            setActualWeekDay(date);
            newDate = GetDate3(days[0]);
        }
        if (calendarTyp == 2) {
            var date = Today();
            setDays([date]);
            setActualWeekDay(date);
            newDate = GetDate3(date);
        }

        // RELOAD DATA FROM DB ?        
        if (newDate != '') {
            let month = new Date(newDate).getMonth();

            if (actualMonth != month) {
                // len v prípade, ak došlo k zmene aktuálneho mesiaca
                db_get(newDate, customUserID);
                setActualMonth(month);
            }
        }

    }

    const CalendarResult = (date, y, m, d) => {
        var days = getWeekDates(date);
        if (calendarTyp == 2) {
            setDays([date]);
        } else {
            setDays(days);
        }
        setActualWeekDay(date);

        // RELOAD DATA FROM DB ?
        if (actualMonth != m) {
            // len v prípade, ak došlo k zmene aktuálneho mesiaca - minimalizovanie nahrávania
            db_get(date, customUserID);
            setActualMonth(m);
        }
    }

    const ChangeCalendaTyp = (id) => {
        if (id == 0) {
            // MESIAC
            setTerminWidth(cellWidth);
        }
        if (id == 1) {
            // TYZDEN
            let date = actualWeekDay;
            var days = getWeekDates(date);
            setDays(days);
            setActualWeekDay(date);
            setTerminWidth(cellWidth);
        }
        if (id == 2) {
            // DEN
            let date = actualWeekDay;
            setDays([date]);
            setActualWeekDay(date);
            setTerminWidth(cellWidthFull);
        }
        setCalendarTyp(id);
    }

    const ChangeWeek = (date_) => {
        let date = date_.date;
        var days = getWeekDates(date);
        setDays(days);
        setActualWeekDay(date);
        setTerminWidth(cellWidth);
        setCalendarTyp(1);
    }

    const ChangeDay = (day) => {
        let date = day;
        setDays([date]);
        setActualWeekDay(date);
        setTerminWidth(cellWidthFull);
        setCalendarTyp(2);
    }

    const TerminFilter = (id, day, group_id) => {
        var tmp = [];
        if (id == 0) {
            if (group_id == 0) {
                tmp = termins.filter(x => x.date == Today());
            } else {
                tmp = termins.filter(x => x.date == Today() && x.group_id == group_id);
            }
        }
        if (id == 1) {
            if (group_id == 0) {
                tmp = termins.filter(x => x.date == day.date);
            } else {
                tmp = termins.filter(x => x.date == day.date && x.group_id == group_id);
            }
        }
        if (id == 2) {
            if (group_id == 0) {
                tmp = termins.filter(x => x.date == day);
            } else {
                tmp = termins.filter(x => x.date == day && x.group_id == group_id);
            }
        }
        return tmp;
    }

    const ChangeTyp = () => {
        if (calendarTyp > 0) {
            ChangeCalendaTyp(calendarTyp - 1);
        }
    }

    const AddNewTermin = (date, hour, minute_) => {
        var minute = minute_ * 30;
        setTerminSelected({ date: date, hour: hour, minute: minute, new: true, group_id: groupID });
        setShowTermin(true);
    }

    const handleClick = (termin) => {
        if (clickTimeoutRef.current) {
            clearTimeout(clickTimeoutRef.current);
            clickTimeoutRef.current = null;
        }
        clickTimeoutRef.current = setTimeout(() => {
            EditTermin(termin);
            clickTimeoutRef.current = null;
        }, 250);
    };

    const EditTermin = (termin) => {
        setTerminSelected({ termin: termin, new: false });
        setShowTermin(true);
    }

    const PreviewTermin = (termin) => {
        setTerminSelected({ termin: termin, new: false });
        setShowTerminPreview(true);
    }

    const RouteEdit = (termin, item) => {

        if (clickTimeoutRef.current) {
            clearTimeout(clickTimeoutRef.current);
            clickTimeoutRef.current = null;
        }
        setTerminSelected(termin);
        setItemSelected(item);
        setShowRoute(true);
    }


    const RouteResult = (typ, item) => {
        if (typ == true) {
            itemSelected.visit_time = item.visit_time;

            var time = 0;
            var items = terminSelected.data.items;
            var termin = { ...terminSelected };

            items.forEach(element => {
                if (element.id == item.id) {
                    time += parseInt(element.time) + (parseInt(element.visit_time) * 60);
                } else {
                    time += parseInt(element.time) + (parseInt(element.visit_time) * 60);
                }
            });

            terminSelected.data.route.time = 100;
            terminSelected.length = parseInt(time / 60);
            terminSelected.time_end = terminSelected.time_start + parseInt(time / 60);
            termin.data.route.time = 100;
            termin.length = parseInt(time / 60);
            termin.time_end = terminSelected.time_start + parseInt(time / 60);
            termin.data = JSON.stringify(termin.data);

            db_update([termin]);
        }

        setShowRoute(false);
    }


    const TerminResult = (redraw) => {
        setShowTermin(false);

        if (redraw == true) {
            db_get(actualWeekDay, customUserID);
        }
    }

    const ChangeToolTip = (value) => {
        setTooltipEnabled(value);
        SaveValue('calendar-tooltips', value == false ? 0 : 1);
    }


    const ChangeCustomUser = (id) => {
        setGroupID(1);
        var tmp = props.subUsers.find(x => x.id == id);
        if (tmp != undefined) {
            setCustomUser(tmp);
        }
        setCustomUserID(id);
        db_get(actualWeekDay, id);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120 - 200, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>

                <div style={{ ...styles.Block, width: 220, height: global.widow_bar_height }}>
                    {props.isLarge == false ? props.subUsers != false ? props.subUsers.length > 1 ?
                        <FormAutoComplete no_default value={customUserID} items={props.subUsers} marginTop={0} field={'name'} editing={true} title={lang.product_category_parent} column2={200} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeCustomUser(id)} />
                        : null : null : null}
                </div>

                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>

                    <div style={{ ...styles.BlockRowRaw }}>
                        {/* 
                        *
                        *
                        * =================================================================================================================
                        * LAVA STRANA - KALENDAR MINI + DNES NAPLANOVANE
                        * =================================================================================================================
                        *
                        *
                        */}
                        {props.isLarge == true ?
                            calendarTyp > 0 ?
                                <div style={{ ...styles.BlockCenter, width: calendarSmallWidth, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflow: 'scroll' }} className='container'>
                                    <div style={{ ...styles.BlockLeft, width: calendarSmallWidth, marginTop: 20 }}>

                                        {/* VYBER UZIVALETA */}
                                        {props.subUsers != false ? props.subUsers.length > 1 ?
                                            <>
                                                <FormAutoComplete no_default value={customUserID} items={props.subUsers} field={'name'} editing={true} title={lang.product_category_parent} column2={38 * 7} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeCustomUser(id)} />
                                                <FormSpace />
                                            </>
                                            : null : null}

                                        <CalendarMini date={actualWeekDay} cellSize={38} lang={props.lang} language={props.language} theme={props.theme} func={CalendarResult.bind(this)} />
                                        {props.user.calendar_types == true ?
                                            props.user.id == customUserID ?
                                                <>
                                                    <p style={{ ...styles.TextTiny, marginTop: 10, marginBottom: 3 }}>{lang.calendar_group}</p>
                                                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={groupID} items={calendarGroups} width={38 * 7} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => setGroupID(id)} />
                                                </>
                                                : null : null}
                                    </div>
                                    <div style={{ ...styles.BlockLeft, marginTop: 26 }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.calendars_planned}</p>
                                        <p style={{ ...styles.TextXSmall, marginBottom: 8, marginTop: 2 }}>{GetDatum2(actualWeekDay)}</p>
                                        <div style={{ ...styles.Block, marginTop: 4 }}>
                                            {TerminFilter(2, actualWeekDay, groupID).length > 0 ? TerminFilter(2, actualWeekDay, groupID).map((termin, index) => (

                                                <div key={index} onClick={() => PreviewTermin(termin)} style={{ ...styles.BlockRow, height: rowHeight, marginTop: 2, marginBottom: 2, backgroundColor: '#00000010', borderRadius: radius, cursor: 'pointer' }}>
                                                    <div style={{ ...styles.Block, width: 16, height: rowHeight }}>
                                                        <FontAwesomeIcon style={{ height: 8, color: terminColors[termin.color].dark }} icon={faCircle} />
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: calendarSmallWidth - 16 - 100 - 10, height: rowHeight }}>
                                                        <TextLine text={termin.typ == 0 ? termin.name == '' ? termin.plan : termin.name : lang.calendar_type[termin.typ]} fontSize={global.font_tiny} color={color.black} />
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: 100, height: rowHeight }}>
                                                        {termin.typ != 3 ?
                                                            <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{GetHours(termin.time_start)} - {GetHours(termin.time_start + termin.length)}</p>
                                                            : null}
                                                    </div>
                                                </div>
                                            ))
                                                :
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextXSmall }}>{lang.calendar_termins_none}</p>
                                                </div>
                                            }
                                        </div>

                                    </div>

                                    <FormSpace height={100} />
                                </div>
                                : null

                            : null}

                        <div style={{ ...styles.Block, width: calendarWidth }}>
                            {/* 
                            *
                            *
                            * =================================================================================================================
                            * KALENDAR - NAVIGATION BUTTONS
                            * =================================================================================================================
                            *
                            *
                            */}
                            <div style={{ ...styles.Block, width: calendarWidth, height: headerHeight }}>
                                <div style={{ ...styles.BlockRowRaw, width: calendarTyp == 0 ? cellWidth * 7 : calendarWidth }}>

                                    {calendarTyp == 0 || calendarTyp == 3 ? null :
                                        <div style={{ ...styles.BlockRow, width: hoursWidth }}></div>
                                    }
                                    <div style={{ ...styles.BlockLeft, width: 170 }}>
                                        <Paper elevation={3} style={{ ...styles.BlockRowRaw, borderRadius: 18 }}>
                                            <div style={{ ...styles.Block, width: 40 }}>
                                                <IconButton onClick={() => GotoPrev()} style={{ width: '100%', height: 36, backgroundColor: naviButtons, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 18, borderBottomLeftRadius: 18 }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.black }} icon={faChevronLeft} />
                                                </IconButton>
                                            </div>
                                            <div style={{ ...styles.Block, width: 60 }}>
                                                <Button onClick={() => GotoToday()} style={{ ...styles.ButtonThemed, width: 80, height: 36, backgroundColor: naviButtons, borderRadius: 0 }}>
                                                    <p style={{ ...styles.TextTiny, color: color.black }}>{lang.today}</p>
                                                </Button>
                                            </div>
                                            <div style={{ ...styles.Block, width: 40 }}>
                                                <IconButton onClick={() => GotoNext()} style={{ width: '100%', height: 36, backgroundColor: naviButtons, borderRadius: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 18, borderBottomRightRadius: 18 }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.black }} icon={faChevronRight} />
                                                </IconButton>
                                            </div>
                                        </Paper>
                                    </div>

                                    <div style={{ ...styles.Block, width: calendarTyp == 0 || calendarTyp == 3 ? calendarWidth - 170 - 170 : calendarWidth - 170 - 170 - hoursWidth, height: 36 }}>
                                        {calendarTyp == 0 || calendarTyp == 3 ?
                                            <p onClick={() => ChangeTyp()} style={{ ...styles.TextLarge, cursor: 'pointer' }}><b>{lang.months_list[GetDateMonth(actualWeekDay) - 1]}</b> {GetDateYear(actualWeekDay)}</p>
                                            :
                                            <p onClick={() => ChangeTyp()} style={{ ...styles.TextLarge, cursor: 'pointer' }}><span style={{ ...styles.TextSmall, marginRight: 10 }}>{getWeekNumber(actualWeekDay)}.{lang.week}</span> <b>{lang.months_list[GetDateMonth(actualWeekDay) - 1]}</b> {GetDateYear(actualWeekDay)}</p>
                                        }
                                    </div>
                                    <div style={{ ...styles.BlockRow, width: 170 }}>
                                        <SelectInput theme={props.theme} enabled={true} lang={lang} value={calendarTyp} items={types} width={120} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => ChangeCalendaTyp(id)} />
                                        <IconButton onClick={() => AddNewTermin(actualWeekDay, 8, 0)} style={{ width: 36, height: 36, backgroundColor: color.calendar_new, marginLeft: 10 }}>
                                            <FontAwesomeIcon style={{ height: 12, color: color.white }} icon={faPlus} />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>

                            {/* 
                            *
                            *
                            * =================================================================================================================
                            * MESAČNÝ KALENDAR
                            * =================================================================================================================
                            *
                            *
                            */}
                            {calendarTyp == 0 ?
                                <div style={{ ...styles.BlockCenter, width: calendarWidth }}>
                                    <div style={{ ...styles.BlockCenter }}>

                                        <div style={{ ...styles.BlockRowRaw, height: 30 }}>
                                            {lang.days.map((day, index) => (
                                                <div key={index} style={{ ...styles.Block, width: cellWidth, height: 30 }}>
                                                    <p style={{ ...styles.TextTiny, color: color.medium_gray }}>{day}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - headerHeight - 30, msOverflowStyle: 'none', scrollbarWidth: 'none', overflow: 'scroll' }} className='container'>
                                            {months.map((month, monthIndex) => {
                                                let today = Today();

                                                return (
                                                    <div key={monthIndex} style={{ ...styles.BlockRowRaw }}>
                                                        {month.map((day, dayIndex) => {
                                                            let items = TerminFilter(1, day, groupID);
                                                            let count = items.length

                                                            return (
                                                                <div key={dayIndex} onClick={() => ChangeWeek(day)} style={{ position: 'relative' }}>
                                                                    <div style={{ ...styles.BlockCenter, width: cellWidth, height: cellWidth, borderLeft: '1px solid ' + borderColor, borderTop: '1px solid ' + borderColor, borderRight: dayIndex == 6 ? '1px solid ' + borderColor : '', borderBottom: monthIndex == months.length - 1 ? '1px solid ' + borderColor : '', backgroundColor: day.enabled == true ? day.date == today ? color.today_highlight : color.white : '#00000000' }}>
                                                                        <div style={{ ...styles.BlockRow, height: monthLabelSize, overflow: 'hidden' }}>
                                                                            <div style={{ ...styles.BlockLeft, width: cellWidth / 2, height: monthLabelSize }}>
                                                                                {count > 0 ?
                                                                                    <Chip label={count} variant='outlined' size='small' style={{ marginLeft: 3, borderColor: color.today_termins, color: color.dark_gray }} />
                                                                                    : null}
                                                                            </div>
                                                                            <div style={{ ...styles.BlockRight, alignItems: 'flex-end', width: cellWidth / 2, height: monthLabelSize }}>
                                                                                <p style={{ ...styles.TextTiny, fontWeight: day.enabled == true ? '600' : '400', color: day.enabled == true ? day.date == today ? color.today_color : color.dark_gray : color.medium_gray, marginRight: 3 }}>{day.day}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ ...styles.BlockCenter, minHeight: cellWidth - monthLabelSize - 8, maxHeight: cellWidth - 8, overflow: 'hidden' }}>
                                                                            {items.map((termin, terminIndex) => {
                                                                                return (
                                                                                    (((terminIndex + 1) * monthChipSize) + monthLabelSize) < cellWidth - 8 ?
                                                                                        <Chip
                                                                                            key={terminIndex}
                                                                                            label={(termin.typ == 3 ? '' : GetHours(termin.time_start)) + ' ' + termin.name}
                                                                                            size='small'
                                                                                            sx={{
                                                                                                justifyContent: 'flex-start',
                                                                                            }}
                                                                                            style={{
                                                                                                fontWeight: '400',
                                                                                                fontSize: global.font_tiny,
                                                                                                alignItems: 'left',
                                                                                                color: terminColors[termin.color].text,
                                                                                                backgroundColor: terminColors[termin.color].light,
                                                                                                maxWidth: cellWidth - 4 + 'px',
                                                                                                minWidth: cellWidth - 4 + 'px',
                                                                                                minHeight: monthChipSize - 2,
                                                                                                maxHeight: monthChipSize - 2,
                                                                                                marginTop: 2,
                                                                                                opacity: day.enabled == true ? 1.0 : 0.6,
                                                                                                cursor: 'pointer'
                                                                                            }} />
                                                                                        : null
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        {count * monthChipSize > cellWidth - monthLabelSize - 8 ?
                                                                            <div style={{ ...styles.Block, height: 8, overflow: 'hidden' }}>
                                                                                <FontAwesomeIcon style={{ width: 16, color: color.dark_gray }} icon={faEllipsis} />
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            })}
                                            <FormSpace height={100} />
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {/* 
                            *
                            *
                            * =================================================================================================================
                            * TYZDEN / DEN
                            * =================================================================================================================
                            *
                            *
                            */}

                            {calendarTyp == 1 || calendarTyp == 2 ?

                                <div style={{ ...styles.BlockCenter, width: calendarWidth, height: props.height - global.widow_bar_height - headerHeight }}>
                                    <div style={{ ...styles.BlockRowRaw, height: daysHeight }}>
                                        <div style={{ ...styles.BlockCenter, width: hoursWidth - 6, position: 'relative' }}></div>
                                        {days.map((day, dayIndex) => (
                                            <div
                                                key={dayIndex}
                                                onClick={() => ChangeDay(day)}
                                                style={{
                                                    ...styles.Block,
                                                    width: days.length == 1 ? cellWidthFull : cellWidth,
                                                    height: daysHeight,
                                                    backgroundColor: today == day && calendarTyp < 2 ? todayColor : '#00000000',
                                                    borderRadius: radius,
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {days.length == 1 ?
                                                    <>
                                                        <p style={{ ...styles.TextXSmall }}>{lang.days[GetDayOfWeek(day)]}<span style={{ marginLeft: 10 }}><b>{GetDatum4(day)}</b></span></p>
                                                    </>
                                                    :
                                                    <>
                                                        <p style={{ ...styles.TextTiny, color: color.medium_gray }}>{lang.days_short_label[GetDayOfWeek(day)]}</p>
                                                        <p style={{ ...styles.TextTiny, fontWeight: actualWeekDay == day ? '600' : '400' }}>{GetDatum4(day)}</p>
                                                    </>
                                                }
                                            </div>
                                        ))}
                                    </div>

                                    <div style={{ ...styles.BlockCenter, width: calendarWidth, height: props.height - global.widow_bar_height - headerHeight - daysHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflow: 'scroll' }} className='container'>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            {/* ZOZNAM HODINY NA LAVEJ STRANE */}
                                            <div style={{ ...styles.BlockCenter, width: hoursWidth, position: 'relative' }}>
                                                {hours.map((hour, index) => (
                                                    <div
                                                        key={index}
                                                        style={{ ...styles.BlockCenter, alignItems: 'flex-end', width: hoursWidth, minHeight: cellSize * zoom }}
                                                    >
                                                        <p style={{ ...styles.TextXTiny, marginRight: 10, color: color.medium_gray }}><b>{hour}</b></p>
                                                    </div>
                                                ))}

                                            </div>

                                            <div style={{ ...styles.BlockRow, alignItems: 'flex-start', marginTop: topOffset }}>
                                                {days.map((day, dayIndex) => (
                                                    <div key={dayIndex} style={{ position: 'relative' }}>
                                                        <div style={{ ...styles.Block }}>
                                                            {hours.map((hour, index) => (
                                                                // DEN
                                                                <div
                                                                    key={index}
                                                                    onClick={() => setActualWeekDay(day)}
                                                                    style={{ ...styles.Block, width: days.length == 1 ? cellWidthFull : cellWidth, minHeight: days.length - 1 ? cellSize * zoom - 2 : cellSize * zoom - 1, borderLeft: '1px solid ' + borderColor, borderRight: dayIndex == days.length - 1 ? '1px solid ' + borderColor : '' }}
                                                                >
                                                                    {minutes.map((minute, i) => (
                                                                        <div
                                                                            key={i}
                                                                            onDoubleClick={() => AddNewTermin(day, index, i)}
                                                                            onDragOver={(e) => { e.preventDefault(); setDragOverID(index * 2 + i); setDragOverDay(day); }}
                                                                            onDrop={(e) => handleDrop(e, index * 60 + (i * (60 / minutes.length)), day)}
                                                                            style={{ ...styles.BlockLeft, justifyContent: 'flex-end', position: 'relative', width: days.length == 1 ? cellWidthFull : cellWidth, minHeight: (cellSize * zoom / minutes.length) - 1, borderTop: (hour == startWorkingTime || hour == endWorkingTime) && i == 0 ? '1px solid ' + color.black : terminDragID > 0 && dragOverID == index * 2 + i && dragOverDay == day ? '1px solid ' + color.dark_gray : i > 0 ? '1px dashed ' + borderLightColor : '1px solid ' + borderColor, borderBottom: index == hours.length - 1 && i == 1 ? '1px solid ' + borderColor : '', backgroundColor: day == today && calendarTyp < 2 ? todayColor : color.white }}
                                                                        >
                                                                            {dayIndex == 0 ?
                                                                                <p style={{ ...styles.TextXXXTiny, color: borderColor, position: 'absolute', bottom: 0, left: 0 }}>{i % 2 == 0 ? String(index).padStart(2, '0') + ':30' : ''}</p>
                                                                                : null}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>

                                                        {/*                                                                                                                
                                                        *
                                                        *
                                                        * =================================================================================================================
                                                        * TERMINY V DANOM DNI - TYZDENNY / DENNY LISTING
                                                        * =================================================================================================================
                                                        *
                                                        *                                                        
                                                        */}
                                                        <>
                                                            {termins != false ? TerminFilter(2, day, groupID).map((termin, index) => {
                                                                const overlaps = checkOverlapping(termin, day).length;

                                                                return (
                                                                    <Paper
                                                                        key={index}
                                                                        elevation={1}
                                                                        draggable={parseInt(termin.user_id) == props.user.id ? true : false}
                                                                        onDragStart={(e) => handleDragStart(e, termin)}
                                                                        onDragEnd={(e) => handleDragEnd(e)}
                                                                        onDragOver={(e) => { e.preventDefault(); setDragOverID(0); setDragTerminOverID(termin.id) }}
                                                                        style={{
                                                                            ...styles.BlockCenter,
                                                                            backgroundColor: terminColors[termin.color].light,
                                                                            backgroundImage: termin.typ == 3 ? 'repeating-linear-gradient(45deg, ' + terminColors[8].lines + ',' + terminColors[8].light + ' 2px, ' + terminColors[8].light + ' 8px)' : undefined,
                                                                            borderLeft: termin.typ == 2 ? undefined : '2px solid ' + terminColors[termin.color].dark,
                                                                            height: termin.length * zoom,
                                                                            width: dragTerminOverID == termin.id ? 10 : `${(terminWidth / (overlaps + 1)) - 3}px`,
                                                                            borderRadius: radius,
                                                                            position: 'absolute',
                                                                            top: termin.time_start * zoom,
                                                                            left: `${((terminWidth / (overlaps + 1)) * (index % (overlaps + 1))) + 1}px`,
                                                                            cursor: 'pointer',
                                                                            overflow: 'hidden',
                                                                            opacity: termin.id == terminDragID ? draggingOpacity : 1,
                                                                            borderRadius: radius,
                                                                            opacity: termin.typ == 3 ? 0.85 : 1.0
                                                                        }}
                                                                    >
                                                                        {dragTerminOverID != termin.id ?
                                                                            <div style={{ ...styles.BlockLeft, overflow: 'hidden' }}>
                                                                                {termin.typ == 2 ?
                                                                                    // TRASA
                                                                                    <div onClick={() => handleClick(termin)} style={{ ...styles.BlockCenter, overflow: 'hidden' }}>
                                                                                        {termin.data != false ?
                                                                                            termin.data.items != false ? termin.data.items.map((item, item_index) => (
                                                                                                <Tooltip
                                                                                                    key={item_index}
                                                                                                    disableHoverListener={tooltipEnabled == true ? false : true}
                                                                                                    componentsProps={{
                                                                                                        tooltip: {
                                                                                                            sx: {
                                                                                                                bgcolor: '#00000000',
                                                                                                                color: "white",
                                                                                                            },
                                                                                                        }
                                                                                                    }}
                                                                                                    title={
                                                                                                        <div style={{ ...styles.Block, width: 350, backgroundColor: color.white, border: '1px solid #A0A0A0', borderRadius: props.radius == undefined ? 0 : props.radius }}>
                                                                                                            <div style={{ ...styles.BlockLeft, width: '96%', paddingTop: 10, paddingBottom: 10, fontWeight: '400' }}>
                                                                                                                {item.company != '' ?
                                                                                                                    <TextLine text={item.company} fontSize={global.font_xsmall} fontWeight='600' marginLeft={2} color={terminColors[termin.color].text} />
                                                                                                                    : null}
                                                                                                                {item.customer_name != '' || item.customer_surname != '' ?
                                                                                                                    <TextLine text={item.customer_name + ' ' + item.customer_surname} fontSize={global.font_xsmall} marginLeft={2} color={terminColors[termin.color].text} />
                                                                                                                    : null}
                                                                                                                <FormSpace height={8} />
                                                                                                                {item.shop_name != '' ?
                                                                                                                    <p style={{ ...styles.TextXSmall }}><b>{item.shop_name}</b></p>
                                                                                                                    : null}
                                                                                                                <p style={{ ...styles.TextXSmall }}>{item.shop_street}</p>
                                                                                                                <p style={{ ...styles.TextXSmall }}>{item.shop_town}</p>
                                                                                                                {item.shop_street != '' || item.shop_town != '' ?
                                                                                                                    <FormSpace height={8} />
                                                                                                                    : null}
                                                                                                                <p style={{ ...styles.TextXSmall }}>{lang.route_visit_time}: {item.visit_time} {lang.calendar_termin_minutes}</p>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }>
                                                                                                    <div onDoubleClick={() => RouteEdit(termin, item)} key={item_index} style={{ ...styles.BlockLeft, justifyContent: 'flex-start', width: days.length == 1 ? '99%' : '94%', height: (item.visit_time * zoom) - 3, backgroundColor: terminColors[termin.color].lines, marginTop: ((item.time / 60) * zoom) + 1, borderRadius: 4, border: '1px solid ' + terminColors[termin.color].dark, overflow: 'hidden' }}>
                                                                                                        {item.company != '' ?
                                                                                                            <TextLine text={item.company} fontSize={global.font_xtiny} marginLeft={2} color={terminColors[termin.color].text} />
                                                                                                            : null}
                                                                                                        {item.customer_name != '' || item.customer_surname != '' ?
                                                                                                            <TextLine text={item.customer_name + ' ' + item.customer_surname} fontSize={global.font_xtiny} marginLeft={2} color={terminColors[termin.color].text} />
                                                                                                            : null}
                                                                                                        {item.shop_street != '' || item.shop_town != '' ?
                                                                                                            <TextLine text={item.shop_street + ' ' + item.shop_town} fontSize={global.font_xtiny} marginLeft={2} color={terminColors[termin.color].text} />
                                                                                                            : null}
                                                                                                        <TextLine text={lang.route_visit_time + ': ' + item.visit_time + ' ' + lang.calendar_termin_minutes} fontSize={global.font_xtiny} marginLeft={2} color={terminColors[termin.color].text} />
                                                                                                        <FormSpace />
                                                                                                    </div>
                                                                                                </Tooltip>
                                                                                            ))
                                                                                                : null : null}
                                                                                    </div>
                                                                                    :
                                                                                    <div onClick={() => EditTermin(termin)} style={{ ...termin.typ == 3 ? styles.Block : styles.BlockLeft, height: termin.length * zoom, justifyContent: termin.typ == 3 ? 'center' : 'flex- start' }}>
                                                                                        <div style={{ ...styles.BlockRow }}>
                                                                                            {termin.typ != 3 ?
                                                                                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                                                                    <p style={{ ...styles.TextTiny, color: terminColors[termin.color].text }}>{GetHours(termin.time_start)} - {GetHours(parseInt(termin.time_start) + parseInt(termin.length))}</p>
                                                                                                </div>
                                                                                                : null}
                                                                                            {groupID == 0 ?
                                                                                                <div style={{ ...styles.BlockRight, width: 30 }}>
                                                                                                    <div style={{ ...styles.Block, height: 18, width: 18, borderRadius: 9, backgroundColor: terminColors[termin.color].dark }}>
                                                                                                        <p style={{ ...styles.TextTiny, color: color.white }}>{calendarGroups[termin.group_id - 1].short}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                : null}
                                                                                        </div>
                                                                                        {/*termin.plan != '' ?
                                                                                            <TextLine text={termin.plan} fontSize={global.font_tiny} marginLeft={0} color={terminColors[termin.color].text} />
                                                                                            : null*/}
                                                                                        <p style={{ ...styles.TextTiny, color: terminColors[termin.color].text, fontWeight: termin.typ == 3 ? '600' : '400' }}>{termin.name}</p>
                                                                                        {termin.note != '' ?
                                                                                            <p style={{ ...styles.TextTiny, color: terminColors[termin.color].text, opacity: 0.75 }}>{termin.note}</p>
                                                                                            : null}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            : null}
                                                                    </Paper>
                                                                )
                                                            }
                                                            ) : null}
                                                        </>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>

                                        <div style={{ ...styles.BlockLeft }}>
                                            <FormControlLabel label={<p style={{ ...styles.TextXSmall }}>{lang.calendar_tooltips}</p>}
                                                style={{ marginLeft: hoursWidth }}
                                                control={<Checkbox checked={tooltipEnabled} size={'small'} onChange={() => ChangeToolTip(!tooltipEnabled)} />}
                                            />
                                        </div>

                                        <FormSpace height={80} />
                                    </div>
                                </div>
                                : null}


                            {/* 
                            *
                            *
                            * =================================================================================================================
                            * LISTING
                            * =================================================================================================================
                            *
                            *
                            */}

                            {calendarTyp == 3 ?
                                <div style={{ ...styles.BlockCenter, width: calendarWidth, height: props.height - global.widow_bar_height - headerHeight }}>
                                    <div style={{ ...styles.Block, height: daysHeight }}>
                                        <p style={{ ...styles.TextXSmall }}>{lang.days[GetDayOfWeek(actualWeekDay)]}<span style={{ marginLeft: 10 }}><b>{GetDatum4(actualWeekDay)}</b></span></p>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, width: calendarWidth, height: props.height - global.widow_bar_height - headerHeight - daysHeight - 10, backgroundColor: color.white, msOverflowStyle: 'none', scrollbarWidth: 'none', overflow: 'scroll' }} className='container'>
                                        {termins != false ? TerminFilter(2, actualWeekDay, groupID).map((termin, index) => {

                                            return (
                                                <Paper
                                                    key={index}
                                                    elevation={1}
                                                    onClick={() => EditTermin(termin)}
                                                    style={{
                                                        ...styles.BlockCenter,
                                                        backgroundColor: terminColors[termin.color].light,
                                                        borderLeft: '2px solid ' + terminColors[termin.color].dark,
                                                        paddingTop: 5, paddingBottom: 5,
                                                        minHeight: listingHeight,
                                                        width: '98%',
                                                        borderRadius: radius,
                                                        cursor: 'pointer',
                                                        overflow: 'hidden',
                                                        borderRadius: radius,
                                                        marginBottom: 5,
                                                        marginTop: 5
                                                    }}
                                                >
                                                    <div style={{ ...styles.BlockLeft, width: '99%' }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                                <p style={{ ...styles.TextTiny, color: terminColors[termin.color].text }}>{termin.typ == 3 ? '' : GetHours(termin.time_start) + ' - ' + GetHours(parseInt(termin.time_start) + parseInt(termin.length))} </p>
                                                            </div>
                                                            {groupID == 0 ?
                                                                <div style={{ ...styles.BlockRight, width: 30 }}>
                                                                    <div style={{ ...styles.Block, height: 18, width: 18, borderRadius: 9, backgroundColor: terminColors[termin.color].dark }}>
                                                                        <p style={{ ...styles.TextTiny, color: color.white }}>{calendarGroups[termin.group_id - 1].short}</p>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div>
                                                        <p style={{ ...styles.TextTiny, color: terminColors[termin.color].text }}>{termin.name}</p>
                                                    </div>
                                                </Paper>
                                            )
                                        }
                                        ) : null}
                                    </div>
                                </div>
                                : null}


                        </div>
                    </div>
                </div>

            </div >

            {showRoute == true ?
                <CalendarRoute remote={props.remote} remoteMobile={props.remoteMobile} item={itemSelected} terminColors={terminColors} calendarGroups={calendarGroups} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={RouteResult.bind()} />
                : null}

            {showTermin == true ?
                <CalendarTermin preview={terminSelected.new == true ? false : parseInt(props.user.id) == parseInt(terminSelected.termin.user_id) ? false : true} remote={props.remote} remoteMobile={props.remoteMobile} termin={terminSelected} terminColors={terminColors} calendarGroups={calendarGroups} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={TerminResult.bind()} />
                : null}

            {showTerminPreview == true ?
                <CalendarTermin remote={props.remote} remoteMobile={props.remoteMobile} preview termin={terminSelected} terminColors={terminColors} calendarGroups={calendarGroups} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={() => setShowTerminPreview(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}




export const CalendarMini = (props) => {
    /*
        PROPS:
            <CalendarMini cellSize={40} lang={props.lang} language={props.language} theme={props.theme} func={CalendarResult.bind(this)} />

            -----------------------------------------------------------------------------------------------

            cellSize            -> velkost jedneho dna
            multi               -> označenie viacero dni (napr. pri dovolenke)
            elevation           -> tien (default = 0)
            background          -> farba pozadia
            button              -> názov tlačítka pri zapnutóm multi móde
            buttonOutlined      -> tlačítko ako outlined

            -----------------------------------------------------------------------------------------------

            const CalendarResult = (date, y, m, d) => {
                console.log(date);
            }

    */
    let color = global.themes[props.theme];

    const [redraw, setRedraw] = useState(false);
    const [cells, setCells] = useState([]);
    const [day, setDay] = useState(0);
    const [selectedDays, setSelectedDay] = useState([]);
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);
    const [todayDay, setTodayDay] = useState(props.date == undefined ? 0 : GetDateDay(props.date));
    const [todayMonth, setTodayMonth] = useState(props.date == undefined ? 0 : GetDateMonth(props.date) - 1);
    const [todayYear, setTodayYear] = useState(props.date == undefined ? 0 : GetDateYear(props.date));

    const boxSize = props.cellSize == undefined ? 40 : props.cellSize;
    const dayOffset = 8;

    // COLORS
    const highLight = color.day_highlight;

    var lang = props.lang;

    let { func } = props;

    useEffect(() => {

        if (props.date == undefined) {
            var today = new Date();
            var d = today.getDate();
            var m = today.getMonth();
            var y = today.getFullYear();


            setTodayDay(d);
            setTodayMonth(m);
            setTodayYear(y);

            TodayDate();
        } else {
            var d = GetDateDay(props.date);
            var m = GetDateMonth(props.date) - 1;
            var y = GetDateYear(props.date);

            setTodayDay(d);
            setTodayMonth(m);
            setTodayYear(y);

            Redraw(0, y, m, d, true);
        }


        return () => {
        };

    }, [props.date]);


    function Redraw(typ_, year, month, day, reload) {
        // PREKRESLENIE DO KALENDARA
        var arr_months = [];

        var days_in_month = GetDays(year, month + 1);
        var first_day = GetDay(year, month, 1) == 0 ? 7 : GetDay(year, month, 1);

        setYear(year);
        setMonth(month);
        setDay(day);

        // ZISKANIE ARRAY - CELY MESIAC
        var n = 0;
        var value = false;
        var day_ = 1;
        var arr = [];
        var day_of_week_ = 0;
        while (n < first_day + days_in_month - 1) {
            if (n == first_day - 1) {
                value = true;
            }
            if (n == first_day - 1 + days_in_month) {
                value = false;
            }

            if (value == true) {
                var day_of_week_ = GetDay(year, month, day_);
            }
            var data = {
                id: n,
                active: value,
                day: value == true ? day_ : 0,
                day_name: lang.days_short[day_of_week_],
                month: month,
                year: year,
                color: color.white,
                items: []
            }
            arr[n] = data;

            if (value == true) {
                day_++;
            }
            n++;
        }
        arr_months = arr;
        setCells(arr);
    }


    const Next = () => {
        var d = 0;
        var m = month + 1;
        var y = year;
        if (m > 11) {
            m = 0;
            y = y + 1;
        }

        Redraw(0, y, m, d, true);
    }

    const Prev = () => {
        var d = 0;
        var m = month - 1;
        var y = year;
        if (m < 0) {
            m = 11;
            y = y - 1;
        }

        Redraw(0, y, m, d, true);
    }

    const TodayDate = () => {
        var data = new Date();
        var y = data.getFullYear();
        var m = data.getMonth();
        var d = data.getDate();

        var data = new Date(y, m, d);

        Redraw(0, y, m, d, true);
    }

    const SelectDay = (y, m, d) => {
        if (d > 0) {
            setYear(y);
            setMonth(m);
            setDay(d);

            var dd = String(d).padStart(2, '0');
            var mm = String(m + 1).padStart(2, '0');
            var yy = String(y);
            let date = yy + '-' + mm + '-' + dd;

            if (props.multi == true) {

                var tmp = selectedDays.find(x => x.date == date);
                if (tmp == undefined) {
                    // ZAZNAM ESTE NIEJE V ARRAY
                    var id = 0;

                    selectedDays.forEach(item => {

                        if (item.id >= id) {
                            id = parseInt(item.id) + 1;
                        }
                    });

                    let data = {
                        id: id,
                        date: date,
                        year: y,
                        month: m,
                        day: d
                    }

                    selectedDays.push(data);
                    setRedraw(!redraw);
                } else {
                    // ZAZNAM UZ JE V ARRAY -> VYMAZAT
                    var tmp = selectedDays.filter(x => x.date != date);
                    setSelectedDay(tmp);
                }

            } else {
                func(date, y, m, d);
            }
        }
    }

    const GetDays = (year, month) => {
        // POCET DNI V MESIAC
        return new Date(year, month, 0).getDate();
    }


    const GetDay = (year, month, day) => {
        // DEN V TYZDNI
        return new Date(year, month, day).getDay();
    }

    const Save = () => {
        var result = false;

        if (selectedDays.length > 0) {
            var tmp = selectedDays;
            tmp.sort((a, b) => {
                if (a.date > b.date) {
                    return 1;
                } else {
                    return -1;
                }
            })
            result = tmp;
        }

        func(result);
    }

    const DayInArray = (item) => {
        var tmp = selectedDays.find(x => x.year == item.year && x.month == item.month && x.day == item.day);

        if (tmp == undefined) {
            return false;
        } else {
            return true;
        }
    }

    /* *******************************************************************************************************************************
    **********************************************************************************************************************************
     
        RENDER - Main
     
    **********************************************************************************************************************************        
    ********************************************************************************************************************************** */
    return (
        <Paper elevation={props.elevation == undefined ? 0 : props.elevation} style={{ ...styles.BlockCenter, width: boxSize * 7, backgroundColor: props.background == undefined ? color.white : props.background, paddingTop: 10, paddingBottom: 10 }}>
            <div style={{ ...styles.Block }}>
                <div style={{ ...styles.BlockRow, width: boxSize * 7 }}>
                    <div style={{ ...styles.Block, width: boxSize * 1 }}>
                        <IconButton onClick={() => Prev()} style={{ width: 36, height: 36, backgroundColor: '#00000000' }}>
                            <FontAwesomeIcon style={{ height: 16, color: color.black }} icon={faChevronLeft} />
                        </IconButton>
                    </div>

                    <div style={{ ...styles.Block, width: boxSize * 5 }}>
                        <p style={{ ...styles.TextLarge, fontWeight: 'normal', marginTop: 7 }}><b>{lang.months_list[month]}</b> {year}</p>
                    </div>
                    <div style={{ ...styles.Block, width: boxSize * 1 }}>
                        <IconButton onClick={() => Next()} style={{ width: 36, height: 36, backgroundColor: '#00000000' }}>
                            <FontAwesomeIcon style={{ height: 16, color: color.black }} icon={faChevronRight} />
                        </IconButton>
                    </div>
                </div>
            </div>
            {/* PONDELOK ... NEDELA */}
            <div style={{ ...styles.BlockRow, maxWidth: boxSize * 7, marginTop: 10 }}>
                {lang.days_short_label.map((item, index) => (
                    <div key={index} style={{ ...styles.Block, width: boxSize, height: 25 }}>
                        <p style={{ ...styles.TextTiny, marginRight: 5, color: color.dark_gray }}>{item[0]}</p>
                    </div>
                ))}
            </div>
            <div style={{ ...styles.BlockCenter, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                {/* ****************************************************************************************************
                    KALENDARNY DNI 
                **************************************************************************************************** */}
                <div style={{ ...styles.BlockRow, maxWidth: boxSize * 7, flexWrap: 'wrap' }}>
                    {cells.map((item, index) => (
                        <div key={index} style={{ ...styles.Block, width: boxSize, height: boxSize, cursor: item.active == true ? 'pointer' : 'default' }}>
                            {item.active == true ?
                                props.multi == true ?
                                    <div onClick={() => SelectDay(item.year, item.month, item.day)} style={{ width: boxSize - dayOffset, height: boxSize - dayOffset, backgroundColor: DayInArray(item) ? highLight : item.active ? item.color : '#00000000', borderRadius: boxSize - 2 / 2, border: item.year == todayYear && item.month == todayMonth && item.day == todayDay ? '1px solid ' + color.dark_gray : '#00000000' }}>
                                        <div style={{ ...styles.Block, height: boxSize - dayOffset }}>
                                            <p style={{ ...styles.TextTiny, color: DayInArray(item) ? color.white : color.black, fontWeight: item.year == todayYear && item.month == todayMonth && item.day == todayDay ? '600' : '400' }}>{item.day}</p>
                                        </div>
                                    </div>
                                    :
                                    <div onClick={() => SelectDay(item.year, item.month, item.day)} style={{ width: boxSize - dayOffset, height: boxSize - dayOffset, backgroundColor: item.year == year && item.month == month && item.day == day ? highLight : item.active ? item.color : '#00000000', borderRadius: boxSize - 2 / 2, border: item.year == todayYear && item.month == todayMonth && item.day == todayDay ? '1px solid ' + color.dark_gray : '#00000000' }}>
                                        <div style={{ ...styles.Block, height: boxSize - dayOffset }}>
                                            <p style={{ ...styles.TextTiny, color: item.year == year && item.month == month && item.day == day ? color.white : color.black, fontWeight: item.year == todayYear && item.month == todayMonth && item.day == todayDay ? '600' : '400' }}>{item.day}</p>
                                        </div>
                                    </div>
                                : null}
                        </div>
                    ))}
                </div>
                {props.multi == true ?
                    selectedDays.length > 0 ?
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <Button onClick={() => Save()} style={{ ...props.buttonOutlined == true ? styles.ButtonOutlined : styles.ButtonThemed, backgroundColor: props.buttonOutlined == true ? undefined : color.button_ok }}>{props.button == undefined ? lang.continue : props.button}</Button>
                        </div>
                        : null
                    : null}
            </div>
        </Paper >
    )
};

export const CalendarTermin = (props) => {
    /*
 
            TERMIN - UDALOST V KALENDARY
 
    */
    const lang = props.lang;
    const terminColors = props.terminColors;
    const calendarGroups = props.calendarGroups != false ? props.calendarGroups.filter(x => x.id > 0) : false;
    const color = global.themes[props.theme];

    const calendar_types = [
        { id: 0, enabled: true, name: lang.calendar_type[0], icon: faCalendarWeek, width: 100 },
        { id: 1, enabled: true, name: lang.calendar_type[1], icon: faCheckSquare, width: 100 },
        { id: 2, enabled: global.appstore == true ? false : true, name: lang.calendar_type[2], icon: faRoute, width: 100 },
        { id: 3, enabled: true, name: lang.calendar_type[3], icon: faPlane, width: 100 }
    ];

    const remind_types = [
        { id: 0, enabled: true, name: lang.calendar_remind_types[0] },
        { id: 1, enabled: true, name: lang.calendar_remind_types[1] },
        { id: 2, enabled: true, name: lang.calendar_remind_types[2] },
        { id: 3, enabled: props.system.sms_enabled == true ? props.system.sms_notify == false ? false : true : false, name: lang.calendar_remind_types[3] }
    ];

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [needSave, setNeedSave] = useState(false);
    const [editing, setEditing] = useState(props.preview == true ? false : true);

    // VALUES
    const hours = Array.from({ length: 24 }, (_, index) => '@hour:00'.replace('@hour', String(index).padStart(2, '0')));
    const minutes = Array.from({ length: 2 }, (_, index) => String(index * 30));
    const [days, setDays] = useState([props.termin.new == true ? props.termin.date : props.termin.termin.date]);

    const [itemID, setItemID] = useState(props.termin.new == true ? 0 : props.termin.termin.id);
    const [users, setUsers] = useState(props.termin.new == true ? [{ id: props.user.id, name: props.user.name + ' ' + props.user.surname }] : false);
    const [usersExcluded, setUsersExcluded] = useState([]);
    const [date, setDate] = useState(props.termin.new == true ? props.termin.date : props.termin.termin.date);
    const [startTime, setStartTime] = useState(GetHours(props.termin.new == true ? props.termin.hour * 60 + props.termin.minute : props.termin.termin.time_start));
    const [endTime, setEndTime] = useState(GetHours(props.termin.new == true ? props.termin.hour * 60 + props.termin.minute + 60 : parseInt(props.termin.termin.time_start) + parseInt(props.termin.termin.length)));
    const [deadlineDate, setDeadlineDate] = useState(props.termin.new == true ? props.termin.date : props.termin.termin.deadline_date);
    const [deadlineTime, setDeadlineTime] = useState(GetHours(props.termin.new == true ? props.termin.hour * 60 + props.termin.minute : props.termin.termin.deadline_time));
    const [length, setLength] = useState(props.termin.new == true ? 60 : props.termin.termin.length);
    const [lengthCustom, setLengthCustom] = useState(props.termin.new == true ? false : lang.termin_lengths.find(x => x.id == props.termin.termin.length) == undefined ? true : false);
    const [planID, setPlanID] = useState(props.termin.new == true ? 0 : props.termin.termin.plan_id);
    const [plans, setPlans] = useState(false);
    const [name, setName] = useState(props.termin.new == true ? '' : props.termin.termin.name);
    const [nameTMP, setNameTMP] = useState('');
    const [note, setNote] = useState(props.termin.new == true ? '' : props.termin.termin.note);
    const [groupID, setGroupID] = useState(props.termin.new == true ? 1 : props.termin.termin.group_id);
    const [colorID, setColorID] = useState(props.termin.new == true ? 0 : props.termin.termin.color);
    const [typ, setTyp] = useState(props.termin.new == true ? 0 : props.termin.termin.typ);
    const [priority, setPriority] = useState(props.termin.new == true ? 0 : props.termin.termin.priority);
    const [routeID, setRouteID] = useState(props.termin.new == true ? 0 : props.termin.termin.route_id);
    const [route, setRoute] = useState(false);
    const [routeItems, setRouteItems] = useState(false);
    const [customers, setCustomers] = useState(false);
    const [customersExcluded, setCustomersExcluded] = useState([]);
    const [allDay, setAllDay] = useState(props.termin.new == true ? false : props.termin.termin.all_day == false ? false : true);
    const [deadline, setDeadline] = useState(props.termin.new == true ? false : props.termin.termin.deadline);
    const [taskFinished, setTaskFinished] = useState(props.termin.new == true ? false : props.termin.termin.task_finished == false ? false : true);
    const [remind, setRemind] = useState(props.termin.new == true ? 0 : props.termin.termin.remind);
    const [user, setUser] = useState('');
    const [userID, setUserID] = useState(props.termin.new == true ? props.user.id : props.termin.termin.user_id);

    const [termin, setTermin] = useState(props.termin.new == true ? false : props.termin.termin);
    const [termins, setTermins] = useState(false);

    // DIALOGS
    const [showCustomers, setShowCustomers] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [showRoutes, setShowRoutes] = useState(false);
    const [showRoutePreview, setShowRoutePreview] = useState(false);

    // WINDOW
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.plans == true || props.preview == true ? width > 720 ? 720 : width : width > 900 ? 900 : width;
    const dialogHeight = height > global.dialog_max_height ? global.dialog_max_height : height;

    const column1 = 140;
    const column2 = 440;
    const calendarWidth = props.plans == true || props.preview == true ? 0 : 240;
    const calendarOffset = 40;

    const errorHeight = 40;
    const buttonHeight = 60;

    // KALENDAR - VELKOSTI
    const hoursWidth = 55;
    const cellWidth = calendarWidth - hoursWidth - 2;
    const cellWidthFull = calendarWidth - hoursWidth - 2;
    const topOffset = 6;
    const cellSize = 60;
    const radius = 6;
    const zoom = 0.75;
    const headerHeight = 70;
    const daysHeight = 50;

    const [terminWidth, setTerminWidth] = useState(cellWidth);

    // COLORS
    const borderColor = '#D0D0D0';
    const borderLightColor = '#E0E0E0';
    const todayColor = color.today_highlight;


    let { func } = props;
    var running = false;

    useEffect(() => {

        if (running == false) {
            let filter = {
                date: props.termin.new == true ? props.termin.date : props.termin.termin.date,
                group_id: props.termin.new == true ? props.termin.group_id : props.termin.termin.group_id,
            };

            if (props.termin.new == false) {
                db_get(props.termin.termin.id);
            } else {
                db_get_data();
            }
            if (props.plans == undefined) {
                db_get_termins(filter);
            }

            running = true;
        }

        return () => {
        };

    }, []);

    const db_get = async (termin_id) => {
        try {
            const response = await fetch(
                global.db_url + 'calendar_termin', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------                    
                    termin_id: termin_id
                })
            })

            const json = await response.json();
            Debug('calendar_termin');
            Debug(json);
            if (json.ok > 0) {
                if (json.termin.data != false) {
                    setRoute(json.termin.data.route);
                    setRouteItems(json.termin.data.items);
                }
                setPlans(json.plans);

                setUser(json.termin.user);
                setUsers(json.termin.users);
                setUserID(json.termin.user_id);

                setCustomers(json.termin.customers);
            }

        } catch (error) {
            console.log(error);
        }

    }

    const db_get_data = async () => {
        try {
            const response = await fetch(
                global.db_url + 'calendar_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                })
            })

            const json = await response.json();
            Debug('calendar_data');
            Debug(json);
            if (json.ok > 0) {
                setPlans(json.plans);
            }

        } catch (error) {
            console.log(error);
        }

    }


    const db_get_termins = async (filter) => {
        try {
            const response = await fetch(
                global.db_url + 'calendar_termins', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------                    
                    date: filter.date,
                    filter: filter
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var items = json.items;

                if (items != false) {

                    var tmp = items.filter(x => x.date == filter.date && x.group_id == filter.group_id);
                    tmp.sort((a, b) => {
                        if (parseInt(a.time_start) > parseInt(b.time_start)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })

                    setTermins(tmp);
                } else {
                    setTermins(false);
                }

                GoToStart();
            }

        } catch (error) {
            console.log(error);
        }

    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'calendar_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                func(true);
            }

        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    }

    const Close = () => {
        func(false);
    }

    const ChangeStartTime = (time, button) => {
        if (button == false) {
            setStartTime(time);
            setEndTime(GetEndTime(time, parseInt(length)));
        } else {

        }
    }

    const ChangeLength = (time, button) => {
        if (button == false) {
            setLength(time);
            setEndTime(GetEndTime(startTime, parseInt(time)));
        } else {
            setLengthCustom(true);
        }
    }

    const GetEndTime = (start_time, length) => {
        let end_time = GetMinutesFromHours(start_time) + length;
        return (GetHours(end_time));
    }

    const GetLength = (start_time, end_time) => {
        let len = GetMinutesFromHours(end_time) - GetMinutesFromHours(start_time);
        if (lang.termin_lengths.find(x => x.id == len) != undefined) {
            setLengthCustom(false);
        } else {
            setLengthCustom(true);
        }
        return len < 0 ? 0 : len;
    }


    const ChangeEndTime = (time, button) => {
        if (button == false) {
            setEndTime(time);
            setLength(GetLength(startTime, time));
        } else {

        }
    }

    const checkOverlapping = (termin) => {
        return termins.filter(x => x.id != termin.id && parseInt(termin.time_start) < parseInt(x.time_start + x.length) && parseInt(termin.time_start + termin.length) > parseInt(x.time_start));
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_termin_7');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const PrevDay = () => {
        let new_date = getPreviousDay(date);
        setDays([new_date]);
        setDate(new_date);

        let filter = {
            date: new_date,
            group_id: groupID
        };
        Debug(filter);
        db_get_termins(filter);
    }

    const NextDay = () => {
        let new_date = getNextDay(date);
        setDays([new_date]);
        setDate(new_date);

        let filter = {
            date: new_date,
            group_id: groupID
        };
        Debug(filter);
        db_get_termins(filter);
    }

    const ChangeGroup = (id) => {
        setGroupID(id);

        let filter = {
            date: date,
            group_id: id
        };
        Debug(filter);
        db_get_termins(filter);
    }

    const CustomersResult = (typ, items, selected_customers) => {
        setShowCustomers(false);

        if (typ == true && items != false) {

            var array = customers == false ? [] : customers;
            selected_customers.forEach(element => {
                let name = element.company == '' ? element.name + ' ' + element.surname : element.surname == '' ? element.company : element.company + ', ' + element.name + ' ' + element.surname;
                Debug(name);
                array.push({ id: element.id, name: name });
            });
            setCustomers(array);

            setNeedSave(true);
        }
    }

    const CheckChanges = () => {
        var result = false;

        if (props.termin.new == true) {
            result = true;
        } else {
            var changes = 0;
            changes += termin.color != colorID ? 1 : 0;
            changes += termin.name != name ? 1 : 0;
            changes += termin.group_id != groupID ? 1 : 0;
            changes += termin.date != date ? 1 : 0;
            changes += termin.time_start != GetMinutesFromHours(startTime) ? 1 : 0;
            changes += termin.time_end != GetMinutesFromHours(endTime) ? 1 : 0;
            changes += termin.length != length ? 1 : 0;
            changes += termin.note != note ? 1 : 0;
            changes += termin.priority != priority ? 1 : 0;
            changes += termin.typ != typ ? 1 : 0;
            changes += (termin.all_day == false ? false : true) != allDay ? 1 : 0;
            changes += termin.route_id != routeID ? 1 : 0;
            changes += termin.remind != remind ? 1 : 0;
            changes += termin.plan_id != planID ? 1 : 0;

            if (typ == 1) {
                changes += (termin.deadline == false ? false : true) != deadline ? 1 : 0;
                changes += termin.deadline_date != deadlineDate ? 1 : 0;
                changes += termin.deadline_time != GetMinutesFromHours(deadlineTime) ? 1 : 0;
                changes += (termin.task_finished == false ? false : true) != taskFinished ? 1 : 0;
            }

            if (route != false && route != undefined) {
                changes += route.id != routeID ? 1 : 0;
            }

            if (changes > 0) {
                result = true;
            }
        }

        return result;
    }

    const ChangeRoutes = (value) => {
        if (value == true) {
            setShowRoutes(true);
        } else {
            setRouteID(0);
            setRoute(false);
        }
    }

    const RouteResult = (typ, route, route_items) => {
        setShowRoutes(false);
        Debug(route_items);
        if (typ == true) {
            setRoute(route);
            setRouteID(route.id);
            setRouteItems(route_items == false ? false : route_items);
            setLength(parseInt(route.time / 60));
            setEndTime(GetEndTime(startTime, parseInt(route.time / 60)));
            setNeedSave(true);
        }
    }

    const ChangeAllDay = (value) => {
        if (value == true) {
            setStartTime('00:00');
            setEndTime('23:59');
            setLength(1440);
        } else {
            setStartTime('08:00');
            setEndTime('09:00');
            setLength(60);
        }
        setAllDay(value);
    }


    const ChangeDeadline = (id) => {
        if (id == true) {
            setDeadlineDate(date);
            setDeadlineTime(endTime);
            setDeadline(true);
        } else {
            setDeadline(false);
        }
    }

    const Save = () => {

        var err = 0;
        var err_text = lang.required_red;
        setError('');

        Debug(GetMinutesFromHours(startTime));
        Debug(GetMinutesFromHours(endTime));
        Debug(length);

        if (GetMinutesFromHours(startTime) >= GetMinutesFromHours(endTime)) {
            err_text = lang.calendar_hours_error;
            err++;
        }
        if (typ == 1 && deadline == true) {
            // ULOHA - deadline
            if (deadlineDate < date) {
                err_text = lang.calendar_deadline_date_error;
                err++;
            } else {
                if (GetMinutesFromHours(endTime) > GetMinutesFromHours(deadlineTime)) {
                    err_text = lang.calendar_deadline_time_error;
                    err++;
                }
            }
        }

        if (planID < 1 && typ == 0) {
            err++;
            err_text = lang.plan_typ_error;
        }

        if (err == 0) {

            var json = 0;
            if (typ == 2) {
                // TRASA
                if (routeID > 0 && route != false) {
                    json = {
                        route: {
                            id: route.id,
                            name: name,
                            route_length: route.route_length,
                            time: route.time,
                            start_lat: route.start_lat,
                            start_lng: route.start_lng,
                        },
                        items: routeItems
                    };
                }
            }

            var users_ = [];
            if (typ == 0 || typ == 1) {
                users.forEach(element => {
                    users_.push(element.id);
                });
            } else {
                users_ = [props.user.id];
            }

            if (customers != false) {
                var customers_ = [];
                customers.forEach(element => {
                    customers_.push(element.id);
                });
            } else {
                customers_ = false;
            }

            let data = {
                id: itemID,
                enabled: true,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                user_id: props.user.id,
                users: JSON.stringify(users_),
                group_id: typ == 0 ? groupID : 1,
                priority: priority,
                date: date,
                all_day: allDay,
                time_start: GetMinutesFromHours(startTime),
                time_end: GetMinutesFromHours(endTime),
                length: parseInt(length),
                deadline: deadline,
                deadline_date: deadlineDate,
                deadline_time: GetMinutesFromHours(deadlineTime),
                plan_id: planID == -1 ? 0 : planID,
                name: name,
                note: note,
                color: typ == 1 ? priority == 0 ? taskFinished == false ? 6 : 10 : taskFinished == false ? 9 : 10 : typ == 2 ? 7 : colorID,
                typ: typ,
                route_id: routeID,
                data: JSON.stringify(json),
                customers: customers_ != false ? JSON.stringify(customers_) : false,
                remind: remind,
                task_finished: taskFinished,
            };

            Debug(data);
            db_update([data]);

        } else {
            setError(err_text);
        }
    }

    const DeleteTermin = () => {
        let data = {
            id: itemID,
            enabled: false,
        };

        Debug(data);
        db_update([data]);
    }


    const ChangeTyp = (id) => {
        // Ak je prepnutá dovolenka - zmena názvu + zamapätanie si povedného názvu
        if (typ == 3) {
            setName(nameTMP);
        }
        if (id == 3) {
            setNameTMP(name);
            setName(lang.calendar_holiday);
        }

        setTyp(id); // Musí byť posledné
    }

    const UsersSelectResult = (typ, selected, selected_users) => {
        setShowUsers(false);

        if (typ == true && selected_users != false) {
            var array = users;
            selected_users.forEach(element => {
                array.push({ id: element.id, name: element.name + ' ' + element.surname });
            });
            setNeedSave(true);
        }
    }

    const CustomersListResult = (array) => {
        if (array == true) {
            // TLACITKO +

            var tmp = [];
            if (customers != false) {
                customers.forEach(element => {
                    tmp.push(element.id);
                });
            }
            setCustomersExcluded(tmp);
            setShowCustomers(true);

        } else {
            if (array == false) {
                setCustomers(false);
            } else {
                setCustomers(array);
            }
            setNeedSave(true);
        }
    }

    const UsersResult = (array) => {
        if (array == true) {
            // TLACITKO +
            var tmp = [];
            users.forEach(element => {
                tmp.push(element.id);
            });
            setUsersExcluded(tmp);

            setShowUsers(true);
        } else {
            if (array == false) {
                setUsers([{ id: props.user.id, name: props.user.name + ' ' + props.user.surname }]);
            } else {
                setUsers(array);
            }
            setNeedSave(true);
        }
    }


    const CalendarMiniResult = (items, y, m, d) => {

        if (items.length > 0) {
            var array = [];
            items.forEach(item => {
                let data = {
                    id: itemID,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    user_id: props.user.id,
                    group_id: groupID,
                    date: item.date,
                    all_day: true,
                    time_start: 0,
                    time_end: 24 * 60,
                    length: 24 * 60,
                    name: name,
                    color: 0,
                    users: JSON.stringify([props.user.id]),
                    typ: typ,
                };

                array.push(data);
            });

            db_update(array);
        }

    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2500 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius, backgroundColor: color.back }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faCalendarDay} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.termin.new == true ? props.plans == true ? lang.plan_new : lang.calendar_termin_new : lang.calendar_termin}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, width: '100%' }}>
                        <div style={{ ...styles.Block }}>

                            <div style={{ ...props.plans == undefined && props.preview != true ? styles.BlockRow : styles.Block }}>

                                {/* 
                                    *
                                    *
                                    * =================================================================================================================
                                    * TERMINY V DANOM DNI - LAVA STRANA
                                    * =================================================================================================================
                                    *
                                    *
                                    */}
                                {props.plans == undefined && props.preview != true ?
                                    <div style={{ ...styles.Block, width: calendarWidth + calendarOffset, borderRight: '1px solid ' + color.light_gray, backgroundColor: color.white, borderBottomLeftRadius: props.radius }}>
                                        <div style={{ ...styles.BlockCenter, width: calendarWidth + calendarOffset, height: dialogHeight - global.dialog_bar_height - 2 }} >
                                            <div style={{ ...styles.Block, height: daysHeight }}>
                                                {days.map((day, dayIndex) => (
                                                    <div
                                                        key={dayIndex}
                                                        style={{
                                                            ...styles.Block,
                                                            width: calendarWidth + calendarOffset,
                                                            height: daysHeight,
                                                            backgroundColor: '#00000000',
                                                            borderRadius: radius,
                                                        }}
                                                    >
                                                        <div style={{ ...styles.Block, width: calendarWidth + calendarOffset, borderBottom: '1px solid ' + color.light_gray, backgroundColor: color.back }}>
                                                            <div style={{ ...styles.BlockRow, width: calendarWidth }}>
                                                                <div style={{ ...styles.BlockLeft, width: 50, height: daysHeight - 1 }}>
                                                                    <IconButton onClick={() => PrevDay()} style={{ ...styles.ButtonIcon }}>
                                                                        <FontAwesomeIcon style={{ width: 10 }} icon={faAngleLeft} />
                                                                    </IconButton>
                                                                </div>

                                                                <div style={{ ...styles.BlockLeft, width: calendarWidth - 50 - 50, height: daysHeight - 1 }}>
                                                                    <p style={{ ...styles.TextXSmall }}>{lang.days[GetDayOfWeek(day)]}<span style={{ marginLeft: 5 }}><b>{GetDatum2(day)}</b></span></p>
                                                                </div>

                                                                <div style={{ ...styles.BlockRight, width: 50, height: daysHeight - 1 }}>
                                                                    <IconButton onClick={() => NextDay()} style={{ ...styles.ButtonIcon }}>
                                                                        <FontAwesomeIcon style={{ width: 10 }} icon={faAngleRight} />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>

                                            <div style={{ ...styles.BlockCenter, width: calendarWidth, height: dialogHeight - global.widow_bar_height - daysHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflow: 'scroll' }} className='container'>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    {/* ZOZNAM HODINY NA LAVEJ STRANE */}
                                                    <div style={{ ...styles.BlockCenter, width: hoursWidth, position: 'relative' }}>
                                                        {hours.map((hour, index) => (
                                                            <div
                                                                id={'id_termin_' + index}
                                                                key={index}
                                                                style={{ ...styles.BlockCenter, alignItems: 'flex-end', width: hoursWidth, minHeight: cellSize * zoom }}
                                                            >
                                                                <p style={{ ...styles.TextXTiny, marginRight: 10, color: color.medium_gray }}><b>{hour}</b></p>
                                                            </div>
                                                        ))}

                                                    </div>

                                                    <div style={{ ...styles.BlockRow, alignItems: 'flex-start', marginTop: topOffset }}>
                                                        {days.map((day, dayIndex) => (
                                                            <div key={dayIndex} style={{ position: 'relative' }}>
                                                                <div style={{ ...styles.Block }}>
                                                                    {hours.map((hour, index) => (
                                                                        // DEN
                                                                        <div
                                                                            key={index}
                                                                            style={{ ...styles.Block, width: days.length == 1 ? cellWidthFull : cellWidth, minHeight: days.length - 1 ? cellSize * zoom - 2 : cellSize * zoom - 1, borderLeft: '1px solid ' + borderColor, borderRight: dayIndex == days.length - 1 ? '1px solid ' + borderColor : '' }}
                                                                        >
                                                                            {minutes.map((minute, i) => (
                                                                                <div
                                                                                    onClick={() => ChangeStartTime(hour[0] + hour[1] + ':' + minute.padStart(2, '0'), false)}
                                                                                    key={i}
                                                                                    style={{ ...styles.BlockLeft, justifyContent: 'flex-end', position: 'relative', width: days.length == 1 ? cellWidthFull : cellWidth, minHeight: (cellSize * zoom / minutes.length) - 1, borderTop: i > 0 ? '1px dashed ' + borderLightColor : '1px solid ' + borderColor, borderBottom: index == hours.length - 1 && i == 1 ? '1px solid ' + borderColor : '', backgroundColor: color.white }}
                                                                                >
                                                                                    {/*dayIndex == 0 ?
                                                                                    <p style={{ ...styles.TextXXXTiny, color: borderColor, position: 'absolute', bottom: 0, left: 0 }}>{i % 2 == 0 ? String(index).padStart(2, '0') + ':30' : ''}</p>
                                                                                : null*/}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))}
                                                                </div>

                                                                {/* TERMINY V DANOM DNI */}
                                                                <>
                                                                    {termins != false ? termins.map((termin, index) => {
                                                                        const overlaps = checkOverlapping(termin).length;

                                                                        return (
                                                                            <Paper
                                                                                key={index}
                                                                                elevation={1}
                                                                                style={{
                                                                                    ...styles.BlockCenter,
                                                                                    backgroundColor: terminColors[termin.color].light,
                                                                                    backgroundImage: props.termin.new == true ? undefined : termin.id == props.termin.termin.id ? 'repeating-linear-gradient(45deg, ' + terminColors[termin.color].lines + ',' + terminColors[termin.color].light + ' 2px, ' + terminColors[termin.color].light + ' 8px)' : undefined,
                                                                                    border: '1px solid ' + terminColors[termin.color].dark,
                                                                                    borderLeft: '2px solid ' + terminColors[termin.color].dark,
                                                                                    height: termin.length * zoom,
                                                                                    width: `${(terminWidth / (overlaps + 1)) - 2}px`,
                                                                                    borderRadius: radius,
                                                                                    position: 'absolute',
                                                                                    top: termin.time_start * zoom,
                                                                                    left: `${((terminWidth / (overlaps + 1)) * (index % (overlaps + 1))) + 1}px`,
                                                                                    overflow: 'hidden',
                                                                                    borderRadius: radius
                                                                                }}
                                                                            >
                                                                                <div style={{ ...styles.BlockLeft }}>
                                                                                    <div style={{ ...styles.BlockRow }}>
                                                                                        {overlaps == 0 ?
                                                                                            <div style={{ ...styles.Block, width: 24 }}>
                                                                                                <FontAwesomeIcon style={{ height: 14, color: terminColors[termin.color].text, opacity: 0.33, marginTop: 1 }} icon={faClock} />
                                                                                            </div>
                                                                                            : null}
                                                                                        <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                                                            <p style={{ ...styles.TextTiny, color: terminColors[termin.color].text }}>{GetHours(termin.time_start)} - {GetHours(termin.time_start + termin.length)}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p style={{ ...styles.TextTiny, color: terminColors[termin.color].text }}>{termin.name}</p>
                                                                                </div>
                                                                            </Paper>
                                                                        )
                                                                    }
                                                                    ) : null}
                                                                </>
                                                            </div>
                                                        ))}

                                                    </div>
                                                </div>
                                                <FormSpace height={80} />
                                            </div>
                                        </div>

                                    </div>
                                    : null}

                                {/* 
                                    *
                                    *
                                    * =================================================================================================================
                                    * UDAJE O TERMINE
                                    * =================================================================================================================
                                    *
                                    *
                                */}

                                <div style={{ ...styles.BlockLeft, width: dialogWidth - calendarWidth - calendarOffset - 1, height: dialogHeight - global.widow_bar_height }}>
                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflow: 'scroll' }} className='container'>

                                        {/* TYP UDALOSTI - Udalosť, Úloha */}
                                        <FormButtons value={typ} editing={props.termin.new == true ? true : false} title={props.preview == true ? lang.calendar_type_ : undefined} buttons={calendar_types.filter(x => x.enabled == true)} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeTyp(id)} />
                                        {parseInt(props.user.id) != parseInt(userID) ?
                                            <FormText value={user} editing={false} title={lang.created_by} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => Debug(txt)} />
                                            : null}
                                        <FormSpace />

                                        {props.user.calendar_types == true && typ == 0 ?
                                            <>
                                                <FormSelect require value={groupID} items={calendarGroups} field={'name'} editing={editing} title={lang.calendar_group} column1={column1} width={column2} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeGroup(id)} />
                                                <FormSpace />
                                            </>
                                            : null}

                                        {typ == 0 ?
                                            // ****************************************************************
                                            // TYP -> UDALOST
                                            // ****************************************************************
                                            <>
                                                {/* NAZOV UDALOSTI */}
                                                <FormSelect require value={planID < 1 ? -1 : planID} items={plans} field={'name'} editing={editing} title={lang.plan_typ} column1={column1} width={column2} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setPlanID(id)} />
                                                <FormText require maxLength={255} value={name} editing={editing} title={lang.calendar_termin_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => setName(txt)} />
                                                <FormSpace />

                                                {/* DATUM A CAS */}
                                                <div style={{ ...styles.BlockRowRaw, width: column1 + column2 + 10 }}>
                                                    <FormText date button_icon={faPen} value={date} editing={editing} title={lang.calendar_date} column1={column1} column2={150} width={150} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDate(txt)} />
                                                    {props.preview != true ?
                                                        <div style={{ ...styles.BlockLeft, minWidth: 30, height: 36, marginTop: 5, marginLeft: 30 }}>
                                                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.calendar_termin_all_day}</p>}
                                                                control={<Checkbox checked={allDay} size={'small'} onChange={() => ChangeAllDay(!allDay)} />}
                                                            />
                                                        </div>
                                                        : null}
                                                </div>
                                                {allDay == false ?
                                                    <>
                                                        {props.preview == true ?
                                                            <FormText time button_icon={faPen} value={startTime + ' - ' + endTime} editing={editing} title={lang.calendar_time_from_to} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => ChangeStartTime(txt, button)} />
                                                            :
                                                            <>
                                                                <div style={{ ...styles.BlockRowRaw, width: column1 + column2 + 10 }}>
                                                                    <FormText time button_icon={faPen} value={startTime} editing={editing} title={lang.calendar_time_from_to} column1={column1} column2={150} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => ChangeStartTime(txt, button)} />
                                                                    <div style={{ ...styles.Block, minWidth: 30, height: 36, marginTop: 5 }}>
                                                                        <FontAwesomeIcon style={{ height: 10, color: color.black }} icon={faMinus} />
                                                                    </div>
                                                                    <FormText time button_icon={faPen} value={endTime} editing={editing} title={lang.calendar_time_end} column1={0} column2={column2 - 180} width={150} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => ChangeEndTime(txt, button)} />
                                                                </div>
                                                                {lengthCustom == true ?
                                                                    <FormText numeric end={lang.calendar_termin_minutes} value={length} editing={editing} title={lang.calendar_time_length} column1={column1} column2={column2} width={150} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => ChangeLength(txt, false)} />
                                                                    :
                                                                    <FormSelect value={length} button button_icon={faPen} button_margin={20} items={lang.termin_lengths} field={'name'} editing={editing} title={lang.calendar_time_length} width={150} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id, button) => ChangeLength(id, button)} />
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    : null}

                                                {/* VIAZANE NA ZAKAZNIKA */}
                                                <FormSpace />
                                                <FormList buttonLeft notDeletable={0} hideID={0} empty={lang.empty_list} value={customers} editing={editing} title={lang.customers} field={'name'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(array) => CustomersListResult(array)} />
                                                {customers != false ?
                                                    <FormNote title={customers.length == 1 ? lang.calendar_termin_customer : lang.calendar_termin_customers} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                                    : null}

                                                {/* UZIVATELIA */}
                                                <FormList buttonLeft notDeletable={props.user.id} hideID={props.user.id} value={users} editing={editing} title={lang.calendar_users} field={'name'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(array) => UsersResult(array)} />

                                                {/* FAREBNE ZNACENIE */}
                                                {props.preview != true ?
                                                    <>
                                                        <FormSpace />
                                                        <FormColors mini value={colorID} items={props.terminColors.filter(x => x.typ == 0)} name={'dark'} editing={editing} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} func={(id) => setColorID(id)} />
                                                    </>
                                                    : null}
                                                {/* POZNAMKA */}
                                                <FormSpace />
                                                <FormText value={note} maxLength={512} rows={4} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />

                                                {/* PRIPOMIENKA */}
                                                <FormSpace />
                                                <FormSelect value={remind} items={remind_types.filter(x => x.enabled == true)} field={'name'} editing={editing} title={lang.calendar_remind} column1={column1} width={column2} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setRemind(id)} />
                                            </>
                                            : null}

                                        {typ == 1 ?
                                            // ****************************************************************
                                            // TYP -> ULOHA
                                            // ****************************************************************
                                            <>
                                                {/* PRIORITY */}
                                                <FormButtons value={priority} editing={editing} title={lang.calendar_termin_priority + ' (' + lang.calendar_termin_priorities[priority] + ')'} buttons={[{ id: 0, icon: faAngleUp, width: 32 }, { id: 1, icon: faAnglesUp, width: 32 }]} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setPriority(id)} />

                                                {/* NAZOV ULOHY */}
                                                <FormText require maxLength={255} value={name} editing={editing} title={lang.calendar_termin_task_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => setName(txt)} />
                                                <FormSpace />

                                                {/* DATUM A CAS */}
                                                <FormLabel small editing={editing} icon={faCalendarWeek} title={lang.calendar_task_date_time} column1={column1} column2={column2} marginTop={20} theme={props.theme} lang={lang} radius={props.radius} func={() => Debug('')} />
                                                <FormText date button_icon={faPen} value={date} editing={editing} title={lang.calendar_date} column1={column1} column2={column2} width={150} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDate(txt)} />
                                                {props.preview == true ?
                                                    <FormText time button_icon={faPen} value={startTime + ' - ' + endTime} editing={editing} title={lang.calendar_time_from_to} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => ChangeStartTime(txt, button)} />
                                                    :
                                                    <div style={{ ...styles.BlockRowRaw, width: column1 + column2 + 10 }}>
                                                        <FormText time button_icon={faPen} value={startTime} editing={editing} title={lang.calendar_time_from_to} column1={column1} column2={150} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => ChangeStartTime(txt, button)} />
                                                        <div style={{ ...styles.Block, minWidth: 30, height: 36, marginTop: 5 }}>
                                                            <FontAwesomeIcon style={{ height: 10, color: color.black }} icon={faMinus} />
                                                        </div>
                                                        <FormText time button_icon={faPen} value={endTime} editing={editing} title={lang.calendar_time_end} column1={0} column2={column2 - 180} width={150} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => ChangeEndTime(txt, button)} />
                                                    </div>
                                                }
                                                {lengthCustom == true ?
                                                    <FormText numeric end={lang.calendar_termin_minutes} value={length} editing={editing} title={lang.calendar_time_length} column1={column1} column2={column2} width={150} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => ChangeLength(txt, false)} />
                                                    :
                                                    <FormSelect value={length} button button_icon={faPen} button_margin={20} items={lang.termin_lengths} field={'name'} editing={editing} title={lang.calendar_time_length} width={150} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id, button) => ChangeLength(id, button)} />
                                                }

                                                {/* DEADLINE */}
                                                <FormLabel small editing={editing} icon={faExclamationCircle} title={lang.calendar_termin_deadline} column1={column1} column2={column2} marginTop={20} theme={props.theme} lang={lang} radius={props.radius} func={() => Debug('')} />
                                                <FormYesNo value={deadline} editing={editing} title={lang.calendar_termin_deadline_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeDeadline(id)} />
                                                {deadline == true ?
                                                    <>
                                                        <FormText date button_icon={faPen} value={deadlineDate} editing={editing} title={lang.calendar_termin_deadline_date} column1={column1} column2={column2} width={150} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDeadlineDate(txt)} />
                                                        <FormText time button_icon={faPen} value={deadlineTime} editing={editing} title={lang.calendar_termin_deadline_time} column1={column1} column2={column2} width={150} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => setDeadlineTime(txt, button)} />
                                                    </>
                                                    : null}

                                                {/* POZNAMKA */}
                                                <FormSpace />
                                                <FormText value={note} maxLength={512} rows={2} editing={editing} title={lang.calendar_task_text} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                                                <FormYesNo value={taskFinished} editing={editing} title={lang.calendar_termin_task_finished} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setTaskFinished(id)} />

                                                {/* VIAZANE NA ZAKAZNIKA */}
                                                <FormSpace />
                                                <FormList buttonLeft notDeletable={0} hideID={0} empty={lang.empty_list} value={customers} editing={editing} title={lang.customers} field={'name'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(array) => CustomersListResult(array)} />
                                                {customers != false ?
                                                    <FormNote title={customers.length == 1 ? lang.calendar_termin_customer : lang.calendar_termin_customers} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                                    : null}

                                                {/* UZIVATELIA */}
                                                <FormList buttonLeft notDeletable={props.user.id} hideID={props.user.id} value={users} editing={editing} title={lang.calendar_users} field={'name'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(array) => UsersResult(array)} />

                                                <FormSpace height={global.list_padding} />
                                            </>
                                            : null}

                                        {typ == 2 ?
                                            // ****************************************************************
                                            // TYP -> TRASA
                                            // ****************************************************************
                                            <>
                                                {props.preview != true ?
                                                    <>
                                                        <FormButton value={routeID == 0 ? lang.calendar_route_none : route != false ? route.name : ''} button_delete={route == false ? false : true} buton_icon={route == false ? faPlus : faPen} editing={editing} title={lang.calendar_route} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeRoutes(id)} />                                                    </>
                                                    : null}

                                                {route != false ?
                                                    // INFO O TRASE
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        <div style={{ ...styles.BlockLeft, width: column1, marginRight: 10 }}></div>
                                                        <div style={{ ...styles.BlockLeft, width: column2 }}>
                                                            <p style={{ ...styles.TextXSmall }}>{lang.calendar_route_customers}: {routeItems == false ? 0 : routeItems.length}</p>
                                                            <p style={{ ...styles.TextXSmall }}>{lang.route_length_}: ~{parseInt(route.route_length / 1000).toFixed(1) + ' ' + lang.route_length_km}</p>
                                                            <p style={{ ...styles.TextXSmall }}>{lang.route_time_total}: ~{GetHoursFromMinutes(parseInt(route.time) / 60) + ' ' + lang.route_length_hours}</p>
                                                            <div style={{ ...styles.BlockRowRaw, marginTop: 5 }}>
                                                                <div style={{ ...styles.Block, width: undefined, height: 20 }}>
                                                                    <FontAwesomeIcon style={{ height: 16, color: color.dark_gray }} icon={faInfoCircle} />
                                                                </div>
                                                                <div style={{ ...styles.Block, width: undefined, height: 20, marginLeft: 8 }}>
                                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.route_length_time_note}</p>
                                                                </div>
                                                            </div>

                                                            <Button onClick={() => setShowRoutePreview(true)} style={{ ...styles.ButtonOutlined, marginTop: 10 }}>{lang.route_preview}</Button>
                                                        </div>
                                                    </div>
                                                    : null}
                                                <FormSpace />

                                                {/* DATUM A CAS */}
                                                <FormText date button_icon={faPen} value={date} editing={editing} title={lang.calendar_date} column1={column1} column2={column2} width={150} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDate(txt)} />
                                                <FormText time button_icon={faPen} value={startTime} editing={editing} title={lang.calendar_time} column1={column1} column2={column2} width={150} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => ChangeStartTime(txt, button)} />

                                                {/* POZNAMKA */}
                                                <FormSpace />
                                                <FormText value={note} maxLength={512} rows={4} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />

                                            </>
                                            : null}


                                        {typ == 3 ?
                                            // ****************************************************************
                                            // TYP -> DOVOLENKA
                                            // ****************************************************************
                                            <>
                                                {/* NAZOV UDALOSTI */}
                                                <FormText require maxLength={255} value={name} editing={itemID == 0 ? true : false} title={lang.calendar_termin_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, button) => setName(txt)} />

                                                {itemID == 0 ?
                                                    <>
                                                        <FormSpace />
                                                        <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.calendar_holiday_select}</p>
                                                        <CalendarMini multi date={date} button={lang.save} cellSize={38} lang={props.lang} language={props.language} theme={props.theme} func={CalendarMiniResult.bind(this)} />
                                                    </>
                                                    :
                                                    <FormText require date value={date} editing={itemID == 0 ? true : false} title={lang.calendar_date} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => Debug('')} />
                                                }

                                            </>
                                            : null}

                                        {props.remote == true && props.remoteMobile == true ?
                                            <FormSpace height={500} />
                                            : null}

                                    </div>
                                    <div style={{ ...styles.Block, height: errorHeight }}>
                                        <FormError small margin={0} error={error} theme={props.theme} />
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: buttonHeight }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            {itemID > 0 && props.preview != true ?
                                                <div style={{ ...styles.Block, width: undefined, marginRight: 20 }}>
                                                    <DeleteButton ask bold sizeNormal label={lang.calendar_termin_delete} icon={faTrash} icon_color={color.black} position={'top'} ok={lang.calendar_termin_delete} backgroundColor={'#00000000'} text={lang.calendar_termin_delete_label} text_note={lang.calendar_termin_delete_text} tooltip={lang.calendar_termin_delete} theme={props.theme} lang={props.lang} func={() => DeleteTermin()} />
                                                </div>
                                                : null}

                                            {typ != 3 && (CheckChanges() == true || needSave == true) && props.preview != true ?
                                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 160, marginRight: 20 }}>{lang.save}</Button>
                                                : null}
                                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 160 }}>{lang.close}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </Paper>

            {showRoutePreview == true ?
                <MyRoutePreview route={route} routeItems={routeItems} organization={props.organization} settings={props.settings} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={() => setShowRoutePreview(false)} />
                : null}

            {showUsers == true ?
                <UsersSelect multiple groups excluded={usersExcluded} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.stock_transport_approve_choose} token={props.token} func={UsersSelectResult.bind()} />
                : null}

            {showCustomers == true ?
                <CustomerSelect showVariations={true} excluded={customersExcluded} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomersResult.bind()} />
                : null}

            {showRoutes == true ?
                <RouteSelect organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={RouteResult.bind()} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}


        </Backdrop >
    );
}



export const CalendarRoute = (props) => {
    /*

            ZMENA NASTAVENI ROUTE

    */

    let lang = props.lang;

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [changed, setChanged] = useState(false);

    const [visitTime, setVisitTime] = useState(props.item.visit_time);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width > 600 ? 600 : width;
    const dialogHeight = height > 550 ? 550 : height;

    const column1 = 200;
    const column2 = dialogWidth - column1 - 40;

    const errorHeight = 50;
    const buttonHeight = 80;

    let { func } = props;
    const color = global.themes[props.theme];

    var running = false;
    useEffect(() => {
        Debug(props.item);
        if (running == false) {

            running = true;
        }

        return () => {
        };

    }, []);



    const Close = () => {
        func(false, false);
    }

    const ChangeVisitTime = (value) => {
        setVisitTime(value);
        setChanged(true);
    }

    const Save = () => {
        var err = 0;
        setError('');

        err = IsEmpty(visitTime) == true ? 1 : 0;

        if (err > 0) {
            setError(lang.required_red);
        } else {
            if (parseInt(visitTime) > 0) {
                var data = props.item
                data.visit_time = visitTime;

                func(true, data);
            } else {
                setError(lang.route_visit_error);
            }
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2500 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius, backgroundColor: color.back }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faMagnifyingGlass} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.route_customer_visit}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight, width: '100%', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.Block, width: '98%' }}>
                            <FormSpace />
                            <FormLabel editing={false} editButton={false} icon={faInfoCircle} title={lang.customer} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => Debug('')} />
                            {props.item.company != '' ?
                                <FormText redraw value={props.item.company} editing={false} title={lang.company_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setVisitTime(txt)} />
                                : null}
                            {props.item.shop_name != '' ?
                                <FormText redraw value={props.item.shop_name} editing={false} title={lang.customer_shop_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setVisitTime(txt)} />
                                : null}
                            {props.item.customer_name != '' || props.item.customer_surname != '' ?
                                <FormText redraw value={props.item.customer_name + ' ' + props.item.customer_surname} title={lang.customer_name} editing={false} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setVisitTime(txt)} />
                                : null}
                            {props.item.shop_street != '' ?
                                <FormText redraw value={props.item.shop_street} editing={false} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setVisitTime(txt)} />
                                : null}
                            {props.item.shop_town != '' ?
                                <FormText redraw value={props.item.shop_town} editing={false} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setVisitTime(txt)} />
                                : null}
                        </div>
                        <FormSpace />
                        <FormText redraw numeric width={150} end={lang.route_visit_time_minutes} value={visitTime} editing={true} title={lang.route_visit_time} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => ChangeVisitTime(txt)} />

                    </div>

                </div>
                <div style={{ ...styles.Block, height: errorHeight }}>
                    <FormError small margin={0} error={error} theme={props.theme} />
                </div>
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        {changed == true ?
                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 160, marginRight: 20 }}>{lang.save}</Button>
                            : null}
                        <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 160 }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>

        </Backdrop >
    );
}
