/*
*
* =================================================================================================================

    WEB PAGE DESIGNER

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    preview    -> len náhľad

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Avatar, DialogTabs, DialogYesNo, EmptyList, FormSelect, FormSpace, FormText, Icon, Loading, ShowError, ShowOK, TextInput, FormYesNo, TextLine, DeleteButton } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, FormControlLabel, IconButton, Paper, Slider, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa1, fa2, faAlignCenter, faAlignLeft, faAlignRight, faArrowLeft, faArrowRight, faBold, faItalic, faLayerGroup, faPen, faPlusCircle, faQuestion, faSave, faT, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetImageLink, GetLeftPosition, GetPages, UniqueID } from './functions';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { Photos } from './photos.js';
import { ProductSelect } from './product_select.js';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Gallery } from './gallery.js';

export const WebPageDesigner = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');
    const background_1 = require('./react/app/page_background_1.png');
    const background_2 = require('./react/app/page_background_2.png');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [pageID, setPageID] = useState(props.item == false ? 0 : props.item.id);
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [overID, setOverID] = useState(-1);
    const [itemTyp, setItemTyp] = useState('');
    const [enabled, setEnabled] = useState(true);

    // STATISTIKA
    const [statItems, setStatItems] = useState(false);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showItem, setShowItem] = useState(false);
    const [showCreatePage, setShowCreatePage] = useState(false);
    const [showColors, setShowColors] = useState(false);
    const [colorID, setColorID] = useState(0);


    // PAGE SETTINGS
    const [pageEnabled, setPageEnabled] = useState(true);
    const [backgroundTyp, setBackgroundTyp] = useState(0);
    const [backgroundColor1, setBackgroundColor1] = useState('#FFFFFF');
    const [backgroundColor2, setBackgroundColor2] = useState('#E0E0E0');
    const [paddingTop, setPaddingTop] = useState(0);
    const [paddingBottom, setPaddingBottom] = useState(20);
    const [borderRadius, setBorderRadius] = useState(0);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = props.preview == true ? 0 : 40;
    const menuHeight = 50;
    const rowHeight = 100;
    const rowStatHeight = 80;
    const tableHeight = 40;
    const statHeight = 50;

    const toolbarHeight = 80;
    const barButtonSize = 34;


    const tabs = [
        { id: 0, enabled: true, label: lang.web_page_items },
        { id: 2, enabled: true, label: lang.settings },
        { id: 3, enabled: props.stats == true ? true : false, label: lang.web_page_stats },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item == false) {
                setTimeout(() => {
                    setShowCreatePage(true);
                }, 250);
            } else {
                // UDAJE Z DB
                db_get(props.item.id, true);
            }

            running = true;
        }

    }, []);


    const db_get = async (page_id, enabled) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'page', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    page_id: page_id,
                    enabled: enabled
                })
            })

            const json = await response.json();
            Debug('RELOAD', 'blue');
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                var page = json.page;

                setItems(tmp);

                setPageEnabled(page.enabled == false ? false : true);
                setBackgroundTyp(page.gradient == false ? 0 : 1);
                setBackgroundColor1(page.background_color_1);
                setBackgroundColor2(page.background_color_2);
                setBorderRadius(page.border_radius);
                setPaddingTop(page.padding_top);
                setPaddingBottom(page.padding_bottom);

                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_stats = async (page_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'page_stats', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    page_id: page_id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setStatItems(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'page_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (pageID == 0) {
                    setPageID(json.ok);
                    func(false, json.ok);
                }
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_reorder = async (items) => {
        setBusy(true);
        Debug(global.model.page_items);
        Debug(items);
        try {
            const response = await fetch(
                global.db_url + 'reorder_items', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.page_items,
                    items: items
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false, 0);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        if (props.preview != true) {
            setItemSelected(item);
            setItemTyp(item.typ);
            setShowItem(true);
        }
    }

    const ChooseResult = (typ, item) => {
        setShowDialog(false);

        if (typ == true) {
            setItemSelected(false);
            setItemTyp(item.id);
            setShowItem(true);
        }
    }

    const ItemResult = (redraw_) => {
        setShowItem(false);

        if (redraw_ == true) {
            db_get(pageID, enabled);
        }
    }

    const CreateResult = (typ) => {
        if (typ == true) {

            let data = {
                id: 0,
                organization_id: props.organization.id,
                user_id: props.user.id,
                enabled: true,
                gradient: false,
                gradient_degrees: 180,
                background_color_1: color.white,
                background_color_2: color.white,
                border_radius: 0,
                padding_top: 0,
                padding_bottom: 20
            };

            db_update(data);

            setShowCreatePage(false);
        } else {
            func(false, 0);
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (result.destination) {
            const tmp = reorder(items, result.source.index, result.destination.index);

            var n = 1;
            var items_ = [];
            tmp.forEach(item => {
                item.priority = n;

                let data = {
                    id: item.id,
                    priority: n
                }
                items_.push(data);
                n++
            });

            setItems(tmp);
            db_reorder(items_);
        }
    }

    const ChangeEnabled = (value) => {
        setEnabled(value);

        db_get(pageID, value);
    }

    const SelectColor = (id) => {
        setColorID(id);
        setShowColors(true);
    }

    const ChangeBackgroundTyp = (id) => {
        setBackgroundTyp(id);
        let data = {
            id: pageID,
            gradient: id == 0 ? false : true
        }
        db_update(data);
    }

    const ColorsResult = (typ, color) => {
        setShowColors(false);
        if (typ == true) {
            if (colorID == 0) {
                setBackgroundColor1(color);
                let data = {
                    id: pageID,
                    background_color_1: color
                }
                db_update(data);
            }
            if (colorID == 1) {
                setBackgroundColor2(color);
                let data = {
                    id: pageID,
                    background_color_2: color
                }
                db_update(data);
            }
        }
    }

    const ChangeRadius = (value) => {
        setBorderRadius(value);

        let data = {
            id: pageID,
            border_radius: value
        }
        db_update(data);
    }

    const ChangePaddingTop = (value) => {
        setPaddingTop(value);

        let data = {
            id: pageID,
            padding_top: value
        }
        db_update(data);
    }

    const ChangePaddingBottom = (value) => {
        setPaddingTop(value);

        let data = {
            id: pageID,
            padding_bottom: value
        }
        db_update(data);
    }

    const ChangePageEnabled = (value) => {
        setPageEnabled(value);

        let data = {
            id: pageID,
            enabled: value
        }
        Debug(data);
        db_update(data);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 3) {
            db_stats(pageID);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faPen} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.preview == true ? lang.web_page_preview : lang.web_page_designer}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height }}>

                        {props.preview != true ?
                            <div style={{ ...styles.Block, height: tabHeight }}>
                                <DialogTabs center value={tabIndex} items={tabs} width={dialogWidth / 3} theme={props.theme} func={(id) => ChangeTab(id)} />
                            </div>
                            : null}

                        {tabIndex == 0 ?
                            /*
                            *
                            * =================================================================================================================
                            * PRVKY STRANKY
                            * =================================================================================================================
                            *
                            */
                            <div style={{ ...styles.BlockCenter }} >
                                <div style={{ ...styles.Block, width: '96%' }}>
                                    {props.preview != true ?
                                        <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray }}>
                                            <div style={{ ...styles.BlockLeft, width: '33%', height: menuHeight }}>
                                                <FormControlLabel label={<p style={{ ...styles.TextXSmall }}>{lang.web_page_enabled}</p>}
                                                    style={{ marginLeft: 0 }}
                                                    control={<Checkbox checked={enabled} size={'small'} onChange={() => ChangeEnabled(!enabled)} />}
                                                />
                                            </div>
                                            <div style={{ ...styles.Block, width: '33%', height: menuHeight }}>
                                                {pageEnabled == false ?
                                                    <Chip label={lang.web_page_unabled_} style={{ backgroundColor: color.dark_red, color: color.white, fontSize: global.font_xsmall }} />
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: '33%', height: menuHeight }}>
                                                <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                    {lang.web_page_item_new}
                                                </Button>
                                            </div>
                                        </div>
                                        : null}

                                    <div style={{
                                        ...styles.BlockCenter,
                                        marginTop: 10,
                                        width: dialogWidth,
                                        height: dialogHeight - global.widow_bar_height - tabHeight - menuHeight - global.radius - 10,
                                        msOverflowStyle: 'none',
                                        scrollbarWidth: 'none',
                                        overflowY: 'scroll'
                                    }} className='container'
                                    >
                                        <div style={{
                                            ...styles.BlockCenter,
                                            background: backgroundTyp == 0 ? 'linear-gradient(180deg, ' + backgroundColor1 + ' 0%, ' + backgroundColor1 + ' 100%)' : 'linear-gradient(180deg, ' + backgroundColor1 + ' 0%, ' + backgroundColor2 + ' 100%)',
                                            width: dialogWidth,
                                            borderRadius: borderRadius,
                                        }}>
                                            <div style={{ ...styles.Block, paddingTop: paddingTop, paddingBottom: paddingBottom }}>
                                                {items != false ?
                                                    <DragDropContext onDragEnd={onDragEnd}>
                                                        <Droppable droppableId="droppable">
                                                            {(provided) => (
                                                                <div
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                    style={{ ...styles.BlockLeft, width: dialogWidth }}
                                                                >
                                                                    {items.map((item, index) => (
                                                                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <div onClick={() => SelectItem(item)}
                                                                                        style={{
                                                                                            ...styles.Block,
                                                                                            cursor: 'pointer',
                                                                                            width: dialogWidth,
                                                                                            borderTop: props.preview == true ? undefined : overID == item.id ? '1px solid #E0E0E0' : null,
                                                                                            borderBottom: props.preview == true ? undefined : overID == item.id ? '1px solid #E0E0E0' : null,
                                                                                            position: 'relative'
                                                                                        }}
                                                                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                                                    >
                                                                                        <PageItemRender design data={item} width={dialogWidth} theme={props.theme} />
                                                                                        {item.enabled == false && enabled == false ?
                                                                                            <div style={{ ...styles.Block, position: 'absolute', top: 0, left: 0 }}>
                                                                                                <Chip size='small' label={lang.web_page_unabled} style={{ backgroundColor: color.error, color: color.white }} />
                                                                                            </div>
                                                                                            : null}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext >
                                                    :
                                                    <div style={{ ...styles.Block, paddingTop: 50, paddingBottom: 40 }}>
                                                        <EmptyList row small lang={lang} />
                                                    </div>
                                                }
                                            </div>

                                        </div>

                                        <FormSpace height={150} />

                                    </div>
                                </div>
                            </div>
                            : null}

                        {tabIndex == 2 ?
                            /*
                            *
                            * =================================================================================================================
                            * NASTAVENIE STRANKY
                            * =================================================================================================================
                            *
                            */

                            <div style={{ ...styles.BlockCenter, backgroundColor: color.back, height: dialogHeight - global.widow_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 20 }}>
                                    <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.web_page_settings}</p>
                                </div>
                                <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                    <Paper style={{ ...styles.BlockLeft, width: undefined, height: toolbarHeight, borderRadius: 6, paddingLeft: 10, paddingRight: 10, marginRight: 20 }}>
                                        {props.email == true ?
                                            <p style={{ ...styles.TextTiny }}><b>{lang.web_page_background}</b></p>
                                            :
                                            <p style={{ ...styles.TextTiny }}><b>{lang.web_page_background_typ}:</b> {backgroundTyp == 0 ? lang.web_page_background_solid : lang.web_page_background_gradient}</p>
                                        }
                                        <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                            {props.email == undefined ?
                                                <div onClick={() => ChangeBackgroundTyp(0)} style={{ ...styles.Block, width: barButtonSize, height: barButtonSize, cursor: 'pointer', backgroundColor: backgroundTyp == 0 ? color.light_red : '#00000000', borderRadius: 8 }}>
                                                    <img src={background_1} style={{ width: '100%', height: '100%', maxWidth: barButtonSize - 6, maxHeight: barButtonSize, objectFit: 'contain' }}></img>
                                                </div>
                                                : null}
                                            {props.email == undefined ?
                                                <div onClick={() => ChangeBackgroundTyp(1)} style={{ ...styles.Block, width: barButtonSize, height: barButtonSize, cursor: 'pointer', backgroundColor: backgroundTyp == 1 ? color.light_red : '#00000000', borderRadius: 8 }}>
                                                    <img src={background_2} style={{ width: '100%', height: '100%', maxWidth: barButtonSize - 6, maxHeight: barButtonSize, objectFit: 'contain' }}></img>
                                                </div>
                                                : null}
                                            <div onClick={() => SelectColor(0)} style={{ ...styles.Block, width: barButtonSize, height: barButtonSize, cursor: 'pointer', marginLeft: backgroundTyp == 0 ? 40 : 10 }}>
                                                <Paper elevation={3} style={{ ...styles.Block, width: barButtonSize - 10, height: barButtonSize - 10, backgroundColor: backgroundColor1, borderRadius: (barButtonSize - 10) / 2 }}></Paper>
                                            </div>
                                            {backgroundTyp == 1 ?
                                                <div style={{ ...styles.Block, width: 40, height: barButtonSize, background: 'linear-gradient(180deg, ' + backgroundColor1 + ' 0%, ' + backgroundColor2 + ' 100%)', borderRadius: 8 }}>
                                                </div>
                                                : null}
                                            {backgroundTyp == 1 ?
                                                <div onClick={() => SelectColor(1)} style={{ ...styles.Block, width: barButtonSize, height: barButtonSize, cursor: 'pointer' }}>
                                                    <Paper elevation={3} style={{ ...styles.Block, width: barButtonSize - 10, height: barButtonSize - 10, backgroundColor: backgroundColor2, borderRadius: (barButtonSize - 10) / 2 }}></Paper>
                                                </div>
                                                : null}
                                        </div>
                                    </Paper>

                                    <Paper style={{ ...styles.BlockLeft, width: 320, height: toolbarHeight, borderRadius: 6, paddingLeft: 10, paddingRight: 10, marginRight: 20 }}>
                                        <p style={{ ...styles.TextTiny }}><b>{lang.web_page_paddings}</b></p>
                                        <div style={{ ...styles.BlockRowRaw, marginTop: 4 }}>
                                            <FormSelect value={paddingTop} items={global.page_paddings} field={'id'} editing={true} title={lang.web_page_padding_top} column1={50} column2={90} width={90} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => ChangePaddingTop(id)} />
                                            <FormSelect value={paddingBottom} items={global.page_paddings} field={'id'} editing={true} title={lang.web_page_padding_bottom} column1={60} column2={90} width={90} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => ChangePaddingBottom(id)} />
                                        </div>
                                    </Paper>

                                    {/*
                                    <div style={{ ...styles.BlockLeft, width: 85, height: toolbarHeight, borderRadius: 6, paddingLeft: 10, paddingRight: 10, marginTop: 10 }}>
                                        <p style={{ ...styles.TextTiny }}><b>{lang.web_page_max_width}</b></p>
                                        <div style={{ ...styles.Block, marginTop: 4 }}>
                                            <FormSelect zero={lang.web_page_max_width_zero} value={maxWidth} items={global.page_max_width} field={'id'} editing={true} column2={85} width={85} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => setMaxWidth(id)} />
                                        </div>
                                    </div>
                                    */}

                                    {props.email == undefined ?
                                        <Paper style={{ ...styles.BlockLeft, width: 100, height: toolbarHeight, borderRadius: 6, paddingLeft: 10, paddingRight: 10, marginRight: 20 }}>
                                            <p style={{ ...styles.TextTiny }}><b>{lang.web_page_border_radius}</b></p>
                                            <div style={{ ...styles.Block, marginTop: 4 }}>
                                                <FormSelect value={borderRadius} items={global.page_radius} field={'id'} editing={true} column2={90} width={90} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => ChangeRadius(id)} />
                                            </div>
                                        </Paper>
                                        : null}
                                    {props.email == undefined ?
                                        <Paper style={{ ...styles.BlockLeft, width: 130, height: toolbarHeight, borderRadius: 6, paddingLeft: 10, paddingRight: 10 }}>
                                            <p style={{ ...styles.TextTiny }}><b>{lang.web_page_enabled_}</b></p>
                                            <div style={{ ...styles.Block, marginTop: 4 }}>
                                                <FormYesNo value={pageEnabled} editing={true} column2={130} theme={props.theme} lang={lang} radius={props.radius} func={(value) => ChangePageEnabled(value)} />
                                            </div>
                                        </Paper>
                                        : null}
                                </div>
                            </div>
                            : null}


                        {tabIndex == 3 ?
                            /*
                            *
                            * =================================================================================================================
                            * ŠTATISTIKA
                            * =================================================================================================================
                            *
                            */

                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height }} >
                                <div style={{ ...styles.Block, width: '100%' }}>

                                    <div style={{ ...styles.Block, width: '98%', height: statHeight }}>
                                        <p style={{ ...styles.TextXSmall }}><b>{lang.web_page_stats_text}</b></p>
                                    </div>

                                    <div style={{ ...styles.Block, width: '98%', border: color.border, marginTop: 10, marginBottom: 10 }}>
                                        <div style={{ ...styles.BlockRow, width: '99%' }}>
                                            <div style={{ ...styles.BlockLeft, width: '86%', height: tableHeight }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.web_page_stat_link}</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: 150, height: tableHeight, borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.web_page_stat_clicks}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - tabHeight - tableHeight - statHeight - 26, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        {statItems != false ? statItems.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    width: '98%',
                                                    backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowStatHeight,
                                                    //cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '96%' }}>
                                                    <div style={{ ...styles.BlockLeft, width: '86%', minHeight: rowStatHeight, justifyContent: 'center' }}>
                                                        <TextLine text={item.button_text} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                        <TextLine text={item.link} fontSize={global.font_tiny} color={color.black} marginTop={5} />
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 150, minHeight: rowStatHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXSmall }}><b>{item.counter}</b> x</p>
                                                    </div>
                                                </div>
                                            </Paper>
                                        )) :
                                            <EmptyList row small lang={lang} />
                                        }
                                    </div>
                                </div>
                            </div>
                            : null}

                    </div>
                </div>
            </Paper >

            {showColors == true ?
                <DialogColors remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={ColorsResult.bind(this)} />
                : null}


            {showCreatePage == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.web_page_create} text={lang.web_page_create_question} sub_text={lang.web_page_create_question_note} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={CreateResult.bind(this)} />
                : null}

            {showDialog == true ?
                <PageItemChoose email={props.email == true ? true : undefined} remote={props.remote} system={props.system} remoteMobile={props.remoteMobile} lang={lang} theme={props.theme} radius={props.radius} func={ChooseResult.bind(this)} />
                : null}

            {showItem == true ?
                <PageItem email={props.email} typ={itemTyp} item={itemSelected} page_id={pageID} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={ItemResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}



export const PageItemChoose = (props) => {

    const lang = props.lang;

    const [selected, setSelected] = useState(false);
    const [overID, setOverID] = useState(-1);

    var items = props.email == true ? global.page_items.filter(x => x.email == true) : global.page_items;

    const [width, setWidth] = useState(window.innerWidth);

    const buttonHeight = 80;
    const dialogWidth = 580;
    const dialogHeight = 680;
    const titleHeight = 55;

    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);

    const Close = () => {
        func(false);
    }

    const Save = () => {
        func(true, selected);
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.web_page_item_select}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, height: titleHeight }}>
                        {selected != false ?
                            <>
                                <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang[selected.name]}</p>
                                <p style={{ ...styles.TextTiny }}>{lang[selected.note]}</p>
                            </>
                            : null}
                    </div>
                    <div style={{ ...styles.BlockCenter, justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {items.map((item, index) => (
                            <div onClick={() => setSelected(item)} key={item.id} style={{ ...styles.Block, backgroundColor: selected == false ? overID == item.id ? color.gray : color.white : selected.id == item.id ? color.lighteen_red : overID == item.id ? color.gray : color.white, width: 124, height: 124, marginLeft: 5, marginRight: 5, marginTop: 20, marginBottom: 20, borderRadius: 5 }} onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}>
                                <img src={item.image} style={{ width: '100%', height: '100%', maxWidth: 96, maxHeight: 96, objectFit: 'contain', borderRadius: 5 }}></img>
                                <p style={{ ...styles.TextXTiny, marginTop: 4, textAlign: 'center' }}>{lang[item.name]}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    {selected != false ?
                        <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.web_page_item_new}</Button>
                        : null}
                </div>
            </Paper>
        </Backdrop>
    );
}


/*
*
* =================================================================================================================
* PRVOK NA STRANKE
* =================================================================================================================
*
*/
export const PageItem = (props) => {

    const lang = props.lang;

    const permited = true;

    const [isBusy, setBusy] = useState(false);
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [template, setTemplate] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);

    const background_1 = require('./react/app/page_background_1.png');
    const background_2 = require('./react/app/page_background_2.png');
    const image_default = require('./react/app/default_image.png');

    // HTML EDITOR
    const blocksFromHTML = convertFromHTML(props.item == false ? '' : props.item.html);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap,);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));
    const [contentState, setContentState] = useState();

    // TOOLBAR
    const [showBackground, setShowBackground] = useState(true);
    const [showPadding, setShowPadding] = useState(true);
    const [showBorder, setShowBorder] = useState(true);
    const [showAlign, setShowAlign] = useState(true);
    const [showWidth, setShowWidth] = useState(true);

    // DIALOGS
    const [showColors, setShowColors] = useState(false);
    const [colorID, setColorID] = useState(0);
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showProducts, setShowProducts] = useState(false);

    // VALUES
    const [enabled, setEnabled] = useState(true);
    const [typ, setTyp] = useState(props.typ);
    const [backgroundTyp, setBackgroundTyp] = useState(0);
    const [backgroundColor1, setBackgroundColor1] = useState('#00000000');
    const [backgroundColor2, setBackgroundColor2] = useState('#000000');
    const [maxWidth, setMaxWidth] = useState(0);
    const [paddingTop, setPaddingTop] = useState(props.typ == 8 ? 0 : 20);
    const [paddingBottom, setPaddingBottom] = useState(props.typ == 8 ? 0 : 10);
    const [borderRadius, setBorderRadius] = useState(0);
    const [label, setLabel] = useState('');
    const [text, setText] = useState('');
    const [link, setLink] = useState('');
    const [html, setHtml] = useState('');
    const [hAlign, setHAlign] = useState(1);
    const [vAlign, setVAlign] = useState(0);
    const [labelSize, setLabelSize] = useState(28);
    const [textSize, setTextSize] = useState(props.typ == 8 ? 1 : props.typ == 9 ? 25 : 16);
    const [labelColor, setLabelColor] = useState('#000000');
    const [textColor, setTextColor] = useState('#000000');
    const [labelStyle, setLabelStyle] = useState(0);
    const [textStyle, setTextStyle] = useState(0);
    const [buttonText, setButtonText] = useState('');
    const [buttonSize, setButtonSize] = useState(1);
    const [buttonColor, setButtonColor] = useState('#0044AA');
    const [buttonTextColor, setButtonTextColor] = useState('#FFFFFF');
    const [buttonStyle, setButtonStyle] = useState(0);
    const [image, setImage] = useState('');
    const [imagePosition, setImagePosition] = useState(0);
    const [imageWidth, setImageWidth] = useState(props.typ == 12 ? 3 : 200);
    const [imageHeight, setImageHeight] = useState(200);
    const [galleryID, setGalleryID] = useState(0);
    const [galleryItems, setGalleryItems] = useState(false);

    const [storeData, setStoreData] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);
    const tabs = [
        { id: 0, enabled: true, label: lang.settings },
        { id: 2, enabled: template != false ? template.gallery == true ? true : false : false, label: lang.gallery },
        { id: 1, enabled: true, label: lang.web_page_item_preview },
    ];

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    const buttonHeight = 60;
    const toolbarHeight = 80;
    const titleHeight = toolbarHeight + 20;
    const tabHeight = 40;
    const barButtonSize = 34;
    const textBar = 50;
    const imageSize = 200;
    const bannerSize = 200;
    const offset = 50;

    let { func } = props;
    let color = global.themes[props.theme];
    var running = false;

    useEffect(() => {
        if (running == false) {

            let tmp = global.page_items.find(x => x.id == props.typ);
            setTemplate(tmp == undefined ? false : tmp);

            if (props.item != false) {
                db_get(props.item.id);
            }

            running = true;
        }
        return () => {
        };

    }, []);

    const db_get = async (item_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'page_item', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    item_id: item_id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                let item = props.item;

                if (item != false) {
                    setEnabled(item.enabled == false ? false : true);

                    setMaxWidth(item.max_width);
                    setBackgroundTyp(item.gradient == false ? 0 : 1);
                    setBackgroundColor1(item.background_color_1);
                    setBackgroundColor2(item.background_color_2);
                    setPaddingTop(item.padding_top);
                    setPaddingBottom(item.padding_bottom);
                    let tmp = global.page_items.find(x => x.id == item.typ);
                    setTemplate(tmp == undefined ? false : tmp);

                    setBorderRadius(item.border_radius);
                    setHAlign(item.h_align);
                    setVAlign(item.v_align);

                    setLabel(item.label);
                    setText(item.text);
                    setLink(item.link);
                    setHtml(item.html);
                    setLabelSize(item.label_size);
                    setTextSize(item.text_size);
                    setLabelColor(item.label_color);
                    setTextColor(item.text_color);
                    setLabelStyle(item.label_style);
                    setTextStyle(item.text_style);
                    setButtonText(item.button_text);
                    setButtonSize(item.button_size);
                    setButtonColor(item.button_color);
                    setButtonTextColor(item.button_text_color);
                    setButtonStyle(item.button_style);
                    setImage(item.image);
                    setImagePosition(item.image_position);
                    setImageWidth(item.width);
                    setImageHeight(item.height);
                    setGalleryID(item.gallery_id);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data, close) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'page_item_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (close == true) {
                    func(true);
                }
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_gallery = async (gallery_id, data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'gallery', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    gallery_id: gallery_id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                data.gallery_items = json.items;
                setTabIndex(1);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const SelectColor = (id) => {
        setColorID(id);
        setShowColors(true);
    }

    const ColorsResult = (typ, color) => {
        setShowColors(false);
        if (typ == true) {
            if (colorID == 0) {
                setBackgroundColor1(color);
            }
            if (colorID == 1) {
                setBackgroundColor2(color);
            }
            if (colorID == 2) {
                setLabelColor(color);
            }
            if (colorID == 3) {
                setTextColor(color);
            }
            if (colorID == 4) {
                setButtonColor(color);
            }
            if (colorID == 5) {
                setButtonTextColor(color);
            }
        }
    }

    const ChangeBackgroundTyp = (id) => {
        setBackgroundTyp(id);
    }

    const SelectImage = () => {
        setSelectPhoto(true);
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setImage(path + file);
        }
    }

    const ChangeTab = (id) => {
        if (id == 0) {
            setTabIndex(id);
        }
        if (id == 1) {
            // NAHLAD
            let data = GetJSON();
            data.gallery_items = false;
            setStoreData(data);

            if (galleryID > 0) {
                db_gallery(galleryID, data);
            } else {
                setTabIndex(id);
            }
        }
        if (id == 2) {
            // GALERIA
            setRedrawRequired(true);
            setTabIndex(id);
        }

    }

    const ProductResult = (typ, ids, items) => {

        if (typ == true && ids != false) {
            let product = items[0];
            setImage(product.image);
            setLabel(product.name);
            setText(product.description_short);

            setShowProducts(false);
        }
    }


    function GetJSON() {
        let html_ = convertToHTML(editorState.getCurrentContent());

        var data = {
            id: itemID,
            organization_id: props.organization.id,
            enabled: enabled,
            page_id: props.page_id,
            typ: typ,
            max_width: maxWidth,
            padding_top: paddingTop,
            padding_bottom: paddingBottom,
            gradient: backgroundTyp == 0 ? false : true,
            gradient_degrees: 180,
            background_color_1: backgroundColor1,
            background_color_2: backgroundColor2,
            border_radius: borderRadius,
            label: label,
            text: text,
            link: link,
            html: html_,
            h_align: hAlign,
            v_align: vAlign,
            label_size: labelSize,
            text_size: textSize,
            label_color: labelColor,
            text_color: textColor,
            label_style: labelStyle,
            text_style: textStyle,
            button_text: buttonText,
            button_size: buttonSize,
            button_color: buttonColor,
            button_text_color: buttonTextColor,
            button_style: buttonStyle,
            image: image,
            image_position: imagePosition,
            width: imageWidth,
            height: imageHeight
        }

        if (itemID == 0 && template.button == true) {
            data.uid = UniqueID();
        }
        return data;
    }

    const Save = () => {
        let data = GetJSON();
        db_update(data, true);
    }

    const GalleryResult = (typ, gallery_id) => {
        if (typ == true && gallery_id > 0) {
            var data = GetJSON();
            data.gallery_id = gallery_id;

            setGalleryID(gallery_id);
            db_update(data, false);
        }
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{template == false ? lang.web_page_item : lang[template.name]}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.Block, height: titleHeight, backgroundColor: color.back }}>
                        <div style={{ ...styles.Block, width: dialogWidth - 20, height: titleHeight }}>
                            {tabIndex == 0 || tabIndex == 2 ?
                                <div style={{ ...styles.BlockRowRaw }}>

                                    {/*template != false ?
                                    <div style={{ ...styles.Block, width: toolbarHeight, height: toolbarHeight, marginRight: 10 }}>
                                        <img src={template.image} style={{ width: '100%', height: '100%', maxWidth: toolbarHeight, maxHeight: toolbarHeight, objectFit: 'contain', borderRadius: 8 }}></img>
                                    </div>
                                    : null*/}
                                    {showBackground == true ?
                                        <Paper style={{ ...styles.BlockLeft, width: undefined, height: toolbarHeight, borderRadius: 6, paddingLeft: 10, paddingRight: 10, marginRight: 10 }}>
                                            {props.email == true ?
                                                <p style={{ ...styles.TextTiny }}><b>{lang.web_page_background}:</b></p>
                                                :
                                                <p style={{ ...styles.TextTiny }}><b>{lang.web_page_background_typ}:</b> {backgroundTyp == 0 ? lang.web_page_background_solid : lang.web_page_background_gradient}</p>
                                            }
                                            <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                                {props.email == undefined ?
                                                    <div onClick={() => ChangeBackgroundTyp(0)} style={{ ...styles.Block, width: barButtonSize, height: barButtonSize, cursor: 'pointer', backgroundColor: backgroundTyp == 0 ? color.light_red : '#00000000', borderRadius: 8 }}>
                                                        <img src={background_1} style={{ width: '100%', height: '100%', maxWidth: barButtonSize - 6, maxHeight: barButtonSize, objectFit: 'contain' }}></img>
                                                    </div>
                                                    : null}
                                                {props.email == undefined ?
                                                    <div onClick={() => ChangeBackgroundTyp(1)} style={{ ...styles.Block, width: barButtonSize, height: barButtonSize, cursor: 'pointer', backgroundColor: backgroundTyp == 1 ? color.light_red : '#00000000', borderRadius: 8 }}>
                                                        <img src={background_2} style={{ width: '100%', height: '100%', maxWidth: barButtonSize - 6, maxHeight: barButtonSize, objectFit: 'contain' }}></img>
                                                    </div>
                                                    : null}
                                                <div onClick={() => SelectColor(0)} style={{ ...styles.Block, width: barButtonSize, height: barButtonSize, cursor: 'pointer', marginLeft: props.email == true ? 10 : backgroundTyp == 0 ? 40 : 10 }}>
                                                    <Paper elevation={3} style={{ ...styles.Block, width: barButtonSize - 10, height: barButtonSize - 10, backgroundColor: backgroundColor1, borderRadius: (barButtonSize - 10) / 2 }}></Paper>
                                                </div>
                                                {backgroundTyp == 1 && props.email == undefined ?
                                                    <div style={{ ...styles.Block, width: 40, height: barButtonSize, background: 'linear-gradient(180deg, ' + backgroundColor1 + ' 0%, ' + backgroundColor2 + ' 100%)', borderRadius: 8 }}>
                                                    </div>
                                                    : null}
                                                {backgroundTyp == 1 && props.email == undefined ?
                                                    <div onClick={() => SelectColor(1)} style={{ ...styles.Block, width: barButtonSize, height: barButtonSize, cursor: 'pointer' }}>
                                                        <Paper elevation={3} style={{ ...styles.Block, width: barButtonSize - 10, height: barButtonSize - 10, backgroundColor: backgroundColor2, borderRadius: (barButtonSize - 10) / 2 }}></Paper>
                                                    </div>
                                                    : null}
                                            </div>
                                        </Paper>
                                        : null}

                                    {showPadding == true ?
                                        <Paper style={{ ...styles.BlockLeft, width: 280, height: toolbarHeight, borderRadius: 6, paddingLeft: 10, paddingRight: 10, marginRight: 10 }}>
                                            <p style={{ ...styles.TextTiny }}><b>{lang.web_page_padding}</b></p>
                                            <div style={{ ...styles.BlockRowRaw, marginTop: 4 }}>
                                                <FormSelect value={paddingTop} items={global.page_paddings} field={'id'} editing={true} title={lang.web_page_padding_top} column1={50} column2={75} width={75} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => setPaddingTop(id)} />
                                                <FormSelect value={paddingBottom} items={global.page_paddings} field={'id'} editing={true} title={lang.web_page_padding_bottom} column1={60} column2={75} width={75} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => setPaddingBottom(id)} />
                                            </div>
                                        </Paper>
                                        : null}

                                    {showWidth == true && props.email == undefined ?
                                        <Paper style={{ ...styles.BlockLeft, width: 85, height: toolbarHeight, borderRadius: 6, paddingLeft: 10, paddingRight: 10, marginRight: 10 }}>
                                            <p style={{ ...styles.TextTiny }}><b>{lang.web_page_max_width}</b></p>
                                            <div style={{ ...styles.Block, marginTop: 4 }}>
                                                <FormSelect zero={lang.web_page_max_width_zero} value={maxWidth} items={global.page_max_width} field={'id'} editing={true} column2={85} width={85} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => setMaxWidth(id)} />
                                            </div>
                                        </Paper>
                                        : null}

                                    {showBorder == true ?
                                        <Paper style={{ ...styles.BlockLeft, width: 85, height: toolbarHeight, borderRadius: 6, paddingLeft: 10, paddingRight: 10, marginRight: 10 }}>
                                            <p style={{ ...styles.TextTiny }}><b>{lang.web_page_border_radius}</b></p>
                                            <div style={{ ...styles.Block, marginTop: 4 }}>
                                                <FormSelect value={borderRadius} items={global.page_radius} field={'id'} editing={true} column2={68} width={68} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => setBorderRadius(id)} />
                                            </div>
                                        </Paper>
                                        : null}

                                    {showAlign == true ?
                                        <Paper style={{ ...styles.Block, width: 90, height: toolbarHeight, borderRadius: 6, paddingLeft: 10, paddingRight: 10, marginRight: 10 }}>
                                            <p style={{ ...styles.TextTiny }}><b>{lang.web_page_align}</b></p>
                                            <div style={{ ...styles.BlockRowRaw, marginTop: 12 }}>
                                                <IconButton onClick={() => setHAlign(0)} style={{ ...styles.ButtonIconThemed }}>
                                                    <FontAwesomeIcon style={{ width: 12, color: hAlign == 0 ? color.black : color.xxgray }} icon={faAlignLeft} />
                                                </IconButton>
                                                <IconButton onClick={() => setHAlign(1)} style={{ ...styles.ButtonIconThemed }}>
                                                    <FontAwesomeIcon style={{ width: 12, color: hAlign == 1 ? color.black : color.xxgray }} icon={faAlignCenter} />
                                                </IconButton>
                                                <IconButton onClick={() => setHAlign(2)} style={{ ...styles.ButtonIconThemed }}>
                                                    <FontAwesomeIcon style={{ width: 12, color: hAlign == 2 ? color.black : color.xxgray }} icon={faAlignRight} />
                                                </IconButton>
                                            </div>
                                        </Paper>
                                        : null}

                                    <Paper style={{ ...styles.BlockLeft, width: 130, height: toolbarHeight, borderRadius: 6, paddingLeft: 10, paddingRight: 10 }}>
                                        <p style={{ ...styles.TextTiny }}><b>{lang.web_page_item_enabled}</b></p>
                                        <div style={{ ...styles.Block, marginTop: 4 }}>
                                            <FormYesNo value={enabled} editing={true} column2={130} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                        </div>
                                    </Paper>

                                </div>
                                : null}

                            {tabIndex == 1 ?
                                <div style={{ ...styles.Block }}>
                                    <p style={{ ...styles.TextSmall }}><b>{lang.web_page_item_preview_}</b></p>
                                </div>
                                : null}

                        </div>
                    </div>

                    <div style={{ ...styles.Block, height: tabHeight, backgroundColor: color.light_gray }}>
                        <DialogTabs center value={tabIndex} items={tabs} width={dialogWidth / 3} theme={props.theme} func={(id) => ChangeTab(id)} />
                    </div>

                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - toolbarHeight - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                            {template != false ? template.product == true ?
                                /*
                                *
                                * =================================================================================================================
                                * PRODUKT - VYBER PRODUKTU
                                * =================================================================================================================
                                *
                                */

                                <div style={{ ...styles.BlockCenter, marginTop: offset }}>
                                    <div style={{ ...styles.Block, width: '90%', paddingTop: 8, paddingBottom: 8, borderRadius: 25, backgroundColor: color.gray, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextSmall }}><b>{lang.product_select.toUpperCase()}</b></p>
                                    </div>
                                    <div style={{ ...styles.Block }}>
                                        <Button onClick={() => setShowProducts(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                            {lang.choose_}
                                        </Button>
                                    </div>
                                </div>
                                : null : null}

                            {template != false ? template.img == true ?
                                /*
                                *
                                * =================================================================================================================
                                * IMAGE
                                * =================================================================================================================
                                *
                                */

                                <div style={{ ...styles.BlockCenter, marginTop: offset }}>
                                    <div style={{ ...styles.Block, width: '90%', paddingTop: 8, paddingBottom: 8, borderRadius: 25, backgroundColor: color.gray, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextSmall }}><b>{lang.web_page_item_image.toUpperCase()}</b></p>
                                    </div>
                                    <div style={{ ...styles.Block }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            {typ == 6 ?
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <div style={{ ...styles.Block, width: undefined, height: 50 }}>
                                                        <Tooltip title={lang.web_page_image_position_left}>
                                                            <IconButton onClick={() => setImagePosition(0)} style={{ ...styles.ButtonIconThemed }}>
                                                                <FontAwesomeIcon style={{ width: 12, color: imagePosition == 0 ? color.black : color.xxgray }} icon={faArrowLeft} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: undefined, height: 50 }}>
                                                        <Tooltip title={lang.web_page_image_position_right}>
                                                            <IconButton onClick={() => setImagePosition(2)} style={{ ...styles.ButtonIconThemed }}>
                                                                <FontAwesomeIcon style={{ width: 12, color: imagePosition == 2 ? color.black : color.xxgray }} icon={faArrowRight} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                : null}
                                            <div style={{ ...styles.Block, width: undefined, height: 50 }}>
                                                <FormSelect value={imageWidth} items={global.image_size} field={'id'} editing={true} title={lang.web_page_item_image_size} column1={80} column2={80} width={80} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => { setImageWidth(id); setImageHeight(id); }} />
                                            </div>
                                        </div>
                                        <div style={{ ...styles.Block, width: typ == 4 ? undefined : imageSize, height: imageSize, backgroundColor: image == '' ? color.light_gray : undefined }}>
                                            <img src={image == '' ? image_default : GetImageLink(image)} style={{ width: '100%', height: '100%', maxWidth: typ == 4 ? undefined : imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                        </div>
                                        <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                            <Button onClick={() => SelectImage()} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                                {lang.image_choose}
                                            </Button>
                                            {image != '' ?
                                                <IconButton onClick={() => setImage('')} style={{ ...styles.ButtonIcon, marginLeft: 20 }}>
                                                    <FontAwesomeIcon style={{ width: 14, color: color.dark_gray }} icon={faXmark} />
                                                </IconButton>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null : null}

                            {template != false ? template.product_img == true ?
                                /*
                                *
                                * =================================================================================================================
                                * OBRAZOK PRODUKTU
                                * =================================================================================================================
                                *
                                */

                                <div style={{ ...styles.BlockCenter, marginTop: offset }}>
                                    <div style={{ ...styles.Block, width: '90%', paddingTop: 8, paddingBottom: 8, borderRadius: 25, backgroundColor: color.gray, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextSmall }}><b>{lang.web_page_item_image.toUpperCase()}</b></p>
                                    </div>
                                    <div style={{ ...styles.Block }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.Block, width: undefined, height: 50 }}>
                                                <FormSelect value={imageWidth} items={global.image_size} field={'id'} editing={true} title={lang.web_page_item_image_size} column1={80} column2={80} width={80} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => { setImageWidth(id); setImageHeight(id); }} />
                                            </div>
                                        </div>
                                        <div style={{ ...styles.Block, width: typ == 4 ? undefined : imageSize, height: imageSize, backgroundColor: image == '' ? color.light_gray : undefined }}>
                                            <img src={image == '' ? image_default : GetImageLink(image)} style={{ width: '100%', height: '100%', maxWidth: typ == 4 ? undefined : imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                        </div>
                                    </div>
                                </div>
                                : null : null}

                            {template != false ? template.label == true ?
                                /*
                                *
                                * =================================================================================================================
                                * NADPIS
                                * =================================================================================================================
                                *
                                */

                                <div style={{ ...styles.BlockCenter, width: '90%', marginTop: offset }}>
                                    <div style={{ ...styles.Block, paddingTop: 8, paddingBottom: 8, borderRadius: 25, backgroundColor: color.gray, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextSmall }}><b>{lang.web_page_item_title.toUpperCase()}</b></p>
                                    </div>
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, width: '30%', height: textBar }}>
                                            <p style={{ ...styles.TextXSmall, marginLeft: 5, color: color.dark_gray }}>{lang.web_page_item_title}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '70%' }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                                    <IconButton onClick={() => setLabelStyle(0)} style={{ ...styles.ButtonIconThemed }}>
                                                        <FontAwesomeIcon style={{ width: 16, color: labelStyle == 0 ? color.black : color.xxgray }} icon={faT} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                                    <IconButton onClick={() => setLabelStyle(1)} style={{ ...styles.ButtonIconThemed }}>
                                                        <FontAwesomeIcon style={{ width: 16, color: labelStyle == 1 ? color.black : color.xxgray }} icon={faBold} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                                    <IconButton onClick={() => setLabelStyle(2)} style={{ ...styles.ButtonIconThemed }}>
                                                        <FontAwesomeIcon style={{ width: 16, color: labelStyle == 2 ? color.black : color.xxgray }} icon={faItalic} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                                    <FormSelect value={labelSize} items={global.page_font_size} field={'id'} editing={true} title={lang.web_page_item_font} column1={120} column2={80} width={80} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => setLabelSize(id)} />
                                                </div>
                                                <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 30 }}>
                                                    <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.web_page_item_color}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 10 }}>
                                                    <Paper onClick={() => SelectColor(2)} elevation={3} style={{ ...styles.Block, width: barButtonSize - 10, height: barButtonSize - 10, backgroundColor: labelColor, borderRadius: (barButtonSize - 10) / 2, cursor: 'pointer' }}></Paper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <TextInput redraw maxLength={100} renabled={true} lang={lang} value={label} func={(txt) => setLabel(txt)} />
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.web_page_item_label_max}</p>
                                </div>
                                : null : null}


                            {template != false ? template.text == true || template.text_short == true ?
                                /*
                                *
                                * =================================================================================================================
                                * TEXT / TEXT SHORT
                                * =================================================================================================================
                                *
                                */

                                <div style={{ ...styles.BlockCenter, width: '90%', marginTop: offset }}>
                                    <div style={{ ...styles.Block, paddingTop: 8, paddingBottom: 8, borderRadius: 25, backgroundColor: color.gray, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextSmall }}><b>{lang.web_page_item_text.toUpperCase()}</b></p>
                                    </div>
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, width: '30%', height: textBar }}>
                                            <p style={{ ...styles.TextXSmall, marginLeft: 5, color: color.dark_gray }}>{template.text_short == true ? lang.web_page_item_text_short : lang.web_page_item_text}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '70%' }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                                    <IconButton onClick={() => setTextStyle(0)} style={{ ...styles.ButtonIconThemed }}>
                                                        <FontAwesomeIcon style={{ width: 16, color: textStyle == 0 ? color.black : color.xxgray }} icon={faT} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                                    <IconButton onClick={() => setTextStyle(1)} style={{ ...styles.ButtonIconThemed }}>
                                                        <FontAwesomeIcon style={{ width: 16, color: textStyle == 1 ? color.black : color.xxgray }} icon={faBold} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                                    <IconButton onClick={() => setTextStyle(2)} style={{ ...styles.ButtonIconThemed }}>
                                                        <FontAwesomeIcon style={{ width: 16, color: textStyle == 2 ? color.black : color.xxgray }} icon={faItalic} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                                    <FormSelect value={textSize} items={global.page_font_size} field={'id'} editing={true} title={lang.web_page_item_font} column1={120} column2={80} width={80} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => setTextSize(id)} />
                                                </div>
                                                <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 30 }}>
                                                    <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.web_page_item_color}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 10 }}>
                                                    <Paper onClick={() => SelectColor(3)} elevation={3} style={{ ...styles.Block, width: barButtonSize - 10, height: barButtonSize - 10, backgroundColor: textColor, borderRadius: (barButtonSize - 10) / 2, cursor: 'pointer' }}></Paper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <TextInput redraw maxLength={template.text_short == true ? 50 : 1000} rows={template.text_short == true ? 1 : 5} enabled={true} lang={lang} value={text} func={(txt) => setText(txt)} />
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{template.text_short == true ? lang.web_page_item_text_short_max : lang.web_page_item_text_max}</p>
                                </div>
                                : null : null}

                            {template != false ? template.line == true ?
                                /*
                                *
                                * =================================================================================================================
                                * ODDELUJUCA CIARA
                                * =================================================================================================================
                                *
                                */

                                <div style={{ ...styles.BlockCenter, width: '90%', marginTop: offset }}>
                                    <div style={{ ...styles.Block, paddingTop: 8, paddingBottom: 8, borderRadius: 25, backgroundColor: color.gray, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextSmall }}><b>{lang.web_page_item_line.toUpperCase()}</b></p>
                                    </div>
                                    <div style={{ ...styles.Block }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                                <FormSelect value={textSize} items={global.line_size} field={'id'} editing={true} title={lang.web_page_item_thickness} column1={120} column2={80} width={80} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => setTextSize(id)} />
                                            </div>
                                            <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 30 }}>
                                                <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.web_page_item_color}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 10 }}>
                                                <Paper onClick={() => SelectColor(3)} elevation={3} style={{ ...styles.Block, width: barButtonSize - 10, height: barButtonSize - 10, backgroundColor: textColor, borderRadius: (barButtonSize - 10) / 2, cursor: 'pointer' }}></Paper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null : null}

                            {template != false ? template.spacer == true ?
                                /*
                                *
                                * =================================================================================================================
                                * SPACER
                                * =================================================================================================================
                                *
                                */

                                <div style={{ ...styles.BlockCenter, width: '90%', marginTop: offset }}>
                                    <div style={{ ...styles.Block, paddingTop: 8, paddingBottom: 8, borderRadius: 25, backgroundColor: color.gray, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextSmall }}><b>{lang.web_page_item_space.toUpperCase()}</b></p>
                                    </div>
                                    <div style={{ ...styles.Block }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                                <FormSelect value={textSize} items={global.page_spacer} field={'id'} editing={true} title={lang.web_page_item_spacer_height} column1={120} column2={80} width={80} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => setTextSize(id)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null : null}

                            {template != false ? template.html == true ?
                                /*
                                *
                                * =================================================================================================================
                                * HTML TEXT
                                * =================================================================================================================
                                *
                                */

                                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - toolbarHeight - tabHeight - buttonHeight, backgroundColor: color.back }}>
                                    <div style={{ ...styles.Block, width: '98%', paddingTop: 8, paddingBottom: 8, borderRadius: 25, backgroundColor: color.gray, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextSmall }}><b>{lang.web_page_item_html.toUpperCase()}</b></p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: dialogWidth - 40, height: textBar }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                                <FormSelect value={textSize} items={global.page_font_size} field={'id'} editing={true} title={lang.web_page_item_font} column1={120} column2={80} width={80} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => setTextSize(id)} />
                                            </div>
                                            <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 30 }}>
                                                <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.web_page_item_button_color_2}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 10 }}>
                                                <Paper onClick={() => SelectColor(3)} elevation={3} style={{ ...styles.Block, width: barButtonSize - 10, height: barButtonSize - 10, backgroundColor: textColor, borderRadius: (barButtonSize - 10) / 2, cursor: 'pointer' }}></Paper>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        ...styles.BlockCenter, width: dialogWidth - 40, backgroundColor: color.back

                                    }}>
                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={setEditorState}
                                            defaultContentState={contentState}
                                            onContentStateChange={setContentState}

                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"

                                            editorStyle={{ backgroundColor: color.white, height: dialogHeight - global.widow_bar_height - toolbarHeight - tabHeight - buttonHeight - 260, border: '1px solid #D0D0D0' }}
                                            toolbarStyle={{ backgroundColor: color.gray, width: dialogWidth - 40 }}

                                            toolbar={{
                                                options: ['inline', 'blockType', 'list', 'history'],
                                                inline: { inDropdown: false },
                                                list: { inDropdown: false },
                                                textAlign: { inDropdown: false },
                                                history: { inDropdown: false },
                                                inline: {
                                                    inDropdown: false,
                                                    options: ['bold', 'italic', 'underline'],
                                                },
                                                blockType: {
                                                    inDropdown: false,
                                                    options: ['Normal', 'H1', 'H2', 'H3'],
                                                },
                                                list: {
                                                    inDropdown: false,
                                                    options: ['unordered', 'ordered'],
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                : null : null}


                            {template != false ? template.banner == true ?
                                /*
                                *
                                * =================================================================================================================
                                * BANNER
                                * =================================================================================================================
                                *
                                */

                                <div style={{ ...styles.BlockCenter, marginTop: offset }}>
                                    <div style={{ ...styles.Block, width: '96%', paddingTop: 8, paddingBottom: 8, borderRadius: 25, backgroundColor: color.gray, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextSmall }}><b>{lang.web_page_item_banner.toUpperCase()}</b></p>
                                    </div>
                                    <div style={{ ...styles.Block, width: '96%', height: imageSize, backgroundColor: image == '' ? color.light_gray : undefined }}>
                                        <img src={image == '' ? image_default : GetImageLink(image)} style={{ width: '100%', height: '100%', maxHeight: imageSize, objectFit: 'contain' }}></img>
                                    </div>
                                    <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                        <Button onClick={() => SelectImage()} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                            {lang.image_choose}
                                        </Button>
                                        {image != '' ?
                                            <IconButton onClick={() => setImage('')} style={{ ...styles.ButtonIcon, marginLeft: 20 }}>
                                                <FontAwesomeIcon style={{ width: 14, color: color.dark_gray }} icon={faXmark} />
                                            </IconButton>
                                            : null}
                                    </div>
                                </div>
                                : null : null}

                            {template != false ? template.button == true ?
                                /*
                                *
                                * =================================================================================================================
                                * BUTTON - LINK
                                * =================================================================================================================
                                *
                                */

                                <div style={{ ...styles.BlockCenter, width: '90%', marginTop: offset }}>
                                    <div style={{ ...styles.Block, paddingTop: 8, paddingBottom: 8, borderRadius: 25, backgroundColor: color.gray, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextSmall }}><b>{lang.web_page_item_link.toUpperCase()}</b></p>
                                    </div>
                                    <div style={{ ...styles.Block }}>

                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                                <p style={{ ...styles.TextXSmall, marginLeft: 5, color: color.dark_gray }}>{lang.web_page_item_button_text}</p>
                                            </div>
                                            {props.email == undefined ?
                                                <>
                                                    <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 90 }}>
                                                        <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.web_page_item_button_typ}:</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 5 }}>
                                                        <IconButton onClick={() => setButtonStyle(0)} style={{ ...styles.ButtonIcon }}>
                                                            <FontAwesomeIcon style={{ width: 16, color: buttonStyle == 0 ? color.black : color.xxgray }} icon={fa1} />
                                                        </IconButton>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 5 }}>
                                                        <IconButton onClick={() => setButtonStyle(1)} style={{ ...styles.ButtonIcon }}>
                                                            <FontAwesomeIcon style={{ width: 16, color: buttonStyle == 1 ? color.black : color.xxgray }} icon={fa2} />
                                                        </IconButton>
                                                    </div>
                                                </>
                                                : null}
                                            <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 20 }}>
                                                <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.web_page_item_button_color_1}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 5 }}>
                                                <Paper onClick={() => SelectColor(4)} elevation={3} style={{ ...styles.Block, width: barButtonSize - 10, height: barButtonSize - 10, backgroundColor: buttonColor, borderRadius: (barButtonSize - 10) / 2, cursor: 'pointer' }}></Paper>
                                            </div>
                                            <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 20 }}>
                                                <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.web_page_item_button_color_2}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 5 }}>
                                                <Paper onClick={() => SelectColor(5)} elevation={3} style={{ ...styles.Block, width: barButtonSize - 10, height: barButtonSize - 10, backgroundColor: buttonTextColor, borderRadius: (barButtonSize - 10) / 2, cursor: 'pointer' }}></Paper>
                                            </div>
                                        </div>
                                        <TextInput redraw center maxLength={50} enabled={true} lang={lang} value={buttonText} width={540} func={(txt) => setButtonText(txt)} />

                                        <p style={{ ...styles.TextXSmall, marginLeft: 5, color: color.dark_gray, marginTop: 20 }}>{lang.web_page_item_button}</p>
                                        <TextInput redraw maxLength={255} enabled={true} lang={lang} value={link} func={(txt) => setLink(txt)} />
                                    </div>
                                </div>
                                : null : null}

                            {template != false ? template.info == true ?
                                /*
                                *
                                * =================================================================================================================
                                * TIP / UPOZORNENIE
                                * =================================================================================================================
                                *
                                */

                                <div style={{ ...styles.BlockCenter, width: '90%', marginTop: offset }}>
                                    <div style={{ ...styles.Block, paddingTop: 8, paddingBottom: 8, borderRadius: 25, backgroundColor: color.gray, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextSmall }}><b>{lang.web_page_tips.toUpperCase()}</b></p>
                                    </div>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.Block, width: undefined, height: textBar }}>
                                            <FormSelect value={imageWidth} items={global.page_borders} field={'id'} editing={true} title={lang.web_page_item_tip_width} column1={120} column2={80} width={80} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => setImageWidth(id)} />
                                        </div>
                                        <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 30 }}>
                                            <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.web_page_item_tip_color_1}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 10 }}>
                                            <Paper onClick={() => SelectColor(4)} elevation={3} style={{ ...styles.Block, width: barButtonSize - 10, height: barButtonSize - 10, backgroundColor: buttonColor, borderRadius: (barButtonSize - 10) / 2, cursor: 'pointer' }}></Paper>
                                        </div>
                                        <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 30 }}>
                                            <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.web_page_item_tip_color_2}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: undefined, height: textBar, marginLeft: 10 }}>
                                            <Paper onClick={() => SelectColor(5)} elevation={3} style={{ ...styles.Block, width: barButtonSize - 10, height: barButtonSize - 10, backgroundColor: buttonTextColor, borderRadius: (barButtonSize - 10) / 2, cursor: 'pointer' }}></Paper>
                                        </div>
                                    </div>
                                </div>
                                : null : null}

                            {template != false ? template.gallery == true ?
                                /*
                                *
                                * =================================================================================================================
                                * GALERIA - velkost obrázkov
                                * =================================================================================================================
                                *
                                */

                                <div style={{ ...styles.BlockCenter, marginTop: offset }}>
                                    <div style={{ ...styles.Block, width: '90%', paddingTop: 8, paddingBottom: 8, borderRadius: 25, backgroundColor: color.gray, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextSmall }}><b>{lang.web_page_gallery.toUpperCase()}</b></p>
                                    </div>
                                    <div style={{ ...styles.Block }}>
                                        <FormSelect value={imageWidth} items={global.image_size} field={'id'} editing={true} title={lang.web_page_gallery_size} column1={200} column2={80} width={80} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => { setImageWidth(id); setImageHeight(id); }} />
                                    </div>
                                </div>
                                : null : null}

                            <FormSpace height={150} />

                        </div>
                        : null}

                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.widow_bar_height - toolbarHeight - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <FormSpace />
                            <PageItemRender design data={storeData} width={dialogWidth} theme={props.theme} />
                        </div>
                        : null}

                    {tabIndex == 2 ?
                        <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.widow_bar_height - toolbarHeight - tabHeight - buttonHeight - 30 }}>
                            <Gallery permited={permited} galleryID={galleryID} galleryTyp={1} width={dialogWidth} height={dialogHeight - global.widow_bar_height - toolbarHeight - tabHeight - buttonHeight - 30} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={GalleryResult.bind(this)} />
                        </div>
                        : null}

                </div >

                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        {tabIndex == 2 ? null :
                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                        }
                        <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, marginLeft: 20 }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper >

            {showProducts == true ?
                <ProductSelect hide_multiple filters only_enabled only_sku={true} country_id={props.locale.id} showVariations={true} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductResult.bind()} />
                : null}

            {selectPhoto == true ?
                <Photos full={typ == 5 || typ == 4 ? true : undefined} remote={props.remote} remoteMobile={props.remoteMobile} autoSelect typ={global.image.news} max_size={typ == 5 || typ == 4 ? 2500 : 600} width={typ == 5 || typ == 4 ? undefined : 600} height={typ == 5 || typ == 4 ? undefined : 600} title={lang.photo_select} organization={props.organization} locale={props.locale} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                : null}

            {showColors == true ?
                <DialogColors remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={ColorsResult.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

        </Backdrop >
    );
}

export const DialogColors = (props) => {

    /*
 
        const [showColors, setShowColors] = useState(false);
 
        {showColors == true ?
            <DialogColors remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token}  func={ColorsResult.bind(this)} />
        : null}
 
        const ColorsResult = (typ, color) => {
            setShowColors(false);
            if (typ == true) {
                Debug(color);
            }
        }
 
    */
    const background_1 = require('./react/app/page_background_1.png');
    const background_2 = require('./react/app/page_background_2.png');

    const lang = props.lang;
    const radius = 20;

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);


    const [overID, setOverID] = useState(-1);
    const [value, setValue] = useState(0);
    const [valueR, setValueR] = useState(0);
    const [valueG, setValueG] = useState(0);
    const [valueB, setValueB] = useState(0);
    const [rgb, setRGB] = useState('#000000');
    const [paletteID, setPaletteID] = useState(0);
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    const tabs = [
        { id: 0, enabled: true, label: lang.web_page_pallette },
        { id: 1, enabled: true, label: lang.web_page_pallette_saved },
    ];


    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 480;
    const dialogHeight = 620;
    const colorSize = 40;
    const bottomHeight = 180;
    const tabHeight = 40;

    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);

    const db_get = async (category_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'colors', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    category_id: category_id
                })
            })

            const json = await response.json();
            Debug('COLORS');
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'color_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                //setShowOK(true);
                db_get(paletteID);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(false);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 1) {
            setItemSelected(false);
            db_get(paletteID);
        }
    }

    function GetRGB(r, g, b) {
        r = Math.max(0, Math.min(255, r));
        g = Math.max(0, Math.min(255, g));
        b = Math.max(0, Math.min(255, b));

        const toHex = (byte) => byte.toString(16).padStart(2, '0');

        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }

    function hexToRgb(hex) {
        // Remove the hash symbol if it's there
        hex = hex.replace(/^#/, '');

        // Parse the red, green, and blue components
        let bigint = parseInt(hex, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;

        setValueR(r);
        setValueG(g);
        setValueB(b);
    }

    const ChangeR = (event, newValue) => {
        setValueR(newValue);
        let rgb_color = GetRGB(newValue, valueG, valueB)
        setRGB(rgb_color);
        setValue(rgb_color)
    };

    const ChangeG = (event, newValue) => {
        setValueG(newValue);
        let rgb_color = GetRGB(valueR, newValue, valueB);
        setRGB(rgb_color);
        setValue(rgb_color)
    };

    const ChangeB = (event, newValue) => {
        setValueB(newValue);
        let rgb_color = GetRGB(valueR, valueG, newValue);
        setRGB(rgb_color);
        setValue(rgb_color)
    };

    const ColorDelete = () => {
        let data = {
            id: itemSelected.id,
            enabled: false,
        }
        setItemSelected(false);
        db_update(data);
    }

    const Save = () => {
        let data = {
            id: 0,
            organization_id: props.organization.id,
            user_id: props.user.id,
            category_id: paletteID,
            enabled: true,
            name: '',
            color: rgb
        }

        db_update(data);
    }

    const ChangeValue = (txt) => {
        var color = txt;
        if (txt[0] != '#') {
            color = '#' + txt;
        }

        setValue(txt);
        setRGB(color);
        hexToRgb(color);
    }

    const ChangeCategory = (id) => {
        setPaletteID(id);
        db_get(id);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 30000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.web_page_color_select}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{
                    ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - bottomHeight, backgroundColor: tabIndex == 0 ? color.dark_gray : color.back
                }}>
                    <div style={{ ...styles.Block, height: tabHeight, backgroundColor: color.back }}>
                        <DialogTabs center value={tabIndex} items={tabs} width={dialogWidth / 2} theme={props.theme} func={(id) => ChangeTab(id)} />
                    </div>

                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - bottomHeight - tabHeight, overflowY: 'hidden', position: 'relative' }}>
                                {global.colors.map((item, index) => (
                                    <Paper elevation={2} onClick={() => func(true, item.color)}
                                        key={item.id}
                                        style={{
                                            ...styles.Block, position: 'absolute', left: (index % 12) * colorSize, top: 20 + (parseInt(index / 12)) * colorSize * 1.5, width: colorSize, height: colorSize * 3, backgroundColor: item.color, borderTopLeftRadius: radius, borderTopRightRadius: radius,
                                            transform: `translate(${0}px, ${overID == item.id ? -15 : 0}px)`,
                                            transition: 'transform 0.25s ease-in-out'
                                        }}

                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                    </Paper>
                                ))}
                            </div>
                        </div>
                        : null}

                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockCenter, justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <FormSelect all={lang.web_page_pallette_category_my} permited value={paletteID} items={[]} field={'name'} codebook={global.codebook.palette} editing={true} title={lang.web_page_pallette_category} width={'100%'} column1={100} column2={dialogWidth - 100 - 40} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => ChangeCategory(id)} />
                            <div style={{ ...styles.BlockCenter, marginTop: 6, backgroundColor: color.white, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - bottomHeight - tabHeight - 50 - 50, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div style={{ ...styles.BlockRowRaw, flexWrap: 'wrap', marginTop: 4 }}>
                                    {items != false ?
                                        items.map((item, index) => (
                                            <div onClick={() => { setItemSelected(item); setRGB(item.color); hexToRgb(item.color); setValue(item.color); }} style={{ ...styles.Block, width: colorSize, height: colorSize, margin: 3, border: color.border, borderRadius: 5 }}>
                                                <div style={{ ...styles.Block, width: colorSize - 4, height: colorSize - 4, backgroundColor: item.color }}></div>
                                            </div>
                                        ))
                                        : null}
                                </div>
                            </div>
                            <div style={{ ...styles.Block, height: 50 }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {itemSelected != false ?
                                        <div style={{ ...styles.Block, width: 60, height: 50 }}>
                                            <DeleteButton text={lang.web_page_pallette_delete + '?'} icon={faTrash} color={color.dark_gray} icon_color={color.dark_gray} tooltip={lang.web_page_pallette_delete} theme={props.theme} lang={props.lang} func={() => ColorDelete()} />
                                        </div>
                                        : null}
                                    <div style={{ ...styles.Block, width: undefined, height: 50 }}>
                                        <Tooltip title={lang.web_page_pallette_save_}>
                                            <IconButton onClick={() => Save()} style={{ ...styles.ButtonIcon, borderColor: color.dark_gray, color: color.dark_gray }}>
                                                <FontAwesomeIcon style={{ height: 16, color: color.dark_gray }} icon={faSave} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                        </div>
                        : null}

                </div>

                <div style={{ ...styles.Block, height: bottomHeight, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, backgroundColor: tabIndex == 0 ? color.dark_gray : color.dark_gray }}>

                    <div style={{ ...styles.BlockCenter }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div style={{ ...styles.Block, height: 110, width: 120 }}>
                                <div onClick={() => func(true, rgb)} style={{ ...styles.Block, height: 50, width: 110, backgroundColor: rgb, borderRadius: 10, cursor: 'pointer' }}></div>
                                <FormText placeholder={'#000000'} value={value} editing={true} title={lang.colors_own} title_color={color.white} column2={100} theme={props.theme} lang={props.lang} radius={props.radius} func={(txt) => ChangeValue(txt)} />
                            </div>
                            <div style={{ ...styles.Block, height: 110, width: dialogWidth - 120 - 20 }}>
                                <Slider min={0} max={255} value={valueR} style={{ color: color.light_red, width: '90%' }} onChange={ChangeR} />
                                <Slider min={0} max={255} value={valueG} style={{ color: color.light_green, width: '90%' }} onChange={ChangeG} />
                                <Slider min={0} max={255} value={valueB} style={{ color: color.light_blue, width: '90%' }} onChange={ChangeB} />
                            </div>
                        </div>
                        {tabIndex == 0 ?
                            <div style={{ ...styles.Block, height: bottomHeight - 110 }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.Block, width: undefined, height: bottomHeight - 110 }}>
                                        <Button onClick={() => func(true, '#00000000')} style={{ ...styles.ButtonOutlined, borderColor: color.white, color: color.white }}>{lang.colors_none}</Button>
                                    </div>
                                    {/*
                                            <div style={{ ...styles.Block, width: undefined, height: 50 }}>
                                                <FormText placeholder={'#000000'} value={value} editing={true} title={lang.colors_own} title_color={color.white} column1={100} column2={110} theme={props.theme} lang={props.lang} radius={props.radius} func={(txt) => setValue(txt)} />
                                            </div>
                                            */}
                                    <div style={{ ...styles.Block, width: undefined, height: bottomHeight - 110, marginLeft: 20 }}>
                                        {value != '' ?
                                            <Button onClick={() => func(true, value[0] == '#' ? value : '#' + value)} style={{ ...styles.ButtonOutlined, borderColor: color.white, color: color.white }}>
                                                {lang.ok}
                                            </Button>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            : null}

                        {tabIndex == 1 ?
                            <div style={{ ...styles.Block, height: bottomHeight - 110 }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {itemSelected != false ?
                                        <div style={{ ...styles.Block, width: undefined, height: bottomHeight - 110, marginLeft: 20 }}>
                                            <Button onClick={() => func(true, rgb)} style={{ ...styles.ButtonOutlined, borderColor: color.white, color: color.white }}>{lang.ok}</Button>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            : null}
                    </div>


                </div>
            </Paper>

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}


export const PageItemRender = (props) => {

    /*
 
    */
    const no_image = require('./react/app/no_image.jpg');
    const data = props.data;
    const gallerySize = data.width;
    //const lang = props.lang;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let color = global.themes[props.theme];

    useEffect(() => {
        Debug(props.data);
        return () => {
        };

    }, []);

    const DivideText = (txt) => {
        var result = txt.split('\n');

        return result;
    }

    const RenderText = (props) => {
        let data = props.data;

        return (
            DivideText(data.text).map((item, index) => (
                item == '' ?
                    <div key={index} style={{ ...styles.Block, height: data.text_size }}></div>
                    :
                    <p key={index} style={{ ...styles.TextTiny, fontSize: data.text_size, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', color: data.text_color, fontWeight: data.text_style == 1 ? '600' : '400', fontStyle: data.text_style == 2 ? 'italic' : undefined }}>{item}</p>
            ))
        )
    }

    return (
        <div style={{
            ...styles.Block,
            paddingTop: data.padding_top,
            paddingBottom: data.padding_bottom,
            borderRadius: data.border_radius,
            background: data.gradient == false ? 'linear-gradient(180deg, ' + data.background_color_1 + ' 0%, ' + data.background_color_1 + ' 100%)' : 'linear-gradient(180deg, ' + data.background_color_1 + ' 0%, ' + data.background_color_2 + ' 100%)'
        }}>
            <div style={{
                ...styles.Block,
                width: data.max_width == 0 ? data.typ == 5 ? '100%' : '96%' : data.max_width,
                maxWidth: data.max_width == 0 ? undefined : data.max_width,
                alignItems: data.h_align == 0 ? 'flex-start' : data.h_align == 1 ? 'center' : 'flex-end',
            }}>
                {data.typ == 1 ?
                    // *********************************************************************************************************
                    // LABEL
                    // *********************************************************************************************************
                    <p style={{ ...styles.TextTiny, fontSize: data.label_size, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', color: data.label_color, fontWeight: data.label_style == 1 ? '600' : '400', fontStyle: data.label_style == 2 ? 'italic' : undefined }}>{data.label}</p>
                    : null}

                {data.typ == 2 ?
                    // *********************************************************************************************************
                    // TEXT
                    // *********************************************************************************************************
                    <RenderText data={data} />
                    : null}

                {data.typ == 3 ?
                    // *********************************************************************************************************
                    // HTML
                    // *********************************************************************************************************
                    <div style={{ ...styles.TextSmall, color: data.text_color, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', fontSize: data.text_size }} dangerouslySetInnerHTML={{ __html: data.html }} />
                    : null}


                {data.typ == 4 ?
                    // *********************************************************************************************************
                    // IMAGE
                    // *********************************************************************************************************
                    <>
                        <img src={data.image == '' ? no_image : GetImageLink(data.image)} style={{ width: '100%', height: '100%', maxWidth: data.width, maxHeight: data.height, objectFit: 'contain', borderRadius: data.border_radius }}></img>
                        {data.text != '' ?
                            <>
                                <FormSpace height={3} />
                                <RenderText data={data} />
                            </>
                            : null}
                    </>
                    : null}

                {data.typ == 5 ?
                    // *********************************************************************************************************
                    // BANNER
                    // *********************************************************************************************************
                    <img src={data.image == '' ? no_image : GetImageLink(data.image)} style={{ width: '100%', height: '100%', /*maxHeight: data.height, */objectFit: 'contain' }}></img>
                    : null}

                {data.typ == 6 ?
                    // *********************************************************************************************************
                    // OBRAZOK + TEXT
                    // *********************************************************************************************************
                    <div style={{ ...styles.BlockRow, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {data.image_position == 0 ?
                            <div style={{ ...styles.BlockLeft, width: data.width + 10 }}>
                                <img src={data.image == '' ? no_image : GetImageLink(data.image)} style={{ width: '100%', height: '100%', maxWidth: data.width, maxHeight: data.height, objectFit: 'contain', borderRadius: data.border_radius }}></img>
                            </div>
                            : null}
                        <div style={{ ...styles.Block, alignItems: data.h_align == 0 ? 'flex-start' : data.h_align == 1 ? 'center' : 'flex-end', width: props.width - data.width - 20, marginLeft: 10, marginRight: 10, justifyContent: 'center', display: 'flex' }}>
                            {data.label != '' ?
                                <p style={{ ...styles.TextTiny, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', marginBottom: 10, fontSize: data.label_size, color: data.label_color, fontWeight: data.label_style == 1 ? '600' : '400', fontStyle: data.label_style == 2 ? 'italic' : undefined }}>{data.label}</p>
                                : null}
                            {data.text != '' ?
                                <RenderText data={data} />
                                : null}
                            {data.button_text != '' ?
                                <a href={props.design == true || data.link == '' ? undefined : data.link} target='_blank' onClick={() => Debug()} style={{ ...data.button_style == 0 ? styles.ButtonThemed : styles.ButtonOutlined, textDecoration: 'none', minWidth: 160, width: undefined, backgroundColor: data.button_style == 0 ? data.button_color : undefined, borderColor: data.button_style == 1 ? data.button_color : undefined, color: data.button_text_color, marginTop: 20 }}>
                                    <div style={{ ...styles.Block, height: 34 }}>
                                        {data.button_text}
                                    </div>
                                </a>
                                : null}
                        </div>
                        {data.image_position == 2 ?
                            <div style={{ ...styles.BlockRight, width: data.width + 10 }}>
                                <img src={data.image == '' ? no_image : GetImageLink(data.image)} style={{ width: '100%', height: '100%', maxWidth: data.width, maxHeight: data.height, objectFit: 'contain' }}></img>
                            </div>
                            : null}
                    </div>
                    : null}

                {data.typ == 8 ?
                    // *********************************************************************************************************
                    // CIARA
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block, minHeight: data.text_size, backgroundColor: data.text_color, marginTop: 10, marginBottom: 10 }}></div>
                    : null}

                {data.typ == 9 ?
                    // *********************************************************************************************************
                    // MEDZERA
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block, minHeight: data.text_size }}></div>
                    : null}

                {data.typ == 10 ?
                    // *********************************************************************************************************
                    // ODKAZ
                    // *********************************************************************************************************
                    <a href={props.design == true || data.link == '' ? undefined : data.link} target='_blank' onClick={() => Debug()} style={{ ...data.button_style == 0 ? styles.ButtonThemed : styles.ButtonOutlined, textDecoration: 'none', minWidth: 160, width: undefined, backgroundColor: data.button_style == 0 ? data.button_color : undefined, borderColor: data.button_style == 1 ? data.button_color : undefined, color: data.button_text_color, marginTop: 20 }}>
                        <div style={{ ...styles.Block, height: 34 }}>
                            {data.button_text}
                        </div>
                    </a>
                    : null}

                {data.typ == 11 ?
                    // *********************************************************************************************************
                    // PRODUKT
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.BlockLeft, width: data.width + 10 }}>
                            <img src={data.image == '' ? no_image : GetImageLink(data.image)} style={{ width: '100%', height: '100%', maxWidth: data.width, maxHeight: data.height, objectFit: 'contain', borderRadius: data.border_radius }}></img>
                        </div>
                        <div style={{ ...styles.Block, marginTop: 10, alignItems: data.h_align == 0 ? 'flex-start' : data.h_align == 1 ? 'center' : 'flex-end' }}>
                            {data.label != '' ?
                                <p style={{ ...styles.TextTiny, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', marginBottom: 6, fontSize: data.label_size, color: data.label_color, fontWeight: data.label_style == 1 ? '600' : '400', fontStyle: data.label_style == 2 ? 'italic' : undefined }}>{data.label}</p>
                                : null}
                            {data.text != '' ?
                                <RenderText data={data} />
                                : null}
                            {data.button_text != '' ?
                                <a href={props.design == true || data.link == '' ? undefined : data.link} target='_blank' onClick={() => Debug()} style={{ ...data.button_style == 0 ? styles.ButtonThemed : styles.ButtonOutlined, textDecoration: 'none', minWidth: 160, width: undefined, backgroundColor: data.button_style == 0 ? data.button_color : undefined, borderColor: data.button_style == 1 ? data.button_color : undefined, color: data.button_text_color, marginTop: 20 }}>
                                    <div style={{ ...styles.Block, height: 34 }}>
                                        {data.button_text}
                                    </div>
                                </a>
                                : null}
                        </div>
                    </div>
                    : null}

                {data.typ == 12 ?
                    // *********************************************************************************************************
                    // TIP / UPOZORNENIE
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block, borderLeft: data.width + 'px solid ' + data.button_text_color, backgroundColor: data.button_color, paddingTop: 10, paddingBottom: 10, borderRadius: data.border_radius }}>
                        <div style={{ ...styles.Block, alignItems: data.h_align == 0 ? 'flex-start' : data.h_align == 1 ? 'center' : 'flex-end' }}>
                            {data.label != '' ?
                                <p style={{ ...styles.TextTiny, marginLeft: 10, marginRight: 10, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', marginBottom: 6, fontSize: data.label_size, color: data.label_color, fontWeight: data.label_style == 1 ? '600' : '400', fontStyle: data.label_style == 2 ? 'italic' : undefined }}>{data.label}</p>
                                : null}
                            {data.text != '' ?
                                <div style={{ marginLeft: 10, marginRight: 10, marginTop: 16 }}>
                                    <RenderText data={data} />
                                </div>
                                : null}
                        </div>
                    </div>
                    : null}

                {data.typ == 13 ?
                    // *********************************************************************************************************
                    // GALERIA
                    // *********************************************************************************************************
                    <>
                        {data.label != '' ?
                            <p style={{ ...styles.TextTiny, marginLeft: 10, marginRight: 10, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', marginBottom: 6, fontSize: data.label_size, color: data.label_color, fontWeight: data.label_style == 1 ? '600' : '400', fontStyle: data.label_style == 2 ? 'italic' : undefined }}>{data.label}</p>
                            : null}
                        {data.text != '' ?
                            <div style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                                <RenderText data={data} />
                            </div>
                            : null}
                        <div style={{ ...styles.Block, marginTop: 10, justifyContent: data.h_align == 0 ? 'flex-start' : data.h_align == 1 ? 'center' : 'flex-end', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {data.gallery_items != false && data.gallery_items != undefined ?
                                data.gallery_items.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={1}
                                        style={{
                                            ...styles.Block,
                                            width: gallerySize,
                                            minHeight: gallerySize,
                                            backgroundColor: color.white,
                                            borderRadius: global.items_radius,
                                            cursor: 'pointer',
                                            margin: 6,
                                        }}
                                    //onClick={() => Preview(item)}
                                    >
                                        <div style={{ ...styles.Block }}>
                                            <Avatar picture image={item.link} size={gallerySize - 16} theme={props.theme} />
                                        </div>

                                    </Paper>
                                ))
                                : null}
                        </div>
                    </>
                    : null}

            </div>
        </div >
    );
}
