/*
*
* =================================================================================================================
* NASTAVENIE MODULOV

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <SettingsModules organization={organization} user={user} settings={props.settings} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate, json } from "react-router-dom";
import { DialogDates, FormButton, FormError, FormLabel, FormNote, FormSelect, FormSpace, FormText, FormYesNo, Line, Loading, ShowError, ShowOK } from './items';
import { styles } from './styles';
import { Button, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { GetTodayYear } from './functions_date';
import { Debug, IsEmpty } from './functions';
import { SettingsNumberFormat } from './settings_number_format';

export const SettingsModules = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let permissions = global.permissions;
    let color = global.themes[props.theme];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [storeData, setStoreData] = useState(false);
    const [pattern, setPattern] = useState('');
    const [patternID, setPatternID] = useState(0);

    const [defaultPattern, setDefaultPattern] = useState('');
    const [smsPattern, setSMSPattern] = useState(false);
    const [productPattern, setProductPattern] = useState(false);
    const [adjustmentPattern, setAdjustmentPattern] = useState(false);
    const [transportPattern, setTransportPattern] = useState(false);
    const [ordersPattern, setOrdersPattern] = useState(false);
    const [stockRemovalsPattern, setStockRemovalsPattern] = useState(false);
    const [stockOrdersPattern, setStockOrdersPattern] = useState(false);
    const [stockOrdersReceivedPattern, setStockOrdersReceivedPattern] = useState(false);
    const [reklamaciePattern, setReklamaciePattern] = useState(false);

    // DIALOGS
    const [showEditor, setShowEditor] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const column1 = 240;
    const column2 = 310;
    const offset = 20;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings_numbers', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var item = json.settings;
                if (item == false) {
                    item = global.settings_numbers;
                }
                setStoreData(item);
                // DATA
                AssignData(item);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings_numbers_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setDefaultPattern(JSON.parse(item.default_pattern));
        setSMSPattern(item.sms_pattern != false ? JSON.parse(item.sms_pattern) : false);
        setProductPattern(item.product_pattern != false ? JSON.parse(item.product_pattern) : false);
        setAdjustmentPattern(item.adjustment_pattern != false ? JSON.parse(item.adjustment_pattern) : false);
        setTransportPattern(item.transport_pattern != false ? JSON.parse(item.transport_pattern) : false);
        setOrdersPattern(item.orders_pattern != false ? JSON.parse(item.orders_pattern) : false);
        setStockRemovalsPattern(item.stock_removals_pattern != false ? JSON.parse(item.stock_removals_pattern) : false);
        setStockOrdersPattern(item.stock_orders_pattern != false ? JSON.parse(item.stock_orders_pattern) : false);
        setStockOrdersReceivedPattern(item.stock_orders_received_pattern != false ? JSON.parse(item.stock_orders_received_pattern) : false);
        setReklamaciePattern(item.reklamacie_pattern != false ? JSON.parse(item.reklamacie_pattern) : false);
    }

    const FormLabelResult = (value) => {
        if (value == 0) {
            setEditing(true);
        }
    }

    const EditPattern = (id, pattern_) => {
        setPatternID(id);
        setPattern(pattern_);
        setShowEditor(true);
    }

    const NumberResult = (typ, json) => {
        setShowEditor(false);
        var data = { id: 0, organization_id: props.organization.id, user_id: props.user.id };

        if (typ == true) {
            if (patternID == 0) {
                // DEFAULT PATTERN
                data.default_pattern = JSON.stringify(json);
                setDefaultPattern(json);
            }
            if (patternID == 1) {
                // SMS KUPA KREDITU PATTERN
                data.sms_pattern = JSON.stringify(json);
                setSMSPattern(json);
            }

            if (patternID == 2) {
                // CISLOVANIE PRODUKTOV
                data.product_pattern = JSON.stringify(json);
                setProductPattern(json);
            }

            if (patternID == 3) {
                // CISLOVANIE PRODUKTOV
                data.adjustment_pattern = JSON.stringify(json);
                setAdjustmentPattern(json);
            }

            if (patternID == 4) {
                // PRESUN SKLADU PRODUKTOV
                data.transport_pattern = JSON.stringify(json);
                setTransportPattern(json);
            }

            if (patternID == 5) {
                // OBJEDNAVKA PRODUKTOV
                data.orders_pattern = JSON.stringify(json);
                setOrdersPattern(json);
            }

            if (patternID == 6) {
                // VYSKLADNENIE PRODUKTOV
                data.stock_removals_pattern = JSON.stringify(json);
                setStockRemovalsPattern(json);
            }


            if (patternID == 7) {
                // OBJEDNAVKY NA SKLAD
                data.stock_orders_pattern = JSON.stringify(json);
                setStockOrdersPattern(json);
            }


            if (patternID == 8) {
                // PRIJATE OBJEDNAVKY TOVARU NA SKLAD
                data.stock_orders_received_pattern = JSON.stringify(json);
                setStockOrdersReceivedPattern(json);
            }

            if (patternID == 9) {
                // REKLAMACIE OBJEDNAVOK
                data.reklamacie_pattern = JSON.stringify(json);
                setReklamaciePattern(json);
            }

            db_update(data);
        }
    }

    return (
        <div style={{ ...styles.BlockCenter, backgroundColor: color.back, paddingTop: offset, paddingBottom: offset }}>

            {storeData != false ?
                <Paper elevation={global.elevation_form} style={{ ...styles.BlockCenter, width: column1 + column2 + (2 * offset), paddingTop: offset, paddingBottom: offset * 2, backgroundColor: color.white, borderRadius: props.radius }}>

                    <FormLabel editing={false} icon={faInfoCircle} editButton={false} title={lang.modules_numbering} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                    <FormButton value={defaultPattern.example} editing={false} title={lang.modules_numbering_default} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => EditPattern(0, defaultPattern)} />

                    <FormSpace />
                    <FormButton value={smsPattern == false ? '' : smsPattern.example} editing={false} title={lang.modules_sms_pattern} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => EditPattern(1, smsPattern)} />
                    <FormButton value={productPattern == false ? '' : productPattern.example} editing={false} title={lang.modules_product_pattern} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => EditPattern(2, productPattern)} />
                    <FormButton value={adjustmentPattern == false ? '' : adjustmentPattern.example} editing={false} title={lang.stock_adjustment_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => EditPattern(3, adjustmentPattern)} />
                    <FormButton value={transportPattern == false ? '' : transportPattern.example} editing={false} title={lang.stock_transport} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => EditPattern(4, transportPattern)} />
                    <FormButton value={ordersPattern == false ? '' : ordersPattern.example} editing={false} title={lang.orders} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => EditPattern(5, ordersPattern)} />
                    <FormButton value={stockRemovalsPattern == false ? '' : stockRemovalsPattern.example} editing={false} title={lang.stock_removals} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => EditPattern(6, stockRemovalsPattern)} />
                    <FormButton value={stockOrdersPattern == false ? '' : stockOrdersPattern.example} editing={false} title={lang.stock_orders} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => EditPattern(7, stockOrdersPattern)} />
                    <FormButton value={stockOrdersReceivedPattern == false ? '' : stockOrdersReceivedPattern.example} editing={false} title={lang.stock_orders_received_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => EditPattern(8, stockOrdersReceivedPattern)} />
                    <FormButton value={reklamaciePattern == false ? '' : reklamaciePattern.example} editing={false} title={lang.reklamacie_orders} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => EditPattern(9, reklamaciePattern)} />

                </Paper>
                : null}

            {props.remote == true && props.remoteMobile == true ?
                <FormSpace height={500} />
                : null}

            {showEditor == true ?
                <SettingsNumberFormat item={pattern} organization={props.organization} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={NumberResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}
        </div>

    );
}


