/*
*
* =================================================================================================================

    Blog

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    public  -> verejný blog

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { EmptyList, FormSpace, FormText, Icon, Loading, ShowError, ShowOK, TextInput, FormYesNo, FormError, TextLine, FormSelect, SelectInput, MenuButton, FormNote, Line, FormNodes } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, Pagination, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faAt, faFileImport, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, GetLeftPosition, GetPages, IsEmpty } from './functions';

export const NewsletterEmails = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'newsletter';

    let defaultFilter = {
        enabled: true,
        category_id: 0,
        country_id: props.locale.id
    }


    // MENU
    const menuItems = [
        { id: 1, enabled: true, label: lang.newsletter_email_customers_import },
        //{ id: 2, enabled: true, label: lang.export_all }
    ];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST    
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // VALUES
    const [categories, setCategories] = useState(false);
    const [categoryID, setCategoryID] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showCustomersImport, setShowCustomersImport] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 60;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            setTabIndex(0);
            db_get(0, defaultFilter);

            running = true;
        }

    }, [props.locale.id]);


    const db_get = async (page, filter) => {
        Debug('FILTER');
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'newsletter_emails', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));
                setCategories(json.categories);

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GetFilter = () => {
        var filter_ = defaultFilter;

        if (tabIndex == 0) {
            filter_.published = true;
        } else {
            filter_.published = false;
        }
        filter_.category_id = categoryID;

        return filter_;
    }

    const Search = (txt) => {
        setSearch(txt);
        var filter_ = GetFilter();

        if (txt.length > 1) {
            filter_.search = txt;
            db_get(0, filter_);
        }
        if (txt == '') {
            delete (filter_.search);
            db_get(0, filter_);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }


    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw_) => {
        setShowDialog(false);

        if (redraw_ == true) {
            db_get(page, filter);
        }
    }

    const CustomersImportResult = (redraw_) => {
        setShowCustomersImport(false);

        if (redraw_ == true) {
            db_get(page, filter);
        }
    }

    const CategoryChange = (id) => {
        var filter_ = GetFilter();
        filter_.category_id = id;

        setCategoryID(id);

        db_get(0, filter_);
    }

    const MenuResult = (id, index, item) => {
        if (item.id == 1) {
            setShowCustomersImport(true);
        }
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faAt} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <MenuButton id={0} menuItems={menuItems} theme={props.theme} func={MenuResult.bind()} />
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }} >
                        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                            <div style={{ ...styles.Block, width: '96%' }}>
                                <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray }}>
                                    <div style={{ ...styles.BlockLeft, width: '33%', height: menuHeight }}>
                                        <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '33%', height: menuHeight }}>
                                        {categories != false ?
                                            <SelectInput all={lang.newsletter_emails_category_all} theme={props.theme} enabled={true} lang={lang} value={categoryID} items={categories} field={'name'} radius={global.radius} func={(txt) => CategoryChange(txt)} />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '34%', height: menuHeight }}>
                                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add }}>
                                                <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                {lang.newsletter_email_new}
                                            </Button>
                                            : null}
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - 6, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '96%' }}>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '55%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, marginBottom: 2, color: color.dark_gray }}>{item.category}</p>
                                                        <TextLine text={item.email} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '45%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <TextLine text={item.name} fontSize={global.font_xsmall} color={color.black} />
                                                    </div>
                                                </div>
                                            </Paper>
                                        )) :
                                            <EmptyList row small lang={lang} />
                                        }

                                        {/* PAGINATION */}
                                        {pages > 1 ?
                                            <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            </div>
                                            : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {showCustomersImport == true ?
                <NewsletterCustomersImport remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} permissions={props.permissions} func={CustomersImportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <NewsletterEmail item={itemSelected} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} permissions={props.permissions} func={DialogResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


export const NewsletterEmail = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'blog_public';

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');
    const background_1 = require('./react/app/page_background_1.png');
    const background_2 = require('./react/app/page_background_2.png');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [redrawRequired, setRedrawRequired] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');
    const [categoryID, setcategoryID] = useState(0);
    const [email, setEmail] = useState('');

    const [storeData, setStoreData] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 420 ? 420 : height;

    // CONSTANTS
    const buttonHeight = 80;
    const errorHeight = 40;
    const column1 = 180;
    const column2 = dialogWidth - column1 - 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                var item = props.item;

                setStoreData(item);

                setEnabled(item.enabled == false ? false : true);
                setcategoryID(item.category_id);
                setName(item.name);
                setEmail(item.email);

            }

            running = true;
        }

    }, []);


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'newsletter_email_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.error == false) {
                    func(true);
                } else {
                    setError(lang.newsletter_email_error);
                }
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }


    const Save = () => {
        setError('');

        var err = 0;
        var error_text = lang.required_red;
        err += IsEmpty(email) ? 1 : 0;
        err += parseInt(categoryID) > 0 ? 0 : 1;

        if (err == 0) {
            let data = {
                id: itemID,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                enabled: enabled,
                category_id: categoryID,
                name: name,
                email: email
            }

            Debug(data);
            db_update(data);
        } else {
            setError(error_text);
        }
    }



    const CheckChanges = () => {

        var change = 0;
        if (storeData == false) {
            change = false
        }
        else {
            change += storeData.enabled != enabled ? 1 : 0;
            change += storeData.name != name ? 1 : 0;
            change += storeData.email != email ? 1 : 0;
            change += storeData.category_id != categoryID ? 1 : 0;
        }

        return change > 0 ? true : false;
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faAt} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{itemID == 0 ? lang.newsletter_email_new : lang.newsletter_email}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height }}>
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block }}>

                                <FormSpace />
                                <FormYesNo value={enabled} editing={true} title={lang.newsletter_email_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                <FormSpace />
                                <FormSelect require value={categoryID == 0 ? -1 : categoryID} items={false} field={'name'} codebook={global.codebook.newsletter_emails} editing={true} title={lang.newsletter_emails_category} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setcategoryID(id)} />
                                <FormSpace />
                                <FormText redraw value={name} editing={true} title={lang.name_surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                <FormText require redraw value={email} editing={true} title={lang.email_address} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />

                            </div>
                        </div>

                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {itemID == 0 || CheckChanges() == true ?
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20, width: 145 }}>{lang.save}</Button>
                                    : null}
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 145 }}>{lang.close}</Button>
                            </div>
                        </div>
                    </div>

                </div>
            </Paper >

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}

export const NewsletterCustomersImport = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'blog_public';

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [redrawRequired, setRedrawRequired] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // RESULT
    const [imported, setImported] = useState(false);
    const [countSum, setCountSum] = useState(0);
    const [countImported, setCountImported] = useState(0);

    // DATA LIST
    const [enabled, setEnabled] = useState(true);
    const [categoryID, setcategoryID] = useState(0);
    const [categories, setCategories] = useState(false);
    const [customerCategoryID, setCustomerCategoryID] = useState(0);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 640 ? 640 : width;
    const dialogHeight = height >= 520 ? 520 : height;

    // CONSTANTS
    const buttonHeight = 80;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = dialogWidth - column1 - 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            db_data();

            running = true;
        }

    }, []);

    const db_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setCategories(json.categories);
            }

            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_import = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'newsletter_email_import', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    country_id: props.locale.id,
                    enabled: enabled,
                    category_id: categoryID,
                    customer_category_id: customerCategoryID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setCountSum(json.sumar_count);
                setCountImported(json.imported);
                setImported(true);
                setRedrawRequired(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }


    const Save = () => {
        var err = 0;
        setError('');

        err += parseInt(categoryID) > 0 ? 0 : 1;
        err += parseInt(customerCategoryID) > 0 ? 0 : 1;

        if (err == 0) {
            db_import();
        } else {
            setError(lang.required_red);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faAt} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.newsletter_email_import}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {imported == false ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height }}>

                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div style={{ ...styles.Block }}>
                                    <FormSpace height={10} />
                                    <p style={{ ...styles.TextXSmall, textAlign: 'center' }}><b>{lang.newsletter_email_customers_import_text}</b></p>
                                    <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 2 }}>{lang.newsletter_email_customers_import_text_}</p>
                                    <FormSpace height={40} />
                                    <p style={{ ...styles.TextXSmall, textAlign: 'center' }}><b>{lang.batch_selection_type_selected_customers}</b></p>
                                    <FormSpace />
                                    <FormYesNo value={enabled} editing={true} title={lang.newsletter_email_import_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                    <FormNodes require value={customerCategoryID} items={categories} editing={true} title={lang.newsletter_customers_category} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCustomerCategoryID(id)} />
                                    <FormSpace />

                                    <div style={{ ...styles.BlockCenter, height: 40 }}>
                                        <FontAwesomeIcon style={{ height: 28, color: color.black }} icon={faArrowDown} />
                                    </div>

                                    <FormSpace />
                                    <FormSelect require value={categoryID == 0 ? -1 : categoryID} items={false} field={'name'} codebook={global.codebook.newsletter_emails} editing={true} title={lang.newsletter_to_email_category} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setcategoryID(id)} />
                                    <FormNote title={lang.newsletter_emails_category_import_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                </div>
                            </div>

                            <div style={{ ...styles.Block, height: errorHeight }}>
                                <FormError small margin={0} error={error} theme={props.theme} />
                            </div>
                            <div style={{ ...styles.Block, height: buttonHeight }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20, width: 145 }}>{lang.import}</Button>
                                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 145 }}>{lang.close}</Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height }}>

                            <div style={{ ...styles.Block, height: dialogHeight - global.widow_bar_height - buttonHeight - errorHeight }} >
                                <FontAwesomeIcon style={{ height: 40, color: color.xxgray }} icon={faFileImport} />
                                <p style={{ ...styles.TextSmall, textAlign: 'center', marginTop: 20 }}><b>{lang.newsletter_email_imported}</b></p>
                                <FormSpace />
                                <Line color={'#D0D0D0'} theme={props.theme} />
                                <FormSpace />
                                <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 0 }}>{lang.newsletter_email_imported_sum}: <b>{countSum}</b></p>
                                <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 4 }}>{lang.newsletter_email_imported_count}: <b>{countImported}</b></p>
                            </div>

                            <div style={{ ...styles.Block, height: errorHeight }}>
                                <FormError small margin={0} error={error} theme={props.theme} />
                            </div>
                            <div style={{ ...styles.Block, height: buttonHeight }}>
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 145 }}>{lang.close}</Button>
                            </div>
                        </div>
                    }
                </div>
            </Paper >

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}
