/*
*
* =================================================================================================================
* DODAVATELIA

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormNodes, TextLine, FormSwitch, FormSelection, FormNote } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, IconButton, Pagination, Paper, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faBook, faDollar, faDollarSign, faFolderTree, faInfoCircle, faLayerGroup, faLocationDot, faPlusCircle, faQuestion, faTruck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, ConvertToPrice, Debug, FormatMoney, GetLeftPosition, GetPages, IsEmpty } from './functions';
import { Photos } from './photos';
import { getGridFilter } from '@mui/x-data-grid/internals';
import { NodeSelect } from './nodes_select';

export const Services = (props) => {

    let module_id = global.model.services;
    let typ = props.typ;
    let currency = props.locale.currency;
    let precisions = props.system.precisions == 0 ? 2 : 3;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'services';

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        typ: props.typ,
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.service_name, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.service_name, default: true },
        { id: 2, enabled: true, checked: false, column: 'code', label: lang.service_code },
    ]);

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [category, setCategory] = useState('');
    const [categoryID, setCategoryID] = useState(0);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showCategorySelect, setShowCategorySelect] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 86;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const filterHeight = 50;
    const filterButtons = 110;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);


    const db_get = async (page, filter, maxItems) => {
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'services', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.services,
                    filename: lang.service, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GetFilter = () => {
        var filter = defaultFilter;

        if (search != '') {
            filter.search = search;
        }
        if (categoryID > 0) {
            filter.category_id = categoryID;
        }
        return filter;
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter()

        if (txt.length > 1) {
            filter.search = txt;
            setSearch(txt);
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            setSearch('');
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_services_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const ChangeEnabled = (item, value) => {
        // ENABLED POLOZKY ZMENENY

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: item.id,
            action: value == true ? global.logs.enabled : global.logs.disabled
        };

        let data = {
            id: item.id,
            enabled: value,
            logs: log
        }
        db_update(data);
    }

    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';
        var filter_ = GetFilter();

        switch (id) {

            case 1: // NAZOV
                column = 'name';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;

            case 3: // PRICE
                column = 'price';
                break;
        }

        filter_.ordering = order;
        filter_.order_column = column;
        db_get(0, filter_, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const CategoryResult = (typ, item) => {
        setShowCategorySelect(false);

        if (typ == true) {
            setCategory(item.label);
            setCategoryID(item.id);

            var filter = GetFilter();
            filter.category_id = item.id;
            db_get(0, filter, maxItems);
        }
    }

    const ResetCategory = () => {
        var filter = GetFilter();
        delete (filter.category_id);
        setCategoryID(0);
        db_get(0, filter, maxItems);

    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    {global.appstore == false ?
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                        : null}
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%', marginTop: 10 }}>
                    <div style={{ ...styles.Block, width: '96%' }}>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.BlockRow, width: '82%' }}>
                                <div style={{ ...styles.BlockLeft, width: 270, height: menuHeight }}>
                                    <TextInput redraw search theme={props.theme} width={250} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                </div>
                                <div style={{ ...styles.Block, width: filterButtons, height: menuHeight }}>
                                    <Button onClick={() => setShowCategorySelect(true)} style={{ ...styles.ButtonOutlined, width: filterButtons, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faFolderTree} />
                                        {lang.product_category}
                                    </Button>
                                </div>
                            </div>

                            <div style={{ ...styles.BlockRight, width: 200, height: menuHeight }}>
                                {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                    <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                        {lang.service_new}
                                    </Button>
                                    : null}
                            </div>
                        </div>

                        <div style={{ ...styles.BlockLeft, height: filterHeight }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {search != '' ?
                                    <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{search}</p>} onDelete={() => Search('')}></Chip>
                                    : null}
                                {categoryID > 0 ?
                                    <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{category}</p>} onDelete={() => ResetCategory()}></Chip>
                                    : null}
                            </div>
                        </div>

                        {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}

                        <div style={{ ...styles.Block, marginBottom: 10, border: color.border }}>
                            <div style={{ ...styles.BlockRow, width: '99%' }}>
                                <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center' }}>
                                    {/* SELECT */}
                                    <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                        <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRow, width: '90%' }}>
                                    {/* DATA */}
                                    <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                    </div>
                                    <div style={{ ...styles.BlockRow, width: '55%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.service}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                            <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                        </div>
                                    </div>
                                    <div style={{ ...styles.Block, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.service_category}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRow, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.price}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                            <MenuButton id={3} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRow, width: 100, borderLeft: color.border }}>
                                    {/* ENABLED */}
                                    <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.enabled}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                        <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - filterHeight - tabHeight - 28, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                            <div id={'id_services_start'} style={{ ...styles.Block }}></div>
                            {/* ****************************************************************************************************************
                                        ITEMS
                                **************************************************************************************************************** */}

                            {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                                        <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                            {/* SELECT */}
                                            <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                        </div>

                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '85%' }}>
                                            {/* DATA */}
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                <Avatar picture image={item.image} size={rowHeight - 20} theme={props.theme} />
                                            </div>

                                            <div style={{ ...styles.BlockLeft, width: '60%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                <TextLine text={item.name} fontSize={global.font_tiny} fontWeight={'600'} color={color.black} />
                                                {item.code != '' ?
                                                    <p style={{ ...styles.TextTiny, marginTop: 3, color: color.dark_gray }}>{item.code}</p>
                                                    : null}
                                                {item.text != '' ?
                                                    <TextLine text={item.text} fontSize={global.font_tiny} color={color.black} />
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                {item.category != '' ?
                                                    <Chip variant='outlined' size='small' style={{ borderColor: color.dark_gray, height: 18 }} label={<p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.category}</p>}></Chip>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny }}>{FormatMoney(item.price, precisions)} {currency}</p>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.Block, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                            {/* ENABLED */}
                                            {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                <SwitchEnable enabled={item.enabled == false ? false : true} theme={props.theme} func={(value) => ChangeEnabled(item, value)} />
                                                : null}
                                            {item.enabled == false ?
                                                <div style={{ ...styles.Block, height: 20 }}>
                                                    <p style={{ ...styles.TextXXTiny, color: color.dark_gray }}>{lang.disabled}</p>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* ****************************************************************************************************************
                                        PAGINATION
                                **************************************************************************************************************** */}

                            <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                <div style={{ ...styles.Block, width: 200 }}></div>
                                <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                    {pages > 1 ?
                                        <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                        : null}
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                            <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                            <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {showCategorySelect == true ?
                <ServiceCategorySelect module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CategoryResult.bind()} />
                : null}

            {showExport == true ?
                <DialogExport remote={props.remote} remoteMobile={props.remoteMobile} rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <Service typ={typ} remote={props.remote} remoteMobile={props.remoteMobile} module_id={module_id} item={itemSelected} organization={props.organization} settings={props.settings} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}



/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const Service = (props) => {

    let module_id = global.model.services;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let currency = props.locale.currency;
    let precisions = props.system.precisions == 0 ? 2 : 3;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [needSave, setNeedSave] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [enabled, setEnabled] = useState(true);
    const [code, setCode] = useState('');
    const [categoryID, setCategoryID] = useState(0);
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [cost, setCost] = useState('');
    const [price, setPrice] = useState('');
    const [dph, setDPH] = useState(-1);
    const [image, setImage] = useState('');
    const [categories, setCategories] = useState(false);

    const [priceFreeEnabled, setPriceFreeEnabled] = useState(false);
    const [priceFree, setPriceFree] = useState(0);
    const [B2B, setB2B] = useState(false);
    const [B2C, setB2C] = useState(false);
    const [needAddress, setNeedAddress] = useState(false);
    const [payments, setPayments] = useState(false);
    const [paymentID, setPaymentID] = useState([]);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = props.item == false ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 150;
    const column2 = 400;

    const tabs = [
        { id: 0, enabled: true, label: lang.setting },
        { id: 1, enabled: true, label: lang.history },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }
            if (props.typ == 0) {
                db_data();
            }
            if (props.typ == 1) {
                db_payments();
            }
            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'service', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id
                })
            })

            const json = await response.json();
            Debug('SERVICE', 'RED');
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'services_category_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setCategories(json.categories);
            }

            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_payments = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'services_payments', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setPayments(json.items);
            }

            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setCode(item.code);
        setCategoryID(item.category_id);
        setImage(item.image);
        setName(item.name);
        setText(item.text);
        setCost(item.cost);
        setPrice(item.price);
        setDPH(item.dph);
        setPriceFreeEnabled(item.price_free_enabled == false ? false : true);
        setPriceFree(item.price_free);
        setB2B(item.b2b == false ? false : true);
        setB2C(item.b2c == false ? false : true);
        setNeedAddress(item.need_address == false ? false : true);
        setPaymentID(item.json == false ? [] : JSON.parse(item.json));
        Debug(item);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.enabled = enabled;
        storeData.code = code;
        storeData.category_id = categoryID;
        storeData.image = image;
        storeData.name = name;
        storeData.text = text;
        storeData.cost = cost;
        storeData.price = price;
        storeData.dph = dph;
        storeData.price_free_enabled = priceFreeEnabled;
        storeData.price_free = priceFree;
        storeData.b2b = B2B;
        storeData.b2c = B2C;
        storeData.need_address = needAddress;
    }

    const Save = () => {
        setError('');
        var error = false;
        error += IsEmpty(name) == true ? 1 : 0;

        if (error == 0) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: props.item == false ? 0 : props.item.id,
                action: props.item == false ? global.logs.created : storeData.enabled != enabled ? enabled == true ? global.logs.enabled : global.logs.disabled : global.logs.modified
            };

            let data = {
                id: props.item == false ? 0 : props.item.id,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                enabled: enabled,
                typ: props.typ,
                code: code.trim(),
                category_id: categoryID,
                image: image,
                name: name.trim(),
                text: text,
                cost: ConvertToPrice(cost, precisions),
                price: ConvertToPrice(price, precisions),
                dph: dph,
                price_free_enabled: priceFreeEnabled,
                price_free: priceFree,
                b2b: B2B,
                b2c: B2C,
                need_address: needAddress,
                json: paymentID != false ? JSON.stringify(paymentID) : false,
                logs: log
            };

            StoreEditing();

            Debug(data);
            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ShowOKPress = () => {
        func(true);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 1) {
            if (logs == false) {
                db_get_logs();
            }
        }
    }


    const AvatarPress = () => {
        setSelectPhoto(true);
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setImage(path + file);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? props.typ == 0 ? lang.service_new : props.typ == 1 ? lang.service_new_trade : lang.service_new_payment : lang.service}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    {props.item != false ?
                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs value={tabIndex} items={tabs} width={dialogWidth / 2} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {storeData != false ?
                                <div>
                                    <FormSpace />
                                    <div style={{ ...styles.Block }}>
                                        <Avatar editable={editing} picture image={image} size={75} theme={props.theme} func={AvatarPress.bind()} />
                                        <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.default_image}</p>
                                    </div>
                                    <Line marginTop={10} theme={props.theme} />
                                    <FormSpace />
                                    <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                    <FormYesNo value={enabled} editing={editing} title={lang.service_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setEnabled(value); setNeedSave(true); }} />
                                    {props.typ != 0 ?
                                        <>
                                            <FormSwitch value1={B2B} value2={B2C} title={props.typ == 1 ? lang.service_trade_for : lang.service_payment_for} title_1={lang.customer_b2b_} title_2={lang.customer_b2c_} editing={editing} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value1, value2) => { setB2B(value1); setB2C(value2); setNeedSave(true); }} />
                                            <FormYesNo value={needAddress} editing={editing} title={lang.service_need_address} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setNeedAddress(value); setNeedSave(true); }} />
                                            <FormNote title={lang.service_need_address_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                            <FormSpace />
                                        </>
                                        : null}

                                    <FormText require redraw value={name} editing={editing} title={props.typ == 0 ? lang.service_name : lang.service_name_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setName(txt); setNeedSave(true); }} />

                                    {props.typ == 0 ?
                                        <>
                                            <FormSpace />
                                            <FormText redraw value={code} editing={editing} title={lang.service_code} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setCode(txt); setNeedSave(true); }} />
                                            <FormNodes value={categoryID} items={categories} editing={editing} title={lang.product_category} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setCategoryID(id); setNeedSave(true); }} />
                                        </>
                                        : null}
                                    <FormSpace />
                                    {props.typ == 0 ?
                                        <FormText money currency={currency} precisions={precisions} redraw value={cost} editing={editing} title={lang.service_cost} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setCost(txt); setNeedSave(true); }} />
                                        : null}

                                    <FormText money currency={currency} precisions={precisions} redraw value={price} editing={editing} title={lang.product_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPrice(txt); setNeedSave(true); }} />
                                    <FormText numeric precisions={precisions} placeholder={lang.system_match} redraw value={dph == -1 ? editing == false ? props.system.dph : '' : dph} editing={editing} title={lang.product_dph + ' %'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setDPH(txt); setNeedSave(true); }} />
                                    <FormSpace />
                                    {props.typ == 1 ?
                                        <>
                                            <FormSelection require value={paymentID} items={payments} field={'name'} editing={editing} title={lang.service_payments} column1={column1} column2={column2} width={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setPaymentID(id); setNeedSave(true); }} />
                                            <FormNote title={lang.service_payments_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                        </>
                                        : null}

                                    {props.typ == 1 || props.typ == 2 ?
                                        <>
                                            <FormYesNo value={priceFreeEnabled} editing={editing} title={props.typ == 1 ? lang.service_trade_free : lang.service_payment_free} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setPriceFreeEnabled(value); setNeedSave(true); }} />
                                            {priceFreeEnabled == true ?
                                                <>
                                                    <FormText money currency={currency} precisions={precisions} redraw value={priceFree} editing={editing} title={lang.service_trade_free_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPriceFree(txt); setNeedSave(true); }} />
                                                    <FormNote title={props.typ == 1 ? lang.service_trade_free_price_note : lang.service_payment_free_price_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                </>
                                                : null}
                                            <FormSpace />
                                        </>
                                        : null}

                                    <FormText rows={2} redraw value={text} editing={editing} title={lang.service_text} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setText(txt); setNeedSave(true); }} />
                                    <FormSpace />

                                    {props.remote == true && props.remoteMobile == true ?
                                        <FormSpace height={500} />
                                        : null}
                                </div>
                                :
                                <Stack style={{ ...styles.Block }} spacing={1}>
                                    <FormSpace />
                                    <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                </Stack>}
                        </div>
                        : null}

                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {tabIndex == 0 && editing == true && needSave == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            {editing == true && props.item != false ?
                                <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                :
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                            }
                        </div>
                    </div>
                </div >

                {selectPhoto == true ?
                    <Photos autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}




export const ServiceSelect = (props) => {

    let module_id = global.model.services;
    let currency = props.locale.currency;
    let precisions = props.system.precisions == 0 ? 2 : 3;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'services';

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        typ: props.typ,
        excluded: props.excluded != undefined ? props.excluded.length == 0 ? undefined : props.excluded : undefined,
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];


    // SYSTEM
    const [isBusy, setBusy] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [category, setCategory] = useState('');
    const [categoryID, setCategoryID] = useState(0);
    const [typ, setTyp] = useState(props.typ);

    const [showCategorySelect, setShowCategorySelect] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 86;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const filterHeight = 50;
    const filterButtons = 110;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, []);


    const db_get = async (page, filter, maxItems) => {
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'services', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GetFilter = () => {
        var filter = defaultFilter;

        if (search != '') {
            filter.search = search;
        }
        if (categoryID > 0) {
            filter.category_id = categoryID;
        }
        filter.typ = typ;
        return filter;
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter()

        if (txt.length > 1) {
            filter.search = txt;
            setSearch(txt);
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            setSearch('');
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_services_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const SelectItem = (item) => {
        func(true, item);
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';
        var filter_ = GetFilter();

        switch (id) {

            case 1: // NAZOV
                column = 'name';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;

            case 3: // PRICE
                column = 'price';
                break;
        }

        filter_.ordering = order;
        filter_.order_column = column;
        db_get(0, filter_, maxItems);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }


    const CategoryResult = (typ, item) => {
        setShowCategorySelect(false);

        if (typ == true) {
            setCategory(item.label);
            setCategoryID(item.id);
            setTyp(0);

            var filter = GetFilter();
            filter.category_id = item.id;
            filter.typ = 0;
            db_get(0, filter, maxItems);
        }
    }

    const ResetCategory = () => {
        var filter = GetFilter();
        delete (filter.category_id);
        setCategoryID(0);
        db_get(0, filter, maxItems);

    }

    const ChangeTyp = (id) => {
        setTyp(id);
        setCategoryID(0);
        var filter = GetFilter();
        filter.typ = id;
        delete (filter.category_id);
        db_get(0, filter, maxItems);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.service_select}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%', marginTop: 10 }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: 270, height: menuHeight }}>
                                        <TextInput redraw search theme={props.theme} width={250} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                    </div>
                                    <div style={{ ...styles.Block, width: filterButtons, height: menuHeight }}>
                                        <Button onClick={() => setShowCategorySelect(true)} style={{ ...styles.ButtonOutlined, width: filterButtons, fontSize: global.font_tiny }}>
                                            <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faFolderTree} />
                                            {lang.product_category}
                                        </Button>
                                    </div>
                                    <div style={{ ...styles.Block, width: filterButtons, height: menuHeight, marginLeft: 10 }}>
                                        <Button onClick={() => ChangeTyp(global.services.trade)} style={{ ...styles.ButtonOutlined, width: filterButtons, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faTruck} />
                                            {lang.trade}
                                        </Button>
                                    </div>
                                    <div style={{ ...styles.Block, width: filterButtons, height: menuHeight, marginLeft: 10 }}>
                                        <Button onClick={() => ChangeTyp(global.services.payment)} style={{ ...styles.ButtonOutlined, width: filterButtons, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 12, marginRight: 8 }} icon={faDollar} />
                                            {lang.payments}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div style={{ ...styles.BlockLeft, height: filterHeight }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {search != '' ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginRight: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{search}</p>} onDelete={() => Search('')}></Chip>
                                        : null}
                                    {categoryID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginRight: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{category}</p>} onDelete={() => ResetCategory()}></Chip>
                                        : null}
                                    {typ > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginRight: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{typ == 1 ? lang.trade : lang.payments}</p>} onDelete={() => ChangeTyp(0)}></Chip>
                                        : null}
                                </div>
                            </div>

                            {/* ****************************************************************************************************************
                                            TABLE HEADER
                                    **************************************************************************************************************** */}

                            <div style={{ ...styles.Block, marginBottom: 10, border: color.border }}>
                                <div style={{ ...styles.BlockRow, width: '99%' }}>
                                    {/*
                                    <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center' }}>
                                        <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                            <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                        </div>
                                    </div>
                                    */}
                                    <div style={{ ...styles.BlockRow }}>
                                        {/* DATA */}
                                        <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableLabelHeight, justifyContent: 'center' }}>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: '55%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.service}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                            </div>
                                        </div>
                                        <div style={{ ...styles.Block, width: 200, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.service_category}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.price}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <MenuButton id={3} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - menuHeight - filterHeight - tabHeight - 28, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                <div id={'id_services_start'} style={{ ...styles.Block }}></div>
                                {/* ****************************************************************************************************************
                                            ITEMS
                                    **************************************************************************************************************** */}

                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowHeight,
                                            cursor: 'pointer',
                                            marginTop: 5, marginBottom: 5,
                                            paddingTop: 2, paddingBottom: 2,
                                            borderLeft: item.enabled == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                                            {/*
                                            <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />                                                
                                            </div>
                                            */}
                                            <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow }}>
                                                {/* DATA */}
                                                <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <Avatar picture image={item.image} size={rowHeight - 20} theme={props.theme} />
                                                </div>

                                                <div style={{ ...styles.BlockLeft, width: '55%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <TextLine text={item.name} fontSize={global.font_tiny} fontWeight={'600'} color={color.black} />
                                                    {item.code != '' ?
                                                        <p style={{ ...styles.TextTiny, marginTop: 3, color: color.dark_gray }}>{item.code}</p>
                                                        : null}
                                                    {item.text != '' ?
                                                        <TextLine text={item.text} fontSize={global.font_tiny} color={color.black} />
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.Block, width: 200, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {item.category != '' ?
                                                        <Chip variant='outlined' size='small' style={{ borderColor: color.dark_gray, height: 18 }} label={<p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.category}</p>}></Chip>
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny }}>{FormatMoney(item.price, precisions)} {currency}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <EmptyList row small lang={lang} />
                                }

                                {/* ****************************************************************************************************************
                                            PAGINATION
                                    **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 200 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </Paper >

            {showCategorySelect == true ?
                <ServiceCategorySelect module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CategoryResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}



export const ServiceCategorySelect = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = {
        enabled: true,
        ordering: global.order.down,
        order_column: 'priority'
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DATA
    const [value, setValue] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 700 ? 700 : height;

    const menuHeight = 60;
    const rowHeight = 40;
    const viewWidth = '96%';

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter);

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'services_category_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.categories;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter_);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = defaultFilter;

        if (txt.length > 1) {

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = ['label'];
            db_get(0, filter);
        }
        if (txt == '') {
            db_get(0, filter);
        }

    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        func(true, item)
    }

    const NodeSelectResult = (id) => {
        var tmp = items.find(x => x.id == id);
        if (tmp != undefined) {
            func(true, tmp);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.product_category}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start'} style={{ ...styles.BlockLeft, width: viewWidth }}>

                            {items != false && search == '' ?
                                <NodeSelect nodes={items} theme={props.theme} func={NodeSelectResult.bind(this)} />
                                : null}

                            {search != '' ? itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    onClick={() => SelectItem(item)}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                                        <div style={{ ...styles.BlockLeft, width: '100%', minHeight: rowHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXSmall }}>{item.label}</p>
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                                : null
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div >

                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}
