import React, { lazy, Suspense, useEffect, useState } from 'react';

import './App.css';
import './globals.js';
import './themes.js';
import './items.css';

import { Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { AppMenu, AppStatusbar, DialogContactUs, DialogDesktopSettings, DialogGDPR, DialogHelp, DialogHelpSelect, GetDefaultDesktop, Loading, MenuInfo, MenuTitle, MenuTop, ShowError, SuperAdmin } from './items';
import { styles } from './styles';

// IMAGES
import logo from './react/app/logo.png';

import { Backdrop, Box, CircularProgress, Fab, Fade, useMediaQuery } from '@mui/material';
import { Desktop } from './desktop';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { Settings } from './settings';
import { CheckPermission, CheckPlugin, Debug, GetCalendarGroups, GetLanguage, LoadLanguage, LoadLogin, LoadValue, ResetLogin, SaveLanguage, SaveValue } from './functions';
import { ConditionDesigner } from './condition_designer';
import { TemplateDialog } from './template_dialog';
import { TemplateWindows } from './template_window';
import { Vendors } from './vendors';
import { Users } from './users';
import { Test } from './Test';
import { SettingsApplication } from './settings_application';
import { TemplateForms } from './template_forms';
import { SMSCredit } from './sms_credit';
import { LogsSystem } from './logs_system';
import { SettingsOrganization } from './settings_organization';
import { MyProfile } from './settings_my_profile';
import { TemplateDialogItems } from './template_dialog_items';
import { Manufacturers } from './manufacturers';
import { Files, FilesWindow } from './files';
import { ProductCategories } from './product_categories';
import { Products } from './products';
import { ProductGroups } from './product_groups';
import { PriceLists } from './price_list';
import { Stocks } from './stocks.js';
import { ProductAdjustments } from './product_adjustment.js';
import { ConditionsList } from './conditions_list.js';
import { Warehouse } from './warehouse.js';
import { ProductTransports } from './product_transport.js';
import { Customers } from './customers.js';
import { CustomersCategories } from './customers_categories.js';
import { CustomersGroups } from './customers_groups.js';
import { ProductFlags } from './product_flags.js';
import { StockCards } from './stock_cards.js';
import { Orders, OrdersWindow } from './orders.js';
import { OrderTypes } from './order_types.js';
import { Distributors } from './distributors.js';
import { StockRemovalsWindow } from './stock_removals.js';
import { Approvals } from './approvals.js';
import { Contacts } from './contacts.js';
import { Customer, EmailsWindow } from './customer.js';
import { StockOrders } from './stock_orders.js';
import { StockOrdersReceived } from './stock_orders_receive.js';
import { CodeBooks } from './codebooks.js';
import { Reklamacie } from './reklamacie.js';
import { DialogAssistent } from './dialog_openai.js';
import { Presentations } from './presentations.js';
import { Quizes } from './quiz.js';
import { MyRoutes } from './routes.js';
import { Calendar, CalendarTermin } from './calendar.js';
import { Plans } from './plans.js';
import { Dashboard } from './dashboard.js';
import { Plugins } from './plugins.js';
import { TradePayments } from './trade_payments.js';
import { WebPageDesigner } from './webpage_designer.js';
import { Blogs } from './blog.js';
import { Newsletters } from './newsletters.js';
import { NewsletterEmails } from './newsletters_emails.js';
import { NewsletterCampaigns } from './newsletters_campaigns.js';
import { Services } from './services.js';
import { ServiceCategories } from './service_categories.js';


export const Main = (props) => {
    const navigate = useNavigate();
    let url_params = useParams();

    const logo_ai = require('./react/app/logo_ai_button.png');

    const [isBusy, setBusy] = useState(false);

    // INTERNET CONNECTION
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    // APLIKACIA
    const [appVersion, setAppVersion] = useState('');
    const [language, setLanguage] = useState(0);
    const [token, setToken] = useState('');
    const [logged, setLogged] = useState(true);
    const [menuData, setMenuData] = useState([]);
    const [menuLabel, setMenuLabel] = useState('');
    const [version, setVersion] = useState('');

    // VZHLAD APKY
    const [theme, setTheme] = useState(0);
    const [offset, setOffset] = useState(global.offset);
    const [radius, setRadius] = useState(16);

    // MENU
    const [bodyID, setBodyID] = useState(0);
    const [menuID, setMenuID] = useState(-1);
    const [actualMenu, setActualMenu] = useState(false);
    const [label, setLabel] = useState('');

    // OPENAI
    const [params, setParams] = useState(false);

    // ORGANIZACIA - USER - NASTAVENIA
    const [organization, setOrganization] = useState(false);
    const [user, setUser] = useState(false);
    const [subUsers, setSubUsers] = useState(false); // PODRIADENY užívatelia + aktuálny user
    const [precision, setPrecision] = useState(3); // Precíznosť desatiných čísel
    const [settings, setSettings] = useState(false);
    const [system, setSystem] = useState(false);
    const [permissions, setPermissions] = useState(false);
    const [newVersion, setNewVersion] = useState(false);
    const [newMobileVersion, setNewMobileVersion] = useState(false);

    // NASTAVENIA PODLA KRAJINY
    const [locale, setLocale] = useState(false); // Aktuálna krajina

    // OKNO
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // DIALOGS
    const [showFiles, setShowFiles] = useState(false);
    const [showTermin, setShowTermin] = useState(false);
    const [termin, setTermin] = useState(false);
    const [showCustomer, setShowCustomer] = useState(false);
    const [customer, setCustomer] = useState(false);

    // HELP
    const [showHelpSelect, setShowHelpSelect] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [helpLink, setHelpLink] = useState('');
    const [showContactUs, setShowContactUs] = useState(false);

    // CHYBA
    const [showError, setShowError] = useState(false);

    // REMOTE - iPAD / Desktop app
    const [remote, setRemote] = useState(url_params.remote == undefined ? false : true);
    const [remoteMobile, setRemoteMobile] = useState(url_params.remote == undefined ? false : parseInt(url_params.version) < 1000 ? true : false);
    const [remoteVersion, setRemoteVersion] = useState(url_params.version == undefined ? false : parseInt(url_params.version));

    // NOTIFIKACIE
    const [notify, setNotify] = useState(0);

    // AI Assistent
    const [enabledAI, setEnabledAI] = useState(false);
    const [showAI, setShowAI] = useState(false);
    const [lastQuestions, setLastQuestions] = useState(false);

    // DASHBOARD SETTINGS
    const [showDashboard, setShowDashboard] = useState(false);

    // BLACK FADE
    const [showBlack, setShowBlack] = useState(false);

    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const window_height = height - global.topmenu_height - (2 * offset) - global.status_height;
    const buttonSize = 58;
    const buttonOffset = 20;

    var lang = GetLanguage(language);

    var started = false;
    var color = global.themes[theme];

    useEffect(() => {

        Debug('------------------------', 'yellow');
        Debug('VISION CRM - START', 'yellow');
        Debug('------------------------', 'yellow');
        if (url_params.remote != undefined) {
            Debug('REMOTE', 'yellow');
        }
        if (url_params.version != undefined) {
            Debug('remote version ' + url_params.version, 'yellow');
        }

        Debug('isSmall: ' + isSmall, 'gray', 'info');
        Debug('isMedium: ' + isMedium, 'gray', 'info');
        Debug('isLarge: ' + isLarge, 'gray', 'info');

        Debug(parseInt(-1) - 1);

        // AKTUALNA VERZIA - RESET
        SaveValue('version', 0);

        // VYMAZANIE INTERVALU V DASHBOARDE
        localStorage.removeItem('vision-crm-dashboard_date');

        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        function cleanupMenu() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }

        function handleResize() {
            setWidth(window.innerWidth);
            // ! VYSKA MOZE SPOSOBOVAT PROBLEMY PRI OTVARANI SOFTVAROVEJ KLAVESNICI
            setHeight(window.innerHeight);
        }

        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        if (started == false) {
            started = true;

            if (window.innerWidth <= global.device_small) {
                // MOBILE
                setRadius(0);
                setOffset(0);
            } else {
                // DESKTOP
                setRadius(global.radius);
                setOffset(global.offset);

                // RELOAD PAGE - AK SA ZMENI VELKOST OKNA               
                window.addEventListener('resize', handleResize);
            }

            setWidth(window.innerWidth);
            setHeight(window.innerHeight);

            //lang = LoadLanguage();
            //setMenuData(lang.menu);

            var login = LoadLogin();
            db_token_check(login.token, true, true);

        }

        // Pravidelná kontrola aplikácie
        // ! SPOSOBUJE PROBLEMY - PREKRESLENIE A VYMAZANIE Rozpísaného textového pola
        /*
        let intervalID = setInterval(() => {
            Debug('##### TIMER -> check data #####');

            var login = LoadLogin();
            db_token(login.token, true);
        }, global.check_period);
        */

        // REMOVE AFTER APPLICATION ENDS
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
            window.removeEventListener('resize', handleResize);
            cleanupMenu();
            //clearInterval(intervalID);
        };

    }, [window.innerWidth, isOnline])

    const logout = () => {
        ResetLogin();
        navigate('/logout');
    }

    const db_token_check = async (token, home, init) => {
        // získanie údajov USER -> z tokenu + aktualizácia informácii
        if (isBusy == false) {

            if (isBusy == true) {
                setBusy(true);
            }
            try {
                const response = await fetch(
                    global.db_url + 'token_check', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: token
                    })
                })

                const json = await response.json();

                Debug('Token checking...', 'red');
                Debug(json);

                setBusy(false);
                if (json.ok > 0) {
                    if (json.authorized == true) {
                        var user_ = json.user;

                        if (user_ != false && json.organization != false) {
                            //! -----------------------------------------------
                            //! SUPER ADMIN - TURN OFF
                            //! -----------------------------------------------
                            //user_.super_admin = false;
                            //user_.super_admin = true;
                            //DEFAULT STOCK = locale.stock_default

                            setMenuData(GetLanguage(user_.language_id).menu);
                            setUser(user_);
                            setToken(token);
                            setLanguage(user_.language_id);
                            setTheme(user_.theme_id);
                            setOrganization(json.organization);
                            setNotify(json.notify);
                            setPrecision(json.settings_system.precisions);

                            if (locale == false) {
                                setLocale(json.locale);
                            }

                            // POVOLENIA
                            setPermissions(json.permissions);

                            // SYSTEMOVE NASTAVENIE APLIKACIE
                            if (json.settings_system == false) {
                                var sett = global.settings_default;
                                sett.currencies = json.currencies;
                                sett.plugins = json.plugins;

                                // PLUGINS - aktívne pluginy
                                sett.sap_enabled = CheckPlugin(sett, 1);
                                sett.eshop_enabled = CheckPlugin(sett, 2);
                                system.ai_enabled = CheckPlugin(sett, 3);
                                system.sms_enabled = CheckPlugin(sett, 4);
                                system.conditions_enabled = CheckPlugin(sett, 5);
                                system.marketing_enabled = CheckPlugin(sett, 6);
                                system.pharminfo_enabled = CheckPlugin(sett, 7);
                                system.pharmos_enabled = CheckPlugin(sett, 8);
                                system.automation_enabled = CheckPlugin(sett, 9);

                                setSystem(sett);
                            } else {
                                var system = json.settings_system;
                                system.currencies = json.currencies;
                                if (system.product_codes != false) {
                                    system.product_codes = JSON.parse(system.product_codes);
                                } else {
                                    system.product_codes = global.settings_default.product_codes
                                }
                                if (system.customers_codes != false) {
                                    system.customers_codes = JSON.parse(system.customers_codes);
                                } else {
                                    system.customers_codes = global.settings_default.customers_codes
                                }
                                system.plugins = json.plugins;

                                // PLUGINS - aktívne pluginy
                                system.sap_enabled = CheckPlugin(system, 1);
                                system.eshop_enabled = CheckPlugin(system, 2);
                                system.sms_enabled = CheckPlugin(system, 4);
                                system.conditions_enabled = CheckPlugin(system, 5);
                                system.marketing_enabled = CheckPlugin(system, 6);
                                system.pharminfo_enabled = CheckPlugin(system, 7);
                                system.pharmos_enabled = CheckPlugin(system, 8);
                                system.automation_enabled = CheckPlugin(system, 9);

                                let ai_enabled = CheckPlugin(system, 3);
                                system.ai_enabled = ai_enabled;
                                setEnabledAI(ai_enabled);

                                setSystem(system);
                            }

                            // UZIVATELSKE NASTAVENIE APLIKACIE
                            if (json.settings == false) {
                                setSettings(global.settings_user_default);
                            } else {
                                setSettings(json.settings);
                            }

                            if (home == true) {
                                setMenuID(0);
                            }

                            // ----------------------------------------------------------------------
                            // PODRIADENY UZIVATELIA
                            // ----------------------------------------------------------------------                            
                            setSubUsers(json.sub_users);

                            // ----------------------------------------------------------------------
                            // KONTROLA NOVEJ WEBOVEJ VERZIE
                            // ----------------------------------------------------------------------
                            if (init == true) {
                                // Prvé spustenie po načítaní (obnove) stránky
                                SaveValue('version', parseInt(json.version));
                                setNewVersion(false);
                                Debug('Ulozenie aktuálnej verzie: ' + json.version, 'violet');
                            } else {
                                // Kontrola WEBOVEJ verzie                                
                                var app_version = parseInt(LoadValue('version', 0));
                                Debug('Kontrola verzie DB: ' + json.version + ' uložená verzia COOKIES: ' + app_version, 'violet');
                                if (parseInt(json.version) > app_version) {
                                    setNewVersion(true);
                                } else {
                                    setNewVersion(false);
                                }
                            }

                            // ----------------------------------------------------------------------
                            // KONTROLA NOVEJ MOBILNEJ VERZIE PRE iPAD
                            // ----------------------------------------------------------------------
                            if (url_params.remote != undefined && url_params.version != undefined) {
                                var mobile_version = parseInt(json.mobile_version);
                                Debug('Mobile version: ' + mobile_version, 'green');
                                if (mobile_version > parseInt(url_params.version)) {
                                    Debug('K dispozícii je nová verzia mobilnej aplikácie');
                                    setNewMobileVersion(true);
                                } else {
                                    setNewMobileVersion(false);
                                }
                            }


                            if (json.enabled == false) {
                                logout();
                            }

                        } else {
                            logout();
                        }
                    } else {
                        logout();
                    }
                } else {
                    setShowError(true);
                }

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const db_update = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const MenuTopPress = (typ, item) => {

        if (typ == 1) {
            // DASHBOARD            
            setShowDashboard(true);
        }
        if (typ == 2) {
            // ZMENA KRAJINY
            Debug(item);
            setLocale(item);
        }
    }

    const MenuPress = (typ, item) => {

        if (typ == 0) {
            Home();
        }
        if (typ == 1) {
            if (item.id == 0) {
                // SPAT NA HLAVNE MENU
                Home();
            } else {
                if (item.children != undefined) {
                    // zobrazenie children menu
                    setMenuData(item.children);
                    setMenuLabel(item.name);
                } else {
                    // vykonanie príkazu - Kontrola povolenia na čítanie                    
                    if (actualMenu != false) {
                        if (actualMenu.reload == true) {
                            // RELOAD TOKEN
                            var login = LoadLogin();
                            db_token_check(login.token, false, false);
                        }
                    }
                    setActualMenu(item);
                    setLabel(item.label);
                    setParams(false);
                    setBodyID(item.id);
                }
            }
        }
        if (typ == 999) {
            // ID MENU OPENED            
            setMenuID(item.id);
        }
    }

    const Home = () => {
        // MENU -> HOME
        //setMenuData(lang.menu);
        //setMenuLabel('');
        //setActualMenu(false);

        setParams(false);
        setBodyID(0);

        var login = LoadLogin();
        db_token_check(login.token, true, false);
    }

    const StatusbarPress = (id) => {
        if (id == 1) {
            // EXPORTOVANE SUBORY
            setShowFiles(true);
        }
    }

    const DesktopPress = (typ, value) => {
        if (typ == 1) {
            // VYVOLANIE MENU
            setLabel('');
            setParams(false);
            setBodyID(value);
        }
        if (typ == 2) {
            // ZOBRAZENIE TERMINU
            setTermin({ termin: value, new: false });
            setShowTermin(true);
        }
        if (typ == 3) {
            // ZOBRAZENIE KARTY ZAKAZNIKA
            setCustomer({ id: value });
            setShowCustomer(true);
        }
        if (typ == 4) {
            // KALENDAR
            setBodyID(600);
        }
    }

    const closeWindow = (redraw) => {
        setParams(false);
        setBodyID(0);

        if (redraw == true) {
            var login = LoadLogin();
            db_token_check(login.token, false, false);
        }
    }

    const HelpSelectResult = (typ) => {
        setShowHelpSelect(false);

        if (typ == 1) {
            // POMOCNIK
            setShowHelp(true);
        }
        if (typ == 2) {
            // KONTAKTUJTE NAS
            setShowContactUs(true);

        }
    }

    const OpenAIResult = (typ, id, params, question) => {
        setShowAI(false);

        if (typ == true) {
            Debug('Last question');
            Debug(question);
            setLastQuestions(question);
            if (id < 10000) {
                // APLIKACIA
                setParams(params);
                setBodyID(id);
            } else {
                // NAVOD
                var link = lang.help_main + '/' + params.chapter;
                Debug(link);
                setHelpLink(link);
                setShowHelp(true);
            }
        } else {
            //setParams(false);
        }
    }

    const DashboardResult = (need_redraw) => {
        setShowDashboard(false);

        if (need_redraw == true) {
            Home();
        }
    }

    return (
        <div style={{ ...styles.Block, height: height, alignItems: 'flex-start', justifyContent: 'flex-start', fontFamily: 'roboto', msOverflowStyle: 'none', scrollbarWidth: 'none', backgroundColor: color.desktop_background, backgroundSize: 'cover' }} className='container'>
            <div style={{ ...styles.BlockLeft, height: height }}>

                {/* MENU - VRCHNA LISTA */}
                <MenuTop notify={notify} new_version={newVersion} new_mobile_version={newMobileVersion} remote={remote} remoteMobile={remoteMobile} theme={theme} user={user} organization={organization} permissions={permissions} menuID={menuID} version={version} label={label} offset={offset} radius={radius} isSmall={isSmall} token={token} bodyID={bodyID} lang={lang} screenWidth={width} width={width} height={height} logged={logged} locale={locale} func={MenuTopPress.bind(this)} />

                <div style={{ ...styles.BlockRow, height: height - global.topmenu_height }}>
                    {(bodyID == 0) || (bodyID > 0 && width > global.max_screen) ?
                        <div style={{ ...styles.BlockCenter, alignItems: 'center', width: global.menu_width, height: height - global.topmenu_height }}>

                            {/* INFO MENU */}
                            <MenuInfo remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} menuID={menuID} bodyID={bodyID} token={token} func={MenuPress.bind(this)} />

                            {/* MAIN MENU */}
                            <AppMenu remote={remote} remoteMobile={remoteMobile} theme={theme} menuID={menuID} version={version} user={user} system={system} settings={settings} permissions={permissions} label={menuLabel} offset={offset} radius={radius} isSmall={isSmall} bodyID={bodyID} token={token} lang={lang} language={language} width={width} menuData={menuData} height={height - global.topmenu_height - global.menu_info_height} func={MenuPress.bind(this)} />

                        </div>
                        : null
                    }

                    {/* BODY */}
                    <div style={{ ...styles.Block, width: (bodyID == 0) || (bodyID > 0 && width > global.max_screen) ? width - global.menu_width : width, height: height - global.topmenu_height }}>

                        <div style={{ ...styles.Block, height: height - global.topmenu_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll', zIndex: 1 }} className='container'>
                            {/*
                            *
                            * =================================================================================================================
                            * ASISTENT BUTTON
                            * =================================================================================================================
                            *
                            */}
                            {user != false ? enabledAI == true ? bodyID != 612 && (permissions.ai == true || user.super_admin == true) ?
                                <div elevation={0} onClick={() => setShowAI(true)} style={{ width: buttonSize, height: buttonSize, position: 'absolute', top: height - buttonSize - buttonOffset, right: buttonOffset, color: color.white, backgroundColor: '#00000040', zIndex: 10, borderRadius: '50%', cursor: 'pointer' }}>
                                    <img src={logo_ai} style={{ width: '100%', height: '100%', maxWidth: buttonSize, maxHeight: buttonSize, objectFit: 'contain', borderRadius: '50%' }}></img>
                                </div>
                                : null : null : null}

                            <div style={{ ...styles.Block, position: 'relative', height: height - global.statusbar_height - global.topmenu_height }}>
                                <div style={{ ...styles.Block, position: 'absolute', alignItems: 'center', height: height - global.statusbar_height - global.topmenu_height }}>
                                    {/* DESKTOP */}
                                    <Desktop bodyID={bodyID} remote={remote} remoteMobile={remoteMobile} organization={organization} theme={theme} system={system} settings={settings} user={user} locale={locale} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} isMedium={isMedium} isLarge={isLarge} title={''} sub_title={''} screenWidth={width} width={isSmall ? width : width - global.menu_width - offset} height={height - global.statusbar_height - global.topmenu_height - offset} token={token} func={DesktopPress.bind(this)} />
                                </div>

                                {logged == true ?
                                    bodyID == 0 ?
                                        null
                                        :
                                        <div style={{ ...styles.BlockCenter, position: 'absolute', alignItems: 'center', height: height - global.statusbar_height - global.topmenu_height }}>

                                            {/*
                                        *
                                        * =================================================================================================================
                                        * AGENDA
                                        * =================================================================================================================
                                        *
                                        */}
                                            {/* KALENDAR */}
                                            {bodyID == 600 ?
                                                <Calendar remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} subUsers={subUsers} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} isMedium={isMedium} isLarge={isLarge} title={lang.calendar} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* PLANOVANIE */}
                                            {bodyID == 601 ?
                                                <Plans remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} subUsers={subUsers} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} isMedium={isMedium} isLarge={isLarge} title={lang.plans} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* TRASY */}
                                            {bodyID == 610 ?
                                                <MyRoutes remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.routes} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* QUIZ - KONTAKTNÉ SPRAVY */}
                                            {bodyID == 611 ?
                                                <Quizes remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.quizes} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* PREZENTACIE */}
                                            {bodyID == 612 ?
                                                <Presentations remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.presentations} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}

                                            {/*
                                        *
                                        * =================================================================================================================
                                        * OBCHOD
                                        * =================================================================================================================
                                        *
                                        */}
                                            {/* ZOZNAM OBJEDNAVKY */}
                                            {bodyID == 400 ?
                                                <OrdersWindow remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.orders} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/* REKLAMACIE OBJEDNAVOK */}
                                            {bodyID == 401 ?
                                                <Reklamacie remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.reklamacie_orders} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/*
                                        *
                                        * =================================================================================================================
                                        * ZAKAZNICI
                                        * =================================================================================================================
                                        *
                                        */}
                                            {/* ZOZNAM ZAKAZNIKOV */}
                                            {bodyID == 300 ?
                                                <Customers remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.customers} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {bodyID == 305 ?
                                                <Contacts remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.contacts} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {bodyID == 306 ?
                                                <EmailsWindow remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.customer_emails} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/* DOKUMENTY - SUBORY */}
                                            {bodyID == 304 ?
                                                <FilesWindow remote={remote} remoteMobile={remoteMobile} params={params} init_type={1} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.files_and_docs} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* MOJE SKUPINY */}
                                            {bodyID == 303 ?
                                                <CustomersGroups remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.customers_groups} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/* KATEGORIE ZAKAZNIKOV */}
                                            {bodyID == 301 ?
                                                <CustomersCategories remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.customers_categories} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}

                                            {/*
                                        *
                                        * =================================================================================================================
                                        * MARKETING
                                        * =================================================================================================================
                                        *
                                        */}
                                            {/* TVORBA NEWSLETTROV */}
                                            {bodyID == 700 ?
                                                <Newsletters remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.newsletter_editor} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* ZOZNAM ODBERATELOV - EMAILOV */}
                                            {bodyID == 703 ?
                                                <NewsletterEmails remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.newsletter_emails_} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* EMAILOVE KAMPANE */}
                                            {bodyID == 705 ?
                                                <NewsletterCampaigns remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.newsletter_campaigns} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* OZNAMY */}
                                            {bodyID == 715 ?
                                                <Blogs internal remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.blogs_internal} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}

                                            {/*
                                        *
                                        * =================================================================================================================
                                        * ESHOP
                                        * =================================================================================================================
                                        *
                                        */}
                                            {/* ESHOP */}
                                            {bodyID == 805 ?
                                                <Blogs public remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.blogs_public} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}

                                            {/*
                                        *
                                        * =================================================================================================================
                                        * PROCESY
                                        * =================================================================================================================
                                        *
                                        */}
                                            {/* POZIADAVKY O SCHVALENIE */}
                                            {bodyID == 500 ?
                                                <Approvals remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.approvals_ask} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/*
                                        *
                                        * =================================================================================================================
                                        * PRODUKTY
                                        * =================================================================================================================
                                        *
                                        */}
                                            {/* PRODUKTY */}
                                            {bodyID == 100 ?
                                                <Products params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.products} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* CENNIKY */}
                                            {bodyID == 115 ?
                                                <PriceLists remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.price_lists} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* KATEGORIE */}
                                            {bodyID == 102 ?
                                                <ProductCategories remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.products_categories} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/* MOJE SKUPINY */}
                                            {bodyID == 103 ?
                                                <ProductGroups remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.product_groups_} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/* Značky */}
                                            {bodyID == 116 ?
                                                <ProductFlags remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.flags} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* Výrobcovia */}
                                            {bodyID == 111 ?
                                                <Manufacturers remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.manufacturers} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* Zoznam dodávateľov */}
                                            {bodyID == 112 ?
                                                <Vendors remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.vendors} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}

                                            {/*
                                        *
                                        * =================================================================================================================
                                        * SLUŽBY
                                        * =================================================================================================================
                                        *
                                        */}

                                            {/* Zoznam sluzieb */}
                                            {bodyID == 900 ?
                                                <Services typ={0} remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.services} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* Kategorie sluzieb */}
                                            {bodyID == 901 ?
                                                <ServiceCategories remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.services_categories} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}


                                            {/*
                                        *
                                        * =================================================================================================================
                                        * SKLAD - WAREHOUSE
                                        * =================================================================================================================
                                        *
                                        */}
                                            {/* SKLAD */}
                                            {bodyID == 122 ?
                                                <Warehouse remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.warehouse} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* ÚPRAVA SKLADOVYCH ZASOB */}
                                            {bodyID == 120 ?
                                                <ProductAdjustments remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.stock_adjustments} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/*
                                        {/* PRESUN ZASOB */}
                                            {bodyID == 121 ?
                                                <ProductTransports remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.stock_transport} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* VYSKLADNENIE ZASOB ZAKAZNIKOM */}
                                            {bodyID == 126 ?
                                                <StockRemovalsWindow remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.stock_removals} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/* OBJEDNAVKA TOVARU NA SKLAD */}
                                            {bodyID == 127 ?
                                                <StockOrders remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.stock_orders} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/*
                                        {/* PRIJEM OBJEDNAVKY TOVARU NA SKLAD */}
                                            {bodyID == 128 ?
                                                <StockOrdersReceived remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.stock_orders_received} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/*

                                        {/* SKLADOVE KARTY */}
                                            {bodyID == 123 ?
                                                <StockCards remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.stock_cards} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* Sklady produktov */}
                                            {bodyID == 125 ?
                                                <Stocks remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.stocks} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* Zoznam distribútorov */}
                                            {bodyID == 124 ?
                                                <Distributors remote={remote} remoteMobile={remoteMobile} params={params} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.distributors} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/*
                                        * =================================================================================================================
                                        * NASTAVENIA
                                        * =================================================================================================================
                                        *
                                        */}
                                            {/* MOJ PROFIL */}
                                            {bodyID == 9005 ?
                                                <MyProfile remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.user_profile} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/*
                                        {/* ORGANIZACIA */}
                                            {bodyID == 9000 ?
                                                <SettingsOrganization remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.organization} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/* USERS */}
                                            {bodyID == 9001 ?
                                                <Users remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.users} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/* TYPY OBJEDNAVOK */}
                                            {bodyID == 9011 ?
                                                <OrderTypes remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.order_types} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* APPLICATION */}
                                            {bodyID == 9002 ?
                                                <SettingsApplication remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.app_settings} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/* NOTIFIKACIE - PREHLAD */}
                                            {bodyID == 9003 ?
                                                <SMSCredit remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.sms_notify_label} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/* PODMIENKY */}
                                            {bodyID == 9020 ?
                                                <ConditionsList remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.conds} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/* CISELNIK */}
                                            {bodyID == 9021 ?
                                                <CodeBooks remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.codebooks} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* DOPRAVA A PLATBA - ESHOP */}
                                            {bodyID == 9022 ?
                                                <TradePayments remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.trade_payments} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {/* SYSTEMOVE LOGY */}
                                            {bodyID == 9004 ?
                                                <LogsSystem remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.system_logs} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/* Pluginy */}
                                            {bodyID == 9030 ?
                                                <Plugins remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.plugins} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                                : null}
                                            {/*
                                        {/*                                        
                                        *
                                        * =================================================================================================================
                                        * TESTOVANIE
                                        * =================================================================================================================
                                        *
                                        */}
                                            {/* TESTOVANIE SCRIPTOV */}
                                            {bodyID == 9997 ?
                                                <TemplateDialog remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
                                                : null}
                                            {bodyID == 9994 ?
                                                <TemplateDialogItems remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
                                                : null}
                                            {bodyID == 9998 ?
                                                <TemplateWindows remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={'Testovanie'} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {bodyID == 9999 ?
                                                <ConditionDesigner remote={remote} remoteMobile={remoteMobile} item_id={0} module_id={12} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
                                                : null}
                                            {bodyID == 9995 ?
                                                <TemplateForms remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={'Testovanie'} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}
                                            {bodyID == 9996 ?
                                                <Test remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={'Testovanie'} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={OpenAIResult.bind(this)} />
                                                : null}
                                            {bodyID == 9993 ?
                                                <WebPageDesigner item={{ id: 3 }} remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} isMedium={isMedium} isLarge={isLarge} title={lang.web_page_designer} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                                : null}

                                            {/* UZIVATEL - NASTAVENIA */}
                                            {bodyID == 9901 ?
                                                <Settings remote={remote} remoteMobile={remoteMobile} organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={window_height} func={() => closeWindow()} />
                                                : null}

                                        </div>
                                    :
                                    null
                                }
                            </div>
                        </div>

                        <AppStatusbar remote={remote} remoteMobile={remoteMobile} theme={theme} menuID={menuID} user={user} system={system} settings={settings} permissions={permissions} offset={offset} radius={radius} isSmall={isSmall} bodyID={bodyID} token={token} lang={lang} language={language} width={width > global.max_screen ? width - global.menu_width : width} func={StatusbarPress.bind(this)} />

                    </div>

                </div>

            </div>

            {/* HELP -> VYBER POMOCNIK / KONTAKTUJTE NAS */}
            {showHelpSelect == true ?
                <DialogHelpSelect remote={remote} remoteMobile={remoteMobile} isSmall={isSmall} theme={theme} user={user} radius={radius} language={language} lang={lang} title={lang.help} sub_title={lang.vision_system} background={color.dark_gray} offset={offset} func={HelpSelectResult.bind(this)} />
                : null}

            {/* HELP -> KONTAKTUJTE NAS */}
            {showContactUs == true ?
                <DialogContactUs remote={remote} remoteMobile={remoteMobile} isSmall={isSmall} theme={theme} user={user} radius={radius} language={language} lang={lang} title={lang.preview} sub_title={lang.vision_system} background={color.dark_gray} offset={offset} func={() => setShowContactUs(false)} />
                : null}

            {/* HELP -> POMOCNÍK */}
            {showHelp == true ?
                <DialogHelp remote={remote} remoteMobile={remoteMobile} link={helpLink} isSmall={isSmall} theme={theme} user={user} radius={radius} language={language} lang={lang} title={lang.preview} sub_title={lang.vision_system} background={color.dark_gray} offset={offset} func={() => setShowHelp(false)} />
                : null}

            {/* EXPORTOVANE SUBORY */}
            {showFiles == true ?
                <Files remote={remote} remoteMobile={remoteMobile} init_type={0} organization={organization} user={user} permissions={permissions} settings={settings} system={system} locale={locale} lang={lang} language={language} theme={theme} offset={offset} radius={radius} precision={precision} isSmall={isSmall} title={lang.files_and_docs} token={token} func={() => setShowFiles(false)} />
                : null}

            {showAI == true ?
                <DialogAssistent remote={remote} remoteMobile={remoteMobile} lastQuestions={lastQuestions} organization={organization} locale={locale} user={user} lang={lang} language={language} permissions={permissions} permission_column={'open_ai'} theme={theme} offset={offset} radius={radius} precision={precision} isSmall={isSmall} token={token} func={OpenAIResult.bind(this)} />
                : null}

            {showDashboard == true ?
                <Dashboard remote={remote} remoteMobile={remoteMobile} organization={organization} locale={locale} user={user} settings={settings} system={system} lang={lang} language={language} permissions={permissions} permission_column={'dashboard'} theme={theme} offset={offset} radius={radius} precision={precision} isSmall={isSmall} token={token} func={DashboardResult.bind(this)} />
                : null}

            {showTermin == true ?
                <CalendarTermin preview={true} remote={remote} remoteMobile={remoteMobile} termin={termin} terminColors={global.terminColors} calendarGroups={GetCalendarGroups(lang)} organization={organization} settings={settings} locale={locale} system={system} user={user} lang={lang} language={language} permissions={permissions} permission_column={'calendar'} theme={theme} offset={offset} radius={radius} precision={precision} isSmall={isSmall} token={token} func={() => setShowTermin(false)} />
                : null}

            {showCustomer == true ?
                <Customer remote={remote} remoteMobile={remoteMobile} module_id={global.model.customers} item={customer} organization={organization} settings={settings} locale={locale} system={system} user={user} lang={lang} language={language} permissions={permissions} permission_column={'customers'} theme={theme} offset={offset} radius={radius} precision={precision} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => setShowCustomer(false)} />
                : null}

            {showError == true ?
                <ShowError theme={theme} text={lang.app_error} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading theme={theme} side={0} func={() => setBusy(false)} />
                : null}

            {/* VYPADOK INTERNETOVEHO PRIPOJENIA */}
            {isOnline == false ?
                <ShowError theme={theme} text={lang.internet_error} sub_text={lang.internet_error_text} icon={faWifi} func={() => setShowError(false)} />
                : null}

            {/* FADE BLACK -> ZMENA TEMY */}
            <Backdrop open={showBlack} transitionDuration={500} style={{ zIndex: 20000, width: width, height: height, backgroundColor: '#000000' }}>
                <div style={{ ...styles.Block, width: width, height: height }}>
                    <img src={logo} style={{ width: '100%', height: '100%', maxWidth: 300, maxHeight: 120, objectFit: 'contain' }}></img>
                    <div style={{ ...styles.Block, height: 50 }}>
                        <CircularProgress style={{ color: '#FFFFFF' }} thickness={2} size={20} />
                    </div>
                </div>
            </Backdrop>

        </div >
    );
}

const LoadingCode = () => {
    return (
        <div>Loading code ...</div>
    )
}


