/*
*
* =================================================================================================================
* POMOCNÍK

    USAGE:
    ----------------------------------------------------------------------------------------------------------------------------

    <Help link={'https://support.datadream.sk/guide/esthederm-salon-v2/sk/moj-ucet/1'} lang={props.lang} theme={props.theme} />

    ----------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { DialogDates, FormError, FormLabel, FormSelect, FormText, FormYesNo, Icon, Line, Loading, ShowError, ShowOK } from './items';
import { styles } from './styles';
import { Button, Drawer, IconButton, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faInfoCircle, faLayerGroup, faMinimize, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetTodayYear } from './functions_date';
import { Debug, IsEmpty } from './functions';

export const Help = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let permissions = global.permissions;
    let color = global.themes[props.theme];
    let minWidth = 800;

    // DATA
    const [openMenu, setOpenMenu] = useState(false);
    const [dialogWidth, setDialogWidth] = useState(minWidth);
    const [minimize, setMinimize] = useState(true);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const titleHeight = 40;
    const heightOffset = 50;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            running = true;
        }

    }, []);

    const OpenHelp = () => {
        setOpenMenu(!openMenu);
    }

    const Expand = () => {
        if (minimize == true) {
            setMinimize(false);
            setDialogWidth(width - 10);
        } else {
            setMinimize(true);
            setDialogWidth(minWidth);
        }
    }
    return (
        <div style={{ ...styles.BlockCenter }}>
            <IconButton onClick={() => OpenHelp()} style={{ ...styles.ButtonClose, width: 26, height: 26, backgroundColor: color.orange }}>
                <FontAwesomeIcon style={{ height: 18, color: color.white }} icon={faQuestion} />
            </IconButton>
            <Drawer
                anchor={'right'}
                open={openMenu}
                hideBackdrop={false}
                style={{ zIndex: 99000 }}
                PaperProps={{
                    sx: {
                        marginTop: '25px',
                        marginRight: '5px',
                        height: height - heightOffset,
                        borderRadius: '10px',
                    }
                }}
            >
                <div style={{ ...styles.BlockCenter, width: dialogWidth, height: 500 }}>
                    <div style={{ ...styles.BlockRow, background: color.window_bar_color }}>
                        <div style={{ ...styles.Block, width: 60, height: titleHeight }}>
                            <Icon theme={props.theme} icon={faLayerGroup} logo />
                        </div>
                        <div style={{ ...styles.BlockLeft, height: titleHeight, width: dialogWidth - 50 - 60 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.helper}</p>
                        </div>
                        <div style={{ ...styles.Block, height: titleHeight, width: 50 }}>
                            <IconButton onClick={() => Expand()} style={{ ...styles.ButtonClose, width: 26, height: 26, backgroundColor: color.white }}>
                                <FontAwesomeIcon style={{ width: 16, color: color.black }} icon={minimize == true ? faExpand : faMinimize} />
                            </IconButton>
                        </div>
                        <div style={{ ...styles.Block, height: titleHeight, width: 50 }}>
                            <IconButton onClick={() => OpenHelp()} style={{ ...styles.ButtonClose, width: 26, height: 26, backgroundColor: color.white }}>
                                <FontAwesomeIcon style={{ width: 16, color: color.black }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: height - titleHeight - heightOffset }}>
                        <iframe width={dialogWidth - 16} height={'100%'} style={{ border: '0px' }} src={props.link}></iframe>
                    </div>
                </div>
            </Drawer>
        </div>

    );
}


