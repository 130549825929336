/*
*
* =================================================================================================================
* PLANOVANIE

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, PlusMinus, TextLine } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, FormControlLabel, IconButton, Pagination, Paper, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowPointer, faArrowUp, faBook, faCalendarDay, faCalendarDays, faFilter, faInfoCircle, faLayerGroup, faLocationDot, faPlusCircle, faQuestion, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetLeftPosition, GetPages, IsEmpty } from './functions';
import { GetDate3, GetDatum2, GetTodayYear, Today, Tommorow } from './functions_date';
import { UsersSelect } from './users_select';
import { CalendarTermin } from './calendar';

export const Plans = (props) => {

    let module_id = global.model.calendar;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'plans';

    let defaultFilter = {
        ordering: global.order.up,
        order_column: 'date',
        typ: 99,
        agent_id: parseInt(props.user.id),
        year: GetTodayYear(),
        date_start: Today(),
        date_end: GetDate3(getNextSevenDaysDate(Today())),
        group_id: 1
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    const calendarGroups = [
        { id: 1, name: lang.calendar_groups[1], short: lang.calendar_groups[1][0] },
        { id: 2, name: lang.calendar_groups[2], short: lang.calendar_groups[2][0] },
        { id: 3, name: lang.calendar_groups[3], short: lang.calendar_groups[3][0] },
        { id: 0, name: lang.calendar_groups[0], short: '' },
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    // TYPY PLANU
    const typs = [
        { id: 99, enabled: true, name: lang.all_records },
        { id: 0, enabled: true, name: lang.calendar_type[0] },
        { id: 1, enabled: true, name: lang.calendar_type[1] },
        { id: 2, enabled: true, name: lang.calendar_type[2] },
        { id: 3, enabled: props.system.sms_enabled == true ? props.system.sms_notify == false ? false : true : false, name: lang.calendar_type[3] },
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.vendor_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TYPY PLANU
    const [typ, setTyp] = useState(99);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.vendor_name, default: true },
        { id: 2, enabled: true, checked: false, column: 'town', label: lang.town },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [showChooseDate, setShowChooseDate] = useState(false);
    const [userID, setUserID] = useState(0);
    const [user, setUser] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 60;
    const menuHeight = 50;
    const rowHeight = 86;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const menuWidth = global.sub_menu_width;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            Debug('system');
            Debug(props.system.sms_notify);
            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, []);


    const db_get = async (page, filter, maxItems) => {
        Debug('filter', 'orange');
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'plans', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'calendar_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: [data]
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.vendors,
                    filename: lang.vendors, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    function getNextSevenDaysDate(date) {
        const inputDate = new Date(date);
        inputDate.setDate(inputDate.getDate() + 7);
        return inputDate;
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = {
            ordering: ordering,
            order_column: orderColumn
        };

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected({ date: Today(), hour: 8, minute: 0, new: true, group_id: 0 });
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        setItemSelected({ termin: item, new: false });
        setShowDialog(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const ChangeTaskFinished = (item, value_) => {
        // ULOHA UKONCENA
        let value = !value_;

        let data = {
            id: item.id,
            task_finished: value,
            color: value == true ? 10 : item.priority == 0 ? 6 : 9,
        }
        item.task_finished = value;
        item.color = value == true ? 10 : item.priority == 0 ? 6 : 9;

        Debug(data);
        db_update(data);
    }

    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 0: // DATUM
                column = 'date';
                break;

            case 1: // NAZOV
                column = 'name';
                break;

            case 2: // AKTIVNE
                column = 'task_active';
                break;
        }

        var filter_ = filter;
        filter_.ordering = order;
        filter_.order_column = column;

        db_get(0, filter_, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const TypReset = () => {
        var filter_ = filter;
        filter_.typ = 99;

        setTyp(99);
        db_get(0, filter_, maxItems);
    }

    const FilterResult = (typ, id) => {
        setShowFilter(false);

        if (typ == true) {
            var filter_ = filter;
            filter_.typ = id;

            setTyp(id);
            db_get(0, filter_, maxItems);
        }
    }

    const UserResult = (typ, selected, users_) => {
        setShowUsers(false);
        Debug(users_);
        if (typ == true && selected != false) {
            setUserID(selected.id);
            setUser(selected);

            var filter_ = filter;
            filter.agent_id = selected.id;

            db_get(0, filter_, maxItems);
        }
    }

    const UserReset = () => {
        setUserID(0);

        var filter_ = filter;
        filter_.agent_id = props.user.id;

        db_get(0, filter_, maxItems);
    }

    const TerminResult = (typ, date_start, date_end) => {
        setShowChooseDate(false);
        if (typ == true) {
            var filter_ = filter;

            filter_.date_start = date_start;
            filter_.date_end = date_end;

            db_get(0, filter_, maxItems);
        }
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    {global.appstore == false ?
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                        : null}
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div id={'id_start'} style={{ ...styles.Block }}></div>
                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: 220, height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <TextInput search theme={props.theme} width={200} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        {/* 
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                        */}
                                    </div>
                                </div>
                                <div style={{ ...styles.Block, width: 160, height: menuHeight }}>
                                    <Button onClick={() => setShowUsers(true)} style={{ ...styles.ButtonOutlined, width: 150, fontSize: global.font_tiny }}>
                                        <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                        {lang.customer_agent}
                                    </Button>
                                </div>

                                {/* 
                                <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                    <IconButton onClick={() => UserOnly(true)} style={{ ...styles.ButtonUser }}>
                                        <FontAwesomeIcon style={{ height: 14 }} icon={faUser} />
                                    </IconButton>
                                </div>
                                */}
                                <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                    <IconButton onClick={() => setShowChooseDate(true)} style={{ ...styles.ButtonUser }}>
                                        <FontAwesomeIcon style={{ height: 14 }} icon={faCalendarDays} />
                                    </IconButton>
                                </div>

                                <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                    <IconButton onClick={() => setShowFilter(true)} style={{ ...styles.ButtonUser }}>
                                        <FontAwesomeIcon style={{ height: 14 }} icon={faFilter} />
                                    </IconButton>
                                </div>

                                <div style={{ ...styles.BlockRight, width: '52%', height: menuHeight }}>
                                    <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                        {lang.plan_new}
                                    </Button>
                                </div>
                            </div>

                            <div style={{ ...styles.BlockLeft, marginTop: 10, height: tabHeight }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {filter.date_start == filter.date_end ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 0 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.plan_choose_termin_}: {GetDatum2(filter.date_start)}</p>}></Chip>
                                        :
                                        filter.date_end == global.date_endless ?
                                            <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.plan_choose_termin_}: {lang.all_records}</p>}></Chip>
                                            :
                                            <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.plan_choose_termin_}: {GetDatum2(filter.date_start)} - {GetDatum2(filter.date_end)}</p>}></Chip>
                                    }
                                    {typ < 99 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.plan_typ}: {typs.find(x => x.id == typ).name}</p>} onDelete={() => TypReset()}></Chip>
                                        : null}
                                    {userID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray, marginLeft: 10 }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{user.name + ' ' + user.surname}</p>} onDelete={() => UserReset()}></Chip>
                                        : null}
                                </div>
                            </div>

                            {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}

                            <div style={{ ...styles.Block, marginBottom: 10, border: color.border, height: tableLabelHeight }}>
                                <div style={{ ...styles.BlockRow, width: '99%' }}>
                                    <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center' }}>
                                        {/* SELECT */}
                                        <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                            <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                        </div>
                                    </div>

                                    {/* DATA */}
                                    <div style={{ ...styles.BlockRow, width: 120, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.date}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                            <MenuButton id={0} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockRow, width: '80%', height: tableLabelHeight, borderLeft: color.border }}>
                                        <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.plan_name}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                            <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockRow, width: 100, borderLeft: color.border }}>
                                        {/* ENABLED */}
                                        <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.enabled}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                            {/* 
                                                <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* ****************************************************************************************************************
                                        TABLE DATA
                                **************************************************************************************************************** */}
                            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - tabHeight - 70, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            width: '99%',
                                            backgroundColor: item.priority == 1 ? item.task_finished == true ? color.disabled : color.lighteen_red : item.task_finished == true ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowHeight,
                                            cursor: 'pointer',
                                            marginTop: 5, marginBottom: 5,
                                            paddingTop: 2, paddingBottom: 2,
                                            borderLeft: item.priority == 1 ? item.task_finished == true ? '4px solid ' + color.dark_gray : '4px solid ' + color.red : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                                            <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                {/* SELECT */}
                                                <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                            </div>

                                            <div style={{ ...styles.BlockLeft, width: 120, minHeight: rowHeight }}>
                                                <p style={{ ...styles.TextXSmall }}>{GetDatum2(item.date)}</p>
                                            </div>
                                            <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '80%' }}>
                                                {/* DATA */}
                                                <div style={{ ...styles.BlockLeft, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <div style={{ ...styles.Block, width: 36, height: 36, borderRadius: 20, backgroundColor: color.white }}>
                                                        <FontAwesomeIcon style={{ height: 20, color: color.light_gray }} icon={global.caledar_type_icons[parseInt(item.typ)]} />
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '90%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.calendar_type[item.typ]}</p>
                                                    {item.name != '' ?
                                                        <TextLine text={item.name} fontSize={global.font_xsmall} fontWeight='600' color={color.black} />
                                                        : null}
                                                </div>
                                            </div>
                                            <div style={{ ...styles.Block, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                {/* ENABLED */}
                                                {item.typ == global.plans.task ?
                                                    <SwitchEnable enabled={item.task_finished == false ? true : false} theme={props.theme} func={(value) => ChangeTaskFinished(item, value)} />
                                                    : null}
                                                {item.task_finished == true ?
                                                    <div style={{ ...styles.Block, height: 20 }}>
                                                        <p style={{ ...styles.TextXXTiny, color: color.dark_gray }}>{lang.plan_finished}</p>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <EmptyList row small lang={lang} />
                                }

                                {/* ****************************************************************************************************************
                                        PAGINATION
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 200 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {
                showFilter == true ?
                    <DialogTypChoose remote={props.remote} system={props.system} remoteMobile={props.remoteMobile} lang={lang} theme={props.theme} radius={props.radius} func={FilterResult.bind(this)} />
                    : null
            }

            {
                showChooseDate == true ?
                    <DialogTerminChoose remote={props.remote} remoteMobile={props.remoteMobile} lang={lang} theme={props.theme} radius={props.radius} func={TerminResult.bind(this)} />
                    : null
            }

            {
                showUsers == true ?
                    <UsersSelect remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.stock_transport_approve_choose} token={props.token} func={UserResult.bind()} />
                    : null
            }

            {
                showExport == true ?
                    <DialogExport remote={props.remote} remoteMobile={props.remoteMobile} rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                    : null
            }

            {
                showDialog == true ?
                    <CalendarTermin preview={itemSelected.new == true ? false : parseInt(props.user.id) == parseInt(itemSelected.termin.user_id) ? false : true} remote={props.remote} remoteMobile={props.remoteMobile} plans termin={itemSelected} terminColors={global.terminColors} calendarGroups={calendarGroups} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null
            }

        </Paper >
    );
}


export const DialogTerminChoose = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogTerminChoose lang={lang} theme={props.theme} radius={props.radius} func={TerminResult.bind(this)} />

            ------------------------------------------------------------
            
            const TerminResult = (typ, date_start, date_end) => {
                
            }
    */
    const lang = props.lang;

    // OBDOBIE - ktoré záznamy sa zobrazia
    const periods = [
        { id: 0, name: lang.plan_periods[0] },
        { id: 1, name: lang.plan_periods[1] },
        { id: 2, name: lang.plan_periods[2] },
        { id: 3, name: lang.plan_periods[3] },
        { id: 4, name: lang.plan_periods[4] },
        { id: 5, name: lang.plan_periods[5] },
        { id: 6, name: lang.plan_periods[6] },
        { id: 7, name: lang.plan_periods[7] },
    ]

    const [dateStart, setDateStart] = useState(Today());
    const [dateEnd, setDateEnd] = useState(Today());
    const [value, setValue] = useState(-1);
    const [expired, setExpired] = useState(true);

    const [width, setWidth] = useState(window.innerWidth);

    const buttonHeight = 80;
    const dialogWidth = 500;
    const dialogHeight = 200 + buttonHeight;


    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = () => {
        var date_start = dateStart;
        if (expired == true) {
            date_start = Today();
        }

        func(true, date_start, dateEnd);
    }

    const ChangePeriod = (id) => {
        var date_start = Today();
        var date_end = Today();

        if (id == 0) {
            // DNES
            date_start = Today();
            date_end = Today();
        }
        if (id == 1) {
            // ZAJTRA
            date_start = Tommorow();
            date_end = Tommorow();
        }
        if (id == 2) {
            // TENTO TYZDEN
            var dates = getWeekStartAndEndDate(new Date());
            date_start = dates.start;
            date_end = dates.end;
        }
        if (id == 3) {
            // SEDEM DNI
            date_start = Today();
            date_end = GetDate3(getNextSevenDaysDate(new Date()));
        }
        if (id == 4) {
            // TENTO MESIAC
            var dates = getMonthStartAndEndDate(new Date());
            date_start = dates.start;
            date_end = dates.end;
        }
        if (id == 5) {
            // TRI MESIACE
            var dates = getThreeMonthPeriodStartAndEndDate(new Date());
            date_start = dates.start;
            date_end = dates.end;
        }
        if (id == 6) {
            // TENTO ROK
            date_start = GetTodayYear() + '-01-01';
            date_end = GetTodayYear() + '-12-31';
        }
        if (id == 7) {
            // VSETKY ZAZNAMY
            date_start = '2000-01-01';
            date_end = global.date_endless;
        }

        setDateStart(date_start);
        setDateEnd(date_end);
        setValue(id);
        //func(true, date_start, date_end);
    }

    function getWeekStartAndEndDate(date) {
        const currentDate = new Date(date);
        const dayOfWeek = currentDate.getDay();
        const diffToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
        const firstDay = new Date(currentDate);
        firstDay.setDate(currentDate.getDate() + diffToMonday);
        const lastDay = new Date(firstDay);
        lastDay.setDate(firstDay.getDate() + 6);

        return {
            start: GetDate3(firstDay),
            end: GetDate3(lastDay)
        };
    }

    function getMonthStartAndEndDate(date) {
        const currentDate = new Date(date);
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        return {
            start: GetDate3(firstDay),
            end: GetDate3(lastDay)
        };
    }

    function getThreeMonthPeriodStartAndEndDate(date) {

        const currentDate = new Date(date);
        const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0);

        return {
            start: GetDate3(startDate),
            end: GetDate3(endDate)
        };
    }

    function getNextSevenDaysDate(date) {
        const inputDate = new Date(date);
        inputDate.setDate(inputDate.getDate() + 7);
        return inputDate;
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faCalendarDays} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.plan_choose_termin}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => func(false)} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <FormSpace />
                    <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.plan_choose_termin_}</p>
                    <SelectInput default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={value} items={periods} width={250} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => ChangePeriod(id)} />
                    <FormSpace />
                    <FormControlLabel label={<p style={{ ...styles.TextXSmall }}>{lang.plan_hide_expired}</p>}
                        control={<Checkbox checked={expired} size={'small'} onChange={() => setExpired(!expired)} />}
                    />

                </div>
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <Button onClick={() => Press()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.ok}</Button>
                        <Button onClick={() => func(false)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, marginLeft: 20 }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}

export const DialogTypChoose = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogTypChoose lang={lang} theme={props.theme} radius={props.radius} func={FilterResult.bind(this)} />

            ------------------------------------------------------------
            
            const TerminResult = (typ, date_start, date_end) => {
                
            }
    */
    const lang = props.lang;

    // TYPY PLANU
    const typs = [
        { id: 99, enabled: true, name: lang.all_records },
        { id: 0, enabled: true, name: lang.calendar_type[0] },
        { id: 1, enabled: true, name: lang.calendar_type[1] },
        { id: 2, enabled: true, name: lang.calendar_type[2] },
        { id: 3, enabled: props.system.sms_notify == false ? false : true, name: lang.calendar_type[3] },
    ];

    const [value, setValue] = useState(-1);

    const [width, setWidth] = useState(window.innerWidth);

    const buttonHeight = 0;
    const dialogWidth = 500;
    const dialogHeight = 200 + buttonHeight;


    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);

    const Change = (id) => {

        setValue(id);
        func(true, id);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faFilter} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.plan_choose_typ}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => func(false)} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <FormSpace />
                    <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.plan_choose_typ_}</p>
                    <SelectInput default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={value} items={typs} width={250} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => Change(id)} />
                </div>
                {/* 
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <Button onClick={() => Press()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.ok}</Button>
                        <Button onClick={() => func(false)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, marginLeft: 20 }}>{lang.close}</Button>
                    </div>
                </div>
                */}
            </Paper>
        </Backdrop>
    );
}