/*
*
* =================================================================================================================
* TREE NODES - Tvorba, Úprava a Výber (selecting)
* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faLayerGroup, faPlus, faEye, faPen, faShareNodes, faFolderTree, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import { Button, IconButton, TextField, Paper, Select, MenuItem, Fade } from '@mui/material';
import { styles } from './styles.js';
import './items.css';
import { Icon } from './items.js';
import { Debug } from './functions.js';


/* *******************************************************
    VRATI ZOZNAM VSETKYCH ID, KTORE SU AKO CHILD

    tree = [{id: 1, parent_id: 0}, ... ]

    USAGE:
    ------
    let array = GetNodeChilds(tree, 1, true);

    nodes:      - array of items
    id:         - root node, v ktorom sa budú hladať childs
    onlyEnabled - len enabled NODES

******************************************************* */

export const GetNodeChilds = (tree, id, onlyEnabled) => {
    var result = [];
    var array = [];
    if (onlyEnabled == true) {
        array = tree.filter(x => (x.parent_id == id) && (x.enabled == true));
    } else {
        array = tree.filter(x => (x.parent_id == id));
    }
    array.forEach(item => {
        result.push(item.id);
        var tmp = GetNodeChilds(tree, item.id);
        if (tmp.length > 0) {
            tmp.forEach(element => {
                result.push(element);
            });
        }
    });

    return result;
}



// *********************************************************************************************************
// *********************************************************************************************************
//
//      TREE NODES
//
// *********************************************************************************************************
// *********************************************************************************************************
export const TreeNodes = (props) => {
    /*
            ----------------------------
            NODE TREE - EDITACIA / VYBER 
            ----------------------------
            
            EDITOVANIE:
            <TreeNodes nodes={roles} title={lang.users_roles} editMode={true} showDisabled={true} allowSelectRoot={true} allowDelete={false} deleteMode={true} lang={props.lang} theme={props.theme} radius={props.radius} func={NodesResult.bind(this)} />

            VÝBER NODE:
            <TreeNodes nodes={roles} title={lang.users_roles} editMode={false} showDisabled={false} allowSelectRoot={true} allowDelete={true} deleteMode={false} lang={props.lang} theme={props.theme} radius={props.radius} func={NodesResult.bind(this)} />

                RESULT:
                ------------------------------------------------------------------
                const NodesResult = (typ, nodes, tree) => {
                    if (typ == 1) {
                        // ULOZIT
                        Debug(nodes);
                        Debug(tree);
                    }
                    if (typ == 2) {
                        // OZNACENY NODE
                        Debug(nodes);
                    }
                }

                INPUT DATA
                ------------------------------------------------------------------                
                const roles = [
                    { id: 1, enabled: true, label: 'CEO', parent_id: 0 },
                    { id: 2, enabled: true, label: 'GM', parent_id: 1 },
                ]
                parent_id       -> 0 - ROOT NODE
                

                PARAMS:
                ------------------------------------------------------------------                
                dialog          -> zobrazí TREE v dialogovom okne
                editMode        -> true = editovací mód, false = mód výberu
                deleteMode      -> true = DELETE NODES, false = DISABLE NODES (zostavajú a array, ale su DISABLED)
                allowDelete     -> true - je povolené mazanie NODES (tlačítko je viditeľné)
                allowSelectRoot -> povolí výber Hlavného ROOT
                showDisabled    -> zobrazuje aj neaktívne NODES

                DEFINOVANE PREKLADY:
                ------------------------------------------------------------------                
                lang.node_name      : názov nového child - pozícia
                lang.node_parent    : vlastník
                lang.choose         : vybrať
                lang.save           : uložiť
                lang.close          : zavrieť
    */


    let color = global.themes[props.theme];

    const [redraw, setRedraw] = useState(false);
    const [nodes, setNodes] = useState(false);
    const [deleteMode, setDeleteMode] = useState(props.deleteMode == true ? true : false);
    const [editMode, setEditMode] = useState(props.editMode == true ? true : false);
    const [allowDelete, setAllowDelete] = useState(props.allowDelete == true ? true : false);
    const [allowSelectRoot, setAllowSelectRoot] = useState(props.allowSelectRoot == true ? true : false);
    const [showDisabled, setShowDisabled] = useState(props.showDisabled == true ? true : false);
    const [isChanged, setChanged] = useState(false); // DOSLO K ZMENE?
    const [showTree, setShowTree] = useState(false);
    const [startFade, setStartFade] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // SETTINGS
    const maxLevel = 6; // maximálna hĺbka stromu (počet SUB-CHILDS)
    const hoverColor = '#F0FAFF';
    const colorLightBlue = '#E2ECFF';
    const colorWhite = '#FFFFFF';
    const colorBlack = '#000000';
    const colorMediumGray = '#888888';
    const colorRed = '#F44336';
    const colorDarkRed = '#C3170B';
    const colorBack = '#F0F0F0';
    const colorGray = '#C0C0C0';
    const colorDisabled = '#D0D0D0';
    const colorOn = '#228822';
    const colorOff = '#666666';

    let lang = props.lang;
    let defaulName = lang.node_name;
    let { func } = props;
    var running = false;

    useEffect(() => {

        if (running == false) {
            setEditMode(props.editMode == true ? true : false);

            if (props.nodes != false) {
                var items = props.nodes;
                items.forEach(item => {
                    items.deletable = false;
                });
                setNodes(props.nodes);
            } else {
                setNodes([{ id: 1, enabled: true, label: 'CEO', parent_id: 0 }]);
            }

            setShowTree(true);

            setTimeout(() => {
                setStartFade(true);
            }, 150);

            running = true;
        }

        return () => {
        };

    }, [props.editMode]);

    const Close = () => {
        func(0, false, false);
    }

    const Redraw = () => {
        setRedraw(!redraw);
        setShowTree(false);

        setTimeout(() => {
            setShowTree(true);
        }, 1);
    }

    const NodeResult = (typ, item) => {
        var node_id = 0;

        if (typ == 1) {
            // DELETE NODE / DISABLE NODE
            if (deleteMode == true) {
                node_id = item.id;
                var items = DeleteNodes(nodes, item.id);
                items = items.filter(x => x.id != item.id);
                setNodes(items);
            } else {
                item.enabled = false;
            }
            setChanged(true);
            Redraw();
        }
        if (typ == 2) {
            // ADD NEW CHILD NODE
            var id = 0;
            nodes.forEach(item => {
                if (parseInt(item.id) >= id) {
                    id = parseInt(item.id) + 1;
                }
            });

            var tmp = nodes;
            let data = { id: id, enabled: true, label: defaulName, parent_id: item, note: '', shared: false, deletable: true };
            tmp.push(data);

            node_id = id;

            setChanged(true);
            Redraw();
        }

        if (typ == 3) {
            // EDITACIA TEXTU - ZMENA NAZVU
            node_id = item.id;

            var tmp = nodes.find(x => x.id === item.id);
            if (tmp != undefined) {
                tmp.label = item.label;
            }

            setChanged(true);
            Redraw();
        }

        if (typ == 4) {
            // VYBER POLOZKY - AK JE CHOOSE MODE (editMode == false)
            func(2, item, false);
        }

        if (typ == 5) {
            // ZMENA PARENT_ID
            node_id = item.id;

            var tmp = nodes.find(x => x.id === item.id);
            if (tmp != undefined) {
                tmp.parent_id = item.parent_id;
            }

            setChanged(true);
            Redraw();
        }

        if (typ == 6) {
            // ENABLE NODE
            item.enabled = !item.enabled;
            setChanged(true);
            Redraw();
        }
    }


    const DeleteNodes = (nodes_, node_id) => {
        var elements = nodes_;
        var array = [];
        var items = elements.filter(x => x.parent_id === node_id);
        items.forEach(item => {
            array.push(item.id);
        });

        array.forEach(item => {
            elements = elements.filter(x => x.id != item);

            elements = DeleteNodes(elements, item);
        });

        return (elements);
    }



    const GetChilds = (nodeList, id) => {
        var result = [];

        var array = [];
        array = nodeList.filter(x => (x.parent_id === id) && x.enabled == true);

        array.forEach(item => {
            result.push(item.id);
            var tmp = GetChilds(nodeList, item.id);
            if (tmp.length > 0) {
                tmp.forEach(element => {
                    result.push(element);
                });
            }
        });

        return result;
    }


    const CreateHierarchyTree = () => {
        // Vytvorenie zoznamu ID, ktore patria jednotlivým NODES
        var result = {};
        nodes.forEach(item => {
            if (item.enabled == true) {
                var array = GetChilds(nodes, item.id);
                array.push(item.id);
                result[item.id] = array;
            }
        });

        return (result);
    }


    const Process = () => {
        // RETURN NODES + CREATE HIERARCHY TREE (kto má koho ako CHILD)
        let tree = CreateHierarchyTree();

        var data = JSON.parse(JSON.stringify(nodes));
        data.forEach(item => {
            delete (item.deletable);
        });

        func(1, data, tree);

    }



    // *********************************************************************************************************
    // TREE NODE
    // *********************************************************************************************************
    const TreeNode = (props) => {

        const [items, setItems] = useState([]);
        const [level, setLevel] = useState(props.level);
        const [over, setOver] = useState(false);
        const [overID, setOverID] = useState(0);

        // UPRAVA NAZVU
        const [editing, setEditing] = useState(-1);
        const [changing, setChange] = useState(-1);
        const [parentID, setParentID] = useState(0);
        const [selectedID, setSelectedID] = useState(0);
        const [childsID, setChildsID] = useState([]);
        const [text, setText] = useState('');

        const dotSize = editMode == true ? '2px' : '1px';
        const roleOffset = 30;
        const roleHeight = 30;
        const roleWidth = 250;
        const lineSize = 10;
        const inputRef = useRef(null);


        let { func } = props;
        var running = false;
        let color = global.themes[props.theme];

        useEffect(() => {
            if (running == false) {
                let parent_id = props.parentID;
                var tmp = [];
                if (showDisabled == true) {
                    tmp = nodes.filter(x => (x.parent_id === parent_id));
                } else {
                    tmp = nodes.filter(x => (x.parent_id === parent_id) && (x.enabled == true));
                }

                setItems(tmp);

                running = true;
            }

            return () => {
            };

        }, []);

        const GetColor = () => {
            var theme = '#205CCA';
            switch (level) {
                case 0:
                    theme = '#F99891';
                    break;

                case 1:
                    theme = '#000000';
                    break;

                case 2:
                    theme = '#205CCA';
                    break;

                case 3:
                    theme = '#AA0000';
                    break;

                case 4:
                    theme = '#00AA00';
                    break;

                case 5:
                    theme = '#BB00BB';
                    break;

                case 6:
                    theme = 'cyan';
                    break;

                case 7:
                    theme = 'brown';
                    break;

                case 8:
                    theme = 'orange';
                    break;

                case 9:
                    theme = '#C3170B';
                    break;
            }

            return theme
        }

        const DeleteItem = (item) => {
            func(1, item);
        }

        const AddNode = () => {
            func(2, props.parentID);
        }

        const EditNode = (item) => {
            if (editMode == true) {
                setText(item.label);
                setEditing(item.id);
                setTimeout(() => {
                    inputRef.current.focus();
                }, 300);
            }
        }

        const UpdateNode = (item) => {
            let data = { id: item.id, label: text };
            func(3, data);
        }

        const UpdateParent = (item) => {
            let data = { id: item.id, parent_id: parentID };
            setChange(false);
            func(5, data);
        }

        const ChangeHover = (value) => {
            if (editMode == true) {
                setOver(value);
            } else {
                setOverID(value);
            }
        }

        const SelectItem = (item) => {
            if (editMode == false) {
                if (item.id == 1) {
                    if (allowSelectRoot == true) {
                        func(4, item);
                    }
                } else {
                    func(4, item);
                }
            }
        }

        const ChangeParent = (item) => {
            // ZMENA PARENT_ID     
            let childs = GetNodeChilds(nodes, item.id);
            childs.push(item.id);
            setChildsID(childs);
            setParentID(item.parent_id);
            setChange(item.id);
        }


        const ChangeParentID = (id) => {
            setParentID(id);
        }

        const EnableParent = (item) => {
            func(6, item);
        }

        return (
            <div style={{ marginLeft: lineSize * 4 }}>
                {items.length > 0 ? items.map((item, index) => (
                    <div key={item.id} id={'node-' + item.id} style={{ marginLeft: roleOffset, marginTop: 1, borderLeft: props.parentID > 0 && index < items.length ? dotSize + ' dotted ' + GetColor() : '' }}>
                        <div style={{ ...styles.BlockRowRaw, paddingTop: 10 }}>
                            {props.parentID > 0 ?
                                <div style={{ ...styles.Block, marginLeft: 1, width: lineSize, height: roleHeight / 2, borderBottom: dotSize + ' dotted ' + GetColor() }}></div>
                                : null}
                            <div style={{ ...styles.BlockRowRaw }} onMouseOverCapture={() => ChangeHover(item.id)} onMouseLeave={() => ChangeHover(0)}>
                                <Paper
                                    onClick={() => SelectItem(item)}
                                    elevation={editMode ? 3 : overID == item.id ? 3 : 1}
                                    style={{
                                        ...styles.BlockRow,
                                        width: changing == item.id ? roleWidth + 100 : roleWidth,
                                        height: item.parent_id == 0 ? roleHeight * 2 : roleHeight,
                                        border: '1px solid #707070',
                                        borderRadius: global.radius,
                                        borderLeft: props.parentID > 0 ? '5px solid ' + GetColor() : '1px solid #707070',
                                        backgroundColor: props.disabled == true ? colorDisabled : item.enabled == false ? colorGray : editMode == false ? overID == item.id ? hoverColor : colorWhite : props.parentID == 0 ? colorLightBlue : colorWhite,
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        cursor: 'pointer'
                                    }}>
                                    <div style={{ ...styles.Block, width: 30, height: roleHeight }}>
                                        {item.id > 1 && editMode == true ?
                                            <IconButton onClick={() => EnableParent(item)} style={{ width: 20, height: 20 }}>
                                                <FontAwesomeIcon style={{ width: 16, color: item.enabled ? colorOn : colorOff }} icon={item.enabled == true ? faToggleOff : faToggleOn} />
                                            </IconButton>
                                            : null}
                                        {editMode == false && item.shared ?
                                            <FontAwesomeIcon style={{ width: 16, color: colorGray }} icon={faShareNodes} />
                                            : null}
                                    </div>
                                    <div onDoubleClick={() => EditNode(item)} style={{ ...styles.BlockLeft, height: item.parent_id == 0 ? roleHeight * 2 : roleHeight, width: changing == item.id ? roleWidth + 100 : roleWidth - 60 }}>
                                        {editing != item.id ?
                                            changing != item.id ?
                                                <p style={{ ...item.parent_id == 0 ? styles.TextSmall : styles.TextTiny, marginLeft: 0, textAlign: 'center', color: over > 0 ? colorDarkRed : colorBlack, fontWeight: over > 0 ? '600' : '400' }}>{item.label}</p>
                                                :
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div style={{ ...styles.Block, width: '20%', height: roleHeight }}>
                                                        <p style={{ ...styles.TextTiny }}>{lang.node_parent}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: '80%', height: roleHeight }}>
                                                        <Select
                                                            value={parentID}
                                                            onChange={event => ChangeParentID(event.target.value)}
                                                            size='small'
                                                            style={{ backgroundColor: '#FFFFFF', height: 28, width: '96%' }}
                                                            MenuProps={{ PaperProps: { sx: { ".MuiList-root": { backgroundColor: '#FFFFFF', }, } }, sx: { width: 200, "& .MuiMenuItem-root.Mui-selected": { backgroundColor: global.theme_blue, }, } }}
                                                            sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: '#000000' } }}
                                                        >
                                                            <MenuItem defaultChecked value={0}><em>{lang.choose}</em></MenuItem>
                                                            {nodes.map((item, index) => (
                                                                childsID.includes(item.id) ? null :
                                                                    <MenuItem key={item.id} value={item.id}>
                                                                        <p style={{ ...styles.TextTiny }}>{item.label}</p>
                                                                    </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                </div>
                                            :
                                            <TextField
                                                inputRef={inputRef}
                                                value={text}
                                                size="small"
                                                autoCapitalize="off"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                style={{ width: '100%' }}
                                                variant="outlined"
                                                onInput={e => setText(e.target.value)}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        UpdateNode(item)
                                                    }
                                                }}
                                            />
                                        }
                                    </div>
                                    <div style={{ ...styles.Block, height: item.parent_id == 0 ? roleHeight * 2 : roleHeight, width: 30 }}>
                                        {over == item.id && changing == -1 && item.id > 1 ?
                                            <IconButton onClick={() => ChangeParent(item)} style={{ ...styles.ButtonIcon, width: 20, height: 20, backgroundColor: colorWhite }}>
                                                <FontAwesomeIcon style={{ width: 12, color: colorMediumGray }} icon={faPen} />
                                            </IconButton>
                                            : null}
                                        {changing === item.id ?
                                            <IconButton onClick={() => UpdateParent(item)} style={{ ...styles.ButtonIcon, width: 20, height: 20, backgroundColor: colorWhite }}>
                                                <FontAwesomeIcon style={{ width: 12, color: colorMediumGray }} icon={faCheck} />
                                            </IconButton>
                                            : null}
                                    </div>
                                </Paper>
                                {over == item.id && item.parent_id > 0 && (allowDelete == true || item.deletable == true) && changing != item.id ?
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.Block, width: lineSize / 2, height: roleHeight }}>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: 40, height: roleHeight }}>
                                            <IconButton onClick={() => DeleteItem(item)} style={{ ...styles.ButtonIcon, width: 30, height: 30, backgroundColor: colorWhite }}>
                                                <FontAwesomeIcon style={{ width: 16, color: colorRed }} icon={faXmark} />
                                            </IconButton>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>

                        <TreeNode theme={props.theme} parentID={item.id} level={level + 1} disabled={props.disabled == true ? true : item.enabled == false ? true : false} func={NodeResult.bind(this)} />

                    </div >
                )) : null}
                {props.parentID > 0 && editMode == true && level < maxLevel ?
                    <div style={{ ...styles.BlockRowRaw, paddingTop: 0, marginLeft: roleOffset }}>
                        <div style={{ ...styles.Block, marginTop: 1, width: 1, height: 15, borderLeft: dotSize + ' dotted ' + GetColor() }}></div>
                        <div style={{ ...styles.Block, width: lineSize, height: 30 }}>
                            <div style={{ ...styles.Block, borderTop: dotSize + ' dotted ' + GetColor() }}></div>
                        </div>
                        <div style={{ width: 20, marginTop: 5 }}>
                            <IconButton onClick={() => AddNode()} style={{ ...styles.ButtonIcon, width: 20, height: 20 }}>
                                <FontAwesomeIcon style={{ width: 10, color: colorMediumGray }} icon={faPlus} />
                            </IconButton>
                        </div >
                    </div >
                    : null}
            </div >
        )
    }

    /*
    *
    * =================================================================================================================
    * MAIN RENDER
    * =================================================================================================================
    *
    */
    return (
        props.dialog == true ?
            // *********************************************************************************************************
            // DIALOG
            // *********************************************************************************************************
            <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
                <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                    <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                        <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                            <Icon theme={props.theme} icon={faFolderTree} />
                        </div>
                        <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        </div>
                        <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                            <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                                <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    {/* BODY */}
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height, minHeight: editMode == true ? nodes != false ? ((nodes.length) * 85) + 80 : undefined : undefined, backgroundColor: colorBack }}>
                            <div style={{ width: '100%' }}>
                                {nodes != false ?
                                    <div style={{ ...editMode == true ? styles.BlockLeft : styles.BlockCenter }}>
                                        <TreeNode theme={props.theme} parentID={0} level={0} func={NodeResult.bind(this)} />
                                    </div>
                                    : null}
                            </div>
                        </div >
                    </div >

                </Paper >
            </Backdrop>
            :
            // *********************************************************************************************************
            // WINDOW
            // *********************************************************************************************************
            <div style={{ minHeight: nodes != false ? ((nodes.length) * 85) + 80 : undefined, width: '100%' }}>
                {nodes != false ?
                    <div style={{ ...styles.BlockLeft }}>
                        {showTree ?
                            <div>
                                <Fade in={startFade}>
                                    <div>
                                        <TreeNode theme={props.theme} parentID={0} level={0} disabled={false} func={NodeResult.bind(this)} />

                                        {editMode == true ?
                                            <div style={{ ...styles.BlockRowRaw, marginLeft: 140, marginTop: 50 }}>
                                                <div style={{ ...styles.BlockLeft, width: 200 }}>
                                                    {isChanged ?
                                                        <Button onClick={() => Process()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_dark }}>{lang.save}</Button>
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: 200 }}>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                </Fade>
                            </div>
                            : null}

                    </div>
                    : null}
            </div>
    );
}