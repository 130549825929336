/*
*
* =================================================================================================================

    DAVKOVA UPRAVA ZAZNAMOV

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <BatchUpdate organization={props.organization} user={props.user} permissions={permissions} system={system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={BatchResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { Avatar, EmptyList, FormAutoComplete, FormError, FormFlags, FormNodes, FormNote, FormOK, FormSelectChips, FormSpace, FormText, FormYesNo, Icon, Loading, SelectInput, ShowError, ShowOK, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, Chip, FormControlLabel, IconButton, MenuItem, Pagination, Paper, Radio, RadioGroup, Select, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faArrowDown, faLayerGroup, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetLeftPosition, GetPages } from './functions';
import { ProductSelect } from './product_select';
import { CustomerSelect } from './customers';

export const BatchUpdate = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let batchTyp = props.model_id == global.model.products ? 0 : 1;
    let endPoint = batchTyp == 0 ? 'product_data' : 'customer_data';
    let eshop = props.system.eshop_enabled;

    let defaultFilter = { enabled: true };

    // ZDROJ PRODUKTOV / ZAKAZNIKOV
    let selectionTypes = [
        // PRODUKTY
        { id: 1, typ: 0, name: lang.batch_selection_type_selected },
        { id: 2, typ: 0, name: lang.batch_selection_type_category },
        { id: 3, typ: 0, name: lang.batch_selection_type_all },

        // ZAKAZNICI
        { id: 11, typ: 1, name: lang.batch_selection_type_selected_customers },
        { id: 12, typ: 1, name: lang.batch_selection_type_category_customers },
        { id: 13, typ: 1, name: lang.batch_selection_type_all_customers },

    ];

    // PARAMETRE - ČO SA BUDE MENIŤ
    // TYP:0 - produkt
    // TYP:1 - zákazník
    let valueTypes = [
        // PRODUKTY
        { id: 0, typ: 0, enabled: true, name: lang.batch_value_select },
        { id: 1, typ: 0, enabled: true, name: lang.product_portal_enabled },   // PORTAL
        { id: 2, typ: 0, enabled: true, name: lang.product_category },         // PRODUKTOVA KATEGORIA
        { id: 3, typ: 0, enabled: true, name: lang.order_types },              // TYPY OBJEDNAVOK
        { id: 4, typ: 0, enabled: true, name: lang.manufacturers },            // VYROBCOVIA
        { id: 5, typ: 0, enabled: true, name: lang.vendors },                  // DODAVATELIA
        { id: 6, typ: 0, enabled: true, name: lang.flags },                    // FLAGS
        { id: 7, typ: 0, enabled: true, name: lang.product_unit },             // JEDNOTKA
        { id: 8, typ: 0, enabled: true, name: lang.product_quantity_package }, // POCET KUSOV V BALENI
        { id: 9, typ: 0, enabled: true, name: lang.product_details },          // POPIS BALENIA
        { id: 10, typ: 0, enabled: true, name: lang.product_guarantee_ },      // DĹŽKA ZÁRUKY
        { id: 11, typ: 0, enabled: true, name: lang.price_list_coeficient },   // KOEFICIENT VYPOCTU CENY        
        { id: 12, typ: 0, enabled: true, name: lang.price_flatten },           // FLATTEN PRICE - pevna cena z koeficientu

        // ZAKAZNICI
        { id: 50, typ: 1, enabled: eshop, name: lang.customer_b2b },           // OZNACENIE ZAKAZNIKA AKO B2B, enabled iba ak je eshop spustený
        { id: 51, typ: 1, enabled: true, name: lang.customer_category },            // ZMENA KATEGORIE

    ];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [error, setError] = useState('');
    const [info, setInfo] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [selectedType, setSelectedType] = useState(batchTyp == 0 ? 1 : 11);

    // DATA
    const [valueTypeID, setValueTypeID] = useState(0);
    const [value, setValue] = useState(false);

    // PRODUKTY
    const [selectProducts, setSelectProducts] = useState(false);

    // CISELNIKY
    const [categories, setCategories] = useState(false);
    const [orderTypes, setOrderTypes] = useState(false);
    const [vendors, setVendors] = useState(false);
    const [manufacturers, setManufacturers] = useState(false);
    const [flags, setFlags] = useState(false);

    // KATEGORIE
    const [categoryID, setCategoryID] = useState(0);
    const [subCategory, setSubCategory] = useState(true);
    const [category, setCategory] = useState('');

    const [activeOnly, setActiveOnly] = useState(false);
    const [protocol, setProtocol] = useState('');

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    const menuHeight = 110;
    const buttonHeight = 80;
    const viewWidth = '96%';
    const productsHeight = 280;

    let column1 = 220;
    let column2 = 300;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_data();

            running = true;
        }

    }, []);

    const db_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + endPoint, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {

                if (batchTyp == 0) {
                    setCategories(json.categories);
                    setOrderTypes(json.order_types);
                    setVendors(json.vendors);
                    setManufacturers(json.manufactures);
                    setFlags(json.flags);
                }
                else {
                    setCategories(json.categories);
                }
            }

            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async () => {
        setBusy(true);

        setError('');
        setInfo('');

        var array = [];
        var value_ = value;

        if (selectedType == 1 || selectedType == 11) {
            // ID produktov do array
            if (items != false) {
                items.forEach(item => {
                    array.push(item.id);
                });
            }
        }

        if (valueTypeID == 3 || selectedType == 13) {
            var tmp = [];
            value.forEach(element => {
                tmp.push({ id: element.id });
            });
            value_ = tmp;
        }

        try {
            const response = await fetch(
                global.db_url + 'batch_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    batch_typ: batchTyp,
                    selected_type: selectedType,
                    array: array,
                    category_id: categoryID,
                    sub_category: subCategory,
                    active_only: activeOnly,
                    // PARAMS
                    value_type: valueTypeID,
                    value: value_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                //setShowOK(true);
                setInfo(lang.batch_updated_ok);
                setProtocol(json.protocol);
            } else {
                //setShowError(true);
                setError(lang.batch_updated_error);
            }
            setRedrawRequired(true);
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const ProductResult = (typ, ids, items_) => {

        if (typ == true && ids != false) {
            var array = items == false ? [] : items;
            items_.forEach(item => {
                let count = array.find(x => x.id == item.id);
                if (count == undefined) {
                    array.push(item);
                }
            });
            setItems(array);
        }

        setSelectProducts(false);
    }

    const CustomersResult = (typ, ids, items_) => {
        Debug(items_);
        if (typ == true && ids != false) {
            var array = items == false ? [] : items;
            items_.forEach(item => {
                let count = array.find(x => x.id == item.id);
                if (count == undefined) {
                    array.push(item);
                }
            });
            setItems(array);
        }

        setSelectProducts(false);
    }

    const ItemDelete = (item) => {
        var tmp = items.filter(x => x.id != item.id);

        if (tmp.length == 0) {
            tmp = false;
        }

        setItems(tmp);
    }

    const DeleteProducts = () => {
        setItems(false);
        setInfo('');
        setError('');
    }

    const ChangeType = (id) => {
        setSelectedType(id);
        setCategoryID(0);
        setItems(false);
        setInfo('');
        setError('');
    }

    const ChangeCategory = (id) => {
        if (categories != false) {
            var tmp = categories.find(x => x.id == id);
            if (tmp != undefined) {
                setCategory(tmp.label);
            }
        }
        setCategoryID(id);
        setInfo('');
        setError('');
    }

    const ChangeParam = (id) => {
        setValueTypeID(id);

        if (id == 1) {
            setValue(false);
        }
        if (id == 2) {
            setValue(-1);
        }
        if (id == 3) {
            setValue([]);
        }
        if (id == 4) {
            setValue(-1);
        }
        if (id == 5) {
            setValue(-1);
        }
        if (id == 6) {
            setValue([]);
        }
        if (id == 7) {
            setValue(lang.piece);
        }
        if (id == 8) {
            setValue('1');
        }
        if (id == 9) {
            setValue('');
        }
        if (id == 10) {
            setValue('24');
        }
        if (id == 11) {
            setValue(true);
        }
        if (id == 12) {
            setValue(true);
        }
        setInfo('');
        setError('');
    }

    const UpdateData = () => {
        db_update();
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 800 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.batch_update}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.Block, height: menuHeight }}>
                            <div style={{ ...styles.BlockRowRaw, height: menuHeight - 40 /*, borderBottom: '1px solid #D0D0D0' */ }}>
                                <div style={{ ...styles.Block, height: menuHeight - 40, width: undefined }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.batch_selection_type}:</p>
                                </div>
                                <div style={{ ...styles.Block, height: menuHeight - 40, width: undefined, marginLeft: 10 }}>
                                    <SelectInput theme={props.theme} lang={lang} value={selectedType} items={selectionTypes.filter(x => x.typ == batchTyp)} field={'name'} width={200} label={lang.mobil} radius={global.radius} func={(id) => ChangeType(id)} />
                                </div>
                                <div style={{ ...styles.Block, height: menuHeight - 40, width: undefined }}>
                                    {selectedType == 1 ?
                                        <Button onClick={() => setSelectProducts(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180, marginLeft: 20 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {selectedType == 1 ? lang.batch_add : selectedType == 2 ? lang.batch_category_select : ''}
                                        </Button>
                                        : null}

                                    {selectedType == 2 ?
                                        <FormNodes placeholder={lang.batch_category_select} value={categoryID} items={categories} editing={true} marginTop={0} title={''} column1={10} column2={200} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeCategory(id)} />
                                        : null}

                                    {selectedType == 11 ?
                                        <Button onClick={() => setSelectProducts(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180, marginLeft: 20 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {selectedType == 11 ? lang.batch_add_customers : selectedType == 12 ? lang.batch_category_select : ''}
                                        </Button>
                                        : null}

                                    {selectedType == 12 ?
                                        <FormNodes placeholder={lang.batch_category_select} value={categoryID} items={categories} editing={true} marginTop={0} title={''} column1={10} column2={200} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeCategory(id)} />
                                        : null}
                                </div>
                            </div>
                            <div style={{ ...styles.BlockCenter, height: 40 }}>
                                <FontAwesomeIcon style={{ height: 28, color: color.black }} icon={faArrowDown} />
                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        {selectedType == 1 || selectedType == 11 ?
                            <div style={{ ...styles.BlockCenter, width: '98%', height: productsHeight, backgroundColor: color.back, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.Block, width: undefined, height: 46 }}>
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{batchTyp == 0 ? lang.products_list : lang.customers_list} <span style={{ marginLeft: 10, fontWeight: '400' }}>({batchTyp == 0 ? lang.order_products : lang.customers_quantity}: {items != false ? items.length : 0})</span></p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: undefined, height: 46, marginLeft: 20 }}>
                                        {items != false ? items.length > 0 ?
                                            <Button onClick={() => DeleteProducts()} style={{ ...styles.ButtonOutlined }}>
                                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faXmark} />
                                                {lang.delete}
                                            </Button>
                                            : null : null}
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockLeft, flexDirection: 'row', flexWrap: 'wrap', width: viewWidth, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                    {items != false ? items.map((item, index) => (
                                        <Chip key={item.id} variant='outlined' label={
                                            <div style={{ ...styles.BlockRow }}>
                                                <div style={{ ...styles.Block, height: 30 }}>
                                                    <Avatar picture image={batchTyp == 0 ? item.image : item.avatar} size={24} theme={props.theme} />
                                                </div>
                                                <div style={{ ...styles.Block, height: 30 }}>
                                                    {batchTyp == 0 ?
                                                        <p style={{ ...styles.TextTiny, marginLeft: 8 }}>{item.name}</p>
                                                        :
                                                        <p style={{ ...styles.TextTiny, marginLeft: 8 }}>{item.company} {item.name} {item.surname}</p>
                                                    }
                                                </div>
                                            </div>
                                        } style={{ margin: 2, backgroundColor: color.white }} onDelete={() => ItemDelete(item)}></Chip>
                                    )) :
                                        <div style={{ ...styles.Block, paddingTop: 50 }}>
                                            <EmptyList row small lang={lang} />
                                        </div>
                                    }
                                </div >
                            </div>
                            : null}

                        {selectedType == 2 || selectedType == 12 ?
                            <div style={{ ...styles.Block, width: dialogWidth, height: productsHeight, backgroundColor: color.back, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                {categoryID > 0 ?
                                    <div style={{ ...styles.Block, width: column1 + column2 + 20, backgroundColor: color.white, borderRadius: global.radius }}>
                                        <FormSpace />
                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.product_category}: {category}</p>
                                        <FormSpace />
                                        <FormYesNo value={subCategory} editing={true} title={lang.batch_sub_categories} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setSubCategory(value)} />
                                        <FormNote title={lang.batch_sub_categories_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                        <FormSpace />
                                        <FormYesNo value={activeOnly} editing={true} title={lang.batch_active} column1={column1} column2={column2} yes={lang.batch_active_1} no={lang.batch_active_2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setActiveOnly(value)} />
                                        <FormSpace />
                                    </div>
                                    : null}
                            </div>
                            : null}

                        {selectedType == 3 || selectedType == 13 ?
                            <div style={{ ...styles.Block, width: dialogWidth, height: productsHeight, backgroundColor: color.back, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div style={{ ...styles.Block, width: column1 + column2 + 20, backgroundColor: color.white, borderRadius: global.radius }}>
                                    <FormSpace />
                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{batchTyp == 0 ? lang.batch_selection_type_all_ : lang.batch_selection_type_all_customers}</p>
                                    <FormSpace />
                                    <FormYesNo value={activeOnly} editing={true} title={lang.batch_active} column1={column1} column2={column2} yes={lang.batch_active_1} no={lang.batch_active_2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setActiveOnly(value)} />
                                    <FormSpace />
                                </div>
                            </div>
                            : null}

                        <div style={{ ...styles.Block, width: dialogWidth, minHeight: dialogHeight - global.dialog_bar_height - menuHeight - productsHeight - buttonHeight, backgroundColor: color.white }}>
                            <div style={{ ...styles.Block, width: dialogWidth, minHeight: dialogHeight - global.dialog_bar_height - menuHeight - productsHeight - buttonHeight - 80, backgroundColor: color.white }}>
                                <FontAwesomeIcon style={{ height: 28, color: color.black }} icon={faArrowDown} />
                                <p style={{ ...styles.TextXSmall, fontWeight: '600', marginTop: 10 }}>{lang.batch_value}</p>
                                <div style={{ ...styles.Block, marginTop: 10 }}>
                                    <SelectInput theme={props.theme} lang={lang} value={valueTypeID} items={valueTypes.filter(x => x.typ == batchTyp && x.enabled == true)} field={'name'} width={300} label={lang.mobil} radius={global.radius} func={(id) => ChangeParam(id)} />
                                    <FormSpace />
                                    {valueTypeID == 1 ?
                                        <FormYesNo value={value} editing={true} title={lang.product_portal_enabled} column1={undefined} column2={undefined} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setValue(id)} />
                                        : null}

                                    {valueTypeID == 2 ?
                                        <FormNodes value={value} items={categories} editing={true} title={lang.product_category} column1={undefined} column2={250} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setValue(id)} />
                                        : null}

                                    {valueTypeID == 3 ? props.system.order_types == true ?
                                        <FormSelectChips value={value} items={orderTypes} field={'name'} editing={true} title={lang.order_types} column1={150} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setValue(id)} />
                                        : null : null}

                                    {valueTypeID == 4 ?
                                        <FormAutoComplete value={value} items={manufacturers} field={'name'} editing={true} title={lang.manufacturer} column1={undefined} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setValue(id)} />
                                        : null}

                                    {valueTypeID == 5 ?
                                        <FormAutoComplete value={value} items={vendors} field={'name'} editing={true} title={lang.vendor} column1={undefined} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setValue(id)} />
                                        : null}

                                    {valueTypeID == 6 ?
                                        <FormFlags help_id={4} value={value} items={flags} field={'name'} editing={true} title={lang.flags} width={200} column1={undefined} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setValue(id)} />
                                        : null}

                                    {valueTypeID == 7 ?
                                        <FormText require redraw help_id={9} value={value} editing={true} title={lang.product_unit} column1={undefined} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setValue(txt)} />
                                        : null}

                                    {valueTypeID == 8 ?
                                        <FormText require redraw value={value} editing={true} title={lang.product_quantity_package} column1={undefined} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setValue(txt)} />
                                        : null}

                                    {valueTypeID == 9 ?
                                        <FormText require redraw value={value} editing={true} title={lang.product_details} column1={undefined} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setValue(txt)} />
                                        : null}

                                    {valueTypeID == 10 ?
                                        <FormText require redraw value={value} editing={true} title={lang.product_guarantee} column1={undefined} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setValue(txt)} />
                                        : null}

                                    {valueTypeID == 11 ?
                                        <FormYesNo value={value} editing={true} title={lang.price_list_coeficient} column1={undefined} column2={undefined} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setValue(id)} />
                                        : null}

                                    {valueTypeID == 12 ?
                                        <>
                                            <FormNote title={lang.price_flatten_note} column1={undefined} column2={undefined} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                        </>
                                        : null}

                                    {valueTypeID == 50 ?
                                        <FormYesNo value={value} editing={true} title={lang.customer_b2b} column1={undefined} column2={undefined} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setValue(id)} />
                                        : null}

                                    {valueTypeID == 51 ?
                                        <FormNodes value={value} items={categories} editing={true} title={lang.product_category} column1={undefined} column2={250} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setValue(id)} />
                                        : null}
                                </div>
                            </div>
                            <div style={{ ...styles.Block, width: dialogWidth, minHeight: 80, backgroundColor: color.white }}>
                                {/* ALERT */}
                                {error != '' ?
                                    <FormError theme={props.theme} error={error} small />
                                    : null}
                                {info != '' ?
                                    <FormOK text={info} sub_text={protocol} small theme={props.theme} />
                                    : null}
                            </div>
                        </div>

                        <div style={{ ...styles.Block, width: dialogWidth, height: buttonHeight }}>
                            {valueTypeID > 0 && (categoryID > 0 || items != false || (selectedType == 3 || selectedType == 13)) ?
                                <Button onClick={() => UpdateData()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 180 }}>
                                    {lang.batch_update_exec}
                                </Button>
                                : null}
                        </div>

                    </div >
                </div >

                {selectProducts == true ?
                    batchTyp == 0 ?
                        <ProductSelect filters only_sku={true} country_id={props.locale.id} showVariations={true} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductResult.bind()} />
                        :
                        <CustomerSelect showVariations={true} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomersResult.bind()} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}


