/*
*
* =================================================================================================================
* NASTAVENIE ORGANIZACIE

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <SettingsCustomize organization={organization} user={user} settings={props.settings} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { DialogDates, FormError, FormLabel, FormNote, FormSelect, FormSpace, FormSubLabel, FormText, FormYesNo, Line, Loading, ShowError, ShowOK } from './items';
import { styles } from './styles';
import { Button, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { GetTodayYear } from './functions_date';
import { ConvertToPrice, Debug, IsEmpty } from './functions';

export const SettingsSystem = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let permissions = global.permissions;
    let color = global.themes[props.theme];
    let currency = props.locale.currency;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [index, setIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState(false);
    const [dph, setDPH] = useState(0);
    const [precisions, setPrecisions] = useState(0);
    const [priceCoeficientEnabled, setPriceCoeficientEnabled] = useState(false);
    const [priceCoeficient, setPriceCoeficient] = useState('1');
    const [pricelistMultiLanguage, setPricelistMultiLanguage] = useState(false);
    const [productAutoCode, setProductAutoCode] = useState(false);
    const [productCodeChecking, setProductCodeChecking] = useState(false);
    const [productCode1, setProductCode1] = useState('');
    const [productCode2, setProductCode2] = useState('');
    const [productCode3, setProductCode3] = useState('');
    const [productCode4, setProductCode4] = useState('');
    const [productCodeEnabled1, setProductCodeEnabled1] = useState(false);
    const [productCodeEnabled2, setProductCodeEnabled2] = useState(false);
    const [productCodeEnabled3, setProductCodeEnabled3] = useState(false);
    const [productCodeEnabled4, setProductCodeEnabled4] = useState(false);
    const [productCodeVendorEnabled, setProductCodeVendorEnabled] = useState(false);
    const [productCodeEanEnabled, setProductCodeEanEnabled] = useState(false);
    const [productBook1, setProductBook1] = useState(false);
    const [productBookEshop1, setProductBookEshop1] = useState(false);
    const [productBookLabel1, setProductBookLabel1] = useState(false);
    const [productCodeSearch1, setProductCodeSearch1] = useState(false);
    const [productBook2, setProductBook2] = useState(false);
    const [productBookEshop2, setProductBookEshop2] = useState(false);
    const [productBookLabel2, setProductBookLabel2] = useState(false);
    const [productCodeSearch2, setProductCodeSearch2] = useState(false);
    const [productBook3, setProductBook3] = useState(false);
    const [productBookEshop3, setProductBookEshop3] = useState(false);
    const [productBookLabel3, setProductBookLabel3] = useState(false);
    const [productCodeSearch3, setProductCodeSearch3] = useState(false);
    const [productCodeSearch4, setProductCodeSearch4] = useState(false);
    const [productsB2B, setProductsB2B] = useState(false);
    const [showCostPrices, setShowCostPrices] = useState(false);
    const [showPricesDPH, setShowPricesDPH] = useState(false);
    const [manufacturersSearch, setManufacturersSearch] = useState(false);
    const [vendorsSearch, setVendorsSearch] = useState(false);
    const [stocks, setStocks] = useState(false);
    const [customersCode1, setCustomersCode1] = useState('');
    const [customersCode2, setCustomersCode2] = useState('');
    const [customersCode3, setCustomersCode3] = useState('');
    const [customersCode4, setCustomersCode4] = useState('');
    const [customersCodeEnabled1, setCustomersCodeEnabled1] = useState(false);
    const [customersCodeEnabled2, setCustomersCodeEnabled2] = useState(false);
    const [customersCodeEnabled3, setCustomersCodeEnabled3] = useState(false);
    const [customersCodeEnabled4, setCustomersCodeEnabled4] = useState(false);
    const [portalStocks, setPortalStocks] = useState(false);
    const [orderTypes, setOrderTypes] = useState(false);
    const [distributors, setDistributors] = useState(false);
    const [blogVisibility, setBlogVisibility] = useState(true);
    const [reklamaciaExportImages, setReklamaciaExportImages] = useState(false);
    const [reklamaciaEmails, setReklamaciaEmails] = useState(false);
    const [reklamaciaEmailSK, setReklamaciaEmailSK] = useState('');
    const [reklamaciaEmailCZ, setReklamaciaEmailCZ] = useState('');
    const [reklamaciaEmailEN, setReklamaciaEmailEN] = useState('');
    const [exportDeleteDays, setExportDeleteDays] = useState(7);

    // ZOZNAM platobných možností
    const [currencies, setCurrencies] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;

    // DEFAULT ITEMS
    const precisionItems = [{ id: 0, label: lang.system_precision_1 }, { id: 1, label: lang.system_precision_2 }];
    const menuWidth = global.sub_menu_width;
    const settingsWidth = dialogWidth - menuWidth > 600 ? 600 : dialogWidth - menuWidth;

    const column1 = 180;
    const column2 = 370;
    const offset = 20;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get();
            Debug('LOCALE');
            Debug(props.organization.currencies);
            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var item = json.settings;
                if (item == false) {
                    item = global.settings_user_default;
                }
                setStoreData(item);
                // DATA
                AssignData(item);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        let product_codes = item.product_codes != false ? JSON.parse(item.product_codes) : false;
        let customers_codes = item.customers_codes != false ? JSON.parse(item.customers_codes) : false;

        setDPH(item.dph);
        setPrecisions(item.precisions);
        setPriceCoeficientEnabled(item.price_coeficient_enabled == false ? false : true);
        setPriceCoeficient(item.price_coeficient);
        setPricelistMultiLanguage(item.pricelist_multi_language == false ? false : true);
        setProductAutoCode(item.product_auto_code == false ? false : true);
        setProductCodeChecking(item.product_code_checking == false ? false : true);
        setProductCodeEnabled1(product_codes == false ? false : product_codes[0].enabled);
        setProductCode1(product_codes == false ? '' : product_codes[0].label);
        setProductCodeEnabled2(product_codes == false ? false : product_codes[1].enabled);
        setProductCode2(product_codes == false ? '' : product_codes[1].label);
        setProductCodeEnabled3(product_codes == false ? false : product_codes[2].enabled);
        setProductCode3(product_codes == false ? '' : product_codes[2].label);
        setProductCode4(product_codes == false ? '' : product_codes[5].label);
        setProductCodeEnabled4(product_codes == false ? false : product_codes[5].enabled);
        setProductCodeVendorEnabled(product_codes == false ? false : product_codes[3].enabled);
        setProductCodeEanEnabled(product_codes == false ? false : product_codes[4].enabled);
        setProductBook1(item.product_book_1 == false ? false : true);
        setProductBook2(item.product_book_2 == false ? false : true);
        setProductBook3(item.product_book_3 == false ? false : true);
        setProductBookEshop1(item.product_book_eshop_1 == false ? false : true);
        setProductBookEshop2(item.product_book_eshop_2 == false ? false : true);
        setProductBookEshop3(item.product_book_eshop_3 == false ? false : true);
        setProductBookLabel1(item.product_book_label_1);
        setProductBookLabel2(item.product_book_label_2);
        setProductBookLabel3(item.product_book_label_3);
        setProductCodeSearch1(item.product_code_search_1 == false ? false : true);
        setProductCodeSearch2(item.product_code_search_2 == false ? false : true);
        setProductCodeSearch3(item.product_code_search_3 == false ? false : true);
        setProductCodeSearch4(item.product_code_search_4 == false ? false : true);
        setProductsB2B(item.products_b2b_b2c == false ? false : true);
        setManufacturersSearch(item.search_manufacturers == false ? false : true);
        setVendorsSearch(item.search_vendors == false ? false : true);
        setStocks(item.stocks == false ? false : true);
        setCustomersCodeEnabled1(customers_codes == false ? false : customers_codes[0].enabled);
        setCustomersCode1(customers_codes == false ? '' : customers_codes[0].label);
        setCustomersCodeEnabled2(customers_codes == false ? false : customers_codes[1].enabled);
        setCustomersCode2(customers_codes == false ? '' : customers_codes[1].label);
        setCustomersCodeEnabled3(customers_codes == false ? false : customers_codes[2].enabled);
        setCustomersCode3(customers_codes == false ? '' : customers_codes[2].label);
        setCustomersCodeEnabled4(customers_codes == false ? false : customers_codes[3].enabled);
        setCustomersCode4(customers_codes == false ? '' : customers_codes[3].label);
        setPortalStocks(item.portal_show_stock == false ? false : true);
        setOrderTypes(item.order_types == false ? false : true);
        setDistributors(item.distributors == false ? false : true);
        setShowCostPrices(item.show_cost_prices == false ? false : true);
        setShowPricesDPH(item.show_prices_dph == false ? false : true);
        setBlogVisibility(item.blog_visibility == false ? false : true);
        setReklamaciaExportImages(item.reklamacia_export_images == false ? false : true);
        setReklamaciaEmails(item.reklamacia_emails == false ? [{ id: 1, email: '' }, { id: 2, email: '' }, { id: 3, email: '' }] : JSON.parse(item.reklamacia_emails));
        if (item.reklamacia_emails != false) {
            var emails = JSON.parse(item.reklamacia_emails);

            var email_sk = emails.find(x => x.id == 1);
            if (email_sk != undefined) {
                setReklamaciaEmailSK(email_sk.email);
            }

            var email_cz = emails.find(x => x.id == 2);
            if (email_cz != undefined) {
                setReklamaciaEmailCZ(email_cz.email);
            }

            var email_en = emails.find(x => x.id == 3);
            if (email_en != undefined) {
                setReklamaciaEmailEN(email_en.email);
            }

        }
        setExportDeleteDays(item.export_delete_days);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti

        storeData.dph = dph;
        storeData.precisions = precisions;
        storeData.price_coeficient_enabled = priceCoeficientEnabled;
        storeData.price_coeficient = priceCoeficient;
        storeData.product_auto_code = productAutoCode;
        storeData.product_book_1 = productBook1;
        storeData.product_book_2 = productBook2;
        storeData.product_book_3 = productBook3;
        storeData.product_book_eshop_1 = productBookEshop1;
        storeData.product_book_eshop_2 = productBookEshop2;
        storeData.product_book_eshop_3 = productBookEshop3;
        storeData.product_code_search_1 = productCodeSearch1;
        storeData.product_code_search_2 = productCodeSearch2;
        storeData.product_code_search_3 = productCodeSearch3;
        storeData.stocks = stocks;
        storeData.portal_show_stock = portalStocks;
        storeData.order_types = orderTypes;
        storeData.distributors = distributors;
        storeData.show_cost_prices = showCostPrices;
        storeData.show_prices_dph = showPricesDPH;
    }

    const GetData = () => {
        // získanie údajov do reťazca

        let product_codes = [
            { id: 0, enabled: productCodeEnabled1, label: productCode1 },
            { id: 1, enabled: productCodeEnabled2, label: productCode2 },
            { id: 2, enabled: productCodeEnabled3, label: productCode3 },
            { id: 3, enabled: productCodeVendorEnabled, label: 'vendor' },
            { id: 4, enabled: productCodeEanEnabled, label: 'ean' },
            { id: 5, enabled: productCodeEnabled4, label: productCode4 },
        ];

        let customers_codes = [
            { id: 0, enabled: customersCodeEnabled1, label: customersCode1 },
            { id: 1, enabled: customersCodeEnabled2, label: customersCode2 },
            { id: 2, enabled: customersCodeEnabled3, label: customersCode3 },
            { id: 3, enabled: customersCodeEnabled4, label: customersCode4 },
        ];

        let data = {
            id: storeData.id,
            organization_id: props.organization.id,
            user_id: props.user.id,
            dph: dph,
            precisions: precisions,
            price_coeficient_enabled: priceCoeficientEnabled,
            price_coeficient: ConvertToPrice(priceCoeficient, precisions == 0 ? 2 : 3),
            pricelist_multi_language: pricelistMultiLanguage,
            product_auto_code: productAutoCode,
            product_code_checking: productCodeChecking,
            product_codes: JSON.stringify(product_codes),
            product_book_1: productBook1,
            product_book_2: productBook2,
            product_book_3: productBook3,
            product_book_eshop_1: productBook1 == false ? false : productBookEshop1,
            product_book_eshop_2: productBook2 == false ? false : productBookEshop2,
            product_book_eshop_3: productBook3 == false ? false : productBookEshop3,
            product_book_label_1: productBookLabel1,
            product_book_label_2: productBookLabel2,
            product_book_label_3: productBookLabel3,
            product_code_search_1: productCodeSearch1,
            product_code_search_2: productCodeSearch2,
            product_code_search_3: productCodeSearch3,
            product_code_search_4: productCodeSearch4,
            products_b2b_b2c: productsB2B,
            show_cost_prices: showCostPrices,
            show_prices_dph: showPricesDPH,
            search_manufacturers: manufacturersSearch,
            search_vendors: vendorsSearch,
            stocks: stocks,
            portal_show_stock: portalStocks,
            customers_codes: JSON.stringify(customers_codes),
            order_types: orderTypes,
            distributors: distributors,
            blog_visibility: blogVisibility,
            reklamacia_export_images: reklamaciaExportImages,
            reklamacia_emails: JSON.stringify([{ id: 1, email: reklamaciaEmailSK.trim() }, { id: 2, email: reklamaciaEmailCZ.trim() }, { id: 3, email: reklamaciaEmailEN.trim() },]),
            export_delete_days: exportDeleteDays,
        }

        return data;
    }

    const Save = () => {
        var error = 0;
        setError('');

        // údaje sú OK - uložiť
        let data = GetData();
        Debug(data);

        if (index == 1) {
            if (productCodeEnabled1 == true) {
                error += IsEmpty(productCode1) == true ? 1 : 0;
            }
            if (productCodeEnabled2 == true) {
                error += IsEmpty(productCode2) == true ? 1 : 0;
            }
            if (productCodeEnabled3 == true) {
                error += IsEmpty(productCode3) == true ? 1 : 0;
            }
        }

        if (error == 0) {
            StoreEditing();
            setEditing(false);

            db_update(data);
        } else {
            setError(lang.required_red);
        }

    }

    const FormLabelResult = (value) => {
        if (value == 0) {
            setEditing(true);
        }
    }

    const ChangeSubMenu = (id) => {
        if (editing == false) {
            setIndex(id);
        }
    }


    return (
        <div style={{ ...styles.BlockCenter, backgroundColor: color.back, paddingTop: offset, paddingBottom: offset }}>

            {storeData != false ?
                <div style={{ ...styles.BlockRow, maxWidth: global.max_width }}>
                    <div style={{ ...styles.BlockCenter, backgroundColor: color.sub_menu_back, width: menuWidth, borderBottomLeftRadius: props.radius, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div onClick={() => ChangeSubMenu(0)} style={{ ...styles.ButtonSubMenu, backgroundColor: index == 0 ? color.white : '#00000000', color: index == 0 ? color.black : editing == true ? color.light_gray : color.dark, borderRightColor: index == 0 ? color.sub_menu_border : '#00000000' }}>{lang.system}</div>
                        <div onClick={() => ChangeSubMenu(2)} style={{ ...styles.ButtonSubMenu, backgroundColor: index == 2 ? color.white : '#00000000', color: index == 2 ? color.black : editing == true ? color.light_gray : color.dark, borderRightColor: index == 2 ? color.sub_menu_border : '#00000000' }}>{lang.customers}</div>
                        <div onClick={() => ChangeSubMenu(1)} style={{ ...styles.ButtonSubMenu, backgroundColor: index == 1 ? color.white : '#00000000', color: index == 1 ? color.black : editing == true ? color.light_gray : color.dark, borderRightColor: index == 1 ? color.sub_menu_border : '#00000000' }}>{lang.products}</div>
                        <div onClick={() => ChangeSubMenu(3)} style={{ ...styles.ButtonSubMenu, backgroundColor: index == 3 ? color.white : '#00000000', color: index == 3 ? color.black : editing == true ? color.light_gray : color.dark, borderRightColor: index == 3 ? color.sub_menu_border : '#00000000' }}>{lang.price_lists_}</div>
                        <div onClick={() => ChangeSubMenu(4)} style={{ ...styles.ButtonSubMenu, backgroundColor: index == 4 ? color.white : '#00000000', color: index == 4 ? color.black : editing == true ? color.light_gray : color.dark, borderRightColor: index == 4 ? color.sub_menu_border : '#00000000' }}>{lang.orders}</div>
                        <div onClick={() => ChangeSubMenu(7)} style={{ ...styles.ButtonSubMenu, backgroundColor: index == 7 ? color.white : '#00000000', color: index == 7 ? color.black : editing == true ? color.light_gray : color.dark, borderRightColor: index == 7 ? color.sub_menu_border : '#00000000' }}>{lang.reklamacie}</div>
                        <div onClick={() => ChangeSubMenu(8)} style={{ ...styles.ButtonSubMenu, backgroundColor: index == 8 ? color.white : '#00000000', color: index == 8 ? color.black : editing == true ? color.light_gray : color.dark, borderRightColor: index == 8 ? color.sub_menu_border : '#00000000' }}>{lang.exported_data_}</div>
                        <div onClick={() => ChangeSubMenu(6)} style={{ ...styles.ButtonSubMenu, backgroundColor: index == 6 ? color.white : '#00000000', color: index == 6 ? color.black : editing == true ? color.light_gray : color.dark, borderRightColor: index == 6 ? color.sub_menu_border : '#00000000' }}>{lang.blogs}</div>
                        {props.system.eshop_enabled == true ?
                            <div onClick={() => ChangeSubMenu(5)} style={{ ...styles.ButtonSubMenu, backgroundColor: index == 5 ? color.white : '#00000000', color: index == 5 ? color.black : editing == true ? color.light_gray : color.dark, borderRightColor: index == 5 ? color.sub_menu_border : '#00000000' }}>{lang.eshop}</div>
                            : null}
                    </div>

                    <div style={{ ...styles.BlockCenter, width: settingsWidth, borderBottomLeftRadius: props.radius, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <Paper elevation={global.elevation_form} style={{ ...styles.BlockCenter, width: column1 + column2 + (2 * offset), paddingTop: offset, paddingBottom: offset * 2, backgroundColor: color.white, borderRadius: props.radius }}>

                            {index == 0 ?
                                /*
                                *
                                * =================================================================================================================
                                * SYSTEM
                                * =================================================================================================================
                                *
                                */
                                <>
                                    <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.system_settings} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                    <FormSelect value={precisions} items={precisionItems} field={'label'} editing={editing} title={lang.system_precision} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setPrecisions(id)} />
                                    <FormNote title={lang.system_precision_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                </>
                                : null}

                            {index == 1 ?
                                /*
                                *
                                * =================================================================================================================
                                * PRODUKTY
                                * =================================================================================================================
                                *
                                */

                                <>
                                    <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.products} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                    <FormYesNo value={showCostPrices} editing={editing} title={lang.show_cost_prices} yes={lang.yes} no={lang.no} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setShowCostPrices(value)} />
                                    <FormYesNo value={showPricesDPH} editing={editing} title={lang.show_prices_dph} yes={lang.yes} no={lang.no} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setShowPricesDPH(value)} />
                                    <FormYesNo value={productCodeChecking} editing={editing} title={lang.product_code_checking} yes={lang.yes} no={lang.no} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductCodeChecking(value)} />
                                    <FormNote title={lang.product_code_checking_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormSpace />

                                    <FormYesNo value={productAutoCode} editing={editing} title={lang.modules_product_pattern} yes={lang.automatic} no={lang.manual} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductAutoCode(value)} />
                                    <FormNote title={lang.modules_product_auto} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormYesNo value={productCodeVendorEnabled} editing={editing} title={lang.product_code_vendor} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductCodeVendorEnabled(value)} />
                                    <FormYesNo value={productCodeEanEnabled} editing={editing} title={lang.product_code_ean} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductCodeEanEnabled(value)} />
                                    <FormSpace />
                                    <FormSpace />

                                    {/* DODATOCNE KODY */}
                                    <FormSubLabel title={lang.products_additional_codes} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormYesNo value={productCodeEnabled1} editing={editing} title={lang.product_code_external + ' 1'} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductCodeEnabled1(value)} />
                                    {productCodeEnabled1 == true ?
                                        <>
                                            <FormText redraw require value={productCode1} editing={editing} title={lang.code_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setProductCode1(txt)} />
                                            {props.system.pharminfo_enabled == true || props.system.pharmos_enabled == true ?
                                                <>
                                                    <FormYesNo help_id={20} value={productCodeSearch1} editing={editing} title={lang.stock_code_searchings} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductCodeSearch1(value)} />
                                                    <FormNote title={lang.stock_code_searchings_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                </>
                                                : null}
                                        </>
                                        : null}

                                    <FormSpace />
                                    <FormYesNo value={productCodeEnabled2} editing={editing} title={lang.product_code_external + ' 2'} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductCodeEnabled2(value)} />
                                    {productCodeEnabled2 == true ?
                                        <>
                                            <FormText redraw require value={productCode2} editing={editing} title={lang.code_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setProductCode2(txt)} />
                                            {props.system.pharminfo_enabled == true || props.system.pharmos_enabled == true ?
                                                <>
                                                    <FormYesNo help_id={20} value={productCodeSearch2} editing={editing} title={lang.stock_code_searchings} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductCodeSearch2(value)} />
                                                    <FormNote title={lang.stock_code_searchings_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                </>
                                                : null}
                                        </>
                                        : null}

                                    <FormYesNo value={productCodeEnabled3} editing={editing} title={lang.product_code_external + ' 3'} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductCodeEnabled3(value)} />
                                    {productCodeEnabled3 == true ?
                                        <>
                                            <FormText redraw require value={productCode3} editing={editing} title={lang.code_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setProductCode3(txt)} />
                                            {props.system.pharminfo_enabled == true || props.system.pharmos_enabled == true ?
                                                <>
                                                    <FormYesNo help_id={20} value={productCodeSearch3} editing={editing} title={lang.stock_code_searchings} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductCodeSearch3(value)} />
                                                    <FormNote title={lang.stock_code_searchings_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                </>
                                                : null}
                                        </>
                                        : null}

                                    <FormYesNo value={productCodeEnabled4} editing={editing} title={lang.product_code_external + ' 4'} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductCodeEnabled4(value)} />
                                    {productCodeEnabled4 == true ?
                                        <>
                                            <FormText redraw require value={productCode4} editing={editing} title={lang.code_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setProductCode4(txt)} />
                                            {props.system.pharminfo_enabled == true || props.system.pharmos_enabled == true ?
                                                <>
                                                    <FormYesNo help_id={20} value={productCodeSearch3} editing={editing} title={lang.stock_code_searchings} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductCodeSearch4(value)} />
                                                    <FormNote title={lang.stock_code_searchings_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                </>
                                                : null}
                                        </>
                                        : null}
                                    <FormSpace />

                                    {/* DODATOCNY CISELNIK */}

                                    <FormSubLabel title={lang.products_additional_codebooks} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormYesNo value={productBook1} editing={editing} title={lang.product_book + ' 1'} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductBook1(value)} />
                                    {productBook1 == true ?
                                        <>
                                            <FormText redraw require value={productBookLabel1} editing={editing} title={lang.product_book_name + ' 1'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setProductBookLabel1(txt)} />
                                            {props.system.eshop_enabled == true ?
                                                <>
                                                    <FormYesNo value={productBookEshop1} editing={editing} title={lang.eshop_codebook_published} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductBookEshop1(value)} />
                                                    {productBookEshop1 == true ?
                                                        <FormNote title={lang.eshop_codebook_published_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                        : null}
                                                </>
                                                : null}
                                        </>
                                        : null}

                                    <FormSpace />
                                    <FormYesNo value={productBook2} editing={editing} title={lang.product_book + ' 2'} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductBook2(value)} />
                                    {productBook2 == true ?
                                        <>
                                            <FormText redraw require value={productBookLabel2} editing={editing} title={lang.product_book_name + ' 1'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setProductBookLabel2(txt)} />
                                            {props.system.eshop_enabled == true ?
                                                <>
                                                    <FormYesNo value={productBookEshop2} editing={editing} title={lang.eshop_codebook_published} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductBookEshop2(value)} />
                                                    {productBookEshop2 == true ?
                                                        <FormNote title={lang.eshop_codebook_published_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                        : null}
                                                </>
                                                : null}
                                        </>
                                        : null}

                                    <FormSpace />
                                    <FormYesNo value={productBook3} editing={editing} title={lang.product_book + ' 3'} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductBook3(value)} />
                                    {productBook3 == true ?
                                        <>
                                            <FormText redraw require value={productBookLabel3} editing={editing} title={lang.product_book_name + ' 1'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setProductBookLabel3(txt)} />
                                            {props.system.eshop_enabled == true ?
                                                <>
                                                    <FormYesNo value={productBookEshop3} editing={editing} title={lang.eshop_codebook_published} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductBookEshop3(value)} />
                                                    {productBookEshop2 == true ?
                                                        <FormNote title={lang.eshop_codebook_published_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                        : null}
                                                </>
                                                : null}
                                        </>
                                        : null}
                                    <FormSpace />

                                    {/* TRIEDENIE PRODUKTOV */}

                                    <FormSubLabel title={lang.product_searchings} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormYesNo value={manufacturersSearch} editing={editing} title={lang.manufacturers_search} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setManufacturersSearch(value)} />
                                    <FormYesNo value={vendorsSearch} editing={editing} title={lang.vendors_search} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setVendorsSearch(value)} />
                                    <FormYesNo value={productsB2B} editing={editing} title={lang.products_settings_b2b_b2c} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setProductsB2B(value)} />
                                    <FormNote title={lang.products_settings_b2b_b2c_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormSpace />

                                    {/* SKLADY 
                                        <FormSpace />
                                        <FormLabel editing={editing} editButton={false} title={lang.stocks} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                        <FormYesNo value={stocks} editing={editing} title={lang.stocks_use} yes={lang.stock_multi} no={lang.stock_one} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setStocks(value)} />
                                        <FormNote title={lang.stocks_use_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                        <FormSpace />
                                        */}


                                </>
                                : null}


                            {index == 2 ?
                                /*
                                *
                                * =================================================================================================================
                                * ZAKAZNICI
                                * =================================================================================================================
                                *
                                */

                                <>
                                    <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.customers} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                    <FormYesNo value={customersCodeEnabled1} editing={editing} title={lang.product_code_external + ' 1'} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setCustomersCodeEnabled1(value)} />
                                    {
                                        customersCodeEnabled1 == true ?
                                            <FormText redraw require value={customersCode1} editing={editing} title={lang.code_name + ' 1'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCustomersCode1(txt)} />
                                            : null
                                    }
                                    <FormSpace />
                                    <FormYesNo value={customersCodeEnabled2} editing={editing} title={lang.product_code_external + ' 2'} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setCustomersCodeEnabled2(value)} />
                                    {
                                        customersCodeEnabled2 == true ?
                                            <FormText redraw require value={customersCode2} editing={editing} title={lang.code_name + ' 2'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCustomersCode2(txt)} />
                                            : null
                                    }
                                    <FormSpace />
                                    <FormYesNo value={customersCodeEnabled3} editing={editing} title={lang.product_code_external + ' 3'} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setCustomersCodeEnabled3(value)} />
                                    {
                                        customersCodeEnabled3 == true ?
                                            <FormText redraw require value={customersCode3} editing={editing} title={lang.code_name + ' 3'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCustomersCode3(txt)} />
                                            : null
                                    }
                                    <FormYesNo value={customersCodeEnabled4} editing={editing} title={lang.product_code_external + ' 4'} yes={lang.enabled} no={lang.disabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setCustomersCodeEnabled4(value)} />
                                    {
                                        customersCodeEnabled4 == true ?
                                            <FormText redraw require value={customersCode4} editing={editing} title={lang.code_name + ' 4'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCustomersCode4(txt)} />
                                            : null
                                    }
                                    <FormSpace />
                                    <FormSpace />

                                    {/* PORTAL - ZAKAZNICKY PORTAL */}
                                    <FormLabel editing={editing} editButton={false} title={lang.customers_portal} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                    <FormYesNo value={portalStocks} editing={editing} title={lang.customers_portal_stock} yes={lang.yes} no={lang.no} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setPortalStocks(value)} />
                                    <FormNote title={lang.customers_portal_stock_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormSpace />
                                </>
                                : null}


                            {index == 3 ?
                                /*
                                *
                                * =================================================================================================================
                                * CENNIKY
                                * =================================================================================================================
                                *
                                */

                                <>
                                    <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.system_price_creator} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />

                                    <FormYesNo value={priceCoeficientEnabled} editing={editing} title={lang.system_price_coeficient_enable} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setPriceCoeficientEnabled(value)} />
                                    {priceCoeficientEnabled == true ?
                                        <div>
                                            <FormText numeric redraw value={priceCoeficient} editing={editing} title={lang.system_price_coeficient} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPriceCoeficient(txt)} />
                                            <FormNote title={lang.system_price_coeficient_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                        </div>
                                        : null}
                                    <FormYesNo value={pricelistMultiLanguage} editing={editing} title={lang.pricelist_multi_language} yes={lang.yes} no={lang.no} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setPricelistMultiLanguage(value)} />
                                    <FormNote title={lang.pricelist_multi_language_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                </>
                                : null}



                            {index == 4 ?
                                /*
                                *
                                * =================================================================================================================
                                * OBJEDNAVKY PRODUKTOV
                                * =================================================================================================================
                                *
                                */

                                <>
                                    <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.orders_products} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                    <FormYesNo value={orderTypes} editing={editing} title={lang.order_types_label} yes={lang.yes} no={lang.no} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setOrderTypes(value)} />
                                    <FormNote title={lang.order_types_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormYesNo value={distributors} editing={editing} title={lang.distributors_use} yes={lang.yes} no={lang.no} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setDistributors(value)} />
                                    <FormNote title={lang.distributors_use_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                </>
                                : null}

                            {index == 8 ?
                                /*
                                *
                                * =================================================================================================================
                                * EXPORT ÚDAJOV
                                * =================================================================================================================
                                *
                                */

                                <>
                                    <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.exported_data_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                    <FormText numeric redraw value={exportDeleteDays} end={lang.export_delete_days_} editing={editing} title={lang.export_delete_days} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setExportDeleteDays(txt)} />
                                    <FormNote title={lang.export_delete_days_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                </>
                                : null}

                            {index == 6 ?
                                /*
                                *
                                * =================================================================================================================
                                * PRISPEVKY
                                * =================================================================================================================
                                *
                                */
                                <>
                                    <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.blogs} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                    <FormYesNo value={blogVisibility} editing={editing} title={lang.blog_all_countries} no={lang.blog_all_countries_yes} yes={lang.blog_all_countries_no} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setBlogVisibility(value)} />
                                    <FormNote title={lang.blog_all_countries_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                </>
                                : null}

                            {index == 7 ?
                                /*
                                *
                                * =================================================================================================================
                                * REKLAMACIE
                                * =================================================================================================================
                                *
                                */

                                <>
                                    <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.reklamacie} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                    <FormYesNo value={reklamaciaExportImages} editing={editing} title={lang.reklamacia_export_image} yes={lang.yes} no={lang.no} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setReklamaciaExportImages(value)} />
                                    <FormNote title={lang.reklamacia_export_image_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />

                                    <FormSpace />
                                    <FormSubLabel editing={editing} title={lang.reklamacia_email_note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                    {props.organization.currencies != false ? props.organization.currencies.map((item, index) => (
                                        item.id == 1 ?
                                            <FormText redraw value={reklamaciaEmailSK} editing={editing} title={lang.reklamacia_email_sk} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setReklamaciaEmailSK(txt)} />
                                            :
                                            item.id == 2 ?
                                                <FormText redraw value={reklamaciaEmailCZ} editing={editing} title={lang.reklamacia_email_cz} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setReklamaciaEmailCZ(txt)} />
                                                :
                                                item.id == 3 ?
                                                    <FormText redraw value={reklamaciaEmailEN} editing={editing} title={lang.reklamacia_email_en} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setReklamaciaEmailEN(txt)} />
                                                    : null
                                    )) : null}

                                </>
                                : null}

                            {index == 5 ?
                                /*
                                *
                                * =================================================================================================================
                                * ESHOP
                                * =================================================================================================================
                                *
                                */

                                <>
                                    <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.eshop} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                </>
                                : null}

                            {/* SAVE - CANCEL */}
                            {
                                editing == true ?
                                    <div style={{ ...styles.Block, paddingTop: 10 }}>
                                        <FormError theme={props.theme} error={error} small />

                                        <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                                            </div>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }

                        </Paper >
                        <FormSpace />

                    </div>

                </div>
                : null}

            {
                props.remote == true && props.remoteMobile == true ?
                    <FormSpace height={500} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null
            }
        </div >

    );
}


