/*
*
* =================================================================================================================

    VYBER USERa

    USAGE:
    -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    <UsersSelect groups organization={props.organization} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.user_select} token={props.token} func={() => setValue(false)} />

    PARAMS:
                groups          -> zobrazí skupiny užívateľov
                approve         -> poziadanie o schálenie presunu zásob
                excluded        -> zoznam ID, ktoré sa nebudú zobrazovať [id...]
                unlimited       -> zobrazí vsetkých uzivatelov
                user_stock      -> len zoznam užívateľov s užívateľsk'ým skladom
* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { Avatar, EmptyList, FormError, Icon, Loading, SelectInput, ShowError, ShowOK, SuperAdmin, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, FormControlLabel, IconButton, MenuItem, Pagination, Paper, Radio, RadioGroup, Select, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetPages, GetLeftPosition } from './functions';

export const UsersSelect = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = {
        enabled: true,
        groups: props.groups == true ? true : false,
        approve: props.approve == true ? true : undefined,
        excluded: props.excluded == undefined ? undefined : props.excluded,
        limited: props.limited,
        unlimited: props.unlimited,
        user_stock: props.user_stock
    };

    // IMAGES
    const user_default = require('./react/app/user.png');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [groups, setGroups] = useState(false);
    const [groupID, setGroupID] = useState(-1);
    const [selectedAll, setSelectedAll] = useState(false);

    // DATA
    const [value, setValue] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 900 ? 900 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    const menuHeight = 60;
    const rowHeight = 70;
    const viewWidth = '96%';
    const buttonHeight = props.multiple == true ? 80 : 0;
    const tableLabelHeight = 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            Debug(props.excluded);
            // UDAJE Z DB
            db_get(0, defaultFilter);

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        Debug(filter);
        try {
            const response = await fetch(
                global.db_url + 'users', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setGroups(json.groups);

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value, false);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);
        if (txt.length > 1) {
            var filter_ = defaultFilter;
            filter_.search = txt;
            db_get(0, filter_);
        }
        if (txt == '') {
            setGroupID(-1);
            db_get(0, defaultFilter);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        func(true, item, [item]);
    }

    const ChangeGroup = (id) => {
        setGroupID(id);
        var filter_ = defaultFilter;

        if (id != -1) {
            filter_.group_id = id;
        }
        db_get(0, filter_);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const SelectClose = () => {
        // MULTI SELECT
        var selected_id = [];
        var selected_items = [];
        items.forEach(item => {
            if (item.selected) {
                selected_id.push(item.id);
                selected_items.push(item);
            }
        });
        if (selected_id.length == 0) {
            selected_id = false;
            selected_items = false;
        }

        func(true, false, selected_items);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2100 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faUser} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                            {groups != false ?
                                <SelectInput disabled={false} default={lang.users_groups_all} theme={props.theme} enabled={true} lang={lang} value={groupID} items={groups} field={'label'} label={''} radius={global.radius} func={(id) => ChangeGroup(id)} />
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight - buttonHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.BlockRow, width: '96%', border: color.border }}>
                            {props.multiple == true ?
                                <div style={{ ...styles.Block, width: 40, height: tableLabelHeight }}>
                                    <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                </div>
                                : null}
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableLabelHeight }}>
                                </div>

                                <div style={{ ...styles.BlockLeft, width: '35%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.user}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '35%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.email}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: props.multiple == true ? 190 : 200, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{''}</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ ...styles.Block, width: viewWidth }}>

                            {items != false ? items.map((item, index) => (
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? global.elevation_over : global.elevation}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : item.id == props.user.id ? color.user_highlight : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '100%' }}>
                                        {props.multiple == true ?
                                            <div style={{ ...styles.Block, width: 40, minHeight: rowHeight }}>
                                                {/* SELECT */}
                                                <Checkbox style={{ padding: 0, margin: 0 }} checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                            </div>
                                            : null}
                                        <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: rowHeight + 10, minHeight: rowHeight, justifyContent: 'center' }}>
                                            <Avatar image={item.avatar} size={rowHeight - 10} theme={props.theme} />
                                        </div>

                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '35%', minHeight: rowHeight, justifyContent: 'center' }}>

                                            {props.settings.name_format == 0 ?
                                                <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name} {item.surname}</p>
                                                :
                                                <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.surname} {item.name}</p>
                                            }
                                        </div>

                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '35%', minHeight: rowHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXSmall }}>{item.email}</p>
                                        </div>

                                        <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 200, minHeight: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.role}</p>
                                            {item.super_admin == false ? null :
                                                <SuperAdmin theme={props.theme} lang={props.lang} />
                                            }
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}
                        </div>
                    </div >
                    {buttonHeight > 0 ?
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => SelectClose()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.ok}</Button>
                        </div>
                        : null}
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


