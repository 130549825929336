/*
*
* =================================================================================================================

    DODACIA ADRESA

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { FormButton, FormError, FormLabel, FormNote, FormSpace, FormText, FormYesNo, Icon, Loading, ShowError, ShowOK } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarth, faInfoCircle, faLayerGroup, faMagnifyingGlass, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ConvertToPrice, Debug, GetLeftPosition, IsEmpty } from './functions';
import { GoogleMap } from './geolocation';
import { DistributorsCodes } from './distributors_export';

export const CustomersAddress = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let customer_code_searching = props.system.pharminfo_enabled == true || props.system.pharmos_enabled == true ? true : false;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(true);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [enabled, setEnabled] = useState(true);
    const [shippingName, setShippingName] = useState('');
    const [shippingStreet, setShippingStreet] = useState('');
    const [shippingTown, setShippingTown] = useState('');
    const [shippingPSC, setShippingPSC] = useState('');
    const [shippingState, setShippingState] = useState('');
    const [shippingMobil, setShippingMobil] = useState('');
    const [shippingEmail, setShippingEmail] = useState('');
    const [shippingPhone, setShippingPhone] = useState('');
    const [shippingNote, setShippingNote] = useState('');
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [gpsAddress, setGPSAddress] = useState('');

    // DODATOCNE KODY
    const [erpCode, setErpCode] = useState('');
    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');

    // NACITANIE KODU - ADC / SUKL
    const [codeID, setCodeID] = useState(0);
    const [showDistributorCodes, setShowDistributorCodes] = useState(false);

    // MAPA
    const [showMap, setShowMap] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 870 ? 870 : height;

    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 150;
    const column2 = dialogWidth - 180;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                // UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_address', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    address_id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;

                    setStoreData(item);
                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_gps = async (address) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_get_gps', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    address: address
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.address != false) {
                    setLng(json.lng);
                    setLat(json.lat);
                    setGPSAddress(json.address);
                } else {
                    setLat(0);
                    setLng(0);
                    setGPSAddress('');
                    setError(lang.gps_not_found);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.customers_address,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                func(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const AssignData = (item) => {
        Debug(item);
        // priradenie údajov pri načítaní z DB        
        setEnabled(item.enabled == false ? false : true);
        setErpCode(item.erp_code);
        setShippingName(item.name);
        setShippingStreet(item.street);
        setShippingTown(item.town);
        setShippingPSC(item.psc);
        setShippingState(item.state);
        setShippingMobil(item.mobil);
        setShippingPhone(item.phone);
        setShippingEmail(item.email);
        setShippingNote(item.note);
        setLat(item.lat);
        setLng(item.lng);
        //setErpCode(item.erp_code);
        setCode1(item.code_1);
        setCode2(item.code_2);
        setCode3(item.code_3);
        setCode4(item.code_4);
    }

    const CopyAddress = () => {
        if (props.address_fakt != false) {
            setShippingName(props.address_fakt.name);
            setShippingStreet(props.address_fakt.street);
            setShippingTown(props.address_fakt.town);
            setShippingPSC(props.address_fakt.psc);
            setShippingState(props.address_fakt.state);
            setShippingMobil(props.address_fakt.mobil);
            setShippingPhone(props.address_fakt.phone);
            setShippingEmail(props.address_fakt.email);
        }
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.enabled = enabled;
        storeData.erp_code = erpCode;
        storeData.name = shippingName;
        storeData.street = shippingStreet;
        storeData.town = shippingTown;
        storeData.psc = shippingPSC;
        storeData.state = shippingState;
        storeData.mobil = shippingMobil;
        storeData.phone = shippingPhone;
        storeData.email = shippingEmail;
        storeData.note = shippingNote;
        storeData.lat = lat;
        storeData.lng = lng;
        //storeData.erp_code = erpCode;
        storeData.code_1 = code1;
        storeData.code_2 = code2;
        storeData.code_3 = code3;
        storeData.code_4 = code4;
    }

    const Save = () => {
        setError('');
        var error = false;

        error += IsEmpty(shippingStreet) == true ? 1 : 0;
        error += IsEmpty(shippingTown) == true ? 1 : 0;
        error += IsEmpty(shippingPSC) == true ? 1 : 0;

        if (error == 0) {

            let data = {
                id: props.item == false ? 0 : props.item.id,
                organization_id: props.organization.id,
                enabled: enabled,
                typ: 1,
                customer_id: props.customerID,
                name: shippingName,
                street: shippingStreet,
                town: shippingTown,
                psc: shippingPSC,
                state: shippingState,
                phone: shippingPhone,
                mobil: shippingMobil,
                email: shippingEmail,
                note: shippingNote,
                //erp_code: erpCode,
                code_1: code1,
                code_2: code2,
                code_3: code3,
                code_4: code4,
                lat: ConvertToPrice(lat, 5),
                lng: ConvertToPrice(lng, 5),
            };

            StoreEditing();

            db_update(data);

        } else {
            setError(lang.required_red);
        }
    }

    const GetGPS = () => {
        var err = 0;

        setError('');

        err = IsEmpty(shippingStreet) == true ? 1 : 0;
        err = IsEmpty(shippingTown) == true ? 1 : 0;

        if (err == 0) {
            var address = shippingStreet;
            if (shippingPSC != '') {
                address += ' ' + shippingPSC + ' ' + shippingTown;
            } else {
                address += ' ' + shippingTown;
            }
            if (shippingState != '') {
                address += ' ' + shippingState;
            }

            db_gps(address);
        } else {
            setError(lang.required_red);
        }

    }

    const ChangeCode = (id, value, button) => {
        if (button == true) {
            setCodeID(id);
            setShowDistributorCodes(true);
        } else {
            if (id == 1) {
                setCode1(value);
            }
            if (id == 2) {
                setCode2(value);
            }
            if (id == 3) {
                setCode3(value);
            }
            if (id == 4) {
                setCode4(value);
            }
        }
    }

    const DistributorCodesResult = (typ, item) => {
        setShowDistributorCodes(false);

        if (typ == true) {
            if (codeID == 1) {
                setCode1(item.key);
            }
            if (codeID == 2) {
                setCode2(item.key);
            }
            if (codeID == 3) {
                setCode3(item.key);
            }
            if (codeID == 4) {
                setCode4(item.key);
            }
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.customer_new_shop_address : lang.customer_shop_address}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.Block }}>

                            <FormSpace />
                            <div style={{ ...styles.BlockRow, width: column1 + column2 + 10 }}>
                                <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                    <FormLabel hide_border editing={editing} editButton={false} icon={faInfoCircle} title={lang.customer_shop_address} column1={column1} column2={200} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} />                                </div>
                                <div style={{ ...styles.BlockRight, width: '40%' }}>
                                    <Button onClick={() => CopyAddress()} style={{ ...styles.ButtonThemed, width: 240, height: 28, backgroundColor: color.light_gray, color: color.black }}>{lang.customer_address_equal}</Button>
                                </div>
                            </div>
                            <FormYesNo value={enabled} editing={editing} title={lang.customer_address_active} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />

                            <FormSpace />
                            {/*
                            <FormText redraw value={erpCode} editing={editing} title={lang.erp_code} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setErpCode(txt)} />
                            */}
                            {props.system.customers_codes[0].enabled == true ?
                                <FormText redraw value={code1} editing={editing} button={customer_code_searching == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.customers_codes[0].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(1, txt, press)} />
                                : null}
                            {props.system.customers_codes[1].enabled == true ?
                                <FormText redraw value={code2} editing={editing} button={customer_code_searching == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.customers_codes[1].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(2, txt, press)} />
                                : null}
                            {props.system.customers_codes[2].enabled == true ?
                                <FormText redraw value={code3} editing={editing} button={customer_code_searching == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.customers_codes[2].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(3, txt, press)} />
                                : null}
                            {props.system.customers_codes[3].enabled == true ?
                                <FormText redraw value={code4} editing={editing} button={customer_code_searching == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.customers_codes[3].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(4, txt, press)} />
                                : null}

                            <FormSpace />
                            <FormText redraw value={shippingName} editing={editing} title={lang.customer_shop_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setShippingName(txt)} />
                            <FormText require redraw value={shippingStreet} editing={editing} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setShippingStreet(txt)} />
                            <FormText require redraw value={shippingTown} editing={editing} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setShippingTown(txt)} />
                            <FormText require redraw value={shippingPSC} editing={editing} title={lang.psc} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setShippingPSC(txt)} />
                            <FormText redraw value={shippingState} editing={editing} title={lang.state} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setShippingState(txt)} />
                            <FormSpace />
                            <FormText redraw value={shippingMobil} editing={editing} title={lang.mobil} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setShippingMobil(txt)} />
                            <FormText redraw value={shippingPhone} editing={editing} title={lang.phone} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setShippingPhone(txt)} />
                            <FormText redraw value={shippingEmail} editing={editing} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setShippingEmail(txt)} />
                            <FormText redraw value={shippingNote} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setShippingNote(txt)} />


                            <FormSpace height={40} />

                            <FormLabel editing={false} editButton={true} buttonIcon={faSearch} icon={faEarth} title={lang.gps_coordinates} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => GetGPS()} />

                            <div style={{ ...styles.BlockRow, width: column1 + column2 + 10 }}>
                                <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                    <FormText redraw numeric value={lat} editing={editing} width={150} title={lang.gps_lat} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setLat(txt)} />
                                    <FormText redraw numeric value={lng} editing={editing} width={150} title={lang.gps_lng} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setLng(txt)} />
                                </div>
                                <div style={{ ...styles.BlockRight, width: '40%' }}>
                                    {lat != 0 && lng != 0 ?
                                        <Button onClick={() => setShowMap(true)} style={{ ...styles.ButtonOutlined, width: 150, marginTop: 2 }}>{lang.gps_show_map}</Button>
                                        : null}
                                </div>
                            </div>

                            {gpsAddress != '' ?
                                <FormNote marginBottom={0} title={gpsAddress} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                : null}


                            <FormSpace height={global.list_padding} />

                            {props.remote == true && props.remoteMobile == true ?
                                <FormSpace height={350} />
                                : null}

                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 10 }}>{lang.save}</Button>
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, marginLeft: 10 }}>{lang.cancel}</Button>
                        </div>
                    </div>
                </div >


                {showDistributorCodes == true ?
                    <DistributorsCodes customer remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={DistributorCodesResult.bind(this)} />
                    : null}

                {showMap == true ?
                    <GoogleMap gps={[{ lat: lat, lng: lng }]} lang={props.lang} theme={props.theme} radius={props.radius} func={() => setShowMap(false)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


