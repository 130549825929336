/*
*
* =================================================================================================================
* SettingsApplication

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <SettingsApplication organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { Colors, DialogImagePreview, DialogInfo, DialogLabel, DialogLogin, DialogTabs, DialogVideo, DialogYesNo, EmployeeSelect, EmptyList, FormError, FormLabel, FormNote, FormText, FormYesNo, Icon, Line, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, SwitchEnable, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Button, IconButton, Pagination, Paper, Switch, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCogs, faInfoCircle, faLayerGroup, faList, faPlusCircle, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetTodayYear } from './functions_date';
import { CheckPermission, Debug, GetPages } from './functions';
import { SettingsCustomize } from './settings_customize';
import { SettingsSystem } from './settings_system';
import { SettingsSMSNotify } from './settings_sms_notify';
import { SettingsModules } from './settings_modules';

export const SettingsApplication = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    const column1 = 180;
    const column2 = 370;
    const offset = 20;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // VALUES
    const [settingsID, setSettingsID] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 50;
    const menuHeight = 50;
    const rowHeight = 100;
    const menuWidth = global.sub_menu_width;

    const tabs = [
        { id: 0, enabled: true, label: lang.app_customize },
        { id: 1, enabled: props.user.super_admin == true ? true : false, label: lang.modules },
        { id: 2, enabled: props.user.super_admin == true ? true : false, label: lang.system },
        { id: 3, enabled: props.system.sms_enabled == true ? props.user.super_admin == true ? true : false : false, label: lang.sms_notifies },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);
    }

    const CustomizeResult = () => {

    }

    const SystemResult = () => {

    }

    const SMSResult = () => {

    }

    const ModulesResult = () => {

    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }} >
                    <div style={{ ...styles.Block, height: tabHeight }}>
                        <DialogTabs value={tabIndex} items={tabs} width={200} theme={props.theme} func={(id) => ChangeTab(id)} />
                    </div>
                    {tabIndex == 0 ?
                        // VZHĽAD - PRISPOSOBENIE
                        <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                                <div style={{ ...styles.Block, width: '96%' }}>

                                    <SettingsCustomize remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomizeResult.bind()} />

                                </div>
                            </div>
                        </div>
                        : null}

                    {tabIndex == 1 ?
                        // MODULY
                        <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, width: '96%' }}>

                                <SettingsModules remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ModulesResult.bind()} />

                            </div>
                        </div>
                        : null}

                    {tabIndex == 2 ?
                        //SYSTEM
                        <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, width: '96%' }}>

                                <SettingsSystem remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} system={props.system} lang={lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} height={props.height - global.widow_bar_height - tabHeight} func={SystemResult.bind()} />

                            </div>
                        </div>
                        : null}

                    {tabIndex == 3 ?
                        // SMS NOTIFIKACIE
                        <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - tabHeight, maxWidth: column1 + column2 + (2 * offset), msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, width: '96%' }}>

                                <SettingsSMSNotify remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={SMSResult.bind()} />

                            </div>
                        </div>
                        : null}
                </div>
            </div>

            {
                showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null
            }

        </Paper >
    );
}


