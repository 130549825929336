/*
*
* =================================================================================================================
* DIALOGOVE OKNA
* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, Form } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faCircle, faUser, faImage, faBars, faInfoCircle, faTrash, faQuestionCircle, faLayerGroup, faCog, faList, faPlus, faAngleLeft, faEye, faCheckCircle, faWarning, faMinus, faEnvelope, faPaperPlane, faFile, faQuestion, faDesktop, faLock, faPrint, faExclamation, faExclamationTriangle, faShare, faXmarkCircle, faPallet, faPalette, faBookOpen, faPhone, faAt, faPersonMilitaryToPerson, faPowerOff, faAngleRight, faCalculator, faPen, faSearch, faAngleDoubleDown, faAngleDown, faPlusCircle, faToggleOff, faToggleOn, faInfo, faListDots, faEllipsisV, faFileAlt, faSliders, faPercentage, faPercent, faBook, faAngleUp, faSortDown, faCircleInfo, faRefresh, faTableCells, faMobile, faMobileAlt, faPhoneAlt, faBell, faAlignLeft, faAlignCenter, faAlignRight, faFileLines, faCalendarDays, faQrcode } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete, Box, Checkbox, Chip, Collapse, Divider, LinearProgress, FormControlLabel, Grow, InputAdornment, ListItemIcon, ListItemText, Menu, OutlinedInput, Popover, Popper, Radio, RadioGroup, Switch, Tab, Tabs, Tooltip, unstable_useEnhancedEffect, useMediaQuery, Badge } from '@mui/material';
import { Button, IconButton, MenuItem, Select, TextField, Paper } from '@mui/material';
import { styles } from './styles.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import './items.css';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { Photos } from './photos.js';
import { PinCode } from './pin_code.js';

//import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { pdfjs } from 'react-pdf';
import { BinaryToData, CheckPermission, CheckPlugin, Debug, FormatMoney, FormatPieces, GetDefaultDesktop, GetImageLink, GetLeftPosition, GetPrice, GetTid, GetTopPosition, GetVersion, IsEmpty, ResetDashboard, ResetLogin, SaveValue, nonEmail } from './functions.js';
import { GetDate2, GetDate3, GetDatum2, GetDatumTime, GetDatumTime2, GetFromDatumTime, GetTimeHM, GetTodayMonth, GetTodayYear, GetTodayYearMonth, Today } from './functions_date.js';
import { red } from '@mui/material/colors';
import LinesEllipsis from 'react-lines-ellipsis';
import { BarChart, LineChart } from '@mui/x-charts';
import { NodeSelect } from './nodes_select.js';
import { Help } from './Help.js';
import { GoogleMap } from './geolocation.js';
import { DialogColors } from './webpage_designer.js';
import { CustomerSelect, CustomerSelectEmail } from './customers.js';
import { UsersSelect } from './users_select.js';
import { QRCodeSVG } from 'qrcode.react';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();


/*
*
* =================================================================================================================
* DIALOGS
* =================================================================================================================
*
*/


export const MenuInfo = (props) => {
    /*
            MENU - na lavej strane

            USAGE:
            <MenuInfo func={MenuTitlePress.bind(this)} />

            const MenuTitlePress = (value) => {
                Debug(value)
            }

    */
    const navigate = useNavigate();
    const [isBusy, setBusy] = useState(false);
    const [showLogout, setShowLogout] = useState(false);

    const avatar = require('./react/app/avatar.jpg');

    const avatarSize = 40;
    const infoSize = 60;
    const infoMargin = 5;


    var lang = props.lang;
    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {
        Debug(lang.yes)
        return () => {
        };

    }, [props.bodyID, props.user, props.language, props.organization, props.locale]);

    const Press = (type, data) => {
        func(type, data);
    }

    const Logout = (value) => {
        setShowLogout(false);
        if (value == true) {
            ResetLogin();
            navigate('/logout');
        }
    }

    const InfoBox = (props) => {
        return (
            <Paper elevation={3} style={{ ...styles.Block, width: infoSize, height: infoSize, border: '1px solid ' + color.dark, borderRadius: props.radius, marginLeft: infoMargin, marginRight: infoMargin, backgroundColor: '#00000000' }}>
                <div style={{ ...styles.Block, height: infoSize / 2 }}>
                    <p style={{ ...styles.TextTiny, color: color.light }}>{props.label}</p>
                </div>
                <div style={{ ...styles.Block, height: infoSize / 2 }}>
                    <p style={{ ...styles.TextSmall, color: color.white }}>{props.value}</p>
                </div>
            </Paper>
        )
    }

    return (
        <div style={{ ...styles.BlockCenter, width: global.menu_width, backgroundColor: color.menu_info, height: global.menu_info_height }} >
            <div style={{ ...styles.Block, height: global.menu_info_height }}>
                {props.user != false ?
                    <div style={{ ...styles.BlockRow }}>

                        <div style={{ ...styles.BlockRight, width: avatarSize + 10 }}>
                            <Avatar image={props.user.avatar} size={avatarSize} lang={props.lang} theme={props.theme} />
                        </div>

                        <div style={{ ...styles.BlockLeft, width: global.menu_width - avatarSize - 10 - 50, height: avatarSize, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextTiny, fontWeight: '500', letterSpacing: 0.25, color: color.white, marginLeft: 10 }}>{props.user.name} {props.user.surname}</p>
                            <p style={{ ...styles.TextXXTiny, color: color.light, marginLeft: 10, marginTop: 3 }}>{props.user.super_admin == true ? props.lang.super_admin : props.user.role}</p>
                            {global.testing == true ?
                                <p style={{ ...styles.TextXXTiny, color: color.light, marginLeft: 10, marginTop: 3 }}>{props.bodyID} user#{props.user.id}</p>
                                : null}
                        </div>

                        <div style={{ ...styles.Block, width: 50 }}>
                            <IconButton onClick={() => setShowLogout(true)} style={{ ...styles.ButtonIcon }}>
                                <FontAwesomeIcon style={{ width: 12, color: color.white }} icon={faPowerOff} />
                            </IconButton>

                        </div>
                    </div>
                    : null}
            </div>

            {/* 
            <div style={{ ...styles.BlockCenter, height: global.menu_info_height / 2 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <InfoBox id={0} label={'Jazyk'} value={lang.language} radius={props.radius} />
                    <InfoBox id={1} label={'mena'} value={props.locale != false ? props.locale.currency : ''} radius={props.radius} />
                    <InfoBox id={2} label={'CRM'} value={props.locale != false ? props.locale.state_short : ''} radius={props.radius} />
                </div>
            </div>
            */}

            {showLogout == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.logout_title} text={lang.logout_ask} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={Logout.bind(this)} />
                : null}
        </div >
    )

};



export const AppMenu = (props) => {
    /*
            MENU - na lavej strane

            USAGE:
            <Menu func={MenuPress.bind(this)} />

            const MenuPress = (value) => {
                Debug(value)
            }

            */

    const ai_flag = require('./react/app/ai_flag.png');

    let super_admin = props.user.super_admin == false ? false : true;
    let permissions = props.permissions;
    let sms = props.system.sms_notify == false ? false : true;
    let order_types = props.system.order_types == false ? false : true;
    let distributors = props.system.distributors == false ? false : true;
    let system = props.system;
    let ai_enabled = props.system.ai_enabled;

    // OCHRANA PIN KODOM
    const [ID, setID] = useState(-1);
    const [showPin, setShowPin] = useState(false);

    // MENU ITEMS
    const [childrenIndex, setChildrenIndex] = useState(0);
    const [openAcc, setOpenAcc] = useState(props.menuID);

    const icon_size = 34;

    var color = global.themes[props.theme];
    let { func } = props;

    useEffect(() => {
        Debug(props.settings);
        setOpenAcc(props.menuID);
        return () => {
        };

    }, [props.language, props.user, props.menuID]);

    const Press = (type, item) => {
        if (type > 0) {
            setChildrenIndex(item.id);
        }
        func(type, item);
    }

    const SetOpenAcc = (item) => {
        if (item.id == 0) {
            setOpenAcc(-1);
            Press(0)
        } else {
            if (openAcc == item.id) {
                setOpenAcc(-1);
                func(999, -1);
            } else {
                if (item.protect == true && props.pinEnabled == true) {
                    setID(item);
                    setShowPin(true);
                } else {
                    setOpenAcc(item.id);
                    func(999, item);
                }
            }
            setChildrenIndex(-1);
        }
    }

    function CheckModel(model) {
        var result = true;

        if (model != undefined) {
            result = system[model];
        }
        return result;
    }

    const Submenu = (props) => {
        var children = props.item;

        return (
            <div style={{ width: '100%', marginBottom: 0 }}>
                {
                    /* ------------------------------------------------
                        SUBMENU - CHILDRENS
                    ------------------------------------------------ */
                }
                {children != null ?
                    children.map(item => {
                        return (
                            item.enabled == true ?
                                (item.admin_only == true && super_admin == true) || (item.admin_only == false || item.admin_only == undefined) ?
                                    CheckPermission(super_admin, permissions[item.permission_column]).read == true || CheckPermission(super_admin, permissions[item.permission_column]).enabled == true || item.permission_column == undefined ?
                                        CheckModel(item.model) ?
                                            item.sms == true && sms == true || item.sms == undefined ?
                                                item.order_types == true && order_types == true || item.order_types == undefined ?
                                                    item.distributors == true && distributors == true || item.distributors == undefined ?
                                                        item.divider == true ?
                                                            <div key={item.id} style={{ ...styles.BlockLeft, marginTop: 16, marginBottom: 4 }}>
                                                                <p style={{ ...styles.TextXXTiny, color: color.lighter_blue, marginLeft: 20, letterSpacing: 0.5, fontWeight: '500' }}>{item.name.toUpperCase()}</p>
                                                                <div style={{ ...styles.Block, height: 1, backgroundColor: '#FFFFFF30', marginTop: 2 }}></div>
                                                            </div>
                                                            :
                                                            <Button key={item.id} onClick={() => Press(1, item)} style={{ ...styles.ButtonMenu, width: global.menu_width - 20, background: childrenIndex == item.id ? color.menu_select : null, borderLeft: childrenIndex == item.id ? '8px solid ' + color.menu_select_border : '8px solid #00000000', color: childrenIndex == item.id ? color.black : color.white }}>
                                                                <FontAwesomeIcon style={{ height: childrenIndex == item.id ? 12 : 8, marginRight: 10, marginLeft: 4 }} icon={childrenIndex == item.id ? faAngleRight : faCircle} />
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                                        <p style={{ ...styles.TextTiny, color: undefined }}>
                                                                            {item.name}
                                                                        </p>
                                                                        {item.ai == true && (permissions.ai == true || super_admin == true) && ai_enabled == true ?
                                                                            <img src={ai_flag} style={{ width: '100%', height: '100%', maxWidth: 14, maxHeight: 14, objectFit: 'contain', marginLeft: 5 }}></img>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                            </Button >
                                                        : null
                                                    : null
                                                : null
                                            : null
                                        : null
                                    : null
                                : null
                        )
                    })
                    : null
                }

            </div >
        )
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ ...styles.BlockMenu, width: global.menu_width, height: props.height, height: props.height, borderRadius: 0, background: color.menu_background /* backgroundImage: `url(${color.menu_image})`, backgroundSize: 'cover'*/ }}>
            <div style={{ ...styles.BlockCenter, height: props.height, alignItems: 'flex-start', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                {props.menuData != undefined ?
                    props.menuData.map((item, index) => (
                        item.enabled == true ?
                            (item.admin_only == true && super_admin == true) || (item.admin_only == false || item.admin_only == undefined) ?
                                CheckModel(item.model) ?
                                    CheckPermission(super_admin, permissions[item.permission_column]).read == true || item.permission_column == undefined ?
                                        <Accordion key={item.id} elevation={0} expanded={openAcc == item.id ? item.id == 0 ? false : true : false} onChange={() => SetOpenAcc(item)} style={{ ...styles.Block, width: '95%', marginTop: 0, marginBottom: 5, backgroundColor: '#00000000', cursor: 'pointer' }}  >
                                            <AccordionSummary style={{ ...styles.BlockRow, height: icon_size }}>
                                                <div style={{ ...styles.Block, width: icon_size, height: icon_size, borderRadius: icon_size / 2, marginLeft: 10, backgroundColor: openAcc == item.id ? item.id == 0 ? color.menu_info : '#00000020' : color.menu_info }}>
                                                    {item.icon != null ?
                                                        <FontAwesomeIcon style={{ height: openAcc == item.id ? 16 : 14, color: item.color == undefined ? color.white : openAcc == item.id ? color.white : item.color }} icon={openAcc == item.id ? item.id == 0 ? item.icon : faAngleDown : item.icon} />
                                                        :
                                                        <p style={{ ...styles.TextNormal, fontWeight: '600', color: item.color == undefined ? color.white : item.color }}>{item.name[0]}</p>
                                                    }
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: global.menu_width - icon_size - 30, height: icon_size, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextNormal, color: color.white, fontWeight: '500', letterSpacing: 0.5, marginLeft: 10 }}>{item.name}</p>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ ...styles.Block, width: props.isSmall ? props.width : '85%' }} >
                                                <Submenu theme={props.theme} employees={props.employees} isSmall={props.isSmall} item={item.children} menu_id={item.id} admin={props.admin} />
                                            </AccordionDetails>
                                        </Accordion>
                                        : null
                                    : null
                                : null
                            : null
                    ))
                    : null
                }
                <div style={{ ...styles.Block, minHeight: 50 }}></div>
            </div>

        </Paper >
    )

};


export const MenuTop = (props) => {
    /*
            MENU - horná lišta

            USAGE:
            <MenuTop func={MenuTopPress.bind(this)} />

            const MenuTopPress = (value) => {
                Debug(value)
            }

            */
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [country, setCountry] = useState(props.country);

    // NOTIFIKACIE
    const [showNotify, setShowNotify] = useState(false);
    const [items, setItems] = useState(false);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const divRef = React.useRef();

    const notifyWidth = 380;
    const notifyHeight = 600;
    const menuHeight = 50;

    const logo = require('./react/app/logo_icon.png');

    let { func } = props;
    var lang = props.lang;

    let color = global.themes[props.theme];

    const BarHeight = 50;

    useEffect(() => {

        setCountry(props.country);
        setAnchorEl(divRef.current);

        return () => {
        };

    }, [props.bodyID, props.logged, props.isSmall, props.employee_id, props.employees, props.country, props.menuID, props.new_version, props.new_mobile_version, props.notify]);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'notify', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                })
            })

            const json = await response.json();
            setBusy(false);
            Debug(json);
            if (json.ok > 0) {
                setOpen(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (type, value, item) => {
        func(type, value, item);
    }

    const Add = () => {
        Press(1, null);
    }

    const ChangeCountry = (item) => {
        ResetDashboard(); // VYNUTENIE NACITANIA DASHBOARDU
        Press(2, item);
    }

    const UpdateWindow = () => {
        window.location.reload();
    }

    const UpdateApplication = () => {

    }

    const Notify = () => {
        if (open == false) {
            db_get();
        } else {
            setOpen(false);
        }
    }


    return (
        <div style={{ ...styles.BlockRow, width: props.width, background: color.top_bar, height: global.topmenu_height - 1, borderBottom: '1px solid #808080' }}>

            <div style={{ ...styles.Block, width: global.menu_width, height: global.topmenu_height }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <div style={{ ...styles.Block, height: global.topmenu_height, width: 50 }}>
                        <img src={logo} style={{ width: '100%', height: '100%', maxWidth: global.topmenu_height - 10, maxHeight: global.topmenu_height - 10, objectFit: 'contain' }}></img>
                    </div>
                    <div style={{ ...styles.BlockLeft, height: global.topmenu_height, width: undefined, marginLeft: 10 }}>
                        <p style={{ ...styles.TextLarge, fontSize: global.topmenu_height - 20, color: color.black, fontWeight: '600' }}>{global.app_name1} <span style={{ fontWeight: '300' }}>{global.app_name2}</span></p>
                    </div>
                </div>
            </div>

            <div style={{ ...styles.Block, width: props.width - (2 * global.menu_width), height: global.topmenu_height }}>
                {props.remote != true && props.new_version == true ?
                    <div style={{ ...styles.Block, width: undefined, height: global.topmenu_height - 4, backgroundColor: color.blue, borderRadius: 20 }}>
                        <div style={{ ...styles.BlockRowRaw, marginLeft: 10, marginRight: 4 }}>
                            <div style={{ ...styles.BlockLeft, width: undefined, height: global.topmenu_height - 4 }}>
                                <FontAwesomeIcon style={{ height: 18, color: color.white }} icon={faInfoCircle} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: undefined, height: global.topmenu_height - 4, marginLeft: 6 }}>
                                <p style={{ ...styles.TextSmall, fontWeight: 600, color: color.white }}>{lang.version_new}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: undefined, height: global.topmenu_height - 4, marginLeft: 20 }}>
                                <Button onClick={() => UpdateWindow()} style={{ ...styles.ButtonThemed, backgroundColor: color.white, color: color.black, width: 160, height: 30 }}>
                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faRefresh} />
                                    {lang.version_update}
                                </Button>
                            </div>
                        </div>
                    </div>
                    : null}
                {props.remote == true && props.new_mobile_version == true ?
                    <div style={{ ...styles.Block, width: undefined, height: global.topmenu_height - 4, backgroundColor: color.dark_red, borderRadius: 20 }}>
                        <div style={{ ...styles.BlockRowRaw, marginLeft: 10, marginRight: 4 }}>
                            <div style={{ ...styles.BlockLeft, width: undefined, height: global.topmenu_height - 4 }}>
                                <FontAwesomeIcon style={{ height: 18, color: color.white }} icon={faInfoCircle} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: undefined, height: global.topmenu_height - 4, marginLeft: 6 }}>
                                <p style={{ ...styles.TextSmall, fontWeight: 600, color: color.white }}>{lang.version_mobile_new}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: undefined, height: global.topmenu_height - 4, marginLeft: 20 }}>
                                <a href={global.ipad_update_link + '/downloads/' + props.user.email + '@in_safari'} target='_blank' style={{ ...styles.Block, textDecoration: 'none', color: global.black, backgroundColor: color.white, width: 120, height: 32, borderRadius: 16 }}>{<p style={{ ...styles.TextTiny }}>{lang.version_update}</p>}</a>
                            </div>
                        </div>
                    </div>
                    : null}
                {props.label != null && (props.new_mobile_version != true && props.new_version != true) ?
                    <p style={{ ...styles.TextSmall, fontWeight: 400, color: color.black, marginLeft: props.width > global.max_screen ? global.menu_width : 0 }}>{props.bodyID == 0 ? '' : props.label}</p>
                    : null}

            </div>

            <div style={{ ...styles.BlockRight, width: global.menu_width, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', height: global.topmenu_height }}>
                <div style={{ ...styles.BlockRowRaw, marginRight: 10, height: global.topmenu_height, alignItems: 'center' }}>
                    {props.bodyID == 0 ?
                        <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                            <Help link={lang.help_main} lang={props.lang} theme={props.theme} />
                        </div>
                        : null}
                    {props.user.country_change == true || props.user.super_admin == true ?
                        props.organization.currencies.length > 1 ?
                            <div style={{ ...styles.BlockLeft, width: 90, height: global.dialog_bar_height }}>
                                <CountrySelect tiny radius width={70} countries={props.organization.currencies} background={color.white} value={props.locale} theme={props.theme} func={(item) => ChangeCountry(item)} />
                            </div>
                            : null
                        : null}
                    <div style={{ ...styles.BlockLeft, width: 60, height: global.dialog_bar_height }}>
                        <IconButton ref={divRef} onClick={() => Notify()} style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: color.white }}>
                            <FontAwesomeIcon style={{ height: 16, color: color.black }} icon={faBell} />
                            <Badge badgeContent={props.notify > 9 ? '9+' : props.notify} color="primary" sx={{ '& .MuiBadge-badge': { backgroundColor: color.dark_red, color: 'white', } }} style={{ size: 5, transform: 'translate(8px, -8px)' }} ></Badge>
                        </IconButton>
                    </div>
                    {/*
                    <IconButton onClick={() => Help()} style={{ width: 26, height: 26, borderRadius: 17, marginLeft: 30, backgroundColor: color.white }}>
                        <FontAwesomeIcon style={{ height: 16, color: color.black }} icon={faQuestion} />
                    </IconButton>
                    */}
                    <IconButton disabled={props.bodyID == 0 ? false : true} onClick={() => Add()} style={{ width: 34, height: 34, borderRadius: 18, marginLeft: 0, marginRight: 10, backgroundColor: color.white, border: '2px solid ' + color.medium_gray, opacity: props.bodyID == 0 ? 1.0 : 0.15 }}>
                        <FontAwesomeIcon style={{ height: 16, color: color.black }} icon={faPlus} />
                    </IconButton>
                </div>
            </div>

            <Popper
                style={{ zIndex: 40002, width: notifyWidth, height: notifyHeight }}
                open={open}
                anchorEl={anchorEl}
                placement={'bottom'}
            >
                <Paper elevation={3} style={{ ...styles.Block, marginTop: 2, border: '1px solid #777777', borderRadius: props.radius, backgroundColor: props.backColor }}>
                    <div style={{ ...styles.BlockRow, height: menuHeight, backgroundColor: color.light_gray, borderTopLeftRadius: props.radius, borderTopLeftRadius: props.radius }}>
                        <div style={{ ...styles.Block, width: menuHeight, height: menuHeight }}>
                            <Icon theme={props.theme} icon={faBell} />
                        </div>
                        <div style={{ ...styles.BlockLeft, width: notifyWidth - menuHeight - 50, height: menuHeight }}>
                            <p style={{ ...styles.TextSmall, marginLeft: 5 }}><b>{lang.notify}</b></p>
                            <p style={{ ...styles.TextXTiny, marginLeft: 5 }}>{lang.notify_list}</p>
                        </div>
                        <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                            <IconButton onClick={() => setOpen(false)} style={{ ...styles.ButtonClose }}>
                                <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: notifyHeight - menuHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {items != false ? items.map((item, index) => (
                            <div></div>
                        )) :
                            <div style={{ ...styles.Block, height: notifyHeight - menuHeight }}>
                                <EmptyList row small lang={lang} />
                            </div>
                        }
                    </div>
                </Paper>
            </Popper>

        </div >
    )

};

export const AppStatusbar = (props) => {
    /*
            MENU - horná lišta

            USAGE:
            <MenuTop func={MenuTopPress.bind(this)} />

            const MenuTopPress = (value) => {
                Debug(value)
            }

            */
    const [isBusy, setBusy] = useState(false);

    const logo = require('./react/app/logo_icon.png');

    let { func } = props;
    var lang = props.lang;

    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, [props.body_id, props.logged]);

    const Press = (type) => {
        func(type);
    }

    return (
        <div style={{ ...styles.Block, width: '100%', background: color.status_bar, height: global.status_height }}>
            {props.remote == true && props.remoteMobile == true ?
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                        <Button onClick={() => Press(1)} style={{ ...styles.ButtonStatusBar }}>
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faFileAlt} />
                            {lang.files}
                        </Button>
                    </div>

                    <div style={{ ...styles.Block, width: '50%' }}></div>
                </div>
                :
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.Block, width: '33%' }}></div>
                    <div style={{ ...styles.Block, width: '33%' }}>
                        <Button onClick={() => Press(1)} style={{ ...styles.ButtonStatusBar }}>
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faFileAlt} />
                            {lang.files}
                        </Button>
                    </div>
                    <div style={{ ...styles.Block, width: '33%' }}>
                    </div>
                </div>
            }
        </div >
    )

};

export const Icon = (props) => {
    /*

            USAGE:
            ---------------------------------------------------------
            <Icon theme={props.theme} icon={faLayer} logo />
            ---------------------------------------------------------

            logo    -> true: zobrazí logo Viseion CRM namiesto ikony

    */

    const size = 30;
    let color = global.themes[props.theme];

    const logo = require('./react/app/logo_icon_white.png');

    return (
        <div style={{ ...styles.Block, width: size, height: size, background: color.icon_background, borderRadius: 7 }} >
            {props.logo == undefined ?
                <FontAwesomeIcon style={{ height: 16, color: color.white }} icon={props.icon} />
                :
                <img src={logo} style={{ width: '100%', height: '100%', maxWidth: size - 10, maxHeight: size - 10, objectFit: 'contain' }}></img>
            }
        </div>
    )
}


export const Loading = (props) => {
    /*

            USAGE:
            <Loading offset={props.offset} side={menu_width} />

            */

    const size = 46;
    const [width, setWidth] = useState(window.innerWidth);

    const logo = require('./react/app/logo_loading.png');

    return (
        <Backdrop open={true} onClick={null} style={{ backgroundColor: '#00000000', top: -100 }}>
            <Paper elevation={5} style={{ ...styles.Block, marginLeft: props.center == true ? 0 : GetLeftPosition(width), width: size + 4, height: size + 4, backgroundColor: '#F0F0F0', borderRadius: (size + 5) / 2 }}>
                <img src={logo} style={{ marginTop: 0, width: size - 10, height: size - 10 }}></img>
                <CircularProgress style={{ position: 'absolute', color: '#000000' }} thickness={2} size={size} />
            </Paper>
        </Backdrop>);
}



export const DialogPasswordChange = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogPasswordChange radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={DialogPasswordChangeResult.bind(this)} />

            const DialogPasswordChangeResult = (typ,value) => {
                console.log(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [password, setPassword] = useState({ old: '', new1: '', new2: '' });
    const [error, setError] = useState('');
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 400;
    const dialogHeight = 500;
    const rowOffset = 20;
    const errorHeight = 40;
    const buttonHeight = 80;

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);

    const db_password_change = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user_change_password', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,

                    user_id: props.user.id,
                    password: password.old,
                    password_new: password.new1
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.check == false) {
                    setError(lang.password_old_err);
                } else {
                    if (json.changed == false) {
                        setShowError(true);
                    } else {
                        setShowOK(true);
                    }
                }
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
        func(true);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Process = () => {

        var pw1 = password.new1.trim();
        var pw2 = password.new2.trim();
        setError('');

        if (pw1 != '' && pw2 != '') {
            if (pw1.length < 8) {
                setError(lang.password_error_len);
            } else if (pw1 != pw2) {
                setError(lang.passwords_not_equal);
            } else {
                db_password_change();
            }
        }
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ ...styles.Block, width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLock} />
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.password_change_}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '80%', height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight }}>
                        <div style={{ ...styles.Block }}>
                            <TextInput password lang={props.lang} value={password.old} label={lang.password_old} func={(txt) => { password.old = txt }} />
                        </div>
                        <div style={{ ...styles.Block, marginTop: rowOffset * 2 }}>
                            <TextInput password lang={props.lang} value={password.new1} label={lang.password_new} func={(txt) => { password.new1 = txt }} />
                        </div>
                        <div style={{ ...styles.Block, marginTop: rowOffset }}>
                            <TextInput password lang={props.lang} value={password.new2} label={lang.password_new_again} func={(txt) => { password.new2 = txt }} />
                        </div>
                    </div>
                    <FormError error={error} theme={props.theme} small />
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Process()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                    </div>
                </div>
            </Paper>

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme}></Loading>
                : null}

        </Backdrop>
    );
}


export const DialogYesNo = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogYesNo theme={props.theme} radius={props.radius} title={'Otázka'} text={'Text otázky'} sub_text={''} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={DialogYesNoResult.bind(this)} />

            ------------------------------------------------------------
            center      -> v strede
            hideClose   -> nebude sa zobrazovat zatvorenie okna
            warning_text    -> upozornenie - cervene
            buttonWidth     -> šírka buttonov
            ------------------------------------------------------------
            
            const DialogYesNoResult = (value) => {
                console.log(value)
            }
            */

    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 600;
    const dialogHeight = props.sub_text == '' && (props.warning_text == undefined || props.warning_text == '') ? 220 : props.warning_text == undefined || props.warning_text == '' ? 250 : 300;

    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }

    return (
        <Backdrop open={true} style={{ zIndex: 500, paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        {props.icon == undefined ?
                            <Icon theme={props.theme} icon={faLayerGroup} logo />
                            :
                            <Icon theme={props.theme} icon={props.icon} />
                        }
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        {props.hide_close == undefined ?
                            <IconButton onClick={() => Press(false)} style={{ ...styles.ButtonClose }}>
                                <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                            </IconButton>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '96%' }}>
                        <p style={{ ...styles.TextNormal, textAlign: 'center' }}>{props.text}</p>
                        {props.sub_text != undefined ?
                            <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 10, color: color.dark_gray }}>{props.sub_text}</p>
                            : null}
                        {props.warning_text != undefined ?
                            <p style={{ ...styles.TextTiny, color: color.dark_red, textAlign: 'center', marginTop: 12, fontWeight: '500' }}>{props.warning_text}</p>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, height: 100 }}>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(true)} style={{ ...styles.ButtonThemed, width: props.buttonWidth == undefined ? 160 : props.buttonWidth, backgroundColor: props.yes_color == undefined ? color.button_dark : props.yes_color }} > {props.yes}</Button>
                    </div>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(false)} style={{ ...styles.ButtonThemed, width: props.buttonWidth == undefined ? 160 : props.buttonWidth, backgroundColor: props.no_color == undefined ? color.button_dark : props.no_color }}>{props.no}</Button>
                    </div>

                </div>
            </Paper>
        </Backdrop >
    );
}

export const DialogChoose = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogChoose theme={props.theme} radius={props.radius} title={'Otázka'} text={'Text otázky'} sub_text={''} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={DialogYesNoResult.bind(this)} />

            ------------------------------------------------------------
            center      -> v strede
            hideClose   -> nebude sa zobrazovat zatvorenie okna
            warning_text    -> upozornenie - cervene
            buttonWidth     -> šírka buttonov
            ------------------------------------------------------------
            
            const DialogYesNoResult = (value) => {
                console.log(value)
            }
            */

    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 600;
    const dialogHeight = props.sub_text != '' ? 220 : 200;

    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }

    return (
        <Backdrop open={true} style={{ zIndex: 500, paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: '#000000A0' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: color.info, borderRadius: props.radius }}>
                <div style={{ ...styles.Block, height: dialogHeight - 80, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '96%' }}>
                        <p style={{ ...styles.TextNormal, textAlign: 'center' }}>{props.text}</p>
                        {props.sub_text != undefined ?
                            <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 10, color: color.dark_gray }}>{props.sub_text}</p>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, height: 80 }}>
                    <div style={{ ...styles.Block, height: 80 }}>
                        <Button onClick={() => Press(1)} style={{ ...styles.ButtonThemed, width: props.buttonWidth == undefined ? 160 : props.buttonWidth, backgroundColor: props.yes_color == undefined ? color.button_dark : props.yes_color }} > {props.yes}</Button>
                    </div>
                    <div style={{ ...styles.Block, height: 80 }}>
                        <Button onClick={() => Press(0)} style={{ ...styles.ButtonThemed, width: props.buttonWidth == undefined ? 160 : props.buttonWidth, backgroundColor: props.no_color == undefined ? color.button_dark : props.no_color }}>{props.no}</Button>
                    </div>

                </div>
            </Paper>
        </Backdrop >
    );
}

export const DialogWarningYesNo = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogWarningYesNo center red confirm radius={props.radius} lang={lang} title={'Otázka'} sub_title={''} text={'Text otázky'} sub_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DialogYesNoResult.bind(this)} />

            ------------------------------------------------------------
            center  -> v strede
            ------------------------------------------------------------
            
            const DialogWarningYesNoResult = (value) => {
                console.log(value)
            }
            */
    const [showConfirm, setShowConfirm] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 600;
    const dialogHeight = 360;

    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }

    const Process = () => {
        if (showConfirm == false && props.confirm == true) {
            setShowConfirm(true)
        } else {
            Press(true);
        }
    }
    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.red == true ? '#400000F0' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            {props.sub_title != '' && props.sub_title != undefined ?
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height, backgroundColor: undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {showConfirm == false ?
                        <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100 }}>
                            <div style={{ ...styles.Block, width: '98%' }}>
                                <FontAwesomeIcon style={{ height: 35, color: global.theme_dark_red }} icon={faExclamationTriangle} />
                                <p style={{ ...styles.TextSmall, marginTop: 20, textAlign: 'center' }}>{props.text}</p>
                                {props.sub_text != undefined ?
                                    <p style={{ ...styles.TextNormal, textAlign: 'center', marginTop: 15, fontWeight: '600' }}>{props.sub_text}</p>
                                    : null}
                                {props.sub_text_2 != undefined ?
                                    <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 15 }}>{props.sub_text_2}</p>
                                    : null}
                            </div>
                        </div>
                        :
                        <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: showConfirm == true ? global.theme_black : undefined }}>
                            <div style={{ ...styles.Block, width: '98%' }}>
                                <p style={{ ...styles.TextNormal, color: global.theme_white, marginTop: 0, textAlign: 'center' }}>{props.lang.confirmation_needed}</p>
                                {props.sub_text != undefined ?
                                    <p style={{ ...styles.TextNormal, color: global.theme_white, textAlign: 'center', marginTop: 15, fontWeight: '600' }}>{props.sub_text}</p>
                                    : null}
                            </div>
                        </div>

                    }
                    <div style={{ ...styles.BlockRow, height: 100 }}>
                        <div style={{ ...styles.Block, height: 100 }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: props.yes_color }}>{props.yes}</Button>
                        </div>
                        <div style={{ ...styles.Block, height: 100 }}>
                            <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark, backgroundColor: props.no_color }}>{props.no}</Button>
                        </div>
                    </div>
                </div>

            </Paper>
        </Backdrop>
    );
}

export const DialogInfo = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogInfo title={'Informácia'} text={'Text informácie'} sub_text={'Sub text informácie'} button={lang.close} icon={faCheckCircle} icon_color={color.red} theme={props.theme} radius={props.radius} func={DialogInfoResult.bind(this)} />

            const DialogInfoResult = (value) => {
                console.log(value)
            }
            */

    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 650 > width ? width : 650;
    const dialogHeight = props.sub_text != undefined ? 320 : 300;

    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);


    const Close = () => {
        func(false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faInfo} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {props.icon != undefined ?
                        <FontAwesomeIcon style={{ height: 40, color: props.icon_color, marginBottom: 20 }} icon={props.icon} />
                        : null}
                    <p style={{ ...styles.TextSmall, textAlign: 'center' }}>{props.text}</p>
                    {props.sub_text != undefined ?
                        <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'center' }}>{props.sub_text}</p>
                        : null}
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, color: color.black }}>{props.button}</Button>
                </div>
            </Paper>
        </Backdrop >
    );
}

export const DialogEnter = (props) => {
    /*
            VKLADANIE UDAJOV

            Percentá - koeficient
            <DialogEnter percentage title={lang.order_discount_} label={lang.order_discount_percentage} text={discount == 1 ? '' : CoeficientToPercentage(discount, 2) + '%'} icon={faPercentage} button={props.lang.save} theme={props.theme} radius={props.radius} lang={props.lang} func={DiscountResult.bind(this)} />

            Zoznam
            <DialogEnter selectable bold items={stocks} title={lang.stock_cancel_} text={lang.stock_cancel_text} label={lang.stock_choose} icon={faSearch} button={props.lang.save} theme={props.theme} radius={props.radius} lang={props.lang} column1={120} column2={300} func={StockCancel.bind(this)} />

            --------------------------------------------------------------------------------    
                PARAMS:

                    close       -> zobrazenie "Zavrieť" tlačítka
                    text        -> text nad výber hodnoty
                    sub_text    -> text pod textom
                    bold        -> text v BOLDe

            --------------------------------------------------------------------------------
            const DialogEnterTextResult = (typ, value) => {
                console.log(value)
            }
            */

    const [error, setError] = useState('');
    const [value, setValue] = useState(props.value);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 600;
    const dialogHeight = props.text == undefined ? 280 : props.note == undefined ? 350 : 400;

    let column1 = props.column1 == undefined ? 150 : props.column1;
    let column2 = props.column2 == undefined ? dialogWidth - column1 - 40 : props.column2;

    let { func } = props;
    const inputRef = useRef(null);
    const color = global.themes[props.theme];

    var running = false;
    useEffect(() => {

        // FOCUS TO TEXTAREA
        if (props.percentage == true) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 300);
        }

        return () => {
        };

    }, []);


    const Press = () => {
        // KONTROLA VLOZENYCH UDAJOV
        var err = false;
        if (props.percentage == true) {
            err += IsEmpty(value) == true ? 1 : 0;
        }
        if (props.selectable == true) {
            err += value > 0 ? 0 : 1;
        }

        if (err == false) {
            func(true, value);
        } else {
            setError(props.lang.required_red);
        }
    }

    const Close = () => {
        func(false, false);
    }

    const ChangeValue = (txt) => {
        setError('');
        setValue(txt);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={props.icon == undefined ? faInfo : props.icon} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 140, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '98%' }}>

                        {props.text != undefined ?
                            <p style={{ ...styles.TextXSmall, textAlign: 'center', marginBottom: props.sub_text == undefined ? 20 : 0, fontWeight: props.bold == true ? '600' : '400' }}>{props.text}</p>
                            : null}

                        {props.text != undefined ?
                            <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 5, marginBottom: 20 }}>{props.sub_text}</p>
                            : null}

                        {props.percentage == true ?
                            <FormText require percentage inputRef={inputRef} value={value} editing={true} title={props.label} column1={column1} column2={column2} theme={props.theme} lang={props.lang} radius={props.radius} func={(txt) => ChangeValue(txt)} />
                            : null}

                        {props.selectable == true ?
                            <FormSelect require value={value} items={props.items} field={'name'} editing={true} title={props.label} width={column2} column1={column1} column2={column2} theme={props.theme} lang={props.lang} radius={props.radius} func={(id) => ChangeValue(id)} />
                            : null}

                        {props.note != undefined ?
                            <div style={{ ...styles.Block, width: '90%' }}>
                                <p style={{ ...styles.TextXSmall, textAlign: 'center', fontWeight: '600', marginTop: 20 }}>{props.lang.warning}</p>
                                <p style={{ ...styles.TextXSmall, textAlign: 'center', marginTop: 2 }}>{props.note}</p>
                            </div>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 140 }}>
                    <div style={{ ...styles.Block, height: 50 }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: 90 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button onClick={() => Press()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{props.button}</Button>
                            {props.close == true ?
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, marginLeft: 20 }}>{props.lang.close}</Button>
                                : null}
                        </div>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}

export const DialogEnterText = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogEnterText rows={1} title={'Informácia'} text={'Text informácie'} icon={faQuestion} button={props.lang.close} theme={props.theme} radius={props.radius} func={DialogEnterTextResult.bind(this)} />

            --------------------------------------------------------
            rows    - pocet riadkov

            --------------------------------------------------------

            const DialogEnterTextResult = (typ, value) => {
                console.log(value)
            }
            */
    const [text, setText] = useState(props.text);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 600;
    const dialogHeight = props.rows == 1 ? 250 : 400;

    let column1 = 150;
    let column2 = dialogWidth - column1 - 40;

    let { func } = props;
    const inputRef = useRef(null);
    const color = global.themes[props.theme];

    var running = false;
    useEffect(() => {

        // FOCUS TO TEXTAREA
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);


        return () => {
        };

    }, []);


    const Press = () => {
        func(true, text);
    }

    const Close = () => {
        func(false, false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faInfo} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '98%' }}>
                        <TextField
                            inputRef={inputRef}
                            value={text}
                            onInput={e => setText(e.target.value)}
                            size="small"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            multiline={props.rows > 1 ? true : false}
                            rows={props.rows}
                            style={{ width: '100%' }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Press();
                                }
                            }}
                            InputProps={{
                                style: { backgroundColor: global.theme_white },
                            }}
                            variant="outlined"
                        />
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <Button onClick={() => Press()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, color: color.black }}>{props.button}</Button>
                </div>
            </Paper>
        </Backdrop>
    );
}


export const DialogEnterPin = (props) => {
    /*

            USAGE:
            <DialogEnterPin center title={lang.sms_code} label={lang.sms_code_sended} pin={''} verify_pin={pinCode} button={lang.continue} lang={lang} theme={props.theme} radius={props.radius} func={PinResult.bind(this)} />

            PARAMS:
            ------------------------------------------------------------------------------------
            verify_pin  -> kod, podla ktoreho bude zadaný pin kontrolovať

            RESULT:
            ------------------------------------------------------------------------------------
            const PinResult = (typ, pin) => {
                if(typ == true){
                    // KOD OK
                } else {
                    // PO TROCH POKUSOCH - NESPRAVNY KOD
                }
            }

    */

    const logo = require('./react/app/user_lock.png');

    const [pin, setPin] = useState(props.pin);
    const [error, setError] = useState('');
    const [tryLeft, setTryLeft] = useState(3);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 300;
    const dialogHeight = 420;

    let { func } = props;
    const inputRef = useRef(null);
    const color = global.themes[props.theme];
    let lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            // FOCUS TO TEXTAREA
            setTimeout(() => {
                inputRef.current.focus();
            }, 300);

            running = true;
        }

        return () => {
        };

    }, []);


    const Press = () => {
        if (pin.length != '' && tryLeft > 0) {
            if (pin.trim() === props.verify_pin) {
                func(true, pin.trim());
            } else {
                setError(lang.sms_code_error);
                let value = tryLeft - 1;
                if (value > 0) {
                    setTryLeft(value);
                    if (value == 1) {
                        setError(lang.sms_code_error + ' - ' + lang.sms_code_last_try);
                    }
                } else {
                    setError(lang.sms_code_error);
                    var timer = setTimeout(() => {
                        Close();
                    }, 1000);
                }
            }
        }
    }

    const Close = () => {
        func(false, false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLock} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '98%' }}>
                        <img src={logo} style={{ width: '100%', height: '100%', maxWidth: 100, maxHeight: 100, objectFit: 'contain' }}></img>
                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 10 }}>
                            <p style={{ ...styles.TextTiny, textAlign: 'center' }}>{props.label}</p>
                            {/*global.testing == true ?
                                <p style={{ ...styles.TextXTiny, textAlign: 'center', color: color.dark_gray }}>{props.verify_pin}</p>
                            : null*/}
                            <p style={{ ...styles.TextXTiny, textAlign: 'center', color: color.dark_gray }}>{props.verify_pin}</p>
                        </div>
                        <div style={{ ...styles.Block, width: '70%' }}>
                            <TextField
                                inputRef={inputRef}
                                value={pin}
                                onInput={(e) => setPin(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                type='text'
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        Press();
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: global.theme_white, borderRadius: global.radius },
                                }}
                                onFocus={event => {
                                    event.target.select();
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div style={{ ...styles.BlockCenter, height: 50 }}>
                            <FormError error={error} small theme={props.theme} />
                        </div>
                        <div style={{ ...styles.Block, height: 80 }}>
                            <Button onClick={() => Press()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, color: color.black }}>{props.button}</Button>
                        </div>

                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}


export const DialogEnterNumber = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogEnterNumber radius={props.radius} title={'Informácia'} sub_title={''} value={0} min={0} max={100} icon={faQuestion} button={props.lang.close} func={EnterNumberResult.bind(this)} />

            --------------------------------------------------------
            rows    - pocet riadkov

            --------------------------------------------------------

            const EnterNumberResult = (typ, value) => {
                console.log(value)
            }
            */
    const [value, setValue] = useState(props.value);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 400;
    const dialogHeight = 250;

    let color = global.themes[props.theme];
    let lang = props.lang;
    let { func } = props;

    useEffect(() => {


        return () => {
        };

    }, []);


    const Close = () => {
        func(false, false);
    }

    const PlusMinusResult = (value_) => {
        setValue(value_);
    }


    return (
        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <Icon theme={props.theme} icon={faLayerGroup} />
                        </div>
                    </div>
                    <div style={{ ...styles.BlockLeft, width: '90%', height: global.dialog_bar_height }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.enter_value}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '98%' }}>
                        <p style={{ ...styles.TextXSmall, fontWeight: '600', marginBottom: 10 }}>{props.title}</p>
                        <PlusMinus min={props.min} value={value} theme={props.theme} func={PlusMinusResult.bind(this)} />
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <Button onClick={() => func(true, parseInt(value))} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{props.button}</Button>
                </div>
            </Paper>
        </Backdrop>
    );
}

export const ShowOK = (props) => {
    /*
            USAGE:
            const [showOK, setShowOK] = useState(false);
            setShowOK(true);


            const ShowOKPress = (value) => {
                setShowOK(false);
            }

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            */

    const [width, setWidth] = useState(window.innerWidth);
    let { func } = props;
    let color = global.themes[props.theme];
    let size = 48;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 750);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000', paddingLeft: props.center == true ? 0 : GetLeftPosition(width), top: -100 }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: size, height: size, backgroundColor: color.darker_green, borderRadius: 40 }}>
                <FontAwesomeIcon style={{ width: size - 15, height: size - 15, color: color.white }} icon={faCheck} />
            </Paper>
        </Backdrop>
    );
}


export const ShowError = (props) => {
    /*
            USAGE:
            const [showError, setShowError] = useState(false);
            setShowError(true);

            const ShowErrorPress = (value) => {
                setShowError(false);
            }

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                : null}

            */

    const [width, setWidth] = useState(window.innerWidth);

    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 4000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000', paddingLeft: props.center == true ? 0 : GetLeftPosition(width) }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: 360, backgroundColor: color.black, borderRadius: 16, paddingTop: 20, paddingBottom: 20 }}>
                <div style={{ ...styles.Block, width: 40, height: 40, borderRadius: 20, backgroundColor: color.dark_red }}>
                    <FontAwesomeIcon style={{ width: 30, height: 30, color: color.white }} icon={props.icon == undefined ? faXmark : props.icon} />
                </div>
                <div style={{ ...styles.Block, width: '95%' }}>
                    <p style={{ ...styles.TextSmall, textAlign: 'center', color: color.white, textAlign: 'center', marginTop: 15 }}>{props.text}</p>
                    {props.sub_text != undefined ?
                        <p style={{ ...styles.TextTiny, color: color.white, textAlign: 'center', marginTop: 8 }}>{props.sub_text}</p>
                        : null}
                </div>
            </Paper>
        </Backdrop >
    );
}

export const ShowInfo = (props) => {
    /*
            USAGE:
            const [showInfo, setShowInfo] = useState(false);
            setShowInfo(true);

            {showInfo == true ?
                <ShowInfo text={lang.db_error} sub_text={lang.db_error_text} lang={lang} theme={props.theme} func={()=>setShowInfo(false)} />
                : null}

            PARAMS:
            ------------------------------------------------------------------
            auto    -> bez button "Close" - zatvorí oknto po stanovenom čase
            icon    -> ikona
            width   -> šírka okna

    */

    const [width, setWidth] = useState(window.innerWidth);

    let { func } = props;
    let color = global.themes[props.theme];
    let dialogWidth = props.width == undefined ? 400 : props.width;
    let lang = props.lang;

    useEffect(() => {
        const timer = setTimeout(() => {
            if (props.auto == true) {
                Press(false);
            }
        }, 3500);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000', paddingLeft: props.center == true ? 0 : GetLeftPosition(width) }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: dialogWidth, backgroundColor: color.info, borderRadius: 16, paddingTop: 26, paddingBottom: 26 }}>
                <div style={{ ...styles.Block, width: 34, height: 34, backgroundColor: color.dark_blue, borderRadius: 20 }}>
                    <FontAwesomeIcon style={{ width: 34, height: 34, color: color.white, border: '2px solid ' + color.dark_blue, borderRadius: 20 }} icon={props.icon == undefined ? faInfoCircle : props.icon} />
                </div>
                <div style={{ ...styles.Block, width: '95%', marginTop: 20 }}>
                    <p style={{ ...styles.TextSmall, textAlign: 'center', color: color.black, textAlign: 'center' }}>{props.text}</p>
                    {props.sub_text != undefined ?
                        <p style={{ ...styles.TextTiny, color: color.black, textAlign: 'center', marginTop: 8 }}>{props.sub_text}</p>
                        : null}

                    {props.auto != true ?
                        <div style={{ ...styles.Block, marginTop: 40 }}>
                            <Button onClick={() => func(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>

                        </div>
                        : null}

                </div>
            </Paper>
        </Backdrop >
    );
}

export const ShowTokenError = (props) => {
    /*
            USAGE:
            const [showTokenError, setShowTokenError] = useState(false);
            setShowTokenError(true);

            {showTokenError == true ?
                <ShowTokenError center remoteApp={props.remoteApp} text={lang.token_error} sub_text={lang.token_error_text} func={TokenErrorPress.bind(this)} />
                : null}

            const TokenErrorPress = () => {
                setShowTokenError(false);
            }

            */

    const [width, setWidth] = useState(window.innerWidth);

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 3000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000', paddingLeft: width > global.max_screen ? props.center == true ? 0 : global.menu_width : 0 }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: 400, height: 150, backgroundColor: global.theme_black, borderRadius: 16 }}>
                <div style={{ ...styles.Block, width: 40, height: 40, borderRadius: 20, backgroundColor: global.theme_dark_red }}>
                    <FontAwesomeIcon style={{ width: 30, height: 30, color: global.theme_white }} icon={faXmark} />
                </div>
                <div style={{ ...styles.Block, width: '95%' }}>
                    <p style={{ ...styles.TextNormal, color: global.theme_white, marginTop: 15 }}>{props.text}</p>
                    {props.sub_text != undefined ?
                        <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 3 }}>{props.sub_text}</p>
                        : null}
                </div>
            </Paper>
        </Backdrop>
    );
}


export const TextInput = (props) => {
    /* USAGE

            const [userData, setUserData] = useState({mobil: ''});

            <TextInput require calc numeric precision={3} money money_text={'€'} redraw theme={props.theme} enabled={true} lang={lang} value={userData.mobil} label={lang.mobil} func={(txt) => { userData.mobil = txt }} />

                required    -> text je vyzadovany - pod textfieldom oznam
                require     -> text je vyzadovany - CERVENY okraj na lavo
                not_zero    -> nesie byt nulovy
                date        -> vklad dátumu
                time        -> vklad casu
                outline     -> ak je chyba, zobrazí sa cervený ramik bez textu
                phone       -> jedna sa o tel. cislo v medzinárodnom formate
                email       -> kontroluje správnost e-mailovej adresy
                enabled     -> editovanie true / false
                label       -> place holder
                redraw      -> text bude prekreslovaný pri zmene iných textových polí - setRedraw(!redraw)
                type        -> default = 'text', 'date'
                placeholder -> placeholder text
                money       -> zobrazi menu na konci textboxu
                money_text  -> text na konci textboxu (undefined == money)
                width       -> širka boxu
                height      -> výška textfieldu
                center      -> vycentrovať titulok aj box
                numeric     -> zadávanie len číslic [0-9] '.' ','   -> FLOAT VALUE
                calc        -> textové pole aj aj ako kalkulacka
                precision   -> počet desatiných miest pri kalkulačke
                decimal     -> zadávanie len číslic [0-9]           -> INT VALUE
                rows        -> pocet riadkov
                search      -> search štýl
                percentage  -> vklad percent
                end         -> text na konci
                maxLength   -> Maximálny počet zadavaných znakov
                enter       -> vráti text az po stlacení tlacitka enter a ako druhý parameter 'enter'
                excape      -> zrusi zadavanie a vrati ako druhy parameter 'escape'

            
                const TextResult = (text, button) => {
                    button -> undefined, enter, escape    
                }
            */



    const [text, setText] = useState(props.value);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [calcResult, setCalcResult] = useState('');
    const [calcOpen, setCalcOpen] = useState(false);

    const timer = useRef();

    let { func } = props;
    var lang = props.lang;
    let color = global.themes[props.theme == undefined ? 0 : props.theme];
    const divRef = React.useRef();
    const precision = props.precision == undefined ? 2 : props.precision;

    useEffect(() => {

        setAnchorEl(divRef.current);

        Check(props.value);
        if (props.redraw != undefined) {
            setText(props.value);
        }

        return () => {
        };

    }, [props.value]);

    const ChangeText = (txt) => {
        if (props.numeric == true) {
            if (props.calc) {
                if (/^[0123456789,.+-/*()]+$/.test(txt)) {
                    setText(txt);
                    Check(txt);
                    if (props.enter == undefined) {
                        func(txt);
                    }
                }
            } else {
                if (/^[0123456789,.]+$/.test(txt)) {
                    setText(txt);
                    Check(txt);
                    var result = txt.replaceAll(',', '.');
                    if (props.enter == undefined) {
                        func(result);
                    }
                }
            }
            if (txt == '') {
                setText(txt);
                Check(txt);
                var result = txt.replaceAll(',', '.');
                if (props.enter == undefined) {
                    func(result);
                }
            }
        } else if (props.decimal == true) {
            if (/^[0123456789]+$/.test(txt)) {
                setText(txt);
                Check(txt);
                func(txt);
            }
            if (txt == '') {
                setText(txt);
                Check(txt);
                if (props.enter == undefined) {
                    func(txt);
                }
            }
        } else if (props.percentage == true) {
            if (/^[0123456789%,.+-]+$/.test(txt)) {
                setText(txt);
                //Check(txt);
                var result = txt.replaceAll(',', '.');
                if (props.enter == undefined) {
                    func(result);
                }
            } else {
                if (txt == '') {
                    setText('');
                }
            }

        } else if (props.search == true) {
            setText(txt);
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                func(txt);
            }, 500);

        } else {
            setText(txt);
            Check(txt);
            if (props.enter == undefined) {
                func(txt);
            }
        }
    }

    const Check = (text) => {
        if (error != '') {
            setError('');
        }
        if (text != undefined) {
            if (props.phone) {
                if (text.trim() != '') {
                    if (text[0] != '+') {
                        setError(lang.format_error);
                    }
                }
            }

            if (props.numeric) {
                if (props.not_zero) {
                    if (text.trim() != '') {
                        if (parseInt(text) == 0) {
                            setError(lang.non_zero_error);
                        }
                    }
                }
            }

            if (props.email) {
                if (text.trim() != '') {
                    if (nonEmail(text.trim()) == true)
                        setError(lang.format_error);
                }
            }

            if (props.calc) {
                if (text.includes('+') || text.includes('-') || text.includes('*') || text.includes('/') || text.includes('(') || text.includes(')')) {
                    setCalcOpen(true);
                } else {
                    setCalcOpen(false);
                }
                var tmp = ParseCondition(text);;
            }
        }
    }

    function ParseCondition(txt_) {
        var txt = txt_.replaceAll(',', '.');
        if (txt == '') {
            txt = '0';
        }
        var result = txt;
        try {
            result = eval(txt);
            result = parseFloat(result).toFixed(precision);
            setCalcResult(result);
        } catch (e) {
            if (e instanceof SyntaxError) {
                result = txt;
                setCalcResult('');
            }
        }

        return result;
    }

    const LostFocus = () => {
        // LOST FOCUS
        if (props.calc) {
            setText(calcResult);
            func(calcResult);
        }
        if (props.percentage) {
            var result = text;
            if (text.includes('%')) {
                var value = text.replaceAll('%', '')
                value = value.replaceAll('+', '');
                value = value.replaceAll('-', '');
                value = parseFloat(value);
                value = value / 100;
                if (text[0] == '-') {
                    result = 1 - value;
                } else {
                    result = 1 + value;
                }
            }
            func(String(result));
        }
        setEditing(false);
    }

    const Clear = () => {
        ChangeText('');
    }

    return (
        <div style={{ ...props.center == undefined ? styles.BlockLeft : styles.Block }}>
            {props.label != undefined && props.label != '' ?
                <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: color.dark_gray, marginLeft: props.radius == undefined ? 2 : props.radius / 2, textAlign: 'left' }}>{props.label}</p>
                : null}
            <TextField
                ref={divRef}
                inputRef={props.inputRef}
                value={text}
                onBlur={() => LostFocus()}
                onFocus={() => setEditing(true)}
                onInput={e => ChangeText(e.target.value)}
                size="small"
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                style={{ width: props.width == undefined ? '100%' : props.width, maxWidth: props.search ? props.width == undefined ? 300 : props.width : undefined }}
                placeholder={props.placeholder == undefined ? '' : props.placeholder}
                multiline={props.rows != undefined ? true : false}
                rows={props.rows != undefined ? props.rows : 1}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        if (props.calc) {
                            setText(calcResult);
                            setCalcOpen(false);
                            func(calcResult);
                        }
                        if (props.enter == true) {
                            func(text, 'enter');
                            //setText('');
                        }
                    }
                    if (event.key === 'Escape') {
                        if (props.escape == true) {
                            func(text, 'escape');
                        }
                    }

                }}
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                    },
                    fieldset: { borderWidth: props.enabled == false ? 0 : 1, borderColor: error != '' ? color.red : color.xxgray, borderLeft: props.require == true ? '3px solid #FF0000' : undefined }
                }}
                inputProps={{ maxLength: props.maxLength == undefined ? undefined : props.maxLength }}
                InputProps={{
                    startAdornment:
                        props.money == true ?
                            <InputAdornment position="start">
                                <div style={{ ...styles.Block, borderRight: '1px solid #A0A0A0' }}>
                                    <p style={{ ...styles.TextSmall, marginRight: 10 }}>{props.money_text == undefined ? lang.money : props.money_text}</p>
                                </div>
                            </InputAdornment> :
                            props.search == true ?
                                <InputAdornment position="start">
                                    <div style={{ ...styles.Block }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.dark_gray }} icon={faSearch} />
                                    </div>
                                </InputAdornment> : null
                    ,
                    endAdornment:
                        props.calc ?
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: '#00000000',
                                            color: "white",
                                        },
                                    }
                                }}
                                title={
                                    <div style={{ ...styles.Block, width: 350, backgroundColor: color.white, border: '1px solid #A0A0A0', borderRadius: props.radius == undefined ? 0 : props.radius }}>
                                        <div style={{ ...styles.Block, width: '96%', paddingTop: 10, paddingBottom: 10 }}>
                                            <p style={{ ...styles.TextNormal }}><span style={{ marginRight: 10 }}><FontAwesomeIcon style={{ height: 16, color: color.black }} icon={faCalculator} /></span>{lang.calculator}</p>
                                            <div style={{ ...styles.Block, marginTop: 5, borderTop: '1px solid ' + color.light_gray }}>
                                                <p style={{ ...styles.TextTiny, marginTop: 5, color: color.darker_gray }}>{lang.textfield_calc_1}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 2, color: color.darker_gray }}>{lang.textfield_calc_2}</p>
                                            </div>
                                        </div>
                                    </div>
                                }>
                                <InputAdornment position="end">
                                    <FontAwesomeIcon style={{ height: 20, color: color.dark_gray }} icon={faCalculator} />
                                </InputAdornment>
                            </Tooltip>
                            :
                            props.percentage ?
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: '#00000000',
                                                color: "white",
                                            },
                                        }
                                    }}
                                    title={
                                        <div style={{ ...styles.Block, width: 450, backgroundColor: color.white, border: '1px solid #A0A0A0', borderRadius: props.radius == undefined ? 0 : props.radius }}>
                                            <div style={{ ...styles.Block, width: '96%', paddingTop: 10, paddingBottom: 10 }}>
                                                <p style={{ ...styles.TextNormal }}><span style={{ marginRight: 10 }}><FontAwesomeIcon style={{ height: 16, color: color.black }} icon={faInfoCircle} /></span>{lang.text_percentage}</p>
                                                <div style={{ ...styles.Block, marginTop: 5, borderTop: '1px solid ' + color.light_gray }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 5, color: color.darker_gray, fontWeight: '600' }}>{lang.text_percentage_text_1}</p>
                                                    <p style={{ ...styles.TextXTiny, textAlign: 'center', marginTop: 2, color: color.darker_gray }}>{lang.text_percentage_text_2}</p>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 10, color: color.darker_gray, fontWeight: '600' }}>{lang.text_percentage_text_3}</p>
                                                    <p style={{ ...styles.TextXTiny, textAlign: 'center', marginTop: 2, color: color.darker_gray }}>{lang.text_percentage_text_4}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }>
                                    <InputAdornment position="end">
                                        <FontAwesomeIcon style={{ height: 20, color: color.dark_gray }} icon={faInfoCircle} />
                                    </InputAdornment>
                                </Tooltip>
                                :
                                props.search == true ?
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => Clear()} style={{ width: 26, height: 26 }}>
                                            <FontAwesomeIcon style={{ width: 10, color: color.dark_gray }} icon={faXmark} />
                                        </IconButton>
                                    </InputAdornment>
                                    :
                                    props.end != undefined ?
                                        <InputAdornment position="end" >
                                            <div style={{ ...styles.Block, borderLeft: '1px solid ' + color.medium_gray }}>
                                                <p style={{ ...styles.TextTiny, color: color.medium_gray, marginLeft: 5 }}>{props.end}</p>
                                            </div>
                                        </InputAdornment>
                                        : null
                    ,
                    style: { height: props.height == undefined ? props.rows == undefined ? 36 : undefined : props.height, backgroundColor: props.enabled == false ? '#00000007' : color.white, borderRadius: props.radius == undefined ? global.radius : props.radius, color: color.black },
                }}
                type={props.date == true ? 'date' : props.password == true ? 'password' : props.time == true ? 'time' : props.type != undefined ? props.type : 'text'}
                variant="outlined"
                disabled={props.enabled == false ? true : false}
                error={error != '' ? true : props.required == true ? text == '' ? true : false : false}
                helperText={props.enabled == false ? '' : error != '' ? error : props.required == true ? text == '' ? props.outline == true ? '' : lang.required : '' : ''}
                FormHelperTextProps={{ style: { color: color.dark_red, margin: 0, padding: 0, marginLeft: 12, fontSize: 12 } }}
            />
            {
                props.calc && editing == true && calcResult != '' ?
                    <Popper
                        style={{ zIndex: 30002 }}
                        open={calcOpen}
                        anchorEl={anchorEl}
                        placement={'bottom-start'}
                    >
                        <div style={{ ...styles.Block, marginLeft: 5, marginTop: 2, border: '1px solid #777777', padding: 5, borderRadius: props.radius == undefined ? 2 : props.radius, backgroundColor: color.white }}>
                            <p style={{ ...styles.TextTiny }}>= {calcResult}</p>
                        </div>
                    </Popper>
                    : null
            }
        </div >
    );
}


export const SelectInput = (props) => {
    /* USAGE

        SINGLE SELECT:
            <SelectInput default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={-1} items={currencies} field={'currency'} label={lang.mobil} radius={global.radius} func={(txt) => Debug(txt)} />

        MULTIPLE SELECT:
            <SelectInput multiple default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={[]} items={currencies} field={'currency'} label={lang.mobil} radius={global.radius} func={(txt) => Debug(txt)} />

            items = [{ id: 1, label: 'Option 1 }, ... ]

            PROPS:
            -------------------------------------------------------------------------------
            default     -> disabled nadpis
            field       -> názov stĺpca, z ktorého sa berie názov do Select items
            multiple    -> multi výber hodnôt, POZOR: value musí byť Array []
            require     -> oznacvený cervený pásik na ľavo
            width       -> šírka
            disabled    -> prvá hodnota - default aktívna / neaktívna
            zero        -> ak je nastavené - namiesto 0 bude text
    */


    const [value, setValue] = useState(props.value);
    const [redraw, setRedraw] = useState(false);

    let { func } = props;
    var lang = props.lang;
    let color = global.themes[props.theme == undefined ? 0 : props.theme];
    let radius = props.radius;

    useEffect(() => {
        setValue(props.value);

        return () => {
        };

    }, [props.value, props.items]);

    const ChangeValue = (id) => {
        setValue(id);
        func(id);
    }

    return (
        <div style={{ ...props.center == undefined ? styles.BlockLeft : styles.Block, width: props.width == undefined ? '100%' : props.width }}>
            <Select
                value={value}
                onChange={event => ChangeValue(event.target.value)}
                multiple={props.multiple == undefined ? false : props.multiple}
                size='small'
                style={{ textAlign: 'left', fontSize: global.font_xsmall, backgroundColor: '#FFFFFF', height: 38, width: '100%', borderRadius: props.radius == undefined ? 19 : props.radius }}
                MenuProps={{
                    PaperProps: { sx: { ".MuiList-root": { backgroundColor: '#FFFFFF', }, } },
                    sx: {
                        width: 200, "& .MuiMenuItem-root.Mui-selected": { backgroundColor: props.multiple ? color.blue : color.lighter_blue, color: props.multiple ? color.white : undefined, borderRadius: 2, margin: 1 },
                        width: 200, "& .MuiMenuItem-root.Mui-selected:hover": { backgroundColor: props.multiple ? color.blue : undefined, color: props.multiple ? color.white : undefined },
                        zIndex: 30000,
                    }
                }}
                sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: color.xxgray, borderLeft: props.require ? props.multiple ? value.length == 0 ? '3px solid #FF0000' : undefined : value == -1 ? '3px solid #FF0000' : undefined : undefined } }}>

                {props.default != undefined ?
                    <MenuItem disabled={props.disabled == undefined ? true : props.disabled} value={-1}><p style={{ ...styles.TextXSmall }}><em>{props.default}</em></p></MenuItem>
                    : null}

                {props.all != undefined ?
                    <MenuItem value={0}><p style={{ ...styles.TextXSmall }}>{props.all}</p></MenuItem>
                    : null}

                {props.items != false ?
                    props.items.map((item, index) => (
                        <MenuItem style={{ fontSize: global.font_xsmall }} key={item.id} value={item.id}>{props.zero == undefined ? item[props.field] : item.id == 0 ? props.zero : item[props.field]}</MenuItem>
                    ))
                    : null}
            </Select>

        </div >
    );
}


export const FormError = (props) => {
    /*

            <FormError theme={props.theme} error={error} small />
            -----------------------------------------------------
        small   -> menšia verzia
        margin  -> marginfrom top
            */
    const height = props.small == true ? 28 : 40;
    let color = global.themes[props.theme];

    return (
        <div style={{ ...styles.Block, width: props.small == true ? undefined : '100%', height: height, backgroundColor: props.backgroundColor == undefined ? color.error : props.backgroundColor, borderRadius: 8, marginTop: props.margin == undefined ? 20 : props.margin }}>
            {props.error != '' ?
                <div style={{ ...styles.BlockRowRaw }}>
                    {props.isSmall ? null :
                        <FontAwesomeIcon style={{ height: 18, color: color.white, marginLeft: 15 }} icon={faWarning} />
                    }
                    <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextXSmall, color: color.white, marginLeft: 10, marginRight: 15 }}>{props.error}</p>
                </div>
                : null}
        </div>
    );
}

export const FormOK = (props) => {
    /*
            <FormOK text={'text'} small theme={props.theme} />
            -----------------------------------------------------
        small       -> menšia verzia
        color       -> farba pozadia
    */

    const height = props.small == true ? props.sub_text == undefined ? 28 : 50 : props.sub_text == undefined ? 40 : 60;
    let color = global.themes[props.theme];

    return (
        props.text != '' ?
            <div style={{ ...styles.Block, width: props.small == true ? undefined : '100%', minHeight: height, backgroundColor: props.color == undefined ? color.darker_green : props.color, borderRadius: height / 2 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <FontAwesomeIcon style={{ height: 18, color: color.white, marginLeft: 15 }} icon={faInfoCircle} />
                    <p style={{ ...styles.TextXSmall, color: color.white, marginLeft: 10, marginRight: 15 }}>{props.text}</p>
                </div>
                {props.sub_text != undefined ?
                    <p style={{ ...styles.TextTiny, color: color.white, marginLeft: 28, marginRight: 28, marginTop: 4 }}>{props.sub_text}</p>
                    : null}
            </div>
            :
            <div style={{ ...styles.Block, height: height, borderRadius: 20, marginTop: 20 }}></div>

    );
}


export const Template = (props) => {
    /*

            USAGE:
            <Template lang={props.lang} theme={props.theme} radius={props.radius} func={Result.bind(this)} />


    */

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    const [isBusy, setBusy] = useState(false);

    let { func } = props;
    var running = false;
    useEffect(() => {
        if (running == false) {

            running = true;
        }
        return () => {
        };

    }, []);

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Process = () => {

    }

    return (
        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
        </div>
    );
}




export const CountrySelect = (props) => {
    /*
            VYBER KRAJINY

            USAGE:
            <CountrySelect radius width={200} countries={props.countries} background={global.theme_lighter} width={600} value={value} func={CountryResult.bind(this)} />

            --------------------------------------------------------
            all -> všetky záznamy
            --------------------------------------------------------

            const CountryResult = (value) => {
                console.log(value)
            }
            */

    const [items, setItems] = useState(props.countries == undefined ? false : props.countries);
    const [item, setItem] = useState(props.value == undefined ? false : props.value);
    const [id, setID] = useState(-1);

    const imageSize = 24;

    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {
        setItems(props.countries == undefined ? false : props.countries);
        setItem(props.value == undefined ? false : props.value);
        setID(props.value != undefined ? props.value.id : -1);

        return () => {
        };

    }, [props.value, props.countries]);


    const ChangeSelect = (value) => {
        var tmp = props.countries.find(x => x.id == value);
        if (tmp != undefined) {
            setItem(tmp);
            setID(value);
            func(tmp);
        }
    }

    return (
        <div style={{ ...styles.Block, width: props.width == undefined ? '100%' : props.width }}>
            {items != false ?
                <Select
                    value={id}
                    onChange={event => ChangeSelect(event.target.value)}
                    size='small'
                    style={{ backgroundColor: props.background == undefined ? color.white : props.background, height: 34, width: props.width == undefined ? '100%' : props.width, borderRadius: props.radius == true ? 17 : 0 }}
                    MenuProps={{
                        style: { zIndex: 35001 }
                    }}
                >
                    {items.map((item, index) => (
                        <MenuItem key={item.id} value={item.id}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <img src={global.web + item.image} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                {props.tiny == true ? null :
                                    <div style={{ ...styles.BlockLeft, height: imageSize, justifyContent: 'center' }}>
                                        <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{item.state_short}</p>
                                    </div>
                                }
                            </div>
                        </MenuItem>
                    ))}
                </Select>
                : null}

        </div>
    );
}



export const DialogImagePreview = (props) => {
    /*
            NAHLAD OBRAZKU

            USAGE:
            <DialogImagePreview file={global.web + "/public/images/..."} radius={props.radius} lang={props.lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={DialogImagePreviewResult.bind(this)} />

            --------------------------------------------------------------------------------------------------------------------
            file    => celá cesta aj s doménov
            crop    => TRUE = orezanie obrázku a rozstiahnutie v celom okne <div>
            delete  => možnosť vymazania obrázku (pri diskusii)
                --------------------------------------------------------------------------------------------------------------------

            const DialogImagePreviewResult = () => {
                }

                */

    const [showDelete, setShowDelete] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [radius, setRadius] = useState(height > 850 ? props.radius : 0);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;
    //const dialogWidth = 900;
    //const dialogHeight = 700;

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        Debug(props.file);
        return () => {
        };

    }, []);


    const Press = (typ, delete_) => {
        func(typ, delete_);
    }

    const Close = () => {
        Press(false, false);
    }

    const DeleteImage = () => {
        setShowDelete(true);
    }

    const DeleteResult = (value) => {
        setShowDelete(false);

        if (value == true) {
            Press(true, true);
        }
    }

    return (
        <Backdrop open={true} style={{ backgroundColor: '#000000E0', zIndex: 999 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, background: global.themes[props.theme].theme_dialog_background, borderRadius: radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: radius, borderTopRightRadius: radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faImage} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 60 - 60, height: global.dialog_bar_height }}>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, backgroundColor: props.background, borderBottomLeftRadius: radius, borderBottomRightRadius: radius }}>
                    {props.crop == true ?
                        <img src={GetImageLink(props.file)} style={{ width: '100%', height: '100%', maxWidth: dialogWidth, maxHeight: dialogHeight - global.dialog_bar_height, objectFit: 'cover' }}></img>
                        :
                        <img src={GetImageLink(props.file)} style={{ width: '100%', height: '100%', maxWidth: dialogWidth, maxHeight: dialogHeight - global.dialog_bar_height, objectFit: 'contain', borderBottomLeftRadius: radius, borderBottomRightRadius: radius }}></img>
                    }
                </div>
            </Paper>

            {showDelete == true ?
                <DialogYesNo center theme={props.theme} radius={props.radius} title={lang.photo_delete} sub_title={''} text={lang.photo_delete_text} sub_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DeleteResult.bind(this)} />
                : null}

        </Backdrop >
    );
}


export const DialogCategory = (props) => {
    /*
                KATEGÓRIE - UPRAVA / NOVA

                USAGE:
                <DialogCategory lang={lang} radius={props.radius} func={DialogCategoryResult.bind(this)} />

            const DialogCategoryResult = (typ,value) => {
                    console.log(value)
                }
                */


    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState(props.editing == true ? props.item.label : '');
    const [slug, setSlug] = useState(props.editing == true ? props.item.slug : '');
    const [enabled, setEnabled] = useState(true);
    const [eshop, setEshop] = useState(true);

    // DB
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 500;
    const dialogHeight = 380;
    const rowOffset = 20;
    const errorHeight = 30;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;

            if (props.editing == true) {
                setEnabled(props.item.enabled == false ? false : true);
                setEshop(props.item.eshop == false ? false : true);
            } else {
                setEnabled(true);
            }
        }
        return () => {
        };

    }, []);

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'category_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ) => {
        func(typ);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
        Press(true);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Process = () => {
        var error = 0;

        error += IsEmpty(label) == true ? true : false;
        error += IsEmpty(slug) == true ? true : false;

        if (error > 0) {
            setError(lang.form_error);
        } else {
            setError('');

            var category = {
                id: props.editing == true ? props.item.id : 0,
                enabled: enabled,
                priority: 10,
                label: label.trim(),
                color: '',
                icon: '',
                salon_id: props.user_id,
                brand_id: 0,
                section_id: 0,
                eshop: eshop,
                slug: slug.trim(),
                language: props.language,
            }

            db_update(category);
        }

    }

    const ChangeLabel = (txt) => {
        setLabel(txt);
        setSlug(GetTid(txt));
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faLayerGroup} />
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: '90%', height: global.dialog_bar_height, alignItems: 'center' }}>
                        <div style={{ ...styles.BlockLeft, width: 180, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.category}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.editing == false ? lang.record_new : lang.record_edit}</p>
                        </div>
                        <div style={{ ...styles.Block, height: 45 }} >
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight }}>
                        <div style={{ ...styles.Block, width: '90%' }}>
                            <div style={{ ...styles.BlockLeft, height: 40, marginTop: 10 }}>
                                <FormControlLabel label={<p style={{ ...styles.TextSmall }}>{lang.category_enabled}</p>}
                                    control={<Checkbox checked={enabled} size={'small'} onChange={() => setEnabled(!enabled)} />}
                                />
                            </div>
                            <div style={{ ...styles.BlockLeft, height: 40, marginTop: 0 }}>
                                {enabled == true ?
                                    <FormControlLabel label={<p style={{ ...styles.TextSmall }}>{lang.category_eshop}</p>}
                                        control={<Checkbox checked={eshop} size={'small'} onChange={() => setEshop(!eshop)} />}
                                    />
                                    : null}
                            </div>
                            <div style={{ ...styles.Block, marginTop: rowOffset }}>
                                <div style={{ ...styles.BlockLeft }}>
                                    <TextInput required enabled={true} lang={lang} value={label} label={lang.label} func={(txt) => ChangeLabel(txt)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: '90%', height: buttonHeight + errorHeight }}>
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError error={error} />
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                        </div>
                    </div>
                </div>
            </Paper>


            {showOK == true ?
                <ShowOK offset={props.offset} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}



export const CategorySelect = (props) => {
    /*
                Výber kategorie

                USAGE:
                <CategorySelect radius background={global.theme_lighter} width={600} items={items} value={value} func={CategorySelectResult.bind(this)} />

            const CategorySelectResult = (value) => {
                    console.log(value)
                }
                */

    const [id, setID] = useState(props.value);
    const [overID, setOverID] = useState(-1);

    const icon_size = 28;
    const buttonSize = 100;
    const offset = 3;

    let { func } = props;

    useEffect(() => {
        setID(props.value);

        return () => {
        };

    }, [props.value]);

    const Press = (value) => {
        setID(value);
        func(value);
    }

    return (
        <div style={{ ...styles.Block, alignItems: props.center == true ? 'center' : 'flex-start', width: props.width, overflowX: 'scroll', backgroundColor: props.background != undefined ? props.background : global.theme_white, borderRadius: props.radius == true ? (icon_size + 10) / 2 : 0, msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>
            <div style={{ ...styles.BlockRowRaw, marginTop: 5, marginBottom: 5 }}>
                {props.items.map((item, index) => (
                    <Paper
                        elevation={item.id == overID ? 4 : 0}
                        onClick={() => Press(item.id)} key={item.id} style={{
                            ...styles.BlockRow, width: undefined, alignItems: 'center', justifyContent: 'center', height: icon_size, backgroundColor: item.id == id ? global.theme_dark : global.theme_light, marginLeft: offset, marginRight: offset, borderRadius: icon_size / 2, cursor: 'pointer',
                        }}
                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                    >

                        <div style={{ display: 'flex', minWidth: buttonSize, height: icon_size, alignItems: 'center', justifyContent: 'center', overflow: 'hidden', whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>
                            <p style={{ ...styles.TextTiny, fontSize: 13, textAlign: 'left', color: item.id == id ? global.theme_white : global.theme_black }}>{item.label}</p>
                        </div>

                    </Paper>
                ))}
            </div>
        </div >
    );
}



export const PlusMinus = (props) => {
    /*
                PLUS - MINUS HODNOTY

                USAGE:
                <PlusMinus min={1} value={value} item={item} theme={props.theme} lang={props.lang} func={PlusMinusResult.bind(this)} />

                ----------------------------------------------------------------------------------------

                min         -> minimalna hodnota 
                max         -> maximálna hodnota 
                no_wait     -> bez cakacej rutiny
                item        -> array - len prenos udajov
                ----------------------------------------------------------------------------------------

                const PlusMinusResult = (value, item) => {
                    console.log(value)
                }

    */

    const [value, setValue] = useState(props.value);
    const [edit, setEdit] = useState(false);

    let { func } = props;

    const width = 120;
    const buttonSize = 30;
    const height = 36;
    let color = global.themes[props.theme];
    const inputRef = useRef(null);

    const timer = useRef();

    useEffect(() => {
        setValue(props.value);

        return () => {
        };

    }, [props.value]);

    const Press = (value) => {
        func(value, props.item);
    }

    const Process = (add) => {
        var old_value = parseInt(value);
        var number = parseInt(parseInt(value) + parseInt(add));
        if (props.min != undefined) {
            if (number < props.min) {
                number = props.min;
            }
        } else {
            if (number < 0) {
                number = 0;
            }
        }
        if (props.max != undefined) {
            if (number > props.max) {
                number = props.max;
            }
        }
        setValue(number);

        // opozdovacia rutina
        if (old_value != number) {
            clearTimeout(timer.current);
            if (props.no_wait == undefined) {
                timer.current = setTimeout(() => {
                    Press(number);
                }, 500);
            } else {
                Press(number);
            }
        }
    }

    const EnterValue = () => {
        var number = parseInt(value);
        if (props.min != undefined) {
            if (number < props.min) {
                number = props.min;
            }
        } else {
            if (number < 0) {
                number = 0;
            }
        }
        if (props.max != undefined) {
            if (number > props.max) {
                number = props.max;
            }
        }
        setValue(number);
        Press(number);

        setEdit(false);
    }

    const StartEdit = () => {
        setEdit(true);
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);

    }

    return (
        edit == false ?
            <div style={{ ...styles.Block, width: width, height: height, backgroundColor: color.white, borderRadius: height / 2, border: color.border }}>
                <div style={{ ...styles.BlockRow, width: width - 8, height: height, backgroundColor: color.white, borderRadius: height / 2 }}>
                    <div style={{ ...styles.Block, width: buttonSize, height: height }}>
                        <IconButton onClick={() => Process(-1)} style={{ width: buttonSize, height: buttonSize, backgroundColor: props.min == undefined ? color.dark : parseInt(value) == props.min ? color.light : color.dark, borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
                            <FontAwesomeIcon style={{ width: 12, color: color.white }} icon={faMinus} />
                        </IconButton>
                    </div>
                    <div onClick={() => StartEdit()} style={{ ...styles.Block, width: width - 8 - (buttonSize * 2), height: height, cursor: 'pointer' }}>
                        <p style={{ ...styles.TextTiny, color: props.min == undefined ? color.black : parseInt(value) == props.min ? color.medium_gray : color.black }}>{value}</p>
                    </div>
                    <div style={{ ...styles.Block, width: buttonSize, height: height }}>
                        <IconButton onClick={() => Process(1)} style={{ width: buttonSize, height: buttonSize, backgroundColor: props.max != undefined ? props.max == parseInt(value) ? color.light : color.dark : color.dark, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>
                            <FontAwesomeIcon style={{ width: 12, color: color.white }} icon={faPlus} />
                        </IconButton>
                    </div>
                </div >
            </div>
            :
            <div style={{ ...styles.BlockRow, width: width, height: height, backgroundColor: color.white, borderRadius: height / 2 }}>
                <TextField
                    inputRef={inputRef}
                    value={value}
                    onInput={(e) => setValue(e.target.value)}
                    size="small"
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    style={{ width: '100%' }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            EnterValue();
                        }
                    }}
                    InputProps={{
                        style: { backgroundColor: global.theme_white, borderRadius: global.radius },
                    }}
                    onFocus={event => {
                        event.target.select();
                    }}
                    variant="outlined"
                />
                <IconButton onClick={() => EnterValue()} style={{ ...styles.ButtonIcon, width: 36, height: 36, borderRadius: global.radius, marginLeft: 5, backgroundColor: color.light_green }}>
                    <FontAwesomeIcon style={{ width: 12 }} icon={faCheck} />
                </IconButton>

            </div>
    );
}


export const UpDown = (props) => {
    /*
                PLUS - MINUS HODNOTY

                USAGE:
                <UpDown show_index item={item} index={index} max={items.length - 1} width={70} color={color.white} border={color.border} theme={props.theme} lang={props.lang} func={UpDownResult.bind(this)} />

                ----------------------------------------------------------------------------------------
                show_index  -> zobrazovat index

                ----------------------------------------------------------------------------------------

                const UpDownResult = (button, index, item) => {
                    if (items != false) {
                        let data1 = {
                            id: items[index].id,
                            priority: items[index + button].priority,
                        };

                        let data2 = {
                            id: items[index + button].id,
                            priority: items[index].priority,
                        };

                        db_reorder(data1, data2);
                    }
                }
    */

    const [value, setValue] = useState(props.value);

    let { func } = props;

    const width = props.width == undefined ? 90 : props.width;
    const height = 30;

    let color = global.themes[props.theme];

    useEffect(() => {
        setValue(props.index);

        return () => {
        };

    }, [props.value]);

    const Process = (number) => {
        func(number, props.index, props.item);
    }


    return (
        <div style={{ ...styles.BlockRow, width: width, height: height, backgroundColor: global.theme_white, borderRadius: height / 2 }}>
            <div style={{ ...styles.Block, width: height, height: height }}>
                {props.index == 0 ? null :
                    <Tooltip title={props.lang.priority_up}>
                        <IconButton onClick={() => Process(-1)} style={{ width: height, height: height, border: props.border == undefined ? undefined : props.border, backgroundColor: props.color == undefined ? color.white : props.color }}>
                            <FontAwesomeIcon style={{ width: 12, color: global.theme_white }} icon={faAngleUp} />
                        </IconButton>
                    </Tooltip>
                }
            </div>
            <div style={{ ...styles.Block, width: width - (height * 2), height: height }}>
                {props.show_index == true ?
                    <p style={{ ...styles.TextXTiny, color: color.medium_gray }}>{props.index}</p>
                    : null}
            </div>
            <div style={{ ...styles.Block, width: height, height: height }}>
                {props.index < props.max ?
                    <Tooltip title={props.lang.priority_down}>
                        <IconButton onClick={() => Process(1)} style={{ width: height, height: height, border: props.border == undefined ? undefined : props.border, backgroundColor: props.color == undefined ? color.white : props.color }}>
                            <FontAwesomeIcon style={{ width: 12, color: global.theme_white }} icon={faAngleDown} />
                        </IconButton>
                    </Tooltip>
                    : null}
            </div>
        </div >
    );
}


export const DialogGroup = (props) => {
    /*
                SKUPINY - SLUŽBY - UPRAVA / NOVA

                USAGE:
                <DialogGroup lang={lang} radius={props.radius} func={DialogGroupResult.bind(this)} />

            const DialogGroupResult = (typ,value) => {
                    console.log(value)
                }
                */


    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState(props.editing == true ? props.item.label : '');
    const [slug, setSlug] = useState(props.editing == true ? props.item.slug : '');
    const [enabled, setEnabled] = useState(true);
    const [eshop, setEshop] = useState(true);

    // DB
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 500;
    const dialogHeight = 380;
    const rowOffset = 20;
    const errorHeight = 30;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;

            if (props.editing == true) {
                setEnabled(props.item.enabled == false ? false : true);
                setEshop(props.item.eshop == false ? false : true);
            } else {
                setEnabled(true);
                setEshop(true);
            }
        }
        return () => {
        };

    }, []);

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'group_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ) => {
        func(typ);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
        Press(true);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Process = () => {
        var error = 0;

        error += IsEmpty(label) == true ? true : false;

        if (error > 0) {
            setError(lang.form_error);
        } else {
            setError('');

            var category = {
                id: props.editing == true ? props.item.id : 0,
                priority: 20,
                brand_id: 0,
                language: props.language,
                salon_id: props.user_id,
                enabled: enabled,
                eshop: eshop,
                label: label.trim(),
                slug: '',
                photo: ''
            }

            db_update(category);
        }

    }

    const ChangeLabel = (txt) => {
        setLabel(txt);
        setSlug(GetTid(txt));
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faLayerGroup} />
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: '90%', height: global.dialog_bar_height, alignItems: 'center' }}>
                        <div style={{ ...styles.BlockLeft, width: 180, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.group}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.editing == false ? lang.record_new : lang.record_edit}</p>
                        </div>
                        <div style={{ ...styles.Block, height: 45 }} >
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight }}>
                        <div style={{ ...styles.Block, width: '90%' }}>
                            <div style={{ ...styles.BlockLeft, height: 40, marginTop: 10 }}>
                                <FormControlLabel label={<p style={{ ...styles.TextSmall }}>{lang.group_enabled}</p>}
                                    control={<Checkbox checked={enabled} size={'small'} onChange={() => setEnabled(!enabled)} />}
                                />
                            </div>
                            <div style={{ ...styles.BlockLeft, height: 40, marginTop: 10 }}>
                                {enabled == true ?
                                    <FormControlLabel label={<p style={{ ...styles.TextSmall }}>{lang.group_eshop}</p>}
                                        control={<Checkbox checked={eshop} size={'small'} onChange={() => setEshop(!eshop)} />}
                                    />
                                    : null}
                            </div>

                            <div style={{ ...styles.Block, marginTop: rowOffset }}>
                                <div style={{ ...styles.BlockLeft }}>
                                    <TextInput required enabled={true} lang={lang} value={label} label={lang.label} func={(txt) => ChangeLabel(txt)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: '90%', height: buttonHeight + errorHeight }}>
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError error={error} />
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                        </div>
                    </div>
                </div>
            </Paper>


            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}


export const DialogVideo = (props) => {
    /*
                PREHRAVANIE VIDEA

                USAGE:
                <DialogVideo file={global.web + "/public/images/..."} radius={props.radius} lang={props.lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={DialogVideoResult.bind(this)} />

                --------------------------------------------------------------------------------------------------------------------
            file    => celá cesta aj s doménov
            crop    => TRUE = orezanie obrázku a rozstiahnutie v celom okne <div>
                    --------------------------------------------------------------------------------------------------------------------

            const DialogVideoResult = () => {
                    }

                    */

    //const [isBusy, setBusy] = useState(false);

    const dialogWidth = 900;
    const dialogHeight = 700;

    const [width, setWidth] = useState(window.innerWidth);

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    const Close = () => {
        Press(0);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faImage} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, backgroundColor: props.background, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <video controls style={{ maxWidth: '100%' }}>
                        <source src={props.file} type="video/mp4" />
                    </video>
                </div>
            </Paper>
        </Backdrop >
    );
}


export const DialogPDF = (props) => {
    /*
                    PDF SUBOR

                    USAGE:
                    <DialogPDF file={global.web + "/public/images/..."} radius={props.radius} lang={props.lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={DialogPDFResult.bind(this)} />

                    --------------------------------------------------------------------------------------------------------------------
            file    => celá cesta aj s doménov
            crop    => TRUE = orezanie obrázku a rozstiahnutie v celom okne <div>
                        --------------------------------------------------------------------------------------------------------------------

            const DialogPDFResult = () => {
                        }

                        */

    const [link, setLink] = useState('');
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const buttonSize = 36;

    let { func } = props;
    var lang = sk;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;
            setLink("https://docs.google.com/gview?url=" + props.file + "&embedded=true");
        }
        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    const Close = () => {
        Press(0);
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const PrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }

    }

    const NextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    }


    return (
        <Backdrop open={true} style={{ backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, maxWidth: 1200, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faImage} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: height - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                    {/* props.remoteApp == false ?
                        <div style={{ ...styles.BlockCenter, height: height - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                            <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}  >
                                <Page pageNumber={pageNumber} width={dialogWidth} />
                            </Document>
                            {numPages > 0 ?
                                <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>
                                    <div style={{ ...styles.BlockRowRaw, height: buttonSize + 20, backgroundColor: global.theme_medium_gray, borderRadius: (buttonSize + 20) / 2, marginTop: 20, marginBottom: 20 }}>
                                        <div style={{ ...styles.Block, width: 60, height: buttonSize + 20 }}>
                                            {pageNumber > 1 ?
                                                <IconButton onClick={() => PrevPage()} style={{ width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: global.theme_black }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faAngleLeft} />
                                                </IconButton>
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 100, height: buttonSize + 20 }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '600', color: global.theme_white }}>{pageNumber} / {numPages}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 60, height: buttonSize + 20 }}>
                                            {pageNumber < numPages ?
                                                <IconButton onClick={() => NextPage()} style={{ width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: global.theme_black }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faAngleRight} />
                                                </IconButton>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    */}
                    <iframe width={'100%'} height={'100%'} style={{ border: '0px' }} src={props.file}></iframe>

                </div>

            </Paper>
        </Backdrop >
    );

    /*
                <div style={{ ...styles.BlockCenter, height: height - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                            <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}  >
                                <Page pageNumber={pageNumber} width={dialogWidth} />
                            </Document>
                            {numPages > 0 ?
                                <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>
                                    <div style={{ ...styles.BlockRowRaw, height: buttonSize + 20, backgroundColor: global.theme_medium_gray, borderRadius: (buttonSize + 20) / 2, marginTop: 20, marginBottom: 20 }}>
                                        <div style={{ ...styles.Block, width: 60, height: buttonSize + 20 }}>
                                            {pageNumber > 1 ?
                                                <IconButton onClick={() => PrevPage()} style={{ width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: global.theme_black }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faAngleLeft} />
                                                </IconButton>
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 100, height: buttonSize + 20 }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '600', color: global.theme_white }}>{pageNumber} / {numPages}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 60, height: buttonSize + 20 }}>
                                            {pageNumber < numPages ?
                                                <IconButton onClick={() => NextPage()} style={{ width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: global.theme_black }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faAngleRight} />
                                                </IconButton>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                        */
}


export const DialogHelp = (props) => {
    /*
                        PDF SUBOR

                        USAGE:
                        <DialogHelp remoteApp={props.remoteApp} radius={props.radius} lang={props.lang} func={HelpResult.bind(this)} />

            const HelpResult = () => {
                        }

                        */

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    let { func } = props;
    var lang = props.lang;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;

        }
        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    const Close = () => {
        Press(0);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: '#000000AA', zIndex: 10000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.helper}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                    <iframe width={'100%'} height={'100%'} style={{ border: '0px' }} src={props.link}></iframe>

                </div>
            </Paper>
        </Backdrop >
    );

}


export const EmptyList = (props) => {
    /*
                        ŽIADNE POLOŽKY - IKONA

                        USAGE:
                        <EmptyList row small lang={lang} />

                        ------------------------------------------------------------------------
            row     => ikona a text su v riadku
            small   => ikona je menšia
            text    => text pod ikonov (nepovinné)
                        ------------------------------------------------------------------------
                        */

    const size = 100;
    const size_small = 50;

    const empty = require('./react/app/empty_list.png');

    return (
        <div style={{ ...styles.Block }}>
            {props.row == true ?
                <div style={{ ...styles.BlockRowRaw }}>
                    <img src={empty} style={{ transform: 'rotate(-7deg)', width: '100%', height: '100%', width: props.small == true ? size_small : size, height: props.small == true ? size_small : size, objectFit: 'contain' }}></img>
                    <p style={{ ...styles.TextNormal, fontSize: props.small == true ? 14 : 18, marginTop: props.small == true ? (size_small / 2) - 5 : (size / 2) - 7 }}>{props.text != undefined ? props.text : props.lang.empty_list}</p>
                </div>
                :
                <div style={{ ...styles.Block }}>
                    <img src={empty} style={{ width: '100%', height: '100%', width: props.small == true ? size_small : size, height: props.small == true ? size_small : size, objectFit: 'contain' }}></img>
                    <p style={{ ...styles.TextNormal, fontSize: props.small == true ? 14 : 18, marginTop: 10 }}>{props.text != undefined ? props.text : props.lang.empty_list}</p>
                </div>
            }
        </div>

    );
}


export const SelectYear = (props) => {
    /*
        VYBER ROKU

        USAGE:
        ----------------------------------------------------------------------------------------
        <SelectYear min={2020} value={year} theme={props.theme} func={SelectYearResult.bind(this)} />

        PARAMS:
        ----------------------------------------------------------------------------------------
                min     -> minimalna hodnota - rok
                
        RESULT:
        ----------------------------------------------------------------------------------------
                const SelectYearResult = (year, month) => {
                    console.log(year, month)
                }

    */

    const [value, setValue] = useState(props.value);
    const [nowYear, setNowYear] = useState(GetTodayYear());

    let { func } = props;

    const width = 140;
    const height = 36;

    const timer = useRef();
    let color = global.themes[props.theme];

    useEffect(() => {
        setValue(props.value);

        return () => {
        };

    }, [props.value]);

    const Press = (value) => {
        var month = 12;
        if (value == GetTodayYear()) {
            month = GetTodayMonth() + 1;
        }
        func(value, month);
    }

    const Process = (add) => {
        var old_value = parseInt(value);
        var number = parseInt(parseInt(value) + parseInt(add));
        if (props.min != undefined) {
            if (number < props.min) {
                number = props.min;
            }

        } else {
            if (number < 0) {
                number = 0;
            }
        }

        if (number > GetTodayYear()) {
            number = GetTodayYear();
        }

        if (props.max != undefined) {
            if (number > props.max) {
                number = props.max;
            }
        }

        setValue(number);

        // opozdovacia rutina
        if (old_value != number) {
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                Press(number);
            }, 300);
        }
    }


    return (
        <div style={{ ...styles.BlockRow, width: width, height: height, backgroundColor: color.white, borderRadius: height / 2 }}>
            <IconButton onClick={() => Process(-1)} style={{ width: height, height: height, backgroundColor: props.min == undefined ? color.dark : value == props.min ? color.light_gray : color.dark }}>
                <FontAwesomeIcon style={{ width: 12, color: color.white }} icon={faMinus} />
            </IconButton>
            <div style={{ ...styles.Block, width: width - (height * 2), height: height }}>
                <p style={{ ...styles.TextSmall }}>{value}</p>
            </div>
            <IconButton onClick={() => Process(1)} style={{ width: height, height: height, backgroundColor: nowYear == value ? color.light_gray : props.max == undefined ? color.dark : props.max == value ? color.light_gray : color.dark }}>
                <FontAwesomeIcon style={{ width: 12, color: color.white }} icon={faPlus} />
            </IconButton>
        </div >
    );
}


export const DialogEmail = (props) => {
    /*
                        ZADAT EMAILOVU ADRESU A TEXT

                        USAGE:
                        <DialogEmails lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={DialogEmailResult.bind(this)} />

            const DialogEmailResult = (typ,value) => {
                            console.log(value)
                        }
                        */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);
    const [info, setInfo] = useState(false);
    const [header, setHeader] = useState(' ');
    const [email, setEmail] = useState('');

    const blocksFromHTML = convertFromHTML('');
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap,);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));
    const [contentState, setContentState] = useState();

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showChoose, setChoose] = useState(false);
    const [showCustomers, setShowCustomers] = useState(false);
    const [showUsers, setShowUsers] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 768 ? 768 : height;

    const headerHeight = 140;
    const errorHeight = 60;
    const buttonHeight = 80;
    const editorHeight = dialogHeight - headerHeight - global.dialog_bar_height - errorHeight - buttonHeight - 40;
    const imageSize = editorHeight - buttonHeight - 50;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;
    const inputRef = useRef(null);
    let color = global.themes[props.theme];
    let column1 = 150;
    let column2 = dialogWidth - column1 - 40;
    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;
        }

        // FOCUS TO TEXTAREA
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);

    }, []);

    const db_send = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'file_send_email', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                setInfo(lang.email_sended);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = (value) => {
        func(value);
    }

    const Process = () => {
        setError('');
        setInfo('');
        var err = 0;

        err += IsEmpty(header) ? 1 : 0;
        err += IsEmpty(email) ? 1 : 0;

        if (err == 0) {
            let html = convertToHTML(editorState.getCurrentContent());

            var data = {
                email: email,
                header: header,
                text: html,
                delete: true,
                attachment: props.attachment == undefined ? false : props.attachment
            }

            db_send(data);
        } else {
            setError(lang.required_red);
        }
    }

    const ChooseResult = (value) => {
        setChoose(false);

        if (value == false) {
            setTimeout(() => {
                setShowCustomers(true);
            }, 250);
        } else {
            setTimeout(() => {
                setShowUsers(true);
            }, 250);
        }
    }

    const CustomersResult = (typ, id, customer, email) => {
        setShowCustomers(false);
        Debug(email);
        if (typ == true) {
            setEmail(email.trim());
        }
    }

    const UserResult = (typ, selected) => {
        setShowUsers(false);

        if (typ == true && selected != false) {
            setEmail(selected.email.trim());
        }
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faAt} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: dialogWidth - 60 - 60, height: global.dialog_bar_height }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close(false)} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight }}>
                        <div style={{ ...styles.Block, width: '95%', height: headerHeight, backgroundColor: global.theme_back }}>
                            <div style={{ ...styles.BlockRow, width: column1 + column2 + 10 }}>
                                <div style={{ ...styles.BlockLeft, width: column1 + column2 + 10 - 100 }}>
                                    <FormText inputRef={inputRef} require redraw value={email} editing={true} title={lang.email_address} column1={column1} column2={column2 - 100} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />
                                </div>
                                <div style={{ ...styles.BlockRight, width: 50, height: 40 }}>
                                    <IconButton onClick={() => setEmail(props.user.email)} style={{ ...styles.ButtonIcon }}>
                                        <FontAwesomeIcon style={{ width: 10 }} icon={faUser} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.BlockRight, width: 50, height: 40 }}>
                                    <IconButton onClick={() => setChoose(true)} style={{ ...styles.ButtonIcon }}>
                                        <FontAwesomeIcon style={{ width: 10 }} icon={faPlus} />
                                    </IconButton>
                                </div>
                            </div>
                            <FormText require redraw value={header} editing={true} title={lang.email_header} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setHeader(txt)} />
                            <FormSpace />
                        </div>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            defaultContentState={contentState}
                            onContentStateChange={setContentState}

                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"

                            editorStyle={{ backgroundColor: color.white, width: dialogWidth - 30, height: editorHeight, border: '1px solid ' + color.light_gray }}
                            toolbarStyle={{ backgroundColor: color.back, width: dialogWidth - 50 }}

                            toolbar={{
                                options: ['inline', 'blockType', 'list', 'history'],
                                inline: { inDropdown: false },
                                list: { inDropdown: false },
                                textAlign: { inDropdown: false },
                                history: { inDropdown: false },
                                inline: {
                                    inDropdown: false,
                                    options: ['bold', 'italic', 'underline'],
                                },
                                blockType: {
                                    inDropdown: false,
                                    options: ['Normal', 'H1', 'H2', 'H3'],
                                },
                                list: {
                                    inDropdown: false,
                                    options: ['unordered', 'ordered'],
                                },
                            }}
                        />
                    </div>

                    <div style={{ ...styles.Block, width: '95%', height: errorHeight + buttonHeight }}>
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            {error != '' ?
                                <FormError theme={props.theme} error={error} small />
                                : null}
                            {info != '' ?
                                <FormOK theme={props.theme} text={info} />
                                : null}
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            {info == false ?
                                <Button onClick={() => Process()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 200 }}>
                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPaperPlane} />
                                    {lang.email_send}
                                </Button>
                                :
                                <Button onClick={() => Close(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 200 }}>
                                    {lang.close}
                                </Button>
                            }
                        </div>
                    </div>

                </div>
            </Paper>

            {showChoose == true ?
                <DialogChoose remote={props.remote} remoteMobile={props.remoteMobile} title={lang.email_choose_label} text={lang.email_choose_text} sub_text={''} icon={faQuestion} yes={lang.user} no={lang.customer} yes_color={color.button_cancel} no_color={color.button_cancel} buttonWidth={200} theme={props.theme} radius={props.radius} func={ChooseResult.bind(this)} />
                : null}

            {showCustomers == true ?
                <CustomerSelect remote={props.remote} remoteMobile={props.remoteMobile} email single showVariations={true} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.customer_select_} token={props.token} func={CustomersResult.bind()} />
                : null}

            {showUsers == true ?
                <UsersSelect remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.user_select} token={props.token} func={UserResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} offset={props.offset} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={props.lang.db_error} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}



export const DialogNotify = (props) => {
    /*
                        ODOSLAT notifikáciu vsetkým / jednému zákazníkom

                        USAGE:
                        <DialogNotify lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={DialogNotifyResult.bind(this)} />

            const DialogNotifyResult = (typ,value) => {
                            console.log(value)
                        }
                        */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);
    const [info, setInfo] = useState(false);
    const [text, setText] = useState(' ');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 320 ? 320 : height;

    const errorHeight = 60;
    const buttonHeight = 80;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;
    const inputRef = useRef(null);

    var running = false;
    useEffect(() => {

        if (running == false) {
            running = true;
        }

        // FOCUS TO TEXTAREA
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);

    }, []);

    const db_send_notifies = async (data) => {
        // ODOSLAT HROMADNU SPRAVU
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'clients_notifies', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
                setInfo(lang.notifies_sended + ' ' + json.count_ok);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_send_notify = async (data) => {
        // ODOSLAT MAIL JEDNEMU ZAKAZNIKOVI
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'client_notify', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
                setInfo(lang.notify_sended);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Process = () => {
        setError('');
        setInfo('');

        if (IsEmpty(text) == false) {
            var data = {
                salon_id: props.user_id,
                language: props.language,
                text: text.trim()
            }
            if (props.client != undefined) {
                data.client_id = props.client.id;
                db_send_notify(data);
            } else {
                db_send_notifies(data);
            }
        }
    }



    const ShowOKPress = () => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faEnvelope} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {/* BODY */}
                    <div style={{ ...styles.Block, width: dialogWidth }}>
                        <div style={{ ...styles.Block, width: '95%', height: 100 }}>
                            <TextInput inputRef={inputRef} required redraw enabled={true} lang={lang} value={text} label={lang.notify_text} func={(txt) => setText(txt)} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '95%' }}>
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            {error != '' ?
                                <FormError error={error} />
                                : null}
                            {info != '' ?
                                <FormOK text={info} />
                                : null}
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 200 }}>
                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPaperPlane} />
                                {lang.notify_send}
                            </Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {showOK == true ?
                <ShowOK offset={props.offset} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}


export const DialogAgreements = (props) => {
    /*
            GDPR, ....
            
            USAGE:
            <DialogAgreements typ={0} lang={lang} radius={props.radius} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={DialogAgreementsResult.bind(this)} />
            -------------------------------------------------------
            typ:
                    0 - všeobecné obchodné podmienky Eshop / CLient
                    1 - GDPR
                    2 - cookies
                    3 - vernostný systém                                        
                    4 - GDPR súhlas so spracovaním osobných údajov
                    
            -------------------------------------------------------
 
            const DialogAgreementsResult = (typ,value) => {
                console.log(value)
            }
    */
    let titles = [
        props.lang.vop,
        props.lang.gdpr,
        props.lang.cookies,
        props.lang.bonus_system,
        props.lang.gdpr_agreement_doc,
        '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        props.lang.vop,
    ];

    const [title, setTitle] = useState(titles[props.typ]);
    const [isBusy, setBusy] = useState(true);
    const [html, setHtml] = useState(false);
    const [email, setEmail] = useState(props.email == undefined ? '' : props.email);
    const [showEnterEmail, setShowEnterEmail] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [pdfLink, setPdfLink] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [radius, setRadius] = useState(height > 768 ? props.radius : 0);
    const inputRef = useRef(null);

    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;
    const buttonHeight = isSmall == true ? 60 : 0;

    let { func } = props;
    var lang = props.lang;
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_agreements();
        }

        return () => {
        };

    }, []);

    const db_agreements = async () => {
        try {
            const response = await fetch(
                global.db_url + 'agreements', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    client_id: props.client == undefined ? 0 : props.client.id,
                    typ: props.typ
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setHtml(json.html);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_agreements_print = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'agreements_print', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    client_id: props.client == undefined ? 0 : props.client.id,
                    typ: props.typ
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setPdfLink(json.filename);
                setTimeout(() => {
                    setBusy(false);
                    inputRef.current.click();
                }, 300);
            } else {
                setBusy(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_agreements_send = async (email) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'agreements_send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    client_id: props.client == undefined ? 0 : props.client.id,
                    typ: props.typ,
                    email: email,
                    lang: {
                        label: titles[props.typ],
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text_
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Print = () => {
        db_agreements_print();
    }

    const Send = () => {
        var e_mail = '';
        if (props.client != undefined) {
            if (props.client.email != '') {
                e_mail = props.client.email;
            }
        }

        if (props.email == undefined) {
            if (e_mail == '') {
                setEmail('');
                setShowEnterEmail(true);
            } else {
                setEmail(e_mail);
                setShowEnterEmail(true);
            }
        } else {
            setShowEnterEmail(true);
        }
    }

    const EnterEmailResult = (value, text) => {
        setShowEnterEmail(false);

        if (value == true) {
            if (nonEmail(text) == false) {
                db_agreements_send(text);
            } else {
                setTimeout(() => {
                    setShowEmailError(true);
                }, 200);
            }
        }
    }


    const DialogInfoResult = () => {
        setShowEmailError(false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    return (

        <Backdrop open={true} style={{  /*paddingLeft: width > global.max_screen ? global.menu_width : 0, */ backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faFile} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '55%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    {isSmall == true ? null :
                        <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height }}>
                            <Button onClick={() => Send()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 160 }}>
                                <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faShare} />
                                {lang.send}
                            </Button>
                        </div>
                    }
                    {isSmall == true ? null :
                        <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height }}>
                            <a ref={inputRef} href={global.pdf_exported + pdfLink} style={{ textDecoration: 'none' }} target='_blank'></a>
                            <Button onClick={() => Print()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 160 }}>
                                <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faPrint} />
                                {lang.print}
                            </Button>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>

                </div>
                {isSmall ?
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button onClick={() => Send()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 160 }}>
                                <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faShare} />
                                {lang.send}
                            </Button>
                            <a ref={inputRef} href={global.pdf_exported + pdfLink} style={{ textDecoration: 'none' }} target='_blank'></a>
                            <Button onClick={() => Print()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 160, marginLeft: 20 }}>
                                <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faPrint} />
                                {lang.print}
                            </Button>
                        </div>
                    </div>
                    : null}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - global.dialog_bar_height, overflowY: 'scroll' }}>
                        {/* BODY */}
                        {html != false ?
                            <div style={{ ...styles.TextSmall, width: '95%', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: html }} />
                            : null}
                    </div>
                </div>
            </Paper>

            {showEnterEmail == true ?
                <DialogEnterText theme={props.theme} rows={1} radius={props.radius} title={lang.email} sub_title={lang.gdpr_enter_email} text={email} icon={faEnvelope} button={props.lang.send} func={EnterEmailResult.bind(this)} />
                : null}

            {showEmailError == true ?
                <DialogInfo theme={props.theme} radius={props.radius} title={lang.format_error} sub_title={''} text={lang.email_wrong_format} icon={faEnvelope} button={props.lang.close} icon_show={faXmarkCircle} icon_color={global.theme_dark_red} func={DialogInfoResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} center func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading theme={props.theme} center></Loading>
                : null}


        </Backdrop >
    );
}



export const DialogGDPR = (props) => {
    /*
                        GDPR, ....

                        USAGE:
                        <DialogGDPR lang={lang} user={props.user} radius={props.radius} func={DialogGDPRResult.bind(this)} />
 
            const DialogGDPRResult = (typ,value) => {
                            console.log(value)
                        }
                        */

    const [isBusy, setBusy] = useState(false);
    const [showGDPR, setShowGDPR] = useState(false);
    const [showVOP, setShowVOP] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 800;
    const dialogHeight = 350;
    var lang = props.lang;

    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);

    const db_save = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            setBusy(false);
            Press(true);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (value) => {
        func(value);
    }

    const Save = () => {
        let data = {
            id: props.user.id,
            gdpr_v2: true
        }

        db_save(data);
    }


    return (
        <Backdrop open={true} style={{ backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faQuestion} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? '95%' : '90%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{lang.gdpr_vop}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.vision_system}</p>
                            }
                        </div>
                    </div>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        </div>
                    }
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <p style={{ ...styles.TextSmall, textAlign: 'center' }}>{lang.gdpr_agree_text}</p>
                    <p style={{ ...styles.TextNormal, textAlign: 'center', fontWeight: '600', marginTop: 10 }}>{lang.gdpr_vop_agree_question}</p>
                    <Button onClick={() => setShowGDPR(true)} style={{ ...styles.ButtonLink, width: 350, marginTop: 40 }}>{lang.gdpr_info}...</Button>
                    <Button onClick={() => setShowVOP(true)} style={{ ...styles.ButtonLink, width: 350, marginTop: 10 }}>{lang.vop}...</Button>
                </div>
                <div style={{ ...styles.BlockRow, height: 100 }}>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Save()} style={{ ...styles.ButtonDark, backgroundColor: global.theme_darker_green, width: 170 }}>{lang.agree}</Button>
                    </div>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_red, width: 170 }}>{lang.disagree}</Button>
                    </div>

                </div>
            </Paper>

            {showGDPR == true ?
                <DialogAgreements theme={props.theme} typ={1} user={props.user} lang={lang} radius={props.radius} sub_title={lang.vision_system} backcolor={true} background={false} func={() => setShowGDPR(false)} />
                : null}

            {showVOP == true ?
                <DialogAgreements theme={props.theme} typ={0} user={props.user} lang={lang} radius={props.radius} sub_title={lang.vision_system} backcolor={true} background={false} func={() => setShowVOP(false)} />
                : null}

            {isBusy == true ?
                <Loading center ></Loading>
                : null}

        </Backdrop>
    );
}




export const GDPRDetails = (props) => {
    /*
                        OKNO - full screen

                        USAGE:
                        <GDPRDetails data={data} lang={lang} radius={props.radius} func={GDPRDetailsResult.bind(this)} />
 
            const DialogResult = (typ,item) => {
                            console.log(value)
                        }
                        */

    const [isBusy, setBusy] = useState(false);
    const [basicData, setBasicData] = useState(true /* props.data.basic_data == false ? false : true */);
    const [gallery, setGallery] = useState(props.data.gallery == false ? false : true);
    const [notes, setNotes] = useState(props.data.notes == false ? false : true);
    const [diagnostic, setDiagnostic] = useState(props.data.diagnostic == false ? false : true);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 700;
    const dialogHeight = props.isSmall ? height : 550;
    const divWidth = props.isSmall ? '96%' : 450;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(false);
    }

    const Process = () => {
        let items = {
            basic_data: true,/* basicData,*/
            notes: notes,
            gallery: gallery,
            diagnostic: diagnostic
        }
        Press(true, items);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.gdpr_details}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.gdpr}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.BlockLeft, height: 60, width: divWidth, marginTop: 20 }}>
                            <p style={{ ...styles.TextSmall }}>{lang.gdpr_settings_text}</p>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: divWidth }}>
                            <FormControlLabel style={{ margin: 0, padding: 0 }} label={<p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.gdpr_settings_basic}</p>}
                                control={<Checkbox disabled checked={basicData} size={'small'} onChange={() => setBasicData(!basicData)} />}
                            />
                            <p style={{ ...styles.TextTiny, marginLeft: 36, textAlign: 'left' }}>{lang.gdpr_settings_basic_text}</p>
                            <FormControlLabel style={{ margin: 0, padding: 0, marginTop: 10 }} label={<p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.gdpr_settings_notes}</p>}
                                control={<Checkbox checked={notes} size={'small'} onChange={() => setNotes(!notes)} />}
                            />
                            <p style={{ ...styles.TextTiny, marginLeft: 36, textAlign: 'left' }}>{lang.gdpr_settings_notes_text}</p>
                            <FormControlLabel style={{ margin: 0, padding: 0, marginTop: 10 }} label={<p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.gdpr_settings_gallery}</p>}
                                control={<Checkbox checked={gallery} size={'small'} onChange={() => setGallery(!gallery)} />}
                            />
                            <p style={{ ...styles.TextTiny, marginLeft: 36, textAlign: 'left' }}>{lang.gdpr_settings_gallery_text}</p>
                            <FormControlLabel style={{ margin: 0, padding: 0, marginTop: 10 }} label={<p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.gdpr_settings_diagnostic}</p>}
                                control={<Checkbox checked={diagnostic} size={'small'} onChange={() => setDiagnostic(!diagnostic)} />}
                            />
                            <p style={{ ...styles.TextTiny, marginLeft: 36, textAlign: 'left' }}>{lang.gdpr_settings_diagnostic_text}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Process()} style={{ ...styles.ButtonDark }}>{lang.save}</Button>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}




export const DialogGdprLog = (props) => {
    /*
                        OKNO - full screen

                        USAGE:
                        <DialogGdprLog lang={lang} radius={props.radius} func={DialogGdprLogResult.bind(this)} />
 
            const DialogGdprLogResult = (typ,value) => {
                            console.log(value)
                        }
                        */

    const [isBusy, setBusy] = useState(true);
    const [items, setItems] = useState(false);
    const [showAskExport, setShowAskExport] = useState(false);
    const [showOK, setShowOK] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 800;
    const dialogHeight = props.isSmall ? height : 600;
    const rowOffset = 20;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            db_get();
            running = true;
        }

        return () => {
        };

    }, []);

    const db_get = async () => {
        try {
            const response = await fetch(
                global.db_url + 'gdpr_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: props.client_id
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                var tmp = json.items;
                var last = false;

                tmp.forEach(item => {
                    var d = BinaryToData(item.action);

                    let data = {
                        basic_data: d.d1,
                        notes: d.d2,
                        gallery: d.d3,
                        diagnostic: d.d4
                    }

                    if (last != false) {
                        data.basic_data_changed = (data.basic_data == last.basic_data ? false : true);
                        data.notes_changed = (data.notes == last.notes ? false : true);
                        data.gallery_changed = (data.gallery == last.gallery ? false : true);
                        data.diagnostic_changed = (data.diagnostic == last.diagnostic ? false : true);
                    } else {
                        data.basic_data_changed = false;
                        data.notes_changed = false;
                        data.gallery_changed = false;
                        data.diagnostic_changed = false;
                    }

                    item.data = data;
                    item.datum = GetDatumTime(lang.months, item.date);
                    if (item.typ != 4 && item.typ != 5 && item.typ != 6) {
                        last = data;
                    }
                });

                setItems(tmp);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_send = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'gdpr_send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: props.client_id,
                    salon_id: props.user.id,
                    items: items,
                    lang: {
                        www_eshop: lang.www_eshop,
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text_,
                        gdpr_logs: lang.gdpr_logs,
                        gdpr_logs_send_email_text: lang.gdpr_logs_send_email_text,
                        header: lang.gdpr_logs,
                        created: lang.created,
                        gdpr_logs_type: lang.gdpr_logs_type,
                        client_agreements: lang.client_agreements,
                        date: lang.date,
                        gdpr_settings_basic: lang.gdpr_settings_basic,
                        gdpr_settings_gallery: lang.gdpr_settings_gallery,
                        gdpr_settings_notes: lang.gdpr_settings_notes,
                        gdpr_settings_diagnostic: lang.gdpr_settings_diagnostic,
                        yes: lang.yes,
                        no: lang.no,
                        permission_added: lang.permission_added,
                        permission_removed: lang.permission_removed,
                        action: lang.action,
                        gdpr_logs_send_table_text: lang.gdpr_logs_send_table_text,
                        email: lang.email_
                    }
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                setShowOK(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Export = (typ) => {
        setShowAskExport(false);
        if (typ == true) {
            db_send();
        }
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? '50%' : '70%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.gdpr_logs}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.gdpr}</p>
                            }
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height }}>
                        {items != false ?
                            <Button onClick={() => setShowAskExport(true)} style={{ ...styles.ButtonDark, width: 160 }}>
                                <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faShare} />
                                {lang.send}
                            </Button>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - global.dialog_bar_height - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container' >
                        {/* BODY */}
                        {items != false ? items.map((item, index) => (
                            <div key={item.id} style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, paddingTop: 15, paddingBottom: 15, backgroundColor: global.theme_white, marginTop: 5, marginBottom: 5 }}>
                                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockLeft, width: props.isSmall ? '100%' : 220 }}>
                                    <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.datum}</p>
                                </div>
                                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockLeft, width: props.isSmall ? '100%' : '60%' }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.gdpr_logs_type[item.typ]}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 5, color: global.theme_dark_gray }}>{lang.email_}: {item.email}</p>
                                </div>
                                {item.typ != 4 && item.typ != 5 && item.typ != 6 ?
                                    <div style={{ ...styles.BlockLeft, width: props.isSmall ? '90%' : '30%', marginTop: props.isSmall ? 20 : 0 }}>
                                        <div style={{ ...styles.BlockLeft, paddingBottom: 5, borderBottom: '1px solid ' + global.theme_medium_gray }}>
                                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.client_agreements}:</p>
                                        </div>
                                        <div style={{ ...styles.BlockRow, marginTop: 5 }}>
                                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.basic_data_changed == true ? global.theme_dark_red : global.theme_black }}>{lang.gdpr_settings_basic}:</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.basic_data_changed == true ? global.theme_dark_red : global.theme_black }}>{item.data.basic_data == true ? lang.yes : lang.no}</p>
                                            </div>
                                        </div>
                                        {item.data.basic_data_changed == true ?
                                            <p style={{ ...styles.TextXTiny, marginLeft: 5 }}>({item.data.basic_data == true ? lang.permission_added : lang.permission_removed})</p>
                                            : null}

                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.notes_changed == true ? global.theme_dark_red : global.theme_black }}>{lang.gdpr_settings_notes}:</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.notes_changed == true ? global.theme_dark_red : global.theme_black }}>{item.data.notes == true ? lang.yes : lang.no}</p>
                                            </div>
                                        </div>
                                        {item.data.notes_changed == true ?
                                            <p style={{ ...styles.TextXTiny, marginLeft: 5 }}>({item.data.notes == true ? lang.permission_added : lang.permission_removed})</p>
                                            : null}

                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.gallery_changed == true ? global.theme_dark_red : global.theme_black }}>{lang.gdpr_settings_gallery}:</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.gallery_changed == true ? global.theme_dark_red : global.theme_black }}>{item.data.gallery == true ? lang.yes : lang.no}</p>
                                            </div>
                                        </div>
                                        {item.data.gallery_changed == true ?
                                            <p style={{ ...styles.TextXTiny, marginLeft: 5 }}>({item.data.gallery == true ? lang.permission_added : lang.permission_removed})</p>
                                            : null}

                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.diagnostic_changed == true ? global.theme_dark_red : global.theme_black }}>{lang.gdpr_settings_diagnostic}:</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.diagnostic_changed == true ? global.theme_dark_red : global.theme_black }}>{item.data.diagnostic == true ? lang.yes : lang.no}</p>
                                            </div>
                                        </div>
                                        {item.data.diagnostic_changed == true ?
                                            <p style={{ ...styles.TextXTiny, marginLeft: 5 }}>({item.data.diagnostic == true ? lang.permission_added : lang.permission_removed})</p>
                                            : null}

                                    </div>

                                    : <div style={{ ...styles.Block, width: '30%' }}></div>}

                            </div>
                        )) :
                            <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                                {isBusy == false ?
                                    <EmptyList lang={lang} />
                                    : null}
                            </div>
                        }
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Close()} style={{ ...styles.ButtonDark }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>

            {showAskExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.gdpr_logs_send_label} sub_title={''} text={lang.gdpr_logs_send_text} sub_text={lang.gdpr_export_question_ + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={Export.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}
        </Backdrop>
    );
}


export const DialogHelpSelect = (props) => {
    /*
                        VYBER - pomocník / kontaktujte nás

                        USAGE:
                        <DialogHelpSelect lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={HelpSelectResult.bind(this)} />

            const HelpSelectResult = (typ,value) => {
                            console.log(value)
                        }
                        */

    const [visible, setVisible] = useState(true);
    const [error, setError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall == true ? width - 20 : 600;
    const dialogHeight = 280;
    const butonSize = props.isSmall == true ? 80 : 100;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (typ) => {
        setVisible(false);
        var timer = setTimeout(() => {
            func(typ);

        }, global.dialog_close_delay);
    }

    return (
        <Backdrop open={visible} transitionDuration={global.dialog_close_delay} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(0)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '95%', height: dialogHeight - global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button onClick={() => Press(1)} style={{ ...styles.ButtonDark, width: butonSize * 2, height: butonSize, backgroundColor: global.themes[props.theme].theme_white }}>
                                <div style={{ ...styles.Block }}>
                                    <FontAwesomeIcon style={{ height: 36, color: global.theme_blue }} icon={faBookOpen} />
                                    <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.helper}</p>
                                </div>
                            </Button>
                            <Button onClick={() => Press(2)} style={{ ...styles.ButtonDark, width: butonSize * 2, height: butonSize, backgroundColor: global.themes[props.theme].theme_white, marginLeft: 40 }}>
                                <div style={{ ...styles.Block }}>
                                    <FontAwesomeIcon style={{ height: 36, color: global.theme_blue }} icon={faAt} />
                                    <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.help_contact_us}</p>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}


export const DialogContactUs = (props) => {
    /*
                        VYBER - kontaktujte nás

                        USAGE:
                        <DialogContactUs theme={props.theme} lang={lang} radius={props.radius} func={ContactUsResult.bind(this)} />

            const ContactUsResult = (typ,value) => {
                            console.log(value)
                        }
                        */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [showOK, setShowOK] = useState(false);
    const [message, setMessage] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 800;
    const dialogHeight = 500;
    const errorSize = 80;
    const butonSize = 80;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const db_send = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'send_message', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    message: message
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.sended == true) {
                    setShowOK(true);
                } else {
                    setError(lang.send_err)
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ) => {
        func(typ);
    }

    const Process = () => {
        setError('');

        if (message.trim() != '') {
            db_send();
        }
    }


    return (
        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faAt} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.help_contact_us}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.vision_system}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(0)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - global.dialog_bar_height - butonSize - errorSize }}>
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.contact_us_text1}</p>
                            <p style={{ ...styles.TextSmall, marginTop: 5 }}>{lang.contact_us_text2}</p>
                        </div>
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <TextInput rows={6} redraw enabled={true} lang={lang} value={message} label={lang.contact_us_note} func={(txt) => { setMessage(txt) }} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '95%', height: errorSize }}>
                        <FormError small error={error}></FormError>
                        {showOK == true ?
                            <FormOK small text={lang.notify_sended}></FormOK>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: '95%', height: butonSize }}>
                        <Button disabled={message.trim() == '' ? true : false} onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: message.trim() == '' ? global.theme_light_gray : global.themes[props.theme].button_dark, color: message.trim() == '' ? global.theme_medium_gray : global.theme_white }}>{lang.send}</Button>
                    </div>
                </div>
            </Paper>

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}
        </Backdrop>
    );
}


export const Line = (props) => {
    /*

            <Line color={'#f0f0f0'} theme={props.theme}/>            
        
            PARAMS:
            -----------------------------------------------------
            color       -> farba ciary
            marginTop
            marginBottom
        
    */

    let color = global.themes[props.theme];
    return (
        <div style={{ ...styles.Block, marginTop: props.marginTop == undefined ? 0 : props.marginTop, marginBottom: props.marginBottom == undefined ? 0 : props.marginBottom }}>
            <div style={{ ...styles.Block, height: 1, backgroundColor: props.color == undefined ? color.light_gray : props.color }}></div>
        </div >
    );
}

export const FormSpace = (props) => {
    /*

            <FormSpace />
            -----------------------------------------------------
        
        
    */

    return (
        <div style={{ ...styles.Block, minHeight: props.height == undefined ? 20 : props.height }}>
        </div >
    );
}


export const FormLabel = (props) => {
    /*
            <FormLabel editing={editing} editButton={false} icon={faInfoCircle} title={'nazov'} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
            
            --------------------------------------------------------
            USAGE:
            icon    -> ak je zadané, zobrazí sa pred napisom okona


            NAVRATOVÉ HODNOTY:
            0   - začatie editovania

    */

    const [value, setValue] = useState(0);

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;

    useEffect(() => {

        return () => {
        };

    }, []);

    const StartEditing = () => {
        // 0 -> začatie editovania
        func(0);
    }

    return (
        <div style={{ ...styles.BlockRowRaw, paddingBottom: props.paddingBottom == undefined ? 10 : props.paddingBottom, marginTop: props.marginTop == undefined ? 0 : props.marginTop, minHeight: props.small == undefined ? 25 : 20, marginBottom: 5, borderBottom: props.editing == true ? props.hide_border == true ? '' : color.border : '' }}>
            <div style={{ ...styles.BlockLeft, width: column1 + column2 - 50 }}>
                <p style={{ ...props.small == true ? styles.TextSmall : styles.TextLarge, color: color.black, fontWeight: '600' }}>{props.icon == undefined ? null : <span><FontAwesomeIcon style={{ color: color.dark_cyan, marginRight: 10 }} icon={props.icon} /></span>}{props.title}</p>
            </div>
            <div style={{ ...styles.BlockRight, width: 50 }}>
                {props.editing == false && props.editButton == true ?
                    <IconButton onClick={() => StartEditing()} style={{ ...styles.ButtonIcon }}>
                        <FontAwesomeIcon style={{}} icon={props.buttonIcon == undefined ? faPen : props.buttonIcon} />
                    </IconButton>
                    : null}
            </div>
        </div>
    );
}

export const FormSubLabel = (props) => {
    /*
            <FormSubLabel title={'nazov'} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius}/>
            
    */

    const [value, setValue] = useState(0);

    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;

    useEffect(() => {

        return () => {
        };

    }, []);

    return (
        <div style={{ ...styles.BlockLeft, width: column1 + column2, marginBottom: props.marginBottom == undefined ? 10 : props.paddingBottom, marginTop: props.marginTop == undefined ? 0 : props.marginTop, height: 30 }}>
            <div style={{ ...styles.BlockRowRaw }}>
                <div style={{ ...styles.BlockLeft, width: 16, height: 30 }}>
                    <FontAwesomeIcon style={{ height: 8, color: color.darker_gray }} icon={faCircle} />
                </div>
                <div style={{ ...styles.BlockLeft, width: undefined, height: 30 }}>
                    <p style={{ ...styles.TextSmall, color: color.darker_gray, fontWeight: '600' }}>{props.icon == undefined ? null : <span><FontAwesomeIcon style={{ color: color.dark_cyan, marginRight: 10 }} icon={props.icon} /></span>}{props.title}</p>
                </div>
            </div>
        </div>
    );
}


export const FormNote = (props) => {
    /*

            Poznámka k zadávaniu údajov

            <FormNote title={'nazov'} icon={faInfoCircle} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius}/>
            
            --------------------------------------------------------
            USAGE:
            icon    -> ak je zadané, zobrazí sa pred napisom okona

    */

    const [value, setValue] = useState(0);

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = 24;

    useEffect(() => {

        return () => {
        };

    }, []);

    const StartEditing = () => {
        // 0 -> začatie editovania
        func(0);
    }

    return (
        <div style={{ ...styles.BlockRowRaw, paddingBottom: props.marginBottom == undefined ? 10 : props.marginBottom, marginTop: props.marginTop == undefined ? 0 : props.marginTop, minHeight: rowHeight }}>
            <div style={{ ...styles.BlockLeft, width: column1 }}>
            </div>
            <div style={{ ...styles.BlockLeft, width: column2 }}>
                <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 6 }}>{props.icon == undefined ? null : <span><FontAwesomeIcon style={{ marginRight: 5 }} icon={faInfoCircle} /></span>}{props.title}</p>            </div>
        </div>
    );
}

export const FormInfo = (props) => {
    /*

            Poznámka k zadávaniu údajov

            <FormInfo title={lang.contact_to_customer_note} marginTop={2} theme={props.theme} lang={lang} radius={props.radius}/>
            
            --------------------------------------------------------
            USAGE:
            icon    -> ak je zadané, zobrazí sa pred napisom okona

    */

    let lang = props.lang;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);

    return (
        <div style={{ ...styles.BlockRowRaw, paddingBottom: props.marginBottom == undefined ? 10 : props.marginBottom, marginTop: props.marginTop == undefined ? 0 : props.marginTop, height: 30 }}>
            <div style={{ ...styles.BlockLeft, width: undefined }}>
                <FontAwesomeIcon style={{ height: 16, color: color.dark_gray }} icon={faInfoCircle} />
            </div>
            <div style={{ ...styles.BlockLeft, width: undefined }}>
                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{props.icon == undefined ? null : <span><FontAwesomeIcon style={{ marginRight: 5 }} icon={faInfoCircle} /></span>}{props.title}</p>            </div>
        </div>
    );
}


export const FormText = (props) => {
    /*
            <FormText require help_id={1} value={} editing={editing} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />

            ------------------------------------------------------------------
            require     -> cervený pásik - požadovaná hodnota
            marginTop   -> vrchný okraj
            tiny        -> tiny text po editácii
            help_id     -> zobrazovať quickhelp
            no_border   -> nevykreslovat spodnú čiaru

    */

    const [value, setValue] = useState(props.value);

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let helpWidth = 30;

    useEffect(() => {

        setValue(props.value);

        return () => {
        };

    }, [props.editing, props.value]);

    const Process = (txt) => {
        if (props.redraw == true) {
            setValue(txt);
        }
        func(txt, false);
    }

    const ResectPercentage = () => {
        setValue('0%');
        func('1');
    }

    const ButtonPress = () => {
        func('', true);
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? props.no_border == undefined ? '1px dotted ' + color.form_line : '' : '' }}>
            {props.column1 > 0 ?
                <div style={{ ...styles.BlockRowRaw, marginRight: props.title != undefined ? 10 : 0 }}>
                    <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                        {props.title != undefined ?
                            <p style={{ ...styles.TextXSmall, textAlign: 'right', color: props.title_color == undefined ? color.dark_gray : props.title_color }}>{props.title}:</p>
                            : null}
                    </div>
                    {props.help_id != undefined ?
                        <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                            <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                        </div>
                        : null}
                </div>
                : null}
            <div style={{ ...styles.BlockLeft, width: column2, height: props.rows == undefined ? rowHeight : undefined }}>
                {props.editing == false ?
                    props.rows == undefined ?
                        <div style={{ display: 'block', alignContent: 'flex-start', maxWidth: '98%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            <span style={{ ...props.tiny == true ? styles.TextXTiny : styles.Text, textAlign: 'left' }}>{props.date == undefined ? props.money == true ? FormatMoney(value, props.precisions) + ' ' + props.currency : value : value == null ? '' : GetDatum2(value)}</span>
                        </div>
                        :
                        <div style={{ ...styles.BlockLeft }}>
                            <p style={{ ...props.tiny == true ? styles.TextXTiny : styles.Text, textAlign: 'left' }}>{props.date == undefined ? props.money == true ? FormatMoney(value, props.precisions) + ' ' + props.currency : value : value == null ? '' : GetDatum2(value)}</p>
                        </div>
                    :
                    <div style={{ ...styles.BlockRowRaw, width: props.percentage == true || props.button == true ? undefined : column2, maxWidth: column2 }}>
                        <TextInput
                            redraw
                            placeholder={props.placeholder == undefined ? undefined : props.placeholder}
                            rows={props.rows == undefined ? undefined : props.rows}
                            time={props.time == undefined ? undefined : props.time}
                            width={props.width == undefined ? props.percentage == true || props.button == true ? column2 - 40 : column2 : props.width}
                            percentage={props.percentage == true ? true : undefined}
                            money={props.money == true ? true : false}
                            calc={props.money == true ? true : props.calc == true ? true : false}
                            money_text={props.currency != undefined ? props.currency : undefined}
                            numeric={props.numeric == true || props.money == true ? true : false}
                            phone={props.phone == true ? true : false}
                            password={props.password == true ? true : false}
                            date={props.date == undefined ? false : true}
                            require={props.require == undefined || props.require == false ? false : true}
                            enabled={props.enabled == undefined ? undefined : props.enabled}
                            end={props.end == undefined ? undefined : props.end}
                            lang={lang}
                            value={props.date == undefined ? value : value == null ? Today() : value}
                            radius={radius}
                            maxLength={props.maxLength == undefined ? undefined : props.maxLength}
                            inputRef={props.inputRef == undefined ? null : props.inputRef}
                            func={(txt) => { Process(txt) }}
                        />
                        {props.percentage == true ?
                            // RESET PERCENT NA 0%
                            <div style={{ ...styles.BlockRight, width: 40 }}>
                                <IconButton onClick={() => ResectPercentage()} style={{ ...styles.ButtonIcon, marginLeft: 10, marginTop: 2 }}>
                                    <FontAwesomeIcon style={{ width: 16 }} icon={faXmark} />
                                </IconButton>
                            </div>
                            : null}

                        {props.button == true ?
                            // TLACITKO
                            <div style={{ ...styles.BlockRight, width: 40 }}>
                                <IconButton onClick={() => ButtonPress()} style={{ ...styles.ButtonIcon, marginLeft: 10, marginTop: 2 }}>
                                    <FontAwesomeIcon style={{ width: 16 }} icon={props.button_icon == undefined ? faCheck : props.button_icon} />
                                </IconButton>
                            </div>
                            : null}
                    </div>
                }
            </div>

        </div>
    );
}



export const FormChips = (props) => {
    /*
            <FormChips require value={array} editing={editing} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />

            ------------------------------------------------------------------
            require     -> cervený pásik - požadovaná hodnota
            marginTop   -> vrchný okraj
            tiny        -> tiny text po editácii
            buffer      -> max. bytes array buffer

            */

    const [items, setItems] = useState(props.value);
    const [text, setText] = useState('');
    const [helper, setHelper] = useState('');

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let buffer = props.buffer == undefined ? 240 : props.buffer;

    useEffect(() => {

        setItems(props.value);
        CheckFree();

        return () => {
        };

    }, [props.editing, props.value]);

    const Process = (txt) => {
        setItems(txt);
        func(txt);
    }

    const AddItem = (item) => {
        if (text.trim() != '') {
            var tmp = items == false ? [] : items;
            let len = CheckFree();

            // LEN POKIAL JE VOLNY BUFFER
            if (len < buffer) {

                // KONTROLA CI UZ JE KOD V ARRAY
                let check = tmp.filter(x => x.label == text);

                if (check.length == 0) {
                    var id = 1;
                    tmp.forEach(element => {
                        if (element.id >= id) {
                            id = parseInt(element.id) + 1;
                        }
                    });
                    let data = { id: id, label: text };
                    tmp.push(data);

                    var n = 1;
                    tmp.forEach(element => {
                        element.id = n;
                        n++;
                    });

                    setItems(tmp);
                    setText('');
                }
            }

            func(tmp.length == 0 ? false : tmp);
        }

    }

    const CheckFree = () => {
        // KONTROLA DLZKY RETAZCOV SPOLU
        var tmp = items == false ? [] : items;
        var help = '';
        var len = 0;

        tmp.forEach(element => {
            len += 25 + element.label.length;
        });

        if (text.trim() != '') {
            len += 25 + text.length;
        }
        if (len > buffer) {
            help = lang.bytes_left_out;
        } else {
            help = lang.bytes_left.replace('@byte', buffer - len);
        }
        setHelper(help);

        return len;
    }

    const DeleteItem = (item) => {
        let tmp = items.filter(x => x.id != item.id);
        setItems(tmp);
        func(tmp.length == 0 ? false : tmp);
    }

    const LostFocus = () => {
        AddItem();
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRight, width: column1, minHeight: rowHeight }}>
                <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray, marginRight: 10 }}>{props.title}:</p>
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, minHeight: rowHeight }}>
                {props.editing == false ?
                    null :
                    <div style={{ ...styles.Block }}>
                        <TextField
                            value={text}
                            onBlur={() => LostFocus()}
                            onInput={e => setText(e.target.value)}
                            size="small"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            style={{ width: '100%' }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    AddItem();
                                }
                            }}
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },
                                fieldset: { borderWidth: 1, borderColor: color.xxgray, borderLeft: props.require == true ? '3px solid #FF0000' : undefined }
                            }}
                            InputProps={{
                                style: { height: 36, backgroundColor: color.white, borderRadius: global.radius, color: color.black },
                            }}
                            type={'text'}
                            variant="outlined"
                            placeholder={lang.insert_press_enter}
                            helperText={props.editing == true ? helper != '' ? helper : '' : ''}
                            FormHelperTextProps={{ style: { color: color.dark_gray, letterSpacing: 0, margin: 0, padding: 0, marginLeft: 12, fontSize: 12 } }}
                        />
                    </div>
                }
                <div style={{ ...styles.Block, alignItems: 'flex-start', justifyContent: 'flex-start', width: column2, flexDirection: 'row', flexWrap: 'wrap' }}>
                    {items != false && items != undefined ? items.map((item, index) => (
                        props.editing == true ?
                            <Chip key={item.id} label={item.label} style={{ margin: 2 }} variant="outlined" onDelete={() => DeleteItem(item)} />
                            :
                            <Chip key={item.id} label={item.label} style={{ margin: 2 }} variant="outlined" />
                    )) : null}
                </div>
            </div>
        </div>
    );
}

export const FormCodes = (props) => {
    /*
            <FormCodes require value={array} product_id={itemID} typ={0} editing={editing} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />

            ------------------------------------------------------------------
            require     -> cervený pásik - požadovaná hodnota
            marginTop   -> vrchný okraj
            tiny        -> tiny text po editácii

            */

    const [items, setItems] = useState(false);
    const [text, setText] = useState('');
    const [helper, setHelper] = useState('');

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;

    var running = false;
    useEffect(() => {

        if (running == false) {

            db_get();
            running = true;
        }

        return () => {
        };

    }, [props.editing]);

    const db_get = async () => {

        try {
            const response = await fetch(
                global.db_url + 'product_codes', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    product_id: props.product_id,
                    enabled: true,
                    typ: props.typ
                })
            })

            const json = await response.json();
            Debug('product_codes');
            Debug(json);
            if (json.ok > 0) {
                setItems(json.items)
            }

        } catch (error) {
            console.log(error);
        }
    }

    const db_update = async (data) => {

        try {
            const response = await fetch(
                global.db_url + 'product_code_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setText('');
                db_get();
            }

        } catch (error) {
            console.log(error);
        }
    }

    const AddItem = () => {
        if (text.trim() != '') {
            let data = {
                id: 0,
                organization_id: props.organization.id,
                product_id: props.product_id,
                enabled: true,
                typ: props.typ,
                code: text
            };

            db_update(data);
        }

    }

    const DeleteItem = (item) => {
        let data = {
            id: item.id,
            enabled: false,
        };

        db_update(data);
    }

    const LostFocus = () => {
        AddItem();
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRight, width: column1, minHeight: rowHeight, marginRight: 10 }}>
                <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, minHeight: rowHeight }}>
                {props.editing == false ?
                    null :
                    <div style={{ ...styles.Block }}>
                        <TextField
                            value={text}
                            //onBlur={() => LostFocus()}
                            onInput={e => setText(e.target.value)}
                            size="small"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            style={{ width: '100%' }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    AddItem();
                                }
                            }}
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },
                                fieldset: { borderWidth: 1, borderColor: color.xxgray, borderLeft: props.require == true ? '3px solid #FF0000' : undefined }
                            }}
                            InputProps={{
                                style: { height: 36, backgroundColor: color.white, borderRadius: global.radius, color: color.black },
                            }}
                            type={'text'}
                            variant="outlined"
                            placeholder={lang.insert_press_enter}
                            helperText={props.editing == true ? helper != '' ? helper : '' : ''}
                            FormHelperTextProps={{ style: { color: color.dark_gray, letterSpacing: 0, margin: 0, padding: 0, marginLeft: 12, fontSize: 12 } }}
                        />
                    </div>
                }
                <div style={{ ...styles.Block, alignItems: 'flex-start', justifyContent: 'flex-start', width: column2, flexDirection: 'row', flexWrap: 'wrap' }}>
                    {items != false ? items.map((item, index) => (
                        props.editing == true ?
                            <Chip key={item.id} label={item.code} style={{ margin: 2 }} variant="outlined" onDelete={() => DeleteItem(item)} />
                            :
                            <Chip key={item.id} label={item.code} style={{ margin: 2 }} variant="outlined" />
                    )) : null}
                </div>
            </div>
        </div>
    );
}


export const FormList = (props) => {
    /*
            <FormList value={array} notDeletable={0} hideID={0} buttonLeft editing={editing} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />

            ------------------------------------------------------------------
            marginTop       -> vrchný okraj
            notDeletable    -> ID údaju, ktorý sa nedá vymazať
            hideID          -> skryť záznam z daným ID
            buttonLeft      -> tlačítko PLUS na lavej strane
            empty           -> text ak je zoznam prázdny
    */

    const [items, setItems] = useState(props.value);
    const [text, setText] = useState('');
    const [helper, setHelper] = useState('');

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let buffer = props.buffer == undefined ? 240 : props.buffer;

    useEffect(() => {

        setItems(props.value);

        return () => {
        };

    }, [props.editing, props.value]);

    const DeleteItem = (item) => {
        let tmp = items.filter(x => x.id != item.id);
        setItems(tmp);
        func(tmp.length == 0 ? false : tmp);
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRight, width: column1, minHeight: rowHeight }}>
                <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray, marginRight: 10 }}>{props.title}:</p>
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, minHeight: rowHeight }}>
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.Block, alignItems: 'flex-start', justifyContent: 'flex-start', width: column2, flexDirection: 'row', flexWrap: 'wrap' }}>
                        {props.buttonLeft == true ?
                            <div style={{ ...styles.Block, width: 40, height: rowHeight }}>
                                {props.editing == true ?
                                    <IconButton onClick={() => func(true)} style={{ ...styles.ButtonIcon }}>
                                        <FontAwesomeIcon style={{}} icon={props.buton_icon == undefined ? faPlus : props.buton_icon} />
                                    </IconButton>
                                    : null}
                            </div>
                            : null}
                        <div style={{ ...styles.BlockLeft, width: column2 - 40 }}>
                            <div style={{ ...styles.Block, alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {items == false ?
                                    <div style={{ ...styles.BlockLeft, width: column2, minHeight: rowHeight }}>
                                        <p style={{ ...styles.TextTiny, color: color.middle_gray }}>{props.empty == undefined ? lang.empty_list : props.empty}</p>
                                    </div>
                                    :
                                    items.length == 0 || (props.hideID > 0 && items.length == 1) ?
                                        <div style={{ ...styles.BlockLeft, width: column2, minHeight: rowHeight }}>
                                            <p style={{ ...styles.TextTiny, color: color.middle_gray }}>{props.empty == undefined ? lang.empty_list : props.empty}</p>
                                        </div>
                                        : null
                                }
                                {items != false && items != undefined ? items.map((item, index) => (
                                    props.hideID == item.id ? null :
                                        props.editing == true && props.notDeletable != item.id ?
                                            <Chip key={item.id} label={item[props.field]} style={{ margin: 2 }} variant="outlined" onDelete={() => DeleteItem(item)} />
                                            :
                                            <Chip key={item.id} label={item[props.field]} style={{ margin: 2 }} variant="outlined" />
                                )) :
                                    null
                                }
                            </div>
                        </div>
                        {props.buttonLeft != true ?
                            <div style={{ ...styles.Block, width: 40, height: rowHeight }}>
                                {props.editing == true ?
                                    <IconButton onClick={() => func(true)} style={{ ...styles.ButtonIcon }}>
                                        <FontAwesomeIcon style={{}} icon={props.buton_icon == undefined ? faPlus : props.buton_icon} />
                                    </IconButton>
                                    : null}
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        </div>
    );
}


export const FormFlags = (props) => {
    /*
            <FormFlags value={ } items={[]} field={'currency'} editing={editing} title={'nazov'} width={200} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCurrencyID(id)} />

            ------------------------------------------------------------------
            width       -> šírka pola <select>
            marginTop   -> vrchný okraj
                */

    const [value, setValue] = useState(props.value);

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let helpWidth = 30;

    useEffect(() => {

        setValue(props.value);

        return () => {
        };

    }, [props.editing, props.value]);

    const Process = (id) => {
        setValue(id);
        func(id);
    }

    const GetLabel = (id) => {
        var result = '';
        if (props.items != false) {
            props.items.forEach(item => {
                if (value.includes(item.id)) {
                    if (result != '') {
                        result += ', ';
                    }
                    result = result + item[props.field];
                }
            });
        }
        if (result == '') {
            result = lang.permission_none;
        }
        return result;
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRowRaw, marginRight: 10 }}>
                <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                    <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
                </div>
                {props.help_id != undefined ?
                    <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                        <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                    </div>
                    : null}
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                {props.editing == false ?
                    <p style={{ ...styles.Text }}>{GetLabel(value)}</p>
                    :
                    <div style={{ ...styles.BlockLeft, width: props.width == undefined ? 200 : props.width }}>
                        <SelectInput multiple require={props.require == undefined ? false : true} width={'100%'} default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={value} items={props.items} field={props.field} label={''} radius={global.radius} func={(txt) => Process(txt)} />
                    </div>
                }
            </div>
        </div>
    );
}



export const FormAlign = (props) => {
    /*
                ZAROVNANIE TEXTU - výber

                <FormAlign value={ } editing={editing} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormButtonResult.bind(this)} />

                ------------------------------------------------------------------
            marginTop   -> vrchný okraj

                */

    const [value, setValue] = useState(props.value);

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;

    useEffect(() => {

        setValue(props.value);

        return () => {
        };

    }, [props.value]);

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRight, width: column1, height: rowHeight, marginRight: 10 }}>
                <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                <div style={{ ...styles.BlockLeft, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ ...styles.BlockLeft, width: 40, height: rowHeight }}>
                            <IconButton onClick={() => func(0)} style={{ ...styles.ButtonIconThemed }}>
                                <FontAwesomeIcon style={{ color: value == 0 ? color.black : color.xxgray }} icon={faAlignLeft} />
                            </IconButton>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: 40, height: rowHeight }}>
                            <IconButton onClick={() => func(1)} style={{ ...styles.ButtonIconThemed }}>
                                <FontAwesomeIcon style={{ color: value == 1 ? color.black : color.xxgray }} icon={faAlignCenter} />
                            </IconButton>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: 40, height: rowHeight }}>
                            <IconButton onClick={() => func(2)} style={{ ...styles.ButtonIconThemed }}>
                                <FontAwesomeIcon style={{ color: value == 2 ? color.black : color.xxgray }} icon={faAlignRight} />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export const FormButton = (props) => {
    /*
                UPRAVA HODNOTY STLACENIM BUTTONU

                <FormButton value={ } editing={editing} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormButtonResult.bind(this)} />

                ------------------------------------------------------------------
            marginTop   -> vrchný okraj

                */

    const [value, setValue] = useState(props.value);

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;

    useEffect(() => {

        setValue(props.value);

        return () => {
        };

    }, [props.value]);

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRight, width: column1, height: rowHeight, marginRight: 10 }}>
                <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                <div style={{ ...styles.BlockLeft, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ ...styles.BlockLeft, width: props.button_delete == true ? column2 - 80 : column2 - 40, height: rowHeight }}>
                            <p style={{ ...styles.Text, textAlign: 'right' }}>{value}</p>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: 40, height: rowHeight }}>
                            <IconButton onClick={() => func(true)} style={{ ...styles.ButtonIcon }}>
                                <FontAwesomeIcon style={{}} icon={props.buton_icon == undefined ? faPen : props.buton_icon} />
                            </IconButton>
                        </div>
                        {props.button_delete == true ?
                            <div style={{ ...styles.BlockLeft, width: 40, height: rowHeight }}>
                                <Tooltip title={lang.delete} PopperProps={{ style: { zIndex: 31001 } }} >
                                    <IconButton onClick={() => func(false)} style={{ ...styles.ButtonIcon }}>
                                        <FontAwesomeIcon style={{}} icon={faXmark} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        </div >
    );
}

export const FormButtons = (props) => {
    /*
                SKUPONA BUTTONOV

                <FormButtons value={0} editing={true} title={''} buttons={[{ id: 0, name: 'Udalosť', icon: faCalendarWeek, width: 100 }, { id: 1, name: 'Úloha', icon: faCheckSquare, width: 100 }]} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => Debug(id)} />

                ------------------------------------------------------------------
                marginTop   -> vrchný okraj

                */

    const [value, setValue] = useState(props.value);

    let { func } = props;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let offset = 6;

    useEffect(() => {

        setValue(props.value);

        return () => {
        };

    }, [props.value]);

    const Press = (button) => {
        if (props.editing == true) {
            func(button.id);
            setValue(button.id);
        }
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop }}>
            <div style={{ ...styles.BlockRight, width: column1, height: rowHeight, marginRight: 10 }}>
                {props.title != '' && props.title != undefined ?
                    <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
                    : null}
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                <div style={{ ...styles.BlockLeft, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <div style={{ ...styles.BlockRow }}>
                        {props.buttons.map((button, index) => (
                            props.editing == true || (props.editing == false && button.id == value) ?
                                <div key={index} onClick={() => Press(button)} style={{ ...styles.Block, width: button.width, marginRight: 5, height: rowHeight - offset, border: value == button.id ? '1px solid' + color.button_selected_dark : '1px solid' + color.light_gray, backgroundColor: value == button.id ? color.button_selected : color.white, borderRadius: props.radius, cursor: 'pointer' }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        {button.icon != undefined ?
                                            <div style={{ ...styles.BlockLeft, width: 16, height: rowHeight - offset }}>
                                                <FontAwesomeIcon style={{ height: 16, color: value == button.id ? color.black : color.middle_gray }} icon={button.icon} />
                                            </div>
                                            : null}
                                        {button.name != undefined ?
                                            <div style={{ ...styles.BlockLeft, width: undefined, height: rowHeight - offset, marginLeft: button.icon != undefined ? 6 : 0 }}>
                                                <p style={{ ...styles.TextTiny, color: value == button.id ? color.black : color.middle_gray }}>{button.name}</p>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                                : null
                        ))}
                    </div>
                </div>
            </div>
        </div >
    );
}

export const FormYesNo = (props) => {
    /*
                <FormYesNo value={ } editing={editing} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />

                ------------------------------------------------------------------
                    marginTop   -> vrchný okraj
                help_id
                */

    const [value, setValue] = useState(props.value);

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let helpWidth = 30;

    useEffect(() => {
        if (props.default == undefined) {
            setValue(props.value == 0 || props.value == false ? 0 : 1);
        } else {
            setValue(props.value);
        }

        return () => {
        };

    }, [props.editing, props.value]);

    const Process = (id) => {
        setValue(id);
        if (props.default == undefined) {
            func(id == 0 ? false : true);
        } else {
            func(id);
        }
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRowRaw, marginRight: 10 }}>
                {props.title != undefined ?
                    <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                        <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
                    </div>
                    : null}
                {props.help_id != undefined ?
                    <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                        <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                    </div>
                    : null}
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                {props.editing == false ?
                    props.default != undefined ?
                        <p style={{ ...styles.Text, textAlign: 'right' }}>{value == 0 ? props.no == undefined ? lang.no : props.no : value == 1 ? props.yes == undefined ? lang.yes : props.yes : props.default}</p>
                        :
                        <p style={{ ...styles.Text, textAlign: 'right' }}>{value == false ? props.no == undefined ? lang.no : props.no : props.yes == undefined ? lang.yes : props.yes}</p>
                    :
                    <div style={{ ...styles.BlockLeft }}>
                        <RadioGroup
                            value={value}
                            onChange={event => Process(event.target.value)}
                            style={{ flexDirection: 'row' }}
                        >
                            {props.default != undefined ?
                                <>
                                    <FormControlLabel value={0} control={<Radio style={{ padding: 0, paddingLeft: 12, paddingRight: 2 }} />} label={<p style={{ ...styles.TextTiny, margin: 0 }}>{props.no == undefined ? lang.no : props.no}</p>} />
                                    <FormControlLabel value={1} control={<Radio style={{ padding: 0, paddingLeft: 12, paddingRight: 2 }} />} label={<p style={{ ...styles.TextTiny }}>{props.yes == undefined ? lang.yes : props.yes}</p>} />
                                    <FormControlLabel value={2} control={<Radio style={{ padding: 0, paddingLeft: 12, paddingRight: 2 }} />} label={<p style={{ ...styles.TextTiny }}>{props.default}</p>} />
                                </>
                                :
                                <>
                                    <FormControlLabel value={1} control={<Radio style={{ padding: 0, paddingLeft: 10, paddingRight: 2 }} />} label={<p style={{ ...styles.TextTiny }}>{props.yes == undefined ? lang.yes : props.yes}</p>} />
                                    <FormControlLabel value={0} control={<Radio style={{ padding: 0, paddingLeft: 10, paddingRight: 2 }} />} label={<p style={{ ...styles.TextTiny }}>{props.no == undefined ? lang.no : props.no}</p>} />
                                </>
                            }
                        </RadioGroup>
                    </div>
                }
            </div>
        </div>
    );
}


export const FormSwitch = (props) => {
    /*
                <FormSwitch value1={true} value2={false} title_1={''} title_2={''} editing={editing} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value1, value2) => {setValue1(value1); setValue2(value2);}} />

                ------------------------------------------------------------------
                marginTop   -> vrchný okraj
                help_id

    */

    const [value1, setValue1] = useState(props.value1);
    const [value2, setValue2] = useState(props.value2);

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let helpWidth = 30;

    useEffect(() => {
        setValue1(props.value1);
        setValue2(props.value2);
        return () => {
        };

    }, [props.editing, props.value1, props.value2]);

    const Process = (id, value) => {
        if (id == 1) {
            setValue1(value);
            func(value, value2);
        }
        if (id == 2) {
            setValue2(value);
            func(value1, value);
        }

    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRowRaw, marginRight: 10 }}>
                {props.title != undefined ?
                    <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                        <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
                    </div>
                    : null}
                {props.help_id != undefined ?
                    <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                        <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                    </div>
                    : null}
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                {props.editing == false ?
                    <p style={{ ...styles.Text, textAlign: 'right' }}><span>{props.title_1}: {value1 == true ? lang.yes : lang.no}</span><span style={{ marginLeft: 20 }}>{props.title_2}: {value2 == true ? lang.yes : lang.no}</span></p>
                    :
                    <div style={{ ...styles.BlockRowRaw }}>
                        <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{props.title_1}</p>}
                            control={<Checkbox checked={value1} size={'small'} onChange={(event) => Process(1, event.target.checked)} />}
                        />
                        <FormControlLabel style={{ marginLeft: 5 }} label={<p style={{ ...styles.TextTiny }}>{props.title_2}</p>}
                            control={<Checkbox checked={value2} size={'small'} onChange={(event) => Process(2, event.target.checked)} />}
                        />
                    </div>
                }
            </div>
        </div>
    );
}

export const FormRadio = (props) => {
    /*
                <FormRadio value={ } items={[id:1, label:'']} editing={editing} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />

                ------------------------------------------------------------------
            marginTop   -> vrchný okraj

                */

    const [value, setValue] = useState(props.value);
    const [label, setLabel] = useState('');

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let helpWidth = 30;

    useEffect(() => {

        setValue(props.value);
        let tmp = props.items.find(x => x.id == props.value);
        if (tmp != undefined) {
            setLabel(tmp.label);
        }

        return () => {
        };

    }, [props.editing, props.value]);

    const Process = (id) => {
        setValue(id);
        func(id);
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? props.hideLine == true ? '' : '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRowRaw, marginRight: 10 }}>
                <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                    <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
                </div>
                {props.help_id != undefined ?
                    <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                        <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                    </div>
                    : null}
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                {props.editing == false ?
                    <p style={{ ...styles.Text, textAlign: 'right' }}>{label}</p>
                    :
                    <div style={{ ...styles.BlockLeft }}>
                        <RadioGroup
                            value={value}
                            onChange={event => Process(event.target.value)}
                            style={{ flexDirection: 'row' }}
                        >
                            {props.items.map((item, index) => (
                                <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={<p style={{ ...styles.TextTiny }}>{item.label}</p>} />
                            ))}
                        </RadioGroup>
                    </div>
                }
            </div>
        </div>
    );
}



export const FormColors = (props) => {
    /*
                SELECTION
                <FormColors value={ } items={[]} name={''} editing={editing} title={'nazov'} width={200} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCurrencyID(id)} />

                MINI
                <FormColors mini value={colorID} items={props.terminColors} name={'light'} editing={true} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setColorID(id)} />

                PALLETTE - výber z palety farieb
                <FormColors palette value={'#FF0000'} editing={true} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setColorID(id)} />

                ------------------------------------------------------------------
                width       -> šírka pola <select>
                marginTop   -> vrchný okraj
                mini        -> mini type
                    */

    const [value, setValue] = useState(props.value);
    const [showColors, setShowColors] = useState(false);

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let colorSize = 18;

    useEffect(() => {

        setValue(props.value);

        return () => {
        };

    }, [props.editing, props.value]);

    const ChangeValue = (id) => {
        setValue(id);
        func(id);
    }

    const ColorsResult = (typ, color) => {
        setShowColors(false);
        if (typ == true) {
            setValue(color);
            func(color);
        }
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRight, width: column1, height: rowHeight, marginRight: 10 }}>
                {props.title == undefined ? null :
                    <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{props.title}:</p>
                }
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                {props.editing == false ?
                    <FontAwesomeIcon style={{ height: 16, color: props.palette == true ? value : props.items[value] }} icon={faCircle} />
                    :
                    props.palette == true ?
                        <div style={{ ...styles.BlockLeft, width: props.width == undefined ? 200 : props.width }}>
                            <IconButton onClick={() => setShowColors(true)} style={{ ...styles.ButtonIconThemed, width: 24, height: 24, backgroundColor: value, border: value == '#00000000' ? color.border : undefined }}>
                                {value == '#00000000' ?
                                    <FontAwesomeIcon style={{ height: 16, color: color.dark_gray }} icon={faXmark} />
                                    : null}
                            </IconButton>
                        </div>
                        :
                        <div style={{ ...styles.BlockLeft, width: props.width == undefined ? 200 : props.width }}>
                            {props.mini == true ?
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {props.items != false ?
                                        props.items.map((item, index) => (
                                            <div key={index} onClick={() => ChangeValue(item.id)} style={{ ...styles.Block, width: colorSize, height: colorSize, backgroundColor: item[props.name], borderRadius: colorSize / 2, marginRight: 5, cursor: 'pointer' }}>
                                                <div key={index} style={{ ...styles.Block, width: colorSize - 6, height: colorSize - 6, backgroundColor: item.id == value ? color.white : undefined, borderRadius: (colorSize - 4) / 2 }}>
                                                </div>
                                            </div>
                                        ))
                                        : null}
                                </div>
                                :
                                <Select
                                    value={value}
                                    onChange={event => ChangeValue(event.target.value)}
                                    size='small'
                                    style={{ textAlign: 'left', fontSize: global.font_xsmall, backgroundColor: '#FFFFFF', height: 38, width: '90%', borderRadius: props.radius == undefined ? 19 : props.radius, color: props.items[value] }}
                                    MenuProps={{
                                        PaperProps: { sx: { ".MuiList-root": { backgroundColor: '#FFFFFF', }, } },
                                        sx: {
                                            zIndex: 30000,
                                        }
                                    }}
                                    sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: color.xxgray, borderLeft: props.require ? props.multiple ? value.length == 0 ? '3px solid #FF0000' : undefined : value == -1 ? '3px solid #FF0000' : undefined : undefined } }}
                                >

                                    {props.items != false ?
                                        props.items.map((item, index) => (
                                            <MenuItem style={{ fontSize: global.font_xsmall, color: props.items[index] }} key={index} value={index}>
                                                <FontAwesomeIcon style={{ height: 16 }} icon={faCircle} />
                                            </MenuItem>
                                        ))
                                        : null}
                                </Select>
                            }
                        </div>
                }
            </div>

            {showColors == true ?
                <DialogColors remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={ColorsResult.bind(this)} />
                : null}
        </div>
    );
}



export const FormNodes = (props) => {
    /*
                    <FormNodes value={categoryID} items={categories} editing={editing} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCategoryID(id)} />

                    ------------------------------------------------------------------
            marginTop   -> vrchný okraj
            deletable   -> zmena ID na 0

                    */

    const [value, setValue] = useState(props.value);
    const [label, setLabel] = useState('');

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const divRef = React.useRef();

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let helpWidth = 30;

    useEffect(() => {

        setValue(props.value);
        if (props.items != undefined && props.items != false) {
            let tmp = props.items.find(x => x.id == props.value);
            if (tmp != undefined) {
                setLabel(tmp.label);
            }
        }

        setAnchorEl(divRef.current);

        return () => {
        };

    }, [props.editing, props.value, props.items]);

    const Process = (id) => {
        setValue(id);
        setOpen(false);

        let tmp = props.items.find(x => x.id == id);
        if (tmp != undefined) {
            setLabel(tmp.label);
        }

        func(id);
    }

    const Delete = () => {
        setLabel('');
        func(0);
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRowRaw, marginRight: 10 }}>
                <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                    <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}</p>
                </div>
                {props.help_id != undefined ?
                    <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                        <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                    </div>
                    : null}
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                {props.editing == false ?
                    <p style={{ ...styles.Text, textAlign: 'right' }}>{label}</p>
                    :
                    <div ref={divRef} style={{ ...styles.Block, height: rowHeight, backgroundColor: color.white, borderRadius: global.radius, border: color.border, borderLeft: props.require == true ? '3px solid #FF0000' : '1px solid ' + color.border }}>
                        <div style={{ ...styles.BlockRow, width: column2, height: rowHeight, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            <div onClick={() => setOpen(!open)} style={{ ...styles.BlockRow, height: rowHeight }}>
                                <div style={{ ...styles.BlockLeft, height: rowHeight }}>
                                    <p style={{ ...styles.TextXSmall, marginLeft: 10, color: label == '' ? color.dark_gray : color.black }}>{label == '' ? props.placeholder == undefined ? lang.choose_ : props.placeholder : label}</p>
                                </div>
                                <div style={{ ...styles.Block, width: 50, height: rowHeight }}>
                                    <FontAwesomeIcon style={{ height: 16, marginBottom: 2, color: color.dark_gray }} icon={faSortDown} />
                                </div>
                            </div>
                            {props.deletable ?
                                <div style={{ ...styles.Block, width: 44, height: rowHeight }}>
                                    <IconButton onClick={() => Delete()} style={{ ...styles.ButtonIconThemed }}>
                                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                    </IconButton>
                                </div>
                                : null}
                        </div>
                        <Popper
                            style={{ width: column2, backgroundColor: color.white, borderRadius: global.radius, zIndex: 31002 }}
                            open={open}
                            anchorEl={anchorEl}
                            placement={'bottom'}
                        >
                            <Paper elevation={3} style={{ ...styles.BlockLeft, paddingTop: 10, paddingBottom: 10 }}>
                                <NodeSelect nodes={props.items} theme={props.theme} func={(id) => Process(id)} />
                            </Paper>
                        </Popper>
                    </div>
                }
            </div>
        </div>
    );
}

export const FormNodeChips = (props) => {
    /*
                    <FormNodeChips value={categoryID} items={categories} editing={editing} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCategoryID(id)} />

                    ------------------------------------------------------------------
            marginTop   -> vrchný okraj
            deletable   -> zmena ID na 0

                    */

    const [values, setValues] = useState(props.value == false ? [] : props.value);
    const [redraw, setRedraw] = useState(false);

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const divRef = React.useRef();

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let helpWidth = 30;

    useEffect(() => {

        setValues(props.value == false ? [] : props.value);
        if (props.items != undefined && props.items != false) {
            let tmp = props.items.find(x => x.id == props.value);
            if (tmp != undefined) {
                //setLabel(tmp.label);
            }
        }

        setAnchorEl(divRef.current);

        return () => {
        };

    }, [props.editing, props.value, props.items]);

    const Process = (id) => {
        var tmp = props.items.find(x => x.id == id);
        var label = id;
        if (label != undefined) {
            label = tmp[props.field];
        }

        let data = {
            id: id,
            label: label
        };

        var array = values;
        array.push(data);
        setValues(array);
        setOpen(false);

        func(array);
    }

    const DeleteItem = (item) => {
        var tmp = values.filter(x => x.id != item.id);
        setValues(tmp);
        func(tmp);

        setRedraw(!redraw);
    }


    return (
        <div style={{ ...styles.Block, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRowRaw }}>
                <div style={{ ...styles.BlockRowRaw, marginRight: 10 }}>
                    <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                        <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
                    </div>
                    {props.help_id != undefined ?
                        <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                            <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                        </div>
                        : null}
                </div>
                <div style={{ ...styles.BlockLeft, width: column2, minHeight: rowHeight }}>
                    {props.editing == false ?
                        <div style={{ ...styles.BlockRow, width: column2, flexWrap: 'wrap', alignItems: 'flex-start' }}>
                            {values != false && values != undefined ? values.map((item, index) => (
                                <Chip key={index} label={item.label} style={{ margin: 2 }} variant="outlined" />
                            ))
                                : null}
                        </div>
                        :
                        <div ref={divRef} style={{ ...styles.Block, minHeight: rowHeight, backgroundColor: color.white, borderRadius: global.radius, border: color.border }}>
                            <div style={{ ...styles.BlockRow, width: column2, height: rowHeight, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                <div onClick={() => setOpen(!open)} style={{ ...styles.BlockRow, height: rowHeight }}>
                                    <div style={{ ...styles.BlockLeft, height: rowHeight }}>
                                        <p style={{ ...styles.TextXSmall, marginLeft: 10, color: color.dark_gray }}>{lang.choose_}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 50, height: rowHeight }}>
                                        <FontAwesomeIcon style={{ height: 16, marginBottom: 2, color: color.dark_gray }} icon={faSortDown} />
                                    </div>
                                </div>
                            </div>

                            <Popper
                                style={{ width: column2, backgroundColor: color.white, borderRadius: global.radius, zIndex: 31002 }}
                                open={open}
                                anchorEl={anchorEl}
                                placement={'bottom'}
                            >
                                <Paper elevation={3} style={{ ...styles.BlockLeft, paddingTop: 10, paddingBottom: 10 }}>
                                    <NodeSelect nodes={props.items} theme={props.theme} func={(id) => Process(id)} />
                                </Paper>
                            </Popper>
                        </div>
                    }
                </div>
            </div>

            {props.editing == true ?
                <div style={{ ...styles.BlockRow, width: column1 + column2 }}>
                    <div style={{ ...styles.Block, width: column1 }}></div>
                    <div style={{ ...styles.BlockRow, width: column2, flexWrap: 'wrap', alignItems: 'flex-start' }}>
                        {values != false && values != undefined ? values.map((item, index) => (
                            <Chip key={index} label={item.label} style={{ margin: 2 }} variant="outlined" onDelete={() => DeleteItem(item)} />
                        ))
                            : null}
                    </div>
                </div>
                : null}

        </div>
    );
}

export const FormSelectChips = (props) => {
    /*
                    <FormSelectChips value={categoryID} items={categories} editing={editing} title={'nazov'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCategoryID(id)} />

                    ------------------------------------------------------------------
            marginTop   -> vrchný okraj
            deletable   -> zmena ID na 0

                    */

    const [values, setValues] = useState(props.value == false ? [] : props.value);
    const [redraw, setRedraw] = useState(false);

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const divRef = React.useRef();

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let helpWidth = 30;

    useEffect(() => {

        setValues(props.value == false ? [] : props.value);
        if (props.items != undefined && props.items != false) {
            let tmp = props.items.find(x => x.id == props.value);
            if (tmp != undefined) {
                //setLabel(tmp.label);
            }
        }

        setAnchorEl(divRef.current);

        return () => {
        };

    }, [props.editing, props.value, props.items]);

    const Process = (id) => {
        var check = values.find(x => x.id == id);
        if (check == undefined) {
            var tmp = props.items.find(x => x.id == id);
            var label = id;
            if (label != undefined) {
                label = tmp[props.field];
            }

            var data = {
                id: id,
            };

            data[props.field] = label

            var array = values;
            array.push(data);
            setValues(array);
            setRedraw(!redraw);

            func(array);
        }
    }

    const DeleteItem = (item) => {
        var tmp = values.filter(x => x.id != item.id);
        setValues(tmp);
        func(tmp);

        setRedraw(!redraw);
    }


    return (
        <div style={{ ...styles.Block, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRowRaw }}>
                <div style={{ ...styles.BlockRowRaw, marginRight: 10 }}>
                    <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                        <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
                    </div>
                    {props.help_id != undefined ?
                        <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                            <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                        </div>
                        : null}
                </div>
                <div style={{ ...styles.BlockLeft, width: column2, minHeight: rowHeight }}>
                    {props.editing == false ?
                        <div style={{ ...styles.BlockRow, width: column2, flexWrap: 'wrap', alignItems: 'flex-start' }}>
                            {values != false && values != undefined ? values.map((item, index) => (
                                <Chip key={index} size='small' label={item[props.field]} style={{ margin: 2 }} variant="outlined" />
                            ))
                                : null}
                        </div>
                        :
                        <div ref={divRef} style={{ ...styles.Block, minHeight: rowHeight, backgroundColor: color.white, borderRadius: global.radius, border: color.border }}>
                            <SelectInput require={props.require == undefined ? false : true} width={props.width} default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={0} items={props.items} field={props.field} label={''} radius={global.radius} func={(txt) => Process(txt)} />
                        </div>
                    }
                </div>
            </div>

            {props.editing == true ?
                <div style={{ ...styles.BlockRow, width: column1 + column2 }}>
                    <div style={{ ...styles.Block, width: column1 }}></div>
                    <div style={{ ...styles.BlockRow, width: column2, flexWrap: 'wrap', alignItems: 'flex-start' }}>
                        {values != false && values != undefined ? values.map((item, index) => (
                            <Chip key={index} size='small' label={item[props.field]} style={{ margin: 2 }} variant="outlined" onDelete={() => DeleteItem(item)} />
                        ))
                            : null}
                    </div>
                </div>
                : null}

        </div>
    );
}


export const FormSelect = (props) => {
    /*
            STANDART SELECTION:
            <FormSelect value={} items={items} field={'currency'} editing={editing} title={'nazov'} width={200} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id)=>setCurrencyID(id)} />
 
            ČÍSELNÍK
            <FormSelect require value={reasonID} items={false} field={'name'} codebook={global.codebook.reasons} editing={editing} title={lang.stock_adjustment_reason} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => ChangeReason(id)} />
 
            ------------------------------------------------------------------
            const items = [{ id: 0, label: lang.customize_name_1 }, { id: 1, label: lang.customize_name_2 }];
            width       -> šírka pola <select>
            marginTop   -> vrchný okraj
            codebook    -> ID číselníka global.codebook .....
            hideButton  -> skryť tlačítko v codebook
            permited    -> povolenie vytvrata ciselnik            
    */

    let permissionEnabled = props.permited == true ? true : props.user != undefined ? CheckPermission(props.user.super_admin, props.permissions['code_book']).edit == true ? true : false : false;
    const [value, setValue] = useState(props.value);

    // CISELNIKY
    const [bookItems, setBookItems] = useState(false);
    const [bookItemsFiltered, setBookItemsFiltered] = useState(false);
    const [showCodeBook, setShowCodeBook] = useState(false);
    const [bookEdit, setBookEdit] = useState(false);
    const [text, setText] = useState('');
    const [itemSelected, setItemSelected] = useState(false);
    const [itemExists, setItemExists] = useState(false);
    const [userColor, setUserColor] = useState('#00000000');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 700 ? 700 : height;


    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let rowItemHeight = 52;
    let titleHeight = 70;
    let helpWidth = 30;

    const inputRef = useRef(null);

    useEffect(() => {

        setValue(props.value);

        if (props.codebook != undefined) {
            if (bookItems == false) {
                db_get(false);
            }
        }

        return () => {
        };

    }, [props.editing, props.value, props.items, props.codebook]);

    const db_get = async (filter) => {
        try {
            const response = await fetch(
                global.db_url + 'codebooks', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    book_id: props.codebook,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setBookItems(json.items);

                if (json.items != false) {
                    var tmp = json.items.filter(x => x.enabled == true);
                    if (tmp.length == 0) {
                        tmp = false;
                    }
                    setBookItemsFiltered(tmp);
                } else {
                    setBookItemsFiltered(json.items);
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    const db_update = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'codebook_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setBookEdit(false);
                db_get(false);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const Process = (id) => {
        setValue(id);
        func(id, false);
    }

    const GetLabel = (id) => {
        var result = '';
        if (props.codebook == undefined) {
            if (props.items != false) {
                var tmp = props.items.find(x => parseInt(x.id) == parseInt(id));

                if (tmp != undefined) {
                    result = tmp[props.field];
                }
            }
        } else {
            if (bookItems != false) {
                var tmp = bookItems.find(x => parseInt(x.id) == parseInt(id));

                if (tmp != undefined) {
                    result = tmp[props.field];
                }
            }
        }
        return result;
    }

    const EditCodeBook = () => {
        setText('');
        setBookEdit(false);
        setShowCodeBook(true);

    }

    const Save = () => {
        setItemExists(false);
        var txt_ = text;
        let name = txt_.trim();
        if (txt_.trim() != '') {

            // KONTROLA, CI SA UZ ZAZNAM NENACHADZA V DB
            let tmp = bookItems.find(x => x.name == name);
            if (tmp == undefined || (itemSelected != false && tmp.id == itemSelected.id)) {
                let data = {
                    id: itemSelected == false ? 0 : itemSelected.id,
                    organization_id: props.organization.id,
                    book_id: props.codebook,
                    enabled: true,
                    name: name,
                    color: props.colors == true ? userColor : '#00000000',
                    priority: 100
                }

                db_update(data);
            } else {
                // ZAZNAM UZ EXISTUJE !!!
                setItemExists(true);
            }
        }
    }

    const NewItem = () => {
        setText('');
        setItemSelected(false);
        setItemExists(false);
        setBookEdit(true);

        setTimeout(() => {
            inputRef.current.focus();
        }, 300);
    }

    const EditItem = (item) => {
        setItemSelected(item);
        setText(item.name);
        setItemExists(false);
        setBookEdit(true);
        setUserColor(item.color);
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);

    }

    const CloseDialog = () => {
        setShowCodeBook(false);
    }

    const ChangeEnabled = (item, value) => {
        let data = {
            id: item.id,
            enabled: value
        }

        db_update(data);
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? color.form_line : '' }}>
            {column1 != undefined ?
                <div style={{ ...styles.BlockRowRaw, marginRight: 10 }}>
                    <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                        <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
                    </div>
                    {props.help_id != undefined ?
                        <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                            <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                        </div>
                        : null}
                </div>
                : null}
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                {props.editing == false ?
                    <p style={{ ...styles.Text }}>{GetLabel(value)}</p>
                    :
                    <div style={{ ...styles.BlockRow, width: props.width == undefined ? 200 : props.column2 }}>
                        <div style={{ ...styles.Block }}>
                            <SelectInput all={props.all} zero={props.zero} require={props.require == undefined ? false : true} width={props.width} default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={value} items={props.codebook == undefined ? props.items : bookItemsFiltered} field={props.field} label={''} radius={global.radius} func={(txt) => Process(txt)} />
                        </div>
                        {props.codebook != undefined && permissionEnabled == true && props.hideButton != true ?
                            <div style={{ ...styles.BlockRight, width: 45, height: rowHeight }}>
                                <IconButton onClick={() => EditCodeBook()} style={{ ...styles.ButtonIcon, width: 36, height: 36, borderRadius: 6 }}>
                                    <FontAwesomeIcon style={{ width: 12, color: color.black }} icon={props.codebook_icon == undefined ? faPen : props.codebook_icon} />
                                </IconButton>
                            </div>
                            : null}
                        {props.button != undefined ?
                            <div style={{ ...styles.BlockRight, width: 45, height: rowHeight, marginLeft: props.button_margin == undefined ? 0 : props.button_margin }}>
                                <IconButton onClick={() => func(0, true)} style={{ ...styles.ButtonIcon, marginLeft: 10 }}>
                                    <FontAwesomeIcon style={{ width: 16, color: color.black }} icon={props.button_icon == undefined ? faPen : props.button_icon} />
                                </IconButton>
                            </div>
                            : null}
                    </div>
                }
            </div>

            <Backdrop open={showCodeBook} style={{ zIndex: 500, paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: '#000000AA' }}>
                <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: color.theme_dialog_background, borderRadius: props.radius }}>
                    <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                        <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                            <Icon theme={props.theme} icon={faBook} />
                        </div>
                        <div style={{ ...styles.Block, width: dialogWidth - 60 - titleHeight, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.codebook}</p>
                        </div>
                        <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                            <IconButton onClick={() => CloseDialog(false)} style={{ ...styles.ButtonClose }}>
                                <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 70, backgroundColor: color.back }}>
                        <div style={{ ...styles.BlockRow, width: '96%' }}>
                            <div style={{ ...styles.BlockLeft, height: titleHeight }}>
                                {bookEdit == true ?
                                    <TextInput
                                        redraw
                                        inputRef={inputRef}
                                        enabled={true}
                                        lang={lang}
                                        value={text}
                                        radius={radius}
                                        //enter
                                        //placeholder={lang.code_book_placeholder}
                                        func={(txt, button) => { setText(txt) }}
                                    />
                                    : null}
                                {itemExists == true ?
                                    <p style={{ ...styles.TextXTiny, color: color.dark_red, marginLeft: 5 }}>{lang.codebook_item_exists}</p>
                                    : null}
                            </div>
                            {bookEdit == true && props.colors == true ?
                                <div style={{ ...styles.BlockLeft, width: 40, height: titleHeight }}>
                                    <FormColors palette value={userColor} marginTop={0} editing={true} column2={40} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} func={(id) => setUserColor(id)} />
                                </div>
                                : null}
                            <div style={{ ...styles.BlockRight, width: 160, height: titleHeight }}>
                                {bookEdit == false ?
                                    <Button onClick={() => NewItem()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add }}>{lang.codebook_add}</Button>
                                    :
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockRight, width: '50%', height: titleHeight }}>
                                            <IconButton onClick={() => Save()} style={{ ...styles.ButtonIcon }}>
                                                <FontAwesomeIcon style={{ height: 18, color: color.dark_green }} icon={faCheck} />
                                            </IconButton>
                                        </div>
                                        <div style={{ ...styles.Block, width: '50%', height: titleHeight }}>
                                            <IconButton onClick={() => setBookEdit(false)} style={{ ...styles.ButtonIcon }}>
                                                <FontAwesomeIcon style={{ height: 18, color: color.dark_red }} icon={faXmark} />
                                            </IconButton>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - global.dialog_bar_height - 70 - titleHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, paddingBottom: global.list_padding }}>
                                {bookItems != false ? bookItems.map((item, index) => (
                                    <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '98%', backgroundColor: item.enabled == true ? color.white : color.disabled, height: rowItemHeight, marginTop: 5, marginBottom: 5, borderRadius: global.radius, borderLeft: item.enabled == false ? color.disabled_border : '' }}>
                                        <div style={{ ...styles.BlockRow, width: '94%' }}>
                                            {props.colors == true ?
                                                <div style={{ ...styles.BlockLeft, width: 40, height: rowItemHeight }}>
                                                    <div style={{ ...styles.Block, width: 25, height: 24, borderRadius: 12, backgroundColor: item.color }}></div>
                                                </div>
                                                : null}
                                            <div style={{ ...styles.BlockLeft, width: '78%', height: rowItemHeight }}>
                                                <p style={{ ...styles.TextXSmall }}>{item.name}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 80, height: rowItemHeight }}>
                                                <SwitchEnable enabled={item.enabled == false ? false : true} theme={props.theme} func={(value) => ChangeEnabled(item, value)} />
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 50, height: rowItemHeight }}>
                                                <IconButton onClick={() => EditItem(item)} style={{ ...styles.ButtonIcon }}>
                                                    <FontAwesomeIcon style={{ width: 14 }} icon={faPen} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 70 }}>
                                        <EmptyList row small lang={lang} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: 70, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        <Button onClick={() => CloseDialog()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.close}</Button>
                    </div>
                </Paper>
            </Backdrop >
        </div >
    );
}

export const FormAutoComplete = (props) => {

    /*
 
        <FormAutoComplete require value={parentID} items={categories} field={'label'} editing={editing} title={lang.product_category_parent} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setParentID(id)} />
 
        no_default  -> nepridávať default value
 
    */


    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let helpWidth = 30;

    let defaultValue = { id: 0, name: '---', label: '---', path: '---', enabled: true };


    const [value, setValue] = useState(null);
    const [text, setText] = useState('');
    const [items, setItems] = useState(false);

    const hiddenInputRef = useRef(null);

    useEffect(() => {
        // VLOZENIE DEFAULT ITEM => ID:0
        var tmp = props.items;
        if (tmp == false) {
            tmp = [];
        }

        if (props.no_default == undefined) {
            let test = tmp.find(x => x.id == 0);
            if (test == undefined) {
                tmp.unshift(defaultValue);
            }
        }
        setItems(tmp);

        // OZNACENIE POLOZKY NA ZAKLADE ID
        if (props.value == 0) {
            setValue(defaultValue);
        } else {
            var array = tmp.find(x => x.id == props.value);
            if (array != undefined) {
                setValue(array);
                setText(array[props.field]);
            } else {
                setValue(null);
            }
        }

        return () => {
        };

    }, [props.items, props.value]);

    const ChooseValue = (item) => {
        setValue(item);
        hiddenInputRef.current.focus();
        func(item.id);
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? color.form_line : '' }}>
            {props.column1 != undefined ?
                <div style={{ ...styles.BlockRowRaw, marginRight: 10 }}>
                    <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                        <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
                    </div>
                    {props.help_id != undefined ?
                        <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                            <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                        </div>
                        : null}
                </div>
                : null}
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                {props.editing == false ?
                    <p style={{ ...styles.Text }}>{text}</p>
                    :
                    <div style={{ ...styles.BlockLeft }}>
                        <div style={{ ...styles.BlockLeft, width: props.width == undefined ? '100%' : props.width }}>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                options={items == false ? [] : items}
                                getOptionLabel={(option) => option[props.field]}
                                style={{ width: '100%', backgroundColor: color.white, borderRadius: radius }}
                                size='small'
                                onChange={(event, newValue) => { ChooseValue(newValue); }}
                                value={value}
                                renderOption={(sub_props, option) => {
                                    return (
                                        <li {...sub_props} key={option.id}>
                                            {option[props.field]}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill                                        
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            style: { height: 36, backgroundColor: color.white, borderRadius: radius, borderLeft: props.require == true ? '3px solid #FF0000' : undefined }
                                        }}
                                    />
                                )}
                            />
                            <input
                                ref={hiddenInputRef}
                                type="text"
                                style={{ position: 'absolute', left: '-9999px' }}
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                }
            </div>
        </div>

    )
}


export const FormProduct = (props) => {

    /*
  
         <FormProduct excluded={[]} editing={editing} title={lang.product_category_parent} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => Debug(txt)} />
  
         no_default  -> nepridávať default value
  
     */


    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let helpWidth = 30;
    let lineHeight = 30;

    let defaultValue = { id: 0, name: '---', label: '---', path: '---', enabled: true };

    const [isBusy, setBusy] = useState(false);
    const [value, setValue] = useState(null);
    const [text, setText] = useState('');
    const [items, setItems] = useState(false);

    useEffect(() => {
        setValue(props.value);

        return () => {
        };

    }, [props.value]);

    const db_get = async (data) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'product_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    organization_id: props.organization.id,
                    excluded: props.excluded,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setItems(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Search = (txt) => {

        setText(txt);
        if (txt.length > 2) {
            let data = {
                country_id: props.locale.id,
                name: txt
            }
            if (props.price_list != undefined) {
                // len produkty v cenníku
                data.price_list = props.price_list;
            }

            Debug(data);
            db_get(data);
        }
    }

    const ChooseValue = (item) => {
        if (item != null) {
            setItems([]);
            func(item);
        }
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? color.form_line : '' }}>
            <div style={{ ...styles.BlockRowRaw, marginRight: props.title != '' ? 10 : 0 }}>
                <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                    {props.title != '' ?
                        <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
                        : null}
                </div>
                {props.help_id != undefined ?
                    <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                        <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                    </div>
                    : null}
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                {props.editing == false ?
                    <p style={{ ...styles.Text }}>{text}</p>
                    :
                    <div style={{ ...styles.BlockLeft }}>
                        <div style={{ ...styles.BlockLeft, width: props.width == undefined ? '100%' : props.width }}>
                            <Autocomplete
                                disablePortal
                                //disableClearable
                                clearOnBlur
                                freeSolo
                                //loading={isBusy}
                                options={items == false ? [] : items}
                                getOptionLabel={(option) => option.keywords}
                                style={{ width: '100%', backgroundColor: color.white, borderRadius: radius }}
                                size='small'
                                onChange={(event, newValue) => { ChooseValue(newValue); }}
                                //value={value}
                                renderOption={(sub_props, option) => {
                                    return (
                                        <li {...sub_props} key={option.id} style={{ marginBottom: 5 }}>
                                            <div style={{ ...styles.BlockLeft, height: lineHeight }}>
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div style={{ ...styles.Block, width: lineHeight }}>
                                                        <Avatar picture image={option.image} size={lineHeight - 2} theme={props.theme} />
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        <div style={{ maxWidth: '90%', justifyContent: 'center', height: lineHeight, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                            <span style={{ ...styles.TextTiny, textAlign: 'left', color: color.dark_gray, marginLeft: 10 }}>{option.code}<span style={{ marginLeft: 10, color: color.black }}>{option.name}</span> {option.parent_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onInput={e => Search(e.target.value)}
                                        placeholder={props.placeholder == undefined ? undefined : props.placeholder}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill                                        
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            style: { fontSize: global.font_xsmall, height: 36, backgroundColor: color.white, borderRadius: radius, borderLeft: props.require == true ? '3px solid #FF0000' : undefined }
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>

    )
}

export const FormPermissions = (props) => {
    /*
                    <FormPermissions value={ } items={[]} field={'currency'} editing={editing} title={'nazov'} width={200} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCurrencyID(id)} />

                    ------------------------------------------------------------------
            width       -> šírka pola <select>
            marginTop   -> vrchný okraj
                        */

    const [value, setValue] = useState(props.value);

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let helpWidth = 30;

    useEffect(() => {

        setValue(props.value);

        return () => {
        };

    }, [props.editing, props.value]);

    const Process = (id) => {
        setValue(id);
        func(id);
    }

    const GetLabel = (id) => {
        var result = '';
        if (props.items != false) {
            props.items.forEach(item => {
                if (value.includes(item.id)) {
                    if (result != '') {
                        result += ', ';
                    }
                    result = result + item[props.field];
                }
            });
        }
        if (result == '') {
            result = lang.permission_none;
        }
        return result;
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? color.form_line : '' }}>
            <div style={{ ...styles.BlockRowRaw, marginRight: 10 }}>
                <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                    <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
                </div>
                {props.help_id != undefined ?
                    <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                        <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                    </div>
                    : null}
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                {props.editing == false ?
                    <p style={{ ...styles.Text }}>{GetLabel(value)}</p>
                    :
                    <div style={{ ...styles.BlockLeft, width: props.width == undefined ? 200 : props.width }}>
                        <SelectInput multiple require={props.require == undefined ? false : true} width={'100%'} default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={value} items={props.items} field={props.field} label={''} radius={global.radius} func={(txt) => Process(txt)} />
                    </div>
                }
            </div>
        </div>
    );
}


export const FormSelection = (props) => {
    /*
                        <FormSelection value={ } items={[]} field={'currency'} editing={editing} title={'nazov'} width={200} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCurrencyID(id)} />

                        ------------------------------------------------------------------
            width       -> šírka pola <select>
            marginTop   -> vrchný okraj
                            */

    const [value, setValue] = useState(props.value);

    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;
    let helpWidth = 30;

    useEffect(() => {

        setValue(props.value);

        return () => {
        };

    }, [props.editing, props.value]);

    const Process = (id) => {
        setValue(id);
        func(id);
    }

    const GetLabel = (id) => {
        var result = '';
        if (props.items != false) {
            props.items.forEach(item => {
                if (value.includes(item.id)) {
                    if (result != '') {
                        result += ', ';
                    }
                    result = result + item[props.field];
                }
            });
        }
        if (result == '') {
            result = lang.permission_none;
        }
        return result;
    }

    return (
        <div style={{ ...styles.BlockRowRaw, marginTop: props.marginTop == undefined ? 5 : props.marginTop, borderBottom: props.editing == false ? '1px dotted ' + color.form_line : '' }}>
            <div style={{ ...styles.BlockRowRaw, marginRight: 10 }}>
                <div style={{ ...styles.BlockRight, width: props.help_id != undefined ? column1 - helpWidth : column1, height: rowHeight }}>
                    <p style={{ ...styles.TextXSmall, textAlign: 'right', color: color.dark_gray }}>{props.title}:</p>
                </div>
                {props.help_id != undefined ?
                    <div style={{ ...styles.BlockRight, width: helpWidth, height: rowHeight }}>
                        <QuickHelp id={props.help_id} lang={lang} width={helpWidth} height={34} theme={props.theme} locale={props.locale} />
                    </div>
                    : null}
            </div>
            <div style={{ ...styles.BlockLeft, width: column2, height: rowHeight }}>
                {props.editing == false ?
                    <p style={{ ...styles.Text }}>{GetLabel(value)}</p>
                    :
                    <div style={{ ...styles.BlockLeft, width: props.width == undefined ? 200 : props.width }}>
                        <SelectInput multiple require={props.require == undefined ? false : true} width={'100%'} default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={value} items={props.items} field={props.field} label={''} radius={global.radius} func={(txt) => Process(txt)} />
                    </div>
                }
            </div>
        </div>
    );
}



export const AutoCompleteInput = (props) => {

    /*
 
        <AutoCompleteInput value={categoryID} items={categories} field={'label'} default={lang.product_category} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => ChangeCategory(txt)} />
 
 
    */


    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let rowHeight = props.height == undefined ? 36 : props.height;
    let radius = props.radius == undefined ? global.radius : props.radius;

    let defaultValue = { id: 0, name: props.default, label: props.default, enabled: true };


    const [value, setValue] = useState(null);
    const [text, setText] = useState('');
    const [items, setItems] = useState(false);

    useEffect(() => {
        // VLOZENIE DEFAULT ITEM => ID:0
        var tmp = props.items;
        if (tmp == false) {
            tmp = [];
        }
        let test = tmp.find(x => x.id == 0);
        if (test == undefined) {
            tmp.unshift(defaultValue);
        }
        setItems(tmp);

        // OZNACENIE POLOZKY NA ZAKLADE ID
        if (props.value == 0) {
            setValue(defaultValue);
        } else {
            var array = tmp.find(x => x.id == props.value);
            if (array != undefined) {
                setValue(array);
                setText(array[props.field]);
            } else {
                setValue(null);
            }
        }

        return () => {
        };

    }, [props.items, props.value]);

    const ChooseValue = (item) => {
        setValue(item);
        func(item.id);
    }

    return (
        <div style={{ ...styles.BlockLeft, width: props.width == undefined ? '100%' : props.width }}>
            <Autocomplete
                disablePortal
                disableClearable
                options={items == false ? [] : items}
                getOptionLabel={(option) => option[props.field]}
                style={{ width: '100%', backgroundColor: color.white, borderRadius: radius }}
                size='small'
                onChange={(event, newValue) => { ChooseValue(newValue); }}
                value={value}
                renderOption={(sub_props, option) => {
                    return (
                        <li {...sub_props} key={option.id}>
                            {option[props.field]}
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill                                        
                        }}
                        InputProps={{
                            ...params.InputProps,
                            style: { height: 36, backgroundColor: color.white, borderRadius: radius }
                        }}
                    />
                )}
            />
        </div>
    )
}


export const DialogTabs = (props) => {
    /*
                            <DialogTabs value={0} items={[{ id: 0, enabled: true, label: 'Menu 1' }, { id: 1, enabled: true, label: 'Menu 2' }]} width={150} theme={props.theme} func={(id) => ChangeTab(id)} />

                            ------------------------------------------------------------------
            width           -> šírka jedneho TABU
            disabled        -> disabled all tabs
            marginTop       -> vrchný okraj
            marginBottom    -> spodný okraj
            backgroundColor -> pozadie celého tabu
            center          -> centrovanie na stred
            items           -> array - jednotlivé <TAB>
                                items={[{ id: 0, label: 'Menu 1' }, { id: 1, label: 'Menu 2' }]}
                                */

    const [value, setValue] = useState(props.value);

    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        setValue(props.value);

        return () => {
        };

    }, [props.value]);

    const Process = (id) => {
        setValue(id);
        func(id);
    }

    return (
        <div style={{
            ...props.center == true ? styles.BlockCenter : styles.BlockLeft,
            backgroundColor: props.backgroundColor == undefined ? undefined : props.backgroundColor,
            height: props.height == undefined ? undefined : props.height - 1,
            marginTop: props.marginTop == undefined ? 0 : props.marginTop,
            marginBottom: props.marginBottom == undefined ? 0 : props.marginBottom,
            justifyContent: 'flex-end',
            borderBottom: '1px solid ' + color.xxgray
        }}>
            <Tabs
                value={value}
                onChange={(event, newValue) => Process(newValue)}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: color.blue,
                    }
                }}
                sx={{ minHeight: 40, height: 40 }}
            >
                {props.items != false ? props.items.map((item, index) => (
                    item.enabled == true ?
                        <Tab
                            key={item.id}
                            disabled={props.disabled == true ? true : false}
                            value={item.id}
                            style={{ maxHeight: 50, width: props.width == undefined ? undefined : props.width }}
                            label={
                                <div style={{ ...styles.Block, height: 30, width: '100%' }}>
                                    <p style={{ ...styles.TextSmall, textTransform: 'none', letterSpacing: 0, fontWeight: value == item.id ? '600' : '400', color: props.disabled == true ? color.xxgray : value == item.id ? color.black : color.dark_gray }}>{item.label}</p>
                                </div>
                            } />
                        : null
                )) : null}

            </Tabs >
        </div >
    );
}


export const DialogLabel = (props) => {
    /*
            POPIS OKNA - Názov a popis
 
            <DialogLabel label={} sub_label={} theme={props.theme} />
 
            ------------------------------------------------------------------
            label       - nadpis
            sub_label   - poznámka
            marginLeft  - default 0
 
    */

    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, [props.value]);


    return (
        <div style={{ ...styles.BlockLeft, paddingTop: 25, paddingBottom: 20 }}>
            <p style={{ ...styles.TextNormal, fontWeight: '600', marginLeft: props.marginLeft == undefined ? 0 : props.marginLeft }}>{props.label}</p>
            <p style={{ ...styles.TextTiny, marginTop: 4, marginLeft: props.marginLeft == undefined ? 0 : props.marginLeft }}>{props.sub_label}</p>
        </div>
    );
}


export const DialogDates = (props) => {
    /*
            Informácie o položke
            vytvorené, upravené a upravil
 
            <DialogDates created={} updated={} updated_by={} lang={props.lang} theme={props.theme} />
 
            ------------------------------------------------------------------
            created     - záznam bol vytvorený - dátum
            updated     - záznam bol upravený - dátum
            updated_by  - kým bol záznam bol upravený - užívateľ (meno a priezvisko)
 
    */

    let color = global.themes[props.theme];
    let lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, [props.value]);


    return (
        <div style={{ ...styles.BlockLeft }}>
            <div style={{ ...styles.BlockRow }}>
                <div style={{ ...styles.BlockLeft, width: 100 }}>
                    <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{lang.created}:</p>
                </div>
                <div style={{ ...styles.BlockLeft, width: 300 }}>
                    <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{GetDatumTime2(props.created)}</p>
                </div>
            </div>
            <div style={{ ...styles.BlockRow, marginTop: 2 }}>
                <div style={{ ...styles.BlockLeft, width: 100 }}>
                    <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{lang.updated}:</p>
                </div>
                <div style={{ ...styles.BlockLeft, width: 300 }}>
                    <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{GetDatumTime2(props.updated)}</p>
                </div>
            </div>
            {props.updated_by != '' ?
                <div style={{ ...styles.BlockRow, marginTop: 2 }}>
                    <div style={{ ...styles.BlockLeft, width: 100 }}>
                        <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{lang.updated_by}:</p>
                    </div>
                    <div style={{ ...styles.BlockLeft, width: 300 }}>
                        <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{props.updated_by}</p>
                    </div>
                </div>
                : null}
        </div>
    );
}


export const ButtonNew = (props) => {
    /*

                                <ButtonNew icon={faPlusCircle} label={lang.user_new} menuItems={[{ id: 0, label: 'New' }]} theme={props.theme} func={ButtonResult.bind()} />


                                PARAMS:
                                ------------------------------------------------------------------------------------
                                icon        - ikona pred textom (false / faPlus)
                                label       - text na tlačítku
                                menuItems   - hodnoty v menu [{id: 0, label: 'New' }, .... ]
                                width       - šírka button

                                RESULT:
                                ------------------------------------------------------------------------------------
                const ButtonResult = (typ, index) => {
                    if (typ == 0) {
                                    // BUTTON CLICK
                                    Debug('Click Button');
                    }
                                if (typ == 1) {
                        // MENU CLICK
                        switch (index) {
                            case 0:
                                Debug('Click - menu 1')
                                break;

                                case 1:
                                Debug('Click - menu 2')
                                break;

                        }
                    }
                }

                                */


    const [menuItems, setMenuItems] = useState(props.menuItems == false || props.menuItems == undefined ? false : props.menuItems);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    let { func } = props;
    let color = global.themes[props.theme];
    const divRef = React.useRef();

    useEffect(() => {

        setAnchorEl(divRef.current);

        return () => {
        };

    }, []);

    const MenuClick = (event) => {
        func(1, event)
    }

    const handleClose = () => {
        setOpen(false);
    }

    const Click = () => {
        func(0, 0);
    }

    return (
        <div style={{ ...styles.BlockRowRaw }}>
            <Button onClick={() => Click()} style={{ ...styles.ButtonThemed, width: props.width == undefined ? 170 : props.width, borderRadius: menuItems != false ? '17px 0px 0px 17px' : '17px', backgroundColor: color.button_add }}>
                {props.icon != undefined && props.icon != false ?
                    <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={props.icon} />
                    : null}
                {props.label}
            </Button>
            {menuItems != false ?
                <IconButton ref={divRef} onClick={() => setOpen(true)} style={{ ...styles.ButtonThemed, borderRadius: '0px 17px 17px 0px', width: 34, backgroundColor: color.button_add, borderLeft: '1px solid ' + color.white }}>
                    <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faAngleDown} />
                </IconButton>
                : null}
            {menuItems != false ?
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={(event) => MenuClick(event.target.value)}
                >
                    {menuItems.map((item, index) => (
                        <MenuItem key={item.id} value={item.id} onClick={handleClose}>{item.label}</MenuItem>
                    ))}
                </Menu>
                : null}
        </div>
    );
}


export const MenuButton = (props) => {
    /*

                                const itemsExportMenu = [
                                {id: 1, checked: false, enabled: true, label: lang.export_selected },
                                {id: 2, checked: false, enabled: true, label: lang.export_all },
                                {id: 30, divider: true },
                                {id: 5, checked: LoadBool('customers-mode', true), enabled: true, label: lang.preview_mode },
                                {id: 32, divider: true },
                                {id: 3, checked: false, enabled: true, icon: faPlus, label: lang.customers_list_group_new },
                                {id: 31, divider: true },
                                {id: 4, checked: false, enabled: true, icon: faRotateRight, label: lang.refresh }
                                ];

                                <MenuButton id={0} icon={faPlusCircle} label={lang.user_new} menuItems={[{ id: 0, label: 'New' }]} theme={props.theme} func={MenuResult.bind()} />


                                PARAMS:
                                ------------------------------------------------------------------------------------
                                id                  - ID tlačítka (používasa pri vrátení hodnoty - identifikácia, ktory menu button bol stlačený ak ich je použitých viac na stránke)
                                menuItems           - hodnoty v menu [{id: 0, label: 'New' }, .... ]
                                sortMenu            - button ako sort buttton
                                icon                - ikona

                                RESULT:
                                ------------------------------------------------------------------------------------

        const MenuResult = (id, index, item) => {
            switch (index) {
                case 1:
                                Debug('Click - menu 1')
                                break;

                                case 2:
                                Debug('Click - menu 2')
                                break;
            }
        }

                                */


    const [menuItems, setMenuItems] = useState(props.menuItems == false || props.menuItems == undefined ? false : props.menuItems);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    let { func } = props;
    let color = global.themes[props.theme];
    const divRef = React.useRef();

    useEffect(() => {

        setMenuItems(props.menuItems);
        setAnchorEl(divRef.current);

        return () => {
        };

    }, [props.menuItems]);

    const MenuClick = (item) => {
        setOpen(false);
        func(props.id, item.id, item);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div style={{ ...styles.Block, width: undefined }}>
            <IconButton ref={divRef} onClick={() => setOpen(true)} style={{ ...styles.ButtonIcon, width: 26, height: 26, backgroundColor: props.sortMenu == undefined ? props.backgroundColor == undefined ? color.white : props.backgroundColor : '#00000000', border: '' }}>
                <FontAwesomeIcon style={{ height: props.sortMenu == undefined ? 16 : 14, color: props.sortMenu == undefined ? props.color == undefined ? color.black : props.color : color.darker_gray }} icon={props.icon == undefined ? props.sortMenu == undefined ? faEllipsisV : faBars : props.icon} />
            </IconButton>
            {menuItems != false ?
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{ zIndex: 31000 }}
                >
                    {menuItems.map((item, index) => (
                        item.divider == true ?
                            <Divider key={item.id} >
                                {item.label != undefined ?
                                    <p style={{ ...styles.TextXTiny, fontWeight: '600', color: color.medium_gray, textAlignLast: 'left' }}>{item.label.toUpperCase()}</p>
                                    : null}
                            </Divider>
                            :
                            item.enabled == true ?
                                <MenuItem onClick={(event) => MenuClick(item)} key={item.id} value={item.id}>
                                    {item.icon != undefined ?
                                        <ListItemIcon>
                                            <FontAwesomeIcon style={{ height: 14, color: color.black }} icon={item.icon} />
                                        </ListItemIcon>
                                        : null}
                                    {item.checked == true ?
                                        <ListItemIcon>
                                            <FontAwesomeIcon style={{ height: 14, color: color.black }} icon={faCheck} />
                                        </ListItemIcon>
                                        : null}
                                    {item.checked == false && item.icon == undefined ?
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        : null}
                                    <ListItemText>
                                        <p style={{ ...styles.TextTiny }}>{item.label}</p>
                                    </ListItemText>
                                </MenuItem>
                                : null
                    ))}
                </Menu>
                : null}
        </div>
    );
}




export const SuperAdmin = (props) => {

    // <SuperAdmin theme={props.theme} lang={props.lang} />

    let color = global.themes[props.theme];

    return (
        <div style={{ ...styles.Block, width: 100, height: 20, borderRadius: 10, backgroundColor: color.super_admin }}>
            <p style={{ ...styles.TextXXTiny, color: color.white }}>{props.lang.super_admin}</p>
        </div>
    );
}

export const SwitchEnable = (props) => {

    // <SwitchEnable checkbox enabled={true} theme={props.theme} func={SwitchResult.bind()} />

    let color = global.themes[props.theme];
    let { func } = props;

    const [enabled, setEnabled] = useState(props.enabled);

    useEffect(() => {

        setEnabled(props.enabled);

        return () => {
        };

    }, [props.enabled]);

    const Click = () => {
        let value = !enabled;
        setEnabled(value);

        func(value);
    }

    return (
        props.checkbox == undefined ?
            <Switch
                checked={enabled}
                size='small'
                disabled={props.disabled == true ? true : false}
                onChange={(event) => Click(event.target.checked)}
            />
            :
            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{props.title}</p>}
                control={<Checkbox checked={enabled} size={'small'} onChange={() => Click()} />}
            />

    );
}


export const Avatar = (props) => {

    /*

        <Avatar editable picture image={ } size={ } theme={props.theme} func={AvatarPress.bind()} />

        PARAMS:
        ----------------------------------------------------------------------------------------------------
        editable    -> zobrazí sa ikona ceruzky - button
        picture     -> default image je obrázok, inak person
        size        -> velkost avataru

        RESULT:
        ----------------------------------------------------------------------------------------------------
    const AvatarPress = (value) => {
        if (value == 1) {
            setSelectPhoto(true);
        }
        if (value == 0) {
            setImage('');
        } if (value == 2) {
            setShowColors(true);
        }
    }

    */

    // IMAGE
    const user_default = require('./react/app/user.png');
    const image_default = require('./react/app/default_image.png');

    // AVATAR SIZE
    const size = 100;

    // PARAMS
    let color = global.themes[props.theme];
    let lang = props.lang;
    let { func } = props;

    const [over, setOver] = useState(false);

    useEffect(() => {

        //setImage(props.image == undefined || props.image == false || props.image == '' ? props.picture == undefined ? user_default : image_default : props.image);

        return () => {
        };

    }, [props.image]);

    const Process = (value) => {
        func(value);
    }

    const Delete = () => {
        func(false);
    }

    const MouseOver = (value) => {
        if (props.editable == true) {
            setOver(value);
        }
    }

    return (
        <div style={{ ...styles.Block, width: props.size == undefined ? size : props.size, height: props.size == undefined ? size : props.size, justifyContent: 'center', position: 'relative' }}>
            <div style={{ ...styles.Block }}>
                {props.image == undefined || props.image == false || props.image == '' ?
                    <img src={props.picture == undefined ? user_default : image_default} style={{ width: '100%', height: '100%', maxWidth: props.size == undefined ? size : props.size, maxHeight: props.size == undefined ? size : props.size, objectFit: 'contain', borderRadius: 10 }}></img>
                    :
                    props.image.substring(0, 1) == '#' ?
                        <div style={{ ...styles.Block, width: props.size == undefined ? size : props.size, height: props.size == undefined ? size : props.size, backgroundColor: props.image, borderRadius: props.size == undefined ? size / 2 : props.size / 2 }}></div>
                        :
                        <img src={GetImageLink(props.image)} style={{ width: '100%', height: '100%', maxWidth: props.size == undefined ? size : props.size, maxHeight: props.size == undefined ? size : props.size, objectFit: 'contain', borderRadius: 10 }}></img>
                }

            </div>
            <div style={{ ...styles.Block, width: props.size == undefined ? size : props.size, height: props.size == undefined ? size : props.size, position: 'absolute', cursor: props.editable == true ? 'pointer' : undefined }}
                onMouseOverCapture={() => MouseOver(true)} onMouseLeave={() => MouseOver(false)}
            >
                {over == true && props.editable == true ?
                    <div style={{ ...styles.BlockRowRaw }}>
                        <IconButton onClick={() => Process(1)} style={{ ...styles.ButtonIcon, backgroundColor: '#FFFFFFB0' }}>
                            <FontAwesomeIcon style={{ height: 12 }} icon={faPen} />
                        </IconButton>
                        {props.color == true ?
                            <IconButton onClick={() => Process(2)} style={{ ...styles.ButtonIcon, backgroundColor: '#FFFFFFB0', marginLeft: 6 }}>
                                <FontAwesomeIcon style={{ height: 12 }} icon={faPalette} />
                            </IconButton>
                            : null}

                        {props.image == undefined || props.image == false || props.image == '' ? null :
                            <IconButton onClick={() => Delete()} style={{ ...styles.ButtonIcon, backgroundColor: '#FFFFFFB0', marginLeft: 6 }}>
                                <FontAwesomeIcon style={{ height: 12 }} icon={faTrash} />
                            </IconButton>
                        }
                    </div>
                    : null}
            </div>
        </div >
    );
}


export const Logs = (props) => {
    /*

                                USAGE:
                                <Logs logs={logs} lang={props.lang} theme={props.theme} currency={currency} precisions={precisions} />

                                PARAMS:
                                ----------------------------------------------------------------
            logs    -> zoznam logov z DB logs_modules

            stats_type  -> Typ statistiky (0-štatistika ceny produkty)
            item_id     -> id produktu, ...
                                */

    var lang = props.lang;
    let color = global.themes[props.theme];

    const [isBusy, setBusy] = useState(false);
    const [graphData, setGraphData] = useState(false);
    const [graphData2, setGraphData2] = useState(false);
    const [graphLabel, setGraphLabel] = useState(false);
    const [stats, setStats] = useState(false);

    let lineHeight = 30;
    let rowHeight = 30;
    let timeWidth = 50;
    let middleWidth = 40;
    let textWidth = 400;
    let precisions = props.precisions;
    let currency = props.locale.currency;

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let graphWidth = width < 800 ? width - 20 : 800;
    let graphHeight = 360;


    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, [props.logs]);

    const db_stats = async (type, item_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'logs_stats', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    type: type,
                    item_id: item_id
                })
            })

            const json = await response.json();
            Debug('LOGS STATS');
            Debug(json);
            if (json.ok > 0) {
                setGraphLabel(json.graph_label);
                setGraphData(json.graph_data);
                setGraphData2(json.graph_data2);
                setStats(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Process = () => {
        db_stats(0, props.item_id);
    }

    return (
        <div style={{ ...styles.Block }}>
            <div style={{ ...styles.BlockLeft, width: '96%' }}>
                {props.stats == true ?
                    <div style={{ ...styles.BlockRight, height: 50, borderBottom: color.border }}>
                        <Button onClick={() => Process()} style={{ ...styles.ButtonThemed, color: color.black, backgroundColor: color.button_action }}>{lang.statistic}</Button>
                    </div>
                    : null}

                {stats == true && graphData != false ?
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.logs_product_label}</p>
                        </div>
                        <LineChart
                            sx={{ rx: 15 }}
                            xAxis={[
                                {
                                    id: 'cost',
                                    data: graphLabel,
                                    scaleType: 'band',
                                    //label: lang.logs_product_time,
                                    labelStyle: { fill: color.medium_gray, fontSize: 12 },
                                    tickLabelStyle: {
                                        angle: graphData.length > 12 ? -45 : 0,
                                        textAnchor: graphData.length > 12 ? 'end' : 'middle',
                                        fontSize: 12,
                                        letterSpacing: 0
                                    },
                                },
                            ]}
                            yAxis={[
                                {
                                    id: 'time',
                                    //label: lang.logs_product_value,
                                    labelStyle: { fill: color.medium_gray, fontSize: 14 },
                                }
                            ]}

                            series={[
                                {
                                    data: graphData,
                                    label: lang.logs_product_cost_label + ' (' + props.currency + ')',
                                    labelStyle: { fill: color.medium_gray, fontSize: 14 },
                                    color: color.graph_bars_1
                                },
                                {
                                    data: graphData2,
                                    label: lang.logs_product_sell_label + ' (' + props.currency + ')',
                                    labelStyle: { fill: color.medium_gray, fontSize: 14 },
                                    color: color.graph_bars_2
                                },
                            ]}
                            slotProps={{
                                legend: {
                                    labelStyle: {
                                        fontSize: 14,
                                        fill: color.dark_gray,
                                    },
                                },
                                bar: {
                                    rx: 5,
                                    ry: 5
                                },
                            }}

                            onItemClick={(event, d) => Debug(d)}

                            width={graphWidth}
                            height={graphHeight}
                        />
                    </div>
                    : null}
                {props.logs != false ? props.logs.map((item, index) => (
                    <div key={item.id} style={{ ...styles.BlockLeft }}>
                        {index > 0 ?
                            <div style={{ ...styles.BlockRowRaw, minHeight: lineHeight }}>
                                <div style={{ ...styles.BlockRight, width: timeWidth }}></div>
                                <div style={{ ...styles.Block, width: middleWidth }}>
                                    <div style={{ ...styles.Block, width: 1, height: lineHeight, backgroundColor: color.medium_gray }}></div>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: textWidth }}></div>
                            </div>
                            :
                            <div style={{ ...styles.BlockLeft, width: timeWidth + middleWidth + textWidth, minHeight: 50 }}>
                                <p style={{ ...styles.TextTiny }}>{lang.logs_history}</p>
                            </div>
                        }
                        <div style={{ ...styles.BlockRowRaw, minHeight: 26 }}>
                            <div style={{ ...styles.BlockRight, width: timeWidth - 30 }}></div>
                            <div style={{ ...styles.Block, width: middleWidth + 60, height: 26, backgroundColor: color.light_gray, borderRadius: 18 }}>
                                <p style={{ ...styles.TextXTiny }}>{GetDatum2(item.created)}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: textWidth - 30 }}></div>
                        </div>

                        <div style={{ ...styles.BlockRowRaw, minHeight: lineHeight / 4 }}>
                            <div style={{ ...styles.BlockRight, width: timeWidth }}></div>
                            <div style={{ ...styles.Block, width: middleWidth }}>
                                <div style={{ ...styles.Block, width: 1, height: lineHeight / 4, backgroundColor: color.medium_gray }}></div>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: textWidth }}></div>
                        </div>

                        <div style={{ ...styles.BlockRowRaw, marginTop: 2, marginBottom: 2 }}>
                            <div style={{ ...styles.BlockRight, width: timeWidth, minHeight: rowHeight }}>
                                <p style={{ ...styles.TextXTiny, color: color.darker_gray }}>{GetFromDatumTime(item.created)}</p>
                            </div>

                            <div style={{ ...styles.Block, width: middleWidth, minHeight: rowHeight }}>
                                <div style={{ ...styles.Block, width: 30, height: 30, backgroundColor: color.medium_gray, borderRadius: 15 }}>
                                    <FontAwesomeIcon style={{ height: item.action == 0 ? 20 : 12, color: color.back }} icon={item.action == 0 ? faCircle : faPen} />
                                </div>
                            </div>
                            {props.typ == 0 ?
                                <div onClick={() => func(true, item)} style={{ ...styles.BlockLeft, width: textWidth, minHeight: rowHeight, cursor: item.error_id > 0 ? 'pointer' : undefined }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.logs_status[item.action]}</p>
                                    <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{item.user}</p>
                                    <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{lang.order}: <b>{lang.order_status[item.status]}</b></p>
                                </div>
                                :
                                <div style={{ ...styles.BlockLeft, width: textWidth, minHeight: rowHeight }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.logs_status[item.action]}</p>
                                    {item.text != '' ?
                                        <p style={{ ...styles.TextTiny }}>{item.text}</p>
                                        : null}
                                    <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{item.user}</p>
                                    {item.action == global.logs.prices_changed || item.action == global.logs.variation_prices_changed ?
                                        <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{lang.order_status}: {FormatMoney(item.cost, precisions)} {currency}, {lang.product_price}: {FormatMoney(item.price, precisions)} {currency}</p>
                                        : null}
                                    {item.action == global.logs.price_cost_changed || item.action == global.logs.variation_price_cost_changed ?
                                        <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{lang.product_cost}: {FormatMoney(item.cost, precisions)} {currency}</p>
                                        : null}
                                    {item.action == global.logs.price_sell_changed || item.action == global.logs.variation_price_sell_changed ?
                                        <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{lang.product_price}: {FormatMoney(item.price, precisions)} {currency}</p>
                                        : null}
                                </div>
                            }
                        </div>
                    </div>
                )) : null}
            </div>
        </div>
    );
}

export const DialogExport = (props) => {
    /*
                                OKNO - full screen

                                USAGE:
                                <DialogExport columns={exportColumns} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />

                                --------------------------------------------------------
                    columns    -> stlpce, ktoré budú exportované

                                --------------------------------------------------------

                    const DialogEnterTextResult = (typ, text, columns) => {
                                    console.log(value)
                                }
                                */


    const [text, setText] = useState(props.text);
    const [columns, setColumns] = useState(props.columns == undefined ? false : props.columns);
    const [redraw, setRedraw] = useState(false);
    const [selected, setSelected] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = props.columns == undefined ? 300 : height >= global.dialog_max_height ? global.dialog_max_height : height;

    const rowHeight = 50;

    let { func } = props;
    let lang = props.lang;

    const inputRef = useRef(null);
    const color = global.themes[props.theme];

    var running = false;
    useEffect(() => {

        // FOCUS TO TEXTAREA
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);


        return () => {
        };

    }, []);


    const Press = () => {
        var tmp = [];
        if (columns != false) {
            tmp = columns.filter(x => x.selected == true);
        }
        func(true, text, tmp);
    }

    const Close = () => {
        func(false, false, false);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const selectAll = () => {
        let value = !selected;

        columns.forEach(item => {
            item.selected = value;
        });

        setSelected(value);
    }
    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius, backgroundColor: color.back }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faInfo} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 80, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '98%' }}>
                        <div style={{ ...styles.Block, width: '98%', height: 150 }}>
                            <p style={{ ...styles.TextTiny, marginTop: 5, marginBottom: 2 }}>{lang.export_note}</p>
                            <TextField
                                inputRef={inputRef}
                                value={text}
                                onInput={e => setText(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                multiline={true}
                                rows={3}
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: color.white },
                                }}
                                variant="outlined"
                            />
                        </div>
                        {props.columns != undefined ?
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 100 - 125, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div style={{ ...styles.Block, paddingBottom: global.empty_padding }}>
                                    <div style={{ ...styles.BlockRow, width: '90%' }}>
                                        <div style={{ ...styles.Block, width: 50, height: rowHeight }}>
                                            <Checkbox checked={selected} size={'small'} onChange={() => selectAll()} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '90%', height: rowHeight }}>
                                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.export_columns}</p>
                                        </div>
                                    </div>
                                    {columns != false ? columns.map((item, index) => (
                                        <Paper elevation={1} key={item.id} style={{ ...styles.BlockRow, width: '90%', marginTop: 3, marginBottom: 3 }}>
                                            <div style={{ ...styles.Block, width: 50, height: rowHeight }}>
                                                <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '90%', height: rowHeight }}>
                                                <p style={{ ...styles.TextTiny }}>{item.label}</p>
                                            </div>
                                        </Paper>
                                    )) : null}
                                </div>
                            </div>
                            : null}

                    </div>
                </div>
                <div style={{ ...styles.Block, height: 80 }}>
                    <Button onClick={() => Press()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, color: color.black }}>{props.button}</Button>
                </div>
            </Paper>
        </Backdrop>
    );
}


export const SearchButton = (props) => {
    /*

                                <SearchButton menuItems={[{ id: 0, label: 'New' }]} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />


                                PARAMS:
                                ------------------------------------------------------------------------------------
                                id                  - ID tlačítka (používasa pri vrátení hodnoty - identifikácia, ktory menu button bol stlačený ak ich je použitých viac na stránke)
                                menuItems           - hodnoty v menu [{id: 0, label: 'New' }, .... ]
                                sortMenu            - button ako sort buttton
                                icon                - ikona

                                RESULT:
                                ------------------------------------------------------------------------------------
                const SearchButtonResult = (index) => {
                        switch (index) {
                            case 0:
                                Debug('Click - menu 1')
                                break;

                                case 1:
                                Debug('Click - menu 2')
                                break;

                        }
                }

                                */


    const [menuItems, setMenuItems] = useState(props.menuItems == false || props.menuItems == undefined ? false : props.menuItems);
    const [open, setOpen] = useState(false);
    const [selectedID, setSelectedID] = useState(-1);
    const [anchorEl, setAnchorEl] = useState(null);

    let { func } = props;
    let color = global.themes[props.theme];
    const divRef = React.useRef();

    useEffect(() => {

        setAnchorEl(divRef.current);

        return () => {
        };

    }, [props.menuItems]);

    const MenuClick = (menu_id) => {
        //setOpen(false);
        func(menu_id);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div style={{ ...styles.Block }}>
            <IconButton ref={divRef} onClick={() => setOpen(true)} style={{ ...styles.ButtonIconThemed, backgroundColor: '#00000000' }}>
                <FontAwesomeIcon style={{ height: 16, color: color.dark_gray }} icon={faSliders} />
            </IconButton>
            {menuItems != false ?
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    style={{ zIndex: 29000 }}
                //onClick={(event) => MenuClick(event.target.value)}
                >
                    <div style={{ ...styles.Block, minWidth: 300, paddingBottom: 10, borderBottom: color.border }}>
                        <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{props.lang.search_filter}</p>
                    </div>
                    {menuItems.map((item, index) => (
                        item.enabled == true ?
                            <MenuItem key={item.id} value={item.id}>
                                <div style={{ ...styles.BlockLeft, height: 20 }}>
                                    <FormControlLabel label={<p style={{ ...styles.TextTiny, marginLeft: 10 }}>{item.label}</p>}
                                        control={<Checkbox checked={item.checked} size={'small'} style={{ padding: 0, margin: 0 }} onChange={() => MenuClick(item.id)} />}
                                    />
                                </div>
                            </MenuItem>
                            : null
                    ))}
                </Menu>
                : null}
        </div>
    );
}


export const DeleteButton = (props) => {
    /*

        ICON BUTTON
            <DeleteButton ask sizeNormal text={lang.cond_delete_ask} icon_color={color.white} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => Delete()} />

        STANDART BUTTON
            <DeleteButton ask button bold label={'Delete'} icon={faTrash} position={'top'} backgroundColor={color.button_delete} text={lang.file_folder_delete} text_note={lang.file_folder_delete_note} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteFolder()} />

        PARAMS:
        --------------------------------------------------------------------------------------------------------
        text                ->  textová otázka
        position            -> pozícia overlay dialogu
        button              -> štandartné tlačítko
        label               -> nápis na štandartnom tlačítku
        icon                -> vlastná ikona
        backgroundColor     -> farba pozadia tlačítka
        backColor           -> farba pozadia dialogového formulára
        bold                -> bold text
        text_note           -> pod text
        ok                  -> názov tlačítka 'OK'
                                */


    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const divRef = React.useRef();

    let { func } = props;
    let color = global.themes[props.theme];
    let lang = props.lang;

    useEffect(() => {

        setAnchorEl(divRef.current);

        return () => {
        };

    }, []);

    const Delete = () => {
        if (props.ask == true) {
            setOpen(true);
        } else {
            func(true);
        }
    }

    const DeleteOK = () => {
        setOpen(false);
        func(true);
    }

    return (
        <div>
            {props.button == undefined ?
                <Tooltip title={props.tooltip == undefined ? lang.delete : props.tooltip} PopperProps={{ style: { zIndex: 31001 } }} >
                    <IconButton ref={divRef} onClick={(e) => { e.stopPropagation(); Delete() }} style={{ ...styles.ButtonIcon, backgroundColor: props.backgroundColor == undefined ? undefined : props.backgroundColor, top: props.top, left: props.left, width: props.sizeNormal == undefined ? 30 : 34, height: props.sizeNormal == undefined ? 30 : 34, borderRadius: props.sizeNormal == undefined ? 15 : 17 }}>
                        <FontAwesomeIcon style={{ width: 10, color: props.icon_color == undefined ? undefined : props.icon_color }} icon={props.icon == undefined ? faXmark : props.icon} />
                    </IconButton>
                </Tooltip>
                :
                <Button ref={divRef} onClick={() => Delete()} style={{ ...styles.ButtonThemed, backgroundColor: props.backgroundColor == undefined ? color.button_delete : props.backgroundColor, width: props.width == undefined ? 160 : props.width }}>
                    {props.icon != undefined ?
                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={props.icon} />
                        : null}
                    <p style={{ ...styles.TextXSmall, color: color.white }}>{props.label}</p>
                </Button>
            }
            <Popper
                style={{ zIndex: 31002 }}
                open={open}
                anchorEl={anchorEl}
                placement={props.position == undefined ? 'bottom' : props.position}
            >
                <Paper elevation={3} style={{ ...styles.Block, marginTop: 2, border: '1px solid #777777', marginBottom: props.position == 'top' ? props.marginBottom == undefined ? 20 : props.marginBottom : 0, paddingTop: 15, paddingBottom: 15, borderRadius: global.radius, backgroundColor: props.backColor == undefined ? color.back : props.backColor }}>
                    <div style={{ ...styles.Block, paddingTop: 0, paddingBottom: 25, paddingLeft: 10, paddingRight: 10 }}>
                        <p style={{ ...styles.TextXSmall, fontWeight: props.bold == undefined ? '400' : '600' }}>{props.text == undefined ? lang.delete_item_ask : props.text}</p>
                        {props.text_note != undefined ?
                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginTop: 5 }}>{props.text_note}</p>
                            : null}
                    </div>
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ ...styles.Block, width: '50%' }}>
                            <Button onClick={(e) => { e.stopPropagation(); DeleteOK() }} style={{ ...styles.ButtonThemed, width: 100, backgroundColor: color.dark_red, marginLeft: 10 }}>
                                <p style={{ ...styles.TextTiny, color: color.white }}>{props.ok == undefined ? lang.ok : props.ok}</p>
                            </Button>
                        </div>
                        <div style={{ ...styles.Block, width: '50%', marginLeft: 20 }}>
                            <Button onClick={(e) => { e.stopPropagation(); setOpen(false) }} style={{ ...styles.ButtonThemed, width: 100, backgroundColor: color.button_cancel, marginRight: 10 }}>
                                <p style={{ ...styles.TextTiny, color: color.white }}>{props.cancel == undefined ? lang.cancel : props.cancel}</p>
                            </Button>
                        </div>
                    </div>
                </Paper>
            </Popper>
        </div>
    );
}


export const Note = (props) => {
    /*
 
        <Note label={lang.note} text={'Textová správa'} list={['text č.1', 'text č.2']} width={400} theme={props.theme} lang={props.lang} />
 
        PARAMS:
        --------------------------------------------------------------------------------------------------------
        text    ->  textová poznámka
 
    */

    let { func } = props;
    let color = global.themes[props.theme];
    let lang = props.lang;

    useEffect(() => {


        return () => {
        };

    }, []);

    return (
        <div style={{ ...styles.BlockLeft, width: props.width == undefined ? '100%' : props.width, borderLeft: '4px solid ' + color.note_line, paddingTop: 7, paddingBottom: 7, backgroundColor: color.note }}>
            <p style={{ ...styles.TextXSmall, marginLeft: 10, fontWeight: '600' }}>{props.label}</p>
            {props.text != undefined && props.text != '' ?
                <p style={{ ...styles.TextXSmall, marginLeft: 10, marginTop: 5 }}>{props.text}</p>
                : null}
            {props.list != undefined ?
                <div style={{ ...styles.BlockLeft, width: undefined, marginLeft: 10, marginTop: 5 }}>
                    {props.list.map((item, index) => (
                        <div style={{ ...styles.BlockRowRaw }}>
                            <FontAwesomeIcon style={{ height: 6, color: color.black, marginTop: 5 }} icon={faCircle} />
                            <p key={index} style={{ ...styles.TextXSmall, marginLeft: 7 }}>{item}</p>
                        </div>
                    ))}
                </div>
                : null}
        </div>
    );
}

export const QuickHelp = (props) => {
    /*

                                <QuickHelp id={1} lang={lang} width={34} height={34} theme={props.theme} locale={props.locale} />

                                PARAMS:
                                --------------------------------------------------------------------------------------------------------
        text    ->  textová poznámka

                                */

    const [isBusy, setBusy] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [text, setText] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    let maxWidth = 250;
    let minWidth = 250;
    let minHeight = 100;
    let color = global.themes[props.theme];
    let lang = props.lang;

    const [anchorEl, setAnchorEl] = useState(null);
    const divRef = React.useRef();

    useEffect(() => {
        setAnchorEl(divRef.current);

        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quick_help', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    help_id: props.id,
                    country_id: props.lang.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var txt = json.text.split('\n');
                setText(txt);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const ChangeOpen = (value) => {
        if (value == false) {
            setShowHelp(false);
        } else {
            setShowHelp(true);
            db_get();
        }
    }
    return (
        <div style={{ ...styles.Block, width: props.width == undefined ? 20 : props.width, height: props.height == undefined ? 18 : props.height, marginLeft: props.marginLeft == undefined ? 0 : props.marginLeft }}>
            <IconButton ref={divRef} onClick={() => ChangeOpen(!showHelp)} style={{ ...styles.ButtonIconThemed, width: 18, height: 18, backgroundColor: '#00000000', border: '2px solid ' + color.medium_gray }}>
                <FontAwesomeIcon style={{ height: 12, color: color.medium_gray }} icon={faInfo} />
            </IconButton>

            {showHelp == true ?
                <div onClick={() => setShowHelp(false)} style={{ position: 'absolute', top: 0, left: 0, width: width, height: height, zIndex: 55033 }}>
                </div>
                : null}

            <Popper
                style={{ zIndex: 55055 }}
                open={showHelp}
                anchorEl={anchorEl}
                placement={'bottom'}
            >
                <Paper elevation={3} onClick={() => setShowHelp(false)} style={{ ...styles.Block, minWidth: minWidth, maxWidth: maxWidth, minHeight: minHeight, marginTop: 2, padding: 5, borderRadius: 10, backgroundColor: color.black, cursor: 'pointer' }}>
                    {global.testing == true ?
                        <div style={{ ...styles.BlockRight }}>
                            <p style={{ ...styles.TextXTiny, color: color.medium_gray }}>#{props.id}</p>
                        </div>
                        : null}

                    {isBusy == false ?
                        text != false ? text.map((item, index) => (
                            item == '' ?
                                <div key={index} style={{ ...styles.Block, height: 10 }}></div>
                                :
                                <div key={index} style={{ ...styles.BlockLeft }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', color: color.white }}>{item}</p>
                                </div>
                        ))
                            : null :
                        <CircularProgress style={{ color: '#FFFFFF' }} thickness={2} size={20} />
                    }
                </Paper>
            </Popper>

        </div>
    );
}

export const SubMenuButton = (props) => {

    /*
    
        <SubMenuButton id={0} selected_id={tabIndex} label={lang.customer_basic_info} width={menuWidth} theme={props.theme} func={ChangeTab.bind(this)}/>
 
    */

    let { func } = props;
    let color = global.themes[props.theme];

    return (
        <Button onClick={() => func(props.id)} style={{ ...styles.ButtonSubMenu, width: props.width - 8, backgroundColor: props.selected_id == props.id ? color.white : '#00000000', color: props.selected_id == props.id ? color.black : color.dark, borderRightColor: props.selected_id == props.id ? color.sub_menu_border : '#00000000' }}>{props.label}</Button>
    );
}


export const WebURLPreview = (props) => {

    /*

                                <WebURLPreview url={previewLink} theme={props.theme} title={lang.file_preview} lang={props.lang} func={() => setShowPreview(false)} />

                                */
    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    let { func } = props;

    // KONCOVKA SUBORU
    let extension = props.url.substring(props.url.lastIndexOf(".")).replace('.', '');

    // JEDNA SA O OBRAZOK?
    let isImage = global.supported_images.includes(extension) ? true : false;

    var running = false;

    useEffect(() => {
        if (running == false) {

            running = true;
        }

    }, []);

    const Close = () => {
        func(false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2500 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faFile} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, backgroundColor: '#E0E0E0', borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    {isImage == true ?
                        <img src={props.url} style={{ width: '100%', height: '100%', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}></img>
                        :
                        <iframe width={'100%'} height={'100%'} style={{ border: '0px', transform: 'scale(1)', transformOrigin: '0 0' }} src={props.url}></iframe>
                    }
                </div >
            </Paper >
        </Backdrop>
    );
}


export const ButtonChangeStyle = (props) => {

    /*
    
        <ButtonChangeStyle value={displayStyle} theme={props.theme} func={(id) => ChangeStyle(id)} />
 
    */

    let { func } = props;
    let color = global.themes[props.theme];
    let buttonWidth = 36;

    return (
        <div style={{ ...styles.BlockRow, width: buttonWidth * 2 }}>
            <div onClick={() => func(0)} style={{ ...styles.Block, backgroundColor: props.value == 0 ? color.white : '#00000020', borderTopLeftRadius: 13, borderBottomLeftRadius: 13, width: buttonWidth, height: 26 }}>
                <FontAwesomeIcon style={{ width: 16, color: props.value == 0 ? color.dark : color.gray, marginLeft: 4 }} icon={props.icon1 == undefined ? faBars : props.icon1} />
            </div>
            <div onClick={() => func(1)} style={{ ...styles.Block, backgroundColor: props.value == 1 ? color.white : '#00000020', borderTopRightRadius: 13, borderBottomRightRadius: 13, width: buttonWidth, height: 26 }}>
                <FontAwesomeIcon style={{ width: 16, color: props.value == 1 ? color.dark : color.gray, marginRight: 4 }} icon={props.icon2 == undefined ? faTableCells : props.icon2} />
            </div>
        </div>
    );
}


export const StockStatus = (props) => {

    /*
    
        <StockStatus stock={stock} product={item} allowed={CheckPermission(props.user.super_admin, props.permissions[permission_column]).editing == true? true: false} theme={props.theme} func={(id) => ChangeStyle(id)} />
 
        ========================================================================
        stock       -> sklad (object)
        product     -> informácie o produkte (object)
        allowed     -> je povolené editovanie
    */

    let { func } = props;
    let color = global.themes[props.theme];
    let stockCellWidth = 136;
    let stockCellHeight = 70;


    return (
        <div style={{ ...styles.Block, width: stockCellWidth, border: color.border, margin: 5, borderRadius: 5 }}>
            <div style={{ ...styles.Block, backgroundColor: global.stock_color[props.stock.type], height: (stockCellHeight / 2) - 5 }}>
                <TextLine text={props.stock.code} fontSize={global.font_tiny} color={color.black} />
            </div>
            <div style={{ ...styles.Block, height: (stockCellHeight / 2) + 5 }}>
                <div style={{ ...styles.BlockRow, width: '92%' }}>
                    <div style={{ ...props.allowed == true ? styles.BlockLeft : styles.Block, height: (stockCellHeight / 2) + 5 }}>
                        <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{FormatPieces(props.stock.quantity)} {props.product.unit}</p>
                    </div>
                    {props.allowed == true ?
                        <div style={{ ...styles.Block, width: 40, height: (stockCellHeight / 2) + 5 }}>
                            <IconButton onClick={() => func(props.stock, props.product)} style={{ ...styles.ButtonIcon }}>
                                <FontAwesomeIcon style={{ width: 12 }} icon={faPen} />
                            </IconButton>
                        </div>
                        : null}
                </div>
            </div>
        </div>
    );
}


export const ContactInfo = (props) => {

    /*
    
        <ContactInfo mobil={item.mobil} phone={item.phone} email={item.email} theme={props.theme} />
 
    */

    let color = global.themes[props.theme];
    let iconWidth = 26;

    return (
        <div style={{ ...styles.BlockLeft }}>
            {props.mobil != '' ?
                <div style={{ ...styles.BlockRowRaw, marginBottom: 6 }}>
                    <div style={{ ...styles.BlockLeft, width: iconWidth }}>
                        <FontAwesomeIcon style={{ height: 16, color: color.dark_gray }} icon={faMobileAlt} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: undefined }}>
                        <p style={{ ...styles.TextTiny }}>{props.mobil}</p>
                    </div>
                </div>
                : null}
            {props.phone != '' ?
                <div style={{ ...styles.BlockRowRaw, marginBottom: 6 }}>
                    <div style={{ ...styles.BlockLeft, width: iconWidth }}>
                        <FontAwesomeIcon style={{ height: 16, color: color.dark_gray }} icon={faPhoneAlt} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: undefined }}>
                        <p style={{ ...styles.TextTiny }}>{props.phone}</p>
                    </div>
                </div>
                : null}
            {props.email != '' ?
                <div style={{ ...styles.BlockRowRaw }}>
                    <div style={{ ...styles.BlockLeft, width: iconWidth }}>
                        <FontAwesomeIcon style={{ height: 16, color: color.dark_gray }} icon={faAt} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: undefined }}>
                        <p style={{ ...styles.TextTiny }}>{props.email}</p>
                    </div>
                </div>
                : null}
        </div>
    );
}

export const ContactCompany = (props) => {

    /*
    
        <ContactCompany center company={item.company} name={item.name} surname={item.surname} theme={props.theme} />
 
    */

    let color = global.themes[props.theme];

    return (
        <div style={{ ...props.center == undefined ? styles.BlockLeft : styles.Block }}>
            {props.company != '' ?
                <TextLine text={props.company} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                : null}
            {props.name != '' || props.surname != '' ?
                <TextLine text={props.name + ' ' + props.surname} fontSize={global.font_xsmall} fontWeight={props.company == '' ? '600' : '400'} color={color.black} />
                : null}
        </div>
    );
}

export const TextLine = (props) => {

    /*

        <TextLine text={item.text} fontSize={global.font_tiny} fontWeight={'600'} marginLeft={5} color={color.black} />

    */

    return (
        <div style={{ display: 'block', alignContent: 'flex-start', maxWidth: '98%', minHeight: props.fontSize + 4, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginTop: props.marginTop == undefined ? 0 : props.marginTop }}>
            <span style={{
                ...styles.TextXSmall,
                fontSize: props.fontSize,
                textAlign: 'left',
                color: props.color,
                fontWeight: props.fontWeight != undefined ? props.fontWeight : '400',
                marginLeft: props.marginLeft != undefined ? props.marginLeft : 0,
                marginLeft: props.marginTop != undefined ? props.marginTop : 0,
                marginLeft: props.marginBottom != undefined ? props.marginBottom : 0,
            }}>
                {props.text}
            </span>
        </div>
    );
}

export const NameSurname = (props) => {

    /*
    
        <NameSurname name={item.name} surname={item.surname} fontSize={global.font_xsmall} color={color.black} settings={props.settings} theme={props.theme} />
 
    */

    let color = global.themes[props.theme];

    return (
        props.settings.name_format == 0 ?
            <p style={{
                ...styles.TextXSmall,
                fontSize: props.fontSize == undefined ? global.font_xsmall : props.fontSize,
                fontWeight: props.fontWeight == undefined ? '400' : props.fontWeight,
                color: props.color == undefined ? color.black : props.color
            }}>
                {props.name} {props.surname}
            </p>
            :
            <p style={{
                ...styles.TextXSmall,
                fontSize: props.fontSize == undefined ? global.font_xsmall : props.fontSize,
                fontWeight: props.fontWeight == undefined ? '400' : props.fontWeight,
                color: props.color == undefined ? color.black : props.color
            }}>
                {props.surname} {props.name}
            </p>
    );
}

// GetNameSurname(customer.company, customer.name, customer.surname, props.settings.name_format)
export const GetNameSurname = (company, name, surname, name_format) => {
    var result = '';

    if (company == '') {
        if (name_format == 0) {
            result = name + ' ' + surname;
        } else {
            result = surname + ' ' + name;
        }
    } else {
        result = company;
    }

    return result;
}

export const ProductSearch = (props) => {

    /*

                                <ProductSearch excluded={excluded} marginTop={25} theme={props.theme} lang={lang} radius={props.radius} locale={props.locale} organization={props.organization} func={(item) => QuickResult(item)} />

                                */

    const [isBusy, setBusy] = useState(false);

    let color = global.themes[props.theme];
    let lang = props.lang;

    const search_quick = require('./react/app/search_quick.png');

    let { func } = props;

    const db_get = async (id) => {

        setBusy(true);

        let filter = {
            ordering: global.order.down,
            order_column: 'name',
            country_id: props.locale.id,
            enabled: true,
            stocks: true,
            stock_id: props.stock_id == undefined ? 0 : props.stock_id,
            product_id: id,
        };

        try {
            const response = await fetch(
                global.db_url + 'price_list_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.price_list,
                    start: 0,
                    length: 1,
                    filter: filter
                })
            })

            const json = await response.json();

            Debug('PRODUCT WITH PRICES', 'green');
            Debug(json);

            setBusy(false);

            if (json.ok > 0) {
                if (json.items != false) {
                    func(json.items[0]);
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Result = (item) => {
        if (props.price_list == undefined) {
            func(item);
        } else {
            // NACITAT VYBRATY PRODUKT Z CENNIKA
            db_get(item.id);
        }
    }

    return (
        <div style={{ ...styles.BlockRow, marginTop: props.marginTop, paddingTop: 15, paddingBottom: 15, backgroundColor: color.light_gray }}>
            <div style={{ ...styles.Block, width: 150 }}>
                <img src={search_quick} style={{ width: '100%', height: '100%', maxWidth: 70, maxHeight: 70, objectFit: 'contain' }}></img>
            </div>
            <div style={{ ...styles.BlockLeft, width: '90%' }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <div style={{ ...styles.Block, height: 20, width: undefined }}>
                        <FontAwesomeIcon style={{ width: 14, color: color.dark_gray }} icon={faSearch} />
                    </div>
                    <div style={{ ...styles.Block, height: 20, width: undefined }}>
                    </div>
                    <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{lang.product_quick_search}</p>
                </div>
                <FormProduct excluded={props.excluded} price_list={props.price_list == undefined ? undefined : props.price_list} placeholder={lang.product_quick_search_} editing={true} title={''} column1={0} column2={350} theme={props.theme} lang={lang} radius={props.radius} locale={props.locale} organization={props.organization} func={(item) => Result(item)} />
                <Box sx={{ width: 340, height: 5 }}>
                    {isBusy ?
                        <LinearProgress />
                        : null}
                </Box>
            </div>
        </div>
    );
}

export const GetReserve = (props) => {

    /*
    
        <GetReserve quantity={item.quantity} unit={item.unit} lang={props.lang} theme={props.theme}/>
 
    */

    let color = global.themes[props.theme];


    return (
        <Chip
            size='small'
            variant='outlined'
            style={{ color: color.dark_gray }}
            label={props.lang.product_reserved_ + ': ' + FormatPieces(props.quantity, 0) + ' ' + props.unit}>
        </Chip>
    );
}

export const DialogGPS = (props) => {
    /*
                                Hladanie GPS podla zadanej adresy

                                */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    // UDAJE
    const [address, setAddress] = useState('');
    const [addressResult, setAddressResult] = useState('');
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [showMap, setShowMap] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 600;
    const dialogHeight = 400;

    let column1 = props.column1 == undefined ? 120 : props.column1;
    let column2 = props.column2 == undefined ? dialogWidth - column1 - 40 : props.column2;

    let { func } = props;
    const inputRef = useRef(null);
    const color = global.themes[props.theme];
    let lang = props.lang;

    var running = false;
    useEffect(() => {

        // FOCUS TO TEXTAREA
        if (props.percentage == true) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 300);
        }

        return () => {
        };

    }, []);


    const db_gps = async (address) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_get_gps', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    address: address
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.address != false) {
                    setAddressResult(json.address);
                    setLat(json.lat);
                    setLng(json.lng);
                } else {
                    setError(lang.gps_not_found);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = () => {
        func(true, addressResult, lat, lng);
    }

    const GetGPS = () => {
        var err = 0;

        setError('');
        setAddressResult('');
        setLat(0);
        setLng(0);

        err = IsEmpty(address) == true ? 1 : 0;

        if (err == 0) {
            db_gps(address);
        } else {
            setError(lang.required_red);
        }

    }

    const Close = () => {
        func(false, false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={props.icon == undefined ? faInfo : props.icon} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.gps_coordinates_from_address}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 130, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '98%' }}>
                        <p style={{ ...styles.TextXSmall, marginBottom: 5 }}><b>{lang.gps_coordinates_search}:</b></p>
                        <FormText require inputRef={inputRef} placeholder={lang.gps_coordinates_search_} value={address} editing={true} title={undefined} column1={undefined} column2={column2} theme={props.theme} lang={props.lang} radius={props.radius} func={(txt) => setAddress(txt)} />
                        <div style={{ ...styles.Block, height: 90 }}>
                            {addressResult != '' ?
                                <>
                                    <p style={{ ...styles.TextTiny }}><b>{lang.gps_coordinates_address}:</b></p>
                                    <p style={{ ...styles.TextXSmall, textAlign: 'center', marginTop: 5 }}>{addressResult}</p>
                                    <p style={{ ...styles.TextXSmall, textAlign: 'center', marginTop: 2 }}>{lat.toFixed(5)}, {lng.toFixed(5)}</p>
                                </>
                                : null}
                        </div>
                        <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                            <Button onClick={() => GetGPS()} style={{ ...styles.ButtonOutlined }}>
                                <FontAwesomeIcon style={{ height: 14, marginRight: 10 }} icon={faSearch} />
                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.search}</p>
                            </Button>
                            {parseFloat(lat) > 0 && parseFloat(lng) > 0 ?
                                <Button onClick={() => setShowMap(true)} style={{ ...styles.ButtonOutlined, marginLeft: 20 }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.gps_show_map}</p>
                                </Button>
                                : null}
                        </div>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 130 }}>
                    <div style={{ ...styles.Block, height: 50 }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: 80 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {parseFloat(lat) > 0 && parseFloat(lng) > 0 ?
                                <Button onClick={() => Press()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {showMap == true ?
                <GoogleMap gps={[{ lat: lat, lng: lng }]} lang={props.lang} theme={props.theme} radius={props.radius} func={() => setShowMap(false)} />
                : null}

        </Backdrop>
    );
}



export const DialogProtocol = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogProtocol title={lang.info} protocol={protocol} button={lang.close} theme={props.theme} radius={props.radius} func={() => setShowProtocol(false)} />
            */

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 600 ? 600 : height;

    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);


    const Close = () => {
        func(false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faFileLines} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 80, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockLeft, width: '96%', marginTop: 20 }}>
                        <p style={{ ...styles.TextXSmall, wordBreak: 'break-all' }}>{props.protocol}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 80 }}>
                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{props.button}</Button>
                </div>
            </Paper>
        </Backdrop >
    );
}


export const DialogChooseTermin = (props) => {
    /*
            VYBER OBDOBIA

            USAGE:
            <DialogChooseTermin lang={lang} theme={props.theme} radius={props.radius} func={TerminResult.bind(this)} />

            ------------------------------------------------------------
            date    -> výber konkrétneho dňa

            const TerminResult = (typ, date_start, date_end) => {
                
            }
    */

    const lang = props.lang;

    // OBDOBIE - ktoré záznamy sa zobrazia
    const periods = [
        { id: 0, name: lang.termin_periodes[0] },
        { id: 1, name: lang.termin_periodes[1] },
        { id: 2, name: lang.termin_periodes[2] },
        { id: 3, name: lang.termin_periodes[3] },
        { id: 4, name: lang.termin_periodes[4] },
        { id: 5, name: lang.termin_periodes[5] },
    ]

    const [dateStart, setDateStart] = useState(Today());
    const [value, setValue] = useState(-1);
    const [showDate, setShowDate] = useState(false);
    const [date, setDate] = useState(Today());

    const [width, setWidth] = useState(window.innerWidth);

    const buttonHeight = 80;
    const dialogWidth = 500;
    const dialogHeight = 200 + buttonHeight + (props.date == true ? 50 : 0);


    let { func } = props;

    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = () => {
        var result = dateStart;
        if (value == 5) {
            result = date;
        }
        func(true, dateStart, value == -1 ? '' : value == 5 ? GetDatum2(result) : lang.termin_periodes[value]);
    }

    const ChangePeriod = (id) => {
        var date_start = Today();

        if (id == 0) {
            // DNES
            date_start = Today();
        }
        if (id == 1) {
            // VCERA
            date_start = getYesterday();
        }
        if (id == 2) {
            // TENTO MESIAC            
            date_start = GetTodayYearMonth();
        }
        if (id == 3) {
            // TENTO ROK
            date_start = GetTodayYear();
        }
        if (id == 4) {
            // VSETKY ZAZNAMY
            date_start = false;
        }
        if (id == 5) {
            // VSETKY ZAZNAMY
            setShowDate(true);
        } else {
            setShowDate(false);
        }
        setDateStart(date_start);
        setValue(id);
    }

    function getYesterday() {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const year = yesterday.getFullYear();
        const month = String(yesterday.getMonth() + 1).padStart(2, '0');
        const day = String(yesterday.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faCalendarDays} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.plan_choose_termin}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => func(false)} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <FormSpace />
                    <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.plan_choose_termin_}</p>
                    <SelectInput default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={value} items={periods} width={250} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => ChangePeriod(id)} />
                    {showDate == true ?
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <TextInput redraw center date lang={props.lang} value={date} label={lang.date} width={160} func={(txt) => setDate(txt)} />
                        </div>
                        : null}
                </div>
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <Button onClick={() => Press()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.ok}</Button>
                        <Button onClick={() => func(false)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, marginLeft: 20 }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}

export const DialogQRCode = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogQRCode title={'Informácia'} value={'https://datadream.sk'}theme={props.theme} radius={props.radius} func={QRCodeResult.bind(this)} />

            const DialogInfoResult = (value) => {
                console.log(value)
            }
            */

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 800 > width ? width : 800;
    const dialogHeight = 800 > height ? 800 : 800;

    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);


    const Close = () => {
        func(false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faQrcode} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <QRCodeSVG value={props.value} size={dialogHeight - global.dialog_bar_height - 140} />
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{props.lang.close}</Button>
                </div>
            </Paper>
        </Backdrop >
    );
}