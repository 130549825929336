/*

    DATABASE: xb036300

*/

import img_background1 from './react/app/background.jpg';
import img_menu1 from './react/app/background_menu.jpg';


global.themes = [
    {
        id: 0,
        background_image: img_background1,
        menu_image: img_menu1,
        title_card: '#BBD6FD', // Hlavná obrazovka title user card
        desktop_background: '#D0D0D0', // POZADIE APLIKACIE
        desktop_window: '#FFFFFFA0', // POZADIE DESKTOPOVYCH PRVKOV
        top_bar: 'linear-gradient(to right,#FFFFFF,#D8D8D8)', // farba TOP MENU
        menu_info: '#102A3C', // Info o uzivatelovi
        menu_select: '#4BB9FF',
        menu_select_border: '#FF7700',
        menu_background: 'linear-gradient(45deg, #10293C 0%, #2F8EB1 100%)',
        dialog_background: 'linear-gradient(220deg, #FFFFFF 0%, #92BBEC 66%)',
        window_background: 'linear-gradient(220deg, #FFFFFFFF 0%, #92BBECFF 66%)',
        window_bar_color: 'linear-gradient(to right,#D9E5EC,#5E94B4)',
        window_bar_color_light: 'linear-gradient(to right,#D9E5EC77,#5E94B477)',
        window_bar_color_ai: 'linear-gradient(to right,#D9E5EC77,#5E94B4A0)',
        status_bar: '#A0A7B0',
        icon_background: 'linear-gradient(135deg,#5E94B4,#1C5B80)',
        shortcut_background: 'linear-gradient(220deg,#0C346B,#205CCA)',
        line: 'linear-gradient(220deg, #5F7AA100 0%, #5F7AA1FF 50%, #5F7AA100 100%)',
        logo_background: '#D0DDEF',
        rainbow: 'linear-gradient(220deg, #FF2222 50%, #0077FF 50%)',
        presentation: 'linear-gradient(135deg, #000000 0%, #002244 100%)',
        back: '#F0F0F0',
        error: '#CA0000',
        ok: '#15A115',

        // SUB-MENU
        sub_menu_back: 'linear-gradient(220deg, #B0CAE5 0%, #B0CAE5 66%)',
        sub_menu_border: '#0066FF',

        // THEMED COLORS
        lighteen: '#D7E8FF',
        lighter: '#BBD6FD',
        light: '#95B2DC',
        medium: '#5F7AA1',
        dark: '#3D5273',
        darker: '#29364A',

        lighteen_blue: '#E2ECFF',
        lighter_blue: '#92BBEC',
        lighter_blue_cyan: '#D9E5EC',
        light_blue: '#2A8EFF',
        blue: '#205CCA',
        dark_blue: '#003BA4',
        darker_blue: '#041A47',

        lighteen_red: '#FFE4E2',
        lighter_red: '#F99891',
        light_red: '#F77268',
        red: '#F44336',
        dark_red: '#C3170B',
        darker_red: '#801000',

        lighteen_green: '#E2FFE3',
        light_green: '#A6FA9D',
        green: '#46F436',
        dark_green: '#18BB18',
        darker_green: '#15A115',
        darkest_green: '#107010',

        yellow: '#FFDD7A',
        badge: '#A12E28',
        placeholder: '#cccccc',

        grayer: '#f7f7f7',
        gray: '#eeeeee',
        light_gray: '#dedede',
        xxgray: '#CCCCCC',
        middle_gray: '#A0A0A0',
        medium_gray: '#888888',
        dark_gray: '#666666',
        darker_gray: '#252525',

        lighteen_violet: '#E9E2FF',
        light_violet: '#FFAAFF',
        violet: '#BB00BB',
        orange: 'orange',
        dark_orange: '#D47300',
        light_cyan: '#80A0FF',
        cyan: 'cyan',
        dark_cyan: '#1080B0',
        brown: 'brown',

        white: '#FFFFFF',
        black: '#000000',

        // BUTTONS
        button_light: '#92C2E7',
        button_medium: '#226AA3',
        button_dark: '#103450',

        button_ok: '#103450',
        button_cancel: '#226AA3',
        button_add: '#0080E6',
        button_add_2: '#800080',
        button_gray: '#DDDDDD',
        button_dark_gray: '#707070',
        button_action: '#92C2E7',
        button_action_in_bar: '#527AA7',
        button_delete: '#AA0000',
        button_finalize: '#A00000',
        button_ai: '#0044AA',
        button_selected: '#CFE0FF',
        button_selected_dark: '#80A0C0',

        // Conditions - designer
        conditions: '#005FA9',

        // NEAKTIVNA POLOZKA - farba pozadia
        disabled: '#D0D0D0',
        disabled_border: '4px solid #FF0000',

        // VARIATION COLOR - product variant
        variation: '#F2FAFF',
        border_variation: '4px solid #A0E0FF',

        // GROUPED COLOR - produkt balíček
        grouped: '#FFF7FF',
        border_grouped: '4px solid #FFA0FF',


        // Super Admin
        super_admin: '#FF7700',

        // default border
        border: '1px solid #dedede',

        // hlavný sklad
        stock: '4px solid #A0E0FF',

        // info dialog
        info: '#BBDDFF',

        // NOTE - farba ciary, farba pozadia
        note_line: '#FF7700',
        note: '#FFFFFF',

        // forms
        form_line: '#dedede',

        // ORDERS
        order_created: '4px solid #FF0000',
        order_in_proccess: '4px solid #00A000',

        graph_bars_1: '#0077FF',
        graph_bars_2: '#FF7700',

        // TABULKA
        table_row: '#AADDFF',

        //MOJE ZOZNAME UZIVATELOV
        user_highlight: '#AADDFF',


        // KALENDAR
        day_highlight: '#70A0F0',
        today_highlight: '#F0F7FF',
        today_termins: '#C0C0C0',
        today_color: '#004488',
        calendar_navi_buttons: '#00000000',
        calendar_new: '#C3170B',

        // dashboard
        dashboard: '#FFFFFFA0',
        window_bar_color_light: 'linear-gradient(to right,#D6E6FE,#D6E6FE)',

        dashboard_blue: '#B4E0FF',
        dashboard_red: '#FFB5B4',
        dashboard_green: '#B4FFC4',
        dashboard_orange: '#FFDBB4',
        dashboard_violet: '#FFD0FF',
    }
]

