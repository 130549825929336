/*
*
* =================================================================================================================
* SKLADY PRODUKTOV

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormNote, FormSelect, DialogEnter } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, IconButton, Pagination, Paper, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faInfoCircle, faLayerGroup, faLocationDot, faMagnifyingGlass, faPaperPlane, faPlusCircle, faQuestion, faSearch, faTag, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, GetLeftPosition, GetPages, IsEmpty } from './functions';
import { Photos } from './photos';
import { filterStateInitializer } from '@mui/x-data-grid/internals';
import { DistributorsCodes } from './distributors_export.js';

export const Stocks = (props) => {

    let module_id = global.model.stocks;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'stocks';

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        country_id: props.locale.id
    }

    // TYPY SKLADOV
    let stock_types = [
        { id: -1, name: lang.warehouses_all },
        { id: 0, name: lang.warehouse_types_[0] },
        { id: 1, name: lang.warehouse_types_[1] },
        { id: 2, name: lang.warehouse_types_[2] },
    ];

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'code', label: lang.stock_code, typ: 'text' },
        { id: 2, column: 'name', label: lang.stock_name, typ: 'text' },
        { id: 3, column: 'street', label: lang.street, typ: 'text' },
        { id: 4, column: 'town', label: lang.town, typ: 'text' },
        { id: 5, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 6, column: 'state', label: lang.state, typ: 'text' },
        { id: 7, column: 'phone', label: lang.phone, typ: 'phone' },
        { id: 8, column: 'mobil', label: lang.mobil, typ: 'phone' },
        { id: 9, column: 'email', label: lang.email, typ: 'text' },
        { id: 10, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [type, settype] = useState(-1);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(global.items_max);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [stockDefault, setStockDefault] = useState(props.system.stock_default);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.stock_name, default: true },
        { id: 2, enabled: true, checked: false, column: 'town', label: lang.town },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showAskStocks, setShowAskStocks] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 86;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const menuWidth = global.sub_menu_width;

    let { func } = props;
    var running = false;

    useEffect(() => {

        if (running == false) {

            // UDAJE Z DB
            settype(-1);
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);

    const db_get = async (page, filter, maxItems) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stocks', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_system_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.settings,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.stocks,
                    filename: lang.stocks, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // ZMENA DEFAULT SKLADU
    // -----------------------------------
    const db_stock_default = async (stock_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_default', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    stock_id: stock_id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GetFilter = () => {

        var filter = defaultFilter;

        if (type > -1) {
            filter.type = type
        }

        return (filter);
    }

    const Search = (txt) => {
        var tmp = items;
        if (txt.length > 0) {
            let find = txt.toLowerCase();
            tmp = items.filter(x => x.code.toLowerCase().includes(find) || x.name.toLowerCase().includes(find));

            if (tmp.length == 0) {
                tmp = false;
            }
        }
        setItemsFiltered(tmp);
        setSearch(txt);

    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' }, "start");
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const ChangeEnabled = (item, value) => {
        // ENABLED POLOZKY ZMENENY

        var tmp = items.filter(x => x.enabled == true);

        if ((tmp.length > 1 && value == false) || value == true) {

            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: item.id,
                action: value == true ? global.logs.enabled : global.logs.disabled
            };

            let data = {
                id: item.id,
                enabled: value,
                logs: log
            }
            db_update(data);
        }
    }

    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // NAZOV
                column = 'name';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;
        }

        var filter = {
            ordering: order,
            order_column: column
        };
        if (search != '') {
            filter.search = search;
        }
        db_get(0, filter, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const ChangeDefaultStock = (item, value) => {
        var tmp = items;
        tmp.forEach(item => {
            item.default_stock = false
        });
        item.default_stock = true;

        setItems(tmp);

        db_stock_default(item.id);
    }

    const EnableStocks = (value) => {
        setShowAskStocks(false);

        if (value == true) {
            let data = {
                id: props.system.id,
                stocks: true
            }
            db_system_update(data);
        }
    }

    const ChangeType = (id) => {
        var filter = GetFilter();
        if (id > -1) {
            filter.type = id;
        } else {
            delete (filter.type);
        }
        settype(id);
        db_get(page, filter, maxItems);

    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    {global.appstore == false ?
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                        : null}
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div id={'id_start'} style={{ ...styles.Block }}></div>
                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%', marginTop: 10 }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '30%', height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <TextInput search theme={props.theme} width={'90%'} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        {/*
                                            <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                                <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                            </div>
                                            */}
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockRight, width: '30%', height: menuHeight }}>
                                    <SelectInput redraw theme={props.theme} enabled={true} lang={lang} value={type} items={stock_types} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => ChangeType(id)} />
                                </div>

                                <div style={{ ...styles.BlockRight, width: '40%', height: menuHeight }}>
                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.stock_new}
                                        </Button>
                                        : null}
                                </div>
                            </div>

                            <div style={{ ...styles.Block, marginTop: 20 }}>

                                {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.Block, marginBottom: 10, border: color.border }}>
                                    <div style={{ ...styles.BlockRow, width: '99%' }}>
                                        <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center' }}>
                                            {/* SELECT */}
                                            <div style={{ ...styles.Block, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: '72%' }}>
                                            {/* DATA */}
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '60%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, width: '95%', height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.stock}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '20%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.Block, width: 100, height: tableLabelHeight, borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.stock_default}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: 100, borderLeft: color.border }}>
                                            {/* ENABLED */}
                                            <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.enabled}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* ****************************************************************************************************************
                                        TABLE DATA
                                **************************************************************************************************************** */}

                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowHeight,
                                            cursor: 'pointer',
                                            marginTop: 5, marginBottom: 5,
                                            paddingTop: 2, paddingBottom: 2,
                                            borderLeft: item.enabled == false ? color.disabled_border : stockDefault == item.id ? color.stock : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                                            <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                {/* SELECT */}
                                                <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                            </div>

                                            <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, width: '72%' }}>
                                                {/* DATA */}
                                                <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <Avatar picture image={item.avatar} size={rowHeight - 20} theme={props.theme} />
                                                </div>

                                                <div style={{ ...styles.BlockLeft, width: '60%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextXTiny }}>{item.code}</p>
                                                    <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: '25%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.warehouse_types[item.type]}</p>

                                                    {item.shared == true ?
                                                        <Chip variant='outlined' size='small' style={{ borderColor: color.dark_gray }} label={<p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{lang.stock_shared}</p>}></Chip>
                                                        : null}
                                                </div>
                                            </div>
                                            <div style={{ ...styles.Block, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                    item.enabled == true ?
                                                        <SwitchEnable disabled={item.default_stock == true} enabled={item.default_stock == true} theme={props.theme} func={(value) => ChangeDefaultStock(item, value)} />
                                                        : null : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                {/* ENABLED */}
                                                {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                    <SwitchEnable enabled={item.enabled == false ? false : true} theme={props.theme} func={(value) => ChangeEnabled(item, value)} />
                                                    : null}
                                                {item.enabled == false ?
                                                    <div style={{ ...styles.Block, height: 20 }}>
                                                        <p style={{ ...styles.TextXXTiny, color: color.dark_gray }}>{lang.disabled}</p>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <EmptyList row small lang={lang} />
                                }

                                {/* ****************************************************************************************************************
                                        PAGINATION
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 200 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


            {showAskStocks == true ?
                <DialogYesNo remote={props.remote} remoteMobile={props.remoteMobile} theme={props.theme} radius={props.radius} title={lang.stocks} text={lang.stocks_enabled_ask} sub_text={lang.stocks_disabled_note} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={EnableStocks.bind(this)} />
                : null}

            {showExport == true ?
                <DialogExport remote={props.remote} remoteMobile={props.remoteMobile} rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <Stock remote={props.remote} remoteMobile={props.remoteMobile} module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}



/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const Stock = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let distributor_searching = props.system.pharminfo_enabled == true || props.system.pharmos_enabled == true ? true : false;

    // TYPY SKLADOV
    let stock_types = [
        { id: 0, name: lang.warehouse_types[0] },
        { id: 1, name: lang.warehouse_types[1] },
        { id: 2, name: lang.warehouse_types[2] },
    ];


    // TYPY EXPORTOV OBJEDNAVOK
    let stock_export_types = [
        { id: 0, enabled: true, name: lang.stock_export_types[0] },
        { id: 1, enabled: true, name: lang.stock_export_types[1] },
        { id: 2, enabled: props.system.pharminfo_enabled == true ? true : false, name: lang.stock_export_types[2] },
        { id: 3, enabled: props.system.pharmos_enabled == true ? true : false, name: lang.stock_export_types[3] },
    ];

    // EXPORT OBJEDNAVOK - kody pouzite v produktoch
    let stock_product_codes = [
        { id: 0, enabled: true, name: lang.stock_export_product_codes[0] },
        { id: 1, enabled: true, name: lang.stock_export_product_codes[1] },
        { id: 2, enabled: props.system.product_codes[0].enabled == true ? true : false, name: props.system.product_codes[0].label },
        { id: 3, enabled: props.system.product_codes[1].enabled == true ? true : false, name: props.system.product_codes[1].label },
        { id: 4, enabled: props.system.product_codes[2].enabled == true ? true : false, name: props.system.product_codes[2].label },
        { id: 5, enabled: props.system.product_codes[5].enabled == true ? true : false, name: props.system.product_codes[5].label },
    ];

    // EXPORT OBJEDNAVOK - kody pouzite v produktoch
    let stock_customer_codes = [
        { id: 0, enabled: true, name: lang.stock_export_customer_codes[0] },
        { id: 1, enabled: props.system.customers_codes[0].enabled == true ? true : false, name: props.system.customers_codes[0].label },
        { id: 2, enabled: props.system.customers_codes[1].enabled == true ? true : false, name: props.system.customers_codes[1].label },
        { id: 3, enabled: props.system.customers_codes[2].enabled == true ? true : false, name: props.system.customers_codes[2].label },
        { id: 4, enabled: props.system.customers_codes[3].enabled == true ? true : false, name: props.system.customers_codes[3].label },
    ];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [enabled, setEnabled] = useState(true);
    const [type, setType] = useState(0);
    const [shared, setShared] = useState(false);
    const [countries, setCountries] = useState(props.organization.currencies == false ? [] : props.organization.currencies);
    const [countryID, setCountryID] = useState(props.locale.id);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [street, setStreet] = useState('');
    const [town, setTown] = useState('');
    const [psc, setPSC] = useState('');
    const [state, setState] = useState('');
    const [phone, setPhone] = useState('');
    const [mobil, setMobil] = useState('');
    const [email, setEmail] = useState('');
    const [note, setNote] = useState('');
    const [avatar, setAvatar] = useState('');
    const [showTransactions, setShowTransactions] = useState(false);

    // EXPORT OBJEDNAVOK - ODOSIELANIE
    const [exportTyp, setExportTyp] = useState(0);
    const [exportEmail, setExportEmail] = useState('');
    const [exportProductCode, setExportProductCode] = useState(0);
    const [exportCustomerCode, setExportCustomerCode] = useState(0);
    const [exportCode, setExportCode] = useState('');
    const [exportPrices, setExportPrices] = useState(false);
    const [showDistributorCodes, setShowDistributorCodes] = useState(false);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // ZOZNAM SKLADOV
    const [stocks, setStocks] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showAskCancelStock, setShowAskCancelStock] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 650 ? 650 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = props.item == false ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 160;
    const column2 = dialogWidth - 60 - column1;

    const tabs = [
        { id: 0, enabled: true, label: lang.setting },
        { id: 2, enabled: true, label: lang.stock_export },
        { id: 1, enabled: true, label: lang.history },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
                db_get_data();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.stocks;
                if (tmp != false) {
                    tmp = tmp.filter(x => x.id != props.item.id);
                    if (tmp.length == 0) {
                        tmp = false;
                    }
                }
                setStocks(tmp);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_cancel = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_cancel', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    stock_id: props.item.id,
                    stock_to: id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setType(item.type);
        setShared(item.shared == false ? false : true);
        setCountryID(item.country_id);
        setName(item.name);
        setCode(item.code);
        setStreet(item.street);
        setTown(item.town);
        setPSC(item.psc);
        setState(item.state);
        setMobil(item.mobil);
        setPhone(item.phone);
        setEmail(item.email);
        setNote(item.note);
        setAvatar(item.avatar);
        setShowTransactions(item.show_transactions == false ? false : true);
        setExportTyp(parseInt(item.export_typ));
        setExportEmail(item.export_email);
        setExportProductCode(item.export_product_code);
        setExportCustomerCode(item.export_customer_code);
        setExportCode(item.export_code);
        setExportPrices(item.export_prices == false ? false : true);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setError('');
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.enabled = enabled;
        storeData.type = type;
        storeData.shared = shared;
        storeData.country_id = countryID;
        storeData.name = name;
        storeData.code = code;
        storeData.street = street;
        storeData.town = town;
        storeData.psc = psc;
        storeData.state = state;
        storeData.mobil = mobil;
        storeData.phone = phone;
        storeData.email = email;
        storeData.note = note;
        storeData.avatar = avatar;
        storeData.show_transactions = showTransactions;
        storeData.export_typ = exportTyp;
        storeData.export_email = exportEmail;
        storeData.export_product_code = exportProductCode;
        storeData.export_customer_code = exportCustomerCode;
        storeData.export_code = exportCode;
        storeData.export_prices = exportPrices;
    }

    const Save = () => {
        setError('');
        var error = false;

        if (tabIndex == 0) {
            error += IsEmpty(name) == true ? 1 : 0;
        }

        if (tabIndex == 2) {
            if (exportTyp == 1) {
                error += IsEmpty(exportEmail) == true ? 1 : 0;
            }
            if (exportTyp == 2 || exportTyp == 3) {
                error += IsEmpty(exportCode) == true ? 1 : 0;
            }
        }

        if (error == 0) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: props.item == false ? 0 : props.item.id,
                action: props.item == false ? global.logs.created : storeData.enabled != enabled ? enabled == true ? global.logs.enabled : global.logs.disabled : global.logs.modified
            };

            let data = {
                id: props.item == false ? 0 : props.item.id,
                organization_id: props.organization.id,
                enabled: enabled,
                type: type,
                shared: shared,
                show_transactions: showTransactions,
                country_id: countryID,
                name: name.trim(),
                code: code,
                street: street,
                town: town,
                psc: psc,
                state: state,
                mobil: mobil,
                phone: phone,
                email: email,
                note: note,
                avatar: avatar,
                export_typ: exportTyp,
                export_email: exportEmail,
                export_product_code: exportProductCode,
                export_customer_code: exportCustomerCode,
                export_code: exportCode,
                export_prices: exportPrices,
                logs: log
            };

            StoreEditing();

            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ShowOKPress = () => {
        func(true);
    }

    const ChangeTab = (id) => {
        setError('');
        setTabIndex(id);

        if (id == 1) {
            if (logs == false) {
                db_get_logs();
            }
        }
    }


    const AvatarPress = () => {
        setSelectPhoto(true);
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);
        }
    }

    const StockCancel = (typ, value) => {
        setShowAskCancelStock(false);

        if (typ == true) {
            db_cancel(value);
        }
    }

    const ChangeExportCode = (txt, button) => {
        if (button == true) {
            setShowDistributorCodes(true);
        } else {
            setExportCode(txt);
        }
    }

    const DistributorCodesResult = (typ, item) => {
        setShowDistributorCodes(false);

        if (typ == true) {
            Debug(item);
            setExportCode(item.uniqueName);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.stock_new : lang.stock}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    {props.item != false ?
                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs disabled={editing} value={tabIndex} items={tabs} width={dialogWidth / 3} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        /*
                        *
                        * =================================================================================================================
                        * NASTAVENIA
                        * =================================================================================================================
                        *
                        */

                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {storeData != false ?
                                <div>
                                    <FormSpace />
                                    <div style={{ ...styles.Block }}>
                                        <Avatar editable={editing} picture image={avatar} size={75} theme={props.theme} func={AvatarPress.bind()} />
                                        <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.default_image}</p>
                                    </div>
                                    <FormSpace />
                                    <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                    <FormYesNo value={enabled} editing={editing} title={lang.stock_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                    <FormYesNo value={shared} editing={editing} title={lang.stock_shared} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setShared(value)} />
                                    <FormNote title={lang.stock_shared_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormYesNo value={showTransactions} editing={editing} title={lang.stock_show_transactions} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setShowTransactions(value)} />
                                    <FormNote title={lang.stock_show_transactions_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />

                                    <FormSpace />
                                    <FormSelect require value={type} items={stock_types} field={'name'} editing={editing} title={lang.warehouse_type} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setType(id)} />
                                    <FormSelect require value={countryID} items={countries} field={'state'} editing={editing} title={lang.app_country} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCountryID(id)} />

                                    <FormSpace />
                                    <FormText redraw value={code} editing={editing} title={lang.stock_code} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCode(txt)} />
                                    <FormNote title={lang.stock_code_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormText require redraw value={name} editing={editing} title={lang.stock_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />

                                    {type == 2 ?
                                        <div style={{ ...styles.Block }}>
                                            <FormSpace />
                                            <FormLabel editing={editing} editButton={false} icon={faLocationDot} title={lang.address} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                            <FormText redraw value={street} editing={editing} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setStreet(txt)} />
                                            <FormText redraw value={town} editing={editing} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setTown(txt)} />
                                            <FormText redraw value={psc} editing={editing} title={lang.psc} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPSC(txt)} />
                                            <FormText redraw value={state} editing={editing} title={lang.state} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setState(txt)} />

                                            <FormSpace />
                                            <FormLabel editing={editing} editButton={false} title={lang.contact_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                            <FormText redraw value={mobil} editing={editing} title={lang.mobil} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setMobil(txt)} />
                                            <FormText redraw value={phone} editing={editing} title={lang.phone} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPhone(txt)} />
                                            <FormText redraw value={email} editing={editing} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />

                                            <FormSpace />
                                            <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />

                                        </div>
                                        : null}


                                    {editing == false && enabled == true ?
                                        CheckPermission(props.user.super_admin, props.permissions[permission_column]).delete == true ?
                                            <div style={{ ...styles.Block }}>
                                                <FormSpace height={30} />
                                                <Button onClick={() => setShowAskCancelStock(true)} style={{ ...styles.ButtonOutlined }}>{lang.stock_cancel}</Button>
                                            </div>
                                            : null : null}
                                    <FormSpace height={global.empty_padding} />

                                    {props.remote == true && props.remoteMobile == true ?
                                        <FormSpace height={400} />
                                        : null}

                                </div>
                                :
                                <Stack style={{ ...styles.Block }} spacing={1}>
                                    <FormSpace />
                                    <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                </Stack>}
                        </div>
                        : null}

                    {tabIndex == 2 ?
                        /*
                        *
                        * =================================================================================================================
                        * EXPORT OBJEDNAVOK
                        * =================================================================================================================
                        *
                        */

                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {storeData != false ?
                                <div>
                                    <FormSpace />
                                    <div style={{ ...styles.Block }}>
                                        <p style={{ ...styles.TextXSmall }}><b>{lang.dist_auto_sending}</b></p>
                                    </div>
                                    <FormSpace height={30} />
                                    <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} icon={faPaperPlane} title={lang.stock_export_label} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                    <FormSelect require value={exportTyp} items={stock_export_types.filter(x => x.enabled == true)} field={'name'} editing={editing} title={lang.stock_export_typ} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setExportTyp(id)} />
                                    <FormSpace height={40} />

                                    {exportTyp > 0 ?
                                        // ODOSIELANIE OBJEDNAVOK EMAILOM
                                        <div style={{ ...styles.Block }}>
                                            <FormYesNo value={exportPrices} editing={editing} title={lang.stock_export_prices} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setExportPrices(value)} />
                                            <FormNote title={lang.stock_export_prices_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                            <FormText require={exportTyp == 1 ? true : undefined} redraw value={exportEmail} editing={editing} title={lang.stock_export_email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setExportEmail(txt)} />
                                            <FormNote title={exportTyp == 1 ? lang.stock_export_email_note : lang.stock_export_email_note_} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                            <FormSpace />
                                        </div>
                                        : null}

                                    {exportTyp == 2 || exportTyp == 3 ?
                                        // KOD DISTRIBUTORA
                                        <div style={{ ...styles.Block }}>
                                            <FormText redraw require value={exportCode} editing={editing} button={distributor_searching == true ? true : undefined} button_icon={faMagnifyingGlass} title={lang.distribute_code} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeExportCode(txt, press)} />
                                            <FormNote title={lang.distribute_code_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                        </div>
                                        : null}

                                    {exportTyp > 0 ?
                                        // NASTAVENIA KODOV PRODUKTOV A ZAKAZNIKOV - ktorý kod bude pouzitý pri exporte
                                        <div style={{ ...styles.Block }}>
                                            <FormSelect require value={exportProductCode} items={stock_product_codes.filter(x => x.enabled == true)} field={'name'} editing={editing} title={lang.stock_export_product_code} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setExportProductCode(id)} />
                                            <FormNote title={lang.stock_export_product_code_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />

                                            <FormSelect require value={exportCustomerCode} items={stock_customer_codes.filter(x => x.enabled == true)} field={'name'} editing={editing} title={lang.stock_export_customer_code} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setExportCustomerCode(id)} />
                                            <FormNote title={lang.stock_export_customer_code_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                        </div>
                                        : null}

                                    {props.remote == true && props.remoteMobile == true ?
                                        <FormSpace height={400} />
                                        : null}

                                </div>
                                :
                                <Stack style={{ ...styles.Block }} spacing={1}>
                                    <FormSpace />
                                    <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                </Stack>}
                        </div>
                        : null}

                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {tabIndex != 1 && editing == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            {editing == true && props.item != false ?
                                <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                :
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                            }
                        </div>
                    </div>
                </div >

                {showDistributorCodes == true ?
                    <DistributorsCodes distributor remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={DistributorCodesResult.bind(this)} />
                    : null}

                {showAskCancelStock == true ?
                    <DialogEnter selectable bold close items={stocks} value={-1} title={lang.stock_cancel_} text={lang.stock_cancel_text} sub_text={lang.stock_cancel_sub_text} note={lang.stock_cancel_note} label={lang.stock_choose} icon={faTrash} button={props.lang.stock_cancel} theme={props.theme} radius={props.radius} lang={props.lang} column1={120} column2={300} func={StockCancel.bind(this)} />
                    : null}

                {selectPhoto == true ?
                    <Photos autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}




export const StockSelect = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = {
        enabled: true,
        ordering: global.order.down,
        order_column: 'name',
        country_id: props.locale.id
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DATA
    const [value, setValue] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 700 ? 700 : height;

    const menuHeight = 60;
    const rowHeight = 66;
    const viewWidth = '96%';

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter);

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stocks', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems));

                setFilter(filter_);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = defaultFilter;

        if (txt.length > 1) {

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = ['name'];
            db_get(0, filter);
        }
        if (txt == '') {
            db_get(0, filter);
        }

    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        func(true, item)
    }

    const NodeSelectResult = (id) => {
        var tmp = items.find(x => x.id == id);
        if (tmp != undefined) {
            func(true, tmp);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.stocks}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start'} style={{ ...styles.BlockLeft, width: viewWidth }}>

                            {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    onClick={() => SelectItem(item)}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                                        <div style={{ ...styles.BlockLeft, width: rowHeight + 6, height: rowHeight }}>
                                            <Avatar picture image={item.avatar} size={rowHeight - 6} theme={props.theme} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '87%', minHeight: rowHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name}</p>
                                            <div style={{ ...styles.BlockLeft, maxHeight: 20, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.street} {item.town}{item.town != '' && item.state != '' ? ', ' : ' '}{item.state}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div >

                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


