/*
*
* =================================================================================================================
* WINDOW

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { Colors, DialogImagePreview, DialogInfo, DialogLogin, DialogTabs, DialogVideo, DialogYesNo, EmployeeSelect, EmptyList, Icon, Loading, Menu, MenuTitle, MenuTop, SelectInput, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Button, IconButton, Pagination, Paper, Tooltip, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faLayerGroup, faList, faPen, faPlusCircle, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetDatumTime2, GetTodayYear } from './functions_date';
import { CheckPermission, Debug, GetPages } from './functions';
import { ConditionProducts } from './conditions.js';
import { ConditionDesigner } from './condition_designer.js';

export const ConditionsList = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'conditions';

    const modules = [
        { id: global.model.products, name: lang.products },
        { id: global.model.price_list, name: lang.price_lists },
    ];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // VALUES
    const [moduleID, setModuleID] = useState(-1);

    // DIALOG
    const [showCondition, setShowCondition] = useState(false);
    const [showProducts, setShowProducts] = useState(false);


    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 0;
    const menuHeight = 50;
    const rowHeight = 80;
    const menuWidth = global.sub_menu_width;
    const tableHeight = 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            //db_get(0, false, 0);

            running = true;
        }

    }, []);


    const db_get = async (page, filter, module_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'conditions', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    model_id: global.model.organizations,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);
        if (txt.length > 1) {
            let filter = { search: txt };
            db_get(0, filter, moduleID);
        }
        if (txt == '') {
            db_get(0, false, moduleID);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter, moduleID);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const ChangeModule = (id) => {
        setModuleID(id);
        setSearch('');
        db_get(0, false, id);
    }

    const SelectItem = (item) => {

    }

    const EditItem = (item) => {
        setItemSelected(item);
        setShowCondition(true);
    }

    const ConditionResult = (typ, item) => {
        setShowCondition(false);
        if (typ == true) {
            db_get(0, filter, moduleID);
        }
    }

    const AddNew = () => {
        if (moduleID > 0) {
            setItemSelected(false);
            setShowCondition(true);
        }
    }

    const SelectCount = (item) => {
        setItemSelected(item);
        setShowProducts(true);
    }

    const ProductsResult = (typ, redraw) => {
        setShowProducts(false);

        if (redraw == true) {
            db_get(page, filter, moduleID);
        }
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                            <div style={{ ...styles.Block, width: '96%' }}>
                                <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray }}>
                                    <div style={{ ...styles.BlockLeft, width: '33%', height: menuHeight }}>
                                        <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} width={'80%'} func={(txt) => Search(txt)} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '33%', height: menuHeight }}>
                                        <SelectInput default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={moduleID} items={modules} field={'name'} width={'80%'} radius={global.radius} func={(id) => ChangeModule(id)} />
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '33%', height: menuHeight }}>
                                        {moduleID > 0 ?
                                            CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                                <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                    {lang.cond_new}
                                                </Button>
                                                : null : null}
                                    </div>
                                </div>

                                {/* TABLE */}
                                <div style={{ ...styles.BlockRow, width: '100%', border: color.border }}>
                                    <div style={{ ...styles.BlockLeft, width: '63%', minHeight: tableHeight, justifyContent: 'center' }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.cond_name}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 100, minHeight: tableHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.cond_used}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 150, minHeight: tableHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.date}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 50, minHeight: tableHeight, justifyContent: 'center' }}>

                                    </div>
                                </div>

                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            onClick={() => SelectItem(item)}
                                            style={{
                                                ...styles.Block,
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '96%' }}>
                                                <div onClick={() => SelectCount(item)} style={{ ...styles.BlockLeft, width: '64%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name == '' ? lang.no_name : item.name}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 2 }}>{item.user}</p>
                                                </div>
                                                <div onClick={() => SelectCount(item)} style={{ ...styles.Block, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <Tooltip title={lang.products_list}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 2 }}>{item.count} x</p>
                                                    </Tooltip>
                                                </div>
                                                <div style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextXTiny }}>{GetDatumTime2(item.updated)}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                                        <IconButton onClick={() => EditItem(item)} style={{ ...styles.ButtonIcon }}>
                                                            <FontAwesomeIcon style={{ height: 14 }} icon={faPen} />
                                                        </IconButton>
                                                        : null}
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }

                                    {/* PAGINATION */}
                                    {pages > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                        </div>
                                        : null}

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>


            {showProducts == true ?
                <ConditionProducts condition_id={itemSelected.id} module_id={moduleID} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductsResult.bind(this)} />
                : null}

            {showCondition == true ?
                <ConditionDesigner item_id={itemSelected == false ? 0 : itemSelected.id} module_id={moduleID} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ConditionResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


