/*
*
* =================================================================================================================

    Blog

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    public  -> verejný blog

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { EmptyList, FormSpace, FormText, Icon, Loading, ShowError, ShowOK, TextInput, FormYesNo, FormError, TextLine, FormSelect, SelectInput, MenuButton, FormNote, Line, DialogTabs, DeleteButton, FormLabel } from './items';
import { styles } from './styles';
import { Backdrop, Button, Chip, IconButton, Pagination, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faEye, faFileImport, faFileText, faInfoCircle, faMagnifyingGlass, faPause, faPlay, faPlusCircle, faRotate, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, GetLeftPosition, GetPages, IsEmpty } from './functions';
import { NewsletterPreview, NewsletterSelect } from './newsletters';
import { WebPageDesigner } from './webpage_designer';

export const NewsletterCampaigns = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'newsletter';

    let defaultFilter = {
        enabled: true,
        status_id: 0,
        country_id: props.locale.id
    }


    // MENU
    const statuses = [
        { id: 1, enabled: true, name: lang.newsletter_statuses[0] },
        { id: 2, enabled: true, name: lang.newsletter_statuses[1] },
        { id: 3, enabled: true, name: lang.newsletter_statuses[2] },
        { id: 4, enabled: true, name: lang.newsletter_statuses[3] },
        { id: 5, enabled: true, name: lang.newsletter_statuses[4] },
    ];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST    
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // VALUES
    const [statusID, setStatusID] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 60;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            setTabIndex(0);
            db_get(0, defaultFilter);

            running = true;
        }

    }, [props.locale.id]);


    const db_get = async (page, filter) => {
        Debug('FILTER');
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'campaigns', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GetFilter = () => {
        var filter_ = defaultFilter;

        if (tabIndex == 0) {
            filter_.published = true;
        } else {
            filter_.published = false;
        }
        filter_.status_id = statusID;

        return filter_;
    }

    const Search = (txt) => {
        setSearch(txt);
        var filter_ = GetFilter();

        if (txt.length > 1) {
            filter_.search = txt;
            db_get(0, filter_);
        }
        if (txt == '') {
            delete (filter_.search);
            db_get(0, filter_);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }


    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw_) => {
        setShowDialog(false);

        if (redraw_ == true) {
            db_get(page, filter);
        }
    }

    const ChangeStatus = (id) => {
        var filter_ = GetFilter();
        filter_.status_id = id;

        setStatusID(id);

        db_get(0, filter_);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faAt} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }} >
                        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                            <div style={{ ...styles.Block, width: '96%' }}>
                                <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray }}>
                                    <div style={{ ...styles.BlockLeft, width: '33%', height: menuHeight }}>
                                        <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '33%', height: menuHeight }}>
                                        <SelectInput all={lang.newsletter_statuses_all} theme={props.theme} enabled={true} lang={lang} value={statusID} items={statuses} field={'name'} radius={props.radius} func={(id) => ChangeStatus(id)} />
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '34%', height: menuHeight }}>
                                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add }}>
                                                <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                {lang.newsletter_campaign_new}
                                            </Button>
                                            : null}
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - 6, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '96%' }}>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '90%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, marginBottom: 2, color: color.dark_gray }}>{item.category}</p>
                                                        <TextLine text={item.name} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <Chip label={lang.newsletter_statuses[item.status]} style={{ color: item.status == 0 ? color.black : color.white, backgroundColor: item.status == 0 ? color.light_gray : item.status == 1 ? color.dark_green : item.status == 2 ? color.dark_red : color.dark_gray }} />

                                                    </div>
                                                </div>
                                            </Paper>
                                        )) :
                                            <EmptyList row small lang={lang} />
                                        }

                                        {/* PAGINATION */}
                                        {pages > 1 ?
                                            <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            </div>
                                            : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {showDialog == true ?
                <NewsletterCampaign item={itemSelected} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} permissions={props.permissions} func={DialogResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


export const NewsletterCampaign = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'blog_public';

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');
    const background_1 = require('./react/app/page_background_1.png');
    const background_2 = require('./react/app/page_background_2.png');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [redrawRequired, setRedrawRequired] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);
    const tabs = [
        { id: 0, enabled: true, label: lang.settings },
        { id: 1, enabled: true, label: lang.newsletter_status },
        { id: 2, enabled: true, label: lang.newsletter_campaign_stats },
    ];

    // DATA LIST
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');
    const [newsletterID, setNewsletterID] = useState(0);
    const [pageID, setPageID] = useState(0);
    const [newsletter, setNewsletter] = useState(false);
    const [categoryID, setCategoryID] = useState(0);
    const [status, setStatus] = useState(0);
    const [stats, setStats] = useState(false);
    const [attachments, setAttachments] = useState(false);
    const [replyTo, setReplyTo] = useState(props.organization.email);
    const [subject, setSubject] = useState(lang.newsletter);

    const [storeData, setStoreData] = useState(false);

    // STATISTIKA
    const [statItems, setStatItems] = useState(false);
    const [overID, setOverID] = useState(-1);

    // DIALOGS
    const [showNewsletters, setShowNewsletters] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showPage, setShowPage] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 800 ? 800 : height;

    // CONSTANTS
    const buttonHeight = 80;
    const errorHeight = 40;
    const column1 = 180;
    const column2 = dialogWidth - column1 - 40;
    const tabHeight = 40;
    const rowStatHeight = 64;
    const tableHeight = 40;
    const statHeight = 50;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                db_get();
            }

            running = true;
        }

    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'campaign', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    campaign_id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var item = json.item;

                setStoreData(item);

                setEnabled(item.enabled == false ? false : true);
                setName(item.name);
                setNewsletterID(item.newsletter_id);
                setCategoryID(item.category_id);
                setStatus(parseInt(item.status));
                setAttachments(item.attachments == false ? false : JSON.parse(item.attachments));
                setReplyTo(item.replyto);
                setSubject(item.subject);
                setPageID(parseInt(json.page_id));
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_status = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'campaign_status', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    campaign_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setStatus(json.campaign.status);
                setStats(json.stats);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'campaign_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (data.id == 0) {
                    setItemID(json.ok);
                    data.id = json.ok;
                }
                setStoreData(data);
                setRedrawRequired(true);
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_status_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'campaign_status_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setStats(json.stats);
                setRedrawRequired(true);
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_stats = async () => {
        Debug(pageID);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'page_stats', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    page_id: pageID,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setStatItems(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }


    const Save = () => {
        setError('');

        var err = 0;
        var error_text = lang.required_red;
        err += IsEmpty(name) ? 1 : 0;
        err += newsletterID == 0 ? 1 : 0;
        err += categoryID == 0 ? 1 : 0;
        err += IsEmpty(replyTo) ? 1 : 0;
        err += IsEmpty(subject) ? 1 : 0;

        if (err == 0) {
            let data = {
                id: itemID,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                user_id: props.user.id,
                enabled: enabled,
                name: name,
                newsletter_id: newsletterID,
                category_id: categoryID,
                attachments: attachments == false ? false : JSON.stringify(attachments),
                replyto: replyTo,
                subject: subject,
            }

            Debug(data);
            db_update(data);
        } else {
            setError(error_text);
        }
    }



    const CheckChanges = () => {

        var change = 0;
        if (storeData == false) {
            change = false
        }
        else {
            change += storeData.enabled != enabled ? 1 : 0;
            change += storeData.name != name ? 1 : 0;
            change += storeData.newsletter_id != newsletterID ? 1 : 0;
            change += storeData.category_id != categoryID ? 1 : 0;
            change += storeData.replyto != replyTo ? 1 : 0;
            change += storeData.attachments != attachments ? 1 : 0;
            change += storeData.subject != subject ? 1 : 0;
        }

        return change > 0 ? true : false;
    }

    const NewsletterResult = (typ, item) => {
        setShowNewsletters(false);

        if (typ == true) {
            setNewsletter(item);
            setNewsletterID(item.id);
            setPageID(parseInt(item.page_id));
            if (name == '') {
                setName(item.name);
            }
        }
    }

    const ChangeStatus = () => {
        let status_ = status == 0 ? 1 : status == 1 ? 2 : status == 2 ? 1 : 3;
        let data = {
            id: itemID,
            status: status_,
            init: status == 0 ? true : false
        }

        setStatus(status_);
        Debug(data);
        db_status_update(data);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 1) {
            db_status();
        }

        if (id == 2) {
            db_stats();
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faAt} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{itemID == 0 ? lang.newsletter_campaign_new : lang.newsletter_campaign_}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height }}>
                        <div style={{ ...styles.Block, height: tabHeight, backgroundColor: color.light_gray }}>
                            <DialogTabs disabled={CheckChanges() == true || itemID == 0 ? true : false} center value={tabIndex} items={tabs} width={dialogWidth / 3} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>

                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {tabIndex == 0 ?
                                <div style={{ ...styles.Block }}>
                                    <FormSpace />
                                    <FormText require redraw value={name} editing={true} title={lang.newsletter_campaign_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                    <FormNote title={lang.newsletter_campaign_name_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormText require redraw value={subject} editing={status < 2 ? true : false} title={lang.newsletter_campaign_subject} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSubject(txt)} />
                                    <FormNote title={lang.newsletter_campaign_subject_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormText require redraw value={replyTo} editing={status < 2 ? true : false} title={lang.newsletter_replyto} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setReplyTo(txt)} />
                                    <FormNote title={lang.newsletter_replyto_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormSelect hideButton require value={categoryID == 0 ? -1 : categoryID} items={false} field={'name'} codebook={global.codebook.newsletter_emails} editing={status == 0 ? true : false} title={lang.newsletter_emails_category} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setCategoryID(id)} />
                                    <FormNote title={lang.newsletter_campaign_category_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <div style={{ ...styles.Block, width: '98%', paddingTop: 16, paddingBottom: 16, backgroundColor: color.white, marginTop: 30 }}>
                                        {newsletter != false ?
                                            <div style={{ ...styles.Block }}>
                                                <p style={{ ...styles.TextXSmall }}><b>{lang.newsletter_campaign_newsletter}:</b></p>
                                                <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{newsletter.name}</p>
                                            </div>
                                            : null}
                                        <div style={{ ...styles.BlockRowRaw, marginTop: 16 }}>
                                            {status == 0 || status == 2 ?
                                                <Button onClick={() => setShowNewsletters(true)} style={{ ...styles.ButtonOutlined, width: 200, fontSize: global.font_tiny }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.dark_red, marginRight: 6 }} icon={newsletterID == 0 ? faMagnifyingGlass : faRotate} />
                                                    {newsletterID == 0 ? lang.newsletter_select_ : lang.newsletter_change}
                                                </Button>
                                                : null}
                                            {newsletterID > 0 ?
                                                <Button onClick={() => setShowPreview(true)} style={{ ...styles.ButtonOutlined, width: 200, fontSize: global.font_tiny, marginLeft: 20 }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.dark_gray, marginRight: 6 }} icon={faEye} />
                                                    {lang.newsletter_preview_}
                                                </Button>
                                                : null}
                                            {newsletterID > 0 && pageID > 0 && status < 2 ?
                                                <Button onClick={() => setShowPage(true)} style={{ ...styles.ButtonOutlined, width: 200, fontSize: global.font_tiny, marginLeft: 20 }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.dark_gray, marginRight: 6 }} icon={faFileText} />
                                                    {lang.newsletter_campaign_page}
                                                </Button>
                                                : null}
                                        </div>
                                    </div>

                                </div>
                                : null}

                            {tabIndex == 1 ?
                                <div style={{ ...styles.Block }}>

                                    {itemID > 0 ?
                                        <>
                                            {status < 3 ?
                                                <div style={{ ...styles.Block, marginTop: 30 }}>
                                                    <p style={{ ...styles.TextSmall, marginBottom: 20 }}><b>{status == 0 ? lang.newsletter_campaign_not_created : status == 1 ? lang.newsletter_campaign_started : lang.newsletter_campaign_stoped}</b></p>

                                                    <DeleteButton ask={status == 0 ? true : undefined} button bold label={status == 0 || status == 2 ? lang.newsletter_campaign_start : lang.newsletter_campaign_stop} icon={status == 0 || status == 2 ? faPlay : faPause} position={'top'} backgroundColor={color.button_delete} text={lang.newsletter_campaign_start + '?'} text_note={lang.newsletter_campaign_start_ask} theme={props.theme} lang={props.lang} func={() => ChangeStatus()} />
                                                    {status == 0 ?
                                                        <>
                                                            <p style={{ ...styles.TextXSmall, marginTop: 16, color: color.dark_gray }}><b>{lang.newsletter_campaign_start_first}</b></p>
                                                            <p style={{ ...styles.TextXSmall, marginTop: 2, color: color.dark_gray }}>{lang.newsletter_campaign_start_ask_note}</p>
                                                        </>
                                                        : null}
                                                </div>
                                                :
                                                <div style={{ ...styles.Block, marginTop: 30 }}>
                                                    <Chip label={<p style={{ ...styles.TextSmall }}><b>{lang.newsletter_campaign_finished}</b></p>} />
                                                </div>
                                            }
                                            {stats != false && status > 0 ?
                                                <div style={{ ...styles.Block, marginTop: 60 }}>
                                                    <FormLabel editing={false} icon={faInfoCircle} title={lang.newsletter_campaign_stat} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={(id) => Debug(id)} />
                                                    <FormText redraw value={stats.count} editing={false} title={lang.newsletter_campaign_stat_count} column1={250} column2={50} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => Debug(txt)} />
                                                    <FormText redraw value={stats.count_sended} editing={false} title={lang.newsletter_campaign_stat_count_sended} column1={250} column2={50} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => Debug(txt)} />
                                                    <FormText redraw value={stats.count_to_send} editing={false} title={lang.newsletter_campaign_stat_count_to_send} column1={250} column2={50} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => Debug(txt)} />
                                                    <FormText redraw value={stats.count_error} editing={false} title={lang.newsletter_campaign_stat_count_error} column1={250} column2={50} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => Debug(txt)} />
                                                </div>
                                                : null}
                                        </>
                                        : null}
                                </div>
                                : null}

                            {tabIndex == 2 ?
                                <div style={{ ...styles.Block }}>
                                    <div style={{ ...styles.Block, width: '98%', height: statHeight }}>
                                        <p style={{ ...styles.TextXSmall }}><b>{lang.newsletter_campaign_stats_items}</b></p>
                                    </div>

                                    <div style={{ ...styles.Block, width: '98%', border: color.border, marginTop: 10, marginBottom: 10 }}>
                                        <div style={{ ...styles.BlockRow, width: '99%' }}>
                                            <div style={{ ...styles.BlockLeft, width: '82%', height: tableHeight }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.web_page_stat_link}</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: 150, height: tableHeight, borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.web_page_stat_clicks}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {pageID > 0 ?
                                        <div style={{ ...styles.Block }}>
                                            {statItems != false ? statItems.map((item, index) => (
                                                <Paper
                                                    key={item.id}
                                                    elevation={item.id == overID ? 4 : 1}
                                                    style={{
                                                        ...styles.Block,
                                                        width: '98%',
                                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                        borderRadius: global.items_radius,
                                                        minHeight: rowStatHeight,
                                                        //cursor: 'pointer',
                                                        marginTop: 5, marginBottom: 5,
                                                        paddingTop: 2, paddingBottom: 2,
                                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                                    }}
                                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                >
                                                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                                                        <div style={{ ...styles.BlockLeft, width: '80%', minHeight: rowStatHeight, justifyContent: 'center' }}>
                                                            <TextLine text={item.button_text} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                            <TextLine text={item.link} fontSize={global.font_tiny} color={color.black} marginTop={5} />
                                                        </div>
                                                        <div style={{ ...styles.Block, width: 150, minHeight: rowStatHeight, justifyContent: 'center' }}>
                                                            <p style={{ ...styles.TextXSmall }}><b>{item.counter}</b> x</p>
                                                        </div>
                                                    </div>
                                                </Paper>
                                            )) :
                                                <EmptyList row small lang={lang} />
                                            }
                                        </div>
                                        : null}
                                </div>
                                : null}
                        </div>


                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {(itemID == 0 && newsletterID > 0) || CheckChanges() == true ?
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20, width: 145 }}>{lang.save}</Button>
                                    : null}
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 145 }}>{lang.close}</Button>
                            </div>
                        </div>
                    </div>

                </div>
            </Paper >

            {showPage == true && pageID > 0 ?
                <WebPageDesigner email item={{ id: pageID }} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowPage(false)} />
                : null}

            {showPreview == true ?
                <NewsletterPreview newsletterID={newsletterID} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowPreview(false)} />
                : null}

            {showNewsletters == true ?
                <NewsletterSelect remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={NewsletterResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}
