/*
*
* =================================================================================================================
* POZNAMKOVY MODUL

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Notes module_id={global.model.orders} item_id={props.item.id} height={dialogHeight - global.dialog_bar_height - tabHeight - errorHeight} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={NotesResult.bind(this)} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    module_id       -> tabulka v DB
    item_id         -> riadok v DB
    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { DialogTabs, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, ShowError, ShowOK, TextInput, Logs, Avatar, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormSelect, FormNote, FormNodes, FormAutoComplete, ShowInfo, SubMenuButton, WebURLPreview, DeleteButton } from './items';
import { styles } from './styles';
import { Backdrop, Box, Button, Checkbox, Chip, IconButton, ListItemIcon, ListItemText, MenuItem, Pagination, Paper, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faAt, faBars, faCheck, faFile, faFilter, faFolderTree, faInfoCircle, faLayerGroup, faPlus, faPlusCircle, faQuestion, faRotateRight, faTableCells, faUser, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, ConvertToPrice, ConvertToPrice2, Debug, GetLeftPosition, GetPages, IsEmpty, LoadArray, LoadBool, LoadValue, SaveArray, SaveBool, SaveValue } from './functions';
import { Photos } from './photos';
import { CustomersPerson } from './customers_person.js';
import { CustomersCategorySelect } from './customers_categories.js';
import { UsersSelect } from './users_select.js';
import { CustomersAddress } from './customers_address.js';
import { CustomersGroup, CustomersGroupSelect } from './customers_groups.js';
import {
    DataGrid, skSK,
    GridColumnMenu,
} from '@mui/x-data-grid';
import { Help } from './Help.js';
import { EmailEditor, EmailPreview } from './Editor.js';
import { GetDatumTime2 } from './functions_date.js';
import { Orders } from './orders.js';

export const Notes = (props) => {

    var defaultFilter = {
        customer_id: props.customerID
    }
    let permission_column = 'customers';

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    const [isBusy, setBusy] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redraw, setRedraw] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(global.items_max);
    const [overID, setOverID] = useState(-1);
    const [noteNew, setNoteNew] = useState('');
    const [note, setNote] = useState('');


    // CONSTANTS
    const rowHeight = 48;
    const paginationHeight = 50;

    let { func } = props;
    var running = false;
    useEffect(() => {
        if (running == false) {
            db_get(0, maxItems, true);

            running = true;
        }
        return () => {
        };

    }, [props.reload]);


    const db_get = async (page, max_items, goto_start) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'notes', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    module_id: props.module_id,
                    item_id: props.item_id,
                    // -------------------------------------
                    start: page * max_items,
                    length: max_items,
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                setPage(page);
                setPages(GetPages(json.count, max_items)); // Max pocet na stranku - Select

                if (goto_start == true) {
                    GoToStart();
                }
            } else {
                setShowError(true);
                setBusy(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'note_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.customer_notes,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);
            if (json.ok > 0) {
                db_get(data.id == 0 ? 0 : page, maxItems, data.id == 0 ? true : false);
            } else {
                setShowError(true);
                setBusy(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_notes_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const SelectItem = (item) => {
        if (CheckPermission(props.user.super_admin, props.permissions[props.permission_column]).edit == true) {
            setNote(item.note);
            setItemSelected(item);
            setItemEditing(true);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, maxItems, true);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, item, true);
    }

    const Save = (newItem) => {
        var err = false;
        if (newItem == true) {
            if (noteNew.trim() == '') {
                err = true;
            }
        } else {
            if (note.trim() == '') {
                err = true;
            }
        }

        if (err == false) {

            let data = {
                id: newItem ? 0 : itemSelected.id,
                organization_id: props.organization.id,
                user_id: props.user.id,
                enabled: true,
                module_id: props.module_id,
                item_id: props.item_id,
                note: newItem ? noteNew.trim() : note.trim()
            };

            if (newItem == true) {
                setNoteNew('');
            }
            setItemEditing(false);
            db_update(data);
        }

    }

    const DeleteItem = (item) => {
        let data = {
            id: item.id,
            enabled: false,
        };
        db_update(data);
    }

    return (
        <div style={{ ...styles.BlockCenter }}>
            <div style={{ ...styles.BlockCenter, width: '96%' }}>
                <div id={'id_notes_start'} style={{ ...styles.BlockCenter }}></div>
                <div style={{ ...styles.BlockCenter }}>

                    {/* NOVA POZNAMKA */}
                    <div style={{ ...styles.Block }}>
                        <FormSpace />
                        <TextInput redraw placeholder={lang.customer_note_placeholder} theme={props.theme} enabled={true} rows={3} lang={lang} value={noteNew} label={'' /*lang.customers_note_new*/} func={(txt) => setNoteNew(txt)} />
                        {noteNew.trim() != '' ?
                            <div style={{ ...styles.BlockRight, paddingTop: 10, paddingBottom: 20 }}>
                                <Button onClick={() => Save(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add }}>{lang.note_add}</Button>
                            </div>
                            : null}
                    </div>

                    {/* ****************************************************************************************************************
                    ZOZNAM POZNAMOK
                    **************************************************************************************************************** */}
                    <div style={{ ...styles.BlockLeft, marginTop: 20, paddingBottom: 5 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.notes}:</p>
                    </div>
                    {items != false ? items.map((item, index) => (
                        <Paper
                            key={item.id}
                            elevation={item.id == overID ? 4 : 1}
                            style={{
                                ...styles.Block,
                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                borderRadius: global.items_radius,
                                //minHeight: rowHeight,
                                cursor: 'pointer',
                                marginTop: 5, marginBottom: 5,
                                paddingTop: 2, paddingBottom: 2,
                                borderLeft: item.enabled == false ? color.disabled_border : '',
                            }}
                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                        >
                            <div style={{ ...styles.Block, width: '98%', paddingTop: 10, paddingBottom: 10 }}>
                                {itemEditing == true && item.id == itemSelected.id ?
                                    <div style={{ ...styles.BlockRow, minHeight: rowHeight }}>
                                        <div style={{ ...styles.Block, width: '95%', minHeight: rowHeight }}>
                                            <TextInput theme={props.theme} enabled={true} rows={3} lang={lang} value={note} label={lang.customers_note_edit} func={(txt) => setNote(txt)} />
                                            <FormSpace></FormSpace>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <Button onClick={() => Save(false)} style={{ ...styles.ButtonOutlined, marginRight: 20 }}>{lang.save}</Button>
                                                <Button onClick={() => setItemEditing(false)} style={{ ...styles.ButtonOutlined }}>{lang.cancel}</Button>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.Block, width: 50 }}>
                                            {CheckPermission(props.user.super_admin, props.permissions[props.permission_column]).delete == true ?
                                                <DeleteButton ask text={lang.customers_note_delete} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteItem(item)} />
                                                : null}
                                        </div>
                                    </div>
                                    :
                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, minHeight: rowHeight }}>
                                        <p style={{ ...styles.TextXTiny, marginBottom: 4, color: color.medium_gray }}>{GetDatumTime2(item.updated)} <span style={{ marginLeft: 10 }}>{item.user}</span></p>
                                        <p style={{ ...styles.TextXSmall }}>{item.note}</p>
                                    </div>
                                }
                            </div>
                        </Paper>
                    )) :
                        <div style={{ ...styles.Block, paddingTop: 40 }}>
                            <EmptyList row small lang={lang} />
                        </div>
                    }

                    {/* ****************************************************************************************************************
                PAGINATION
                **************************************************************************************************************** */}

                    <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                        <div style={{ ...styles.Block, width: 200 }}></div>
                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                            {pages > 1 ?
                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                : null}
                        </div>
                        {items != false ?
                            <div style={{ ...styles.Block, width: 200 }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                        <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                        <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </div>

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </div >
    );
}
