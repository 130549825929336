/*
*
* =================================================================================================================

    DIALOG - POPIS

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { EmptyList, FormError, Icon, Loading, ShowError, ShowOK, TextInput, Avatar, DeleteButton } from './items';
import { styles } from './styles';
import { Backdrop, Button, FormControlLabel, IconButton, MenuItem, Pagination, Paper, Radio, RadioGroup, Select, Tooltip, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faList, faPen, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, GetLeftPosition, GetPages } from './functions';
import { GetDatumTime2 } from './functions_date';
import { ConditionDesigner } from './condition_designer';

export const Conditions = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let module_id = props.module_id;

    let defaultFilter = {};

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DIALOG
    const [showCondition, setShowCondition] = useState(false);
    const [showProducts, setShowProducts] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 900 ? 900 : width;
    const dialogHeight = height >= 800 ? 800 : height;

    const menuHeight = 60;
    const rowHeight = 70;
    const viewWidth = '96%';
    const tableHeight = 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, false);

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'conditions', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: props.module_id,
                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter_
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter_);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    model_id: global.model.organizations,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(false, redrawRequired);
    }

    const Search = (txt) => {
        setSearch(txt);
        if (txt.length > 1) {
            var filter_ = defaultFilter;
            filter_.search = txt;
            db_get(0, filter_);
        }
        if (txt == '') {
            db_get(0, false);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        func(true, item)
    }

    const SelectCount = (item) => {
        setItemSelected(item);
        setShowProducts(true);
    }

    const EditItem = (item) => {
        setItemSelected(item);
        setShowCondition(true);
    }


    const ConditionResult = (typ, item) => {
        setShowCondition(false);
        if (typ == true) {
            db_get(0, filter);
        }
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowCondition(true);
    }

    const ProductsResult = (typ, redraw) => {
        setShowProducts(false);

        if (redraw == true) {
            setRedrawRequired(true);
            db_get(page, filter);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.conds}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                {lang.cond_new}
                            </Button>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start'} style={{ ...styles.Block, width: viewWidth }}>

                            {/* TABLE */}
                            <div style={{ ...styles.BlockRow, width: '100%', border: color.border }}>
                                <div style={{ ...styles.BlockLeft, width: '63%', minHeight: tableHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.cond_name}</p>
                                </div>
                                <div style={{ ...styles.Block, width: 100, minHeight: tableHeight, justifyContent: 'center', borderLeft: color.border }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.cond_used}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: 150, minHeight: tableHeight, justifyContent: 'center', borderLeft: color.border }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.date}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: 50, minHeight: tableHeight, justifyContent: 'center' }}>

                                </div>
                            </div>

                            {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '64%', minHeight: rowHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name == '' ? lang.no_name : item.name}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 2 }}>{item.user}</p>
                                        </div>
                                        <div onClick={() => SelectCount(item)} style={{ ...styles.Block, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                            <Tooltip title={lang.products_list}>
                                                <p style={{ ...styles.TextTiny, marginTop: 2 }}>{item.count} x</p>
                                            </Tooltip>
                                        </div>
                                        <div style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXTiny }}>{GetDatumTime2(item.updated)}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                            <IconButton onClick={() => EditItem(item)} style={{ ...styles.ButtonIcon }}>
                                                <FontAwesomeIcon style={{ height: 14 }} icon={faPen} />
                                            </IconButton>
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div >

                    </div>
                </div >

                {showProducts == true ?
                    <ConditionProducts condition_id={itemSelected.id} module_id={module_id} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductsResult.bind(this)} />
                    : null}

                {showCondition == true ?
                    <ConditionDesigner item_id={itemSelected == false ? 0 : itemSelected.id} module_id={module_id} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ConditionResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}



export const ConditionProducts = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'conditions';

    let defaultFilter = {};

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DATA
    const [value, setValue] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 750 ? 750 : height;

    const menuHeight = 60;
    const rowHeight = 70;
    const viewWidth = '96%';

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, false);

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'condition_items', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: props.module_id,
                    condition_id: props.condition_id,
                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter_);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'condition_items_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: props.module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setRedrawRequired(true);
                db_get(page, filter);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(0, redrawRequired);
    }

    const Search = (txt) => {
        setSearch(txt);
        if (txt.length > 1) {
            var filter_ = defaultFilter;
            filter_.search = txt;
            db_get(0, filter_);
        }
        if (txt == '') {
            db_get(0, false);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {

    }

    const DeleteProduct = (item) => {
        let data = {
            id: item.id,
            condition_id: 0
        };

        db_update(data);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faList} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.cond_used_items}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start'} style={{ ...styles.Block, width: viewWidth }}>
                            {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                                        <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                            <Avatar picture image={item.image} size={rowHeight - 10} theme={props.theme} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '58%', minHeight: rowHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name}</p>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '20%', minHeight: rowHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXTiny }}>{item.code}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                            {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                <DeleteButton ask theme={props.theme} lang={props.lang} func={() => DeleteProduct(item)} />
                                                : null}
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div >

                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}





