/*
*
* =================================================================================================================
* PRODUKT

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={props.locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    TYP:    1 - štandart
            2 - variant
            3 - balík

* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';

import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormAutoComplete, FormNote, AutoCompleteInput, FormRadio, FormChips, ShowInfo, DeleteButton, FormFlags, FormNodes, FormSelect, Note, FormNodeChips, FormSelectChips, ButtonChangeStyle, FormInfo, TextLine, StockStatus, PlusMinus, FormSwitch, FormParams, FormCodes } from './items';
import { styles } from './styles';
import { Backdrop, Box, Button, Checkbox, Chip, IconButton, ListItemIcon, ListItemText, MenuItem, Pagination, Paper, Skeleton, Stack, TextField, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowPointer, faArrowUp, faBars, faCheck, faCheckCircle, faCircle, faCircleRight, faCopy, faDollar, faDownload, faFileExport, faFilter, faFlag, faFolderTree, faInfo, faInfoCircle, faLayerGroup, faList, faLocation, faLock, faMagnifyingGlass, faPallet, faPen, faPlus, faPlusCircle, faQuestion, faRefresh, faRotateRight, faTableCells, faTrash, faUpload, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, ConvertToPrice, ConvertToPrice2, Debug, FormatMoney, FormatPieces, GetLeftPosition, GetPages, GetPrice, IsEmpty, LoadBool, LoadValue, SaveBool, SaveValue, LoadArray, SaveArray, UniqueID, FormatMoneyDPH } from './functions';
import { Photos } from './photos';
import { Today } from './functions_date';
import { ProductCategorySelect } from './product_categories';
import { ManufacturerSelect } from './manufacturers';
import { VendorSelect } from './vendors';
import { BatchUpdate } from './batch_update';
import { ProductsGroup, ProductsGroupSelect } from './product_groups';
import { ProductSelect } from './product_select.js';
import { ConditionDesigner } from './condition_designer.js';
import { Conditions } from './conditions.js';
import { StockHistory } from './stock_history.js';
import { ProductFlagsSelect } from './product_flags.js';
import {
    DataGrid, skSK,
    GridColumnMenu,
} from '@mui/x-data-grid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DialogColors, WebPageDesigner } from './webpage_designer.js';
import { Gallery } from './gallery.js';
import { DistributorsCodeChecker, DistributorsCodes } from './distributors_export.js';
import { ProductDuplicate, ProductInfo, ProductVariation } from './products.js';


export const Product = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let currency = props.locale.currency;
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let coeficientEnabled = props.system.price_coeficient_enabled == false ? false : true;
    let coeficient = props.system.price_coeficient;
    //let product_id = props.item.parent_id == 0 ? props.item.id : props.item.parent_id;
    let product_code_searching = props.system.pharminfo_enabled == true || props.system.pharmos_enabled == true ? true : false;

    let productTyp = [{ id: 1, label: lang.product_type_standart }, { id: 2, label: lang.product_type_variant }, { id: 3, label: lang.product_type_group }];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);
    const [needSave, setNeedSave] = useState(props.item == false ? true : false); // Po zmene - zobrazi sa tlacitko 'Uložiť'

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [overID, setOverID] = useState(0);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [itemID, setItemID] = useState(props.item == false ? 0 : parseInt(props.item.parent_id) == 0 ? parseInt(props.item.id) : parseInt(props.item.parent_id));

    // TABS
    const [tabIndex, setTabIndex] = useState(props.item == false ? 0 : props.item.parent_id == 0 ? 3 : 2);

    // DATA
    const [storeData, setStoreData] = useState(false);
    const [categories, setCategories] = useState(false);
    //const [countries, setCountries] = useState(props.organization.currencies);
    const [manufactures, setManufactures] = useState(false);
    const [vendors, setVendors] = useState(false);
    const [flagItems, setFlagItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [orderTypesList, setOrderTypesList] = useState(false);

    const [enabled, setEnabled] = useState(true);
    const [number, setNumber] = useState('');
    const [image, setImage] = useState('');
    const [name, setName] = useState('');
    const [variations, setVariations] = useState(false);
    const [priceCoeficientEnabled, setPriceCoeficientEnabled] = useState(coeficientEnabled == true ? true : false);
    const [priceTempCoeficientEnabled, setPriceTempCoeficientEnabled] = useState(coeficientEnabled == true ? true : false);
    const [cost, setCost] = useState('');
    const [costTemp, setCostTemp] = useState('');
    const [price, setPrice] = useState('');
    const [priceTemp, setPriceTemp] = useState('');
    const [priceRetail, setPriceRetail] = useState('');
    const [priceRetailTemp, setPriceRetailTemp] = useState('');
    const [dph, setDph] = useState(-1);
    const [code, setCode] = useState('');
    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const [codeVendor, setCodeVendor] = useState('');
    const [descriptionShort, setDescriptionShort] = useState('');
    const [descriptionLong, setDescriptionLong] = useState('');
    const [details, setDetails] = useState('');
    const [tags, setTags] = useState('');
    const [quantityStock, setQuantityStock] = useState('0');
    const [quantityPackage, setQuantityPackage] = useState('1');
    const [unit, setUnit] = useState(lang.piece);
    const [guarantee, setGuarantee] = useState('24');
    const [manufactureID, setManufactureID] = useState(0);
    const [vendorID, setVendorID] = useState(0);
    const [categoryID, setCategoryID] = useState(0);
    const [orderTypes, setOrderTypes] = useState(false);
    const [dateStart, setDateStart] = useState(Today());
    const [dateEnd, setDateEnd] = useState(Today());
    const [dateEndless, setDateEndless] = useState(true);
    const [priceTempEnabled, setPriceTempEnabled] = useState(false);
    const [priceStart, setPriceStart] = useState(Today());
    const [priceEnd, setPriceEnd] = useState(Today());
    const [countryID, setCountryID] = useState(props.locale.id);
    const [conditionID, setConditionID] = useState(0);
    const [conditionName, setConditionName] = useState('');
    const [note, setNote] = useState('');
    const [typ, setTyp] = useState(1);
    const [flags, setFlags] = useState([]);
    const [book1, setBook1] = useState(0);
    const [book2, setBook2] = useState(0);
    const [book3, setBook3] = useState(0);
    const [portal, setPortal] = useState(true);
    const [pageID, setPageID] = useState(0);
    const [galleryID, setGalleryID] = useState(0);
    const [variantTyp, setVariantTyp] = useState(0);
    const [B2B, setB2B] = useState(true);
    const [B2C, setB2C] = useState(true);
    const [stockUID, setStockUID] = useState(false);

    // PARAMETRE PRODUKTU
    const [paramSelected, setParamSelected] = useState(false);
    const [params, setParams] = useState(false);
    const [showParam, setShowParam] = useState(false);

    // ZOZNAM VARIACII - variačný typ
    const [variationsItems, setVariationsItems] = useState(false);
    const [variantCount, setVariantCount] = useState(0);

    // NACITANIE KODU - ADC / SUKL
    const [codeID, setCodeID] = useState(0);
    const [showDistributorCodes, setShowDistributorCodes] = useState(false);

    // ZOZNAM PRODUKTOV - typ balíček
    const [products, setProducts] = useState(false);
    const [productsCount, setProductsCount] = useState(0);
    const [selectProducts, setSelectProducts] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [itemsID, setItemsID] = useState([]);

    // Vlozenie produktu do variacie
    const [showSelectProducts, setShowSelectProducts] = useState(false);
    const [selectedProductID, setSelectedProductID] = useState(0);
    const [showAskInsertProduct, setShowAskInsertProduct] = useState(false);
    const [showAskEjectProduct, setShowAskEjectProduct] = useState(false);

    // PODMIENKY
    const [showCondition, setShowCondition] = useState(false);
    const [showConditions, setShowConditions] = useState(false);

    // SKLADY
    const [stocks, setStocks] = useState(false);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showVariation, setShowVariation] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showDuplicate, setShowDuplicate] = useState(false);
    const [showPage, setShowPage] = useState(false);

    // TABS
    const [tabs, setTabs] = useState([
        { id: 3, enabled: true, label: lang.info },
        { id: 0, enabled: CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false, label: lang.setting },
        { id: 2, enabled: true, label: lang.product_variations },
        { id: 4, enabled: true, label: lang.products },
        { id: 5, enabled: props.item == false ? false : true, label: lang.stock_card },
        { id: 6, enabled: props.system.eshop_enabled == true ? true : false, label: lang.gallery },
        { id: 1, enabled: true, label: lang.history },
    ]);

    // TYPY VARIANTU
    const variant_types = [
        { id: 0, enabled: true, name: lang.product_variant_types[0] },
        { id: 1, enabled: true, name: lang.product_variant_types[1] },
    ];

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = (dialogWidth / 2) - 40 - column1;
    const rowHeight = 50;
    const rowProductHeight = 70;
    let DialogLeft = props.center == true ? 0 : GetLeftPosition(width);
    const stockCellWidth = 140;
    const stockCellHeight = 96;
    const tableLabelHeight = 28;

    let { func } = props;
    var running = false;

    useEffect(() => {
        Debug('PROPS');
        Debug(props);
        Debug(itemID);
        if (running == false) {
            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                if (props.item.parent_id == 0) {
                    db_get(itemID);
                } else {
                    db_get(itemID);
                    db_variations();
                }

            } else {
                setStoreData({});
                //setDph(props.locale.dph);
                if (props.system.product_auto_code == true) {
                    db_get_code();
                }
            }

            db_data();

            running = true;
        }

    }, []);

    const db_get = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: id,
                    prices_final: false,
                    stock_status: true,
                    condition_exe: false
                })
            })

            const json = await response.json();
            Debug('PRODUCT');
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    if (item.variations == true) {
                        tabs[2].enabled = true;
                        tabs[4].enabled = false;
                    } else {
                        tabs[2].enabled = false;
                        tabs[4].enabled = true;
                    }

                    if (item.grouped == true) {
                        tabs[3].enabled = true;
                    } else {
                        tabs[3].enabled = false;
                    }

                    setVariantCount(item.variant_count);
                    setProductsCount(item.products_count);
                    setParams(item.params == undefined ? false : item.params);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_code = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_code', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setCode(json.code);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_variations = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'variations', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setVariationsItems(json.variations);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_sub_products = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'sub_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setProducts(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_sub_products_add = async (product) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'sub_products_add', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    group_id: itemID,
                    product_id: product,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_sub_products();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_sub_products_delete = async (itemID) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'sub_products_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_sub_products()
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_variant_add = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'variation_add_product', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID,
                    product_id: id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setRedrawRequired(true);
                db_variations();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_variant_eject = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'variation_eject_product', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    parent_id: itemID,
                    product_id: id,
                })
            })

            const json = await response.json();
            Debug('EJECT');
            Debug(json);
            if (json.ok > 0) {
                setRedrawRequired(true);
                db_variations();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    product_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {

                if (data.variations == true) {
                    tabs[2].enabled = true;
                } else {
                    tabs[2].enabled = false;
                }

                if (data.grouped == true) {
                    tabs[3].enabled = true;
                } else {
                    tabs[3].enabled = false;
                }

                setEditing(false);
                setRedrawRequired(true);
                setNeedSave(false);
                if (data.id == 0) {
                    setItemID(json.item_id);
                    setStockUID()
                    db_get(json.item_id);
                    //func(true, json.item_id);
                } else {
                    setShowOK(true);
                }
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_check = async (data) => {
        Debug(code);
        Debug(data);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data,
                    country_id: countryID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.count == 0) {

                    db_update(data);
                } else {
                    setError(lang.product_code_error);
                    setBusy(false);
                }
            } else {
                setBusy(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                /*
                var tmp = json.categories;        
                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.path > b.path) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }
                setCategories(tmp);
                */
                setCategories(json.categories);
                setManufactures(json.manufactures);
                setVendors(json.vendors);
                setFlagItems(json.flags_all);
                var tmp = json.order_types;
                if (tmp != false) {
                    tmp = json.order_types.filter(x => x.products_all == 0);
                }
                setOrderTypesList(tmp);

                var tmp = json.stocks;
                if (tmp != false) {
                    tmp.forEach(item => {
                        item.quantity = 0;
                    });
                }
                setStocks(tmp);
            }

            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_reorder = async (items) => {
        setBusy(true);
        Debug(items);
        try {
            const response = await fetch(
                global.db_url + 'reorder_items', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.products,
                    items: items
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_params = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_params', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    product_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setParams(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_sub_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'sub_product_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (result.destination) {
            const tmp = reorder(variationsItems, result.source.index, result.destination.index);

            var n = 1;
            var items_ = [];
            tmp.forEach(item => {
                item.priority = n;

                let data = {
                    id: item.id,
                    priority: n
                }
                items_.push(data);
                n++
            });

            setVariationsItems(tmp);
            db_reorder(items_);
        }
    }

    const Close = () => {
        func(redrawRequired, 0);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setTyp(item.variations == true ? 2 : item.grouped == true ? 3 : 1);
        setPortal(item.portal == false ? false : true);
        setNumber(item.number);
        setImage(item.image);
        setName(item.name);
        setVariations(item.variations == false ? false : true);
        setPriceCoeficientEnabled(item.price_coeficient_enabled == false ? false : true);
        setPriceTempCoeficientEnabled(item.price_temp_coeficient_enabled == false ? false : true);
        setCost(item.cost);
        setCostTemp(item.cost_temp);
        setPrice(item.price);
        setPriceTemp(item.price_temp);
        setPriceRetail(item.price_retail);
        setPriceRetailTemp(item.price_retail_temp);
        setDph(item.dph);
        setCode(item.code);
        setCode1(item.code_1);
        setCode2(item.code_2);
        setCode3(item.code_3);
        setCode4(item.code_4);
        setCodeVendor(item.code_vendor);
        setDescriptionShort(item.description_short);
        setDescriptionLong(item.description_long);
        setDetails(item.details);
        setTags(item.tags);
        setQuantityStock(item.quantity_stock);
        setQuantityPackage(item.quantity_package);
        setUnit(item.unit);
        setGuarantee(item.guarantee);
        setManufactureID(item.manufacture_id);
        setVendorID(item.vendor_id);
        setCategoryID(item.products_category_id);
        setOrderTypes(item.order_types);
        setDateStart(item.date_start == null ? Today() : item.date_start);
        setDateEnd(item.date_end == global.date_endless ? Today() : item.date_end);
        setDateEndless(item.date_end == global.date_endless ? true : false);
        setPriceTempEnabled(item.price_temp_enabled == false ? false : true);
        setPriceStart(item.price_start == null ? Today() : item.price_start);
        setPriceEnd(item.price_end == null ? Today() : item.price_end);
        setCountryID(item.country_id);
        setConditionID(item.condition_id);
        setNote(item.note);
        setFlags(item.flags == false ? [] : item.flags);
        setBook1(item.book_1);
        setBook2(item.book_2);
        setBook3(item.book_3);
        setConditionName(item.condition_name);
        setPageID(item.page_id);
        setGalleryID(item.gallery_id);
        setVariantTyp(item.variant_typ);
        setB2B(item.b2b == false ? false : true);
        setB2C(item.b2c == false ? false : true);
        setStockUID(item.stock_uid);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
        setNeedSave(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.enabled = enabled;
        storeData.portal = portal;
        storeData.image = image;
        storeData.name = name;
        storeData.variations = variations;
        storeData.price_coeficient_enabled = priceCoeficientEnabled;
        storeData.price_temp_coeficient_enabled = priceTempCoeficientEnabled;
        storeData.cost = cost;
        storeData.cost_temp = costTemp;
        storeData.price = price;
        storeData.price_temp = priceTemp;
        storeData.price_temp_enabled = priceTempEnabled;
        storeData.price_retail = priceRetail;
        storeData.price_retail_temp = priceRetailTemp;
        storeData.dph = dph;
        storeData.code = code;
        storeData.code_1 = code1;
        storeData.code_2 = code2;
        storeData.code_3 = code3;
        storeData.code_4 = code4;
        storeData.code_vendor = codeVendor;
        storeData.description_short = descriptionShort;
        storeData.description_long = descriptionLong;
        storeData.details = details;
        storeData.tags = tags;
        //storeData.quantity_stock = quantityStock;
        storeData.quantity_package = quantityPackage;
        storeData.unit = unit;
        storeData.guarantee = guarantee;
        storeData.manufacture_id = manufactureID;
        storeData.vendor_id = vendorID;
        storeData.products_category_id = categoryID;
        storeData.order_types = orderTypes;
        storeData.date_start = dateStart;
        storeData.date_end = dateEndless == true ? global.date_endless : dateEnd;
        storeData.priceStart = priceStart;
        storeData.priceEnd = priceEnd;
        storeData.country_id = countryID;
        storeData.condition_id = conditionID;
        storeData.note = note;
        storeData.flags = flags;
        storeData.book_1 = book1;
        storeData.book_2 = book2;
        storeData.book_3 = book3;
        storeData.variant_typ = variantTyp;
        storeData.b2b = B2B;
        storeData.b2c = B2C;
    }

    const Save = () => {

        setError('');
        var error = 0;
        var error_text = lang.required_red;

        if (typ != 2) {
            error += IsEmpty(code) == true ? 1 : 0;
        }
        error += IsEmpty(name) == true ? 1 : 0;
        error += IsEmpty(cost) == true ? 1 : 0;
        error += IsEmpty(unit) == true ? 1 : 0;
        error += IsEmpty(quantityPackage) == true ? 1 : 0;

        if (dateEndless == false) {
            // Kontrola dátumov
            let date1 = new Date(dateStart).getTime();
            let date2 = new Date(dateEnd).getTime();
            if (date1 > date2) {
                error++;
                error_text = lang.product_date_start_error;
            }
        }

        if (priceTempEnabled == true) {
            // Kontrola dátumov
            let date1 = new Date(priceStart).getTime();
            let date2 = new Date(priceEnd).getTime();
            if (date1 > date2) {
                error++;
                error_text = lang.product_price_start_error;
            }
        }

        if (error == 0) {
            // TEXT LOGU
            var action = global.logs.created;
            if (itemID != 0) {
                action = global.logs.modified;
                if (enabled != storeData.enabled) {
                    action = enabled == true ? global.logs.enabled : global.logs.disabled;
                }

            }

            if (itemID != 0) {
                if (storeData.cost != cost) {
                    action = global.logs.price_cost_changed;
                }
                if (storeData.price != price) {
                    action = global.logs.price_sell_changed;
                }
                if (storeData.cost != cost && storeData.price != price) {
                    action = global.logs.prices_changed;
                }
            }

            var order_types = [];
            if (orderTypes != false) {
                orderTypes.forEach(element => {
                    let data = { id: element.id };
                    order_types.push(data);
                });
            }

            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                product_id: itemID,
                parent_id: 0,
                action: action,
                cost: ConvertToPrice(cost, precisions),
                price: priceCoeficientEnabled == true ? ConvertToPrice(parseFloat(cost * coeficient), precisions) : ConvertToPrice(price, precisions),
                enabled: enabled
            };

            let data = {
                id: itemID,
                organization_id: props.organization.id,
                enabled: enabled,
                variations: typ == 2 ? true : false,
                grouped: typ == 3 ? true : false,
                portal: portal,
                parent_id: 0,
                name: name.trim(),
                image: image,
                price_coeficient_enabled: priceCoeficientEnabled,
                price_temp_coeficient_enabled: priceTempCoeficientEnabled,
                cost: ConvertToPrice(cost, precisions),
                cost_temp: ConvertToPrice(costTemp, precisions),
                price: ConvertToPrice(price, precisions),
                price_temp: ConvertToPrice(priceTemp, precisions),
                price_temp_enabled: priceTempEnabled,
                price_retail: ConvertToPrice(priceRetail, precisions),
                price_retail_temp: ConvertToPrice(priceRetailTemp, precisions),
                dph: dph == '' ? -1 : dph,
                code: code.trim(),
                code_1: typ != 2 ? code1 : '',
                code_2: typ != 2 ? code2 : '',
                code_3: typ != 2 ? code3 : '',
                code_4: typ != 2 ? code4 : '',
                code_vendor: typ != 2 ? codeVendor : '',
                description_short: descriptionShort,
                description_long: descriptionLong,
                details: details,
                tags: tags,
                //quantity_stock: quantityStock,
                quantity_package: quantityPackage,
                unit: unit,
                guarantee: guarantee,
                manufacture_id: manufactureID,
                vendor_id: vendorID,
                products_category_id: categoryID,
                order_types: order_types.length == 0 ? false : JSON.stringify(order_types),
                date_start: dateStart,
                date_end: dateEndless == true ? global.date_endless : dateEnd,
                price_start: priceStart,
                price_end: priceEnd,
                country_id: countryID,
                condition_id: conditionID,
                note: note.trim(),
                book_1: book1,
                book_2: book2,
                book_3: book3,
                flags: flags == false ? false : JSON.stringify(flags),
                variant_typ: variantTyp,
                b2b: B2B,
                b2c: B2C,

                logs: log,
                stocks: stocks != false ? stocks : false,

            };
            StoreEditing();

            if (props.system.product_code_checking == true) {
                db_check(data);
            } else {
                db_update(data);
            }


        } else {
            setError(error_text);
        }

    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);
        setEditing(false);
        setNeedSave(false);

        if (id == 0) {
            setEditing(true);
            db_get(itemID);
        }

        if (id == 1) {
            db_get_logs(false);
        }

        if (id == 2) {
            db_variations();
        }

        if (id == 3) {
            // Informácie o produkte
        }

        if (id == 4) {
            db_sub_products();
        }

    }


    const AvatarPress = (typ) => {
        if (typ == true) {
            setNeedSave(true);
            setSelectPhoto(true);
        } else {
            setImage('');
        }
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setImage(path + file);
        }
    }

    const AddNew = () => {
        setSelectedItem(false);
        setShowVariation(true);
    }

    const AddNewProduct = () => {
        setSelectedItem(false);
        setShowSelectProducts(true);
    }

    const ProductSelectResult = (typ, items) => {
        setShowAskInsertProduct(false);
        if (typ == true && items != false) {
            if (selectedProductID > 0) {
                Debug(selectedProductID);
                db_variant_add(selectedProductID);
            }
        }
    }

    const ProductEject = (item) => {
        setSelectedProductID(item.id);
        setShowAskEjectProduct(true);
    }

    const ProductEjectResult = (typ) => {
        setShowAskEjectProduct(false);

        if (typ == true) {
            db_variant_eject(selectedProductID);
        }
    }


    const ProductSelectAskResult = (typ, items) => {
        setShowSelectProducts(false);
        if (typ == true && items != false) {
            setSelectedProductID(items[0]);
            setShowAskInsertProduct(true);
        }
    }

    const editVariation = (item) => {
        setSelectedItem(item);
        setShowVariation(true);
    }

    const VariationResult = (redraw_) => {
        setShowVariation(false);

        if (redraw_ == true) {
            db_variations();
            setRedrawRequired(true);
        }
    }

    const StartEditing = () => {
        setEditing(true);
        setNeedSave(false);
    }

    const ChangeTyp = (id) => {
        setTyp(id);
    }

    const ProductResult = (typ, items) => {
        setSelectProducts(false);
        if (typ == true && items != false) {
            db_sub_products_add(items[0]);
        }
    }


    const AddProducts = (item) => {
        // TODO
        var arr = [];
        if (products != false) {
            products.forEach(item => {
                arr.push(item.id);
            });
        }
        setItemsID(arr);
        setItemSelected(item);
        setSelectProducts(true);
    }


    const DeleteItem = (item) => {
        db_sub_products_delete(item.grouped_id);
    }

    const ProductPreview = (item) => {
        setItemSelected(item);
        setShowInfo(true);
    }

    const Condition = () => {
        setShowCondition(true);
    }

    const ConditionResult = (typ, id, item) => {
        setShowCondition(false);
        if (typ == true) {
            setConditionName(item.name);
            setConditionID(id);
        }
    }

    const ConditionsResult = (typ, item) => {
        setShowConditions(false);
        if (typ == true) {
            setConditionName(item.name);
            setConditionID(item.id);
        }
        if (typ == false && item == true) {
            // REDRAW
            db_get(itemID);
        }
    }

    const GoToStart = () => {
        setTimeout(() => {
            var element = document.getElementById('id_product_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth', block: "nearest" }); // start, center, end, nearest
            }
        }, global.goto_start_delay);
    }

    const ChangeDetails = (value) => {
        setShowDetails(value);
        GoToStart();
    }

    const StockHistoryResult = () => {
    }

    const DuplicateResult = (typ) => {
        setShowDuplicate(false);
    }

    const PageResult = (redraw_, page_id) => {
        if (page_id > 0) {
            let data = {
                id: itemID,
                page_id: page_id
            }
            setPageID(page_id);
            db_update(data);

        } else {
            setShowPage(false);
        }
    }

    const GalleryResult = (typ, gallery_id) => {
        if (typ == true && gallery_id > 0) {
            let data = {
                id: itemID,
                gallery_id: gallery_id
            }
            setGalleryID(gallery_id);
            Debug(data);
            db_update(data);
        }
    }

    const ChangeCode = (id, value, button) => {

        if (button == true) {
            setNeedSave(true);
            setCodeID(id);
            setShowDistributorCodes(true);
        } else {
            setNeedSave(true);
            if (id == 1) {
                setCode1(value);
            }
            if (id == 2) {
                setCode2(value);
            }
            if (id == 3) {
                setCode3(value);
            }
            if (id == 4) {
                setCode4(value);
            }
        }
    }

    const DistributorCodesResult = (typ, item) => {
        setShowDistributorCodes(false);

        if (typ == true) {
            setNeedSave(true);
            if (codeID == 1) {
                setCode1(item.adc);
            }
            if (codeID == 2) {
                setCode2(item.adc);
            }
            if (codeID == 3) {
                setCode3(item.adc);
            }
            if (codeID == 4) {
                setCode4(item.adc);
            }
        }
    }

    const PlusMinusResult = (value, item) => {
        console.log(value);
        console.log(item);

        if (parseInt(item.grouped_id) > 0) {
            let data = {
                id: item.grouped_id,
                quantity: value
            }

            db_sub_update(data);
        }
    }

    const ParamResult = (typ, redraw_) => {
        setShowParam(false);

        if (typ == true) {
            db_params();
        }
    }

    const EditParam = (item) => {
        setParamSelected(item);
        setShowParam(true);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: DialogLeft, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{itemID == 0 ? lang.product_new : itemID != 0 ? props.item.name : lang.product}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: '#F4F4F4', borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {itemID != 0 && storeData != false ?
                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs disabled={tabIndex == 0 && needSave == true ? true : false} center value={tabIndex} items={tabs} width={dialogWidth / 6} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        /*
                        *
                        * =================================================================================================================
                        * NASTAVENIA PRODUKTU
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div id={'id_product_start'} style={{ ...styles.Block }}>
                            </div>

                            {storeData != false ?
                                <div style={{ ...styles.Block, width: '99%' }}>

                                    <FormSpace />
                                    <div style={{ ...styles.Block }}>
                                        <Avatar editable={editing} picture image={image} size={120} theme={props.theme} func={AvatarPress.bind()} />
                                        <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.product_image}</p>
                                    </div>
                                    <FormSpace />
                                    <div style={{ ...showDetails == true ? styles.BlockRow : styles.Block }}>
                                        <div style={{ ...styles.Block, width: '50%' }}>
                                            <div style={{ ...styles.Block, width: '98%', backgroundColor: color.white, borderRadius: radius }}>
                                                <div style={{ ...styles.Block, width: '98%' }}>
                                                    {/* INFORMACIE O PRODUKTE */}
                                                    <FormSpace />
                                                    <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} icon={faInfoCircle} title={lang.product_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => StartEditing()} />
                                                    <FormYesNo value={enabled} editing={editing} title={lang.product_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setEnabled(value); setNeedSave(true); }} />

                                                    {props.system.eshop_enabled == true ?
                                                        <>
                                                            <FormYesNo value={portal} editing={editing} title={lang.product_portal_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setPortal(value); setNeedSave(true); }} />
                                                            <FormNote title={lang.product_portal_enabled_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                        </>
                                                        : null}

                                                    {itemID == 0 ?
                                                        <FormRadio help_id={3} value={typ} items={productTyp} editing={editing} title={lang.product_typ} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { ChangeTyp(value); setNeedSave(true); }} />
                                                        :
                                                        <FormRadio hideLine value={typ} items={productTyp} editing={false} title={lang.product_typ} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                                    }

                                                    {props.system.eshop_enabled == true && props.system.products_b2b_b2c == true ?
                                                        <FormSwitch value1={B2B} value2={B2C} title={lang.products_b2b_b2c} title_1={lang.customer_b2b_} title_2={lang.customer_b2c_} editing={editing} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value1, value2) => { setB2B(value1); setB2C(value2); setNeedSave(true); }} />
                                                        : null}

                                                    {props.system.eshop_enabled == true && typ == 2 ?
                                                        <FormSelect help_id={21} require value={variantTyp} items={variant_types} field={'name'} editing={editing} title={lang.product_variant_typ} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setVariantTyp(id); setNeedSave(true); }} />
                                                        : null}

                                                    {/* countries.length > 1 ?
                                                        <FormSelect require value={countryID} items={countries} field={'state'} editing={editing} title={lang.app_country} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCountryID(id)} />
                                                    : null */}

                                                    <FormSpace />
                                                    <FormText require={typ == 2 ? false : true} redraw value={code} editing={editing} title={lang.product_code} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setCode(txt); setNeedSave(true); }} />
                                                    <FormText require redraw value={name} editing={editing} title={lang.product_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setName(txt); setNeedSave(true); }} />
                                                    <FormSpace />

                                                    {typ != 2 && showDetails == false && props.system.product_codes[0].enabled == true ?
                                                        <FormText redraw value={code1} editing={editing} button={product_code_searching == true && props.system.product_code_search_1 == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.product_codes[0].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(1, txt, press)} />
                                                        : null}
                                                    {typ != 2 && showDetails == false && props.system.product_codes[1].enabled == true ?
                                                        <FormText redraw value={code2} editing={editing} button={product_code_searching == true && props.system.product_code_search_2 == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.product_codes[1].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(2, txt, press)} />
                                                        : null}
                                                    {typ != 2 && showDetails == false && props.system.product_codes[2].enabled == true ?
                                                        <FormText redraw value={code3} editing={editing} button={product_code_searching == true && props.system.product_code_search_3 == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.product_codes[2].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(3, txt, press)} />
                                                        : null}
                                                    {typ != 2 && showDetails == false && props.system.product_codes[5].enabled == true ?
                                                        <FormText redraw value={code4} editing={editing} button={product_code_searching == true && props.system.product_code_search_4 == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.product_codes[5].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(4, txt, press)} />
                                                        : null}

                                                    <FormSpace />
                                                    <FormNodes value={categoryID} items={categories} editing={editing} title={lang.product_category} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setCategoryID(id); setNeedSave(true); }} />
                                                    {props.system.order_types == true ?
                                                        <FormSelectChips value={orderTypes} items={orderTypesList} field={'name'} editing={editing} title={lang.order_types} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setOrderTypes(id); setNeedSave(true); }} />
                                                        : null}
                                                    <FormSpace />
                                                    {/*
                                                    <FormAutoComplete value={categoryID} items={categories} field={'path'} editing={editing} title={lang.product_category} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setCategoryID(id)} />
                                                    */}
                                                    {showDetails == true ?
                                                        <>
                                                            <FormAutoComplete value={manufactureID} items={manufactures} field={'name'} editing={editing} title={lang.manufacturer} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setManufactureID(id); setNeedSave(true); }} />
                                                            <FormAutoComplete value={vendorID} items={vendors} field={'name'} editing={editing} title={lang.vendor} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setVendorID(id); setNeedSave(true); }} />
                                                        </>
                                                        : null}
                                                    {typ != 2 ?
                                                        <FormFlags help_id={4} value={flags} items={flagItems} field={'name'} editing={editing} title={lang.flags} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setFlags(id); setNeedSave(true); }} />
                                                        : null}
                                                    < FormSpace />
                                                </div>
                                            </div>
                                        </div>
                                        {showDetails == true ?
                                            <div style={{ ...styles.Block, width: '50%' }}>
                                                {typ != 2 ?
                                                    <div style={{ ...styles.BlockLeft, width: '98%', backgroundColor: color.white, borderRadius: radius }}>
                                                        <div style={{ ...styles.Block, width: '98%' }}>
                                                            {/* DODATOCNE KODY PRODUKTU */}
                                                            <FormSpace />
                                                            <FormLabel editing={editing} title={lang.product_code_external_} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                                            {props.system.product_codes[3].enabled == true ?
                                                                // KOD DODAVATELA
                                                                <FormText redraw value={codeVendor} editing={editing} title={lang.product_code_vendor_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => { setCodeVendor(txt); setNeedSave(true); }} />
                                                                : null}
                                                            {props.system.product_codes[4].enabled == true ?
                                                                // EAN KODY
                                                                <FormCodes redraw product_id={itemID} typ={0} editing={editing} title={lang.product_code_ean} column1={column1} column2={column2} theme={props.theme} radius={props.radius} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} token={props.token} />
                                                                : null}
                                                            {props.system.product_codes[0].enabled == true ?
                                                                <FormText redraw value={code1} editing={editing} button={product_code_searching == true && props.system.product_code_search_1 == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.product_codes[0].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(1, txt, press)} />
                                                                : null}
                                                            {props.system.product_codes[1].enabled == true ?
                                                                <FormText redraw value={code2} editing={editing} button={product_code_searching == true && props.system.product_code_search_2 == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.product_codes[1].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(2, txt, press)} />
                                                                : null}
                                                            {props.system.product_codes[2].enabled == true ?
                                                                <FormText redraw value={code3} editing={editing} button={product_code_searching == true && props.system.product_code_search_3 == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.product_codes[2].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(3, txt, press)} />
                                                                : null}
                                                            {props.system.product_codes[5].enabled == true ?
                                                                <FormText redraw value={code4} editing={editing} button={product_code_searching == true && props.system.product_code_search_4 == true ? true : undefined} button_icon={faMagnifyingGlass} title={props.system.product_codes[5].label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt, press) => ChangeCode(4, txt, press)} />
                                                                : null}
                                                            <FormSpace />
                                                        </div>
                                                    </div>
                                                    : null}
                                                <FormSpace />
                                                <div style={{ ...styles.BlockLeft, width: '98%', backgroundColor: color.white, borderRadius: radius }}>
                                                    <div style={{ ...styles.Block, width: '98%' }}>
                                                        {/* DATUM PREDAJA */}
                                                        <FormSpace />
                                                        <FormLabel editing={false} title={lang.product_date_start_} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                                        <FormYesNo value={dateEndless} editing={editing} title={lang.product_date_endless} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setDateEndless(value); setNeedSave(true); }} />
                                                        <FormText redraw date value={dateStart} editing={editing} title={lang.product_date_start} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setDateStart(txt); setNeedSave(true); }} />
                                                        <FormNote title={lang.date_include} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                        {dateEndless == false ?
                                                            <div>
                                                                <FormText redraw date value={dateEnd} editing={editing} title={lang.product_date_end} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setDateEnd(txt); setNeedSave(true); }} />
                                                                <FormNote title={lang.date_include} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                            </div>
                                                            : null}
                                                        <FormSpace />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </div>

                                    <FormSpace />
                                    <div style={{ ...showDetails == true ? styles.BlockRow : styles.Block }}>
                                        <div style={{ ...styles.Block, width: '50%' }}>
                                            <div style={{ ...styles.Block, width: '98%', backgroundColor: color.white, borderRadius: radius }}>
                                                <div style={{ ...styles.Block, width: '98%' }}>
                                                    {/* CENY PRODUKTU */}
                                                    <FormSpace />
                                                    <FormLabel editing={false} title={lang.product_prices} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                                    <FormText require money help_id={7} currency={currency} precisions={precisions} redraw value={cost} editing={editing} title={lang.product_cost} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setCost(txt); setNeedSave(true); }} />
                                                    {coeficientEnabled == true ?
                                                        <FormYesNo help_id={5} value={priceCoeficientEnabled} editing={editing} title={lang.system_price_coeficient_enable} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setPriceCoeficientEnabled(value); setNeedSave(true); }} />
                                                        : null}
                                                    {priceCoeficientEnabled == false || coeficientEnabled == false ?
                                                        <FormText money help_id={8} currency={currency} precisions={precisions} redraw value={price} editing={editing} title={lang.product_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPrice(txt); setNeedSave(true); }} />
                                                        :
                                                        <FormNote title={lang.product_price_coeficient} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                    }
                                                    <FormText help_id={6} money currency={currency} precisions={precisions} redraw value={priceRetail} editing={editing} title={lang.product_price_retail} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPriceRetail(txt); setNeedSave(true); }} />
                                                    <FormSpace />
                                                    <FormText numeric precisions={precisions} placeholder={lang.system_match} redraw value={dph == -1 ? '' : dph} editing={editing} title={lang.product_dph + ' %'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setDph(txt); setNeedSave(true); }} />
                                                    <FormNote title={lang.product_dph_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                    <FormSpace />
                                                </div>
                                            </div>
                                        </div>
                                        {showDetails == true ?
                                            <div style={{ ...styles.Block, width: '50%' }}>
                                                <div style={{ ...styles.Block, width: '98%', backgroundColor: color.white, borderRadius: radius }}>
                                                    <div style={{ ...styles.Block, width: '98%' }}>

                                                        {/* DOCASNA CENA */}
                                                        <FormSpace />
                                                        <FormLabel editing={false} title={lang.product_price_ranged} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                                        <FormYesNo value={priceTempEnabled} editing={editing} title={lang.product_price_ranged} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setPriceTempEnabled(value); setNeedSave(true); }} />
                                                        <FormSpace />
                                                        {priceTempEnabled == true ?
                                                            <div>
                                                                <FormText redraw date value={priceStart} editing={editing} title={lang.product_price_start} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPriceStart(txt); setNeedSave(true); }} />
                                                                <FormText redraw date value={priceEnd} editing={editing} title={lang.product_price_end} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPriceEnd(txt); setNeedSave(true); }} />
                                                                <FormSpace />
                                                                <FormText money currency={currency} precisions={precisions} redraw value={costTemp} editing={editing} title={lang.product_cost} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setCostTemp(txt); setNeedSave(true); }} />
                                                                {coeficientEnabled == true ?
                                                                    <FormYesNo value={priceTempCoeficientEnabled} editing={editing} title={lang.system_price_coeficient_enable} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setPriceTempCoeficientEnabled(value); setNeedSave(true); }} />
                                                                    : null}
                                                                {priceTempCoeficientEnabled == false || coeficientEnabled == false ?
                                                                    <FormText money currency={currency} precisions={precisions} redraw value={priceTemp} editing={editing} title={lang.product_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPriceTemp(txt); setNeedSave(true); }} />
                                                                    :
                                                                    <FormNote title={lang.product_price_coeficient} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                                }
                                                                <FormText money currency={currency} precisions={precisions} redraw value={priceRetailTemp} editing={editing} title={lang.product_price_retail} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setPriceRetailTemp(txt); setNeedSave(true); }} />
                                                                <FormSpace />
                                                            </div>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </div>

                                    <FormSpace />
                                    <div style={{ ...showDetails == true ? styles.BlockRow : styles.Block }}>
                                        <div style={{ ...styles.Block, width: '50%' }}>
                                            <div style={{ ...styles.Block, width: '98%', backgroundColor: color.white, borderRadius: radius }}>
                                                <div style={{ ...styles.Block, width: '98%' }}>
                                                    {/* NASTAVENIA PRODUKTU */}
                                                    <FormSpace />
                                                    <FormLabel editing={false} title={lang.product_settings} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                                    <FormText require redraw help_id={9} value={unit} editing={editing} title={lang.product_unit} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setUnit(txt); setNeedSave(true); }} />
                                                    <FormText require redraw value={quantityPackage} editing={editing} title={lang.product_quantity_package} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setQuantityPackage(txt); setNeedSave(true); }} />
                                                    <FormText help_id={13} redraw value={details} editing={editing} title={lang.product_details} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setDetails(txt); setNeedSave(true); }} />
                                                    <FormNote title={lang.product_details_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                    <FormText redraw numeric value={guarantee} editing={editing} title={lang.product_guarantee} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setGuarantee(txt); setNeedSave(true); }} />

                                                    {props.system.product_book_1 == true ?
                                                        <FormSelect require value={book1} items={false} field={'name'} codebook={global.codebook.product_book_1} editing={editing} title={props.system.product_book_label_1} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => { setBook1(id); setNeedSave(true); }} />
                                                        : null}
                                                    {props.system.product_book_2 == true ?
                                                        <FormSelect require value={book2} items={false} field={'name'} codebook={global.codebook.product_book_2} editing={editing} title={props.system.product_book_label_1} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => { setBook2(id); setNeedSave(true); }} />
                                                        : null}
                                                    {props.system.product_book_3 == true ?
                                                        <FormSelect require value={book3} items={false} field={'name'} codebook={global.codebook.product_book_3} editing={editing} title={props.system.product_book_label_1} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => { setBook3(id); setNeedSave(true); }} />
                                                        : null}
                                                    <FormSpace />
                                                </div>
                                            </div>
                                        </div>

                                        {showDetails == true ?
                                            <div style={{ ...styles.Block, width: '50%' }}>
                                                <div style={{ ...styles.Block, width: '98%', backgroundColor: color.white, borderRadius: radius }}>
                                                    <div style={{ ...styles.Block, width: '98%' }}>

                                                        {/* POPIS PRODUKTU */}
                                                        <FormSpace />
                                                        <FormLabel editing={false} title={lang.product_description_information} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                                        <FormText redraw value={descriptionShort} editing={editing} title={lang.product_description_short} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setDescriptionShort(txt); setNeedSave(true); }} />
                                                        <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setNote(txt); setNeedSave(true); }} />
                                                        <FormText help_id={10} redraw value={tags} editing={editing} title={lang.tags} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setTags(txt); setNeedSave(true); }} />
                                                        <FormNote title={lang.tags_text} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                                        <FormSpace />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </div>

                                    {props.system.eshop_enabled == true && itemID != 0 && typ == 1 || typ == 3 ?
                                        // PARAMETRE A SPECIFIKACIA PRODUKTU
                                        <>
                                            <FormSpace />
                                            <div style={{ ...styles.Block, width: '50%' }}>
                                                <div style={{ ...styles.Block, width: '98%', backgroundColor: color.white, borderRadius: radius }}>
                                                    <div style={{ ...styles.Block, width: '98%' }}>
                                                        <FormSpace />
                                                        <FormLabel editButton={editing} buttonIcon={faPlus} editing={false} title={lang.product_params_label} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => { setParamSelected(false); setShowParam(true); }} />

                                                        {showParam == true ?
                                                            <ProductParams product_id={itemID} param={paramSelected} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={ParamResult.bind(this)} />
                                                            : null}

                                                        {params != false ? params.map((item, index) => (
                                                            <div key={index} onClick={() => EditParam(item)} style={{ ...styles.BlockRowRaw, cursor: 'pointer' }}>
                                                                <div style={{ ...styles.BlockRight, width: column1, marginRight: 10, height: 30 }}>
                                                                    <p style={{ ...styles.TextTiny }}>{item.name}:</p>
                                                                </div>
                                                                {item.color != undefined ?
                                                                    item.color != false ?
                                                                        <div style={{ ...styles.BlockRowRaw, width: column2 }}>
                                                                            <div style={{ ...styles.BlockLeft, width: 32, height: 30 }}>
                                                                                <div style={{ ...styles.BlockLeft, width: 24, height: 24, borderRadius: 12, backgroundColor: item.color }}>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ ...styles.BlockLeft, width: column2 - 32, height: 30 }}>
                                                                                <p style={{ ...styles.TextTiny }}>{item.text} {item.unit}</p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div style={{ ...styles.BlockLeft, width: column2, height: 30 }}>
                                                                            <p style={{ ...styles.TextTiny }}>{item.text} {item.unit}</p>
                                                                        </div>

                                                                    :
                                                                    <div style={{ ...styles.BlockLeft, width: column2, height: 30 }}>
                                                                        <p style={{ ...styles.TextTiny }}>{item.text} {item.unit}</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )) : false}
                                                        <FormSpace />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : null}

                                    <FormSpace />
                                    {itemID == 0 && typ != 2 ?
                                        <div style={{ ...styles.Block, width: showDetails == true ? '98%' : '50%', backgroundColor: color.white, borderRadius: radius }}>
                                            <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                                {/* SKLADOVE INFORMACIE */}
                                                <FormSpace />
                                                <p style={{ ...styles.TextLarge, color: color.black, fontWeight: '600' }}>{lang.product_stock_information}:</p>
                                                <p style={{ ...styles.TextXSmall, color: color.black, marginTop: 5 }}>{lang.stock_starting_quantity}:</p>
                                                <FormSpace />
                                                <div style={{ ...styles.BlockCenter, flexDirection: 'row', flexWrap: 'wrap' }}>
                                                    {stocks != false ? stocks.map((item, index) => (
                                                        <div key={item.id} style={{ ...styles.Block, width: stockCellWidth, border: color.border, margin: 5, borderRadius: 5 }}>
                                                            <div style={{ ...styles.Block, height: 30, backgroundColor: global.stock_color[item.type] }}>
                                                                <div style={{ ...styles.Block, width: stockCellWidth - 8, height: 30 }}>
                                                                    <TextLine text={item.code} fontSize={global.font_tiny} color={color.black} />
                                                                </div>
                                                            </div>
                                                            <div style={{ ...styles.Block, height: stockCellHeight - 30 }}>
                                                                <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{lang.stock_quantity}</p>
                                                                <TextInput numeric center precision={0} redraw theme={props.theme} enabled={true} lang={lang} value={item.quantity} label={''} width='90%' func={(txt) => { item.quantity = txt; setNeedSave(true); }} />
                                                            </div>
                                                        </div>
                                                    )) : null}
                                                </div>
                                                <FormSpace />
                                            </div>
                                        </div>
                                        : null}

                                    {showDetails == true ? editing == true ?
                                        typ != 2 && props.system.conditions_enabled == true ?
                                            <div style={{ ...styles.Block, width: '98%', marginTop: 30, backgroundColor: color.white, borderRadius: global.radius }}>
                                                <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                                    <FormSpace />
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        {conditionID > 0 ?
                                                            <div style={{ ...styles.BlockLeft, width: undefined, height: 40, marginRight: 10 }}>
                                                                <FontAwesomeIcon style={{ height: 16, color: color.darker_green }} icon={faCheckCircle} />
                                                            </div>
                                                            : null}
                                                        <div style={{ ...styles.BlockLeft, width: undefined, height: 40 }}>
                                                            <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{conditionID == 0 ? lang.cond : lang.cond_active}</p>
                                                        </div>
                                                        {conditionID > 0 ?
                                                            <div style={{ ...styles.BlockLeft, width: undefined, height: 40, marginLeft: 10 }}>
                                                                <DeleteButton ask text={lang.cond_delete_ask} theme={props.theme} lang={props.lang} func={() => setConditionID(0)} />
                                                            </div>
                                                            : null}
                                                    </div>

                                                    {conditionID == 0 ?
                                                        <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.cond_note}</p>
                                                        : null}
                                                    <div style={{ ...styles.BlockRow }}>
                                                        <div style={{ ...styles.BlockLeft, width: '50%' }}>

                                                        </div>
                                                        <div style={{ ...styles.BlockRight, width: '50%' }}>

                                                        </div>
                                                    </div>

                                                    {conditionID > 0 && conditionName != '' ?
                                                        <div style={{ ...styles.BlockLeft }}>
                                                            <p style={{ ...styles.TextXSmall, textAlign: 'center', marginLeft: 26 }}>{lang.cond_name}: <b>{conditionName}</b></p>
                                                        </div>
                                                        : null}

                                                    <FormSpace />
                                                    <div style={{ ...styles.Block }}>
                                                        <div style={{ ...styles.BlockRowRaw }}>
                                                            <div style={{ ...styles.Block, width: undefined, height: 40 }}>
                                                                <Button onClick={() => Condition()} style={{ ...styles.ButtonThemed, color: color.black, width: 180, backgroundColor: color.button_action }}>{conditionID == 0 ? lang.cond_new : lang.cond_update}</Button>
                                                            </div>
                                                            <div style={{ ...styles.Block, width: undefined, height: 40, marginLeft: 10 }}>
                                                                <IconButton onClick={() => setShowConditions(true)} style={{ ...styles.ButtonIcon, width: 36, height: 36, borderRadius: 18 }}>
                                                                    <FontAwesomeIcon style={{ height: 16 }} icon={faList} />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormSpace />
                                                </div>
                                            </div>
                                            : null
                                        :
                                        typ != 2 ?
                                            <div style={{ ...styles.Block, width: '98%', marginTop: 30, backgroundColor: color.white, borderRadius: global.radius }}>
                                                <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                                    <FormSpace />
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        {conditionID > 0 ?
                                                            <div style={{ ...styles.BlockLeft, width: undefined, height: 40, marginRight: 10 }}>
                                                                <FontAwesomeIcon style={{ height: 16, color: color.darker_green }} icon={faCheckCircle} />
                                                            </div>
                                                            : null}
                                                        <div style={{ ...styles.BlockLeft, width: undefined, height: 40 }}>
                                                            <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{conditionID == 0 ? lang.cond : lang.cond_active}</p>
                                                            {conditionID > 0 && conditionName != '' ?
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <p style={{ ...styles.TextXSmall, textAlign: 'center' }}>{lang.cond_name}: <b>{conditionName}</b></p>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <FormSpace />
                                                </div>
                                            </div>
                                            : null
                                        : null}

                                    {props.system.eshop_enabled == true && itemID > 0 ?
                                        <div style={{ ...styles.Block, marginTop: 10 }}>
                                            <Button onClick={() => setShowPage(true)} style={{ ...styles.ButtonOutlined, width: 220 }}>{pageID == 0 ? lang.web_page_product_new : lang.web_page_product}</Button>
                                        </div>
                                        : null}

                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        {itemID > 0 ?
                                            <Button onClick={() => ChangeDetails(!showDetails)} style={{ ...styles.ButtonLink }}>{showDetails == false ? lang.show_detail_settings : lang.hide_detail_settings}</Button>
                                            : null}
                                    </div>

                                    {editing == true && itemID > 0 && (typ == 1 || typ == 3) ?
                                        <div style={{ ...styles.Block, marginTop: 28 }}>
                                            <FormInfo title={lang.product_copy_info} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                        </div>
                                        : null}

                                    {editing == false && itemID > 0 && (typ == 1 || typ == 3) ?
                                        <div style={{ ...styles.Block, marginTop: 28 }}>
                                            <Button onClick={() => setShowDuplicate(true)} style={{ ...styles.ButtonLink }}>{lang.product_copy}</Button>
                                            <p style={{ ...styles.TextTiny }}>{lang.product_copy_note}</p>
                                        </div>
                                        : null}

                                    <FormSpace height={global.list_padding} />
                                </div>
                                : null}

                        </div>
                        : null}

                    {tabIndex == 2 ?
                        /*
                        *
                        * =================================================================================================================
                        * ZOZNAM VARIACII
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.Block, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {variations == true ?
                                <div style={{ ...styles.Block, maxWidth: global.max_width }}>
                                    <FormSpace />
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, width: '50%', height: 50 }}>
                                            <p style={{ ...styles.TextLarge, color: color.black, fontWeight: '600' }}>{lang.product_variations}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '50%', height: 50 }}>
                                            <div style={{ ...styles.BlockRowRaw, height: 50 }}>
                                                {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                                    <Button onClick={() => AddNewProduct()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_gray, width: 180 }}>
                                                        <FontAwesomeIcon style={{ height: 16, color: color.black, marginRight: 6 }} icon={faDownload} />
                                                        <p style={{ ...styles.TextTiny, color: color.black }}>{lang.product_variation_add_product}</p>
                                                    </Button>
                                                    : null}
                                                {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                                    <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180, marginLeft: 10 }}>
                                                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                        {lang.product_variation_new}
                                                    </Button>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>

                                    {itemID == 0 ?
                                        <p style={{ ...styles.TextTiny }}>{lang.product_variations_text}</p>
                                        : null}

                                    {/* ZOZNAM VARIACII */}
                                    <div style={{ ...styles.Block, marginTop: 10 }}>
                                        <div style={{ ...styles.BlockRow, border: color.border }}>
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableLabelHeight, justifyContent: 'center' }}></div>
                                            <div style={{ ...styles.BlockLeft, width: '15%', height: tableLabelHeight, borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{lang.product_variation_code} { }</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '25%', height: tableLabelHeight, borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{lang.product_variation_name} { }</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: '20%', height: tableLabelHeight, borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny }}>{lang.product_cost}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: '20%', height: tableLabelHeight, borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny }}>{lang.product_price}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: '15%', height: tableLabelHeight, borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny }}>{lang.product_quantity_stock_}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: rowHeight, height: tableLabelHeight, borderLeft: color.border }}>
                                            </div>
                                        </div>


                                        {variationsItems != false ?
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId="droppable">
                                                    {(provided) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            style={{ ...styles.Block, width: dialogWidth - 10 }}
                                                        >
                                                            {variationsItems.map((item, index) => (
                                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <Paper
                                                                                key={item.id}
                                                                                elevation={item.id == overID ? 4 : 1}
                                                                                style={{
                                                                                    ...styles.Block,
                                                                                    width: dialogWidth > global.max_width ? global.max_width : dialogWidth,
                                                                                    backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                                                    borderRadius: global.items_radius,
                                                                                    minHeight: rowHeight,
                                                                                    cursor: 'pointer',
                                                                                    marginTop: 5, marginBottom: 5,
                                                                                    paddingTop: 2, paddingBottom: 2,
                                                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                                                }}
                                                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                                            >
                                                                                <div style={{ ...styles.BlockRow }}>
                                                                                    <div onClick={() => editVariation(item)} style={{ ...styles.Block, width: rowHeight, height: rowHeight, justifyContent: 'center' }}>
                                                                                        <Avatar picture image={item.image} size={rowHeight - 10} theme={props.theme} />
                                                                                    </div>
                                                                                    <div onClick={() => editVariation(item)} style={{ ...styles.BlockLeft, width: '15%', height: rowHeight }}>
                                                                                        <p style={{ ...styles.TextTiny }}>{item.code}</p>
                                                                                    </div>
                                                                                    <div onClick={() => editVariation(item)} style={{ ...styles.BlockLeft, width: '25%', height: rowHeight }}>
                                                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name} { }</p>
                                                                                    </div>
                                                                                    <div onClick={() => editVariation(item)} style={{ ...styles.BlockRight, width: '20%', height: rowHeight }}>
                                                                                        <p style={{ ...styles.TextXSmall }}>{FormatMoney(item.cost, precisions)} {currency}</p>
                                                                                    </div>
                                                                                    <div onClick={() => editVariation(item)} style={{ ...styles.BlockRight, width: '20%', height: rowHeight }}>
                                                                                        <p style={{ ...styles.TextXSmall }}>{FormatMoney(item.price, precisions)} {currency}</p>
                                                                                    </div>
                                                                                    <div onClick={() => editVariation(item)} style={{ ...styles.Block, width: '15%', height: rowHeight }}>
                                                                                        <p style={{ ...styles.TextXSmall }}>{item.quantity_stock}</p>
                                                                                    </div>
                                                                                    <div style={{ ...styles.Block, width: rowHeight, height: rowHeight }}>
                                                                                        <Tooltip title={lang.product_variation_eject}>
                                                                                            <IconButton onClick={() => ProductEject(item)} style={{ ...styles.ButtonIcon }}>
                                                                                                <FontAwesomeIcon style={{ width: 12, color: color.dark_gray }} icon={faUpload} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            </Paper>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext >
                                            : null}
                                    </div>
                                </div>
                                : <EmptyList row small lang={lang} />
                            }

                        </div>
                        : null}

                    {tabIndex == 3 ?
                        /*
                        *
                        * =================================================================================================================
                        * INFORMACIE O PRODUKTE
                        * =================================================================================================================
                        *
                        */

                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <ProductInfo product_id={itemID} dialog={false} dialogWidth={dialogWidth} dialogHeight={dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} />
                        </div>
                        : null}

                    {tabIndex == 4 ?
                        /*
                        *
                        * =================================================================================================================
                        * BALICEK - PRODUKTY
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.Block, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, maxWidth: global.max_width }}>
                                <FormSpace />
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: 50 }}>
                                        <p style={{ ...styles.TextLarge, color: color.black, fontWeight: '600' }}>{lang.product_sub_products_label}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '50%', height: 50 }}>
                                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                            <Button onClick={() => AddProducts()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                {lang.product_sub_products_add}
                                            </Button>
                                            : null}
                                    </div>
                                </div>

                                {itemID == 0 ?
                                    <p style={{ ...styles.TextTiny }}>{lang.product_variations_text}</p>
                                    : null}

                                {/* ZOZNAM PRODUKTOV */}
                                <div style={{ ...styles.Block, marginTop: 10 }}>
                                    <div style={{ ...styles.BlockRow, border: color.border, paddingTop: 5, paddingBottom: 6 }}>
                                        <div style={{ ...styles.BlockLeft, width: rowHeight + 10, justifyContent: 'center' }}></div>
                                        <div style={{ ...styles.BlockLeft, width: '24%', borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{lang.product_code}</p>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '68%', borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{lang.product_name}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 150, borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{lang.product_packet_quantity}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 110, borderLeft: color.border }}>
                                        </div>
                                    </div>
                                    {products != false ? products.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block,
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowProductHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                <div onClick={() => ProductPreview(item)} style={{ ...styles.BlockRow, width: '90%' }}>
                                                    <div style={{ ...styles.BlockLeft, width: rowHeight, height: rowProductHeight, justifyContent: 'center' }}>
                                                        <Avatar picture image={item.image} size={rowHeight - 5} theme={props.theme} />
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '25%', height: rowProductHeight }}>
                                                        <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{item.code}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '70%', height: rowProductHeight }}>
                                                        {item.parent_id > 0 ?
                                                            <p style={{ ...styles.TextTiny }}>{item.parent_name}</p>
                                                            : null}
                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name}</p>
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.Block, width: 150, height: rowProductHeight }}>
                                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                        <PlusMinus min={1} value={item.quantity} item={item} theme={props.theme} lang={props.lang} func={PlusMinusResult.bind(this)} />
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.Block, width: 75, height: rowProductHeight }}>
                                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).delete == true ?
                                                        <DeleteButton ask text={lang.product_delete_from_list} icon_color={color.black} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteItem(item)} />
                                                        : null}
                                                </div>
                                            </div>
                                        </Paper>

                                    )) : null
                                    }
                                </div>
                            </div>
                        </div>
                        : null}

                    {tabIndex == 1 ?
                        /*
                        *
                        * =================================================================================================================
                        * HISTORIA
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs stats logs={logs} lang={props.lang} stats_type={0} item_id={itemID} currency={currency} organization={props.organization} user={props.user} token={props.token} theme={props.theme} locale={props.locale} precisions={precisions} />
                        </div>
                        : null}

                    {tabIndex == 5 ?
                        /*
                        *
                        * =================================================================================================================
                        * SKLAD - pohyby na sklade
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.Block, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight }}>
                            <div style={{ ...styles.Block, maxWidth: global.max_width }}>
                                <StockHistory showNew showSelect stock_uid={stockUID} width={dialogWidth} height={dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight} organization={props.organization} locale={props.locale} system={props.system} user={props.user} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={StockHistoryResult.bind(this)} />
                            </div>
                        </div>
                        : null}

                    {tabIndex == 6 ?
                        /*
                        *
                        * =================================================================================================================
                        * GALERIA FOTOGRAFII
                        * =================================================================================================================
                        *
                        */

                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Gallery permited={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} galleryID={galleryID} galleryTyp={0} width={dialogWidth} height={dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={GalleryResult.bind(this)} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {tabIndex == 0 && editing == true && needSave == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            {editing == true && itemID != 0 ?
                                <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                :
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                            }
                        </div>
                    </div>
                </div >

                {showDistributorCodes == true ?
                    <DistributorsCodes product remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={DistributorCodesResult.bind(this)} />
                    : null}

                {showPage == true ?
                    <WebPageDesigner item={pageID == 0 ? false : { id: pageID }} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={PageResult.bind(this)} />
                    : null}

                {showDuplicate == true ?
                    <ProductDuplicate product_id={itemID} organization={props.organization} settings={props.settings} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DuplicateResult.bind(this)} />
                    : null}

                {showAskEjectProduct == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.product_variation_eject} text={lang.product_variation_eject_ask} sub_text={lang.product_variation_eject_note} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={ProductEjectResult.bind(this)} />
                    : null}

                {showSelectProducts == true ?
                    <ProductSelect info={lang.product_select_variant} country_id={countryID} showVariations={false} hide_grouped={true} hide_multiple={true} only_sku={true} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductSelectAskResult.bind()} />
                    : null}

                {showAskInsertProduct == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.product_variation_add_product_label} text={lang.product_variation_add_product_ask} sub_text={lang.product_variation_add_product_note} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={ProductSelectResult.bind(this)} />
                    : null}

                {showCondition == true ?
                    <ConditionDesigner item_id={conditionID} module_id={global.model.products} organization={props.organization} locale={props.locale} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ConditionResult.bind(this)} />
                    : null}

                {showConditions == true ?
                    <Conditions item_id={conditionID} module_id={global.model.products} organization={props.organization} locale={props.locale} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ConditionsResult.bind(this)} />
                    : null}

                {showVariation == true ?
                    <ProductVariation module_id={module_id} stocks={stocks} count={variationsItems == false ? 0 : variationsItems.length} code={code} item={selectedItem} productID={itemID} productEnabled={enabled} countryID={countryID} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={VariationResult.bind()} />
                    : null}

                {showInfo == true ?
                    <ProductInfo product_id={itemSelected.id} dialog={true} dialogWidth={dialogWidth - 20} dialogHeight={dialogHeight - 20} organization={props.organization} locale={props.locale} user={props.user} system={props.system} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={() => setShowInfo(false)} />
                    : null}

                {selectPhoto == true ?
                    <Photos autoSelect typ={global.image.products} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} locale={props.locale} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {selectProducts == true ?
                    <ProductSelect excluded={itemsID} country_id={countryID} showVariations={true} hide_grouped={true} hide_multiple={true} only_sku={true} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductResult.bind()} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}


export const ProductParams = (props) => {
    /*
        PRIDAVANIE A UPRAVA PARAMETROV PRODUKTU
    */

    const [paramID, setParamID] = useState(props.param == false ? -1 : props.param.codebook_id);
    const [paramSelected, setParamSelected] = useState(props.param == false ? false : props.param.codebook);
    const [bookID, setBookID] = useState(props.param == false ? -1 : props.param.codebook != false ? props.param.codebook.codebook_id : -1);
    const [bookItems, setBookItems] = useState(false);

    const [valueID, setValueID] = useState(props.param == false ? -1 : props.param.book_id);
    const [value, setValue] = useState(props.param == false ? '' : props.param.value);
    const [unit, setUnit] = useState(props.param == false ? '' : props.param.unit);

    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 650 > width ? width : 650;
    const dialogHeight = 280;


    let { func } = props;
    let lang = props.lang;
    let color = global.themes[props.theme];
    let column1 = props.column1;
    let column2 = props.column2;
    let buttonHeight = 90;
    let radius = props.radius == undefined ? global.radius : props.radius;

    var running = false;

    useEffect(() => {

        if (running == false) {
            db_get();
            running = true;
        }

        return () => {
        };

    }, []);

    const db_get = async () => {
        try {
            const response = await fetch(
                global.db_url + 'codebooks', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    book_id: global.codebook.product_parameters,
                    filter: false
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                if (json.items != false) {
                    var tmp = json.items.filter(x => x.enabled == true);
                    if (tmp.length == 0) {
                        tmp = false;
                    }
                    setBookItems(tmp);
                } else {
                    setBookItems(json.items);
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    const db_update = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'product_param_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                func(true, true);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const ChangeParam = (id) => {
        var codebook_id = 0;
        var parameter = 0;
        if (bookItems != false) {
            var tmp = bookItems.find(x => x.id == id);
            if (tmp != undefined) {
                setParamSelected(tmp);
                codebook_id = tmp.codebook_id;
                parameter = tmp.parameter;
            }
        }

        setParamID(id);
        setValueID(-1);

        if (parameter == 3) {
            setBookID(codebook_id);
        }
    }

    const Close = () => {
        func(false, false);
    }

    const Process = () => {

        let data = {
            id: props.param == false ? 0 : props.param.id,
            organization_id: props.organization.id,
            product_id: props.product_id,
            codebook_id: paramID,
            enabled: true,
            value: paramSelected.parameter == 0 || paramSelected.parameter == 1 ? value : 0,
            text: paramSelected.parameter == 2 ? value : '',
            book_id: paramSelected.parameter == 3 ? valueID : 0,
            unit: unit
        }
        Debug(data);
        db_update(data);
    }

    const Check = () => {
        var result = false;
        if (paramID > 0) {
            Debug(valueID);
            if (paramSelected.parameter == 3) {
                if (valueID > 0) {
                    result = true;
                }
            } else {
                if (value != '') {
                    result = true;
                }
            }
        }

        return result;
    }

    const Delete = () => {
        let data = {
            id: props.param == false ? 0 : props.param.id,
            enabled: false,
        }
        Debug(data);
        db_update(data);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1010 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faInfo} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 180, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.param == false ? lang.product_param_new : lang.product_param_update}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <DeleteButton ask label={lang.parameter_delete + '?'} width={220} icon={faTrash} position={'bottom'} backgroundColor={color.white} text={lang.parameter_delete_text} text_note={''} tooltip={lang.parameter_delete} theme={props.theme} lang={props.lang} func={() => Delete()} />
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, width: undefined }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div style={{ ...styles.BlockRight, marginRight: 10, marginTop: 18, justifyContent: 'flex-start', width: column1 }}>
                                <FormSelect redraw value={paramID} items={bookItems} field={'name'} editing={true} width={'100%'} column2={column1} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => ChangeParam(id)} />
                            </div>
                            <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', width: column2 }}>
                                <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', width: column2, maxWidth: column2 }}>
                                    <div style={{ ...styles.BlockRowRaw, width: column2, maxWidth: column2 }}>
                                        {paramSelected != false ?
                                            paramSelected.parameter == 3 ?
                                                <div style={{ ...styles.Block, marginTop: 18 }}>
                                                    <FormSelect redraw colors value={valueID} items={false} field={'name'} codebook={bookID} editing={true} width={'100%'} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setValueID(id)} />
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockRow, marginTop: 4 }}>
                                                    <TextInput
                                                        redraw
                                                        width={column2 - 80 - 10}
                                                        numeric={paramSelected.parameter == 0 || paramSelected.parameter == 1 ? true : false}
                                                        lang={lang}
                                                        value={value}
                                                        radius={radius}
                                                        label={lang.product_param_value}
                                                        func={(txt) => { setValue(txt) }}
                                                    />
                                                    <div style={{ ...styles.BlockRight, width: 90 }}>
                                                        <TextInput
                                                            redraw
                                                            width={80}
                                                            lang={lang}
                                                            value={unit}
                                                            radius={radius}
                                                            label={lang.product_param_unit}
                                                            func={(txt) => { setUnit(txt) }}
                                                        />
                                                    </div>
                                                </div>
                                            : null}

                                    </div>
                                    {paramSelected != false ?
                                        <p style={{ ...styles.TextTiny, marginTop: 2, color: color.dark_gray }}>{lang.parameter_types[paramSelected.parameter]}</p>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                            {Check() == true ?
                                <Button onClick={() => Process()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{props.param == false ? lang.parameter_add : lang.save}</Button>
                                : null}
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.cancel}</Button>

                        </div>
                    </div>
                </div>
            </Paper>
        </Backdrop >
    );
}

