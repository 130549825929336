/*
*
* =================================================================================================================

    DIALOG - POPIS

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { DeleteButton, DialogYesNo, EmptyList, FormError, Icon, Loading, ShowError, ShowOK, Avatar, TextLine, ButtonChangeStyle, DialogImagePreview } from './items';
import { styles } from './styles';
import { Backdrop, Button, FormControlLabel, IconButton, MenuItem, Pagination, Paper, Radio, RadioGroup, Select, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faImages, faLayerGroup, faPlug, faPlus, faPlusCircle, faQuestion, faTableCells, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetLeftPosition, GetPages, UniqueID } from './functions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Photos } from './photos';

export const Gallery = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permited = props.permited == true ? true : false;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [galleryID, setGalleryID] = useState(parseInt(props.galleryID) == 0 ? 0 : parseInt(props.galleryID));
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [overID, setOverID] = useState(-1);

    // DATA
    const [selectPhoto, setSelectPhoto] = useState(0);
    const [showCreateGallery, setShowCreateGallery] = useState(false);
    const [ordering, setOrdering] = useState(0);
    const [showPreview, setShowPreview] = useState(0);

    const dialogWidth = props.width;
    const dialogHeight = props.height;

    // CONSTANTS
    const column1 = 160;
    const column2 = 600 - column1 - 40;
    const rowSize = 160;
    const tabHeight = 50;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            if (props.galleryID > 0) {
                // UDAJE Z DB
                db_get(props.galleryID);
            }

            running = true;
        }

    }, []);

    const db_get = async (gallery_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'gallery', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    gallery_id: gallery_id
                })
            })

            const json = await response.json();
            Debug('GALLERY');
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'gallery_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setGalleryID(json.ok);
                func(true, json.ok);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_items_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'gallery_items_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_get(galleryID);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_reorder = async (items) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reorder_items', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.gallery_items,
                    items: items
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false, false);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (result.destination) {
            const tmp = reorder(items, result.source.index, result.destination.index);

            var n = 1;
            var items_ = [];
            tmp.forEach(item => {
                item.priority = n;

                let data = {
                    id: item.id,
                    priority: n
                }
                items_.push(data);
                n++
            });

            setItems(tmp);
            db_reorder(items_);
        }
    }

    const DeleteImage = (item) => {
        let data = {
            id: item.id,
            enabled: false,
            link: ''
        }
        db_items_update(data);
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            let data = {
                id: 0,
                organization_id: props.organization.id,
                gallery_id: galleryID,
                enabled: true,
                link: path + file,
                priority: 100,
            }
            db_items_update(data);
        }
    }


    const CreateResult = (typ) => {
        if (typ == true) {

            let data = {
                id: 0,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                enabled: true,
                typ: props.galleryTyp
            };

            db_update(data);

            setShowCreateGallery(false);
        } else {
            func(false, 0);
        }
    }

    const ChangeStyle = (id) => {
        setOrdering(id);
    }

    const Preview = (item) => {
        setItemSelected(item);
        setShowPreview(true);
    }

    return (
        <div style={{ width: dialogWidth, height: dialogHeight, backgroundColor: color.back, borderRadius: props.radius }}>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: dialogHeight }}>
                <div style={{ ...styles.Block, height: tabHeight }}>
                    {galleryID > 0 ?
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div style={{ ...styles.Block, width: undefined, height: tabHeight, marginRight: 10 }}>
                                <p style={{ ...styles.TextTiny }}>{lang.gallery_display}</p>
                            </div>
                            <div style={{ ...styles.Block, width: undefined, height: tabHeight }}>
                                <ButtonChangeStyle value={ordering} icon1={faTableCells} icon2={faBars} theme={props.theme} func={(id) => ChangeStyle(id)} />
                            </div>
                        </div>
                        : null}
                </div>

                <div style={{ ...styles.BlockCenter, height: dialogHeight - tabHeight - 6, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    {galleryID == 0 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - tabHeight - 6, paddingTop: 0 }}>
                            <p style={{ ...styles.TextXSmall }}>{lang.gallery_no_exist}</p>
                            {permited == true ?
                                <Button onClick={() => setShowCreateGallery(true)} variant="outlined" component="label" style={{ ...styles.ButtonOutlined, marginTop: 20 }}>
                                    {lang.gallery_create}
                                </Button>
                                : null}
                        </div>
                        :
                        <div style={{ ...styles.BlockCenter, flexDirection: ordering == 1 ? 'column' : 'row', flexWrap: ordering == 1 ? undefined : 'wrap' }}>

                            {items != false ? ordering == 1 ?
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{ ...styles.Block }}
                                            >
                                                {items.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <Paper
                                                                    key={item.id}
                                                                    elevation={item.id == overID ? 4 : 1}
                                                                    style={{
                                                                        ...styles.Block,
                                                                        width: 400,
                                                                        minHeight: rowSize / 2,
                                                                        backgroundColor: color.white,
                                                                        borderRadius: global.items_radius,
                                                                        cursor: 'pointer',
                                                                        marginTop: 6,
                                                                    }}
                                                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                                    onClick={() => Preview(item)}
                                                                >
                                                                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                                        <div style={{ ...styles.Block, width: 40, height: rowSize / 2 }}>
                                                                            <p style={{ ...styles.TextTiny }}>{index + 1}</p>
                                                                        </div>
                                                                        <div style={{ ...styles.Block, width: rowSize / 2 + 10, height: rowSize / 2 }}>
                                                                            <Avatar picture image={item.link} size={rowSize / 2 - 16} theme={props.theme} />
                                                                        </div>
                                                                        <div style={{ ...styles.BlockLeft, width: '60%', height: rowSize / 2 }}>
                                                                        </div>
                                                                        <div style={{ ...styles.Block, width: 80, height: rowSize / 2, borderLeft: color.border }}>
                                                                            {permited == true ?
                                                                                <DeleteButton ask text={lang.gallery_photo_delete} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteImage(item)} />
                                                                                : null}
                                                                        </div>
                                                                    </div>

                                                                </Paper>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext >
                                :
                                items.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            width: rowSize,
                                            minHeight: rowSize,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            cursor: 'pointer',
                                            margin: 6,
                                        }}
                                        onClick={() => Preview(item)}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.Block, position: 'relative' }}>
                                            <Avatar picture image={item.link} size={rowSize - 16} theme={props.theme} />
                                            <div style={{ ...styles.Block, width: 40, height: 36, position: 'absolute', top: 0, right: 0 }}>
                                                {permited == true ?
                                                    <DeleteButton ask text={lang.gallery_photo_delete} backgroundColor={color.white} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteImage(item)} />
                                                    : null}
                                            </div>
                                        </div>

                                    </Paper>
                                ))
                                :
                                <div style={{ ...styles.Block, height: 100 }}>
                                    <EmptyList row small lang={lang} />
                                </div>
                            }

                            {permited == true ?
                                <div style={{ ...styles.Block, width: ordering == 1 ? '100%' : rowSize, height: ordering == 1 ? rowSize / 2 : rowSize, marginTop: ordering == 1 ? 30 : 0 }}>
                                    <Button onClick={() => setSelectPhoto(true)} variant="outlined" component="label" style={{ ...styles.ButtonOutlined, backgroundColor: '#00000000', width: rowSize - 16, height: rowSize - 16 }}>
                                        <div style={{ ...styles.Block }}>
                                            <FontAwesomeIcon style={{ height: 20 }} icon={faPlusCircle} />
                                            {lang.gallery_add}
                                        </div>
                                    </Button>
                                </div>
                                : null}

                        </div>
                    }

                </div >
            </div >

            {showPreview == true ?
                <DialogImagePreview file={itemSelected.link} radius={props.radius} lang={props.lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} theme={props.theme} func={() => setShowPreview(false)} />
                : null}

            {showCreateGallery == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.gallery_create} text={lang.gallery_create_ask} sub_text={props.galleryTyp == 0 ? lang.gallery_create_askproduct : ''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={CreateResult.bind(this)} />
                : null}

            {selectPhoto == true ?
                <Photos autoSelect typ={global.image.gallery} max_size={1200} full title={lang.photo_select} organization={props.organization} locale={props.locale} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </div >
    );
}


