/*
*
* =================================================================================================================
* TVORBA PODMIENOK

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <ConditionDesigner item_id={1} organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    item_id     -> id záznamu v DB, 0 = nový záznam (nová podmienka)

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { FormError, Icon, Loading, ShowError, ShowOK, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, FormControlLabel, IconButton, MenuItem, Paper, Radio, RadioGroup, Select, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faLayerGroup, faList, faMinus, faMinusCircle, faPen, faPlus, faPlusCircle, faQuestion, faTrash, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { GetDatum2, GetDatumTime2, GetTimeHM, Today } from './functions_date';
import { Debug, GetLeftPosition } from './functions';

export const ConditionDesigner = (props) => {

    let color = global.themes[props.theme];
    var lang = props.lang;

    // IMAGES
    //const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [editingID, setEditingID] = useState(-1);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // PODMIENKA
    const [name, setName] = useState('');
    const [eventID, setEventID] = useState(0);
    const [eventEnabled, setEventEnabled] = useState(true); // povolený výber spustenia podmienky
    const [result, setResult] = useState(false);
    const [resultLabel, setResultLabel] = useState('');
    const [resultID, setResultID] = useState(-1);
    const [results, setResults] = useState(false);
    const [conditions, setConditions] = useState([{ id: 0, operator: null, pattern: 1, statements: [] }]);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    const themeColor = color.conditions;
    const polygonSize = 80;
    const lineSize = 15;
    const rowSize = 30;
    const radius = 8;
    const paddingSize = 20;
    const maxWidth = 700;
    const resultWidth = 350;
    const decimal = 3; // POCET DESATINYCH MIEST

    const moduleID = props.module_id;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            var tmp = global.variable_results.filter(x => global.result[moduleID].variables.includes(x.id));
            setResults(tmp);

            if (props.item_id > 0) {
                db_get();
            }

            running = true;
        }
    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_get', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    item_id: props.item_id,
                    model_id: global.model.conditions,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    setName(json.item.name);

                    let data = JSON.parse(json.item.data);
                    setEventID(data.event);
                    setEventEnabled(data.event_enabled);
                    setResult(data.result);
                    setResultID(data.result_id);
                    setResultLabel(data.result_label);
                    setConditions(data.conditions);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    model_id: global.model.conditions,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {

                func(true, json.item_id, data);
                //setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const Close = () => {
        func(false, false);
    }

    const ParseOperator = (id) => {
        var result = lang.cond_undefined;

        if (id > -1) {
            var tmp = global.operators.find(x => x.id === id);

            if (tmp != undefined) {
                if (tmp.lang == undefined) {
                    result = tmp.label;
                } else {
                    result = lang[tmp.lang];
                }
                result = tmp.label;
            }
        }
        return result;
    }

    const ParseVariable1 = (variable) => {
        // PRVA PREMENNA V PODMIENKE -> udaj z global.variables
        var result = '...';
        let tmp = global.variables.find(x => x.id == variable.id);
        if (tmp != undefined) {
            result = lang[tmp.lang];
        }
        return result;
    }

    const ParseVariable2 = (variable) => {
        // DRUHA PREMENNA V PODMIENKE - UDAJ PODLA DATATYPE

        var result = lang.cond_undefined;

        if (variable != false) {
            switch (variable.datatype) {
                case 0: // Decimal 1000.000
                    if (variable.value != '') {
                        result = String(parseFloat(variable.value).toFixed(decimal));
                    }
                    break;
                case 1: // Bigint                    
                    if (variable.value != '') {
                        result = String(parseInt(variable.value));
                    } else {
                        result = '0';
                    }
                    break;
                case 2: // String
                    result = variable.value
                    break;
                case 3: // Boolean
                    if (variable.value != '') {
                        result = variable.value == 'false' ? lang.no : lang.yes
                    }
                    break;
                case 4: // Date
                    if (variable.value != '') {
                        result = GetDatum2(variable.value);
                    }
                    break;
                case 5: // Time
                    if (variable.value != '') {
                        result = GetTimeHM(variable.value);
                    }
                    break;
                case 6: // Datetime
                    if (variable.value != '') {
                        result = GetDatumTime2(variable.value);
                    }
                    break;
                case 7: // Array                
                    variable.value.forEach(item => {
                        if (result != '') {
                            result += ', ';
                        }
                        if (variable.array_datatype == 0) {
                            // Array - Decimal                            
                            result += String(parseFloat(item).toFixed(decimal));
                        }
                        if (variable.array_datatype == 1) {
                            // Array - Bigint                            
                            result += String(parseInt(item));
                        }

                    });

                    break;

                default:
                    result = lang.cond_undefined;
                    break;
            }
        }

        return result;
    }

    const DrawLine = (props) => {
        return (
            <div style={{ ...styles.Block, width: polygonSize }}>
                <div style={{ ...styles.Block, width: 2, height: props.size == undefined ? lineSize : props.size, backgroundColor: themeColor }}></div>
            </div>
        )
    }

    const LineNumber = (props) => {
        return (
            <div style={{ ...styles.Block, width: rowSize - 2, height: rowSize - 2, borderRadius: '50%', backgroundColor: color.gray, border: '1px solid ' + color.dark_gray }}>
                <p style={{ ...styles.TextTiny, fontWeight: '500' }}>{props.number}</p>
            </div>
        )
    }

    const LineOperator = (props) => {

        return (
            <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: 40 }}>
                <div style={{ ...styles.Block, width: rowSize, marginLeft: rowSize / 2 }}>
                    <div style={{ ...styles.Block, width: 2, height: 10, backgroundColor: color.medium_gray }}></div>
                </div>
                <div style={{ ...styles.Block, width: rowSize * 2, height: 20, backgroundColor: color.lighteen_blue, borderRadius: 10, paddingTop: 2, paddingBottom: 2 }}>
                    <p style={{ ...styles.TextTiny, color: color.dark_blue }}>{props.operator}</p>
                </div>
                <div style={{ ...styles.Block, width: rowSize, marginLeft: rowSize / 2 }}>
                    <div style={{ ...styles.Block, width: 2, height: 10, backgroundColor: color.medium_gray }}></div>
                </div>
            </div>
        )
    }

    const VariableEdit1 = (props) => {

        /* ------------------------------------------------------------------------------------
        UPRAVA PREMENNEJ 1

        ------------------------------------------------------------------------------------ */
        const [items, setItems] = useState(false);
        const [value, setValue] = useState(0);

        let { func } = props;

        useEffect(() => {
            var tmp = global.variables.filter(x => global.variable1[moduleID].variables.includes(x.id));
            tmp.forEach(item => {
                item.title = lang[item.lang];
            });
            setItems(tmp);
            setValue(props.statement.variable1.id);

            return () => {
            };

        }, []);

        const ChangeType = (id) => {
            setValue(id);

            var item = global.variables.find(x => x.id === id);

            if (item != undefined) {
                func(props.statement, item);
            }
        }

        return (
            <Select
                value={value}
                onChange={event => ChangeType(event.target.value)}
                size='small'
                style={{ backgroundColor: '#FFFFFF', height: 38, width: '100%', borderRadius: radius }}
                sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: '#000000' }, }}>

                <MenuItem disabled value={-1}><em>{lang.cond_choose}</em></MenuItem>

                {items != false ? items.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                )) : null}
            </Select>
        )
    }

    const Variable1Result = (statement, item) => {
        statement.variable1 = item;
        statement.variable2.datatype = item.datatype;
        statement.operator = -1;
        switch (item.datatype) {
            case 0:
                statement.variable2.value = '0';
                break;
            case 1:
                statement.variable2.value = '0';
                break;
            case 2:
                statement.variable2.value = '';
                break;
            case 3:
                statement.variable2.value = true;
                break;
            case 4:
                statement.variable2.value = Today();
                break;
            case 5:
                statement.variable2.value = '08:00';
                break;
            case 6:
                statement.variable2.value = Today();
                break;
            case 7:
                statement.variable2.value = '';
                break;
        }
        setRedraw(!redraw);
    }

    const OperatorEdit = (props) => {

        /* ------------------------------------------------------------------------------------
        UPRAVA OPERATORA ==, >, <, ....
        typ operatora získa z prvej premennej - VARIABLE 1

        PROPS:
             


        ------------------------------------------------------------------------------------ */
        const [items, setItems] = useState(false);
        const [value, setValue] = useState(0);

        let { func } = props;

        useEffect(() => {

            let datatype = props.statement.variable1.datatype;
            var tmp = global.operators.filter(x => x.datatype == datatype);
            setItems(tmp);
            setValue(props.statement.operator);

            return () => {
            };

        }, []);

        const ChangeType = (id) => {
            setValue(id);
            props.statement.operator = id;
            func(props.statement, id);
        }

        return (
            <Select
                value={value}
                onChange={event => ChangeType(event.target.value)}
                size='small'
                style={{ backgroundColor: '#FFFFFF', height: 38, width: '100%', borderRadius: radius }}
                sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: '#000000' }, }}>

                <MenuItem disabled value={-1}><em>{lang.cond_choose}</em></MenuItem>
                {items != false ? items.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                )) : null}
            </Select>
        )
    }

    const OperatorResult = (statement, id) => {
        statement.operator = id;
        statement.operator_exe = global.operators[id].exe_id;
        setRedraw(!redraw);
    }

    const VariableEdit2 = (props) => {

        /* ------------------------------------------------------------------------------------
        UPRAVA DRUHEJ PREMENNEJ - HODNOTY PODLA DATATYPU PRVEJ PREMENNEJ

        ------------------------------------------------------------------------------------ */
        const [value, setValue] = useState(props.statement.variable2.value);

        let { func } = props;
        let datatype = props.statement.variable1.datatype;

        useEffect(() => {

            return () => {
            };

        }, []);

        const ChangeValue = (txt) => {
            func(props.statement, txt);
            setValue(txt);
        }

        const ChangeRadio = (value_) => {
            func(props.statement, value_);
            setValue(value_);
        }

        return (
            datatype == 3 ?
                <div style={{ ...styles.Block }}>
                    <RadioGroup
                        value={value}
                        onChange={event => ChangeRadio(event.target.value)}
                        style={{ flexDirection: 'row' }}
                    >
                        <FormControlLabel value="true" control={<Radio />} label={<p style={{ ...styles.TextTiny }}>{lang.cond_yes}</p>} />
                        <FormControlLabel value="false" control={<Radio />} label={<p style={{ ...styles.TextTiny }}>{lang.cond_no}</p>} />
                    </RadioGroup>
                </div>
                :
                <TextInput required time={datatype == 5 ? true : false} date={datatype == 4 ? true : false} decimal={datatype == 1 ? true : false} numeric={datatype == 0 ? true : false} enabled={true} lang={lang} value={value} radius={radius} func={(txt) => { ChangeValue(txt) }} />
        )
    }

    const Variable2Result = (statement, item) => {
        statement.variable2.value = item;
    }

    const ResultEdit = (props) => {

        /* ------------------------------------------------------------------------------------
        UPRAVA RESULTU
        ------------------------------------------------------------------------------------ */
        const [value, setValue] = useState(props.typ == true ? result.value_true : result.value_false);

        let { func } = props;
        let datatype = result.datatype;

        useEffect(() => {

            return () => {
            };

        }, []);

        const ChangeValue = (txt) => {
            func(props.typ, txt);
            setValue(txt);
        }

        const ChangeRadio = (state) => {
            setValue(state);
            func(props.typ, state);
        }

        return (
            datatype == 3 ?
                <div style={{ ...styles.Block }}>
                    <RadioGroup
                        value={value}
                        onChange={event => ChangeRadio(event.target.value)}
                        style={{ flexDirection: 'row' }}
                    >
                        <FormControlLabel value="true" control={<Radio />} label={<p style={{ ...styles.TextTiny }}>{lang.cond_yes}</p>} />
                        <FormControlLabel value="false" control={<Radio />} label={<p style={{ ...styles.TextTiny }}>{lang.cond_no}</p>} />
                    </RadioGroup>
                </div>
                :
                <TextInput required time={datatype == 5 ? true : false} date={datatype == 4 ? true : false} decimal={datatype == 1 ? true : false} numeric={datatype == 0 ? true : false} enabled={true} lang={lang} value={value} radius={radius} func={(txt) => { ChangeValue(txt) }} />
        )
    }

    const VariableResult = (typ, value) => {
        if (typ == true) {
            result.value_true = value;
        } else {
            result.value_false = value;
        }
    }

    const AddStatement = (condition) => {
        let id = 0;
        condition.statements.forEach(item => {
            if (parseInt(item.id) >= parseInt(id)) {
                id = parseInt(item.id) + 1;
            }
        });

        let tmp = { id: id, variable1: { id: -1, table_id: 0, item_id: 0, key: '', datatype: 0 }, operator: -1, operator_exe: -1, variable2: { datatype: 0, value: '' }, statement_operator: 6 };;
        condition.statements.push(tmp);
        setEditingID(condition.id);
        setRedraw(!redraw);
    }

    const DeleteStatement = (condition, statement_id) => {
        let tmp = condition.statements.filter(x => x.id != statement_id);
        condition.statements = tmp;

        setRedraw(!redraw);
    }

    const ChangeOperator = (statement) => {
        if (statement.statement_operator == 6) {
            statement.statement_operator = 7;
        } else {
            statement.statement_operator = 6;
        }

        setRedraw(!redraw);
    }

    const ChangeConditionOperator = (condition) => {
        if (condition != false) {
            if (condition.operator == 6) {
                condition.operator = 7;
            } else {
                condition.operator = 6;
            }

            setRedraw(!redraw);
        }
    }

    const AddCondition = () => {
        let id = 0;

        conditions.forEach(item => {
            if (parseInt(item.id) >= parseInt(id)) {
                id = parseInt(item.id) + 1;
            }
        });

        let tmp = { id: id, operator: 6, pattern: 0, statements: [] };
        conditions.push(tmp);

        setRedraw(!redraw);
    }

    const DeleteCondition = (condition_id) => {
        if (conditions.length > 1) {
            let tmp = conditions.filter(x => x.id != condition_id);

            if (tmp.length > 0) {
                tmp[0].operator = null;
            }

            setConditions(tmp);
        }
        setRedraw(!redraw);
    }


    const ParsePattern = (statements, typ) => {
        var result = '';
        var open = false;
        var operator = '';

        if (statements.length > 0) {
            if (typ == 0) {
                result = '';
                var index = 0;
                var open = false;
                statements.forEach(item => {

                    let tmp = global.operators.find(x => x.id === item.statement_operator);
                    operator = ' ' + tmp.label.toLowerCase() + ' ';

                    if (index % 2 == 0) {
                        if (index > 0) {
                            result += operator;
                        }

                        result += '(';
                        open = true;
                    }

                    if (index > 0 && index % 2 == 1) {
                        result += operator;
                    }

                    result += String(index + 1);

                    if (index % 2 == 1) {
                        result += ')';
                        open = false
                    }

                    index++;

                });
                if (open == true) {
                    result += ')';
                }
                result += '';
            }

            if (typ == 1) {
                result = '';
                var index = 0;
                statements.forEach(item => {
                    let tmp = global.operators.find(x => x.id === item.statement_operator);
                    operator = ' ' + tmp.label.toLowerCase() + ' ';

                    if (index > 0) {
                        result += operator;
                    }

                    result += '(' + String(index + 1) + ')';
                    index++;

                });

                result += '';
            }

        }

        return result;
    }


    const ChangePattern = (condition) => {
        /*
        var pattern = condition.pattern;
        if (pattern == 0) {
            pattern = 1;
        } else {
            pattern = 0;
        }

        condition.pattern = pattern;

        setRedraw(!redraw);
        */
    }


    const ChangeResult = (id) => {
        var tmp = global.variable_results.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.value_true = '';
            tmp.value_false = '';
            setResult(tmp);
            setResultLabel(lang[tmp.lang]);
        }
        setResultID(id);
    }


    const EditCondition = (typ, condition) => {
        setError('');
        if (typ == -1) {
            // kontrola údajov

            let error = CheckData(condition);
            if (error == false) {
                setEditingID(typ);
            }
        } else {
            setEditingID(typ)
        }
    }

    const CheckData = (condition) => {
        var error = false;
        condition.statements.forEach(item => {
            let datatype = item.variable2.datatype;
            let value = item.variable2.value;

            if (item.variable1.id == -1) {
                error = true;
            }
            if (item.operator == -1) {
                error = true;
            }
            if ((datatype == 0 || datatype == 1 || datatype == 7) && value == '') {
                error = true;
            }
        });
        return error;
    }


    const Save = () => {
        // kontrola RESULTU
        setError('');
        var error = 0;
        var error_text = lang.cond_error;

        let datatype = result.datatype;
        let value_true = result.value_true;
        let value_false = result.value_false;

        if ((datatype == 0 || datatype == 1 || datatype == 2 || datatype == 7 || datatype == 3) && (value_true == '' || value_false == '')) {
            error += 1;
            error_text = lang.cond_result_error;
        }

        var index = 1;
        conditions.forEach(item => {
            if (item.statements.length == 0) {
                error += 1;
                error_text = lang.cond_condition_error + ' (' + index + ')';
            } else {
                error += CheckData(item);
            }
            index++;
        });

        if (error == 0) {
            let data = {
                id: props.item_id,
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: props.module_id,
                enabled: true,
                name: name,
                data: JSON.stringify({
                    module_id: moduleID,
                    event: eventID,
                    event_enabled: eventEnabled,
                    result_id: resultID,
                    result: result,
                    result_label: resultLabel,
                    conditions: conditions
                })
            }
            Debug(conditions);
            db_update(data);
        } else {
            setError(error_text);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.gray, paddingTop: 20, paddingBottom: 100, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        <div style={{ ...styles.Block, width: polygonSize * 4, paddingTop: 5, paddingBottom: 10, backgroundColor: themeColor, borderRadius: radius }}>
                            <p style={{ ...styles.TextSmall, letterSpacing: 1, color: color.white, fontWeight: '600', marginBottom: 15 }}>{lang.cond_exe}</p>
                            <p style={{ ...styles.TextTiny, letterSpacing: 0.5, color: color.white, fontWeight: '400', marginBottom: 2 }}>{lang.cond_name}</p>
                            <TextInput redraw center enabled={true} lang={lang} value={name} width={'90%'} radius={radius} func={(txt) => { setName(txt) }} />
                            { /*
                            eventEnabled == true ?
                                <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                                    <Select
                                        value={eventID}
                                        onChange={event => setEventID(event.target.value)}
                                        size='small'
                                        style={{ backgroundColor: '#FFFFFF', height: 38, width: '100%', borderRadius: radius }}
                                        sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: '#000000' }, }}>
                                        <MenuItem disabled value={-1}><em>{lang.cond_choose}</em></MenuItem>
                                        <MenuItem value={0}>{lang.cond_read}</MenuItem>
                                        <MenuItem value={1}>{lang.cond_write}</MenuItem>
                                    </Select>
                                </div>
                                : null
                            */}

                            <div style={{ ...styles.Block, width: '90%', marginTop: 15 }}>
                                <p style={{ ...styles.TextTiny, letterSpacing: 0.5, color: color.white, fontWeight: '400', marginBottom: 2 }}>{lang.cond_result_condition}</p>
                                <Select
                                    value={resultID}
                                    onChange={event => ChangeResult(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: '#FFFFFF', height: 38, width: '100%', borderRadius: radius }}
                                    sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: '#000000' }, }}>

                                    <MenuItem disabled value={-1}><em>{lang.cond_choose}</em></MenuItem>

                                    {results != false ? results.map((item, index) => (
                                        <MenuItem key={item.id} value={item.id}>{lang[item.lang]}</MenuItem>
                                    )) : null}
                                </Select>
                            </div>
                        </div>
                        {resultID > -1 ?
                            <div style={{ ...styles.Block }}>
                                {/* ******************************************************************************************************************************
                                CONDITIONS - LIST
                            ****************************************************************************************************************************** */}
                                {conditions != false ? conditions.map((condition, idx) => (
                                    <div key={condition.id} style={{ ...styles.Block }}>
                                        <DrawLine />
                                        <div onClick={() => ChangeConditionOperator(idx == 0 ? false : condition)} style={{ ...styles.Block, width: polygonSize + 80, minHeight: polygonSize + 10, backgroundColor: themeColor, clipPath: 'polygon(49% 0%, 51% 0%, 100% 49%, 100% 51%, 51% 100%, 49% 100%, 0% 51%, 0% 49%)', borderRadius: '50%', cursor: 'pointer' }}>
                                            <p style={{ ...styles.TextXSmall, letterSpacing: 1, color: color.white, fontWeight: '600' }}>{condition.operator == null ? lang.cond : ParseOperator(condition.operator)}</p>
                                        </div>
                                        <DrawLine />
                                        <Paper elevation={3} style={{ ...styles.Block, width: '96%', maxWidth: maxWidth, backgroundColor: color.white, borderRadius: radius, paddingBottom: paddingSize, border: editingID == condition.id ? '2px solid ' + themeColor : '0px solid' }}>
                                            <div style={{ ...styles.BlockRow, marginTop: 5, paddingBottom: 5, borderBottom: '1px solid #B0B0B0' }}>
                                                <div style={{ ...styles.BlockLeft, width: '94%', height: 30 }}>
                                                    <p style={{ ...styles.TextSmall, letterSpacing: 1, fontWeight: '600', marginLeft: 20 }}>{idx + 1}. {lang.cond} </p>
                                                </div>
                                                {editingID != condition.id && ((idx > 0) || (conditions.length > 1)) ?
                                                    <IconButton onClick={() => DeleteCondition(condition.id)} style={{ ...styles.ButtonIcon, marginRight: 15 }}>
                                                        <FontAwesomeIcon style={{ height: 14, color: color.black }} icon={faTrash} />
                                                    </IconButton>
                                                    : null}
                                                {editingID == condition.id ?
                                                    <IconButton onClick={() => EditCondition(-1, condition)} style={{ ...styles.ButtonIcon, marginRight: 5 }}>
                                                        <FontAwesomeIcon style={{ height: 14, color: color.black }} icon={faCheck} />
                                                    </IconButton>
                                                    :
                                                    <IconButton onClick={() => EditCondition(condition.id, condition)} style={{ ...styles.ButtonIcon, marginRight: 5 }}>
                                                        <FontAwesomeIcon style={{ height: 14, color: color.black }} icon={faPen} />
                                                    </IconButton>
                                                }
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '96%', marginTop: 20 }}>
                                                {condition.statements.length == 0 ?
                                                    <IconButton onClick={() => AddStatement(condition)} style={{ ...styles.ButtonIcon }}>
                                                        <FontAwesomeIcon style={{ height: 14, color: color.black }} icon={faPlus} />
                                                    </IconButton>
                                                    : null}
                                                <div style={{ ...styles.BlockLeft, justifyContent: 'center' }}>
                                                    {condition.statements != false ? condition.statements.map((statement, index) => (
                                                        editingID == condition.id ?
                                                            <div key={statement.id} style={{ ...styles.BlockLeft, marginBottom: 10 }}>
                                                                <div style={{ ...styles.BlockRow }}>
                                                                    <div style={{ ...styles.Block, width: '34%' }}>
                                                                        <VariableEdit1 statement={statement} func={Variable1Result.bind(this)} />
                                                                    </div>
                                                                    <div style={{ ...styles.Block, width: '20%' }}>
                                                                        <div style={{ ...styles.Block, width: '85%' }}>
                                                                            {statement.variable1.id > -1 ?
                                                                                <OperatorEdit statement={statement} func={OperatorResult.bind(this)} />
                                                                                : null}
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ ...styles.Block, width: '34%' }}>
                                                                        {statement.operator > -1 ?
                                                                            <VariableEdit2 statement={statement} func={Variable2Result.bind(this)} />
                                                                            : null}
                                                                    </div>
                                                                    <div style={{ ...styles.BlockRight, width: 80 }}>
                                                                        <div style={{ ...styles.BlockRowRaw, paddingTop: 3 }}>
                                                                            <div style={{ ...styles.Block, width: 30 }}>
                                                                                <IconButton onClick={() => DeleteStatement(condition, statement.id)} style={{ width: 30, height: 30, marginRight: 10 }}>
                                                                                    <FontAwesomeIcon style={{ height: 14, color: color.black }} icon={faMinus} />
                                                                                </IconButton>
                                                                            </div>
                                                                            <div style={{ ...styles.Block, width: 30 }}>
                                                                                {index == condition.statements.length - 1 && condition.statements.length < 8 ?
                                                                                    <IconButton onClick={() => AddStatement(condition)} style={{ width: 30, height: 30 }}>
                                                                                        <FontAwesomeIcon style={{ height: 14, color: color.black }} icon={faPlus} />
                                                                                    </IconButton>
                                                                                    : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div key={statement.id} style={{ ...styles.BlockLeft }}>
                                                                {statement.statement_operator != null ?
                                                                    index > 0 ?
                                                                        <div onClick={() => ChangeOperator(statement)} style={{ cursor: 'pointer' }}>
                                                                            <LineOperator operator={ParseOperator(statement.statement_operator)} />
                                                                        </div>
                                                                        : null : null}
                                                                <div style={{ ...styles.BlockRowRaw, marginLeft: 15 }}>
                                                                    <LineNumber number={index + 1} />
                                                                    <div key={statement.id} style={{ ...styles.BlockRowRaw, width: undefined }}>
                                                                        <div style={{ ...styles.Block, width: undefined, justifyContent: 'center', height: rowSize, marginLeft: 15 }}>
                                                                            <p style={{ ...styles.TextXSmall, color: color.darker_gray, textAlign: 'left' }}>{ParseVariable1(statement.variable1)}</p>
                                                                        </div>
                                                                        <div style={{ ...styles.Block, width: undefined, justifyContent: 'center', height: rowSize, marginLeft: 15 }}>
                                                                            <p style={{ ...styles.TextXSmall, color: color.darker_gray }}>{ParseOperator(statement.operator)}</p>
                                                                        </div>
                                                                        <div style={{ ...styles.BlockLeft, width: undefined, justifyContent: 'center', height: rowSize, marginLeft: 15 }}>
                                                                            <p style={{ ...styles.TextXSmall, color: color.darker_gray, textAlign: 'left' }}>{ParseVariable2(statement.variable2)}</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                    )) : null}
                                                </div>
                                                {condition.statements.length > 0 ?
                                                    <div onClick={() => ChangePattern(condition)} style={{ ...styles.BlockLeft, width: undefined, marginTop: 25, cursor: 'pointer' }}>
                                                        <p style={{ ...styles.TextTiny, marginLeft: 15 }}>{lang.cond_pattern}:<span style={{ marginLeft: 10, fontWeight: '600', backgroundColor: color.gray, padding: 5, borderRadius: 5 }}>{ParsePattern(condition.statements, condition.pattern)}</span></p>
                                                    </div>
                                                    : null}
                                            </div>
                                        </Paper>
                                    </div>
                                )) : null}

                                {/* -----------------------------------------------------------------------------------------------------------------------------
                            PRIDAT DALSIU PODMIENKU 
                        ----------------------------------------------------------------------------------------------------------------------------- */}
                                <DrawLine size={lineSize / 2} />
                                <div style={{ ...styles.Block, width: polygonSize * 3, minHeight: 40, backgroundColor: color.light_gray, border: '1px solid #808080', borderRadius: radius }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <IconButton onClick={() => AddCondition()} style={{ width: 30, height: 30, backgroundColor: color.white, marginRight: 10 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.black }} icon={faPlus} />
                                        </IconButton>
                                        <div style={{ ...styles.Block, width: undefined, height: 30 }}>
                                            <p style={{ ...styles.TextXSmall, color: color.black }}>{lang.cond_next}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* -----------------------------------------------------------------------------------------------------------------------------
                            VYSLEDOK                 
                        ----------------------------------------------------------------------------------------------------------------------------- */}
                                <DrawLine size={lineSize * 3} />
                                <div style={{ ...styles.Block, width: polygonSize * 3, minHeight: 40, backgroundColor: themeColor, borderRadius: radius }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.Block, width: undefined, height: 30 }}>
                                            <FontAwesomeIcon style={{ height: 20, color: color.white, marginRight: 10 }} icon={faCheckCircle} />
                                        </div>
                                        <div style={{ ...styles.Block, width: undefined, height: 30 }}>
                                            <p style={{ ...styles.TextXSmall, color: color.white }}>{lang.cond_result}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: undefined, height: 30 }}>
                                            <FontAwesomeIcon style={{ height: 20, color: color.white, marginLeft: 10 }} icon={faXmarkCircle} />
                                        </div>
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockRow, width: '96%' }}>
                                    <div style={{ ...styles.BlockRight, width: '50%' }}>
                                        <DrawLine />
                                        <div style={{ ...styles.Block, width: polygonSize, minHeight: polygonSize, backgroundColor: color.white, border: '2px solid ' + themeColor, borderRadius: '50%' }}>
                                            <p style={{ ...styles.TextSmall, letterSpacing: 1, color: themeColor, fontWeight: '600' }}>{lang.cond_yes}</p>
                                        </div>
                                        <DrawLine />
                                        {/* -----------------------------------------------------------------------------------------------------------------------------
                                            VYSLEDOK == TRUE 
                                        ----------------------------------------------------------------------------------------------------------------------------- */}
                                        <Paper elevation={3} style={{ ...styles.Block, maxWidth: resultWidth, backgroundColor: color.white, borderRadius: radius, paddingTop: 10, paddingBottom: 10 }}>
                                            <div style={{ ...styles.BlockLeft, width: '96%' }}>
                                                <div style={{ ...styles.BlockRow, paddingBottom: 5, marginBottom: 5, borderBottom: '1px solid ' + color.light_gray }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.dark_green, marginRight: 5 }} icon={faCheckCircle} />
                                                    <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.cond_true}</p>
                                                </div>
                                                <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                                    <div style={{ ...styles.Block, width: '96%' }}>
                                                        <div style={{ ...styles.BlockLeft, paddingBottom: 5 }}>
                                                            <p style={{ ...styles.TextTiny }}>{resultLabel} <span style={{ ...styles.TextXTiny, color: color.dark_gray }}>{lang.cond_copy_value}</span></p>
                                                        </div>
                                                        {result != false ?
                                                            <ResultEdit typ={true} func={VariableResult.bind(this)} />
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </Paper>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '50%', marginLeft: 20 }}>
                                        <DrawLine />
                                        <div style={{ ...styles.Block, width: polygonSize, minHeight: polygonSize, backgroundColor: color.white, border: '2px solid ' + themeColor, borderRadius: '50%' }}>
                                            <p style={{ ...styles.TextSmall, letterSpacing: 1, color: themeColor, fontWeight: '600' }}>{lang.cond_no}</p>
                                        </div>
                                        <DrawLine />
                                        {/* -----------------------------------------------------------------------------------------------------------------------------
                                            VYSLEDOK == FALSE 
                                        ----------------------------------------------------------------------------------------------------------------------------- */}
                                        <Paper elevation={3} style={{ ...styles.Block, maxWidth: resultWidth, backgroundColor: color.white, borderRadius: radius, paddingTop: 10, paddingBottom: 10 }}>
                                            <div style={{ ...styles.BlockLeft, width: '96%' }}>
                                                <div style={{ ...styles.BlockRow, paddingBottom: 5, marginBottom: 5, borderBottom: '1px solid ' + color.light_gray }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.dark_red, marginRight: 5 }} icon={faXmarkCircle} />
                                                    <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.cond_false}</p>
                                                </div>
                                                <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                                    <div style={{ ...styles.Block, width: '96%' }}>
                                                        <div style={{ ...styles.BlockLeft, paddingBottom: 5 }}>
                                                            <p style={{ ...styles.TextTiny }}>{resultLabel} <span style={{ ...styles.TextXTiny, color: color.dark_gray }}>{lang.cond_copy_value}</span></p>
                                                        </div>
                                                        {result != false ?
                                                            <ResultEdit typ={false} func={VariableResult.bind(this)} />
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </Paper>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        {editingID == -1 && resultID > -1 ?
                            <div style={{ ...styles.Block, marginTop: 10 }}>
                                <div style={{ ...styles.Block, paddingBottom: 20 }}>
                                    <FormError theme={props.theme} error={error} small />
                                </div>
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_dark }}>{lang.save}</Button>
                            </div>
                            : null}

                    </div >
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


