/*
*
* =================================================================================================================
* OBJEDNAVKY TOVARU NA SKLAD

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useRef, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormSelect, DeleteButton, FormProduct, ProductSearch, TextLine, GetReserve } from './items';
import { styles } from './styles';
import { Backdrop, Button, Chip, IconButton, Pagination, Paper, Skeleton, Stack, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faCheck, faInfoCircle, faLayerGroup, faPen, faPlus, faPlusCircle, faQuestion, faWandMagicSparkles, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, FormatMoney, FormatPieces, GetLeftPosition, GetMoney, GetPages, IsEmpty } from './functions';
import { Photos } from './photos';
import { ProductSelect } from './product_select.js';
import { GetDatum1, GetDatum2, GetDatumTime, GetDatumTime2, GetTodayYear } from './functions_date.js';
import { ProductInfo } from './products.js';
import { Notes } from './notes.js';
import { WarehouseStockCard } from './warehouse.js';

export const StockOrders = (props) => {

    let module_id = global.model.stock_orders;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'stock_orders';

    let defaultFilter = {
        year: GetTodayYear(),
        country_id: props.locale.id,
        status: 0,
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    const stockStates = [
        { id: 0, name: lang.all_records },
        { id: 1, name: lang.stock_order_status[1] },
        { id: 2, name: lang.stock_order_status[2] },
    ]
    const [stockStateID, setStockStateID] = useState(0);

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.stock_adjustment_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [year, setYear] = useState(GetTodayYear());

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.number, default: true },
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 86;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const menuWidth = global.sub_menu_width;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);

    const db_get = async (page, filter, maxItems) => {
        Debug(filter, 'orange');
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_orders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {

                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_order_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.stock_orders,
                    filename: lang.stock_orders, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            filter.search = txt;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            filter.search = '';
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth', block: "start" });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }


    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // NAZOV
                column = 'name';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;
        }

        var filter = {
            ordering: order,
            order_column: column
        };
        if (search != '') {
            filter.search = search;
        }
        db_get(0, filter, maxItems);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const SelectYearResult = (year_, month) => {
        setYear(year_);
        var filter_ = GetFilter();
        filter_.year = year_;
        db_get(0, filter_, maxItems);
    }

    const GetFilter = () => {
        var filter = defaultFilter;
        filter.year = year;
        //filter.ordering = ordering;
        //filter.order_column = orderColumn;

        filter.status = stockStateID;

        if (search.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            filter.search = search;
            filter.search_columns = columns;
        }

        return filter;
    }

    const ChangeState = (id) => {
        setStockStateID(id);

        var filter = GetFilter();
        filter.status = id;
        db_get(0, filter, maxItems);
    }

    return (

        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div id={'id_start'} ></div>
                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%', marginTop: 10 }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '60%', height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.BlockLeft, width: undefined, height: menuHeight }}>
                                            <TextInput search theme={props.theme} width={300} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        </div>
                                        <div style={{ ...styles.Block, width: 30, height: menuHeight }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: 220, height: menuHeight, marginLeft: 20 }}>
                                            <SelectInput redraw theme={props.theme} enabled={true} lang={lang} value={stockStateID} items={stockStates} width={200} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => ChangeState(id)} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRight, width: 200, height: menuHeight }}>
                                    <SelectYear min={2020} value={year} theme={props.theme} func={SelectYearResult.bind(this)} />
                                </div>
                                <div style={{ ...styles.BlockRight, width: 200, height: menuHeight }}>
                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.stock_order_new}
                                        </Button>
                                        : null}
                                </div>
                            </div>

                            <div style={{ ...styles.Block, marginTop: 20 }}>

                                {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, marginBottom: 10, border: color.border }}>
                                    {/* DATA */}
                                    <div style={{ ...styles.BlockLeft, width: '25%', height: tableLabelHeight, justifyContent: 'center' }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.number}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '25%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.stock}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.order_products}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.status}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 200, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.date}</p>
                                    </div>
                                </div>

                                {/* ****************************************************************************************************************
                                        TABLE DATA
                                **************************************************************************************************************** */}

                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowHeight,
                                            cursor: 'pointer',
                                            marginTop: 5, marginBottom: 5,
                                            paddingTop: 2, paddingBottom: 2,
                                            borderLeft: item.enabled == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow }}>
                                            {/* DATA */}
                                            <div style={{ ...styles.BlockLeft, width: '25%', height: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{item.number}</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '25%', height: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, marginLeft: 5, fontWeight: '600' }}>{item.stock}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 150, height: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny }}>{item.products}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 150, height: rowHeight, justifyContent: 'center' }}>
                                                <Chip label={lang.stock_order_status[item.status]} size='small' variant='outlined' style={{ color: global.stock_order_chip[item.status], borderColor: global.stock_order_chip[item.status], fontSize: global.font_tiny }} />
                                            </div>
                                            <div style={{ ...styles.Block, width: 200, height: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny }}>{GetDatumTime2(item.updated)}</p>
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <EmptyList row small lang={lang} />
                                }

                                {/* ****************************************************************************************************************
                                        PAGINATION
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 200 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showExport == true ?
                <DialogExport rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <StockOrder module_id={module_id} item={itemSelected} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}



/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const StockOrder = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let currency = props.locale.currency;
    let precisions = props.system.precisions == 0 ? 2 : 3;
    let show_cost = props.system.show_cost_prices == false ? false : props.user.super_admin == true ? true : props.permissions.show_cost == false ? false : true;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.item == false ? true : false);
    const [overID, setOverID] = useState(0);
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(props.item == false ? 0 : 1);

    // DATA
    const [storeData, setStoreData] = useState({});


    const [number, setNumber] = useState('');
    const [stockID, setStockID] = useState(-1);
    const [cost, setCost] = useState(0);
    const [status, setStatus] = useState(global.stock_orders.created);
    const [note, setNote] = useState('');
    const [sume, setSume] = useState(0);

    // UPRAVA MNOZSTVA
    const [quantity, setQuantity] = useState('');
    const [itemSelected, setItemSelected] = useState(false);


    // SKLADY
    const [stocks, setStocks] = useState(false);

    // PRODUKTY
    const [products, setProducts] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(50);
    const [product, setProduct] = useState(false);
    const [showFinish, setShowFinish] = useState(false);

    const [selectProducts, setSelectProducts] = useState(false);
    const [excluded, setExcluded] = useState([]);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showWizard, setShowWizard] = useState(false);
    const [showStockCard, setShowStockCard] = useState(false);
    const [itemStockCard, setItemStockCard] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = itemID == false ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 150;
    const column2 = 400;
    const rowHeight = 80;
    const tableHeight = 40;
    const titleHeight = 80;
    const paginationHeight = 80;

    const [tabs, setTabs] = useState([
        { id: 0, enabled: true, label: lang.setting },
        { id: 1, enabled: props.item == false ? false : true, label: lang.products },
        { id: 3, enabled: true, label: lang.notes },
        { id: 2, enabled: true, label: lang.history },
    ]);

    let { func } = props;
    var running = false;
    const inputRef = useRef(null);

    useEffect(() => {
        if (running == false) {
            Debug('PERMISSIONS', 'cyan');
            Debug(show_cost);
            if (itemID != 0) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
                db_products(page, false, maxItems, true);
            }

            //if (props.system.stocks == true) {
            db_get_data();
            //}

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_order', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_order_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();
            Debug('stock_order_data', 'yellow');
            Debug(json);
            if (json.ok > 0) {
                setStocks(json.stocks);
                if (itemID == 0) {
                    setNumber(json.number);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        Debug('STOCK UPDATE');
        Debug(data);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_order_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                setEditing(false);
                if (itemID == 0) {
                    setItemID(json.ok);
                    tabs[1].enabled = true;
                    setNumber(json.number);
                }
                setRedrawRequired(true);

                if (data.status == 2) {
                    setStatus(data.status);
                }

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_products = async (page, filter, maxItems, scroll) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_order_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    stock_order_id: itemID,
                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {

                var tmp = json.items;
                setProducts(tmp);

                var sum = 0;
                if (tmp != false) {
                    tmp.forEach(element => {
                        sum += (parseFloat(element.cost) * parseInt(element.quantity));
                    });
                }
                setSume(sum);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setExcluded(json.items_id);

                if (scroll == true) {
                    GoToStart();
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_product_update = async (items_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_order_product_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    stock_id: stockID,
                    items: items_
                })
            })

            const json = await response.json();
            Debug('UPDATE STATUS');
            Debug(json);
            if (json.ok > 0) {
                db_products(page, false, maxItems, false);
            } else {
                setShowError(true);
            }
            setRedrawRequired(true);
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const ChangePage = (value) => {
        db_products(value - 1, false, maxItems, true);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB

        setNumber(item.number);
        setStockID(item.stock_id);
        setNote(item.note);
        setStatus(item.status);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.number = number;
        storeData.status = status;
        storeData.stock_id = stockID;
        storeData.note = note;
    }

    const Save = () => {
        setError('');
        var error = false;
        error += IsEmpty(number) == true ? 1 : 0;
        if (stockID == -1) {
            error++;
        }


        if (error == 0) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: itemID,
                action: itemID == 0 ? global.logs.created : global.logs.modified
            };

            let data = {
                id: itemID,
                enabled: true,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                user_id: props.user.id,
                number: number.trim(),
                currency: props.locale.currency,
                currency_id: props.locale.id,
                stock_id: stockID,
                cost: cost,
                products: products == false ? 0 : products.length,
                status: status,
                note: note,
                logs: log
            };

            StoreEditing();

            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);
        if (id == 1) {
            db_products(0, false, maxItems, true);
        }
        if (id == 2) {
            db_get_logs();
        }
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {

        }
    }

    const ProductResult = (typ, ids, items) => {

        if (typ == true && ids != false) {
            var data = [];

            items.forEach(product => {

                let element = {
                    id: 0,
                    enabled: true,
                    organization_id: props.organization.id,
                    user_id: props.user.id,
                    stock_order_id: itemID,
                    vendor_code: '',
                    product_id: product.id,
                    stock_uid: product.stock_uid,
                    name: product.name,
                    unit: product.unit,
                    parent_name: product.parent_name,
                    image: product.parent != undefined ? product.image == '' ? product.parent.image : product.image : product.image,
                    quantity: 1,
                    cost: product.cost,
                    dph: product.parent != undefined ? product.parent.dph == -1 ? props.locale.dph : product.parent.dph : product.dph == -1 ? props.locale.dph : product.dph
                };

                data.push(element);

            });

            db_product_update(data);
        }

        setSelectProducts(false);
    }

    const DeleteProduct = (item) => {
        let data = {
            id: item.id,
            enabled: false,
            stock_order_id: itemID,
            delete: true,
        }
        db_product_update([data]);
    }

    const ChangeQuantity = (value) => {
        Debug(value);
        setQuantity(value);
    }

    const EditQuantity = (item) => {
        setQuantity(parseInt(item.quantity));
        setItemSelected(item);
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);
    }

    const SaveQuantity = () => {
        let data = {
            id: itemSelected.id,
            quantity: quantity,
            quantity_received: quantity,
            change_quantity: true,
        }
        db_product_update([data]);

        setItemSelected(false);
    }

    const ChangeEditing = () => {
        setEditing(true);
    }

    const productDetails = (item) => {

        setItemStockCard(item);
        setShowStockCard(true);
    }

    const FinishResult = (value) => {
        setShowFinish(false);

        if (value == true) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: itemID,
                action: global.logs.finished
            };

            let data = {
                id: itemID,
                status: global.stock_orders.finished,
                logs: log
            };

            db_update(data);
        }
    }

    const NotesResult = () => { }

    const SelectProducts = () => {
        setSelectProducts(true);
    }

    const QuickResult = (item) => {
        ProductResult(true, [item.id], [item]);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_products(page, false, item, true);
    }

    const WizardResult = (typ) => {
        setShowWizard(false);

        if (typ == true) {
            setRedrawRequired(true);
            db_products(page, false, maxItems, true);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{itemID == 0 ? lang.stock_order_new_label : lang.stock_order}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.Block, height: tabHeight }}>
                        {itemID > 0 && editing == false ?
                            <DialogTabs center value={tabIndex} items={tabs} width={dialogWidth / 4} theme={props.theme} func={(id) => ChangeTab(id)} />
                            : null}
                    </div>


                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block }}>
                                {storeData != false ?
                                    <div>
                                        <FormSpace />
                                        <FormLabel editing={editing} editButton={status == 1 ? CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false : false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => ChangeEditing(true)} />
                                        <FormText require redraw value={number} editing={editing} title={lang.number} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNumber(txt)} />
                                        <FormSelect require value={stockID} items={stocks} field={'name'} editing={editing} title={lang.stock} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setStockID(id)} />
                                        <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                                    </div>
                                    :
                                    <Stack style={{ ...styles.Block }} spacing={1}>
                                        <FormSpace />
                                        <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    </Stack>}
                            </div>

                        </div>
                        : null}

                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight }} >
                            <div style={{ ...styles.Block, width: '96%' }}>
                                <div style={{ ...styles.BlockRow, width: '99%' }}>
                                    <div style={{ ...styles.BlockLeft, width: 380, height: titleHeight }}>
                                        <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.sume}: {FormatMoney(sume, precisions)} {currency}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: '36%', height: titleHeight }}>
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.stock_order_status[status]}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: 190, height: titleHeight }}>
                                        {editing == false ?
                                            status == 1 ?
                                                CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                                    <Button onClick={() => setShowWizard(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, color: color.black, width: 170 }}>
                                                        <FontAwesomeIcon style={{ height: 14, marginRight: 10 }} icon={faWandMagicSparkles} />
                                                        {lang.stock_order_auto}
                                                    </Button>
                                                    : null : null : null}
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: 190, height: titleHeight }}>
                                        {editing == false ?
                                            status == 1 ?
                                                CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                                    <Button onClick={() => SelectProducts()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, color: color.black, width: 170 }}>
                                                        <FontAwesomeIcon style={{ height: 14, marginRight: 10 }} icon={faPlus} />
                                                        {lang.stock_adjustment_products_add}
                                                    </Button>
                                                    : null : null : null}
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockRow, width: '99%', border: color.border, height: tableHeight }}>
                                    <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableHeight, justifyContent: 'center' }}>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: status == global.stock_orders.created ? '40%' : '70%', height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product}</p>
                                    </div>
                                    {show_cost == true && status == global.stock_orders.created ?
                                        <div style={{ ...styles.BlockLeft, width: '15%', height: tableHeight, borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product_cost}</p>
                                        </div>
                                        : null}
                                    {status == global.stock_orders.created ?
                                        <div style={{ ...styles.Block, width: '15%', height: tableHeight, borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.product_quantity_stock_}</p>
                                        </div>
                                        : null}
                                    <div style={{ ...styles.Block, width: 210, height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.stock_quantity}</p>
                                    </div>

                                    <div style={{ ...styles.BlockRight, width: 60, height: tableHeight, borderLeft: color.border }}>

                                    </div>
                                </div>

                                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - titleHeight - tableHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <div id={'id-start'} style={{ ...styles.BlockCenter, paddingBottom: global.list_padding }}>
                                        {products != false ? products.map((item, index) => (

                                            <Paper
                                                key={item.id}
                                                id={'id-product-' + item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    width: '99%',
                                                    backgroundColor: item.enabled == false ? color.disabled : item.grouped == true ? color.grouped : item.variations == true ? color.variation : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : item.variations == true ? color.border_variation : item.grouped == true ? color.border_grouped : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                    <div onClick={() => productDetails(item)} style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <Avatar picture image={item.image} size={rowHeight - 10} theme={props.theme} />
                                                    </div>
                                                    <div onClick={() => productDetails(item)} style={{ ...styles.BlockLeft, width: status == global.stock_orders.created ? '40%' : '70%', minHeight: rowHeight }}>
                                                        {item.parent_name != '' ?
                                                            <TextLine text={item.parent_name} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                            : null}
                                                        <TextLine text={item.name} fontSize={item.parent_name != '' ? global.font_tiny : global.font_xsmall} fontWeight={item.parent_name != '' ? '400' : '600'} color={color.black} />
                                                        <p style={{ ...styles.TextTiny }}>{item.code}</p>
                                                    </div>
                                                    {show_cost == true && status == global.stock_orders.created ?
                                                        <div onClick={() => productDetails(item)} style={{ ...styles.BlockRight, width: '15%', minHeight: rowHeight }}>
                                                            <p style={{ ...styles.TextTiny }}>{FormatMoney(item.cost, precisions)} {currency}/1{item.unit}</p>
                                                        </div>
                                                        : null}
                                                    {status == global.stock_orders.created ?
                                                        <div onClick={() => productDetails(item)} style={{ ...styles.BlockRight, width: '15%', minHeight: rowHeight }}>
                                                            <p style={{ ...styles.TextXSmall, marginBottom: 2 }}>{FormatPieces(item.stock_quantity, precisions)} {item.unit}</p>
                                                            <GetReserve quantity={item.stock_reserved} unit={item.unit} lang={props.lang} theme={props.theme} />
                                                        </div>
                                                        : null}
                                                    <div style={{ ...styles.BlockRight, width: 210, minHeight: rowHeight }}>
                                                        {itemSelected != false && itemSelected.id == item.id ?
                                                            <div style={{ ...styles.BlockRowRaw }}>
                                                                <div style={{ ...styles.BlockLeft, width: 100, minHeight: rowHeight }}>
                                                                    <TextField
                                                                        inputRef={inputRef}
                                                                        value={itemSelected == false ? FormatPieces(quantity) : quantity}
                                                                        onInput={(e) => ChangeQuantity(e.target.value)}
                                                                        size="small"
                                                                        autoCapitalize="off"
                                                                        autoComplete="off"
                                                                        autoCorrect="off"
                                                                        style={{ width: '100%' }}
                                                                        onKeyDown={(event) => {
                                                                            if (event.key === 'Enter') {
                                                                                SaveQuantity(false);
                                                                            }
                                                                            if (event.key === 'Escape') {
                                                                                setItemSelected(false);
                                                                            }
                                                                        }}
                                                                        InputProps={{
                                                                            style: { backgroundColor: global.theme_white },
                                                                        }}
                                                                        onFocus={event => {
                                                                            event.target.select();
                                                                        }}
                                                                        variant="outlined"
                                                                    />
                                                                </div>
                                                                <div style={{ ...styles.Block, width: 40, minHeight: rowHeight }}>
                                                                    <IconButton onClick={() => SaveQuantity(false)} style={{ ...styles.ButtonIcon }}>
                                                                        <FontAwesomeIcon style={{ width: 14, color: color.dark_green }} icon={faCheck} />
                                                                    </IconButton>
                                                                </div>
                                                                <div style={{ ...styles.Block, width: 40, minHeight: rowHeight }}>
                                                                    <IconButton onClick={() => setItemSelected(false)} style={{ ...styles.ButtonIcon }}>
                                                                        <FontAwesomeIcon style={{ width: 14, color: color.dark_red }} icon={faXmark} />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div style={{ ...styles.BlockRowRaw, height: rowHeight, alignItems: 'center' }}>
                                                                <p style={{ ...styles.TextXSmall }}>{FormatPieces(item.quantity)} {item.unit}</p>
                                                                {status == 1 ? CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                                    <IconButton onClick={() => EditQuantity(item)} style={{ ...styles.ButtonIcon, marginLeft: 20 }}>
                                                                        <FontAwesomeIcon style={{ width: 10 }} icon={faPen} />
                                                                    </IconButton>
                                                                    : null : null}
                                                            </div>
                                                        }

                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: 60, minHeight: rowHeight }}>
                                                        {status == 1 ? CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ?
                                                            <DeleteButton ask theme={props.theme} lang={props.lang} func={() => DeleteProduct(item)} />
                                                            : null : null}
                                                    </div>
                                                </div>
                                            </Paper>

                                        )) :
                                            <div style={{ ...styles.Block, paddingTop: 25 }}>
                                                <EmptyList row small lang={lang} />
                                            </div>
                                        }

                                        {status == 1 ?
                                            <ProductSearch excluded={excluded} marginTop={25} theme={props.theme} lang={lang} radius={props.radius} locale={props.locale} organization={props.organization} func={(item) => QuickResult(item)} />
                                            : null}

                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight, paddingBottom: global.empty_padding }}>
                                            <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                                <div style={{ ...styles.Block, width: 200 }}></div>
                                                <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                                    {pages > 1 ?
                                                        <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.Block, width: 200 }}>
                                                    {products != false ?
                                                        <div style={{ ...styles.BlockRowRaw }}>
                                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                            </div>
                                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                        : null}

                    {tabIndex == 3 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Notes module_id={global.model.stock_orders} item_id={itemID} height={dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={NotesResult.bind(this)} />
                        </div>
                        : null}


                    {tabIndex == 2 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                            <div style={{ ...styles.BlockRowRaw }}>
                                {tabIndex == 1 && status == 1 && products != false ?
                                    <Button onClick={() => setShowFinish(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_finalize, marginRight: 20 }}>{lang.finish_and_close}</Button>
                                    : null}
                                {tabIndex == 0 && editing == true ?
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                    : null}
                                {editing == true && itemID != 0 ?
                                    <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                    :
                                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                                }
                            </div>
                            :
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                        }
                    </div>
                </div >

            </Paper >

            {showStockCard == true ?
                <WarehouseStockCard item={itemStockCard} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={() => setShowStockCard(false)} />
                : null}

            {showWizard == true ?
                <StockOrderWizard order_id={itemID} organization={props.organization} settings={props.settings} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={WizardResult.bind()} />
                : null}

            {showFinish == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.stock_order_finish_label} text={lang.stock_order_finish_ask} sub_text={lang.stock_order_finish_ask_note} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={FinishResult.bind(this)} />
                : null}

            {selectProducts == true ?
                <ProductSelect filters excluded={excluded} only_enabled stock={stockID} only_sku={true} country_id={props.locale.id} showVariations={true} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductResult.bind()} />
                : null}

            {selectPhoto == true ?
                <Photos autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} locale={props.locale} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop>
    );
}


export const StockOrderWizard = (props) => {


    const [isBusy, setBusy] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 600;
    const dialogHeight = 350;

    const automator = require('./react/app/automator.png');

    let color = global.themes[props.theme];
    let lang = props.lang;

    let { func } = props;

    const db_wizard = async () => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'stock_order_wizard', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    order_id: props.order_id,
                })
            })

            const json = await response.json();

            Debug('WIZARD', 'green');
            Debug(json);

            setBusy(false);

            if (json.ok > 0) {
                setRedraw(true);
                setShowOK(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redraw);
    }

    const Process = () => {
        db_wizard();
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faWandMagicSparkles} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.stock_order_auto}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '90%' }}>
                        <img src={automator} style={{ width: '100%', height: '100%', maxWidth: 70, maxHeight: 70, objectFit: 'contain' }}></img>
                        <p style={{ ...styles.TextSmall, textAlign: 'center', fontWeight: '600', marginTop: 20 }}>{lang.stock_order_auto_text}</p>
                        <p style={{ ...styles.TextSmall, textAlign: 'center', marginTop: 15 }}>{lang.stock_order_auto_note}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <Button onClick={() => Process()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.stock_order_auto_run}</Button>
                </div>
            </Paper>

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={() => func(true)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={props.lang.db_error} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme}></Loading>
                : null}

        </Backdrop>
    );
}


export const StockOrderSelect = (props) => {

    let module_id = global.model.stock_orders;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'stock_orders';

    let defaultFilter = {
        year: GetTodayYear(),
        country_id: props.locale.id,
        status: 2,
        received: false
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    const stockStates = [
        { id: 0, name: lang.all_records },
        { id: 1, name: lang.stock_order_status[0] },
        { id: 2, name: lang.stock_order_status[1] },
    ]
    const [stockStateID, setStockStateID] = useState(0);

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.stock_adjustment_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [year, setYear] = useState(GetTodayYear());

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.number, default: true },
    ]);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;


    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 86;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const menuWidth = global.sub_menu_width;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);

    const db_get = async (page, filter, maxItems) => {
        Debug(filter, 'orange');
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_orders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {

                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            filter.search = txt;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            filter.search = '';
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth', block: "start" });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        func(true, item);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const SelectYearResult = (year_, month) => {
        setYear(year_);
        var filter_ = GetFilter();
        filter_.year = year_;
        db_get(0, filter_, maxItems);
    }

    const GetFilter = () => {
        var filter = defaultFilter;
        filter.year = year;
        //filter.ordering = ordering;
        //filter.order_column = orderColumn;

        filter.status = stockStateID;

        if (search.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            filter.search = search;
            filter.search_columns = columns;
        }

        return filter;
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.stock_order_select}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start'} ></div>
                        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%', marginTop: 10 }}>
                            <div style={{ ...styles.Block, width: '96%' }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '80%', height: menuHeight }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockLeft, width: undefined, height: menuHeight }}>
                                                <TextInput search theme={props.theme} width={300} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                            </div>
                                            <div style={{ ...styles.Block, width: 30, height: menuHeight }}>
                                                <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: 200, height: menuHeight }}>
                                        <SelectYear min={2020} value={year} theme={props.theme} func={SelectYearResult.bind(this)} />
                                    </div>
                                </div>

                                <div style={{ ...styles.Block, marginTop: 20 }}>

                                    {/* ****************************************************************************************************************
                                        TABLE HEADER
                                **************************************************************************************************************** */}

                                    <div style={{ ...styles.BlockRow, marginBottom: 10, border: color.border }}>
                                        {/* DATA */}
                                        <div style={{ ...styles.BlockLeft, width: '25%', height: tableLabelHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.number}</p>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '25%', height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.stock}</p>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.order_products}</p>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.status}</p>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: 200, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.date}</p>
                                        </div>
                                    </div>

                                    {/* ****************************************************************************************************************
                                        TABLE DATA
                                **************************************************************************************************************** */}

                                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block,
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow }}>
                                                {/* DATA */}
                                                <div style={{ ...styles.BlockLeft, width: '25%', height: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{item.number}</p>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '25%', height: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, marginLeft: 5, fontWeight: '600' }}>{item.stock}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 150, height: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny }}>{item.products}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 150, height: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600', color: global.stock_order_colors[item.status] }}>{lang.stock_order_status[item.status]}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 200, height: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny }}>{GetDatumTime2(item.updated)}</p>
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }

                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                **************************************************************************************************************** */}

                                    <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}

