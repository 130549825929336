/*
*
* =================================================================================================================
* NOTIFIKACIE - KREDI - prehľad využívania + kúpa kreditu

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Notify organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { Colors, DialogImagePreview, DialogInfo, DialogLogin, DialogTabs, DialogVideo, DialogYesNo, EmployeeSelect, EmptyList, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, Pagination, Paper, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faLayerGroup, faList, faPlusCircle, faQuestion, faShoppingCart, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetDatum2, GetDatumTime, GetDatumTime2, GetTodayYear } from './functions_date';
import { CheckPermission, Debug, FormatMoney, GetLeftPosition, GetPages } from './functions';
import { Graph } from './graph';

export const SMSCredit = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    const titleHeight = 60;

    // IMAGES
    //const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // VALUES
    const [credit, setCredit] = useState(0);
    const [year, setYear] = useState(GetTodayYear());
    const [graphData, setGraphData] = useState(false);

    // DIALOGS
    const [showBuy, setShowBuy] = useState(false);
    const [showBuySuccess, setShowBuySuccess] = useState(false);
    const [showBuyDetails, setShowBuyDetails] = useState(false);
    const [showSMSDetails, setShowSMSDetails] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 60;
    const menuHeight = 50;
    const rowHeight = 60;
    const buyHeight = 80;

    const tabs = [
        { id: 0, enabled: true, label: lang.sms_notify_credit_buyed },
        { id: 1, enabled: true, label: lang.sms_notify_credit_stat },
    ];

    let icon_color = [color.dark_green, color.dark_red, color.black, color.dark_gray];
    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_purchases(0, { year: year });

            running = true;
        }

    }, []);


    const db_purchases = async (page, filter) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'credit_purchases', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setCredit(json.credit);

                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_stats = async (year) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'credit_stats', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    year: year
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setCredit(json.credit);
                setGraphData(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_stats_details = async (page, year) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'credit_stats_details', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    start: page * global.items_max,
                    length: global.items_max,
                    year: year,
                    language_id: props.user.language_id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setCredit(json.credit);

                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeIndex = (id) => {
        setTabIndex(id);
        if (id == 0) {
            let year_ = GetTodayYear();
            setItemsFiltered(false);
            setItems(false);
            setPage(0);
            setPages(0);
            setYear(year_);
            db_purchases(0, { year: year_ });
        }
        if (id == 1) {
            let year_ = GetTodayYear();
            setItemsFiltered(false);
            setItems(false);
            setPage(0);
            setPages(0);
            setYear(year_);
            db_stats(year_);
        }
    }

    const BuyResult = (typ) => {
        setShowBuy(false);

        if (typ == true) {
            // KREDIT JE OBJEDNANY
            var timer = setTimeout(() => {
                setShowBuySuccess(true);
            }, 250);

        }
    }

    const ClosePurchase = () => {
        setShowBuySuccess(false);
        setYear(GetTodayYear());
        db_purchases(0, { year: GetTodayYear() });
    }

    const ChangePage = (value) => {
        if (tabIndex == 0) {
            db_purchases(value - 1, filter);
        } else {
            db_stats_details(value - 1, year);
        }
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        if (CheckPermission(props.user.super_admin, props.permissions['sms_notify']).editing == true) {
            setItemSelected(item);
            setShowBuyDetails(true);
        }
    }

    const SelectYearResult = (year_, month) => {
        setYear(year_);
        db_purchases(0, { year: year_ });
    }

    const CreditDetailsResult = (redraw) => {
        setShowBuyDetails(false);

        if (redraw == true) {
            db_purchases(page, filter);
        }
    }

    const GraphResult = (id, value, value2) => {
        if (id == -2) {
            // ZMENA ROKA
            setItems(false);
            setItemsFiltered(false);
            setPage(0);
            setPages(0);
            setYear(value);
            db_stats(value);
        }
        if (id == -1) {
            // STLPEC - deselect
            setItems(false);
            setItemsFiltered(false);
            setPage(0);
            setPages(0);
        }
        if (id >= 0) {
            // OZNACENY STLPEC
            setItems(false);
            setItemsFiltered(false);
            setPage(0);
            setPages(0);
            setYear(value2.year);
            db_stats_details(0, value2.year);
        }
    }

    const SelectSMS = (item) => {
        setItemSelected(item);
        setShowSMSDetails(true);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <Paper elevation={global.elevation_form} style={{ ...styles.Block, maxWidth: global.max_width, backgroundColor: color.white, borderRadius: props.radius, minHeight: buyHeight - 10, marginTop: 10 }}>
                        <div style={{ ...styles.BlockRow, width: '96%' }}>
                            <div style={{ ...styles.BlockLeft, width: '50%', height: titleHeight }}>
                                <p style={{ ...styles.TextXSmall }}>{lang.sms_notify_credit_status}: <b>{FormatMoney(credit, 2)}</b></p>
                            </div>
                            <div style={{ ...styles.BlockRight, width: '50%', height: titleHeight }}>
                                {CheckPermission(props.user.super_admin, props.permissions['credit_buy']).enabled == true ?
                                    <Button onClick={() => setShowBuy(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_dark }}>{lang.sms_notify_credit_buy}</Button>
                                    : null}
                            </div>
                        </div>
                    </Paper>

                    <div style={{ ...styles.Block, minHeight: tabHeight }}>
                        <DialogTabs center value={tabIndex} items={tabs} width={200} theme={props.theme} func={(id) => ChangeIndex(id)} />
                    </div>

                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, paddingBottom: global.list_padding, height: props.height - global.widow_bar_height - tabHeight - buyHeight - global.list_padding - 2, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div id={'id_start'} style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                                <div style={{ ...styles.BlockCenter, width: '96%' }}>
                                    <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray }}>
                                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                            <SelectYear min={2020} value={year} theme={props.theme} func={SelectYearResult.bind(this)} />
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, marginTop: 20 }}>
                                        <div style={{ ...styles.BlockLeft, paddingBottom: 10 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.sms_credit_purchase_list}</p>
                                        </div>
                                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                onClick={() => SelectItem(item)}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: item.status == 3 ? color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.status == 3 ? color.disabled_border : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '100%' }}>
                                                    <div style={{ ...styles.Block, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        {global.sms_credit_status[item.status] != false ?
                                                            <FontAwesomeIcon style={{ height: 16, color: color.black }} icon={global.sms_credit_status[item.status]} />
                                                            : null}
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXSmall }}>{lang.sms_credit_purchase_credit}: {FormatMoney(item.credits, 2)}</p>
                                                        <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{lang.sms_credit_purchase_price}: {FormatMoney(item.price, 2)} €</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight, justifyContent: 'center', borderRight: '1px solid ' + color.light_gray }}>
                                                        <p style={{ ...styles.TextXTiny }}>{lang.sms_credit_purchase_state}:</p>
                                                        <p style={{ ...styles.TextTiny, marginTop: 2, fontWeight: '600', color: icon_color[item.status] }}>{lang.sms_credit_purchase_status[item.status]}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: '18%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXTiny }}>{lang.sms_credit_purchase_date}</p>
                                                        <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{GetDatumTime2(item.created)}</p>
                                                    </div>
                                                </div>
                                            </Paper>
                                        )) :
                                            <EmptyList row small lang={lang} />
                                        }

                                        {/* PAGINATION */}
                                        {pages > 1 ?
                                            <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            </div>
                                            : null}

                                    </div>

                                </div>
                            </div>
                        </div>
                        : null}


                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockCenter, paddingBottom: global.list_padding, height: props.height - global.widow_bar_height - tabHeight - buyHeight - global.list_padding - 2, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                                <div style={{ ...styles.BlockCenter, width: '96%' }}>

                                    <div style={{ ...styles.BlockCenter, marginTop: 20 }}>
                                        <Graph
                                            lang={props.lang}
                                            elevation={0}
                                            radius={0}
                                            title={lang.sms_notify_credit_stat_label}
                                            sub_title={''}
                                            title_align={'left'}
                                            width={global.max_width}
                                            height={400}
                                            typ={0}
                                            data={graphData}
                                            barWidth={50}
                                            highColor={global.theme_red}
                                            overColor={global.theme_blue}
                                            showMax={false}
                                            lines={true}
                                            units={''}
                                            marginTop={10}
                                            selectable={true}
                                            show_value={true}
                                            year={true}
                                            legend={false}
                                            theme={props.theme}
                                            func={GraphResult.bind(this)}
                                        />
                                    </div>

                                </div>
                            </div>
                            {itemsFiltered != false ?
                                <div id={'id_start'} style={{ ...styles.BlockCenter, maxWidth: global.max_width, marginTop: 20 }}>
                                    <div style={{ ...styles.BlockLeft, paddingBottom: 10 }}>
                                        <p style={{ ...styles.TextTiny }}>{lang.sms_notify_credit_list}</p>
                                    </div>
                                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            onClick={() => SelectSMS(item)}
                                            style={{
                                                ...styles.Block,
                                                backgroundColor: item.status == 3 ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '96%' }}>
                                                <div style={{ ...styles.BlockLeft, width: '30%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextXTiny }}>{lang.sms_notify_credit_sender}</p>
                                                    <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{item.user}</p>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '30%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextXTiny }}>{lang.sms_notify_credit_receiver}</p>
                                                    <p style={{ ...styles.TextXSmall, marginTop: 2 }}>+{item.receiver}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: '20%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextXTiny }}>{lang.sms_credit_purchase_credit}</p>
                                                    <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{item.credit}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: '20%', minHeight: rowHeight, justifyContent: 'center', borderLeft: '1px solid ' + color.light_gray }}>
                                                    <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{GetDatumTime2(item.created)}</p>
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }

                                    {/* PAGINATION */}
                                    {pages > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                        </div>
                                        : null}

                                </div>
                                : null}
                        </div>
                        : null}


                </div>
            </div>

            {showSMSDetails == true ?
                <SMSDetail item={itemSelected} organization={props.organization} user={props.user} settings={props.settings} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={() => setShowSMSDetails(false)} />
                : null}

            {showBuy == true ?
                <SMSCreditBuy organization={props.organization} user={props.user} settings={props.settings} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={BuyResult.bind()} />
                : null}

            {showBuySuccess == true ?
                <DialogInfo title={lang.info_} text={lang.sms_credit_buy_success_text} sub_text={lang.sms_credit_buy_note} button={lang.close} icon={faCheckCircle} icon_color={color.dark_green} theme={props.theme} radius={props.radius} func={() => ClosePurchase(false)} />
                : null}

            {showBuyDetails == true ?
                <SMSCreditDetails item={itemSelected} organization={props.organization} user={props.user} settings={props.settings} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} permissions={props.permissions} isSmall={props.isSmall} token={props.token} func={CreditDetailsResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}

/*
*
* =================================================================================================================
* DETAIL OBJEDNAVKY KREDITOV
* =================================================================================================================
*
*/

export const SMSCreditDetails = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = { enabled: true };

    // IMAGES
    //const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [purchase, setPurchase] = useState(false);
    const [userName, setUsername] = useState('');

    // DIALOGS
    const [showCancelOrder, setShowCancelOrder] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 700 ? 700 : width;
    const dialogHeight = height >= 350 ? 350 : height;

    const buttonHeight = 80;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'credit_purchase', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    purchase_id: props.item.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setPurchase(json.purchase);
                setUsername(json.user_name);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'credit_purchase_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const CancelResult = (typ) => {
        setShowCancelOrder(false);

        if (typ == true) {
            let data = {
                id: props.item.id,
                status: 3,
                credits: purchase.credits,
                price: purchase.price,
            };

            db_update(data);
        }
    }

    const ClosePurchase = () => {
        setShowOK(false);
        func(true);
    }
    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.sms_credit_purchase_detail}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {purchase != false ?
                        <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                                <p style={{ ...styles.TextLarge }}>{lang.order}: {purchase.number}</p>
                                <p style={{ ...styles.TextXSmall, marginTop: 20 }}>{lang.sms_credit_purchase_date}: {GetDatum2(purchase.created)}</p>
                                <p style={{ ...styles.TextXSmall, marginTop: 10 }}>{lang.sms_credit_purchase_credit}: {FormatMoney(purchase.credits, 0)}</p>
                                <p style={{ ...styles.TextXSmall }}>{lang.sms_credit_purchase_price}: {FormatMoney(purchase.price, 2)} €</p>
                                <p style={{ ...styles.TextXSmall }}>{lang.sms_credit_purchase_state}: {lang.sms_credit_purchase_status[purchase.status]} </p>
                                <div style={{ ...styles.Block, marginTop: 10, paddingTop: 10, borderTop: '1px solid ' + color.light_gray }}>
                                    <p style={{ ...styles.TextXSmall, color: color.dark_gray }}>{lang.user}: {userName}</p>
                                </div>
                            </div>
                            <div style={{ ...styles.Block, height: buttonHeight }}>
                                {purchase.status == 0 && CheckPermission(props.user.super_admin, props.permissions['sms_notify']).delete == true ?
                                    <Button onClick={() => setShowCancelOrder(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, color: color.black }}>{lang.sms_credit_purchase_cancel}</Button>
                                    : null}
                            </div>
                        </div>
                        : null}
                </div >

                {showCancelOrder == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={'Otázka'} text={'Text otázky'} sub_text={''} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.button_ok} no_color={color.button_cancel} func={CancelResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => ClosePurchase()} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


/*
*
* =================================================================================================================
* KUPA KREDITU
* =================================================================================================================
*
*/

export const SMSCreditBuy = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let iconSize = 110;

    // IMAGES
    //const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [items, setItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [actualCredit, setActualCredit] = useState(0);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 300 ? 300 : height;

    const menuHeight = 60;
    const rowHeight = 70;
    const viewWidth = '96%';

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'credit_shop', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setItems(json.settings);
                setActualCredit(parseFloat(json.credit).toFixed(2));
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'credit_shop_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    credit: selectedItem.credit,
                    price: selectedItem.price,
                })
            })

            const json = await response.json();

            setBusy(false);

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }


    const ShowOKPress = () => {
        setShowOK(false);
        func(true);
    }

    const Buy = () => {
        // KUPA KREDITU

        db_update();
    }

    const CreditIcon = (props) => {
        return (
            props.buy == true ?
                <div style={{ ...styles.BlockCenter, width: iconSize, height: iconSize + 100 }}>
                    <Paper elevation={3} style={{ ...styles.Block, width: iconSize - 4, height: iconSize - 4, borderRadius: '50%', border: '2px solid ' + props.item.color }}>
                        <p style={{ ...styles.TextLarge, fontWeight: '600', letterSpacing: 1.2 }}>{FormatMoney(props.item.credit, 0)}</p>
                        <p style={{ ...styles.TextTiny }}>{lang.credits_}</p>
                    </Paper>
                    <p style={{ ...styles.TextXSmall, marginTop: 5 }}>{FormatMoney(props.item.price, 2)} €</p>

                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                        <Button onClick={() => setSelectedItem(props.item)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, width: iconSize, color: color.black }}>{lang.choose_}</Button>
                    </div>
                </div>
                :
                <div style={{ ...styles.Block, width: '90%' }}>
                    <div style={{ ...styles.Block, width: iconSize + 10, height: iconSize + 20 }}>
                        <Paper elevation={3} style={{ ...styles.Block, width: iconSize, height: iconSize, borderRadius: '50%', border: '2px solid ' + props.item.color }}>
                            <p style={{ ...styles.TextLarge, fontWeight: '600', letterSpacing: 1.2 }}>{FormatMoney(props.item.credit, 0)}</p>
                            <p style={{ ...styles.TextTiny }}>{lang.credits_}</p>
                        </Paper>
                    </div>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - iconSize - 20 }}>
                        <div style={{ ...styles.Block }}>
                            <p style={{ ...styles.TextXSmall, marginTop: 5, fontWeight: '600' }}>{lang.credit_price}: {FormatMoney(props.item.price, 2)} €</p>
                            <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.sms_credit_buy_note}</p>
                        </div>
                        <div style={{ ...styles.BlockRow, marginTop: 24 }}>
                            <div style={{ ...styles.Block, width: '50%' }}>
                                <Button onClick={() => Buy()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.buy}</Button>
                            </div>
                            <div style={{ ...styles.Block, width: '50%' }}>
                                <Button onClick={() => setSelectedItem(false)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.cancel}</Button>
                            </div>
                        </div>

                    </div>
                </div>
        )
    }
    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faShoppingCart} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.sms_notify_credit_buy_label}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {selectedItem == false ?
                        items != false ?
                            <div style={{ ...styles.BlockRow, width: '90%' }}>
                                {items.map((item, index) => (
                                    <div key={item.id} style={{ ...styles.Block, width: '33%', height: dialogHeight - global.dialog_bar_height }}>
                                        <CreditIcon item={item} buy={true} />
                                    </div>
                                ))}
                            </div>
                            : null :
                        <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height }}>
                            <CreditIcon item={selectedItem} color={color.blue} buy={false} />
                        </div>
                    }
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind()} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


/*
*
* =================================================================================================================
* DETAIL SMS Správy
* =================================================================================================================
*
*/

export const SMSDetail = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let iconSize = 110;

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 320 ? 320 : height;

    let { func } = props;

    const Close = () => {
        func(false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faShoppingCart} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.sms_notify_credit_details}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <p style={{ ...styles.TextXSmall, marginTop: 10 }}>{lang.date}: {GetDatumTime2(props.item.created)}</p>
                    <p style={{ ...styles.TextXSmall, marginTop: 20 }}>{lang.sms_notify_credit_sender}: {props.item.user}</p>
                    <p style={{ ...styles.TextXSmall }}>{lang.sms_notify_credit_receiver}: {props.item.receiver}</p>
                    <p style={{ ...styles.TextXSmall }}>{lang.sms_credit_purchase_credit}: {props.item.credit}</p>
                    <p style={{ ...styles.TextXSmall, marginTop: 20 }}><b>{lang.sms_notify_message}:</b></p>
                    <p style={{ ...styles.TextXSmall }}>{props.item.message}</p>
                </div >

            </Paper >
        </Backdrop>
    );
}



