/*
*
* =================================================================================================================
* DODAVATELIA

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, PlusMinus, ButtonNew, DeleteButton, FormSelect, DialogEnterText, DialogEnter, FormNote, FormButton, ButtonChangeStyle, ProductSearch } from './items';
import { styles } from './styles';
import { Backdrop, Box, Button, Checkbox, Chip, Hidden, IconButton, ListItemIcon, ListItemText, MenuItem, Pagination, Paper, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowPointer, faArrowUp, faAsterisk, faBan, faBars, faInfo, faInfoCircle, faLayerGroup, faPaperPlane, faPercent, faPercentage, faPlus, faPlusCircle, faQuestion, faRotate, faRotateLeft, faSave, faSearch, faTableCells, faTrash, faUser, faWarning, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, CoeficientToPercentage, ConvertToPrice, ConvertToPrice2, Debug, FormatMoney, FormatPieces, GetLeftPosition, GetPages, GetPriceDiscount, IsEmpty, LoadArray, LoadBool, LoadValue, RoundValue, SaveArray, SaveBool, SaveValue, StockCoveredChip } from './functions';
import { Photos } from './photos';
import { GetDatum2, GetDatumTime, GetDatumTime2 } from './functions_date.js';
import {
    DataGrid, skSK,
    GridColumnMenu,
} from '@mui/x-data-grid';
import { ProductSelect } from './product_select.js';
import { CustomerSelect } from './customers.js';
import { OrderType } from './order_types.js';
import { Notes } from './notes.js';
import { UsersSelect } from './users_select.js';

export const StockRemovals = (props) => {

    let module_id = global.model.stock_removals;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'stock_removals';
    let currency = props.locale.currency;
    let max_width = props.width > 1100 ? 1100 : props.width;
    let stocks_limited = props.permissions.stocks_limited == true && props.user.super_admin == false ? true : false;

    let defaultFilter = {
        ordering: global.order.up,
        order_column: 'created',
        customer_id: props.customerID == undefined ? 0 : props.customerID,
        country_id: props.locale.id,
        agent_id: stocks_limited == true ? props.user.id : undefined,
    }

    const [precisions, setPrecisions] = useState(props.system.precisions == 0 ? 2 : 3);
    const [maxWidth, setMaxWidth] = useState(max_width);

    var columns = [
        {
            field: 'number',
            headerName: lang.order_number,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 160,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: false,
            hide: false,
        },
        {
            field: 'company',
            headerName: lang.company_name,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 250,
            editable: false,
            sortable: false,
            sorting: true,
            hideable: true,
            hide: false,
        },
        {
            field: 'surname',
            headerName: lang.surname,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 180,
            editable: false,
            sortable: false,
            sorting: true,
            hide: false,
        },
        {
            field: 'name',
            headerName: lang.name,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            //renderCell: (params) => (params.row.parent_id == 0 ? params.row.name : params.row.parent_name + ' - ' + params.row.name),
            type: 'string',
            align: 'left',
            width: 180,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'user',
            headerName: lang.user,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'string',
            align: 'left',
            width: 180,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'products',
            headerName: lang.stock_removals_products,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            type: 'number',
            align: 'center',
            width: 150,
            sortable: false,
            sorting: true,
            editable: false,
            hide: false,
        },
        {
            field: 'status',
            headerName: lang.status,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            //type: 'number',
            renderCell: (params) => lang.stock_removals_status[params.row.status],
            align: 'center',
            width: 140,
            editable: false,
            sortable: false,
            sorting: false,
            hide: false,
            hideable: true,
        },
    ];

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    var itemsExportMenu =
        [
            { id: 1, checked: false, enabled: true, label: lang.export_selected },
            { id: 2, checked: false, enabled: true, label: lang.export_all },
            { id: 30, divider: true },
            { id: 5, checked: LoadBool('stock-removals-mode', true), enabled: true, label: lang.preview_mode },
            { id: 31, divider: true },
            { id: 3, checked: false, enabled: true, icon: faRotateLeft, label: lang.refresh }

        ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    const orderStates = [
        { id: -1, name: lang.stock_removals_all },
        { id: 0, name: lang.stock_removals_status[global.stock_removals.created] },
        { id: 1, name: lang.stock_removals_status[global.stock_removals.finished] },
    ]
    const [orderStateID, setOrderStateID] = useState(-1);

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);
    const [stocks, setStocks] = useState(false);
    const [stockID, setStockID] = useState(0);

    const [displayStyle, setDisplayStyle] = useState(LoadValue('stock-removals-style', 0));
    const [previewMode, setPreviewMode] = useState(LoadBool('stock-removals-mode', true));
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(LoadArray('stock-removals-table', {}));

    const [showUsers, setShowUsers] = useState(false);
    const [agentID, setAgentID] = useState(0);
    const [agent, setAgent] = useState(false);

    const [userID, setUserID] = useState(0);
    const [user, setUser] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.name, default: true },
        { id: 2, enabled: true, checked: false, column: 'town', label: lang.town },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showOrderNew, setShowOrderNew] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 96;
    const tableLabelHeight = 40;
    const paginationHeight = 50;
    const widthOffset = 20;
    const chipHeight = 50;
    const previewHeight = 112;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);
            db_data();

            running = true;
        }

    }, [props.locale]);


    const db_get = async (page, filter, maxItems) => {
        setBusy(true);
        Debug('filter', 'red');
        Debug(filter);

        try {
            const response = await fetch(
                global.db_url + 'stock_removals', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_data = async () => {
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();
            Debug('PODUCT DATA', 'cyan');
            Debug(json);
            if (json.ok > 0) {
                var array = [{ id: 0, name: lang.stock_all }];
                var tmp = json.stocks;
                tmp = tmp.filter(x => x.type == 1);
                if (tmp.length == 0) {
                    tmp = false;
                } else {
                    tmp.forEach(item => {
                        array.push(item);
                    });
                }
                setStocks(array);
            }
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.orders,
                    filename: lang.orders,
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const GetFilter = () => {
        var filter = defaultFilter;

        if (search != '') {
            filter.search = search;
        }

        if (orderStateID > 0) {
            filter.status = orderStateID;
        }
        if (stockID > 0) {
            filter.stock_id = stockID;
        }
        if (agentID > 0) {
            filter.stock_id = agent.stock_default;
        }
        if (stocks_limited == false) {
            if (userID > 0) {
                filter.user_id = userID;
            }
        }
        return filter;
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = GetFilter();

        if (txt.length > 1) {
            filter.search = txt;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            delete (filter.search);
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowOrderNew(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowDialog(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const MenuResult = (id, index, item) => {
        // EXPORT MENU       
        if (index == 5) {
            // PREVIEW MODE
            var tmp = item;
            tmp.checked = previewMode == true ? false : true;
            setPreviewMode(!previewMode);
            SaveBool('stock-removals-mode', !previewMode);
        }
        if (index == 3) {
            // RELOAD
            db_get(page, filter, maxItems);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // DATUM
                column = 'created';
                break;

            case 2: // MENO PRIEZVISKO
                column = 'surname_name';
                break;

            case 4: // STATUS
                column = 'status';
                break;

        }

        var filter_ = GetFilter();
        filter_.ordering = order;
        filter_.order_column = column;

        db_get(0, filter_, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SortUp = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.up;
        filter.order_column = data.colDef.field;
        db_get(0, filter, maxItems);
    }

    const SortDown = (data) => {
        data.hideMenu();
        var filter = GetFilter();
        filter.ordering = global.order.down;
        filter.order_column = data.colDef.field;
        db_get(0, filter, maxItems);
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }

    const ChangeStyle = (value) => {
        setItems(false);
        setItemsFiltered(false);
        setItemSelected(false);
        setDisplayStyle(value);

        if (value == 0) {
            setMaxWidth(max_width);
        } else {
            setMaxWidth(props.width);
        }
        SaveValue('stock-removals-style', value);
        db_get(0, filter, maxItems);
    }

    function MyMenuItem(props) {
        const { menuHandler, menuLabel, menuIcon } = props;
        return (
            <MenuItem onClick={menuHandler}>
                <ListItemIcon>
                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={menuIcon} />
                </ListItemIcon>
                <ListItemText>{menuLabel}</ListItemText>
            </MenuItem>
        );
    }

    function CustomColumnMenu(props) {
        // VLASTNÁ COLUMN MENU
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuSortItem: null,
                    columnMenuFilterItem: null,
                    columnMenuUserItem1: props.colDef.sorting == true ? MyMenuItem : null,
                    columnMenuUserItem2: props.colDef.sorting == true ? MyMenuItem : null,
                }
                }
                slotProps={{
                    columnMenuUserItem1: {
                        displayOrder: 1,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowUp,
                        menuHandler: () => SortUp(props),
                    },
                    columnMenuUserItem2: {
                        displayOrder: 0,
                        menuLabel: lang.order_items,
                        menuIcon: faArrowDown,
                        menuHandler: () => SortDown(props),
                    },

                }}
            />
        );
    }

    const ChangeColumns = (data) => {
        // ZOBRAZIT / SKRYT STLPCE -> uloženie do cookies
        setColumnVisibilityModel(data);
        SaveArray('stock-removals-table', data);
        Debug(columns);
    }

    const OrderNewResult = (typ, redraw_, order) => {
        Debug('order', 'red');
        Debug(order);
        setShowOrderNew(false);
        if (redraw_ == true) {
            db_get(page, filter, maxItems);
            setItemSelected(order);
            setShowDialog(true);
        }
    }

    const ChangeState = (id) => {
        setOrderStateID(id);
        var filter = GetFilter();
        if (id >= 0) {
            filter.status = id;
        } else {
            delete (filter.status);
        }

        db_get(0, filter, maxItems);
    }

    const UserOnly = () => {
        setAgentID(props.user.id);
        setAgent(props.user);

        var filter = GetFilter();
        filter.stock_id = props.user.stock_default;

        db_get(0, filter, maxItems);
    }


    const AgentReset = () => {
        setAgentID(0);
        setStockID(0);

        var filter = GetFilter();
        //delete (filter.agent_id);
        delete (filter.stock_id);

        db_get(0, filter, maxItems);
    }

    const AgentResult = (typ, selected) => {
        setShowUsers(false);

        if (typ == true && selected != false) {
            setAgentID(selected.id);
            setAgent(selected);

            var filter = GetFilter();
            filter.stock_id = selected.stock_default;

            db_get(0, filter, maxItems);
        }
    }

    return (
        <div id={'id_start'} style={{ ...styles.BlockCenter, height: props.height }}>
            <div style={{ ...styles.BlockRow, width: maxWidth, height: global.widow_bar_height }}>
                <div style={{ ...styles.Block, width: maxWidth - 50 - 50 - 60, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    {/*
                    <p style={{ ...styles.TextDialogLabel, marginLeft: 10 }}>{props.title}</p>
                    */}
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.widow_bar_height }}>
                    <ButtonChangeStyle value={displayStyle} theme={props.theme} func={(id) => ChangeStyle(id)} />
                </div>

                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.Block, marginTop: 10 }}>
                        <div style={{ ...styles.Block, width: maxWidth - widthOffset }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: 220, height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <TextInput search theme={props.theme} width={200} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        {/*
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                        */}
                                    </div>
                                </div>
                                {/*
                                <div style={{ ...styles.Block, width: 220, height: menuHeight }}>
                                    <SelectInput redraw theme={props.theme} enabled={true} lang={lang} value={stockID} items={stocks} width={200} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => ChangeStock(id)} />
                                </div>
                                */}
                                <div style={{ ...styles.Block, width: 220, height: menuHeight }}>
                                    <SelectInput redraw theme={props.theme} enabled={true} lang={lang} value={orderStateID} items={orderStates} width={200} field={'name'} label={lang.mobil} radius={global.radius} func={(id) => ChangeState(id)} />
                                </div>
                                <div style={{ ...styles.Block, width: 200, height: menuHeight }}>
                                    {stocks_limited == false ?
                                        <Button onClick={() => setShowUsers(true)} style={{ ...styles.ButtonOutlined, width: 180, fontSize: global.font_tiny }}>
                                            <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faArrowPointer} />
                                            {lang.stock_of_user}
                                        </Button>
                                        : null}
                                </div>
                                <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                                    {props.user.stock_default > 0 && stocks_limited == false ?
                                        <IconButton onClick={() => UserOnly(true)} style={{ ...styles.ButtonUser }}>
                                            <FontAwesomeIcon style={{ height: 14 }} icon={faUser} />
                                        </IconButton>
                                        : null}
                                </div>
                                <div style={{ ...styles.BlockRight, width: maxWidth - 220 - 220 - 200 - 50, height: menuHeight }}>
                                    {CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 190 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.stock_removals_new}
                                        </Button>
                                        : null}
                                </div>
                            </div>

                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {agentID > 0 ?
                                        <Chip size='medium' style={{ backgroundColor: color.xxgray }} label={<p style={{ ...styles.TextTiny, color: color.darker_gray }}>{lang.stock_of_rep}: {agent.name + ' ' + agent.surname}</p>} onDelete={() => AgentReset()}></Chip>
                                        : null}
                                </div>
                            </div>

                            {displayStyle == 0 ?
                                <div style={{ ...styles.Block, marginTop: 20 }}>

                                    {/* ****************************************************************************************************************
                                        TABLE HEADER
                                    **************************************************************************************************************** */}

                                    <div style={{ ...styles.Block, marginBottom: 10, border: color.border }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.Block, width: 50, height: tableLabelHeight, justifyContent: 'center' }}>
                                                <Checkbox checked={selectedAll} size={'small'} onChange={() => ChangeSelectedAll()} />
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: 150, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.order_number}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: maxWidth - 50 - 150 - 150 - 160 - 160 - widthOffset, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.customer}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 160, height: tableLabelHeight, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.stock_removals_products}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 160, height: tableLabelHeight, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.status}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: 120, height: tableLabelHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.date}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableLabelHeight, justifyContent: 'center' }}>
                                                    <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ****************************************************************************************************************
                                        ZOZNAM OBJEDNAVOK
                                    **************************************************************************************************************** */}

                                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block,
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.status < global.orders.finished ? '4px solid ' + global.stock_removals_colors[item.status] : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '99%' }}>
                                                <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                    {/* SELECT */}
                                                    <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                                </div>

                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny }}>{item.number}</p>
                                                </div>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: maxWidth - 50 - 120 - 150 - 160 - 160 - widthOffset, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{item.company}</p>
                                                    <p style={{ ...styles.TextTiny }}>{item.name} {item.surname}</p>
                                                    {/*
                                                    <p style={{ ...styles.TextTiny, marginTop: 10 }}>{item.user}</p>
                                                    */}
                                                </div>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 160, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {parseInt(item.products) > 0 ?
                                                        <p style={{ ...styles.TextXSmall }}>{item.products}</p>
                                                        :
                                                        <Chip label={lang.stock_removal_empty} size='small' style={{ fontWeight: '400', backgroundColor: color.error, color: color.white, fontSize: global.font_tiny }}></Chip>
                                                    }
                                                </div>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 160, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {/* STATUS */}
                                                    <Chip label={lang.stock_removals_status[item.status]} variant='outlined' size='small' style={{ borderColor: global.stock_removals_colors[item.status], color: global.stock_removals_colors[item.status], fontSize: global.font_tiny }}></Chip>
                                                </div>

                                                {/* DATA */}
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny }}>{GetDatum2(item.created)}</p>
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <div style={{ ...styles.Block, paddingTop: 20 }}>
                                            <EmptyList row small lang={lang} />
                                        </div>
                                    }

                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                    **************************************************************************************************************** */}

                                    <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            {itemsFiltered != false ?
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                        <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                        <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}


                            {displayStyle == 1 ?
                                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - menuHeight - chipHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <Box sx={{
                                        height: previewMode == true ? props.height - global.widow_bar_height - menuHeight - chipHeight - 84 - previewHeight : props.height - global.widow_bar_height - menuHeight - chipHeight - 84,
                                        width: 1,
                                        /*
                                        '& .super-app-theme--header': {
                                            backgroundColor: color.light_gray,
                                        },
                                        */
                                        '& .super-app-theme--normal': {
                                            backgroundColor: color.white,
                                            color: '#000000',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.table_row),
                                            },
                                        },
                                        '& .super-app-theme--red': {
                                            backgroundColor: color.lighteen_blue,
                                            color: color.dark_red,
                                            fontWeight: 'normal',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.gray),
                                            },
                                        },
                                        '& .super-app-theme--blue': {
                                            backgroundColor: color.white,
                                            color: color.blue,
                                            fontWeight: 'normal',
                                            '&:hover': {
                                                bgcolor: (theme) => (color.gray),
                                            },
                                        },
                                    }}>
                                        <DataGrid

                                            sx={{
                                                "& .MuiDataGrid-columnHeaderTitle": {
                                                    whiteSpace: "normal",
                                                    lineHeight: "normal"
                                                },
                                                "& .MuiDataGrid-columnHeader": {
                                                    height: "unset !important"
                                                },
                                                "& .MuiDataGrid-columnHeaders": {
                                                    maxHeight: "168px !important"
                                                },
                                                fontSize: global.font_table, border: global.theme_table_border_width, borderColor: color.dark_gray,
                                            }}

                                            rows={itemsFiltered == false ? [] : itemsFiltered}
                                            columns={columns.filter(x => x.hide == false)}
                                            slots={{ columnMenu: CustomColumnMenu }}
                                            rowHeight={previewMode == true ? 30 : undefined}

                                            checkboxSelection
                                            disableRowSelectionOnClick
                                            onRowSelectionModelChange={(newRowSelectionModel) => { setItemsSelected(newRowSelectionModel) }}
                                            onRowClick={(params) => { setItemSelected(params.row) }}
                                            onRowDoubleClick={(params) => { SelectItem(params.row) }}
                                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                            getRowClassName={(params) => `super-app-theme--normal`}
                                            hideFooterRowCount={true}
                                            hideFooterSelectedRowCount={true}
                                            hideFooterPagination
                                            hideFooter
                                            columnVisibilityModel={columnVisibilityModel}
                                            onColumnVisibilityModelChange={(newModel) =>
                                                ChangeColumns(newModel)
                                            }
                                        />
                                    </Box>

                                    {previewMode == true && itemSelected != false ?
                                        <div style={{ ...styles.Block, height: previewHeight, marginTop: 5 }}>
                                            <div style={{ ...styles.BlockRow, width: '100%' }}>
                                                <div style={{ ...styles.BlockLeft, width: previewHeight, height: previewHeight, justifyContent: 'center' }}>
                                                    <Avatar picture image={itemSelected.image} size={previewHeight - 10} theme={props.theme} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '40%', height: previewHeight, justifyContent: 'center' }}>
                                                    {itemSelected.company != '' ?
                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{itemSelected.company}</p>
                                                        : null}
                                                    <p style={{ ...styles.TextTiny, fontWeight: itemSelected.company != '' ? '400' : '600' }}>{itemSelected.surname} {itemSelected.name}</p>

                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '40%', height: previewHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    {itemSelected.phone != '' ?
                                                        <p style={{ ...styles.TextXSmall, marginLeft: 10 }}>{lang.phone}: {itemSelected.phone}</p>
                                                        : null}
                                                    {itemSelected.mobil != '' ?
                                                        <p style={{ ...styles.TextXSmall, marginLeft: 10 }}>{lang.mobil}: {itemSelected.mobil}</p>
                                                        : null}
                                                    {itemSelected.email != '' ?
                                                        <p style={{ ...styles.TextXSmall, marginLeft: 10 }}>{lang.email}: {itemSelected.email}</p>
                                                        : null}
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                    {/* ****************************************************************************************************************
                                        PAGINATION
                                        **************************************************************************************************************** */}
                                    <div style={{ ...styles.BlockRow, paddingTop: 10, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 200 }}></div>
                                        <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                            {pages > 1 ?
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            {itemsFiltered != false ?
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                        <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                        <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>

                                </div>
                                : null}


                        </div>
                    </div>
                </div>
            </div>

            {showUsers == true ?
                <UsersSelect user_stock organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.stock_of_rep_choose} token={props.token} func={AgentResult.bind()} />
                : null}

            {showOrderNew == true ?
                <StockRemovalNew customerID={props.customerID == undefined ? 0 : props.customerID} item={itemSelected} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} settings={props.settings} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={OrderNewResult.bind()} />
                : null}

            {showExport == true ?
                <DialogExport rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <StockRemoval module_id={module_id} item={itemSelected} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </div >
    );
}



/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const StockRemoval = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;
    let precisions = 2;
    let stock_default = props.user.stock_default;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = { enabled: true, all_records: true };

    let status_menu = [
        { id: global.stock_removals.created, label: lang.stock_removals_status[global.orders.created] },
        { id: global.stock_removals.finished, label: lang.stock_removals_status[global.orders.finished] },
    ];


    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [redraw, setRedraw] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [editing, setEditing] = useState(props.item == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [order, setOrder] = useState(false);
    const [orderEdit, setOrderEdit] = useState(false);
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [excluded, setExcluded] = useState([]);
    const [status, setStatus] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [maxItems, setMaxItems] = useState(50);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);

    // OBJEDNAVKA
    const [stocks, setStocks] = useState(false);
    const [stockID, setStockID] = useState(stock_default);
    const [number, setNumber] = useState('');

    // ZAKAZNIK
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [enabled, setEnabled] = useState(true);
    const [customerID, setCustomerID] = useState(0);
    const [company, setCompany] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [street, setStreet] = useState('');
    const [town, setTown] = useState('');
    const [address, setAddress] = useState(false);
    const [psc, setPSC] = useState('');
    const [state, setState] = useState('');
    const [phone, setPhone] = useState('');
    const [mobil, setMobil] = useState('');
    const [email, setEmail] = useState('');
    const [ico, setICO] = useState('');
    const [dic, setDIC] = useState('');
    const [icDPH, setICDPH] = useState('');
    const [dph, setDPH] = useState(false);
    const [note, setNote] = useState('');
    const [avatar, setAvatar] = useState('');
    const [countryID, setCountryID] = useState(props.locale.id);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DOPLNIT PRODUKT
    const [selectProducts, setSelectProducts] = useState(false);

    // VYMAZANIE OBJEDNAVKY - ZRUSENIE
    const [showDelete, setShowDelete] = useState(false);

    // VYKONANIE VYSKLADNENIA
    const [showExec, setShowExec] = useState(false);

    // PRODUKT - DETAILS
    const [showProduct, setShowProduct] = useState(false);

    // VYBER ZAKAZNIKA
    const [showCustomers, setShowCustomers] = useState(false);

    // UKONCENIE - ODOSLANIE OBJEDNAVKY
    const [showFinalize, setShowFinalize] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1100 ? 1100 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = props.item == false ? 0 : 40;
    const buttonHeight = 80;
    const errorHeight = 40;
    const column1 = 150;
    const column2 = 400;
    const rowProductHeight = 70;
    const rowHeight = 92;
    const tableHeight = 40;
    const sumHeight = 66;


    let itemsExportMenu = [
        { id: 1, enabled: true, label: lang.order_export_pdf },
    ];

    const tabs = [
        { id: 0, enabled: true, label: lang.products },
        { id: 3, enabled: true, label: lang.order_settings },
        { id: 1, enabled: true, label: lang.customer },
        { id: 4, enabled: true, label: lang.notes },
        { id: 2, enabled: true, label: lang.history },
    ];

    let { func } = props;
    var running = false;
    var lineChange = false;
    var lineType = 0;

    useEffect(() => {
        if (running == false) {
            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();

                var filter = defaultFilter;
                filter.stock_id = props.item.stock_id;
                db_get_products(0, filter, true);
            }

            db_data();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'stock_removal', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();
            Debug('VYSKLADNENIE', 'white');

            Debug(json);
            if (json.ok > 0) {
                if (json.item != false && json.customer != false) {
                    let item = json.item;
                    setOrder(item);
                    setStatus(item.status);
                    setNumber(item.number);
                    setStockID(item.stock_id);

                    AssignData(json.customer);
                    if (json.address != false) {
                        let address_ = json.address;
                        setAddress(address_);
                        setStreet(address_.street);
                        setTown(address_.town);
                        setPSC(address_.psc);
                        setState(address_.state);
                    } else {
                        setAddress('');
                        setStreet('');
                        setTown('');
                        setPSC('');
                        setState('');
                    }
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_data = async () => {
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();
            Debug('PODUCT DATA', 'cyan');
            Debug(json);
            if (json.ok > 0) {

                var tmp = json.stocks;
                tmp = tmp.filter(x => x.type == 1);
                if (tmp.length == 0) {
                    tmp = false;
                }
                setStocks(tmp);
            }
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_products = async (page, filter_, scroll) => {
        setBusy(true);
        Debug('filter', 'orange');
        Debug(filter_);
        try {
            const response = await fetch(
                global.db_url + 'stock_removal_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    stock_removal_id: itemID,
                    //start: page * maxItems,
                    //length: maxItems,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug('PRODUCTS', 'yellow');
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    var tmp = json.items;

                    if (tmp != false) {
                        /*
                        tmp.sort((a, b) => {
                            if (a.name < b.name) {
                                return 1;
                            } else {
                                return -1;
                            }
                        })

                        tmp.sort((a, b) => {
                            if (parseInt(a.order_type_id) > parseInt(b.order_type_id)) {
                                return 1;
                            } else {
                                return -1;
                            }
                        })
                        */
                    }

                    setItems(tmp);
                    setExcluded(json.items_id);

                    setPage(page);
                    setPages(GetPages(json.count, maxItems));
                    RedrawPage(tmp, page, search, scroll);

                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                setRedrawRequired(true);
                setShowOK(true);

                if (data.customer_id != undefined) {
                    db_get();
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_product_update = async (data, scroll) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_removal_product_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setRedraw(!redraw);
                setRedrawRequired(true);

                db_get_products(page, GetFilter(), scroll);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_exec = async () => {
        setBusy(true);

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: itemID,
            action: global.logs.stock_removal_finished
        };

        try {
            const response = await fetch(
                global.db_url + 'stock_removal_exec', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    stock_removal_id: itemID,
                    log: log
                })
            })

            const json = await response.json();
            Debug('stock_removal_exec', 'cyan');
            Debug(json);
            if (json.ok > 0) {
                setStatus(global.stock_removals.finished);
                setRedraw(!redraw);
                setRedrawRequired(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export_pdf = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stock_romoval_export_pdf', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------                    
                    transport_id: itemID
                })
            })

            const json = await response.json();
            Debug('Export PDF');
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setRedrawRequired(true);
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const GetFilter = () => {
        var filter_ = defaultFilter;
        filter_.stock_id = stockID;

        return filter_;
    }


    const Close = () => {
        func(redrawRequired);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setCustomerID(item.id);
        setCompany(item.company);
        setName(item.name);
        setSurname(item.surname);
        setMobil(item.mobil);
        setPhone(item.phone);
        setEmail(item.email);
        setICO(item.ico);
        setDIC(item.dic);
        setICDPH(item.ic_dph);
        setDPH(item.dph == false ? false : true);
        setNote(item.note);
        setAvatar(item.avatar);
        setCountryID(item.country_id);
    }

    const MenuResult = (id, index, item) => {
        switch (index) {
            case 1:
                db_export_pdf();
                break;
        }
    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 2) {
            db_get_logs();
        }
        if (id == 0) {
            db_get_products(page, GetFilter(), true);
        }
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id-products-start');
            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 150);
    }

    const SliceItems = (items_, page_) => {
        var data = false;
        if (items_ != false) {
            data = items_.slice(maxItems * page_, (maxItems * page_) + maxItems)
        }
        return data;
    }

    const RedrawPage = (items_, page_, search_, scroll) => {
        let data = items_;

        if (data != false) {
            if (search_.trim() != '') {
                data = data.filter(x => x.name.toLowerCase().includes(search_.toLowerCase()) || x.parent_name.toLowerCase().includes(search_.toLowerCase()));
            }
            setPages(GetPages(data.length, maxItems));

        } else {
            setPages(0);
        }

        setItemsFiltered(SliceItems(data, page_));

        if (scroll == true) {
            GoToStart();
        }
    }

    const ChangePage = (value) => {
        setPage(value - 1);
        RedrawPage(items, value - 1, search, true);
        //db_get(value - 1, filter);
    }

    const SelectItem = (item) => {
        if (CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true) {
            setItemSelected(item);
            setShowProduct(true);
        }
    }

    const PlusMinusResult = (quantity, item) => {
        Debug(item);
        let data = {
            id: item.id,
            quantity: quantity,
        }

        Debug(data);
        db_product_update(data, false);
    }



    const Save = () => {
        // NIE JE POTREBNE
        setOrderEdit(false);

    }


    const ProductResult = (typ, ids, selected, order_type) => {

        if (typ == true && ids != false) {
            let item = selected[0];

            let data = {
                id: 0,
                enabled: true,
                organization_id: props.organization.id,
                stock_removal_id: itemID,
                product_id: item.id,
                uid: item.stock_uid,
                quantity: 1,
            }

            Debug(data);
            db_product_update(data, false);
        }

        setSelectProducts(false);
    }


    const DeleteResult = (value) => {
        setShowDelete(false);

        if (value == true) {
            let data = {
                id: itemID,
                status: global.orders.canceled
            }

            Debug(data);
            db_update(data);
        }
    }

    const SaveChanges = () => {
        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: itemID,
            action: global.logs.modified
        };

        let data = {
            id: itemID,
            number: number,
            logs: log
        };

        order.number = number;
        Debug(data);

        db_update(data);

    }

    const CustomersResult = (typ, items, items_) => {
        setShowCustomers(false);

        if (typ == true && items != false) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: itemID,
                action: global.logs.modified
            };

            let data = {
                id: itemID,
                customer_id: items[0],
                logs: log
            };

            order.customer_id = items[0];
            order.name = items_[0].name;
            order.surname = items_[0].surname;
            order.company = items_[0].company;

            db_update(data);
        }
    }

    const ChangeStock = (id) => {
        setStockID(id);

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: itemID,
            action: global.logs.modified
        };

        let data = {
            id: itemID,
            stock_id: id,
            logs: log
        };

        db_update(data);

    }

    const NotesResult = () => {

    }

    const SelectProducts = () => {
        setSelectProducts(true);
    }


    const ExecResult = (value) => {
        setShowExec(false);

        if (value == true) {
            db_exec();
        }
    }

    const QuickResult = (item) => {
        ProductResult(true, [item.id], [item]);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 800 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120 - 50, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.stock_removal}: <span style={{ ...styles.TextXSmall, fontWeight: '400' }}>{order != false ? order.number : ''}</span></p>
                    </div>
                    <div style={{ ...styles.Block, width: 50, height: global.widow_bar_height }}>
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    {props.item != false ?
                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs value={tabIndex} items={tabs} width={dialogWidth / 5} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        /*
                        *
                        * =================================================================================================================
                        * OBJEDNANE PRODUKTY
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.BlockCenter, height: status < global.orders.finished ? dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight : dialogHeight - global.dialog_bar_height - tabHeight - 5 }} >
                            <div style={{ ...styles.Block, width: '98%' }}>
                                <div style={{ ...styles.BlockRow, height: sumHeight, width: dialogWidth - 30 }}>
                                    <div style={{ ...styles.BlockLeft, width: '30%', height: sumHeight }}>
                                        <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.date}: {GetDatumTime2(order.created)}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: '40%', height: sumHeight }}>
                                        <p style={{ ...styles.TextXSmall }}>{lang.stock_removals_status_}: <b>{lang.stock_removals_status[status]}</b></p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '30%', height: sumHeight }}>
                                        {status == global.stock_removals.created ?
                                            <Button onClick={() => SelectProducts()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180, marginRight: 20 }}>
                                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPlusCircle} />
                                                {lang.stock_removals_add}
                                            </Button>
                                            : null}
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockRow, width: dialogWidth - 30, border: color.border, marginTop: 10, marginBottom: 10 }}>
                                    {/* DATA */}
                                    <div style={{ ...styles.BlockLeft, width: rowHeight, height: tableHeight, justifyContent: 'center' }}>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: dialogWidth - 30 - rowHeight - 200 - 180, height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 5 }}>{lang.product_name}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 200, height: tableHeight, borderLeft: color.border }}>
                                        {status == global.stock_removals.created ?
                                            <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 5 }}>{lang.product_quantity_stock_}</p>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 180, height: tableHeight, borderLeft: color.border }}>
                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 5 }}>{lang.stock_quantity}</p>
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - 130, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                    <div id={'id-products-start'} ></div>

                                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                        <div key={item.id} style={{ ...styles.Block }}>

                                            <Paper
                                                //key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: item.quantity == 0 ? color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowProductHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '98%' }}>

                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <Avatar picture image={item.image} size={rowHeight - 8} theme={props.theme} />
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockRow, overflow: 'hidden', width: dialogWidth - 40 - rowHeight - 200 - 180, minHeight: rowHeight }}>
                                                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', minHeight: rowHeight, marginLeft: 10 }}>
                                                            <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.code}</p>
                                                            {item.parent_name != '' ?
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <p style={{ ...styles.TextXSmall, fontWeight: '600', whiteSpace: 'nowrap' }}>{item.parent_name}</p>
                                                                    <p style={{ ...styles.TextTiny, whiteSpace: 'nowrap' }}>{item.name}</p>
                                                                </div>
                                                                :
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <p style={{ ...styles.TextXSmall, fontWeight: '600', whiteSpace: 'nowrap' }}>{item.name}</p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 200, minHeight: rowHeight }}>
                                                        {status == global.stock_removals.created ?
                                                            <p style={{ ...styles.TextXSmall, fontWeight: '600', whiteSpace: 'nowrap' }}>{item.stock_quantity}</p>
                                                            : null}
                                                        {status == global.stock_removals.created ?
                                                            <StockCoveredChip marginTop={5} stock_quantity={item.stock_quantity} stock_reserved={0} quantity={item.quantity} lang={props.lang} />
                                                            : null}
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 180, minHeight: rowHeight }}>
                                                        {status == global.stock_removals.created ?
                                                            <PlusMinus min={0} value={item.quantity} item={item} theme={props.theme} lang={props.lang} func={PlusMinusResult.bind(this)} />
                                                            :
                                                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{item.quantity}</p>
                                                        }
                                                    </div>

                                                </div>
                                            </Paper>
                                        </div>
                                    )) :
                                        <div style={{ ...styles.Block, paddingTop: 40 }}>
                                            <EmptyList row small lang={lang} />
                                        </div>
                                    }

                                    {status == global.stock_removals.created ?
                                        <ProductSearch excluded={excluded} marginTop={25} theme={props.theme} lang={lang} radius={props.radius} locale={props.locale} organization={props.organization} func={(item) => QuickResult(item)} />
                                        : null}

                                    {/* PAGINATION */}
                                    {pages > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                        </div>
                                        : null}

                                    <div style={{ ...styles.Block, minHeight: global.list_padding }}></div>

                                </div>

                            </div>
                        </div>
                        : null}

                    {tabIndex == 3 ?
                        /*
                        *
                        * =================================================================================================================
                        * NASTAVENIA OBJEDNAVKY
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block }}>
                                <FormSpace />
                                <FormLabel editing={false} icon={faInfoCircle} title={lang.stock_removal} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                <FormSpace />
                                <FormText require redraw value={number} editing={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? status == global.stock_removals.created ? true : false : false} title={lang.order_number_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNumber(txt)} />
                                <FormSpace />
                                {status == global.stock_removals.created ?
                                    <div>
                                        <FormButton value={company == '' ? name + ' ' + surname : company} editing={status == global.stock_removals.created ? true : false} title={lang.customer} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={() => setShowCustomers(true)} />
                                        <FormNote title={lang.stock_removals_customer_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                    </div>
                                    : null}
                                <FormSelect require value={stockID} items={stocks} field={'name'} editing={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? status == global.stock_removals.created ? true : false : false} title={lang.stock} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeStock(id)} />
                                {status == global.stock_removals.created ?
                                    <FormNote title={lang.stock_removals_stock_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                    : null}
                            </div>
                        </div>
                        : null}

                    {tabIndex == 1 ?
                        /*
                        *
                        * =================================================================================================================
                        * ZAKAZNIK - INFO
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - 5, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div>
                                <FormSpace />
                                <div style={{ ...styles.Block }}>
                                    <Avatar editable={false} picture image={avatar} size={75} theme={props.theme} func={null} />
                                    <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.default_image}</p>
                                </div>
                                <Line marginTop={10} theme={props.theme} />
                                < FormSpace />
                                <FormLabel editing={false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                <FormText require redraw value={company} editing={false} title={lang.company_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                <FormText require redraw value={name} editing={false} title={lang.name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                <FormText require redraw value={surname} editing={false} title={lang.surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                <FormText redraw value={ico} editing={editing} title={lang.ico} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setICO(txt)} />
                                <FormText redraw value={dic} editing={editing} title={lang.dic} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDIC(txt)} />
                                <FormYesNo value={dph} editing={editing} title={lang.dph_pay} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setDPH(value)} />

                                <FormSpace />
                                <FormLabel editing={false} editButton={false} title={lang.customer_shop_address} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                <FormText redraw value={street} editing={false} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setStreet(txt)} />
                                <FormText redraw value={town} editing={false} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setTown(txt)} />
                                <FormText redraw value={psc} editing={false} title={lang.psc} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPSC(txt)} />
                                <FormText redraw value={state} editing={false} title={lang.state} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setState(txt)} />

                                <FormSpace />
                                <FormLabel editing={false} editButton={false} title={lang.contact_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                <FormText redraw value={mobil} editing={false} title={lang.mobil} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setMobil(txt)} />
                                <FormText redraw value={phone} editing={false} title={lang.phone} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPhone(txt)} />
                                <FormText redraw value={email} editing={false} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />

                                <FormSpace />
                                <FormText redraw value={note} editing={false} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                                <FormSpace height={150} />

                            </div>
                        </div>
                        : null}


                    {tabIndex == 4 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Notes module_id={global.model.stock_removals} item_id={itemID} height={dialogHeight - global.dialog_bar_height - tabHeight - errorHeight} organization={props.organization} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={NotesResult.bind(this)} />
                        </div>
                        : null}

                    {tabIndex == 2 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        <div style={{ ...styles.Block, height: buttonHeight, borderTop: color.border }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {status == global.stock_removals.created && items != false ?
                                    <Button onClick={() => setShowExec(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 220, marginRight: 20 }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPaperPlane} />
                                        {lang.stock_removals_finish}
                                    </Button>
                                    : null}
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, width: 180, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faXmark} />
                                    {lang.close}
                                </Button>
                            </div>
                        </div>
                        : null}


                    {tabIndex == 3 ?
                        <div style={{ ...styles.Block, height: buttonHeight, borderTop: color.border }}>
                            {order.number != number ?
                                <Button onClick={() => SaveChanges()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 180 }}>
                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faSave} />
                                    {lang.save_changes}
                                </Button>
                                : null}
                        </div>
                        : null}
                </div >

                {selectProducts == true ?
                    <ProductSelect hide_multiple excluded={excluded} only_enabled only_sku={true} filters stock={stockID} country_id={countryID} showVariations={true} organization={props.organization} locale={props.locale} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={precisions} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={ProductResult.bind()} />
                    : null}

                {showCustomers == true ?
                    <CustomerSelect shop_select shop_required single showVariations={true} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomersResult.bind()} />
                    : null}

                {showExec == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.stock_removal} text={lang.stock_removals_exec} sub_text={lang.stock_removals_exec_note} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={ExecResult.bind(this)} />
                    : null}

                {showDelete == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.order_delete_label} text={lang.order_delete_text} sub_text={lang.order_delete_note} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={DeleteResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}





export const StockRemovalNew = (props) => {
    /*
            VYTVORENIE NOVEHO PREDAJA

            USAGE:
            <OrderNew module_id={module_id} item={itemSelected} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />

            const DialogInfoResult = (value) => {
                console.log(value)
            }
    */

    let module_id = global.model.stock_removals;
    let stock_default = props.user.stock_default > 0 ? props.user.stock_default : 0;

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);

    // DATA
    const [stocks, setStocks] = useState(false);
    const [stockID, setStockID] = useState(-1);
    const [customer, setCustomer] = useState(false);
    const [customerID, setCustomerID] = useState(props.customerID);
    const [customerShopID, setCustomerShopID] = useState(0);
    const [address, setAddress] = useState(false);

    // DIALOGS
    const [showCustomers, setShowCustomers] = useState(false);

    // SCREEN DIMS
    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 600;
    const dialogHeight = 800;

    let column1 = 150;
    let column2 = dialogWidth - column1 - 40;
    let customerHeight = 300;

    let { func } = props;
    let color = global.themes[props.theme];
    let lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {

            if (props.customerID > 0) {
                db_get(props.customerID);
            }

            db_data();

            running = true;
        }

        return () => {
        };

    }, []);


    const db_get = async (customer_id) => {
        try {
            const response = await fetch(
                global.db_url + 'customer', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,

                    id: customer_id
                })
            })

            const json = await response.json();
            Debug('CUSTOMER');
            Debug(json);
            if (json.ok > 0) {
                setCustomer(json.item);
                setCustomerID(json.item.id);
                setAddress(json.address1);
            }
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_data = async () => {
        try {
            const response = await fetch(
                global.db_url + 'product_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.stocks != false) {
                    var tmp = json.stocks;
                    tmp = tmp.filter(x => x.type == global.warehouse_type.user);
                    if (tmp.length == 0) {
                        tmp = false;
                    }

                    setStocks(tmp);

                    if (json.stocks.length == 1) {
                        setStockID(json.stocks[0].id);
                    } else {
                        setStockID(stock_default);
                    }
                }

            }
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data,
                    number_pattern: true
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                data.id = json.item_id;
                func(true, true, data);
            } else {
                setShowError(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false, false);
    }

    const CustomersResult = (typ, items, customer, address) => {
        setShowCustomers(false);

        if (typ == true && items != false) {
            var address_id = address == false ? 0 : address.id;

            setCustomer(customer);
            setCustomerID(customer.id);
            setAddress(address);

            //setCustomer(items[0]);
            setCustomerShopID(address_id);
            //db_get(items[0]);
        }
    }

    const Save = () => {
        var err = 0;
        setError('');
        if (stockID > 0) {

            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: props.item == false ? 0 : props.item.id,
                action: global.logs.created
            };

            let data = {
                id: 0,
                enabled: true,
                organization_id: props.organization.id,
                user_id: props.user.id,
                customer_id: customerID,
                shop_id: customerShopID,
                stock_id: stockID,
                number: '',
                status: 0,
                logs: log
            };

            Debug(data);
            db_update(data);
        } else {
            setError(lang.order_stock_error);
        }
    }


    const ChangeStock = (id) => {
        setStockID(id);
        setError('');
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faInfo} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.stock_removals_new}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                    <div style={{ ...styles.BlockCenter }}>
                        <div style={{ ...styles.BlockCenter, backgroundColor: color.back }}>
                            <FormSpace />
                            <FormLabel editing={false} icon={faInfoCircle} title={lang.customer} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} />
                            <FormSpace />
                            <FormSelect require value={stockID} items={stocks} field={'name'} editing={true} title={lang.stock} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeStock(id)} />
                            <FormNote title={lang.stock_removals_stock_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                            <FormSpace />

                            {customer != false ?
                                <div>
                                    <FormText redraw value={customer.company} editing={false} title={lang.company_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormText redraw value={customer.name} editing={false} title={lang.name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormText redraw value={customer.surname} editing={false} title={lang.surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                </div>
                                : null}

                            {address != false ?
                                <div>
                                    <FormSpace />
                                    <FormText redraw value={address.street} editing={false} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                    <FormText redraw value={address.town} editing={false} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} />
                                </div>
                                : null}

                            <FormSpace />

                        </div>
                        {props.customerID == 0 ?
                            <div style={{ ...styles.Block, marginTop: 0 }}>
                                <Button onClick={() => setShowCustomers(true)} style={{ ...styles.ButtonOutlined, width: 200, marginTop: 20 }}>
                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faSearch} />
                                    {lang.customer_select}
                                </Button>
                            </div>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 40 }}>
                    <FormError margin={0} error={error} small theme={props.theme} />
                </div>
                <div style={{ ...styles.Block, height: 60 }}>
                    {customerID > 0 && stockID > 0 ?
                        <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.order_create}</Button>
                        : null}
                </div>
            </Paper>

            {showCustomers == true ?
                <CustomerSelect shop_select shop_required single showVariations={true} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomersResult.bind()} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}


export const StockRemovalsWindow = (props) => {

    let module_id = global.model.vendors;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'products_vendors';
    let { func } = props;

    useEffect(() => {


    }, [props.locale]);

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => func(true)} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                <StockRemovals customerID={0} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={props.lang.orders} screenWidth={props.screenWidth} width={props.width} height={props.height} token={props.token} />
            </div>

        </Paper >
    );
}