/*
*
* =================================================================================================================

    DIALOG - OPEN AI

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    <DialogOpenAI organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={'open_ai'} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={AIResult.bind(this)} />

    const AIResult = (typ, text) => {

    }
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { FormError, Icon, Loading, ShowError, ShowOK, TextInput, TextLine } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faLayerGroup, faMicrochip, faPaperPlane, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetLeftPosition, OpenAIChat } from './functions';

export const DialogOpenAI = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let nodes = [{ id: 1, label: 'ROOT', parent_id: 0 }, { id: 2, label: 'CHILD - 1', parent_id: 1 }, { id: 3, label: 'CHILD - 2', parent_id: 1 }, { id: 4, label: 'PARENT - 2', parent_id: 0 }, { id: 5, label: 'CHILD - 3', parent_id: 2 }]

    // IMAGES
    const logo = require('./react/app/openai.png');

    const [isBusy, setBusy] = useState(false);

    // VALUES
    /*
    const [question, setQuestion] = useState(
        lang.openai_email_request + ' ' +
        lang.openai_email_company.replace('@company', props.organization.name) + ', ' +
        lang.openai_email_mobil.replace('@mobil', props.user.mobil) + ', ' +
        lang.openai_email_email.replace('@email', props.user.email) + ', ' +
        lang.openai_email_name.replace('@name', props.user.name + ' ' + props.user.surname) + '.' +
        lang.openai_email_subject + ': '
    );
    */
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState(lang.openai_answer_default);
    const [history, setHistory] = useState([]);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 550 ? 550 : height;
    const buttonHeight = 80;
    const logoSize = 80;

    let { func } = props;
    var running = false;
    const inputRef = useRef(null);

    useEffect(() => {
        if (running == false) {
            running = true;

            var ask = lang.openai_email_request + ' ' +
                lang.openai_email_company.replace('@company', props.organization.name) + ', ' +
                lang.openai_email_no_email_mobil + ', ' +
                lang.openai_email_name.replace('@name', props.user.name + ' ' + props.user.surname) + '. ' +
                lang.openai_email_subject + ': ';

            if (props.subject != undefined) {
                ask += props.subject;
            }

            setQuestion(ask);

            setTimeout(() => {
                inputRef.current.focus();
            }, 300);
        }

    }, []);

    const db_get = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'openai_chat', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    history: data
                })
            })

            const json = await response.json();
            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                var result = OpenAIChat(data, '', json.answer, lang, true); // ODPOVED
                Debug(result);
                setHistory(result);
                setAnswer(json.answer);

                setTimeout(() => {
                    inputRef.current.focus();
                }, 300);
            }

        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    }

    const Close = () => {
        func(true);
    }

    const Test = () => {
        var result = OpenAIChat(history, question, '', lang, true); // OTAZKA
        setHistory(result);
        setQuestion('');

        Debug(result);
        db_get(result);
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faMicrochip} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.openai_label}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ ...styles.Block, width: logoSize + 10, height: logoSize + 20 }}>
                            <img src={logo} style={{ width: '100%', height: '100%', maxWidth: logoSize, maxHeight: logoSize, objectFit: 'contain' }}></img>
                        </div>

                        {/* ************************************************************************************************************************
                                TVORBA EMAILU
                        ************************************************************************************************************************ */}
                        <div style={{ ...styles.Block, width: dialogWidth - (logoSize + 10) }}>
                            <div style={{ ...styles.Block, width: '98%', height: dialogHeight - global.widow_bar_height - buttonHeight }}>
                                <div style={{ ...styles.BlockLeft, height: dialogHeight - global.widow_bar_height - buttonHeight - 150, justifyContent: 'flex-start', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <p style={{ ...styles.TextXSmall, marginTop: 10, textAlign: 'left', fontWeight: '600' }}>{lang.openai_answer}</p>
                                    <p style={{ ...styles.TextXSmall, marginTop: 10, textAlign: 'left' }}>{answer}</p>
                                </div>
                                <div style={{ ...styles.Block, height: 150 }}>
                                    <TextInput rows={4} redraw inputRef={inputRef} lang={props.lang} value={question} label={lang.openai_request} func={(txt) => { setQuestion(txt) }} />
                                </div>
                            </div>
                            <div style={{ ...styles.Block, height: buttonHeight }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <Button onClick={() => Test(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 230 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 10 }} icon={faPaperPlane} />
                                        {lang.openai_ask}
                                    </Button>
                                    <Button onClick={() => func(true, answer)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 230, marginLeft: 20 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 10 }} icon={faCheckCircle} />
                                        {lang.openai_close_email}
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div >

            </Paper >


            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}


export const DialogAssistent = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;


    // IMAGES
    const logo = require('./react/app/logo_ai.png');

    const [isBusy, setBusy] = useState(false);

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [answerFunc, setAnswerFunc] = useState(0);
    const [answerParams, setAnswerParams] = useState(false);
    const [answerKeys, setAnswerKeys] = useState(false);
    const [answerError, setAnswerError] = useState(false);
    const [history, setHistory] = useState([]);
    const [execute, setExecute] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 400 ? 400 : width;
    const dialogHeight = height >= 460 ? 460 : height;
    const buttonHeight = 80;
    const titleHeight = 100;
    const logoSize = titleHeight - 4;

    let { func } = props;
    var running = false;
    const inputRef = useRef(null);

    useEffect(() => {
        if (running == false) {
            running = true;

            setTimeout(() => {
                inputRef.current.focus();
            }, 300);
        }

    }, []);

    const db_get = async (data, tutorial, txt) => {
        if (tutorial == true) {
            Debug('Hľadanie v návode');
        } else {
            Debug('Údaje v aplikácii');
        }

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'openai_function', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    country_id: props.locale.id,
                    // -------------------------------------
                    history: data,
                    tutorial: tutorial
                })
            })

            const json = await response.json();
            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                var error = false;

                if (json.answer == '' || json.function_id == 0) {
                    error = true;
                    setAnswerError(true);
                }

                setAnswer(json.answer == '' ? 'Chyba' : JSON.stringify(json.answer));
                setAnswerFunc(json.answer == '' ? 0 : json.function_id);

                let keys = json.answer == '' ? false : Object.keys(json.arguments);
                let params = json.answer == '' ? false : json.arguments;
                setAnswerKeys(keys);
                setAnswerParams(params);

                //setQuestion('');

                Debug('params');
                Debug(params);

                if (error == false) {
                    func(true, json.function_id, params, txt);
                }

            }

        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    }

    const Close = () => {
        func(false);
    }

    const AskAI = (txt) => {
        var tutorial = txt.includes('?') ? true : false;

        var result = OpenAIChat([], txt.trim(), '', lang, false); // OTAZKA
        setHistory(result);
        setAnswerError(false);

        db_get(result, tutorial, txt);
    }

    const SetLast = () => {
        setQuestion(props.lastQuestions);
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: '#000000AA', zIndex: 8000, top: props.remote == true && props.remoteMobile == true ? -250 : 0 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, backgroundColor: color.white, borderRadius: props.radius, borderTopLeftRadius: titleHeight / 2 }}>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight }}>
                    <div style={{ ...styles.BlockRow, background: color.window_bar_color_ai, borderTopRightRadius: global.radius, borderTopLeftRadius: titleHeight / 2 }}>
                        <div style={{ ...styles.Block, width: titleHeight, height: titleHeight, backgroundColor: color.dark_blue, borderRadius: titleHeight / 2 }}>
                            <img src={logo} style={{ width: '100%', height: '100%', maxWidth: logoSize, maxHeight: logoSize, objectFit: 'contain' }}></img>
                        </div>
                        <div style={{ ...styles.Block, width: dialogWidth - titleHeight, height: titleHeight }}>
                            <p style={{ ...styles.TextXXLarge, fontWeight: '400' }}><b>{'Vizzie'}</b> <span>AI {lang.openai_assistent}</span></p>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight }}>
                        <div style={{ ...styles.BlockCenter, height: 50 }}>
                            <p style={{ ...styles.TextXSmall, marginTop: 10 }}>{lang.openai_assistent_ask}</p>
                            {answerError == true ?
                                <p style={{ ...styles.TextXSmall, marginTop: 2 }}>{lang.openai_error}</p>
                                : null}
                        </div>
                        <div style={{ ...styles.Block, width: '96%', height: dialogHeight - titleHeight - buttonHeight - 50 }}>
                            <TextInput placeholder={lang.openai_request_question} inputRef={inputRef} rows={5} redraw enter lang={props.lang} value={question} func={(txt) => { AskAI(txt) }} />
                            <div onClick={() => SetLast()} style={{ ...styles.Block, marginTop: 10, cursor: 'pointer' }}>
                                <p style={{ ...styles.TextTiny }}>{lang.openai_last_request}:</p>
                                <TextLine text={props.lastQuestions} fontSize={global.font_xsmall} color={color.black} />
                            </div>
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {/*
                                <Button onClick={() => Process()} style={{ ...styles.ButtonOutlined, marginRight: 10 }}>{lang.send}</Button>
                                */}
                                <Button onClick={() => Close()} style={{ ...styles.ButtonOutlined }}>{lang.close}</Button>
                            </div>

                        </div>
                    </div>
                </div >

            </Paper >


            {
                isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null
            }

        </Backdrop >
    );
}


