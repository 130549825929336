/*
*
* =================================================================================================================

    Blog

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    public  -> verejný blog

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { Avatar, DialogTabs, EmptyList, FormSpace, FormText, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, FormYesNo, FormError, TextLine, DeleteButton, DialogLabel, FormSelect } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, colors, FormControlLabel, IconButton, Pagination, Paper, rgbToHex, Toolbar, Tooltip, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa1, fa2, faAlignCenter, faAlignLeft, faAlignRight, faArrowLeft, faArrowRight, faBold, faCheck, faCheckCircle, faCircle, faFileLines, faImage, faItalic, faLayerGroup, faList, faMessage, faPen, faPlusCircle, faQuestion, faT, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetDatum2, GetTodayYear, Today } from './functions_date';
import { CheckPermission, Debug, GetImageLink, GetLeftPosition, GetPages, IsEmpty } from './functions';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { Photos } from './photos.js';
import { ProductSelect } from './product_select.js';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DialogColors, PageItemRender, WebPageDesigner } from './webpage_designer.js';
import { Description } from '@mui/icons-material';

export const Blogs = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = props.public == true ? 'blog_public' : 'blog_internal';
    let permission_create = CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ? true : false;
    let publicBlog = props.public == true ? true : false;
    let publicInternal = props.internal == true ? true : false;

    let defaultFilter = {
        enabled: true,
        public: publicBlog,
        internal: publicInternal,
        published: true,
        country_id: props.locale.id
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST    
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // VALUES
    const [value, setValue] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = permission_create == true ? 40 : 0;
    const menuHeight = 50;
    const rowHeight = 100;
    const menuWidth = global.sub_menu_width;

    const tabs = [
        { id: 0, enabled: true, label: lang.blog_published },
        { id: 1, enabled: true, label: lang.blog_not_published },
        //{ id: 2, enabled: true, label: lang.settings },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            // UDAJE Z DB
            setTabIndex(0);
            db_get(0, defaultFilter);

            running = true;
        }

    }, [props.locale.id]);


    const db_get = async (page, filter) => {
        Debug('FILTER');
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'blogs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    model_id: global.model.organizations,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GetFilter = () => {
        var filter_ = defaultFilter;

        if (tabIndex == 0) {
            filter_.published = true;
        } else {
            filter_.published = false;
        }

        return filter_;
    }
    const Search = (txt) => {
        setSearch(txt);
        var filter_ = GetFilter();

        if (txt.length > 1) {
            filter_.search = txt;
            db_get(0, filter_);
        }
        if (txt == '') {
            delete (filter_.search);
            db_get(0, filter_);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);
        if (id == 2) {

        } else {
            var filter_ = GetFilter();

            if (id == 0) {
                filter_.published = true;
            } else {
                filter_.published = false;
            }

            db_get(0, filter_);
        }

    }
    const ChangeSubMenu = (id) => {
        setIndex(id);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }


    const SelectItem = (item) => {
        if (permission_create == true) {
            setItemSelected(item);
            setShowDialog(true);
        } else {
            Preview(item);
        }
    }

    const BlogResult = (redraw_, published) => {
        setShowDialog(false);

        if (redraw_ == true) {
            filter.published = published;
            setTabIndex(published == true ? 0 : 1);
            db_get(page, filter);
        }
    }

    const Preview = (item) => {
        setItemSelected(item);
        setShowPreview(true);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faMessage} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>

                    {permission_create == true ?
                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs center value={tabIndex} items={tabs} width={230} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>
                        : null}

                    {tabIndex == 0 || tabIndex == 1 ?
                        <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                                <div style={{ ...styles.Block, width: '96%' }}>
                                    <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray }}>
                                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                            {permission_create == true ?
                                                <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                                    {lang.blog_new}
                                                </Button>
                                                : null}
                                        </div>
                                    </div>

                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <Avatar picture image={item.image} size={rowHeight - 20} theme={props.theme} />
                                                    </div>

                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '63%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <div style={{ ...styles.BlockRowRaw, marginBottom: 8 }}>
                                                            <div style={{ ...styles.BlockLeft, width: undefined, height: 22 }}>
                                                                <p style={{ ...styles.TextTiny }}>{GetDatum2(item.date)} <span style={{ marginLeft: 10 }}></span></p>
                                                            </div>
                                                            {item.category != '' ? <Chip size='small' label={item.category} /> : null}
                                                        </div>

                                                        <TextLine text={item.title} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                        <TextLine text={item.description} fontSize={global.font_tiny} color={color.black} />
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <Chip variant='outlined' size='small' label={item.published == false ? lang.blog_not_published : lang.blog_published} style={{ color: item.published == false ? color.dark_red : color.dark_green, borderColor: item.published == false ? color.dark_red : color.dark_green }} />
                                                    </div>
                                                    {permission_create == true ?
                                                        <div style={{ ...styles.BlockRight, width: 100, minHeight: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                            <Chip onClick={() => Preview(item)} variant='filled' size='medium' label={lang.web_page_item_preview} style={{ backgroundColor: color.light_gray, borderLeft: color.border }} />
                                                        </div>
                                                        : null}
                                                </div>
                                            </Paper>
                                        )) :
                                            <EmptyList row small lang={lang} />
                                        }

                                        <FormSpace />

                                        {/* PAGINATION */}
                                        {pages > 1 ?
                                            <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            </div>
                                            : null}

                                    </div>

                                </div>
                            </div>
                        </div>
                        : null}

                    {tabIndex == 2 ?
                        <div style={{ ...styles.BlockCenter, maxWidth: global.max_width, height: props.height - global.widow_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        </div>

                        : null}
                </div>
            </div>

            {showPreview == true ?
                <BlogPreview blogID={itemSelected.id} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowPreview(false)} />
                : null}

            {showDialog == true ?
                <Blog public={publicBlog} internal={publicInternal} item={itemSelected} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} permissions={props.permissions} func={BlogResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


export const Blog = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'blog_public';

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');
    const background_1 = require('./react/app/page_background_1.png');
    const background_2 = require('./react/app/page_background_2.png');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [redrawRequired, setRedrawRequired] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // VEREJNY / INTERNY
    const [blogPublic, setBlogPublic] = useState(props.publicBlog);
    const [blogInternal, setBlogInternal] = useState(props.publicInternal);

    // DATA LIST
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [pageID, setPageID] = useState(0);
    const [date, setDate] = useState(Today());
    const [enabled, setEnabled] = useState(true);
    const [categoryID, setCategoryID] = useState(0);
    const [published, setPublished] = useState(false);
    const [uid, setUID] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [author, setAuthor] = useState(props.user.name + ' ' + props.user.surname);
    const [settings, setSettings] = useState(false);
    const [storeData, setStoreData] = useState(false);

    // DIALOGS
    const [showColors, setShowColors] = useState(false);
    const [colorID, setColorID] = useState(0);
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showPage, setShowPage] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 800 ? 800 : height;

    // CONSTANTS
    const tabHeight = props.preview == true ? 0 : 40;
    const imageSize = 200;
    const offset = 30;
    const buttonHeight = 80;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = dialogWidth - column1 - 40;

    const tabs = [
        { id: 0, enabled: true, label: lang.blog_head },
        //{ id: 1, enabled: true, label: lang.web_page_preview },
        { id: 2, enabled: true, label: lang.settings },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                db_get(props.item.id);
            }

            running = true;
        }

    }, []);


    const db_get = async (blog_id, enabled) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'blog', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    blog_id: blog_id,
                    enabled: enabled
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var blog = json.blog;

                if (blog != false) {
                    setStoreData(blog);
                    setDate(blog.date);
                    setCategoryID(blog.category_id);
                    setEnabled(blog.enabled == false ? false : true);
                    setPublished(blog.published == false ? false : true);
                    setUID(blog.uid);
                    setPageID(blog.page_id);
                    setTitle(blog.title);
                    setDescription(blog.description);
                    setImage(blog.image);
                    setAuthor(blog.author);
                    setSettings(blog.settings == false ? false : JSON.parse(blog.settings));
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'blog_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (itemID == 0) {
                    data.id = json.ok;
                    setItemID(json.ok);
                }
                setStoreData(data);
                Debug('data', 'orange');
                Debug(data);
                setRedrawRequired(true);
                if (data.enabled == false) {
                    func(true, published);
                } else {
                    setShowOK(true);
                }

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired, published);
    }

    const ColorsResult = (typ, color) => {
        setShowColors(false);
        if (typ == true) {
        }
    }

    const SelectImage = () => {
        setSelectPhoto(true);
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setImage(path + file);
        }
    }

    const Save = () => {
        setError('');

        var err = 0;
        var error_text = lang.required_red;
        err += IsEmpty(title) ? 1 : 0;
        if (image == '') {
            error_text = lang.blog_image_error;
            err++;
        }

        if (err == 0) {
            let data = {
                id: itemID,
                date: date,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                user_id: props.user.id,
                enabled: enabled,
                public: props.public == true ? true : false,
                internal: props.internal == true ? true : false,
                published: published,
                uid: uid,
                category_id: categoryID == -1 ? 0 : categoryID,
                page_id: pageID,
                title: title,
                description: description,
                image: image,
                author: author,
                settings: JSON.stringify(settings)
            }

            Debug(data);
            db_update(data);
        } else {
            setError(error_text);
        }
    }


    const DeleteBlog = () => {
        let data = {
            id: itemID,
            enabled: false
        }
        Debug(data);
        db_update(data);
    }

    const PageResult = (redraw_, page_id) => {
        if (page_id > 0) {
            let data = {
                id: itemID,
                page_id: page_id
            }
            setPageID(page_id);
            Debug(data);
            db_update(data);

        } else {
            setShowPage(false);
        }
    }

    const Preview = (item) => {
        setShowPreview(true);
    }

    const CheckChanges = () => {

        var change = 0;

        if (storeData == false) {
            change = false
        }
        else {
            let published_ = storeData.published == false ? false : true;
            change += published_ != published ? 1 : 0;
            change += storeData.image != image ? 1 : 0;
            change += storeData.title != title ? 1 : 0;
            change += storeData.description != description ? 1 : 0;
            change += storeData.author != author ? 1 : 0;
            change += storeData.date != date ? 1 : 0;
            change += storeData.category_id != categoryID ? 1 : 0;
        }

        return change > 0 ? true : false;
    }

    const AvatarPress = (value) => {
        if (value == 1) {
            setSelectPhoto(true);
        }
        if (value == 0) {
            setImage('');
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faMessage} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{itemID == 0 ? lang.blog_new : lang.blog}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height }}>

                        {props.preview != true ?
                            <div style={{ ...styles.Block, height: tabHeight }}>
                                <DialogTabs disabled={itemID == 0 ? true : false} center value={tabIndex} items={tabs} width={dialogWidth / 2} theme={props.theme} func={(id) => setTabIndex(id)} />
                            </div>
                            : null}

                        {tabIndex == 0 ?
                            /*
                            *
                            * =================================================================================================================
                            * HLAVICKA BLOGU
                            * =================================================================================================================
                            *
                            */
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div style={{ ...styles.Block }}>
                                    <div style={{ ...styles.Block, backgroundColor: color.light_gray }}>
                                        <FormSpace />
                                        <div style={{ ...styles.BlockCenter, width: '90%' }}>
                                            <p style={{ ...styles.TextTiny, marginBottom: 2, color: color.dark_gray }}><b>{lang.blog_image}</b></p>
                                            {/*
                                            <img src={image == '' ? no_image : GetImageLink(image)} style={{ width: '100%', height: '100%', maxWidth: imageSize - 14, maxHeight: imageSize - 14, objectFit: 'contain', borderRadius: 10 }}></img>
                                            */}
                                            <Avatar editable={true} picture image={image} size={imageSize - 14} theme={props.theme} func={AvatarPress.bind()} />
                                        </div>
                                        <FormSpace />
                                    </div>
                                    <div style={{ ...styles.Block, width: '92%' }}>
                                        <FormSpace />
                                        <div style={{ ...styles.BlockCenter }}>
                                            <p style={{ ...styles.TextTiny, marginBottom: 2, color: color.dark_gray }}><b>{lang.blog_label}</b></p>
                                            <TextInput require redraw maxLength={100} lang={lang} value={title} func={(txt) => setTitle(txt)} />
                                        </div>
                                        <FormSpace />
                                        <div style={{ ...styles.BlockCenter, }}>
                                            <p style={{ ...styles.TextTiny, marginBottom: 2, color: color.dark_gray }}><b>{lang.blog_description}</b></p>
                                            <TextInput redraw maxLength={500} rows={2} lang={lang} value={description} func={(txt) => setDescription(txt)} />
                                        </div>
                                    </div>

                                    {itemID == 0 ? null :
                                        <div style={{ ...styles.Block, width: '92%', marginTop: 18 }}>
                                            <FontAwesomeIcon style={{ height: 36, color: color.xxgray, marginBottom: 8 }} icon={faFileLines} />
                                            <p style={{ ...styles.TextTiny, marginBottom: 8, color: color.dark_gray }}><b>{lang.blog_content_text}</b></p>
                                            <Button onClick={() => setShowPage(true)} style={{ ...styles.ButtonOutlined, width: pageID == 0 ? 220 : 180, fontSize: global.font_tiny }}>
                                                {pageID == 0 ?
                                                    <FontAwesomeIcon style={{ height: 16, color: color.dark_gray, marginRight: 6 }} icon={faPlusCircle} />
                                                    : null}
                                                {pageID == 0 ? lang.blog_content_new : lang.blog_content}
                                            </Button>
                                        </div>
                                    }

                                </div>

                            </div>
                            : null}

                        {tabIndex == 1 ?
                            /*
                            *
                            * =================================================================================================================
                            * PAGE PREVIEW
                            * =================================================================================================================
                            *
                            */

                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.widow_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div style={{ ...styles.Block, width: '100%' }}>

                                </div>
                            </div>
                            : null}

                        {tabIndex == 2 ?
                            /*
                            *
                            * =================================================================================================================
                            * NASTAVENIE STRANKY
                            * =================================================================================================================
                            *
                            */

                            <div style={{ ...styles.BlockCenter, backgroundColor: color.back, height: dialogHeight - global.widow_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <FormSpace />
                                <FormYesNo value={published} editing={true} title={lang.blog_published_} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setPublished(value)} />
                                <FormText redraw value={date} date editing={true} title={lang.blog_date} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDate(txt)} />
                                <FormSpace />
                                <FormSelect value={categoryID == 0 ? -1 : categoryID} items={false} field={'name'} codebook={global.codebook.blogs} editing={true} title={lang.blog_category} width={'100%'} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} organization={props.organization} user={props.user} token={props.token} permissions={props.permissions} func={(id) => setCategoryID(id)} />
                                <FormText redraw value={author} editing={true} title={lang.blog_author} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setAuthor(txt)} />

                                <FormSpace height={50} />
                                <p style={{ ...styles.TextTiny, marginBottom: 10 }}>{lang.blog_delete_info}</p>
                                <DeleteButton ask button bold width={220} backgroundColor={color.medium_gray} label={lang.blog_delete} ok={lang.delete} icon={faTrash} position={'top'} text={lang.blog_delete_ask} text_note={lang.blog_delete_ask_text} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteBlog()} />
                            </div>
                            : null}

                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {itemID != 0 ?
                                    <Button onClick={() => Preview()} style={{ ...styles.ButtonThemed, backgroundColor: color.middle_gray, marginRight: 20 }}>{lang.web_page_item_preview}</Button>
                                    : null}
                                {itemID == 0 || CheckChanges() == true ?
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{itemID == 0 ? lang.create : lang.save}</Button>
                                    : null}
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper >

            {showPreview == true ?
                <BlogPreview blogID={itemID} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowPreview(false)} />
                : null}

            {showPage == true ?
                <WebPageDesigner item={pageID == 0 ? false : { id: pageID }} remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={PageResult.bind(this)} />
                : null}

            {selectPhoto == true ?
                <Photos remote={props.remote} remoteMobile={props.remoteMobile} autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} locale={props.locale} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                : null}

            {showColors == true ?
                <DialogColors remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={ColorsResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}


export const BlogPreview = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [blog, setBlog] = useState(false);
    const [page, setPage] = useState(false);
    const [items, setItems] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    const imageSize = 200;
    const blogWidth = dialogWidth > 800 ? 800 : dialogWidth - 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                // UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'blog_preview', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    blog_id: props.blogID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setBlog(json.blog);
                setPage(json.page);
                setItems(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faMessage} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.blog_preview}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockCenter }}>

                        {blog != false ?
                            /*
                            *
                            * =================================================================================================================
                            * BLOG
                            * =================================================================================================================
                            *
                            */

                            <div style={{ ...styles.Block, paddingTop: 25, paddingBottom: 25, backgroundColor: color.gray }}>
                                <div style={{ ...styles.BlockRow, width: blogWidth }}>
                                    <div style={{ ...styles.BlockLeft, width: imageSize + 40 }}>
                                        <img src={blog.image == '' ? no_image : GetImageLink(blog.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', borderRadius: 10 }}></img>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: blogWidth - imageSize - 40 }}>
                                        <p style={{ ...styles.TextXLarge, fontWeight: '600' }}>{blog.title}</p>
                                        {blog.description != '' ?
                                            <p style={{ ...styles.TextXSmall, marginTop: 20 }}>{blog.description}</p>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            : null}

                        {page != false ?
                            /*
                            *
                            * =================================================================================================================
                            * STRANKA
                            * =================================================================================================================
                            *
                            */

                            <div style={{
                                ...styles.BlockCenter,
                                background: page.gradient == 0 ? 'linear-gradient(180deg, ' + page.background_color_1 + ' 0%, ' + page.background_color_1 + ' 100%)' : 'linear-gradient(180deg, ' + page.background_color_1 + ' 0%, ' + page.background_color_1 + ' 100%)',
                                width: dialogWidth,
                                borderRadius: page.border_radius,
                            }}>
                                <div style={{ ...styles.Block, paddingTop: page.padding_top, paddingBottom: page.padding_bottom }}>
                                    {items != false ? items.map((item, index) => (
                                        <div key={item.id}
                                            style={{
                                                ...styles.Block,
                                                width: dialogWidth,
                                            }}
                                        >
                                            <PageItemRender data={item} width={dialogWidth} theme={props.theme} />
                                        </div>
                                    ))
                                        : null
                                    }
                                </div>
                            </div>
                            : null}

                        {blog != false ?
                            /*
                            *
                            * =================================================================================================================
                            * AUTOR, PUBLIKOVANE
                            * =================================================================================================================
                            *
                            */

                            <div style={{ ...styles.BlockLeft, paddingTop: 30, paddingBottom: 50, width: blogWidth }}>
                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.blog_published}: {GetDatum2(blog.date)}</p>
                                {blog.author != '' ?
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginTop: 2 }}>{lang.blog_author}: {blog.author}</p>
                                    : null}
                            </div>
                            : null}
                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}
