/*
*
* =================================================================================================================
* DODAVATELIA

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, TextLine, DeleteButton, AutoCompleteInput, ContactCompany } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, IconButton, Pagination, Paper, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faPlus, faInfoCircle, faLayerGroup, faLocationDot, faPlusCircle, faQuestion, faXmark, faAngleLeft, faArrowAltCircleLeft, faArrowAltCircleRight, faPlayCircle, faAngleRight, faAngleUp, faAngleDoubleDown, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, GetLeftPosition, GetPages, IsEmpty, UniqueID } from './functions';
import { Photos } from './photos';
import { BarChart } from '@mui/x-charts';
import { TodayDateTime } from './functions_date.js';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export const Presentations = (props) => {

    let module_id = global.model.presentations;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'presentations';

    let defaultFilter = {
        ordering: global.order.up,
        order_column: 'created',
        country_id: props.locale.id,
        enabled: props.embedded == true ? true : undefined
    }

    // MENU -> ZORADOVANIE ASC / DESC
    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    // MENU -> EXPORTOVANIE UDAJOV
    const itemsExportMenu = [
        { id: 1, enabled: true, label: lang.export_selected },
        { id: 2, enabled: true, label: lang.export_all }
    ];

    /* Exportované údaje
            column   -> názov stĺpca v DB
            label    -> názov stĺpca - LABEL
            typ      -> typ premennej
    */
    const exportColumns = [
        { id: 1, column: 'name', label: lang.vendor_name, typ: 'text' },
        { id: 2, column: 'street', label: lang.street, typ: 'text' },
        { id: 3, column: 'town', label: lang.town, typ: 'text' },
        { id: 4, column: 'psc', label: lang.psc, typ: 'text' },
        { id: 5, column: 'state', label: lang.state, typ: 'text' },
        { id: 6, column: 'phone', label: lang.phone, typ: 'text' },
        { id: 7, column: 'mobil', label: lang.mobil, typ: 'text' },
        { id: 8, column: 'email', label: lang.email, typ: 'text' },
        { id: 9, column: 'note', label: lang.note, typ: 'text' },
        { id: 100, column: 'enabled', label: lang.enabled, typ: 'boolean' },
    ];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState(defaultFilter.ordering);
    const [orderColumn, setOrderColumn] = useState(defaultFilter.order_column);
    const [selectedAll, setSelectedAll] = useState(false);

    // SEARCH COLUMNS
    const [searchColumns, setSearchColumns] = useState([
        { id: 1, enabled: true, checked: true, column: 'name', label: lang.vendor_name, default: true },
        { id: 2, enabled: true, checked: false, column: 'town', label: lang.town },
        { id: 3, enabled: true, checked: false, column: 'email', label: lang.email_address }
    ]);

    // VALUES
    const [menuIndex, setMenuIndex] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showPresentation, setShowPresentation] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const playHeight = 40;
    const menuHeight = 50;
    const rowHeight = 50;
    const paginationHeight = 50;
    const rowSize = 240;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, maxItems);

            running = true;
        }

    }, [props.locale]);


    const db_get = async (page, filter, maxItems) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'presentations', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);
                setOrdering(filter.ordering);
                setOrderColumn(filter.order_column);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // UPDATE DB - ENABLE / DISABLE
    // -----------------------------------
    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id === data.id);
                if (tmp != undefined) {
                    tmp.enabled = data.enabled;
                }

                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // -----------------------------------
    // EXPORT DATA
    // -----------------------------------
    const db_export = async (itemRows, note) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'export_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    typ: global.export_type.xls,
                    model_id: module_id,
                    label: lang.vendors,
                    filename: lang.vendors, // názov súboru po stiahnutí
                    item_rows: itemRows,
                    columns: exportColumns,
                    note: note
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = defaultFilter;

        if (txt.length > 1) {
            // zoznam stlpcov v ktorých sa bude vyhladávať
            var columns = [];
            searchColumns.forEach(item => {
                if (item.checked == true) {
                    columns.push(item.column);
                }
            });
            if (columns.length == 0) {
                // ak nieje zadaný žiaden stlpec - default
                var tmp = searchColumns.find(x => x.default === true);
                columns.push(tmp.column);
            }

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = columns;
            db_get(0, filter, maxItems);
        }
        if (txt == '') {
            db_get(0, filter, maxItems);
        }
    }

    const ChangePage = (value) => {
        setSelectedAll(false);
        db_get(value - 1, filter, maxItems);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, global.goto_start_delay);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        if (CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true) {
            setItemSelected(item);
            setShowDialog(true);
        }
    }

    const PlayItem = (item) => {
        setItemSelected(item);
        setShowPresentation(true);
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter, maxItems);
        }
    }

    const PresentationResult = (typ) => {
        setShowPresentation(false);

        if (redraw == true) {
        }
    }

    const ChangeEnabled = (item, value) => {
        // ENABLED POLOZKY ZMENENY

        let log = {
            organization_id: props.organization.id,
            user_id: props.user.id,
            module_id: module_id,
            item_id: item.id,
            action: value == true ? global.logs.enabled : global.logs.disabled
        };

        let data = {
            id: item.id,
            enabled: value,
            logs: log
        }
        db_update(data);
    }

    const MenuResult = (id, index) => {
        // EXPORT MENU
        var enable = true;
        if (index == 1) {
            let tmp = items.find(x => x.selected === true);
            if (tmp == undefined) {
                enable = false;
            }
        }

        if (enable == true) {
            setMenuIndex(index);
            setShowExport(true);
        }
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'name';

        switch (id) {

            case 1: // NAZOV
                column = 'name';
                break;

            case 2: // ENABLED
                column = 'enabled';
                break;
        }

        var filter = {
            ordering: order,
            order_column: column
        };
        if (search != '') {
            filter.search = search;
        }
        db_get(0, filter, maxItems);
    }

    const ChangeSelected = (item) => {
        item.selected = !item.selected;
        setRedraw(!redraw);
    }

    const ChangeSelectedAll = () => {
        let value = !selectedAll;

        items.forEach(item => {
            item.selected = value;
        });

        setSelectedAll(value);
    }

    const ChangeMaxItems = (item) => {
        setMaxItems(item);
        db_get(0, filter, item);
    }

    const DialogExportResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            var itemRows = []; // zoznam IDs ktore budú exportované (0 -> všetky)

            switch (menuIndex) {
                case 1:
                    items.forEach(item => {
                        if (item.selected == true) {
                            itemRows.push(item.id);
                        }
                    });
                    if (itemRows.length > 0) {
                        db_export(itemRows, note);
                    }
                    break;

                case 2:
                    itemRows = false;
                    db_export(itemRows, note);
                    break;
            }
        }
    }

    const SearchButtonResult = (id) => {
        var tmp = searchColumns.find(x => x.id === id);
        if (tmp != undefined) {
            tmp.checked = !tmp.checked;
        }
        if (search != '') {
            Search(search);
        } else {
            setRedraw(!redraw);
        }
    }


    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.embedded == true ? 0 : props.radius }}>
            {props.embedded == true ? null :
                <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} />
                    </div>
                    <div style={{ ...styles.Block, width: props.width - 180, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <MenuButton id={0} menuItems={itemsExportMenu} theme={props.theme} func={MenuResult.bind()} />
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
            }
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.embedded == true ? props.height : props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.embedded == true ? 0 : props.radius, borderBottomRightRadius: props.embedded == true ? 0 : props.radius }}>
                <div style={{ ...styles.BlockCenter, maxWidth: props.embedded == true ? '98%' : '100%', height: props.embedded == true ? props.height : props.height - global.widow_bar_height }}>
                    <div id={'id_start'} style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                        <div style={{ ...styles.Block, height: 60 }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <TextInput search theme={props.theme} width={300} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                        {/*
                                        <div style={{ ...styles.Block, width: 30, paddingTop: 3 }}>
                                            <SearchButton menuItems={searchColumns} lang={lang} theme={props.theme} func={SearchButtonResult.bind()} />
                                        </div>
                                        */}
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                    {props.customer == undefined && CheckPermission(props.user.super_admin, props.permissions[permission_column]).write == true ?
                                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 190 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.presentation_new}
                                        </Button>
                                        : null}
                                </div>
                            </div>
                        </div>

                        <div style={{ ...styles.BlockCenter, height: props.embedded == true ? props.height : props.height - global.widow_bar_height - 60 - 6, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.BlockCenter, marginTop: 20, flexDirection: 'row', flexWrap: 'wrap', textAlign: 'left' }}>

                                {/* ****************************************************************************************************************
                                        TABLE DATA
                                **************************************************************************************************************** */}

                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            width: rowSize,
                                            minHeight: rowSize + rowHeight + playHeight,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            cursor: 'pointer',
                                            margin: 6,
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.Block }}>
                                            {/* DATA */}
                                            <div onClick={() => SelectItem(item)} style={{ ...styles.Block, height: rowSize, opacity: item.enabled == true ? 1 : 0.30 }}>
                                                <Avatar picture image={item.image} size={rowSize - 16} theme={props.theme} />
                                            </div>

                                            <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: '96%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                <TextLine text={item.name} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                            </div>
                                            <div onClick={() => PlayItem(item)} style={{ ...styles.Block, minHeight: playHeight, justifyContent: 'center', borderTop: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.middle_gray }}>{lang.presentation_run_}</p>
                                            </div>
                                        </div>

                                    </Paper>
                                )) :
                                    <EmptyList row small lang={lang} />
                                }

                                {/* ****************************************************************************************************************
                                        PAGINATION
                                **************************************************************************************************************** */}

                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 200 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={maxItems} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeMaxItems(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {showExport == true ?
                <DialogExport remote={props.remote} remoteMobile={props.remoteMobile} rows={3} title={lang.export_note} text={''} icon={faQuestion} button={props.lang.export} theme={props.theme} radius={props.radius} lang={lang} func={DialogExportResult.bind(this)} />
                : null}

            {showDialog == true ?
                <Presentation remote={props.remote} remoteMobile={props.remoteMobile} customer={props.customer == undefined ? undefined : props.customer} module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showPresentation == true ?
                <PresentationPlay remote={props.remote} remoteMobile={props.remoteMobile} customer={props.customer == undefined ? undefined : props.customer} stats={true} module_id={module_id} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={PresentationResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}



/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const Presentation = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [editing, setEditing] = useState(props.item == false ? true : false);
    const [overID, setOverID] = useState(-1);
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');
    const [note, setNote] = useState('');
    const [avatar, setAvatar] = useState('');
    const [images, setImages] = useState(false);
    const [effect, setEffect] = useState(2);
    const [ordering, setOrdering] = useState(0);
    const [slideLast, setSlideLast] = useState(0);

    // LOGGING SYSTEM
    const [logs, setLogs] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);

    // ŠTATISTIKA
    const [statCount, setStatCount] = useState(0);
    const [graphData, setGraphData] = useState(false);
    const [axis, setAxis] = useState([]);
    const [slides, setSlides] = useState([]);
    const [slideSelected, setSlideSelected] = useState(false);
    const [showSlide, setShowSlide] = useState(false);

    // UZIVATELIA
    const [users, setUsers] = useState(false);
    const [userID, setUserID] = useState(0);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1100 ? 1100 : width;
    const dialogHeight = height >= 800 ? 800 : height;

    // CONSTANTS
    const tabHeight = 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 160;
    const column2 = 600 - column1 - 40;
    const rowSize = (dialogWidth - 52) / 4;

    const tabs = [
        { id: 0, enabled: true, label: lang.setting },
        { id: 2, enabled: true, label: lang.presentation_slides },
        { id: 3, enabled: true, label: lang.presentation_stats },
        { id: 1, enabled: true, label: lang.history },
    ];

    const ordering_menu = [
        { id: 0, name: lang.presentation_ordering_0 },
        { id: 1, name: lang.presentation_ordering_1 },
        { id: 2, name: lang.presentation_ordering_2 },
    ];


    const tableHeight = 40;
    const inputRef = useRef(null);

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            Debug('Customer');
            Debug(props.customer);
            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'presentation', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);
                    setUsers(json.users);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_images = async (ordering_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'presentation_images', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    presentation_id: itemID,
                    ordering: ordering_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setImages(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_stats = async (filter) => {
        Debug('filter', 'red');
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'presentation_stats', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    presentation_id: itemID,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setGraphData(json.graph_data);
                setAxis(json.axis);
                setSlides(json.items);
                setStatCount(json.count);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_logs = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'get_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    module_id: module_id,
                    item_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setLogs(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: module_id,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (data.id == 0) {
                    setItemID(json.item_id);
                    storeData.id = json.item_id;
                }
                setEditing(false);
                setRedrawRequired(true);
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_upload = async (attachments) => {

        setBusy(true);

        const data = new FormData();

        var n = 0;
        var i = 0;
        [...attachments].forEach(item => {
            if (item.library == false) {
                data.append("file_" + n, item);
                n++;
            } else {
                data.append("file_library_" + i, item.link);
                i++;
            }
        });

        data.append("token", props.token);
        data.append("user_id", props.user.id);
        data.append("organization_id", props.organization.id);
        data.append("presentation_id", itemID);

        data.append("attachments", n);
        data.append("attachments_library", i);

        try {
            const response = await fetch(
                global.db_url + 'presentation_upload', {
                method: 'POST',
                body: data
            })

            const json = await response.json();

            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                db_images();
                //setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_delete = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'presentation_image_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_images();
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_reorder = async (items) => {

        try {
            const response = await fetch(
                global.db_url + 'reorder_items', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.presentation_items,
                    items: items
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }

        } catch (error) {
            console.log(error);

        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setName(item.name);
        setNote(item.note);
        setAvatar(item.image);
        setEffect(item.effect);
        setOrdering(item.ordering);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.id = itemID;
        storeData.enabled = enabled;
        storeData.name = name;
        storeData.note = note;
        storeData.image = avatar;
        storeData.effect = effect;
        storeData.ordering = ordering;
    }

    const Save = () => {
        setError('');
        var error = false;
        error += IsEmpty(name) == true ? 1 : 0;

        if (error == 0) {
            let log = {
                organization_id: props.organization.id,
                user_id: props.user.id,
                module_id: module_id,
                item_id: props.item == false ? 0 : itemID,
                action: itemID == 0 ? global.logs.created : storeData.enabled != enabled ? enabled == true ? global.logs.enabled : global.logs.disabled : global.logs.modified
            };

            let data = {
                id: itemID,
                organization_id: props.organization.id,
                country_id: props.locale.id,
                enabled: enabled,
                name: name.trim(),
                note: note,
                image: avatar,
                effect: effect,
                ordering: ordering,
                logs: log
            };

            StoreEditing();

            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ChangeOrdering = (value) => {
        setOrdering(value)
        let data = {
            id: itemID,
            ordering: value
        };
        db_update(data);
        db_images(value);
    }

    const DeleteImage = (item) => {
        db_delete(item.id);
    }


    const ChangeTab = (id) => {
        setTabIndex(id);
        setSlideLast(0);

        if (id == 2) {
            db_images();
        }
        if (id == 3) {
            setSlideSelected(false);
            setShowSlide(false);
            if (graphData == false) {
                var filter = {};
                if (userID > 0) {
                    filter.user_id = userID;
                }
                if (props.customer != undefined) {
                    filter.customer_id = props.customer.id;
                }
                db_stats(filter);
            }
        }
        if (id == 1) {
            if (logs == false) {
                db_get_logs();
            }
        }
    }


    const AvatarPress = () => {
        setSelectPhoto(true);
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);
        }
    }


    function AttachmentUpload(e) {
        // VYBER SUBOROV A ODOSLANIE NA SERVER           
        var files = e.target.files
        var attachments = [];

        [...files].forEach(file => {
            file.id = UniqueID();
            file.library = false;
            attachments.push(file);
        });

        db_upload(attachments);
    }

    const Slide = (e) => {
        var slide = slides[e.dataIndex];

        setSlideSelected(slide);
        setShowSlide(true);
    }


    const ChangeUser = (id) => {
        setUserID(id);

        var filter = {};
        if (id > 0) {
            filter.user_id = id
        }
        if (props.customer != undefined) {
            filter.customer_id = props.customer.id;
        }
        db_stats(filter);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (result.destination) {
            const tmp = reorder(images, result.source.index, result.destination.index);
            var n = 1;
            var items_ = [];
            tmp.forEach(item => {
                item.priority = n;

                let data = {
                    id: item.id,
                    priority: n
                }
                items_.push(data);
                n++
            });

            setImages(tmp);
            db_reorder(items_);
        }

    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.presentation_new : lang.presentation}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.Block, height: tabHeight }}>
                        {itemID > 0 ?
                            <DialogTabs value={tabIndex} items={tabs} width={dialogWidth / 4} theme={props.theme} func={(id) => ChangeTab(id)} />
                            : null}
                    </div>

                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {storeData != false ?
                                <div>
                                    <FormSpace />
                                    <div style={{ ...styles.Block }}>
                                        <Avatar editable={editing} picture image={avatar} size={75} theme={props.theme} func={AvatarPress.bind()} />
                                        <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.presentation_image}</p>
                                    </div>
                                    <Line marginTop={10} theme={props.theme} />
                                    < FormSpace />
                                    <FormLabel editing={editing} editButton={CheckPermission(props.user.super_admin, props.permissions[permission_column]).edit == true ? true : false} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                    <FormYesNo value={enabled} editing={editing} title={lang.presentation_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                    <FormText require redraw value={name} editing={editing} title={lang.presentation_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                    <FormYesNo value={effect} editing={editing} title={lang.presentation_effect} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} no={lang.presentation_effect_0} yes={lang.presentation_effect_1} default={lang.presentation_effect_2} func={(value) => setEffect(value)} />
                                    <FormSpace />
                                    <FormText rows={3} redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />

                                    {props.remote == true && props.remoteMobile == true ?
                                        <FormSpace height={500} />
                                        : null}

                                </div>
                                :
                                <Stack style={{ ...styles.Block }} spacing={1}>
                                    <FormSpace />
                                    <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                </Stack>}
                        </div>
                        : null}

                    {tabIndex == 2 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, height: 80, borderBottom: color.border, marginBottom: 5 }}>
                                <p style={{ ...styles.TextTiny, marginBottom: 2 }}>{lang.presentation_ordering}</p>
                                <SelectInput width={250} default={''} theme={props.theme} enabled={true} lang={lang} value={ordering} items={ordering_menu} field={'name'} radius={global.radius} func={(txt) => ChangeOrdering(txt)} />
                            </div>
                            {ordering == 2 ?
                                <div style={{ ...styles.Block, width: '96%', border: color.border }}>
                                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                                        <div style={{ ...styles.Block, width: 40, height: tableHeight }}>
                                        </div>
                                        <div style={{ ...styles.Block, width: rowSize / 2 + 10, height: tableHeight, justifyContent: 'center', borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.presentation_slide_}</p>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '80%', height: tableHeight, borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.file_filename}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 80, height: tableHeight, borderLeft: color.border }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.delete}</p>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            <div style={{ ...styles.BlockCenter, flexDirection: ordering == 2 ? 'column' : 'row', flexWrap: ordering == 2 ? undefined : 'wrap', textAlign: 'left' }}>

                                {images != false ? ordering == 2 ?
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={{ ...styles.Block }}
                                                >
                                                    {images.map((item, index) => (
                                                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Paper
                                                                        key={item.id}
                                                                        elevation={item.id == overID ? 4 : 1}
                                                                        style={{
                                                                            ...styles.Block,
                                                                            width: dialogWidth - 40,
                                                                            minHeight: rowSize / 2,
                                                                            backgroundColor: item.id == slideLast ? color.lighteen_red : color.white,
                                                                            borderRadius: global.items_radius,
                                                                            cursor: 'pointer',
                                                                            marginTop: 6,
                                                                        }}
                                                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                                        onClick={() => setSlideLast(slideLast == item.id ? 0 : item.id)}
                                                                    >
                                                                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                                            <div style={{ ...styles.Block, width: 40, height: rowSize / 2 }}>
                                                                                <p style={{ ...styles.TextTiny }}>{index + 1}</p>
                                                                            </div>
                                                                            <div style={{ ...styles.Block, width: rowSize / 2 + 10, height: rowSize / 2 }}>
                                                                                <Avatar picture image={item.thumb} size={rowSize / 2 - 16} theme={props.theme} />
                                                                            </div>
                                                                            <div style={{ ...styles.BlockLeft, width: '80%', height: rowSize / 2 }}>
                                                                                <TextLine text={item.filename} fontSize={global.font_tiny} color={color.black} />
                                                                            </div>
                                                                            <div style={{ ...styles.Block, width: 80, height: rowSize / 2, borderLeft: color.border }}>
                                                                                <DeleteButton ask text={lang.presentation_slide_delete} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteImage(item)} />
                                                                            </div>
                                                                        </div>

                                                                    </Paper>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext >
                                    :
                                    images.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            style={{
                                                ...styles.Block,
                                                width: rowSize,
                                                minHeight: rowSize,
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                cursor: 'pointer',
                                                margin: 6,
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.Block, position: 'relative' }}>
                                                <Avatar picture image={item.thumb} size={rowSize - 16} theme={props.theme} />
                                                <div style={{ ...styles.Block, width: 40, height: 36, position: 'absolute', top: 0, right: 0 }}>
                                                    <DeleteButton ask text={lang.presentation_slide_delete} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteImage(item)} />
                                                </div>
                                            </div>

                                        </Paper>
                                    ))
                                    :
                                    <div style={{ ...styles.Block, height: 100 }}>
                                        <EmptyList row small lang={lang} />
                                    </div>
                                }

                                <div style={{ ...styles.Block, width: rowSize, height: ordering == 2 ? 80 : rowSize }}>
                                    <Button variant="outlined" component="label" style={{ ...styles.ButtonOutlined, backgroundColor: '#00000000', width: 200 }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPlus} />
                                        {lang.presentation_slide_add}
                                        <input ref={inputRef} onInput={(e) => AttachmentUpload(e)} /* onChange={(e) => AttachmentUpload(e)} */ multiple hidden type="file" />
                                    </Button>
                                </div>

                            </div>


                        </div>
                        : null}

                    {tabIndex == 3 ?
                        <div style={{ ...styles.Block, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.BlockRow, width: dialogWidth - 40, borderBottom: color.border }}>
                                <div style={{ ...styles.BlockRow, width: dialogWidth - 160 - 40, height: 160 }}>
                                    {props.customer != undefined ?
                                        <div style={{ ...styles.BlockLeft, width: (dialogWidth - 160 - 40) / 2, height: 160 }}>
                                            <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.customer}:</p>
                                            <ContactCompany company={props.customer.company} name={props.customer.name} surname={props.customer.surname} theme={props.theme} />
                                        </div>
                                        : null}
                                    <div style={{ ...styles.BlockLeft, width: (dialogWidth - 160 - 40) / 2, height: 160 }}>
                                        <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.user_select}</p>
                                        <AutoCompleteInput value={userID} items={users} field={'name'} width={250} default={lang.all_records} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => ChangeUser(txt)} />
                                    </div>
                                </div>
                                <div style={{ ...styles.Block, width: 160, height: 160 }}>
                                    {showSlide == true ?
                                        <Avatar picture image={slideSelected.thumb} size={158} theme={props.theme} />
                                        : null}
                                </div>
                            </div>

                            {graphData != false ?
                                <>
                                    <p style={{ ...styles.TextXSmall, marginTop: 5 }}>{lang.presentation_stat_count}: {statCount}</p>
                                    <BarChart
                                        sx={{ rx: 15 }}
                                        grid={{ horizontal: true }}
                                        xAxis={[
                                            {
                                                id: 'ids',
                                                data: axis,
                                                scaleType: 'band',
                                                label: '(' + lang.presentation_slide + ')',
                                                labelStyle: { fill: color.medium_gray, fontSize: 14 }
                                            },
                                        ]}
                                        /*
                                        yAxis={[
                                            {
                                                id: 'time',
                                                label: '(' + lang.presentation_time + ')',
                                                labelStyle: { fill: color.medium_gray, fontSize: 14 }
                                            }
                                        ]}
                                        */
                                        series={[
                                            {
                                                data: graphData,
                                                label: lang.presentation_stat_label,
                                                labelStyle: { fill: color.medium_gray, fontSize: 14 },
                                                color: color.graph_bars_1
                                            },
                                        ]}
                                        slotProps={{
                                            legend: {
                                                labelStyle: {
                                                    fontSize: 14,
                                                    fill: color.dark_gray,
                                                },
                                            },
                                            bar: {
                                                rx: 5,
                                                ry: 5
                                            },
                                        }}

                                        onItemClick={(event, d) => Slide(d)}

                                        width={dialogWidth - 40}
                                        height={dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - 230}
                                    />
                                </>
                                : null}
                        </div>
                        : null}

                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Logs logs={logs} lang={props.lang} locale={props.locale} theme={props.theme} />
                        </div>
                        : null}

                    {tabIndex == 0 ?
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError small margin={0} error={error} theme={props.theme} />
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {tabIndex == 0 && editing == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            {editing == true ?
                                itemID > 0 ?
                                    < Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                    : null
                                :
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, width: 180, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                                    {itemID > 0 && editing == false ?
                                        <Button onClick={() => setShowPreview(true)} style={{ ...styles.ButtonThemed, width: 180, backgroundColor: color.button_ok, marginLeft: 20 }}>{lang.presentation_preview}</Button>
                                        : null}
                                </div>

                            }
                        </div>
                    </div>
                </div >

                {showPreview == true ?
                    <PresentationPlay stats={false} module_id={module_id} item={storeData} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={() => setShowPreview(false)} />
                    : null}

                {selectPhoto == true ?
                    <Photos autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}


/*
*
* =================================================================================================================
* SPUSTENIE PREZENTACIE
*
*   PARAMS:
* -----------------------------------------------------------------------------------------------------------------
*   stats         -> true (ukladanie štatistiky pozeriania)
*   customer_id    -> ID zákazníka, ktorému bola prezentovaná (undefined = 0)
* =================================================================================================================
*
*/
export const PresentationPlay = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [images, setImages] = useState(false);
    const [imageIndex, setImageIndex] = useState(-1);
    const [imageLast, setImageLast] = useState(-1);
    const [controls, setControls] = useState(true);
    const [thumbs, setThumbs] = useState(false);
    const [showIntro, setShowIntro] = useState(true);
    const [started, setStarted] = useState(false);
    const [startedTime, setStartedTime] = useState(false);
    const [endedTime, setEndedTime] = useState(false);
    const [startedDateTime, setStartedDateTime] = useState(false);

    // CASOVAC
    const [timing, setTiming] = useState([]);

    const [loading, setLoading] = useState({ value: 0 });
    const [loaded, setLoaded] = useState(false);
    const [showFinish, setShowFinish] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width;
    const dialogHeight = height;
    const buttonSize = 40;
    const thumbSize = 80;

    const timeOffset = 3000; // Ak je cas stráveny na snímku mensi ako predefinovaný -> nepočítať 

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            Debug('props.item', 'yellow');
            Debug(props.item);
            Debug('Customer');
            Debug(props.customer);
            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_images();
            }

            running = true;
        }

    }, []);

    const db_stats_update = async (items_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'presentation_stats_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    items: items_
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);
            func(true);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_images = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'presentation_images', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    presentation_id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                const items = json.items;

                if (items != false) {
                    /*
                    items.sort((a, b) => {
                        if (parseInt(a.priority) < parseInt(b.priority)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    */
                    var n = 0;
                    var array = [];

                    items.forEach(item => {
                        /*
                        const imageElement = new Image();
                        imageElement.src = global.web_presentations + item.image;
                        */

                        array.push(global.web_presentations + item.image);

                        //let img = preloadImage(global.web_presentations + item.image);

                        let data = {
                            id: item.id,
                            index: n,
                            start_time: 0,
                            end_time: 0,
                            time: 0,
                        }
                        timing.push(data);

                        n++;
                    });

                    //CacheImages(array);
                    setImages(items);
                } else {
                    setBusy(false);
                }
            }
            //setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        if (started == true) {
            if (props.stats == true) {
                GetActualSlide();
                setEndedTime(Date.now());
                setShowFinish(true);
            } else {
                func(false);
            }
        } else {
            func(false);
        }
    }

    const GetActualSlide = () => {
        var index = imageIndex;

        // ------------------------------------------------
        // ACTUAL SLIDE
        // ------------------------------------------------
        var item1 = timing[imageIndex];
        var start_time = item1.start_time == 0 ? Date.now() : new Date(item1.start_time);
        var end_time = Date.now();

        // AK JE CAS NA SLIDE mensí ako 3 sekundy -> nerátať
        var elapsed = (end_time - start_time) > timeOffset ? (end_time - start_time) : 0;

        var data1 = {
            id: item1.id,
            index: index,
            start_time: 0,
            end_time: 0,
            time: item1.time + (elapsed / 1000),
        };
        timing[index] = data1;
    }

    const ChangeIndex = (new_index, start) => {

        let index = imageIndex;
        setImageLast(index);

        if (start == false) {
            GetActualSlide();
        }

        // ------------------------------------------------
        // NEXT SLIDE
        // ------------------------------------------------
        var item2 = timing[new_index];

        var data2 = {
            id: item2.id,
            index: new_index,
            start_time: Date.now(),
            end_time: 0,
            time: item2.time,
        };
        timing[new_index] = data2;

        // ------------------------------------------------

        setImageIndex(new_index);
    }

    const Controls = () => {
        let value = !controls;
        setThumbs(false);
        setControls(value);
    }

    const Start = () => {
        ChangeIndex(0, true);
        setStarted(true);
        setStartedTime(Date.now());
        setStartedDateTime(TodayDateTime());
        setTimeout(() => {
            setShowIntro(false);
        }, 1500);
    }

    const Loaded = () => {
        loading.value = loading.value + 1;
        if (loading.value >= images.length - 2) {
            setBusy(false);
            setLoaded(true);
        }
    }

    const FinishResult = (value) => {
        setShowFinish(false);

        if (props.stats == true && value == true && started == true) {
            Debug('Ulozenie statistiky');
            var items = [];
            timing.forEach(item => {
                if (parseInt(item.time) > 0) {
                    let data = {
                        organization_id: props.organization.id,
                        user_id: props.user.id,
                        customer_id: props.customer == undefined ? 0 : props.customer.id,
                        presentation_id: props.item.id,
                        slide_id: item.id,
                        elapsed: item.time
                    };
                    items.push(data);
                }
            });
            // ZACIATOK PREZENTACIE -> slide = 0
            let data = {
                created: startedDateTime,
                organization_id: props.organization.id,
                user_id: props.user.id,
                customer_id: props.customer == undefined ? 0 : props.customer.id,
                presentation_id: props.item.id,
                slide_id: 0,
                elapsed: (endedTime - startedTime) / 1000
            };
            items.push(data);

            db_stats_update(items);
        } else {
            Debug('Neukladanie statistiky', 'red');
            func(false);
        }
    }

    return (
        <Backdrop open={true} style={{ zIndex: 2000, backgroundColor: '#000000FF' }}>
            <div style={{ ...styles.Block }}>
                {images != false ? images.map((item, index) => (
                    <div key={index} style={{ ...styles.Block }}>
                        <img onLoad={() => Loaded()} onClick={() => Controls(!controls)} key={index} src={global.web_presentations + item.image} style={{ width: '100%', height: '100%', maxWidth: width, maxHeight: height, objectFit: 'contain', position: 'absolute', opacity: imageIndex == index ? 1 : loaded == false ? 1 : 0, transitionDuration: props.item.effect == 1 || props.item.effect == 2 ? '0.75s' : undefined, transform: props.item.effect == 2 ? imageIndex == index ? 'scale(1.00)' : 'scale(1.08)' : undefined, display: imageIndex == index || imageIndex == index - 1 || imageIndex == index + 1 || index == imageLast ? 'block' : 'none' }}></img >
                        {/*
                        <audio onEnded={() => Start()} autoPlay>
                            <source src={global.web_voices + 'test.mp3'} type='audio/mpeg' />
                        </audio>
                        */}
                    </div>
                )) : null
                }

                {
                    showIntro == true ?
                        <div style={{ ...styles.Block, width: width, height: height, background: color.presentation, position: 'absolute', opacity: imageIndex < 0 ? 1 : 0, transitionDuration: '0.75s' }}>
                            {props.item.image != '' ?
                                <Avatar picture image={props.item.image} size={250} theme={props.theme} />
                                : null}
                            <p style={{ ...styles.TextLarge, color: color.white, marginTop: 10, marginBottom: 50, textAlign: 'center' }}>{props.item.name}</p>
                            {images != false && loaded == true ?
                                <>
                                    <IconButton onClick={() => Start()} style={{ width: 64, height: 64 }}>
                                        <FontAwesomeIcon style={{ height: 64, color: color.white }} icon={faPlayCircle} />
                                    </IconButton>
                                    <p style={{ ...styles.TextXSmall, color: color.white, marginTop: 5, textAlign: 'center', color: color.medium_gray, letterSpacing: 1.1 }}>{lang.presentation_run}'</p>
                                </>
                                : null}
                        </div>
                        : null
                }

                {
                    imageIndex >= 0 && images != false ?
                        <div onClick={() => ChangeIndex(imageIndex > 0 ? imageIndex - 1 : imageIndex, false)} style={{ ...styles.Block, width: 300, height: height / 2, position: 'absolute', left: 0, top: height / 4 }}>
                            <FontAwesomeIcon style={{ height: 80, color: color.white, opacity: showIntro == true ? 0.6 : 0, transitionDuration: '1.00s' }} icon={faAngleLeft} />
                        </div>
                        : null
                }

                {
                    imageIndex >= 0 && images != false ?
                        <div onClick={() => ChangeIndex(imageIndex < images.length - 2 ? imageIndex + 1 : images.length - 1, false)} style={{ ...styles.Block, width: 300, height: height / 2, position: 'absolute', right: 0, top: height / 4 }}>
                            <FontAwesomeIcon style={{ height: 80, color: color.white, opacity: showIntro == true ? 0.6 : 0, transitionDuration: '1.00s' }} icon={faAngleRight} />
                        </div>
                        : null
                }

                {
                    imageIndex >= 0 && controls == true && images != false ?
                        <div style={{ ...styles.Block, width: buttonSize + buttonSize + 200 + 10, height: buttonSize + 10, position: 'absolute', bottom: buttonSize / 2, backgroundColor: '#00000070', borderRadius: (buttonSize + 10) / 2 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, width: buttonSize, height: buttonSize }}>
                                    {imageIndex > 0 ?
                                        <IconButton onClick={() => ChangeIndex(imageIndex > 0 ? imageIndex - 1 : imageIndex, false)} style={{ ...styles.ButtonIcon, width: buttonSize, height: buttonSize, width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: '#00000040' }}>
                                            <FontAwesomeIcon style={{ height: buttonSize, color: color.white, opacity: 0.7 }} icon={faArrowAltCircleLeft} />
                                        </IconButton>
                                        : null}
                                </div>
                                <div onClick={() => setThumbs(!thumbs)} style={{ ...styles.Block, width: 200, height: buttonSize, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextLarge, color: color.white }}>{imageIndex + 1} / {images.length}</p>
                                </div>
                                <div style={{ ...styles.Block, width: buttonSize, height: buttonSize }}>
                                    {imageIndex < images.length - 1 ?
                                        <IconButton onClick={() => ChangeIndex(imageIndex < images.length - 2 ? imageIndex + 1 : images.length - 1, false)} style={{ ...styles.ButtonIcon, width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: '#00000040' }}>
                                            <FontAwesomeIcon style={{ height: buttonSize, color: color.white, opacity: 0.7 }} icon={faArrowAltCircleRight} />
                                        </IconButton>
                                        : null}
                                </div>
                            </div>
                        </div>
                        : null
                }

                {
                    thumbs == true && images != false && controls == true ?
                        <div style={{ ...styles.Block, width: '80%', height: thumbSize + 10, position: 'absolute', bottom: buttonSize / 2 + thumbSize, backgroundColor: '#000000A0' }}>
                            <div style={{ ...styles.BlockCenter, alignItems: 'flex-start', height: thumbSize, flexDirection: 'row', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowX: 'scroll' }} className='container'>
                                {images.map((item, index) => (
                                    <img onClick={() => ChangeIndex(index, false)} key={index} src={global.web_presentations + item.thumb} style={{ width: '100%', height: '100%', maxWidth: thumbSize, maxHeight: thumbSize, objectFit: 'contain', marginRight: 5, borderRadius: 5 }}></img>
                                ))}
                            </div>
                        </div>
                        : null
                }


                {
                    controls == true ?
                        <div style={{ ...styles.Block, width: buttonSize, height: buttonSize, position: 'absolute', top: 10, right: 10 }}>
                            <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose, border: '1px solid #000000' }}>
                                <FontAwesomeIcon style={{ height: 18 }} icon={faXmark} />
                            </IconButton>
                        </div>
                        : null
                }

            </div >

            {
                showFinish == true ?
                    <DialogYesNo center theme={props.theme} radius={props.radius} title={lang.presentation_stop} text={lang.presentation_stop_ask} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={FinishResult.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading center></Loading>
                    : null
            }


        </Backdrop >
    );
}



export const PresentationSelect = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = {
        enabled: true,
        ordering: global.order.down,
        order_column: 'name'
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DATA
    const [value, setValue] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 700 ? 700 : height;

    const menuHeight = 60;
    const rowHeight = 66;
    const viewWidth = '96%';

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter);

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'presentations', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems));

                setFilter(filter_);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = defaultFilter;

        if (txt.length > 1) {

            var search_text = txt.split(' ');
            filter.search = search_text;
            filter.search_columns = ['name'];
            db_get(0, filter);
        }
        if (txt == '') {
            db_get(0, filter);
        }

    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        func(true, item)
    }

    const NodeSelectResult = (id) => {
        var tmp = items.find(x => x.id == id);
        if (tmp != undefined) {
            func(true, tmp);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.vendors}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start'} style={{ ...styles.BlockLeft, width: viewWidth }}>

                            {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    onClick={() => SelectItem(item)}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                        borderRadius: global.items_radius,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                                        <div style={{ ...styles.BlockLeft, width: rowHeight + 6, height: rowHeight }}>
                                            <Avatar picture image={item.avatar} size={rowHeight - 6} theme={props.theme} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '87%', minHeight: rowHeight, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name}</p>
                                            <div style={{ ...styles.BlockLeft, maxHeight: 20, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.street} {item.town}{item.town != '' && item.state != '' ? ', ' : ' '}{item.state}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div >

                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


