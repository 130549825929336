/*
*
* =================================================================================================================
* GOOGLE MAP
* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Icon, Loading } from './items';
import { styles } from './styles';
import './items.css';
import { Backdrop, Button, Chip, CircularProgress, IconButton, Paper, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import { faEarth, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetLeftPosition } from './functions';



export const GoogleMap = (props) => {

    /*
        ZOBRAZENIE SALONU NA MAPE
        
        USAGE:
        <GoogleMap width={400} height={400} />
        
    */

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    const [isBusy, setBusy] = useState(false);
    const [gps, setGps] = useState(props.gps);
    const [zoom, setZoom] = useState(props.zoom == undefined ? 13 : props.zoom);
    const pin = require('./react/app/pin.png');

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 900 ? 900 : width;
    const dialogHeight = height >= 900 ? 900 : height;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
        }

    }, [])

    const Close = () => {
        func(false);
    }

    const AnyReactComponent = (props) => {
        return (
            <div style={{ position: 'absolute', top: -40, left: -20, width: 40, height: 40 }}>
                <img src={pin} height={40} />
                {props.priority > 0 ?
                    <Chip size='small'
                        label={<p style={{ ...styles.TextSmall, color: color.red, fontWeight: '600' }}>{props.priority > 0 ? props.priority : ''}</p>}
                        style={{ position: 'absolute', top: 25, left: 25, backgroundColor: '#FFFFFFA0' }}
                    />
                    : null}
            </div>
        )
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, background: color.theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                        <Icon theme={props.theme} icon={faEarth} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: dialogWidth - 120, height: global.dialog_bar_height }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.gps_map}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                    <div style={{ width: dialogWidth, height: dialogHeight - global.dialog_bar_height }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyCVLKeXod-XpjnLLg1MEbf_wO5OFc5Wv3g' }}
                            defaultCenter={{ lat: parseFloat(gps != false ? gps[0].lat : 48.1485965), lng: parseFloat(gps != false ? gps[0].lng : 17.1077477) }}
                            defaultZoom={zoom}
                        >
                            {gps != false ? gps.map((item, index) => (
                                <AnyReactComponent
                                    key={index}
                                    lat={item.lat}
                                    lng={item.lng}
                                    priority={item.priority == undefined ? 0 : item.priority}
                                    text="SALON"
                                />
                            )) : null}
                        </GoogleMapReact>

                        {isBusy == true ?
                            <Loading center={true} />
                            : null}

                    </div>
                </div>

            </Paper>
        </Backdrop >
    );
}



