/*
*
* =================================================================================================================

    TVORBA CISLA DOKUMENTOV

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <SettingsNumberFormat item={false} organization={props.organization} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={NumberResult.bind()} />
    
    PARAMS:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    item    -> JSON nastavenia

    RESULT:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const NumberResult = (typ, json) => {
    }
    

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { EmptyList, FormError, Icon, Loading, SelectYear, ShowError, ShowOK, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, FormControlLabel, IconButton, MenuItem, Pagination, Paper, Radio, RadioGroup, Select, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetLeftPosition, GetPages } from './functions';
import { GetTodayMonth, GetTodayYear } from './functions_date';

export const SettingsNumberFormat = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [number, setNumber] = useState('---');
    const [decimal, setDecimal] = useState(4);
    const [prefix, setPrefix] = useState('');
    const [char1, setChar1] = useState(0);
    const [value1, setValue1] = useState(0);
    const [char2, setChar2] = useState(0);
    const [value2, setValue2] = useState(0);
    const [char3, setChar3] = useState(0);
    const [value3, setValue3] = useState(0);
    const [pattern, setPattern] = useState('');

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 850 ? 850 : width;
    const dialogHeight = height >= 450 ? 450 : height;

    const exampleHeight = 90;
    const buttonHeight = 150;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            let item = props.item;
            if (item != false) {
                setPrefix(item.prefix);
                setDecimal(item.decimal);
                setChar1(item.char1);
                setChar2(item.char2);
                setChar3(item.char3);
                setValue1(item.value1);
                setValue2(item.value2);
                setValue3(item.value3);
                setPattern(item.pattern);
                CreateNumber(item.prefix, item.char1, item.value1, item.char2, item.value2, item.char3, item.value3, item.decimal);
            }
            running = true;
        }

    }, []);

    const Close = () => {
        func(false, false, false);
    }

    const ChangePrefix = (txt) => {
        setPrefix(txt);
        CreateNumber(txt, char1, value1, char2, value2, char3, value3, decimal);
    }

    const ChangeValue1 = (id) => {
        setValue1(id);
        CreateNumber(prefix, char1, id, char2, value2, char3, value3, decimal);
    }

    const ChangeValue2 = (id) => {
        setValue2(id);
        CreateNumber(prefix, char1, value1, char2, id, char3, value3, decimal);
    }

    const ChangeValue3 = (id) => {
        setValue3(id);
        CreateNumber(prefix, char1, value1, char2, value2, char3, id, decimal);
    }

    const ChangeChar1 = (id) => {
        setChar1(id);
        CreateNumber(prefix, id, value1, char2, value2, char3, value3, decimal);
    }

    const ChangeChar2 = (id) => {
        setChar2(id);
        CreateNumber(prefix, char1, value1, id, value2, char3, value3, decimal);
    }

    const ChangeChar3 = (id) => {
        setChar3(id);
        CreateNumber(prefix, char1, value1, char2, value2, id, value3, decimal);
    }

    const CreateNumber = (prefix, char1, value1, char2, value2, char3, value3, decimal) => {
        var result = '';
        setError('');

        result = prefix;
        result += GetChar(char1);
        result += GetValue(value1, decimal);
        result += GetChar(char2);
        result += GetValue(value2, decimal);
        result += GetChar(char3);
        result += GetValue(value3, decimal);

        var patt = '';
        patt = prefix;
        patt += GetChar(char1);
        patt += GetPatternValue(value1, decimal);
        patt += GetChar(char2);
        patt += GetPatternValue(value2, decimal);
        patt += GetChar(char3);
        patt += GetPatternValue(value3, decimal);

        setPattern(patt);
        setNumber(result);
    }

    const GetChar = (id) => {
        var result = '';

        switch (id) {
            case 0:
                result = '';
                break;
            case 1:
                result = '-';
                break;
            case 2:
                result = '/';
                break;

        }

        return (result);
    }

    const GetValue = (id, decimal) => {
        var result = '';

        switch (id) {
            case 0:
                result = '';
                break;
            case 1:
                result = GetTodayYear();
                break;
            case 2:
                result = GetTodayMonth().toString().padStart(2, '0');
                break;
            case 3:
                result = '1'.padStart(decimal, '0');
                break;

        }

        return (result);
    }


    const GetPatternValue = (id, decimal) => {
        var result = '';

        switch (id) {
            case 0:
                result = '';
                break;
            case 1:
                result = '@year';
                break;
            case 2:
                result = '@month';
                break;
            case 3:
                result = '@number' + decimal;
                break;

        }

        return (result);
    }

    const ChangeDecimal = (value) => {
        setDecimal(value);
        CreateNumber(prefix, char1, value1, char2, value2, char3, value3, value);
    }

    const Save = () => {
        if (value1 == 3 || value2 == 3 || value3 == 3) {
            setError('');

            // VRATI spat údaje
            let data = {
                prefix: prefix,
                value1: value1,
                value2: value2,
                value3: value3,
                char1: char1,
                char2: char2,
                char3: char3,
                decimal: decimal,
                pattern: pattern,
                example: number,
                month: (value1 == 2 || value2 == 2 || value3 == 2) ? true : false,
                year: (value1 == 1 || value2 == 1 || value3 == 1) ? true : false
            }

            func(true, data);
        } else {
            // CHYBA - musí byt oznacené poradové číslo
            setError(lang.document_number_error);
        }
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.document_number_title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, backgroundColor: color.back }}>
                    <div style={{ ...styles.Block, height: exampleHeight, backgroundColor: color.gray, borderBottom: '1px solid ' + color.light_gray }}>
                        <p style={{ ...styles.TextTiny }}>{lang.document_number_format}</p>
                        <p style={{ ...styles.TextXLarge, fontWeight: '600' }}>{number}</p>
                        {global.testing == true ?
                            <p style={{ ...styles.TextXTiny, marginTop: 4, color: color.dark_gray }}>{pattern}</p>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - exampleHeight - buttonHeight }}>
                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                            {/* PREFIX */}
                            <div style={{ ...styles.BlockLeft, width: '12%' }}>
                                <TextInput redraw theme={props.theme} enabled={true} lang={lang} value={prefix} label={lang.document_number_prefix} func={(txt) => ChangePrefix(txt)} />
                            </div>
                            {/* ZNAK */}
                            <div style={{ ...styles.BlockLeft, width: 80 }}>
                                <p style={{ ...styles.TextInput, color: color.dark_gray }}>{lang.document_number_char}</p>
                                <Select
                                    value={char1}
                                    onChange={event => ChangeChar1(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: '#FFFFFF', height: 36, width: '100%', borderRadius: global.radius }}
                                >
                                    <MenuItem value={0}>{lang.document_number_char_none}</MenuItem>
                                    <MenuItem value={1}>{'-'}</MenuItem>
                                    <MenuItem value={2}>{'/'}</MenuItem>
                                </Select>
                            </div>
                            {/* VALUE 1 */}
                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                <p style={{ ...styles.TextInput, color: color.dark_gray }}>{lang.document_number_value}</p>
                                <Select
                                    value={value1}
                                    onChange={event => ChangeValue1(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: '#FFFFFF', height: 36, width: '100%', borderRadius: global.radius }}
                                >
                                    <MenuItem value={0}>{lang.document_number_value_none}</MenuItem>
                                    <MenuItem value={1}>{lang.year}</MenuItem>
                                    <MenuItem value={2}>{lang.month}</MenuItem>
                                    <MenuItem value={3}>{lang.document_number}</MenuItem>
                                </Select>
                            </div>
                            {/* ZNAK */}
                            <div style={{ ...styles.BlockLeft, width: 80 }}>
                                <p style={{ ...styles.TextInput, color: color.dark_gray }}>{lang.document_number_char}</p>
                                <Select
                                    value={char2}
                                    onChange={event => ChangeChar2(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: '#FFFFFF', height: 36, width: '100%', borderRadius: global.radius }}
                                >
                                    <MenuItem value={0}>{lang.document_number_char_none}</MenuItem>
                                    <MenuItem value={1}>{'-'}</MenuItem>
                                    <MenuItem value={2}>{'/'}</MenuItem>
                                </Select>
                            </div>
                            {/* VALUE 2 */}
                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                <p style={{ ...styles.TextInput, color: color.dark_gray }}>{lang.document_number_value}</p>
                                <Select
                                    value={value2}
                                    onChange={event => ChangeValue2(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: '#FFFFFF', height: 36, width: '100%', borderRadius: global.radius }}
                                >
                                    <MenuItem value={0}>{lang.document_number_value_none}</MenuItem>
                                    <MenuItem value={1}>{lang.year}</MenuItem>
                                    <MenuItem value={2}>{lang.month}</MenuItem>
                                    <MenuItem value={3}>{lang.document_number}</MenuItem>
                                </Select>
                            </div>
                            {/* ZNAK */}
                            <div style={{ ...styles.BlockLeft, width: 80 }}>
                                <p style={{ ...styles.TextInput, color: color.dark_gray }}>{lang.document_number_char}</p>
                                <Select
                                    value={char3}
                                    onChange={event => ChangeChar3(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: '#FFFFFF', height: 36, width: '100%', borderRadius: global.radius }}
                                >
                                    <MenuItem value={0}>{lang.document_number_char_none}</MenuItem>
                                    <MenuItem value={1}>{'-'}</MenuItem>
                                    <MenuItem value={2}>{'/'}</MenuItem>
                                </Select>
                            </div>
                            {/* VALUE 3 */}
                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                <p style={{ ...styles.TextInput, color: color.dark_gray }}>{lang.document_number_value}</p>
                                <Select
                                    value={value3}
                                    onChange={event => ChangeValue3(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: '#FFFFFF', height: 36, width: '100%', borderRadius: global.radius }}
                                >
                                    <MenuItem value={0}>{lang.document_number_value_none}</MenuItem>
                                    <MenuItem value={1}>{lang.year}</MenuItem>
                                    <MenuItem value={2}>{lang.month}</MenuItem>
                                    <MenuItem value={3}>{lang.document_number}</MenuItem>
                                </Select>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, marginTop: 30 }}>
                            <p style={{ ...styles.TextTiny, marginBottom: 10 }}>{lang.document_number_decimal}</p>
                            <SelectYear min={2} max={8} value={decimal} theme={props.theme} func={ChangeDecimal.bind(this)} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>

                        <FormError error={error} small theme={props.theme} />
                        <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                            <div style={{ ...styles.BlockRight, width: '50%', marginRight: 20 }}>
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '50%', marginLeft: 20 }}>
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                            </div>
                        </div>
                    </div>

                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


