/*
*
* =================================================================================================================
* 
    PLUGINY
    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { DialogTabs, DialogVideo, DialogYesNo, FormLabel, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Logs, Avatar, Line, SwitchEnable, MenuButton, SelectInput, DialogExport, SearchButton, FormNote, DialogProtocol } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, IconButton, Pagination, Paper, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faBook, faCircleInfo, faCog, faInfoCircle, faLayerGroup, faLocationDot, faPlusCircle, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, CheckPlugin, Debug, GetLeftPosition, GetPages, IsEmpty } from './functions';
import { Photos } from './photos';

export const Plugins = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let permission_column = 'plugins';

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(global.plugins);
    const [itemSelected, setItemSelected] = useState(false);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 70;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get();

            running = true;
        }

    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'plugins', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var plugins = json.items;
                if (json.items != false) {
                    plugins.forEach(plugin => {
                        var tmp = items.find(x => x.id == plugin.plugin_id);
                        if (tmp != undefined) {
                            tmp.enabled = plugin.enabled;
                            tmp.item_id = parseInt(plugin.id);
                            let settings = JSON.parse(plugin.settings);
                            if (settings != false) {
                                tmp.settings.forEach(element => {
                                    var item = settings.find(x => x.id == element.id);
                                    if (item != undefined) {
                                        element.value = item.value;
                                    }
                                });
                                plugin.settings = tmp;
                            }
                        }
                    });
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        if (props.user.super_admin == true) {
            setItemSelected(item);
            setShowDialog(true);
        }
    }

    const DialogResult = (redraw_) => {
        setShowDialog(false);

        if (redraw_ == true) {
            db_get();
        }
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                            <div id={'id_start'} style={{ ...styles.Block, width: '96%' }}>

                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    {items != false ? items.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            onClick={() => SelectItem(item)}
                                            style={{
                                                ...styles.Block,
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '96%' }}>
                                                <div style={{ ...styles.BlockLeft, width: 60 }}>
                                                    <FontAwesomeIcon style={{ height: 30, color: color.light_gray }} icon={item.icon} />
                                                </div>

                                                <div style={{ ...styles.BlockLeft, width: '85%' }}>
                                                    <p style={{ ...styles.TextXSmall }}><b>{lang[item.name]}</b></p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang[item.text]}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 100 }}>
                                                    <Chip label={<p style={{ ...styles.TextTiny }}>{item.enabled == true ? lang.enabled : lang.disabled}</p>} />
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }

                                </div>

                            </div>
                            <FormSpace height={global.empty_padding} />
                        </div>
                    </div>
                </div>
            </div>

            {showDialog == true ?
                <Plugin remote={props.remote} remoteMobile={props.remoteMobile} item={itemSelected} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={permission_column} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}


            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}



/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const Plugin = (props) => {

    let module_id = props.module_id;
    let permission_column = props.permission_column;

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(true);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    const tabs = [
        { id: 0, enabled: true, label: lang.info },
        { id: 1, enabled: true, label: lang.settings },
    ];

    // DATA
    const [storeData, setStoreData] = useState({});

    const [enabled, setEnabled] = useState(props.item.enabled == false ? false : true);
    const [items, setItems] = useState(props.item.settings);
    const [showProtocol, setShowProtocol] = useState(false);
    const [protocol, setProtocol] = useState('');


    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    // CONSTANTS
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 190;
    const column2 = dialogWidth - 40 - column1;
    const tabHeight = 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                //db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async (link) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + link, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setProtocol(JSON.stringify(json));
                setShowProtocol(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'plugin_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);

    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.enabled = enabled;

    }

    const Save = () => {
        setError('');
        var error = false;

        if (error == 0) {

            let data = {
                id: props.item.item_id,
                organization_id: props.organization.id,
                plugin_id: props.item.id,
                enabled: enabled,
                settings: JSON.stringify(items)
            };

            StoreEditing();
            Debug(data);
            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ShowOKPress = () => {
        func(true);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faLayerGroup} logo />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.plugin}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.Block, height: tabHeight }}>
                        {enabled == true ?
                            <DialogTabs disabled={enabled == true ? false : true} center value={tabIndex} items={tabs} width={dialogWidth / 3} theme={props.theme} func={(id) => ChangeTab(id)} />
                            : null}
                    </div>

                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                            <div style={{ ...styles.Block, paddingTop: 16, paddingBottom: 16, backgroundColor: color.light_gray }}>
                                <p style={{ ...styles.TextSmall }}><b>{lang[props.item.name]}</b></p>
                                <p style={{ ...styles.TextXSmall, marginTop: 5 }}>{lang[props.item.text]}</p>
                            </div>

                            <FormSpace />
                            <FormYesNo value={enabled} editing={editing} title={lang.plugin_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                            <FormSpace />


                        </div>
                        : null}

                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block }}>

                                {items != false ? items.map((item, index) => (
                                    <div key={index} style={{ ...styles.Block }}>
                                        {item.label != '' ?
                                            <>
                                                <FormSpace />
                                                <FormLabel editing={editing} title={lang[item.label]} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => Debug(true)} />
                                            </>
                                            : null}
                                        {item.type == 'yesno' ?
                                            <FormYesNo value={item.value} editing={true} title={lang[item.name]} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { item.value = value }} />
                                            :
                                            <>
                                                <FormText numeric={item.type == 'integer' ? true : undefined} password={item.type == 'password' ? true : false} redraw value={item.value} editing={true} title={lang[item.name]} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { item.value = txt }} />
                                                {item.note != '' ?
                                                    <FormNote title={lang[item.note]} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                                    : null}
                                            </>
                                        }
                                    </div>
                                )) : null}

                                {props.item.button != '' ?
                                    <Button onClick={() => db_get(props.item.button)} style={{ ...styles.ButtonOutlined, marginTop: 50 }}>{lang[props.item.button_text]}</Button>
                                    : null}

                                {props.remote == true && props.remoteMobile == true ?
                                    <FormSpace height={400} />
                                    : null}
                            </div>
                        </div>
                        : null}

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {editing == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                        </div>
                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >

            {showProtocol == true ?
                <DialogProtocol title={lang.protocol} protocol={protocol} button={lang.close} theme={props.theme} radius={props.radius} func={() => setShowProtocol(false)} />
                : null}

        </Backdrop>
    );
}


