import React, { useEffect, useState, useRef } from 'react';

import { styles } from './styles';
import { Button, IconButton, Paper, Backdrop, Chip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faEnvelope, faEye, faFileText, faFolderOpen, faPaperPlane, faPlus, faQuestion, faSearch, faWandMagicSparkles, faXmark } from '@fortawesome/free-solid-svg-icons';


import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { DialogChoose, DialogYesNo, FormError, FormOK, FormSpace, Icon, Loading, ShowError, ShowInfo, ShowOK, TextInput, WebURLPreview } from './items';
import { Photos } from './photos';
import { Debug, GetLeftPosition, IsEmpty, OpenAIChat, UniqueID } from './functions';
import { FileSelect } from './files';
import DOMPurify from 'dompurify';
import { CustomerSelect, CustomerSelectEmail } from './customers';
import { UsersSelect } from './users_select';
import { DialogOpenAI } from './dialog_openai';


export const EditorHtml = (props) => {

    /*
        Editor HTML textu

        <EditorHtml block={true} theme={props.theme} text={''} lang={lang} radius={radius} func={EditorHtmlResult.bind(this)} />

        --------------------------------------------------------------------------------------------------------------------
        text    => vo formáte HTML
        radius  => window borderRadius
        --------------------------------------------------------------------------------------------------------------------

        const EditorHtmlResult = (typ, text) => {
            if (typ == true) {
                console.log(text);
            }
        }

    */

    const blocksFromHTML = convertFromHTML(props.text);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap,);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));
    const [contentState, setContentState] = useState();

    const [preview, setPreview] = useState(false);
    const [previewText, setPreviewText] = useState('');
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : props.width != undefined ? props.width : 900;
    const dialogHeight = props.isSmall ? height : props.height != undefined ? props.height : 700;
    const buttonHeight = 70;

    let { func } = props;
    var lang = props.lang;
    const editorRef = useRef(null);

    useEffect(() => {
        // FOCUS TO TEXTAREA
        setTimeout(() => {
            editorRef.current.focusEditor();
        }, 300);

    }, []);

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(false, false);
    }

    const Save = () => {
        let html = convertToHTML(editorState.getCurrentContent());
        Press(true, html);
    }

    const ChangePreview = (value) => {
        if (value == true) {
            let html = convertToHTML(editorState.getCurrentContent());
            setPreviewText(html);
        }
        setPreview(value);
    }

    return (
        props.block == true ?
            <Paper elevation={0} style={{ width: dialogWidth, height: dialogHeight, background: global.theme_white, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: dialogHeight, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {preview == false ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - - buttonHeight, backgroundColor: global.theme_white }}>
                            {/* BODY */}
                            <Editor
                                ref={editorRef}
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                defaultContentState={contentState}
                                onContentStateChange={setContentState}

                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"

                                editorStyle={{ backgroundColor: global.theme_white, height: props.isSmall ? dialogHeight - buttonHeight : dialogHeight - buttonHeight - 50, border: '1px solid ' + global.theme_light_gray }}
                                toolbarStyle={{ backgroundColor: global.theme_gray, width: dialogWidth - 20 }}

                                toolbar={{
                                    options: ['inline', 'blockType', 'list', 'history'],
                                    inline: { inDropdown: false },
                                    list: { inDropdown: false },
                                    textAlign: { inDropdown: false },
                                    history: { inDropdown: false },
                                    inline: {
                                        inDropdown: false,
                                        options: ['bold', 'italic', 'underline'],
                                    },
                                    blockType: {
                                        inDropdown: false,
                                        options: ['Normal', 'H1', 'H2', 'H3'],
                                    },
                                    list: {
                                        inDropdown: false,
                                        options: ['unordered', 'ordered'],
                                    },
                                }}
                            />
                        </div>
                        :
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - buttonHeight }}>
                            <div style={{ ...styles.BlockLeft, width: '95%' }}>
                                <div style={{ ...styles.TextSmall, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: previewText }} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: '95%' }}>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.Block, height: buttonHeight }}>
                                <Button onClick={() => ChangePreview(!preview)} style={{ ...styles.ButtonDark, backgroundColor: preview == true ? global.theme_dark_red : global.themes[props.theme].theme_dark }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faEye} />
                                    {preview == true ? lang.close : lang.preview}
                                </Button>
                            </div>
                            <div style={{ ...styles.Block, height: buttonHeight }}>
                                <Button onClick={() => Save()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
            :
            <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
                <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                    <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faFileText} />
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                            <div style={{ ...styles.BlockLeft, height: 45 }}>
                                <p style={{ ...styles.TextDialogLabel }}>{lang.text_editor}</p>
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.vision_system}</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        {preview == false ?
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: global.theme_white }}>
                                {/* BODY */}
                                <Editor
                                    ref={editorRef}
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    defaultContentState={contentState}
                                    onContentStateChange={setContentState}

                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"

                                    editorStyle={{ backgroundColor: global.theme_white, height: props.isSmall ? dialogHeight - global.dialog_bar_height - buttonHeight - 100 : dialogHeight - global.dialog_bar_height - buttonHeight - 55, border: '1px solid ' + global.theme_light_gray }}
                                    toolbarStyle={{ backgroundColor: global.theme_gray, width: dialogWidth - 20 }}

                                    toolbar={{
                                        options: ['inline', 'blockType', 'list', 'history'],
                                        inline: { inDropdown: false },
                                        list: { inDropdown: false },
                                        textAlign: { inDropdown: false },
                                        history: { inDropdown: false },
                                        inline: {
                                            inDropdown: false,
                                            options: ['bold', 'italic', 'underline'],
                                        },
                                        blockType: {
                                            inDropdown: false,
                                            options: ['Normal', 'H1', 'H2', 'H3'],
                                        },
                                        list: {
                                            inDropdown: false,
                                            options: ['unordered', 'ordered'],
                                        },
                                    }}
                                />
                            </div>
                            :
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                                <div style={{ ...styles.BlockLeft, width: '95%' }}>
                                    <div style={{ ...styles.TextSmall, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: previewText }} />
                                </div>
                            </div>
                        }
                        <div style={{ ...styles.Block, width: '95%' }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.Block, height: buttonHeight }}>
                                    <Button onClick={() => ChangePreview(!preview)} style={{ ...styles.ButtonDark, backgroundColor: preview == true ? global.theme_dark_red : global.themes[props.theme].theme_dark }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faEye} />
                                        {lang.preview}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, height: buttonHeight }}>
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Paper>
            </Backdrop>

    );
}


export const EmailEditor = (props) => {
    /*
            Vytváranie emailov
            
            USAGE:
            <EmailEditor lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} theme={props.theme} func={DialogEmailResult.bind(this)} />

            const DialogEmailResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);
    const [info, setInfo] = useState(false);
    const [email, setEmail] = useState(props.email != undefined ? props.email : '');
    const [emailCC, setEmailCC] = useState(props.email_cc != undefined ? props.email_cc : '');
    const [addCC, setAddCC] = useState(false);
    const [header, setHeader] = useState(props.subject != undefined ? props.subject : '');
    const [redraw, setRedraw] = useState(false);

    const blocksFromHTML = convertFromHTML('');
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));
    const [contentState, setContentState] = useState();
    const [aiSubject, setAISubject] = useState('');
    const [attachments, setAttachments] = useState([]);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showSizeError, setShowSizeError] = useState(false);
    const [showOpenAI, setShowOpenAI] = useState(false);

    // VYBER ZAKAZNIKA
    const [showCustomers, setShowCustomers] = useState(false);
    const [showCustomerEmails, setShowCustomerEmail] = useState(false);
    const [customerID, setCustomerID] = useState(props.customerID);
    const [customer, setCustomer] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [showChoose, setChoose] = useState(false);

    // ZOBRAZIT KNIZNICU
    const [showLibrary, setShowLibrary] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1000 ? 1000 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    const headerHeight = 80;
    const errorHeight = 60;
    const buttonHeight = 80;
    const editorHeight = props.email == '' ? 300 : 400;

    const no_image = require('./react/app/no_image.jpg');

    let color = global.themes[props.theme];
    let { func } = props;
    var lang = props.lang;

    const inputRef = useRef(null);
    const inputRef2 = useRef(null);

    var running = false;
    useEffect(() => {
        Debug(props.params);
        if (running == false) {
            running = true;

            if (props.params != undefined) {
                if (props.params != false) {
                    if (props.params.assistent == true) {
                        if (props.params.subject != undefined) {
                            setAISubject(props.params.subject);
                        }
                        db_get_ai();
                    }
                }
            }
        }

        // FOCUS TO TEXTAREA
        if (props.email == '') {
            setTimeout(() => {
                inputRef2.current.focus();
            }, 300);
        } else {
            setTimeout(() => {
                inputRef.current.focus();
            }, 300);
        }

    }, []);

    const db_send = async (header, html) => {

        setBusy(true);

        const data = new FormData();

        var n = 0;
        var i = 0;
        [...attachments].forEach(item => {
            if (item.remote == false) {
                data.append("file_" + n, item);
                n++;
            } else {
                data.append("file_remote_" + i, item.link);
                i++;
            }
        });

        data.append("token", props.token);
        data.append("user_id", props.user.id);
        data.append("organization_id", props.organization.id);

        data.append("customer_id", customerID);
        data.append("header", header.trim());
        data.append("text", html.trim());

        data.append("attachments", n);
        data.append("attachments_delete", props.attachment_delete == false ? 0 : 1); // vymazat prílohy po odoslani

        data.append("attachments_library", i);

        data.append("delete", props.delete); // vymazat email po odoslani
        data.append("email", email); // emailova adresa, kde bude email odoslany
        data.append("cc", emailCC); // skrytá kópia
        Debug('props.attachment_delete');
        Debug(props.attachment_delete);
        try {
            const response = await fetch(
                global.db_url + 'send_email_app', {
                method: 'POST',
                body: data
            })

            const json = await response.json();

            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                setShowOK(true);
                setInfo(lang.email_sended);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_ai = async () => {
        setBusy(true);

        var question = lang.openai_email_request + ' ' +
            lang.openai_email_company.replace('@company', props.organization.name) + ', ' +
            lang.openai_email_no_email_mobil + ', ' +
            lang.openai_email_name.replace('@name', props.user.name + ' ' + props.user.surname) + '. ' +
            lang.openai_email_subject + ': ';

        if (props.subject != undefined) {
            question += props.subject;
        }

        var data = OpenAIChat([], question, '', lang, true); // OTAZKA

        try {
            const response = await fetch(
                global.db_url + 'openai_chat', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    history: data
                })
            })

            const json = await response.json();
            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                let blocksFromHTML = convertFromHTML(json.answer);
                const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
                setEditorState(EditorState.createWithContent(state));
            }

        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const CloseOK = () => {
        func(true);
    }

    const Close = () => {
        Press(false, false);
    }

    const Process = () => {
        setError('');
        setInfo('');

        let html = convertToHTML(editorState.getCurrentContent());

        if (IsEmpty(header)) {
            setError(lang.email_error_header);
        } else {
            db_send(header.trim(), html);
            setShowOK(true);
            setInfo(lang.email_sended);
        }
    }

    const AttachmentDelete = (id) => {
        var tmp = attachments.filter(x => x.id != id);
        setAttachments(tmp);
        Debug(tmp);
        setRedraw(!redraw);
    }

    function GetSize() {
        var size = 0;
        attachments.forEach(item => {
            size += item.size;
        });

        return size;
    }


    function AttachmentUpload(e) {
        // VYBER SUBOROV A ODOSLANIE NA SERVER           
        var files = e.target.files
        var attachments_files = attachments;
        var size = GetSize();
        var err = false;

        [...files].forEach(file => {
            Debug(file);
            if (size + file.size < global.attachment_max) {
                file.id = UniqueID();
                file.remote = false;
                attachments_files.push(file);
                size += file.size;
            } else {
                err = true;
            }
        });
        if (err == true) {
            setShowSizeError(true);
        }

        setRedraw(!redraw);
    }

    function getFilename(fullPath) {
        return fullPath.replace(/^.*[\\\/]/, '');
    }

    const SelectResult = (value, link) => {
        var attachments_files = attachments;
        if (value == true) {
            let data = {
                id: UniqueID(),
                name: getFilename(link),
                link: link,
                remote: true,
                size: 0,
            };
            attachments_files.push(data);
        }

        setShowLibrary(false);
    }

    const CustomersResult = (typ, customer_id, customer, email) => {
        setShowCustomers(false);

        if (typ == true && customer != false) {
            if (addCC == false) {
                setCustomerID(customer_id);
                setCustomer(customer);
                setEmail(email.trim());
            } else {
                if (emailCC.trim() == '') {
                    setEmailCC(email.trim());
                } else {
                    setEmailCC(emailCC + ',' + email.trim());
                }

            }
        }
    }

    const CustomerEmailResult = (typ, email) => {
        setShowCustomerEmail(false);

        if (typ == true) {
            if (addCC == false) {
                setCustomerID(props.customerID);
                setEmail(email.trim());
            }
        }
    }

    const UserResult = (typ, selected) => {
        setShowUsers(false);

        if (typ == true && selected != false) {
            if (emailCC.trim() == '') {
                setEmailCC(selected.email.trim());
            } else {
                setEmailCC(emailCC + ',' + selected.email.trim());
            }
        }
    }

    const AddEmail = (value) => {
        setAddCC(value);
        if (props.customerID == 0) {
            setShowCustomers(true);
        } else {
            if (value == false) {
                setShowCustomerEmail(true);
            } else {
                setShowCustomers(true);
            }
        }
    }

    const ChooseResult = (value) => {
        setChoose(false);

        if (value == false) {
            setTimeout(() => {
                AddEmail(true);
            }, 250);
        } else {
            setTimeout(() => {
                setShowUsers(true);
            }, 250);
        }
    }


    const AIResult = (typ, text) => {
        setShowOpenAI(false);

        if (typ == true) {
            let blocksFromHTML = convertFromHTML(text);
            const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            setEditorState(EditorState.createWithContent(state));
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1100 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faEnvelope} />
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }} >

                    {/* BODY */}
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.BlockCenter, backgroundColor: color.back }}>
                            <div style={{ ...styles.BlockRow, width: '96%', marginTop: 10 }}>
                                <div style={{ ...styles.BlockRow, width: '35%', backgroundColor: global.theme_back, marginRight: 40 }}>
                                    <TextInput inputRef={inputRef2} require redraw enabled={true} lang={lang} value={email} label={lang.email_address_label} func={(txt) => setEmail(txt)} />
                                    <div style={{ ...styles.BlockRight, width: 40 }}>
                                        <IconButton onClick={() => AddEmail(false)} style={{ ...styles.ButtonIcon, marginTop: 22 }}>
                                            <FontAwesomeIcon style={{ width: 10 }} icon={faSearch} />
                                        </IconButton>
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockRow, width: '63%', backgroundColor: global.theme_back }}>
                                    <TextInput require redraw enabled={true} lang={lang} value={emailCC} label={lang.email_cc} func={(txt) => setEmailCC(txt)} />
                                    <div style={{ ...styles.BlockRight, width: 40 }}>
                                        <IconButton onClick={() => setChoose(true)} style={{ ...styles.ButtonIcon, marginTop: 22 }}>
                                            <FontAwesomeIcon style={{ width: 10 }} icon={faPlus} />
                                        </IconButton>
                                    </div>
                                </div>

                            </div>
                            <div style={{ ...styles.Block, width: '96%', height: headerHeight, backgroundColor: global.theme_back }}>
                                <TextInput inputRef={inputRef} require redraw enabled={true} lang={lang} value={header} label={lang.email_header} func={(txt) => setHeader(txt)} />
                            </div>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                defaultContentState={contentState}
                                onContentStateChange={setContentState}

                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"

                                editorStyle={{ backgroundColor: color.white, width: dialogWidth - 46, height: editorHeight - headerHeight - 30, border: '1px solid ' + color.light_gray }}
                                toolbarStyle={{ backgroundColor: color.back, width: dialogWidth - 46 }}

                                toolbar={{
                                    options: ['inline', 'blockType', 'list', 'history'],
                                    inline: { inDropdown: false },
                                    list: { inDropdown: false },
                                    textAlign: { inDropdown: false },
                                    history: { inDropdown: false },
                                    inline: {
                                        inDropdown: false,
                                        options: ['bold', 'italic', 'underline'],
                                    },
                                    blockType: {
                                        inDropdown: false,
                                        options: ['Normal', 'H1', 'H2', 'H3'],
                                    },
                                    list: {
                                        inDropdown: false,
                                        options: ['unordered', 'ordered'],
                                    },
                                }}
                            />
                        </div>

                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.email_attachments}</p>
                            <p style={{ ...styles.TextTiny }}>{lang.email_attachment_max_size}</p>
                        </div>
                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 }}>
                            {attachments.map((item, index) => (
                                <div key={index} style={{ ...styles.Block, width: undefined, margin: 4 }}>
                                    <Chip label={item.name} onDelete={() => AttachmentDelete(item.id)}></Chip>
                                </div>
                            ))}
                        </div>
                        <div style={{ ...styles.Block, marginTop: 20, paddingBottom: 20 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {props.organization.cloud_enabled == true ?
                                    <Button onClick={() => setShowLibrary(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action_in_bar, width: 200, marginRight: 20 }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faFolderOpen} />
                                        {lang.file_library_select}
                                    </Button>
                                    : null}
                                <Button variant="contained" component="label" style={{ ...styles.ButtonThemed, backgroundColor: color.button_action_in_bar, width: 200, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPlus} />
                                    {lang.email_attachment_add}
                                    <input ref={inputRef} onInput={(e) => AttachmentUpload(e)} /* onChange={(e) => AttachmentUpload(e)} */ multiple hidden type="file" />
                                </Button>
                                {props.system.ai_enabled == true && (props.permissions.ai == true || props.user.super_admin == true) ?
                                    <Button onClick={() => setShowOpenAI(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action_in_bar, width: 200 }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faWandMagicSparkles} />
                                        {lang.openai_email_assistent}
                                    </Button>
                                    : null}
                            </div>
                        </div>

                        {props.remote == true && props.remoteMobile == true ?
                            <FormSpace height={300} />
                            : null}

                    </div>
                    <div style={{ ...styles.Block, height: errorHeight + buttonHeight - 1, borderTop: '1px solid #00000020' }}>
                        <div style={{ ...styles.Block, width: '98%', height: errorHeight }}>
                            {error != '' ?
                                <FormError error={error} theme={props.theme} />
                                : null}
                            {info != '' ?
                                <FormOK text={info} theme={props.theme} />
                                : null}
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, width: 200 }}>
                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPaperPlane} />
                                {lang.email_send}
                            </Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {showOpenAI == true ?
                <DialogOpenAI remote={props.remote} remoteMobile={props.remoteMobile} subject={aiSubject} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} permission_column={'open_ai'} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={AIResult.bind(this)} />
                : null}

            {showChoose == true ?
                <DialogChoose remote={props.remote} remoteMobile={props.remoteMobile} title={lang.email_choose_label} text={lang.email_choose_text} sub_text={''} icon={faQuestion} yes={lang.user} no={lang.customer} yes_color={color.button_cancel} no_color={color.button_cancel} buttonWidth={200} theme={props.theme} radius={props.radius} func={ChooseResult.bind(this)} />
                : null}

            {showCustomerEmails == true ?
                <CustomerSelectEmail remote={props.remote} remoteMobile={props.remoteMobile} one_click customerID={props.customerID} organization={props.organization} settings={props.settings} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomerEmailResult.bind()} />
                : null}

            {showUsers == true ?
                <UsersSelect remote={props.remote} remoteMobile={props.remoteMobile} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.user_select} token={props.token} func={UserResult.bind()} />
                : null}

            {showCustomers == true ?
                <CustomerSelect remote={props.remote} remoteMobile={props.remoteMobile} email single showVariations={true} organization={props.organization} locale={props.locale} user={props.user} settings={props.settings} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.customer_select_} token={props.token} func={CustomersResult.bind()} />
                : null}

            {showLibrary == true ?
                <FileSelect remote={props.remote} remoteMobile={props.remoteMobile} selecting init_type={1} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.file_library_select_title} token={props.token} func={SelectResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} offset={props.offset} func={() => CloseOK()} />
                : null}

            {showSizeError == true ?
                <ShowInfo theme={props.theme} text={props.lang.email_attachment_size_error} sub_text={lang.email_attachment_size_error_note} lang={props.lang} func={() => setShowSizeError(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={props.lang.db_error} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop >
    );
}



export const EmailPreview = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = { enabled: true };


    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [item, setItem] = useState(false);
    const [attachments, setAttachments] = useState(false);
    const [attachment, setAttachment] = useState(false);
    const [showAttachment, setShowAttachment] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width > 860 ? 860 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    let attachmentsHeight = 100;
    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get();

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_email', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setItem(json.item);
                setAttachments(json.item.attachments == false ? false : JSON.parse(json.item.attachments));
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(false);
    }


    const ShowAttachment = (item) => {
        setAttachment(global.web + '/' + item.name);
        setShowAttachment(true);
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width),backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2500 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faAt} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.email_message}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - attachmentsHeight, borderBottomLeftRadius: props.radius, backgroundColor: '#E0E0E0', borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.Block, width: '96%', paddingTop: 10 }}>
                        {item != false ?
                            <>
                                <p style={{ ...styles.TextTiny }}>{lang.email}: <b>{item.address_to}</b></p>
                                {item.cc != '' ?
                                    <p style={{ ...styles.TextTiny, textAlign: 'center' }}>{lang.email_cc_}: {item.cc.replaceAll(',', ', ')}</p>
                                    : null}
                                <div style={{ ...styles.TextXSmall, width: dialogWidth - 40 }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message) }} />
                            </>
                            : null}
                    </div>
                </div >

                <div style={{ ...styles.BlockCenter, height: attachmentsHeight - 1, backgroundColor: '#E0E0E0', borderTop: '1px solid #D0D0D0', borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    {attachments != false ?
                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.email_attachments_}</p>
                        : null}
                    <div style={{ ...styles.BlockLeft, flexDirection: 'row', flexWrap: 'wrap', marginTop: 5 }}>
                        {attachments != false ? attachments.map((item, index) => (
                            <div key={index} style={{ ...styles.Block, width: undefined }}>
                                <Chip onClick={() => ShowAttachment(item)} label={lang.email_attachment + ' ' + (index + 1)} style={{ margin: 2, cursor: 'pointer' }} ></Chip>
                            </div>
                        )) :
                            <p style={{ ...styles.TextXTiny, marginTop: 10 }}>{lang.email_attachment_none}</p>
                        }
                    </div>
                </div>

                {showAttachment == true ?
                    <WebURLPreview url={attachment} theme={props.theme} title={lang.email_attachment} lang={props.lang} func={() => setShowAttachment(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}

