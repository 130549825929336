/*
*
* =================================================================================================================
* GROUPS - užívateľské skupiny

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Window organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { ButtonNew, Avatar, DialogLabel, EmptyList, FormError, FormLabel, FormText, FormYesNo, Icon, Line, Loading, ShowError, ShowOK, SwitchEnable, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, MenuItem, Pagination, Paper, Tab, Tabs, Tooltip, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCheckCircle, faInfoCircle, faKey, faLayerGroup, faList, faPlug, faPlus, faPlusCircle, faQuestion, faToggleOn, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetDatum2, GetDatumTime2, GetTodayYear } from './functions_date';
import { BinaryToPermission, Debug, GetPages, PermissionToBinary } from './functions';
import { GetNodeChilds, TreeNodes } from './nodes';
import { UsersSelect } from './users_select';


export const UsersGroups = (props) => {
    /*
            // ZOZNAM POUŽÍVATEĽOV - USERS LIST

            USAGE:
            <UsersPermissions theme={props.theme} lang={props.lang} radius={props.radius} />

            PARAMS:
    */

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // SYSTEM
    const [isBusy, setBusy] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DIALOGS
    const [showUserRegister, setShowUserRegister] = useState(false);

    // CONSTANTS
    const menuHeight = 50;
    const rowHeight = 50;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            db_get(0, false);

            running = true;
        }

        return () => {
        };

    }, []);

    const db_get = async (page, filter) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'users_groups', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -----------------------------
                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Search = (txt) => {
        setSearch(txt);
        if (txt.length > 1) {
            let filter = { search: txt };
            db_get(0, filter);
        }
        if (txt == '') {
            db_get(0, false);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const AddNewResult = () => {
        setItemEditing(false);
        setShowUserRegister(true);
    }

    const SelectItem = (item) => {
        setItemEditing(item);
        setShowUserRegister(true);
    }

    const UserResult = (typ, redraw) => {
        setShowUserRegister(false);
        if (typ == true && redraw == true) {
            db_get(page, filter);
        }
    }

    return (
        <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
            <div style={{ ...styles.Block, width: '96%' }}>

                <DialogLabel label={lang.user_groups} sub_label={lang.user_groups_text} theme={props.theme} />

                <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray, marginTop: 10 }}>
                    <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                        <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                    </div>
                    <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                        <ButtonNew label={lang.users_group_new} theme={props.theme} func={AddNewResult.bind()} />
                    </div>
                </div>

                <div style={{ ...styles.Block, marginTop: 20 }}>
                    {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                        <Paper
                            key={item.id}
                            elevation={item.id == overID ? global.elevation_over : global.elevation}
                            onClick={() => SelectItem(item)}
                            style={{
                                ...styles.Block,
                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                borderRadius: global.items_radius,
                                minHeight: rowHeight,
                                cursor: 'pointer',
                                marginTop: 5, marginBottom: 5,
                                paddingTop: 2, paddingBottom: 2,
                                borderLeft: item.enabled == false ? color.disabled_border : '',
                            }}
                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                        >
                            <div style={{ ...styles.BlockRow, width: '96%' }}>
                                <div style={{ ...styles.BlockLeft, width: '30%', minHeight: rowHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.label}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '65%', minHeight: rowHeight, justifyContent: 'center', borderRight: '1px solid ' + color.light_gray }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.note}</p>
                                </div>
                                <div style={{ ...styles.Block, width: '15%', minHeight: rowHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextXXTiny }}>{lang.updated}</p>
                                    <p style={{ ...styles.TextXTiny, marginTop: 5 }}>{GetDatum2(item.updated)}</p>
                                </div>
                            </div>
                        </Paper>
                    )) :
                        <div style={{ ...styles.Block, paddingTop: global.empty_padding }}>
                            <EmptyList row small lang={lang} />
                        </div>
                    }

                    {/* PAGINATION */}
                    {pages > 1 ?
                        <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                        </div>
                        : null}

                </div>

            </div>

            {showUserRegister ?
                <UserGroup itemEditing={itemEditing} organization={props.organization} user={props.user} settings={props.settings} lang={lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} token={props.token} func={UserResult.bind()} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </div>
    );
}



/*
*
* =================================================================================================================
* UZIVATELSKE SKUPINY - NEW GROUP
* =================================================================================================================
*
*/
export const UserGroup = (props) => {

    let color = global.themes[props.theme];
    let permissions = global.permissions;
    var lang = props.lang;
    let radius = props.radius;

    const column1 = 200;
    const column2 = 450;
    const offset = 20;
    const menuHeight = 50;
    const rowHeight = 50;

    // IMAGES
    const user_default = require('./react/app/user.png');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(props.itemEditing == false ? true : false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState('');

    // DATA
    const [items, setItems] = useState(false);

    const [id, setID] = useState(props.itemEditing == false ? 0 : props.itemEditing.id);
    const [enabled, setEnabled] = useState(true);
    const [label, setLabel] = useState('');
    const [note, setNote] = useState('');

    // ZOZNAM UZIVATELOV
    const [users, setUsers] = useState(false);
    const [usersFiltered, setUsersFiltered] = useState(false);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);

    // DIALOGS
    const [showUsers, setShowUsers] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= column1 + column2 + (4 * offset) ? column1 + column2 + (4 * offset) : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    let states1 = [{ id: 1, label: lang.permission_view }, { id: 2, label: lang.permission_create }, { id: 3, label: lang.permission_edit }, { id: 4, label: lang.permission_delete }]
    let states2 = [{ id: 1, label: lang.permission_view }, { id: 2, label: lang.permission_create }, { id: 3, label: lang.permission_edit }, { id: 4, label: lang.permission_delete }, { id: 5, label: lang.permission_export }]

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (props.itemEditing == false) {
                // NOVY ZAZNAM - DEFAULT VALUES
                let data = {
                    enabled: true,
                    label: '',
                    note: '',
                }

                AssignData(data);
                setItems(data);
            } else {
                // UPRAVA ZAZNAMU
                db_get();
            }


            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'users_group', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ---------------------------------------
                    group_id: props.itemEditing.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setItems(json.item);
                AssignData(json.item);

                setUsersFiltered(json.users);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    model_id: global.model.users_groups,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setRedraw(true);
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_users_list = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'users_groups_list_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    model_id: global.model.users_groups,
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setRedraw(true);
                setShowOK(true);
                db_get();
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const ShowErrorPress = () => {
        setShowError(false);
    }

    const ShowOKPress = () => {
        setShowOK(false);
        if (props.itemEditing == false) {
            Press(true, true);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(redraw, redraw);
    }

    const FormLabelResult = (value) => {
        if (value == 0) {
            setEditing(true);
        }
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(items);
        setEditing(false);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB - Zmena Byte To Array
        setEnabled(item.enabled == false ? false : true);
        setLabel(item.label);
        setNote(item.note);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti - Zmena Array To Byte        
        items.enabled = enabled;
        items.label = label;
        items.note = note;
    }

    const GetData = () => {
        // získanie údajov do reťazca
        let data = {
            id: id,
            organization_id: props.organization.id,
            user_id: props.user.id,
            enabled: enabled,
            label: label,
            note: note,
            priority: 1000
        }

        return data;
    }

    const Save = () => {
        setError('');

        if (label.trim() != '') {
            let data = GetData();

            StoreEditing();
            setEditing(false);

            db_update(data);
        } else {
            setError(lang.required_red);
        }
    }

    const UsersResult = (typ, item) => {
        setShowUsers(false);

        if (typ == true) {
            let data = {
                id: 0,
                organization_id: props.organization.id,
                enabled: true,
                user_group_id: props.itemEditing.id,
                user_id: item.id
            }

            db_users_list(data);
        }
    }

    const DeleteUser = (item) => {
        let data = {
            id: item.list_id,
            organization_id: 0,
            user_group_id: 0,
            user_id: 0,
            enabled: false
        }

        db_users_list(data);
    }

    const ChangeEnabled = (item, value) => {
        Debug(item);
        let data = {
            id: item.list_id,
            enabled: value,
        }
        db_users_list(data);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faUsers} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.itemEditing == false ? lang.users_group_new : lang.user_group_edit}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        {items != false ?
                            <div style={{ ...styles.BlockCenter, width: column1 + column2 + (2 * offset), paddingTop: offset, paddingBottom: offset * 2, backgroundColor: color.white }}>
                                <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.user_group_info} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                                <Line color={color.light_gray} />
                                <FormYesNo value={enabled} editing={editing} title={lang.user_group_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEnabled(txt)} />
                                <FormText require value={label} editing={editing} title={lang.user_group_label} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setLabel(txt)} />
                                <FormText value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />

                                {/* SAVE - CANCEL */}
                                {editing == true ?
                                    <div style={{ ...styles.Block, paddingTop: 10 }}>
                                        <FormError theme={props.theme} error={error} small />

                                        <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                                            </div>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.editing_finish}</Button>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                            : null}

                        {items != false && props.itemEditing != false && editing == false ?
                            <div style={{ ...styles.Block, width: column1 + column2 + (2 * offset) }}>
                                <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray, marginTop: 30 }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                        <p style={{ ...styles.TextSmall }}>{lang.users_group_list}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                        <ButtonNew label={lang.users_add} theme={props.theme} func={() => setShowUsers(true)} />
                                    </div>
                                </div>

                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    {usersFiltered != false ? usersFiltered.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? global.elevation_over : global.elevation}
                                            style={{
                                                ...styles.Block,
                                                backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                borderRadius: global.items_radius,
                                                minHeight: rowHeight,
                                                cursor: 'pointer',
                                                marginTop: 5, marginBottom: 5,
                                                paddingTop: 2, paddingBottom: 2,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '100%' }}>
                                                <div style={{ ...styles.Block, width: rowHeight + 10, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <Avatar image={item.avatar} size={rowHeight - 10} theme={props.theme} />
                                                </div>

                                                <div style={{ ...styles.BlockLeft, width: '42%', minHeight: rowHeight, justifyContent: 'center' }}>

                                                    {props.settings.name_format == 0 ?
                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name} {item.surname}</p>
                                                        :
                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.surname} {item.name}</p>
                                                    }
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.role}</p>
                                                </div>

                                                <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextXSmall }}>{item.email}</p>
                                                </div>

                                                <div style={{ ...styles.Block, width: 60, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <SwitchEnable enabled={item.enabled == false ? false : true} theme={props.theme} func={(value) => ChangeEnabled(item, value)} />
                                                </div>

                                                <div style={{ ...styles.Block, width: 50, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {item.id == overID ?
                                                        <Tooltip title={lang.delete}>
                                                            <IconButton onClick={() => DeleteUser(item)} style={{ ...styles.ButtonIcon }}>
                                                                <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        : null}
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <div style={{ ...styles.Block, paddingTop: 20 }}>
                                            <EmptyList row small lang={lang} />
                                        </div>
                                    }

                                </div>

                            </div>
                            : null}

                    </div >
                </div >

                {showUsers == true ?
                    <UsersSelect organization={props.organization} user={props.user} settings={props.settings} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.user_select} token={props.token} func={UsersResult.bind()} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}

