/*
*
* =================================================================================================================

    FILES - exportované súbory

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import './globals_conditions';
import { DeleteButton, DialogInfo, DialogTabs, EmptyList, FormError, FormNote, FormPermissions, FormSelect, FormSpace, FormText, FormYesNo, Icon, FormLabel, Loading, MenuButton, ShowError, ShowInfo, ShowOK, SubMenuButton, TextInput, WebURLPreview, DialogEmail, Line, TextLine } from './items';
import { styles } from './styles';
import { Backdrop, Button, Chip, CircularProgress, FormControlLabel, IconButton, MenuItem, Pagination, Paper, Radio, RadioGroup, Select, Tooltip, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faCopy, faDownload, faEye, faFile, faFileAlt, faFileImage, faFloppyDisk, faFolder, faFolderOpen, faInfoCircle, faLayerGroup, faPaperPlane, faPen, faPlus, faPlusCircle, faTrash, faUpload, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetGBFromBytes, GetLeftPosition, GetMBFromBytes, GetPages, IsEmpty, UniqueID } from './functions';
import { GetDatumTime2 } from './functions_date';

export const Files = (props) => {

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.window ? props.width : width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = props.window ? props.height : height >= global.dialog_max_height ? global.dialog_max_height : height;

    let color = global.themes[props.theme];
    let { func } = props;


    useEffect(() => {

        Debug('FILES PROPS 1');
        Debug(props);

        return () => {
        };

    }, [props.params]);

    const Close = () => {
        func(false);
    }


    const FilesContent = (props) => {

        var lang = props.lang;
        let radius = props.radius;
        let color = global.themes[props.theme];
        let permissions = global.permissions;
        let cloudEnabled = props.organization.cloud_enabled == true ? true : false;

        let defaultFilter = {
            ordering: global.order.up,
            order_column: 'created',
        };

        // IMAGES
        const no_image = require('./react/app/no_image.jpg');

        // SYSTEM
        const [isBusy, setBusy] = useState(false);
        const [redraw, setRedraw] = useState(false);
        const [error, setError] = useState('');
        const [overID, setOverID] = useState(-1);
        const [params, setParams] = useState(props.params);


        const [showOK, setShowOK] = useState(false);
        const [showError, setShowError] = useState(false);

        // TABS
        const [tabIndex, setTabIndex] = useState(props.init_type);


        // DATA LIST
        const [items, setItems] = useState(false);
        const [itemsFiltered, setItemsFiltered] = useState(false);
        const [itemSelected, setItemSelected] = useState([]);
        const [itemEditing, setItemEditing] = useState(false);
        const [page, setPage] = useState(0);
        const [pages, setPages] = useState(0);
        const [filter, setFilter] = useState(defaultFilter);
        const [search, setSearch] = useState('');

        // CLOUD
        const [cloudUsed, setCloudUsed] = useState(-1);
        const [cloudFree, setCloudFree] = useState(0);
        const [cloudPercentage, setCloudPercentage] = useState(0);
        const [showCloudFull, setShowCloudFull] = useState(false);

        // ORDERING
        const [ordering, setOrdering] = useState(global.order.up);
        const [orderingColumn, setOrderingColumn] = useState('created');

        // FOLDERS
        const [folders, setFolders] = useState(false);
        const [folderSelected, setFolderSelected] = useState(false);
        const [showFolder, setShowFolder] = useState(false);

        // UPLOAD
        const [showUpload, setShowUpload] = useState(false);

        // DETAIL SUBORU / NAHLAD
        const [showFile, setShowFile] = useState(false);
        const [showFileExport, setShowFileExport] = useState(false);
        const [showPreview, setShowPreview] = useState(false);
        const [previewLink, setPreviewLink] = useState('');

        // SCREEN SETTINGS
        const [width, setWidth] = useState(window.innerWidth);
        const [height, setHeight] = useState(window.innerHeight);

        const dialogWidth = props.window ? props.width : width >= global.dialog_max_width ? global.dialog_max_width : width;
        const dialogHeight = props.window ? props.height : height >= global.dialog_max_height ? global.dialog_max_height : height;

        const itemsOrdering = [
            { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
            { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
        ];

        const folderHeight = 40;
        const menuHeight = 60;
        const rowHeight = 70;
        const viewWidth = '96%';
        const menuWidth = global.sub_menu_width;
        const tableHeight = 40;
        const statusHeight = 40;

        let { func } = props;
        var running = false;

        useEffect(() => {
            if (running == false) {
                Debug('FILES PROPS 2');
                Debug(props);
                // UDAJE Z DB
                var filter = defaultFilter;
                if (props.init_type != 1) {
                    filter.folder = props.init_type;
                }
                if (cloudEnabled == false) {
                    filter.folder = 0;
                    setTabIndex(0);
                }
                db_folders();

                if (props.params != undefined) {
                    if (props.params != false) {
                        if (props.params.last == true || props.params.exports == true) {
                            setTabIndex(0);
                        }
                        if (props.params.filename != undefined) {
                            filter.search = props.params.filename;
                            setSearch(props.params.filename);
                        }
                        if (props.params.shared != undefined) {
                            filter.folder = 4;
                            filter.shared = true;
                            setTabIndex(4);
                        }
                        if (props.params.public != undefined) {
                            filter.folder = 5;
                            filter.public = true;
                            setTabIndex(5);
                        }
                        if (props.params.images != undefined) {
                            filter.folder = 2;
                            setTabIndex(2);
                        }
                    }
                }
                db_get(0, filter);

                running = true;
            }

        }, [props.params]);

        const db_get = async (page, filter_) => {
            setBusy(true);
            Debug('filter', 'cyan');
            Debug(filter_);
            try {
                const response = await fetch(
                    global.db_url + 'files', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: props.token,
                        user_id: props.user.id,
                        organization_id: props.organization.id,
                        // -------------------------------------

                        start: page * global.items_max,
                        length: global.items_max,
                        filter: filter_
                    })
                })

                const json = await response.json();
                Debug(json);
                if (json.ok > 0) {
                    var tmp = json.items;
                    setItems(tmp);
                    setItemsFiltered(tmp);
                    SetCloudInfo(json.cloud_used);

                    setPage(page);
                    setPages(GetPages(json.count, global.items_max));

                    setFilter(filter_);
                    GoToStart();

                    if (params != undefined) {
                        if (params != false) {
                            if (params.add_new != undefined) {
                                UploadFile(json.cloud_used);
                            }
                        }
                    }
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }

        const db_folders = async () => {
            //setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'file_folders', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: props.token,
                        user_id: props.user.id,
                        organization_id: props.organization.id,
                        language: props.language
                    })
                })

                const json = await response.json();
                if (json.ok > 0) {
                    var tmp = json.items;
                    setFolders(tmp);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }

        const db_update = async (data) => {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'db_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: props.token,
                        user_id: props.user.id,
                        organization_id: props.organization.id,
                        // -------------------------------------

                        model_id: global.model.exported_data,
                        data: data
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    setShowOK(true);
                } else {
                    setShowError(true);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }

        const db_update_preview = async (data) => {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'db_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: props.token,
                        user_id: props.user.id,
                        organization_id: props.organization.id,
                        // -------------------------------------
                        model_id: global.model.file,
                        data: data
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }

        const db_delete = async (id) => {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'file_delete', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: props.token,
                        user_id: props.user.id,
                        organization_id: props.organization.id,
                        // -------------------------------------
                        id: id
                    })
                })

                const json = await response.json();
                Debug(json);
                if (json.ok > 0) {
                    db_get(page, filter);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }

        const Press = (typ, value) => {
            func(typ, value);
        }

        const SetCloudInfo = (cloud_used) => {
            // ULOZISKO - CLOUD - VYPOCET VOLNEHO MIESTA
            setCloudUsed(cloud_used);
            var free = parseInt(props.organization.cloud_size) - parseInt(cloud_used);
            setCloudFree(free < 0 ? 0 : free);
            setCloudPercentage(100 - Math.round((free / props.organization.cloud_size) * 100));
        }

        const GetFilter = () => {
            var filter_ = defaultFilter;
            if (tabIndex != 1) {
                filter_.folder = tabIndex;
            }
            if (search != '') {
                filter_.search = search;
            }
            filter_.ordering = ordering;
            filter_.order_column = orderingColumn;

            if (tabIndex == 4) {
                // ZDIELANE
                filter_.shared = true;
            }
            if (tabIndex == 5) {
                // VEREJNE
                filter_.public = true;
            }

            return filter_;
        }

        const MenuSortResult = (id, index) => {
            let order = index == 1 ? global.order.up : global.order.down;
            var column = 'filename';
            var filter_ = GetFilter();

            switch (id) {

                case 1: // NAZOV SUBORU
                    column = 'filename';
                    break;

                case 2: // VYTVORENE
                    column = 'created';
                    break;

            }

            filter_.ordering = order;
            filter_.order_column = column;
            setOrdering(order);
            setOrderingColumn(column);

            db_get(0, filter_);
        }

        const Search = (txt) => {
            setSearch(txt);
            var filter_ = GetFilter();
            delete (filter_.search);

            if (txt.length > 1) {
                filter_.search = txt;
                db_get(0, filter_);
            }
            if (txt == '') {
                db_get(0, filter_);
            }
        }

        const ChangePage = (value) => {
            db_get(value - 1, filter);
        }

        const GoToStart = () => {
            setTimeout(() => {
                const element = document.getElementById('id_files_start');

                if (element != null) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }

        const Download = (item) => {
            let data = {
                id: item.id,
                downloaded: true
            }
            item.downloaded = true;
            db_update(data);
        }

        const ChangeTab = (id) => {
            setTabIndex(id);
            setSearch('');

            var filter = GetFilter();
            delete (filter.public);
            delete (filter.shared);
            delete (filter.search);

            if (id != 1) {
                filter.folder = id;
                if (id == 4) {
                    // ZDIELANE                
                    filter.shared = true;
                }
                if (id == 5) {
                    // VEREJNE                
                    filter.public = true;
                }
            } else {
                delete (filter.folder);
            }
            db_get(0, filter);

        }

        const NewFolder = () => {
            setFolderSelected(false);
            setShowFolder(true);
        }

        const EditFolder = () => {
            setFolderSelected({ id: tabIndex });
            setShowFolder(true);
        }

        const FolderResult = (redraw_, deleted) => {
            setShowFolder(false);

            if (deleted == true) {
                setTabIndex(1);
                var filter_ = filter;
                delete (filter_.folder);
                db_get(0, filter_);
            }
            if (redraw_ == true) {
                db_folders();
            }
        }

        const UploadFile = (cloud_free) => {
            if (cloud_free > 0) {
                setShowUpload(true);
            } else {
                setShowCloudFull(true);
            }
        }

        const UploadResult = (redraw_) => {
            setShowUpload(false);
            setParams(false);

            if (redraw_ == true) {
                db_get(page, filter);
            }
        }

        const SelectItem = (item) => {
            setItemSelected(item);
            setShowFile(true);
        }

        const SelectItemExported = (item) => {
            setItemSelected(item);
            setShowFileExport(true);
        }

        const FileResult = (redraw) => {
            setShowFile(false);

            if (redraw == true) {
                db_get(page, filter);
            }
        }

        const FileExportResult = (redraw) => {
            setShowFileExport(false);

            if (redraw == true) {
            }
        }

        const PreviewFile = (item) => {
            if (global.supported_types.includes(item.extension)) {

                // POZRETIE + 1
                let viewed = parseInt(item.viewed) + 1;
                let data = {
                    id: item.id,
                    viewed: viewed
                }
                item.viewed = viewed;
                db_update_preview(data);

                // ZOBRAZENIE SUBORU
                setPreviewLink(global.web + '/' + item.link);
                setShowPreview(true);
            }
        }

        const DeleteFile = (item) => {
            if (item.id > 0) {
                db_delete(item.id);
            }
        }

        return (

            <>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, backgroundColor: color.back }}>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.BlockCenter, background: color.sub_menu_back, width: menuWidth, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <FormSpace height={10} />
                                {cloudEnabled == true ?
                                    <div style={{ ...styles.BlockRow, borderBottom: '1px solid #00000040', paddingBottom: 5 }}>
                                        <div style={{ ...styles.BlockRight, width: 30, height: folderHeight }}>
                                            <FontAwesomeIcon style={{ height: 14, color: '#00000080' }} icon={faFolderOpen} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: menuWidth - 110, height: folderHeight }}>
                                            <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{lang.files_folders}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 40, height: folderHeight }}>
                                            {tabIndex > 5 ?
                                                <IconButton onClick={() => EditFolder()} style={{ ...styles.ButtonIcon }}>
                                                    <FontAwesomeIcon style={{ width: 10 }} icon={faPen} />
                                                </IconButton>
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 40, height: folderHeight }}>
                                            <Tooltip title={lang.files_folder_new}>
                                                <IconButton onClick={() => NewFolder()} style={{ ...styles.ButtonIcon }}>
                                                    <FontAwesomeIcon style={{ width: 10 }} icon={faPlus} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    : null}
                                <FormSpace height={10} />
                                <SubMenuButton id={0} selected_id={tabIndex} width={menuWidth} label={lang.files_downloads} theme={props.theme} func={ChangeTab.bind(this)} />
                                {cloudEnabled == true ?
                                    <div>
                                        <FormSpace height={26} />
                                        <SubMenuButton id={1} selected_id={tabIndex} width={menuWidth} label={lang.files_all} theme={props.theme} func={ChangeTab.bind(this)} />
                                        <SubMenuButton id={2} selected_id={tabIndex} width={menuWidth} label={lang.files_images} theme={props.theme} func={ChangeTab.bind(this)} />
                                        <SubMenuButton id={3} selected_id={tabIndex} width={menuWidth} label={lang.files_documents} theme={props.theme} func={ChangeTab.bind(this)} />
                                        <SubMenuButton id={4} selected_id={tabIndex} width={menuWidth} label={lang.files_shared} theme={props.theme} func={ChangeTab.bind(this)} />
                                        <SubMenuButton id={5} selected_id={tabIndex} width={menuWidth} label={lang.files_public} theme={props.theme} func={ChangeTab.bind(this)} />
                                        {folders != false ? folders.map((item, index) => (
                                            <SubMenuButton key={index} id={item.id} selected_id={tabIndex} width={menuWidth} label={item.name} theme={props.theme} func={ChangeTab.bind(this)} />
                                        )) : null}
                                    </div>
                                    : null}

                            </div>
                            <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - global.dialog_bar_height, borderBottomRightRadius: props.radius }}>

                                <div style={{ ...styles.BlockRow, width: viewWidth }}>
                                    <div style={{ ...styles.BlockLeft, width: '40%', height: menuHeight }}>
                                        <TextInput redraw search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '40%', height: menuHeight }}>
                                        {tabIndex == 0 ?
                                            <p style={{ ...styles.TextTiny }}>{lang.export_deleting.replace('@days', props.system.export_delete_days)}</p>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: 200, height: menuHeight }}>
                                        {cloudEnabled == true ?
                                            <Button onClick={() => UploadFile(cloudFree)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 10 }} icon={faUpload} />
                                                {lang.files_upload}
                                            </Button>
                                            : null}
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - global.dialog_bar_height - menuHeight - 1, borderBottomRightRadius: props.radius }} >

                                    {tabIndex > 0 ?
                                        <div style={{ ...styles.Block, border: color.border, width: dialogWidth - menuWidth - 40, height: tableHeight }}>
                                            <div style={{ ...styles.BlockRow }}>
                                                <div style={{ ...styles.Block, width: rowHeight + 8, height: tableHeight, justifyContent: 'center' }}>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: dialogWidth - menuWidth - 40 - rowHeight + 8 - 170 - 170 - 150, height: tableHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, height: tableHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.file_filename}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableHeight, justifyContent: 'center' }}>
                                                        <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: 170, height: tableHeight, borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.files_folder}</p>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: 170, height: tableHeight, borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.user}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: 150, height: tableHeight, borderLeft: color.border }}>
                                                    <div style={{ ...styles.BlockLeft, height: tableHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.created}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 30, height: tableHeight, justifyContent: 'center' }}>
                                                        <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: 80, height: tableHeight, borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.delete}</p>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div style={{ ...styles.BlockLeft, border: color.border, width: dialogWidth - menuWidth - 40, height: tableHeight }}>
                                            <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.files_exported}</p>
                                        </div>
                                    }

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - menuHeight - tableHeight - statusHeight, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div id={'id_files_start'} style={{ ...styles.Block }}></div>
                                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    width: dialogWidth - menuWidth - 40,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                {tabIndex == 0 ?
                                                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                        <div onClick={() => SelectItemExported(item)} style={{ ...styles.BlockLeft, width: '15%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                            <p style={{ ...styles.TextTiny }}>{GetDatumTime2(item.created)}</p>
                                                        </div>
                                                        <div onClick={() => SelectItemExported(item)} style={{ ...styles.BlockLeft, width: '50%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                            <TextLine text={item.filename} fontSize={global.font_tiny} fontWeight={'600'} color={color.black} />
                                                            <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginTop: 2 }}>{item.note}</p>
                                                        </div>
                                                        <div onClick={() => SelectItemExported(item)} style={{ ...styles.Block, width: '15%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                            {item.downloaded == true ?
                                                                <Chip size={'small'} variant='outlined' style={{ fontSize: global.font_xtiny, color: color.darker_green, borderColor: color.darker_green }} label={lang.downloaded} />
                                                                : null}
                                                        </div>
                                                        <div style={{ ...styles.Block, width: '20%', minHeight: rowHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                            <a href={props.remote == true ? global.web + '/' + item.link + '@in_safari' : global.web + '/' + item.link} onClick={() => Download(item)} download={item.filename} target="_blank" style={{ ...styles.BlockRowRaw, textDecoration: 'none' }}>
                                                                <FontAwesomeIcon style={{ height: 14, color: item.id == overID ? color.dark_blue : color.middle_gray, marginRight: 10 }} icon={faDownload} />
                                                                <p style={{ ...styles.TextTiny, color: item.id == overID ? color.dark_blue : color.middle_gray }}>{lang.download}</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.BlockRow }}>
                                                        <div onClick={() => PreviewFile(item)} style={{ ...styles.Block, width: rowHeight + 8, minHeight: rowHeight, justifyContent: 'center' }}>
                                                            {global.supported_images.includes(item.extension) && item.size < 5000000 ?
                                                                <img src={global.web + '/' + item.link} style={{ width: '100%', height: '100%', maxWidth: rowHeight - 10, maxHeight: rowHeight - 10, objectFit: 'contain' }}></img>
                                                                :
                                                                <FontAwesomeIcon style={{ height: 20, color: color.xxgray }} icon={faFileAlt} />
                                                            }
                                                        </div>
                                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: dialogWidth - menuWidth - 40 - rowHeight + 8 - 170 - 170 - 150, minHeight: rowHeight, justifyContent: 'center', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                            <TextLine text={item.filename} fontSize={global.font_tiny} fontWeight={'600'} color={color.black} />
                                                        </div>
                                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: 170, minHeight: rowHeight, justifyContent: 'center', overflow: 'hidden' }}>
                                                            <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 5 }}>{item.folder}</p>
                                                        </div>
                                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: 170, minHeight: rowHeight, justifyContent: 'center', overflow: 'hidden' }}>
                                                            <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.user}</p>
                                                        </div>
                                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: 150, minHeight: rowHeight, justifyContent: 'center', overflow: 'hidden' }}>
                                                            <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{GetDatumTime2(item.created)}</p>
                                                        </div>
                                                        <div style={{ ...styles.Block, width: 80, minHeight: rowHeight, justifyContent: 'center' }}>
                                                            {props.user.id == item.user_id ?
                                                                <DeleteButton ask text={lang.file_delete_ask} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteFile(item)} />
                                                                : null}
                                                        </div>
                                                    </div>
                                                }
                                            </Paper>
                                        )) :
                                            <div style={{ ...styles.Block, paddingTop: 30 }}>
                                                <EmptyList row small lang={lang} />
                                            </div>
                                        }

                                        {/* PAGINATION */}
                                        {pages > 1 ?
                                            <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            </div>
                                            : null}
                                    </div>

                                    <div style={{ ...styles.Block, height: statusHeight - 1, borderTop: color.border }}>
                                        {cloudEnabled == true ? cloudUsed > -1 ?
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.Block, position: 'relative', width: statusHeight - 4, height: statusHeight }}>
                                                    <CircularProgress size={statusHeight - 4} variant="determinate" value={100} style={{ color: color.light_gray }} />
                                                    <div style={{ ...styles.Block, position: 'absolute', top: 0, left: 0, width: statusHeight - 4, height: statusHeight }}>
                                                        <CircularProgress size={statusHeight - 4} variant="determinate" value={cloudPercentage > 100 ? 100 : cloudPercentage} style={{ color: color.blue }} />
                                                    </div>
                                                    <div style={{ ...styles.Block, position: 'absolute', top: 0, left: 0, width: statusHeight - 4, height: statusHeight }}>
                                                        <p style={{ ...styles.TextXXTiny }}>{Math.round(cloudPercentage)}%</p>
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.Block, width: undefined, height: statusHeight }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.file_cloud_space}: {GetMBFromBytes(cloudUsed)}/{GetMBFromBytes(props.organization.cloud_size)} MB<span style={{ marginLeft: 40 }}>{lang.file_cloud_free_space}: {GetMBFromBytes(cloudFree)} MB</span></p>
                                                </div>
                                            </div>
                                            : null : null}
                                    </div>
                                </div >
                            </div>
                        </div >
                    </div >
                </div >

                {showCloudFull == true ?
                    <ShowInfo remote={props.remote} remoteMobile={props.remoteMobile} text={lang.file_cloud_full} sub_text={lang.file_cloud_full_note} lang={lang} theme={props.theme} icon={faXmark} func={() => setShowCloudFull(false)} />
                    : null}

                {showPreview == true ?
                    <WebURLPreview remote={props.remote} remoteMobile={props.remoteMobile} url={previewLink} theme={props.theme} title={lang.file_preview} lang={props.lang} func={() => setShowPreview(false)} />
                    : null}

                {showUpload == true ?
                    <FilesUpload remote={props.remote} remoteMobile={props.remoteMobile} freeSpace={cloudFree} folder_id={tabIndex} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={UploadResult.bind()} />
                    : null}

                {showFile == true ?
                    <FileDetails remote={props.remote} remoteMobile={props.remoteMobile} item={itemSelected} organization={props.organization} settings={props.settings} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={FileResult.bind()} />
                    : null}

                {showFileExport == true ?
                    <FileExportDetails remote={props.remote} remoteMobile={props.remoteMobile} item={itemSelected} organization={props.organization} settings={props.settings} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={FileExportResult.bind()} />
                    : null}

                {showFolder == true ?
                    <FilesFolder remote={props.remote} remoteMobile={props.remoteMobile} item={folderSelected} organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={FolderResult.bind()} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}
            </>

        );
    }

    return (
        props.window == true ?
            <FilesContent {...props} />
            :
            <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 30000 }}>

                <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                    <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                        <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                            <Icon theme={props.theme} icon={faFileAlt} />
                        </div>
                        <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        </div>
                        <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                            <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                                <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>

                    <FilesContent {...props} />

                </Paper >
            </Backdrop >
    );
}


/*
*
* =================================================================================================================
* NOVY ADRESAR / UPRAVA ADRESARA
* =================================================================================================================
*
*/
export const FilesFolder = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 270 ? 270 : height;

    const column1 = 140;
    const column2 = dialogWidth - column1 - 40;


    const buttonHeight = 70;
    const errorHeight = 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            Debug('props.item', 'red');
            Debug(props.item);
            if (props.item != false) {
                // UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'file_folder', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id
                })
            })

            const json = await response.json();
            Debug('json', 'green');
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    setEnabled(json.item.enabled == false ? false : true);
                    setName(json.item.name);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.file_folders,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                func(true, false);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_delete = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'file_folder_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                func(true, true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false, false);
    }

    const Save = () => {
        var err = 0;
        err += IsEmpty(name) == true ? 1 : 0;

        if (err == 0) {
            let data = {
                id: props.item == false ? 0 : props.item.id,
                organization_id: props.organization.id,
                user_id: props.user.id,
                //enabled: enabled,
                name: name
            }

            db_update(data);
        } else {
            setError(lang.required_red);
        }
    }

    const DeleteFolder = () => {
        db_delete();
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faFolderOpen} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 180, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.files_folder_new_ : lang.files_folder}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        {props.item != false ?
                            <DeleteButton ask bold icon={faTrash} backgroundColor={color.white} text={lang.file_folder_delete} text_note={lang.file_folder_delete_note} tooltip={lang.delete} theme={props.theme} lang={props.lang} func={() => DeleteFolder()} />
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        <FormSpace />
                        {/*
                        <FormYesNo value={enabled} editing={true} title={lang.files_folder_enabled} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                        */}
                        <FormText require redraw value={name} editing={true} title={lang.files_folder_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />

                    </div>
                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 10 }}>{lang.save}</Button>
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, marginLeft: 10 }}>{lang.cancel}</Button>
                        </div>
                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => func(true)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


/*
*
* =================================================================================================================
* VYBER SUBOROV NA NAHRATIE NA SERVER
* =================================================================================================================
*
*/
export const FilesUpload = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [folderID, setFolderID] = useState(props.folder_id < 2 ? 3 : props.folder_id);
    const [folders, setFolders] = useState(false);
    const [files, setFiles] = useState([]);

    // PREKROCENA MAX.VELKOST SUBORU
    const [showFileSizeError, setShowFileSizeError] = useState(false);
    const [showSpaceError, setShowSpaceError] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 600 ? 600 : height;

    const menuWidth = global.sub_menu_width;
    const buttonHeight = 70;
    const rowHeight = 40;
    const barHeight = 50;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            db_folders();
            running = true;
        }

    }, []);

    const db_folders = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'file_folders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                var tmp = json.items;
                setFolders(tmp);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_upload = async () => {

        setBusy(true);

        const data = new FormData();

        var n = 0;
        [...files].forEach(item => {
            data.append("file_" + n, item);
            n++;
        });

        data.append("token", props.token);
        data.append("user_id", props.user.id);
        data.append("organization_id", props.organization.id);
        data.append("folder_id", folderID);
        data.append("file_count", files.length);

        try {
            const response = await fetch(
                global.db_url + 'files_upload', {
                method: 'POST',
                body: data
            })

            const json = await response.json();

            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const ChangeFolder = (id) => {
        setFolderID(id);
    }


    function SelectFiles(e) {
        // VYBER SUBOROV A ODOSLANIE NA SERVER           
        var selected = e.target.files;
        var err = false;
        var space_err = false;
        var size = 0;
        [...selected].forEach(file => {

            if (file.size <= parseInt(props.organization.cloud_file_size)) {
                size += file.size;

                if (parseInt(props.freeSpace) - size > 0) {
                    file.id = UniqueID();
                    files.push(file);
                } else {
                    space_err = true;
                }
            } else {
                err = true;
            }
        });

        if (err == true) {
            setShowFileSizeError(true);
        }
        if (space_err == true) {
            setShowSpaceError(true);
        }

        setRedraw(!redraw);
    }

    const DeleteFile = (id) => {
        var tmp = files.filter(x => x.id != id);
        setFiles(tmp);
        setRedraw(!redraw);
    }


    const Process = () => {
        db_upload();
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faUpload} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.files_upload_title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - global.dialog_bar_height - buttonHeight, background: color.sub_menu_back }}>
                            <div style={{ ...styles.Block, width: menuWidth, height: barHeight }}>
                                <p style={{ ...styles.TextTiny }}>{lang.files_upload_folder}</p>
                            </div>
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - global.dialog_bar_height - buttonHeight - 40, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <SubMenuButton id={2} selected_id={folderID} width={menuWidth} label={lang.files_images} theme={props.theme} func={ChangeFolder.bind(this)} />
                                <SubMenuButton id={3} selected_id={folderID} width={menuWidth} label={lang.files_documents} theme={props.theme} func={ChangeFolder.bind(this)} />
                                {folders != false ? folders.map((item, index) => (
                                    <SubMenuButton key={index} id={item.id} selected_id={folderID} width={menuWidth} label={item.name} theme={props.theme} func={ChangeFolder.bind(this)} />
                                )) : null}
                            </div>

                        </div>
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, width: dialogWidth - menuWidth }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: dialogWidth - menuWidth - 200, height: barHeight }}>
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, fontWeight: '600', marginLeft: 10 }}>{lang.file_list}</p>
                                </div>
                                <div style={{ ...styles.Block, width: 200, height: barHeight }}>
                                    <Button variant="contained" component="label" style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 10 }} icon={faPlus} />
                                        {lang.files_select}
                                        <input onInput={(e) => SelectFiles(e)} multiple hidden type="file" />
                                    </Button>
                                </div>
                            </div>
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - barHeight, width: dialogWidth - menuWidth }} >
                                <div style={{ ...styles.BlockRow, width: dialogWidth - menuWidth - 20, borderBottom: color.border, height: rowHeight }}>
                                    <div style={{ ...styles.BlockLeft, width: dialogWidth - menuWidth - 20 - 80, height: rowHeight }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.file_filename}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 80, height: rowHeight }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.delete}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - barHeight - rowHeight, width: dialogWidth - menuWidth, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    {files.map((item, index) => (
                                        <div key={index} style={{ ...styles.BlockRow, width: dialogWidth - menuWidth - 20, borderBottom: color.border, height: rowHeight, marginTop: 3, marginBottom: 3 }}>
                                            <div style={{ ...styles.BlockLeft, width: dialogWidth - menuWidth - 20 - 80 - 100, height: rowHeight }}>
                                                <p style={{ ...styles.TextXSmall }}>{item.name}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 100, height: rowHeight }}>
                                                <p style={{ ...styles.TextTiny }}>{GetMBFromBytes(item.size)} MB</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 80, height: rowHeight }}>
                                                <IconButton onClick={() => DeleteFile(item.id)} style={{ ...styles.ButtonIcon, width: 26, height: 26 }}>
                                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight - 1, borderTop: color.border }}>
                        {files.length > 0 ?
                            <Button onClick={() => Process()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_dark, width: 240 }}>{lang.files_upload_start}</Button>
                            : null}
                    </div>
                </div>

                {showSpaceError == true ?
                    <ShowInfo text={lang.file_cloud_not_enoght_space} sub_text={lang.file_cloud_full_note} lang={lang} theme={props.theme} icon={faXmark} func={() => setShowSpaceError(false)} />
                    : null}

                {showFileSizeError == true ?
                    <ShowInfo text={lang.file_size_error} sub_text={lang.file_size_error_note.replace('@size', GetMBFromBytes(props.organization.cloud_file_size))} lang={lang} theme={props.theme} icon={faXmark} func={() => setShowFileSizeError(false)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => func(true)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}


/*
*
* =================================================================================================================
* DETAILY O SUBORE
* =================================================================================================================
*
*/
export const FileDetails = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [needSave, setNeedSave] = useState(false);

    // DATA
    const [enabled, setEnabled] = useState(true);
    const [filename, setFilename] = useState('');
    const [folders, setFolders] = useState(false);
    const [folderID, setFolderID] = useState('');
    const [note, setNote] = useState('');
    const [link, setLink] = useState('');
    const [file, setFile] = useState('');
    const [size, setSize] = useState('');
    const [viewed, setViewed] = useState('0');
    const [extension, setExtension] = useState('');
    const [downloaded, setDownloaded] = useState('');
    const [publicFile, setPublicFile] = useState(false);
    const [shared, setShared] = useState([]);
    const [ownerID, setOwnerID] = useState(0);


    // ZOZNAM USIVATELOV
    const [users, setUsers] = useState(false);

    const [showCopied, setShowCopied] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showPreviewButton, setShowPreviewButton] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);
    const tabs = [
        { id: 0, enabled: true, label: lang.file },
        { id: 1, enabled: true, label: lang.files_sharing },
    ];

    let editing = parseInt(props.item.user_id) == parseInt(props.user.id) ? true : false;

    // ODOSLAT EMAILOM 
    const [showEmail, setShowEmail] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 720 ? 720 : width;
    const dialogHeight = height >= 600 ? 600 : height;

    const column1 = 140;
    const column2 = dialogWidth - column1 - 40;

    const buttonHeight = 70;
    const errorHeight = 40;
    let tabHeight = 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            if (props.item != false) {
                // UDAJE Z DB
                db_folders();
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'file', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id
                })
            })

            const json = await response.json();
            Debug('json', 'green');
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    setEnabled(json.item.enabled == false ? false : true);
                    setFolderID(json.item.folder_id);
                    setFilename(json.item.filename);
                    setLink(global.web + '/' + json.item.link);
                    setFile(json.item.link);
                    setNote(json.item.note);
                    setSize(json.item.size);
                    setViewed(json.item.viewed);
                    setDownloaded(json.item.downloaded);
                    setPublicFile(json.item.public == false ? false : true);
                    setShared(json.item.shared == false ? [] : JSON.parse(json.item.shared));
                    setOwnerID(json.item.user_id);

                    setUsers(json.users);

                    let ext = json.item.link.substring(json.item.link.lastIndexOf(".")).replace('.', '').toLowerCase();
                    setExtension(ext);

                    if (global.supported_types.includes(ext)) {
                        setShowPreviewButton(true);
                    } else {
                        setShowPreviewButton(false);
                    }

                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_folders = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'file_folders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    language: props.language
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                var tmp = json.items;
                var folders_ = [];
                folders_.push({ id: 2, name: lang.files_images });
                folders_.push({ id: 3, name: lang.files_documents });
                if (tmp != false) {
                    tmp.forEach(item => {
                        folders_.push(item);
                    });
                }
                setFolders(folders_);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.file,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update_preview = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.file,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
            }

        } catch (error) {
            console.log(error);
        }
    }

    const Close = () => {
        func(false);
    }

    const Save = () => {
        var err = 0;
        err += IsEmpty(filename) == true ? 1 : 0;

        var shared_ = shared;
        if (shared.length > 0) {
            if (!shared.includes(props.user.id)) {
                shared.push(props.user.id);
            }
            shared_ = JSON.stringify(shared);
        } else {
            shared_ = false;
        }

        if (err == 0) {
            let data = {
                id: props.item == false ? 0 : props.item.id,
                folder_id: folderID,
                filename: filename,
                note: note,
                shared: publicFile == true ? false : shared_,
                public: publicFile
            }
            db_update(data);
        } else {
            setError(lang.required_red);
        }
    }

    const GetSize = (value) => {
        return parseFloat(value / (1024 * 1024)).toFixed(2);
    }

    const LinkCopy = () => {
        navigator.clipboard.writeText(link);
        setShowCopied(true);
        var timer = setTimeout(() => {
            setShowCopied(false);
        }, 2000);
    }


    const Preview = () => {

        // POZRETIE + 1
        let viewed_ = parseInt(viewed) + 1;
        let data = {
            id: props.item.id,
            viewed: viewed_
        }
        setViewed(viewed_);
        db_update_preview(data);

        setShowPreview(true);
    }


    const Download = () => {
        // DOWNLOAD + 1
        let downloaded_ = parseInt(downloaded) + 1;
        let data = {
            id: props.item.id,
            downloaded: downloaded_
        }
        setDownloaded(downloaded_);
        db_update_preview(data);
    }

    const ChangeTab = (id) => {
        setTabIndex(id);
    }

    const SendResult = () => {
        setShowEmail(false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faFileAlt} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.file}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, height: tabHeight }}>
                        <DialogTabs value={tabIndex} items={tabs} width={dialogWidth / 2} theme={props.theme} func={(id) => ChangeTab(id)} />
                    </div>
                    {tabIndex == 0 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <FormSpace />
                            <FormSelect require value={folderID} items={folders} field={'name'} editing={editing} title={lang.files_folder} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setFolderID(id); setNeedSave(true) }} />
                            <FormText require redraw value={filename} editing={editing} title={lang.file_filename} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setFilename(txt); setNeedSave(true) }} />
                            <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => { setNote(txt); setNeedSave(true) }} />

                            <FormSpace />
                            <FormText no_border redraw value={GetSize(size) + ' MB'} editing={false} title={lang.file_size} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSize(txt)} />
                            <FormText no_border redraw value={viewed} editing={false} title={lang.file_viewed} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSize(txt)} />
                            <FormText no_border redraw value={downloaded} editing={false} title={lang.file_downloaded} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSize(txt)} />
                            <div style={{ ...styles.Block, width: '98%', paddingTop: 10 }}>
                                <Line theme={props.theme}></Line>
                                <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 10 }}>{lang.file_link}</p>
                                <p style={{ ...styles.TextTiny, textAlign: 'center', color: color.dark_gray }}>{link}</p>
                                <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                    <div style={{ ...styles.Block, width: 180 }}>
                                        <Button onClick={() => LinkCopy()} style={{ ...styles.ButtonOutlined }}>
                                            <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faCopy} />
                                            {lang.files_copy}
                                        </Button>
                                    </div>
                                    <div style={{ ...styles.Block, width: 180 }}>
                                        <a href={props.remote == true ? link + '@in_safari' : link} onClick={() => Download()} download={filename} target="_blank" style={{ ...styles.ButtonOutlined, textDecoration: 'none' }}>
                                            <div style={{ ...styles.Block, height: 34 }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faDownload} />
                                                    {lang.download}
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                    {showPreviewButton == true ?
                                        <div style={{ ...styles.Block, width: 180 }}>
                                            <Button onClick={() => Preview()} style={{ ...styles.ButtonOutlined }}>
                                                <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faEye} />
                                                {lang.file_preview}
                                            </Button>
                                        </div>
                                        : null}
                                </div>
                                {showCopied == true ?
                                    <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.files_copied}</p>
                                    : null}
                            </div>
                        </div>
                        : null}


                    {tabIndex == 1 ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {editing == true ?
                                <div style={{ ...styles.Block }}>
                                    <FormSpace />
                                    <FormLabel editing={false} editButton={false} icon={faInfoCircle} title={lang.files_sharing_label} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />

                                    <FormYesNo value={publicFile} editing={true} title={lang.files_public} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => { setPublicFile(value); setNeedSave(true) }} />
                                    {publicFile == true ?
                                        <FormNote title={lang.files_public_note} column1={column1} column2={column2} marginTop={5} theme={props.theme} lang={lang} radius={props.radius} />
                                        : null}
                                    <FormSpace />
                                    {publicFile == false ?
                                        <div>
                                            <FormPermissions value={shared} items={users} field={'name_surname'} editing={true} title={lang.files_shared} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setShared(id); setNeedSave(true) }} />
                                            <FormNote title={lang.files_shared_note} column1={column1} column2={column2} marginTop={5} theme={props.theme} lang={lang} radius={props.radius} />
                                        </div>
                                        : null}
                                </div>
                                : null}
                            <div style={{ ...styles.Block, paddingTop: 20 }}>
                                <Line theme={props.theme} />
                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    <Button onClick={() => setShowEmail(true)} style={{ ...styles.ButtonOutlined, width: 220 }}>{lang.file_email_send}</Button>
                                </div>
                            </div>
                        </div>
                        : null}


                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                        {editing == false ?
                            <p style={{ ...styles.TextTiny }}>{lang.files_share_permissions}</p>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {editing == true && needSave == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save_changes}</Button>
                                : null}
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                        </div>
                    </div>
                </div >

                {showEmail == true ?
                    <DialogEmail attachment={file} organization={props.organization} settings={props.settings} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.file_email_send_label} token={props.token} func={SendResult.bind()} />
                    : null}

                {showPreview == true ?
                    <WebURLPreview url={link} theme={props.theme} title={lang.file_preview} lang={props.lang} func={() => setShowPreview(false)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => func(true)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


/*
*
* =================================================================================================================
* VYBER SUBORU Z KNIZNICE

    <FilesUpload organization={props.organization} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={UploadResult.bind()} />

* =================================================================================================================
*
*/
export const FileSelect = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;
    let cloudEnabled = props.organization.cloud_enabled == true ? true : false;

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'created',
    };

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(props.init_type);


    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // CLOUD
    const [cloudUsed, setCloudUsed] = useState(-1);
    const [cloudFree, setCloudFree] = useState(0);
    const [cloudPercentage, setCloudPercentage] = useState(0);
    const [showCloudFull, setShowCloudFull] = useState(false);

    // ORDERING
    const [ordering, setOrdering] = useState('asc');
    const [orderingColumn, setOrderingColumn] = useState('created');

    // FOLDERS
    const [folders, setFolders] = useState(false);
    const [folderSelected, setFolderSelected] = useState(false);
    const [showFolder, setShowFolder] = useState(false);

    // UPLOAD
    const [showUpload, setShowUpload] = useState(false);

    // DETAIL SUBORU / NAHLAD
    const [showFile, setShowFile] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [previewLink, setPreviewLink] = useState('');

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= global.dialog_max_width ? global.dialog_max_width : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    const itemsOrdering = [
        { id: 1, enabled: true, label: lang.ordering, icon: faArrowUp },
        { id: 2, enabled: true, label: lang.ordering, icon: faArrowDown }
    ];

    const folderHeight = 40;
    const menuHeight = 60;
    const rowHeight = 70;
    const viewWidth = '96%';
    const menuWidth = global.sub_menu_width;
    const tableHeight = 40;
    const statusHeight = 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            var filter = defaultFilter;
            if (props.init_type != 1) {
                filter.folder = props.init_type;
            }
            if (cloudEnabled == false) {
                filter.folder = 0;
                setTabIndex(0);
            }
            db_folders();
            db_get(0, filter);

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        Debug('filter', 'cyan');
        Debug(filter_);
        try {
            const response = await fetch(
                global.db_url + 'files', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);
                SetCloudInfo(json.cloud_used);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter_);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_folders = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'file_folders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    language: props.language
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                var tmp = json.items;
                setFolders(tmp);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update_preview = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.file,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const SetCloudInfo = (cloud_used) => {
        // ULOZISKO - CLOUD - VYPOCET VOLNEHO MIESTA
        setCloudUsed(cloud_used);
        var free = parseInt(props.organization.cloud_size) - parseInt(cloud_used);
        setCloudFree(free < 0 ? 0 : free);
        setCloudPercentage(100 - Math.round((free / props.organization.cloud_size) * 100));
    }

    const GetFilter = () => {
        var filter_ = defaultFilter;
        if (tabIndex != 1) {
            filter_.folder = tabIndex;
        }
        if (search != '') {
            filter_.search = search;
        }
        filter_.ordering = ordering;
        filter_.order_column = orderingColumn;

        if (tabIndex == 4) {
            // ZDIELANE
            filter_.shared = true;
        }
        if (tabIndex == 5) {
            // VEREJNE
            filter_.public = true;
        }

        return filter_;
    }

    const MenuSortResult = (id, index) => {
        let order = index == 1 ? global.order.up : global.order.down;
        var column = 'filename';
        var filter_ = GetFilter();

        switch (id) {

            case 1: // NAZOV SUBORU
                column = 'filename';
                break;

            case 2: // VYTVORENE
                column = 'created';
                break;

        }

        filter_.ordering = order;
        filter_.order_column = column;
        setOrdering(order);
        setOrderingColumn(column);

        db_get(0, filter_);
    }

    const Search = (txt) => {
        setSearch(txt);
        var filter_ = GetFilter();
        delete (filter_.search);

        if (txt.length > 1) {
            filter_.search = txt;
            db_get(0, filter_);
        }
        if (txt == '') {
            db_get(0, filter_);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_files_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const ChangeTab = (id) => {
        setTabIndex(id);
        setSearch('');

        var filter = GetFilter();
        delete (filter.public);
        delete (filter.shared);

        if (id != 1) {
            filter.folder = id;
            if (id == 4) {
                // ZDIELANE                
                filter.shared = true;
            }
            if (id == 5) {
                // VEREJNE                
                filter.public = true;
            }
        } else {
            delete (filter.folder);
        }
        db_get(0, filter);

    }

    const SelectItem = (item) => {
        if (props.images && global.supported_products_images.includes(item.extension) || props.images == undefined) {
            func(true, item.link);
        }
    }

    const PreviewFile = (item) => {
        if (global.supported_types.includes(item.extension)) {

            // POZRETIE + 1
            let viewed = parseInt(item.viewed) + 1;
            let data = {
                id: item.id,
                viewed: viewed
            }
            item.viewed = viewed;
            db_update_preview(data);

            // ZOBRAZENIE SUBORU
            setPreviewLink(global.web + '/' + item.link);
            setShowPreview(true);
        }
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 30000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faFileAlt} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, backgroundColor: color.back }}>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.BlockCenter, background: color.sub_menu_back, width: menuWidth, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <FormSpace height={10} />
                                <SubMenuButton id={1} selected_id={tabIndex} width={menuWidth} label={lang.files_all_} theme={props.theme} func={ChangeTab.bind(this)} />
                                <SubMenuButton id={2} selected_id={tabIndex} width={menuWidth} label={lang.files_images} theme={props.theme} func={ChangeTab.bind(this)} />
                                <SubMenuButton id={3} selected_id={tabIndex} width={menuWidth} label={lang.files_documents} theme={props.theme} func={ChangeTab.bind(this)} />
                                <SubMenuButton id={4} selected_id={tabIndex} width={menuWidth} label={lang.files_shared} theme={props.theme} func={ChangeTab.bind(this)} />
                                <SubMenuButton id={5} selected_id={tabIndex} width={menuWidth} label={lang.files_public} theme={props.theme} func={ChangeTab.bind(this)} />
                                {folders != false ? folders.map((item, index) => (
                                    <SubMenuButton key={index} id={item.id} selected_id={tabIndex} width={menuWidth} label={item.name} theme={props.theme} func={ChangeTab.bind(this)} />
                                )) : null}
                            </div>
                            <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - global.dialog_bar_height, borderBottomRightRadius: props.radius }}>

                                <div style={{ ...styles.BlockRow, width: viewWidth }}>
                                    <div style={{ ...styles.BlockLeft, width: '40%', height: menuHeight }}>
                                        <TextInput redraw search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '40%', height: menuHeight }}>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: 200, height: menuHeight }}>
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - global.dialog_bar_height - menuHeight - 1, borderBottomRightRadius: props.radius }} >
                                    <div style={{ ...styles.Block, border: color.border, width: '96%', height: tableHeight }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.Block, width: rowHeight + 8, height: tableHeight, justifyContent: 'center' }}>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '35%', height: tableHeight, justifyContent: 'center', borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, height: tableHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.file_filename}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableHeight, justifyContent: 'center' }}>
                                                    <MenuButton id={1} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: 170, height: tableHeight, borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.files_folder}</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: 170, height: tableHeight, borderLeft: color.border }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.user}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: 150, height: tableHeight, borderLeft: color.border }}>
                                                <div style={{ ...styles.BlockLeft, height: tableHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.created}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 30, height: tableHeight, justifyContent: 'center' }}>
                                                    <MenuButton id={2} sortMenu={true} menuItems={itemsOrdering} theme={props.theme} func={MenuSortResult.bind()} />
                                                </div>
                                            </div>
                                            <div style={{ ...styles.Block, width: 100, height: tableHeight, borderLeft: color.border }}>
                                                <p style={{ ...styles.TextXTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.file_size_}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - menuHeight - tableHeight - statusHeight, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div id={'id_files_start'} style={{ ...styles.Block }}></div>
                                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: props.images ? global.supported_products_images.includes(item.extension) ? color.white : color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    width: '96%',
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                    opacity: props.images ? global.supported_products_images.includes(item.extension) ? 1 : 0.25 : 1
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >

                                                <div style={{ ...styles.BlockRow }}>
                                                    <div onClick={() => PreviewFile(item)} style={{ ...styles.Block, width: rowHeight + 8, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        {global.supported_images.includes(item.extension) && item.size < 5000000 ?
                                                            <img src={global.web + '/' + item.link} style={{ width: '100%', height: '100%', maxWidth: rowHeight - 10, maxHeight: rowHeight - 10, objectFit: 'contain' }}></img>
                                                            :
                                                            <FontAwesomeIcon style={{ height: 20, color: color.xxgray }} icon={faFileAlt} />
                                                        }
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '35%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.filename}</p>
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: 170, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.folder}</p>
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: 170, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{item.user}</p>
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{GetDatumTime2(item.created)}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXTiny, color: color.dark_gray }}>{GetMBFromBytes(item.size)} MB</p>
                                                    </div>
                                                </div>
                                            </Paper>
                                        )) :
                                            <div style={{ ...styles.Block, paddingTop: 30 }}>
                                                <EmptyList row small lang={lang} />
                                            </div>
                                        }

                                        {/* PAGINATION */}
                                        {pages > 1 ?
                                            <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            </div>
                                            : null}
                                    </div>

                                    <div style={{ ...styles.Block, height: statusHeight - 1, borderTop: color.border }}>
                                        {cloudEnabled == true ? cloudUsed > -1 ?
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.Block, position: 'relative', width: statusHeight - 4, height: statusHeight }}>
                                                    <CircularProgress size={statusHeight - 4} variant="determinate" value={100} style={{ color: color.light_gray }} />
                                                    <div style={{ ...styles.Block, position: 'absolute', top: 0, left: 0, width: statusHeight - 4, height: statusHeight }}>
                                                        <CircularProgress size={statusHeight - 4} variant="determinate" value={cloudPercentage > 100 ? 100 : cloudPercentage} style={{ color: color.blue }} />
                                                    </div>
                                                    <div style={{ ...styles.Block, position: 'absolute', top: 0, left: 0, width: statusHeight - 4, height: statusHeight }}>
                                                        <p style={{ ...styles.TextXXTiny }}>{Math.round(cloudPercentage)}%</p>
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.Block, width: undefined, height: statusHeight }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.file_cloud_space}: {GetMBFromBytes(cloudUsed)}/{GetMBFromBytes(props.organization.cloud_size)} MB<span style={{ marginLeft: 40 }}>{lang.file_cloud_free_space}: {GetMBFromBytes(cloudFree)} MB</span></p>
                                                </div>
                                            </div>
                                            : null : null}
                                    </div>
                                </div >
                            </div>
                        </div >
                    </div >
                </div >

                {showPreview == true ?
                    <WebURLPreview url={previewLink} theme={props.theme} title={lang.file_preview} lang={props.lang} func={() => setShowPreview(false)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}


/*
*
* =================================================================================================================
* DETAILY O EXPORTOVANOM SUBORE
* =================================================================================================================
*
*/
export const FileExportDetails = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [enabled, setEnabled] = useState(true);
    const [filename, setFilename] = useState('');
    const [folders, setFolders] = useState(false);
    const [folderID, setFolderID] = useState(-1);
    const [note, setNote] = useState('');
    const [link, setLink] = useState('');
    const [size, setSize] = useState('');
    const [extension, setExtension] = useState('');
    const [downloaded, setDownloaded] = useState('');
    const [file, setFile] = useState(false);
    const [ownerID, setOwnerID] = useState(0);

    const [showSpaceError, setShowSpaceError] = useState(false);

    // ZOZNAM UZIVATELOV
    const [users, setUsers] = useState(false);

    const [showCopied, setShowCopied] = useState(false);
    const [showPreviewButton, setShowPreviewButton] = useState(false);
    const [needSave, setNeedSave] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    // ODOSLAT EMAILOM 
    const [showEmail, setShowEmail] = useState(false);

    let editing = true;

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 820 ? 820 : width;
    const dialogHeight = height >= 600 ? 600 : height;

    const column1 = 140;
    const column2 = dialogWidth - column1 - 40;

    const buttonHeight = 70;
    const errorHeight = 40;
    let tabHeight = 40;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            Debug(props);
            if (props.item != false) {
                // UDAJE Z DB
                db_folders();
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'file_exported', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id
                })
            })

            const json = await response.json();
            Debug('file_exported -> json', 'green');
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    setEnabled(json.item.enabled == false ? false : true);
                    setFilename(json.item.filename);
                    setLink(global.web + '/' + json.item.link);
                    setFile(json.item.link);
                    setNote(json.item.note);
                    // setSize(json.item.size);
                    // setViewed(json.item.viewed);
                    setDownloaded(json.item.downloaded);
                    //setPublicFile(json.item.public == false ? false : true);
                    //setShared(json.item.shared == false ? [] : JSON.parse(json.item.shared));
                    setOwnerID(json.item.user_id);

                    setUsers(json.users);

                    let ext = json.item.link.substring(json.item.link.lastIndexOf(".")).replace('.', '').toLowerCase();
                    setExtension(ext);

                    if (global.supported_types.includes(ext)) {
                        setShowPreviewButton(true);
                    } else {
                        setShowPreviewButton(false);
                    }

                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_folders = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'file_folders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    language: props.language
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                var tmp = json.items;
                var folders_ = [];
                folders_.push({ id: 2, name: lang.files_images });
                folders_.push({ id: 3, name: lang.files_documents });
                if (tmp != false) {
                    tmp.forEach(item => {
                        folders_.push(item);
                    });
                }
                setFolders(folders_);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'db_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    model_id: global.model.exported_data,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_copy = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'file_exported_copy', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    folder_id: folderID,
                    id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setNeedSave(false);
                setShowOK(true);
            } else {
                if (json.full == true) {
                    setShowSpaceError(true);
                } else {
                    setShowError(true);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(false);
    }

    const Copy = () => {
        db_copy();
    }


    const LinkCopy = () => {
        navigator.clipboard.writeText(link);
        setShowCopied(true);
        var timer = setTimeout(() => {
            setShowCopied(false);
        }, 2000);
    }



    const Download = () => {
        // DOWNLOAD + 1
        let downloaded_ = parseInt(downloaded) + 1;
        let data = {
            id: props.item.id,
            downloaded: downloaded_
        }
        setDownloaded(downloaded_);
        db_update(data);
    }


    const SendResult = () => {
        setShowEmail(false);
    }

    const Preview = () => {
        setShowPreview(true);
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faFileAlt} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.file_exported}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <FormSpace />
                        <FormText require redraw value={filename} editing={false} title={lang.file_filename} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setFilename(txt)} />
                        <FormText redraw value={note} editing={false} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                        <FormText no_border redraw value={downloaded} editing={false} title={lang.file_downloaded} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSize(txt)} />

                        {props.organization.cloud_enabled == true ?
                            <div>
                                <FormSpace height={30} />
                                <FormSelect require value={folderID} items={folders} field={'name'} editing={editing} title={lang.file_copy_to_folder} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setFolderID(id); setNeedSave(true); }} />
                                <FormNote title={lang.file_copy_to_folder_note} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                            </div>
                            : null}
                        <div style={{ ...styles.Block, width: '98%', paddingTop: 10 }}>
                            <Line theme={props.theme}></Line>
                            <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 10 }}>{lang.file_link}</p>
                            <p style={{ ...styles.TextTiny, textAlign: 'center', color: color.dark_gray }}>{link}</p>
                            <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                <div style={{ ...styles.Block, width: 180 }}>
                                    <Button onClick={() => LinkCopy()} style={{ ...styles.ButtonOutlined }}>
                                        <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faCopy} />
                                        {lang.files_copy}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, width: 180 }}>
                                    <a href={props.remote == true ? link + '@in_safari' : link} onClick={() => Download()} download={filename} target="_blank" style={{ ...styles.ButtonOutlined, textDecoration: 'none' }}>
                                        <div style={{ ...styles.Block, height: 34 }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faDownload} />
                                                {lang.download}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <Button onClick={() => setShowEmail(true)} style={{ ...styles.ButtonOutlined, width: 220 }}>
                                    <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faPaperPlane} />
                                    {lang.file_email_send}
                                </Button>
                                {showPreviewButton == true ?
                                    <div style={{ ...styles.Block, width: 180 }}>
                                        <Button onClick={() => Preview()} style={{ ...styles.ButtonOutlined }}>
                                            <FontAwesomeIcon style={{ height: 14, marginRight: 8 }} icon={faEye} />
                                            {lang.file_preview}
                                        </Button>
                                    </div>
                                    : null}
                            </div>
                            {showCopied == true ?
                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.files_copied}</p>
                                : null}

                        </div>
                    </div>

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                        {editing == false ?
                            <p style={{ ...styles.TextTiny }}>{lang.files_share_permissions}</p>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {folderID > 0 && needSave == true ?
                                <Button onClick={() => Copy()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.file_copy}</Button>
                                : null}
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                        </div>
                    </div>
                </div >

                {showPreview == true ?
                    <WebURLPreview url={link} theme={props.theme} title={lang.file_preview} lang={props.lang} func={() => setShowPreview(false)} />
                    : null}

                {showEmail == true ?
                    <DialogEmail attachment={file} organization={props.organization} settings={props.settings} system={props.system} locale={props.locale} user={props.user} lang={props.lang} language={props.language} permissions={props.permissions} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.file_email_send_label} token={props.token} func={SendResult.bind()} />
                    : null}

                {showSpaceError == true ?
                    <ShowInfo text={lang.file_cloud_not_enoght_space} sub_text={lang.file_cloud_full_note} lang={lang} theme={props.theme} icon={faXmark} func={() => setShowSpaceError(false)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop >
    );
}


export const FilesWindow = (props) => {


    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let { func } = props;

    useEffect(() => {


    }, [props.locale, props.params]);

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => func(true)} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflow: 'hidden' }}>
                <Files remote={props.remote} remoteMobile={props.remoteMobile} params={props.params} init_type={props.init_type == undefined ? 1 : props.init_type} window organization={props.organization} user={props.user} system={props.system} settings={props.settings} permissions={props.permissions} lang={props.lang} language={props.language} locale={props.locale} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={props.lang.orders} width={props.width} height={props.height} token={props.token} />
            </div>

        </Paper >
    );
}

